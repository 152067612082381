<div
  *ngIf="organisationService && organisationService.activeItem && certification"
  class="two-columns"
>
  <div>
    <h1>Zertifizierung anlegen</h1>
    <div class="mini-grid-product-type">
      <mat-form-field>
        <input
          matInput
          placeholder="Zertifizierungsstelle"
          [value]="organisationService.activeItem.organisationName"
          disabled
        />
      </mat-form-field>
      <mat-form-field>
        <mat-select
          name="certificationCategory"
          placeholder="Produktkategorie"
          [(ngModel)]="certification.category"
          required
        >
          <mat-option [value]="'APP'">Zertifizierung</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <form #form="ngForm">
      <mat-form-field
        class="fullWidth product-name"
        hintLabel="Keine schon vorhandenen Zertifizierungsnamen oder Sonderzeichen"
      >
        <input
          matInput
          [(ngModel)]="certification.name"
          placeholder="Zertifizierungsname"
          name="certificationName"
          required
          #certificationName="ngModel"
        />
        <mat-error
          *ngIf="
            certificationName.errors?.required && certificationName.touched
          "
        >
          Bitte geben Sie einen Zertifizierungsnamen ein.
        </mat-error>
      </mat-form-field>
        <mat-form-field>
          <mat-select
            placeholder="Zertifizierungstyp"
            name="certificationType"
            [(ngModel)]="certification.certificationType"
            required
          >
            <mat-option [value]="1">Merkmale</mat-option>
            <mat-option [value]="2">Produkttyp</mat-option>
            <mat-option [value]="0">Toolchain</mat-option>
          </mat-select>
        </mat-form-field>
      <app-text-editor
        [(editorText)]="certification.description"
        (textValidityChange)="onDescriptionValidityChange($event)"
      ></app-text-editor>
      <mat-form-field class="fullWidth url">
        <input
          matInput
          [(ngModel)]="certification.url"
          placeholder="URL"
          name="url"
        />
      </mat-form-field>

      <div>
        <p>
          <em>
            Zum Anlegen einer Zertifizierung ist das Anlegen einer Variante
            sowie einer dazugehörigen Version notwendig
          </em>
        </p>
        <div>
          <mat-form-field class="fullWidth">
            <input
              matInput
              placeholder="Variantenname"
              name="variantName"
              #variantName="ngModel"
              [(ngModel)]="certification.certificationVariants[0].name"
              required
            />
            <mat-error
              *ngIf="variantName.errors?.required && variantName.touched"
            >
              Bitte geben Sie einen Variantennamen ein.
            </mat-error>
          </mat-form-field>
        </div>
        <app-version-edit
          [version]="
            certification.certificationVariants[0]
              .certificationVariantVersions[0]
          "
          [dataType]="'CERTIFICATION'"
          (validityChanged)="onValidityChanged($event)"
        ></app-version-edit>
      </div>
      <div class="mini-grid-buttons">
        <button
          id="savebtn"
          mat-raised-button
          class="btn_green"
          [disabled]="
            !isFormValid ||
            !isInputValid() ||
            form.invalid ||
            !isDescriptionValid
          "
          (click)="createCertification()"
        >
          Speichern
        </button>
        <button
          mat-raised-button
          class="btn_red"
          (click)="cancelCreateCertification()"
        >
          Abbrechen
        </button>
      </div>
    </form>
  </div>

  <div>
    <div class="image-upload">
      <div class="img-wrapper">
        <img
          class="img"
          *ngIf="certification.fileId"
          [src]="imagePath(certification.fileId)"
        />
        <img
          class="img"
          *ngIf="!certification.fileId"
          src="../../../../../assets/img/hexagon.png"
        />
      </div>
    </div>
    <button
      class="image-upload-button"
      mat-raised-button
      (click)="openFileManager('Logo')"
    >
      Logo ändern
    </button>
    <h2>Dokumente</h2>
    <ul>
      <li
        *ngFor="let id of certification.documentFileIds"
        class="document-item"
      >
        {{ fileInfo(id)?.filename }}
        <button (click)="deleteDocument(id)">X</button>
      </li>
    </ul>
    <button mat-raised-button (click)="openFileManagerPicture('Dokumente')">
      hinzufügen
    </button>

    <h2>Zertifizierungsgalerie</h2>
    <div class="certification-gallery-editor">
      <div class="gallery-preview">
        <div
          *ngFor="let id of certification.galleryFileIds"
          class="gallery-item"
        >
          <div class="gallery-item-control">
            <button
              mat-flat-button
              class="gal_sortLeft"
              (click)="moveGalleryItem(id, true)"
            >
              &lt;
            </button>
            <button
              mat-flat-button
              class="gal_delete"
              color="warn"
              (click)="deleteFromGallery(id)"
            >
              &times;
            </button>
            <button
              mat-flat-button
              class="gal_sortRight"
              (click)="moveGalleryItem(id, false)"
            >
              &gt;
            </button>
          </div>
          <app-secure-image [src]="imagePath(id)"></app-secure-image>
        </div>
      </div>
      <div class="gallery-preview-control">
        <div class="mini-hex-button" id="gallery-add-item">
          <hexagon
            [icon]="'plus'"
            [hover]="true"
            [outerColor]="'green'"
            (click)="openFileManagerPicture('Galerie')"
          ></hexagon>
        </div>
      </div>
    </div>
  </div>
</div>
