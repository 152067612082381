import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CommentInterface } from "src/app/model/reviews/comment-interface";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ReviewService } from "src/app/services/review-service/review.service";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { UserService } from "src/app/services/user-service/userservice";
import { ReviewInterface } from "src/app/model/reviews/review-interface";

@Component({
  selector: "app-write-comment",
  templateUrl: "./write-comment.component.html",
  styleUrls: ["./write-comment.component.css"],
})
export class WriteCommentComponent implements OnInit {
  hoverstate: number = 0;
  private maxTextChar: number = 10000;
  @Input() reviewId: number;
  @Input() commentId?: number;
  @Output() commentAddEmitter = new EventEmitter<ReviewInterface>();
  @Output() commentCancelEmitter = new EventEmitter();

  form = new FormGroup({
    text: new FormControl("", [
      Validators.required,
      Validators.maxLength(this.maxTextChar),
    ]),
  });

  constructor(
    private reviewService: ReviewService,
    private dialogService: DialogService,
    private userService: UserService
  ) {}

  ngOnInit() {}

  getErrorMessageText() {
    var errorMessage = "";
    if (this.form.get("text").hasError("required")) {
      errorMessage = "Bitte geben Sie ihren Kommentar ein";
    } else if (this.form.get("text").hasError("maxlength")) {
      errorMessage = `Ihr Kommentar darf nicht länger als ${this.maxTextChar} Zeichen sein`;
    }
    return errorMessage;
  }

  sendComment(): void {
    this.dialogService
      .openConfirmDialog(
        "Sind Sie sicher den Kommentar abzusenden?",
        "Nein",
        "Ja"
      )
      .subscribe((result) => {
        if (result) {
          let currentUser = this.userService.currentUser;
          if (this.form.get("text").valid) {
            const newComment: CommentInterface = {
              text: this.form.get("text").value,
              commentId: this.commentId,
              creatorId: currentUser.userId,
            };

            let newReview: ReviewInterface;
            this.reviewService
              .addComment(this.reviewId, newComment)
              .subscribe((review: ReviewInterface) => {
                newReview = review;
                if (this.commentId == null) {
                  this.dialogService.openDialog(
                    "Kommentar erfolgreich abgesendet",
                    ""
                  );
                } else {
                  this.dialogService.openDialog(
                    "Kommentar erfolgreich bearbeitet",
                    ""
                  );
                }
                this.commentAddEmitter.emit(newReview);
              });
          } else {
            this.dialogService.openDialog(
              "Es ist ein Fehler aufgetreten",
              "Das Kommentarfeld muss zwischen 1 bis 20.000 Zeichen lang sein"
            );
          }
        }
      });
  }

  cancel(): void {

          this.commentCancelEmitter.emit();

  }

  hoverEnter(newHoverState: number): void {
    this.hoverstate = newHoverState;
  }

  hoverLeave(newHoverState: number): void {
    this.hoverstate = newHoverState;
  }
}
