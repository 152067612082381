import { Component, OnInit, Input, Output, ViewChild, ElementRef, OnDestroy, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.css']
})
export class InfiniteScrollComponent implements OnInit, OnDestroy {

  @Input() options = {}
  @Output() scrolled = new EventEmitter;
  @ViewChild('anchor', {static: true}) anchor: ElementRef<HTMLElement>;

  private intersectionObserver: IntersectionObserver;

  constructor(private host: ElementRef) { }

  ngOnInit() {
    const options = {
      root: this.isHostScrollable() ? this.host.nativeElement : null,
      ...this.options
    };

    this.intersectionObserver = new IntersectionObserver(([entry]) => {
      entry.isIntersecting && this.scrolled.emit();
    }, options);

    this.intersectionObserver.observe(this.anchor.nativeElement);
  }

  ngOnDestroy() {
    if(this.intersectionObserver){
      this.intersectionObserver.disconnect();
    }
  }

  get element() {
    return this.host.nativeElement;
  }

  
  isHostScrollable() {
    const style = window.getComputedStyle(this.element);
    return style.getPropertyValue('overflow') == 'auto' || style.getPropertyValue('overflow-y') == 'scroll';
  }
  
}
