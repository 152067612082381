import { Component, OnInit, Input } from '@angular/core';

import { HeaderServiceService } from 'src/app/services/header-service/header-service.service';
@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.css']
})
export class HomeScreenComponent implements OnInit {


  constructor(
    private headerService: HeaderServiceService) { }

  ngOnInit(): void {
    this.headerService.loadHeaderText("BIMSWARM");
  }

}
