<div class="grid-container">
  <div class="profile-picture">
    <div>
      <img
        *ngIf="profile?.profilePictureId == null; else picture"
        src="{{ getDefaultProfileImageUrl() }}"
        alt="Profilbild"
        width="100%"
        height="100%"
      />
      <ng-template #picture>
        <app-secure-image
          [src]="getDefaultProfileImageUrl()"
          [fileId]="profile?.profilePictureId"
          [contain]="true"
          [isScreen]="true"

        ></app-secure-image>
      </ng-template>
    </div>
  </div>
  <div class="grid-item contact-info">
    <h1>Kontakt</h1>

    <h3>Name</h3>
    <h2>
      {{ profile?.name }}
    </h2>

    <h3>Telefonnummer</h3>
    <h2>{{ profile?.phoneNumber || "-" }}</h2>

    <h3>Mobile Nummer</h3>
    <h2>{{ profile?.mobileNumber || "-" }}</h2>

    <h3>E-Mail</h3>
    <h2>{{ profile?.mail || "-" }}</h2>

    <h3>Webseite</h3>
    <h2>{{ profile?.website || "-" }}</h2>
  </div>
  <div class="grid-item left">
    <div>
      <h3>Projekte</h3>
      <h2 *ngIf="profile?.projects.length == 0">-</h2>
      <div *ngFor="let project of profile?.projects">
        <h2>{{ project?.projectName }}</h2>
      </div>

      <h3>Arbeitgeber</h3>
      <h2>{{ profile?.employer || "-" }}</h2>

      <h3>Tätigkeit</h3>
      <h2>{{ profile?.jobDescription || "-" }}</h2>
    </div>
  </div>

  <div class="grid-item">
    <h1>Persönliche Beschreibung</h1>
    <h2>
      {{ profile?.aboutMe || "-" }}
    </h2>
    <h1>BIMSWARM Organisation</h1>
    <div
      *ngFor="
        let organisation of this.profile?.organisations || [] | confirmedMembers
      "
    >
      <h2>{{ organisation.organisationName || "-" }}</h2>
    </div>
    <h1>Bewertungen</h1>
    <app-infinite-scroll (scrolled)="onScroll()">
      <div class="activities" *ngIf="reviews.length > 0">
        <app-public-activity-item
          *ngFor="let review of reviews"
          [username]="profile?.name"
          [review]="review"
        ></app-public-activity-item>
      </div>
    </app-infinite-scroll>
  </div>
</div>
