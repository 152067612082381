import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Schema } from "src/app/model/compatiblity/schema-interface";
import { SchemaService } from "src/app/services/compatiblity-service/schema-service.service";

@Component({
  selector: "app-add-schema-form",
  templateUrl: "./add-schema-form.component.html",
  styleUrls: ["./add-schema-form.component.css"],
})
export class AddSchemaFormComponent implements OnInit, AfterViewInit {
  public addSchemaForm: FormGroup;
  /**
   * The control for the search field in the "add schema form"
   */
  searchControl = new FormControl();
  searchObservable: Observable<String>;
  /**
   * A datasource containing all available schemas used in the "add schema" form
   * excluding any schema that was already added to the documenttype
   */
  public addSchemaDataSource: MatTableDataSource<Schema> = new MatTableDataSource<Schema>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * A list containing all schemas that are available in the service
   */
  @Input("allSchemas") private allSchemas: Schema[];

  /**
   * Contains all selected schemas
   */
  @Input("selectedSchemaIds") public selectedSchemaIds: number[];
  @Output() onSave: EventEmitter<number[]> = new EventEmitter();

  constructor(private schemaService: SchemaService, fb: FormBuilder) {
    this.addSchemaForm = fb.group({
      schemas: [[]],
    });
  }

  ngOnInit() {
    // Setup the observable on the search input and debounce its values to
    // not trigger the filter on every input
    this.searchControl.valueChanges
      .pipe<string>(debounceTime(500))
      .subscribe((value) => {
        this.addSchemaDataSource.filter = value;
      });
    this.addSchemaDataSource.filterPredicate = (data: Schema, filter) => {
      const composite =
        data.dataFormat.name + data.name + data.subSchema + data.version;

      return composite.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    };
    this.addSchemaDataSource.data = this.allSchemas.filter(
      (schema) => !this.selectedSchemaIds.includes(schema.id)
    );
  }

  handleSchemasSave(): void {
    this.onSave.emit(this.addSchemaForm.controls.schemas.value);
  }

  /**
   * Callback for the schema checkbox in the "add schema" form
   * @param schemaId the id of the selected schemas
   */
  public handleSchemaCheckboxChange(schemaId: number) {
    this.addSchemaForm.controls.schemas.value.push(schemaId);
  }

  ngAfterViewInit(): void {
    // intialize the paginator for the data source
    this.addSchemaDataSource.paginator = this.paginator;
  }
}
