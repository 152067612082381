import { Component, OnInit, Input } from "@angular/core";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AddressInterface } from "src/app/model/address-interface";
import { query } from '@angular/animations';
import { MarketplaceFilterService } from 'src/app/services/componentServices/marketplace-filter-service/marketplace-filter.service';
import { CheckBoxCategorie } from 'src/app/model/marketplace/marketplace-checkbox';

@Component({
  selector: "app-product-detail-display-organisation",
  templateUrl: "./product-detail-display-organisation.component.html",
  styleUrls: [
    "./product-detail-display-organisation.component.css",
    "../product-detail-display.css",
  ],
})
export class ProductDetailDisplayOrganisationComponent implements OnInit {
  constructor(private router: Router, 
              private route: ActivatedRoute,
              private marketPlaceFilterService: MarketplaceFilterService,
    ) {}
  
  @Input()
  organisation: OrganisationInterface;

  ngOnInit() {}

  navigateToMarketplace() {
    //this.marketPlaceFilterService.filter.organisationIds.push(this.organisation.organisationId);
    this.marketPlaceFilterService.addFilter(this.organisation.organisationId, CheckBoxCategorie.ORGANISATION);

  }

  navigateToOrganisationPage(){
    this.router.navigateByUrl('/privacy', {skipLocationChange: true}).then(() => {

    this.router.navigateByUrl("/marktplatz?tab=2&organisationId=" + this.organisation.organisationId);
   });

  }
}
