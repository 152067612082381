import { NgModule } from "@angular/core";
import { CustomComponentModule } from "./app-component-module";

import { QuillModule } from "ngx-quill";
import { AttributesScreenComponent } from "../components/attributes/attributes-screen/attributes-screen.component";
import { HomeScreenComponent } from "../components/miscellaneous/home-screen/home-screen.component";
import { OrganisationInformationComponent } from "../components/organisation-user-settings/views-organisation-management/organisation-information/organisation-information.component";
import { CertificationRequestsComponent } from './../components/organisation-user-settings/views-organisation-management/certification-requests/certification-requests.component';
import { OrganisationUserComponent } from "../components/organisation-user-settings/views-organisation-management/organisation-user/organisation-user.component";
import { KontoScreenComponent } from "../components/organisation-user-settings/views-user-settings/konto-screen/konto-screen.component";
import { OrganisationScreenComponent } from "../components/organisation-user-settings/views-user-settings/organisation-screen/organisation-screen.component";
import { SettingsScreenComponent } from "../components/organisation-user-settings/views-user-settings/settings-screen/settings-screen.component";
import { CreateCertificationVersionScreenComponent } from "../components/product-certification/certifications/certification-variant-versions/certification-version-create-screen/create-certification-version.component";
import { CertVersionManageScreenComponent } from "../components/product-certification/certifications/certification-variant-versions/certification-version-manage-screen/cert-verrsion-manage-screen.component";
import { CertificationVariantCreateScreenComponent } from "../components/product-certification/certifications/certification-variants/certification-variant-create-screen/certification-variant-create-screen.component";
import { CertificationVariantEditScreenComponent } from "../components/product-certification/certifications/certification-variants/certification-variant-edit-screen/certification-variant-edit-screen.component";
import { CertificationVariantScreenComponent } from "../components/product-certification/certifications/certification-variants/certification-variant-screen/certification-variant-screen.component";
import { CertificationEditScreenComponent } from "../components/product-certification/certifications/certifications/certification-edit-screen/certification-edit-screen.component";
import { CertificationInfoScreenComponent } from "../components/product-certification/certifications/certifications/certification-info-screen/certification-info-screen.component";
import { CertificationNoContentScreenComponent } from "../components/product-certification/certifications/certifications/certification-no-content-screen/certification-no-content-screen.component";
import { CertificationScreenComponent } from "../components/product-certification/certifications/certifications/certification-screen/certification-screen.component";
import { ProductVariantVersionCreateScreenComponent } from "../components/product-certification/products/product-variant-version-create-screen/product-variant-version-create-screen.component";
import { CreateProductVariantScreenComponent } from "../components/product-certification/products/product-variants/create-product-variant-screen/create-product-variant-screen.component";
import { EditProductVariantScreenComponent } from "../components/product-certification/products/product-variants/edit-product-variant-screen/edit-product-variant-screen.component";
import { ProductVariantScreenComponent } from "../components/product-certification/products/product-variants/product-variant-screen/product-variant-screen.component";
import { ProductEditScreenComponent } from "../components/product-certification/products/products/product-edit-screen/product-edit-screen.component";
import { ProductInfoScreenComponent } from "../components/product-certification/products/products/product-info-screen/product-info-screen.component";
import { ProductScreenComponent } from "../components/product-certification/products/products/product-screen/product-screen.component";
import { ProductSsoClientRegistrationComponent } from "../components/product-certification/products/products/product-sso-client-registration/product-sso-client-registration.component";
import { ProductsNoContentComponent } from "../components/product-certification/products/products/products-no-content/products-no-content.component";
import { ProductTypeGroupEditComponent } from "../components/product-type-groups/product-type-group-edit/product-type-group-edit.component";
import { ProductTypeGroupNoContentComponent } from "../components/product-type-groups/product-type-group-no-content/product-type-group-no-content.component";
import { ProductTypeGroupScreenComponent } from "../components/product-type-groups/product-type-group-screen/product-type-group-screen.component";
import { ProductTypeGroupVersionScreenComponent } from "../components/product-type-groups/product-type-group-version-screen/product-type-group-version-screen.component";
import { PtgOverviewComponent } from "../components/product-type-groups/ptg-overview/ptg-overview.component";
import { LoginScreenComponent } from "../components/registration-login/login/login-screen/login-screen.component";
import { PasswordResetScreenComponent } from "../components/registration-login/login/password-reset-screen/password-reset-screen.component";
import { ResendMailScreenComponent } from "../components/registration-login/login/resend-mail-screen/resend-mail-screen.component";
import { RegistryConfirmComponent } from "../components/registration-login/registration/registry-confirm/registry-confirm.component";
import { RegistryPageComponent } from "../components/registration-login/registration/registry-page/registry-page.component";
import { VerifyUserNameComponent } from "../components/registration-login/registration/verify-user-name/verify-user-name.component";
import { CommonFeatureAdministrationComponent } from "../components/common-feature/common-feature-administration/common-feature-administration.component";
import { ComposerScreenComponent } from '../components/composer/composer-screen/composer-screen.component';
import { ComposerEditorScreenComponent } from '../components/composer/composer-editor-screen/composer-editor-screen.component';
import { ComposerOrganisationScreenComponent } from '../components/composer/composer-organisation-screen/composer-organisation-screen.component';
import { DataFormatsScreenComponent } from '../components/compatiblity-features/data-formats-screen/data-formats-screen.component';
import { EditDataFormatScreenComponent } from '../components/compatiblity-features/edit-data-format-screen/edit-data-format-screen.component';
import { DocumentTypesScreenComponent } from '../components/compatiblity-features/document-types-screen/document-types-screen.component';
import { EditDocumentTypeScreenComponent } from '../components/compatiblity-features/edit-document-type-screen/edit-document-type-screen.component';
import { DialogReportReviewComponent } from '../components/dialog-windows/report-review/report-review.component';
import { ReviewAdministrationComponent } from '../components/reviews/review-administration/review-administration.component';
import { ComposerViewerScreenComponent } from '../components/composer/composer-viewer-screen/composer-viewer-screen.component';
import { UserProfilePublicComponent } from "../components/components-profiles/user-profile-public/user-profile-public.component";
import { UserProfileScreenComponent } from "../components/components-profiles/user-profile-screen/user-profile-screen.component";
import {NutzerverwaltungScreenComponent} from "../components/organisation-user-settings/views-user-settings/nutzerverwaltung-screen/nutzerverwaltung-screen.component";
import { CamundaProcessListComponent } from "../components/camunda/camunda-process-list/camunda-process-list.component";

@NgModule({
  declarations: [
    
    HomeScreenComponent,
    RegistryPageComponent,
    RegistryConfirmComponent,
    LoginScreenComponent,
    KontoScreenComponent,
    CommonFeatureAdministrationComponent,
    NutzerverwaltungScreenComponent,
    SettingsScreenComponent,
    OrganisationScreenComponent,
    OrganisationInformationComponent,
    CertificationRequestsComponent,
    OrganisationUserComponent,
    ProductScreenComponent,
    ProductEditScreenComponent,
    ProductSsoClientRegistrationComponent,
    ProductInfoScreenComponent,
    ProductVariantScreenComponent,
    CreateProductVariantScreenComponent,
    EditProductVariantScreenComponent,
    ProductVariantVersionCreateScreenComponent,
    PtgOverviewComponent,
    ProductTypeGroupScreenComponent,
    ProductTypeGroupEditComponent,
    ProductTypeGroupVersionScreenComponent,
    AttributesScreenComponent,
    ProductTypeGroupNoContentComponent,
    ProductsNoContentComponent,
    CertificationScreenComponent,
    CertificationNoContentScreenComponent,
    CertificationInfoScreenComponent,
    CertificationEditScreenComponent,
    CertificationVariantScreenComponent,
    CertificationVariantCreateScreenComponent,
    CertificationVariantEditScreenComponent,
    CreateCertificationVersionScreenComponent,
    CertVersionManageScreenComponent,
    PasswordResetScreenComponent,
    ResendMailScreenComponent,
    VerifyUserNameComponent,
    ComposerScreenComponent,
    ComposerEditorScreenComponent,
    ComposerOrganisationScreenComponent,
    DataFormatsScreenComponent,
    EditDataFormatScreenComponent,
    DocumentTypesScreenComponent,
    EditDocumentTypeScreenComponent,
    DialogReportReviewComponent,
    ReviewAdministrationComponent,
    ComposerViewerScreenComponent,
    UserProfilePublicComponent,
    UserProfileScreenComponent,
    
  ],
  imports: [CustomComponentModule, QuillModule],
  exports: [
    //Modules
    CustomComponentModule,
    //Views
    HomeScreenComponent,
    RegistryPageComponent,
    RegistryConfirmComponent,
    LoginScreenComponent,
    KontoScreenComponent,
    CommonFeatureAdministrationComponent,
    NutzerverwaltungScreenComponent,
    SettingsScreenComponent,
    OrganisationScreenComponent,
    OrganisationInformationComponent,
    OrganisationUserComponent,
    ProductScreenComponent,
    ProductEditScreenComponent,
    ProductSsoClientRegistrationComponent,
    ProductInfoScreenComponent,
    ProductVariantScreenComponent,
    CreateProductVariantScreenComponent,
    EditProductVariantScreenComponent,
    ProductVariantVersionCreateScreenComponent,
    PtgOverviewComponent,
    ProductTypeGroupScreenComponent,
    ProductTypeGroupEditComponent,
    ProductTypeGroupVersionScreenComponent,
    AttributesScreenComponent,
    CertificationScreenComponent,
    CertificationNoContentScreenComponent,
    CertificationInfoScreenComponent,
    CertificationEditScreenComponent,
    CertificationVariantScreenComponent,
    CertificationVariantCreateScreenComponent,
    CertificationVariantEditScreenComponent,
    CreateCertificationVersionScreenComponent,
    CertVersionManageScreenComponent,
    PasswordResetScreenComponent,
    ResendMailScreenComponent,
    VerifyUserNameComponent,
    ComposerScreenComponent,
    ComposerEditorScreenComponent,
    ComposerOrganisationScreenComponent,
    DataFormatsScreenComponent,
    EditDataFormatScreenComponent,
    DocumentTypesScreenComponent,
    EditDocumentTypeScreenComponent,
    DialogReportReviewComponent,
    ReviewAdministrationComponent,
    ComposerViewerScreenComponent,
    UserProfilePublicComponent,
    UserProfileScreenComponent,
    CamundaProcessListComponent,
  ],
})
export class CustomViewModule {}
