import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { PtgInterface } from "src/app/model/ptgs/ptg-interface";
import { PtgVersionInterface } from "src/app/model/ptgs/ptg-version-interface";

import { AttributeInterface } from "src/app/model/attributes/attribute-interface";
import { PtgAttributeValidationInterface } from "src/app/model/attributes/ptg-attribute-validation-interface";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { Status } from "src/app/model/enums/status";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { UtilService } from "src/app/services/util-service/utilService";
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-product-type-group-screen",
  templateUrl: "./product-type-group-screen.component.html",
  styleUrls: ["./product-type-group-screen.component.css"],
})
export class ProductTypeGroupScreenComponent implements OnInit {
  public newPtg: PtgInterface;
  public newPtgVersion: PtgVersionInterface;
  public ptgAttributeValidations: PtgAttributeValidationInterface[] = [];
  public showFeatureCatalogue = false;
  private isDescriptionValid: boolean = true;

  constructor(
    private ptgService: PtgService,
    public organisationService: OrganisationService,
    private router: Router,
    private utilService: UtilService,
    private dialogService: DialogService,
    private userService: UserService
  ) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  savePtg() {
    if (this.utilService.isStringNullOrEmpty(this.newPtg.name)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte geben Sie einen Namen ein."
      );
      return;
    }
    if (
      !UtilService.areStringsValid(this.newPtg.name, this.newPtg.description) //Backend String Length Limit is 60
    ) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen Sie sicher, dass Name und Beschreibung der PTG nicht zu lang sind."
      );
      return;
    }

    if (this.utilService.isStringNullOrEmpty(this.newPtg.description)) {
      this.newPtg.description = "";
    }

    if (this.utilService.isStringNullOrEmpty(this.newPtgVersion.description)) {
      this.newPtgVersion.description = "";
    }

    this.newPtgVersion.attributeValidations = this.ptgAttributeValidations;

    this.ptgService.ITEM_MAP[":organisationId"] = String(
      this.organisationService.activeItem.organisationId
    );

    this.ptgService.createPtg(this.newPtg).then((createdPtg: PtgInterface) => {
      this.router.navigateByUrl(
        "/organisations/" +
          createdPtg.organisationId +
          "/productTypeGroups/" +
          createdPtg.id
      );
      this.dialogService.openDialog(
        "Produkttypegruppe erstellt",
        "Die Produkttypegruppe: " +
          createdPtg.name +
          " wurde erfolgreich erstellt."
      );
    });
  }

  savePtgCancel() {
    var url = this.router.url.replace("/create", "");
    this.router.navigateByUrl(url);
  }

  showFeatureCatalogueToggle() {
    this.showFeatureCatalogue = !this.showFeatureCatalogue;
  }

  featureCatalogueConfirmPressed(attributes: AttributeInterface[]) {
    for (const attribute of attributes) {
      const ptgAttributeValidationContainingAttribute = this.ptgAttributeValidations.find(
        (attributeValidation) => {
          const existingAttribute = attributeValidation.attribute;
          return attribute.id == existingAttribute.id;
        }
      );
      if (ptgAttributeValidationContainingAttribute == null) {
        const newAttributeValidation: PtgAttributeValidationInterface = {
          attribute: attribute,
          optionalValidation: false,
        };
        this.ptgAttributeValidations.push(newAttributeValidation);
      } else {
        const existingAttribute =
          ptgAttributeValidationContainingAttribute.attribute;
        existingAttribute.validations = attribute.validations;
      }
    }
    this.showFeatureCatalogueToggle();
  }

  ngOnInit() {
    this.newPtgVersion = {
      number: 1,
      status: Status.ACTIVE,
      description: "",
      attributeValidations: [],
    };
    let organisationId = this.organisationService.activeItem
      ? this.organisationService.activeItem.organisationId
      : null;
    this.newPtg = {
      name: "",
      organisationId: organisationId,
      versions: [this.newPtgVersion],
      description: "",
    };
  }

  public onDescriptionValidityChange(descriptionValid: boolean): void {
    this.isDescriptionValid = descriptionValid;
  }
}
