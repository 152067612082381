<div class="contact" *ngIf="contact && contactName">
  <div class="headline">Kontakt</div>
  <div></div>
  <div class="line">Ansprechpartner:</div>
  <div class="line">{{ contactName }}</div>
  <div class="line">Tel.:</div>
  <div class="line">{{ contactTelephoneNumber }}</div>
  <div class="line">E-Mail:</div>
  <div class="line">{{ contactEmail }}</div>
  <div class="line">Webseite:</div>
  <a
    *ngIf="contactWebsite"
    href="{{ contactWebsite }}"
    title="Zertifizierungsstellen-Webseite"
  >
    <a
      class="bold"
      href="{{ contactWebsite | linkHttpPipe }}"
      target="_blank"
      >{{ contactWebsite }}</a
    >
  </a>
</div>
