import { Injectable, Attribute, ChangeDetectorRef } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { PtgInterface } from "src/app/model/ptgs/ptg-interface";
import { PtgVersionInterface } from "../../../model/ptgs/ptg-version-interface";

import { UserService } from "../../user-service/userservice";

import { SortMethod } from "../../../model/enums/SortMethod";
import { BaseService } from "../../base.service";
import { Observable, of, Subscribable } from "rxjs";
import { ProductDtoConverterServiceService } from "../../product-dto-converter-service/product-dto-converter-service.service";
import { API_BASE_PRODUCTSERVICE, API_MAP } from "src/environments/api";
import { ProductInterface } from 'src/app/model/products/product-interface';
import { take } from 'rxjs/operators';

const SWARM_API = API_BASE_PRODUCTSERVICE;
const ALL_PTGS_URI = "/producttypegroups";
// with additional id = /producttypegroups/{id}
const SINGLE_PTG_URI = ALL_PTGS_URI + "/";

@Injectable()
export class PtgService extends BaseService<PtgInterface> {
  protected equal(item1: PtgInterface, item2: PtgInterface): boolean {
    return true;
  }
  protected toID(Ptg: PtgInterface | number): number {
    if (!Ptg) return -1;
    if (typeof Ptg === "number") return Ptg;
    if (Ptg.id) return Ptg.id;
    return -1;
  }

  constructor(
    protected http: HttpClient,
    private userService: UserService,
    protected dtoConverter: ProductDtoConverterServiceService
  ) {
    super(http, dtoConverter);
    this.SWARM_API_BASE = SWARM_API;

    this.SWARM_API_MAP = API_MAP["PTGs"];
    this.ITEM_MAP = this.userService.ACTIVE_MAP;
    this.SERVICE_FLAG = ":ptgId";
  }

  setActivePtg(ptg: PtgInterface | number): Promise<boolean> {
    let id = this.toID(ptg);

    this.userService.ACTIVE_MAP["ptgId"] = String(id);
    return this.setActiveItem(id).then(() => {
      this.activeVersion = this.activeItem.versions[0];
      return true;
    });
  }



  // MAKE BETTER
  getPtgFromVersion(ptgVersion: PtgVersionInterface): PtgInterface {
    let returnPtg: PtgInterface;
    this.collection.forEach((ptg) => {
      if (ptg.versions.find((version) => version.id === ptgVersion.id)) {
        returnPtg = ptg;
      }
    });
    return returnPtg;
  }
  //TODO CHECK IN BACKEND
  //TODO CHECK IF CORRECT LOGIC
  getPtgVersion(versionId: number) {
    var allVersion = this.collection
      .map((x) => x.versions)
      .reduce((p, c) => p.concat(c));
    return allVersion.find((x) => x.id === versionId);
  }

  async createPtg(ptg: PtgInterface): Promise<PtgInterface> {
    this.ITEM_MAP["ptgId"] = String(ptg);
    return this.postItem(ptg);
  }


  ptgSliceWithActiveVersion() : Observable<PtgInterface[]> {
    return this.http.get<PtgInterface[]>(SWARM_API + "/producttypegroups");
  }



  ptgsWithActiveVersion() {
    var allPtgWitActiveVersion : PtgInterface[] = [];
    this.collection.forEach((x: PtgInterface) => {
      if (x.versions.some((x) => x.status === "ACTIVE")) {
        allPtgWitActiveVersion.push(x);
      }
    });
    allPtgWitActiveVersion.sort((a: PtgInterface, b: PtgInterface) => {
      return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
    });
    return allPtgWitActiveVersion;
  }
  /* this one is used for the product version edit screen */
  getPtgSliceActVer(
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
    sortMethod: SortMethod
    ) {
      let dto = {
        pageIndex: pageIndex,
        pageSize: pageSize,
        searchTerm: searchTerm,
        sortMethod: sortMethod
      }
      if(!this.userService.isAuthorized("system", "nutzerrechte"))
      {
        return;
      }
      const json = JSON.stringify(dto);
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
      return this.http.post(
        SWARM_API + "/producttypegroups/ptgSlice",
        json,
        httpOptions
      );
    }

  getPtgByTheirVersion(versionId: number) : Observable<PtgInterface>
  {
    return this.http.get<PtgInterface>(SWARM_API + "/producttypegroups/ptgVersion/" + versionId)
  }

  getAllPTGsForOrganisation(organisationId: number)
  {
    return new Promise((resolve => {
    this.http.get<PtgInterface[]>(SWARM_API + "/producttypegroups/organisation/" + organisationId).pipe(take(1))
    .subscribe((data : PtgInterface[]) => {
      resolve(data);
    })}));
  }

  //TODO
  getPtgSlice(
    pageIndex: number,
    pageSize: number,
    searchTerm: string,
    sortMethod: SortMethod
  ) {
    let dto = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm.trim(),
      sortMethod: sortMethod,
    };
    if (!this.userService.isAuthorized("system", "nutzerrechte")) {
      return;
    }
    const json = JSON.stringify(dto);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post(
      SWARM_API + this.SWARM_API_MAP["FILTER"],
      json,
      httpOptions
    );
  }

 // MAKE BETTER

  getAllPtgsForProductVariantVersion(variantVersionId: number): Promise<PtgInterface[]> {
    return new Promise((resolve, reject) => {
      this.http.get<PtgInterface[]>(SWARM_API + "/producttypegroups/variantversion/" + variantVersionId)
        .subscribe((ptgs) => {
          resolve(ptgs);
        },
          () => {
            reject();
          });
    });
  }

  /**
   * WRAPPER
   */
  getAllPtgs(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .get<PtgInterface[]>(this.SWARM_API_BASE + this.SWARM_API_MAP["ALL"])
        .subscribe(
          (ptgs) => {
            this.collection = ptgs;
            this.next();
            resolve(true);
          },
          err => {
            reject(false);
          },
          () => {
            reject(false);
          }
        );
    });
  }
  // TEMP ?
  activeVersion: PtgVersionInterface;

  setActiveVersion(version: PtgVersionInterface | number) {
    let versionId = version;
    if (!(typeof version === "number")) {
      versionId = (version as PtgVersionInterface).id;
    }

    this.collection.forEach((x) => {
      x.versions.forEach((y) => {
        if (y.id === version) {
          this.activeVersion = y;
          return;
        }
      });
    });
  }

  getAllPtgsThatAreReferencedByActiveProducts(): Subscribable<PtgInterface[]> {
    return this.http.get<PtgInterface[]>(
      this.SWARM_API_BASE + this.SWARM_API_MAP["ACTIVE"]
    );
  }

  isProductTypeGroupAssignedToProductOrCertification(ptgVersionId: number):Subscribable<boolean> {
    const url: string =
      API_BASE_PRODUCTSERVICE + API_MAP["PTGs"]["ASSIGNED"];

    return this.http.get<boolean>(url + "/" + ptgVersionId);
  }

}
