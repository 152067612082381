import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent } from "@angular/common/http";
import { Router } from "@angular/router";
import { DocumentInterface } from "src/app/model/documents/document-interface";
import { Subscribable, Observable } from "rxjs";
import { ProductService } from "../product-service/productService";
import { OrganisationService } from "../organisation-service/organisationservice";
import { UserService } from "../../user-service/userservice";
import { FileUploadService } from "../file-upload-service/file-upload.service";
import { API_BASE_PRODUCTSERVICE, API_MAP } from "src/environments/api";

const SWARM_API = API_BASE_PRODUCTSERVICE;
const SWARM_DOC_API = SWARM_API + "/documents/";

@Injectable()
export class DocumentService {
  constructor(
    private http: HttpClient,
    private organisationService: OrganisationService,
    private userService: UserService,
    private fileUploadService: FileUploadService
  ) {}

  getDocument(documentId, isCertification?: boolean) {
    let headers = new Headers();
    headers.append("Content-Type", "application/x-pdf");

    if (!this.userService.isAuthorized("system", "nutzerrechte")) {
      return;
    }
    return this.http.get<DocumentInterface>(
      "/proxy/api/v0/productservice/documents/" + documentId + "/content"
    );
  }

  getDocumentsRelatedToProductList(productId) {
    let headers = new Headers();
    headers.append("Content-Type", "application/x-pdf");

    if (
      this.userService.currentUserAuthorizedActions &&
      !this.userService.isAuthorized("system", "nutzerrechte")
    ) {
      return;
    }
    return this.http.get<DocumentInterface[]>(
      SWARM_DOC_API + "products/" + productId
    );
  }

  getDocumentsRelatedToCertificationList(certificationId) {
    let headers = new Headers();
    headers.append("Content-Type", "application/x-pdf");

    if (
      this.userService.currentUserAuthorizedActions &&
      !this.userService.isAuthorized("system", "nutzerrechte")
    ) {
      return;
    }
    return this.http.get<DocumentInterface[]>(
      SWARM_DOC_API + "certifications/" + certificationId
    );
  }

  uploadDocument(
    fileToUpload: File,
    entityId: number,
    isCertification: boolean,

  ): Observable<HttpEvent<String>> {
    const url =
      API_BASE_PRODUCTSERVICE +
      API_MAP["documents"]["POST"].replace(":entityId", entityId + "");
    if (
      !this.userService.isAuthorized(
        this.organisationService.activeItem.organisationId,
        "uploadDocument"
      )
    ) {
      alert("missingPermission to Upload Document\n document.service.ts:45");
      return;
    }
    return this.fileUploadService.uploadFile(fileToUpload, url, isCertification);
  }

  deleteDocument(documentId: number) {
    if (
      !this.userService.isAuthorized(
        this.organisationService.activeItem.organisationId,
        "deleteDocument"
      )
    ) {
      alert("missingPermission to Delete Document\n document.service.ts:51");
      return;
    }
    this.http
      .delete<DocumentInterface>(
        API_BASE_PRODUCTSERVICE +
          API_MAP["documents"]["DELETE"]
            .replace(":documentId", documentId + "")
            .replace(
              ":organisationId",
              this.organisationService.activeItem.organisationId + ""
            )
      )
      .subscribe();
  }
}
