<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <div class="wrapper">
    <div class="file-upload">
      <input
        (change)="onFileChange($event)"
        accept=".ttl"
        class="form-control"
        formControlName="file"
        id="file"
        type="file">
      <button [disabled]="form.invalid" class="upload-button" color="primary" mat-raised-button>Upload</button>
    </div>
    <mat-error *ngIf="f.fileSource.errors?.fileExtension">{{'file-upload.typeError'|translate}}</mat-error>
    <mat-error *ngIf="requestError">
      <span>{{'file-upload.processError'|translate}}</span><br>
      <span>{{'file-upload.try'|translate}}</span>
    </mat-error>
  </div>
</form>
