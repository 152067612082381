import { PtgInterface } from "src/app/model/ptgs/ptg-interface";
import {
  addedPTGs,
  newDescription,
  newItemDescription,
  organisationOptions,
  parsePTGCode,
  ptgOptions,
  removePTGs,
  temporaryProductImageSource,
  temporaryProductName,
  temporarySelectedOrganisationId,
} from "../../model-editor/custom/composerChangeBuffer";
import { ComposerInstanceList } from "../../model-editor/custom/factory/composerInstanceList";
import { ComposerLabel } from "../../model-editor/custom/factory/composerLabel";
import { ComposerAssociatedProductForViewer } from "./factory/composerAssociatedProductForViewer";
import { composerDownloadDataButtonForViewer } from "./factory/composerDownloadDataButtonForViewer";
import { ComposerPTGSelectionForViewer } from "./factory/composerPTGSelectionForViewer";
import { ComposerSelectForViewer } from "./factory/composerSelectForViewer";
import { ComposerTextBoxForViewer } from "./factory/composerTextBoxForViewer";
import { ComposerUploadDataButtonForViewer } from "./factory/composerUploadDataButtonForViewer";

/* Creates the Property Panel Elements and adds them in the correct Order */
export function ComposerPropertiesForViewer(group: any, element: any) {
  if (isProcess(element)) {
    createProcessIdAndNameElements();
  }

  if (isToolchainInstanceItem(element)) {
    createToolchainItemIdNameAndDescriptionElements();
  }

  if (isProcess(element)) {
    createProcessElements();
  }

  if (isToolchainInstanceItem(element)) {
    createToolchainItemElements();
  }

  if (isDataObject(element)) {
    createDataObjectElements(element);
  }

  /* Creates and adds multiple ToolchainItemElements */
  function createToolchainItemElements() {
    let removeOptions: PtgInterface[] = [];
    if (
      element.businessObject.selectedPTGs &&
      element.businessObject.selectedPTGs.length > 0
    ) {
      const selectedPTGIds = parsePTGCode(
        element.businessObject.selectedPTGs
      ).map((id) => parseInt(id));
      selectedPTGIds.forEach((ptgId) => {
        const ptg = ptgOptions.find((ptg) => ptg.id === ptgId);

        if (ptg) {
          removeOptions.push(ptg);
        }
      });
    }

    removePTGs.forEach((ptg) => {
      if (removeOptions.includes(ptg)) {
        removeOptions.splice(removeOptions.indexOf(ptg), 1);
      }
    });
    addedPTGs.forEach((ptg) => removeOptions.push(ptg));

    group.entries.push(
      ComposerPTGSelectionForViewer({
        id: "ptgRemoving",
        label: "Produkttypen",
        options: removeOptions,
      })
    );

    group.entries.push(
      ComposerAssociatedProductForViewer({
        id: "associatedProduct",
        productId: element.businessObject.productId,
        productName: temporaryProductName
          ? temporaryProductName
          : element.businessObject.productName,
        imageDataSrc: temporaryProductImageSource
          ? temporaryProductImageSource
          : element.businessObject.imageDataUrl,
      })
    );
  }

  /* Creates and adds multiple Process Elements */
  function createProcessElements() {
    group.entries.push(
      ComposerTextBoxForViewer({
        id: "description",
        label: "Beschreibung",
        content:
          newDescription !== ""
            ? newDescription
            : element.businessObject.$attrs.description,
      })
    );

    group.entries.push(
      ComposerLabel({
        id: "creator",
        labelName: "Ersteller",
        labelContent: element.businessObject.$attrs.creator,
      })
    );

    group.entries.push(
      ComposerSelectForViewer({
        id: "creatorOrganisation",
        label: "Ersteller Organisation",
        options: organisationOptions,
        currentlySelectedId:
          temporarySelectedOrganisationId !== -2
            ? temporarySelectedOrganisationId
            : element.businessObject.$attrs.organisation,
      })
    );

    group.entries.push(
      ComposerLabel({
        id: "lastChanges",
        labelName: "Letzte Änderungen",
        labelContent: element.businessObject.$attrs.lastChanges,
      })
    );

    // group.entries.push(
    //   ComposerInstanceList({
    //     id: "toolchainInstances",
    //     label: "Toolchain Instanzen",
    //     content: element.businessObject.$attrs.toolchainInstances.split(":;:;"),
    //   })
    // );
  }

  /* Creates and adds Id, Name and Description of the ToolchainItem*/
  function createToolchainItemIdNameAndDescriptionElements() {
    group.entries.push(
      ComposerLabel({
        id: "id",
        labelName: "Id",
        labelContent: element.businessObject.id.substring(
          1,
          element.businessObject.id.length
        ),
      })
    );

    group.entries.push(
      ComposerLabel({
        id: "itemName",
        labelName: "Name",
        labelContent: element.businessObject.itemName,
      })
    );

    group.entries.push(
      ComposerTextBoxForViewer({
        id: "itemDescription",
        label: "Beschreibung",
        content:
          newItemDescription !== ""
            ? newItemDescription
            : element.businessObject.description,
      })
    );
  }

  /* Creates and adds Id and Name of the Process */
  function createProcessIdAndNameElements() {
    group.entries.push(
      ComposerLabel({
        id: "id",
        labelName: "Id",
        labelContent: element.businessObject.id.substring(
          1,
          element.businessObject.id.length
        ),
      })
    );

    group.entries.push(
      ComposerLabel({
        id: "name",
        labelName: "Name",
        labelContent: element.businessObject.name,
      })
    );
  }

  function createDataObjectElements(element: any) {
    group.entries.push(
      ComposerLabel({
        id: "dataObject",
        labelName: "Datenobjekt",
        labelContent: "Aktuelle Datei",
      }),
      composerDownloadDataButtonForViewer({
        id: "fileList",
        visible: true, //TODO CHANGE TO DYNAMIC VALUE WHEN ENDPOINT OF CDE IS AVAILABLE
      })
    );

    if (element.incoming[0].businessObject.$parent.productId === "") {
      group.entries.push(
        ComposerUploadDataButtonForViewer({
          id: "uploadDataButton",
        })
      );
    }
  }
}

/* Checks if the Element is a BPMN Process */
function isProcess(element: any) {
  return element.businessObject.$type === "bpmn:Process";
}

/* Checks if the Element is a Composer ToolchainItem */
function isToolchainInstanceItem(element: any) {
  return element.businessObject.$type === "composer:ToolchainInstanceItem";
}

/* Checks if the Element is a DataObject */
function isDataObject(element: any) {
  return element.businessObject.$type === "bpmn:DataObjectReference";
}
