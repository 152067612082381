import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileHandlerService } from './file-handler.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-handler.component.html',
  styleUrls: ['./file-handler.component.css'],
  animations: [
    trigger('successAnimation', [
      state('success', style({
        backgroundColor: '#5cb85c', // Green color for success
        opacity: 1
      })),
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate('0.5s ease-in')
      ]),
      transition(':leave', [
        animate('0.5s ease-out', style({
          opacity: 0
        }))
      ])
    ]),
    trigger('failureAnimation', [
      state('failure', style({
        backgroundColor: '#d9534f', // Red color for failure
        opacity: 1
      })),
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate('0.5s ease-in')
      ]),
      transition(':leave', [
        animate('0.5s ease-out', style({
          opacity: 0
        }))
      ])
    ])
  ]
})

export class FileHandlerComponent {
  @Input() fileName: any = '';
  @Output() uploadComplete = new EventEmitter<string>();

  selectedFiles: FileList | null = null;
  progress = 0;
  message = '';
  requirementMet = true;
  selectedFile: File ;
  constructor(private fileHandlerService: FileHandlerService) { }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }
  selectAndUpload(event: any): void {
    this.selectedFiles = event.target.files;
    if (!this.selectedFiles || this.selectedFiles.length === 0) {
      this.message = 'Please select a file to upload.';
      return;
    }

    var file = event.target.files;
    this.selectedFile = file.item(0);
    file = file.item(0);
    
    this.progress = 0;
    if (!file) {
      console.error('Selected file is null or undefined.');
      return;
    }

    this.fileHandlerService.uploadWithName(file, this.fileName).subscribe(
      (event: any) => {
        if (event.type === 'uploadProgress') {
          this.progress = Math.round((100 * event.loaded) / event.total);
        } else if (event.type === 'response') {
          this.message = event.body.message;
          this.requirementMet = true;
          this.selectedFiles = null;
          this.uploadComplete.emit(this.fileName);
        }
      },
      error => {
        this.progress = 0;
        this.message = 'Could not upload the file!';
        console.error('Upload error:', error);
      }
    );
    return this.uploadComplete.emit(this.fileName);
  }

  upload(): void {
    if (!this.selectedFiles || this.selectedFiles.length === 0) {
      this.message = 'Please select a file to upload.';
      return;
    }

    const file = this.selectedFiles.item(0);
    this.selectedFile = this.selectedFiles.item(0);
    this.progress = 0;
    if (!file) {
      console.error('Selected file is null or undefined.');
      return;
    }

    this.fileHandlerService.uploadWithName(file, this.fileName).subscribe(
      (event: any) => {
        if (event.type === 'uploadProgress') {
          this.progress = Math.round((100 * event.loaded) / event.total);
        } else if (event.type === 'response') {
          this.message = event.body.message;
          this.requirementMet = true;
          this.selectedFiles = null;
          this.uploadComplete.emit(this.fileName);
        }
      },
      error => {
        this.progress = 0;
        this.message = 'Could not upload the file!';
        console.error('Upload error:', error);
      }
    );
    return this.uploadComplete.emit(this.fileName);
  }
}
