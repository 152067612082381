import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ReviewInterface } from "src/app/model/reviews/review-interface";
import { UserService } from "src/app/services/user-service/userservice";
import { ExcerptPipe } from "src/app/pipes/excerpt.pipe";

@Component({
  selector: "app-comment-table",
  templateUrl: "./comment-table.component.html",
  styleUrls: [
    "./comment-table.component.css",
    "../../../product-detail-display/product-detail-display.css",
  ],
})
export class CommentTableComponent {
  @Input()
  reviews: ReviewInterface[];

  @Output()
  upvote: EventEmitter<number> = new EventEmitter<number>();

  stringlength: number = 100;

  constructor(private userService: UserService) {}

  get userId(): number {
    return this.userService.currentUser.userId;
  }

  upvoteClick(reviewId: number) {
    this.upvote.emit(reviewId);
  }

  hasUserUpvoted(review: ReviewInterface) {
    return review.upvotes.some((x) => x === this.userId);
  }
}
