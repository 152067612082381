import { delegate } from 'min-dom/dist';
import { debounce, keys } from 'lodash';
import {
  addPTGOnPropertiesPanel,
  getCurrentlyActiveToolchain,
  removePTGOnPropertiesPanel,
  updateSelectedLogo,
  updateSelectedOrganisation,
  updateSelectedProduct,
  updateText,
  updateValuesofPropertyPanelElement,
} from './composerChangeBuffer';
import { ModelEditorComponent } from '../model-editor.component';
import { productImageDataUrls } from '../util/preloadedAssets';
import { environment } from 'src/environments/environment';
import { ComposerService } from 'src/app/services/composer-service/composer.service';

const DEBOUNCE_DELAY = 2000;

/*
 * Builds the ComposerChangeHandler Function
 * Gets an Instance of ModelEditorComponent as Parameter so that the HandlerFunction
 * can use the BPMNJS Variable, that gets initialised after this, on Runtime
 */
export function buildComposerChangeHandler(
  modelEditor: ModelEditorComponent,
  composerService: ComposerService
) {
  /* Custom Function that adds Listeners for handling Change Events */
  return function ComposerChangeHandler(container: any) {
    function findFirstDiffPos(a, b) {
      let i = 0;
      if (a === b) { return -1; }
      while (a[i] === b[i]) { i++; }
      return i;
    }
    /* Handler for Change Events */
    let handleChange = function handleChange(event) {
      if (event.type === 'input' || event.type === 'textarea') {
        if (updateText(event.target.value, event.target.id)) {
          reloadPropertiesPanel(modelEditor.getBPMNJSInstance());
          handleSaveInformation(event);
          if (event.target['id'] === 'name') {
            const oldVal = event.srcElement.defaultValue;
            const nameElem = document.getElementById('name');
            const nameVal = nameElem['value'];
            const focusIndex = findFirstDiffPos(oldVal, nameVal) + 1;
            nameElem.focus();
            // @ts-ignore
            nameElem.setSelectionRange(focusIndex, focusIndex);
          } else if (event.target['id'] === 'description') {
            const oldVal = event.srcElement.innerHTML;
            const descrElem = document.getElementById('description');
            const descrVal = descrElem.innerHTML;
            const focusIndex = findFirstDiffPos(oldVal, descrVal) + 1;
            descrElem.focus();
            // @ts-ignore
            descrElem.setSelectionRange(focusIndex, focusIndex);
          } else if (event.target['id'] === "itemNameProp") {
            const oldVal = event.srcElement.defaultValue;
            const nameElem = document.getElementById("itemNameProp");
            const nameVal = nameElem['value'];
            const focusIndex = findFirstDiffPos(oldVal, nameVal) + 1;
            nameElem.focus();
            // @ts-ignore
            nameElem.setSelectionRange(focusIndex, focusIndex);
          } else if (event.target['id'] === "itemDescription") {
            const oldVal = event.srcElement.innerHTML;
            const descrElem = document.getElementById("itemDescription");
            const descrVal = descrElem.innerHTML;
            const focusIndex = findFirstDiffPos(oldVal, descrVal) + 1;
            descrElem.focus();
            // @ts-ignore
            descrElem.setSelectionRange(focusIndex, focusIndex);
          }
        }
      }
    };

    /* Handler for ptgAdding Events */
    const handlePTGAdded = function handlePTGAdded(event) {
      addPTGOnPropertiesPanel(
        (document.getElementById('ptgAdding') as HTMLSelectElement).value
      );

      reloadPropertiesPanel(modelEditor.getBPMNJSInstance());
      handleSaveInformation(event);
    };

    /* Handler for ptgRemoving Events */
    const handlePTRemoved = function handlePTRemoved(event) {
      removePTGOnPropertiesPanel(
        (document.getElementById('ptgRemoving') as HTMLSelectElement).value
      );

      reloadPropertiesPanel(modelEditor.getBPMNJSInstance());
      handleSaveInformation(event);
    };

    const handleOrganisationSelect = function handleOrganisationSelect(event) {
      if (document.getElementById('creatorOrganisation')) {
        updateSelectedOrganisation(
          (document.getElementById('creatorOrganisation') as HTMLSelectElement)
            .value
        );

        reloadPropertiesPanel(modelEditor.getBPMNJSInstance());
        handleSaveInformation(event);
      }
    };

    /* Handler for saveInformation Events */
    const handleSaveInformation = function handleSaveInformation(event) {
      const bpmnJS = modelEditor.getBPMNJSInstance();
      // Safety check as the BPMNJS Instance gets initialised after this Handler is created
      if (bpmnJS) {
        // Calls the update Method
        updateValuesofPropertyPanelElement(
          bpmnJS.get('modeling'),
          bpmnJS.get('elementRegistry')
        );
        // Calls the internal update Function
        bpmnJS
          .get('propertiesPanel')
          .update(bpmnJS.get('propertiesPanel')._current.element);
      }
    };

    /* Handler for chooseProduct Events */
    const handleChooseProduct = function handleChooseProduct(event) {
      const currentlyActiveToolchain = getCurrentlyActiveToolchain(
        modelEditor.getBPMNJSInstance().get('elementRegistry')
      );
      modelEditor
        .openProductToToolchainItemDialog(
          currentlyActiveToolchain.businessObject.productName,
          currentlyActiveToolchain.businessObject.description
        )
        .subscribe((productInformation) => {
          if (productInformation) {
            if (productInformation.productFileId) {
              composerService
                .loadDataSourceForNewFileId(productInformation.productFileId)
                .subscribe((done) => {
                  if (done) {
                    updateSelectedProduct(
                      productInformation.productName,
                      productInformation.productId,
                      productImageDataUrls.find(
                        (element) =>
                          element.fileId === productInformation.productFileId
                      ).dataUrl
                    );
                    reloadPropertiesPanel(modelEditor.getBPMNJSInstance());
                    handleSaveInformation(event);
                  }
                });
            } else {
              updateSelectedProduct(
                productInformation.productName,
                productInformation.productId,
                environment.defaultPictureProducts
              );
              reloadPropertiesPanel(modelEditor.getBPMNJSInstance());
              handleSaveInformation(event);
            }
          }
        });
    };

    /* Handler for clearProduct Events */
    const handleClearProduct = function handleClearProduct(event) {
      updateSelectedProduct('', '', '');

      reloadPropertiesPanel(modelEditor.getBPMNJSInstance());
      handleSaveInformation(event);
    };

    /* Handler for chooseIcon Events */
    const handleChooseIcon = function handleChooseIcon(event) {
      modelEditor.openFileManagerDialog().subscribe((fileInformation) => {
        if (fileInformation && fileInformation.length > 0) {
          composerService
            .loadDataSourceForNewFileId(fileInformation[0])
            .subscribe((done) => {
              if (done) {
                updateSelectedLogo(
                  productImageDataUrls.find(
                    (element) => element.fileId === fileInformation[0]
                  ).dataUrl,
                  fileInformation[0]
                );
                reloadPropertiesPanel(modelEditor.getBPMNJSInstance());
                handleSaveInformation(event);
              }
            });
        } else {
          updateSelectedLogo(environment.defaultPictureProducts, fileInformation[0]);
          reloadPropertiesPanel(modelEditor.getBPMNJSInstance());
          handleSaveInformation(event);
        }
      });
    };

    // debounce update only elements that are target of key events,
    // i.e. INPUT and TEXTAREA. SELECTs will trigger an immediate update anyway.
    delegate.bind(container, 'select', 'change', handleOrganisationSelect);
    delegate.bind(
      container,
      'input, textarea, [contenteditable]',
      'input',
      handleChange
    );
    delegate.bind(
      container,
      'input, textarea, select, [contenteditable]',
      'change',
      handleChange
    );
    delegate.bind(container, 'button', 'ptgAdded', handlePTGAdded);
    delegate.bind(container, 'button', 'ptgRemoved', handlePTRemoved);
    delegate.bind(
      container,
      'button',
      'saveInformation',
      handleSaveInformation
    );
    delegate.bind(container, 'button', 'chooseProduct', handleChooseProduct);
    delegate.bind(container, 'button', 'clearProduct', handleClearProduct);
    delegate.bind(container, 'button', 'chooseIcon', handleChooseIcon);
  };
}

/* Custom Function to check if Entries Changed that takes the Entries HTML into account */
export function ComposerEntriesChanged(prevEntries, entries) {
  let changed = false;
  const prevEntriesIds = keys(prevEntries);

  prevEntriesIds.forEach((prevEntryId) => {
    changed =
      changed ||
      !entries[prevEntryId] ||
      prevEntries[prevEntryId].html !== entries[prevEntryId].html;
  });

  return changed;
}


/* Reloads the Property Panel */
export function reloadPropertiesPanel(bpmnJS: any) {
  // Safety check as the BPMNJS Instance gets initialised after this Handler is created
  if (bpmnJS) {
    // Calls the internal update Function
    bpmnJS
      .get('propertiesPanel')
      .update(bpmnJS.get('propertiesPanel')._current.element);
  }
}
