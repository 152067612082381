import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewEncapsulation,
  Input,
  EventEmitter,
  Output, OnChanges,
} from "@angular/core";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NavbarService } from "src/app/services/componentServices/navbar-service/navbar-service";
import { MarketScreenState } from "src/app/model/enums/marketScreenState";
import { first } from "rxjs/operators";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { environment } from "src/environments/environment";
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';

@Component({
  selector: "app-certification-detail-display",
  templateUrl: "./certification-detail-display.component.html",
  styleUrls: ["./certification-detail-display.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CertificationDetailDisplayComponent implements OnInit, OnChanges {
  public certification: CertificationInterface;
  public organisation: OrganisationInterface;
  private pictureUrl: string;
  public displaySpinner: boolean = true;
  public certificationKeywords: string[] = [];
  public publishDate: Date;
  @Output() goBack = new EventEmitter<void>();
  @Input() actualCertification: CertificationInterface;

  displayedColumns: string[] = ['merkmal', 'value'];

  selectedTab: number = 0;
  selectTab(tabIndex: number) {
    this.selectedTab = tabIndex;
  }


  constructor(
    private certificationService: CertificationService,
    private organisationService: OrganisationService,
    private navbarService: NavbarService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private dialogService: DialogService
  ) {}

  ngOnChanges() {
    if (this.actualCertification?.company != null) {
      this.organisationService.getOrganisation(this.actualCertification.company).then((organisation) => {
        this.organisation = organisation;
        this.displaySpinner = false;
      });
      this.loadCertificationKeywords();
    }
    this.cd.detectChanges();
  }


  ngOnInit(): void {
    this.loadCertificationKeywords();
  }

  private loadCertificationKeywords(): void {
    this.certificationKeywords = [];
    this.actualCertification?.certificationVariants
      .forEach(certVar => certVar.certificationVariantVersions
        .forEach(certVers => certVers.keywords
          .forEach( keyword => {
            if (!this.certificationKeywords.includes(keyword)) {
              this.certificationKeywords.push(keyword);
            }
          })));
  }

  getDateTime(): void {
    var x : Date = new Date(this.actualCertification?.publishDate);
    this.publishDate = x;
  }

/**
oeffnet das Dialogfenster zum versenden einer Angebotsanfrage
*/
  requestOffer() {
    this.dialogService.openOfferCertRequestDialog(this.actualCertification?.id);
  }

  navigateToOrganisation(id: number) {
    const queryParams: Params = { tab: 2,
                                productId: null, 
                                ptgId: null, 
                                ptgAttrId: null,
                                certificationId: null, 
                                orgId: id, 
                                organisationId: id, 
                                attr: null,
                                category:null,
                                certificationType: null,
                                rating:null,
                                flag: null };
    this.displaySpinner = true;
    this.router.navigate(
    [],
    {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
    
    this.cd.detectChanges();
  }

  public routeToWebsite() : void {
    if (this.actualCertification?.url == null || this.actualCertification?.url == undefined || this.actualCertification?.url == "")
      {
      return;
      }
    // check for correct scheme
    let url;
    if (this.actualCertification?.url.match("http")) {
      url = this.actualCertification?.url;
    } else {
      url = "http://" + this.actualCertification?.url;
    }

    let valid_url;
    try {
      valid_url = new URL(url);
    } catch (e) {
      return;
    }

    window.open(url, "_blank");
  }
}
