import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanReadableDate'
})
export class HumanReadableDate implements PipeTransform {

  transform(timestamp: string, args?: any): String {
    let regexStr = timestamp.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+)?|\.[0-9]+/g);
    let month: string;

    switch(regexStr[1]) {
      case("01"): month = "Januar "; break;
      case("02"): month = "Februar "; break;
      case("03"): month = "März "; break;
      case("04"): month = "April "; break;
      case("05"): month = "Mai "; break;
      case("06"): month = "Juni "; break;
      case("07"): month = "Juli "; break;
      case("08"): month = "August "; break;
      case("09"): month = "September "; break;
      case("10"): month = "Oktober "; break;
      case("11"): month = "November "; break;
      case("12"): month = "Dezember "; break;
    }

    return regexStr[2] + ". " + month + regexStr[0];;
  }

}
