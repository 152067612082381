import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.css']
})
export class PasswordStrengthComponent implements OnInit {

  @Input()
  private password: string;

  public passwordStrength: number = 1;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.calculatePasswordStrength();
  }

  /* Calculates the Strength of the Password based on the ShannonEntropy */
  private calculatePasswordStrength(): void {
    let shannonEntropyResult: number =
      this.password.length > 0 ? this.shannonEntropy(this.password) : 0;

    if (shannonEntropyResult < 0.9) {
      this.passwordStrength = 1;
    } else if (shannonEntropyResult <= 2) {
      this.passwordStrength = 20;
    } else if (shannonEntropyResult <= 2.5) {
      this.passwordStrength = 40;
    } else if (shannonEntropyResult <= 3) {
      this.passwordStrength = 60;
    } else if (shannonEntropyResult <= 3.5) {
      this.passwordStrength = 80;
    } else if (shannonEntropyResult >= 4.0) {
      this.passwordStrength = 100;
    }
  }

  private shannonEntropy(password: string): number {
    let frequencyMap: Map<string, number> = this.getCharacterFrequency(
      password
    );

    return -Object.values(frequencyMap)
      .map((frequency: number) => frequency / password.length)
      .map(
        (relativeFrequency: number) =>
          relativeFrequency * Math.log2(relativeFrequency)
      )
      .reduce((a, b) => a + b);
  }

  private getCharacterFrequency(string: string): Map<string, number> {
    let characterAry = Array.from(string);
    let frequencyMap: Map<string, number> = new Map<string, number>();
    characterAry.forEach((char: string) =>
      frequencyMap[char] ? frequencyMap[char]++ : (frequencyMap[char] = 1)
    );
    return frequencyMap;
  }

}
