import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { async } from 'rxjs';
import { CertificationInterface } from 'src/app/model/certifications/certification-interface';
import { OrganisationInterface } from 'src/app/model/organisations/organisation-interface';
import { OrganisationMinimalInterface } from 'src/app/model/organisations/organisation-minimal-interface';
import { ProductInterface } from 'src/app/model/products/product-interface';
import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition('* => void', [animate('500ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ImageSliderComponent implements OnInit {
  @Input() item?: ProductInterface | CertificationInterface;
  @Input() category: string;
  @Input() organisation?: OrganisationInterface;
  public pictureURL = environment.defaultPictureOrganisationProducts;

  public test: CertificationInterface;
  public slides = [];

  constructor(private cd: ChangeDetectorRef,
              private productService: ProductService
    ) {

  }

  ngOnChanges() {
    this.slides = [];
    this.currentSlide = 0;
    if(this.item != null && this.category != "organisation")
    {
      this.galleryItems();
    }

    if(this.item != null && this.category === "organisation")
    {
      this.pictureURL = environment.defaultPictureOrganisationPTGs;
      this.slides.push(this.item.fileId);
    }
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    switch(this.category) {
      case "product":
        this.pictureURL = environment.defaultPictureOrganisationProducts;
        this.galleryItems();
        break;
      case "certification":
        this.pictureURL = environment.defaultPictureProducts;
        this.galleryItems();
        break;
      case "organisation":
        this.pictureURL = environment.defaultPictureOrganisationPTGs;
        break;
    }
  }

  
  galleryItems() {
    this.slides = [];

    if(this.item?.galleryFileIds !== null)
    {
      this.slides.push(this.item?.fileId);
      this.item?.galleryFileIds.forEach((item) => {
        this.slides.push(item);
     })

    } else { 
      this.slides.push(this.pictureURL);
    }
  }


  currentSlide = 0;

  onPreviousClick() {

    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
  }
}
