import { MatCheckboxChange } from "@angular/material/checkbox";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from "@angular/core";

import { ProductService } from "../../../../services/dataServices/product-service/productService";
import { Status } from "../../../../model/enums/status";
import { OrganisationService } from "../../../../services/dataServices/organisation-service/organisationservice";
import { SidemenuServiceService } from "src/app/services/componentServices/sidemenu-service/sidemenu-service.service";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { PtgService } from 'src/app/services/dataServices/ptg-service/ptgService';

@Component({
  selector: "app-sidemenu-new",
  templateUrl: "./sidemenu-new.component.html",
  styleUrls: ["./sidemenu-new.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidemenuNewComponent implements OnInit {
  @Input()
  urlEqualsComposer;

  public status = Status;
  private isActiveChecked = false;
  private isInactiveChecked = false;

  constructor(
    public sidemenuService: SidemenuServiceService,
    private productService: ProductService,
    private organisationService: OrganisationService,
    private ptgService: PtgService,
    private cd: ChangeDetectorRef,
    private certificationService: CertificationService,
  ) {}

  ngOnInit() {
    this.sidemenuService.sidemenuDataObserver.subscribe((x) => {
      if (!this.cd["destroyed"]) {
        this.cd.detectChanges();
      }
    });
    this.productService.activeItemObserver.subscribe((x) => {
      if (!this.cd["destroyed"]) {
        this.cd.detectChanges();
      }
    });
    this.ptgService.itemCollectionObserver.subscribe((x) => {
      if (!this.cd["destroyed"]) {
        this.cd.detectChanges();
      }
    })
    this.ptgService.activeItemObserver.subscribe((x) => {
      if (!this.cd["destroyed"]) {
        this.cd.detectChanges();
      }
    })
    this.productService.itemCollectionObserver.subscribe((x) => {
      if (!this.cd["destroyed"]) {
        this.cd.detectChanges();
      }
    });
    this.certificationService.activeItemObserver.subscribe((x) => {
      if (!this.cd["destroyed"]) {
        this.cd.detectChanges();
      }
    });
    this.certificationService.itemCollectionObserver.subscribe((x) => {
      if (!this.cd["destroyed"]) {
        this.cd.detectChanges();
      }
    });
  }

  showFilteredProducts(event: MatCheckboxChange) {
    if (event.checked) {
      this.sidemenuService.status = true;
    } else {
      this.sidemenuService.status = false;
    }

      let productsOfCurrentOrga = this.productService.getProductsOfOrganisation(
        this.organisationService.activeItem.organisationId)

      this.sidemenuService.loadSidemenuProducts(productsOfCurrentOrga);
  }
}
