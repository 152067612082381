import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hexacon-button-left-text-icon',
  templateUrl: './hexacon-button-left-text-icon.component.html',
  styleUrls: ['./hexacon-button-left-text-icon.component.css']
})
export class HexaconButtonLeftTextIconComponent implements OnInit {

  constructor() { }

  @Input()
  public text: string;

  @Input()
  public icon: string;

  ngOnInit() {
  }

}
