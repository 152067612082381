
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MarketplaceRedirectServiceService {

   private directedFromProducts: boolean;
   private directedFromProductDetails: boolean;

  constructor() { }

  setDirectedFromProducts(): void{
      this.directedFromProducts = true;
  }

  setDirectedFromOrganisations(): void{
      this.directedFromProducts = false;
  }

  setDirectedFromProductDetails(flag: boolean) {
    this.directedFromProductDetails = flag;
  }

  isDirectedFromProducts(): boolean {
      return this.directedFromProducts
  }

  isDirectedFromProductDetails(): boolean {
    return this.directedFromProductDetails;
  }
}
