export enum ProductCategory {
  APP = 'APP',
  SERVICE = 'SERVICE',
  CONTENT = 'CONTENT',
  ALLGEMEIN = 'ALLGEMEIN',
  HOCHBAU = 'HOCHBAU',
  TIEFBAU = 'TIEFBAU',
  WASSERBAU = 'WASSERBAU',
  STRASENBAU = 'STRASENBAU',
  EISENBAHNBAU = 'EISENBAHNBAU',
  INFRASTRUKTUR = 'INFRASTRUKTUR',
  BAUSTELLE = 'BAUSTELLE',
  EDGESERVER = 'EDGESERVER'
}
