import { ChangeDetectorRef, Injectable } from "@angular/core";

import { Router, NavigationEnd } from "@angular/router";

import { BehaviorSubject, Subscription } from "rxjs";
import { NavbarDataInterface } from "src/app/model/navbar-data-interface";
import { MarketScreenState } from "src/app/model/enums/marketScreenState";
import { OrganisationService } from "../../dataServices/organisation-service/organisationservice";
import { OrganisationType } from "src/app/model/enums/organisationType";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { UserService } from "../../user-service/userservice";
import { environment } from "src/environments/environment";
import { ProductlistService } from "../../productlist-service/productlist.service";
import { ProductOverview } from "src/app/model/product-overview/product-overview";

@Injectable({
  providedIn: "root",
})
export class NavbarService {
  public navbarData: NavbarDataInterface[];

  public activeScreenSubject = new BehaviorSubject<MarketScreenState>(
    MarketScreenState.Products
  );
  public activeScreenObservable = this.activeScreenSubject.asObservable();

  activeOrganisation: OrganisationInterface;

  NAV_STATE_ORGANISATION_FLAG = false;
  NAV_STATE_MY_PRODUCTS_LIST = false;

  constructor(
    private organisationService: OrganisationService,
    private userService: UserService,
    private productListService: ProductlistService,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        var url = this.router.routerState.snapshot.url.toString();
        this.NAV_STATE_ORGANISATION_FLAG = new RegExp(
          ".*/organisations/.*"
        ).test(url)
          ? true
          : false;
        if (this.NAV_STATE_ORGANISATION_FLAG) {
          this.activeOrganisation = this.organisationService.activeItem;
          this.setOrganisationType();
          if (
            this.activeOrganisation.organisationType ==
            OrganisationType.PRODUCT_TYPE_ASSOCIATION
          ) {
            this.setAttributeButton();
          }
        }

        this.setUserStatus();
        this.loadButtons();
        this.activeScreenSubject.next(null);
      }
    });
    this.userService.currentUserPictureChangeObservable.subscribe(() => {
      this.setUserStatus();
      this.loadButtons();
      this.activeScreenSubject.next(null);
    });

    //If this is checked before the User Login is registered this ensures that its correctly set
    this.userService.currentUserObservable.subscribe((user) => {
      if (user && this.userService.loggedIn) {
        this.productListService
          .isProductListEmpty()
          .subscribe((isEmpty: boolean) => {
            if (!isEmpty) {
              this.NAV_STATE_MY_PRODUCTS_LIST = true;
              this.loadButtons();
            }
          });
      }
    });

    //If this is checked after the User Login is registered this should work fine
    if (this.userService.loggedIn) {
      this.productListService
        .isProductListEmpty()
        .subscribe((isEmpty: boolean) => {
          if (!isEmpty) {
            this.NAV_STATE_MY_PRODUCTS_LIST = true;
            this.loadButtons();
          }
        });
    }
  }

  homeButton: NavbarDataInterface = {
    label: "Home",
    icon: "home",
    show: true,
    link: environment.homeButtonLink,
    labelBelow: true,
  };
  loginButton: NavbarDataInterface = {
    label: "Anmelden",
    icon: "logIn",
    show: true,
    link: "/login",
    labelBelow: false,
  };
  logOutButton: NavbarDataInterface = {
    label: "Registrieren",
    icon: "register",
    show: true,
    link: "/registry",
    labelBelow: true,
  };
  marketplaceButton: NavbarDataInterface = {
    label: "Marktplatz",
    icon: "marketplace",
    show: true,
    link: "/marktplatz",
    labelBelow: false,
  };

  myProductsButton: NavbarDataInterface = {
    label: "Meine Produkte",
    icon: "",
    show: true,
    link: "/meineProdukte",
    labelBelow: false,
  };

  entityButton: NavbarDataInterface = {
    label: "Produkttypen",
    icon: "ptgs",
    show: true,
    link: "",
    labelBelow: true,
  };
  attributeButton: NavbarDataInterface = {
    label: "Merkmale",
    icon: "attributes",
    show: false,
    link: "",
    labelBelow: false,
  };
  composerButton: NavbarDataInterface = {
    label: "Composer",
    icon: "composer",
    show: true,
    link: "/composer",
    labelBelow: true,
  };

  setUserStatus() {
    this.setLoginButton();
    this.setLogOutButton();
  }

  setLoginButton() {
    if (this.userService.loggedIn) {
      let icon: string = "profile";
      if (
        this.userService.currentUser != null &&
        this.userService.currentUser.fileId != null
      ) {
        icon = null;
        this.loginButton.img = this.userService.currentUser.fileId;
      }
      this.changeButton(this.loginButton, "Profil", icon, "/profil/settings");
    } else {
      this.loginButton = this.changeButton(
        this.loginButton,
        "Anmelden",
        "logIn",
        "/login"
      );
    }
  }

  setLogOutButton() {
    if (this.userService.loggedIn) {
      this.logOutButton = this.changeButton(
        this.logOutButton,
        "Abmelden",
        "logOff",
        "/"
      );
    } else {
      this.logOutButton = this.changeButton(
        this.logOutButton,
        "Registrieren",
        "register",
        "/registry"
      );
    }
  }

  setAttributeButton() {
    this.activeOrganisation = this.organisationService.activeOrganisation;
    this.attributeButton.show = true;
    this.attributeButton.link =
      "/organisations/" + this.activeOrganisation.organisationId + "/features";
  }

  setOrganisationType() {
    var type = this.activeOrganisation.organisationType;

    var orgId = this.activeOrganisation.organisationId;

    switch (type) {
      case OrganisationType.PRODUCT_TYPE_ASSOCIATION: {
        this.entityButton = this.changeButton(
          this.entityButton,
          "Produkttypen",
          "ptgs",
          "/organisations/" + orgId + "/productTypeGroups"
        );
        break;
      }

      case OrganisationType.PRODUCT_PROVIDER: {
        this.entityButton = this.changeButton(
          this.entityButton,
          "Produkte",
          "products",
          "/organisations/" + orgId + "/products"
        );
        break;
      }

      case OrganisationType.CERTIFICATION_PROVIDER: {
        this.entityButton = this.changeButton(
          this.entityButton,
          "Zertifizierungen",
          "certifications",
          "/organisations/" + orgId + "/certifications"
        );
        break;
      }

      case OrganisationType.CERTIFICATION_OFFICE: {
        this.entityButton = this.changeButton(
          this.entityButton,
          "Zertifizierungen",
          "certifications",
          "/organisations/" + orgId + "/certifications"
        );
        break;
      }
    }
  }

  changeButton(
    button: NavbarDataInterface,
    label: string,
    icon: string,
    link: string
  ) {
    button.label = label;
    button.icon = icon;
    button.link = link;
    return button;
  }

  loadButtons() {
    this.navbarData = [];
    this.navbarData.push(this.loginButton);
    this.navbarData.push(this.logOutButton);
    this.navbarData.push(this.marketplaceButton);
    this.navbarData.push(this.homeButton);

    if (this.userService.loggedIn && this.NAV_STATE_MY_PRODUCTS_LIST) {
      this.navbarData.push(this.myProductsButton);
    }

    if (this.userService.loggedIn && this.userService.currentUser.hasComposerAccess) {
      this.navbarData.push(this.composerButton);
    }
    if (this.NAV_STATE_ORGANISATION_FLAG) {
      this.navbarData.push(this.entityButton);
      if (
        this.activeOrganisation.organisationType ==
        OrganisationType.PRODUCT_TYPE_ASSOCIATION
      ) {
        this.navbarData.push(this.attributeButton);
      }
    }
  }
}
