<mat-form-field>
  <mat-label>Organisation</mat-label>
  <mat-select (selectionChange)="onSelectedOrganisationChange($event)">
    <mat-option *ngFor="let orga of availableOrganisations" [value]="orga">{{orga.organisationName}}</mat-option>
  </mat-select>
</mat-form-field>
<app-toolchain-template-table *ngIf="selectedOrganisation" [toolchainTemplates]="toolchainTemplates" [toolchainInstances]="toolchainInstances"
                              (deleteTemplateEvent)="deleteToolchainTemplate($event)"
                              (deleteToolchainEvent)="deleteToolchain($event)"
                              (reloadToolchainsAndTemplatesEvent)="reloadToolchains()"
></app-toolchain-template-table>
