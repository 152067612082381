import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, HostListener, NgZone, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DisplayItemInterface } from 'src/app/model/marketplace/display-item-interface';
import { DataType } from 'src/app/model/data-type.enum';
import { MarketplaceService } from 'src/app/services/dataServices/marketplace-service/marketplace.service';
import { DtoPageFactory } from 'src/app/model/marketplace/dto-page-factory';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HeaderServiceService } from 'src/app/services/header-service/header-service.service';
import {merge, Subscription} from 'rxjs';
import { UPDATE_DELAY_IN_MS } from 'src/environments/constants';
import { CertificationService } from 'src/app/services/dataServices/certification-service/certificationService';
import { CheckBoxCategorie } from 'src/app/model/marketplace/marketplace-checkbox';
import { animate, AUTO_STYLE, state, style, transition, trigger, } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { ProductInterface } from 'src/app/model/products/product-interface';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import { CertificationInterface } from 'src/app/model/certifications/certification-interface';
import { OrganisationInterface } from 'src/app/model/organisations/organisation-interface';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { MarketplaceFilterService } from 'src/app/services/componentServices/marketplace-filter-service/marketplace-filter.service';
import { ProductlistService } from 'src/app/services/productlist-service/productlist.service';
import { UserService } from 'src/app/services/user-service/userservice';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { ServerDetailDisplayComponent } from './detail-pages/product-detail-display/server-detail-display.component';

@Component({
  selector: 'app-marketplace-overview',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: AUTO_STYLE,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
        })
      ),
      transition('* => *', [animate('0.2s ease-out')]),
    ]),
  ],
  templateUrl: './marketplace-overview.component.html',
  styleUrls: ['./marketplace-overview.component.scss'],
})
export class MarketplaceOverviewComponent implements OnInit, OnDestroy {

  get totalNrOfItems(): number {
    return this.marketplaceService.totalNrOfItems;
  }


  constructor(
    private breakpointObserver: BreakpointObserver,
    private marketplaceService: MarketplaceService,
    private productService: ProductService,
    private headerService: HeaderServiceService,
    private certificationService: CertificationService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private router: Router,
    private titleService: Title,
    private organisationService: OrganisationService,
    public marketPlaceFilterService: MarketplaceFilterService,
    private productListService: ProductlistService,
    private dialogService: DialogService,
    private userService: UserService,
    private zone: NgZone
  ) {
    this.products = Array(100)
      .fill(0)
      .map((x, i) => i);

  }


  /**
   * State indicator to switch between different display types.
   */
  get displaySetting() {
    return this.marketplaceService.filterService.marketScreenState;
  }
  /**
   * Array of items to display.
   */
  @ViewChild('tab', { static: false }) tab: MatTabGroup;
  public displayItem: DisplayItemInterface[] = [];

  private isGoBack = true;

  public displaySize = 25;
  public pageIndex: number = this.marketPlaceFilterService.pageIndex;

  public changeDetected = false;
  public subscription: Subscription;
  public queryParamSubscription: Subscription;
  public tabsDisabled = [false, false, false];
  public searchText = "";
  public showNoResults = false;


  orderByOrganisationFlag = false;

  products: number[];
  producttypeFilterString = '';
  providerFilterString = '';

  showProducttypeFilter = false;
  showProviderFilter = false;
  filterSpinner = false;
  showDetails = false;
  @Input() clearNav = false;
  spinner = true;
  displaySpinner = false;

  actualProduct: ProductInterface;
  actualCertification: CertificationInterface;
  actualOrganisation: OrganisationInterface;
  filterServiceSubscription: Subscription;
  public marketScreenState: DataType;

  /**
   * The update Timer to delay backend request between
   * a sequence of manipulationens
   */
  private updateDelay;

  public tmpUrl = '';

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  ngOnInit() {
    // handling the queryparams in the url for the filter or selection of product/cert/orga displayitem and
    // basically changing the settings of the site to the url parameters
    this.loadQueryParams();

    this.spinner = true;
    this.displaySpinner = false;
    this.subscribeToOrder();
    this.titleService.setTitle('Marktplatz - BIMSWARM-Forschung');
    this.headerService.loadHeaderText('Marktplatz');
    const filter = this.marketplaceService.filterService;

    // ######################################################
    // register the overview for all causes that change the
    // filter settings, and merge them into one steram
    // ######################################################



    this.subscription = merge(
      filter.screenStateObservable,
      filter.filterChangeObservable,
      filter.searchTextSubject,
      filter.sortingOptionSubject,
    ).subscribe((_) => {
      this.changeDetected = true;
      // ######################################################
      // this sets a delay timer that is "restarted" everytime
      // there is a new update request send to the display.
      // this prevents unnecessary backend request when
      // the filter changes to quickly f.i. when typing a
      // searchterm or toggling filter icons quickly
      // #####################################################

      if (this.updateDelay) { clearTimeout(this.updateDelay); }
      this.updateDelay = setTimeout(() => {
        this.pageIndex = 0;
        if (this.queryParamSubscription) {
          this.queryParamSubscription.unsubscribe();
        }

        this.loadDisplayItems().then(() => {
          this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            this.handleQueryParamSubscription(params);
          });
          this.changeDetected = false;
        });
      }, UPDATE_DELAY_IN_MS);



      // ######################################################
      // this registers the marketplace component to the route
      // parameter map.
      // this way a different component can use the navigation
      // parameters as a way to set the marketplace filter
      // without the need to inject the sidemenu filter service
      // ######################################################
      this.route.paramMap.subscribe((x) => {
        if (x.get('organisation')) {
          const organisationToFilter = parseInt(x.get('organisation'));
          filter.addFilter(organisationToFilter, CheckBoxCategorie.ORGANISATION);
        }
      });

      // ---------------------------------------------------------
    });
    this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToProductScreen();
    this.marketPlaceFilterService.screenStateObservable.subscribe((marketScreenState) => {
      this.marketScreenState = marketScreenState;

    });
    if (this.userService.loggedIn) {
      this.productListService.getAllProducts();
    }
  }

  disableAllTabs(): void {
    this.tabsDisabled = [true, true, true];
  }

  enableAllTabs(): void {
    this.tabsDisabled = [false, false, false];
  }

  handleQueryParamSubscription(params: Params) {
    // Set the tab (Produkte, Zertifikate, Anbieter) from url query params if present
    this.resolveTabQueryParam(params['tab']);

    // Open the details of product/certification/organisation with corresponding id from url query if present
    let itemId: number;
    if (params['productId']) {
      itemId = params['productId'];
    } else if (params['certificationId']) {
      itemId = params['certificationId'];
    } else if (params['organisationId']) {
      itemId = params['organisationId'];
    }
    this.resolveDisplayItemQueryParam(itemId);
  }

  private resolveTabQueryParam(tab?: number) {
    if (tab) {
      this.tab.selectedIndex = tab;
    } else {
      this.tab.selectedIndex = 0;
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { tab: 0 },
          queryParamsHandling: 'merge'
        }
      );
    }
  }

  private resolveDisplayItemQueryParam(itemId?: number) {
    this.marketPlaceFilterService.getToggleBooleanClearFilter().subscribe((response) => {
      if (response) {
        this.marketPlaceFilterService.setToggleBooleanClearFilter(false);
        this.goBack();
        this.router.navigateByUrl('/marktplatz?tab=0');
        this.cd.detectChanges();
      }
    });

    if (itemId) {
      this.isGoBack = false;
      let item: DisplayItemInterface;
      for (const displayIt of this.displayItem) {
        if (displayIt.id == itemId) { item = displayIt; }
      }
      this.getDisplayItemDetails(item?.id, item?.subscript).then(() => {
        this.showDetails = true;
        if (localStorage.getItem("redirectingForRating") == "true"){
          document.getElementById("ratingPane").scrollIntoView({behavior:"smooth"});
          localStorage.removeItem("redirectingForRating");
        }
        this.cd.detectChanges();
      });
    }
  }

  changeMarketPlaceScreenState(event: MatTabChangeEvent) {
    this.showDetails = false;
    this.spinner = true;
    switch (event.index) {
      case 0:
        this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToProductScreen();
        this.marketPlaceFilterService.resetFilters();
        break;
      case 1:
          this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToProductScreen();
          this.marketPlaceFilterService.resetFilters();
          break;  
      case 2:
        this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToProductAsContentScreen();
        this.marketPlaceFilterService.resetFilters();
        break;
      case 3:
        this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToProductAsDienstleistungenScreen();
        this.marketPlaceFilterService.resetFilters();
        break;
      case 4:
        this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToProductAsKIScreen();
        this.marketPlaceFilterService.resetFilters();
        break;
      case 5:
          this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToProductAsRobotScreen();
          this.marketPlaceFilterService.resetFilters();
          break;     
      case 6:
          this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToCertificationScreen();
          this.marketPlaceFilterService.resetFilters();
          break;
      case 7:
            this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToOrganisationScreen();
            this.marketPlaceFilterService.resetFilters();
            break;
                
    }
    this.actualProduct = null;
    this.actualCertification = null;
    this.actualOrganisation = null;
    if (this.isGoBack) {
      this.goBack();
    } else {
      this.isGoBack = true;
    }
    this.cd.detectChanges();
  }

  changeMarketPlaceScreenStateAlways(event: MatTabChangeEvent) {


    switch (event.index) {
          case 0:
            this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToProductAsSoftwareScreen();
            break;
          case 1:
            this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToProductAsContentScreen();
            break;
          case 2:
            this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToProductAsDienstleistungenScreen();
            break;
          case 3:
            this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToProductAsKIScreen();
            break;
          case 4:
            this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToCertificationScreen();  
            break;
          case 5:
            this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToOrganisationScreen();    
            break;
          case 6:
            this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToBaustelleScreen();      
            break;     
    
          case 7:
            this.marketplaceService.filterService.marketScreenState = this.marketplaceService.filterService.changeToEDGEScreen();
            break;                     
    }

  }

  /**
   * 1) Set the tab in url query to now selected tab 2.
   * 2) Reset all filters from url query, reset by explicitly handing null as value for each filter option key that is available in params
   * @param event Event upon tab change in marketplace overview, event.index is the now selected tab
   */
  changeQueryTab(event: MatTabChangeEvent) {
    this.disableAllTabs();
    const oldTabUrl = this.router.url;
    const oldTabUrlParam = this.router.url.split('?'); // operationen nach dem ?
    let keyParams = oldTabUrlParam[1];
    if (keyParams.includes('&')) {
      keyParams = keyParams.split('&')[0];

    }
    const lastTab = +keyParams.substr(-1);
    const changedToNewTab = lastTab != event.index;


    let queryParams: Params = {
      tab: event.index
    };


    this.changeMarketPlaceScreenStateAlways(event);


    if (changedToNewTab) {
      this.resetAllFilters();
      this.changeMarketPlaceScreenState(event);

      queryParams = {
        tab: event.index,
        productId: null,
        certificationId: null,
        organisationId: null,
        category: null,
        ptgId: null,
        certificationType: null,
        appSubPro: null,
        orgId: null,
        certifiId: null,
        search: null,
        attr: null,
        rating: null
      };


    }
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      }
    );
    this.cd.detectChanges();
  }

  private resetAllFilters(): void {
    this.marketPlaceFilterService.resetSearchTextValue();
    this.marketPlaceFilterService.productFilter = [];
    this.marketPlaceFilterService.commonFeatureEntriesFilter = [];
    this.marketPlaceFilterService.setOrgaTypeFilter(null, [], []);
    this.marketPlaceFilterService.clearAllFilterLists();

  }

  @HostListener('window:scroll')
  onScroll() {
    let x = document.querySelector('.grid');
    if (x == null) {
      x = document.querySelector('.grid2');
    }
    if (x.scrollHeight > ((document.body.offsetHeight) / 2) || x.scrollHeight > window.innerHeight) {
      if (this.displayItem.length < this.totalNrOfItems && this.displayItem.length > 5) {
        this.displaySpinner = true;
        this.pageIndex++;
        setTimeout(() => { this.loadNextDisplayPage(); }, 200);
      }
    }
  }

  subscribeToOrder(): void {
    this.marketplaceService.filterService.orderByOrganisationSubject.subscribe(
      (o) => {
        this.orderByOrganisationFlag = o;
      }
    );
  }

  goBack(): void {
    this.showDetails = false;
    if (this.route.snapshot.queryParams['appSubPro'] != undefined) {
      this.marketPlaceFilterService.removeFilter(this.route.snapshot.queryParams['appSubPro'][0], CheckBoxCategorie.PRODUCTS);
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { appSubPro: null, productId: null, certificationId: null, organisationId: null },
          queryParamsHandling: 'merge',
        });
    } else if (this.router.url.includes('/marktplatz?tab=1')) {
      if (this.route.snapshot.queryParams['certifiId']) {
        this.marketPlaceFilterService.removeFilter(this.route.snapshot.queryParams['certifiId'][0], CheckBoxCategorie.CERTIFICATION);
      }
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { certifiId: null, productId: null, certificationId: null, organisationId: null },
          queryParamsHandling: 'merge',
        });
    } else if (this.router.url.includes('/marktplatz?tab=2')) {
      if (this.route.snapshot.queryParams['orgId']) {
        this.marketPlaceFilterService.removeFilter(this.route.snapshot.queryParams['orgId'][0], CheckBoxCategorie.ORGANISATION);
      }
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { orgId: null, productId: null, certificationId: null, organisationId: null },
          queryParamsHandling: 'merge',
        });
    } else {
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { productId: null, certificationId: null, organisationId: null },
          queryParamsHandling: 'merge',
        });
    }
    this.cd.detectChanges();
  }

  /**
   * Like LoadDisplyPage but adds the new DisplaySlice instead of replacing local
   */
  private loadNextDisplayPage() {
    // -----------------------------------------------------------
    // the createFunction handles the filter

    const dtoPageRequest = this.createPageRequest();

    // the marketplaceService handles the right api
    const request = this.marketplaceService.getFilteredSlice(
      dtoPageRequest,
      this.displaySetting
    );
    this.marketplaceService
      .getDisplayItems(request)
      .then((displayItem: DisplayItemInterface[]) => {
        this.displayItem = this.displayItem.concat(displayItem);
        this.displaySpinner = false;
        this.cd.detectChanges();
      });
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
    // -----------------------------------------------------------

  }

  /**
   * This function does the linking to the detail page.
   * @param link link to navigate to
   */
  onDisplayItemClicked(link: string) {
    if (this.displaySetting === DataType.ORGANISATION) {
      link = '@' + link;
    }
    this.subscription.unsubscribe();
    this.router.navigateByUrl(link);
  }

  /**
   * Loads the current page.
   * Resets display on every load.
   * Redraws display on every load.
   */
  private loadDisplayItems() {
    return new Promise<void>(resolve => {
      // -----------------------------------------------------------
      // the createFunction handles the filter
      const dtoPageRequest = this.createPageRequest();
      // the marketplaceService handles the right api
      const request = this.marketplaceService.getFilteredSlice(
        dtoPageRequest,
        this.displaySetting
      );
      this.marketplaceService
        .getDisplayItems(request)
        .then((displayItem: DisplayItemInterface[]) => {
          this.displayItem = displayItem;
          this.showNoResults = false;
          if (this.marketPlaceFilterService.searchText !== '' && this.displayItem.length === 0) {
            this.showNoResults = true;
          } else if (this.displayItem.length === 0) {
            this.showNoResults = true;
          }
          this.spinner = false;
          this.marketPlaceFilterService.filterSpinner = false;
          if (!this.cd['destroyed']) {
            this.cd.detectChanges();
          }
          resolve();
          this.enableAllTabs();
        });

    });

  }

  /**
   * Loads the current page.
   * Resets display on every load.
   * Redraws display on every load.
   */
  private loadDisplayItem(productId: number) {
    return new Promise<void>(resolve => {
      // -----------------------------------------------------------
      this.marketplaceService
        .getDisplayItem(productId)
        .then((displayItem: DisplayItemInterface) => {
          const oldItem: DisplayItemInterface = this.displayItem.find(item => item.id === productId);
          oldItem.reviewRating = displayItem.reviewRating;
          this.actualProduct.rating = displayItem.reviewRating.rating;
          this.actualProduct.ratingCount = displayItem.reviewRating.reviewNumber;
          if (!this.cd['destroyed']) {
            this.cd.detectChanges();
          }
          resolve();
        });
    });
  }

  changeQueryParams(id: number) {
    let queryParams: Params;
    if (this.marketplaceService.filterService.marketScreenState === DataType.PRODUCT) {
      queryParams = { productId: id };
    } else if (this.marketplaceService.filterService.marketScreenState === DataType.CERTIFICATION) {
      queryParams = { certificationId: id };
    } else if (this.marketplaceService.filterService.marketScreenState === DataType.ORGANISATION) {
      queryParams = { organisationId: id, flag: null };
    } else if (this.marketplaceService.filterService.marketScreenState === DataType.BAUSTELLE) {
      queryParams = { productId: id };
      this.marketplaceService.filterService.marketScreenState = DataType.PRODUCT
    }  else if (this.marketplaceService.filterService.marketScreenState === DataType.CONTENT) {
      queryParams = { productId: id };
      this.marketplaceService.filterService.marketScreenState = DataType.PRODUCT
    }  else if (this.marketplaceService.filterService.marketScreenState === DataType.KÜNTSTLICHE_INTELLIGENZ) {
      queryParams = { productId: id };
      this.marketplaceService.filterService.marketScreenState = DataType.PRODUCT
    } else if (this.marketplaceService.filterService.marketScreenState === DataType.DIENSTLEISTUNGEN) {
      queryParams = { productId: id };
      this.marketplaceService.filterService.marketScreenState = DataType.PRODUCT
    } else if (this.marketplaceService.filterService.marketScreenState === DataType.ROBOT) {
      queryParams = { productId: id };
      this.marketplaceService.filterService.marketScreenState = DataType.PRODUCT
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
    this.showDetails = true;
    this.cd.detectChanges();
  }

  getDisplayItemDetails(prodId: number, orgaName: string) {
    return new Promise((resolve) => {
      switch (this.marketplaceService.filterService.marketScreenState) {
        case DataType.PRODUCT:
          this.productService.getItem(prodId).then((product) => {
            this.actualProduct = product;
            this.actualProduct.organisationName = orgaName;
            resolve(true);
          });
          break;
        case DataType.KÜNTSTLICHE_INTELLIGENZ:
            this.productService.getItem(prodId).then((product) => {
              this.actualProduct = product;
              this.actualProduct.organisationName = orgaName;
              resolve(true);
            });
            break;  
        case DataType.ROBOT:
              this.productService.getItem(prodId).then((product) => {
                this.actualProduct = product;
                this.actualProduct.organisationName = orgaName;
                resolve(true);
              });
              break;      
        case DataType.DIENSTLEISTUNGEN:
              this.productService.getItem(prodId).then((product) => {
                this.actualProduct = product;
                this.actualProduct.organisationName = orgaName;
                resolve(true);
              });
              break;
        case DataType.CONTENT:
                this.productService.getItem(prodId).then((product) => {
                  this.actualProduct = product;
                  this.actualProduct.organisationName = orgaName;
                  resolve(true);
                });
                break;
        case DataType.CERTIFICATION:
          this.certificationService.getItem(prodId).then((certification) => {
            this.actualCertification = certification;
            this.actualCertification.organisationName = orgaName;
            resolve(true);
          });
          break;
        case DataType.ORGANISATION:
          this.organisationService.getOrganisation(prodId).then((organisation) => {
            this.actualOrganisation = organisation;
            this.actualOrganisation.name = organisation.organisationName;
            resolve(true);
          });
          break;
        case DataType.BAUSTELLE:
            this.productService.getItem(prodId).then((product) => {
              this.actualProduct = product;
              this.actualProduct.organisationName = orgaName;
              resolve(true);
            });
            break;
        case DataType.EDGESERVER:
              this.productService.getItem(prodId).then((product) => {
                this.actualProduct = product;
                this.actualProduct.organisationName = orgaName;
                resolve(true);
              });
              break;      
        default:
          break;
      }
    });
  }

  isLoggedIn(): boolean {
    return this.userService.loggedIn;
  }

  isBookmarked(productId: number, displayType: number) {
    let isBookmarked = false;
    this.productListService.getProductList().getValue().forEach((bookmarkedProduct) => {
      if (bookmarkedProduct.id == productId && bookmarkedProduct.displayType === displayType) { isBookmarked = true; }
    });
    return isBookmarked;
  }
  /**
   * Create a dtoPageRequest from the filter of the filterservice
   */
  @HostListener('window:size', [])
  private createPageRequest() {
    const filterService = this.marketplaceService.filterService;
    if (Number(window.innerWidth) < 2561 && Number(window.innerHeight) < 1441) {
      DtoPageFactory.bluePrint.pageSize = 30;
    } else if (Number(window.innerWidth) < 3841 && Number(window.innerHeight) < 2161) {
      DtoPageFactory.bluePrint.pageSize = 50;
    } else if (Number(window.innerHeight) < 7681 && Number(window.innerHeight) < 4321) {
      DtoPageFactory.bluePrint.pageSize = 216;
    }

    DtoPageFactory.loadFilter(filterService.filter);
    DtoPageFactory.bluePrint.pageIndex = this.pageIndex;
    DtoPageFactory.bluePrint.searchTerm = filterService.searchText;
    if (filterService.sortingOptionSubject.getValue() !== undefined) {
      DtoPageFactory.bluePrint.sortMethod = filterService.sortingOptionSubject.getValue();
    }
    return DtoPageFactory.createDtoPage();
  }

  checkIfFocusForProduct(item: DisplayItemInterface) {
    if (!this.showDetails) {
      return false;
    }
    if (this.actualProduct) {
      return item.id === this.actualProduct.id;
    }
    return false;
  }

  checkIfFocusForCertificate(item: DisplayItemInterface) {
    if (!this.showDetails) {
      return false;
    }
    if (this.actualCertification) {
      return item.id === this.actualCertification.id;
    }
    return false;
  }

  checkIfFocusForOrga(item: DisplayItemInterface) {
    if (!this.showDetails) {
      return false;
    }
    if (this.actualOrganisation) {
      return item.id === this.actualOrganisation.organisationId;
    }
    return false;
  }


  ngOnDestroy(): void {
    this.marketplaceService.filterService.marketScreenState = DataType.PRODUCT;
    this.subscription.unsubscribe();
    if (this.queryParamSubscription) { this.queryParamSubscription.unsubscribe(); }
    this.displayItem = [];

    if (this.filterServiceSubscription) {
      this.filterServiceSubscription.unsubscribe();
    }
  }
  /**
   * this function is there to take the previous Queryparams that came with the link
   * and restore the page with all the parameters when a detail page is opened
   */
  private loadQueryParams(): void {
    if (this.route.snapshot.queryParams['productId']) {
      const id = this.route.snapshot.queryParams['productId'];
      this.tmpUrl = '/marktplatz?tab=0';
      if (this.route.snapshot.queryParams['category']) {
        const category = this.route.snapshot.queryParams['category'];
        this.tmpUrl = this.tmpUrl + '&category=' + category;
      }
      if (this.route.snapshot.queryParams['certificationType']) {
        const certType = this.route.snapshot.queryParams['certificationType'];
        this.tmpUrl = this.tmpUrl + '&certificationType=' + certType;
      }
      if (this.route.snapshot.queryParams['certifiId']) {
        const certId = this.route.snapshot.queryParams['certifiId'];
        if (Array.isArray(certId)) {
          certId.forEach((element) => { this.tmpUrl = this.tmpUrl + '&certifiId=' + element; });
        } else {
          this.tmpUrl = this.tmpUrl + '&certifiId=' + certId;
        }
      }
      if (this.route.snapshot.queryParams['ptgId']) {
        const ptgId = this.route.snapshot.queryParams['ptgId'];
        if (Array.isArray(ptgId)) {
          ptgId.forEach((element) => { this.tmpUrl = this.tmpUrl + '&ptgId=' + element; });
        } else {
          this.tmpUrl = this.tmpUrl + '&ptgId=' + ptgId;
        }
      }
      if (this.route.snapshot.queryParams['orgId']) {
        const orgaId = this.route.snapshot.queryParams['orgId'];
        if (Array.isArray(orgaId)) {
          orgaId.forEach((element) => { this.tmpUrl = this.tmpUrl + '&orgId=' + element; });
        } else {
          this.tmpUrl = this.tmpUrl + '&orgId=' + orgaId;
        }
      }
      if (this.route.snapshot.queryParams['rating']) {
        const rating = this.route.snapshot.queryParams['rating'];
        if (Array.isArray(rating)) {
          rating.forEach((element) => { this.tmpUrl = this.tmpUrl + '&rating=' + element; });
        } else {
          this.tmpUrl = this.tmpUrl + '&rating=' + rating;
        }
      }
      if (this.route.snapshot.queryParams['attr']) {
        const attrId = this.route.snapshot.queryParams['attr'];
        if (Array.isArray(attrId)) {
          attrId.forEach((element) => { this.tmpUrl = this.tmpUrl + '&attr=' + element; });
        } else {
          this.tmpUrl = this.tmpUrl + '&attr=' + attrId;
        }
      }
      this.tmpUrl = this.tmpUrl + '&appSubPro=' + id + '&productId=' + id;
      this.router.navigateByUrl(this.tmpUrl);
    } else if (this.route.snapshot.queryParams['certificationId']) {
      const id = this.route.snapshot.queryParams['certificationId'];
      this.tmpUrl = '/marktplatz?tab=1';
      if (this.route.snapshot.queryParams['orgId']) {
        const orgaId = this.route.snapshot.queryParams['orgId'];
        this.tmpUrl = this.tmpUrl + '&orgId=' + orgaId;
      }
      if (this.route.snapshot.queryParams['certificationType']) {
        const certType = this.route.snapshot.queryParams['certificationType'];
        this.tmpUrl = this.tmpUrl + '&certificationType=' + certType;
      }
      this.tmpUrl = this.tmpUrl + '&certifiId=' + id + '&certificationId=' + id;
      this.router.navigateByUrl(this.tmpUrl);
    } else if (this.route.snapshot.queryParams['organisationId']) {
      const id = this.route.snapshot.queryParams['organisationId'];
      this.tmpUrl = '/marktplatz?tab=2';
      if (this.route.snapshot.queryParams['category']) {
        const category = this.route.snapshot.queryParams['category'];
        this.tmpUrl = this.tmpUrl + '&category=' + category;
      }
      if (this.route.snapshot.queryParams['certificationType']) {
        const certType = this.route.snapshot.queryParams['certificationType'];
        this.tmpUrl = this.tmpUrl + '&certificationType=' + certType;
      }
      this.tmpUrl = this.tmpUrl + '&orgId=' + id + '&organisationId=' + id;
      this.router.navigateByUrl(this.tmpUrl);


    }
  }
}
