import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy, ChangeDetectorRef,
} from "@angular/core";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { VariantVersionInterface } from "src/app/model/products/variant-version-interface";
import { DataType } from "src/app/model/data-type.enum";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { PtgVersionInterface } from "src/app/model/ptgs/ptg-version-interface";
import { AttributeInterface } from "src/app/model/attributes/attribute-interface";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { AttributeValidationServiceService } from "src/app/services/dataServices/dataValidation/attribute-validation-service/attribute-validation-service.service";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { ProductService } from "src/app/services/dataServices/product-service/productService";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { ProductVariantInterface } from "src/app/model/products/product-variant-interface";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";
import { ValidationInterface } from "src/app/model/attributes/validation-interface";
import { ProductInterface } from "src/app/model/products/product-interface";
import { MatSelectChange } from "@angular/material/select";
import { ProductVariantService } from "src/app/services/dataServices/product-variant-service/product-variant.service";
import { CertificationVariantService } from "src/app/services/dataServices/certification-variant-service/certification-variant-service";
import { ProductVariantVersionService } from "src/app/services/dataServices/product-variant-version-service/product-variant-version.service";
import { CertificationVariantVersionService } from "src/app/services/dataServices/cetrification-variant-version-service/certification-variant-version-service";
import { Status } from "src/app/model/enums/status";

@Component({
  selector: "app-version-edit",
  templateUrl: "./version-edit.component.html",
  styleUrls: ["./version-edit.component.css"],
})
export class VersionEditComponent implements OnInit, OnDestroy {
  @Input() version:
    | CertificationVariantVersionInterface
    | VariantVersionInterface;

  @Input() dataType: DataType;
  @Input() isCreateNew: boolean;
  @Output() validityChanged = new EventEmitter<boolean>();
  @ViewChild("form") ngForm: NgForm ;
  private formValid: boolean = false;
  private descriptionValid: boolean = true;
  private formSubscription: Subscription;
  private showFeatureCatalogue: boolean;

  public catalogueAttributes: AttributeInterface[] = [];
  private optionalPtgAttributes: AttributeInterface[] = [];
  private ptgAttributes: AttributeInterface[] = [];
  public isCertification: boolean = false;
  private addOtherVersionAttributesMode: boolean = false;
  private allVersionsOfActiveVariant:
    | CertificationVariantVersionInterface[]
    | VariantVersionInterface[];
  private selectedDependeeVariant:
    | CertificationVariantInterface
    | ProductVariantInterface;
  private selectedDependeeVersion:
    | CertificationVariantVersionInterface
    | VariantVersionInterface;

  constructor(
    private productService: ProductService,
    private productVariantService: ProductVariantService,
    private productVariantVersionService: ProductVariantVersionService,
    private certificationService: CertificationService,
    private certificationVariantService: CertificationVariantService,
    private certificicationVariantVersionService: CertificationVariantVersionService,
    private ptgService: PtgService,
    private attributeValidationService: AttributeValidationServiceService,
    private dialogService: DialogService,
  ) {}

  ngOnDestroy() {
    if (this.formSubscription != null) {
      this.formSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.isCertification = this.dataType.toString().includes("CERTIFICATION");
    this.attributeValidationService.reset();
    this.setInitialVariableValues();
  }

  ngAfterViewInit () {
    this.setUpFormSubscription();
  }

  private setUpFormSubscription() {
    this.formSubscription = this.ngForm.valueChanges.subscribe(() => {
      if (this.ngForm.valid != this.formValid) {
        this.formValid = this.ngForm.valid;
        this.validityChanged.emit(this.formValid && this.descriptionValid);
      }
    });
  }

  private setInitialVariableValues(): void {
    this.version.ptgVersions = [];
    this.version.ptgVersionIds = [];
    this.version.keywords = [];
    this.version.status = Status.INACTIVE;
    this.version.description = "";
    if (this.isVersionEditMode()) {
      this.allVersionsOfActiveVariant = this.getAllVersionsOfActiveVariant();
      if (this.version.id != null) {
        let currentVersion:
          | CertificationVariantVersionInterface
          | VariantVersionInterface;
        if (this.isCertification) {
          currentVersion = this.certificicationVariantVersionService.activeItem;
        } else {
          currentVersion = this.productVariantVersionService.activeItem;
        }
        this.version.status = currentVersion.status;
        this.version.name = currentVersion.name;
        this.version.comment = currentVersion.comment;
        this.version.description = currentVersion.description;
        this.version.keywords = currentVersion.keywords;
        this.addAttributeValidationsFromOtherVersion(this.version.id);
        this.version.previousVersion = currentVersion.previousVersion;
        this.selectedDependeeVersion = null;
      } else if (this.version.previousVersion != null) {
        this.addAttributeValidationsFromOtherVersion(
          this.version.previousVersion
        );
      }
      this.selectedDependeeVariant = null;
    }
  }

  public isVersionEditMode() {
    return this.dataType.includes("VERSION");
  }

  private getAllVersionsOfActiveVariant():
    | CertificationVariantVersionInterface[]
    | VariantVersionInterface[] {
    let allVersionsOfActiveVariant = [];
    let activeVariant;
    if (this.isCertification) {
      activeVariant = this.certificationVariantService.activeItem;
      allVersionsOfActiveVariant = activeVariant.certificationVariantVersions.filter(
        (version) => version.id != this.version.id
      );
    } else {
      activeVariant = this.productVariantService.activeItem;
      allVersionsOfActiveVariant = activeVariant.variantVersions.filter(
        (version) => version.id != this.version.id
      );
    }
    this.selectedDependeeVariant = activeVariant;
    return allVersionsOfActiveVariant;
  }

  private setEffectiveAttributeValidations(): void {
    this.attributeValidationService.setEffectiveAttributeValidations(
      this.catalogueAttributes,
      this.ptgAttributes,
      this.optionalPtgAttributes
    );
  }

  private setAttributeValidationValuesFromStringValues(attributeValidation: {
    attribute: AttributeInterface;
    value: string;
  }) {
    const attribute: AttributeInterface = attributeValidation.attribute;
    const validationValue: string = attributeValidation.value;
    const value = this.parseAttributeValueFromString(
      attribute.datatype,
      validationValue
    );
    const validation = attribute.validations[0];
    this.attributeValidationService.modelChangedValid(
      attribute,
      validation,
      value
    );
  }

  // Helper method that Parses the value of an attribute depending on its datatype
  private parseAttributeValueFromString(
    dataType: string,
    validationValue: string
  ) {
    if (validationValue == null) {
      return null;
    }
    let result: any;
    switch (dataType) {
      case "NUMERIC": {
        result = parseInt(validationValue);
        if (isNaN(result)) {
          result = null;
        }
        break;
      }
      case "BOOLEAN": {
        result =
          validationValue === "undefined" ? null : validationValue === "true";
        break;
      }
      case "LIST": {
        result = validationValue.split(";");
        break;
      }
      default: {
        result = validationValue;
      }
    }
    return result;
  }

  // data flow method for component input of attribute validation table
  private getOptionalAttributesOfPtgVersion(
    ptgVersion: PtgVersionInterface
  ): AttributeInterface[] {
    const attributes: AttributeInterface[] = [];
    const ptgAttributeValidations = ptgVersion.attributeValidations;
    for (const ptgAttributeValidation of ptgAttributeValidations) {
      if (ptgAttributeValidation.optionalValidation) {
        const attribute = ptgAttributeValidation.attribute;
        attributes.push(attribute);
      }
    }
    return attributes;
  }

  // data flow method for component input of attribute validation table
  private getNonOptionalAttributesOfPtgVersion(
    ptgVersion: PtgVersionInterface
  ): AttributeInterface[] {
    const attributes: AttributeInterface[] = [];
    const ptgAttributeValidations = ptgVersion.attributeValidations;
    for (const ptgAttributeValidation of ptgAttributeValidations) {
      if (!ptgAttributeValidation.optionalValidation) {
        const attribute = ptgAttributeValidation.attribute;
        attributes.push(attribute);
      }
    }
    return attributes;
  }

  private getPtgName(version: PtgVersionInterface): string {
    return version.ptgName;
  }

  // Event listener for the attribute validation table
  public onAttributesChanged(attributes: AttributeInterface[]): void {
    this.catalogueAttributes = attributes;
    this.setEffectiveAttributeValidations();
  }

  // event listener for add-ptg component
  public onPtgVersionAdded(ptgVersion: PtgVersionInterface): void {
    const attributeValidations = ptgVersion.attributeValidations;
    for (const attributeValidation of attributeValidations) {
      const attribute = attributeValidation.attribute;
      if (attributeValidation.optionalValidation) {
        this.optionalPtgAttributes = this.optionalPtgAttributes.concat(
          attribute
        );
      } else {
        this.ptgAttributes = this.ptgAttributes.concat(attribute);
      }
    }
    this.setEffectiveAttributeValidations();
  }

  // Event listener for the add-ptg-component
  public onPtgVersionsChanged(ptgVersions: PtgVersionInterface[]): void {
    this.ptgAttributes = [];
    this.optionalPtgAttributes = [];
    this.setPtgAttributeArrays(ptgVersions);
    this.setEffectiveAttributeValidations();
  }

  private setPtgAttributeArrays(ptgVersions: PtgVersionInterface[]): void {
    for (const ptgVersion of ptgVersions) {
      for (const ptgAttributeValidation of ptgVersion.attributeValidations) {
        const attribute = ptgAttributeValidation.attribute;
        if (ptgAttributeValidation.optionalValidation) {
          this.optionalPtgAttributes = this.optionalPtgAttributes.concat(
            attribute
          );
        } else {
          this.ptgAttributes = this.ptgAttributes.concat(attribute);
        }
      }
    }
  }

  private showFeatureCatalogueToggle(): void {
    this.showFeatureCatalogue = !this.showFeatureCatalogue;
  }

  // Handles the data flow from the version to the Feature-Catalogue
  public featureCatalogueConfirmPressed(
    attributes: AttributeInterface[]
  ): void {
    for (const newAttribute of attributes) {
      this.addNewAttribute(newAttribute);
    }
    this.setEffectiveAttributeValidations();
    this.showFeatureCatalogueToggle();
  }

  // Adds a new attribute to the current attributes of the version
  // Checks for duplicates.
  private addNewAttribute(newAttribute: AttributeInterface): void {
    const existingAttributes = this.catalogueAttributes.concat(
      this.ptgAttributes,
      this.optionalPtgAttributes
    );
    const existingAttrbutesWithSameId = existingAttributes.filter(
      (existingAttribute) => {
        return existingAttribute.id === newAttribute.id;
      }
    );
    for (const existingAttribute of existingAttrbutesWithSameId) {
      const containsSameValidation = this.checkIfAttributesContainSameValidation(
        existingAttribute,
        newAttribute
      );
      if (containsSameValidation) {
        this.dialogService.openDialog(
          "Merkmal hinzufügen fehlgeschlagen",
          "Die gewählte Validierung für das Merkmal " +
            newAttribute.name +
            " ist bereits vorhanden. Bitte wählen sie eine andere Validierung oder ein anderes Merkmal"
        );
        return;
      }
    }
    this.attributeValidationService.activeEntityFeatureValues.set(
      newAttribute.id,
      undefined
    );
    this.attributeValidationService.attributesValidated.set(
      newAttribute.id,
      false
    );
    this.catalogueAttributes.push(newAttribute);
    existingAttributes.push(newAttribute);
  }

  private checkIfAttributesContainSameValidation(
    attribute1: AttributeInterface,
    attribute2: AttributeInterface
  ): boolean {
    const validation1 = attribute1.validations[0];
    const validation2 = attribute2.validations[0];
    return validation1.id === validation2.id;
  }

  public isVariantCreateMode() {
    return this.dataType.includes("VARIANT");
  }

  private addOtherVersionAttributesModeToggle(value: boolean): void {
    this.addOtherVersionAttributesMode = value;
  }

  private getOtherVariantsOfEntity():
    | CertificationVariantInterface[]
    | ProductVariantInterface[] {
    if (this.isCertification) {
      return this.certificationService.activeItem.certificationVariants;
    } else {
      return this.productService.activeItem.productVariants;
    }
  }

  private setDependeeVariant(event: MatSelectChange): void {
    let variantId: number = parseInt(event.value);
    if (this.isCertification) {
      let currentCertification: CertificationInterface = this
        .certificationService.activeItem;
      this.selectedDependeeVariant = currentCertification.certificationVariants.find(
        (variant: CertificationVariantInterface) => variant.id === variantId
      );
    } else {
      let currentProduct: ProductInterface = this.productService.activeItem;
      this.selectedDependeeVariant = currentProduct.productVariants.find(
        (variant: ProductVariantInterface) => variant.id === variantId
      );
    }
  }

  private filterSelectedVariant(
    selectedVariantId: string,
    selected: boolean
  ): CertificationVariantVersionInterface[] | VariantVersionInterface[] {
    let variants = this.getOtherVariantsOfEntity();
    try {
      if (!selected) {
        if (this.isCertification) {
          const tempVariants: CertificationVariantInterface[] = variants;
          return tempVariants.filter(
            (variant: CertificationVariantInterface) =>
              variant.id === parseInt(selectedVariantId)
          )[0].certificationVariantVersions;
        } else {
          const tempVariants: ProductVariantInterface[] = variants;
          return tempVariants.filter(
            (variant: ProductVariantInterface) =>
              variant.id === parseInt(selectedVariantId)
          )[0].variantVersions;
        }
      }
    } catch (e) {}
  }

  // listener method
  // Gets PTGs and Attributes from another version(previous version) and sets them in the current version to be created
  public onAddAttributeValidationsFromOtherVersion(
    event: MatSelectChange
  ): void {
    if (this.selectedDependeeVersion != null) {
      this.removePreviousVersion();
    }
    let versionId: number = parseInt(event.value);
    this.addAttributeValidationsFromOtherVersion(versionId);
  }

  private addAttributeValidationsFromOtherVersion(versionId: number): void {
    let selectedVersion;
    selectedVersion = this.getSelectedVersion(versionId);
    let newPtgValidations: number[] = this.addPtgVersionFromOtherVersion(
      selectedVersion.ptgVersions
    );
    const attributeValidationValues: {
      attribute: AttributeInterface;
      value: string;
    }[] = selectedVersion.attributeValidationValues;
    this.addVersionAttributesFromOtherVersion(
      attributeValidationValues,
      newPtgValidations
    );
    this.setEffectiveAttributeValidations();
    for (const attributeValidationValue of attributeValidationValues) {
      this.setAttributeValidationValuesFromStringValues(
        attributeValidationValue
      );
    }
    this.selectedDependeeVersion = selectedVersion;
  }

  private getSelectedVersion(
    versionId: number
  ): CertificationVariantVersionInterface | VariantVersionInterface {
    let selectedVersion;
    if (this.isCertification) {
      const tempVariant: CertificationVariantInterface = this
        .selectedDependeeVariant;
      selectedVersion = tempVariant.certificationVariantVersions.find(
        (version: CertificationVariantVersionInterface) =>
          versionId === version.id
      );
    } else {
      const tempVariant: ProductVariantInterface = this.selectedDependeeVariant;
      selectedVersion = tempVariant.variantVersions.find(
        (version: VariantVersionInterface) => versionId === version.id
      );
    }
    return selectedVersion;
  }

  private addPtgVersionFromOtherVersion(
    newPtgVersions: PtgVersionInterface[]
  ): number[] {
    let newPtgValidations: number[] = [];
    for (const ptgVersion of newPtgVersions) {
      const attributeValidations = ptgVersion.attributeValidations;
      for (const attributeValidation of attributeValidations) {
        const attribute = attributeValidation.attribute;
        const validation = attribute.validations[0];
        newPtgValidations.push(validation.id);
      }
      const isPtgVersionDuplicate = this.version.ptgVersions.some(
        (existingPtgVersion) => {
          return existingPtgVersion.id === ptgVersion.id;
        }
      );
      if (!isPtgVersionDuplicate) {
        this.version.ptgVersions.push(ptgVersion);
        this.version.ptgVersionIds.push(ptgVersion.id);
        this.onPtgVersionAdded(ptgVersion);
      }
    }
    return newPtgValidations;
  }

  private addVersionAttributesFromOtherVersion(
    attributeValidationValues: {
      attribute: AttributeInterface;
      value: string;
    }[],
    validationIdsFromPtgs: number[]
  ) {
    for (const attributeValidationValue of attributeValidationValues) {
      const attribute: AttributeInterface = attributeValidationValue.attribute;
      const validation: ValidationInterface = attribute.validations[0];
      if (validationIdsFromPtgs.indexOf(validation.id) === -1) {
        this.addNewAttribute(attribute);
      }
    }
  }

  // removes dependency on previous version from subversion.
  // removes PTGS and individual Attributes of the previous version
  private removePreviousVersion(): void {
    this.removePtgsOfPreviousVersion(this.selectedDependeeVersion);

    this.removeVersionAttributesOfPreviousVersion(this.selectedDependeeVersion);
  }

  // removes ptgs of the previous version on removePreviousVersion call
  private removePtgsOfPreviousVersion(
    previousVersion:
      | CertificationVariantVersionInterface
      | VariantVersionInterface
  ): void {
    for (const previousPtgVersion of previousVersion.ptgVersions) {
      this.version.ptgVersions = this.version.ptgVersions.filter(
        (ptgVersion) => {
          return ptgVersion.id !== previousPtgVersion.id;
        }
      );
    }
    this.onPtgVersionsChanged(this.version.ptgVersions);
  }

  // removes attributes of the previous version on removePreviousVersion call
  private removeVersionAttributesOfPreviousVersion(
    previousVersion:
      | CertificationVariantVersionInterface
      | VariantVersionInterface
  ) {
    const prevVersionAttributeValues: {
      attribute: AttributeInterface;
      value: string;
    }[] = previousVersion.attributeValidationValues;
    for (const attributeValue of prevVersionAttributeValues) {
      const prevAttribute = attributeValue.attribute;
      this.catalogueAttributes = this.catalogueAttributes.filter(
        (attribute) => {
          return !this.checkIfAttributesContainSameValidation(
            attribute,
            prevAttribute
          );
        }
      );
    }
    this.onAttributesChanged(this.catalogueAttributes);
  }

  public onDescriptionValidityChange(descriptionValid: boolean): void {
    if (this.descriptionValid != descriptionValid) {
      this.descriptionValid = descriptionValid;
      this.validityChanged.emit(this.descriptionValid && this.formValid);
    }
  }
}
