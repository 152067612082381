import { Component, OnInit, Input } from "@angular/core";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { AddressInterface } from "src/app/model/address-interface";
import { ContactInterface } from "src/app/model/organisations/contact-interface";

@Component({
  selector: "app-certification-detail-display-organisation",
  templateUrl: "./certification-detail-display-organisation.component.html",
  styleUrls: ["./certification-detail-display-organisation.component.css"],
})
export class CertificationDetailDisplayOrganisationComponent implements OnInit {
  @Input() organisation: OrganisationInterface;

  constructor() {}

  ngOnInit() {}

  private getFirstLineOfAddress(address: AddressInterface): string {
    let addressLine = address.street;
    if (address.streetAdditional != null) {
      addressLine += " ";
      addressLine += address.streetAdditional;
    }
    return addressLine;
  }

  private getSecondLineOfAddress(address: AddressInterface): string {
    let addressLine = address.postalCode + " " + address.city;
    return addressLine;
  }

  private getContactEmail(): string {
    if (!this.hasContactPerson(this.organisation)) {
      return "Keine Angabe";
    }
    const firstContact = this.getFirstContactPerson(this.organisation);
    const email = firstContact.email ? firstContact.email : "Keine Angabe";
    return email;
  }

  private getContactTelephoneNumber(): string {
    if (!this.hasContactPerson(this.organisation)) {
      return "Keine Angabe";
    }
    const firstContact = this.getFirstContactPerson(this.organisation);
    let telephoneNumber = firstContact.phoneNumber
      ? firstContact.phoneNumber
      : "Keine Angabe";
    return telephoneNumber;
  }

  private getContactPerson(): string {
    if (!this.hasContactPerson(this.organisation)) {
      return "Keine Angabe";
    }
    const contactPerson = this.getFirstContactPerson(this.organisation);
    return contactPerson.contactName;
  }

  private hasContactPerson(organisation: OrganisationInterface): boolean {
    const contacts = organisation.contacts;
    return contacts != null && contacts.length != 0;
  }

  private getFirstContactPerson(
    organisation: OrganisationInterface
  ): ContactInterface {
    const contacts = organisation.contacts;
    return contacts[0];
  }

  private hasContactWebsite(organisation: OrganisationInterface): boolean {
    let website = organisation.website;
    return website != null;
  }

  private getContactWebsite(): string {
    if (this.hasContactWebsite(this.organisation)) {
      let website = this.organisation.website;
      return website;
    }
    return "Keine Angabe";
  }
}
