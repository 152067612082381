import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from "@angular/core";
import { MalfunctionService } from "src/app/services/malfunction-service/malfunction.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MalfunctionInterface } from "src/app/model/malfunctions/malfunction-interface";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { environment } from "src/environments/environment";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { OrganisationMemberInterface } from "src/app/model/organisations/organisationMember-interface";
import { ProductVariantInterface } from "src/app/model/products/product-variant-interface";
import { ProductService } from "src/app/services/dataServices/product-service/productService";

@Component({
  selector: "app-report-malfunction-screen",
  templateUrl: "./report-malfunction-screen.component.html",
  styleUrls: ["./report-malfunction-screen.component.css"],
})
export class ReportMalfunctionScreenComponent implements OnInit, OnDestroy {
  public productId: number = +this.route.snapshot.paramMap.get("productId");
  public organisationId: number;

  @Input() public malfunction?: MalfunctionInterface;
  private malfunctionId?: number;
  @Input() public editForm: boolean; //Ist das Formular bearbeitbar?
  @Input() public adminView?: boolean = false; //Ist der Benutzer Mitglied der Organisation des Produktest?
  @Output() private showFormEmitter: EventEmitter<any> = new EventEmitter();

  private membersOfOrga: OrganisationMemberInterface[];
  private isPublic: boolean;

  private currentlySelectedClerk: OrganisationMemberInterface;
  public productVariants?: ProductVariantInterface[];
  public productName: string;

  private currentlySelectedVariantName: string;
  private currentlySelectedVersionName: string;

  private readonly maxTitelChar: number = 100;
  private readonly maxDescriptionChar: number = 10000; //TODO: Quill Editor zählt leere Zeilen (Absätze etc) als Characters -> zu fixen

  public readonly textEditorModules = {
    toolbar: [
      ["bold", "italic", "underline", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };
  //-------------------------------------------------------------------------
  public readonly textEditorStyle: {
    height: "auto";
    "font-family": "'Roboto', sans-serif";
  };

  errorMessageSubmit: String =
    `Der Titel muss 1 bis ${this.maxTitelChar} Zeichen lang sein und \n` +
    `das Feebackfeld darf höchstens ${this.maxDescriptionChar} Zeichen lang sein \n` +
    "und Status sowie Priorität müssen gesetzt sein." +
    "\n Falls der Status auf 'In Arbeit' gesetzt wurde, muss eine Bearbeiter hinzugefügt werden";

  public form = new FormGroup({
    titel: new FormControl("", [
      Validators.required,
      Validators.maxLength(this.maxTitelChar),
    ]),
    description: new FormControl("", [
      Validators.maxLength(this.maxDescriptionChar),
    ]),
    priority: new FormControl("", [Validators.required]),
    status: new FormControl("", [Validators.required]),
    isPublic: new FormControl(""),
    clerkList: new FormArray([]),
    versionList: new FormArray([]),
  });

  constructor(
    public malfunctionService: MalfunctionService,
    private organisationService: OrganisationService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private dialogService: DialogService
  ) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
 }
  get clerkList() {
    return this.form.get("clerkList") as FormArray;
  }

  get versionList() {
    return this.form.get("versionList") as FormArray;
  }

  ngOnInit() {
    if (this.adminView) {
      this.organisationId = +this.route.snapshot.paramMap.get("organisationId");
      this.organisationService
        .getAllMembersOfOrganisationById(this.organisationId)
        .subscribe((allMembers) => {
          this.membersOfOrga = allMembers;
          this.membersOfOrga = this.membersOfOrga.filter(
            (member) =>
              !this.clerkList.value.some(
                (clerk: OrganisationMemberInterface) =>
                  clerk.userId === member.userId
              )
          );
        });
    }

    if (this.malfunction) {
      this.malfunctionId = this.malfunction.malfunctionId;
      this.form.get("status").setValue(this.malfunction.status);
      this.form.get("titel").setValue(this.malfunction.titel);
      this.form.get("description").setValue(this.malfunction.description);
      this.malfunction.clerkList.forEach((clerk) =>
        this.clerkList.push(new FormControl(clerk))
      );

      this.malfunction.affectedProductNames.forEach((name) =>
        this.versionList.push(new FormControl(name))
      );
      this.form.get("isPublic").setValue(this.malfunction.isPublic);
      this.form.get("priority").setValue(this.malfunction.priority);
    } else {
      this.form.get("isPublic").setValue(false);
    }

    this.productService.getProduct(this.productId).then((product) => {
      this.productName = product.name;
      this.productVariants = product.productVariants;
    });

    if (!this.editForm) {
      this.form.disable();
    }
  }

  ngOnDestroy() {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }
  /**
   * Liste der Sachbearbeiter zurücksetzen
   */
  resetClerkList() {
    this.clerkList.value.forEach((clerk: OrganisationMemberInterface) =>
      this.membersOfOrga.push(clerk)
    );
    while (this.clerkList.length != 0) {
      this.clerkList.removeAt(0);
    }
  }

  /**
   * Sachbearbeiter zur Liste hinzufügen und aus der Liste aller Orgateilnehmer entfernen
   */
  addClerk(): void {
    if (this.membersOfOrga.length > 0) {
      if (this.currentlySelectedClerk) {
        this.clerkList.push(new FormControl(this.currentlySelectedClerk));
        this.membersOfOrga = this.membersOfOrga.filter(
          (member) => member.userId !== this.currentlySelectedClerk.userId
        );
        this.currentlySelectedClerk = null;
      } else {
        this.dialogService.openDialog(
          "Bitte wählen Sie einen Sachbearbeiter für diese Störung aus",
          ""
        );
      }
    }
  }

  /**
   * Sachbearbeiter aus der Liste entfernen und zur Liste aller Orgateilnehmer hinzufügen
   * @param removedClerk, Sachbearbeiter, der zu entfernen ist
   * @param index, position des zu entfernenden Sachbearbeiters in der Liste
   */
  removeClerk(removedClerk: OrganisationMemberInterface, index: number) {
    this.clerkList.removeAt(index);
    this.membersOfOrga.push(removedClerk);
  }

  /**
   * Produktname + -version hinzufügen
   */
  addVariantVersion() {
    let addedVersion =
      this.productName +
      " " +
      this.currentlySelectedVariantName +
      " " +
      this.currentlySelectedVersionName;
    if (!this.isVersionAlreadyAdded(addedVersion)) {
      this.versionList.push(new FormControl(addedVersion));
    } else {
      this.dialogService.openDialog(
        "Produktversion wurde bereits hinzugefügt",
        "Bitte wählen Sie eine andere Version aus"
      );
    }
  }

  /**
   *
   * @param index Produktname + -version entfernen
   */
  removeVariantVersion(index: number) {
    this.versionList.removeAt(index);
  }

  /**
   * Formular bearbeitbar machen
   */
  enableEdit(): void {
    this.form.enable();
    this.editForm = true;
  }

  private isVersionAlreadyAdded(versionToBeChecked: string): boolean {
    return (
      this.versionList.value.filter((version) => version === versionToBeChecked)
        .length > 0
    );
  }

  private getErrorMessageTitel(): String {
    let errorMessage = "";
    if (this.form.get("titel").hasError("required")) {
      errorMessage = "Der Titel darf nicht leer sein";
    } else if (this.form.get("titel").hasError("maxlength")) {
      errorMessage = `Ihr Titel darf nicht länger als ${this.maxTitelChar} Zeichen sein`;
    }
    return errorMessage;
  }

  /**
   * Störung absenden, ausgeführt wenn Button gedrückt wird
   */
  submitMalfunction(): void {
    this.dialogService
      .openConfirmDialog(
        "Sind Sie sicher die Störung abzusenden?",
        "Nein",
        "Ja"
      )
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          if (this.isFormValid()) {
            this.sendMalfunction(this.createMalfunctionInterface());
          } else {
            this.dialogService.openDialog(
              "Es ist ein Fehler aufgetreten",
              this.errorMessageSubmit
            );
          }
        }
      });
  }

  /**
   * Störung verwerfen, das Formular für das Bearbeiten/Erstellen der Störung wird verworfen
   */
  cancel(): void {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie die Störung wirklich abbrechen?",
        "Nein",
        "Ja"
      )
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.closeForm();
        }
      });
  }

  private sendMalfunction(newMalfunction: MalfunctionInterface): void {
    this.malfunctionService
      .createMalfunction(newMalfunction)
      .subscribe((malfunction) => {
        this.malfunctionService.setNewMalfunction(malfunction);
        this.dialogService
          .openDialog(
            this.editForm
              ? "Störung erfolgreich aktualisiert"
              : "Störung erfolgreich gemeldet",
            ""
          )
          .subscribe(() => this.closeForm());
      });
  }

  /**
   * Störung wird gelöscht
   * @param malfunction, die zu löschende Störung
   */
  deleteMalfunction(malfunction: MalfunctionInterface) {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie diese Störung wirklich löschen?",
        "Nein",
        "Ja"
      )
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.malfunctionService
            .deleteMalfunction(malfunction)
            .subscribe(() => {
              this.dialogService
                .openDialog("Störung erfolgreich gelöscht", "")
                .subscribe(() => {
                  this.malfunctionService.setDeletedMalfunction(malfunction);
                  this.closeForm();
                });
            });
        }
      });
  }

  private closeForm(): void {
    this.editForm = false;
    this.form.disable();
    this.showFormEmitter.emit();
  }

  private isFormValid(): boolean {
    if (
      (this.form.get("status").value === "IN_PROGRESS" ||
        this.form.get("status").value === "FIXED") &&
      this.clerkList.value.length <= 0
    ) {
      return false;
    }

    return (
      this.form.get("titel").valid &&
      this.form.get("description").valid &&
      this.form.get("priority").valid &&
      this.form.get("status").valid
    );
  }

  private createMalfunctionInterface(): MalfunctionInterface {
    if (this.form.get("status").value === "REPORTED") {
      this.resetClerkList();
    }
    return {
      malfunctionId: this.malfunctionId,
      organisationId: this.organisationId,
      titel: this.form.get("titel").value,
      description: this.form.get("description").value,
      priority: this.form.get("priority").value,
      productId: this.productId,
      userId: null,
      status: this.form.get("status").value,
      isPublic: this.form.get("isPublic").value,
      clerkList: this.clerkList.value,
      affectedProductNames: this.versionList.value,
    };
  }
}
