<li [dragula]="'DRAGGABLE'" [ngClass]="label ? 'show' : 'hide'">
  <hex-label-item
    *ngIf="addButton"
    [link]="link"
    [label]="label"
    (click)="toggleClick()"
    [ngClass]="label ? 'show' : 'hide'"
  ></hex-label-item>
  <div
    *ngIf="!addButton"
    class="wrapper"
    (click)="toggleClick()"
  >
    <div
      class="container"
      [ngClass]="{ activeBg: isActive() }"
      draggable="true"
    >
      <div class="hexagon-wrapper">
        <hexagon
          *ngIf="addButton"
          [icon]="'plus'"
          [outerColor]="'white'"
          [middleColor]="'green'"
        ></hexagon>
        <hexagon
          *ngIf="!addButton && belongsToOrganisation"
          [outerColor]="'white'"
          [middleColor]="'darkblue'"
          [innerColor]="'white'"
          [img]="image"
          [imageId]="imageId"
        ></hexagon>
        <!--TODO hier untere Zeile evtl. wieder [middleColor]="image? 'white' : 'lightgreen'" einfügen-->
        <hexagon
          *ngIf="!addButton && !belongsToOrganisation"
          [outerColor]="'white'"
          [middleColor]="'lightgreen'"
          [innerColor]="'white'"
          [img]="sideMenuImageUrl"
          [hasNoImage]="hasNoImage"
          [imageId]="imageId"
        ></hexagon>
      </div>
      <p>{{ label }}</p>
    </div>
    <red-cross-button
      *ngIf="type === 'Usecase'"
      class="red-cross"
      (press)="deleteUsecase()"
    ></red-cross-button>
  </div>
  <ul
    *ngIf="
      hasChildren &&
      ((ptgId && ptgService.activeItem && ptgService.activeItem.id === ptgId) ||
        (entityId &&
          productService.activeItem &&
          productService.activeItem.id === entityId) ||
        (entityId &&
          certificationService.activeItem &&
          certificationService.activeItem.id === entityId) ||
        clicked)
    "
  >
    <app-sidemenu-button
      *ngFor="let child of children"
      [label]="child.label"
      [link]="child.link"
      [hasNoImage]="true"
      [entityId]="child.entityId"
      [ptgId]="child.ptgId"
      [addButton]="child.addButton"
      [type]="child.type"
      [rating]="child.rating"
      [composerItem]="child.composerItem"
      [children]="child.children"
      [isCertification]="isCertification"
    >
    </app-sidemenu-button>
  </ul>
</li>
