import {Pipe, PipeTransform} from '@angular/core';
import {PtgVersionInterface} from '../model/ptgs/ptg-version-interface';
import {Status} from '../model/enums/status';

@Pipe({name: 'filterPtgActive'})
export class FilterPtgActive implements PipeTransform {
  transform(ptgVersions: PtgVersionInterface[]) {
    if (ptgVersions === undefined) {
      return [];
    } else {
      return ptgVersions.filter((ptgVersion: PtgVersionInterface) => ptgVersion.status === Status.ACTIVE);
    }
  }
}
