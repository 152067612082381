import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-marketplace-sideimagehexagon',
  templateUrl: './marketplace-sideimagehexagon.component.html',
  styleUrls: ['./marketplace-sideimagehexagon.component.css']
})
export class MarketplaceSideimagehexagonComponent implements OnInit {

  @Input()  img: string;
  constructor() { }

  ngOnInit() { }

}
