import {Pipe, PipeTransform} from '@angular/core'
import { PtgInterface } from '../model/ptgs/ptg-interface';
import { Status } from '../model/enums/status';

@Pipe({name: 'markInactive'})
export class MarkInactivePipe implements PipeTransform {
    transform(ptgName: string, ptg: PtgInterface): string {
        var result : string = ptgName;
        if(!ptg.versions.some(version => version.status === Status.ACTIVE)){
            result = result + " (inaktiv)"
        }
        return result;
    }
}