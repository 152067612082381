<div *ngIf="displayItem.length > 0">
  <h2>{{ title }}</h2>
  <div
    class="content"
    (mousemove)="movingMouse($event)"
    (mouseleave)="resumeScrolling()"
    (mouseup)="resumeScrolling()"
  >
    <div class="leftside hover" (click)="moveLeft()">
      <h1><</h1>
    </div>
    <div class="rightside hover" (click)="moveRight()">
      <h1>></h1>
    </div>
    <div class="rondell" id="rondell" (mousedown)="pauseScrolling($event)">
      <ng-container *ngFor="let item of displayItem">
        <app-display-item
          [itemInfo]="item"
          (click)="onDisplayItemClicked(item.link)"
          (dragstart)="itemDrag($event)"
        ></app-display-item>
      </ng-container>
    </div>
  </div>
</div>
