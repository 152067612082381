<div *ngIf="product" class="container">
    <div class="collapseButton" (click)="collapseProductOverview()">
      <div class="collapseButtonIcon"></div>
    </div>
    <div class="leftSide">
      <label for="productName"> Name </label>
      <h1 id="productName">{{ product.name }}</h1>
  
      <label for="description"> Beschreibung</label>
      <p id="description" [innerHTML]="product.description"></p>
  
      <button mat-raised-button class="btn_green" (click)="navigateToProduct()">
        Details
      </button>
  
      <button mat-raised-button style="margin: 0.5vw;" class="btn_green" (click)="createContainer()">
        Deploy
      </button>
    </div>
  <mat-spinner *ngIf="!product"></mat-spinner>
  