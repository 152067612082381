<div class="window" style="display: grid;">
    <h1 style="margin-left: 2vh; width: max-content; font-variant: small-caps;"> Title : {{ process?.name }} - Aufgabenliste</h1>
    <div style="display: inline-flex; justify-content: space-between;">
      <h2 style="margin-left: 2vh; font-variant: small-caps;"> ID: {{ process?.id }}</h2>
      <button class="button-17" (click)="restartProcess()" role="button" appearance="primary">Starten </button>
    </div>
    <app-bpmn-diagram [xml]="bpmnXml"></app-bpmn-diagram>
    <div> 
      <div *ngIf="serviceTasks.length > 0" style="display: inline-flex; flex-wrap: wrap;">
        <div *ngFor="let task of serviceTasks; let i = index" class="service-card" style="margin: 1em;">
          <h1>{{ removeLastWord(task.name) }}</h1>
          <h3 class="mb-6">Task-ID: {{ task.id }}</h3>
          <div class="card-container" style="display: flex; justify-content: space-evenly;">
            <div class="filesCard" style="margin-right: 10px;">
              <h4>Zieleingabe Daten</h4>
              <h5 *ngFor="let input of task.dataAssociations?.sourceRef">{{ input }}</h5>
            </div>
            <div class="filesCard" style="margin-left: 10px;">
              <h4>Zielausgabe Daten </h4>
              <h5 *ngFor="let output of task.dataAssociations?.targetRef">{{ output }}</h5>
            </div>
          </div>
          <div *ngIf="task.readyState === 'ready'" >
            <div class="animation-container">
              <div class="dot"></div>
            </div>
        </div>
        <div *ngIf="task.readyState === 'start'" class="waiting-animation">
      </div>
      <div *ngIf="task.readyState === 'failed'" style="position: relative;">
        <button class="button-17" style=" display: block" role="button" appearance="secondary" 
        (click)="performActionOnTasks(task)" [disabled]="i > 0 && serviceTasks[i - 1].readyState !== 'finished'">
        Dienst neu Starten
        </button>

        <ng-container *ngFor="let file of task.dataAssociations.sourceRef; let i = index">
          <div class="card-body">
            <div style="display: inline-flex;
                  flex-direction: row;
                  align-items: baseline;
                  width: 100%;">
              <h4 style="margin-right: 5vw;"> Bitte laden Sie die Eingabedatei hoch:</h4>
              <h5> {{ getFileName(file)}} </h5>
            </div>
            <app-file-uploader [fileName]="getFileName(file)" ></app-file-uploader>
          </div>
        </ng-container>
        <div class="error"></div>   
      </div>
          <div *ngIf="task.readyState === 'finished'">
            <div class="content">
                <svg width="40" height="40">
                  <circle fill="none" stroke="#68E534" strokeWidth="2" cx="20" cy="20" r="19" strokeLinecap="round" transform="rotate(-90 20 20)" class="circle"></circle>
                  <polyline fill="none" stroke="#68E534" points="08,21.4 17.3,28.4 30.4,13.8" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" class="tick"></polyline>
                </svg>
              </div>
          </div>
          <div *ngIf="task.fetchError" class="error-message" style="color: red;">File fetch failed. Please restart the process.</div>
          <div *ngIf="task.message" class="filesCard" style="color: red; font-size: smaller;">API ERROR : {{ task.message }}</div>
          <div class="card-container" style="display: flex; justify-content: space-evenly;">
          <div *ngIf="hasFilesWithServiceId(inputFiles, task.serviceId)" class="filesCard" style="margin-left: 10px;">
            <h5 size="l">Eingabedatei</h5>
            <div *ngFor="let file of inputFiles">
              <ng-container *ngIf="file.serviceId === task.serviceId">
                <a href="#" (click)="downloadFile($event, file)" style="cursor: pointer;">{{ file.name }}</a>
              </ng-container>
            </div>
          </div>
       
          <div *ngIf="hasFilesWithServiceId(responseFiles, task.serviceId)" class="filesCard" style="margin-left: 10px;">
            <h5 size="l">API-Antwort – Ausgabedaten</h5>
            <div *ngFor="let file of responseFiles">
              <ng-container *ngIf="file.serviceId === task.serviceId">
                <a href="#" (click)="downloadFile($event, file)"style="cursor: pointer;">{{ file.name }}</a>
              </ng-container>
            </div>
          </div>
          <div *ngIf="hasFilesWithServiceId(extractedFiles, task.serviceId)" class="filesCard" style="margin-left: 10px;">
            <h5 size="l">ZIP-Extrahierungsdateien</h5>
            <div *ngFor="let file of extractedFiles">
              <ng-container *ngIf="file.serviceId === task.serviceId">
                <a href="#" (click)="downloadFile($event, file)" style="cursor: pointer;">{{ file.name }}</a>
              </ng-container>
            </div>
          </div>
        </div>
          <button class="button-17" role="button" appearance="secondary" 
                  (click)="performActionOnTasks(task)" [disabled]="i > 0 && serviceTasks[i - 1].readyState !== 'finished'">
                  Dienst Starten
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-websocket (socketReady)="handleSocketReady($event)" #websocket></app-websocket>
  
