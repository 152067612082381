import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanReadableTime'
})
export class HumanReadableTime implements PipeTransform {

  transform(timestamp: string, args?: any): String {
    let hrt: string = "";
    let regexStr = timestamp.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+)?|\.[0-9]+/g);
    hrt = regexStr[2] + "." + regexStr[1] + "." + regexStr[0];
    return hrt;

  }

}
