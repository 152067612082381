import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-star",
  templateUrl: "./star.component.html",
  styleUrls: ["./star.component.css"],
})
export class StarComponent implements OnInit {
  starArray=[1, 2, 3, 4, 5];
  maxRating=5;

  @Input() rating: number;
  @Input() ratingCount?: number;
  @Input() bigger?: boolean;
  @Input() veryBig?: boolean;
  @Output() starClickEmitter = new EventEmitter<number>();
  @Output() starHoverEmitter = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {
  }

  clickOnStar(star: number): void {
    this.starClickEmitter.emit(star);
  }

  hover(star: number): void {
    this.starHoverEmitter.emit(star);
  }

  leave(): void {
    this.starHoverEmitter.emit(0);
  }


  get getRatingInPercent(): number{
    return (this.rating/this.maxRating)*100;
  }
}
