

import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";

import { ProductService } from '../services/dataServices/product-service/productService';
import { OrganisationService } from '../services/dataServices/organisation-service/organisationservice';

@Injectable()
export class MarketplaceProductResolver implements Resolve<any> {
  constructor(
    private productService : ProductService,
    private organisationService : OrganisationService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
 
    let product = parseInt(route.paramMap.get("productId"));
   
    // This prepares the Product Detail display 
    await this.productService.setActiveProduct(product);
    await this.organisationService.setActiveItem(this.productService.activeItem.company)
    
    }
}
