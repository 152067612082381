<!--TODO Eigene Hexagon Komponente auslagern-->
<div class="hexagon outer" [ngClass]="outerColor" [class.hover]="hover">
  <div
    *ngIf="!img"
    [ngClass]="{
      'custom-image-outer': isCustomImage && !belongsToProductCategory,
      'custom-image-outer-belongs-to-ptg':
        isCustomImage && belongsToProductCategory
    }"
    [ngStyle]="{
      'background-image':
        isCustomImage && belongsToProductCategory && getCompatiblityImage()
    }"
  ></div>
  <div
    [ngClass]="{
      'custom-img': isCustomImage && !belongsToProductCategory,
      'custom-img-belongs-to-ptg': isCustomImage && belongsToProductCategory,
      'img-composer-item': !isCustomImage && composerItem
    }"
    [ngStyle]="{ 'background-image': getImg() }"
  ></div>
  <div class="hexagon inner" [ngClass]="middleColor">
    <div
      *ngIf="innerColor; else noInner"
      class="hexagon inner2"
      [ngClass]="innerColor"
    >
      <div *ngIf="icon" [ngSwitch]="icon">
        <div *ngSwitchCase="'plus'">
          <div class="stripe"></div>
          <div class="stripe"></div>
        </div>
        <div *ngSwitchCase="'edit'">
          <div class="pencil">
            <div class="mine"></div>
            <div class="pen"></div>
          </div>
        </div>
        <div *ngSwitchCase="'minus'">
          <div class="stripe"></div>
        </div>
        <div *ngSwitchDefault>
          <div class="svg-icon" [ngClass]="icon"></div>
        </div>
      </div>
      <app-secure-image
        *ngIf="!icon && img && !hasNoImage"
        [src]="getImg()"
        [fileId]="imageId"
      ></app-secure-image>
    </div>
    <ng-template #noInner>
      <div *ngIf="icon" [ngSwitch]="icon">
        <div *ngSwitchCase="'plus'">
          <div class="stripe"></div>
          <div class="stripe"></div>
        </div>
        <div *ngSwitchCase="'edit'">
          <div class="pencil">
            <div class="mine"></div>
            <div class="pen"></div>
          </div>
        </div>
        <div *ngSwitchCase="'minus'">
          <div class="stripe"></div>
        </div>
        <div *ngSwitchDefault>
          <div class="svg-icon" [ngClass]="icon"></div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
