<div class="grid">
<!--<div class="item down">-->
  <!-------------------------------------------------------------------->
  <app-hmtl-display
    style="overflow-y: auto; margin-bottom: 1rem;"
    *ngIf="organisation"
    [text]="this.organisation.description"
  ></app-hmtl-display>
  <!-------------------------------------------------------------------->
  <app-detail-display-adress-display
    *ngIf="organisation"
    [address]="organisation.address"
    [name]="organisation.organisationName"
  >
  </app-detail-display-adress-display>
  <!-------------------------------------------------------------------->
  <app-detail-display-contact-display
    *ngIf="organisation"
    [contact]="organisation.contacts[0]"
  ></app-detail-display-contact-display>

  <!-------------------------------------------------------------------->
  <div class="item">
    <button
      mat-raised-button
      class="btn_green"
      (click)="navigateToMarketplace()"
    >
      Produkte des Anbieters
    </button>

    <button mat-raised-button
    class="btn_green"
    (click)="navigateToOrganisationPage()"
    >
      Zur Anbieterseite
    </button>
    <!-------------------------------------------------------------------->
  </div>
</div>
