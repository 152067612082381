import { Pipe, PipeTransform } from '@angular/core';
import { OrganisationInterface } from '../model/organisations/organisation-interface';

@Pipe({
  name: 'filterOrganisationSearch'
})
export class FilterOrganisationSearchPipe implements PipeTransform {

  transform(organisations: OrganisationInterface[], searchText: string): OrganisationInterface[] {
    if (searchText.length === 0 || searchText[0] == undefined || searchText[0] === "") {
      return organisations;
    }

    searchText = searchText[0].toLowerCase();

    return organisations.filter(org => {
      return org.organisationName.toLowerCase().includes(searchText);
    })
  }

}
