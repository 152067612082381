import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";

@Injectable({
  providedIn: "root",
})
export class CertificationDtoConverterService {
  constructor(private activeRoute: ActivatedRoute) {}

  /**
   * Iterates through all variants of certification and transforms them to dto
   * @param certification Certification to convert to dto
   */
  convertToCertificationDto(
    certification: CertificationInterface
  ): CertificationInterface {
    for (const variant of certification.certificationVariants) {
      this.convertToVariantDto(variant);
    }
    return certification;
  }

  /**
   * Iterates through all versions of variant and transforms them to dto
   * @param variant variant to convert to dto
   */
  convertToVariantDto(
    variant: CertificationVariantInterface
  ): CertificationVariantInterface {
    for (const version of variant.certificationVariantVersions) {
      this.convertToVersionDto(version);
    }
    return variant;
  }

  /**
   * Iterates through version's PTGs and Attributes and adds them to dto
   * @param version Version to transform to dto
   */
  convertToVersionDto(
    version: CertificationVariantVersionInterface
  ): CertificationVariantVersionInterface {
    let attributeValidationValues = {};
    let ptgVersionIds = [];
    // Add PTG Versions to object
    for (const ptgVersion of version.ptgVersions) {
      ptgVersionIds.push(ptgVersion.id);
    }
    //TODO THIS SHOULD NOT BE HERE
    if (version.attributeValidationValues instanceof Array && version.attributeValidationValues !== undefined ) {
      for (let index = 0; index < version.attributeValidationValues.length; index++) {
        const attributValidationValue = version.attributeValidationValues[index];
        if (attributValidationValue != null) {
          if (attributValidationValue.attribute) {
            attributValidationValue.attribute.validations.forEach(validation => {
              attributeValidationValues[validation.id] = attributValidationValue.value;
            });
          } else {
            attributeValidationValues[index] = attributValidationValue;
          }
        }
      }
    } else {
      for (const attributeValue in version.attributeValidationValues) {
        attributeValidationValues[attributeValue] = version.attributeValidationValues[attributeValue];
      }
    }
    version.ptgVersionIds = ptgVersionIds;
    version.attributeValidationValues = attributeValidationValues;
    return version;
  }

  getRedirectUrl(): string {
    return (
      "organisations/" +
      this.activeRoute.firstChild.snapshot.params.organisationId +
      "/certifications/"
    );
  }
}
