import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { Subscription } from "rxjs";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { Router } from "@angular/router";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { UtilService } from "src/app/services/util-service/utilService";
import { CertificationVariantService } from "src/app/services/dataServices/certification-variant-service/certification-variant-service";
import { CertificationVariantVersionService } from "src/app/services/dataServices/cetrification-variant-version-service/certification-variant-version-service";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-certification-variant-screen",
  templateUrl: "./certification-variant-screen.component.html",
  styleUrls: ["./certification-variant-screen.component.css"],
})
export class CertificationVariantScreenComponent implements OnInit, OnDestroy {
  public activeVersion: CertificationVariantVersionInterface;
  public activeVersionPtgs: string[] = [];

  public doneLoading = false;

  private activeItemSub: Subscription;
  private certificationListSub: Subscription;
  private routerSubscription: Subscription;

  constructor(
    public certificationService: CertificationService,
    public router: Router,
    public organisationService: OrganisationService,
    public ptgService: PtgService,
    public utilService: UtilService,
    public variantService: CertificationVariantService,
    public versionService: CertificationVariantVersionService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private titleService: Title,
  ) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  // Route to cert var edit
  private routeEditVariant(): void {
    this.router.navigate([
      "organisations/" +
        this.organisationService.activeItem.organisationId +
        "/certifications/" +
        this.certificationService.activeItem.id +
        "/variants/" +
        this.variantService.activeItem.id +
        "/edit",
    ]);
  }

  // When edit button is pressed route to var edit screen with activated version
  private editVariantVersionPress(versionId: number): void {
    this.versionService.ITEM_MAP[":certificationVersionId"] = String(versionId);
    this.versionService
      .setActiveVersion(versionId)
      .then(() => {
        var url =
          this.router.routerState.snapshot.url.toString() +
          "/version/" +
          versionId +
          "/edit";
        this.router.navigate([url]);
      })
      .catch(() => {});
  }

  // When add button is pressed route to var create screen
  private addVariantVersionPress(versionId: number): void {
    this.versionService.ITEM_MAP[":certificationVersionId"] = String(versionId);

    var url =
      this.router.routerState.snapshot.url.toString() + "/version/create";
    this.router.navigate([url, { previous: versionId }]);
  }

  private showPrevious(id: number): string {
    let v = this.versionService.collection.find((v) => {
      return v.id === id;
    });
    if (!v) {
      return "-";
    }
    return v.name;
  }

  private showInfoOfVersion(versionId: number): void {
    this.activeVersionPtgs = [];
    if (this.activeVersion && this.activeVersion.id === versionId) {
      this.activeVersion = null;
      return;
    }

    this.activeVersion = this.variantService.activeItem.certificationVariantVersions.filter(
      (version) => version.id === versionId
    )[0];
  }

  // Routes to new mask, where we can apply our certification to products
  private productCertification(certVersionId: number): void {
    let url =
      "organisations/" +
      this.organisationService.activeItem.organisationId +
      "/certifications/" +
      this.certificationService.activeItem.id +
      "/variants/" +
      this.variantService.activeItem.id +
      "/version/" +
      certVersionId +
      "/manage";
    this.router.navigateByUrl(url);
  }

  private deleteVariant() {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie die Variante wirklich löschen?",
        "Abbrechen",
        "Löschen"
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.variantService.ITEM_MAP[":organisationId"] = String(
            this.certificationService.activeItem.company
          );
          this.variantService.ITEM_MAP[":certificationId"] = String(
            this.certificationService.activeItem.id
          );

          this.variantService.ITEM_MAP[":certificationVariantId"] = String(
            this.variantService.activeItem.id
          );
          this.variantService
            .deleteItem(this.variantService.activeItem)
            .then(
              async () => {
                // On success, route to certification overview and reload organisationCerts

                await this.certificationService.loadCertificationsForOrganisation(
                  this.organisationService.activeItem
                );
                let url: string =
                  "/organisations/" +
                  this.variantService.ITEM_MAP[":organisationId"] +
                  "/certifications/" +
                  this.variantService.ITEM_MAP[":certificationId"];
                this.router.navigateByUrl(url);
                this.dialogService.openDialog(
                  "Variant gelöscht",
                  "Die Variante wurde erfolgreich gelöscht."
                );
              },
              () => {
                // On error, alert user
                this.dialogService.openDialog(
                  "Variant nicht gelöscht",
                  "Die letzte Variante einer Zertifizierung darf nicht gelöscht werden."
                );
              }
            )
            .catch((e) => {
              console.error(e);
            });
        }
      });
  }

  private async deleteVariantVersionPress(versionId: number) {
    await this.versionService.setActiveVersion(versionId);
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie die Version wirklich löschen?",
        "Abbrechen",
        "Löschen"
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.versionService
            .deleteItem(this.versionService.activeItem)
            .then(
              async () => {
                // On success, reload page
                this.dialogService.openDialog(
                  "Version gelöscht",
                  "Die Version wurde erfolgreich gelöscht."
                );
                this.activeVersion = null;
                this.cd.detectChanges();
              },
              () => {
                // On error, alert user
                this.dialogService.openDialog(
                  "Version nicht gelöscht",
                  "Die letzte Version einer Zertifizierungsvariante darf nicht gelöscht werden."
                );
              }
            )
            .catch((e) => {
              console.error(e);
            });
        }
      });
  }

  ngOnInit() {
    this.versionService.ITEM_MAP[":organisationId"] = String(
      this.organisationService.activeItem.organisationId
    );
    this.versionService.ITEM_MAP[":certificationId"] = String(
      this.certificationService.activeItem.id
    );
    this.versionService.ITEM_MAP[":certificationVariantId"] = String(
      this.variantService.activeItem.id
    );
    this.ptgService.itemCollectionObserver.subscribe(
      () => {},
      (e) => {
        this.dialogService.openDialog(e.errorTitle, e.errorText);
      }
    );

    this.certificationListSub = this.certificationService.itemCollectionObserver.subscribe(
      () => {
        if (this.variantService.activeItem) {
          this.cd.detectChanges();
        }
      }
    );

    this.activeItemSub = this.variantService.activeItemObserver.subscribe(
      () => {
        this.titleService.setTitle(this.certificationService.activeItem.name + " Variante: " + 
                                     this.variantService.activeItem.name + " - BIMSWARM");
        this.activeVersion = this.variantService.activeItem.certificationVariantVersions[0];
        this.cd.detectChanges();
      }
    );

    this.cd.detectChanges();
  }

  ngOnDestroy() {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
    if (this.activeItemSub) {
      this.activeItemSub.unsubscribe();
    }
    if (this.certificationListSub) {
      this.certificationListSub.unsubscribe();
    }
  }
}
