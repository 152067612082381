import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AttributeInterface } from "src/app/model/attributes/attribute-interface";
import { PtgAttributeValidationInterface } from "src/app/model/attributes/ptg-attribute-validation-interface";

@Component({
  selector: "attribute-select-table",
  templateUrl: "./attribute-select-table.component.html",
  styleUrls: ["./attribute-select-table.component.css"],
})
export class AttributeSelectTableComponent {
  @Input()
  public editable: boolean;

  @Input()
  public ptgAttributeValidations: PtgAttributeValidationInterface[];
  @Output() ptgAttributeValidationsChange: EventEmitter<
    PtgAttributeValidationInterface[]
  > = new EventEmitter<PtgAttributeValidationInterface[]>();

  public removeAttribute(attributeId: number) {
    this.ptgAttributeValidations = this.ptgAttributeValidations.filter(
      (attributeValidation) => {
        const attribute = attributeValidation.attribute;
        return attribute.id != attributeId;
      }
    );
    this.ptgAttributeValidationsChange.emit(this.ptgAttributeValidations);
  }

  public getSortedAttributeList(): AttributeInterface[] {
    let attributes: AttributeInterface[] = [];
    const optionalAttributes = [];
    const nonOptionalAttributes = [];
    for (const attributeValidation of this.ptgAttributeValidations) {
      const attribute = attributeValidation.attribute;
      if (attributeValidation.optionalValidation) {
        optionalAttributes.push(attribute);
      } else {
        nonOptionalAttributes.push(attribute);
      }
    }
    attributes = attributes.concat(
      this.sortAttributesAlphabetically(nonOptionalAttributes)
    );
    attributes = attributes.concat(
      this.sortAttributesAlphabetically(optionalAttributes)
    );
    return attributes;
  }

  private sortAttributesAlphabetically(
    attributes: AttributeInterface[]
  ): AttributeInterface[] {
    return attributes.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      let order = 0;
      if (nameA < nameB) {
        order = -1;
      } else if (nameB < nameA) {
        order = 1;
      }
      return order;
    });
  }

  public isAttributeOptional(attributeId: number): boolean {
    const ptgAttributeValidation = this.findPtgAttributeValidationByAttribute(
      attributeId
    );
    return ptgAttributeValidation.optionalValidation;
  }

  public onToggleOptional(optional: boolean, attributeId: number) {
    const changedPtgAttributeValidation = this.findPtgAttributeValidationByAttribute(
      attributeId
    );
    changedPtgAttributeValidation.optionalValidation = optional;
  }

  public findPtgAttributeValidationByAttribute(attributeId: number) {
    return this.ptgAttributeValidations.find((attributeValidation) => {
      const attribute = attributeValidation.attribute;
      return attribute.id == attributeId;
    });
  }
}
