<h2>Datenformate</h2>
<a mat-button routerLink="/profil/dataformats/add" class="btn_green">
  Datenformat erstellen
</a>
<div>
  <table mat-table [dataSource]="dataSource" class="full-width data-table">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row">{{ row.dataFormat.name }}</td>
    </ng-container>

    <ng-container matColumnDef="showColumn">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-button class="btn_green">Anzeigen</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="editColumn">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <a
          routerLink="/profil/dataformats/edit/{{ row.dataFormat.id }}"
          mat-button
          >Bearbeiten</a
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let detail">
        <div>{{ detail.dataFormat.name }}</div>
        {{ detail.dataFormat.version }}
        {{ detail.dataFormat.description }}
        Aktiv: {{ detail.dataFormat.active }}
      </mat-cell>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.expanded]="expandedElement == row.dataFormat.id"
      class="element-row"
      (click)="expandedElement = row.dataFormat.id"
    ></tr>
    <tr
      mat-row
      *matRowDef="
        let row;
        columns: ['expandedDetail'];
        when: isExpansionDetailRow
      "
      [@detailExpand]="
        row.dataFormat.id == expandedElement ? 'expanded' : 'collapsed'
      "
      style="overflow: hidden"
      class="detail-row"
      [class.expanded]="expandedElement == row.dataFormat.id"
    ></tr>
  </table>
</div>
