import { Component, OnInit } from "@angular/core";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { SidemenuServiceService } from "src/app/services/componentServices/sidemenu-service/sidemenu-service.service";
import { Router, ActivatedRoute } from "@angular/router";
import { HeaderServiceService } from "src/app/services/header-service/header-service.service";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-certification-no-content-screen",
  templateUrl: "./certification-no-content-screen.component.html",
  styleUrls: ["./certification-no-content-screen.component.css"],
})
export class CertificationNoContentScreenComponent implements OnInit {

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    private headerService: HeaderServiceService,
    private organisationService: OrganisationService,
    private userService: UserService
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnInit() {

    if (this.organisationService && this.organisationService.activeItem) {
      this.headerService.loadHeaderText(
        this.organisationService.activeItem.organisationName
      );
    }
  }
}
