<!------------------- User Icon / Image ------------------------------------->
<!--------------------------------------------------------------------------->
<div class="comment_wrapper">
  <!--------------------------------------------------------------------------->
  <div class="down" style="width: 100%">
    <!-------------------------------------------------------------->
    <!--------------------------------------------------->
    <div class="down">
      <ng-container *ngFor="let comment of review.comments">
        <!--------------------------------------->
        <div class="comment_text_field">
          {{ this.comment.text }}
          <ng-container *ngIf="orgaAdmin"
            ><button
              mat-icon-button
              (click)="deleteComment(review.reviewId, comment.commentId)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>
        </div>
        <div class="timestamp_field">
          {{ comment.timestamp | humanReadableDate }}
        </div>
      </ng-container>
      <!--div class="comment_text_field no_comment"  *ngIf="review.comments.length == 0">
        Für diese Bewertung existieren noch keine Kommentare.
      </div-->
    </div>
    <!----------------------------------------------------->
    <!--------------------Add Comment Box ----------------->
    <!----------------------------------------------------->
    <!--div>
      <button
        mat-raised-button
        class="btn_green"
        id="createReview"
        (click)="showWriteComment = true"
        *ngIf="this.loggedIn && this.loggedIn.userId !== 0"
      >
        Kommentar hinzufügen
      </button>
      <div class="write-comment" *ngIf="showWriteComment">
        <app-write-comment
          [reviewId]="this.review.reviewId"
          (commentAddEmitter)="
            handleCommentAdd($event); showWriteComment = false
          "
          (commentCancelEmitter)="showWriteComment = false"
        ></app-write-comment>
      </div>
    </div-->
  </div>
</div>
