import { OrganisationOverview } from './../../dataSource/organisationDataSource';
import { OrganisationService } from './../../services/dataServices/organisation-service/organisationservice';
import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delegate-certification',
  template: `
  <h1 mat-dialog-title>{{data.title}}</h1>
    <div>
      <li *ngFor="let delegatedOrga of data.certificationOffices" style="margin: 0.5em; font-size: 16px; color: grey;"> {{ delegatedOrga.organisationName }} </li>
    </div>
    <h3 style="margin-left: 0.5em;"> mit der Zertifizierung von 
      <b>{{data.attributeOrPtg}}</b> 
      beauftragen? </h3>
    <div>
      <button mat-button (click)="onClick(false)" class="btn_red" style="float: left" cdkFocusInitial>Abbrechen</button>
      <button mat-button (click)="onClick(true)" class="btn_green" style="float: right" cdkFocusInitial>Senden</button>
    </div>
`,
  styleUrls: ['./dialog.css'],
})
export class DialogConfirmDelegateCertificationComponent{
  certificationOffices: OrganisationOverview[] = [];
  delegatedTo: OrganisationOverview;

  constructor(
    private organisationService: OrganisationService,
    public dialogRef: MatDialogRef<DialogConfirmDelegateCertificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClick(confirm: boolean): void{
    this.dialogRef.close(confirm);
  }
}
