import { Component, OnDestroy, OnInit } from "@angular/core";
import {ActivatedRoute, Data, Router} from "@angular/router";
import { VariantVersionInterface } from "src/app/model/products/variant-version-interface";
import { AttributeValidationServiceService } from "src/app/services/dataServices/dataValidation/attribute-validation-service/attribute-validation-service.service";
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { ProductVariantService } from "src/app/services/dataServices/product-variant-service/product-variant.service";
import { ProductVariantVersionService } from "src/app/services/dataServices/product-variant-version-service/product-variant-version.service";
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-product-variant-version-create-screen",
  templateUrl: "./product-variant-version-create-screen.component.html",
  styleUrls: ["./product-variant-version-create-screen.component.css"],
})
export class ProductVariantVersionCreateScreenComponent implements OnInit, OnDestroy {
  public version: VariantVersionInterface;
  public isCreateNew = false;
  public isFormValid = false;

  constructor(
    private organisationService: OrganisationService,
    private productService: ProductService,
    public productVariantService: ProductVariantService,
    private productVariantVersionService: ProductVariantVersionService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    public attributeValidationService: AttributeValidationServiceService,
    private userService: UserService
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }}

  ngOnInit(): void {
      this.version = {};
      // set flag for create or edit mode
      this.isCreateNew = this.router.url.includes("create");
      if (this.isCreateNew) {
        const previousVersionId = parseInt(this.route.snapshot.params['versionId']);
        this.version.previousVersion = previousVersionId;
      } else {
        const currentVersion = this.productVariantVersionService.activeItem;
        this.version.id = currentVersion.id;
      }
      if (!this.isCreateNew) {
        this.isFormValid = true;
      }
  }

  public cancelCreateVersion(): void {
    this.router.navigate([
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/products/" +
        this.productService.activeItem.id +
        "/variants/" +
        this.productVariantService.activeItem.id,
    ]);
  }

  ngOnDestroy() : void {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }

  public createVersion(): void {
    //If at least one attribute not valid
    if (!this.isInputValid()) {
      this.dialogService.openDialog(
        "Speichern Fehlgeschlagen",
        "Bitte validieren sie alle Attribute!"
      );
      return;
    }

    // if this version has either no name or status (active / inactive)
    if (!this.isFormValid) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte geben Sie den Versionsnamen und -status an!"
      );
      return;
    }
    if (this.version.description == null) {
      this.version.description == "";
    }
    this.version.attributeValidationValues = this.attributeValidationService.getAttributeValidationValues();

    if (this.isCreateNew) {
      this.productVariantVersionService
        .postItem(this.version)
        .then((v) => {
          let url = this.router.url.split("/version/")[0];
          this.router.navigateByUrl(url);
          this.dialogService.openDialog(
            "Version erstellt",
            "Die Version " + v.name + " wurde erfolgreich erstellt."
          );
        })
        .catch((e) => {});
    } else {
      this.productVariantVersionService
        .putItem(this.version)
        .then((v) => {
          let url = this.router.url.split("/version/")[0];
          this.router.navigateByUrl(url);
          this.dialogService.openDialog(
            "Version bearbeitet",
            "Die Version " + v.name + " wurde erfolgreich bearbeitet."
          );
        })
        .catch((error) => {
          this.dialogService.openDialog(error, error);
        });
    }
  }

  public isInputValid(): boolean {
    return this.attributeValidationService.checkAllEntityAttributeValuesValidated();
  }

  public onValidityChanged(valid: boolean) {
    this.isFormValid = valid;
  }
}
