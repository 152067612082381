import { Component, OnInit, Input } from "@angular/core";
import { ProductService } from "../../../services/dataServices/product-service/productService";
import { OrganisationService } from "../../../services/dataServices/organisation-service/organisationservice";

@Component({
  selector: "app-add-product-dependency-panel",
  templateUrl: "./add-product-dependency-panel.component.html",
  styleUrls: ["./add-product-dependency-panel.component.css"],
})
export class AddProductDependencyPanelComponent implements OnInit {
  @Input()
  product;

  @Input()
  isCreate: boolean;

  addProductDependencyMode: boolean = false;

  constructor(
    public productService: ProductService,
    public organisationService: OrganisationService
  ) {}

  addProductDependencyModeToggle(value: boolean): void {
    this.addProductDependencyMode = value;
  }

  addProductDependency(event, selectBox): void {
    let productId: number = parseInt(event.value);
    this.product.relatedProducts = this.product.relatedProducts.concat(
      productId
    );
    selectBox.value = "";
  }

  checkIfNoProducts(): boolean {
    return this.productService.collection.length === 0;
  }

  ngOnInit() {}
}
