import { Pipe, PipeTransform } from '@angular/core';
import { ProductInterface } from '../model/products/product-interface';

@Pipe({ name: 'productsOfPtg' })
export class FilterProductsOfPtg implements PipeTransform {
  transform(products: ProductInterface[], ptg: string[]) {
    if (!ptg || ptg.length == 0) {
      return products;
    }

    const filteredProducts = [];

    for (const product of products) {
      for (const variant of product.productVariants) {
        for (const version of variant.variantVersions) {
          for (const versionPtgVersion of version.ptgVersions) {
            if (ptg.find(version => version == versionPtgVersion.id.toString())) {
              if (filteredProducts.indexOf(product) === -1) {
                filteredProducts.push(product);
              }
            }
          }
        }
      }
    }

    return filteredProducts;
  }
}
