import { Component, Input, OnInit } from '@angular/core';
import { CertificationRequestLogInterface } from 'src/app/model/certifications/certification-request-log-interface';

@Component({
  selector: 'app-certification-request-log-entry',
  templateUrl: './certification-request-log-entry.component.html',
  styleUrls: ['./certification-request-log-entry.component.css']
})
export class CertificationRequestLogEntryComponent implements OnInit {

  @Input()
  public logEntry: CertificationRequestLogInterface;
  
  constructor() { }

  ngOnInit() {
  }

}
