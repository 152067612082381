<div class="two-columns">
  <div>
    <h2 *ngIf="!isEdit">Dokumententyp anlegen</h2>
    <h2 *ngIf="isEdit">Dokumententyp bearbeiten</h2>
    <form [formGroup]="form">
      <div class="auto-min">
        <mat-form-field>
          <input
            matInput
            placeholder="Name"
            formControlName="name"
            class="full-width"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-select
            placeholder="Status"
            formControlName="active"
            class="full-width"
          >
            <mat-option [value]="true">Aktiv</mat-option>
            <mat-option [value]="false">Inaktiv</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!--Update to Angular 9: changed [(editorText)] to [editorText] bc form.controls.description.value was marked as readonly in console error. Needs testing-->
      <app-text-editor
        [editorText]="form.controls.description.value"
        (editorTextChange)="onDescriptionTextChange($event)"
        class="full-width"
      ></app-text-editor>
    </form>
    <h2>Zulässige Datenformate</h2>
    <!--Schemas-->
    <mat-table class="full-width" [dataSource]="selectedSchemas">
      <ng-container matColumnDef="dataFormatName">
        <mat-header-cell *matHeaderCellDef>Datenformat</mat-header-cell>
        <mat-cell *matCellDef="let element">{{
          element.dataFormat.name
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="version">
        <mat-header-cell *matHeaderCellDef>Version</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.version }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="subSchemaName">
        <mat-header-cell *matHeaderCellDef>Subschema</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.subSchema }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            class="btn_red"
            (click)="handleRemoveSchemaClick(element.id)"
          >
            <i class="fa fa-times"></i>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="[
          'dataFormatName',
          'name',
          'version',
          'subSchemaName',
          'actions'
        ]"
      ></mat-header-row>
      <mat-row
        *matRowDef="
          let row;
          columns: [
            'dataFormatName',
            'name',
            'version',
            'subSchemaName',
            'actions'
          ]
        "
      ></mat-row>
    </mat-table>
    <button mat-button class="btn_green" (click)="toggleAddSchema()">
      Datenformat hinzufügen
    </button>
    <ng-container *ngIf="showAddSchemaForm">
      <app-add-schema-form [selectedSchemaIds]="selectedSchemaIds" [allSchemas]="allSchemas" (onSave)="onSchemaSave($event)"></app-add-schema-form>
    </ng-container>

    <div class="button-row">
      <button mat-button class="btn_green" (click)="save()">Speichern</button>
      <button mat-button (click)="handleCancelClick()">Abbrechen</button>
    </div>
  </div>
</div>
