import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ComposerToolchainTemplateOverview } from 'src/app/model/composer/composer-toolchain-overview-interface';
import { ComposerService } from 'src/app/services/composer-service/composer.service';
import { UserService } from 'src/app/services/user-service/userservice';
import {ComposerToolchainInstanceOverview} from '../../../../model/composer/composer-toolchain-instance-overview';

@Component({
  selector: 'app-toolchain-template-table',
  templateUrl: './toolchain-template-table.component.html',
  styleUrls: ['./toolchain-template-table.component.css']
})
export class ToolchainTemplateTableComponent implements OnInit {

  @Input()
  public toolchainTemplates: ComposerToolchainTemplateOverview[];

  @Input()
  public toolchainInstances: ComposerToolchainInstanceOverview[];

  @Output() deleteTemplateEvent = new EventEmitter<number>();

  @Output() deleteToolchainEvent = new EventEmitter<number>();

  @Output() reloadToolchainsAndTemplatesEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  deleteToolchainTemplate(templateId: number) {
    this.deleteTemplateEvent.emit(templateId);
  }

  deleteToolchain(toolchainId: number) {
    this.deleteToolchainEvent.emit(toolchainId);
  }

  reloadToolchainsAndTemplates() {
    this.reloadToolchainsAndTemplatesEvent.emit();
  }

}
