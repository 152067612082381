import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ErrorStateMatcher } from "@angular/material/core";
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  FormControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { CryptoService } from "src/app/services/crypto-service/crypto.service";
import { UserInterface } from "src/app/model/user/user-interface";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { HeaderServiceService } from "src/app/services/header-service/header-service.service";
import { UserService } from "src/app/services/user-service/userservice";
import { UtilService } from "src/app/services/util-service/utilService";
import { GENDERS } from 'src/environments/constants';
import { VALIDATORS } from 'src/environments/validators';

// self-defined ErrorStateMatcher - required to validate password-confirm
export class registryErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control.value !== control.parent.value.password && control.parent.invalid
    );
    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: "app-registry-page",
  templateUrl: "./registry-page.component.html",
  styleUrls: ["./registry-page.component.css"],
})
export class RegistryPageComponent implements OnInit {
  @ViewChild("passwordField") private passwordField: ElementRef;
  @ViewChild("passwordFieldConfirm") private passwordFieldConfirm: ElementRef;

  public arePasswordRulesVisible: boolean = false;
  // FromGroup of registry form
  registryForm: FormGroup;

  // ErrorStateMatcher - Class defined above
  matcher = new registryErrorStateMatcher();

  // controlls of registry form
  email: AbstractControl;
  gender: AbstractControl;
  title: AbstractControl;
  firstname: AbstractControl;
  lastname: AbstractControl;
  password: AbstractControl;
  confirmpassword: AbstractControl;
  organization: AbstractControl;
  website: AbstractControl;
  position: AbstractControl;

  code: String;
  inviteCode: string;
  invitedUserName: string = "";
  registerClicked: boolean = false;
  registerWithInvite: boolean = false;

  // user object to handle registry easier
  private newUser: UserInterface = {
    userName: "",
    password: "",
    firstName: "",
    lastName: "",
    gender: "",
    title: "",
   organisationDto: {organisationName:"", website:"", address: {city: "", postalCode:"", street:""}}
  };

  rsaEncrypt;

  @Input()
  providers;

  public genders = GENDERS;

  constructor(
    private router: Router,
    private userservice: UserService,
    private dialogService: DialogService,
    private utilService: UtilService,
    private headerService: HeaderServiceService,
    private route: ActivatedRoute,
    private cryptoService: CryptoService,
    fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    // building registryForm and adding validators
    this.registryForm = fb.group(
      {
        email: ["", VALIDATORS.email],
        gender: [""],
        title: [""],
        firstname: ["", VALIDATORS.firstname],
        lastname: ["", VALIDATORS.lastname],
        password: ["", VALIDATORS.password],
        confirmpassword: [""],
        organization: ["", VALIDATORS.organization],
        website: ["", VALIDATORS.website],
        position: ["", VALIDATORS.position]
      },
      { validator: VALIDATORS.registryForm }
    );
    this.email = this.registryForm.controls["email"];
    this.gender = this.registryForm.controls["gender"];
    this.title = this.registryForm.controls["title"];
    this.firstname = this.registryForm.controls["firstname"];
    this.lastname = this.registryForm.controls["lastname"];
    this.password = this.registryForm.controls["password"];
    this.confirmpassword = this.registryForm.controls["confirmpassword"];
    this.organization = this.registryForm.controls["organization"];
    this.website = this.registryForm.controls["website"];
    this.position = this.registryForm.controls["position"];

    this.renderer.listen("window", "click", (e: Event) => {
      if (
        e.target === this.passwordField.nativeElement ||
        this.passwordField.nativeElement.contains(e.target)||   e.target === this.passwordFieldConfirm.nativeElement ||
          this.passwordFieldConfirm.nativeElement.contains(e.target)
      ) {
        this.arePasswordRulesVisible = true;
      } else {
        this.arePasswordRulesVisible = false;
      }
      this.cd.detectChanges();
    });
  }

  showAgb() {
    this.dialogService
      .openAgbDialogInform(
        "Schließen"
      );

  }
  showPrivacy() {
    this.dialogService
      .openPrivacyDialogInform(
          "Schließen"
      );

  }

  // on registry press
  onSubmit() {
    this.registerClicked = true;
    if (
      this.utilService.isStringNullOrEmpty(this.firstname.value) ||
      this.utilService.isStringNullOrEmpty(this.lastname.value) ||
      this.utilService.isStringNullOrEmpty(this.email.value) ||
      this.utilService.isStringNullOrEmpty(this.password.value) ||
      this.password.value != this.confirmpassword.value ||
      this.utilService.isStringNullOrEmpty(this.organization.value)) {
      this.dialogService.openDialog(
        "Es ist ein Fehler aufgetreten",
        "Bitte füllen Sie alle Felder korrekt aus"
      );
      this.registerClicked = false;
      return false;
    }

    this.newUser.userName = this.email.value.toLowerCase();
    this.newUser.gender = this.gender.value;
    this.newUser.title = this.title.value;
    this.newUser.jobDescription = this.position.value;
    this.newUser.employer = this.organization.value;
    this.newUser.firstName = this.firstname.value;
    this.newUser.lastName = this.lastname.value;
    this.newUser.organisationDto.organisationName = this.organization.value;
    this.newUser.organisationDto.website = this.website.value;
    this.newUser.organisationDto.address.city = this.position.value;
    this.newUser.password = this.cryptoService.encrypt(this.password.value);
    if (this.code) {
      this.registerWithCode();
    } else {
      this.registerWithoutCode();
    }
  }

  registerWithCode() {
    this.userservice.registerWithCode(this.newUser, this.code).subscribe(
      (_) => {
        const modalResult = this.dialogService.openDialog(
          "Email Bestätigung",
          `Ein Bestätigungslink wurde an die E-Mail-Adresse ${this.newUser.userName} versandt. Überprüfen Sie Ihr Postfach und befolgen Sie die Anweisungen in der E-Mail.`
        );
        modalResult.subscribe((_) => {
          this.router.navigate(["login"]);
        });
      },
      () => {
        this.registerClicked = false;
      }
    );
  }

  registerWithoutCode() {
    let _outerThis = this;
    this.userservice.register(this.newUser).subscribe(
      (_) => {
        if (_outerThis.registerWithInvite !== true) {
          const modalResult = this.dialogService.openDialog(
            "Email Bestätigung",
            `Ein Bestätigungslink wurde an die E-Mail-Adresse ${this.newUser.userName} versandt. Überprüfen Sie Ihr Postfach und befolgen Sie die Anweisungen in der E-Mail.`
          );
          modalResult.subscribe((_) => {
            this.router.navigate(["login"]);
          });
        } else {
          this.router.navigate(["login"]);
        }
      },
      () => {
        this.registerClicked = false;
      }
    );
  }

  checkIfUserAlreadyInvited() {
    if (this.checkForInviteCode()) {
      this.userservice.checkIfUserHasBeenInvited(this.inviteCode).subscribe(
        (userName) => {
          this.registerWithInvite = true;
          this.invitedUserName = userName;
          this.registryForm.patchValue({
            email: this.invitedUserName,
          });
          this.registryForm.get("email").disable();
        },
        () => {
          this.registerWithInvite = false;
        }
      );
    } else {
      this.registryForm.patchValue({
        email: "",
      });
    }
  }

  checkForInviteCode(): boolean {
    // relativer check statt festgelegter substring?
    let result: boolean;
    let inviteCodePattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    let url = this.router.url;
    let submittedCode = url.substring(10, 46);
    if (submittedCode != "" && inviteCodePattern.test(submittedCode)) {
      this.inviteCode = submittedCode;
      result = true;
    } else {
      this.registerWithInvite = false;
      this.inviteCode = "";
      result = false;
    }
    return result;
  }

  ngOnInit() {
    this.checkIfUserAlreadyInvited();
    this.route.queryParams.subscribe((params) => {
      this.code = params["code"];
    });
    this.headerService.loadHeaderText("BIMSWARM");
  }

  public onCancel(): void {
    this.router.navigate([""]);
  }
}
