import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { OrganisationService } from "../../../../services/dataServices/organisation-service/organisationservice";
import { UserService } from "../../../../services/user-service/userservice";
import { OrganisationMemberInterface } from "../../../../model/organisations/organisationMember-interface";
import { OrganisationInterface } from "../../../../model/organisations/organisation-interface";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";

const INVITES: OrganisationMemberInterface[] = [];

@Component({
  selector: "app-organisation-user",
  templateUrl: "./organisation-user.component.html",
  styleUrls: ["./organisation-user.component.css"],
})
export class OrganisationUserComponent implements OnInit, OnDestroy {
  components = [];
  private hasContacts: boolean;
  private openMembersMode: boolean = false;
  private inviteMemberMode: boolean = false;
  private transmitMemberMode: boolean = false;
  private enableSendBtn: boolean = true;
  private messageTransmitResponse: String = "";
  private memberRolesChangedList = {};

  private showOrganisationContacts: boolean = false;

  private userToInvite: OrganisationMemberInterface;

  private activeItemSubscription: Subscription;

  private message: String;

  columnsToDisplay = ["email", "role", "remove"];

  dataSource = new MatTableDataSource(INVITES);

  constructor(
    public organisationService: OrganisationService,
    private dialogService: DialogService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  //TODO Type of Element?
  changeMemberRole(event, userId: number): void {
    this.memberRolesChangedList[userId] = event.value;
  }

  //----------------------------------------------------------------------------------------
  //#region TOGGLES
  //----------------------------------------------------------------------------------------

  openMembersModeToggle(): void {
    this.inviteMemberMode = false;
    this.transmitMemberMode = false;
    this.showOrganisationContacts = false;
    this.openMembersMode = !this.openMembersMode;
    this.cd.detectChanges();
  }

  inviteMembersModeToggle(): void {
    this.openMembersMode = false;
    this.transmitMemberMode = false;
    this.showOrganisationContacts = false;
    this.inviteMemberMode = !this.inviteMemberMode;
    INVITES.length = 0;
  }

  transmitMembersModeToggle(): void {
    this.messageTransmitResponse = "";
    this.openMembersMode = false;
    this.inviteMemberMode = false;
    this.showOrganisationContacts = false;
    this.transmitMemberMode = !this.transmitMemberMode;
    this.cd.detectChanges();
  }

  uploadMemberListModeToggle(): void {
    this.inviteMemberMode = false;
    this.openMembersMode = false;
  }

  organisationContactsToggle(): void {
    this.hasContacts = this.organisationService.activeItem.contacts.length > 0;
    this.inviteMemberMode = false;
    this.openMembersMode = false;
    this.transmitMemberMode = false;
    this.showOrganisationContacts = !this.showOrganisationContacts;
  }

  //----------------------------------------------------------------------------------------
  //#endregion
  //----------------------------------------------------------------------------------------

  acceptMemberQuery(userId: number): void {
    let user: OrganisationMemberInterface = this.organisationService.activeItem.organisationMembers.filter(
      (member: OrganisationMemberInterface) => member.userId === userId
    )[0];
    this.organisationService.acceptMemberQuery(userId).subscribe(
      (res: OrganisationInterface) => {
        this.organisationService.activeItem = res;
        this.openMembersModeToggle();
      },
      (error) => {}
    );
  }

  declineMemberQuery(userId: number): void {
    this.organisationService.declineMemberQuery(userId).subscribe(
      (res: OrganisationInterface) => {
        this.organisationService.activeItem = res;
        this.organisationService.nextItem();
      },
      (error) => {}
    );
  }

  //identisch zu declineMemberQuery bis auf Fehlermeldung
  removeMember(member: OrganisationMemberInterface): void {
    this.dialogService
      .openRemoveMemberDialog(member.userName)
      .subscribe((result: { confirmed: boolean; message: string }) => {
        if (result != null && result.confirmed) {
          const userId = member.userId;
          this.organisationService
            .declineMemberQuery(userId, result.message)
            .subscribe(
              (res: OrganisationInterface) => {
                this.organisationService.activeItem = res;
                this.organisationService.nextItem();
              },
              (error) => {},
              () => {
                if (userId === this.userService.currentUser.userId) {
                  this.router.navigate(["profil/settings"]);
                }
              }
            );
        }
      });
  }

  inviteMembersConfirm(): void {
    if (INVITES.length > 0) {
      this.organisationService.inviteMembersToOrganisation(INVITES).subscribe(
        (org: OrganisationInterface) => this.successfulInvite(org),
        (error) =>
          this.dialogService
            .openDialog(
              "Einladungen wurden nicht versendet",
              "Überprüfen Sie die angegebenen E-Mails"
            )
            .subscribe()
      );
    } else {
      this.dialogService
        .openDialog(
          "Ein Fehler ist aufgetreten",
          "Bitte fügen Sie mindestens einen Nutzer hinzu"
        )
        .subscribe();
    }
  }

  private successfulInvite(org: OrganisationInterface): void {
    this.dialogService
      .openDialog("Die Einladung wurde erfolgreich versendet.", "Sollte der Nutzer bereits eine Anfrage auf Mitgliedschaft gestellt haben, ist er nun zu Ihrer Organisation hinzugefügt.")
      .subscribe(() => {
        this.organisationService.activeItem = org;
        this.inviteMembersModeToggle();
        if (!this.cd["destroyed"]) {
          this.cd.detectChanges();
        }
        INVITES.length = 0;
      });
  }

  updateOrganisationMembers(): void {
    this.organisationService.updateOrganisationMembers(
      this.memberRolesChangedList,
      (error?: Error) => {
        if(!error) {
          // update activeOrganisationMembership
          for (var id in this.memberRolesChangedList) {
            if (
              Number(id) ===
              this.organisationService.activeOrganisationMembership.userId
            ) {
              this.organisationService.activeOrganisationMembership.role = this.memberRolesChangedList[
                id
              ];
            }
          }
          this.dialogService.openDialog("Änderungen wurden gespeichert", "");
        }
      }
    );
  }

  addOneEntry(): void {
    let inviteUser: OrganisationMemberInterface = {
      membershipStatus: "USER_CONFIRMATION_PENDING",
      role: this.userToInvite.role,
      userName: this.userToInvite.userName,
    };
    let duplicate: boolean = false;
    for (let user of INVITES) {
      if (user.userName === inviteUser.userName) {
        duplicate = true;
      }
    }
    if (!duplicate) {
      if (
        this.userToInvite.userName != undefined &&
        this.userToInvite.role != undefined
      ) {
        INVITES.push(inviteUser);
        this.dataSource = new MatTableDataSource(INVITES);
      }
    }
    this.userToInvite.userName = undefined;
    this.userToInvite.role = undefined;
  }

  removeEntry(user: OrganisationMemberInterface): void {
    const index = INVITES.indexOf(user, 0);
    if (index > -1) {
      INVITES.splice(index, 1);
    }
    this.dataSource = new MatTableDataSource(INVITES);
  }

  getInvites(): boolean {
    return INVITES.length >= 1;
  }

  getRole(role: string): string {
    if (role === "USER") {
      return "Benutzer";
    } else {
      return "Admin";
    }
  }

  ngOnInit(): void {
    this.hasContacts = this.organisationService.activeItem.contacts.length > 0;
    this.titleService.setTitle("Mitglieder verwalten: " + this.organisationService.activeItem.organisationName +  " - BIMSWARM")
    this.userToInvite = {
      membershipStatus: "USER_CONFIRMATION_PENDING",
      role: null,
      userName: null,
    };


    this.activeItemSubscription = this.organisationService.activeItemObserver.subscribe(
      (x) => {
        this.cd.detectChanges();
      }
    );

    for (let user of this.organisationService.activeItem.organisationMembers) {
      if (
        user.membershipStatus === "ADMIN_CONFIRMATION_PENDING" ||
        user.membershipStatus === "USER_CONFIRMATION_PENDING"
      ) {
        this.openMembersMode = true;
        return;
      }
    }
  }

  ngOnDestroy() {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
    if (this.activeItemSubscription) {
      this.activeItemSubscription.unsubscribe();
    }
  }

  transmitConfirm() {
    this.enableSendBtn = false;
    this.userService
      .transmitOrgaPosition(
        this.organisationService.activeItem.organisationName,
        this.userToInvite.userName,
        this.organisationService.activeItem.organisationId,
        this.message
      )
      .subscribe(
        (res) => {
          this.enableSendBtn = true;
          if (res.status == 200) {
            this.messageTransmitResponse =
              "Einladung wurde erfolgreich versendet.";
          } else {
            this.messageTransmitResponse = res.statusText;
          }
          this.cd.detectChanges();
        },
        (err) => {
          this.enableSendBtn = true;
          this.messageTransmitResponse = err.error;
          this.cd.detectChanges();
        }
      );
  }

  isCurrentUserAdmin() {
    let members: OrganisationMemberInterface[] = this.organisationService
      .activeItem.organisationMembers;
    let user: OrganisationMemberInterface = members.find(
      (member: OrganisationMemberInterface) => {
        return member.userId === this.userService.currentUser.userId;
      }
    );
    return (user.role === "ADMIN" || this.userService.isCurrentUserPlatformAdmin());
  }
}
