import { TrackingService } from './../../../../services/tracking-service/tracking.service';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, first } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { UserService } from "src/app/services/user-service/userservice";
import { throwError } from "rxjs";

@Component({
  selector: "app-login-screen",
  templateUrl: "./login-screen.component.html",
  styleUrls: ["./login-screen.component.css"],
})

export class LoginScreenComponent implements OnInit {
  private intervalId: any = null;
  private intervalLength = 100;

  constructor(
    private authService: AuthService,
    private userservice: UserService,
    private organisationService: OrganisationService,
    private trackingService: TrackingService,
    private route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {

    this.route.queryParams.subscribe(
      (params) => {
        const authorization_code = params["code"];
          let isUserLoggedIn: string = localStorage.getItem("refresh_token");
        if (authorization_code) {
          this.authService
            .loginWithCode(authorization_code)
            .pipe(first(), )
            .subscribe((x) => {
              this.userservice.getCurrentUser().subscribe((user) => {
                this.userservice.handleLogin(user);
                this.organisationService.loadOrganisationsForUser();
                this.trackingService.eventEmitter("login", "login", "user_logged_in", user.userName);
              });
            });
        } else if (isUserLoggedIn) {
              window.history.back();

        } else {

          const specs =
            "channelmode=no,directories=no,fullscreen=no,menubar=no,toolbar=no,status=no,titlebar=no";
          const loginWindow = window.open(
            environment.oauthLoginUri,
            "Login",
            specs
          );
          this.intervalId = window.setInterval(() => {
            if (loginWindow && loginWindow.location.href.includes("/marktplatz")) {
              window.clearInterval(this.intervalId);
              window.location.href = loginWindow.location.href;
              if (this.userservice.redirectProductId) {
                window.location.href = loginWindow.location.href + '?tab=0&productId=' + this.userservice.redirectProductId;
                this.userservice.saveProductId(null);
              }
              loginWindow.close();
              }
          }, this.intervalLength);
          // vgl.: https://medium.com/tekraze/popup-window-for-oauth-login-in-angular-2-applied-to-js-based-languages-also-fb811cd28b43
        }
      },
      (error) => {}
    );
  }


  /*
    Problem: causes login issue with loginWithCode
    where if you logged out and tried to login again
    it would throw 2 errors with error code 0 and 500
  */
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: Anmeldung fehlgeschlagen.`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nAnmeldung fehlgeschlagen.`;
    }
    window.alert(errorMessage);

    window.location.href = environment.homeButtonLink;
    return throwError("errorMessage");
  }
}
