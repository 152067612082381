<div>
  <div *ngIf="editable">
    <p *ngIf="keywordPanel"><b>Schlagworte zur Suchoptimierung</b></p>
    <p *ngIf="!keywordPanel"><b>Feature des Produkts (max. 20)</b></p>
    <div class="auto-min">
      <mat-form-field>
        <input *ngIf="keywordPanel" matInput placeholder="Schlagwort" [(ngModel)]="newKeyword" (keyup.enter)="addKeyword();">
        <input *ngIf="!keywordPanel" matInput placeholder="Produkt-Feature" [(ngModel)]="newKeyword" (keyup.enter)="addKeyword();">
      </mat-form-field>
      <div class="mini-hex-button">
        <hexagon [icon]="'plus'" [hover]="true" [outerColor]="'green'" (click)="addKeyword()"></hexagon>
      </div>
    </div>
  </div>
  <div class="keywordsPanel">
    <div *ngFor="let keyword of keywords" class="container">
      <div class="keywordCardWrapper">
        <div *ngIf="editable" class="deleteBtn" (click)="removeKeyword(keyword)">
          <div class="stripe"></div>
        </div>
        <div class="keywordCard">
          <p class="keyword">{{keyword}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
