import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { ProductVariantService } from "../services/dataServices/product-variant-service/product-variant.service";
import { ProductVariantVersionService } from "../services/dataServices/product-variant-version-service/product-variant-version.service";

@Injectable()
export class VariantVersionResolver implements Resolve<any> {
  constructor(private versionService: ProductVariantVersionService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    if (!route.url.toString().includes("create")) {
      let version = parseInt(route.paramMap.get("versionId"));
      await this.versionService.setActiveVersion(version);
    }
  }
}
