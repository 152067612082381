import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanReadableClockTime'
})
export class HumanReadableClockTime implements PipeTransform {

  transform(timestamp: string, args?: any): String {
    let hrt: string = "";
    let splitted = timestamp.split(":");
    hrt = splitted[0] + ":" + splitted[1];
    return hrt;

  }

}
