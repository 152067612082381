<form #form="ngForm">
  <div [ngClass]="{ 'mini-grid-product-type': !isVersionEditMode() }">
    <mat-form-field class="fullWidth">
      <input
        matInput
        #versionName="ngModel"
        [(ngModel)]="this.version.name"
        placeholder="Versionsname"
        name="versionName"
        [attr.disabled]="true"
        required
      />
      <mat-error *ngIf="versionName.errors?.required && versionName.touched">
        Bitte geben Sie einen Versionsnamen ein.
      </mat-error>
    </mat-form-field>
    <div [ngClass]="{ 'mini-grid-product-type': isVersionEditMode() }">
      <mat-form-field>
        <mat-select
          placeholder="Status der Version"
          [(ngModel)]="version.status"
          name="versionStatus"
        >
          <mat-option [value]="'INACTIVE'">Inaktiv</mat-option>
          <mat-option [value]="'ACTIVE'">Aktiv</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="isVersionEditMode()">
        <mat-select
          placeholder="Vorgängerversion"
          [(ngModel)]="version.previousVersion"
          name="previousVersions"
          (selectionChange)="onAddAttributeValidationsFromOtherVersion($event)"
          [disabled]="isCreateNew"
        >
          <mat-option
            *ngFor="let variantVersion of allVersionsOfActiveVariant"
            [value]="variantVersion.id"
            >{{ variantVersion.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="isVariantCreateMode()" class="mini-grid-dependency">
    <div class="mikro-grid-ptg">
      <p *ngIf="isCertification; else productDependencyText">
        Sollen für diese Version Merkmale einer anderen Zertifizierungsversion
        übernommen werden?
      </p>
      <ng-template #productDependencyText>
        <p>
          Sollen für diese Version Merkmale einer anderen Produktversion
          übernommen werden?
        </p>
      </ng-template>
      <toggle-switch
        [trueLabel]="'Ja'"
        [falseLabel]="'Nein'"
        [allowUndefined]="false"
        (valueChange)="addOtherVersionAttributesModeToggle($event)"
      >
      </toggle-switch>
    </div>
    <div
      [ngClass]="{
        hide: !addOtherVersionAttributesMode,
        'previous-version-panel': addOtherVersionAttributesMode
      }"
    >
      <triangle-indent></triangle-indent>
      <div class="previous-version-panel-second">
        <mat-form-field>
          <mat-select
            placeholder="Variante"
            (selectionChange)="setDependeeVariant($event)"
            #variantReference
          >
            <mat-option
              *ngFor="let variant of getOtherVariantsOfEntity()"
              value="{{ variant.id }}"
            >
              {{ variant.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select
            placeholder="Version"
            (selectionChange)="
              onAddAttributeValidationsFromOtherVersion($event)
            "
            #versionReference
          >
            <mat-option
              *ngFor="
                let version of filterSelectedVariant(
                  variantReference.value,
                  variantReference.selected === undefined
                )
              "
              value="{{ version.id }}"
              >{{ version.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div *ngIf="isVersionEditMode()">
    <mat-form-field class="fullWidth">
      <textarea
        matInput
        [(ngModel)]="version.comment"
        placeholder="Kommentar (intern)"
        name="comment"
      ></textarea>
    </mat-form-field>
    <app-text-editor
      [(editorText)]="version.description"
      (textValidityChange)="onDescriptionValidityChange($event)"
    ></app-text-editor>
  </div>
  <app-add-ptg-dependency-panel
    [isCertification]="isCertification"
    [version]="version"
    (addedPtgVersion)="onPtgVersionAdded($event)"
  ></app-add-ptg-dependency-panel>

  <app-selected-ptg-panel
    [version]="version"
    [catalogueAttributes]="catalogueAttributes"
    (changedPtgVersions)="onPtgVersionsChanged($event)"
  >
  </app-selected-ptg-panel>

  <ng-container *ngFor="let ptgVersion of version.ptgVersions">
    <p>
      <b>
        Merkmale aus Produkttyp {{ getPtgName(ptgVersion) }} - Version
        {{ ptgVersion.number }}
      </b>
    </p>
    <p>
      Verpflichtende Merkmale:
    </p>
    <attribute-validation-table
      [attributes]="getNonOptionalAttributesOfPtgVersion(ptgVersion)"
      [optional]="false"
      [editable]="false"
    >
    </attribute-validation-table>
    <p>
      Optionale Merkmale:
    </p>
    <attribute-validation-table
      [attributes]="getOptionalAttributesOfPtgVersion(ptgVersion)"
      [optional]="true"
      [editable]="false"
    >
    </attribute-validation-table>
  </ng-container>

  <div class="min-auto">
    <p *ngIf="isCertification; else productText">
      <strong> Merkmale für die Zertifizierungsversion hinzufügen </strong>
    </p>
    <ng-template #productText>
      <p>
        <strong>Merkmale für die Produktversion hinzufügen</strong>
      </p>
    </ng-template>
    <attribute-catalogue
      (onPressConfirm)="featureCatalogueConfirmPressed($event)"
      [noMargin]="true"
    ></attribute-catalogue>
  </div>

  <attribute-validation-table
    [(attributes)]="catalogueAttributes"
    [editable]="true"
    (attributesChanged)="onAttributesChanged($event)"
  >
  </attribute-validation-table>

  <addEntriesToList-panel
    [(keywords)]="version.keywords"
    [editable]="true"
    [keywordPanel]="true"
  ></addEntriesToList-panel>
</form>
