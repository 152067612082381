<div class="certification-detail-overview">
  <!--------Schlagwörter---------->
  <div class="keywords" *ngIf="keywords && keywords.size > 0">
    <div>
      <p>
        <span class="bold">Schlagwörter: </span> {{ getKeyWordsAsString() }}
      </p>
    </div>
  </div>

  <!---------Beschreibung des Zertifikates--------->
  <div
    *ngIf="
      certification.description != null && certification.description.length > 0
    "
  >
    <p><strong>Zertifikatbeschreibung</strong></p>
    <p class="description" [innerHTML]="certification.description"></p>
  </div>

  <!-------Anzeige aller Variante sowie ihre Versionen----------->
  <div>
    <table>
      <thead>
        <tr>
          <th>Variante</th>
          <th>Version</th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngFor="let variantVersionPair of variantVersionHash | keyvalue"
        >
          <tr>
            <td>{{ variantVersionPair.key }}</td>
            <td>{{ variantVersionPair.value[0] }}</td>
          </tr>
          <tr *ngFor="let versionName of variantVersionPair.value | slice: 1">
            <td></td>
            <td>{{ versionName }}</td>
          </tr>
          <br />
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
