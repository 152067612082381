import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  API_BASE_USERSERVICE,
  API_MAP,
  API_BASE_PRODUCTSERVICE,
  API_BASE_TOOLCHAINSERVICE,
  API_BASE_BAUSTELLE,
} from "src/environments/api";
import { DtoPageRequest } from "src/app/model/marketplace/dto-page-request";
import { DataType } from "src/app/model/data-type.enum";
import { MarketplaceFilterService } from "../../componentServices/marketplace-filter-service/marketplace-filter.service";
import { AttributeInterface } from "src/app/model/attributes/attribute-interface";
import { ProductInterface } from "src/app/model/products/product-interface";
import { Observable } from "rxjs";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { PtgInterface } from "src/app/model/ptgs/ptg-interface";
import { FilterCheckboxDtoInterface } from "src/app/model/marketplace/filter-checkbox-interface";
import { CertificationOverviewInterface } from "src/app/model/marketplace/certification-overview-interface";
import { DisplayItemInterface } from "src/app/model/marketplace/display-item-interface";
import { environment } from "src/environments/environment";
import { OrganisationType } from "src/app/model/enums/organisationType";
import { ProductOverviewInterface } from "src/app/model/marketplace/product-overview-interface";
import { OrganisationService } from "../organisation-service/organisationservice";
import { OrganisationOverviewInterface } from "src/app/model/marketplace/organisation-overview-interface";
import { ProductOverviewDataInterface } from "src/app/model/marketplace/product-overview-data-interface";
import { OrganisationOverviewDataInterface } from "src/app/model/marketplace/organisation-overview-data-interface";
import { BaustelleOverviewDataInterface } from "src/app/model/marketplace/baustelle-overview-data-interface";
import { CertificationOverviewDataInterface } from "src/app/model/marketplace/certification-overview-data-interface";
import { ComposerProductPageRequestInterface } from "src/app/model/composer/composer-product-page-request-interface";
import { ToolchainMarketplaceOverviewInterface } from "src/app/model/marketplace/toolchain-marketplace-overview-interface";
import { ToolchainOverviewInterface } from "src/app/model/marketplace/toolchain-overview-interface";
import { BaustelleOverviewInterface, ProductOverviewInDifferentFormsInterface } from "src/app/model/marketplace/baustelle-overview-interface";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class MarketplaceService {
  public totalNrOfItems: number;

  constructor(
    private http: HttpClient,
    public filterService: MarketplaceFilterService,
    private organisationService: OrganisationService
  ) {}

  /**
   * //TODO figure out the return types
   *
   * @param pageRequest Query String
   * @param dataType Information about the Service API to query
   *
   * @returns Observable of the http Request
   */
  getFilteredSlice(

    pageRequest: DtoPageRequest,
    dataType: DataType,
  ) {
    const json = JSON.stringify(pageRequest);
    var url = "";
    switch (dataType) {
      case DataType.BAUSTELLE: {
        url = API_BASE_BAUSTELLE;
        return this.http.get(url, httpOptions);
        break;
      }
      case DataType.ORGANISATION: {
        url = API_BASE_USERSERVICE + API_MAP["organisations"]["FILTER"];
        break;
      }
      case DataType.ATTRIBUTE: {
        url = API_BASE_PRODUCTSERVICE + API_MAP["attributes"]["FILTER"];
        break;
      }
      case DataType.PTG: {
        url = API_BASE_PRODUCTSERVICE + API_MAP["PTGs"]["FILTER"];
        break;
      }
      case DataType.PRODUCT: {

        url = API_BASE_PRODUCTSERVICE + API_MAP["products"]["FILTER"];
        break;
      }

       case DataType.SOFTWARE: {
        url = API_BASE_PRODUCTSERVICE + API_MAP["products"]["SOFTWARE"];
        break;
      }
      case DataType.EDGESERVER: {
        url = API_BASE_PRODUCTSERVICE + API_MAP["products"]["EDGE"];
         return this.http.get(url, httpOptions);
        break;
      }

      case DataType.DIENSTLEISTUNGEN: {
        url = API_BASE_PRODUCTSERVICE + API_MAP["products"]["DIENSTLEISTUNGEN"];
        return this.http.get(url, httpOptions);
        break;
      }
      case DataType.KÜNTSTLICHE_INTELLIGENZ: {
        url = API_BASE_PRODUCTSERVICE + API_MAP["products"]["KÜNTSTLICHE_INTELLIGENZ"];
        return this.http.get(url, httpOptions);
        break;
      }

      case DataType.ROBOT: {
        url = API_BASE_PRODUCTSERVICE + API_MAP["products"]["ROBOT"];
        return this.http.get(url, httpOptions);
        break;
      }

      case DataType.CONTENT: {
        url = API_BASE_PRODUCTSERVICE + API_MAP["products"]["CONTENT"];
        return this.http.get(url, httpOptions);
        break;
      }
      case DataType.CERTIFICATION: {
        url = API_BASE_PRODUCTSERVICE + API_MAP["certifications"]["FILTER"];
        break;
      }
      case DataType.TOOLCHAIN: {
        url = API_BASE_TOOLCHAINSERVICE + API_MAP["composer"]["FILTER"];
        break;
      }
    }
    // TODO: wieso Post und nicht get ?
    return this.http.post(url, json, httpOptions);
  }
  getDisplayItems(request: Observable<any>): Promise<DisplayItemInterface[]> {
    let displayItem: DisplayItemInterface[];

    return new Promise((resolve, reject) => {
      switch (this.filterService.marketScreenState) {
        //-----------------------------------------------
        case DataType.PRODUCT: {
          request.subscribe((x: ProductOverviewDataInterface) => {
            this.totalNrOfItems = x.totalNumberOfProducts;

            displayItem = this.transcribeDisplayItems(x.productSlice);
            resolve(displayItem);
          });
          break;
        }

        case DataType.SOFTWARE: {
          request.subscribe((x) => {
            this.totalNrOfItems = x.length;
            displayItem = this.transcribeDisplayItems(x);
            resolve(displayItem);
          });
          break;
        }

        case DataType.CONTENT: {
          request.subscribe((x) => {
            this.totalNrOfItems = x.length;
            displayItem = this.transcribeDisplayItems(x);
            resolve(displayItem);
          });
          break;
        }

        case DataType.DIENSTLEISTUNGEN: {
          request.subscribe((x) => {
            this.totalNrOfItems = x.length;
            displayItem = this.transcribeDisplayItems(x);
            resolve(displayItem);
          });
          break;
        }

        case DataType.KÜNTSTLICHE_INTELLIGENZ: {
          request.subscribe((x) => {
            this.totalNrOfItems = x.length;
            displayItem = this.transcribeDisplayItems(x);
            resolve(displayItem);
          });
          break;
        }

        case DataType.ROBOT: {
          request.subscribe((x) => {
            this.totalNrOfItems = x.length;
            displayItem = this.transcribeDisplayItems(x);
            resolve(displayItem);
          });
          break;
        }

        case DataType.EDGESERVER: {
          request.subscribe((x) => {
            this.totalNrOfItems = x.length;
            displayItem = this.transcribeDisplayItems(x);
            resolve(displayItem);
          });
          break;
        }
        //-----------------------------------------------
        case DataType.ORGANISATION: {

          request.subscribe((x: OrganisationOverviewDataInterface) => {
            this.totalNrOfItems = x.totalNumberOfOrganisations;
            displayItem = this.transcribeDisplayItems(x.organisations);
            resolve(displayItem);
          });
          break;
        }
        //-----------------------------------------------
        case DataType.BAUSTELLE: {

          request.subscribe((x) => {
            this.totalNrOfItems = x.length;
            displayItem = this.transcribeDisplayItems(x);
            resolve(displayItem);
          });
          break;
        }
        //-----------------------------------------------
        case DataType.CERTIFICATION: {
          request.subscribe((x: CertificationOverviewDataInterface) => {
              this.totalNrOfItems = x.totalNumberOfCertifications;
              x.totalNumberOfCertifications = x.certificationSlice.length;

            displayItem = this.transcribeDisplayItems(x.certificationSlice);
            resolve(displayItem);
          });
          break;
        }
        //-----------------------------------------------
        case DataType.TOOLCHAIN: {
          request.subscribe((x: ToolchainMarketplaceOverviewInterface) => {
            this.totalNrOfItems = x.totalNumberOfToolchains;
            displayItem = this.transcribeDisplayItems(x.toolchainSlice);
            resolve(displayItem);
          });
          break;
        }
        default: {
          // reject(null);
          break;
        }
        //-----------------------------------------------
      }
    });
  }

  getDisplayItem(productId: number): Promise<DisplayItemInterface> {
    let displayItem: DisplayItemInterface;
    var url = "";
    return new Promise((resolve, reject) => {
      url = API_BASE_PRODUCTSERVICE + API_MAP["products"]["FILTER_ONE"].replace(":productId", productId + "");
      this.http.get(url, httpOptions).subscribe((x: ProductOverviewInterface) => {
            displayItem = this.transcribeDisplayItem(x);
            resolve(displayItem);
          });
    });
  }
  /*
  getHighlightedElements(): Promise<DisplayItemInterface[]> {
    let displayItem: DisplayItemInterface[];
    var url = "";
    return new Promise((resolve, reject) => {
      switch (this.filterService.marketScreenState) {
        case DataType.PRODUCT: {
          url = API_BASE_PRODUCTSERVICE + API_MAP["products"]["HIGHLIGHTED"];
          this.http
            .get(url, httpOptions)
            .subscribe((productOverview: ProductOverviewDataInterface) => {
              resolve(
                this.transcribeDisplayItems(productOverview.productSlice)
              );
            });
          break;
        }
        case DataType.ORGANISATION: {
          url = API_BASE_USERSERVICE + API_MAP["organisations"]["HIGHLIGHTED"];
          this.http
            .get(url, httpOptions)
            .subscribe(
              (organisationOverview: OrganisationOverviewDataInterface) => {
                resolve(
                  this.transcribeDisplayItems(
                    organisationOverview.organisations
                  )
                );
              }
            );
          break;
        }
        case DataType.CERTIFICATION: {
          url =
            API_BASE_PRODUCTSERVICE + API_MAP["certifications"]["HIGHLIGHTED"];
          this.http
            .get(url, httpOptions)
            .subscribe(
              (CertificationOverview: CertificationOverviewDataInterface) => {
                resolve(
                  this.transcribeDisplayItems(
                    CertificationOverview.certificationSlice
                  )
                );
              }
            );
          break;
        }
        case DataType.TOOLCHAIN: {
          url = API_BASE_TOOLCHAINSERVICE + API_MAP["composer"]["HIGHLIGHTED"];
          this.http
            .get(url, httpOptions)
            .subscribe(
              (toolchainOverview: ToolchainMarketplaceOverviewInterface) => {
                resolve(
                  this.transcribeDisplayItems(toolchainOverview.toolchainSlice)
                );
              }
            );
          break;
        }
        default: {
          reject(null);
        }
      }
    });
  }
  */
  /**
   * Queries the backend for filter data of active entities, consists of organisation data, ptg data and attribute data
   * @param isCertification boolean flag whether we want data for active products or active certifications
   *
   * @returns Observable of the http Request
   */

  //TODOOOO
  getOfActiveEntities(): //   isCertification: boolean
  Observable<FilterCheckboxDtoInterface> {
    let url: string = API_BASE_PRODUCTSERVICE;
    url += API_MAP["products"]["FILTER_ACTIVE"];
    return this.http.get<FilterCheckboxDtoInterface>(url);
  }

  /**
   * Gibt die URL vom Bild f�r die gegebene ID zur�ck
   */
  getPictureUrl(id: number, dataType: DataType): string {
    let pictureUrl = "";
    switch (dataType) {
      case DataType.CERTIFICATION: {
        pictureUrl =
          API_BASE_PRODUCTSERVICE +
          API_MAP["certificationPictures"]["GET"].replace(
            ":certificationId",
            id + ""
          ) +
          "?isCertification=true";
        break;
      }
      case DataType.PRODUCT: {
        pictureUrl =
          API_BASE_PRODUCTSERVICE +
          API_MAP["productPictures"]["GET"].replace(":entityId", id + "");
        break;
      }
      case DataType.ORGANISATION: {
        pictureUrl =
          API_BASE_USERSERVICE +
          API_MAP["organisationPictures"]["GET"].replace(
            ":organisationId",
            id + ""
          );
        break;
      }
    }
    return pictureUrl;
  }

  //#############################################################
  transcribeDisplayItems(dtosToTranscribe: any[]) {
    return dtosToTranscribe.map((x) => this.transcribeDisplayItem(x));
  }

  /**
   * Create a displayItem from the dto of the backend services.
   * @param dtoToTranscribe
   * @param type
   */
  public transcribeDisplayItem(
    dtoToTranscribe:
      | ProductOverviewInterface
      | OrganisationOverviewInterface
      | CertificationOverviewInterface
      | ToolchainOverviewInterface
      | BaustelleOverviewInterface
  ) {
    let type = this.filterService.marketScreenState;
    let item: DisplayItemInterface;
    //-----------------------------------------------------------
    switch (type) {
      //-----------------------------------------------
      case DataType.PRODUCT: {
        item = this.transcribeProduct(
          dtoToTranscribe as ProductOverviewInterface
        );
        break;
      }

      case DataType.SOFTWARE: {
        item = this.transcribeProductinDifferentForms(
          dtoToTranscribe as ProductOverviewInDifferentFormsInterface
        );
        break;
      }

      case DataType.CONTENT: {
        item = this.transcribeProductinDifferentForms(
          dtoToTranscribe as ProductOverviewInDifferentFormsInterface
        );
        break;
      }

      case DataType.DIENSTLEISTUNGEN: {
        item = this.transcribeProductinDifferentForms(
          dtoToTranscribe as ProductOverviewInDifferentFormsInterface
        );
        break;
      }

      case DataType.KÜNTSTLICHE_INTELLIGENZ: {
        item = this.transcribeProductinDifferentForms(
          dtoToTranscribe as ProductOverviewInDifferentFormsInterface
        );
        break;
      }

      case DataType.ROBOT: {
        item = this.transcribeProductinDifferentForms(
          dtoToTranscribe as ProductOverviewInDifferentFormsInterface
        );
        break;
      }

      //-----------------------------------------------
      case DataType.EDGESERVER: {
        item = this.transcribeProductinDifferentForms(
          dtoToTranscribe as ProductOverviewInDifferentFormsInterface
        );
        break;
      }
      //-----------------------------------------------
      case DataType.ORGANISATION: {
        item = this.transcribeOrganisation(
          dtoToTranscribe as OrganisationOverviewInterface
        );

        break;
      }
      //-----------------------------------------------
      case DataType.BAUSTELLE: {
        item = this.transcribeBaustelle(
          dtoToTranscribe as BaustelleOverviewInterface
        );

        break;
      }
      //-----------------------------------------------
      case DataType.CERTIFICATION: {

        item = this.transcribeCertification(
          dtoToTranscribe as CertificationOverviewInterface
        );

        break;
      }
      //-----------------------------------------------
      case DataType.TOOLCHAIN: {
        item = this.transcribeToolchain(
          dtoToTranscribe as ToolchainOverviewInterface
        );
        break;
      }
      //-----------------------------------------------
    }
    //-----------------------------------------------------------
    return item;
  }

  //#############################################################

  //#############################################################
  //#region TRANSCRIBE DTO OBJECTS TO DISPLAY ITEM

  private transcribeCertification(
    dtoToTranscribe: CertificationOverviewInterface
  ) {
    // casting
    let certification = dtoToTranscribe as CertificationOverviewInterface;
    let item: DisplayItemInterface;
    // then set the correct variables from the backend DTO
    item = {
      name: certification.certificationName,
      displayType: DataType.CERTIFICATION,
      id: certification.certificationId,
      subscript: certification.organisationName,
      link: "/marktplatz/certification/" + certification.certificationId,
      pictureUrl: certification.pictureAvailable
        ? this.getPictureUrl(
            certification.certificationId,
            DataType.CERTIFICATION
          )
        : environment.defaultPictureProducts,
      organisationId: certification.organisationId,
      fileId: certification.fileId,
      description: certification.description,
      certificationType: certification.certificationType,
      productsCertified: certification.productsCertified,
    };
    return item;
  }

  private transcribeOrganisation(
    dtoToTranscribe: OrganisationOverviewInterface
  ) {
    // casting
    let organisation = dtoToTranscribe as OrganisationOverviewInterface;
    let item: DisplayItemInterface;
    // then set the correct variables from the backend DTO
    item = {
      name: organisation.organisationName,
      displayType: DataType.ORGANISATION,
      id: organisation.organisationId,
      subscript: "",
      link: organisation.domain,
      pictureUrl: organisation.pictureAvailable
        ? this.getPictureUrl(organisation.organisationId, DataType.ORGANISATION)
        : environment.defaultPictureOrganisationProducts,
      organisationId:  1,
      description: organisation.description,
      fileId: organisation.fileId,
      organisationType: organisation.organisationType,
      certificationCount: organisation.certificationCount,
    };
    return item;
  }


  private transcribeProductinDifferentForms(dtoToTranscribe: ProductOverviewInDifferentFormsInterface) {
    // casting
    let product = dtoToTranscribe as ProductOverviewInDifferentFormsInterface;
    // then set the correct variables from the backend DTO
    let item: DisplayItemInterface;

    item = {
      name: product.name,
      displayType: DataType.PRODUCT,
      id: product.id,
      subscript: product.organisationName,
      link: "/marktplatz/products/" + product.id,
      pictureUrl: product.pictureAvailable
        ? this.getPictureUrl(product.id, DataType.BAUSTELLE)
        : environment.defaultPictureProducts,
      fileId: product.fileId,
      description: product.description,
      organisationId: product.company,
      attributeCertificationCount: product.attributeCertificationCount,
      ptgCertificationCount: product.ptgCertificationCount,
      toolchainCertificationCount: product.toolchainCertificationCount,
      reviewRating: {
        rating: product.rating,
        reviewNumber: product.ratingCount,
      },
      ptgsWhereAllMandatoryAttributesImplementedCount: product.ptgsWhereAllMandatoryAttributesImplementedCount
    };
    return item;
  }


  private transcribeBaustelle(dtoToTranscribe: BaustelleOverviewInterface) {
    // casting
    let product = dtoToTranscribe as BaustelleOverviewInterface;
    // then set the correct variables from the backend DTO
    let item: DisplayItemInterface;

    item = {
      name: product.name,
      displayType: DataType.BAUSTELLE,
      id: product.id,
      subscript: product.organisationName,
      link: "/marktplatz/products/" + product.id,
      pictureUrl: product.pictureAvailable
        ? this.getPictureUrl(product.id, DataType.BAUSTELLE)
        : environment.defaultPictureProducts,
      fileId: product.fileId,
      description: product.description,
      organisationId: product.company,
      attributeCertificationCount: product.attributeCertificationCount,
      ptgCertificationCount: product.ptgCertificationCount,
      toolchainCertificationCount: product.toolchainCertificationCount,
      reviewRating: {
        rating: product.rating,
        reviewNumber: product.ratingCount,
      },
      ptgsWhereAllMandatoryAttributesImplementedCount: product.ptgsWhereAllMandatoryAttributesImplementedCount
    };
    return item;
  }

  private transcribeProduct(dtoToTranscribe: ProductOverviewInterface) {
    // casting
    let product = dtoToTranscribe as ProductOverviewInterface;
    // then set the correct variables from the backend DTO
    let item: DisplayItemInterface;

    item = {
      name: product.productName,
      displayType: DataType.PRODUCT,
      id: product.productId,
      subscript: product.organisationName,
      link: "/marktplatz/products/" + product.productId,
      pictureUrl: product.pictureAvailable
        ? this.getPictureUrl(product.productId, DataType.PRODUCT)
        : environment.defaultPictureProducts,
      fileId: product.fileId,
      description: product.description,
      organisationId: product.organisationId,
      attributeCertificationCount: product.attributeCertificationCount,
      ptgCertificationCount: product.ptgCertificationCount,
      toolchainCertificationCount: product.toolchainCertificationCount,
      reviewRating: {
        rating: product.rating,
        reviewNumber: product.ratingCount,
      },
      ptgsWhereAllMandatoryAttributesImplementedCount: product.ptgsWhereAllMandatoryAttributesImplementedCount
    };
    return item;
  }

  /* Transcribe the given DTO to create a DisplayItem with Data from a Toolchain */
  private transcribeToolchain(dtoToTranscribe: ToolchainOverviewInterface) {
    // casting
    let toolchain = dtoToTranscribe as ToolchainOverviewInterface;
    let item: DisplayItemInterface;
    // then set the correct variables from the backend DTO
    item = {
      name: toolchain.toolchainName,
      displayType: DataType.TOOLCHAIN,
      id: toolchain.toolchainId,
      pictureUrl: environment.defaultPictureProducts,
      subscript: toolchain.organisationName,
      link: "/marktplatz/toolchain/" + toolchain.toolchainId,
      fileId: toolchain.fileId,
      reviewRating: {
        rating: toolchain.rating,
        reviewNumber: toolchain.ratingCount,
      },
    };
    return item;
  }

  getFilteredSliceForComposer(request: ComposerProductPageRequestInterface) {
    const json = JSON.stringify(request);
    const url =
      API_BASE_PRODUCTSERVICE + API_MAP["products"]["COMPOSER_FILTER"];

    return this.http.post(url, json, httpOptions);
  }

  getFilteredSliceForBaustelle(request: ComposerProductPageRequestInterface) {
    const json = JSON.stringify(request);
    const url =
      API_BASE_PRODUCTSERVICE + API_MAP["products"]["BAUSTELLE_FILTER"];

    return this.http.post(url, json, httpOptions);
  }
}

//#endregion
//#############################################################
