import { Component, OnDestroy, OnInit } from "@angular/core";
import { DocumentType } from "src/app/model/compatiblity/document-type-interface";
import { DocumentTypeService } from "src/app/services/compatiblity-service/document-type-service.service";

@Component({
  selector: "app-document-types-screen",
  templateUrl: "./document-types-screen.component.html",
  styleUrls: ["./document-types-screen.component.css"],
})
export class DocumentTypesScreenComponent implements OnInit, OnDestroy {
  /**
   * Holds the currently displayed @type {DocumentType}s
   */
  public data: DocumentType[];
  /**
   * Contains the column names of the columns displayed in the table
   */
  public displayedColumns = ["name", "showColumn", "editColumn"];

  constructor(documentTypeService: DocumentTypeService) {
    // Get all DocumentTypes from the service and set the data property
    documentTypeService
      .getDocumentTypes()
      .subscribe((value) => (this.data = value));

    document.documentElement.style.setProperty('--scrollStatus', 'auto');
  }
  
  ngOnInit()
  {

  }

  ngOnDestroy()
  {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }
}
