<div
  class="row"
  [ngClass]="{
    grey: isSuccess(data) || isFinished(data),
    odd: index % 2 != 0,
    even: index % 2 == 0
  }"
  (click)="toggleCollapse()"
>
  <div class="firstTableColumn">
    <p>{{ data.toolchainId }}</p>
  </div>
  <div class="centered">
    <p>{{ data.name }}</p>
  </div>
  <div class="centered">
    <p
      [ngClass]="{
        failed: isFailed(data),
        success: isSuccess(data)
      }"
    >
      {{ data.status }}
    </p>
  </div>
  <div class="centered">
    <!--Update to Angular 9: Splitting on dates no longer allowed. Needs new implementation.-->
    <!-- <p>{{ data.start.split(" ")[0] | humanReadableDate }} {{ data.start.split(" ")[1] | humanReadableClockTime }}Uhr</p> -->
  </div>
  <div class="centered">
    <p>{{ editorName }}</p>
  </div>
  <div class="buttonContainer">
    <div *ngIf="isFailed(data)" class="svg-icon restartButton"></div>
    <div *ngIf="isWorking(data)" class="svg-icon stopButton"></div>
    <div *ngIf="isFinished(data)" class="svg-icon deleteButton"></div>
  </div>
  <div>
    <div
      class="triangle"
      [ngClass]="{
        turn: !collapsed,
        grey: isSuccess(data) || isFinished(data)
      }"
    ></div>
  </div>
</div>
<div
  class="expandedRow"
  [ngClass]="{
    grey: isSuccess(data) || isFinished(data),
    odd: index % 2 != 0,
    even: index % 2 == 0,
    hidden: collapsed
  }"
  (click)="toggleCollapse()"
>
  <div class="expandedRowRow">
    <div class="textWithLabel">
      <p>Name</p>
      <p class="underlined">{{ data.name }}</p>
    </div>
    <div class="textWithLabel">
      <p>Assoziierte Benutzer</p>
      <div class="associatedUsers">
        <p>{{editorName}}</p>
        <!-- <p *ngFor="let user of data.associatedUsers">{{ user }}</p> -->
      </div>
    </div>
  </div>
  <div class="expandedRowRow">
    <div class="textWithLabel">
      <p>Beschreibung</p>
      <p>{{ data.description }}</p>
    </div>
    <div [id]="'instanceViewer' + data.toolchainId"></div>
  </div>
  <div class="expandedRowRow">
    <div>
      <button mat-raised-button class="btn_green" (click)="openInstance()">Instanz öffnen</button>
      <button mat-raised-button class="btn_red" (click)="deleteToolchain()">Löschen</button>
    </div>
    <button *ngIf="isWorking(data)" mat-raised-button class="btn_red">Abbrechen</button>
    <button *ngIf="isFinished(data)" mat-raised-button class="btn_red">Löschen</button>
    <button *ngIf="isFailed(data)" mat-raised-button class="btn_green">Restart</button>
  </div>
</div>
