import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datatypeToString'
})
export class DatatypeToStringPipe implements PipeTransform {

  transform(datatype: string, args?: any): String {
    switch(datatype) {
      case "BOOLEAN": {
        return "Wahrheitswert"
      }
      case "LIST": {
        return "Liste"
      }
      case "NUMERIC": {
        return "Zahl"
      }
      case "STRING": {
        return "Text"
      }
    }

  }

}
