import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";
import { inherits } from "util";
import { joinEvent, toolchainItemBase } from "../../util/preloadedAssets";
import { parseSvg } from "../../util/svgUtil";

/* Allows dynamic Parameter Injection */
ToolchainItemRender["$inject"] = ["eventBus"];


/* Custom Renderer for Toolchain Items */
export default function ToolchainItemRender(eventBus: any) {
  BaseRenderer.call(this, eventBus, 1500);

  /* Checks if the Renderer is able to render the Element */
  this.canRender = function (element) {
    return element.type === "composer:ToolchainItem";
  };

  /* Draws the Element */
  this.drawShape = function (parent, shape) {
    /* Reads the Attributes of the BusinessObject */
    const task = shape.businessObject;
    const productName = task.productName;
    const itemName = task.itemName;
    const dataUrl = task.imageDataUrl;

    let svg = toolchainItemBase;
    svg = modifySvg(svg, productName, itemName, dataUrl);

    let image = parseSvg(svg).firstChild;
    image = document.importNode(image, true);

    parent.appendChild(image);

    return image;
  };
}

/* Modifies the SVG String and adds the Productname, Itemname and the Product Image as new SVG Tags */
function modifySvg(
  svg: string,
  productName: string,
  itemName: string,
  imageDataSource: string
): string {
  const endofSvg: number = svg.indexOf("</svg>");

  /* Shortens the Names if they are too long to be fully displayed */
  if (itemName.length > 18) {
    itemName = itemName.substring(0, 14) + " ...";
  }
  if (productName.length > 18) {
    productName = productName.substring(0, 14) + " ...";
  }

  const image = imageDataSource !== "" ? '<image href="' +
  imageDataSource +
  '" height="55" width="92" x="5" y="18" />' : "";

  return (
    svg.substring(0, endofSvg) +
    '<text id="itemName" x="10" y="15">' +
    itemName +
    "</text>" +
    image +
    svg.substring(endofSvg, svg.length)
  );
}

inherits(ToolchainItemRender, BaseRenderer);
