<ul *ngIf="sidemenuService.productsVisible">
  <div>
    <mat-checkbox (change)="showFilteredProducts($event)">
      Nach aktiven Produkten filtern
    </mat-checkbox>
  </div>
</ul>
<ul>
  <app-sidemenu-button
    *ngFor="let button of sidemenuService.sidemenuData"
    [label]="button.label"
    [link]="button.link"
    [addButton]="button.addButton"
    [children]="button.children"
    [hasChildren]="button.hasChildren"
    [entityId]="button.entityId"
    [ptgId]="button?.ptgId"
    [usecaseId]="button.usecaseId"
    [belongsToOrganisation]="button.belongsToOrganisation"
    [composerItem]="button.composerItem"
    [clicked]="button.clicked"
    [type]="button.type"
    [rating]="button.rating"
    [isCertification]="button.isCertification"
    [imageId]="button.imageId"
  >
  </app-sidemenu-button>
</ul>
