<div class="one-column">
  <h1>Nutzerverwaltung</h1>
  <h2>BIM-SWARM Nutzer</h2>
  <div class="search-panel">
    <div class="button-search"></div>
    <mat-form-field floatLabel="never">
      <input
        matInput
        [(ngModel)]="userSearch"
        placeholder="Suchen..."
        (input)="filterUserSearchText()"
      />
    </mat-form-field>
  </div>
  <div class="table">
    <div class="row border">
      <p class="closer-to-border"><b>Nutzer</b></p>
      <p class="closer-to-border"><b>Rolle</b></p>
      <p class="closer-to-border"><b>Composer-Nutzungsrechte</b></p>
      <p class="closer-to-border"><b>Nutzer zur Organisation einladen</b></p>
      <p class="closer-to-border"><b>Nutzer löschen</b></p>
    </div>
    <div
      *ngFor="let user of userRoleGroups; let i = index"
      class="row"
    >
      <div>
        <p>{{user.userName}}</p>
      </div>
      <div>
        <mat-form-field>
          <mat-select
            [(value)]="user.userRole"
            (selectionChange)="changeUserRole($event, user.userId)"
          >
            <mat-option [value]="'USER'"
            >USER</mat-option
            >
            <mat-option  [value]="'PLATTFORMADMIN'"
            >PLATTFORMADMIN</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="center-elements">
        <mat-checkbox [(ngModel)]="user.hasComposerAccess"
                      (ngModelChange)="changeComposerAccess($event, user.userId)">
        </mat-checkbox>
      </div>
      <div class="center-elements">
        <app-hexagon-button-text-icon
          [text]="'Nutzer zu einer Organisation hinzufügen'"
          [icon]="'/assets/img/icons/Organisation_hinzufuegen.png'"
          (click)="onAddOrganisationToggle(i)"
        >
        </app-hexagon-button-text-icon>
      </div>
      <div class="center-elements">
        <button mat-raised-button
                class="btn_green"
                (click)="deleteUser(user.userId)"
        >Nutzer löschen</button>
      </div>
      
      <div *ngIf="dataSourceUserRoles.addOrganisationMode[i]">
        <div class="columns-50-50">
          <mat-form-field>
            <mat-select
              placeholder="Organisation"
              [(ngModel)]="addOrganisation.organisationName"
            >
              <mat-option
                *ngFor="let organisation of organisations"
                value="{{ organisation }}"
              >{{ organisation }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Rolle" [(value)]="addOrganisation.role">
              <mat-option [value]="'ADMIN'">Administrator</mat-option>
              <mat-option [value]="'USER'">Benutzer</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="columns-50-50">
            <button
              mat-raised-button
              class="btn_green"
              (click)="addOrganisationMembership(i)"
            >
              Ok
            </button>
            <button
              mat-raised-button
              class="btn_red"
              (click)="onAddOrganisationToggle(i)"
            >
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
    <mat-paginator
      [length]="totalNumberOfElements | async"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10]"
    ></mat-paginator>
  </div>
</div>
