import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'hex-label-button',
  templateUrl: './hex-label-button.component.html',
  styleUrls: ['./hex-label-button.component.css']
})
export class HexLabelButtonComponent implements OnInit {

  @Input() label: string;

  @Input() whiteBorder?: boolean;

  constructor() { }

  ngOnInit() { }

}
