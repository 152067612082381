<div class="grid" *ngIf="certificationEdit != undefined">
  <app-info-box [certification]="this.certificationEdit"></app-info-box>
  <div class="version-divider">
    <h2>Varianten</h2>
    <div class="dividerline"></div>
  </div>
  <div>
    <!-- TODO: FIX missing Parameterns in case of undefined; flache Scope-Elemente -->
    <app-info-box *ngFor="let variant of this.certificationEdit.certificationVariants" [certificationVariant]="variant">
    </app-info-box>
  </div>
</div>
