import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from "@angular/core";
import { Router } from "@angular/router";
import { ComposerToolchainInstanceOverview } from 'src/app/model/composer/composer-toolchain-instance-overview';
import { ToolChainInstanceStatus } from "src/app/model/composer/composer-toolchain-instance-status-interface";
import { ComposerToolchainTemplateOverview } from 'src/app/model/composer/composer-toolchain-overview-interface';
import { ComposerService } from 'src/app/services/composer-service/composer.service';
import { UserService } from 'src/app/services/user-service/userservice';
import { HeaderServiceService } from "../../../services/header-service/header-service.service";
import { loadIcons } from "../model-editor/util/preloadedAssets";
import {DialogService} from "../../../services/dialog-service/dialog-service.service";
import { CamundaProcessListComponent } from "../../camunda/camunda-process-list/camunda-process-list.component";
@Component({
  selector: "app-composer-screen",
  templateUrl: "./composer-screen.component.html",
  styleUrls: ["./composer-screen.component.css"],
})
export class ComposerScreenComponent implements OnInit {

  public toolchainTemplates: ComposerToolchainTemplateOverview[];

  public toolchainInstances: ComposerToolchainInstanceOverview[];

  constructor(
    private headerService: HeaderServiceService,
    private router: Router,
    private composerService: ComposerService,
    private userService: UserService,
    private changeDetector: ChangeDetectorRef,
    private dialogService: DialogService,
    private camundaProcessList: CamundaProcessListComponent,

  ) {
    //Preloads the Icons used within the BPMN Viewer
    loadIcons().subscribe();
  }

  ngOnInit(): void {
    this.headerService.loadHeaderText("COMPOSER");
    this.loadToolchainTemplatesAndInstances();
  }

  /* Redirects to a new Template Page */
  public addNewTemplate() {
    this.router.navigateByUrl("/composer/template/new");
  }

  public addEntryToSelected() {
  }

  public deleteToolchainTemplate(templateId) {
    this.dialogService.openConfirmDialog("Toolchain-Template Löschen", "Abbrechen", "Löschen", "Wollen Sie das Template wirklich löschen?")
      .subscribe((res) => {
        if (res) {
          this.composerService.deleteToolchainTemplate(templateId)
            .subscribe(() => {
              this.loadToolchainTemplatesAndInstances();
            });
        }
      });
  }

  public deleteToolchain(toolchainId) {
    this.dialogService.openConfirmDialog("Toolchain-Instanz Löschen", "Abbrechen", "Löschen", "Wollen Sie die Instanz wirklich löschen?")
      .subscribe((res) => {
        if (res) {
          this.composerService.deleteToolchain(toolchainId)
            .subscribe(() => {
              this.loadToolchainTemplatesAndInstances();
            });
        }
      });
  }

  public loadToolchainTemplatesAndInstances() {
    this.composerService.getToolchainInstancesForUser(this.userService.currentUser.userId)
      .subscribe((toolchainInstances) => {
        if (toolchainInstances) {
          this.toolchainInstances = toolchainInstances;
          this.changeDetector.detectChanges();
        }
      });

    this.composerService.getToolchainsForUser(this.userService.currentUser.userId)
      .subscribe((toolchainTemplates) => {
        if (toolchainTemplates) {
          this.toolchainTemplates = toolchainTemplates;
          this.changeDetector.detectChanges();
        }
      });
  }
}
