import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { ProductVariantInterface } from "src/app/model/products/product-variant-interface";
import { ProductInterface } from "src/app/model/products/product-interface";
import { VariantVersionInterface } from "src/app/model/products/variant-version-interface";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { ProductCertificationService } from "src/app/services/product-certification-service/product-certification.service";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { CertificationTypes } from "src/app/model/enums/certificationTypes";
import { ProductCertificationInformationInterface } from 'src/app/model/product-certification/product-certification-information-interface';
import { Router } from '@angular/router';

@Component({
  selector: "app-product-detail-display-certificates",
  templateUrl: "./product-detail-display-certificates.component.html",
  styleUrls: [
    "./product-detail-display-certificates.component.css",
    "../product-detail-display.css",
  ],
})
export class ProductDetailDisplayCertificatesComponent implements OnInit {
  @Input()
  product: ProductInterface;

  attr_certificates: ProductCertificationInformationInterface[] = [];
  ptg_certificates: ProductCertificationInformationInterface[] = [];
  tc_certificates:ProductCertificationInformationInterface[] = [];


  displayColumnsCertificate: string[] = ["Zertifikat", "Variante", "Version"];

  routeToCertificationDetailPage(id: number) : void {

      this.router.navigateByUrl('/privacy', {skipLocationChange: true}).then(() => {

        this.router.navigateByUrl('/marktplatz?tab=1&certificationId=' + id);
     });
  }



  updateCertificates(version: VariantVersionInterface) {
    this.attr_certificates = [];
    this.ptg_certificates = [];
    this.tc_certificates = [];

    //-----------------------------------------------------------------------
    this.pCertificationService
     .getCertificationInformationOfProductVersion(version.id)
      .subscribe(
        // success
        (info: ProductCertificationInformationInterface[]) => {

          info.forEach((certificate: ProductCertificationInformationInterface) => {
            switch(certificate.certification.certificationType)
            {
              case CertificationTypes.TOOLCHAIN_CERT : {
                this.tc_certificates.push(certificate);
                break;
              }

              case CertificationTypes.ATTRIBUTE_CERT : {
                this.attr_certificates.push(certificate);
                break;
              }

              case CertificationTypes.PTG_CERT : {
                this.ptg_certificates.push(certificate);
                break;
              }
            }
          })
          if (!this.cd["destroyed"]) {
            this.cd.detectChanges();
          }
          //--------------------------------------------------------
          /*info.forEach((certificate: ProductCertificationInformationInterface) => {
            //----------------------------------------------
            let certificateDisplayString = this.constructCertificateString(
              certificate
            );
            //----------------------------------------------
            switch (certificate.certification.certificationType) {
              case CertificationTypes.TOOLCHAIN_CERT: {
                // toolchainCertification
                this.tc_certificates.push(certificateDisplayString);
                break;
              }
              case CertificationTypes.ATTRIBUTE_CERT: {
                // attributeCertification
                this.attr_certificates.push(certificateDisplayString);
                break;
              }
              case CertificationTypes.PTG_CERT: {
                // ptgCertification
                this.ptg_certificates.push(certificateDisplayString);
                break;
              }
              default: {
              }
            }
            //----------------------------------------------
          });

          if (!this.cd["destroyed"]) {
            this.cd.detectChanges();
          } */
          //--------------------------------------------------------
        },
        // failure
        (e) => {}
      );
    //-----------------------------------------------------------------------
  }

  constructCertificateString(certificate: ProductCertificationInformationInterface) {
    //-----------------------------------------------------------------------
    let certification = certificate.certification;
    let variant = certificate.variant;
    let version = certificate.version;
    //-----------------------------------------------------------------------
    return certification.name + " " + variant.name + " " + version.name;
  }

  public hasAtLeastOneCertificate(): boolean {
    return (
      (this.attr_certificates && this.attr_certificates.length > 0) ||
      (this.ptg_certificates && this.ptg_certificates.length > 0) ||
      (this.tc_certificates && this.tc_certificates.length > 0)
    );
  }

  constructor(
    private pCertificationService: ProductCertificationService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {}


  ngOnChanges(product: ProductInterface) {
    this.attr_certificates = [];
    this.ptg_certificates = [];
    this.tc_certificates = [];
    this.cd.detectChanges();
  }

  ngOnInit() {
  }
}
