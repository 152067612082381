import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { ComposerProductFileInterface } from "src/app/model/composer/composer-product-file-interface";
import { ToolChainInstanceStatus } from "src/app/model/composer/composer-toolchain-instance-status-interface";
import { UserService } from "src/app/services/user-service/userservice";
import * as BpmnJS from "bpmn-js/dist/bpmn-viewer.production.min.js";
import ForkEventRender from "../../model-editor/custom/renderer/forkEventRender";
import JoinEventRender from "../../model-editor/custom/renderer/joinEventRender";
import ToolchainItemRender from "../../model-editor/custom/renderer/toolchainItemRender";
import { ComposerModdle } from "../../model-editor/custom/composerModdle";
import { ComposerService } from "src/app/services/composer-service/composer.service";
import { Router } from "@angular/router";
import { ComposerToolchainInstanceOverview } from "src/app/model/composer/composer-toolchain-instance-overview";
import ToolchainInstanceItemRender from "../../model-editor/custom/renderer/toolchainInstanceItemRender";

@Component({
  selector: "app-toolchain-instance-table-row",
  templateUrl: "./toolchain-instance-table-row.component.html",
  styleUrls: ["./toolchain-instance-table-row.component.css"],
})
export class ToolchainInstanceTableRowComponent implements OnInit {
  @Input()
  public data: ComposerToolchainInstanceOverview;

  @Input()
  public index: number;

  @Output() deleteToolchainEvent = new EventEmitter<number>();

  public collapsed: boolean = true;

  private bpmnJsViewer: BpmnJS;

  public xml: string;

  private xmlFileIds: ComposerProductFileInterface[];

  private canvas: Node;

  public editorName: String;

  constructor(
    private userService: UserService,
    private changeDetector: ChangeDetectorRef,
    private composerService: ComposerService,
    private router: Router
  ) {}

  ngOnInit() {}

  /* Returns whether the ToolchainStatus is Failed or not */
  public isFailed(data: ComposerToolchainInstanceOverview): boolean {
    return data.status === ToolChainInstanceStatus.FAILED;
  }

  /* Returns whether the ToolchainStatus is Finished or not */
  public isFinished(data: ComposerToolchainInstanceOverview): boolean {
    return data.status === ToolChainInstanceStatus.FINISHED;
  }

  /* Returns whether the ToolchainStatus is Success or not */
  public isSuccess(data: ComposerToolchainInstanceOverview): boolean {
    return data.status === ToolChainInstanceStatus.SUCCESS;
  }

  /* Returns whether the ToolchainStatus is Working or not */
  public isWorking(data: ComposerToolchainInstanceOverview): boolean {
    return data.status === ToolChainInstanceStatus.WORKING;
  }

  ngAfterContentInit() {
    if (this.data) {
      //Parses Status String to Enum Value
      this.data.status = ToolChainInstanceStatus[this.data.status];

      this.userService.getNameOfUser(this.data.userId).subscribe((userName) => {
        if (userName) {
          this.editorName = userName;
          this.changeDetector.detectChanges();
        }
      });
    }
  }

  /* Toggles the Collapse Status of the Row */
  public toggleCollapse() {
    this.collapsed = !this.collapsed;
    if (!this.collapsed && this.data) {
      this.canvas = document.getElementById(
        "instanceViewer" + this.data.toolchainId
      );

      if (!this.bpmnJsViewer) {
        this.loadXMLFromBackend();
      } else {
        this.loadXML();
      }
    }
  }

  /* Loads the needed XML from the Backend */
  private loadXMLFromBackend() {
    this.composerService
      .loadXMLForToolchain(this.data.toolchainId)
      .subscribe((xmlAndFileIds) => {
        if (xmlAndFileIds) {
          this.xml = xmlAndFileIds.xml;
          this.xmlFileIds = xmlAndFileIds.fileIds;
          this.changeDetector.detectChanges();

          this.composerService
            .loadDataUrlsForProductsInXml(this.xmlFileIds, this.xml, false)
            .subscribe((updatedXML) => {
              if (updatedXML) {
                this.xml = updatedXML;
                this.createBpmnJSViewerInstance();
              }
            });
        }
      });
  }

  /* Creates an Instance of a BpmnJS-Viewer */
  private createBpmnJSViewerInstance() {
    this.bpmnJsViewer = new BpmnJS({
      container: "#instanceViewer" + this.data.toolchainId,
      additionalModules: [
        {
          __init__: ["forkEventRender"],
          forkEventRender: ["type", ForkEventRender],
        },
        {
          __init__: ["joinEventRender"],
          joinEventRender: ["type", JoinEventRender],
        },
        {
          __init__: ["toolchainItemRender"],
          toolchainItemRender: ["type", ToolchainItemRender],
        },
        {
          __init__: ["toolchainInstanceItemRender"],
          toolchainInstanceItemRender: ["type", ToolchainInstanceItemRender],
        },
      ],
      //Custom Definition Elements
      moddleExtensions: {
        composer: ComposerModdle,
      },
    });

    this.canvas = document.getElementById(
      "instanceViewer" + this.data.toolchainId
    );

    this.bpmnJsViewer.attachTo(this.canvas);

    this.bpmnJsViewer.on("import.done", ({ error }) => {
      if (!error) {
        setTimeout(() => {
          this.bpmnJsViewer.get("canvas").zoom("fit-viewport");
        }, 500);
      }
    });

    this.loadXML();
  }

  /* Opens the Template used for this Instance */
  public openTemplate() {
    this.router.navigateByUrl(
      "/composer/template/" + this.data.toolchainTemplateId
    );
  }

  /* Opens the Instance */
  public openInstance() {
    this.router.navigateByUrl(
      "/composer/instance/" + this.data.toolchainId
    );
  }

  /* Imports the XML into the BpmnJs Viewer */
  private loadXML() {
    this.bpmnJsViewer.importXML(this.xml).then((success) => {});
  }

  deleteToolchain() {
    this.deleteToolchainEvent.emit(this.data.toolchainId);
  }
}
