import {
  Component,
  Inject,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {FileServiceService} from "../../services/file-service/file-service.service";

@Component({
  selector: "app-dialogfilemanager-confirm",
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>

    <div mat-dialog-content>
      <filemanager
        [multiSelectMode]="data.multiSelect"
        [uploadDisabled]="data.uploadDisable"
        [filter]="data.filter"
        [fileId]="data.fileId"
        (selectedFileChanged)="fileChanged($event)"
      ></filemanager>
    </div>
    <div mat-dialog-actions>
      <button
        [style]="'height: 35px;width: 100px;display: flex;justify-content: center;'"
        mat-button
        (click)="onClick(true)"
        class="btn_green "
        [disabled]="fileService.uploading"
        cdkFocusInitial
      ><span *ngIf="!fileService.uploading">
        {{ data.buttonYes }}
      </span>
        <mat-spinner
         [style]="'stroke: #ffffff'"
         *ngIf="fileService.uploading" [diameter]="35"></mat-spinner>
      </button>
      <button
        [disabled]="!fileService.uploading"
        [style]="'height: 35px;width: 100px;display: flex;justify-content: center;'"
        mat-button
        (click)="onClick(false)"
        class="btn_red"
        cdkFocusInitial
      >
        {{ data.buttonNo }}
      </button>
    </div>
  `,
  //styleUrls: ['src/app/components/components-miscellaneous/dialog.css']
})
export class DialogFileManagerComponent {
  private selected: [];
  constructor(
    public dialogRef: MatDialogRef<DialogFileManagerComponent>,
    public fileService: FileServiceService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  fileChanged(selection) {
    this.selected = selection;
  }

  onClick(confirmed: boolean): void {
    this.dialogRef.close(confirmed ? this.selected : []);
  }
}
