<div style="display: flex; flex-direction: column; padding-bottom: 3rem;">
  <!------------------------------------------------------------------------------------>
  <!--########################       AVERAGE SCORE + TABLE        ####################-->
  <!------------------------------------------------------------------------------------>
  <h1>Bewertungen</h1>
  <div *ngIf="this.reviews.length == 0" class="italic">
    <p>Für dieses Produkt liegen noch keine Bewertungen vor.</p>
  </div>
  <!------------------------------------------------------------------------------------>
  <div
    #averageScore
    style="display: grid; grid-template-columns: 24em 64%; column-gap: 2em; margin-top: 2%; width: 100%"
    class="item"
    *ngIf="this.reviews.length > 0"
  >
    <!--------------------------------------------------------------------------->
    <div
      style="
        display: flex;
        flex-direction: row;
        width: max-content;
        align-items: center;
        padding-left: 1vw;
      "
    >
      <!----------------------------------------------------------------->
      <div class="h7">{{ average | number: "1.1-1" }}</div>
      <!----------------------------------------------------------------->
<!--      <div *ngIf="this.average">-->
<!--        <app-star [rating]="this.average"></app-star>-->
<!--      </div>-->
      <!----------------------------------------------------------------->
    </div>
    <!--------------------------------------------------------------------------->
    <div>
      <app-review-table
        *ngIf="this.nrOfReviews"
        [reviewData]="this.nrOfReviews"
      ></app-review-table>
    </div>
    <!--------------------------------------------------------------------------->
  </div>
  <!------------------------------------------------------------------------------------>
  <!--##########################         BUTTON ROW         ##########################-->
  <!------------------------------------------------------------------------------------>

  <!------------------------------------------------------------------------------------>
  <!--##########################        COMMENT TABLE       ##########################-->
  <!------------------------------------------------------------------------------------>
  <div *ngIf="reviews">
    <app-reviews
      [reviews]="reviews"
      [targetId]="this.targetId"
      [reviewType]="this.reviewType"
      [showWriteReview]="showWriteReview"
      (reviewCloseEmitter)="reviewCloseEmitter.emit();"
      (reviewOpenEmitter)="reviewOpenEmitter.emit();"
      (reviewsChanged)="this.onReviewsChanged($event)"
    >
    </app-reviews>
    <!--------------------------------------------------------------------------->
  </div>
  <!------------------------------------------------------------------------------------>
</div>
