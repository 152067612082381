import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { Router } from "@angular/router";
import * as BpmnJS from "bpmn-js/dist/bpmn-viewer.production.min.js";
import { ComposerProductFileInterface } from "src/app/model/composer/composer-product-file-interface";
import { ComposerToolchainTemplateOverview } from "src/app/model/composer/composer-toolchain-overview-interface";
import { ComposerService } from "src/app/services/composer-service/composer.service";
import { UserService } from "src/app/services/user-service/userservice";
import { ComposerModdle } from "../../model-editor/custom/composerModdle";
import ForkEventRender from "../../model-editor/custom/renderer/forkEventRender";
import JoinEventRender from "../../model-editor/custom/renderer/joinEventRender";
import ToolchainItemRender from "../../model-editor/custom/renderer/toolchainItemRender";
import {ComposerToolchainInstanceOverview} from "../../../../model/composer/composer-toolchain-instance-overview";
import {DialogService} from "../../../../services/dialog-service/dialog-service.service";

@Component({
  selector: "app-toolchain-template-table-row",
  templateUrl: "./toolchain-template-table-row.component.html",
  styleUrls: [
    "./../toolchain-instance-table-row/toolchain-instance-table-row.component.css",
    "./toolchain-template-table-row.component.css",
  ],
})
export class ToolchainTemplateTableRowComponent implements OnInit {
  @Input()
  public data: ComposerToolchainTemplateOverview;

  @Input()
  public index: number;

  @Input()
  public instances: ComposerToolchainInstanceOverview[];

  @Output() deleteTemplateEvent = new EventEmitter<number>();

  @Output() deleteToolchainEvent = new EventEmitter<number>();

  @Output() reloadToolchainsAndTemplatesEvent = new EventEmitter<any>();

  public collapsed: boolean = true;

  private bpmnJsViewer: BpmnJS;

  public xml: string;

  private xmlFileIds: ComposerProductFileInterface[];

  private canvas: Node;

  public editorName: String;

  public creatorName: String;

  constructor(
    private router: Router,
    private composerService: ComposerService,
    private changeDetector: ChangeDetectorRef,
    private userService: UserService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {}

  ngAfterContentInit() {
    if (this.data) {
      this.userService
        .getNameOfUser(this.data.lastEditor)
        .subscribe((userName) => {
          if (userName) {
            this.editorName = userName;
            if (!this.changeDetector["destroyed"]) {
              this.changeDetector.detectChanges();
            }
          }
        });

      if (this.data.lastEditor !== this.data.userId) {
        this.userService
          .getNameOfUser(this.data.userId)
          .subscribe((userName) => {
            if (userName) {
              this.creatorName = userName;
              if (!this.changeDetector["destroyed"]) {
                this.changeDetector.detectChanges();
              }
            }
          });
      }
    }
  }

  /* Toggles the Collapse Status of the Row */
  public toggleCollapse() {
    this.collapsed = !this.collapsed;
    if (!this.collapsed && this.data) {
      this.canvas = document.getElementById(
        "viewerContainer" + this.data.toolchainId
      );

      if (!this.bpmnJsViewer) {
        this.loadXMLFromBackend();
      } else {
        this.loadXML();
      }
    }
  }

  /* Loads the needed XML from the Backend */
  private loadXMLFromBackend() {
    this.composerService
      .loadXMLForToolchain(this.data.toolchainId)
      .subscribe((xmlAndFileIds) => {
        if (xmlAndFileIds) {
          this.xml = xmlAndFileIds.xml;
          this.xmlFileIds = xmlAndFileIds.fileIds;
          if (!this.changeDetector["destroyed"]) {
            this.changeDetector.detectChanges();
          }

          this.composerService
            .loadDataUrlsForProductsInXml(this.xmlFileIds, this.xml, true)
            .subscribe((updatedXML) => {
              if (updatedXML) {
                this.xml = updatedXML;
                setTimeout(() => {
                  this.createBpmnJSViewerInstance();
                }, 200);
              }
            });
        }
      });
  }

  /* Opens the Editor with the Template corresponding to the Row */
  public openTemplate() {
    this.router.navigateByUrl("/composer/template/" + this.data.toolchainId);
  }

  /* Creates an Instance of a BpmnJS-Viewer */
  private createBpmnJSViewerInstance() {
    this.bpmnJsViewer = new BpmnJS({
      container: "#viewerContainer" + this.data.toolchainId,
      additionalModules: [
        {
          __init__: ["forkEventRender"],
          forkEventRender: ["type", ForkEventRender],
        },
        {
          __init__: ["joinEventRender"],
          joinEventRender: ["type", JoinEventRender],
        },
        {
          __init__: ["toolchainItemRender"],
          toolchainItemRender: ["type", ToolchainItemRender],
        },
      ],
      //Custom Definition Elements
      moddleExtensions: {
        composer: ComposerModdle,
      },
    });

    this.bpmnJsViewer.attachTo(this.canvas);

    this.bpmnJsViewer.on("import.done", ({ error }) => {
      if (!error) {
        setTimeout(() => {
          this.bpmnJsViewer.get("canvas").zoom("fit-viewport");
        }, 500);
      }
    });

    this.loadXML();
  }

  /* Imports the XML into the BpmnJs Viewer */
  private loadXML() {
    this.bpmnJsViewer.importXML(this.xml).then((success) => {});
  }

  /* Create a new Toolchain Instance based on this Template */
  public createToolchainInstance() {
    if (this.data.valid) {
      this.composerService
        .createToolchainInstance(this.data.toolchainId).subscribe((res) => {
          this.dialogService.openDialog("Toolchain-Instanz angelegt", "Die Toolchain-Instanz wurde erfolgreich angelegt.").subscribe(() => {
            this.reloadToolchainsAndTemplatesEvent.emit();
          });
      });
    }
  }

  deleteToolchainTemplate() {
    this.deleteTemplateEvent.emit(this.data.toolchainId);
  }

  deleteToolchain(event) {
    this.deleteToolchainEvent.emit(event);
  }
}
