import { ChangeDetectorRef } from '@angular/core';
import { Component, Input, OnInit } from "@angular/core";
import { CheckboxControlValueAccessor } from '@angular/forms';
import { isEqualWith } from 'lodash';
import { LinkingMethod } from 'src/app/model/enums/linkingMethod';

@Component({
  selector: "app-common-feature-item-edit-form",
  templateUrl: "./common-feature-item-edit-form.component.html",
  styleUrls: ["./common-feature-item-edit-form.component.css"],
})
export class CommonFeatureItemEditFormComponent implements OnInit {
  public showEntryFilloutHint: boolean = false;
  public showNameMustBeFilled: boolean = false;
  public showAtLeastOneEntry: boolean = false;

  @Input()
  public name: string;

  @Input()
  public filterOption: boolean;

  @Input()
  public showDetailPage: boolean;

  @Input()
  public showFeatureEntry: boolean;

  @Input()
  public index: number;

  @Input() 
  linkingMethodInput: String;

  @Input()
  public tempFeatureEntries: string[] = [];

  readonly featureEntryId = "featureEntry";
  readonly featureNameId = "featureName";
  readonly featureFilterOptionId = "featureFilterOption";
  readonly showDetailOnPage = "showDetailOnPage";


  public linkingMethod: { 
    id: Number;
    linkingMethod: LinkingMethod;
    disabled: Boolean;
    checked: Boolean;
  }[] = [
    {
      id: 0,
      linkingMethod: LinkingMethod.AND,
      disabled: false,
      checked: true,
    },
    {
      id: 1,
      linkingMethod: LinkingMethod.OR,
      disabled: true,
      checked: false
    },
    {
      id: 2,
      linkingMethod: LinkingMethod.XOR,
      disabled: true,
      checked: false
    }
  ];
   

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges() { 
    switch(this.linkingMethodInput) 
    {
      case  "AND": 
     
            this.linkingMethod[0].checked = true;
            this.linkingMethod[1].checked = false;
            this.linkingMethod[2].checked = false
            //this.disableCheckboxes(0);
            break;
      case  "OR":
            this.linkingMethod[0].checked = false
            this.linkingMethod[1].checked = true;
            this.linkingMethod[2].checked = false
            //this.disableCheckboxes(1);
            break;
      case  "XOR": 

            this.linkingMethod[0].checked = false;
            this.linkingMethod[1].checked = false;
            this.linkingMethod[2].checked = true;
            //this.disableCheckboxes(2);
            break;
    }
    this.cd.detectChanges();
  }


  ngOnInit() {}

  /* Shows the Frontend Form to add a new Entry for a Feature */
  public showFeatureEntryForm(): void {
    if (this.showFeatureEntry) {
      this.updateCurrentEntries();

      const newFeatureEntryInput = document.getElementById(
        this.featureEntryId + this.index
      ) as HTMLInputElement;

      if (newFeatureEntryInput.value.length === 0) {
        this.showEntryFilloutHint = true;
      } else {
        this.tempFeatureEntries.push(newFeatureEntryInput.value);
        newFeatureEntryInput.value = "";
        this.showEntryFilloutHint = false;
      }
    } else {
      this.showFeatureEntry = true;
    }
  }

  /* Returns an Object with the current Feature Elements */
  public getElements() {
    const featureName = document.getElementById(
      this.featureNameId + this.index
    ) as HTMLInputElement;

    const showDetailPageCheckbox: HTMLInputElement = document.getElementById(
      this.showDetailOnPage + this.index
    ).firstChild.firstChild.firstChild as HTMLInputElement;

    const filterOptionCheckBox: HTMLInputElement = document.getElementById(
      this.featureFilterOptionId + this.index
    ).firstChild.firstChild.firstChild as HTMLInputElement;

    const newFeature = this.showFeatureEntry
      ? (document.getElementById(
          this.featureEntryId + this.index
        ) as HTMLInputElement)
      : undefined;

    var linkerMethod: LinkingMethod;
     
      this.linkingMethod.forEach((linkMethod) => {
        if(linkMethod.checked){
           linkerMethod = linkMethod.linkingMethod;
        }
      })
  


    this.showNameMustBeFilled = this.isFeatureNameEmpty(featureName);

    this.showAtLeastOneEntry = this.showNameMustBeFilled
      ? false
      : this.isNoEntryFilled(
          this.tempFeatureEntries,
          this.showFeatureEntry,
          newFeature
        );

    if (this.showNameMustBeFilled || this.showAtLeastOneEntry) {
      return undefined;
    }

    this.updateCurrentEntries();

    if (this.showFeatureEntry && newFeature.value.length > 0) {
      this.tempFeatureEntries.push(newFeature.value);
      newFeature.value = "";
      this.showFeatureEntry = false;
    }

    const isCheckedFilterOption: boolean = filterOptionCheckBox.checked;

    const isCheckedDetailPage: boolean = showDetailPageCheckbox.checked;

    return {
      name: featureName.value,
      filterOption: isCheckedFilterOption,
      featureEntries: this.tempFeatureEntries,
      showDetailPage: isCheckedDetailPage,
      linkerMethod
    };
  }

  /* Disables the new Entry Form Input */
  public removeNewFeatureEntry(): void {
    this.showFeatureEntry = false;
  }

  /* Removes an Entry from the current Feature */
  public removeFeatureEntry(id: number): void {
    this.updateCurrentEntries();

    this.tempFeatureEntries.splice(id, 1);
  }

  /* Updates the currently saved Entries */
  public updateCurrentEntries(): void {
    for (let i = 0; i < this.tempFeatureEntries.length; i++) {
      const featureEntry = document.getElementById(
        this.featureEntryId + this.index + "," + i
      ) as HTMLInputElement;

      if (featureEntry.value.length > 0) {
        this.tempFeatureEntries[i] = featureEntry.value;
      }
    }
  }

  public isFeatureNameEmpty(featureName: HTMLInputElement): boolean {
    if (featureName.value.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  public disableCheckboxes(id: number){
      switch(id) {
        case 0:
            if(this.linkingMethod[0].checked)
            {
              this.linkingMethod[0].disabled = false;
              this.linkingMethod[1].checked = false;
              this.linkingMethod[1].disabled = true;
              this.linkingMethod[2].checked = false;
              this.linkingMethod[2].disabled = true;
            } else { 
              this.linkingMethod[1].disabled = false;
              this.linkingMethod[2].disabled = false;
            }
          break;
        case 1:
          if(this.linkingMethod[1].checked)
          {
            this.linkingMethod[1].disabled = false;
            this.linkingMethod[0].checked = false;
            this.linkingMethod[0].disabled = true;
            this.linkingMethod[2].checked = false;
            this.linkingMethod[2].disabled = true;
          } else { 
            this.linkingMethod[0].disabled = false;
            this.linkingMethod[2].disabled = false;
          }
          break;
        case 2:
          if(this.linkingMethod[2].checked)
          {
            this.linkingMethod[2].disabled = false;
            this.linkingMethod[0].checked = false;
            this.linkingMethod[0].disabled = true;
            this.linkingMethod[1].checked = false;
            this.linkingMethod[1].disabled = true;
          } else { 
            this.linkingMethod[1].disabled = false;
            this.linkingMethod[0].disabled = false;
          }
          break;
     }
  }

  public isNoEntryFilled(
    tempFeatureEntries: string[],
    showFeatureEntry: boolean,
    newFeature: HTMLInputElement
  ) {
    const tempFeatureEntriesEmpty = tempFeatureEntries.length === 0;
    const noNewFeatureorNewFeatureEmpty =
      !showFeatureEntry || newFeature.value.length === 0;
    if (tempFeatureEntriesEmpty && noNewFeatureorNewFeatureEmpty) {
      return true;
    }

    return false;
  }
}
