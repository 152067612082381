import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebsocketComponent } from '../web-socket/web-socket.component';
import { FileHandlerService } from '../file-handler/file-handler.service';
import axios from 'axios';
import { Subscription, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BpmnDiagramComponent } from '../bpmn-diagram-component/bpmn-diagram.component'; // Update path

@Component({
  selector: 'app-camunda-task',
  templateUrl: './camunda-task.component.html',
  styleUrls: ['./camunda-task.component.css']
})
export class CamundaTaskComponent implements OnInit {
  @ViewChild('websocket', { static: true }) websocket!: WebsocketComponent;
  @ViewChild(BpmnDiagramComponent, { static: true }) bpmnDiagramComponent!: BpmnDiagramComponent;

  bpmnXml: string = '';
  shouldRenderList: any[] = [];
  shouldNotRenderList: any[] = [];
  manualTasks: any[] = [];
  process: any;
  serviceTasks: any[] = [];
  extractedFiles: any[] = [];
  responseFiles: any[] = [];
  inputFiles: any[] = [];
  serviceTasksDatabase: any;

  private checkManualTasksTimeout: any;
  private checkToStart: any = 0;
  private socketSubscription: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fileHandlerService: FileHandlerService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.serviceTasksDatabase = require('./assets/BIMKIT_database.json');
    const navigationState = history.state;
    if (!navigationState || !navigationState.bpmnXml || !navigationState.shouldRenderList ||
      !navigationState.shouldNotRenderList || !navigationState.manualTasks || !navigationState.process) {
      this.router.navigate(['/startprocess/list']);
      return;
    }
    this.bpmnXml = navigationState.bpmnXml;
    this.shouldRenderList = navigationState.shouldRenderList;
    this.shouldNotRenderList = navigationState.shouldNotRenderList;
    this.manualTasks = navigationState.manualTasks;
    this.process = navigationState.process;
    this.checkManualTasksTimeout = setTimeout(() => {
      this.checkManualTasks();
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.websocket) { this.websocket.closeWebSocket(); }
    this.socketSubscription.unsubscribe();
  }

  checkManualTasks(): void {
    if (this.manualTasks.length === this.serviceTasks.length && this.checkToStart === 0) {
      this.checkToStart = 1;
      if (this.websocket) { this.websocket.closeWebSocket(); }
      this.socketSubscription.unsubscribe();
      this.performActionOnTasks(this.serviceTasks[0]);
    } else {
      this.checkManualTasksTimeout = setTimeout(() => {
        this.checkManualTasks();
      }, 5000);
    }
  }

  sendMessage(process: any): void {
    if (!this.websocket || this.websocket.getReadyState() !== WebSocket.OPEN) {
      console.error('WebSocket connection not open.');
      return;
    }
    this.websocket.sendMessage(JSON.stringify(process));
  }

  loadServiceTasksDatabase(): void {
    this.http.get<any>('assets/BIMKIT_database.json').subscribe(
      data => {
        this.serviceTasksDatabase = data;
      },
      error => {
        console.error('Error loading service tasks database:', error);
      }
    );
  }

  handleSocketReady(socket: WebSocket): void {
    socket.addEventListener('message', (event: MessageEvent) => {
      this.handleSocketMessage(event);
    });
  }

  restartProcess(): void {
    this.checkManualTasksStatus()
  }

  checkManualTasksStatus(): void {
    if (this.manualTasks.length == this.serviceTasks.length) {
      this.performActionOnTasks(this.serviceTasks[0]);
    }
  }

  handleSocketMessage(event: MessageEvent): void {
    const receivedMessage = event.data;
    let jsonMessage: any;
    try { jsonMessage = JSON.parse(receivedMessage); }
    catch (error) { return; }
    if (jsonMessage.bpmnKey
      && jsonMessage.dataAssociations
      && jsonMessage.id
      && jsonMessage.name
      && jsonMessage.readyState) {
      this.handleServiceModel(jsonMessage);
    }
    else { this.handleOtherMessages(jsonMessage); }
  }

  handleServiceModel(serviceModel: any): void {
    this.serviceTasks.push(serviceModel);
    this.bpmnDiagramComponent.updateNodeStyle(serviceModel);
    this.cdr.detectChanges();
  }

  handleOtherMessages(message: any): void {
  }

  async fetchFileWithRetry(file: string, retries: number = 3): Promise<any | null> {
    if (retries === 0) {
      return null; // Failed after all retries
    }

    const inputFileName = [...this.shouldRenderList, ...this.shouldNotRenderList].find(taskName => taskName.includes(file.toLowerCase()));
    if (!inputFileName) {
      return null; // File not found
    }

    try {
      const inputFile: Blob = await this.fileHandlerService.getFile(inputFileName.toLowerCase()).toPromise();
      return [inputFile, inputFileName];
    } catch (error) {
      console.error('Error fetching file:', error);
      await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 5 seconds before retrying
      return this.fetchFileWithRetry(file, retries - 1); // Retry with one less retry count
    }
  }

  async performActionOnTasks(task: any): Promise<void> {
    const { inputFiles, serviceTasks, shouldRenderList, shouldNotRenderList } = this;
    const currentTask = serviceTasks.find(t => t.name === task.name);
    const currentTaskIndex = serviceTasks.findIndex(t => t.name === task.name);
    const BIMKITService = this.serviceTasksDatabase[(task.name).replace(/Ã¤/g, "ä")];
    if (!BIMKITService) {
      const currentTaskIndex = serviceTasks.findIndex(t => t.name === task.name);
      serviceTasks[currentTaskIndex].readyState = 'failed';
      currentTask.readyState = 'failed';
      this.handleFileFetchError(task.id, "Service doesn't exist in Database");
      this.bpmnDiagramComponent.updateNodeStyle(currentTask);
      this.cdr.detectChanges();
      return;
    }
    if (BIMKITService) {
      task.serviceId = BIMKITService;
      currentTask.readyState = 'start';
      currentTask.fetchError = false;
      currentTask.message = "";
      serviceTasks[currentTaskIndex].readyState = 'start';
      serviceTasks[currentTaskIndex].fetchError = false;
      serviceTasks[currentTaskIndex].message = "";
      this.bpmnDiagramComponent.updateNodeStyle(currentTask);
      this.cdr.detectChanges();
      try {
        let fileFetchFailed = false;
        let fetchedFiles: { name: string, data: any }[] = [];

        await Promise.all(task.dataAssociations.sourceRef.map(async (file: string) => {
          const [inputFile, inputFileName] = await this.fetchFileWithRetry(file);
          if (inputFile) {
            const index = inputFiles.findIndex(input => input.name.toLowerCase().includes(file.toLowerCase()) && input.serviceId === BIMKITService);
            if (index !== -1) {
              inputFiles.splice(index, 1, { name: inputFileName, data: inputFile, serviceId: BIMKITService });
              fetchedFiles.splice(index, 1, { name: inputFileName, data: inputFile });
              this.cdr.detectChanges();
            }
            else if (index === -1) {
              inputFiles.push({ name: inputFileName, data: inputFile, serviceId: BIMKITService });
              fetchedFiles.push({ name: inputFileName, data: inputFile });
              this.cdr.detectChanges();
            }
          } else {
            fileFetchFailed = true;
          }
        }));

        if (fileFetchFailed) {
          await this.switchAndRetry(task);
          const currentTaskIndex = serviceTasks.findIndex(t => t.name === task.name);
          serviceTasks[currentTaskIndex].readyState = 'failed';
          currentTask.readyState = 'failed';
          this.handleFileFetchError(task.id, "");
          this.bpmnDiagramComponent.updateNodeStyle(currentTask);
          this.cdr.detectChanges();
          return;
        }
        let workerStatus = 'occupied';
        while (workerStatus === 'occupied') {
          // Check worker status
          const workerStatusResponse = await this.checkWorkerStatus();
          workerStatus = workerStatusResponse.workerStatus;
          if (workerStatus === 'occupied') {
            serviceTasks[currentTaskIndex].message = "API-Worker ist beschäftigt. Nach einer Verzögerung wird es noch einmal versucht...";
            this.cdr.detectChanges();
            await this.delay(5000);
          }
        }

        const BIMKITRequest = await this.sendBIMKITrequest(inputFiles, task, BIMKITService);
        if (BIMKITRequest) {
          currentTask.readyState = 'finished';
          serviceTasks[currentTaskIndex].readyState = 'finished';
          this.bpmnDiagramComponent.updateNodeStyle(serviceTasks[currentTaskIndex]);
          this.cdr.detectChanges();
          if (currentTaskIndex !== -1 && currentTaskIndex < serviceTasks.length - 1) {
            await this.performActionOnTasks(serviceTasks[currentTaskIndex + 1]);
            this.cdr.detectChanges();
          }
        } else {
          serviceTasks[currentTaskIndex].readyState = 'failed';
          this.bpmnDiagramComponent.updateNodeStyle(currentTask);
          this.cdr.detectChanges();
        }
      } catch (error) {
        console.error('Error sending BIMKIT request:', error);
        serviceTasks[currentTaskIndex].readyState = 'failed';
        this.bpmnDiagramComponent.updateNodeStyle(currentTask);
        this.cdr.detectChanges();
      }
    }
    this.cdr.detectChanges();
  }


  async switchAndRetry(task: any): Promise<void> {
    // Logic for switching file formats and retrying the task
    const { shouldRenderList, serviceTasks, shouldNotRenderList } = this;
    const currentTaskIndex = serviceTasks.findIndex(t => t.name === task.name);

    // Get the names of the output files of the last task
    const lastTaskOutputFiles = serviceTasks[currentTaskIndex - 1].dataAssociations.targetRef;

    // Generate new file names by switching their formats
    const newFiles = lastTaskOutputFiles.map(filename => {
      const fileExtension = filename.split('.').pop(); // Get file extension
      const fileNameWithoutExtension = filename.split('.').slice(0, -1).join('.'); // Get file name without extension
      let newFileFormat = '';

      // Determine the new file format based on the current format
      if (shouldRenderList.includes(fileNameWithoutExtension.toLowerCase())) {
        // If the file was previously rendered, switch to not render format
        newFileFormat = shouldNotRenderList.find(format => format.toLowerCase().includes(fileNameWithoutExtension.toLowerCase())) || '';
      } else if (shouldNotRenderList.includes(fileNameWithoutExtension.toLowerCase())) {
        // If the file was previously not rendered, switch to render format
        newFileFormat = shouldRenderList.find(format => format.toLowerCase().includes(fileNameWithoutExtension.toLowerCase())) || '';
      }
      // Construct new file name with the switched format
      return newFileFormat !== '' ? `${fileNameWithoutExtension}.${newFileFormat}.${fileExtension}` : filename;
    });
    // Upload each new file with its corresponding name
    newFiles.forEach(async (newFileName, index) => {
      await this.fileHandlerService.uploadWithName(serviceTasks[currentTaskIndex].dataAssociations.sourceRef[index], newFileName);
    });

    // Retry current task with the new files
    await this.performActionOnTasks({ ...task });
  }


  async checkWorkerStatus(): Promise<{ workerStatus: string }> {
    try {
      const response = await fetch('https://bimkit-portal-api-bimkit-ntag-portal.apps.k8s.nt.ag/jobs/worker-status');
      const data = await response.json();
      return { workerStatus: data.workerStatus };
    } catch (error) {
      console.error('Error checking worker status:', error);
      return { workerStatus: 'error' };
    }
  }

  async delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }



  handleFileFetchError(taskId: any, text: any): void {
    const updatedTasks = this.serviceTasks.map((task) => {
      if (task.id === taskId) {
        task.message = text;
        return { ...task, fetchError: true };
      }
      return task;
    });
    this.serviceTasks = updatedTasks;
    this.cdr.detectChanges();
  }

  async sendBIMKITrequest(inputFiles: any[], task: any, serviceID: any) {
    const { serviceTasks } = this;
    const apiUrl = 'https://bimkit-portal-api-bimkit-ntag-portal.apps.k8s.nt.ag/jobs/start-sync';
    const formData = new FormData();
    inputFiles.forEach((inputFile, index) => {
      if (inputFile.serviceId === serviceID) {
        formData.append(`files[]`, inputFile.data, inputFile.name);
      }
    });
    formData.append('job-name', 'BIMKIT-BIMSWARM');
    formData.append('service-id', task.serviceId);
    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const targetRefs = task.dataAssociations.targetRef;
      let fileType;
      const usedIndexes: number[] = [];
      await Promise.all(response.data.steps[0].intermediateResults.reverse().map(async (result: any, index: any) => {
        if (result.endsWith('.zip')) {
          fileType = 'zip';
        } else if (result.endsWith('.png')) {
          fileType = 'png';
        } else if (result.endsWith('.jpg')) {
          fileType = 'png';
        } else if (result.endsWith('.json')) {
          fileType = 'json';
        } else if (result.endsWith('.ifc')) {
          fileType = 'ifc';
        } else if (result.endsWith('.ply')) {
          fileType = 'ply';
        } else if (result.endsWith('.obj')) {
          fileType = 'obj';
        } else if (result.endsWith('.h5')) {
          fileType = 'h5';
        } 
        else {
          fileType= '';
        }
        let foundFilename: string | undefined;
        if (fileType || fileType === '') {
          const combinedList = [...this.shouldRenderList, ...this.shouldNotRenderList];
          const matchingFilenames = combinedList.filter((filename: string) => filename.endsWith("." + fileType));
          
          if (matchingFilenames.length > 0) {
            for (const filename of matchingFilenames) {
              const nameOnly = filename.split('.').slice(0, -1).join('.');
              const targetRefIndex = targetRefs.findIndex((ref: string, index: number) => {
                if (usedIndexes.includes(index)) {
                  return false;
                }
                return ref.toLowerCase().startsWith(nameOnly);
              });
              if (targetRefIndex !== -1) {
                usedIndexes.push(targetRefIndex);
                const foundFilename = filename;
                await this.downloadAndSave(result, task.serviceId, foundFilename, fileType);
                this.cdr.detectChanges();
                break;
              }
            }
          }
        }
      }));

      if (!fileType) {
        const currentTaskIndex = serviceTasks.findIndex(t => t.name === task.name);
        serviceTasks[currentTaskIndex].readyState = 'failed';
        try { this.handleFileFetchError(task.id, 'Error sending BIMKIT request: API Response empty') } catch (error) { this.handleFileFetchError(task.id, error.response.data.message) }
        const errorMessage = 'Error sending BIMKIT request: API Response empty';
        console.error(errorMessage);
        throw new Error(errorMessage);
        return false
      }
      return true
    } catch (error) {
      const currentTaskIndex = serviceTasks.findIndex(t => t.name === task.name);
      serviceTasks[currentTaskIndex].readyState = 'failed';
      try { this.handleFileFetchError(task.id, error.response.data.steps[0].error) } catch (error) { this.handleFileFetchError(task.id, error.response.data.message) }
      return false
    }
  }

  private getFileExtension(filename: string): string {
    return filename.split('.').pop()?.toLowerCase() || '';
  }

  private async downloadAndSave(fileUrl: string, serviceId: any, serviceModel: any, fileType: string) {
    try {
      const response: any = await axios.get(fileUrl, { responseType: 'arraybuffer' });
      const fileName = serviceModel.toLowerCase();
      const file = new File([response.data], fileName, { type: response.type });
      this.fileHandlerService.uploadWithName(file, fileName).subscribe(() => {
        const uploadedFile = { name: fileName, data: response.data, serviceId: serviceId };
        this.responseFiles.push(uploadedFile);
        this.cdr.detectChanges();
      });
      this.cdr.detectChanges();
    } catch (error) {
      console.error('Error downloading or uploading file:', error);
      this.cdr.detectChanges();
    }
  }

  hasFilesWithServiceId(files: any[], serviceId: any): boolean {
    return files.some(file => file.serviceId === serviceId);
  }

  downloadFile(event: MouseEvent, file: any) {
    event.preventDefault();
    const blob = new Blob([file.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  removeLastWord(name: string): string {
    const words = name.split(' ');
    words.pop(); // Remove the last word
    return words.join(' ');
  }

  getFileName(file: string): string {
    const matchingItem = this.shouldRenderList.concat(this.shouldNotRenderList)
      .find(item => item.toLowerCase().includes(file.toLowerCase()));
    return matchingItem ? matchingItem : file;
  }
}
