import { Pipe, PipeTransform } from '@angular/core';
import { ProductInterface } from '../model/products/product-interface';

@Pipe({ name: 'productsOfOrganisation' })
export class FilterProductsOfOrganisation implements PipeTransform {
  transform(products: ProductInterface[], organisationName: string[]) {
    if (!organisationName || organisationName.length == 0) {
      return products;
    }
    let res: ProductInterface[] = [];
    for (let o of organisationName) {
      res = res.concat(products.filter(
        product => product.companyData.organisationName === o
      ));
    }
    return res;
  }
}
