import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'confirmedMembers'})
export class ConfirmedMembersPipe implements PipeTransform{
  transform(members) {
    if(members){
      let result = members.filter(member => member.membershipStatus === 'CONFIRMED');
      result.sort(function(a,b){
        let x = a.organisationName.toLowerCase();
        let y = b.organisationName.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;});
      return result;
    }
    return members;
  }
}
