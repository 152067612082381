import { AttributeDataSource } from './../../../../dataSource/attributeDataSource';
import { AttributeService } from 'src/app/services/dataServices/attribute-service/attributeService';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { Subscription } from "rxjs";
import { PtgAttributeDataSource } from "src/app/dataSource/ptgAttributeDataSource";
import { Sort } from "@angular/material/sort";
import {Form, FormControl} from "@angular/forms";
import { SortMethod } from "src/app/model/enums/SortMethod";
import { PtgAttributesOverviewInterface } from "src/app/model/pagination/ptg-attributes-overview-interface";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import {Router} from "@angular/router";
import {AttributeInterface} from "../../../../model/attributes/attribute-interface";
import { UserService } from "src/app/services/user-service/userservice";

@Component({
  selector: "app-ptg-attribute-catalogue",
  templateUrl: "./ptg-attribute-catalogue.component.html",
  styleUrls: ["./ptg-attribute-catalogue.component.css"],
})
export class PtgAttributeCatalogueComponent implements OnInit, OnDestroy {
  private dataSourceSubscription: Subscription;
  private dataAllSourceSubscription: Subscription;
  private permissionsLoadedSub: Subscription;
  public ptgAttributeGroups: PtgAttributesOverviewInterface[] = [];
  public ptgSearch: FormControl;
  public allAttributeSearch: FormControl;
  public ptgDatasource: PtgAttributeDataSource;
  public attributesDatasource: AttributeDataSource;
  public sortString: string = "ID";

  public pageIndex: number = 0;
  public pageSize: number = 30;
  public searchChanged: boolean = false;
  public mode: string = "";
  public ptgAttributes: AttributeInterface[] = [];
  public allAttributes: AttributeInterface[] = [];
  public searchTerm: string = "";

  constructor(
    private ptgService: PtgService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private organisationService: OrganisationService,
    private router: Router,
    private titleService: Title,
    private attributeService: AttributeService,
  ) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    //Reroutes to the Marketplace if the User is not a member of the Organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnInit(): void {
    this.ptgSearch = new FormControl("");
    this.ptgDatasource = new PtgAttributeDataSource(this.ptgService);
    this.attributesDatasource = new AttributeDataSource (this.attributeService);

    if (this.router.url.includes("/ptgs")) {
      this.mode = "ptgs";
      this.titleService.setTitle(this.organisationService.activeItem.organisationName + " Merkmale nach PTGs - BIMSWARM");
    } else {
      this.mode = "all";
      this.titleService.setTitle(this.organisationService.activeOrganisation.organisationName + " Alle Merkmale - BIMSWARM");
    }

    this.dataAllSourceSubscription = this.attributesDatasource
    .getObservable()
    .subscribe((res) => {
      if (res) {
        if (this.searchChanged) {
          this.allAttributes = [];
        }
        this.allAttributes = this.allAttributes.concat(res);

        this.allAttributes = this.allAttributes.filter((attr) => {
          return attr.name.toLowerCase().includes(this.searchTerm.toLowerCase());
        });
        this.searchChanged = false;
        this.cd.detectChanges();
      }
    });
    this.dataSourceSubscription = this.ptgDatasource
      .getObservable()
      .subscribe((res) => {
        if (res) {
          res.forEach((ptg) => (ptg.hidden = true));
          if (this.searchChanged) {
            this.ptgAttributeGroups = [];
            this.ptgAttributes = [];
          }
          this.ptgAttributeGroups = this.ptgAttributeGroups.concat(res);

          this.ptgAttributeGroups.forEach((ptg) => {
            this.ptgAttributes = this.ptgAttributes.concat(ptg.attributes);
            ptg.attributes.sort(this.dynamicSort("name"));
          });
          this.ptgAttributes = this.ptgAttributes.filter((attr) => {
            return attr.name.toLowerCase().includes(this.searchTerm.toLowerCase());
          });
          this.searchChanged = false;
          this.cd.detectChanges();
        }
      });
    this.loadAttributesPage("", SortMethod[this.sortString]);

    this.onChange();
  }

  onChange(): void {
    this.ptgSearch.valueChanges.subscribe(
      (searchTerm: string) => {
        this.searchChanged = true;
        this.searchTerm = searchTerm;
        this.pageIndex = 0;
        this.loadAttributesPage(searchTerm, SortMethod[this.sortString]);
      },
      (error) => {},
    );
  }

  loadAttributesPage(searchTerm: string, sortMethod: SortMethod): void {
    if (searchTerm.includes(" ") && searchTerm.trim().length == 0) {
      return;
    }
    if(this.mode == 'all'){
      this.attributesDatasource.loadAttributes(
        this.pageIndex,
        this.pageSize,
        searchTerm.trim(),
        sortMethod
      );
    } else {
      this.ptgDatasource.loadPtgAttributes(
        this.pageIndex,
        this.pageSize,
        searchTerm.trim(),
        sortMethod
      );
    }
  }


  sortData(sort: Sort): void {
    this.sortString = "ID";

    if (sort.direction !== "") {
      this.sortString = sort.active + "_" + sort.direction.toUpperCase();
    }
    this.searchChanged = true;

    const searchString = this.ptgSearch.value;
    this.loadAttributesPage(searchString, SortMethod[this.sortString]);
  }

  showAttributes(name: string, versionNumber: number): void {
    this.ptgAttributeGroups.forEach((e) =>
      e.ptgName == name && e.ptgVersionNumber === versionNumber
        ? (e.hidden = !e.hidden)
        : (e.hidden = true)
    );
  }



  ngOnDestroy(): void {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
    if (this.dataSourceSubscription) {
      this.dataSourceSubscription.unsubscribe();
    }
    if (this.dataAllSourceSubscription) {
      this.dataSourceSubscription.unsubscribe();
    }
    if (this.permissionsLoadedSub) {
      this.permissionsLoadedSub.unsubscribe();
    }
  }

  onScroll(): void {
    if (
      this.ptgAttributeGroups.length < this.ptgDatasource.totalNumberOfElements && this.mode =='ptgs' ||
      this.allAttributes.length < this.attributesDatasource.totalNumberOfElements  && this.mode =='all'
    ) {
      this.pageIndex++;
      const searchString = this.ptgSearch.value;
      this.loadAttributesPage(searchString, SortMethod[this.sortString]);
    }
  }

  dynamicSort(property: string): (a: AttributeInterface, b: AttributeInterface) => number {
    let sortOrder: number = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a: AttributeInterface, b: AttributeInterface) {
      const result: number = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  }
}
