//TODO: DELETE
//This is now in the new header component

import { ChangeDetectorRef, Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../../../services/user-service/userservice";
import { OrganisationService } from "../../../services/dataServices/organisation-service/organisationservice";
import { HeaderServiceService } from "../../../services/header-service/header-service.service";
import { Subscription, Unsubscribable, Observable } from "rxjs";
import { NavbarService } from "src/app/services/componentServices/navbar-service/navbar-service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private activeOrgSub: Unsubscribable;

  constructor(
    private router: Router,
    public organisationService: OrganisationService,
    public userService: UserService,
    public headerService: HeaderServiceService,
    private navbarService: NavbarService,
    private cd: ChangeDetectorRef
  ) {}

  containsString(string) {
    return this.router.url.includes(string);
  }

  equalsString(string) {
    return this.router.url === string;
  }

  ngOnInit() {
    this.activeOrgSub = this.organisationService.activeItemObserver.subscribe(
      (org) => {
        if (org) {
          this.headerService.loadHeaderText(org.organisationName);
          this.cd.detectChanges();
        }
      }
    );
  }

  ngOnDestroy() {
    this.activeOrgSub.unsubscribe();
  }
}
