import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { API_BASE_FILESERVICE, API_MAP } from "src/environments/api";
import { environment } from "src/environments/environment";
import { AssetType, getTextOfFile } from "./fileUtil";

/* Content of Assets that needs to be preloaded as their Renderer cannot wait */
export let forkEvent: string;
export let joinEvent: string;
export let toolchainItemBase: string;
export let productImageDataUrls: ComposerFileIdImageInterface[] = [];

/* Gets called in the Constructor of model-editor */
export function loadIcons(): Observable<boolean> {
  let loadedIcons = 0;
  const iconAmount = 3;

  return new Observable<boolean>((observer) => {
    getTextOfFile("composer-fork-large.svg", AssetType.SVG).then((svg) => {
      forkEvent = svg;

      loadedIcons++;

      if (loadedIcons === iconAmount) {
        observer.next(true);
      }
    });
    getTextOfFile("composer-join-large.svg", AssetType.SVG).then((svg) => {
      joinEvent = svg;

      loadedIcons++;

      if (loadedIcons === iconAmount) {
        observer.next(true);
      }
    });
    getTextOfFile("composer-toolchain-item.svg", AssetType.SVG).then(
      (svg) => {
        toolchainItemBase = svg;
        
        loadedIcons++;

        if (loadedIcons === iconAmount) {
          observer.next(true);
        }
      }
    );
  });
}

/* Loads the DataUrl for a FileId and adds it to the existing DataUrls */
export function addNewFileId(
  fileId,
  httpClient,
  domSanitizer
): Observable<boolean> {
  const fileObservable = new Observable<boolean>((observer) => {
    if (fileId === "noFileId" || fileId === null) {
      if (!productImageDataUrls.find((element) => element.fileId === fileId)) {
        productImageDataUrls.push({
          fileId: fileId,
          dataUrl: environment.defaultPictureProducts,
        });
      }
      return observer.next(true);
    } else {
      loadImage(
        API_BASE_FILESERVICE +
          API_MAP["files"]["GET"].replace(":fileId", fileId),
        httpClient,
        domSanitizer
      ).subscribe((dataUrlObject) => {
        if (
          !productImageDataUrls.find((element) => element.fileId === fileId)
        ) {
          productImageDataUrls.push({
            fileId: fileId,
            dataUrl: dataUrlObject.changingThisBreaksApplicationSecurity,
          });
        }
        return observer.next(true);
      });
    }
  });

  return fileObservable;
}

/* Sends the Request for an Image */
function loadImage(
  url: string,
  httpClient: HttpClient,
  domSanitizer: DomSanitizer
): Observable<any> {
  return httpClient.get(url, { responseType: "blob" }).pipe(
    map((e) => {
      return domSanitizer.bypassSecurityTrustResourceUrl(
        URL.createObjectURL(e)
      );
    })
  );
}

export class ComposerFileIdImageInterface {
  fileId: string;
  dataUrl: string;
}
