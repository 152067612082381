<div *ngIf="organisation != null" class="certification-detail-organisation">
  <div
    *ngIf="
      organisation.description != null && organisation.description.length > 0
    "
  >
    <p class="description" [innerHTML]="organisation.description"></p>
  </div>

  <div *ngIf="organisation.address != null" class="address">
    <div class="headline">Adresse</div>
    <div class="line">{{ organisation.organisationName }}</div>
    <div class="line">{{ getFirstLineOfAddress(organisation.address) }}</div>
    <div class="line">{{ getSecondLineOfAddress(organisation.address) }}</div>
  </div>

  <div class="contact">
    <div class="headline">Kontakt</div>
    <div></div>
    <div class="line">Ansprechpartner:</div>
    <div class="line">{{ getContactPerson() }}</div>
    <div class="line">Tel.:</div>
    <div class="line">{{ getContactTelephoneNumber() }}</div>
    <div class="line">E-Mail:</div>
    <div class="line">{{ getContactEmail() }}</div>
    <div class="line">Webseite:</div>
    <a
      *ngIf="hasContactWebsite(organisation)"
      href="{{ getContactWebsite() }}"
      title="Zertifizierungsstellen-Webseite"
    >
      <a
        class="bold"
        href="{{ getContactWebsite() | linkHttpPipe }}"
        target="_blank"
        >{{ getContactWebsite() }}</a
      >
    </a>
  </div>
</div>
