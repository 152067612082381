import { Router } from "@angular/router";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { UserService } from "../../../../services/user-service/userservice";
import { OrganisationService } from "../../../../services/dataServices/organisation-service/organisationservice";
import { UtilService } from "../../../../services/util-service/utilService";
import { DialogService } from "../../../../services/dialog-service/dialog-service.service";
import { OrganisationInterface } from "../../../../model/organisations/organisation-interface";
import { UserInterface } from "../../../../model/user/user-interface";
import { Subscription } from "rxjs";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { environment } from "src/environments/environment";
import { HeaderServiceService } from 'src/app/services/header-service/header-service.service';
import { ProviderInterface } from '../../../../model/user/provider-interface';
import { map, startWith } from 'rxjs/operators';
import { API_BASE_FILESERVICE, API_MAP } from 'src/environments/api';





function domainNameValidator(control: FormControl): { [s: string]: boolean } {
  const domainNameRegex = /^[\wöäüÖÄÜß]+$/g;
  if (!control.value.match(domainNameRegex)) {
    return { domainNameInvalid: true };
  }
}

function cityOnlyAlphabeticCharactersValidator(
  control: FormControl
): { [s: string]: boolean } {
  const cityNameRegex = /^[A-Za-zöäüÖÄÜß ]+$/g;
  if (!control.value.match(cityNameRegex)) {
    return { cityNameContainsNonAlphabeticalCharacters: true };
  }
}

function specialCharacters(control: FormControl): { [s: string]: boolean } {
  const specialChar: RegExp = /[^a-zA-Z-ß. \d]/g;
  if (control.value && control.value.match(specialChar)) {
    return { specialCharFound: true };
  }
}

@Component({
  selector: "app-organisation-screen",
  templateUrl: "./organisation-screen.component.html",
  styleUrls: ["./organisation-screen.component.css"],
})
export class OrganisationScreenComponent implements OnInit, OnDestroy {
  addOrganisationMode: boolean = false;
  newOrganisationMode: boolean = false;

  newOrganisationAddress: string;
  newOrganisationNumber: string;
  uploadPictureFile: File;
  createdOrganisation;

  initialImageUrl = environment.defaultPictureProducts;

  // array of organisations for DropDown-Menu
  organisations: Array<string> = [];

  private addOrganisation: OrganisationInterface;

  public newOrganisation: OrganisationInterface;

  private currentMembershipSubscription: Subscription;

  private organisationCreateForm: FormGroup;
  private readonly domainPrefix = "@";
  private isDescriptionValid: boolean = true;

  //controls of organisationCreateForm
  private name: AbstractControl;
  private type: AbstractControl;
  private street: AbstractControl;
  private hNr: AbstractControl;
  private postalCode: AbstractControl;
  private city: AbstractControl;
  private domain: AbstractControl;
  private url: AbstractControl;

  constructor(
    public userService: UserService,
    private organisationService: OrganisationService,
    private dialogService: DialogService,
    private utilService: UtilService,
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private titleService: Title,
      public headerService: HeaderServiceService,
  ) {
    this.organisationCreateForm = formBuilder.group({
      name: ["", Validators.required],
      type: ["", Validators.required],
      street: ["", specialCharacters],
      hNr: [""],
      postalCode: [""],
      city: [""],
      domain: ["", Validators.required],
      description: ["", Validators.maxLength(3000)],
      url: [""],
    });
    this.name = this.organisationCreateForm.controls["name"];
    this.type = this.organisationCreateForm.controls["type"];
    this.street = this.organisationCreateForm.controls["street"];
    this.hNr = this.organisationCreateForm.controls["hNr"];
    this.postalCode = this.organisationCreateForm.controls["postalCode"];
    this.city = this.organisationCreateForm.controls["city"];
    this.domain = this.organisationCreateForm.controls["domain"];
    this.url = this.organisationCreateForm.controls["url"];
    document.documentElement.style.setProperty('--scrollStatus', 'auto');

  }

  ngOnInit(): void {
    this.titleService.setTitle("Mitgliedschaften - BIMSWARM");
    this.addOrganisation = {
      membershipStatus: "ADMIN_CONFIRMATION_PENDING",
      userId: this.userService.currentUser.userId,
      organisationId: null,
      organisationName: null,
      organisationType: null,
      address: null,
      domain: null,
      role: null,
      website: null,
    };

    this.newOrganisation = {
      address: {
        city: null,
        postalCode: null,
        street: "",
        streetAdditional: null,
      },
      domain: "",
      organisationId: Math.floor(Math.random() * 100),
      organisationName: null,
      organisationType: null,
      description: "",
      website: null,
    };

    this.currentMembershipSubscription = this.userService.currentMembershipsObserver.subscribe(
      (x) => {
        this.fillOrganisationlist();
        this.cd.detectChanges();
      }
    );
  }

  ngOnDestroy() {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');

    this.currentMembershipSubscription.unsubscribe();
  }

  // fetch all organisations from organisationService to fill up organisation array
  // sollte nach der Überarbeitung der Orga-DTOs sich nur noch die Namen der Orgs holen
  fillOrganisationlist(): void {
    this.organisationService.getAllOrgs();
    for (var i = 0; i < this.organisationService.collection.length; i++) {
      this.organisations.push(
        this.organisationService.collection[i].organisationName
      );
    }
  }

  onAddOrganisationToggle(): void {
    this.addOrganisationMode = !this.addOrganisationMode;
    this.newOrganisationMode = false;
  }

  newOrganisationModeToggle(): void {
    this.newOrganisationMode = !this.newOrganisationMode;
    this.addOrganisationMode = false;
  }

  removeOrganisationMembership(id: number, role: string): void {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie die Mitgliedschaft wirklich entfernen?",
        "Abbrechen",
        "Entfernen"
      )
      .subscribe((result) => {
        if (result) {
          this.userService.removeOrganisationMembership(id).subscribe(
            (res) => {
              this.userService.fetchCurrentUser();
            },
            (error) => {}
          );
        }
      });
  }

  acceptOrganisationMembership(id: number): void {
    this.userService.acceptOrganisationMembership(id).subscribe(
      (res: UserInterface) => {
        this.userService.saveUser(res);
        this.userService.getUserMemberships();
      },
      (error) => {}
    );
  }

  addOrganisationMembership(): void {
    if (!this.addOrganisation.organisationName || !this.addOrganisation.role) {
      this.dialogService.openDialog(
        "Antrag auf Mitgliedschaft",
        "Bitte geben Sie die Organisation und Rolle an!"
      );
      return;
    }
    this.addOrganisation.organisationId = this.organisationService.collection.filter(
      (organisation: OrganisationInterface) =>
        organisation.organisationName === this.addOrganisation.organisationName
    )[0].organisationId;
    this.userService.addOrganisationMembership(this.addOrganisation, (e) => {});
  }

  removeMembershipRequest(organisationId: number): void {
    this.userService.removeOrganisationMembership(organisationId).subscribe(
      (res) => {
        this.userService.fetchCurrentUser();
      },
      (error) => {}
    );
  }

  // TODO Eventy typeof
  onFileSelected(event): void {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.uploadPictureFile = fileList[0];
    }
  }

  changeDomainToName(event, domainField) {
    let domainString: string = "";
    let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?äÄöÖüÜ]+/;
    for (let letter of event) {
      if (format.test(letter)) {
        domainString = domainString.concat("_");
      } else {
        domainString = domainString.concat(letter);
      }
    }
    domainField.value = domainString;
    this.domain.setErrors(null);
  }

  onNewOrganisationConfirm(domainField: HTMLInputElement): void {
    if (
      !UtilService.areStringsValid(
        this.name.value,
        this.newOrganisation.description
      )
    ) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen Sie sicher, dass der Name und die Beschreibung der Organisation nicht zu lang sind."
      );
      return;
    }
    this.dialogService
      .openConfirmDialog(
        "Möchtest du diese Organisation wirklich anlegen?",
        "Nein",
        "Ja",
        ""
      )
      .subscribe((result) => {
        if (result) {





          this.newOrganisation.organisationName = this.name.value;
          this.newOrganisation.organisationType = this.type.value;
          this.newOrganisation.address = {
            street: this.street.value,
            streetAdditional: this.hNr.value,
            postalCode: this.postalCode.value,
            city: this.city.value,
          };
          this.newOrganisation.contacts = [];
          this.newOrganisation.domain = domainField.value;
          this.newOrganisation.website = this.url.value;
          if (
            this.utilService.isStringNullOrEmpty(
              this.newOrganisation.description
            )
          ) {
            this.newOrganisation.description = "";
          }

          let createOrgaRequest = this.organisationService
            .postItem(this.newOrganisation)
            .then(
              async (org) => {
                if (this.uploadPictureFile) {
                  await this.organisationService
                    .uploadOrganisationPicture(
                      this.uploadPictureFile,
                      this.organisationService.activeItem.organisationId,
                      true
                    )
                    .subscribe();
                }

             await this.organisationService.loadOrganisationsForUser()

                 this.newOrganisationModeToggle();
                    this.organisationCreateForm.reset();
                    this.dialogService.openDialog(
                      "Organisation erstellt",
                      "Die neue Organisation " +
                        org.organisationName +
                        " wurde erfolgreich erstellt."
                    );


                    let image = (org.fileId) ? API_BASE_FILESERVICE + API_MAP["files"]["GET"].replace(":fileId", org.fileId) : environment.defaultPictureOrganisationProducts;
                    let updatedMembership: ProviderInterface = {
                      name: org.organisationName,
                      img: image,
                      organisationId: org.organisationId
                    }



this.headerService.setActiveProvider(updatedMembership);





              },
              (error) => {}
            );
         this.userService.updateAuthorisation();
          //TODO
          /*
        if (this.uploadPictureFile) {
          createOrgaRequest
            .pipe(
              mergeMap((newOrga) =>
                this.organisationService
                  .uploadOrganisationPicture(
                    this.uploadPictureFile,
                    newOrga.organisationId
                  )
                  .pipe(
                    catchError((err) => {
                      return throwError({ error: err.error, type: "upload" });
                    })
                  )
              )
            )
            .subscribe(
              () => {
                this.userService.fetchCurrentUser();
                this.organisationService.fetchcollection((e) => {}


              },
              () => {
                this.dialogService.openDialog(
                  "Organisation erfolgreich erstellt",
                  ""
                );
                this.newOrganisationModeToggle();
              }
            );
        } else {
          createOrgaRequest.subscribe(
            (newOrga) => {
              this.userService.fetchCurrentUser();
              this.organisationService.fetchcollection((e) =>
                this.dialogService.openDialog(e.errorTitle, e.errorText)
              );
              //  this.dialogService.openDialog("Organisation erfolgreich erstellt", "");
            },
            (err) =>
              this.dialogService.openDialog(
                "Organisation nicht erstellt",
                err.error
              ),
            () => {
              this.dialogService.openDialog(
                "Organisation erfolgreich erstellt",
                ""
              );
              this.newOrganisationModeToggle();
              this.userService.updateAuthorisation(
              );
            }
          );
        } */
        }
      });
  }

  imagePath(id) {
    return "/proxy/api/v0/fileservice/file/" + id;
  }

  onDescriptionValidityChange(descriptionValid: boolean): void {
    this.isDescriptionValid = descriptionValid;
  }

  openFileManager(target): void {
    this.dialogService
      .openFileManagerDialog(
        "Datei für " + target + " auswählen",
        "Abbrechen",
        "Übernehmen",
        "TEST",
        { multi: false, upload: true }
      )
      .subscribe((confirmed) => {
        if (confirmed.length >= 1) {
          if (target == "preview") {
            this.newOrganisation.previewFileId = confirmed[0];
            this.cd.detectChanges();
          } else {
            this.newOrganisation.fileId = confirmed[0];
            this.cd.detectChanges();
          }
        }
      });
  }

  /**
   * Toggles the "Add Organisation"- Form off and resets the drop down list to placeholder values.
   */
  onCancelAddOrganisation(): void {
    this.onAddOrganisationToggle();
    this.addOrganisation.role = null;
    this.addOrganisation.organisationName = null;
  }
}
