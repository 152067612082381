/* Custom Modul for the Properties Panel */
export function ComposerTextBoxForViewer(options: {
    id: string;
    label: string;
    content: string;
  }) {
    return {
      id: options.id,
      html:
        '<label data-value="label" ' +
        'data-show="showLabel" ' +
        '></label>' +
        '<textarea rows="3" style="width: 92%" id="' + options.id + '" disabled>' + options.content + '</textarea>' +
        "",
      get: function (element, node) {
        return {
          label: options.label,
        };
      },
      showLabel: function (element, node) {
        return true;
      },
    };
  }
  