import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { TIMESTAMP_STRING } from "../../../environments/constants";

const SWARM_API = "/proxy/api/v0/";
@Injectable({ providedIn: "root" })
export class AuthService {
  public anonymousLoggedIn;
  public anonymousLoggedInSubject: BehaviorSubject<{}>;
  public anonymousLoggedInObserver: Observable<{}>;

  constructor(private http: HttpClient) {
    this.anonymousLoggedInSubject = new BehaviorSubject<{}>(
      this.anonymousLoggedIn
    );
    this.anonymousLoggedInObserver =
      this.anonymousLoggedInSubject.asObservable();
  }

  loginWithCode(authorization_code: string): Observable<number> {
    return this.http
      .post<{
        access_token: string;
        refresh_token: string;
        "swarm-id": number;
      }>(SWARM_API + `userservice/users/login`, {
        code: authorization_code,
        grant_type: "authorization_code",
        scope: "read",
      })
      .pipe(
        map((tokens) => {
          if (tokens) {
            const swarmId: number = tokens["swarm-id"];
            localStorage.setItem("access_token", tokens.access_token);
            localStorage.setItem(
              "access_token" + TIMESTAMP_STRING,
              new Date().toString()
            );
            localStorage.setItem("refresh_token", tokens.refresh_token);
            localStorage.setItem(
              "refresh_token" + TIMESTAMP_STRING,
              new Date().toString()
            );
            return swarmId;
          }
          return null;
        })
      );
  }

  loginAnonymous(): void {
    this.http
      .post<{ access_token: string }>(
        SWARM_API + `userservice/users/login/anonymous`,
        {}
      )
      .subscribe((token) => {
        let isUserLoggedIn: string = localStorage.getItem("refresh_token");
        if (token && !isUserLoggedIn) {
          localStorage.setItem("access_token", token.access_token);
          localStorage.setItem(
            "access_token" + TIMESTAMP_STRING,
            new Date().toString()
          );
          this.anonymousLoggedInSubject.next(token);
        }
      });
  }

  logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("access_token" + TIMESTAMP_STRING);
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_token" + TIMESTAMP_STRING);
    document.cookie = "JSESSIONID=;max-age=0";
    window.location.href = "/sso/logout";
    this.loginAnonymous();
  }

  deleteCookie(name: String) {
    console.log("delete cookie=" + name);
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Max-Age=0";
  }

  refreshTokens() {
    const refresh_token = localStorage.getItem("refresh_token");
    if (!refresh_token) {
      return of(null);
    }
    return this.http
      .post<{ access_token: string; refresh_token: string }>(
        SWARM_API + "userservice/users/login/refresh",
        { refresh_token: refresh_token }
      )
      .pipe(
        map((tokens) => {
          if (tokens) {
            localStorage.setItem("access_token", tokens.access_token);
            localStorage.setItem(
              "access_token" + TIMESTAMP_STRING,
              new Date().toString()
            );
            localStorage.setItem("refresh_token", tokens.refresh_token);
            localStorage.setItem(
              "refresh_token" + TIMESTAMP_STRING,
              new Date().toString()
            );
            return tokens.access_token;
          } else {
            return null;
          }
        })
      );
  }

  getLoginPage() {
    let params: HttpParams = new HttpParams();
    params = params.append("client_id", "swarm-frontend");
    params = params.set("response_type", "code");
    params = params.set("scope", "read");
    return this.http.get(SWARM_API + "pcssoservice/oauth2/authorize", {
      params: params,
      responseType: "text",
    });
  }

  registerSsoClient(clientRegistrationDto: {
    client: {
      clientId: string;
      clientName: string;
      clientSecret: string;
      authorizedGrantTypes: string;
      webServerRedirectUri: string;
    };
    productId: number;
  }) {
    var httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post(
      environment.oauthRegisterApp,
      clientRegistrationDto,
      httpOptions
    );
  }

  getClientInfo(ssoClientId: string) {
    return this.http.get<{
      clientId: string;
      clientName: string;
      clientSecret: string;
      authorizedGrantTypes: string;
      webServerRedirectUri: string;
    }>(SWARM_API + "pcssoservice/oauth/client/info/" + ssoClientId);
  }
}
