import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GENDERS} from "../../../../../../environments/constants";
import {ContactInterface} from "../../../../../model/organisations/contact-interface";


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  @Input()
  private contactInput: ContactInterface;
  @Output()
  private done = new EventEmitter<ContactInterface>();
  contactForm: FormGroup;

  contactName: AbstractControl;
  phoneNumber: AbstractControl;
  mobilePhoneNumber: AbstractControl;
  email: AbstractControl;
  website: AbstractControl;
  gender: AbstractControl;

  public genders = GENDERS;

  private contact: ContactInterface = {
    contactName: "",
    phoneNumber: "",
    mobilePhoneNumber: "",
    email: "",
    website: "",
    gender: "",
  };

  constructor(
    private formbuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.initialiseContactForm();
    this.contactName = this.contactForm.controls["contactName"];
    this.phoneNumber = this.contactForm.controls["phoneNumber"];
    this.mobilePhoneNumber = this.contactForm.controls["mobilePhoneNumber"];
    this.email = this.contactForm.controls["email"];
    this.website = this.contactForm.controls["website"];
    this.gender = this.contactForm.controls["gender"];
  }

  onSubmit() {
    if (this.contactInput !== undefined) {
      this.contact.contactId = this.contactInput.contactId;
    }
    this.contact.contactName = this.contactName.value;
    this.contact.phoneNumber = this.phoneNumber.value;
    this.contact.mobilePhoneNumber = this.mobilePhoneNumber.value;
    this.contact.email = this.email.value;
    this.contact.website = this.website.value;
    this.contact.gender = this.gender.value;
    this.done.emit(this.contact);
    this.contactForm.reset();
  }

  initialiseContactForm() {
    this.contactForm = this.formbuilder.group({
      contactName: [this.contactInput === undefined ? "" : this.contactInput.contactName, Validators.required],
      phoneNumber: [this.contactInput === undefined ? "" : this.contactInput.phoneNumber],
      mobilePhoneNumber: [this.contactInput === undefined ? "" : this.contactInput.mobilePhoneNumber],
      email: [this.contactInput === undefined ? "" : this.contactInput.email, Validators.required],
      website: [this.contactInput === undefined ? "" : this.contactInput.website],
      gender: [this.contactInput === undefined ? "" : this.contactInput.gender],
    });
  }

}
