import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { DataType } from "src/app/model/data-type.enum";
import { FileInfoInterface } from "src/app/model/marketplace/file-info-interface";
import { FileServiceService } from "src/app/services/file-service/file-service.service";
import { first } from "rxjs/operators";

@Component({
  selector: "app-detail-display-document-list",
  templateUrl: "./detail-display-document-list.component.html",
  styleUrls: ["./detail-display-document-list.component.css"],
})
export class DetailDisplayDocumentListComponent implements OnInit {
  @Input()
  fileIds: string[];

  @Input()
  public dataType: DataType;

  public files: FileInfoInterface[];
  public ripple: string = "rgb(255, 242, 204)";

  constructor(private fileService: FileServiceService,
              private cd: ChangeDetectorRef) {}

  ngOnChanges() {
    if(this.fileIds != null)
    {
    this.loadDocuments();
    }else if(this.fileIds.length === 0){
      this.files = [];
    }
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.loadDocuments();
  }

  private loadDocuments() {
    if (this.dataType === DataType.CERTIFICATION) {
      this.ripple = "rgb(255, 242, 204)";
    } else {
      this.ripple = "#e8efefff";
    }
    this.loadDocumentMetaData();
  }


  loadDocumentMetaData() {
    if (this.fileIds != null && this.fileIds.length > 0) {
      this.fileService
        .getMetaDataForFiles(this.fileIds)
        .pipe(first())
        .subscribe((fileInfos: FileInfoInterface[]) => {
          this.files = fileInfos;
        });
    }
  }

  private getDate(dateString: string): Date {
    return new Date(dateString);
  }

  private getFormattedFileSize(fileSize: string): string {
    const postfixOptions = ["B", "KB", "MB"];
    let result = "";
    let postfixIndex = 0;
    let sizeAsNumber = Number(fileSize);
    if (isNaN(sizeAsNumber)) {
      return result;
    }
    while (sizeAsNumber > 1000) {
      sizeAsNumber /= 1000;
      postfixIndex++;
    }
    return (
      parseFloat("" + sizeAsNumber).toFixed(1) +
      " " +
      postfixOptions[postfixIndex]
    );
  }

  onClick(fileUrl: string): void {
    window.open(fileUrl);
  }
}
