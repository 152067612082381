import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-websocket',
  templateUrl: './web-socket.component.html',
  styleUrls: ['./web-socket.component.css']
})
export class WebsocketComponent implements OnInit, OnDestroy {

  public socket: WebSocket | null = null;
  receivedMessage: string = '';
  @Output() socketReady: EventEmitter<WebSocket> = new EventEmitter<WebSocket>();
  constructor() { }

  ngOnInit(): void {
    this.initWebSocket();
  }

  ngOnDestroy(): void {
    this.closeWebSocket();
  }

  private initWebSocket(): void {
    this.socket = new WebSocket('wss://research.bimswarm.online/camunda/websocket');

    this.socket.onopen = () => {
      this.socketReady.emit(this.socket!);
      // Notify parent or perform any other actions
    };

    this.socket.onmessage = (event) => {
      const message = event.data;
      this.receivedMessage = message;
    };

    this.socket.onclose = (event) => {
    };
  }

  public getSocket(): any {
    if (this.socket) {
      return this.socket;
    } else return null
  }

  public closeWebSocket(): void {
    if (this.socket) {
      this.socket.close();
    }
  }

  sendMessage(message: string): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket connection not open.');
    }
  }

  public getReadyState(): any {
    if (this.socket && this.socket.readyState){
    return this.socket.readyState;
  } else 
  return null;
}
}