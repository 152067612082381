import { OrganisationOverview } from './../../dataSource/organisationDataSource';
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { DialogAddProductToNodeComponent } from "src/app/components/composer/dialog-add-product-to-node/dialog-add-product-to-node.component";
import { DialogToolchainInstanceUploadDataComponent } from 'src/app/components/composer/dialog-toolchain-instance-upload-data/dialog-toolchain-instance-upload-data.component';
import { DialogEditMetaDataComponent } from "src/app/components/composer/model-editor/dialog-edit-meta-data/dialog-edit-meta-data.component";
import { DialogAttributeCreate } from "src/app/components/dialog-windows/dialog-attribute-create";
import { DialogCertReqChangeStatusComponent } from 'src/app/components/dialog-windows/dialog-certReq-change-status.component';
import { DialogCommonFeatureProductCreationComponent } from "src/app/components/dialog-windows/dialog-common-feature-product-creation/dialog-common-feature-product-creation.component";
import { DialogConfirmComponent } from "src/app/components/dialog-windows/dialog-confirm.component";
import { DialogDelegateCertificationComponent } from 'src/app/components/dialog-windows/dialog-delegate-certification.component';
import { DialogDeleteUserManageOrganisationsComponent } from "src/app/components/dialog-windows/dialog-delete-user-manage-organisations/dialog-delete-user-manage-organisations.component";
import { DialogFileManagerComponent } from "src/app/components/dialog-windows/dialog-filemanager.component";
import { DialogOverviewExampleDialogComponent } from "src/app/components/dialog-windows/dialog-overview-example-dialog.component";
import { DialogRemoveMemberConfirmComponent } from "src/app/components/dialog-windows/dialog-remove-member-confirm/dialog-remove-member-confirm.component";
import {
  DialogRequestComponent,
} from "src/app/components/dialog-windows/dialog-request/dialog-request.component";
import {RequestType} from "src/app/model/enums/request-type";
import {  DialogCertRequestComponent} from "src/app/components/dialog-windows/dialog-request/dialog-certRequest.component";
import { DialogText } from "src/app/components/dialog-windows/dialog-text";
import { DialogAgb } from "src/app/components/dialog-windows/dialog-agb-text/dialog-agb";

import { DialogPrivacy } from "src/app/components/dialog-windows/dialog-privacy-text/dialog-privacy";

import { DialogReportReviewComponent } from 'src/app/components/dialog-windows/report-review/report-review.component';
import { CommonFeatureEntryInterface } from "src/app/model/commonFeature/common-feature-entry-interface";
import { CommonFeatureInterface } from "src/app/model/commonFeature/common-feature-interface";
import { OrganisationChangeInterface } from "src/app/model/organisations/organisation-change-interface";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { DialogConfirmDelegateCertificationComponent } from 'src/app/components/dialog-windows/dialog-confirm-delegate-certification.component';
import { CertificationRequestStatus } from 'src/app/model/enums/certification-requests-status';
import { DialogFileRequestComponent } from 'src/app/components/dialog-windows/dialog-certReq-requestFile.component';
import { DialogCookieConsentComponent } from 'src/app/components/dialog-windows/dialog-cookie-consent/dialog-cookie-consent.component';
import { DialogBaustelleRequestComponent } from 'src/app/components/dialog-windows/dialog-request/dialog-baustelle-request.component';
import { DialogServerRequestComponent } from 'src/app/components/dialog-windows/dialog-request/dialog-server-request.component';
import { DialogAddProductToBaustelleComponent } from 'src/app/components/product-certification/products/products/product-edit-screen/dialog-add-product-to-baustelle/dialog-add-product-to-baustelle.component';
import { DialogWarenkorbComponent } from 'src/app/components/dialog-windows/dialog-request/dialog-warenkorb/dialog-warenkorb.component';


@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(private dialog: MatDialog) {
  }

  openDialog(title, message): Observable<any> {
    const reference = this.dialog.open(DialogOverviewExampleDialogComponent, {
      width: "500px",
      data: {
        title: title,
        message: message,
      },
    });
    return reference.afterClosed();
  }

  openTextDialog(
    title: string,
    text: string,
    buttonYesLabel: string,
    buttonNoLabel: string
  ): Observable<any> {
    const reference = this.dialog.open(DialogText, {
      width: "75vw",
      data: {
        title: title,
        buttonNo: buttonNoLabel,
        buttonYes: buttonYesLabel,
        message: text,
      },
    });
    return reference.afterClosed();
  }

/** Dialogfenster was zur reinen Information des Users dient */
  openTextDialogInform(
    title: string,
    text: string,
    buttonNoLabel: string
  ): Observable<any> {
    const reference = this.dialog.open(DialogText, {
      width: "75vw",
      data: {
        title: title,
        buttonNo: buttonNoLabel,
        message: text,
      },
    });
    return reference.afterClosed();
  }

  /** Dialogfenster was zur reinen Information des Users dient uber Privacy */
    openPrivacyDialogInform(
      buttonNoLabel: string
    ): Observable<any> {
      const reference = this.dialog.open(DialogPrivacy, {
        width: "75vw",
        autoFocus: false,
      maxHeight: '90vh',
      data: {

        buttonNo: buttonNoLabel,

      },
      });
      return reference.afterClosed();
    }
    /** Dialogfenster was zur reinen Information des Users dient uber Agb */

    openAgbDialogInform(
      buttonNoLabel: string
    ): Observable<any> {
      const reference = this.dialog.open(DialogAgb, {
        width: "75vw",
        autoFocus: false,
      maxHeight: '90vh',
      data: {
        buttonNo: buttonNoLabel,
        openPrivacy: () => this.openPrivacyDialogInform('Schließen')
      },
      });
      return reference.afterClosed();
    }


  openDialogAttribute(
    title: string,
    feature,
    result: boolean
  ): Observable<any> {
    const reference = this.dialog.open(DialogAttributeCreate, {
      width: "500px",
      data: {
        title: title,
        feature: feature,
        result: result,
      },
    });

    return reference.afterClosed();
  }

  openConfirmDialog(
    title: string,
    buttonNoLabel: string,
    buttonYesLabel: string,
    message?: string
  ): Observable<boolean> {
    const reference = this.dialog.open(DialogConfirmComponent, {
      width: "auto",
      data: {
        title: title,
        buttonNo: buttonNoLabel,
        buttonYes: buttonYesLabel,
        message: message,
      },
    });
    return reference.afterClosed();
  }

  openFileManagerDialog(
    title: string,
    buttonNoLabel: string,
    buttonYesLabel: string,
    message: string,
    option?: Options,
    fileId?: string
  ): Observable<Array<string>> {
    let multi: boolean = false;
    let filter: boolean | String = false;
    let upload: boolean = true;
    if (option != null) {
      if (option.multi != null) {
        multi = option.multi;
      }
      if (option.filter != null) {
        filter = option.filter;
      }
      if (option.upload != null) {
        upload = option.upload;
      }
    }
    const reference = this.dialog.open(DialogFileManagerComponent, {
      width: "auto",
      data: {
        title: title,
        buttonNo: buttonNoLabel,
        buttonYes: buttonYesLabel,
        message: message,
        multiSelect: multi,
        uploadDisable: upload == false,
        filter: filter,
        fileId: fileId
      },
    });
    return reference.afterClosed();
  }

  openCommonFeatureEditDialog(
    selected: {
      feature: CommonFeatureInterface;
      entry: CommonFeatureEntryInterface;
    }[]
  ): Observable<{ feature: CommonFeatureInterface; entry: CommonFeatureEntryInterface }[]> {
    const reference = this.dialog.open(
      DialogCommonFeatureProductCreationComponent,
      {
        width: "60vw",
        data: {
          selected: selected,
        },
      }
    );
    return reference.afterClosed();
  }

  openDemoRequestDialog(productId: number): void {
    this.dialog.open(DialogRequestComponent, {
      width: "auto",
      data: {
        title: "Demo-Anfrage",
        productId: productId,
        type: RequestType.DEMO,
      },
    });
  }

  openBaustelleRequestDialog(productId: number): void {
    this.dialog.open(DialogBaustelleRequestComponent, {
      width: "auto",
      data: {
        title: "Anfrage zur Bereitstellung von Diensten im EDGE-Server auf der Baustelle",
        productId: productId,
        type: RequestType.BAUSTELLE,
      },
    });
  }



  openServerRequestDialog(productId: number): void {
    this.dialog.open(DialogServerRequestComponent, {
      width: "auto",
      data: {
        title: "Anfrage EDGE-Server auf der Baustelle",
        productId: productId,
        type: RequestType.EDGESERVER,
      },
    });
  }

  openOfferRequestDialog(productId: number): void {
    this.dialog.open(DialogRequestComponent, {
      width: "auto",
      data: {
        title: "Angebot-Anfrage",
        productId: productId,
        type: RequestType.OFFER,
      },
    });
  }

  openListingRequestDialog(): void {
    this.dialog.open(DialogRequestComponent, {
      width: "auto",
      data: {
        title: "Listung für Anbieter anfragen",
        type: RequestType.LISTING,
        openAgb: () => this.openAgbDialogInform('Schließen'),
        openPrivacy: () => this.openPrivacyDialogInform('Schließen')
      },
    });
  }


  openWarenKorbDialog(): void {
    this.dialog.open(DialogWarenkorbComponent, {
      width: "auto",
      data: {
        title: "Warenkorb",
        type: RequestType.WARENKORB
      },
    });
  }
/** oeffnet ein Dialogfenster fuer Zertifikats Angebotsanfragen */
  openOfferCertRequestDialog(certId: number): void {
    this.dialog.open(DialogCertRequestComponent, {
      width: "auto",
      data: {
        title: "Angebot-Anfrage",
        productId: certId,
        type: RequestType.OFFER,
      },
    });
  }

  public openRemoveMemberDialog(
    username: string
  ): Observable<{ confirmed: boolean; message: string }> {
    const reference = this.dialog.open(DialogRemoveMemberConfirmComponent, {
      width: "auto",
      data: {
        username: username,
      },
    });
    return reference.afterClosed();
  }

  public openDeleteUserDialog(
    userId: number,
    organisationMemberships: OrganisationInterface[]
  ): Observable<{
    confirmed: boolean;
    organisationChanges: OrganisationChangeInterface[];
  }> {
    const reference = this.dialog.open(
      DialogDeleteUserManageOrganisationsComponent,
      {
        width: "auto",
        data: {
          userId: userId,
          organisationMemberships: organisationMemberships,
        },
      }
    );

    return reference.afterClosed();
  }

  public openAddProductToToolchainItem(
    toolchainItemName,
    toolchainItemDescription
  ): Observable<{ productName: string; productId: string; productFileId: string }> {
    const reference = this.dialog.open(DialogAddProductToNodeComponent, {
      width: "80vw",
      data: {
        toolchainItemName,
        toolchainItemDescription,
      },
    });

    return reference.afterClosed();
  }
  
  public openAddProductToBaustelleItem(
    toolchainItemName,
    toolchainItemDescription
  ): Observable<{ productName: string; productId: string; productFileId: string }> {
    const reference = this.dialog.open(DialogAddProductToBaustelleComponent, {
      width: "80vw",
      data: {
        toolchainItemName,
        toolchainItemDescription,
      },
    });

    return reference.afterClosed();
  }
  public openEditToolchainMetaData(
    modeling: any,
    id: string,
    name: string,
    description: string,
    selectedPTGs: number[],
    productName: string,
    productId: string,
    productFileId: string,
    element: any
  ) {
    this.dialog.open(DialogEditMetaDataComponent, {
      width: "40vw",
      height: "40vh",
      data: {
        modeling: modeling,
        dialogService: this,
        id: id,
        name: name,
        description: description,
        selectedPTGs: selectedPTGs,
        productName: productName,
        productId: productId,
        productFileId: productFileId,
        element: element,
      },
    });
  }

  openCertReqChangeStatusDialog(title: string): Observable<CertificationRequestStatus> {
    const reference = this.dialog.open(DialogCertReqChangeStatusComponent, {
      width: "auto",
      data: {
        title: title,
      },
    });
    return reference.afterClosed();
  }

  openDelegateCertificationDialog(title: string, delegatedToOrganisations: OrganisationOverview[]): Observable<OrganisationOverview> {
    const reference = this.dialog.open(DialogDelegateCertificationComponent, {
      width: "auto",
      data: {
        title: title,
        delegatedToOrganisations: delegatedToOrganisations,
      },
    });
    return reference.afterClosed();
  }

  openConfirmDelegateCertificationDialog(title: string, certificationOffices: OrganisationOverview[], attributeOrPtg: string): Observable<boolean> {
    const reference = this.dialog.open(DialogConfirmDelegateCertificationComponent, {
      width: "auto",
      data: {
        title: title,
        certificationOffices: certificationOffices,
        attributeOrPtg: attributeOrPtg,
      },
    });
    return reference.afterClosed();
  }

  openFileRequestDialog(title: string, buttonNoLabel: string, buttonYesLabel: string): Observable<string> {
    const reference = this.dialog.open(DialogFileRequestComponent, {
      width: "auto",
      data: {
        title: title,
        buttonNoLabel: buttonNoLabel,
        buttonYesLabel: buttonYesLabel
      },
    });
    return reference.afterClosed();
  }

  openReportReviewDialog(
    buttonNoLabel: string,
    buttonYesLabel: string
  ): Observable<String> {
    const dat: ReportReviewDialogData = {
      buttonNo: buttonNoLabel,
      buttonYes: buttonYesLabel
    };
    const reference = this.dialog.open(DialogReportReviewComponent, {
      width: "auto",
      data: dat,
    });
    return reference.afterClosed();
  }

  public openToolchainInstanceDataUpload(containerId: string, contentId: string): Observable<String[]> {
    const reference = this.dialog.open(DialogToolchainInstanceUploadDataComponent, {
      width: "40vw",
      data: {
        containerId: containerId,
        contentId: contentId
      }
    });
    return reference.afterClosed();
  }

  openCookieConsentDialog(title: string,
    buttonYesLabel: string,
    message?: string
  ): Observable<boolean> {
    const reference = this.dialog.open(DialogCookieConsentComponent, {
      width: "auto",
      data: {
        title: title,
        buttonYes: buttonYesLabel,
        message: message,
      },
      disableClose: true
    });
    return reference.afterClosed();
  }


}


export interface Options {
  multi?: boolean;
  filter?: boolean | String;
  upload?: boolean;
}

export interface ReportReviewDialogData {
  buttonNo: string,
  buttonYes: string
}
