import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServerPortainerService {
  private portainerUrl = 'http://research.bimswarm.online:1000';
  private authToken: string;
  constructor(private http: HttpClient) { }
  
  setAuthToken(token: string): void {
    this.authToken = token;
  }

  getEndpoints(): Observable<any[]> {
    if (!this.authToken) {
      throw new Error('Authorization token is missing. Please log in first.');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'X-API-Key': `${this.authToken}`
      })
    };
    return this.http.get<any[]>(this.portainerUrl + '/endpoints', httpOptions);
  }

  getEndpointById(id: any): Observable<any> {
    if (!this.authToken) {
      throw new Error('Authorization token is missing. Please log in first.');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'X-API-Key': this.authToken
      })
    };
    return this.http.get<any>(this.portainerUrl + "/endpoints/" + id, httpOptions);
  }

  getEndpointByHostIp(hostName: string): Observable<any> {
  let foundEndpoint = null;
  this.getEndpoints()
    .subscribe(
      portainerInstance => {
        for (let endpoint of portainerInstance) {
          if (endpoint.Snapshots[0].DockerSnapshotRaw.Info.Name === hostName) {
            foundEndpoint = endpoint;
            break;
          }
        }
      },
    );
    return foundEndpoint;
  }
}
