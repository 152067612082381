import { ProjectInterface } from './../../../../model/user/user-project-interface';
import { ChangeDetectorRef, Component, OnInit, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { DialogService } from "../../../../services/dialog-service/dialog-service.service";
import { HeaderServiceService } from "../../../../services/header-service/header-service.service";
import { SidemenuServiceService } from "src/app/services/componentServices/sidemenu-service/sidemenu-service.service";
import { UserInterface } from "../../../../model/user/user-interface";
import { UserService } from "../../../../services/user-service/userservice";
import { UtilService } from "../../../../services/util-service/utilService";
import { VALIDATOR_EXP } from "src/environments/constants";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-settings-screen",
  templateUrl: "./settings-screen.component.html",
  styleUrls: ["./settings-screen.component.css"],
})
export class SettingsScreenComponent implements OnInit {
  public userDetails: UserInterface;
  public editMode: boolean = false;
  private dialogTitle: string = "Persönliche Daten";
  private wasProfilePictureChanged: boolean = false;
  private defaultProject: ProjectInterface = {projectName: "-", userName: this.userservice.currentUser.userName}
  private userProjects: ProjectInterface[] = [this.defaultProject];
  private newProjectName:  string = "";
  private editModeProjects = false;


  constructor(
    public userservice: UserService,
    private utilService: UtilService,
    private dialogService: DialogService,
    private sidemenuService: SidemenuServiceService,
    private headerService: HeaderServiceService,
    private titleService: Title,
    private cd: ChangeDetectorRef
  ) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');

  }


  ngOnInit() {
    this.sidemenuService.productsVisible = false;
    this.titleService.setTitle("Persönliche Daten - BIMSWARM");
    let fullUserName: string = this.userservice.currentUser.firstName + " " + this.userservice.currentUser.lastName;
    if (this.userservice.currentUser) {
      this.headerService.loadHeaderText(
        fullUserName
      );
      this.getProjects(this.userservice.currentUser.userName);
      this.resetUserDetails();
    } else {
      if (localStorage.getItem("refresh_token")) {
        this.headerService.loadHeaderText(
          fullUserName
        );

        this.getProjects(this.userservice.currentUser.userName);
        this.resetUserDetails();
        this.cd.detectChanges();
      }
    }
  }

  fileChanged(evt) {}

  onEditPress(): void {
    this.resetUserDetails();
    this.editMode = !this.editMode;
    this.editModeProjects = false;
    this.wasProfilePictureChanged = false;
  }

  onConfirmPress(): void {
    localStorage.setItem("hasTrackingConsent", String(this.userDetails.hasTrackingConsent))
    const name = this.userDetails.firstName + this.userDetails.lastName;
    if (
      name.match(VALIDATOR_EXP.CONTAINS_NUMBER) ||
      name.match(VALIDATOR_EXP.CONTAINS_SPECIAL_CHAR)
    ) {
      this.dialogService.openDialog(
        this.dialogTitle,
        "Ihr Name darf keine Zahlen oder Sonderzeichen enthalten!"
      );
      return;
    }
    if(this.userDetails.employer.length == 0 || this.userDetails.employer == '-')
    {
      this.dialogService.openDialog(
        this.dialogTitle,
        "Bitte geben Sie einen Arbeitgeber an."
      );
      return;
    }
    if (
      this.utilService.isStringNullOrEmpty(this.userDetails.firstName) ||
      this.utilService.isStringNullOrEmpty(this.userDetails.lastName)
    ) {
      this.dialogService.openDialog(
        this.dialogTitle,
        "Bitte geben Sie einen Vornamen und Nachnamen ein!"
      );
      return;
    }
    
    for (let property in this.userDetails) {
      if (this.userDetails[property] === "-") {
        this.userDetails[property] = "";
      }
    }
    for (let property in this.userDetails.address) {
      if (this.userDetails.address[property] === "-") {
        this.userDetails.address[property] = "";
      }
    }
    this.userservice.changeUserDetails(this.userDetails).subscribe(
      (res) => {
        this.userservice.currentUser = res;
        if (this.wasProfilePictureChanged) {
          this.userservice.currentUserPictureChangeSubject.next(true);
        }
        this.onEditPress();
        this.cd.detectChanges();
        let fullUserName: string = this.userservice.currentUser.firstName + " " + this.userservice.currentUser.lastName;
        this.headerService.loadHeaderText(
          fullUserName
        );
        
        this.dialogService.openDialog(
          this.dialogTitle,
          "Persönliche Daten wurden erfolgreich gespeichert."
        );
      },
      (error) => {}
    );
  }

  resetUserDetails(): void {
    this.userDetails = {
      userId: this.userservice.currentUser.userId,
      userName: this.userservice.currentUser.userName,
      gender: this.userservice.currentUser.gender || "",
      title: this.userservice.currentUser.title || "",
      firstName: this.userservice.currentUser.firstName || "-",
      lastName: this.userservice.currentUser.lastName || "-",
      fileId: this.userservice.currentUser.fileId,
      website: this.userservice.currentUser.website || "-",
      employer: this.userservice.currentUser.employer || "-",
      aboutMe: this.userservice.currentUser.aboutMe || "-",
      phoneNumber: this.userservice.currentUser.phoneNumber || "-",
      mobileNumber: this.userservice.currentUser.mobileNumber || "-",
      jobDescription: this.userservice.currentUser.jobDescription || "-",
      hasTrackingConsent: this.userservice.currentUser.hasTrackingConsent || false,
      address: {
        street:
          this.userservice.currentUser.address &&
          this.userservice.currentUser.address.street
            ? this.userservice.currentUser.address.street
            : "-",
        streetAdditional:
          this.userservice.currentUser.address &&
          this.userservice.currentUser.address.streetAdditional
            ? this.userservice.currentUser.address.streetAdditional
            : "-",
        postalCode:
          this.userservice.currentUser.address &&
          this.userservice.currentUser.address.postalCode
            ? this.userservice.currentUser.address.postalCode
            : "-",
        city:
          this.userservice.currentUser.address &&
          this.userservice.currentUser.address.city
            ? this.userservice.currentUser.address.city
            : "-",
      },
      organisationDto: {}
    };
  }

  private getDefaultProfileImageUrl(): string {
    return environment.defaultPictureProfile;
  }

  //löscht den eintrag der fileid des users
  public resetProfilePicture() {
    this.userDetails.fileId = null;
  }

  private showFileUpload(): void {
    this.dialogService
      .openFileManagerDialog(
        "Profilbild auswählen",
        "Abbrechen",
        "Übernehmen",
        ""
      )
      .subscribe((confirmed) => {
        if (confirmed != null && confirmed.length >= 1) {
          this.wasProfilePictureChanged = true;
          this.userDetails.fileId = confirmed[0];
          this.cd.detectChanges();
        }
      });
  }

  getProjects(userName: string){
    this.userservice.getUserProjects(userName).subscribe(
      (projects) =>{
        this.userProjects = projects;
        this.cd.detectChanges();
      }
    );
  }

  onRemoveProject(projectId: number): void {
    this.dialogService
    .openConfirmDialog(
      "Wollen Sie das Projekt wirklich entfernen?",
      "Abbrechen",
      "Entfernen"
    )
    .subscribe((dialogChoice) => {
      if (dialogChoice) {
        this.userservice.removeUserProject(projectId).subscribe(
          ()=>{
            this.getProjects(this.userservice.currentUser.userName);
          }
        );


      }
    });
  }

  onAddProject(projectName: string){
    let newProject: ProjectInterface = {
      userName: this.userservice.currentUser.userName,
      projectName: this.newProjectName
    };
    this.userservice.addUserProject(newProject).subscribe(
      ()=>{
        this.getProjects(this.userservice.currentUser.userName);
        this.editModeProjects =false;
        this.newProjectName = "";
      }
    );
  }

  ngOnDestroy(): void {

    document.documentElement.style.setProperty('--scrollStatus', 'hidden');

  }
}
