import { BehaviorSubject, Subscription, of, Observable } from "rxjs";
import { CertificationVariantVersionService } from "../services/dataServices/cetrification-variant-version-service/certification-variant-version-service";
import { SortMethod } from "../model/enums/SortMethod";
import { DtoPageRequest } from "../model/marketplace/dto-page-request";
import { catchError } from "rxjs/operators";
import { CertificationVersionProductInfoInterface } from "../model/marketplace/product-infos-of-certification-interface";

export class CertificationRelatedProductDataSource {
  private certificationRelatedProductDataSourceSubject: BehaviorSubject<
    CertificationVersionProductInfoInterface[]
  > = new BehaviorSubject([]);
  totalNumberOfElements: number = 1;
  private currentRequest: Subscription;

  constructor(
    private certificationVariantVersionService: CertificationVariantVersionService
  ) {}

  loadProducts(
    certificationVariantVersionId: number,
    pageIndex: number,
    pageSize: number,
    organisationIds: number[] = [],
    ptgIds: number[] = [],
    attributeIds: number[] = [],
    commonFeatureEntryIds: number[] = [],
    statusIds: number[] = [],
    searchTerm: string = "",
    sortMethod: SortMethod = SortMethod.PRODUCT_NAME_ASC,
    ptgAttrIds: number[] = [],
  ): void {
    if (this.currentRequest) {
      this.currentRequest.unsubscribe();
      this.currentRequest = null;
    }
    let pageRequest: DtoPageRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      organisationIds: organisationIds,
      ptgIds: ptgIds,
      attributeIds: attributeIds,
      commonFeatureEntryIds: commonFeatureEntryIds,
      statusIds: statusIds,
      searchTerm: searchTerm,
      sortMethod: sortMethod,
      ptgAttrIds: ptgAttrIds
    };
    this.currentRequest = this.certificationVariantVersionService
      .getRelatedProductInfosPage(certificationVariantVersionId, pageRequest)
      .pipe(catchError(() => of([])))
      .subscribe((productInfo) => {
        this.totalNumberOfElements = productInfo["totalElements"];
        this.certificationRelatedProductDataSourceSubject.next(
          productInfo["content"]
        );
      });
  }

  getAsObservable(): Observable<CertificationVersionProductInfoInterface[]> {
    return this.certificationRelatedProductDataSourceSubject.asObservable();
  }
}
