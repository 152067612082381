import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";

@Component({
  selector: "hexagon",
  templateUrl: "./hexagon.component.html",
  styleUrls: ["./hexagon.component.css"],
})
export class HexagonComponent implements OnInit {
  @Input() compatiblityPtg: string;
  @Input() img: string;
  @Input() icon?: string;
  @Input() composerItem?: boolean;
  @Input() inComposer?: boolean;
  @Input() belongsToProductCategory?: boolean;
  @Input() isCustomImage?: boolean;

  @Input() hover?: boolean;

  @Input() outerColor?: string;
  @Input() middleColor?: string;
  @Input() innerColor?: string;

  @Input() hasNoImage?: boolean;
  @Input() imageId: string;

  constructor(private _sanitizer: DomSanitizer) {
    this.getImg();
  }

  ngOnInit() {}

  getImg(): string {
    if (!this.img) {
      this.img = environment.defaultPictureProducts;
    }
    return this.img;
  }

  getCompatiblityImage(): string {
    let result: string;
    if (this.compatiblityPtg) {
      result = `url(/assets/img/misc/Hexagon-Outer-PTG-Clicked-${this.uppercaseFirst(
        this.compatiblityPtg
      )}.png)`;
    } else {
      result = `url(/assets/img/misc/Hexagon-Outer-PTG-Clicked-Grey.png)`;
    }
    return result;
  }

  uppercaseFirst(string: string) {
    return string.substring(0, 1).toUpperCase() + string.substring(1);
  }
}
