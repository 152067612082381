import {ChangeDetectorRef, Component, Input, OnInit} from "@angular/core";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { ContactInterface } from 'src/app/model/organisations/contact-interface';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import {OrganisationInterface} from "../../../../../model/organisations/organisation-interface";

@Component({
  selector: "app-create-contact-form",
  templateUrl: "./create-contact-form.component.html",
  styleUrls: ["./create-contact-form.component.css"],
})
export class CreateContactFormComponent implements OnInit {

  @Input()
  private orga: OrganisationInterface;
  @Input()
  public contacts: ContactInterface[];

  public contactManagement: boolean = false;
  public contactCreation: boolean = false;

  constructor(
    private organisationService: OrganisationService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    for (const contact of this.contacts) {
      contact.edit = false;
    }
  }

  toggleContactManagement(): void {
    this.contactManagement = !this.contactManagement;
    for (const contact of this.contacts) {
      contact.edit = false;
    }
    this.contactCreation = false;
  }

  toggleContactEdit(contact: ContactInterface): void {
    this.contactCreation = false;
    if (contact.edit) {
      contact.edit = false;
    } else {
      for (const allContacts of this.contacts) {
        allContacts.edit = false;
      }
      contact.edit = true;
    }
  }

  toggleContactCreation(): void {
    for (const contact of this.contacts) {
      contact.edit = false;
    }
    this.contactCreation = !this.contactCreation;
  }

  createOrganisationContact(contact: ContactInterface): void {
    this.organisationService
      .createContactForOrganisation(contact, this.orga.organisationName)
      .subscribe(
        (createdContact: ContactInterface) => {
          let activeOrg = this.organisationService.activeItem;
          if (activeOrg != null) {
            let contacts = activeOrg.contacts;
            if (contacts == null) {
              activeOrg.contacts = [];
              contacts = activeOrg.contacts;
            }
            contacts.push(createdContact);
          }
          this.dialogService.openDialog(
            "Kontakt erfolgreich erstellt",
            "Der Kontakt für die Organisation wurde erfolgreich erstellt."
          ).subscribe(() => {
            this.contactCreation = false;
            this.cd.detectChanges();
          });
        },
        (error) => {}
      );
  }

  editOrganisationContact(contact: ContactInterface): void {
    this.organisationService
      .updateOrganisationContact(this.orga.organisationId, contact)
      .subscribe(() => {
         let oldContact = this.contacts.find(cont => cont.contactId === contact.contactId);
         oldContact.contactName = contact.contactName;
         oldContact.email = contact.email;
         oldContact.gender = contact.gender;
         oldContact.mobilePhoneNumber = contact.mobilePhoneNumber;
         oldContact.phoneNumber = contact.phoneNumber;
         oldContact.website = contact.website;
        this.dialogService.openDialog(
          "Kontakt erfolgreich aktualisiert",
          "Der Kontakt für die Organisation wurde erfolgreich aktualisiert."
        ).subscribe(() => {
          for (const cont of this.contacts) {
            cont.edit = false;
          }
          this.cd.detectChanges();
        });
      });
  }

  deleteOrganisationContact(contact: ContactInterface): void {
    this.dialogService.openConfirmDialog(
      "Organisations Kontakt löschen",
      "Löschen",
      "Abbrechen",
      "Wollen Sie den Kontakt für die Organisation wirklich löschen."
    ).subscribe(() => {
      this.organisationService
        .deleteOrganisationContact(this.orga.organisationId, contact.contactId)
        .subscribe(() => {
          this.contacts = this.contacts.filter((cont) => cont.contactId !== contact.contactId);
          this.dialogService.openDialog(
            "Kontakt erfolgreich gelöscht",
            "Der Kontakt für die Organisation wurde erfolgreich gelöscht."
          ).subscribe(() => {
            this.cd.detectChanges();
          });
        });
    });
  }
}
