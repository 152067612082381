<div class="left_column" *ngIf="ptg; else loading">
  <div *ngIf="EDIT_FLAG; else create">
    <h1>Produkttypversion bearbeiten</h1>
  </div>
  <ng-template #create>
    <h1>Produkttypversion anlegen</h1>
  </ng-template>
  <div class="auto-auto">
    <mat-form-field>
      <input
        matInput
        placeholder="Version"
        [(ngModel)]="version.number"
        disabled
      />
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Status" [(value)]="version.status">
        <mat-option value="ACTIVE">Aktiv</mat-option>
        <mat-option value="INACTIVE">Inaktiv</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <app-text-editor
    [(editorText)]="version.description"
    (textValidityChange)="onDescriptionValidityChange($event)"
  ></app-text-editor>
  <p>
    <b>Merkmale für die Version {{ version.number }}</b>
  </p>
  <div *ngIf="!belongsToProductOrCertification" class="min-auto">
    Merkmal hinzufügen
    <attribute-catalogue
      [attributeList] = "selectedAttributes"
      (onPressConfirm)="featureCatalogueConfirmPressed($event)"
    ></attribute-catalogue>
  </div>
  <div *ngIf="belongsToProductOrCertification" class="min-auto">
    Dieser Produkttyp wird von anderen Produkten oder Zertifikat referenziert und kann nicht
    bearbeitet werden
  </div>
  <ng-container>
    <attribute-select-table
      [(ptgAttributeValidations)]="ptgAttributeValidations"
      [editable]="!belongsToProductOrCertification"
    ></attribute-select-table>
  </ng-container>
  <div class="auto-auto">
    <button
      mat-raised-button
      class="btn_green"
      (click)="saveVersion()"
      [disabled]="!isDescriptionValid"
    >
      Speichern
    </button>
    <button mat-raised-button class="btn_red" (click)="routeToPtgOverview()">
      Abbrechen
    </button>
  </div>
</div>
<ng-template #loading>
  <p>Loading Data, please wait</p>
</ng-template>
