import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Subscribable } from 'rxjs';
import { DocumentInterface } from '../model/documents/document-interface';
import { DocumentService } from '../services/dataServices/document-service/document.service';

@Injectable()
export class CertificationDocumentListResolver implements Resolve<Subscribable<DocumentInterface[]>>{
    constructor(private documentService: DocumentService){}

  resolve(route: ActivatedRouteSnapshot) {
    return this.documentService.getDocumentsRelatedToCertificationList(parseInt(route.paramMap.get('certificationId')));
  }
}
