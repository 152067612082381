import { Component, OnInit, Input } from "@angular/core";
import { environment } from "src/environments/environment";
import { ProductInterface } from "src/app/model/products/product-interface";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { MalfunctionService } from "src/app/services/malfunction-service/malfunction.service";
import { Router } from "@angular/router";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { UserService } from "src/app/services/user-service/userservice";

@Component({
  selector: "app-detail-display-header",
  templateUrl: "./detail-display-header.component.html",
  styleUrls: [
    "./detail-display-header.component.css",
    "../../detail-display.css",
  ],
})
export class DetailDisplayHeaderComponent implements OnInit {
  @Input() name: string = "";
  @Input() subscript: string = "";
  @Input() organisation: OrganisationInterface;
  @Input() ptgNames: string[];
  @Input() rating: number = 0.0;
  @Input() ratingCount: number;
  @Input() reviewType: number = -1;
  @Input() product: ProductInterface;
  @Input() fileId: string;

  id: number;
  public defaultPictureUrl: string =
    environment.defaultPictureOrganisationProducts;

  isThereMalfunction: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly ptgService: PtgService,
    private readonly organisationService: OrganisationService,
    private readonly malfunctionService: MalfunctionService,
    private readonly userService: UserService
  ) {}

  ngOnInit() {
    this.id = parseInt(this.router.url.split("/")[3]);
    if (!this.fileId) {
      this.defaultPictureUrl = environment.defaultPictureOrganisationPTGs;
      return;
    }

    if (this.userService.loggedIn) {
      this.checkMalfunctionsExistent();
    }
    if (this.organisation == null && this.product != null) {
      this.getOrganisation();
    }
    if (!this.ptgNames) {
      this.ptgNames = Array.from(
        new Set(
          this.product.productVariants
            .map((variant) => {
              return variant.variantVersions;
            })
            .reduce((x, y) => {
              return x.concat(y);
            })
            .map((version) => {
              return version.ptgVersions;
            })
            .reduce((x, y) => {
              return x.concat(y);
            })
            .map((ptgVersion) => {
              return this.ptgService.getPtgFromVersion(ptgVersion).name;
            })
            .sort()
        )
      );
    }
  }

  routeMalfunctions() {
    this.router.navigateByUrl(`malfunction/${this.id}/overview`);
  }

  /**
   * Prüft, ob es mindestens eine Störung für das angezeigte Produkt gibt
   */
  private checkMalfunctionsExistent(): void {
    this.malfunctionService.getMalfunctions(this.id).then((success) => {
      if (
        success.filter(
          (malfunction) =>
            malfunction.isPublic && malfunction.status !== "FIXED"
        ).length > 0
      )
        this.isThereMalfunction = true;
    });
  }

  private getOrganisation(): void {
    this.organisationService.getOrganisation(this.product.company).then(
      (organisation) => {
        this.organisation = organisation;
      },
      (e) => {}
    );
  }
}
