import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { PtgInterface } from "src/app/model/ptgs/ptg-interface";
import { Unsubscribable } from "rxjs";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { UtilService } from 'src/app/services/util-service/utilService';
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-product-type-group-edit",
  templateUrl: "./product-type-group-edit.component.html",
  styleUrls: ["./product-type-group-edit.component.css"],
})
export class ProductTypeGroupEditComponent implements OnInit, OnDestroy{
  public ptg: PtgInterface;
  public ptgId: number;
  public resolvedPtg: PtgInterface;
  public ptgSubscription: Unsubscribable;
  public organisastionName: string = "";
  public isDescriptionValid: boolean = true;

  constructor(
    private ptgService: PtgService,
    private router: Router,
    private utilService: UtilService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private organisationService: OrganisationService,
    private userService: UserService,
    private titleService: Title
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  savePtg(): void {
    if (this.utilService.isStringNullOrEmpty(this.ptg.name)) {
      this.dialogService.openDialog(
        "PTG bearbeiten",
        "Bitte geben Sie einen PTG-Namn an!"
      );
      return;
    }


    if(!UtilService.areStringsValid(this.ptg.name, this.ptg.description)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Der Name oder die Beschreibung sind zu lang."
      );
      return;
    }

    this.ptgService.putItem(this.ptg).then(
      (ptg) => {
        var url = this.router.url.replace("/edit", "");
        this.router.navigateByUrl(url);
        this.dialogService.openDialog(
          "Produkttyp bearbeitet",
          "Der Produkttyp: " +
            ptg.name +
            " wurde erfolgreich bearbeitet."
        );
      },
      (e) => {}
    );
  }

  savePtgCancel(): void {
    var url = this.router.url.replace("/edit", "");
    this.router.navigateByUrl(url);
  }

  ngOnInit(): void {
    this.ptgId = this.ptgService.activeItem.id;
    this.ptg = {
      id: undefined,
      name: "",
      description: "",
      organisationId: undefined,
    };
    if (this.ptgId) {
      this.resolvedPtg = this.ptgService.activeItem;
      this.ptg = this.resolvedPtg;
      this.cd.detectChanges();
    }
    if (this.organisationService.activeItem != null) {
      this.organisastionName = this.organisationService.activeItem.organisationName;
    }
    this.titleService.setTitle(this.ptgService.activeItem.name + ": Produkttyp bearbeiten - BIMSWARM");
  }

  ngOnDestroy(): void {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
    if (this.ptgSubscription) {
      this.ptgSubscription.unsubscribe();
    }
  }

  public onDescriptionValidityChange(descriptionValid: boolean): void {
    this.isDescriptionValid = descriptionValid;
  }
}
