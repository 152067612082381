import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "addEntriesToList-panel",
  templateUrl: "./addEntriesToList-panel.component.html",
  styleUrls: ["./addEntriesToList-panel.component.css"],
})
export class AddEntriesToListComponent implements OnInit {
  public newKeyword: string = "";

  @Input()
  public keywordPanel: boolean;

  @Input()
  public editable: boolean;

  /**
   * Keywords ist ein Array, welches entweder Features oder Schlagworte enthält und einem Produkt/PTG/Zertifikat hinzufügt
   */
  @Input()
  public keywords: string[] = [];

  @Output()
  public keywordsChange = new EventEmitter();

  constructor(private dialogService: DialogService) {}

  ngOnInit() {
    if (!this.keywords) {
      this.keywords = [];
    }
  }

  addKeyword() {
    if (this.keywords.length < 20) {
      if (this.newKeyword) {
        this.keywords.push(this.newKeyword);
      }
      this.newKeyword = "";
      this.keywordsChange.emit(this.keywords);
    } else {
      this.dialogService.openDialog(
        "Zu viele Produkt-Features",
        "Ein Produkt darf nur 20 Features besitzen. Um ein anderes Feature hinzuzufügen, entfernen Sie bitte ein schon hinzugefügtes."
      );
    }
  }

  removeKeyword(keyword) {
    const index = this.keywords.indexOf(keyword, 0);
    if (index > -1) {
      this.keywords.splice(index, 1);
    }
  }
}
