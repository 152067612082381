import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CommonFeatureInterface } from "src/app/model/commonFeature/common-feature-interface";
import { LinkingMethod } from 'src/app/model/enums/linkingMethod';
import { CommonFeatureService } from "src/app/services/common-feature-service/common-feature.service";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { API_BASE_PRODUCTSERVICE } from 'src/environments/api';
import { CommonFeatureItemEditFormComponent } from "../common-feature-item-edit-form/common-feature-item-edit-form.component";

@Component({
  selector: "app-common-feature-item",
  templateUrl: "./common-feature-item.component.html",
  styleUrls: ["./common-feature-item.component.css"],
})
export class CommonFeatureItemComponent implements OnInit {
  @ViewChild(CommonFeatureItemEditFormComponent)
  private child: CommonFeatureItemEditFormComponent;

  @Input()
  public commonFeature: CommonFeatureInterface;

  @Input()
  public index: number;

  @Output()
  public updatedFeature = new EventEmitter<CommonFeatureInterface>();

  @Output()
  public deletedFeature = new EventEmitter<CommonFeatureInterface>();

  public showFeatureEntries: boolean = false;
  public showEditFeature: boolean = false;

  tempFeatureEntries: string[] = [];

  private readonly collapseText = "Einklappen";
  private readonly expandText = "Ausklappen";
  private readonly showEntryButtonId = "showEntryButton";

  constructor(
    private commonFeatureService: CommonFeatureService,
    private dialogService: DialogService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.tempFeatureEntries = this.commonFeature.featureEntries.map(
      (entry) => entry.name
    );

  }

  /* Toggles whether the Entries of a Feature are shown or not */
  public toggleFeatureEntries(): void {
    this.showFeatureEntries = !this.showFeatureEntries;

    const toggleButton = document.getElementById(
      this.showEntryButtonId + this.index
    ) as HTMLButtonElement;

    if (this.showFeatureEntries) {
      toggleButton.firstChild.textContent = this.collapseText;
    } else {
      toggleButton.firstChild.textContent = this.expandText;
    }
  }

  /* Enables the Frontend Edit Mode in which the Values are shown in Input Fields */
  public editFeature(): void {
    this.tempFeatureEntries = this.commonFeature.featureEntries.map(
      (entry) => entry.name
    );
    this.showEditFeature = true;
    this.showFeatureEntries = false;
  }

  /* Cancels the Edit Mode and turns the Values back to Labels */
  public cancelEdit(): void {
    this.tempFeatureEntries = [];
    this.showEditFeature = false;
  }

  /* Calls the Service to update the Feature with the Form Values */
  public saveFeature(): void {
    const featureElements = this.child.getElements();
    if (featureElements) {
      const newEntryToOldEntryMap = {};
      for (let i = 0; i < this.commonFeature.featureEntries.length; i++) {
        if (this.commonFeature.featureEntries[i].name !== featureElements.featureEntries[i]) {
          newEntryToOldEntryMap[this.commonFeature.featureEntries[i].id.toString()] =  featureElements.featureEntries[i];
        }
      }
      this.commonFeatureService
        .updateFeature(
          this.commonFeature.id,
          featureElements.name,
          featureElements.filterOption,
          featureElements.featureEntries,
          featureElements.showDetailPage,
          featureElements.linkerMethod,
          newEntryToOldEntryMap
        )
        .subscribe((success) => {
          this.updatedFeature.emit(success);
        });
    }
  }

  /* Calls the Service to delete the Feature */
  public deleteFeature(): void {
    this.dialogService
      .openConfirmDialog(
        "Löschen eines Common Features",
        "Nein",
        "Ja",
        "Sind sie sich sicher das sie " +
          this.commonFeature.name +
          " löschen wollen?"
      )
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.commonFeatureService
            .deleteFeature(this.commonFeature.id)
            .subscribe(() => {
              this.deletedFeature.emit(this.commonFeature);
            });
        }
      });
  }

  sortByName(list) {
    return list.slice().sort((a, b) => a.localeCompare(b));
  }
}
