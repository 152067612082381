<app-websocket #websocket>></app-websocket>
<div class="tasklist">
  <h1>{{ process.name }} - Hochladen von Dateien für Dienste</h1>
  <!-- Check if all uploads are completed and shouldRenderList is empty -->
  <ng-container *ngIf="isAllShouldRenderCompleted()">
    <div style="color: green;">
      Alle Uploads wurden erfolgreich abgeschlossen. Sie können nun die Dienstkette starten.
    </div>
    <button (click)="handleStartToolchain()" [disabled]="!requirementsMet">Starten</button>
  </ng-container>
  <!-- Message for files to upload -->
  <div *ngIf="shouldRenderList.length > 0 && !isAllShouldRenderCompleted()" style="color: red;">
    Bitte laden Sie die folgenden Dateien hoch:
    <ul>
      <ng-container *ngFor="let element of shouldRenderList">
        <!-- Check if the element is not in completedUploads -->
        <ng-container *ngIf="!isElementCompleted(element)">
          <li>{{ element }}</li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
  <!-- Task cards -->
  <ng-container *ngIf="elements.length > 0; else errorTemplate">
    <div *ngFor="let serviceId of elements; let index = index" style="width: auto; margin: 0.9vh;">
      <div class="task-card" style="margin: 0.9vh;">
        <h2>Dienst {{ removeLastWord(serviceId.attributes.name.value) }}</h2>
        <div style="display: inline-flex;
                    flex-direction: row;
                    align-items: baseline;
                    justify-content: space-between;
                    width: 100%;">
          <p class="mb-6">Dienst ID:</p> <h3>{{ serviceId.id }}</h3>
        </div>
        <div class="card">
          <ng-container *ngFor="let result of renderInputElement(serviceId, index, elements)">
            <div class="card-body" *ngIf="result.shouldRender && !completedUploads.includes(result.dataObjectReferences.toLowerCase())">
              <div style="display: inline-flex;
                    flex-direction: row;
                    align-items: baseline;
                    width: 100%;">
                <h4 style="margin-right: 5vw;"> Bitte laden Sie die Eingabedatei hoch:</h4>
                <h5> {{ result.dataObjectReferences }} </h5>
              </div>
              <app-file-uploader [fileName]="result.dataObjectReferences" (uploadComplete)="handleUploadComplete($event)"></app-file-uploader>
            </div>
            <div class="card-body" *ngIf="!result.shouldRender">
              <p style="font-size: x-small;">Kein Datei-Upload erforderlich.</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #errorTemplate>
    <h2>Fehler beim Laden von BPMN. Bitte versuchen Sie erneut, die Dienstkette auszuführen.</h2>
  </ng-template>
</div>
