import { ProductInterface } from "src/app/model/products/product-interface";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { Subscription } from "rxjs";
import { NavbarService } from "src/app/services/componentServices/navbar-service/navbar-service";
import { API_BASE_USERSERVICE, API_MAP } from "src/environments/api";
import { MarketScreenState } from "src/app/model/enums/marketScreenState";
import {
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { ContactInterface } from "src/app/model/organisations/contact-interface";
import { ContactMailInterface } from "src/app/model/user/contact-mail-interface";
import { ActivatedRoute, Router } from "@angular/router";
import { OrganisationType } from "src/app/model/enums/organisationType";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { ProductService } from "src/app/services/dataServices/product-service/productService";
import { MarketplaceRedirectServiceService } from "src/app/services/marketplace-redirect-service/marketplace-redirect-service.service";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { SelectedProductPanelComponent } from 'src/app/components/products-ptg-dependencies/selected-product-panel/selected-product-panel.component';

const errorMessageRequired: string = "Dieses Feld darf nicht leer sein";
const errorMessageEmail: string = "Diese Email ist nicht korrekt";
const emailPattern: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: "app-organisation-detail-display",
  templateUrl: "./organisation-detail-display.component.html",
  styleUrls: ["./organisation-detail-display.component.css"],
})
export class OrganisationDetailDisplayComponent implements OnInit, OnDestroy {
  @ViewChild("sideImageHexagon") sideImageHexagon: ElementRef;

  @Output() goBack = new EventEmitter<void>();
  @Input() actualProduct: ProductInterface;

  displayedColumns: string[] = ['merkmal', 'value'];

  selectedTab: number = 0;
  activatedRoute: ActivatedRoute;
  selectTab(tabIndex: number) {
    this.selectedTab = tabIndex;
  }

  private routeSubscription: Subscription;
  public organisation: OrganisationInterface;
  private contact: ContactInterface;
  private organisationPictureSource: string;
  private organisationPreviewPictureSource: string;
  public mode = "Overview";
  private domainName: string;
  private products: ProductInterface[];
  public displaySpinner: boolean = true;
  private certificates: CertificationInterface[];
  private phoneNumber: string;
  private companyName: string;
  private url: string;
  private imageBackupSource: string = environment.defaultPictureProducts;

  private showFormToggle: boolean = false;

  private contactForm: FormGroup = this.formBuilder.group({
    subject: ["", Validators.required],
    titel: ["", Validators.required], // Anrede
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    email: [
      "",
      [Validators.required, Validators.email, Validators.pattern(emailPattern)],
    ],
    message: ["", Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private organisationService: OrganisationService,
    private activeRoute: ActivatedRoute,
    private productService: ProductService,
    private certificationService: CertificationService,
    private router: Router,
    private navbarService: NavbarService,
    private cd: ChangeDetectorRef,
    private marketplaceRedirectionService: MarketplaceRedirectServiceService,
    private dialogService: DialogService,
    private titleService: Title
  ) {}

  ngOnChanges() {
    if(this.actualProduct != null)
    {
    this.organisationService.getOrganisationByDomainOrName(this.actualProduct.name).then((organisation) => {
      this.organisation = organisation;
    })
    }
  }

  ngOnInit() {
    this.displaySpinner = false;
  }

  ngOnDestroy(): void {

  }

  getErrorMessage(isEmailError: boolean = false): string {
    return isEmailError ? errorMessageEmail : errorMessageRequired;
  }

  submitForm(): void {
    if (this.contactForm.valid) {
      let contactMail = this.createContactMail();
      this.organisationService
        .sendContactMail(contactMail)
        .subscribe(() =>
          this.dialogService
            .openDialog("Nachricht erfolgreich gesendet", "")
            .subscribe(() => this.closeAndResetForm())
        );
    } else {
      this.dialogService.openDialog(
        "Fehler aufgetreten",
        "Prüfen Sie bitte Ihre Eingabefelder"
      );
    }
  }

  get firstName() {
    return this.contactForm.get("firstName");
  }
  get lastName() {
    return this.contactForm.get("lastName");
  }
  get titel() {
    return this.contactForm.get("titel");
  }
  get subject() {
    return this.contactForm.get("subject");
  }
  get message() {
    return this.contactForm.get("message");
  }
  get email() {
    return this.contactForm.get("email");
  }

  public imagePath(fileId: string): string {
    return "/proxy/api/v0/fileservice/file/" + fileId;
  }

  /**
   * Loads the organisation details, including both the product and the certification details of the organisation
   */
  loadOrganisationDetails(): void {
    this.organisationService
      .getOrganisationByDomainOrName(this.domainName)
      .then((organisation: OrganisationInterface) => {
        this.organisation = organisation;
        this.products = this.productService.getProductsOfOrganisation(
          organisation.organisationId
        );
        this.certificates = this.certificationService.getCertificationsOfOrganisation(
          organisation.organisationId
        );

        if (this.organisation) {
          this.contact = this.organisation.contacts[0];
          //TODO LUCAS
          if (this.organisation.fileId) {
            this.organisationPictureSource = this.imagePath(this.organisation.fileId)
          } else {
            if (
              this.organisation.organisationType ===
              OrganisationType.PRODUCT_PROVIDER
            ) {
              this.organisationPictureSource =
                environment.defaultPictureOrganisationProducts;
            } else {
              this.organisationPictureSource =
                environment.defaultPictureOrganisationPTGs;
            }
          }
          if (this.organisation.previewPictureId) {
            this.organisationPreviewPictureSource =
              API_BASE_USERSERVICE +
              API_MAP["organisationPictures"]["GET_PREVIEW"].replace(
                ":organisationId",
                String(this.organisation.organisationId)
              );
          } else {
            if (
              this.organisation.organisationType ===
              OrganisationType.PRODUCT_PROVIDER
            ) {
              this.organisationPreviewPictureSource =
                environment.defaultPictureOrganisationProducts;
            } else {
              this.organisationPreviewPictureSource =
                environment.defaultPictureOrganisationPTGs;
            }
          }
        }
        if (!this.cd["destroyed"]) {
          this.cd.detectChanges();
        }
      });
  }

  //TODO: Methode wird nicht benutzt. Im html ist der dazugehörige Button auskommentiert
  /**
   * Routes back to the marketplace overview page
   */
  showOverview(): void {
    this.navbarService.activeScreenSubject.next(
      MarketScreenState.Organisations
    );
    this.router.navigate(["/marktplatz"]);
  }

  /**
   * Closes and resets the contact form
   */
  closeAndResetForm(): void {
    this.contactForm.reset();
    this.phoneNumber = null;
    this.companyName = null;
    this.url = null;
    this.showFormToggle = false;
  }

/**
leitet weiter zum Produkt Tab / Zertifikations Tab  und zeigt alle Produkte der Org an
*/
 routeToProducts(  organisationType: OrganisationType): void {

//let oldTabUrl = this.router.url;

//let searchFor = 'organisationId=';
//let tmpId = oldTabUrl.substring(oldTabUrl.indexOf(searchFor) + searchFor.length);
//let currId = tmpId.split('&')[0];
//let idOfOrg = Number(currId);
let urlTo = organisationType === OrganisationType.PRODUCT_PROVIDER ? '/marktplatz?tab=0&orgId=':'/marktplatz?tab=1&orgId=' ;
let tab = organisationType === OrganisationType.PRODUCT_PROVIDER ? 0 : 1;


  const queryParams = {
    tab:  tab,
    productId: null,
    certificationId: null,
    organisationId: null,
    category: null,
    ptgId: null,
    certificationType: null,
    appSubPro: null,
    orgId: this.organisation.organisationId,
    certifiId: null,
    search: null,
    attr: null,
    rating: null
  };
  this.goBack.emit();
  this.router.navigate(
    [],
    {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    }
  );

  //this.goBack.emit();
//this.router.navigateByUrl(urlTo + idOfOrg + "&flag=orId");


 }




  /**
   * Creates a contact mail
   */
  private createContactMail(): ContactMailInterface {
    let contactMail: ContactMailInterface = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      email: this.email.value,
      contact: this.contact,
      message: this.message.value,
      subject: this.subject.value,
      titel: this.titel.value,
      authorOrganisationName: this.companyName,
      phoneNumber: this.phoneNumber,
      url: this.url,
    };
    return contactMail;
  }

  /**
   * Routes to the detail-page of a product
   *
   * @param  {number} id The id of a product
   */
  onProductClicked(id: number) {
    this.router.navigateByUrl("/marktplatz/products/" + id);
  }

  /**
   * Routes to the detail-page of a certificate
   *
   * @param  {number} id The id of a certificate
   */
  onCertificateClicked(id: number) {
    this.router.navigateByUrl("/marktplatz/certification/" + id);
  }

  routeToWebsite() {
    if (this.organisation.website == null || this.organisation.website == undefined || this.organisation.website == "")
      {
      return;
      }
    // check for correct scheme
    let url;
    if (this.organisation.website.match("http")) {
      url = this.organisation.website;
    } else {
      url = "http://" + this.organisation.website;
    }

    let valid_url;
    try {
      valid_url = new URL(url);
    } catch (e) {
      return;
    }

    window.open(url, "_blank");
  }
}
