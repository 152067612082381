import { Injectable } from "@angular/core";
import { ProductCertificationOverview } from "src/app/model/product-certification/product-certification-overview";
import { HttpClient } from "@angular/common/http";
import { UserService } from "../user-service/userservice";
import { API_BASE_PRODUCTSERVICE } from "src/environments/api";
import { ProductCertificationInformationInterface } from 'src/app/model/product-certification/product-certification-information-interface';

@Injectable({
  providedIn: "root",
})
export class ProductCertificationService {
  private http: HttpClient;
  private userService: UserService;

  constructor(http: HttpClient, userService: UserService) {
    this.http = http;
    this.userService = userService;
  }

  getProductCertificationOverviews(certVersionId: number, organisationId: number) {
    if (
      this.userService.currentUserAuthorizedActions &&
      !this.userService.isAuthorized("system", "nutzerrechte")
    ) {
      return;
    }
    return this.http.get<ProductCertificationOverview[]>(
      API_BASE_PRODUCTSERVICE +
        "/productcertification/overviews/" +
        certVersionId +
        "/" +
        organisationId
    );
  }

  getCertificationInformationOfProductVersion(versionId: number) {
    return this.http.get<ProductCertificationInformationInterface[]>(
      API_BASE_PRODUCTSERVICE + "/productcertification/overviews/" + versionId
    );
  }

  /**
   * Calls the backend method to receive productCertificationOverviewDTOs all products already certified by the given certVersion
   */
  getCertifiedProducts(certificationVersionId: number) {
    return this.http.get<ProductCertificationOverview[]>(
    API_BASE_PRODUCTSERVICE + "/productcertification/certified/" + certificationVersionId
    );
  }
}
