import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ComposerProductFileInterface } from "src/app/model/composer/composer-product-file-interface";
import { ComposerService } from "src/app/services/composer-service/composer.service";

@Component({
  selector: "app-composer-viewer-screen",
  templateUrl: "./composer-viewer-screen.component.html",
  styleUrls: [
    "./../composer-editor-screen/composer-editor-screen.component.css",
    "./composer-viewer-screen.component.css",
  ],
})
export class ComposerViewerScreenComponent implements OnInit {
  public xml: string;
  public xmlFileIds: ComposerProductFileInterface[];

  private paramSubscription: Subscription;

  constructor(
    private composerService: ComposerService,
    private changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.paramSubscription = this.route.paramMap.subscribe(async (params) => {
      const toolchainIdParameter = params.get("toolchainId");
      const toolchainId = parseInt(toolchainIdParameter);

      this.composerService
        .loadXMLForToolchain(toolchainId)
        .subscribe((xmlAndFileIds) => {
          if (xmlAndFileIds) {
            this.xml = xmlAndFileIds.xml;
            this.xmlFileIds = xmlAndFileIds.fileIds;
            this.changeDetector.detectChanges();
          }
        });
    });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

  /* Redirects to the Overview Page */
  public goToOverviewPage() {
    this.router.navigateByUrl("/composer");
  }
}
