import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Parser } from 'xml2js';
import { BehaviorSubject } from 'rxjs';
import { FileHandlerComponent } from '../file-handler/file-handler.component';
import { WebsocketComponent } from '../web-socket/web-socket.component';

@Component({
  selector: 'app-service-task',
  templateUrl: './service-task.component.html',
  styleUrls: ['./service-task.component.css']
})
export class ServiceTaskComponent implements OnInit {

  @ViewChild('fileUploader') fileUploader!: FileHandlerComponent;
  @ViewChild('websocket') websocket!: WebsocketComponent;

  socket: WebsocketComponent | null = null;
  process: any;
  elements: any[] = [];
  processState: any[] = [];
  notifications: any[] = [];
  requirementMet: boolean = true;
  notificationText: string = "";
  shouldRenderList: any[] = [];
  shouldNotRenderList: any[] = [];
  currentProcess: any[] = [];
  previousProcessStatus: any = null;
  isFileUploaded: boolean = false;
  bpmnJson: any = null;
  processID: any = null;
  uploadedFile: any = null;
  selectedFiles: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  processDefinitionName: string = "";
  processFiles: any[] = [];
  manualTasks: any[] = [];
  completedUploads: any[] = [];
  dataObjectList: any[] = [];
  requirementsMet: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) { 
    

  }

  ngOnInit(): void {
    const navigationState = history.state;
    if (!navigationState || !navigationState.process) {
     this.router.navigate(['/startprocess/list']);
    return;
  }
    this.process = navigationState.process;
    this.handleBpmn(this.process.xml, this.process);
  }

  handleBpmn(file: any, processDefinitionXML: any): void {
    const bpmnXml = file;
    const parser = new DOMParser();
    const xmlDocument = parser.parseFromString(bpmnXml, 'application/xml');
    this.handleBpmnJsonParsed(xmlDocument, processDefinitionXML);
    this.selectedFiles.next([xmlDocument]);
  }
  
  handleBpmnJsonParsed(bpmnJson: any, processDefinitionXML: any): void {
    this.bpmnJson = bpmnJson;
    this.elements = this.getElements(bpmnJson);
    this.processState = this.getProccess(bpmnJson);
    this.dataObjectList = this.analyzeDataObjects(bpmnJson);
    this.manualTasks = this.analyzeBPMN(bpmnJson, processDefinitionXML);
  }

  getElements(bpmnJson: any): any[] {
    if (bpmnJson) {
      const newElementIds: any[] = []; 
      const definitions = bpmnJson.all["Definitions_1"];
      if (definitions) {
        const process = definitions.children[0];
        if (process) {
            const childrenArray = Array.from(process.children);
              childrenArray.forEach((element: any) => {
              if (element.nodeName == 'bpmn:manualTask') {
                newElementIds.push(element);
              }
            });
        }
      }
      this.elements = newElementIds;
      return newElementIds;
    }
    return [];
  }
  
  getProccess(bpmnJson: any): any {
    if (bpmnJson) {
      const newProcess = [];
      const definitions = bpmnJson.all["Definitions_1"];
      if (definitions) {
        const process = definitions.children[0];
        if (process) { newProcess.push(process); }
      }
      this.processState = newProcess;
      return newProcess;
    }
    return [];
  }

  analyzeDataObjects(dataObjects: any): any {
    const dataObjectList: any = {};
    const definitions = dataObjects.all["Definitions_1"];
      if (definitions) {
        const process = definitions.children[0];
        if (process) {
          const childrenArray = Array.from(process.children);
              childrenArray.forEach((element: any) => {
              if (element.nodeName == 'bpmn:dataObjectReference') {
                const id = element.attributes["id"].value;
                let name = '';
                try {
                  name = element.attributes["name"].value;
                } catch (error) {
                  name = '';
                }
                if (!dataObjectList[name]) {
                  dataObjectList[name] = [];
                }
                dataObjectList[name].push({
                  id: id,
                  name: name,
                  path: '',
                });
              }
            });
        }}
    this.dataObjectList = dataObjectList;
    return dataObjectList;
  }
  

  
  analyzeBPMN(bpmnJson: any, processDefinitionXML: any): ManualTask[] {
    const manualTasks: ManualTask[] = [];
    let processKey = bpmnJson.all["Definitions_1"].children[0].attributes[0];
    bpmnJson = bpmnJson.all["Definitions_1"];
    const updateId = (element: any) => {
      if (typeof element === 'string' && element.includes(processKey.value)) {
        processKey = element;
      }
    };
    Object.keys(processDefinitionXML).forEach((key) => {
      const element = processDefinitionXML[key];
      updateId(element);
    })
    if (bpmnJson) {
      const process = bpmnJson.children[0];
      if (process) {
        const childrenArray = Array.from(process.children);
        childrenArray.forEach((element: any) => {
          if (element.nodeName == 'bpmn:manualTask') {
            const elements = Array.from(element.children);
            var incomingInput: any = "";
            var outgoingInput: any = "";
            var inputsInput: any = [];
            var outputsInput: any = [];
            elements.forEach((node: any) => {
              if (node.nodeName == 'bpmn:incoming') { 
                incomingInput = node.textContent;
              } 
              if (node.nodeName == 'bpmn:outgoing') { 
                outgoingInput = node.textContent;
              }
              if (node.nodeName =="bpmn:dataInputAssociation") {
                const inputsArray = Array.from(node.children);
                inputsArray.forEach((i: any) => {
                  if (i.nodeName == "bpmn:sourceRef"){
                    const sourceRef = i.textContent;
                    inputsInput.push({ sourceRef });
                  }
                 });
              }
              if (node.nodeName =="bpmn:dataOutputAssociation") {
                const outputsArray = Array.from(node.children);
                outputsArray.forEach((i: any) => {
                  if (i.nodeName == "bpmn:targetRef"){
                    const targetRef = i.textContent;
                    outputsInput.push({ targetRef });
                  }
                 });
              }
            });
            const manualTaskElements = element;
              const manualTaskInfo: ManualTask = {
                id: manualTaskElements.attributes["id"].value,
                name: manualTaskElements.attributes["name"].value,
                incoming: incomingInput,
                outgoing: outgoingInput,
                dataInputAssociations: inputsInput,
                dataOutputAssociations: outputsInput,
                bpmnKey: processKey,
                readyState: 'waiting',
              }
              manualTasks.push(manualTaskInfo);
          }
        });
      }
    }
    this.manualTasks = manualTasks;
    return manualTasks;
  }


renderInputElement(serviceId: any, index: number, elements: any[]): { shouldRender: boolean, dataObjectReferences: string | null }[] {
    const fileUploadData: { shouldRender: boolean, dataObjectReferences: string | null }[] = [];
    const targetRefs: any[] = [];
    const childrenArray = Array.from(serviceId.children);
    var previousOutputIds: any[] = [];
    var shouldRender: boolean = false;
    var dataObjectReferences: string | null = null;
    childrenArray.forEach((element: any) => {
      if (element.nodeName == 'bpmn:dataInputAssociation') { 
        const NodechildrenArray = Array.from(element.children);
        NodechildrenArray.forEach((node: any) => {
          if (node.nodeName == 'bpmn:sourceRef') { 
            const sourceRefId = node.textContent;
            let dataObjectName = "";
            dataObjectReferences = "";
            shouldRender = true;
            elements.forEach((prevServiceId: any) => {
              const previousOutputIdsArray = Array.from(prevServiceId.children);
              previousOutputIdsArray.forEach((pre: any) => {    
                if (pre.nodeName == 'bpmn:dataOutputAssociation') {    
                  const childrenArray = Array.from(pre.children);
                  childrenArray.forEach((child: any) => { 
                    if (child.nodeName == 'bpmn:targetRef') { 
                      previousOutputIds.push(child.textContent);
                    }
                  });  
                }
              }); 
            });

            if (previousOutputIds.includes(sourceRefId)) { shouldRender = false; }
            this.processState.forEach((processElement: any) => {
              const processArray = Array.from(processElement.children);
              processArray.forEach((element: any) => {   
                if (element.nodeName == 'bpmn:dataObjectReference' && element.id === sourceRefId) {
                  try {
                    dataObjectName = element.attributes.name.textContent;
                  } catch (error) {
                    dataObjectName = '';
                  }
                  dataObjectReferences = element.attributes.id.textContent + '.' + dataObjectName;
                  dataObjectReferences = dataObjectReferences.toLowerCase();
                  if (shouldRender && !this.shouldRenderList.includes(dataObjectReferences.toLowerCase())) {
                    this.shouldRenderList.push(dataObjectReferences.toLowerCase());
                    this.processFiles.push([dataObjectReferences.toLowerCase(), processElement.attributes.name.value.toLowerCase()]);
                  } else if (!shouldRender && !this.shouldNotRenderList.includes(dataObjectReferences.toLowerCase()) && !this.completedUploads.includes(dataObjectReferences.toLowerCase())) {
                    this.shouldNotRenderList.push(dataObjectReferences.toLowerCase());
                    this.completedUploads.push(dataObjectReferences.toLowerCase());
                  }
                  if (shouldRender) {
                    fileUploadData.push({ shouldRender: true, dataObjectReferences });
                }
                }
              });  
            });
          }
        });
      }
    });   
    return fileUploadData;
}


handleUploadComplete(uploadedFileName: string): void {
  if (
    this.shouldRenderList.includes(uploadedFileName.toLowerCase()) &&
    !this.completedUploads.includes(uploadedFileName.toLowerCase())
  ) {
    this.completedUploads.push(uploadedFileName);
    if (this.areAllRequiredUploadsCompleted()) {
      const updatedManualTasks = this.manualTasks.map((task) => {
        const isReady = this.isTaskReady(task, this.elements, this.shouldRenderList);
        return { ...task, readyState: isReady ? 'ready' : 'waiting' };
      });
      if (this.websocket && this.websocket.getReadyState() === WebSocket.OPEN) {
        const dataToSend = {
          manualTasks: updatedManualTasks,
          shouldRenderList: this.shouldRenderList,
        };
        this.websocket.sendMessage(JSON.stringify(dataToSend));
      } else {
        console.error('WebSocket connection not open.');
      }
    }
  }
}

isTaskReady(task: any, elements: any[], completedUploads: string[]): boolean {
  const previousOutputIds: string[] = [];

  for (const element of elements) {
    for (const child of element.children) {
      if (child.nodeName === 'bpmn:dataOutputAssociation') {
        const targetRefs = Array.from(child.children);
        const ids = targetRefs.map((ref: any) => ref.textContent);
        previousOutputIds.push(...ids);
      }
    }
  }

  const isReady = task.dataInputAssociations.every((inputAssoc: any) => {
    const sourceRefId = inputAssoc.sourceRef;
    return !completedUploads.includes(sourceRefId.toLowerCase());
  });
  return isReady;
}



areAllRequiredUploadsCompleted(): boolean {
  const allRequiredElements: string[] = [...this.shouldRenderList, ...this.shouldNotRenderList];
  const sortedAllRequiredElements: string[] = [...new Set(allRequiredElements)].sort();
  const sortedCompletedUploads: string[] = [...new Set(this.completedUploads)].sort();
  const areAllCompleted: boolean = JSON.stringify(sortedAllRequiredElements) === JSON.stringify(sortedCompletedUploads);
  if (areAllCompleted) {
    this.requirementsMet = true;
  }
  return areAllCompleted;
}

handleStartToolchain(): void {
  if (this.websocket && this.websocket.getReadyState() === WebSocket.OPEN) {
    const updatedManualTasks = this.getUpdatedManualTasks();
    const dataToSend = { shouldRenderList: this.shouldRenderList, shouldNotRenderList: this.shouldNotRenderList, manualTasks: updatedManualTasks, process: this.process };
    this.websocket.sendMessage(JSON.stringify(dataToSend));
  } 
  else { 
    console.error('WebSocket connection not open.'); 
  }
  this.router.navigate(['/composer/camundaTasks'], {  
    state: {
      bpmnXml: this.process.xml,
      shouldRenderList: this.shouldRenderList,
      shouldNotRenderList: this.shouldNotRenderList,
      manualTasks: this.manualTasks,
      process: this.process,
    }
  });
}

getUpdatedManualTasks(): any[] {
  return this.manualTasks.map(task => ({
    ...task,
    readyState: this.isTaskReady(task, this.elements, this.shouldRenderList) ? 'ready' : 'waiting'
  }));
}

isElementCompleted(element: any): boolean {
  return this.completedUploads.includes(element);
}

isAllShouldRenderCompleted(): boolean {
  return this.shouldRenderList.every(element => this.isElementCompleted(element));
}

removeLastWord(name: string): string {
  const words = name.split(' ');
  words.pop(); // Remove the last word
  return words.join(' ');
}
}

interface ManualTask {
  id: string;
  name: string;
  incoming: string;
  outgoing: string;
  dataInputAssociations: { sourceRef: string }[];
  dataOutputAssociations: { targetRef: string }[];
  bpmnKey: string;
  readyState: string;
}
