export enum DataType {
  PRODUCT = "PRODUCT",
  ATTRIBUTE = "ATTRIBUTE",
  PTG = "PTG",
  PRODUCT_VARIANT = "PRODUCT_VARIANT",
  PRODUCT_VERSION = "PRODUCT_VERSION",
  PTG_VERSION = "PTG_VERSION",
  ORGANISATION = "ORGANISATION",
  CERTIFICATION = "CERTIFICATION",
  CERTIFICATION_VARIANT = "CERTIFICATION_VARIANT",
  CERTIFICATION_VERSION = "CERTIFICATION_VERSION",
  TOOLCHAIN = "TOOLCHAIN",
  RATING = "RATING",
  BAUSTELLE = "BAUSTELLE",
  EDGESERVER = "EDGESERVER",
  SOFTWARE = "SOFTWARE",
  CONTENT = "CONTENT",
  DIENSTLEISTUNGEN = "DIENSTLEISTUNGEN",
  KÜNTSTLICHE_INTELLIGENZ = "KÜNTSTLICHE_INTELLIGENZ",
  ROBOT = "ROBOT"
}
