import { Component, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-text",
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
      <iframe
        class="dialog-text"
        width="99%"
        srcdoc="{{ data.message }}"
      ></iframe>
    </div>
    <div mat-dialog-actions>
      <button
        mat-button
        (click)="onClick(true)"
        class="btn_green"
        cdkFocusInitial
      >
        {{ data.buttonYes }}
      </button>
      <button
        mat-button
        (click)="onClick(false)"
        class="btn_red"
        cdkFocusInitial
      >
        {{ data.buttonNo }}
      </button>
    </div>
  `,
  styleUrls: ["./dialog.css"],
})
export class DialogText {
  constructor(
    public dialogRef: MatDialogRef<DialogText>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onClick(confirmed: boolean): void {
    this.dialogRef.close(confirmed);
  }
}
