<div class="grid" *ngIf="productEdit != undefined">
  <app-info-box [product]="this.productEdit"></app-info-box>
  <div class="version-divider">
    <h2>Varianten</h2>
    <div class="dividerline"></div>
  </div>
  <div>
    <!-- TODO: FIX missing Parameterns in case of undefined; flache Scope-Elemente -->
    <app-info-box *ngFor="let variant of this.productEdit.productVariants" [variant]="variant">
    </app-info-box>
  </div>
</div>
