const svgSpecification = "http://www.w3.org/2000/svg";

/* Checks if the given String is a SVG and parses it to a Document */
export function parseSvg(svg: string): Document {
  if (svg.substring(0, 4) === "<svg" && svg.indexOf(svgSpecification) !== -1) {
    const parsed = parseDocument(svg);

    return parsed;
  }
  return undefined;
}

/* Parses a String to a Document Element */
function parseDocument(svg: string): Document {
  const parser = new DOMParser();
  return parser.parseFromString(svg, "text/xml");
}
