import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { LoginUserInterface } from "../../model/user/login-user-interface";
import { UserInterface } from "../../model/user/user-interface";
import { MembershipInterface } from "../../model/user/user-memberships-interface";
import { BehaviorSubject, Observable, Subscribable } from "rxjs";
import { OrganisationInterface } from "../../model/organisations/organisation-interface";
import { OrganisationMemberInterface } from "../../model/organisations/organisationMember-interface";
import { AuthService } from "../auth-service/auth.service";
import { environment } from "src/environments/environment";
import { ServiceError } from "src/app/components/service-error";
import { OrganisationType } from "../../model/enums/organisationType";
import { OrganisationChangeInterface } from "src/app/model/organisations/organisation-change-interface";
import { API_MAP } from "src/environments/api";
import { PublicProfileInterface } from "src/app/model/user/public-profile-interface";
import { LoggedInProfileInterface } from 'src/app/model/user/loggedin-profile-interface';
import { ProjectInterface } from "src/app/model/user/user-project-interface";


const SWARM_API = "/proxy/api/v0/userservice";
const USER_PREFIX = "/users";
const ACCOUNT_PREFIX = "/accounts";
const USER_PICTURE_PREFIX = USER_PREFIX + "/pictures";
const ORGA_PICTURE_PREFIX = "/organisations/pictures";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable()
export class UserService {

  public currentUser: UserInterface;
  public currentUserSubject: BehaviorSubject<UserInterface>;
  public currentUserObservable: Observable<UserInterface>;

  public currentMemberships: Array<OrganisationInterface> = [];
  public currentMembershipsSubject: BehaviorSubject<
    Array<OrganisationInterface>
  >;
  public currentMembershipsObserver: Observable<Array<OrganisationInterface>>;
  public currentUserPictureChangeSubject: BehaviorSubject<boolean>;
  public currentUserPictureChangeObservable: Observable<boolean>;

  public newUser;
  public loggedIn: boolean = false;
  private isPlatformAdmin: boolean = false;

  public currentUserAuthorizedActions;
  public currentUserAuthorizedActionsSubject: BehaviorSubject<{}>;
  public currentUserAuthorizedActionsObserver: Observable<{}>;

  public redirectProductId = null;

  ACTIVE_MAP: { [id: string]: string } = {
    ":productId": "",
    ":userId": "",
    ":organisationId": "",
    ":variantId": "",
    ":versionId": "",
    ":attributeId": "",
    ":certificationId": "",
    ":certificationVariantId": "",
    ":certificationVersionId": "",
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {
    this.ACTIVE_MAP[":productId"] = "0";
    this.ACTIVE_MAP[":userId"] = "0";
    this.ACTIVE_MAP[":organisationId"] = "0";
    this.ACTIVE_MAP[":variantId"] = "0";
    this.ACTIVE_MAP[":versionId"] = "0";
    this.ACTIVE_MAP[":attributeId"] = "0";
    this.ACTIVE_MAP[":certificationId"] = "0";
    this.ACTIVE_MAP[":certificationVariantId"] = "0";
    this.ACTIVE_MAP[":certificationVersionId"] = "0";

    this.currentUser = {organisationDto: {}};

    this.currentUserSubject = new BehaviorSubject<UserInterface>(
      this.currentUser
    );

    this.currentUserObservable = this.currentUserSubject.asObservable();

    this.currentMembershipsSubject = new BehaviorSubject<
      Array<OrganisationInterface>
    >(this.currentMemberships);
    this.currentMembershipsObserver = this.currentMembershipsSubject.asObservable();
    this.currentUserPictureChangeSubject = new BehaviorSubject<boolean>(true);
    this.currentUserPictureChangeObservable = this.currentUserPictureChangeSubject.asObservable();

    this.currentUserAuthorizedActionsSubject = new BehaviorSubject<{}>(
      this.currentUserAuthorizedActions
    );
    this.currentUserAuthorizedActionsObserver = this.currentUserAuthorizedActionsSubject.asObservable();
  }

  getUserMemberships(): void {
    if (!this.isAuthorized("system", "nutzerrechte")) {
      return;
    }
    this.http
      .get<MembershipInterface>(SWARM_API + USER_PREFIX + "/memberships")
      .subscribe((organisationMemberships) =>
        this.saveMemberships(organisationMemberships)
      );
  }

  saveMemberships(membership): void {
    if (!this.isAuthorized("system", "nutzerrechte")) {
      return;
    }
    if (membership) {
      this.currentMemberships = membership;
    }
    if (this.currentMemberships) {
      for (let organisation of this.currentMemberships) {
        if (organisation.pictureId) {
          organisation.organisationPicture =
            SWARM_API + ORGA_PICTURE_PREFIX + "/" + organisation.organisationId;
        } else {
          this.checkIfOrgaProductOrPtg(organisation);
        }
      }
    }
    this.currentMembershipsSubject.next(membership);
  }

  checkIfOrgaProductOrPtg(organisation: OrganisationInterface): void {
    let orga = undefined;
    if (orga && orga.organisationType) {
      if (orga.organisationType === OrganisationType.PRODUCT_PROVIDER) {
        organisation.organisationPicture =
          environment.defaultPictureOrganisationProducts;
      } else {
        organisation.organisationPicture =
          environment.defaultPictureOrganisationPTGs;
      }
    }
  }

  /**
   * If through deletion of a file (process within file-manager component) one or more fields of a user or
   * one of the user`s organisations point to a no longer existing file this function deletes the references
   * @param objectId the objectId that was connected to a now deleted file via object link
   * @param type the kind of connection the object link held to the now deleted file
   */
  deleteFileReference(objectId: number, type: string) {
    if (type == "profile-picture") {
      this.currentUser.fileId = null;
      this.currentUserPictureChangeSubject.next(true);
      return;
    }
    for (let i = 0; i < this.currentMemberships.length; i++) {
      if (this.currentMemberships[i].organisationId == objectId) {
        if (type == "organisation-profile-picture") {
          this.currentMemberships[i].fileId = null;
          return;
        }
        if (type == "organisation-preview-picture") {
          this.currentMemberships[i].previewFileId = null;
        }
      }
    }
  }

  logout(): void {
    this.loggedIn = false;
    this.currentUser = null;
    this.currentUserSubject.next(this.currentUser);
    this.currentMemberships = null;
    this.authService.logout();
    this.router.navigate(["/"]);
  }

  handleLogin(data: UserInterface): void {
    this.loggedIn = true;
    this.saveUser(data);
    this.router.navigate(['/marktplatz']);
    this.updateAuthorisation().then((authorized) => this.getUserMemberships());
  }

  isAlreadyLoggedIn(data: UserInterface) {
    this.saveUser(data);
    this.getUserMemberships();
    this.loggedIn = true;
  }

  updateAuthorisation() {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .get<any>(SWARM_API + "/users/permissions")
        .subscribe((permissions) => {
          if (permissions) {
            this.currentUserAuthorizedActions = permissions;
            //TODO
            //this.currentUserAuthorizedActionsSubject.next(permissions);
            this.isPlatformAdmin = false;
            if (permissions.system) {
              if (permissions.system.indexOf("adminrechte") >= 0) {
                this.isPlatformAdmin = true;
              }
            }
            resolve(true);
          } else {
            reject(false);
          }
        });
    });
    //get Authorisation by UserID
  }

  getAnonymousAuthorisation() {
    //get Authorisation by UserID
    return this.http
      .get<any>(SWARM_API + `/users/anon/permissions`)
      .subscribe((permissions) => {
        if (permissions) {
          this.currentUser.userName = environment.anonymous[0];
          this.currentUserAuthorizedActions = permissions;
          this.currentUserAuthorizedActionsSubject.next(permissions);
          this.isPlatformAdmin = false;
          if (permissions.system) {
            if (permissions.system.indexOf("PlatformAdmin") >= 0) {
              this.isPlatformAdmin = true;
            }
          }
        }
      });
  }

  isAuthorized(context, actionName): boolean {
    if (this.isPlatformAdmin) {
      return true;
    } else {
      if (this.currentUserAuthorizedActions) {
        if (this.currentUserAuthorizedActions[context]) {
          if (
            this.currentUserAuthorizedActions[context].indexOf(actionName) >= 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  resetPassword(username: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .put<UserInterface>(
          SWARM_API + ACCOUNT_PREFIX + "/resetPassword",
          username,
          httpOptions
        )
        .subscribe(
          () => {
            resolve(true);
          },
          () => {
            reject(false);
          }
        );
    });
  }

  resendMail(username: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .put<UserInterface>(
          SWARM_API + ACCOUNT_PREFIX + "/resendMail",
          username,
          httpOptions
        )
        .subscribe(
          () => {
            resolve(true);
          },
          () => {
            reject(false);
          }
        );
    });
  }

  register(user): Subscribable<UserInterface> {
    const json = JSON.stringify(user);
    if (!this.isAuthorized("system", "nutzerrechte")) {
      return;
    }
    return this.http.post<UserInterface>(
      SWARM_API + ACCOUNT_PREFIX + "/registration",
      json,
      httpOptions
    );
  }
  registerWithCode(user, code): Subscribable<UserInterface> {
    const json = JSON.stringify(user);
    if (!this.isAuthorized("system", "nutzerrechte")) {
      return;
    }
    return this.http.post<UserInterface>(
      SWARM_API + ACCOUNT_PREFIX + "/registration/" + code,
      json,
      httpOptions
    );
  }

  registerWithoutNeedingConfirmation(
    user: UserInterface
  ): Subscribable<UserInterface> {
    const json = JSON.stringify(user);
    if (!this.isAuthorized("system", "nutzerrechte")) {
      return;
    }
    return this.http.post<UserInterface>(
      SWARM_API + ACCOUNT_PREFIX + "/registration/invited",
      json,
      httpOptions
    );
  }

  confirmRegistration(token: string) {
    let params = new HttpParams();
    params = params.append("token", token);
    return this.http.put(
      `${SWARM_API}${ACCOUNT_PREFIX}/registration/activate`,
      "",
      { params: params }
    );
  }

  verifyNewUserName(token: string, isNewMail: boolean) {
    let params = new HttpParams();
    params = params.append("token", token);
    let suffix: string = isNewMail ? 'newMail' : 'oldMail';
    const url = SWARM_API + USER_PREFIX + "/verify/" + suffix;
    return this.http.put(url, "", { params: params });
  }

  transmitOrgaPosition(
    orgaName: String,
    mail: String,
    orgaId: number,
    message: String
  ): Observable<HttpResponse<String>> {
    if (!this.isAuthorized(orgaId, "changeOrganisationDetails")) {
      return;
    }
    const url =
      SWARM_API +
      ACCOUNT_PREFIX +
      "/invitation/" +
      orgaId +
      "?mail=" +
      mail +
      "&orgaName=" +
      orgaName +
      "&message=" +
      message;
    return this.http.put<String>(url, message, { observe: "response" });
  }

  changeUserDetails(userDetails: UserInterface): Subscribable<UserInterface> {
    const json = JSON.stringify(userDetails);
    if (!this.isAuthorized("profil", "changeUserAddress")) {
      return;
    }
    return this.http.put<UserInterface>(
      SWARM_API + USER_PREFIX,
      json,
      httpOptions
    );
  }

  uploadUserPicture(file: File): Subscribable<string> {
    if (!this.isAuthorized("profil", "changeUserPicture")) {
      return;
    }
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    const options = {
      headers: new HttpHeaders({}),
    };
    return this.http.post<string>(
      SWARM_API + USER_PICTURE_PREFIX,
      formData,
      options
    );
  }

  getUserPicture(): string {
    return SWARM_API + USER_PICTURE_PREFIX + "/" + this.currentUser.userId;
  }

  updateCurrentUser(): void {
    if (!this.isAuthorized("profil", "changeUserAddress")) {
      return;
    }
    this.getCurrentUser().subscribe((res: UserInterface) => {
      this.saveUser(res);
    });
  }

  deleteAccount(deleteUserDto: {
    password: string;
    organisationChanges: OrganisationChangeInterface[];
  }): Subscribable<UserInterface> {
    if (!this.isAuthorized("profil", "deleteUser")) {
      return;
    }
    const json = JSON.stringify(deleteUserDto);
    return this.http.post<UserInterface>(
      SWARM_API + ACCOUNT_PREFIX + "/delete",
      json,
      httpOptions
    );
  }

  deleteAccountAsPlatformAdmin(id: number) : Subscribable<UserInterface> {
    if(!this.isAuthorized("profil", "deleteUser")){
      return;
    }
    return this.http.post<UserInterface>(
      SWARM_API + ACCOUNT_PREFIX + "/deleteUserAsPA/" + id,
      httpOptions
    )
  }

  removeOrganisationMembership(
    organisationId: number
  ): Subscribable<UserInterface> {
    if (!this.isAuthorized("profil", "deleteMembership")) {
      return;
    }
    return this.http.delete<UserInterface>(
      SWARM_API + USER_PREFIX + "/memberships/" + organisationId
    );
  }

  acceptOrganisationMembership(
    organisationId: number
  ): Subscribable<UserInterface> {
    if (!this.isAuthorized("profil", "acceptMembership")) {
      return;
    }
    return this.http.get<UserInterface>(
      SWARM_API +
        USER_PREFIX +
        "/memberships/" +
        organisationId +
        "/confirmation"
    );
  }

  addOrganisationMembership(organisation: OrganisationInterface, callback: Function): void {
    if (!this.isAuthorized("profil", "requestMembership")) {
      return;
    }
    const json = JSON.stringify(organisation);
    const user = this.loggedIn ? this.currentUser : this.newUser;

    this.http
      .post<UserInterface>(
        SWARM_API + USER_PREFIX + "/memberships",
        json,
        httpOptions
      )
      .subscribe(
        (data) => {
          this.fetchCurrentUser();
        },
        (error) => {
          if (callback) {
            callback(new ServiceError(1, "ServerError", "Error", error.error));
          }
        }
      );
  }

  addOrganisationMembershipOnRegistry(
    organisation: OrganisationInterface,
    data: UserInterface
  ): void {
    if (!this.isAuthorized("profil", "requestMembership")) {
      return;
    }
    const json = JSON.stringify(organisation);
    const user = data;

    this.http
      .post<UserInterface>(
        SWARM_API + USER_PREFIX + "/memberships",
        json,
        httpOptions
      )
      .subscribe //data => //
      ();
  }

  fetchCurrentUser(): void {
    if (!this.isAuthorized("system", "nutzerrechte")) {
      return;
    }
    this.getCurrentUser().subscribe(
      (data: UserInterface) => {
        if(data.hasTrackingConsent){
          localStorage.setItem("hasTrackingConsent", String(data.hasTrackingConsent));
        }
        this.saveUser(data);
      },
      (error) => {
        //
      }
    );
    this.getUserMemberships();
  }

  getCurrentUser(): Observable<UserInterface> {
    //KEINE NUTZERRECHTE ABFRAGEN SONST KAPUTT!!!
    this.http.get<UserInterface>(SWARM_API + USER_PREFIX).subscribe((user: UserInterface) => {
      if(user.hasTrackingConsent){
          localStorage.setItem("hasTrackingConsent", String(user.hasTrackingConsent));
        }
    });
    return this.http.get<UserInterface>(SWARM_API + USER_PREFIX);
  }

  public saveUser(user: UserInterface): void {
    this.currentUser = user;
    this.currentUserSubject.next(user);
  }

  updatePassword(credentials, callback: Function): void {
    if (!this.isAuthorized("profil", "changePassword")) {
      return;
    }
    const json = JSON.stringify(credentials);
    this.http
      .put<LoginUserInterface>(
        SWARM_API + ACCOUNT_PREFIX + "/password",
        json,
        httpOptions
      )
      .subscribe(
        (data) => {
          this.fetchCurrentUser();
          callback(null);
        },
        () => {
          callback(
            new ServiceError(
              1,
              "RequiredFieldsEmptyOrNull",
              "Passwort ändern fehlgeschlagen",
              "Ihr Passwort konnte nicht geändert werden, da das aktuelle Passwort nicht mit dem Ihres Accounts übereinstimmt."
            )
          );
        }
      );
  }

  updateEmail(credentials, callback: Function): void {
    if (!this.isAuthorized("profil", "changeUserName")) {
      return;
    }
    const json = JSON.stringify(credentials);
    this.http
      .put<LoginUserInterface>(
        SWARM_API + ACCOUNT_PREFIX + "/username",
        json,
        httpOptions
      )
      .subscribe(
        (data) => {
          callback(null);
        },
        () => {
          callback(
            new ServiceError(
                1,
                "RequiredFieldsEmptyOrNull",
                "E-Mail ändern fehlgeschlagen",
                "Die neue und die alte E-Mail-Adresse dürfen nicht identisch sein"
            )
          );
        }
      );
  }

  isUserAdminOfOrganisationOrPlattformAdmin(organisationId): boolean {

    return this.currentMemberships.some(
      (membership: OrganisationMemberInterface) =>
        membership.organisationId === organisationId &&
        (membership.role.toLowerCase() === "admin" || this.isPlatformAdmin)
    );
  }

  checkIfUserHasBeenInvited(token: string): Subscribable<string> {
    const requestOptions: Object = {
      responseType: "text",
    };
    return this.http.get<string>(
      SWARM_API + ACCOUNT_PREFIX + "/invitation/" + token,
      requestOptions
    );
  }

  isCurrentUserPlatformAdmin() {
    return this.isPlatformAdmin;
  }

  getNameOfUser(userId: number) {
    const requestOptions: Object = {
      responseType: "text",
    };
    const url =
      SWARM_API +
      API_MAP["users"]["NAME"].replace(":userId", userId.toString());

    return this.http.get<String>(url, requestOptions);
  }

  getPublicProfile(username: string): Subscribable<PublicProfileInterface> {
    const url: string =
      SWARM_API +
      API_MAP["users"]["PUBLIC_PROFILE"].replace(":username", username);
    return this.http.get<PublicProfileInterface>(url);
  }

  getLoggedInProfile(username: string): Subscribable<LoggedInProfileInterface> {
    const url: string =
      SWARM_API +
      API_MAP["users"]["LOGGEDIN_PROFILE"].replace(":username", username);

    return this.http.get<LoggedInProfileInterface>(url);
  }

  getUserProjects(userName: string): Subscribable<ProjectInterface[]> {
    const url: string =
      SWARM_API +
      API_MAP["users"]["PROJECTS"]+"/"+userName;

    return this.http.get<ProjectInterface[]>(url);
  }

  removeUserProject(projectId: number){
    return this.http.delete<ProjectInterface>(
      SWARM_API +
      API_MAP["users"]["PROJECTS"]+"/"+projectId
    );
  }

  addUserProject( project: ProjectInterface) {
    return this.http.post<ProjectInterface>(
      SWARM_API +
      API_MAP["users"]["PROJECTS"], project, httpOptions
    );
  }

  getUserTrackingConsent(userName: string): Subscribable<string>{
    const url: string =
      SWARM_API +
      API_MAP+"/"+userName+"/trackingConsent";

    return this.http.get<string>(url);
  }

  setUserTrackingConsent(userName: string, hasTrackingConsent:boolean){
    const url: string =
      SWARM_API +
      API_MAP+"/"+userName+"/trackingConsent";
    return this.http.post<boolean>(
      url, hasTrackingConsent, httpOptions
    );
  }


  saveProductId(id: any) {
    this.redirectProductId = id;
  }
}
