export const environment = {
  apiUrl: 'http://research.bimswarm.online/selfdescription',
  production: false,
  homeButtonLink: "/marktplatz",
  specialCharacterRegex: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,
  defaultPictureProducts: "/assets/svg-icons/standardIcon.svg",
  defaultPicturePTGs: "/assets/img/defaultPtg.png",
  defaultPictureOrganisationProducts: "/assets/svg-icons/products.svg",
  defaultPicuterOrganisationCertifications:
    "/assets/svg-icons/certification_ptg.svg",
  defaultPictureOrganisationPTGs: "/assets/svg-icons/ptgs.svg",
  defaultPictureProfile: "/assets/svg-icons/profile.svg",
  certificationAttributeIcon: "/assets/svg-icons/certification_attribute.svg",
  certificationPtgIcon: "/assets/svg-icons/certification_ptg.svg",
  certificationToolchain: "/assets/svg-icons/certification_toolchain.svg",
  certificationAttributeMandatory: "/assets/img/SWARM-Logo.png",
  certificationDefaultImage: "/assets/img/misc/certification_attribute_gold.png",
  anonymous: [
    "swarm-frontend",
    "swarm-frontend-dev",
    "swarm-frontend-staging",
    "swarm-frontend-prod",
    "swarm-frontend-prod2",
    "swarm-frontend-live",
  ],
  oauthLoginUri:
    "https://research.bimswarm.online/sso/oauth2/authorize?client_id=swarm-frontend-live&response_type=code&scope=read",
  oauthRegisterApp:
    "https://research.bimswarm.online/sso/oauth2/client/registration",
  // oauthLoginUri:
  //   "http://localhost/sso/oauth2/authorize?client_id=swarm-frontend&response_type=code&scope=read",
  // oauthRegisterApp: "http://localhost/sso/oauth2/client/registration",
  publicKey:
    "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAp/4L58ZRVoq2CjGWQRr/84QvNI6H+Rxeb7G+vfKRRtgc8m1q+TJonQjTJpKTOboVN8B6g16B3R3ZXyGkhH07EKgCp3zue+fuC6QluoWXNeIUsJfTlclTv3wV+ye2cQamwqSRrGtOg4Ja7qO9PolqATOJNO6iR7SFXrh6Nj2vAqKTEWmHNX7N3LaU15cUVh46itkPHlVQQQBxmGmWlWGn1OrYpJt85hCl94lflzWh/Nsy9hNPbxlj/GBPsOH1ECgwyR//ENnHih2hCMuppM5+TK2On0nPZdtJrq+vA0eXXYHnsr6UdaXjHtWH6yyMPmmbs8arRDcacC1vIE9DtiG5Ie1Tb7uFvYzr45Luaw5h2R6N253QSNIuXgZ1GA+oHxJN5BLKMcg7rerE0q2w0iTTTEbM67Eb9idn8lE5sMIbbnTIu9NpbBTnqv14gV0HXy9Blfl4svgpgRui4D0RXbl/Ce4JPedhwCiTQN4ik1SvAcjEYCqGNUOx1nBL1ar49rsKlk8kgBYGY7xZy90lHS1HWusw63o+sNsxYg3+56/2D6512sUvy7FEk3AyL912HP4MRiQyqTLRMfBwcLX8VqU/qxDl8Rh4tz5q1ra5/micaYhlSmbFWVZRHcgNYPzaCse5G1EJIYRhWfN7kXu6h+Ppdt5jeTcPO5kzS+TVL72itKMCAwEAAQ==",
  GA_TRACKING_ID: "",
  };

import Quill from "quill";
const Link = Quill.import("formats/link");
Link.sanitize = function (url) {
  if (!url.startsWith("https://") && !url.startsWith("/")) {
    url = "https://" + url;
  }
  return url;
};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
