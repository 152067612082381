import axios from 'axios';
import { from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import  http from "./http-common";

@Injectable({
  providedIn: 'root'
})
export class FileHandlerService {
  private uploadUrl: string = "https://research.bimswarm.online/camunda/upload";
  private uploadWithNameUrl: string = "https://research.bimswarm.online/camunda/uploadWithName";
  private filesUrl: string = "https://research.bimswarm.online/camunda/files";

  constructor() { }

  upload(file: File): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);

    return from(
      axios.post(this.uploadUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: progressEvent => {
        // Handle progress
      }
    })
    );
  }

  uploadWithName(file: File, name: string): Observable<any> {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);

    return from(
      axios.post(this.uploadWithNameUrl, formData, {
      onUploadProgress: progressEvent => {
        // Handle progress
      }
    })
    );
  }

  getFiles(): Observable<any> {
    return from(axios.get(this.filesUrl));
  }

  getFile(filename: string): Observable<Blob> {
    return from(
      axios.get(`${this.filesUrl}/${filename}`, {
        responseType: 'blob'
      })
    ).pipe(
      map(response => response.data)
    );
  }
}
