import {Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProductInterface } from 'src/app/model/products/product-interface';
import { ServiceError } from 'src/app/components/service-error';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { FileServiceService } from 'src/app/services/file-service/file-service.service';
import { environment } from 'src/environments/environment';
import { DocumentService } from 'src/app/services/dataServices/document-service/document.service';
import { UtilService } from 'src/app/services/util-service/utilService';
import { ProductCommonFeatureInterface } from 'src/app/model/commonFeature/product-common-feature-interface';
import { CommonFeatureService } from 'src/app/services/common-feature-service/common-feature.service';
import { ProductCategory } from 'src/app/model/enums/productCategory';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { UserService } from 'src/app/services/user-service/userservice';
import {BehaviorSubject, Observable} from "rxjs";
import { ComposerService } from 'src/app/services/composer-service/composer.service';

@Component({
  selector: 'app-product-edit-screen',
  templateUrl: './product-edit-screen.component.html',
  styleUrls: [
    './product-edit-screen.component.css',
    '../../../../../app.component.css',
  ],
})
export class ProductEditScreenComponent implements OnInit, OnDestroy {

  form;

  intialImageUrl: string;

  productEdit: ProductInterface;

  fetchedProduct: ProductInterface;

  putError: ServiceError;

  isInitialised = false;
  urlData: String;

  commonFeatures: ProductCommonFeatureInterface[] = [];

  private filesById;

  public productCategory = ProductCategory;
  private isDescriptionValid = true;
  public fileIdsToNames = new BehaviorSubject({});

  constructor(
    public organisationService: OrganisationService,
    public productService: ProductService,
    public documentService: DocumentService,
    public fileService: FileServiceService,
    public router: Router,
    private dialogService: DialogService,
    public activeRoute: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private commonFeatureService: CommonFeatureService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private titleService: Title,
    private composerService: ComposerService
  ) {

    // Reroutes to marketplace if the user is not a member of the organisation
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl('/marktplatz');
    }
  }

  
  onSaveEdit(): void {
    this.productEdit.pictureId;
    this.intialImageUrl;

    this.productService.ITEM_MAP[':organisationId'] = String(
      this.organisationService.activeItem.organisationId
    );
    let editPromise;

    if (
      !UtilService.areStringsValid(
        this.productEdit.name,
        this.productEdit.description
      )
    ) {
      this.dialogService.openDialog(
        'Produkt bearbeiten fehlgeschlagen',
        'Der Name oder die Beschreibung sind zu lang.'
      );
      return;
    }

    editPromise = this.productService.editProduct(this.productEdit);

    editPromise
      .then((product: ProductInterface) => {
        const url = this.router.url.replace('/edit', '');
        this.router.navigateByUrl(url);
        this.dialogService.openDialog(
          'Produkt bearbeitet',
          'Das Produkt ' + product.name + ' wurde erfolgreich bearbeitet.'
        );

        this.commonFeatureService
          .updateFeaturesForProduct(
            this.productEdit.id,
            this.commonFeatures.map((elem) => elem.entry.id)
          )
          .subscribe((success) => {});
      })
      .catch((e: Error) => {});
  }

  onCancelEdit(): void {
    this.router.navigate([
      'organisations',
      this.organisationService.activeItem.organisationId,
      'products',
      this.fetchedProduct.id,
    ]);
  }

  filterOrganisationName(organisationId): string {
    return this.organisationService.collection.filter(
      (organisation) => organisation.organisationId === organisationId
    )[0].organisationName;
  }

  removeTags(descriptionText: string) {
    descriptionText = descriptionText.replace('<p>', '');
    descriptionText = descriptionText.replace('</p>', '');
    this.productEdit.description = descriptionText;
    return descriptionText.length;
  }

  setProductEditCategory(event: ProductCategory): void {
    this.productEdit.category = event;
  }

  showCommonFeatureSelectionDialog() {
    this.dialogService
      .openCommonFeatureEditDialog([...this.commonFeatures])
      .subscribe((success) => {
        if (success) {
          this.commonFeatures = success;
        }
      });
  }

  public openProductToBaustelleItemDialog(
    toolchainItemName: string,
    toolchainItemDescription: string
  ): Observable<{
    productName: string;
    productId: string;
    productFileId: string;
  }> {
    return this.dialogService.openAddProductToBaustelleItem(
      toolchainItemName,
      toolchainItemDescription
    );
  }
  
 handleChooseProduct() {
  this.openProductToBaustelleItemDialog(
        this.productEdit.name,
        this.productEdit.description
      )
      .subscribe((productInformation) => {
        if (productInformation) {
        }
      });
  };
  // deletes a fileid from the gallery picture list
  public deleteFromGallery(fileId: string): void {
    const galleryIndex = this.productEdit.galleryFileIds.indexOf(fileId);
    this.productEdit.galleryFileIds.splice(galleryIndex, 1);
  }

  // deletes a fileid from the document file list
  public deleteDocument(fileId: string): void {
    const galleryIndex = this.productEdit.documentFileIds.indexOf(fileId);
    this.productEdit.documentFileIds.splice(galleryIndex, 1);
  }

  // changes the index of a picture in the gallery
  public moveGalleryItem(fileId: string, toLeft: boolean): void {
    const galleryIndex = this.productEdit.galleryFileIds.indexOf(fileId);
    if (toLeft && galleryIndex > 0) {
      const tmp = this.productEdit.galleryFileIds[galleryIndex - 1];
      this.productEdit.galleryFileIds[
        galleryIndex - 1
      ] = this.productEdit.galleryFileIds[galleryIndex];
      this.productEdit.galleryFileIds[galleryIndex] = tmp;
    }
    if (!toLeft && galleryIndex < this.productEdit.galleryFileIds.length - 1) {
      const tmp = this.productEdit.galleryFileIds[galleryIndex];
      this.productEdit.galleryFileIds[
        galleryIndex
      ] = this.productEdit.galleryFileIds[galleryIndex + 1];
      this.productEdit.galleryFileIds[galleryIndex + 1] = tmp;
    }
  }

  // opens the filemanager for setting documents or gallery images
  public openFileManagerPicture(target: string): void {
    const options = { multi: false, filter: false, upload: true };
    if (target == 'Dokumente' || target == 'Galerie') {
      options.multi = true;
    }
    this.dialogService
      .openFileManagerDialog(
        'Datei für ' + target + ' auswählen',
        'Abbrechen',
        'Übernehmen',
        'Wählen Sie eine Datei.',
        options,
        this.productEdit.fileId
      )
      .subscribe( (confirmed: string[])  => {
        if (confirmed && confirmed.length > 0) {
          this.setNewFileIds(target, confirmed);
        }
        if (this.productService.toBeDeletedIds) {
          this.productEdit.galleryFileIds = this.differenceIdList(this.productEdit.galleryFileIds, this.productService.toBeDeletedIds);
          this.productEdit.documentFileIds = this.differenceIdList(this.productEdit.documentFileIds, this.productService.toBeDeletedIds);
          this.productService.toBeDeletedIds.forEach((deletedFileId: string) => {
            if (deletedFileId == this.productEdit.fileId) {
              this.productEdit.fileId = null;
            }
          });
          this.productService.toBeDeletedIds = [];
          this.cd.detectChanges();
        }
        this.getDocumentNames();
      });
  }

  /**
   * Helpermethod for setting the new fileIds chosen in the filemanager
   * @param target target of the fileIds (logo, gallery, documents)
   * @param confirmed list of fileIds chosen in filemanager
   */
  private setNewFileIds(target: string, confirmed: string[]) {
    const productData = this.productEdit;
    if (target == 'Logo') {
      productData.fileId = confirmed[0];
    }
    if (target == 'Galerie') {
      confirmed.forEach((element: string) => {
        if (productData.galleryFileIds.indexOf(element) == -1) {
          productData.galleryFileIds.push(element);
        }
      });
    }
    if (target == 'Dokumente') {
      confirmed.forEach((element: string) => {
        if (productData.documentFileIds.indexOf(element) == -1) {
          productData.documentFileIds.push(element);
        }
      });
    }
    this.productEdit = productData;
    this.ref.detectChanges();
  }

  private getDocumentNames() {
    this.fileService.data$.subscribe(data => {
      for (const file in data) {
        this.fileIdsToNames[file] = data[file].filename;
      }
    });
    this.fileService.getMetaDataForFiles(this.productEdit.documentFileIds).subscribe(res => {
      const transitionMap = {};
      for (const fileInfo of res) {
        transitionMap[fileInfo.id] = fileInfo.filename;
      }
      this.fileIdsToNames.next(transitionMap);
      this.cd.detectChanges();
    });
  }

  public getNameOfDocument(id: string) {
    return this.fileIdsToNames.getValue()[id];
  }

  // gets fileinfo for a given fileid
  public fileInfo(fileId: string) {
    this.fileService.data$.subscribe((data) => {
      this.filesById = data;
    });
    // Racing Codition with File-Service.service.ts updateMyFiles() on Startup
    // So this.filesById can be empty when used to check for fid
    return Object.keys(this.filesById).length > 0
      ? this.filesById[fileId]
      : 'Loading...';
  }

  // helpermethod for imagepath of a fileid
  public imagePath(fileId: string) {
    if (fileId) {
      return '/proxy/api/v0/fileservice/file/' + fileId;
    }
    return '';
  }

  ngOnInit(): void {
    this.fetchedProduct = this.productService.activeItem;

    this.productEdit = {
      name: this.fetchedProduct.name,
      description: this.fetchedProduct.description,
      url: this.fetchedProduct.url,
      documentFileIds: this.fetchedProduct.documentFileIds,
      relatedProducts: this.fetchedProduct.relatedProducts,
      id: this.fetchedProduct.id,
      productVariants: this.fetchedProduct.productVariants,
      fileId: this.fetchedProduct.fileId,
      galleryFileIds: this.fetchedProduct.galleryFileIds,
      slogan: this.fetchedProduct.slogan,
      features: this.fetchedProduct.features,
    };
    this.productEdit.oldName = this.productEdit.name;
    if (this.productService.activeItem.pictureId) {
      this.intialImageUrl = this.productService.getProductPicture(
        this.productService.activeItem.id
      );
    } else {
      this.intialImageUrl = environment.defaultPictureProducts;
    }
    this.titleService.setTitle(this.productEdit.name + ': Produkt bearbeiten - BIMSWARM');
    this.commonFeatureService
      .getFeaturesForProduct(this.productEdit.id)
      .subscribe((success) => {
        this.commonFeatures = success;
      });
    this.getDocumentNames();
  }

  ngOnDestroy(): void {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }

  public onDescriptionValidityChange(descriptionValid: boolean): void {
    this.isDescriptionValid = descriptionValid;
  }

  private differenceIdList (confirmed: string[], deleted: string[]): string[] {
    deleted.forEach((deletedFileId: string) => {
      const index = confirmed.indexOf(deletedFileId);
      if (index > -1) {
        confirmed.splice(index, 1);
      }
    });
    return confirmed;
  }
}
