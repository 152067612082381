<div class="letter_wrapper">
  <app-secure-image
    *ngIf="fileId"
    [src]="getDefaultProfileImageUrl()"
    [fileId]="fileId"
    [circle]="true"
    [isComment]="true"
  ></app-secure-image>
  <div *ngIf="!fileId" [ngStyle]="{ 'height': '3em', 'width': '3em', 'margin-right': '3vw !important', 'background-color': this.colors[this.index % 6], 'border-radius' : '50%'}">
    <div class="center_elements">{{ this.letter }}</div>
  </div>

</div>
