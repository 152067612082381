import { Pipe, PipeTransform } from '@angular/core';
import { MarketplaceCheckbox } from '../model/marketplace/marketplace-checkbox';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: MarketplaceCheckbox[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter((it) => {
      return it.label.toLocaleLowerCase().includes(searchText);
    });
  }
}