import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import { ListElementInterface } from "src/app/model/listElement/listElement-interface";
import { ProductOverview } from "src/app/model/product-overview/product-overview";
import { BookmarkOverview } from 'src/app/model/bookmark-overview/bookmark-overview';
import {OrganisationService} from "../dataServices/organisation-service/organisationservice";
import {Router} from "@angular/router";

const SWARM_API = "/proxy/api/v0/productservice/productlist";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable()
export class ProductlistService {
  productList: BehaviorSubject<BookmarkOverview[]> = new BehaviorSubject<BookmarkOverview[]>([]);

  constructor(private http: HttpClient,
              private router: Router,
              private organisationService: OrganisationService) {}

  getProductList(): BehaviorSubject<BookmarkOverview[]> {
    return this.productList;
  }
  /**
   * Holt alle Produkte des Users
   */
  getAllProducts(): Promise<any> {
    return new Promise<any>(resolve => {
      this.getAllBookmarkElementsOfUser()
        .subscribe((allProducts) => {
          allProducts.forEach((element) => {
            if (element.displayType === 2) {
              this.organisationService.getOrganisation(element.id).then((orga) => element.fileId = orga.fileId);
            }
          });
          this.productList.next(allProducts);
          resolve();
          /* this.productList.forEach((product) => {
             this.malfunctionService.getMalfunctions(product.id).then(
               (success) => {
                 let activeMalfunctions = success.filter(
                   (malfunction) =>
                     malfunction.isPublic && malfunction.status !== "FIXED"
                 );
                 if (activeMalfunctions.length > 0)
                   product.hasActiveMalfunctions = true;
               },
               (failure) => (product.hasActiveMalfunctions = false)
             );
           }); */
        });
    });
  }

  getAllListElementsOfUser(): Observable<ProductOverview[]> {
    return this.http.get<ProductOverview[]>(SWARM_API);
  }

  getAllBookmarkElementsOfUser(): Observable<BookmarkOverview[]> {
    return this.http.get<BookmarkOverview[]>(SWARM_API + "/bookmark")
  }

  deleteElementFromList(productId: number, displayType: number): Observable<ProductOverview> {
    return this.http.delete<ProductOverview>(SWARM_API + "/" + productId + "/" + displayType);
  }

  addElementToList(productId: number, displayType: number): Observable<ListElementInterface> {
    return this.http.post<ListElementInterface>(
      SWARM_API + "/" + productId + "/" + displayType,
      null,
      httpOptions
    );
  }

  isProductListEmpty(): Observable<Boolean> {
    return this.http.get<Boolean>(SWARM_API + "/listEmpty");
  }
}
