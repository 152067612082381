import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter, SimpleChanges,
} from "@angular/core";
import { PtgInterface } from "../../../model/ptgs/ptg-interface";
import { PtgVersionInterface } from "../../../model/ptgs/ptg-version-interface";
import { VariantVersionInterface } from "../../../model/products/variant-version-interface";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { AttributeValidationServiceService } from "src/app/services/dataServices/dataValidation/attribute-validation-service/attribute-validation-service.service";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { Status } from "src/app/model/enums/status";
import {ActivatedRoute, Data, Router} from "@angular/router";

@Component({
  selector: "app-selected-ptg-panel",
  templateUrl: "./selected-ptg-panel.component.html",
  styleUrls: ["./selected-ptg-panel.component.css"],
})
export class SelectedPtgPanelComponent implements OnInit {
  @Input()
  version: VariantVersionInterface | CertificationVariantVersionInterface;

  @Input()
  catalogueAttributes: PtgVersionInterface[];

  @Output()
  changedPtgVersions = new EventEmitter<PtgVersionInterface[]>();

  allPTGs: PtgInterface[];

  allActivePtgVersions = {};

  constructor(
    public ptgService: PtgService,
    public attributeValidationService: AttributeValidationServiceService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  getPtgFromVersion(versionId: number): Promise<PtgInterface> {
    return  new Promise((resolve, reject) => {
      let result: PtgInterface = null;
      if (this.allPTGs != undefined) {
        for (let index = 0; index < this.allPTGs.length; index++) {
          const ptg = this.allPTGs[index];
          for (let i = 0; i < ptg.versions.length; i++) {
            const version = ptg.versions[i];
            if (version.id === versionId) {
              result = ptg;
              resolve(result);
            }
          }
        }
      }
    });
  }

  getActiveVersionsOfPtg(ptg: PtgInterface): PtgVersionInterface[] {
    const ptgVersions = ptg?.versions;
    return ptgVersions.filter((ptgVersion) => {
      return ptgVersion.status === Status.ACTIVE;
    });
  }

  getAllActivePtgVersions(ptgVersion) {
   /*if (Object.keys(this.allActivePtgVersions).length === 0) {
      return;
    } */ //Fuehrt dazu, dass man bei neuen Produkten/Varianten/Versionen keine Produkttypen hinzufuegen kann
    if (this.allActivePtgVersions[ptgVersion.id] !== undefined) {
      return this.allActivePtgVersions[ptgVersion.id];
    } else {
      this.ptgService.getPtgByTheirVersion(ptgVersion.id).subscribe((ptg : PtgInterface) => {
          this.allPTGs.push(ptg);
          this.cd.detectChanges();
        this.allActivePtgVersions[ptgVersion.id] = this.getActiveVersionsOfPtg(ptg);
        return this.allActivePtgVersions[ptgVersion.id];
      });
    }
  }

  removePtgVersion(ptgVersion: PtgVersionInterface): void {
    this.resetAttributesValidations(ptgVersion);
    this.version.ptgVersions = this.version.ptgVersions.filter(
      (version: PtgVersionInterface) => version.id !== ptgVersion.id
    );
    this.version.ptgVersionIds = this.version.ptgVersionIds.filter(
      (ptgId: number) => ptgId !== ptgVersion.id
    );
    this.changedPtgVersions.emit(this.version.ptgVersions);
  }

  resetAttributesValidations(ptgVersion: PtgVersionInterface) {
    ptgVersion.attributeValidations.forEach((atrVal) => {
      const attribute = atrVal.attribute;
      atrVal.attribute.validations.forEach((val) => {
        this.attributeValidationService.activeEntityFeatureValues.delete(
          attribute.id
        );
        this.attributeValidationService.attributesValidated.delete(
          attribute.id
        );
      });
    });
  }

  editThisPtgVersion(ptgVersion: PtgVersionInterface, event): void {
    let versionId: number = parseInt(event.value);
    let newPtgVersion: PtgVersionInterface = null;
    for (let index = 0; index < this.allPTGs.length; index++) {
      const ptg = this.allPTGs[index];
      for (let i = 0; i < ptg.versions.length; i++) {
        const version = ptg.versions[i];
        if (version.id === versionId) {
           newPtgVersion = version;
        }
      }
    }

    this.removePtgVersion(ptgVersion);
    if (!this.version.ptgVersions.includes(newPtgVersion)) {
      this.version.ptgVersions.push(newPtgVersion);
      this.version.ptgVersionIds.push(newPtgVersion.id);
    }
    this.changedPtgVersions.emit(this.version.ptgVersions);
  }

  ngOnInit(): void {
    this.route.data.subscribe((data: Data) => {
      this.allPTGs = data['ptgForProductVariantResolver'];
      if(this.allPTGs == null)
        this.allPTGs = [];
      for (const ptgVersion of this.version.ptgVersions) {
        this.getPtgFromVersion(ptgVersion.id).then((ptg) => {
          this.allActivePtgVersions[ptgVersion.id] = this.getActiveVersionsOfPtg(ptg);
        });
      }
    });
  }

}
