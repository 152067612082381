<div class="grid" *ngIf="variantService.activeVariant">
  <div>
    <div class="mini-grid-headline">
      <h1>{{ variantService.activeVariant.name }}</h1>
      <rounded-button
        [label]="'Variante bearbeiten'"
        (click)="routeEditVariant()"
      ></rounded-button>
      <rounded-button
        [label]="'Variante löschen'"
        (click)="deleteVariant()"
      ></rounded-button>
    </div>

    <div class="versionTable-row-header">
      <h4 class="version-table-first-column grid-item">Name</h4>
      <h4 class="grid-item">Status</h4>
      <h4 class="version-table-title grid-item">Vorgängerversion</h4>
    </div>
    <ng-container
      *ngFor="
        let version of this.variantService.activeItem
          .certificationVariantVersions
      "
    >
      <div
        class="versionTable-row"
        [ngClass]="{
          highlight: activeVersion != null && version.id == activeVersion.id
        }"
        (click)="showInfoOfVersion(version.id)"
      >
        <p class="version-table-first-column grid-item">
          {{ version.name }}
        </p>
        <p class="grid-item">
          {{ version.status }}
        </p>
        <p class="grid-item">
          {{
            version.previousVersion === null
              ? "-"
              : showPrevious(version.previousVersion)
          }}
        </p>
        <div
          class="mini-hex-button"
          [matTooltip]="'Version löschen'"
          [matTooltipPosition]="'below'"
          matTooltipClass="tooltip"
        >
          <hexagon
            [icon]="'minus'"
            [hover]="true"
            [outerColor]="'green'"
            (click)="deleteVariantVersionPress(version.id)"
          >
          </hexagon>
          <!------------------------------------------>
        </div>
        <div
          class="mini-hex-button"
          [matTooltip]="'Version bearbeiten'"
          [matTooltipPosition]="'below'"
          matTooltipClass="tooltip"
        >
          <hexagon
            [icon]="'edit'"
            [hover]="true"
            [outerColor]="'green'"
            (click)="editVariantVersionPress(version.id)"
          ></hexagon>
        </div>
        <rounded-button
          *ngIf="version.status === 'ACTIVE'"
          [label]="'Produkte zertizifieren'"
          (click)="productCertification(version.id)"
        ></rounded-button>
        <div
          class="mini-hex-button"
          [matTooltip]="'Nachfolgeversion erstellen'"
          [matTooltipPosition]="'below'"
          matTooltipClass="tooltip"
        >
          <hexagon
            [icon]="'plus'"
            [hover]="true"
            [outerColor]="'green'"
            (click)="addVariantVersionPress(version.id)"
          >
          </hexagon>
        </div>
      </div>
    </ng-container>
  </div>
  <app-version-info-box
    style="margin-top: 2em"
    *ngIf="activeVersion"
    [version]="activeVersion"
  ></app-version-info-box>
</div>
