<div class="two-columns" *ngIf="productVariantService.activeItem">
  <div>
    <h1>Variante bearbeiten</h1>
    <form #form="ngForm">
      <mat-form-field class="variant-name-field">
        <input
          matInput
          placeholder="Variantenname"
          [(ngModel)]="variantEditName"
          name="variantName"
          required
          [value]="variantEdit.name"
          #variantName="ngModel"
        />
        <mat-error *ngIf="variantName.errors?.required && variantName.touched">
          Bitte geben Sie einen Variantennamen ein.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="edit-feature-description">
        <textarea
          matInput
          placeholder="Kommentar (intern)"
          [(ngModel)]="variantEditComment"
          name="comment"
          [value]="variantEdit.comment"
        ></textarea>
      </mat-form-field>
      <app-text-editor
        [(editorText)]="variantEditDescription"
        (textValidityChange)="onDescriptionValidityChange($event)"
      ></app-text-editor>
      <div class="buttons">
        <button
          mat-raised-button
          class="btn_green"
          (click)="editVariant()"
          [disabled]="form.invalid || !isDescriptionValid"
        >
          Speichern
        </button>
        <button mat-raised-button class="btn_red" (click)="cancelVariantEdit()">
          Abbrechen
        </button>
      </div>
    </form>
  </div>
</div>
