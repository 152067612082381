<div *ngIf="productVariantService.activeVariant">
  <div class="two-colmn-grid">
    <div class="column1">
      <h1 class="no-overflow">{{ productVariantService.activeVariant.name }}</h1>
    </div>
    <div class="column2">
      <rounded-button style="margin-right: 1em;"
        [label]="'Variante bearbeiten'"
        (click)="routeEditVariant()"
        *ngIf="isOrgaAdminOrPlattformAdmin"
      ></rounded-button>
      <rounded-button
        [label]="'Variante löschen'"
        (click)="deleteVariant()"
        *ngIf="isOrgaAdminOrPlattformAdmin"
      ></rounded-button>
    </div>
  </div>
  <div class="grid">
    <div class="versionTable-row-header column1">
      <h4
        class="version-table-first-column grid-item"
        style="margin-top: 0.5em; margin-bottom: 0.5em"
      >
        Name
      </h4>
      <h4 class="grid-item">Status</h4>
      <h4 class="grid-item">
        Vorgängerversion
      </h4>
      <h4 class="grid-item" style="margin-left: 1em;">Zertifikate</h4>
    </div>
    <ng-container
      *ngFor="
        let version of this.productVariantService.activeItem.variantVersions
      "
    >
      <div
        class="versionTable-row column1"
        [ngClass]="{
          highlight: activeVersion != null && version.id == activeVersion.id
        }"
        (click)="showInfoOfVersion(version.id)"
      >
        <!-------------------COLMN1----------------------->
        <p class="version-table-first-column grid-item">
          {{ version.name }}
        </p>
        <!-------------------COLMN2----------------------->
        <p class="grid-item">
          {{ version.status }}
        </p>
        <!-------------------COLMN3----------------------->
        <p class="grid-item">
          {{
            version.previousVersion === null
              ? "-"
              : showPrevious(version.previousVersion)
          }}
        </p>
        <div
          class="mini-hex-button"
          [matTooltip]="'Version löschen'"
          [matTooltipPosition]="'below'"
          matTooltipClass="tooltip"
        >
          <hexagon
            [icon]="'minus'"
            [hover]="true"
            [outerColor]="'green'"
            (click)="deleteVariantVersionPress(version.id)"
            *ngIf="isOrgaAdminOrPlattformAdmin"
          >
          </hexagon>
          <!------------------------------------------>
        </div>
        <!--<hexagon [icon]="'edit'" [hover]="true" [outerColor]="'green'" (click)="editVariantVersionPress(version.id)">-->
        <!--</hexagon>-->
        <!-------------------COLMN4----------------------->
        <div
          class="mini-hex-button"
          [matTooltip]="'Version bearbeiten'"
          [matTooltipPosition]="'below'"
          matTooltipClass="tooltip"
        >
          <hexagon
            [icon]="'edit'"
            [hover]="true"
            [outerColor]="'green'"
            (click)="editVariantVersionPress(version.id)"
          ></hexagon>
        </div>
        <!-------------------COLMN5----------------------->
        <div
          class="mini-hex-button"
          [matTooltip]="'Nachfolgeversion erstellen'"
          [matTooltipPosition]="'below'"
          matTooltipClass="tooltip"
          *ngIf="isOrgaAdminOrPlattformAdmin"
        >
          <hexagon
            [icon]="'plus'"
            [hover]="true"
            [outerColor]="'green'"
            (click)="addVariantVersionPress(version.id)"
            *ngIf="isOrgaAdminOrPlattformAdmin"
          >
          </hexagon>
        </div>
        <!-------------------COLMN6----------------------->
        <div class="grid-item">
          <div class="two-column-grid">
            <div class="mini-grid-colum1">
              <p>
                {{
                  certificate === null
                    ? "-"
                    : certificate
                }}
              </p>
            </div>
            <div class="mini-grid-colum2">
              <p>
                <rounded-button class="grid-item"
                [label]="'Zertifikat beantragen'"
                (click)="requestCertification(version.id)"
              ></rounded-button>
              </p>
              <p>
                <rounded-button class="grid-item"
                [label]="'Status prüfen'"
                [color]="'yellow'"
                (click)="showCertificationRequestStatus(version.id)"
              ></rounded-button>
              </p>
            </div>
          </div>

        </div>
        <!------------------------------------------>
      </div>
    </ng-container>
    <div class="top-right">
      <app-version-info-box
      *ngIf="activeVersion"
      [version]="activeVersion"
      [product]="this.productService.activeProduct"
      [variant]="this.productVariantService.activeVariant"
    ></app-version-info-box>
    </div>
    
  </div> 
</div>
