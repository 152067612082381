<div class="container">
  <div class="idContainer">
    <label>Id</label>
    <p>{{ id }}</p>
  </div>
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="name" (keydown)="change()" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Beschreibung</mat-label>
    <input matInput [(ngModel)]="description" (keydown)="change()" />
  </mat-form-field>
  <div class="ptgAdding">
    <mat-form-field>
      <mat-label>Produkttypen</mat-label>
      <mat-select [formControl]="currentlySelectedPTG">
        <mat-option *ngFor="let option of options" [value]="option">{{
          option.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button class="btn_green" (click)="addToSelected()">+</button>
  </div>
  <label>Produkttypen</label>
  <div class="ptgRemoving">
    <div *ngFor="let option of selectedPTGs" class="removePTGOption">
      <span>{{option.name}}</span>
      <button mat-raised-button class="btn_red" (click)=removeFromSelected(option)>X</button>
    </div>
  </div>
  <div class="associatedProduct">
    <label>Produkt</label>
    <label>{{productName}}</label>
    <app-secure-image [fileId]="productFileId" [src]="imageBackupSource"></app-secure-image>
    <button mat-raised-button class="btn_green" (click)="openProductSelection()">Produkt wählen</button>
  </div>
</div>
<div class="buttons">
  <button mat-raised-button class="btn_green" (click)="save()" [disabled]="!changesMade">Speichern</button>
  <button mat-raised-button class="btn_red" (click)="cancel()">
    Abbrechen
  </button>
</div>
