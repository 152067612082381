import { Injectable } from "@angular/core";
import { AttributeInterface } from "src/app/model/attributes/attribute-interface";

@Injectable()
export class UtilService {
  constructor() {}

  isStringNullOrEmpty(val: string): boolean {
    if (val === undefined || val === null || val.trim() === "") {
      return true;
    }
    return false;
  }

  transformAttributeValue(attributeValue): string {
    if (attributeValue != null) {
      return attributeValue.replace(/\;/g, ", ");
    }
  }

  stripHTML(html) {
    var doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  sortByName(objectArray: any[]) {
    objectArray.sort((a, b) => {
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();

      return nameA.localeCompare(nameB);
    });
  }

  public checkAttrDatatype(
    attribute: AttributeInterface,
    validationValue: string
  ) {
    let result: any;
    switch (attribute.datatype) {
      case "NUMERIC": {
        result = parseInt(validationValue);
        break;
      }
      case "BOOLEAN": {
        result =
          validationValue == null || validationValue === "undefined"
            ? null
            : validationValue === "true";
        break;
      }
      case "LIST": {
        if (validationValue) {
          result = validationValue.split(";");
        }
        break;
      }
    }
    return result;
  }

  /**
   * helper method to check for string lengths of the name and description of entities ( name 255, description 10000 letters
   */
  public static areStringsValid(name: string, description: string) {
    let checkName: boolean = true;
    if (name) {
      checkName = name.length < 61;
    }

    let checkDescr: boolean = true;
    if (description) {
      checkDescr = description.length < 10001;
    }

    return checkName && checkDescr;
  }

  public getTransformedAttributeValue(dataType: string, value: string) {
    if (dataType === "BOOLEAN") {
      if (value == null) {
        return "Keine Angabe";
      } else if (value) {
        return "Ja";
      } else {
        return "Nein";
      }
    } else if (dataType === "LIST") {
      if(value != null)
      {
      return value.replace(/;/g, ", ");
      }
    }
    return value;
  }
}
