import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { ProductVariantService } from "../services/dataServices/product-variant-service/product-variant.service";
import { UserService } from "../services/user-service/userservice";

@Injectable()
export class VariantResolver implements Resolve<any> {
  constructor(private variantService: ProductVariantService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    let variant = parseInt(route.paramMap.get("variantId"));
    if (variant) {
      await this.variantService.setActiveVariant(variant);
    }
  }
}
