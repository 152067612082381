import { Injectable } from '@angular/core';
import { CamundaRestService } from './camunda-rest.service';

@Injectable({
  providedIn: 'root'
})
export class CamundaActionsService {
  constructor(private camundaRestService: CamundaRestService) {}

  loadProcessDefinitions(processDefinitionId: string): Promise<any> {
    return this.camundaRestService.fetchProcessDefinitions(processDefinitionId);
  }

  loadProcessDefinitionsWithXML(processDefinitionId: string): Promise<any> {
    return this.camundaRestService.fetchProcessDefinitions(processDefinitionId);
  }

  loadAllProcessDefinitionsWithXML(): Promise<any> {
    return this.camundaRestService.fetchAllProcessDefinition();
  }

  loadProcessDefinitionXML(processDefinitionId: string): Promise<any> {
    return this.camundaRestService.fetchProcessDefinitionXML(processDefinitionId);
  }

  loadFormKey(processDefinitionKey: string): Promise<any> {
    return this.camundaRestService.fetchFormKey(processDefinitionKey);
  }

  startProcessInstance(processDefinitionKey: string, values: any): Promise<any> {
    return this.camundaRestService.postProcessInstance(processDefinitionKey, values);
  }

  deployProcess(filename: string, file: Blob): Promise<any> {
    return this.camundaRestService.postProcessXML(filename, file);
  }
}
