import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkHttpPipe'
})
export class LinkHttpPipe implements PipeTransform {

  transform(href:string): any {
    return href.startsWith("http://") || href.startsWith("https://") ? href : "http://" + href;
  }

}
