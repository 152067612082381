import { ReviewType } from 'src/app/model/enums/reviewType';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReviewInterface } from 'src/app/model/reviews/review-interface';

@Component({
  selector: 'app-public-review',
  templateUrl: './public-review.component.html',
  styleUrls: ['./public-review.component.css']
})
export class PublicReviewComponent implements OnInit {
  reviewTypeEnum = ReviewType;

  @Input()
  public review: ReviewInterface;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  /* Navigates to the ProductPage of the given ProductId */
  public navigateToProduct(productId: number) {
    this.router.navigate(["marktplatz", "products", productId]);
    window.scrollTo(0,0);
  }

  /* Navigates to the OrganisationPage of the given Organisationname */
  public navigateToOrganisation(orgaName: string) {
    this.router.navigate(["@" + orgaName]);
    window.scrollTo(0,0);
  }

}
