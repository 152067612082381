import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { UserService } from "../../../services/user-service/userservice";
import { Router } from "@angular/router";
import { OrganisationService } from "../../../services/dataServices/organisation-service/organisationservice";
import { OrganisationType } from "../../../model/enums/organisationType";
import { userRole } from "../../../model/enums/userRole";
import { HeaderServiceService } from "../../../services/header-service/header-service.service";

import { OrganisationInterface } from "../../../model/organisations/organisation-interface";
import { Observable, Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { NavbarService } from "src/app/services/componentServices/navbar-service/navbar-service";


@Component({
  selector: "app-nav-buttons",
  templateUrl: "./nav-buttons.component.html",
  styleUrls: ["./nav-buttons.component.css"],
})
export class NavButtonsComponent implements OnInit, OnDestroy {
  OrganisationType = OrganisationType;
  userRole = userRole;
  userMembershipRole;

  allOrganisations$: Observable<OrganisationInterface[]>;
  allOrganisations: OrganisationInterface[];
  subscriptions: Subscription[] = [];

  private currentMembershipSubscription: Subscription;

  constructor(
    public userService: UserService,
    public organisationService: OrganisationService,
    private router: Router,
    private headerService: HeaderServiceService,
    public navbarService: NavbarService,
    private cd: ChangeDetectorRef
  ) {
    this.allOrganisations$ = this.organisationService.itemCollectionObserver;
  }

  ngOnInit(): void {
    this.currentMembershipSubscription = this.userService.currentMembershipsObserver.subscribe((x) => {
        this.cd.detectChanges();
    });
    this.organisationService.activeItemObserver.subscribe((x) => {
      this.cd.detectChanges();
    });
    this.organisationService.itemCollectionObserver.subscribe((x) => {
      this.cd.detectChanges();
    });
    this.allOrganisations$.subscribe((res) => {
      this.allOrganisations = res;
    });
    this.subscriptions.push(
      this.navbarService.activeScreenObservable.subscribe((x) => {
        if (x === null) {
          if (!this.cd["destroyed"]) {
            this.cd.detectChanges();
          }
        }
      })
    );
  }

  setActive(organisationId, organisationName, userId: number): void {
    this.headerService.loadHeaderText(organisationName);
    let url = "/organisations/" + organisationId + "/information";

    if (this.router.url !== url) {
      this.router.navigate([url]);
    }
  }

  logout(): void {
    this.userService.logout();
  }

  action(link: string): void{
    if(link.startsWith("/") && this.router.url !== link){
      this.navigate(link);
    }else if(link.startsWith("http")){
      window.location.href=link;
    }
  }

  navigate(link: string) {
    this.router.navigateByUrl(link);
  }
  labelBelow(index): boolean {
    return index % 2 !== 0;
  }

  getNavButtonImage(organisation: OrganisationInterface): string {
    let defaultImage: string;
    switch(organisation.organisationType) {
      case OrganisationType.PRODUCT_PROVIDER:
        defaultImage = environment.defaultPictureOrganisationProducts;
        break;
      case OrganisationType.CERTIFICATION_PROVIDER:
        defaultImage = environment.defaultPicuterOrganisationCertifications;
        break;
      case OrganisationType.CERTIFICATION_OFFICE:
        defaultImage = environment.defaultPicuterOrganisationCertifications;
        break;
      case OrganisationType.PRODUCT_TYPE_ASSOCIATION:
        defaultImage = environment.defaultPictureOrganisationPTGs;
        break;
    }
    return defaultImage;
  }

  ngOnDestroy(): void {
    this.currentMembershipSubscription.unsubscribe();
    this.subscriptions.forEach((element) => {
      element.unsubscribe();
    });
  }
}
