<ng-container>
  <form>
    <div class="button-container" style="margin: 0.5em;">
      <div [ngClass]="allowUndefined ? 'button-wrap' : 'button-wrap-2-buttons'">
        <div>
          <input type="radio" [name]="name" id="yes-button+{{name}}" class="hidden radio-label" (click)="changeValue(true)" [disabled]="disableToggle" [checked]="value == true || value == 'true'">
          <label for="yes-button+{{name}}" class="button-label">
            <h1>{{trueLabel}}</h1>
          </label>
        </div>
        <div *ngIf="allowUndefined">
          <input type="radio" [name]="name" id="maybe-button+{{name}}" class="hidden radio-label"(click)="changeValue(null)" [disabled]="disableToggle" [checked]="value == null || value == 'undefined'">
          <label for="maybe-button+{{name}}" class="button-label">
            <h1>-</h1>
          </label>
        </div>
        <div>
          <input type="radio" [name]="name" id="no-button+{{name}}" class="hidden radio-label" (click)="changeValue(false)" [disabled]="disableToggle" [checked]="this.value == false || this.value == 'false'">
          <label for="no-button+{{name}}" class="button-label">
            <h1> {{falseLabel}} </h1>
          </label>
        </div>
      </div>
    </div>
  </form>
  </ng-container>
