<div class="grid" *ngIf="!version; else oneVersion">
  <div class="ptg" *ngIf="ptg">
    <div class="two-colmn-grid">
      <div class="column1">
        <h2 *ngIf="ptg; else loading" class="no-overflow">{{ ptg.name }}</h2>
        <ng-template #loading>
          <p>Loading</p>
        </ng-template>
      </div>
      <div class="column2 headline right">
        <rounded-button
          *ngIf="
           ptg.organisationId === organisationService.activeItem.organisationId && isUserAdminOfOrganisationOrPlattformAdmin()
          "
          [label]="'Produkttyp bearbeiten'"
          (click)="editPtg(ptg.id)"
        ></rounded-button>
        <rounded-button
        *ngIf="
         ptg.organisationId === organisationService.activeItem.organisationId && isUserAdminOfOrganisationOrPlattformAdmin()
        "
        [label]="'Produkttyp löschen'"
      ></rounded-button>
      </div>
    </div>
    <p
      class="description"
      *ngIf="ptg; else loading"
      [innerHTML]="ptg.description"
    ></p>
    <div class="version-count">
      <div>
        <b><p>Versionen</p></b>
        <b
          ><h2 *ngIf="ptg; else loading">{{ ptg.versions.length }}</h2></b
        >
      </div>
      <div>
        <b><p>Davon aktiv</p></b>
        <b
          ><h2 *ngIf="activeVersions; else loading">
            {{ activeVersions.length }}
          </h2></b
        >
      </div>
    </div>
  </div>
  <div class="version-divider">
    <h2>Versionen</h2>
    <div class="dividerline"></div>
  </div>
  <div>
    <div *ngFor="let ptgVersion of versions" class="versionsBackground">
      <h2>Version {{ ptgVersion.number }}</h2>
      <div class="column2 headline right upper">
        <rounded-button
          *ngIf="
            ptg.organisationId === organisationService.activeItem.organisationId && isUserAdminOfOrganisationOrPlattformAdmin()
          "
          [label]="'Version bearbeiten'"
          (click)="editVersion(ptg.id, ptgVersion.id)"
        ></rounded-button>
        <rounded-button
        *ngIf="
          ptg.organisationId === organisationService.activeItem.organisationId && isUserAdminOfOrganisationOrPlattformAdmin()
        "
        [label]="'Version löschen'"
      ></rounded-button>
      </div>
      <div class="version-info">
        <div>
          <p><b>Beschreibung</b></p>
          <p class="description" [innerHTML]="ptgVersion.description"></p>
        </div>
        <div class="stats">
          <p>
            <b>Status: {{ ptgVersion.status }}</b>
          </p>
          <p>
            <b>Merkmale: {{ ptgVersion.attributeValidations.length }}</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #oneVersion>
  <div class="versionView changeColor" *ngIf="version">
    <div class="versionsBackground versionOverview">
      <div class="headline">
        <h2>Version {{ version.number }}</h2>
        <rounded-button
          *ngIf="
            ptg.organisationId === organisationService.activeItem.organisationId && isUserAdminOfOrganisationOrPlattformAdmin()
          "
          [label]="'Version bearbeiten'"
          (click)="editVersion(ptg.id, version.id)"
        ></rounded-button>
      </div>
      <div class="version-info">
        <div>
          <b><p>Beschreibung</p></b>
          <p class="description" [innerHTML]="version.description"></p>
        </div>
        <div class="stats">
          <b
            ><p>Status: {{ version.status }}</p></b
          >
          <b
            ><p>
              Merkmale:
              {{ version.attributeValidations.length }}
            </p></b
          >
        </div>
      </div>
      <attribute-select-table
        [(ptgAttributeValidations)]="version.attributeValidations"
        [editable]="false"
      ></attribute-select-table>
    </div>
  </div>
</ng-template>
