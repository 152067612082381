<div (click)="switchToggle()" class="attributeCatalogueBtn"></div>
<div [hidden]="toggle">
  <div class="peak"></div>

  <div class="container prevent-selection">
    <div class="grid">
      <div
        [ngClass]="{
          attributeCatalogueNoMargin: noMargin,
          attributeCatalogue: !noMargin
        }"
      >
        <div class="search-panel">
          <div class="button-search"></div>
          <mat-form-field [formGroup]="attributeForm" floatLabel="never">
            <input
              matInput
              formControlName="attributeSearch"
              placeholder="Suchen..."
            />
          </mat-form-field>
        </div>

        <div class="table-container">
          <div class="table">
            <div class="options">
              <div
                *ngFor="let item of displayOptions"
                [ngClass]="displayBy == item.name ? 'active' : 'passiv'"
                (click)="displayByNameOrPtg(item.name)"
                class="sort-option"
              >
                <p>{{ item.title }}</p>
              </div>
            </div>
            <div
              matSort
              (matSortChange)="sortData($event)"
              class="header"
              class="header"
            >
              <div></div>
              <div mat-sort-header="ALPHABETICAL">
                <p>Name</p>
              </div>
              <div *ngIf="displayBy == 'name'" mat-sort-header="DATATYPE">
                <p>Datentyp</p>
              </div>
              <div *ngIf="displayBy == 'ptg'">
                <p>Version</p>
              </div>
            </div>

            <div class="content" *ngIf="displayBy == 'name'; else ptgContent">
              <app-attribute-table
                [attributes]="attributeGroups"
                [showAll]="false"
                [selectedAttributes]="selectedAttributes"
                [attributeNameEditable]="false"
                [(checkedValidations)]="checkedValidations"
              ></app-attribute-table>
            </div>
            <ng-template #ptgContent>
              <div class="content">
                <div
                  *ngFor="
                    let ptgVersionGroup of ptgVersionGroups;
                    let i = index
                  "
                >
                  <div
                    class="ptgRows"
                    [ngClass]="i % 2 ? 'ptgEven' : 'ptgOdd'"
                    (click)="showPtgVersionValues(ptgVersionGroup)"
                  >
                    <div>
                      <div
                        *ngIf="ptgVersionGroup.ptgVersionDescription"
                        matTooltip="{{ ptgVersionGroup.ptgVersionDescription }}"
                        [matTooltipPosition]="'below'"
                        matTooltipClass="tooltip"
                      >
                        <img
                          [src]="'/assets/img/icons/info.png'"
                          alt="Beschreibungs-Icon"
                        />
                      </div>
                    </div>
                    <div>
                      <p>
                        <b>{{ ptgVersionGroup.ptgName }}</b>
                      </p>
                    </div>
                    <div>
                      <p>
                        <b>Version {{ ptgVersionGroup.ptgVersionNumber }}</b>
                      </p>
                    </div>
                    <div>
                      <div
                        class="triangle"
                        [ngClass]="{ turn: ptgVersionGroup.hidden }"
                      ></div>
                    </div>
                  </div>
                  <div [ngClass]="{ hide: ptgVersionGroup.hidden }">
                    <app-attribute-table
                      [attributes]="ptgVersionGroup.attributes"
                      [showAll]="true"
                      [selectedAttributes]="selectedAttributes"
                      [attributeNameEditable]="false"
                      [(checkedValidations)]="checkedValidations"
                    ></app-attribute-table>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <mat-paginator
          [length]="totalNumberOfElements | async"
          [pageSize]="5"
          [pageSizeOptions]="[5]"
        ></mat-paginator>
        <div class="buttons">
          <button mat-raised-button class="btn_green" (click)="confirm()">
            Übernehmen
          </button>
          <button mat-raised-button class="btn_red" (click)="cancel()">
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
