import { DtoPageRequest } from "./dto-page-request";
import { SortMethod } from "../enums/SortMethod";
import { MarketplaceFilterInterface } from "./marketplace-filter-interface";
import { OrganisationType } from "../enums/organisationType";
import { ProductCategory } from "../enums/productCategory";
import { CertificationTypes } from "../enums/certificationTypes";

export class DtoPageFactory {
  static bluePrint: DtoPageRequest = {
    pageIndex: 0,
    pageSize: 30,
    organisationIds: [],
    ratingScore: [],
    attributeIds: [],
    commonFeatureEntryIds: [],
    productIds: [],
    certificationIds: [],
    statusIds: [1],
    searchTerm: "",
    sortMethod: SortMethod.ALPHABETICAL_ASC,
    ptgIds: [],
    ptgAttrIds: []
  };

  static nextPage(): DtoPageRequest {
    this.bluePrint.pageIndex++;
    return this.createDtoPage();
  }

  static loadFilter(filter: MarketplaceFilterInterface) {
    this.bluePrint.attributeIds = filter.attributeIds;
    this.bluePrint.organisationIds = filter.organisationIds;
    this.bluePrint.ptgIds = filter.ptgIds;
    this.bluePrint.organisationType = filter.orgaType;
    this.bluePrint.certificationTypes = filter.certificationTypes;
    this.bluePrint.certificationIds = filter.certificationIds;
    this.bluePrint.productIds =  filter.productIds;
    this.bluePrint.categories = filter.productCategories;
    this.bluePrint.commonFeatureEntryIds = filter.commonFeatureIds;
    this.bluePrint.ratingScore = filter.ratingScore;
    this.bluePrint.ptgAttrIds = filter.ptgAttrIds;
  }

  static createDtoPage(): DtoPageRequest {
    return JSON.parse(JSON.stringify(this.bluePrint));
  }
}
