import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {observable, Observable} from 'rxjs';
import {
  addNewFileId,
  productImageDataUrls,
} from 'src/app/components/composer/model-editor/util/preloadedAssets';
import { ComposerProductFileInterface } from 'src/app/model/composer/composer-product-file-interface';
import { ComposerToolchainInstanceOverview } from 'src/app/model/composer/composer-toolchain-instance-overview';
import { ComposerToolchainTemplateOverview } from 'src/app/model/composer/composer-toolchain-overview-interface';
import { ToolchainDetailInterface } from 'src/app/model/marketplace/toolchain-detail-interface';
import { ProductOverview } from 'src/app/model/product-overview/product-overview';
import { API_MAP } from 'src/environments/api';

const SWARM_API = '/proxy/api/v0/toolchainservice';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  responseType: 'text' as 'json',
};

@Injectable({
  providedIn: 'root',
})
export class ComposerService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) {}

  /* Sends the ToolchainXML to the backend and returns the updated XML  */
  public saveToolchain(xml: string): Observable<string> {
    return new Observable<string>((observer) => {
      this.httpClient
        .put<string>(
          SWARM_API + API_MAP['composer']['CREATE'],
          xml,
          httpOptions
        )
        .subscribe((response) => {
          observer.next(response);
        });
    });
  }

  /* Load the ToolchainXML with needed Informations for a given ToolchainId */
  public loadXMLForToolchain(toolchainId: number) {
    return new Observable<any>((observer) => {
      this.httpClient
        .get(
          SWARM_API +
            API_MAP['composer']['GET'].replace(
              ':toolchainId',
              toolchainId.toString()
            )
        )
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            this.router.navigateByUrl('/composer');
          }
        );
    });
  }

  /* Returns all Toolchains that were created by the given User */
  public getToolchainsForUser(userId: number) {
    return new Observable<ComposerToolchainTemplateOverview[]>((observer) => {
      this.httpClient
        .get<ComposerToolchainTemplateOverview[]>(
          SWARM_API + API_MAP['composer']['USER']
        )
        .subscribe((response) => {
          observer.next(response);
        });
    });
  }

  /* Returns all ToolchainInstances that were created by the given User */
  public getToolchainInstancesForUser(userId: number) {
    return new Observable<ComposerToolchainInstanceOverview[]>((observer) => {
      this.httpClient
        .get<ComposerToolchainInstanceOverview[]>(
          SWARM_API + API_MAP['composer']['USER_INSTANCES']
        )
        .subscribe((response) => {
          observer.next(response);
        });
    });
  }

  /* Returns all ToolchainTemplates that are assigned to an given Organisation */
  public getToolchainTemplatesForOrganisation(organisationId: number) {
    const url =
      SWARM_API +
      API_MAP['composer']['ORGANISATION'].replace(
        ':organisationId',
        organisationId.toString()
      );

    return new Observable<ComposerToolchainTemplateOverview[]>((observer) => {
      this.httpClient
        .get<ComposerToolchainTemplateOverview[]>(url)
        .subscribe((response) => {
          observer.next(response);
        });
    });
  }

  /* Returns all ToolchainInstances that are assigned to an given Organisation */
  public getToolchainInstancesForOrganisation(organisationId: number) {
    const url =
      SWARM_API +
      API_MAP['composer']['ORGANISATION_INSTANCES'].replace(
        ':organisationId',
        organisationId.toString()
      );

    return new Observable<ComposerToolchainInstanceOverview[]>((observer) => {
      this.httpClient
        .get<ComposerToolchainInstanceOverview[]>(url)
        .subscribe((response) => {
          observer.next(response);
        });
    });
  }

  /* Updates the XML by replacing the DataURLs with the newly loaded once */
  public updateXML(
    xml: string,
    xmlFileIds: ComposerProductFileInterface[],
    template: boolean
  ): string {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'text/xml');
    let toolchainElements;

    if (template) {
      toolchainElements = xmlDoc.getElementsByTagName('composer:ToolchainItem');
    } else {
      toolchainElements = xmlDoc.getElementsByTagName(
        'composer:ToolchainInstanceItem'
      );
    }
    this.updateToolchainElements(toolchainElements, xmlFileIds);
    this.updateProcess(xmlDoc.getElementsByTagName('bpmn:process')[0]);

    const serializer: XMLSerializer = new XMLSerializer();
    const newXML = serializer.serializeToString(xmlDoc);

    return newXML;
  }

  /* Updates the DataUrl within the ToolchainElements */
  private updateToolchainElements(
    toolchainElements: HTMLCollectionOf<Element>,
    xmlFileIds: ComposerProductFileInterface[]
  ) {
    for (let i = 0; i < toolchainElements.length; i++) {
      const productId = toolchainElements.item(i).getAttribute('productId');

      if (productId !== '') {
        const fileId = xmlFileIds.find(
          (product) => product.productId === parseInt(productId)
        ).fileId;

        const dataUrl = productImageDataUrls.find(
          (element) => element.fileId === fileId
        ).dataUrl;

        toolchainElements.item(i).setAttribute('imageDataUrl', dataUrl);
      }
    }
  }

  /* Updates the DataUrl within the Process */
  private updateProcess(process: Element) {
    if (process.hasAttribute('logo')) {
      const fileId = process.getAttribute('logo');
      const dataUrl = '';

      if (fileId !== '') {
        const dataUrl = productImageDataUrls.find(
          (element) => element.fileId === fileId
        ).dataUrl;


        process.setAttribute('logoDataUrl', dataUrl);
      }
    }
  }

  /*
   * Loads the DataUrls for all Products given
   * Returns the updated XML
   */
  public loadDataUrlsForProductsInXml(
    productFileIds: ComposerProductFileInterface[],
    xml: string,
    template: boolean
  ): Observable<string> {
    let loadedProducts = 0;

    const observable: Observable<string> = new Observable((observer) => {
      productFileIds.forEach((productFileId) => {
        const alreadyLoaded = productImageDataUrls.find(
          (loaded) => loaded.fileId === productFileId.fileId
        );
        if (!alreadyLoaded) {
          this.loadDataSourceForNewFileId(productFileId.fileId).subscribe(
            (success) => {
              const isLast = loadedProducts === productFileIds.length - 1;
              if (success && isLast) {
                observer.next(this.updateXML(xml, productFileIds, template));
              }
              loadedProducts++;
            }
          );
        } else {
          const isLast = loadedProducts === productFileIds.length - 1;
          if (isLast) {
            observer.next(this.updateXML(xml, productFileIds, template));
          }
          loadedProducts++;
        }
      });

      if (productFileIds.length === 0) {
        observer.next(xml);
      }
    });

    return observable;
  }

  /*
   * Loads the DataUrls for all Products from the Palette
   * Returns true when done
   */
  public loadDataUrlsForPaletteProducts(
    products: ProductOverview[]
  ): Observable<boolean> {
    let loadedProducts = 0;

    const observable: Observable<boolean> = new Observable((observer) => {
      products.forEach((product) => {
        if (product.fileId === undefined) {
          product.fileId = 'noFileId';
        }

        const alreadyLoaded = productImageDataUrls.find(
          (loaded) => loaded.fileId === product.fileId
        );
        if (!alreadyLoaded) {
          this.loadDataSourceForNewFileId(product.fileId).subscribe(
            (success) => {
              const isLast = loadedProducts === products.length - 1;
              if (success && isLast) {
                observer.next(true);
              }
              loadedProducts++;
            }
          );
        } else {
          const isLast = loadedProducts === products.length - 1;
          if (isLast) {
            observer.next(true);
          }
          loadedProducts++;
        }
      });

      if (products.length === 0) {
        observer.next(true);
      }
    });

    return observable;
  }

  /* Loads the DataUrl for a given FileId and adds it to the DataUrl Array */
  public loadDataSourceForNewFileId(fileId: string): Observable<boolean> {
    return addNewFileId(fileId, this.httpClient, this.domSanitizer);
  }

  public deleteToolchainTemplate(templateId: number): Observable<any> {
    const url =
      SWARM_API +
      API_MAP['composer']['DELETE_TEMPLATE'].replace(
      ':templateId',
        templateId.toString()
    );
    return new Observable((observer) =>
      this.httpClient.delete(url, httpOptions).subscribe((success) => {
        if (success) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      })
    );
  }

  public deleteToolchain(toolchainId: number): Observable<any> {
    const url =
      SWARM_API +
      API_MAP['composer']['DELETE_TOOLCHAIN'].replace(
        ':toolchainId',
        toolchainId.toString()
      );
    return new Observable((observer) =>
      this.httpClient.delete(url, httpOptions).subscribe((success) => {
        if (success) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      })
    );
  }

  /* Create a Toolchain Instance from a Template */
  public createToolchainInstance(toolchainId: number): Observable<boolean> {
    const url =
      SWARM_API +
      API_MAP['composer']['START'].replace(
        ':templateId',
        toolchainId.toString()
      );

    return new Observable((observer) =>
      this.httpClient.post(url, httpOptions).subscribe((success) => {
        if (success) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      })
    );
  }

  public downloadFile(
    containerExternalId: string,
    contentExternalId: string
  ): void {

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'multipart/form-data');
    const access_token: string = localStorage.getItem('access_token');
    if (access_token) {
      myHeaders.append('Authorization', 'Bearer ' + access_token);
    }

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const url =  API_MAP['composer']['FILE']
      .replace(':containerId', containerExternalId.toString())
      .replace(':contentId', contentExternalId.toString());

    // @ts-ignore
    fetch(url, requestOptions)
      .then(response => response.blob())
      .then(result => {
        if (result.size !== 1651) {
          if (result.type === 'text/plain') {
            this.downloadBlob(result, 'download_composer.ifc');
          } else {
            this.downloadBlob(result, 'download_composer');
          }
        }
      })
      .catch(error => console.log('error', error));

  }

  // https://dev.to/nombrekeff/download-file-from-blob-21ho
  private downloadBlob(blob, name) {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }

  public uploadFile(
    containerExternalId: string,
    contentExternalId: string,
    file: File
  ): Observable<boolean> {
    const customHeader = {
      headers: new HttpHeaders()
    };

    const url =  API_MAP['composer']['FILE']
      .replace(':containerId', containerExternalId.toString())
      .replace(':contentId', contentExternalId.toString());

    const formData = new FormData();
    formData.append('file', file);

    return new Observable((observer) =>
      this.httpClient.post<string>(url, formData, customHeader)
        .subscribe((success) => {
          if (success) {
            observer.next(true);
          }
        })
    );
  }

  /* Returns the ToolchainDetailInterface of a Toolchain */
  public getToolchainDetail(
    toolchainId: number
  ): Observable<ToolchainDetailInterface> {
    const url =
      SWARM_API +
      API_MAP['composer']['DETAIL'].replace(
        ':toolchainId',
        toolchainId.toString()
      );

    return new Observable((observer) =>
      this.httpClient
        .get<ToolchainDetailInterface>(url)
        .subscribe((toolchainDetail) => {
          if (toolchainDetail) {
            observer.next(toolchainDetail);
          } else {
            observer.next(null);
          }
        })
    );
  }
}
