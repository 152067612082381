<div class="filter-panel">
  <rounded-button *ngIf="composerItem && isPtgFiltered" (press)="resetFilter(selectField)" [label]="'zurücksetzen'" [bold]="true"></rounded-button>
  <mat-form-field *ngFor="let option of filterOptions">
    <mat-select *ngIf="option.datatype === 'BOOLEAN' || option.datatype === 'LIST'" placeholder={{option.name}} [multiple]="option.datatype == 'LIST'" [(value)]="filterOptionValues[option.attributeId]" (selectionChange)="filterOptionsChanged($event, true, selectField.value, option.attributeId)" #selectField>
      <div *ngIf="option.datatype === 'BOOLEAN'">
        <mat-option [value]="true">
          True
        </mat-option>
        <mat-option [value]="false">
          False
        </mat-option>
      </div>
      <div *ngIf="option.datatype === 'LIST'">
        <mat-option *ngFor="let validationValue of option.validations"
                    [value]="validationValue">
                    {{validationValue}}
        </mat-option>
      </div>
    </mat-select>
    <input matInput *ngIf="option.datatype === 'STRING'" placeholder={{option.name}} [(ngModel)]="filterOptionValues[option.attributeId]" (keypress)="filterOptionsChanged($event, false, stringField.value, option.attributeId)" #stringField>
    <input matInput *ngIf="option.datatype === 'NUMERIC'" type="number" placeholder={{option.name}} [(ngModel)]="filterOptionValues[option.attributeId]" (keypress)="filterOptionsChanged($event, false, numericField.value, option.attributeId)" #numericField>
  </mat-form-field>
  <app-rating-panel (filter)="filterRating($event)"></app-rating-panel>
</div>
  
