import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "countCharacters",
})
export class CountCharactersPipe implements PipeTransform {
  transform(value: string): number {
    return value.length;
  }
}
