<div
  class="item"
  style="display: grid; gap: 1em; grid-template-columns: repeat(3, min-content)"
>
  <button mat-raised-button (click)="requestDemo()" class="btn_green">
    Demo anfragen
  </button>
  <button mat-raised-button (click)="requestOffer()" class="btn_green">
    Angebot anfragen
  </button>
  <button [disabled]="this.website == null || this.website == undefined || this.website == ''" mat-raised-button (click)="routeToWebsite()" class="btn_green">
    Produkt-Webseite
  </button>
  <button mat-raised-button (click)="addToProductList()" class="btn_green" *ngIf="isLoggedIn()">
    Produkt zu meiner Produktliste hinzufügen
  </button>
  <button mat-raised-button (click)="routeToMalfunctionOverview()" class="btn_green" *ngIf="isLoggedIn()">
    Störungsübersicht anzeigen
  </button>
</div>