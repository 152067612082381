import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { ReviewService } from "src/app/services/review-service/review.service";
import { ReviewInterface } from "src/app/model/reviews/review-interface";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { UserService } from "src/app/services/user-service/userservice";
import { Router } from '@angular/router';
import {OrganisationService} from "../../../services/dataServices/organisation-service/organisationservice";
@Component({
  selector: "app-reviews",
  templateUrl: "./reviews.component.html",
  styleUrls: ["./reviews.component.css"],
})
export class ReviewsComponent implements OnInit {
  private readonly showInitialLinesAmount: number = 4;
  @Input() reviewType: number;
  @Input() targetId: number;
  @Input() reviews: ReviewInterface[];
  @Input() showWriteReview: boolean;

  @Output() reviewChangeEmitter = new EventEmitter();
  @Output() upvoteEmitter = new EventEmitter<ReviewInterface>();
  @Output() private reviewsChanged = new EventEmitter<ReviewInterface[]>();
  @Output() reviewCloseEmitter = new EventEmitter();
  @Output() reviewOpenEmitter = new EventEmitter();

  editState: boolean;

  constructor(
    private reviewService: ReviewService,
    private dialogService: DialogService,
    private changeDetector: ChangeDetectorRef,
    public userService: UserService,
    private router: Router
  ) {}
  ngOnInit(): void {}

  ngOnChanges(): void {
    this.setHasLongDescriptionForReviews();
  }

  deleteReview(review: ReviewInterface): void {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie die Bewertung wirklich löschen?",
        "Nein",
        "Ja"
      )
      .subscribe((result) => {
        if (result) {
          this.reviewService.deleteReview(review).subscribe(deleted => {
            this.dialogService.openDialog("Bewertung erfolgreich gelöscht", "");
            this.reviews = this.reviews.filter((rev) => review !== rev);
            this.reviewsChanged.emit(this.reviews);
            this.changeDetector.detectChanges();
          });

        }
      });
  }

  reportReview(review: ReviewInterface): void {
    this.dialogService.openReportReviewDialog("Abbrechen", "Bewertung melden")
      .subscribe((reportReason) => {
        if(reportReason) {
          this.reviewService.createReport(reportReason, review.reviewId);
          this.dialogService.openDialog("Bewertung wurde gemeldet", "");
        }
      });
  }

  async getAllReviews() {
    this.reviews = await this.reviewService.getReviews(
      this.reviewType,
      this.targetId
    );
  }

  addReview(newReview: ReviewInterface): void {
    this.reviews.push(newReview);
    this.reviewsChanged.emit(this.reviews);
    this.changeDetector.detectChanges();
  }

  editReview(editedReview: ReviewInterface, oldReview: ReviewInterface): void {
    this.reviews[this.reviews.indexOf(oldReview)] = editedReview;
    this.setHasLongDescriptionForReviews();
    this.reviewsChanged.emit(this.reviews);
    this.changeDetector.detectChanges();
  }

  checkOverflow(elementId): boolean {
    //
    let element = document.getElementById(String(elementId));
    //
    return element.offsetHeight < element.scrollHeight;
  }

  upvoteClick(review: ReviewInterface) {
    this.reviewService
      .upvoteReview(review.reviewId, this.userService.currentUser.userId)
      .subscribe((newReview: ReviewInterface) => {
        this.editReview(newReview, review);
      });
  }

  hasUserUpvoted(review: ReviewInterface) {
    return review.upvotes.some(
      (upvote: number) => this.userService.currentUser && (upvote === this.userService.currentUser.userId)
    );
  }

  /**
   * Determines whether the user has already submitted a review for the product.
   *
   * @returns boolean
   */
  hasUserReviewedProduct(): boolean {

    return this.reviews.some(
      (review: ReviewInterface) => review.creatorId == this.userService.currentUser.userId
    );
  }

  /**
   * Setzt den Boolean-Wert für alle reviews, ob der "Mehr/Weniger anzeigen"-Button erscheinen soll
   */
  private setHasLongDescriptionForReviews(): void {
    this.reviews.forEach((review: ReviewInterface) => {
      if(this.containsTooManyParagraphs(review.reviewText)) {
        review.hasLongDescription = true;
        review.showComplete = false;
        review.shortReviewText = this.generateShortReviewText(review.reviewText);
      } else {
        review.hasLongDescription = false;
        review.showComplete = true;
      }
    });
  }

  /**
   * Checks whether a description string contains at least 4 (showInitialLinesAmount) <p> tags, or if it is too long.
   * This matters since each <p> inside the enclosing tag causes a new line, thus 4 <p> tags result in 4 lines of text,
   * which is the amount initially displayed
   */
  private containsTooManyParagraphs(description: string): boolean {
    let tooManyParagraphsOrTooLong: boolean = false;
    const pCount: number = (description.match(/\n/g) || []).length;
    if(pCount >= this.showInitialLinesAmount) {
      return tooManyParagraphsOrTooLong = true;
    }
    if(description.length > 200) {
      return tooManyParagraphsOrTooLong = true;
    }
    return tooManyParagraphsOrTooLong;
  }

  /**
   * Cap too long reviewTexts/ reviewTexts with too many paragraphs to a shortened text and add "..." to the end
   * This shortened text is then displayed by default, the full reviewText is visible when the user clicks "Mehr anzeigen"
   * @param reviewText the original (full length) review text that is too long/has to many paragraphs to be fully displayed
   * @returns shortReviewText to be displayed in product detail page
   */
  private generateShortReviewText(reviewText: string): string {
    let shortReviewText: string = reviewText.substr(0, 199);
    let splitted: string[] = shortReviewText.split(/\n/g);
    if(splitted.length >= this.showInitialLinesAmount) {
      shortReviewText = "";
      for(let i = 0; i < this.showInitialLinesAmount; i++) {
        shortReviewText += splitted[i] + "\n";
      }
    }
    shortReviewText += "...";
    return shortReviewText;
  }

  /* Navigates to the Public User Profile of the User with the given Username */
  // public navigateToUserPrivateProfile(username: string) {
  //   this.router.navigate(["profil", username, "public"]);
  //   window.scrollTo(0,0);
  // }

  /* Navigates to the User Profile of the User with the given Username for logged in Users */
  public navigateToUserProfile(username: number) {
    if(this.userService.loggedIn){
      this.router.navigate(["profil", username]);
    } else {
      this.router.navigate(["profil", username, "public"]);
    }
    window.scrollTo(0,0);
  }

  public showReview(): void {
    this.showWriteReview = true;
    this.reviewOpenEmitter.emit();
    this.changeDetector.detectChanges();
  }

  public cancelReview(review?: ReviewInterface): void {
    if (review) {
      review.onEdit = false;
    }
    this.reviewCloseEmitter.emit();
  }
}
