<div class="two-columns">
  <div>
    <h1>Als SSO Klient Registrieren</h1>
    <form #form = "ngForm">
      <div class="grid">
        <mat-form-field>
          <input matInput placeholder="KlientId" name="clientId" [(ngModel)]="ssoClient.clientId" required [disabled]="!editable">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="KlientName" name="clientName" [(ngModel)]="ssoClient.clientName" required [disabled]="!editable">
        </mat-form-field>
        <mat-form-field *ngIf="editable">
          <input matInput placeholder="KlientPasswort" name="clientSecret" [(ngModel)]="ssoClient.clientSecret" type="password" required>
        </mat-form-field>
        <mat-form-field>
          <mat-select multiple [ngModel]="selectedGrantOptions" name="authGrantTypes" (ngModelChange)="onGrantSelection($event)" 
          placeholder="Authorization Grants" [disabled]="!editable">
            <mat-option *ngFor="let option of authorizationGrantOptions" [value]="option">{{option}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="WebServerRedirectUri" name="webServerRedirectUri" [(ngModel)]="ssoClient.webServerRedirectUri" required [disabled]="!editable">
        </mat-form-field>
        <br/>
      </div>
        <div class="grid" *ngIf="editable">
          <button mat-raised-button class="btn_green" [disabled]="form.invalid"
          (click)="onSave()">
          Speichern
        </button>
        <button mat-raised-button class="btn_red" (click)="onCancel()">Abbrechen
        </button>
      </div>
    </form>
</div>
</div>
