<div>
  <ng-container
    *ngFor="let attribute of attributes; let i = index"
    [ngSwitch]="attribute.datatype"
  >
    <div
      class="attributeRow"
      (click)="showAttributeValidation(attribute.id)"
      [ngClass]="{
        odd: i % 2 != 0,
        even: i % 2 == 0,
        clickable: !showAll,
        ptgAttribute: showAll
      }"
    >
      <div>
        <div
          *ngIf="attribute.description"
          matTooltip="{{ attribute.description }}"
          [matTooltipPosition]="'below'"
          matTooltipClass="tooltip"
        >
          <img [src]="'/assets/img/icons/info.png'" alt="Beschreibungs-Icon" />
        </div>
      </div>
      <div>
        <p>
          <b>{{ attribute.name }}</b>
        </p>
      </div>
      <div>
        <p>
          <b>{{ attribute.datatype | datatypeToString }}</b>
        </p>
      </div>
      <div class="optional" *ngIf="showOptional">
        <validation-icon [value]="attribute.optional"></validation-icon>
      </div>
      <div>
        <div
          *ngIf="!showAll"
          class="triangle"
          [ngClass]="{ turn: attribute.hidden }"
        ></div>
      </div>
    </div>
    <ng-container *ngSwitchCase="'BOOLEAN'">
      <div
        class="validationRow"
        [ngClass]="{ hidden: attribute.hidden }"
        *ngFor="let validation of attribute.validations"
      >
        <div *ngIf="attributeNameEditable" class="triplecolumn">
          <p>
            <mat-form-field appearance="legacy" class="formfield">
              <mat-label>{{ attribute.name }}</mat-label>
              <input
                matInput
                placeholder="Attributename"
                [(ngModel)]="this.newAttributeName"
              />
              <mat-hint>Merkmalsname</mat-hint>
            </mat-form-field>
          </p>
          <button
            mat-button
            (click)="this.saveChanges(attribute.id)"
            class="btn_green attrButton"
          >
            Speichern
          </button>
          <button
            mat-button
            (click)="this.abortEdit(attribute.id)"
            class="btn_red attrButton"
          >
            Abbrechen
          </button>
        </div>
        <div class="description">
          <div
            *ngIf="validation.description"
            matTooltip="{{ validation.description }}"
            [matTooltipPosition]="'below'"
            matTooltipClass="tooltip"
          >
            <img
              [src]="'/assets/img/icons/info.png'"
              alt="Beschreibungs-Icon"
            />
          </div>
        </div>
        <div class="attributeDetails">
          <div>
            <p>Validierungswert: {{ validation.value | booleanToString }}</p>
          </div>
          <div *ngIf="showCheckbox">
            <mat-checkbox
              class="checkbox"
              (click)="toggleValidation(attribute, validation)"
              [(ngModel)]="checkedValidations[validation.id]"
            >
            </mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'STRING'">
      <div
        class="validationRow"
        [ngClass]="{ hidden: attribute.hidden }"
        *ngFor="let validation of attribute.validations"
      >
        <div *ngIf="attributeNameEditable" class="triplecolumn">
          <p>
            <mat-form-field appearance="legacy" class="formfield">
              <mat-label>{{ attribute.name }}</mat-label>
              <input
                matInput
                placeholder="Attributename"
                [(ngModel)]="this.newAttributeName"
              />
              <mat-hint>Merkmalsname</mat-hint>
            </mat-form-field>
          </p>
          <button
            mat-button
            (click)="this.saveChanges(attribute.id)"
            class="btn_green attrButton"
          >
            Speichern
          </button>
          <button
            mat-button
            (click)="this.abortEdit(attribute.id)"
            class="btn_red attrButton"
          >
            Abbrechen
          </button>
        </div>
        <div class="description">
          <div
            *ngIf="validation.description"
            matTooltip="{{ validation.description }}"
            [matTooltipPosition]="'below'"
            matTooltipClass="tooltip"
          >
            <img
              [src]="'/assets/img/icons/info.png'"
              alt="Beschreibungs-Icon"
            />
          </div>
        </div>
        <div class="attributeDetails">
          <div>
            <p>
              Min. Länge:
              {{ validation.minSize != null ? validation.minSize : "0" }}
            </p>
            <p>
              Max. Länge:
              {{
                validation.maxSize != null ? validation.maxSize : "+ Unendlich"
              }}
            </p>
            <p>Format: {{ validation.pattern ? validation.pattern : "-" }}</p>
          </div>
          <div *ngIf="showCheckbox">
            <mat-checkbox
              class="checkbox"
              (click)="toggleValidation(attribute, validation)"
              [(ngModel)]="checkedValidations[validation.id]"
            >
            </mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'NUMERIC'">
      <div
        class="validationRow"
        [ngClass]="{ hidden: attribute.hidden }"
        *ngFor="let validation of attribute.validations"
      >
        <div *ngIf="attributeNameEditable" class="triplecolumn">
          <p>
            <mat-form-field appearance="legacy" class="formfield">
              <mat-label>{{ attribute.name }}</mat-label>
              <input
                matInput
                placeholder="Attributename"
                [(ngModel)]="this.newAttributeName"
              />
              <mat-hint>Merkmalsname</mat-hint>
            </mat-form-field>
          </p>
          <button
            mat-button
            (click)="this.saveChanges(attribute.id)"
            class="btn_green attrButton"
          >
            Speichern
          </button>
          <button
            mat-button
            (click)="this.abortEdit(attribute.id)"
            class="btn_red attrButton"
          >
            Abbrechen
          </button>
        </div>
        <div class="description">
          <div
            *ngIf="validation.description"
            matTooltip="{{ validation.description }}"
            [matTooltipPosition]="'below'"
            matTooltipClass="tooltip"
          >
            <img
              [src]="'/assets/img/icons/info.png'"
              alt="Beschreibungs-Icon"
            />
          </div>
        </div>
        <div class="attributeDetails">
          <div>
            <p>
              Min. Wert:
              {{
                validation.minSize != null ? validation.minSize : "- Unendlich"
              }}
            </p>
            <p>
              Max. Wert:
              {{
                validation.maxSize != null ? validation.maxSize : "+ Unendlich"
              }}
            </p>
          </div>
          <div *ngIf="showCheckbox">
            <mat-checkbox
              class="checkbox"
              (click)="toggleValidation(attribute, validation)"
              [(ngModel)]="checkedValidations[validation.id]"
            >
            </mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST'">
      <div
        class="validationRow"
        [ngClass]="{ hidden: attribute.hidden }"
        *ngFor="let validation of attribute.validations"
      >
        <div *ngIf="attributeNameEditable" class="triplecolumn">
          <p>
            <mat-form-field appearance="legacy" class="formfield">
              <mat-label>{{ attribute.name }}</mat-label>
              <input
                matInput
                placeholder="Attributename"
                [(ngModel)]="this.newAttributeName"
              />
              <mat-hint>Merkmalsname</mat-hint>
            </mat-form-field>
          </p>
          <button
            mat-button
            (click)="this.saveChanges(attribute.id)"
            class="btn_green attrButton"
          >
            Speichern
          </button>
          <button
            mat-button
            (click)="this.abortEdit(attribute.id)"
            class="btn_red attrButton"
          >
            Abbrechen
          </button>
        </div>
        <div class="description">
          <div
            *ngIf="validation.description"
            matTooltip="{{ validation.description }}"
            [matTooltipPosition]="'below'"
            matTooltipClass="tooltip"
          >
            <img
              [src]="'/assets/img/icons/info.png'"
              alt="Beschreibungs-Icon"
            />
          </div>
        </div>
        <div class="attributeDetails">
          <div>
            <p>
              Min. Auswahl:
              {{ validation.minSize != null ? validation.minSize : "0" }}
            </p>
            <p>
              Max. Auswahl:
              {{
                validation.maxSize != null
                  ? validation.maxSize
                  : validation.values.length
              }}
            </p>
            <p>
              Listenwerte:
              <span
                *ngFor="let listValue of validation.values; let isLast = last"
                >{{ listValue }}{{ isLast ? "" : ", " }}</span
              >
            </p>
          </div>
          <div *ngIf="showCheckbox">
            <mat-checkbox
              class="checkbox"
              (click)="toggleValidation(attribute, validation)"
              [(ngModel)]="checkedValidations[validation.id]"
            >
            </mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
