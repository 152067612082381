import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { CertificationVariantService } from "src/app/services/dataServices/certification-variant-service/certification-variant-service";
import { Router } from "@angular/router";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { UtilService } from "src/app/services/util-service/utilService";
import { AttributeValidationServiceService } from "src/app/services/dataServices/dataValidation/attribute-validation-service/attribute-validation-service.service";
import { CertificationDtoConverterService } from "src/app/services/certification-dto-converter-service/certification-dto-converter-service.service";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-certification-variant-create-screen",
  templateUrl: "./certification-variant-create-screen.component.html",
  styleUrls: ["./certification-variant-create-screen.component.css"],
})
export class CertificationVariantCreateScreenComponent implements OnInit, OnDestroy {
  public variant: CertificationVariantInterface;
  public version: CertificationVariantVersionInterface;

  private isFormValid: boolean = false;
  private isDescriptionValid: boolean = true;

  constructor(
    public certificationService: CertificationService,
    private certificationVariantService: CertificationVariantService,
    private certificationDtoConverterService: CertificationDtoConverterService,
    private router: Router,
    private dialogService: DialogService,
    private utilService: UtilService,
    public attributeValidationService: AttributeValidationServiceService,
    private organisationService: OrganisationService,
    private userService: UserService,
    private titleService: Title
  ) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnInit(): void {
    this.version = {};

    this.variant = {
      name: "",
      certificationVariantVersions: [this.version],
      description: "",
    };

    this.titleService.setTitle(this.certificationService.activeItem.name + ": Variante anlegen - BIMSWARM");
  }

  ngOnDestroy() 
  {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }
  private cancelCreateVariant(): void {
    this.router.navigate([
      this.certificationDtoConverterService.getRedirectUrl() +
        this.certificationService.activeItem.id,
    ]);
  }

  // Collects all data that was set up, validates it and sends create request to backend
  private createVariant(): void {
    if (!this.isInputValid()) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen Sie sicher, dass alle Attribute validiert sind" +
          " oder entfernen Sie alle Produkttypzugehörigkeiten, von denen nicht alle Attribute validiert sind"
      );
      return;
    }

    if(!UtilService.areStringsValid(this.variant.name, this.variant.description)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Der Name oder die Beschreibung sind zu lang."
      );
      return;
    }

    if(!UtilService.areStringsValid(this.version.name, null)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen sie sicher, dass der Name der Version nicht zu lang ist."
      );
      return;
    }


    if (this.variant.description == null) {
      this.variant.description = "";
    }
    if (this.version.description == null) {
      this.version.description = "";
    }

    if (
      this.utilService.isStringNullOrEmpty(this.variant.name) ||
      this.utilService.isStringNullOrEmpty(this.version.name)
    ) {
      this.dialogService.openDialog(
        "Variante erstellen",
        "Bitte geben Sie einen Varianten- und Versionsnamen an!"
      );
      return;
    }

    this.version.attributeValidationValues = this.attributeValidationService.getAttributeValidationValues();

    this.certificationVariantService.postItem(this.variant).then(
      (x) => {
        this.certificationService.loadCertificationsForOrganisation(
          this.organisationService.activeItem
        );
        let url =
          "/organisations/" +
          this.certificationService.activeItem.company +
          "/certifications/" +
          this.certificationService.activeItem.id +
          "/variants/" +
          x.id;
        this.router.navigateByUrl(url);
      },
      (e) => {
        this.dialogService.openDialog(e, e);
      }
    );
  }

  public isInputValid(): boolean {
    return this.attributeValidationService.checkAllEntityAttributeValuesValidated();
  }

  public onValidityChanged(valid: boolean): void {
    this.isFormValid = valid;
  }

  public onDescriptionValidityChange(descriptionValid: boolean): void {
    this.isDescriptionValid = descriptionValid;
  }
}
