import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-attribute-create",
  styleUrls: ["./dialog.css"],
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
      <p>Name: {{ data.feature.name }}</p>
      <p>Datentyp: {{ data.feature.datatype | datatypeToString }}</p>
      <p *ngIf="data.feature.datatype === 'BOOLEAN'">
        Validierungswert:
        {{ data.feature.validations[0].value | booleanToString }}
      </p>
      <p *ngIf="data.feature.validations[0].pattern">
        Format: {{ data.feature.validations[0].pattern }}
      </p>
      <p
        *ngIf="
          data.feature.validations[0].minSize &&
          data.feature.datatype === 'NUMERIC'
        "
      >
        Min. Wert: {{ data.feature.validations[0].minSize }}
      </p>
      <p
        *ngIf="
          data.feature.validations[0].maxSize &&
          data.feature.datatype === 'NUMERIC'
        "
      >
        Max. Wert: {{ data.feature.validations[0].maxSize }}
      </p>
      <p
        *ngIf="
          data.feature.validations[0].minSize &&
          data.feature.datatype !== 'NUMERIC'
        "
      >
        Min. Länge: {{ data.feature.validations[0].minSize }}
      </p>
      <p
        *ngIf="
          data.feature.validations[0].maxSize &&
          data.feature.datatype !== 'NUMERIC'
        "
      >
        Max. Länge: {{ data.feature.validations[0].maxSize }}
      </p>
      <p
        *ngIf="
          data.feature.validations[0].values && data.feature.datatype === 'LIST'
        "
      >
        Listenwerte: {{ getValuesAsString(data.feature.validations[0].values) }}
      </p>
    </div>
    <div mat-dialog-actions>
      <button
        mat-button
        (click)="onClick(true)"
        class="btn_green"
        cdkFocusInitial
      >
        Speichern
      </button>
      <button
        mat-button
        (click)="onClick(false)"
        class="btn_green"
        cdkFocusInitial
      >
        Abbrechen
      </button>
    </div>
  `,
})
export class DialogAttributeCreate {
  constructor(
    public dialogRef: MatDialogRef<DialogAttributeCreate>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onClick(result: boolean): void {
    this.dialogRef.close(result);
  }

  getValuesAsString(listValues: string[]) {
    let result: string = "";
    for (let index = 0; index < listValues.length; index++) {
      const value = listValues[index];
      result += value;
      result += ", ";
    }
    result = this.removeTrailingCommaAndWhitespace(result);
    return result;
  }

  private removeTrailingCommaAndWhitespace(listValuesString: string) {
    return listValuesString.substr(0, listValuesString.length - 2);
  }
}
