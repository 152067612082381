import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { Router, ActivatedRoute } from "@angular/router";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { UtilService } from "src/app/services/util-service/utilService";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { CertificationVariantService } from "src/app/services/dataServices/certification-variant-service/certification-variant-service";
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-certification-variant-edit-screen",
  templateUrl: "./certification-variant-edit-screen.component.html",
  styleUrls: ["./certification-variant-edit-screen.component.css"],
})
export class CertificationVariantEditScreenComponent implements OnInit, OnDestroy{
  certificationEdit: CertificationInterface;
  variantEdit: CertificationVariantInterface;
  variantEditName: string = "";
  variantEditComment: string = "";
  variantEditDescription: string = "";
  private isDescriptionValid: boolean = true;

  constructor(
    public certificationService: CertificationService,
    public router: Router,
    private route: ActivatedRoute,
    public organisationService: OrganisationService,
    private utilService: UtilService,
    private dialogService: DialogService,
    public certificationVariantService: CertificationVariantService,
    private userService: UserService,
    private titleService: Title,
  ) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  editVariant(): void {
    if (this.utilService.isStringNullOrEmpty(this.variantEditName)) {
      this.dialogService.openDialog(
        "Zertifizierungsvariante bearbeiten fehlgeschlagen",
        "Bitte geben Sie einen Variantennamen ein!"
      );
      return;
    }

    if(!UtilService.areStringsValid(this.variantEditName, this.variantEditDescription)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Der Name oder die Beschreibung sind zu lang."
      );
      return;
    }
    let variant: CertificationVariantInterface = this.certificationEdit.certificationVariants.find(
      (variant: CertificationVariantInterface) =>
        variant.id === this.variantEdit.id
    );
    variant.name = this.variantEditName;
    variant.comment = this.variantEditComment;
    variant.description = this.variantEditDescription;
    this.certificationVariantService.ITEM_MAP[":organisationId"] = String(
      this.certificationService.activeItem.company
    );

    this.certificationVariantService
      .putItem(variant)
      .then(() => {
        let url = this.router.url.toString().replace("/edit", "");
        this.router.navigateByUrl(url);
      })
      .catch((e) => {
        this.dialogService.openDialog(e, e);
      });
  }

  cancelVariantEdit(): void {
    var url = this.router.url.toString().replace("/edit", "");
    this.router.navigate([url]);
  }

  ngOnInit(): void {
    this.certificationEdit = this.certificationService.activeItem;
    //TODO LUCAS;
    this.variantEdit = this.certificationEdit.certificationVariants.find(
      (variant) =>
        variant.id ===
        parseInt(this.route.snapshot.paramMap.get("certificationVariantId"))
    );
    this.variantEditName = this.variantEdit.name;
    this.variantEditDescription = this.variantEdit.description;
    this.variantEditComment = this.variantEdit.comment;
    this.titleService.setTitle(this.certificationEdit.name + ": Zertifizierungsvariante bearbeiten - BIMSWARM");
  }

  ngOnDestroy() { 
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }
  public onDescriptionValidityChange(descriptionValid: boolean): void {
    this.isDescriptionValid = descriptionValid;
  }
}
