import { TrackingService } from './../../../services/tracking-service/tracking.service';
import { UserService } from 'src/app/services/user-service/userservice';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Host, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay, timeout } from 'rxjs/operators';
import { HeaderServiceService } from 'src/app/services/header-service/header-service.service';
import { MarketplaceFilterService } from 'src/app/services/componentServices/marketplace-filter-service/marketplace-filter.service';
import { MarketPlaceSideFilterComponent } from '../../marketplace/subcomponents/marketplace-side-filter/market-place-side-filter.component';
import { ProviderInterface } from './../../../model/user/provider-interface';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedIn = false;
  activeProvider: ProviderInterface[];


  headerText$: Observable<String>;
  queryParams: Params = null;
  isHandset$: Observable<boolean> = this.breakpointObserver
  .observe(Breakpoints.Handset)
  .pipe(
    map((result) => result.matches),
    shareReplay()
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    public headerService: HeaderServiceService,
    private userService: UserService,
    private marketPlaceFilterService: MarketplaceFilterService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private trackingService: TrackingService,
    ) { }

  ngOnInit(): void {
    this.headerText$ = this.headerService.activeHeaderObservable$;
    this.userService.currentUserObservable.subscribe((user) => {
      if (user && this.userService.loggedIn) {
        this.loggedIn = this.userService.loggedIn;
      } else {
        this.loggedIn = false;
        this.headerService.setMenuTabs([]);
      }
    });
    this.headerService.providersObservable$.subscribe((activeProvider) => {
      this.activeProvider = activeProvider;
    })
  }

//---------Buttons, explicitly default/package private access as these are used in corresponding html-file--------------------

  routeToMarketplace(): void {
    if(this.router.url.includes('/marktplatz'))
    {
      this.marketPlaceFilterService.resetFilters();
      this.marketPlaceFilterService.changeToProductScreen();
      //this.cd.detectChanges()

    } else if(this.userService.loggedIn)
    {

   this.headerService.setActiveProvider(this.activeProvider[0],true);


  } else {
      this.router.navigate(["marktplatz"]);


  }
      this.cd.detectChanges();
  }

  public clickedLogo(): void {
     this.trackingService.eventEmitter("test", "test", "test")
     this.marketPlaceFilterService.setToggleBooleanClearFilter(true);
  }

}
