import { CertificationRequestService } from './../../../../../services/dataServices/certification-request/certification-request.service';
import { OrganisationType } from './../../../../../model/enums/organisationType';
import { OrganisationOverview } from './../../../../../dataSource/organisationDataSource';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { CertificationRequestInterface } from 'src/app/model/certifications/certification-request-interface';
import { CertificationRequestLogInterface } from 'src/app/model/certifications/certification-request-log-interface';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { PaymentMethodCertificationRequest } from 'src/app/model/enums/payment-methods-certification-requests';
import { ExecutionTypeCertificationRequest } from 'src/app/model/enums/execution-type-certification-request';
import { CertificationRequestStatus } from 'src/app/model/enums/certification-requests-status';
import { CertificationRequestOverviewComponent } from 'src/app/components/certification-request-shared/certification-request-overview/certification-request-overview.component';
import { CertificationRequestDelegation } from 'src/app/model/certifications/certification-request-delegation';

@Component({
  selector: 'app-certification-request-processing',
  templateUrl: './certification-request-processing.component.html',
  styleUrls: ['./certification-request-processing.component.css'],
})
export class CertificationRequestProcessingComponent implements OnInit {
  executionTypeEnum = ExecutionTypeCertificationRequest;

  public certificationRequest: CertificationRequestInterface = {};
  public executionType: ExecutionTypeCertificationRequest;
  public executionTypeConfirmed: boolean = false;
  public hasPassed: boolean;
  public hasPassedConfirmed: boolean = false;
  public inAdvanceToggle: boolean;
  public inAdvance: PaymentMethodCertificationRequest;
  public inAdvanceConfirmed: boolean = false;

  //contains all organisations the request has already been delegated to and their respective working status on the certification request
  private delegatedTo: CertificationRequestDelegation[] = [];
  //working copy of the delegated organisations
  private delegatedToOrganisations: OrganisationOverview[] = [];

  private delegationConfirmed: boolean = false;

  constructor(
    private certificationRequestService: CertificationRequestService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private organisationService: OrganisationService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.certificationRequestService.getCertificationRequestById(1).then((certificationRequest: CertificationRequestInterface) => {
      this.certificationRequest = certificationRequest;

      //Keep this section later, when data is retrieved from backend:
      //Sets which buttons/fields are already set and should bis disabled now. 
      //E.g.: The payment method was already set (field in certification-request-interface: inAdvance): the toggle-choice for payment method should not be enabled.
      if(this.certificationRequest.payment) {
        this.inAdvance = this.certificationRequest.payment;
        if(this.certificationRequest.payment === PaymentMethodCertificationRequest.IN_ADVANCE) {
          this.inAdvanceToggle = true;
        } else {
          this.inAdvanceToggle = false;
        }
        this.inAdvanceConfirmed = true;
      }
      if(this.certificationRequest.executionType != null) {
        this.executionType = this.certificationRequest.executionType;
        this.executionTypeConfirmed = true;
      }
      if(this.certificationRequest.delegatedTo) {
        this.delegatedTo = this.certificationRequest.delegatedTo;
        for(let delegated of this.delegatedTo) {
          this.delegatedToOrganisations.push(delegated.delegatedTo);
        }
      }
      //End of keep-section
    });
  }

  public backToAllRequests() {
    this.router.navigate([
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/certificationRequests"
    ]);
  }

  public acceptRequest() {
    this.dialogService.openConfirmDialog("Wollen Sie diese Anfrage annehmen/ablehnen?", "Ablehnen", "Annehmen")
      .subscribe((confirmed: boolean) => {
        if(confirmed) {
          this.certificationRequest.status = CertificationRequestStatus.ACCEPTED;
          this.certificationRequestService.updateRequestAndAddLogEntry(this.certificationRequest, "Ticket wurde vom Zertifizierungsanbierter zur Bearbeitung angenommen.")
            .then((updatedRequest: CertificationRequestInterface) => {
              this.certificationRequest = updatedRequest;
            }) 
        } else {
          this.certificationRequest.status = CertificationRequestStatus.REJECTED;
          this.certificationRequestService.updateRequestAndAddLogEntry(this.certificationRequest, "Der Zertifizierungsanbieter hat das Ticket abgelehnt.")
            .then((updatedRequest: CertificationRequestInterface) => {
              this.certificationRequest = updatedRequest;
            })
        }
        this.cd.detectChanges();
      })
  }

  public changeStatus() {
    this.dialogService.openCertReqChangeStatusDialog("Statusänderung")
      .subscribe((newStatus: CertificationRequestStatus) => {
        if(newStatus) {
          this.certificationRequest.status = newStatus;
          this.certificationRequestService.updateRequestAndAddLogEntry(this.certificationRequest, "Der Status wurde zu '" + newStatus + "' geändert")
            .then((returned: CertificationRequestInterface) => {
              this.certificationRequest = returned;
            })
          this.cd.detectChanges();
        }
      })
  }

  public onCommented(logText: string) {
      this.certificationRequestService.updateRequestAndAddLogEntry(this.certificationRequest, logText)
        .then((returned: CertificationRequestInterface) => {
          this.certificationRequest  = returned;
          this.cd.detectChanges();
        })
  }

  /**
   * Case: User selected execution type and confirmed; now selects otherwise -> options for "old" selections should no longer be available
   * Not clear: Logging?
   */
  public resetExecutionTypeConfirmed() {
    this.executionTypeConfirmed = false;
    this.certificationRequest.status = CertificationRequestStatus.ACCEPTED;
    this.delegatedToOrganisations = [];
  }

  public confirmExecutionType() {
    switch(this.executionType) {
      case ExecutionTypeCertificationRequest.INHOUSE: {
        this.dialogService.openConfirmDialog("Wollen Sie die Zertifzierung durchführen?", "Nein", "Ja")
          .subscribe((confirmed: boolean) => {
            if(confirmed) {
              this.executionTypeConfirmed = true;
              this.certificationRequest.status = CertificationRequestStatus.IN_PROGRESS;
              this.certificationRequestService.updateRequestAndAddLogEntry(this.certificationRequest, "Die Zertifizierung erfolgt durch: " + this.organisationService.activeItem.organisationName + ".")
              this.cd.detectChanges();
            }
          })
        break;
      }
      case ExecutionTypeCertificationRequest.EXTERNAL: {
        this.addDelegation().then(() => {
          this.executionTypeConfirmed = true;
          this.cd.detectChanges();
        });
        break;
      }
    }
  }

  /**
   * Listening for value change in child component: toggle-switch
   * @param inAdvance : Value selected in toggle component and emitted upon select
   */
  public onInAdvanceValueChanged(inAdvanceToggle: boolean) {
    this.inAdvanceToggle = inAdvanceToggle;
  }

  public confirmPaymentVariant() {
    this.dialogService.openConfirmDialog("Wollen Sie die Rechnungseinstellung festlegen?", "Nein", "Ja")
      .subscribe((confirmed: boolean) => {
        if(confirmed) {
          //Log entry
          this.inAdvanceConfirmed = true;
          if(this.inAdvanceToggle) {
            this.certificationRequest.payment = PaymentMethodCertificationRequest.IN_ADVANCE;
            this.certificationRequest.status = CertificationRequestStatus.WAITING;
            this.certificationRequestService.updateRequestAndAddLogEntry(this.certificationRequest, "Der Zertifizierungsprozess wird nach Zahlungseingang fortgeführt.")
              .then((returned: CertificationRequestInterface) => {
                this.certificationRequest = returned;
              })
          } else {
            this.certificationRequest.payment = PaymentMethodCertificationRequest.AFTER_CERTIFICATION;
            this.certificationRequestService.updateRequestAndAddLogEntry(this.certificationRequest, "Die Rechnungsstellung erfolgt nach Abschluss des Zertifizierungsprozesses.")
              .then((returned: CertificationRequestInterface) => {
                this.certificationRequest = returned;
              })
          }
          this.cd.detectChanges();
        }
      })
  }

  /**
   * Dont yet change the certification request in backend. This function so far only adds the delegated organisations to view and collects them
   * As soon as the user decides the delegations to be complete, confiming is required (confirmDelegation-method), this starts the delegation backend-wise
   */
  public addDelegation(): Promise<boolean> {
    this.delegationConfirmed = false;
    return new Promise((resolve) => {
      this.dialogService.openDelegateCertificationDialog("Wählen Sie einen Zertifizierungsdienstleister:", this.delegatedToOrganisations)
      .subscribe((delegatedTo: OrganisationOverview) => {
        if(delegatedTo) {
          this.delegatedToOrganisations.push(delegatedTo);
          this.cd.detectChanges();
          resolve(true);
        }
      })
    })
  }

  /**
   * Removes this organisation and asks for another one for delegation. 
   * The button "Ändern" should only be available when the delegated organisation has not yet accepted the delegation. TODO
   * @param delegatedOrga 
   */
  public changeDelegation(delegatedOrga: OrganisationOverview) {
    this.removeDelegation(delegatedOrga);
    this.addDelegation();
  }

  /**
   * Removes this organisation from delegation. 
   * The button "Stornieren" should only be available when the delegated organisation has not yet accepted the delegation. TODO
   * @param delegatedOrga 
   */
  private removeDelegation(delegatedOrga: OrganisationOverview) : boolean {
    this.delegatedToOrganisations.splice(this.delegatedToOrganisations.indexOf(delegatedOrga), 1);
    this.cd.detectChanges();
    return true;
  }

  /**
   * After confirmation the organisations that have been chosen are informed and the request is updated. 
   * This includes setting the delegatedTo-field of the request and initializing the sub-status with "REQUESTED"
   */
  public confirmDelegation() {
    let dialogTitle: string = "Wollen Sie die Zertifizierungsdienstleister";
    if(this.delegatedToOrganisations.length == 1) {
      dialogTitle = "Wollen Sie den Zertifizierungsdienstleister";
    }
    this.dialogService.openConfirmDelegateCertificationDialog(
      dialogTitle, 
      this.delegatedToOrganisations, 
      this.certificationRequest.attribute
    ).subscribe((confirmed: boolean) => {
      if(confirmed) {
        this.delegationConfirmed = true;
        this.certificationRequest.delegatedTo = this.delegatedTo = this.transferToDelegated();
        this.certificationRequestService.updateRequestAndAddLogEntry(this.certificationRequest, "Die Zertifizierungsprüfung wird durch einen Zertifizierungsdienstleister durchgeführt.")
          .then((returned: CertificationRequestInterface) => {
            this.certificationRequest = returned;
          })
        this.cd.detectChanges();
      }
    })
  }

  public transferToDelegated(): CertificationRequestDelegation[] {
    let updatedDelegatedToOrganiations: CertificationRequestDelegation[] = [];
    OUTER: for(let organisation of this.delegatedToOrganisations) {
      if(this.delegatedTo && this.delegatedTo.length > 0) {
        updatedDelegatedToOrganiations = this.delegatedTo;
        for(let alreadyDelegated of updatedDelegatedToOrganiations) {
          if(alreadyDelegated.delegatedTo.organisationId == organisation.organisationId) continue OUTER;
        }
      }
      let newDelegated: CertificationRequestDelegation = { 
        delegatedTo: organisation,
        status: CertificationRequestStatus.REQUESTED
      }
      updatedDelegatedToOrganiations.push(newDelegated);
    }
    return updatedDelegatedToOrganiations;
  }

  /**
   * Listening for value change in child component: toggle-switch
   * @param passed : Value selected in toggle component and emitted upon select
   */
  public onHasPassedValueChanged(passed: boolean) {
    this.hasPassed = passed;
  }

  public confirmCertification() {
    this.dialogService.openConfirmDialog("Wollen Sie das Prüfungsergebnis festlegen?", "Nein", "Ja")
      .subscribe((confirmed: boolean) => {
        if(confirmed) {
          if(this.certificationRequest.payment === PaymentMethodCertificationRequest.AFTER_CERTIFICATION) {
            let logText = "Die Zertifizierungsprüfung war erfolgreich. Die Zertifizierung erfolgt nach Zahlungseingang."
            if(!this.hasPassed) {
              logText = "Die Zertifizierungsprüfung wurde nicht bestanden. Der Vorgang wird nach Zahlungseingang abgeschlossen."
            }
            this.certificationRequest.status = CertificationRequestStatus.WAITING;
            this.certificationRequestService.updateRequestAndAddLogEntry(this.certificationRequest, logText)
              .then((returned: CertificationRequestInterface) => {
                this.certificationRequest = returned;
              })
          } else {
            let logText = "Die Zertifizierungsprüfung war erfolgreich."
            if(!this.hasPassed) {
              logText = "Die Zertifizierungsprüfung wurde nicht bestanden."
              this.certificationRequest.status = CertificationRequestStatus.NOT_PASSED;
            } else {
              this.certificationRequest.status = CertificationRequestStatus.PASSED;
            }
            this.certificationRequestService.updateRequestAndAddLogEntry(this.certificationRequest, logText)
              .then((returned: CertificationRequestInterface) => {
                this.certificationRequest = returned;
              })
          }
          this.hasPassedConfirmed = true;
          this.cd.detectChanges();
        }
      })
  }

  public closeTicket() {
    this.certificationRequest.status = CertificationRequestStatus.CLOSED;
    this.certificationRequestService.updateRequestAndAddLogEntry(this.certificationRequest, "Der Zertifizierungsvorgang wurde abgeschlossen, die Zertifizierung wurde dem Produkt hinzugefügt.")
      .then((returned: CertificationRequestInterface) => {
        this.certificationRequest = returned;
      })
  }

}
