import { Router } from "@angular/router";
import { UserService } from "src/app/services/user-service/userservice";
import { FormBuilder, Validators } from "@angular/forms";
import { FormGroup, AbstractControl } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { VALIDATORS } from 'src/environments/validators';

@Component({
  selector: "app-resend-mail-screen",
  templateUrl: "./resend-mail-screen.component.html",
  styleUrls: ["./resend-mail-screen.component.css"],
})
export class ResendMailScreenComponent implements OnInit {
    resendMailForm: FormGroup;

  email: AbstractControl;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private dialogService: DialogService
  ) {
    this.resendMailForm = formBuilder.group({
        email: ["", VALIDATORS.email],
    });
    this.email = this.resendMailForm.controls["email"];
  }

  onSubmit() {
    let username = this.email.value.toLowerCase();
    this.userService
      .resendMail(username)
      .then(() => {
        this.dialogService
          .openDialog(
            "Aktivierungsmail erneut gesendet",
            "Die Aktivierungsmail wurde erneut gesendet und wird innerhalb der nächsten Minuten ankommen. " +
            "Nach erfolgreicher Aktivierung können Sie sich einloggen und auf ihren Account zugreifen."
          )
          .subscribe(() => {
            this.router.navigate(["login"]);
          });
      })
      .catch(() => {});
  }

  public onCancel(): void {
    this.router.navigate([""]);
  }

  ngOnInit() {}
}
