import { FormGroup } from "@angular/forms";
import { Component, OnInit, Input } from "@angular/core";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";

@Component({
  selector: "app-attribute-validation",
  templateUrl: "./attribute-validation.component.html",
  styleUrls: ["./attribute-validation.component.css"],
})
export class AttributeValidationComponent implements OnInit {
  @Input()
  featureForm: FormGroup;

  @Input()
  listValues: string[];

  readonly negativeMessage =
    "Stellen Sie sicher, dass nur positive Zahlen benutzt werden und das der minimal-Wert nicht größer als der maximal-Wert ist!";

  constructor(private dialogService: DialogService) {}

  ngOnInit() {
    if (this.featureForm.value.dataType === "BOOLEAN") {
      this.featureForm.controls.booleanValue.setValue(false);
    }
  }

  validate(form: FormGroup, listValues?: string[]): boolean {
    //--------------------------------------------------------------------------------------------
    if (!this.checkAttribute(this.featureForm)) {
      this.dialogService.openDialog("Merkmal anlegen", this.negativeMessage);
      return false;
    }
    //--------------------------------------------------------------------------------------------
    const formValue = form.value;
    //--------------------------------------------------------------------------------------------
    if (
      formValue.dataType === "STRING" &&
      formValue.stringMax != null &&
      formValue.stringMax < 1
    ) {
      this.dialogService.openDialog(
        "Merkmal anlegen fehlgeschlagen",
        "Die Maximallänge eines Textmerkmals muss mindestens 1 betragen."
      );
      return false;
    }
    //--------------------------------------------------------------------------------------------
    if (form.value.dataType === "LIST") {
      this.listValues = listValues;
      //---------------------------------------------------------------------------------
      if (!this.listValues || this.listValues.length <= 0) {
        this.dialogService.openDialog(
          "Merkmal anlegen fehlgeschlagen",
          "Ein Listen-Merkmal muss mindestens einen Listenwert haben"
        );
        return false;
      }
      //---------------------------------------------------------------------------------
      else if (
        !this.checkNumberRange(
          form.value.listMin,
          form.value.listMax,
          0,
          this.listValues.length
        )
      ) {
        this.dialogService.openDialog(
          "Merkmal anlegen fehlgeschlagen",
          "Die Mindestanzahl und Maximalanzahl eines Listen-Merkmal müssen zwischen 0 und der Anzahl an Elementen in der Liste sein."
        );
        return false;
      }
      //---------------------------------------------------------------------------------
    }
    //--------------------------------------------------------------------------------------------
    return true;
  }

  checkAttribute(form: FormGroup): boolean {
    if (form.value.dataType === "LIST") {
      return this.checkNumberRange(form.value.listMin, form.value.listMax);
    }
    if (form.value.dataType === "NUMERIC") {
      return this.checkNumberRange(
        form.value.numberMin,
        form.value.numberMax,
        Number.MIN_SAFE_INTEGER
      );
    }
    if (form.value.dataType === "STRING") {
      return this.checkNumberRangeForString(form.value?.stringMin, form.value?.stringMax);
    }
    if (form.value.dataType === "BOOLEAN") {
      return true;
    }
    return undefined; //!!!
  }

  checkNumberRangeForString(  min: number | null | undefined,
    max: number | null | undefined,
    allowedMin: number = 0,
    allowedMax: number = Number.MAX_VALUE): boolean { 
      return Number(min) <= Number(max) && Number(min) >= allowedMin && Number(max) <= allowedMax;

  }


  checkNumberRange(
    min: number | null | undefined,
    max: number | null | undefined,
    allowedMin: number = 0,
    allowedMax: number = Number.MAX_VALUE
  ): boolean {
    const minValue = min || allowedMin;
    const maxValue = max || allowedMax;
    return (
      Number(allowedMin) <= Number(minValue) &&
      Number(minValue) <= Number(allowedMax) &&
      Number(allowedMin) <= Number(maxValue) &&
      Number(maxValue) <= Number(allowedMax) &&
      Number(minValue) <= Number(maxValue)
    );
  }
}
