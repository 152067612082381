import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";
import { inherits } from "util";
import { parseSvg } from "../../util/svgUtil";
import { forkEvent } from "../../util/preloadedAssets"

/* Allows dynamic Parameter Injection */
ForkEventRender["$inject"] = ["eventBus"];

/* Custom Renderer for Fork-Events */
export default function ForkEventRender(eventBus: any) {
  BaseRenderer.call(this, eventBus, 1500);

  /* Checks if the Renderer is able to render the Element */
  this.canRender = function (element) {
    return element.type === "composer:ForkEvent";
  };

  /* Draws the Element */
  this.drawShape = function (parent, shape) {
    let image = parseSvg(forkEvent).firstChild;
    image = document.importNode(image, true);

    parent.appendChild(image);

    return image;
  };
}

inherits(ForkEventRender, BaseRenderer);
