<div class="wrapper">
  <div *ngIf="ptgAttributeValidations.length != 0" class="grid">
    <div class="header">
      <div>
        <p>Name des Merkmals</p>
      </div>
      <div>
        <p>Validierung</p>
      </div>
      <div>
        <p>Optional</p>
      </div>
    </div>
    <div class="attributes">
      <div
        *ngFor="let attr of getSortedAttributeList(); let i = index"
        [ngSwitch]="attr.datatype"
        class="row"
      >
        <div>
          <div
            [matTooltip]="
              attr.description
                ? attr.description
                : 'Keine Kurzinformation vorhanden'
            "
            [matTooltipPosition]="'below'"
            matTooltipClass="tooltip"
          >
            <img
              [src]="'/assets/img/icons/info.png'"
              alt="Beschreibungs-Icon"
              class="tooltip-icon"
            />
          </div>
          <div>
            <red-cross-button
              [hidden]="!editable"
              (click)="removeAttribute(attr.id)"
            ></red-cross-button>
          </div>
        </div>
        <div>
          <p>{{ attr.name }}</p>
        </div>
        <div *ngSwitchCase="'BOOLEAN'" class="mini-grid-attribute-validations">
          <p class="boolean-value">Wert:</p>
          <p class="boolean-validation-value">
            {{ attr.validations[0].value | booleanToString }}
          </p>
        </div>
        <div *ngSwitchCase="'STRING'" class="mini-grid-attribute-validations">
          <p>Min. Zeichen:</p>
          <p>
            {{
              attr.validations[0].minSize ? attr.validations[0].minSize : "0"
            }}
          </p>
          <p>Max. Zeichen:</p>
          <p>
            {{
              attr.validations[0].maxSize ? attr.validations[0].maxSize : "∞"
            }}
          </p>
          <p>Format:</p>
          <p>
            {{
              attr.validations[0].pattern ? attr.validations[0].pattern : "-"
            }}
          </p>
        </div>
        <div *ngSwitchCase="'NUMERIC'" class="mini-grid-attribute-validations">
          <p>Min. Wert:</p>
          <p>
            {{
              attr.validations[0].minSize ? attr.validations[0].minSize : "0"
            }}
          </p>
          <p>Max. Wert:</p>
          <p>
            {{
              attr.validations[0].maxSize ? attr.validations[0].maxSize : "∞"
            }}
          </p>
        </div>
        <div *ngSwitchCase="'LIST'" class="mini-grid-attribute-validations">
          <p>Min. Auswahl:</p>
          <p>
            {{
              attr.validations[0].minSize ? attr.validations[0].minSize : "0"
            }}
          </p>
          <p>Max. Auswahl:</p>
          <p>
            {{
              attr.validations[0].maxSize
                ? attr.validations[0].maxSize
                : attr.validations[0].values.length
            }}
          </p>
          <p>Listenwerte:</p>
          <p>
            <span
              *ngFor="
                let attributeValue of attr.validations[0].values;
                let isLast = last
              "
              >{{ attributeValue }}{{ isLast ? "" : ", " }}</span
            >
          </p>
        </div>
        <toggle-switch
          [trueLabel]="'Ja'"
          [falseLabel]="'Nein'"
          [allowUndefined]="false"
          (valueChange)="onToggleOptional($event, attr.id)"
          [value]="isAttributeOptional(attr.id)"
          [disableToggle]="!editable"
        ></toggle-switch>
      </div>
    </div>
  </div>
</div>
