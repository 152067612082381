import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { identifierModuleUrl } from '@angular/compiler';

@Component({
  selector: 'app-hexagon-button-text-icon',
  templateUrl: './hexagon-button-text-icon.component.html',
  styleUrls: ['./hexagon-button-text-icon.component.css']
})
export class HexagonButtonTextIconComponent implements OnInit {

  constructor() {
  }

  @Input()
  public text: string;

  @Input()
  public icon: string;

  @Input()
  public width: number = 2;

  ngOnInit() {
  }

}
