import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ProductInterface } from "src/app/model/products/product-interface";
import { ProductService } from "src/app/services/dataServices/product-service/productService";

@Component({
  selector: "app-composer-product-overview",
  templateUrl: "./composer-product-overview.component.html",
  styleUrls: ["./composer-product-overview.component.css"],
})
export class ComposerProductOverviewComponent implements OnInit {
  @Input()
  public productId: number;

  @Output()
  public collapseOverview: EventEmitter<any> = new EventEmitter();

  public product: ProductInterface;

  public mockPTGs: string[] = ["IFC", "CAFM", "CDE"];
  public mockFeatures: { label: string; name: string }[] = [
    { label: "Zielgruppe", name: "Architekten" },
    { label: "BIM", name: "Open BIM" },
    { label: "Leistungsphase", name: "3,4,5" },
  ];
  public mockCertificates: string[] = ["IFC 2x3", "IFC4"];
  public mockInputFormats: string[] = [".dwg", ".dxf", ".gml"];
  public mockOutputFormats: string[] = [".dwg", ".dxf", ".ifc"];

  constructor(private productService: ProductService, private router: Router) {}

  ngOnChanges() {
    this.productService.getProduct(this.productId).then((success) => {
      this.product = success;
    });
  }

  ngOnInit() {
    this.productService.getProduct(this.productId).then((success) => {
      this.product = success;
    });
  }

  /* Opens a new Tab with the ProductDetail Page of the currently selected Product */
  public navigateToProduct() {
    // Converts the route into a string that can be used
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/marktplatz/products/${this.product.id}`])
    );

    window.open(url, "_blank");
  }

  /* Calls the EventEmitter to close the Overview */
  public collapseProductOverview() {
    this.collapseOverview.emit();
  }
}
