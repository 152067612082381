import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-hmtl-display",
  templateUrl: "./hmtl-display.component.html",
  styleUrls: ["./hmtl-display.component.css"],
})
export class HmtlDisplayComponent implements OnInit {
  //----------------------------------------------------------
  /**
   * The HTML text to display
   */
  @Input()
  text: string;

  /**
   * The lineLimit to display in collapsed state.
   * If equals 0 the toggleButton is disabled.
   * Initial Value is 0
   */
  @Input()
  lineLimit: number = 0;

  /**
   * Boolean that tracks the display state.
   * Either Display is collapsed to "lineLimit" or
   * is expanded to maximim line number
   */
  isExpanded: boolean = false;

  //----------------------------------------------------------

  constructor() {}

  ngOnInit() {}

  //----------------------------------------------------------

  private toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  /**
   * This toggles the lable depending on isExpanded.
   */
  private lable() {
    if (this.isExpanded) return "weniger";
    return "mehr";
  }

  /**
   * Supplies the text to display.
   * Breaks line on "paragraph" tag </p>.
   *
   */
  public limitText(): string {
    // if expanded or no linelimit
    if (this.isExpanded || this.lineLimit == 0) {
      return this.text;
    }

    // split on linebreak
    let line = this.text.split(new RegExp("</p>"));
    let limitedText = "";

    for (let i = 1; i <= this.lineLimit; i++) {
      limitedText += line[i - 1] + "</p>";
    }

    return limitedText;
  }
  //----------------------------------------------------------
}
