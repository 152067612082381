import {Injectable} from '@angular/core';
import {ProductInterface} from '../../model/products/product-interface';
import {ActivatedRoute} from '@angular/router';
import { ProductVariantInterface } from 'src/app/model/products/product-variant-interface';
import { VariantVersionInterface } from 'src/app/model/products/variant-version-interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductDtoConverterServiceService {

  constructor( private activeRoute: ActivatedRoute) { }

  convertToProductDto(product: ProductInterface): ProductInterface {
    for (const variant of product.productVariants) {
      this.convertToVariantDto(variant);
    }
    return product;
  }

  convertToVariantDto(variant: ProductVariantInterface): ProductVariantInterface {
    for(const version of variant.variantVersions) {
      this.convertToVersionDto(version);
    }
    return variant
  }

  convertToVersionDto(version: VariantVersionInterface): VariantVersionInterface {
    let attributeValidationValues = {};
    let ptgVersionIds = [];
    for (const ptgVersion of version.ptgVersions) {
      ptgVersionIds.push(ptgVersion.id);
    }
    //TODO THIS SHOULD NOT BE HERE
    if (version.attributeValidationValues instanceof Array && version.attributeValidationValues !== undefined ) {
      for (let index = 0; index < version.attributeValidationValues.length; index++) {
        const attributValidationValue = version.attributeValidationValues[index];
        if (attributValidationValue != null) {
          if (attributValidationValue.attribute) {
            attributValidationValue.attribute.validations.forEach(validation => {
              attributeValidationValues[validation.id] = attributValidationValue.value;
            });
          } else {
            attributeValidationValues[index] = attributValidationValue;
          }
        }
      }
    } else {
      for (const attributeValue in version.attributeValidationValues) {
        attributeValidationValues[attributeValue] = version.attributeValidationValues[attributeValue];
      }
    }
    version.ptgVersionIds = ptgVersionIds;
    version.attributeValidationValues = attributeValidationValues;
    return version;
  }

  getRedirectUrl(): string {
    return 'organisations/' +
    this.activeRoute.firstChild.snapshot.params.organisationId +
    '/products/';
  }
}
