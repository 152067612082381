<div class="scrollable">
<div class="version-selector-wrapper">
  <app-detail-display-variant-version-selector
    [certification]="certification"
    [dataType]="'CERTIFICATION'"
    (onVersionChosen)="onVersionChosen($event)"
  >
  </app-detail-display-variant-version-selector>
</div>


<div class="twocolumnsgrid">
<app-certification-detail-display-type-overview class="certType" [certificationType]="certification?.certificationType"></app-certification-detail-display-type-overview>
<div class="search-panel">
  <div (click)="resetAndReload()" class="button-search"></div>
  <mat-form-field floatLabel="never" class="input">
    <input
      matInput
      (keyup)="resetAndReload()"
      [(ngModel)]="searchTerm"
      type="search"
      placeholder="Suchen..."
    />
  </mat-form-field>
</div>
</div>





<p *ngIf="certifiedProducts.length === 0">
  Keine Produkte mit dieser Zertifikatsversion vorhanden
</p>
<app-infinite-scroll
  (scrolled)="onScroll()"
  *ngIf="certifiedProducts.length > 0"
>
<table
mat-table
[dataSource]="certifiedProducts"
class="mat-elevation-z4"
style="width: 100%"
>
<!-- Position Column -->
<ng-container matColumnDef="Anbieter">
  <th (click)="toggleSort('ORGANISATION_NAME_ASC')" mat-header-cell *matHeaderCellDef [innerHTML]="'Anbieter' + checkForSortIcon('ORGANISATION')"></th>
  <td class="product-name" mat-cell (click)=" navigateToOrganisationDetailPage(element.organisationNameProduct)" *matCellDef="let element">{{ element.organisationNameProduct }}</td>
</ng-container>

<ng-container matColumnDef="Produkt">
  <th mat-header-cell *matHeaderCellDef (click)="toggleSort('PRODUCT_NAME_ASC')" [innerHtml]="'Produkt' + checkForSortIcon('PRODUCT_NAME')"></th>
  <td class="product-name" (click)="navigateToProductDetailPage(element.productId)" mat-cell *matCellDef="let element">{{ element.productName }}</td>
</ng-container>

<ng-container matColumnDef="Produktvariante">
  <th mat-header-cell *matHeaderCellDef  (click)="toggleSort('PRODUCT_VARIANT_NAME_ASC')" [innerHtml]="'Produktvariante' + checkForSortIcon('PRODUCT_VARIANT_NAME')"></th>
  <td  mat-cell *matCellDef="let element">{{ element.productVariantName }}</td>
</ng-container>

<ng-container matColumnDef="Produktversion">
  <th mat-header-cell *matHeaderCellDef  (click)="toggleSort('PRODUCT_VARIANT_VERSION_NAME_ASC')"[innerHtml]="'Produktversion' + checkForSortIcon('PRODUCT_VARIANT_VERSION_NAME')"></th>
  <td  mat-cell *matCellDef="let element">{{ element.productVariantVersionName }}</td>
</ng-container>


<tr mat-header-row *matHeaderRowDef="displayColumnsCertifiedProducts"></tr>
<tr mat-row *matRowDef="let row; columns: displayColumnsCertifiedProducts"></tr>
</table>

<br/>
<br/>
</app-infinite-scroll>
</div>