import { SidemenuButtonInterface } from "src/app/model/sidemenuButtonInterface";
import { ProductInterface } from "src/app/model/products/product-interface";
import { ProductVariantInterface } from "src/app/model/products/product-variant-interface";
import { PtgInterface } from "src/app/model/ptgs/ptg-interface";
import { PtgVersionInterface } from "src/app/model/ptgs/ptg-version-interface";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";

export class SidemenuButttonFactory {
  static CreateButtonFromProduct(
    product: ProductInterface
  ): SidemenuButtonInterface {
    let b = {
      label: product.name,
      composerItem: false,
      type: null,
      hasChildren: true,
      children: [],
      ptgId: null,
      entityId: product.id,
      usecaseId: null,
      link: "/organisations/" + product.company + "/products/" + product.id,
      isCertification: false,
      imageId: product.fileId,
    };

    return b;
  }

  static CreateButtonFromVariant(
    variant: ProductVariantInterface,
    product: ProductInterface
  ) {
    let b = {
      label: variant.name,
      link:
        "/organisations/" +
        product.company +
        "/products/" +
        product.id +
        "/variants/" +
        variant.id,
      addButton: false,
      entityId: variant.id,
      ptgId: null,
      image: null,
    };
    return b;
  }

  static CreateButtonFromPtg(ptg: PtgInterface) {
    let b = {
      label: ptg.name,
      link:
        "/organisations/" + ptg.organisationId + "/productTypeGroups/" + ptg.id,
      addButton: false,
      entityId: null,
      ptgId: ptg.id,
      image: null,
      hasChildren: true,
    };
    return b;
  }

  static CreateButtonFromPtgVersion(ptgVersion, ptg, label) {
    let b = {
      label: "Version " + label,
      link:
        "/organisations/" +
        ptg.organisationId +
        "/productTypeGroups/" +
        ptg.id +
        "/versions/" +
        ptgVersion.id,
      addButton: false,
      entityId: null,
      ptgId: ptg.id,
      image: null,
    };

    return b;
  }

  static CreatePtgAddButton(type: string, organisation: number) {
    let b = {
      label: type + " anlegen",
      link: "/organisations/" + organisation + "/productTypeGroups/create",
      addButton: true,
      entityId: null,
      ptgId: null,
      image: null,
    };
    return b;
  }

  static CreateButtonFromCertification(
    certification: CertificationInterface
  ): SidemenuButtonInterface {
    let b = {
      label: certification.name,
      composerItem: false,
      type: null,
      hasChildren: true,
      children: [],
      ptgId: null,
      entityId: certification.id,
      usecaseId: null,
      link:
        "/organisations/" +
        certification.company +
        "/certifications/" +
        certification.id,
      isCertification: true,
      imageId: certification.fileId,
    };

    return b;
  }

  static CreateButtonFromCertificationVariant(
    variant: CertificationVariantInterface,
    certification: CertificationInterface
  ) {
    let b = {
      label: variant.name,
      link:
        "/organisations/" +
        certification.company +
        "/certifications/" +
        certification.id +
        "/variants/" +
        variant.id,
      addButton: false,
      entityId: variant.id,
      ptgId: null,
      image: null,
      isCertification: true,
    };
    return b;
  }
}
