import { PtgInterface } from "src/app/model/ptgs/ptg-interface";

/* Custom Modul for the Properties Panel */
export function ComposerPTGSelectionForViewer(options: {
  id: string;
  label: string;
  options: PtgInterface[];
}) {
  let optionsString = "";
  options.options.forEach(
    (element) => (optionsString += createSelectOption(element))
  );

  return {
    id: options.id,
    html:
      '<div><div style="display: flex; justify-content: space-between; align-items: flex-end;"><label data-value="label" ' +
      '" style="width: 70%"></label></div><select disabled size="5" id="' +
      options.id +
      '">' +
      optionsString +
      "</select></div>",
    get: function (element, node) {
      return {
        label: options.label,
      };
    },
  };
}


/* Creates the HTML Code for a Select Option */
function createSelectOption(option: PtgInterface) {
    return "<option value='" + option.id + "'>" + option.name + "</option>";
  }
  