import { Component, OnInit, Input } from '@angular/core';
import {ProductService} from "../../../services/dataServices/product-service/productService";

@Component({
  selector: 'app-selected-product-panel',
  templateUrl: './selected-product-panel.component.html',
  styleUrls: ['./selected-product-panel.component.css']
})
export class SelectedProductPanelComponent implements OnInit {

  @Input()
  product;

  constructor(
    public productService: ProductService
  ) { }

  removeProductDependency(productId: number): void{
    this.product.relatedProducts = this.product.relatedProducts.filter(product => product !== productId);
  }

  ngOnInit() {
  }

}
