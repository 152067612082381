import { ReviewInterface } from 'src/app/model/reviews/review-interface';
import { ReportInterface } from './../../../model/reviews/report-interface';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { ChangeDetectorRef, Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ReviewService } from 'src/app/services/review-service/review.service';
import { ReviewReportStatus } from 'src/app/model/enums/reviewReportStatus';

@Component({
  selector: 'app-review-report-item',
  templateUrl: './review-report-item.component.html',
  styleUrls: ['./review-report-item.component.css']
})
export class ReviewReportItemComponent implements OnInit {
  
  @Input()
  public review: ReviewInterface;

  @Output() 
  reportDeleted = new EventEmitter<ReportInterface>();

  @Output()
  reviewDeleted = new EventEmitter<ReviewInterface>();

  public showReports: boolean = false;
  public toggleButtonText: string = "Meldungen"

  constructor(
    private reviewService: ReviewService,
    private dialogService: DialogService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {}

  deleteReview(review: ReviewInterface): void {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie die Bewertung wirklich löschen?",
        "Nein",
        "Ja"
      )
      .subscribe((confirm) => {
        if (confirm) {
          this.reviewDeleted.emit(review);
        }
      });
  }

  deleteReviewReport(reviewReport: ReportInterface) {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie diese Meldung wirklich löschen?",
        "Nein",
        "Ja"
      )
      .subscribe((confirm) => {
        if(confirm){
          this.reportDeleted.emit(reviewReport);
        }
      })
  }

  public toggleShowReports(): void {
    if(this.toggleButtonText == "Meldungen") {
      this.toggleButtonText = "Einklappen"
    } else {
      this.toggleButtonText = "Meldungen"
    }
    this.showReports = !this.showReports;
  }
}
