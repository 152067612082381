import { FileServiceService } from './../../../services/file-service/file-service.service';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { Component, Input, OnInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { CertificationRequestInterface } from 'src/app/model/certifications/certification-request-interface';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { CertificationRequestLogInterface } from 'src/app/model/certifications/certification-request-log-interface';

@Component({
  selector: 'certification-request-overview',
  templateUrl: './certification-request-overview.component.html',
  styleUrls: ['./certification-request-overview.component.css']
})
export class CertificationRequestOverviewComponent implements OnInit{
  public filesById;

  @Input()
  public certificationRequest: CertificationRequestInterface;

  @Input()
  public isCertifier: boolean

  @Output()
  commented = new EventEmitter<string>();
  
  constructor(
    private organisationService: OrganisationService,
    private dialogService: DialogService,
    private fileService: FileServiceService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() { }

  /**
   * Available only for requesting party AND after certification request was submitted
   */
  public uploadFile() {
    this.dialogService.openFileManagerDialog(
      "Datei zum Zertifizierungsantrag hinzufügen",
      "Abbrechen",
      "Speichern",
      "Wählen Sie eine oder mehrere Dateien aus, die dem Zertifizierungsantrag hinzugefügt werden sollen.",
      { multi: true, filter: 'application/pdf', upload: true }
    ).subscribe( (confirmed: string[]) => {
      let newFileIds = [];
      if(this.certificationRequest.fileIds) {
        newFileIds = this.certificationRequest.fileIds;
      }
      confirmed.forEach((element: string) => {
        if (newFileIds.indexOf(element) == -1) {
          newFileIds.push(element);
        }
      });
      this.certificationRequest.fileIds = newFileIds;
      this.cd.detectChanges();
    })
  }

  /**
   * Available only for requesting party AND after certification request was submitted
   */
  public fileInfo(fileId: string) {
    this.fileService.data$.subscribe((data) => {
      this.filesById = data;
    });
    //Racing Codition with File-Service.service.ts updateMyFiles() on Startup
    //So this.filesById can be empty when used to check for fid
    return Object.keys(this.filesById).length > 0
      ? this.filesById[fileId]
      : "Loading...";
  }

  /**
   * Available only for requesting party AND after certification request was submitted
   */
  public removeFile(fileId: string) {
    this.dialogService.openConfirmDialog("Wollen Sie diese Datei aus dem Zertifizierungsantrag entfernen?", "Abbrechen", "Entfernen")
      .subscribe((confirmed: boolean) => {
        if(confirmed) {
          let newFileIds = this.certificationRequest.fileIds;
          newFileIds.splice(newFileIds.indexOf(fileId), 1);
          this.certificationRequest.fileIds = newFileIds;
          //Save this information --> inform backend of change of certification request
          //LogEntry for this change
          this.cd.detectChanges();
        }
      })
  }

  /**
   * Available only for certificating parties AND after certification request was accepted
   */
  public requestFiles() {
    this.dialogService.openFileRequestDialog("Nachricht", "Abbrechen", "Senden")
      .subscribe((requested: string) => {
        if(requested) { //Text entered by User: Which files have to be added to the request?
          //Missing: All backend-stuff & actually sending the mail.
          //Log entry
          this.dialogService.openDialog("Dateienanfrage versendet", "Der Antragssteller wird per Mail benachrichtigt.");
        }
      })
  }

  public onCommented(logText: string) {
    this.commented.emit(logText);
  }

}
