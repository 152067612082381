import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";

import { ProductService } from "../../../../services/dataServices/product-service/productService";
import { OrganisationService } from "../../../../services/dataServices/organisation-service/organisationservice";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { SidemenuButtonInterface } from "../../../../model/sidemenuButtonInterface";
import { DragulaService } from "ng2-dragula";
import { environment } from "src/environments/environment";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { SidemenuServiceService } from "src/app/services/componentServices/sidemenu-service/sidemenu-service.service";
import { ProductVariantService } from "src/app/services/dataServices/product-variant-service/product-variant.service";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { CertificationVariantService } from "src/app/services/dataServices/certification-variant-service/certification-variant-service";

@Component({
  selector: "app-sidemenu-button",
  templateUrl: "./sidemenu-button.component.html",
  styleUrls: ["./sidemenu-button.component.css"],
})
export class SidemenuButtonComponent implements OnInit, OnDestroy {
  @Input()
  link: string;

  @Input()
  label: string;

  @Input()
  routerLinkActive: boolean;

  @Input()
  routerLinkActiveOptions;

  sideMenuImageUrl: string;

  @Input()
  hasChildren: boolean;

  @Input()
  children: Array<SidemenuButtonInterface>;

  @Input()
  entityId: number;

  @Input()
  isCertification: boolean = false;

  @Input()
  ptgId: number;

  @Input()
  usecaseId: number;

  @Input()
  addButton: boolean;

  @Input()
  belongsToOrganisation: boolean;

  @Input()
  composerItem: boolean;

  @Input()
  clicked: boolean;

  @Input()
  type: string;

  @Input()
  rating: number;

  @Input()
  productVariants: any;

  @Input()
  hasNoImage: boolean = false;

  @Input() private imageId: string;

  @Output()
  onPress: EventEmitter<any> = new EventEmitter<any>();

  private activeProductSubscription: Subscription;
  private activeCertificationSubscription: Subscription;

  constructor(
    public productService: ProductService,
    public ptgService: PtgService,
    public productVariantService: ProductVariantService,
    public organisationService: OrganisationService,
    public router: Router,
    private cd: ChangeDetectorRef,
    private dragulaService: DragulaService,
    private sidemenuService: SidemenuServiceService,
    public certificationService: CertificationService,
    public certificationVariantService: CertificationVariantService
  ) {
    this.dragulaService.destroy("DRAGGABLE");
    this.dragulaService.createGroup("DRAGGABLE", {
      accepts: () => {
        return false;
      },
      revertOnSpill: true,
      copy: true,
      direction: "vertical",
    });
  }

  onClick(): void {
    this.onPress.emit();
  }

  toggleClick(): void {
    //-------------------------------------------
    if (this.ptgId) {
      this.ptgService
        .setActivePtg(this.ptgId)
        .then(() => {
          if (this.link) {
            this.router.navigateByUrl(this.link);
          }
        })
        .then(() => {
          if (!this.hasChildren) {
            let s = this.link.split("/");
            let v = parseInt(s[s.length - 1]);
            this.ptgService.setActiveVersion(v);
            this.ptgService.next();
          }
        })
        .then(() => {
          // this.sidemenuService.loadSidemenuPtgs(this.ptgService.collection);
        })
        .then();
    } else {
      if (this.entityId) {
        if (this.hasChildren) {
          if (this.isCertification) {
            this.certificationService.setActiveItem(this.entityId);
          } else {
            this.productService.setActiveItem(this.entityId);
          }
        } else {
          if (this.isCertification) {
            this.certificationVariantService.setActiveVariant(this.entityId);
          } else {
            this.productVariantService.setActiveVariant(this.entityId);
          }
        }
      }
      //-------------------------------------------
      if (this.link) {
        this.router.navigateByUrl(this.link);
      }
    }
  }

  isActive(): boolean {
    if (this.link) {
      return this.router.url.replace("/edit", "") == this.link.replace(/\s/g, "%20");
    }
  }

  ngOnInit(): void {
    if (this.isCertification) {
      this.activeCertificationSubscription = this.certificationService.activeItemObserver.subscribe(
        (x) => {
          if (!this.cd["destroyed"]) {
            this.cd.detectChanges();
          }
        }
      );
    } else {
      this.activeProductSubscription = this.productService.activeItemObserver.subscribe(
        (x) => {
          if (!this.cd["destroyed"]) {
            this.cd.detectChanges();
          }
        }
      );
    }

    this.sidemenuService.sidemenuDataObserver.subscribe((x) => {
      if (!this.cd["destroyed"]) {
        this.cd.detectChanges();
      }
    });

    //else
    if (this.link && this.link.includes("variants")) {
      this.sideMenuImageUrl = environment.defaultPictureProducts;
    } else {
      if (this.entityId) {
        if (this.isCertification) {
          this.sideMenuImageUrl = this.certificationService.getCertificationPicture(
            this.entityId
          );
        } else {
          this.sideMenuImageUrl = this.productService.getProductPicture(
            this.entityId
          );
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.isCertification) {
      this.activeCertificationSubscription.unsubscribe();
    } else {
      this.activeProductSubscription.unsubscribe();
    }
  }
}
