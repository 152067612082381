// ssh-console.component.ts

import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { SshService } from '../ssh.service';
import { ProductInterface } from "src/app/model/products/product-interface";

@Component({
  selector: 'app-ssh',
  templateUrl: './ssh-console.component.html',
  styleUrls: ['./ssh-console.component.css']
})
export class SshComponent {
  @Input() product: ProductInterface;

  username: string = '';
  pemFile: File | null = null;
  host: string = '';
  command: string = '';
  output: string = '';

  constructor(
    private sshService: SshService,     
    private cdr: ChangeDetectorRef
    ) { }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.pemFile = event.target.files[0];
    }
  }

  Bereitstellen() {
    if (!this.pemFile) {
      console.error('No PEM file selected.');
      return;
    }

    const formData = new FormData();
    formData.append('username', this.username);
    formData.append('pemFile', this.pemFile);
    formData.append('host', this.host);
    formData.append('command', "docker run ");
    formData.append('dockerimage', this.product.sApiEndpoint);
    this.sshService.executeCommand(formData)
      .subscribe(data => {
        this.output = data.output;
        this.cdr.detectChanges();
      }, error => {
        console.error(error);
        this.output = 'Error occurred while executing command.';
        this.cdr.detectChanges();
      });
      this.cdr.detectChanges();
  }

  executeCommand() {
    if (!this.pemFile) {
      console.error('No PEM file selected.');
      return;
    }
    const formData = new FormData();
    formData.append('username', this.username);
    formData.append('pemFile', this.pemFile);
    formData.append('host', this.host);
    formData.append('command', this.command);
    formData.append('dockerimage', this.product.sApiEndpoint);
    this.sshService.executeCommand(formData)
      .subscribe(data => {
        this.output = data.output;
        this.cdr.detectChanges();
      }, error => {
        console.error(error);
        this.output = 'Error occurred while executing command.';
        this.cdr.detectChanges();
      });
      this.cdr.detectChanges();
  }
}
