import { NgModule } from "@angular/core";

// Pipes
import { ConfirmedMembersPipe } from "../pipes/confirmedMembersPipe";
import { AdminConfirmationPendingPipe } from "../pipes/adminConfirmationPendingPipe";
import { UserConfirmationPendingPipe } from "../pipes/userConfirmationPendingPipe";
import { FilterTypeNoAdmin } from "../pipes/filterTypeNoAdmin";
import { FilterTypeAdmin } from "../pipes/filterTypeAdmin";
import { FilterProductsOfOrganisation } from "../pipes/filterProductsOfOrganisation";
import { FilterProductsOfPtg } from "../pipes/filterProductsOfPtg";
import { FilterProductVariantsOfPtg } from "../pipes/filterProductVariantsOfPtg";
import { FilterProductVersionsOfPtg } from "../pipes/filterProductVersionsOfPtg";
import { FilterBelongsToOrganisation } from "../pipes/filterBelongsToOrganisation";
import { FilterPtgActive } from "../pipes/filterPtgActive";
import { FilterAlreadyChosen } from "../pipes/filterAlreadyChosen";
import { FilterAlreadyChosenArray } from "../pipes/filterAlreadyChosenArray";
import { FilterVariantVersionActive } from "../pipes/filterVariantVersionActive";
import { DatatypeToStringPipe } from "../pipes/datatype-to-string.pipe";
import { MarkInactivePipe } from "../pipes/markInactive";
import { FilterAttributesOfProducts } from "./../pipes/filterAttributesOfProducts";
import { FilterRatingOfProducts } from "./../pipes/filterRatingOfProducts";
import { FilterCertificatesOfProducts } from "../pipes/filterCertificatesOfProducts";
import { FilterProductSearchPipe } from "./../pipes/filter-product-search.pipe";
import { LinkHttpPipe } from "../pipes/link-http.pipe";
import { FilterOrganisationSearchPipe } from "./../pipes/filter-organisation-search.pipe";
import { FilterItself } from "../pipes/filterItself";
import { ExcerptPipe } from "../pipes/excerpt.pipe";
import { HumanReadableTime } from "../pipes/humanReadableTime";
import { BooleanToStringPipe } from "../pipes/boolean-to-string.pipe";
import { RemoveMarkUpPipe } from "../pipes/remove-mark-up.pipe";
import { CountCharactersPipe } from "../pipes/count-characters.pipe";
import { HumanReadableDate } from '../pipes/humanReadableDate';
import { HumanReadableClockTime } from '../pipes/humanReadableClockTime';
import { FilterPipe } from '../pipes/filter';
import {FilterInstanceReferencesTemplate} from "../pipes/filter-instance-references-template";
import {SortCommonFeaturesByName} from "../pipes/sortCommonFeaturesByName";

@NgModule({
  declarations: [
    ConfirmedMembersPipe,
    AdminConfirmationPendingPipe,
    UserConfirmationPendingPipe,
    FilterTypeNoAdmin,
    FilterTypeAdmin,
    FilterProductsOfOrganisation,
    FilterProductsOfPtg,
    FilterProductVariantsOfPtg,
    FilterProductVersionsOfPtg,
    FilterBelongsToOrganisation,
    FilterPtgActive,
    FilterAlreadyChosen,
    FilterAlreadyChosenArray,
    FilterVariantVersionActive,
    DatatypeToStringPipe,
    MarkInactivePipe,
    FilterAttributesOfProducts,
    FilterRatingOfProducts,
    FilterCertificatesOfProducts,
    LinkHttpPipe,
    FilterProductSearchPipe,
    FilterOrganisationSearchPipe,
    FilterItself,
    ExcerptPipe,
    HumanReadableTime,
    HumanReadableClockTime,
    BooleanToStringPipe,
    RemoveMarkUpPipe,
    CountCharactersPipe,
    HumanReadableDate,
    FilterPipe,
    FilterInstanceReferencesTemplate,
    SortCommonFeaturesByName
  ],
  exports: [
    //Pipes
    ConfirmedMembersPipe,
    AdminConfirmationPendingPipe,
    UserConfirmationPendingPipe,
    FilterTypeNoAdmin,
    FilterTypeAdmin,
    FilterProductsOfOrganisation,
    FilterProductsOfPtg,
    FilterProductVariantsOfPtg,
    FilterProductVersionsOfPtg,
    FilterBelongsToOrganisation,
    FilterPtgActive,
    FilterAlreadyChosen,
    FilterAlreadyChosenArray,
    FilterVariantVersionActive,
    DatatypeToStringPipe,
    MarkInactivePipe,
    FilterAttributesOfProducts,
    FilterRatingOfProducts,
    FilterCertificatesOfProducts,
    LinkHttpPipe,
    FilterProductSearchPipe,
    FilterOrganisationSearchPipe,
    FilterItself,
    ExcerptPipe,
    HumanReadableTime,
    HumanReadableClockTime,
    BooleanToStringPipe,
    RemoveMarkUpPipe,
    CountCharactersPipe,
    HumanReadableDate,
    FilterPipe,
    FilterInstanceReferencesTemplate,
    SortCommonFeaturesByName
  ],
})
export class CustomPipeModule {}
