import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rating-panel',
  templateUrl: './rating-panel.component.html',
  styleUrls: ['./rating-panel.component.css']
})
export class RatingPanelComponent implements OnInit {

  constructor() { }

  @Output()
  filter: EventEmitter<any> = new EventEmitter<any>();

  filterRating(rating){
    this.filter.emit(rating);
  }

  ngOnInit() {
  }

}
