<div class="information">
  <div class="leftSide">
    <div class="itemWithLabel">
      <p>Name des TC-Item</p>
      <h2>{{ toolchainItemName }}</h2>
    </div>
    <div class="row">
      <div class="itemWithLabel">
        <p>Input TC-Item</p>
        <div class="itemList">
          <p *ngFor="let inputItem of data.inputItems">{{ inputItem.name }}</p>
        </div>
      </div>
      <div class="itemWithLabel">
        <p>Output TC-Item</p>
        <div class="itemList">
          <p *ngFor="let outputItem of data.outputItems">
            {{ outputItem.name }}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="itemWithLabel">
        <p>Input Format</p>
        <div class="itemList">
          <p>{{ data.inputItems[0].format }}</p>
        </div>
      </div>
      <div class="itemWithLabel">
        <p>Output Format</p>
        <div class="itemList">
          <p>{{ data.outputItems[0].format }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="rightSide">
    <div class="itemWithLabel">
      <p>Beschreibung</p>
      <app-hmtl-display [text]="toolchainItemDescription"></app-hmtl-display>
    </div>
  </div>
</div>
<div class="productSearch">
  <div class="leftSide">
    <div class="search-panel">
      <div class="button-search"></div>
      <mat-form-field floatLabel="never">
        <input
          matInput
          [(ngModel)]="searchText"
          type="search"
          placeholder="Suchen..."
          (keyup)="receiveSearchText()"
        />
      </mat-form-field>
    </div>
    <app-composer-product-filter-element
      filterName="Produkttypen"
      [options]="availablePTGs"
      [selected]="selectedPTGs"
      (onSelectedChange)="updateSelectedPTGs($event)"
    ></app-composer-product-filter-element>
    <app-composer-product-filter-element
      filterName="Produktanbieter"
      [options]="availableOrgas"
      [selected]="[]"
      (onSelectedChange)="updateSelectedOrgas($event)"
    ></app-composer-product-filter-element>
    <mat-checkbox
      class="checkbox"
      [checked]="onlyCertified"
      (change)="receiveCertifiedCheckboxValueChange($event)"
      >Zertifiziert</mat-checkbox
    >
  </div>
  <div class="rightSide">
    <div class="products" [ngClass]="{
      smallVersion: selectedItem !== undefined
    }">
      <mat-spinner *ngIf="changeDetected"></mat-spinner>
      <app-infinite-scroll (scrolled)="onScroll()" >
        <div class="list" *ngIf="!changeDetected">
          <ng-container *ngFor="let item of displayItem; let i = index;">
            <app-display-item [itemInfo]="item" [id]="'displayItem'  + i" (click)="updateSelectedProduct(item)"></app-display-item>
          </ng-container>
        </div>
      </app-infinite-scroll>
    </div>
    <app-composer-product-overview *ngIf="selectedItem" [productId]="selectedItem.id" (collapseOverview)="collapseProductOverview()"></app-composer-product-overview>
  </div>
</div>
<div class="buttons">
  <button mat-raised-button class="btn_green" [disabled]="!selectedItem" (click)="save()">Speichern</button>
  <button mat-raised-button class="btn_red" (click)="cancel()">Abbrechen</button>
</div>
