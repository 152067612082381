import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { Subscription } from "rxjs";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-certification-info-screen",
  templateUrl: "./certification-info-screen.component.html",
  styleUrls: ["./certification-info-screen.component.css"],
})
export class CertificationInfoScreenComponent implements OnInit {
  private activeCertificationSubscription: Subscription;

  public certificationEdit: CertificationInterface;

  constructor(
    public certificationService: CertificationService,
    public organisationService: OrganisationService,
    public router: Router,
    public route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private titleService: Title
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnInit() {
    
    this.activeCertificationSubscription = this.certificationService.activeItemObserver.subscribe(
      (cert: CertificationInterface) => {
        if (cert) {
          this.certificationEdit = cert;
          this.titleService.setTitle(this.organisationService.activeItem.organisationName + ": " + this.certificationService.activeCertification.name + " - BIMSWARM");
          this.cd.detectChanges();
        }
      }
    );
  }

  ngOnDestroy() {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
    if(this.activeCertificationSubscription){
      this.activeCertificationSubscription.unsubscribe();
    }
  }
}
