import {Pipe, PipeTransform} from '@angular/core';
import {ProductInterface} from '../model/products/product-interface';

@Pipe({name: 'filterBelongsToOrganisation'})
export class FilterBelongsToOrganisation implements PipeTransform {
  transform(products: ProductInterface[], organisationId: number): ProductInterface[] {
    if(products){
      var test = products.filter((product: ProductInterface)  => product.company === organisationId);
      return test
    }
  }
}
