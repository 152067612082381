<div class="two-columns" *ngIf="userDetails">
  <div>
    <h1>{{ dialogTitle }}</h1>
    <mat-form-field>
      <input
        matInput
        placeholder="E-Mail"
        [value]="userDetails.userName"
        disabled
      />
    </mat-form-field>
    <div class="leftColumn">
      <div class="prevent-grid-blowout">
        <mat-form-field>
          <mat-select
            placeholder="Anrede"
            [(ngModel)]="userDetails.gender"
            [disabled]="!editMode"
          >
            <mat-option value="">-</mat-option>
            <mat-option value="Herr">Herr</mat-option>
            <mat-option value="Frau">Frau</mat-option>
          </mat-select>
          <!-- <input *ngIf="!editMode" matInput placeholder="Anrede" [value]="userDetails.gender" disabled> -->
        </mat-form-field>
        <mat-form-field>
          <input
            *ngIf="editMode"
            matInput
            placeholder="Vorname"
            [(ngModel)]="userDetails.firstName"
          />
          <input
            *ngIf="!editMode"
            matInput
            placeholder="Vorname"
            [value]="userDetails.firstName"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <input
            *ngIf="editMode"
            matInput
            placeholder="Telefon Nummer"
            [(ngModel)]="userDetails.phoneNumber"
          />
          <input
            *ngIf="!editMode"
            matInput
            placeholder="Telefon Nummer"
            [value]="userDetails.phoneNumber"
            disabled
          />
        </mat-form-field>

        <mat-form-field>
          <input
            *ngIf="editMode"
            matInput
            placeholder="Arbeitgeber"
            [(ngModel)]="userDetails.employer"
          />
          <input
            *ngIf="!editMode"
            matInput
            placeholder="Arbeitgeber"
            [value]="userDetails.employer"
            disabled
          />
        </mat-form-field>
      </div>
      <div class="prevent-grid-blowout">
        <mat-form-field>
          <mat-select
            placeholder="Titel"
            [(ngModel)]="userDetails.title"
            [disabled]="!editMode"
          >
            <mat-option value="">-</mat-option>
            <mat-option value="Dr.">Dr.</mat-option>
          </mat-select>
          <!-- <input *ngIf="!editMode" matInput placeholder="Titel" [value]="userDetails.title" disabled> -->
        </mat-form-field>
        <mat-form-field>
          <input
            *ngIf="editMode"
            matInput
            placeholder="Nachname"
            [(ngModel)]="userDetails.lastName"
          />
          <input
            *ngIf="!editMode"
            matInput
            placeholder="Nachname"
            [value]="userDetails.lastName"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <input
            *ngIf="editMode"
            matInput
            placeholder="Mobile Nummer"
            [(ngModel)]="userDetails.mobileNumber"
          />
          <input
            *ngIf="!editMode"
            matInput
            placeholder="Mobile Nummer"
            [value]="userDetails.mobileNumber"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <input
            *ngIf="editMode"
            matInput
            placeholder="Webseite"
            [(ngModel)]="userDetails.website"
          />
          <input
            *ngIf="!editMode"
            matInput
            placeholder="Webseite"
            [value]="userDetails.website"
            disabled
          />
        </mat-form-field>
      </div>
    </div>
    <mat-form-field>
      <input
        *ngIf="editMode"
        matInput
        placeholder="Tätigkeit"
        [(ngModel)]="userDetails.jobDescription"
      />
      <input
        *ngIf="!editMode"
        matInput
        placeholder="Tätigkeit"
        [value]="userDetails.jobDescription"
        disabled
      />
    </mat-form-field>

    <mat-form-field *ngIf="editMode">
      <mat-label>Persönliche Beschreibung</mat-label>
      <textarea matInput placeholder="Persönliche Beschreibung" rows="5" [(ngModel)]="userDetails.aboutMe"></textarea>
    </mat-form-field>

    <mat-form-field *ngIf="!editMode">
      <mat-label>Persönliche Beschreibung</mat-label>
      <textarea matInput placeholder="Persönliche Beschreibung" rows="5" [value]="userDetails.aboutMe" disabled></textarea>
    </mat-form-field>

    <mat-slide-toggle [disabled]="!editMode" [(ngModel)]="userDetails.hasTrackingConsent">Cookies für die Statistik</mat-slide-toggle>

    <div class="leftColumn">
      <div class="prevent-grid-blowout">
        <button
          *ngIf="editMode"
          mat-raised-button
          class="btn_green"
          (click)="onConfirmPress()"
        >
          Speichern
        </button>
      </div>
      <div class="prevent-grid-blowout">
        <button
          *ngIf="editMode"
          mat-raised-button
          class="btn_red"
          (click)="onEditPress()"
        >
          Abbrechen
        </button>
      </div>
    </div>

    <div class="titlep">
      <h3>Projekte</h3>
    </div>
    <div class="column" *ngIf="userProjects.length==0" else #userHasProjects>
      <p>-</p>
    </div>
    <div #userHasProjects class="column" *ngFor="let project of userProjects">
      <p *ngIf="editMode">{{ project.projectName }}</p>
      <p *ngIf="!editMode" style="color: rgba(0, 0, 0, 0.38)">
        {{ project.projectName }}
      </p>

      <div *ngIf="editMode">
        <app-hexagon-button-text-icon style="float: right;"
          [text]="'Entfernen'"
          [icon]="'/assets/img/icons/discard.png'"
          [width]="1.5"
          (click)="onRemoveProject(project.id)"
        >
        </app-hexagon-button-text-icon>
      </div>
    </div>
    <button
    mat-raised-button
    class="btn_green"
    *ngIf="editMode" (click)="editModeProjects = !editModeProjects">
      Neues Projekt Hinzufügen
    </button>
    <div class="newProject" *ngIf="editModeProjects">
      <mat-form-field appearance="legacy">
        <mat-label>Projektname</mat-label>
        <input
          matInput
          maxlength="25"
          [(ngModel)]="newProjectName"
        />
        <span matSuffix style="position: relative; bottom: 8px;">
          <button
            mat-raised-button
            class="btn_green"
            (click)="onAddProject(newProjectName)"
          >
            Hinzufügen
          </button>
        </span>
      </mat-form-field>
    </div>
  </div>

  <div class="right-column">
    <div
      [ngClass]="editMode ? 'profile-picture-edit-mode' : 'profile-picture'"
      (click)="editMode ? showFileUpload() : $event.stopPropagation()"
    >
      <img
        *ngIf="userDetails.fileId == null; else picture"
        src="{{ getDefaultProfileImageUrl() }}"
        alt="Profilbild"
        width="100%"
        height="100%"
      />
      <ng-template #picture>
        <app-secure-image
          [src]="getDefaultProfileImageUrl()"
          [fileId]="userDetails.fileId"
          [contain]="true"
          [isProfilPic]="true"
        ></app-secure-image>
      </ng-template>
    </div>
    <div *ngIf="editMode && userDetails.fileId">
      <button mat-icon-button (click)="resetProfilePicture()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <!--Beispiele für den direkten Einbau des FM
       <h2>SingleSelect[filter PDF]</h2>
    <filemanager
      (selectedFileChanged)="fileChanged($event,'SiSe')"
      [multiSelectMode]=false
      [uploadDisabled]="true"
      filter="application/pdf"
    ></filemanager>
    <h2>MultiSelect</h2>
    <filemanager
      (selectedFileChanged)="fileChanged($event,'MuSe')"
      [multiSelectMode]="true"
      [uploadDisabled]="true"
    ></filemanager>
    <h2>MultiSelect & SingleUpload [filter PDF und PNG]</h2>
    <filemanager
      (selectedFileChanged)="fileChanged($event,'MuSeSiUp')"
      [multiSelectMode]="true"
      [uploadDisabled]="false"
      [uploadModeSingle]="true"
      filter="application/pdf image/png"
    ></filemanager>
    <h2>MultiSelect & MultiUpload</h2>
    <filemanager
      (selectedFileChanged)="fileChanged($event,'MuSeMuUp')"
      [multiSelectMode]="true"
      [uploadDisabled]="false"
    ></filemanager>
    <h2>MultiSelect & MultiUpload & DescriptionEditor</h2>
    <filemanager
      (selectedFileChanged)="fileChanged($event,'MuSeMuUpDeEd')"
      [multiSelectMode]="true"
      [fileEditEnabled]="true"
    ></filemanager> -->
  </div>
  <div class="leftColumn">
    <div class="prevent-grid-blowout">
      <button
        *ngIf="!editMode"
        mat-raised-button
        class="btn_green"
        (click)="onEditPress()"
      >
        Bearbeiten
      </button>
    </div>
  </div>
</div>
