import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { CertificationVariantService } from "src/app/services/dataServices/certification-variant-service/certification-variant-service";
import { CertificationVariantVersionService } from "src/app/services/dataServices/cetrification-variant-version-service/certification-variant-version-service";
import { Router, ActivatedRoute } from "@angular/router";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { AttributeValidationServiceService } from "src/app/services/dataServices/dataValidation/attribute-validation-service/attribute-validation-service.service";
import { UtilService } from 'src/app/services/util-service/utilService';
import { UserService } from 'src/app/services/user-service/userservice';
import { runInThisContext } from 'vm';

@Component({
  selector: "app-create-certification-version",
  templateUrl: "./create-certification-version.component.html",
  styleUrls: ["./create-certification-version.component.css"],
})
export class CreateCertificationVersionScreenComponent
  implements OnInit {
  public version: CertificationVariantVersionInterface;

  public isCreateNew = false;
  public isFormValid = false;

  constructor(
    private organisationService: OrganisationService,
    private certificationService: CertificationService,
    public certificationVariantService: CertificationVariantService,
    private certificationVariantVersionService: CertificationVariantVersionService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    public attributeValidationService: AttributeValidationServiceService,
    private userService: UserService,
    private cd: ChangeDetectorRef
  ) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
      
    }
  }

  ngOnInit(): void {
    this.version = {};
    // set flag for create or edit mode
    this.isCreateNew = this.router.url.includes("create");
    if (this.isCreateNew) {
      this.route.paramMap.subscribe((x) => {
        if (x.get("previous")) {
          var previousVersionId = parseInt(x.get("previous"));
          this.version.previousVersion = previousVersionId;
        }
      });
    } else {
      const currentVersion = this.certificationVariantVersionService.activeItem;
      this.version.id = currentVersion.id;
      this.version.certificationVariantId = currentVersion.certificationVariantId;
      if(this.version.certificationVariantId == null)
      {
        this.version.certificationVariantId = Number(this.certificationVariantService.ITEM_MAP[":certificationVariantId"]);
      }
      this.version.validity = currentVersion.validity;
      this.version.firstReminder = currentVersion.firstReminder;
      this.version.secondReminder = currentVersion.secondReminder;
      this.version.thirdReminder = currentVersion.thirdReminder;
    }
  }

  cancelCreateVersion(): void {
    this.router.navigate([
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/certifications/" +
        this.certificationService.activeItem.id +
        "/variants/" +
        this.certificationVariantService.activeItem.id,
    ]);
  }

  createVersion(): void {
    // if at least one attribute not valid
    if (!this.isInputValid()) {
      this.dialogService.openDialog(
        "Speichern Fehlgeschlagen",
        "Bitte validieren sie alle Attribute!"
      );
      return;
    }

    // if this version has either no name or status (active / inactive)
    if (!this.isFormValid) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte geben Sie den Versionsnamen und -status an!"
      );
      return;
    }

    if(!UtilService.areStringsValid(this.version.name, this.version.description)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Der Name oder die Beschreibung sind zu lang."
      );
      return;
    }

    if (this.version.description == null) {
      this.version.description = "";
    }

    this.version.attributeValidationValues = this.attributeValidationService.getAttributeValidationValues();

    if (this.isCreateNew) {
      this.certificationVariantVersionService
        .postItem(this.version)
        .then((v) => {
          let url = this.router.url.split("/version/")[0];
          this.router.navigateByUrl(url);
          this.dialogService.openDialog(
            "Version erstellt",
            "Die Version " + v.name + " wurde erfolgreich erstellt."
          );
        })
        .catch((e) => {
          this.dialogService.openDialog(e, e);
        });
    } else {
      // else we put update
      
      this.certificationVariantVersionService
        .putItem(this.version)
        .then((v) => {
          let url = this.router.url.split("/version/")[0];
          this.router.navigateByUrl(url);
          this.dialogService.openDialog(
            "Version bearbeitet",
            "Die Version " + v.name + " wurde erfolgreich bearbeitet."
          );
        })
        .catch(() => {
          this.version.status = this.certificationVariantVersionService.activeItem.status;
          this.version.ptgVersions = [];
          this.cd.detectChanges();
        });
    }
  }

  isInputValid(): boolean {
    return this.attributeValidationService.checkAllEntityAttributeValuesValidated();
  }

  public onValidityChanged(valid: boolean) {
    this.isFormValid = valid;
  }
}
