<div class="container">
  <div #canvas class="diagram-container" id="canvas">
    <mat-button-toggle-group
      class="modelEditorToggleButtons"
      value="myProducts"
      (change)="toggleButtonChange($event)"
    >
      <mat-button-toggle value="myProducts">Produktfavoriten</mat-button-toggle>
      <mat-button-toggle value="organisations">Anbieter</mat-button-toggle
      >
    </mat-button-toggle-group>
    <mat-form-field
      *ngIf="!showMyProducts"
      appearance="fill"
      class="organisationSelect"
    >
      <mat-label>Anbieter</mat-label>
      <mat-select
        [value]="selectedOrganisation"
        (selectionChange)="changedSelectedOrganisation($event)"
      >
        <mat-option *ngFor="let membership of availableProductOrganisations" [value]="membership">{{
          membership.organisationName
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="diagram-properties-panel" id="propertiesPanel"></div>
</div>
