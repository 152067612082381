<div class="centered">
  <div class="title">
    <p>
      {{ logEntry.timestamp | humanReadableTime }}
    </p>
  </div> 
  <div class="border"></div>
</div>
<div class="logHeader">
  {{ logEntry.changedBy }} {{ logEntry.timestamp.split(" ")[1] | humanReadableClockTime }}
</div>
<div class="logText">
  {{ logEntry.logEntryText }}
</div>
