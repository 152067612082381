<div class="actionButtons">
  <button mat-raised-button class="btn_green" (click)="goToOverviewPage()">Übersicht</button>
  <button mat-raised-button class="btn_green" (click)="saveToolchain()">Speichern</button>
  <button mat-raised-button class="btn_green" (click)="deploy()">Bereitstellen</button>
  <h3 *ngIf="validToolchain" class="valid">Valide Dienstkette</h3>
  <h3 *ngIf="!validToolchain" class="invalid">Invalide Dienstkette</h3>
</div>
<app-model-editor
  *ngIf="xml && xmlFileIds"
  [xml]="xml"
  [fileIdsForXML]="xmlFileIds"
  (validityChanged)="changeToolchainValidity($event)"
></app-model-editor>
