<div>
  <b>
    <p>Produkttypen</p>
  </b>
  <table>
    <tbody>
      <tr *ngFor="let ptgVersion of version.ptgVersions" class="not">
        <td>
          <p>{{ ptgVersion.ptgName }}</p>
        </td>
        <td>
          <mat-form-field class="fullWidth">
            <mat-select
              placeholder="Version"
              (selectionChange)="editThisPtgVersion(ptgVersion, $event)"
              [value]="ptgVersion.id"
            >
              <mat-option
                *ngFor="
                  let version of getAllActivePtgVersions(ptgVersion)
                "
                [value]="version.id"
              >
                {{ version.number }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
          <red-cross-button
            (click)="removePtgVersion(ptgVersion)"
            class="red-square-cross-button"
          >
          </red-cross-button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
