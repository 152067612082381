import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user-service/userservice';
import { StartUpService } from '../start-up-service/start-up.service';

@Injectable({
  providedIn: 'root'
})
export class AnonGuard implements CanActivate {
  constructor(private startupService : StartUpService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if(!this.startupService.isUserLoggedIn()) {
      this.router.navigateByUrl("/marktplatz")
      return false
    }
    return true;
  }
}
