<div class="container" (click)="logout()">
  <div *ngIf="!labelBelow" class="title above">
    <p class="prevent-selection">{{ label }}</p>
  </div>
  <div class="hexagon-wrapper">
    <hexagon
      [img]="img"
      [icon]="icon"
      [hover]="!noHover"
      [outerColor]="'white'"
      [middleColor]="'green'"
      [innerColor]="'white'"
      [imageId]="imageId"
    >
    </hexagon>
  </div>
  <div *ngIf="labelBelow" class="title below">
    <p>
      {{ label }}
    </p>
  </div>
</div>
