import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Status } from 'src/app/model/enums/status';
import { PtgInterface } from "src/app/model/ptgs/ptg-interface";
import { PtgVersionInterface } from "src/app/model/ptgs/ptg-version-interface";
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { UserService } from "src/app/services/user-service/userservice";

const SWARM_API = "/proxy/api/v0/productservice";
const httpOptions = { 
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
}


@Component({
  selector: "app-ptg-overview",
  templateUrl: "./ptg-overview.component.html",
  styleUrls: ["./ptg-overview.component.css", "../../../app.component.css"],
})
export class PtgOverviewComponent implements OnInit {
  public ptg: PtgInterface;
  public versions: PtgVersionInterface[];
  public version: PtgVersionInterface;
  public activeVersions: PtgVersionInterface[];
  private URI_ORGANISATION: string = "/organisations/";
  private URI_PTGS: string = "/productTypeGroups/";
  private URI_VERSIONS: string = "/versions/";
  private URI_EDIT: string = "/edit";

  constructor(
    public ptgService: PtgService,
    public organisationService: OrganisationService,
    public router: Router,
    private activeRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private titleService: Title,
    private dialogService: DialogService,
    private http: HttpClient
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnInit(): void {
    this.ptgService.activeItemObserver.subscribe((x) => {
      this.ptg = this.ptgService.activeItem;
      this.versions = this.ptg.versions;
      // This checks if we are navigating the ptg version page or the ptg page
      //-------------------------------
      this.titleService.setTitle(this.organisationService.activeItem.organisationName + ": " + this.ptgService.activeItem.name + " - BIMSWARM");

      let versionId = parseInt(
        this.activeRoute.snapshot.paramMap.get("versionId")
      );
      if (versionId) {
        this.version = this.ptgService.activeVersion;
        this.titleService.setTitle(this.ptgService.activeItem.name + " Version " + versionId + " - BIMSWARM");
      } else {
        this.version = null;
      }
      if (!this.cd["destroyed"]) {
        this.cd.detectChanges();
      }
    });

    this.activeVersions = this.versions.filter(
      (version: PtgVersionInterface) => {
        return version.status === Status.ACTIVE;
      }
    );
    this.cd.detectChanges();
  }

  ngOnDestroy()
  {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }
  editPtg(ptgId: number): void {
    let URI: string =
      this.URI_ORGANISATION +
      this.organisationService.activeItem.organisationId +
      this.URI_PTGS +
      ptgId +
      this.URI_EDIT;
    this.router.navigate([URI]);
  }

  deletePtg(ptgId: number): void {
      this.dialogService.openConfirmDialog("Wollen Sie diesen Produkttypen wirklich löschen?", "Abbrechen", "Löschen")
      .subscribe((confirmed: boolean) => { 
        if(confirmed)
        {
          this.http.post(SWARM_API + "/producttypegroups/delete/" + ptgId + "/" + this.organisationService.activeItem.organisationId, httpOptions).subscribe((confirmation) => {
            if(confirmation)
              this.dialogService.openDialog("Produkttyp wurde gelöscht", "");
          });
        }
      });
  }

  editVersion(ptgId: number, versionId: number): void {
    let URI: string =
      this.URI_ORGANISATION +
      this.organisationService.activeItem.organisationId +
      this.URI_PTGS +
      ptgId +
      this.URI_VERSIONS +
      versionId +
      this.URI_EDIT;
    this.router.navigate([URI]);
  }

  deleteVersion(ptgId: number, versionId: number): void {
    this.dialogService.openConfirmDialog("Wollen Sie diese Produkttypenversion wirklich löschen?", "Abbrechen", "Löschen")
    .subscribe((confirmed: boolean) => { 
      if(confirmed)
      {
        this.http.post(SWARM_API + "/producttypegroups/deleteVersion/" + ptgId + "/" + versionId + "/" + this.organisationService.activeItem.organisationId, httpOptions).subscribe((confirmation) => {
          if(confirmation)
            this.dialogService.openDialog("Produkttypversion wurde gelöscht", "");
        });
      }
    });
  }

  isUserAdminOfOrganisationOrPlattformAdmin() {
    return this.userService.isUserAdminOfOrganisationOrPlattformAdmin(
      this.organisationService.activeItem.organisationId
    );
  }
}
