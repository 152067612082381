import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RequestInterface } from "src/app/model/marketplace/request-interface";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { ProductService } from "src/app/services/dataServices/product-service/productService";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";

import { UserService } from "src/app/services/user-service/userservice";
import { GENDERS, VALIDATOR_EXP } from "src/environments/constants";

const letterRegex = new RegExp(VALIDATOR_EXP.CONTAINS_LETTER);
const numberRegex = new RegExp(VALIDATOR_EXP.CONTAINS_NUMBER);
const specialCharRegex = new RegExp(VALIDATOR_EXP.CONTAINS_SPECIAL_CHAR);

function containsLettersButNoNumbers(control: FormControl): ValidationErrors {
  if (
    control.value !== "" &&
    (!letterRegex.test(control.value) || numberRegex.test(control.value))
  ) {
    return { containsNoLettersOrContainsNumbers: true };
  }
}

function containsAtLeastLettersAndSpecialChars(
  control: FormControl
): ValidationErrors {
  if (
    control.value !== "" &&
    (!letterRegex.test(control.value) || !specialCharRegex.test(control.value))
  ) {
    return { doesNotContainLettersAndSpecialChars: true };
  }
}

function containsNumbersButNoLetters(control: FormControl): ValidationErrors {
  if (
    control.value !== "" &&
    (letterRegex.test(control.value) || !numberRegex.test(control.value))
  ) {
    return { containsNotOnlyNumbersAndSpecialChars: true };
  }
}

@Component({
  selector: "app-dialog-certRequest",
  templateUrl: "./dialog-request.component.html",
  styleUrls: [
    "./dialog-request.component.css",
    "../dialog.css",
  ],
})
export class DialogCertRequestComponent implements OnInit {
  ngOnInit() {}

  demoRequestForm: FormGroup;

  subject: AbstractControl;
  firstname: AbstractControl;
  lastname: AbstractControl;
  email: AbstractControl;
  phoneNumber: AbstractControl;
  company: AbstractControl;
  url: AbstractControl;
  gender: AbstractControl;
  request: AbstractControl;
  baustellenAdresse: AbstractControl;
  anzahlNutzer: AbstractControl;
  startDatum: AbstractControl;
  endDatum: AbstractControl;
  productName: string;
  productId: number;
  companyId: number;
  contactEmail: string;
  type: RequestType;

  showForm: boolean = true;
  showDemoSuccessMessage = false;
  showOfferSuccessMessage = false;
  showListingSuccessMessage = false;
  showErrorMessage = false;
  showNoContactMail = false;
  showWaitForResult = false;

  public genders = GENDERS;

  constructor(
    public dialogRef: MatDialogRef<DialogCertRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private formbuilder: FormBuilder,
    private productService: ProductService,
    private organisationService: OrganisationService,
    private certificationService: CertificationService
  ) {
    this.initialiseContactForm();
    this.subject = this.demoRequestForm.controls["subject"];
    this.firstname = this.demoRequestForm.controls["firstname"];
    this.lastname = this.demoRequestForm.controls["lastname"];
    this.email = this.demoRequestForm.controls["email"];
    this.phoneNumber = this.demoRequestForm.controls["phoneNumber"];
    this.company = this.demoRequestForm.controls["company"];
    this.url = this.demoRequestForm.controls["url"];
    this.gender = this.demoRequestForm.controls["gender"];
    this.request = this.demoRequestForm.controls["request"];
    this.baustellenAdresse = this.demoRequestForm.controls["baustellenAdresse"];
    this.anzahlNutzer = this.demoRequestForm.controls["anzahlNutzer"];
    this.startDatum = this.demoRequestForm.controls["startDatum"];
    this.endDatum = this.demoRequestForm.controls["endDatum"];
    this.type = this.data["type"];

    this.certificationService.getCertification(this.data["productId"]).then((product) => {
      this.productName = product.name;
      this.productId = product.id;
      this.companyId = product.company;
      this.organisationService
        .getOrganisation(product.company)
        .then((organisation) => {
          this.contactEmail =
            organisation.contacts.length > 0
              ? organisation.contacts[0].email
              : null;
        });
    });
  }
/** eventhandler falls auf senden gedrueckt wird */
  onSubmit(): void {
    if (
      this.firstname.valid &&
      this.lastname.valid &&
      this.email.valid &&
      this.request.valid
    ) {
      this.showForm = false;
      this.showWaitForResult = true;

      if (this.contactEmail !== null) {
 if (this.type === RequestType.OFFER) {

          this.certificationService
            .sendOfferRequest(this.buildRequestDto())
            .subscribe(
              () => {
                this.showWaitForResult = false;
                this.showOfferSuccessMessage = true;
              },
              () => {
                this.showWaitForResult = false;
                this.showErrorMessage = true;
              }
            );
        }
      } else {
        this.showWaitForResult = false;
        this.showNoContactMail = true;
      }
    }
  }
/** Eingaben des useres werden transformiert */
  buildRequestDto(): RequestInterface {
    let contactEmailArr = new Array(this.contactEmail);
    return {
      firstName: this.firstname.value,
      lastName: this.lastname.value,
      titel: this.gender.value,
      email: this.email.value,
      phoneNumber: this.phoneNumber.value ? this.phoneNumber.value : undefined,
      company: this.company.value ? this.company.value : undefined,
      url: this.url.value ? this.url.value : undefined,
      request: this.request.value,
      contactEmail: contactEmailArr,
      productName: this.productName,
      productId: this.productId,
      companyId: this.companyId,
      anzahlNutzer: this.anzahlNutzer.value,
      startDatum: this.startDatum.value,
      endDatum: this.endDatum.value,
      baustellenAdresse: this.baustellenAdresse.value
    };
  }
  /** anfangszustand des dialogfensters / Formelemente des dialogfensters **/
  initialiseContactForm(): void {
    this.demoRequestForm = this.formbuilder.group({
      subject: [""],
      firstname: [
        "",
        Validators.compose([
          Validators.required,
          containsLettersButNoNumbers.bind(this.firstname),
        ]),
      ],
      lastname: [
        "",
        Validators.compose([
          Validators.required,
          containsLettersButNoNumbers.bind(this.lastname),
        ]),
      ],
      email: [
        "",
        Validators.compose([
          Validators.required,
          containsAtLeastLettersAndSpecialChars.bind(this.email),
        ]),
      ],
      phoneNumber: ["", containsNumbersButNoLetters.bind(this.phoneNumber)],
      company: [""],
      url: ["", containsAtLeastLettersAndSpecialChars.bind(this.url)],
      gender: [""],
      request: ["", Validators.required],
      
    });
  }
/** eventhandler */
  cancel(): void {
    this.dialogRef.close();
  }
}

export enum RequestType {
  DEMO,
  OFFER,
}
