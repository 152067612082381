import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { Injectable, OnInit } from '@angular/core';
import { CertificationRequestInterface } from 'src/app/model/certifications/certification-request-interface';
import { CertificationRequestLogInterface } from 'src/app/model/certifications/certification-request-log-interface';
import { CertificationRequestStatus } from 'src/app/model/enums/certification-requests-status';

@Injectable()

export class CertificationRequestService {

  private certificationRequests: CertificationRequestInterface[] = [];

  constructor(
    private organisationService: OrganisationService,
  ) {
    this.initialize();
   }

  initialize() {
    //Generate some dummy-data that will later be replaced by data from backend
    let dummyCertificationRequest: CertificationRequestInterface = {
      id: 1,
      requestingOrganisationId: 48,
      requestingOrganisationName: "Produktanbieter",
      productName: "Ticketprodukt",
      productVariant: "Var1",
      productVersion: "V1",
      certificationCategory: "Merkmalszertifizierung",
      certificationType: "Erstzertifizierung",
      attribute: "Merkmal1",
      status: CertificationRequestStatus.REQUESTED,
    }
    this.updateRequestAndAddLogEntry(dummyCertificationRequest, "Zertifizierungsantrag wurde erstellt.")
      .then((certificationRequest: CertificationRequestInterface) => {
        this.certificationRequests.push(dummyCertificationRequest);  
      })
    //Create second example: Status "In Bearbeitung"
    let secondDummy: CertificationRequestInterface = {
      id: 2,
      requestingOrganisationId: 48,
      requestingOrganisationName: "Produktanbieter",
      productName: "Ticketprodukt",
      productVariant: "Var1",
      productVersion: "V1",
      certificationCategory: "Merkmalszertifizierung",
      certificationType: "Erstzertifizierung",
      attribute: "Merkmal1",
      status: CertificationRequestStatus.IN_PROGRESS,
    };
    this.updateRequestAndAddLogEntry(secondDummy, "Zertifizierungsantrag wurde erstellt.")
    .then((certificationRequest: CertificationRequestInterface) => {
      this.certificationRequests.push(secondDummy);  
    })
  }

  public getCertificationRequestById(requestId: number) : Promise<CertificationRequestInterface> {
    return new Promise(resolve => {
      resolve(this.certificationRequests.find((x) => {
        return x.id === requestId;
        })
      )});
  }

  public getCertificationRequestsByOrganisation(organisationId: number) : Promise<CertificationRequestInterface[]> {
    return new Promise(resolve => {
      resolve(this.certificationRequests);
    })
  }

  /**
   * Receives updated certification request data, every change has to be connected to a log entry. Add new log entry here, send to backend
   * Backend: Adds timestamp to logEntry and returns the updated CertificationRequestInterface (with correct log containing the new entry)
   * Returns: Promise of CertificationRequestInterface. Make sure to update in components
   * @param certificationRequest updated CertificationRequestInterface
   * @param logText Should describe change (in german), will be displayed in CertificationRequestLog
   */
  public updateRequestAndAddLogEntry(certificationRequest: CertificationRequestInterface, logText: string) : Promise<CertificationRequestInterface> {
    return new Promise(resolve => {
        let updatedCertificationRequest: CertificationRequestInterface = certificationRequest;
        let newLog: CertificationRequestLogInterface[] = [];    
        if(certificationRequest.certificationRequestLog) {
          newLog = certificationRequest.certificationRequestLog;
        }
        let newLogEntry: CertificationRequestLogInterface = {};
        newLogEntry.changedBy = this.organisationService.activeItem.organisationName;
        newLogEntry.changedById = this.organisationService.activeItem.organisationId;
        newLogEntry.logEntryText = logText;
        //Omit the dummy timestamp as soon as a valid one is available from updating the requests in backend
        newLogEntry.timestamp = "2021-02-10 09:15:23.768";
        newLog.push(newLogEntry);
        updatedCertificationRequest.certificationRequestLog = newLog;
    
        //return the updated request from backend, manually setting timestamp & history can then be deleted
        resolve(updatedCertificationRequest);
    })
  }

  public createCertificationRequest(certificationRequest: CertificationRequestInterface) {
    certificationRequest.status = CertificationRequestStatus.REQUESTED;
    let newLog: CertificationRequestLogInterface[] = [];    
    let newLogEntry: CertificationRequestLogInterface = {};
    newLogEntry.changedBy = this.organisationService.activeItem.organisationName;
    newLogEntry.changedById = this.organisationService.activeItem.organisationId;
    newLogEntry.logEntryText = "Zertifizierungsantrag wurde erstellt.";
    //Omit the dummy timestamp as soon as a valid one is available from updating the requests in backend
    newLogEntry.timestamp = "2021-02-10 09:15:23.768";
    newLog.push(newLogEntry);
    certificationRequest.id = 4; //This also has to be generated in backend later
    certificationRequest.certificationRequestLog = newLog;
    this.certificationRequests.push(certificationRequest);
  }
}
