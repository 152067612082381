import { Directive, HostListener, Input, ElementRef } from "@angular/core";

@Directive({
  selector: "[appDigitOnly]",
})
export class DigitOnlyDirective {
  private navigationKeys = [
    "Backspace",
    "Delete",
    "Tab",
    "Escape",
    "Enter",
    "Home",
    "End",
    "ArrowLeft",
    "ArrowRight",
    "Clear",
    "Copy",
    "Paste",
  ];

  @Input() allowNegative = false;
  @Input() allowComma = false;
  private inputElement: HTMLInputElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.key === "a" && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === "c" && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === "v" && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === "x" && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === "a" && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === "c" && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === "v" && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === "x" && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }
    if (e.key == " ") {
      e.preventDefault();
    }
    if (e.key != "-" && e.key != "," && isNaN(Number(e.key))) {
      e.preventDefault();
    }
    if (
      e.key == "-" &&
      (!this.allowNegative || this.inputElement.value.indexOf("-") != -1)
    ) {
      e.preventDefault();
    }
    if (
      e.key == "," &&
      (!this.allowComma || this.inputElement.value.indexOf(",") != -1)
    ) {
      e.preventDefault();
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    const pastedInput: string = event.clipboardData.getData("text/plain");
    this.pasteData(pastedInput);
    event.preventDefault();
  }

  @HostListener("drop", ["$event"])
  onDrop(event: DragEvent) {
    const textData = event.dataTransfer.getData("text");
    this.inputElement.focus();
    this.pasteData(textData);
    event.preventDefault();
  }

  private pasteData(pastedContent: string): void {
    const sanitizedContent = this.sanitizeInput(pastedContent);
    const pasted = document.execCommand("insertText", false, sanitizedContent);
    if (!pasted) {
      const { selectionStart: start, selectionEnd: end } = this.inputElement;
      this.inputElement.setRangeText(sanitizedContent, start, end, "end");
    }
  }

  private sanitizeInput(input: string): string {
    let result = input.replace(/[^0-9-,]/g, "");
    if (this.inputElement.value.indexOf("-") != -1 || !this.allowNegative) {
      result = result.replace("-", "");
    }
    if (this.inputElement.value.indexOf(",") != -1 || !this.allowComma) {
      result = result.replace(",", "");
    }
    const maxLength = this.inputElement.maxLength;
    if (maxLength > 0) {
      // the input element has maxLength limit
      const allowedLength = maxLength - this.inputElement.value.length;
      result = allowedLength > 0 ? result.substring(0, allowedLength) : "";
    }
    return result;
  }
}
