<h1 mat-dialog-title>Mitglied entfernen</h1>
<div mat-dialog-content>
  <p class="dialog-text">
    Soll das Mitglied {{ data.username }} wirklich entfernt werden?
  </p>
</div>
<div>
  <h3>Begründung:</h3>
  <textarea
    [(ngModel)]="message"
    placeholder="Begründung für das Entfernen"
  ></textarea>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onClick(true)" class="btn_green" cdkFocusInitial>
    Bestätigen
  </button>
  <button mat-button (click)="onClick(false)" class="btn_red" cdkFocusInitial>
    Abbrechen
  </button>
</div>
