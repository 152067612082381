import {Component, Inject} from "@angular/core";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators,} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RequestInterface} from "src/app/model/marketplace/request-interface";
import {OrganisationService} from "src/app/services/dataServices/organisation-service/organisationservice";
import {ProductService} from "src/app/services/dataServices/product-service/productService";
import {GENDERS, VALIDATOR_EXP} from "src/environments/constants";
import {RequestType} from "src/app/model/enums/request-type";
import {ListingRequestInterface} from "../../../model/ListingRequestInterface";
import {DialogService} from "../../../services/dialog-service/dialog-service.service";

const letterRegex = new RegExp(VALIDATOR_EXP.CONTAINS_LETTER);
const numberRegex = new RegExp(VALIDATOR_EXP.CONTAINS_NUMBER);
const specialCharRegex = new RegExp(VALIDATOR_EXP.CONTAINS_SPECIAL_CHAR);


function containsLettersButNoNumbers(control: FormControl): ValidationErrors {
  if (
    control.value !== "" &&
    (!letterRegex.test(control.value) || numberRegex.test(control.value))
  ) {
    return { containsNoLettersOrContainsNumbers: true };
  }
}

function containsAtLeastLettersAndSpecialChars(
  control: FormControl
): ValidationErrors {
  if (
    control.value !== "" &&
    (!letterRegex.test(control.value) || !specialCharRegex.test(control.value))
  ) {
    return { doesNotContainLettersAndSpecialChars: true };
  }
}

function containsNumbersButNoLetters(control: FormControl): ValidationErrors {
  if (
    control.value !== "" &&
    (letterRegex.test(control.value) || !numberRegex.test(control.value))
  ) {
    return { containsNotOnlyNumbersAndSpecialChars: true };
  }
}

@Component({
  selector: "app-dialog-request",
  templateUrl: "./dialog-request.component.html",
  styleUrls: [
    "./dialog-request.component.css",
    "../dialog.css",
  ],
})
export class DialogRequestComponent {

  REQUEST_TYPE_LISTING: RequestType = RequestType.LISTING;
  demoRequestForm: FormGroup;

  subject: AbstractControl;
  firstname: AbstractControl;
  lastname: AbstractControl;
  email: AbstractControl;
  phoneNumber: AbstractControl;
  company: AbstractControl;
  url: AbstractControl;
  gender: AbstractControl;
  request: AbstractControl;
  products: AbstractControl;
  baustellenAdresse: AbstractControl;
  anzahlNutzer: AbstractControl;
  startDatum: AbstractControl;
  endDatum: AbstractControl;

  productName: string;
  productId: number;
  companyId: number;
  contactEmail: string[];
  type: RequestType;
  openAGB: any;
  openPrivacy: any;

  showForm: boolean = true;
  showDemoSuccessMessage = false;
  showOfferSuccessMessage = false;
  showListingSuccessMessage = false;
  showErrorMessage = false;
  showNoContactMail = false;
  showWaitForResult = false;

  public genders = GENDERS;

  constructor(
    public dialogRef: MatDialogRef<DialogRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private formbuilder: FormBuilder,
    private productService: ProductService,
    private organisationService: OrganisationService,
  ) {
    this.initialiseContactForm();
    this.subject = this.demoRequestForm.controls["subject"];
    this.firstname = this.demoRequestForm.controls["firstname"];
    this.lastname = this.demoRequestForm.controls["lastname"];
    this.email = this.demoRequestForm.controls["email"];
    this.phoneNumber = this.demoRequestForm.controls["phoneNumber"];
    this.company = this.demoRequestForm.controls["company"];
    this.url = this.demoRequestForm.controls["url"];
    this.gender = this.demoRequestForm.controls["gender"];
    this.request = this.demoRequestForm.controls["request"];
    this.products = this.demoRequestForm.controls["products"];

    this.baustellenAdresse = this.demoRequestForm.controls["baustellenAdresse"];
    this.anzahlNutzer = this.demoRequestForm.controls["anzahlNutzer"];
    this.startDatum = this.demoRequestForm.controls["startDatum"];
    this.endDatum = this.demoRequestForm.controls["endDatum"];


    this.type = this.data["type"];
    this.openAGB = this.data["openAgb"];
    this.openPrivacy = this.data["openPrivacy"];
    if (this.data["productId"]) {
      this.productService.getProduct(this.data["productId"]).then((product) => {
        this.productName = product.name;
        this.productId = product.id;
        this.companyId = product.company;
        this.organisationService
          .getOrganisation(product.company)
          .then((organisation) => {
            this.contactEmail = null;
            if (organisation.contacts.length > 0) {
              this.contactEmail = [];
              for (let contact of organisation.contacts) {
                this.contactEmail = this.contactEmail.concat(contact.email);
              }
            }
          });
      });
    }
  }

  onSubmit(): void {
    if (
      this.firstname.valid &&
      this.lastname.valid &&
      this.email.valid &&
      this.request.valid
    ) {
      this.showForm = false;
      this.showWaitForResult = true;

      if (this.contactEmail !== null) {
        if (this.type === RequestType.DEMO) {
          this.productService.sendDemoRequest(this.buildRequestDto()).subscribe(
            () => {
              this.showWaitForResult = false;
              this.showDemoSuccessMessage = true;
            },
            () => {
              this.showWaitForResult = false;
              this.showErrorMessage = true;
            }
          );
        } else if (this.type === RequestType.OFFER) {
          this.productService
            .sendOfferRequest(this.buildRequestDto())
            .subscribe(
              () => {
                this.showWaitForResult = false;
                this.showOfferSuccessMessage = true;
              },
              () => {
                this.showWaitForResult = false;
                this.showErrorMessage = true;
              }
            );
        } else if (this.type === RequestType.LISTING) {
          this.productService
            .sendListingRequest(this.buildRequestDtoListing())
            .subscribe(
              () => {
                this.showWaitForResult = false;
                this.showListingSuccessMessage = true;
              },
              () => {
                this.showWaitForResult = false;
                this.showErrorMessage = true;
              }
            );
        }
      } else {
        this.showWaitForResult = false;
        this.showNoContactMail = true;
      }
    }
  }

  buildRequestDto(): RequestInterface {
    return {
      firstName: this.firstname.value,
      lastName: this.lastname.value,
      titel: this.gender.value,
      email: this.email.value,
      phoneNumber: this.phoneNumber.value ? this.phoneNumber.value : undefined,
      company: this.company.value ? this.company.value : undefined,
      url: this.url.value ? this.url.value : undefined,
      request: this.request.value,
      contactEmail: this.contactEmail,
      productName: this.productName,
      productId: this.productId,
      companyId: this.companyId,
      anzahlNutzer: this.anzahlNutzer.value,
      startDatum: this.startDatum.value,
      endDatum: this.endDatum.value,
      baustellenAdresse: this.baustellenAdresse.value
    };
  }

  buildRequestDtoListing(): ListingRequestInterface {
    return {
      firstName: this.firstname.value,
      lastName: this.lastname.value,
      titel: this.gender.value,
      email: this.email.value,
      phoneNumber: this.phoneNumber.value ? this.phoneNumber.value : undefined,
      company: this.company.value ? this.company.value : undefined,
      url: this.url.value ? this.url.value : undefined,
      request: this.request.value,
      products: this.products.value,
      contactEmail: 'contact@bimswarm.de',
      
    };
  }

  initialiseContactForm(): void {
    this.demoRequestForm = this.formbuilder.group({
      subject: [""],
      firstname: [
        "",
        Validators.compose([
          Validators.required,
          containsLettersButNoNumbers.bind(this.firstname),
        ]),
      ],
      lastname: [
        "",
        Validators.compose([
          Validators.required,
          containsLettersButNoNumbers.bind(this.lastname),
        ]),
      ],
      email: [
        "",
        Validators.compose([
          Validators.required,
          containsAtLeastLettersAndSpecialChars.bind(this.email),
        ]),
      ],
      phoneNumber: ["", containsNumbersButNoLetters.bind(this.phoneNumber)],
      company: [""],
      url: ["", containsAtLeastLettersAndSpecialChars.bind(this.url)],
      gender: [""],
      request: ["", Validators.required],
      products: ["", this.type === RequestType.LISTING && Validators.required],
      anzahlNutzer: ["", this.type === RequestType.EDGESERVER && Validators.required],
      startDatum: ["", this.type === RequestType.EDGESERVER && Validators.required],
      endDatum: ["", this.type === RequestType.EDGESERVER && Validators.required],
      baustellenAdresse: ["", this.type === RequestType.EDGESERVER && Validators.required]
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  showAgb() {
    this.openAGB();
  }
// TODO Mo  dynamisch den Inhalt der privacy componente anzeigen
  showPrivacy() {
    this.openPrivacy();
  }
}
