import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonFeatureEntryInterface } from "src/app/model/commonFeature/common-feature-entry-interface";
import { CommonFeatureInterface } from "src/app/model/commonFeature/common-feature-interface";
import { ProductCommonFeatureInterface } from "src/app/model/commonFeature/product-common-feature-interface";
import { CommonFeatureService } from "src/app/services/common-feature-service/common-feature.service";

@Component({
  selector: "app-dialog-common-feature-product-creation",
  templateUrl: "./dialog-common-feature-product-creation.component.html",
  styleUrls: [
    "./dialog-common-feature-product-creation.component.css",
    "../dialog.css",
  ],
})
export class DialogCommonFeatureProductCreationComponent implements OnInit {
  public allCommonFeatures: CommonFeatureInterface[] = [];
  public availableCommonFeatures: CommonFeatureInterface[] = [];
  public selectedCommonFeatures: ProductCommonFeatureInterface[] = [];
  public shownSelectedFeatures: ProductCommonFeatureInterface[] = [];
  public expandedFeature: boolean[] = [];

  private readonly expandButtonId = "expandButton";

  private readonly expandText = "Ausklappen";

  private readonly collapseText = "Einklappen";

  constructor(
    public dialogRef: MatDialogRef<DialogCommonFeatureProductCreationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private commonFeatureService: CommonFeatureService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.selectedCommonFeatures = this.data["selected"];
  }

  ngOnInit() {
    this.commonFeatureService.getAllFeatures().subscribe((success) => {
      this.allCommonFeatures = success;
      this.updateAvailableCommonFeature();
      this.updateShownSelectedCommonFeatures();
    });
  }

  /* Toggles whether the Entries of a Feature should be visible */
  public toggleFeatureExpand(id: number): void {
    this.expandedFeature[id] = !this.expandedFeature[id];

    const expandButton = document.getElementById(
      this.expandButtonId + id
    ) as HTMLButtonElement;

    if (expandButton.firstChild.textContent === this.expandText) {
      expandButton.firstChild.textContent = this.collapseText;
    } else {
      expandButton.firstChild.textContent = this.expandText;
    }
  }

  /* Selects the Entry of an Feature */
  public selectFeatureEntry(
    feature: CommonFeatureInterface,
    entry: CommonFeatureEntryInterface
  ): void {
    this.selectedCommonFeatures.push({
      feature: feature,
      entry: entry,
    });
    this.updateAvailableCommonFeature();
    this.updateShownSelectedCommonFeatures();
  }

  /* Closes the Dialog without saving */
  public cancel(): void {
    this.dialogRef.close();
  }

  /* Clears the SearchText for available Features */
  public clearSearchText(): void {
    (document.getElementById("searchText") as HTMLInputElement).value = "";
    this.updateAvailableCommonFeature();
  }

  /* Clears the SearchText for selected Features */
  public clearSearchTextSelected(): void {
    (document.getElementById("searchTextSelected") as HTMLInputElement).value =
      "";
    this.updateShownSelectedCommonFeatures();
  }

  /* Removes Feature from the Selected Feature List */
  public removeSelectedFeature(id): void {
    this.selectedCommonFeatures.splice(id, 1);
    this.updateAvailableCommonFeature();
    this.updateShownSelectedCommonFeatures();
  }

  /* Saves the currently selected Features */
  public save(): void {
    this.dialogRef.close(this.selectedCommonFeatures);
  }

  /**
   * Removes all already selected feature Entries from the given list of featureEntries.
   * Used to remove already selected entries in the "available" colmn expanded Features.
   *
   * @param featureEntries
   */
  removeSelectedEntriesFrom(featureEntries: CommonFeatureEntryInterface[]) {
    let selectedFeatureEntries = this.selectedCommonFeatures.map(
      (x) => x.entry
    );
    return featureEntries.filter((featureEntry) => {
      return (
        selectedFeatureEntries.findIndex((x) => featureEntry.id == x.id) < 0
      );
    });
  }

  public updateAvailableCommonFeature(): void {
    const searchText = document.getElementById(
      "searchText"
    ) as HTMLInputElement;

    this.availableCommonFeatures = [];
    this.expandedFeature = [];

    this.allCommonFeatures.forEach((feature) => {
      if (
        feature.name.includes(searchText.value) ||
        feature.featureEntries.some((entry) =>
          entry.name.includes(searchText.value)
        )
      ) {
        this.availableCommonFeatures.push(feature);
        this.expandedFeature.push(false);
      }
    });

    this.changeDetector.detectChanges();
  }

  public updateShownSelectedCommonFeatures(): void {
    const searchText = document.getElementById(
      "searchTextSelected"
    ) as HTMLInputElement;

    this.shownSelectedFeatures = [];

    this.selectedCommonFeatures.forEach((selected) => {
      if (
        selected.feature.name.includes(searchText.value) ||
        selected.entry.name.includes(searchText.value)
      ) {
        this.shownSelectedFeatures.push(selected);
      }
    });

    this.changeDetector.detectChanges();
  }
}
