import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Title } from "@angular/platform-browser"
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { ProductInterface } from "src/app/model/products/product-interface";
import { ProductService } from "src/app/services/dataServices/product-service/productService";
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-product-sso-client-registration",
  templateUrl: "./product-sso-client-registration.component.html",
  styleUrls: ["./product-sso-client-registration.component.css"],
})
export class ProductSsoClientRegistrationComponent implements OnInit {
  public ssoClient: {
    clientId: string;
    clientName: string;
    clientSecret: string;
    authorizedGrantTypes: string;
    webServerRedirectUri: string;
  };
  public authorizationGrantOptions: string[] = [
    "password",
    "authorization_code",
    "client_credentials",
    "refresh_token",
  ];
  public selectedGrantOptions: string[] = [];
  public product: ProductInterface;
  public editable: boolean = true;

  constructor(
    private productService: ProductService,
    private authService: AuthService,
    private dialogService: DialogService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private organisationService: OrganisationService,
    private titleService:Title
  ) {
    //Reroutes to the Marketplace if the User is not a member of the Organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnInit() {
    this.product = this.productService.activeProduct;
    const name = this.product.name;
    this.titleService.setTitle(name + ": Als SSO Klient Registrieren - BIMSWARM");
    const id = name.replace(environment.specialCharacterRegex, "-");
    this.ssoClient = {
      clientId: id,
      clientName: name,
      clientSecret: "",
      authorizedGrantTypes: "",
      webServerRedirectUri: "",
    };
    if (this.product.ssoClientId != null) {
      this.editable = false;
      this.authService.getClientInfo(this.product.ssoClientId).subscribe(
        (res) => {
          this.ssoClient = res;
          this.selectedGrantOptions = this.ssoClient.authorizedGrantTypes.split(
            ","
          );
          this.cd.detectChanges();
        },
        (error) => {}
      );
    }
  }

  onSave() {
    if (this.selectedGrantOptions.length === 0) {
      this.dialogService.openDialog(
        "Registrieren Fehlgeschlagen",
        "Bitte wählen Sie mindestens einen Authorization Grant aus der Liste aus."
      );
      return;
    }
    let authorizedGrantTypes = "";
    for (let i = 0; i < this.selectedGrantOptions.length; i++) {
      authorizedGrantTypes =
        authorizedGrantTypes + this.selectedGrantOptions[i] + ",";
    }
    this.ssoClient.authorizedGrantTypes = authorizedGrantTypes.substring(
      0,
      authorizedGrantTypes.length - 1
    );
    const clientRegistrationDto = {
      client: this.ssoClient,
      productId: this.product.id,
    };
    this.authService.registerSsoClient(clientRegistrationDto).subscribe(
      () => {
        let changedProduct = this.productService.products.find(
          (prod) => prod.id === this.product.id
        );
        changedProduct.ssoClientId = this.ssoClient.clientId;
        this.productService.activeItem = changedProduct;
        this.onCancel();
      },
      (error) => {}
    );
  }

  onCancel() {
    const productId = this.product.id;
    const organisationId = this.product.company;
    this.router.navigate([
      "organisations",
      organisationId,
      "products",
      productId,
    ]);
  }

  onGrantSelection(selected: string[]) {
    this.selectedGrantOptions = selected;
  }
}
