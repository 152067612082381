import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { CertificationVariantService } from '../services/dataServices/certification-variant-service/certification-variant-service';

@Injectable()
export class CertificationVariantResolver implements Resolve<any> {
  constructor(private certificationVariantService: CertificationVariantService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    let variant = parseInt(route.paramMap.get("certificationVariantId"));
    if (variant) {
      await this.certificationVariantService.setActiveVariant(variant);
    }
  }
}
