import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "filterAlreadyChosen" })
export class FilterAlreadyChosen implements PipeTransform {
  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  transform(products: any, chosenProducts: any) {

    if (this.isEmpty(chosenProducts)) {
      return products;
    }
    if (!products) return []

    const productsUnchosen = [];

    for (const product of products) {
      let productAlreadyChosen = false;
      for (const chosenProduct of chosenProducts) {
        if (product.id === chosenProduct) {
          productAlreadyChosen = true;
        }
      }
      if (!productAlreadyChosen) {
        productsUnchosen.push(product);
      }
    }
    return productsUnchosen;
  }
}
