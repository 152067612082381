<div class="content" *ngIf="contacts != null" [formGroup]="formGroup">
  <div
    class="contactGrid"
    formArrayName="formArray"
    *ngFor="let contact of formArray.controls; let i = index"
  >
    <div
      class="grid-50-50"
      [ngClass]="{ odd: i % 2 == 1, even: i % 2 == 0 }"
      [formGroupName]="i"
    >
      <mat-form-field>
        <input
          matInput
          placeholder="Name der Kontaktperson"
          formControlName="contactName"
        />
        <mat-error *ngIf="contact.get('contactName').invalid">
          Bitte geben Sie einen Namen ein.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Anrede" formControlName="gender">
          <mat-option *ngFor="let gender of genders" [value]="gender.value">{{gender.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          placeholder="Telefonnummer"
          formControlName="phoneNumber"
        />
        <mat-error *ngIf="contact.get('phoneNumber').invalid">
          Bitte nur Zahlen und Buchstaben.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          placeholder="Mobiltelefonnummer"
          formControlName="mobilePhoneNumber"
        />
        <mat-error *ngIf="contact.get('mobilePhoneNumber').invalid">
          Bitte nur Zahlen und Buchstaben.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="E-Mail" formControlName="email" />
        <mat-error *ngIf="contact.get('email').invalid">
          Bitte eine valide E-Mail angeben.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Webseite" formControlName="website" />
        <mat-error *ngIf="contact.get('website').invalid">
          Bitte eine valide Internetadresse angeben.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="removeButton">
      <red-cross-button *ngIf = "isViewForAdmin" (click)="removeContact(i)"></red-cross-button>
    </div>
  </div>
  <button
    *ngIf = "isViewForAdmin"
    mat-raised-button
    class="btn_green"
    [disabled]="!formArray.valid"
    (click)="save()"
  >
    Speichern
  </button>
  <button
    *ngIf = "isViewForAdmin"
    mat-raised-button
    class="btn_red"
    (click)="cancel()"
  >
    Abbrechen
  </button>
</div>
