<div
  style="
    height: 90%;
    display: flex;
    flex-flow: column;
    background-color: white;
  "
>
  <mat-toolbar color="primary" style="position: relative; z-index: 3">
    <span class="left">{{actualProduct?.name}}</span>
    <button mat-icon-button class="back" (click)="goBack.emit()" color="white">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <div *ngIf="this.displaySpinner">
    <mat-spinner></mat-spinner>
  </div>
  <mat-tab-group *ngIf="!this.displaySpinner" style="flex: 1; overflow: hidden" [backgroundColor]="'primary'">
    <mat-tab label="Übersicht">
      <div class="container">
        <app-image-slider
        [item]="this.actualProduct"
        [category]="'organisation'"
        ></app-image-slider>
      </div>
      <div class="padding">
        <div style="display: flex">
          <div class="left">
            <div style="display: flex; align-items: center">
              <div style="padding: 1rem">
                <h1 [ngClass]="(actualProduct?.name.length > 45) ? 'wordellipsis' : ''" style="margin: 0">{{actualProduct?.name}}</h1>
              </div>
            </div>
          </div>
          <div class="right" *ngIf="this.organisation?.website != null">
            <button *ngIf="this.organisation?.organisationType=='PRODUCT_PROVIDER'"
            mat-raised-button (click)="routeToProducts(organisation.organisationType)" color="accent" style="margin: 2px"
            >Produkte</button>
             <!-- TODO  evtl. noch fuer die anderen Typen von Organisationen    CERTIFICATION_OFFICE & PRODUCT_TYPE_ASSOCIATION  -->
            <button *ngIf="this.organisation?.organisationType =='CERTIFICATION_PROVIDER'"
            mat-raised-button (click)="routeToProducts(organisation.organisationType)" color="accent" style="margin: 2px"
            >Zertifikate</button>

            <button mat-raised-button [disabled]="this.organisation?.website == null
            || this.organisation?.website == undefined
            || this.organisation?.website == ''"
            mat-raised-button (click)="routeToWebsite()" color="accent"
            >Organisationswebseite</button>

          </div>
        </div>
        <mat-divider></mat-divider>
        <br />
        <br />
        <h3>Organisationsbeschreibung</h3>
        <p class="word-wrap" [innerHTML]="actualProduct?.description"></p>
        <br />
      </div>
    </mat-tab>
