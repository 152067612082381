import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PublicReviewInterface } from "src/app/model/reviews/public-review-interface";
import { PublicProfileInterface } from "src/app/model/user/public-profile-interface";
import { ReviewService } from "src/app/services/review-service/review.service";
import { UserService } from "src/app/services/user-service/userservice";
import { environment } from "src/environments/environment";

const PAGE_SIZE: number = 5;

@Component({
  selector: "app-user-profile-public",
  templateUrl: "./user-profile-public.component.html",
  styleUrls: ["./user-profile-public.component.css"],
})
export class UserProfilePublicComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private changeDetector: ChangeDetectorRef,
    private reviewService: ReviewService
  ) {}

  public username: string;
  public profile: PublicProfileInterface;
  public reviews: PublicReviewInterface[] = [];
  public totalNumberOfReviews: number;
  public pageIndex: number = 0;

  ngOnInit() {
    this.username = this.route.snapshot.paramMap.get("user");

    this.userService.getPublicProfile(this.username).subscribe(
      (publicProfile) => {
        this.profile = publicProfile;
        if (!this.changeDetector["destroyed"]) {
          this.changeDetector.detectChanges();
        }
        this.loadReviewPage();
      },
      (err) =>
        console.error("There was an Error loading the Public Profile", err)
    );
  }

  public getDefaultProfileImageUrl(): string {
    return environment.defaultPictureProfile;
  }

  /* Loads more Reviews when scrolled */
  public onScroll() {
    if (this.reviews.length < this.totalNumberOfReviews) {
      this.pageIndex++;
      this.loadNextReviewPage();
    }
  }

  /* Loads the next Page of Reviews and concats them to the current */
  public loadNextReviewPage() {
    const reviewPageDto = this.createReviewPageDto();
    let request = this.reviewService
      .getPublicReviewSlice(reviewPageDto, this.username)
      .subscribe((sliceOfReviewDtos) => {
        this.reviews = this.reviews.concat(sliceOfReviewDtos.reviewSlice);
        this.totalNumberOfReviews = sliceOfReviewDtos.totalNumberOfReviews;
        if (!this.changeDetector["destroyed"]) {
          this.changeDetector.detectChanges();
        }
      });
  }

  /* Loads Reviews and replaces the current */
  public loadReviewPage() {
    const reviewPageDto = this.createReviewPageDto();
    this.reviewService
      .getPublicReviewSlice(reviewPageDto, this.username)
      .subscribe((sliceOfReviewDtos) => {
        this.reviews = sliceOfReviewDtos.reviewSlice;
        this.totalNumberOfReviews = sliceOfReviewDtos.totalNumberOfReviews;
        if (!this.changeDetector["destroyed"]) {
          this.changeDetector.detectChanges();
        }
      });
  }

  public createReviewPageDto() {
    return {
      pageIndex: this.pageIndex,
      pageSize: PAGE_SIZE,
    };
  }
}
