<form
  [formGroup]="contactForm"
  (ngSubmit)="onSubmit()"
  (keydown.enter)="$event.preventDefault()"
>
  <div class="left-column">
    <mat-form-field>
      <input
        matInput
        placeholder="Name der Kontaktperson"
        [formControl]="contactName"
        pattern="^[0-9A-Za-zÀ-ÿ\s,._+;()*~'#@!?:&-]+$"
        required
      />
      <mat-error *ngIf="contactName.hasError"> </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Anrede" [formControl]="gender">
        <mat-option *ngFor="let gender of genders" [value]="gender.value">{{gender.label}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="Telefonnummer"
        [formControl]="phoneNumber"
        pattern="^(\+?\d{1,4}[- ]?)?[0-9 ]{10}$"
      />
      <mat-error *ngIf="phoneNumber.hasError">
        Bitte nur Zahlen und Buchstaben.
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="Mobiltelefonnummer"
        [formControl]="mobilePhoneNumber"
        pattern="^(\+?\d{1,4}[- ]?)?\d{10}$"
      />
      <mat-error *ngIf="mobilePhoneNumber.hasError">
        Bitte nur Zahlen und Buchstaben.
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="E-Mail"
        [formControl]="email"
        pattern="[^@]+@[^@]+\.[^@]+"
        required
      />
      <mat-error *ngIf="email.hasError">
        Bitte eine valide E-Mail angeben.
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="Webseite"
        [formControl]="website"
        pattern="[\w\düöä-]+[.][\w]+"
      />
      <mat-error *ngIf="website.hasError">
        Bitte eine valide Internetadresse angeben.
      </mat-error>
    </mat-form-field>
    <button
      mat-raised-button
      type="submit"
      class="btn_green"
      [disabled]="contactForm.invalid"
    >
      Kontakt speichern
    </button>
  </div>
</form>
