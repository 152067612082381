<div class="max-width">
  <div class="two-column-grid">
    <div>
      <h3>Produktname</h3>
    </div>
    <div>
      <mat-form-field>
        <input
          matInput
          [value] = "certificationRequest.productName"
          disabled
        />
      </mat-form-field>
    </div>
    <div>
      <h3>Produktvariantenname</h3>
    </div>
    <div>
      <mat-form-field>
        <input
          matInput
          [value] = "certificationRequest.productVariant"
          disabled
        />
      </mat-form-field>
    </div>
    <div>
      <h3>Produktversion</h3>
    </div>
    <div>
      <mat-form-field>
        <input
          matInput
          [value] ="certificationRequest.productVersion"
          disabled
        />
      </mat-form-field>
    </div>
    <div>
      <h3>Zertifizierungsart</h3>
    </div>
    <div>
      <mat-form-field>
        <input
          matInput
          [value] = "certificationRequest.certificationCategory"
          disabled
        />
      </mat-form-field>
    </div>
    <div>
      <h3>Zertifizierungstyp</h3>
    </div>
    <div>
      <mat-form-field>
        <input
          matInput
          [value] = "certificationRequest.certificationType"
          disabled
        />
      </mat-form-field>
    </div>
    <div *ngIf="certificationRequest.certificationCategory === 'Merkmalszertifizierung'">
      <h3>Merkmal</h3>
    </div>
    <div *ngIf="certificationRequest.certificationCategory === 'Merkmalszertifizierung'">
      <mat-form-field>
        <input
          matInput
          [value] ="certificationRequest.attribute"
          disabled
        />
      </mat-form-field>
    </div>
    <div *ngIf="certificationRequest.certificationCategory === 'Produktzertifizierung'">
      <h3>PTG</h3>
    </div>
    <div *ngIf="certificationRequest.certificationCategory === 'Produktzertifizierung'">
      <mat-form-field>
        <input
          matInput
          [value] ="certificationRequest.ptg"
          disabled
        />
      </mat-form-field>
    </div>
    <div *ngIf="(this.certificationRequest.fileIds && this.certificationRequest.fileIds.length > 0) || this.certificationRequest.status === 'In Bearbeitung'">
      <h3>Dateien</h3>
    </div>
    <div class="aligned">
      <div *ngIf="this.certificationRequest.fileIds && this.certificationRequest.fileIds.length > 0" style="margin-bottom: 1em;">
        <li *ngFor="let fileId of this.certificationRequest.fileIds" class="file-selected-list">
          {{ fileInfo(fileId)?.filename }}<red-cross-button (click)="removeFile(fileId)" class="inline"></red-cross-button>
        </li>
      </div>
      <app-hexagon-button-text-icon *ngIf="!isCertifier && this.certificationRequest.status === 'In Bearbeitung'"
        [text]="'Datei hinzufügen'"
        [icon]="'/assets/img/misc/add.png'"
        (click)="uploadFile()"
      >
      </app-hexagon-button-text-icon>
      <button *ngIf="isCertifier && this.certificationRequest.status === 'In Bearbeitung'"
        mat-raised-button
        class="btn_green right alined-button"
        (click)="requestFiles()"
      >
      Datei(en) anfordern
      </button>
    </div>
  </div>
  <!-------- Editor/Display of past events on this certification request --------->
  <div class="grid-width">
    <app-certification-request-log
      [certificationRequest] = "certificationRequest"
      (commented) = "onCommented($event)"
    >
    </app-certification-request-log>
  </div>
</div>
