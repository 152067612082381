import { UserService } from "./../user-service/userservice";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { DialogService } from "../dialog-service/dialog-service.service";

@Injectable({
  providedIn: "root",
})
export class ComposerAccessGuard implements CanActivate {
  constructor(
    public router: Router,
    public dialogService: DialogService,
    public userService: UserService
  ) {}

  canActivate() {
  this.userService.getCurrentUser().subscribe((user) => {
    if (!user.hasComposerAccess) {
      this.dialogService.openDialog(
        "Kein Zugang!",
        "Sie haben keine Zugriffsrechte für den Composer!"
      );
      this.router.navigateByUrl("/marktplatz");
      return false;
    }
   });
  return true;
  }
}
