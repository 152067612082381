<div>
  <h3>Ausgewählte Datei</h3>
  <div class="files">
    <p *ngIf="!file">Es wurde keine Datei ausgewählt</p>
    <p *ngIf="file">{{file.name}}</p>
  </div>
</div>

<!--Update to Angular 9: Took '*ngIf="!uploadDisabled"' out of this div as it does not exits in class-->
<div class="uploadFileDisplay">
  <button mat-raised-button (click)="fileUpload.click()">
    Datei zum Upload auswählen
  </button>
  <input
    style="display: none"
    type="file"
    name=""
    value=""
    id = "fileUpload"
    (change)="onFileSelected($event)"
    #fileUpload
  />
</div>

<div class="buttons">
  <button
    mat-raised-button
    class="btn_green"
    [disabled]="!file"
    (click)="save()"
  >
    Speichern
  </button>
  <button mat-raised-button class="btn_red" (click)="cancel()">
    Abbrechen
  </button>
</div>
