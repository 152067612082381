/* Custom Modul for the Properties Panel */
export function ComposerTextField(options: {
  id: string;
  label: string;
  content: string;
}) {
  return {
    id: options.id,
    html:
      '<label data-value="label" ' +
      'data-show="showLabel" ' +
      '></label>' +
      '<input type="text" style="width: 92%" id="' + options.id + '" value="' + options.content + '">' +
      "",
    get: function (element, node) {
      return {
        label: options.label,
      };
    },
    showLabel: function (element, node) {
      return true;
    },
  };
}
