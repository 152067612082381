<form [formGroup]="addSchemaForm">
  <mat-form-field>
    <input
      type="search"
      matInput
      placeholder="Nach Datenformat filtern"
      [formControl]="searchControl"
    />
  </mat-form-field>
  <mat-table class="full-width" [dataSource]="addSchemaDataSource">
    <ng-container matColumnDef="addColumn">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
          (change)="handleSchemaCheckboxChange(element.id)"
          [checked]="addSchemaForm.controls.schemas.value.includes(element.id)"
        ></mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dataFormatName">
      <mat-header-cell *matHeaderCellDef>Datenformat</mat-header-cell>
      <mat-cell *matCellDef="let element">{{
        element.dataFormat.name
      }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="version">
      <mat-header-cell *matHeaderCellDef>Version</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.version }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="subSchemaName">
      <mat-header-cell *matHeaderCellDef>Subschema</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.subSchema }}</mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="[
        'addColumn',
        'dataFormatName',
        'name',
        'version',
        'subSchemaName'
      ]"
    ></mat-header-row>
    <mat-row
      *matRowDef="
        let row;
        columns: [
          'addColumn',
          'dataFormatName',
          'name',
          'version',
          'subSchemaName'
        ]
      "
    ></mat-row>
  </mat-table>
  <div *ngIf="addSchemaDataSource.filteredData.length == 0" class="no-dataformats-hint">
    <i>
      Keine Datenformate gefunden. Erstellen Sie erst ein Datenformat oder
      überprüfen Sie die Filtereinstellungen.
    </i>
  </div>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
  <button
    type="submit"
    (click)="handleSchemasSave()"
    mat-button
    class="btn_green submit-button"
  >
    Ausgewählte hinzufügen
  </button>
</form>
