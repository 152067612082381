/* Registration */
export function CustomPaletteForViewer(
  palette,
) {
  palette.registerProvider(this);
}

/* Allows dynamic Parameter Injection */
CustomPaletteForViewer["$inject"] = [
  "palette",
];

/* Definies which Elements are shown in the Palette */
CustomPaletteForViewer.prototype.getPaletteEntries = function (element: any) {
  return {};
}
