import { Component, Inject } from "@angular/core";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { AdminOrganisationAction } from "src/app/model/enums/adminOrganisationAction";
import { OrganisationChangeInterface } from "src/app/model/organisations/organisation-change-interface";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { OrganisationMemberInterface } from "src/app/model/organisations/organisationMember-interface";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";

@Component({
  selector: "app-dialog-delete-user-manage-organisations",
  templateUrl: "./dialog-delete-user-manage-organisations.component.html",
  styleUrls: [
    "./dialog-delete-user-manage-organisations.component.css",
    "../dialog.css",
  ],
})
export class DialogDeleteUserManageOrganisationsComponent {
  public decisions: OrganisationDecision[] = [];
  public leftOrganisations: {
    organisationName: string;
    reason: string;
  }[] = [];

  constructor(
    private organisationService: OrganisationService,
    private dialogRef: MatDialogRef<
      DialogDeleteUserManageOrganisationsComponent
    >,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      userId: number;
      organisationMemberships: OrganisationInterface[];
    }
  ) {
    this.decisions = [];
    this.leftOrganisations = [];
    for (const membership of data.organisationMemberships) {
      if (membership.membershipStatus != "CONFIRMED") {
        continue;
      }
      if (membership.role == "USER") {
        this.leftOrganisations.push({
          organisationName: membership.organisationName,
          reason: "Sie sind einfacher Nutzer der Organisation.",
        });
      } else {
        this.checkIfOrganisationHasOtherAdmin(membership);
      }
    }
  }

  private checkIfOrganisationHasOtherAdmin(
    organisationOverview: OrganisationInterface
  ): void {
    this.organisationService
      .getOrganisation(organisationOverview)
      .then((organisationDetail: OrganisationInterface) => {
        const allOrganisationMembers: OrganisationMemberInterface[] =
          organisationDetail.organisationMembers;
        let adminCount: number = 0;
        allOrganisationMembers.forEach(
          (member: OrganisationMemberInterface) => {
            if (member.role == "ADMIN") {
              adminCount++;
            }
          }
        );
        if (adminCount > 1) {
          this.leftOrganisations.push({
            organisationName: organisationOverview.organisationName,
            reason: "Es existiert mindestens ein weiterer Admin.",
          });
        } else {
          let otherConfirmedMemberships: OrganisationMemberInterface[] = organisationDetail.organisationMembers.filter(
            (membership) => {
              return (
                membership.userId != this.data.userId &&
                membership.membershipStatus == "CONFIRMED"
              );
            }
          );
          let decision: OrganisationDecision = new OrganisationDecision(
            organisationDetail.organisationId,
            organisationDetail.organisationName,
            otherConfirmedMemberships,
            AdminOrganisationAction.TRANSFER,
            null
          );
          if (otherConfirmedMemberships.length == 0) {
            decision.selectedAction = AdminOrganisationAction.DELETE;
            decision.reason = "Sie sind das einzige Mitglied der Organisation.";
          }
          this.decisions.push(decision);
        }
      });
  }

  public onClick(confirm: boolean): void {
    let organisationChanges: OrganisationChangeInterface[] = [];
    for (const decision of this.decisions) {
      organisationChanges.push({
        organisationId: decision.organisationId,
        action: decision.selectedAction,
        newAdminUsername: decision.transferTarget,
      });
    }
    const result: {
      confirmed: boolean;
      organisationChanges: OrganisationChangeInterface[];
    } = {
      confirmed: confirm,
      organisationChanges: organisationChanges,
    };
    this.dialogRef.close(result);
  }

  public onSelectionChange(
    event: MatButtonToggleChange,
    organisationId: number
  ): void {
    let changedDecision: OrganisationDecision = this.decisions.find(
      (decision: OrganisationDecision) =>
        decision.organisationId === organisationId
    );
    changedDecision.selectedAction = event.value;
  }

  public onTransferSelectionChange(
    event: MatSelectChange,
    organisationId: number
  ): void {
    let changedDecision: OrganisationDecision = this.decisions.find(
      (decision: OrganisationDecision) =>
        decision.organisationId === organisationId
    );
    changedDecision.transferTarget = event.value;
  }

  public hasIncompleteTransfer(): boolean {
    return this.decisions.some(
      (decision: OrganisationDecision) =>
        decision.selectedAction == AdminOrganisationAction.TRANSFER &&
        (decision.transferTarget == null || decision.transferTarget.length == 0)
    );
  }
}

class OrganisationDecision {
  organisationId: number;
  organisationName: string;
  organisationMembers: OrganisationMemberInterface[];
  selectedAction: AdminOrganisationAction;
  transferTarget?: string;
  reason?: string;

  constructor(
    organisationId: number,
    organisationName: string,
    organisationMembers: OrganisationMemberInterface[],
    organisationAction: AdminOrganisationAction,
    reason: string
  ) {
    this.organisationId = organisationId;
    this.organisationName = organisationName;
    this.organisationMembers = organisationMembers;
    this.selectedAction = organisationAction;
    this.reason = reason;
  }
}
