<div class="maxWidth">
  <h1>Zertifizierungsantrag</h1>
  <!-------- First Step of Certification Request ------------------------->
  <form #form="ngForm" *ngIf="step == 0">
    <div class="two-column-grid">
      <div>
        <h3>Produktname</h3>
      </div>
      <div>
        <mat-form-field>
          <input
            matInput
            [value] = "product.name"
            disabled
          />
        </mat-form-field>
      </div>
      <div>
        <h3>Produktvariantenname</h3>
      </div>
      <div>
        <mat-form-field>
          <input
            matInput
            [value] = "variant.name"
            disabled
          />
        </mat-form-field>
      </div>
      <div>
        <h3>Produktversion</h3>
      </div>
      <div>
        <mat-form-field>
          <input
            matInput
            [value] ="version.name"
            disabled
          />
        </mat-form-field>
      </div>
      <div>
        <h3>Zertifierungsart</h3>
      </div>
      <div>
        <mat-form-field>
          <mat-select
            placeholder="Zertifierungsart wählen"
            [(ngModel)]="certificationRequest.certificationCategory"
            name="category"
            required
          >
            <mat-option value="Merkmalszertifizierung">Merkmalszertifizierung</mat-option>
            <mat-option value="Produktzertifizierung">Produktzertifizierung</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="mini-grid-buttons">
      <div>
        <button
          mat-raised-button
          class="btn_red"
          (click)="cancelCertificationProcess()"
        >
          Abbrechen
        </button>
      </div>
      <div>
        <button
          type="button"
          mat-raised-button
          class="btn_green right"
          [disabled]="form.invalid"
          (click)="nextStep()"
        >
          Weiter
        </button>
      </div>
    </div>
  </form>
  <!-------- Second Step of Certification Request ------------------------->
  <form #form="ngForm" *ngIf="step == 1">
    <div class="two-column-grid">
      <div>
        <h3>Zertifizierungsart</h3>
      </div>
      <div>
        <mat-form-field>
          <input
            matInput
            [value] = "certificationRequest.certificationCategory"
            disabled
          />
        </mat-form-field>
      </div>
      <div>
        <h3>Zertifizierungstyp</h3>
      </div>
      <div>
        <mat-form-field>
          <mat-select
            placeholder="Zertifierungstyp wählen"
            [(ngModel)]="certificationRequest.certificationType"
            name="type"
            required
          >
            <mat-option value="Erstzertifizierung">Erstzertifizierung</mat-option>
            <mat-option value="Rezertifizierung">Rezertifizierung</mat-option>
            <mat-option value="Vorhandenes Zertifikat hinzufügen">Vorhandenes Zertifikat hinzufügen</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-------- As soon as the certificationType was chosen & if Merkmalszertifizierung ------------------------->
      <div *ngIf="certificationRequest.certificationType && certificationRequest.certificationCategory === 'Merkmalszertifizierung'">
        <h3>Merkmal</h3>
      </div>
      <div *ngIf="certificationRequest.certificationType && certificationRequest.certificationCategory === 'Merkmalszertifizierung'">
        <mat-form-field>
          <mat-select
            placeholder="Merkmal-Version wählen"
            [(ngModel)]="certificationRequest.attribute"
            name="attribute"
            required
          >
            <mat-option
              *ngFor="let attribute of testList"
              value="{{ attribute }}"
              >{{ attribute }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="extra-margin">
          <app-hexacon-button-left-text-icon
            [text]="'Zu den Zertifizierungskriterien'"
            [icon]="'/assets/img/misc/arrowRight.png'"
            (click)="showCriteria()"
          >
          </app-hexacon-button-left-text-icon>
        </div>
      </div>
      <!-------- As soon as the certificationType was chosen & if Produktzertifizierung ------------------------->
      <div *ngIf="certificationRequest.certificationType && certificationRequest.certificationCategory === 'Produktzertifizierung'">
        <h3>PTG</h3>
      </div>
      <div *ngIf="certificationRequest.certificationType && certificationRequest.certificationCategory === 'Produktzertifizierung'">
        <mat-form-field>
          <mat-select
            placeholder="PTG-Version wählen"
            [(ngModel)]="certificationRequest.ptg"
            name="ptg"
            required
          >
            <mat-option
              *ngFor="let ptg of testList2"
              value="{{ ptg }}"
              >{{ ptg }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="extra-margin">
          <app-hexacon-button-left-text-icon
            [text]="'Zu den Zertifizierungskriterien'"
            [icon]="'/assets/img/misc/arrowRight.png'"
            (click)="showCriteria()"
          >
          </app-hexacon-button-left-text-icon>
        </div>
      </div>
    </div>
    <!-------- Buttons "Weiter/Zurück" ------------------------->
    <div class="mini-grid-buttons">
      <div>
        <button
          type="button"
          mat-raised-button
          class="btn_red"
          (click)="stepBack()"
        >
          Zurück
        </button>
      </div>
      <div>
        <button
          type="button"
          mat-raised-button
          class="btn_green right"
          [disabled]="form.invalid"
          (click)="nextStep()"
        >
          Weiter
        </button>
      </div>
    </div>
  </form>
  <!-------- Last&Third Step of Certification Request ------------------------->
  <form #form="ngForm" *ngIf="step == 2">
    <div class="two-column-grid">
      <div>
        <h3>Produktname</h3>
      </div>
      <div>
        <mat-form-field>
          <input
            matInput
            [value] = "product.name"
            disabled
          />
        </mat-form-field>
      </div>
      <div>
        <h3>Produktvariantenname</h3>
      </div>
      <div>
        <mat-form-field>
          <input
            matInput
            [value] = "variant.name"
            disabled
          />
        </mat-form-field>
      </div>
      <div>
        <h3>Produktversion</h3>
      </div>
      <div>
        <mat-form-field>
          <input
            matInput
            [value] ="version.name"
            disabled
          />
        </mat-form-field>
      </div>
      <div>
        <h3>Zertifizierungsart</h3>
      </div>
      <div>
        <mat-form-field>
          <input
            matInput
            [value] = "certificationRequest.certificationCategory"
            disabled
          />
        </mat-form-field>
      </div>
      <div>
        <h3>Zertifizierungstyp</h3>
      </div>
      <div>
        <mat-form-field>
          <input
            matInput
            [value] = "certificationRequest.certificationType"
            disabled
          />
        </mat-form-field>
      </div>
      <div *ngIf="certificationRequest.certificationCategory === 'Merkmalszertifizierung'">
        <h3>Merkmal</h3>
      </div>
      <div *ngIf="certificationRequest.certificationCategory === 'Merkmalszertifizierung'">
        <mat-form-field>
          <input
            matInput
            [value] ="certificationRequest.attribute"
            disabled
          />
        </mat-form-field>
      </div>
      <div *ngIf="certificationRequest.certificationCategory === 'Produktzertifizierung'">
        <h3>PTG</h3>
      </div>
      <div *ngIf="certificationRequest.certificationCategory === 'Produktzertifizierung'">
        <mat-form-field>
          <input
            matInput
            [value] ="certificationRequest.ptg"
            disabled
          />
        </mat-form-field>
      </div>
    </div>
    <!-------- Buttons "Weiter/Anfrage senden" ------------------------->
    <div class="mini-grid-buttons">
      <div>
        <button
          type="button"
          mat-raised-button
          class="btn_red"
          (click)="stepBack()"
        >
          Zurück
        </button>
      </div>
      <div>
        <button
          id="savebtn"
          mat-raised-button
          class="btn_green right"
          [disabled]="form.invalid"
          (click)="sendRequest()"
        >
          Anfrage senden
        </button>
      </div>
    </div>
  </form>
</div>
