import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogRequestComponent } from '../dialog-request.component';
interface CartItem {
  name: string;
  quantity: number;
  price: number;
}
@Component({
  selector: 'app-dialog-warenkorb',
  templateUrl: './dialog-warenkorb.component.html',
  styleUrls: ['./dialog-warenkorb.component.css',
            '../../dialog.css',
  ],
})
export class DialogWarenkorbComponent implements OnInit {

  cartItems: CartItem[] = [
    { name: 'CommIT - Premiuim', quantity: 1, price: 0o0 },
    { name: 'X20', quantity: 1, price: 0o0 },
    { name: 'Baubot', quantity: 1, price: 0o0 },
    { name: 'z-128: IFC-Modell Generierung', quantity: 1, price: 0o0 },
    { name: 'z-204: Bauteilerkennung', quantity: 1, price: 0o0 },
    { name: 'z-127: Erkennung IFC-Kategorie', quantity: 1, price: 0o0 },
  ];
  displayedColumns: string[] = ['name', 'quantity', 'price', 'total'];
  constructor(public dialogRef: MatDialogRef<DialogRequestComponent>) { 
  }

  ngOnInit(): void {
  }

  getTotalCost(): number {
    return this.cartItems.reduce((acc, item) => acc + (item.quantity * item.price), 0);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
