import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { ServiceError } from "src/app/components/service-error";
import { environment } from "src/environments/environment";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { DocumentService } from "src/app/services/dataServices/document-service/document.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { ProductCategory } from "src/app/model/enums/productCategory";
import { take } from "rxjs/operators";
import { UtilService } from 'src/app/services/util-service/utilService';
import { FileServiceService } from "src/app/services/file-service/file-service.service";
import { FileInfoInterface } from 'src/app/model/marketplace/file-info-interface';
import { UserService } from 'src/app/services/user-service/userservice';
import { CertificationTypes } from "src/app/model/enums/certificationTypes";


@Component({
  selector: "app-certification-edit-screen",
  templateUrl: "./certification-edit-screen.component.html",
  styleUrls: ["./certification-edit-screen.component.css"],
})
export class CertificationEditScreenComponent implements OnInit, OnDestroy {
  certificationUploadPictureFile: File;
  certificationUploadDocumentFile: File;
  form;

  intialImageUrl: string;

  certificationEdit: CertificationInterface;

  fetchedCertification: CertificationInterface;

  putError: ServiceError;

  isInitialised: boolean = false;
  urlData: String;

  public certificationCategory = ProductCategory;
  private isDescriptionValid: boolean = true;

  private filesById;

  constructor(
    public organisationService: OrganisationService,
    public certificationService: CertificationService,
    public documentService: DocumentService,
    public productService: ProductService,
    public router: Router,
    private dialogService: DialogService,
    public activeRoute: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private fileService: FileServiceService,
    private userService: UserService,
    public titleService: Title
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }

    this.fetchedCertification = this.certificationService.activeItem;
    this.certificationService
      .getListOfGalleryPictureIds(this.fetchedCertification.id)
      .pipe(take(1))
      .subscribe((picGalleryIds) => {
        this.certificationEdit.galleryPictureIds = picGalleryIds;
      });
    this.certificationEdit = {
      name: this.fetchedCertification.name,
      description: this.fetchedCertification.description,
      url: this.fetchedCertification.url,
      documentIds: this.fetchedCertification.documentIds,
      id: this.fetchedCertification.id,
      certificationVariants: this.fetchedCertification.certificationVariants,
      pictureId: this.fetchedCertification.pictureId,
      galleryPictureIds: this.fetchedCertification.galleryPictureIds,
      validity: this.fetchedCertification.validity,
      certificationType: this.fetchedCertification.certificationType,
    };
    this.certificationEdit.oldName = this.certificationEdit.name;
    if (this.certificationService.activeItem.pictureId) {
      this.intialImageUrl = this.certificationService.getCertificationPicture(
        this.certificationService.activeItem.id
      );
    } else {
      this.intialImageUrl = environment.defaultPictureProducts;
    }
  }

  onSaveEdit(): void {
    if (
      !UtilService.areStringsValid(
        this.certificationEdit.name,
        this.certificationEdit.description
      )
    ) {
      this.dialogService.openDialog(
        "Bearbeiten fehlgeschlagen",
        "Der Name oder die Beschreibung sind zu lang."
      );
      return;
    }
    this.certificationEdit.pictureId;
    this.intialImageUrl;

    //TODO
    this.certificationService.ITEM_MAP[":organisationId"] = String(
      this.organisationService.activeItem.organisationId
    );
    let editPromise;
    if (this.certificationUploadPictureFile) {
      editPromise = this.certificationService.editCertification(
        this.certificationEdit,
        this.certificationUploadPictureFile
      );
    } else {
      editPromise = this.certificationService.editCertification(
        this.certificationEdit
      );
    }
    editPromise
      .then(() => {
        this.dialogService
          .openDialog("Zertifizierung erfolgreich bearbeitet", "")
          .subscribe(() => {
                let url = this.router.url.replace("/edit", "");
                    this.router.navigateByUrl(url);
              });
      })
      .then(() => {
        // this.sideMenuService.reloadCertifications();
      })
      .catch((e: Error) => {
        this.dialogService.openDialog(e, e);
      });
  }

  // deletes a fileid from the gallery picture list
  public deleteFromGallery(fileId: string): void {
    let galleryIndex = this.certificationEdit.galleryFileIds.indexOf(fileId);
    this.certificationEdit.galleryFileIds.splice(galleryIndex, 1);
  }

  // deletes a document from the document file list
  public deleteDocument(fileId): void {
    let galleryIndex = this.certificationEdit.documentFileIds.indexOf(fileId);
    this.certificationEdit.documentFileIds.splice(galleryIndex, 1);
  }

  // changes the index of a picture in the gallery list
  public moveGalleryItem(fileId: string, toLeft: boolean): void {
    let galleryIndex = this.certificationEdit.galleryFileIds.indexOf(fileId);
    if (toLeft && galleryIndex > 0) {
      let tmp = this.certificationEdit.galleryFileIds[galleryIndex - 1];
      this.certificationEdit.galleryFileIds[
        galleryIndex - 1
      ] = this.certificationEdit.galleryFileIds[galleryIndex];
      this.certificationEdit.galleryFileIds[galleryIndex] = tmp;
    }
    if (
      !toLeft &&
      galleryIndex < this.certificationEdit.galleryFileIds.length - 1
    ) {
      let tmp = this.certificationEdit.galleryFileIds[galleryIndex];
      this.certificationEdit.galleryFileIds[
        galleryIndex
      ] = this.certificationEdit.galleryFileIds[galleryIndex + 1];
      this.certificationEdit.galleryFileIds[galleryIndex + 1] = tmp;
    }
  }

  // opens the filemanager for setting documents or gallery images
  public openFileManagerPicture(target: string): void {
    let options = { multi: false, filter: false, upload: true };
    if (target == "Dokumente" || target == "Galerie") {
      options.multi = true;
    }
    this.dialogService
      .openFileManagerDialog(
        "Datei für " + target + " auswählen",
        "Abbrechen",
        "Übernehmen",
        "Wählen Sie eine Datei.",
        options
      )
      .subscribe((confirmed: string[]) => {
        if (confirmed && confirmed.length > 0) {
          this.setNewFileIds(target, confirmed);
        }
        if (this.productService.toBeDeletedIds) {
          this.certificationEdit.galleryFileIds = this.differenceIdList(
            this.certificationEdit.galleryFileIds,
            this.productService.toBeDeletedIds
          );
          this.certificationEdit.documentFileIds = this.differenceIdList(
            this.certificationEdit.documentFileIds,
            this.productService.toBeDeletedIds
          );
          this.productService.toBeDeletedIds.forEach(
            (deletedFileId: string) => {
              if (deletedFileId == this.certificationEdit.fileId) {
                this.certificationEdit.fileId = null;
              }
            }
          );
          this.productService.toBeDeletedIds = [];
          this.ref.detectChanges();
        }
      });
  }

  /**
   * Helpermethod for setting the new fileIds chosen in the filemanager
   * @param target target of the fileIds (logo, gallery, documents)
   * @param confirmed list of fileIds chosen in filemanager
   */
  private setNewFileIds(target: string, confirmed: string[]) {
    let certificationData = this.certificationEdit;
    if (target == "Logo") {
      certificationData.fileId = confirmed[0];
    }
    if (target == "Galerie") {
      confirmed.forEach((element: string) => {
        if (certificationData.galleryFileIds.indexOf(element) == -1) {
          certificationData.galleryFileIds.push(element);
        }
      });
    }
    if (target == "Dokumente") {
      confirmed.forEach((element: string) => {
        if (certificationData.documentFileIds.indexOf(element) == -1) {
          certificationData.documentFileIds.push(element);
        }
      });
    }
    this.certificationEdit = certificationData;
    this.ref.detectChanges();
  }

  // gets fileinfo for a fileid
  public fileInfo(fileId: string) {
    this.fileService.data$.subscribe((data: FileInfoInterface) => {
      this.filesById = data;
    });
    return this.filesById[fileId];
  }

  //helper method for imagepath of a fileid
  public imagePath(fileId: string) {
    if (fileId) {
      return "/proxy/api/v0/fileservice/file/" + fileId;
    }
    return "";
  }

  deleteCertification(): void {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie die Zertifizierung wirklich löschen?",
        "Abbrechen",
        "Löschen"
      )
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.router.navigate([
            "organisations/" +
              this.organisationService.activeItem.organisationId +
              "/certifications",
          ]);
          this.certificationService.deleteCurrentCertification();
        }
      });
  }

  /**
   * gets the certificationType for frontend view
   */
  getCertificationType(): string {
    let certType: string | number = this.fetchedCertification.certificationType;
    let certTypeName: string = "";
    switch (certType) {
      case CertificationTypes.TOOLCHAIN_CERT || 0:
        certTypeName = "Toolchain";
        break;
      case CertificationTypes.ATTRIBUTE_CERT || 1:
        certTypeName = "Merkmale";
        break;
      case CertificationTypes.PTG_CERT || 2:
        certTypeName = "Produkttyp";
        break;
      default:
        throw Error("certificationType not known");
    }
    return certTypeName;
  }

  onCancelEdit(): void {
    this.router.navigate([
      "organisations",
      this.organisationService.activeItem.organisationId,
      "certifications",
      this.fetchedCertification.id,
    ]);
  }

  filterOrganisationName(organisationId): string {
    return this.organisationService.collection.filter(
      (organisation) => organisation.organisationId === organisationId
    )[0].organisationName;
  }

  setCertificationEditCategory(event: string): void {
    this.certificationEdit.category = event;
  }

  setCertificationValidity(event: number): void {
    this.certificationEdit.validity = event;
  }

  ngOnInit(): void {
    this.fetchedCertification = this.certificationService.activeItem;
    var picGalleryIds = this.certificationService.getListOfGalleryPictureIds(
      this.fetchedCertification.id
    );
    this.certificationEdit = {
      name: this.fetchedCertification.name,
      description: this.fetchedCertification.description,
      url: this.fetchedCertification.url,
      documentFileIds: this.fetchedCertification.documentFileIds,
      id: this.fetchedCertification.id,
      certificationVariants: this.fetchedCertification.certificationVariants,
      fileId: this.fetchedCertification.fileId,
      galleryFileIds: this.fetchedCertification.galleryFileIds,
    };
    this.titleService.setTitle(this.certificationEdit.name + ": Zertifizierung bearbeiten - BIMSWARM");
    this.fetchedCertification.oldName = this.fetchedCertification.name;
  }

  ngOnDestroy(): void { 
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }

  public onDescriptionValidityChange(descriptionValid: boolean) {
    this.isDescriptionValid = descriptionValid;
  }

  private differenceIdList(confirmed: string[], deleted: string[]): string[] {
    deleted.forEach((deletedFileId: string) => {
      var index = confirmed.indexOf(deletedFileId);
      if (index > -1) {
        confirmed.splice(index, 1);
      }
    });
    return confirmed;
  }
}
