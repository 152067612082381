import { Component, OnInit, Input} from '@angular/core';
import { DataType } from 'src/app/model/data-type.enum';
import { DisplayItemInterface } from 'src/app/model/marketplace/display-item-interface';
import { OrganisationInterface } from 'src/app/model/organisations/organisation-interface';
import { NavbarService } from 'src/app/services/componentServices/navbar-service/navbar-service';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { ProductlistService } from 'src/app/services/productlist-service/productlist.service';
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: 'app-display-item',
  templateUrl: './display-item.component.html',
  styleUrls: ['./display-item.component.scss'],
})
export class DisplayItemComponent implements OnInit {

  @Input()
  itemInfo: DisplayItemInterface;

  public organisation: OrganisationInterface;


  bgColors = [
    '#005a5a',
    '#3b8b8e',
    '#3da936',
    '#61bdae',
    '#94c028',
    '#a0bbbc',
    '#e95b9b',
    '#ec6c6e',
    '#f19cc2',
  ];

  randomBgColor = this.bgColors[
    Math.floor(Math.random() * this.bgColors.length)
  ];

  hover = false;
  @Input() focus = false;
  @Input() isProduct = true;
  @Input() isProvider = false;
  @Input() isCertification = false;
  @Input() bookmarked = false;


  constructor(private organisationService: OrganisationService,
              private userService: UserService,
              private productListService: ProductlistService,
              private dialogService: DialogService,
              private navbarService: NavbarService,
              ) {
  }

  ngOnInit() {
    document.documentElement.style.setProperty('--visibleLines', '5');
    document.documentElement.style.setProperty('--visibleLinesOrg', '8');
    document.documentElement.style.setProperty('--visibleLinesCert', '7');
    document.documentElement.style.setProperty('--visibleLinesNoCert', '6');

    if (this.isCertification) {
      this.isProduct = false;
      this.isProvider = false;
      this.organisationService.getOrganisation(this.itemInfo.organisationId).then((x) => {
        this.organisation = x;
      });
    } else if (this.isProvider) {

      this.isProduct = false;
      this.isCertification = false;
    } else if (this.isProduct) { this.isCertification = false;
      this.isProvider = false;
      this.organisationService.getOrganisation(this.itemInfo.organisationId).then((x) => {
        this.organisation = x;
      });
    } else {
      this.isProvider = false;
      this.isCertification = false;
      this.isProduct = true;
    }
  }

  toggleProductInList(productId: number) {
    if (this.bookmarked) {
      if (this.itemInfo.displayType === DataType.CERTIFICATION) {
      this.removeProductFromList(productId, 0);
      } else if (this.itemInfo.displayType === DataType.PRODUCT) {
      this.removeProductFromList(productId, 1);
 } else if (this.itemInfo.displayType === DataType.ORGANISATION) {
      this.removeProductFromList(productId, 2);
 }
    } else {
      if (this.itemInfo.displayType === DataType.CERTIFICATION) {
      this.addToProductList(productId, 0);
      } else if (this.itemInfo.displayType === DataType.PRODUCT) {
      this.addToProductList(productId, 1);
      } else if (this.itemInfo.displayType === DataType.ORGANISATION) {
      this.addToProductList(productId, 2);
      }
    }
  }

  public hasCertification(): boolean {
    if (this.itemInfo.attributeCertificationCount > 0 || this.itemInfo.ptgCertificationCount > 0 || this.itemInfo.toolchainCertificationCount > 0) {  return true; }
    return false;
  }


  addToProductList(productId: number, displayType: number): void {
      this.productListService.addElementToList(productId, displayType).subscribe(() => {
          if (displayType === 1) {
            this.dialogService.openDialog(
              'Das Produkt wurde zu Ihrer Merkliste hinzugefügt',
              ''
            ).subscribe(() => {
              this.productListService.getAllProducts();
            });
          } else if (displayType === 0) {
            this.dialogService.openDialog(
              'Die Zertifizierung wurde zu Ihrer Merkliste hinzugefügt',
              ''
            ).subscribe(() => {
              this.productListService.getAllProducts();
            });
          } else if (displayType === 2) {
            this.dialogService.openDialog(
              'Der Anbieter wurde zu Ihrer Merkliste hinzugefügt',
              ''
            ).subscribe(() => {
              this.productListService.getAllProducts();
            });
          }
          if (!this.navbarService.NAV_STATE_MY_PRODUCTS_LIST) {
              this.navbarService.NAV_STATE_MY_PRODUCTS_LIST = true;
              this.navbarService.loadButtons();
          }
      });
  }

  private removeProductFromList(productId: number, displayType: number): void {
    this.productListService.deleteElementFromList(productId, displayType)
    .subscribe(() => {
      if (displayType === 0) {
      this.dialogService
        .openDialog('Die Zertifizierung wurde von Ihrer Merkliste', '').subscribe(() => {
        this.productListService.getAllProducts();
      });
      }
      if (displayType === 1) {
      this.dialogService
        .openDialog('Das Produkt wurde von Ihrer Merkliste entfernt', '').subscribe(() => {
        this.productListService.getAllProducts();
      });
      }
      if (displayType === 2) {
      this.dialogService
        .openDialog('Der Anbieter wurde von Ihrer Merkliste entfernt', '').subscribe(() => {
        this.productListService.getAllProducts();
      });
      }
    });
  }

  isLoggedIn(): boolean {
    return this.userService.loggedIn;
  }

  removeEnters(): String {
    let description = this.itemInfo.description;
    description = description.replace(/<p>/g, ' ');
    description = description.replace(/<ul>/g, ' ');
    description = description.replace(/<li>/g, ' ');
    description = description.replace(/<\/p>/g, '\r\n');
    description = description.replace(/<\/li>/g, '\r\n');
    description = description.replace(/<\/ul>/g, '\r\n');
    return description;
  }

  bookmark(event: Event) {
    event.stopPropagation();
    this.bookmarked = !this.bookmarked;
  }
}
