import { PtgInterface } from "src/app/model/ptgs/ptg-interface";

/* Custom Modul for the Properties Panel */
export function ComposerSelectionRemoving(options: {
  id: string;
  label: string;
  options: PtgInterface[];
}) {
  let optionsString = "";
  options.options.forEach(
    (element) => (optionsString += createSelectOption(element))
  );

  /* Custom Event that gets triggered when an Element is removed - Handled by ComposerChangeHandler */
  const customEvent =
    "{const customEvent = document.createEvent('HTMLEvents');" +
    "customEvent.initEvent('ptgRemoved', true, true);" +
    "customEvent.eventName = 'ptgRemoved';" +
    "event.target.dispatchEvent(customEvent);}";

  return {
    id: options.id,
    html:
      '<div><div style="display: flex; justify-content: space-between; align-items: flex-end;"><label data-value="label" ' +
      '" style="width: 70%"></label><button style="width: 15%; margin-bottom: 10px; height: 20%" onclick="' +
      customEvent +
      '"><b>x</b></button></div><select size="5" id="' +
      options.id +
      '">' +
      optionsString +
      "</select></di>",
    get: function (element, node) {
      return {
        label: options.label,
      };
    },
  };
}

/* Creates the HTML Code for a Select Option */
function createSelectOption(option: PtgInterface) {
  return "<option value='" + option.id + "'>" + option.name + "</option>";
}
