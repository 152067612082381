import PropertiesActivator from "bpmn-js-properties-panel/lib/PropertiesActivator"
import { inherits } from 'util';
import { ComposerPropertiesForViewer } from '../customPropertiesForViewer';

ToolchainPropertiesProviderForViewer["$inject"] = [
    "eventBus",
    "bpmnFactory",
    "translate",
    "elementRegistry"
]

/* Provider Function that gets called when a Property Panel is created */
export function ToolchainPropertiesProviderForViewer(eventBus: any, bpmnFactory: any, translate: any, elementRegistry: any) {
    PropertiesActivator.call(this, eventBus);

    this.getTabs = function (element: any) {
        const generalTab = {
            id: "general",
            label: "general",
            groups: createGeneralTabGroups(element, bpmnFactory),
        };

        return [
            generalTab
        ]
    }
}

/* Creates and returns a Group with the Elements of the Property Panel */
function createGeneralTabGroups(element: any, bpmnFactory: any){
    const generalGroup = {
        id: "general",
        label: "general",
        entries: [],
    }

    /* Creating of the Elements that are shown */
    ComposerPropertiesForViewer(generalGroup, element);

    return [
        generalGroup
    ]
}

inherits(ToolchainPropertiesProviderForViewer, PropertiesActivator);