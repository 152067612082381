import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { DocumentType } from "src/app/model/compatiblity/document-type-interface";
import { DocumentTypeDTO } from "src/app/model/compatiblity/dto/document-type-dto";
import { API_MAP } from "src/environments/api";

const SWARM_API = "/proxy/api/v0";
const JSON_HEADERS = { "Content-Type": "application/json" };

@Injectable({
  providedIn: "root",
})
export class DocumentTypeService {
  url: string;
  constructor(private http: HttpClient) {
    this.url = SWARM_API + API_MAP["compatiblity"]["DOCUMENT_TYPE"];
  }

  /**
   * Returns an observable of the request to get a single @type {DocumentType}
   * @param documentTypeId the id of the requested @type {DocumentType}
   */
  public getDocumentType(documentTypeId: number): Observable<DocumentType> {
    return this.http.get<DocumentType>(this.url + "/" + documentTypeId);
  }

  /**
   * Transfers the dto to the server and returns an observable of the request
   * @param documentTypeDTO the dto describing the new @type {DocumentType}
   */
  public createDocumentType(documentTypeDTO: DocumentTypeDTO) {
    return this.http.post<DocumentType>(
      this.url,
      JSON.stringify(documentTypeDTO),
      { headers: JSON_HEADERS }
    );
  }

  /**
   * Returns an observable of the request to get all document types
   */
  public getDocumentTypes() {
    return this.http.get<DocumentType[]>(this.url);
  }

  /**
   * Transfers the DTO with the updated values and returns the request
   * @param documentTypeId the id of the updated document type
   * @param dto the DTO describing the updated document type
   */
  public updateDocumentType(
    documentTypeId: number,
    dto: DocumentTypeDTO
  ): Observable<DocumentType> {
    return this.http.put<DocumentType>(
      this.url + "/" + documentTypeId,
      JSON.stringify(dto),
      { headers: JSON_HEADERS }
    );
  }
}
