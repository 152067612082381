import { Component, OnInit, Input } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { PtgVersionInterface } from "src/app/model/ptgs/ptg-version-interface";
import { AttributeInterface } from "src/app/model/attributes/attribute-interface";
import { AttributeValidationValueInterface } from "src/app/model/certifications/attribute-validation-values-interface";
import { ValidationInterface } from "src/app/model/attributes/validation-interface";
import { Router } from "@angular/router";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { CertificationVariantService } from "src/app/services/dataServices/certification-variant-service/certification-variant-service";
import { CertificationVariantVersionService } from "src/app/services/dataServices/cetrification-variant-version-service/certification-variant-version-service";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";

@Component({
  selector: "app-certification-detail-display-overview",
  templateUrl: "./certification-detail-display-overview.component.html",
  styleUrls: ["./certification-detail-display-overview.component.css"],
})
export class CertificationDetailDisplayOverviewComponent implements OnInit {
  @Input()
  certification: CertificationInterface;

  public keywords: Set<string> = new Set<string>();
  public variantVersionHash: Hash = {};

  constructor(private ptgService: PtgService, private router: Router, private titleService: Title) {}

  ngOnInit() {
    this.getKeywords();
    this.getAllNamesOfVarantsAndVersions();
    this.titleService.setTitle(this.certification.name + " - BIMSWARM");
  }

  getKeywords(): void {
    this.certification.certificationVariants.forEach(
      (certificationVariant: CertificationVariantInterface) =>
        this.getKeywordsOfVariant(certificationVariant)
    );
  }

  private getKeyWordsAsString(): string {
    return Array.from(this.keywords).join(", ");
  }

  private getKeywordsOfVersion(
    version: CertificationVariantVersionInterface
  ): void {
    version.keywords.forEach((keyword) => this.keywords.add(keyword));
  }

  private getKeywordsOfVariant(variant: CertificationVariantInterface): void {
    variant.certificationVariantVersions.forEach((version) =>
      this.getKeywordsOfVersion(version)
    );
  }

  private getAllNamesOfVarantsAndVersions(): void {
    this.certification.certificationVariants.forEach(
      (variant: CertificationVariantInterface) =>
        this.getAllNamesOfVariant(variant)
    );
  }

  /**
   * Alle Versionnamen werden in einer Liste als Value gespeichert entsprechend der Variante als Key
   * @param variant, Variante des Zertifikates
   */
  private getAllNamesOfVariant(variant: CertificationVariantInterface): void {
    let versionNames = [];
    variant.certificationVariantVersions.forEach(
      (version: CertificationVariantVersionInterface) =>
        versionNames.push(version.name)
    );
    this.variantVersionHash[variant.name] = versionNames;
  }
}

interface Hash {
  [key: string]: string[];
}
