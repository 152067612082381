import { ProductCertificationUpdateInterface } from "./../../../../../model/product-certification/product-certification-update-interface";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ProductCertificationOverview } from "src/app/model/product-certification/product-certification-overview";
import { Router } from "@angular/router";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { CertificationVariantService } from "src/app/services/dataServices/certification-variant-service/certification-variant-service";
import { CertificationVariantVersionService } from "src/app/services/dataServices/cetrification-variant-version-service/certification-variant-version-service";
import { ProductMinimalInterface } from "src/app/model/products/product-minimal-interface";
import { ProductCertificationService } from "src/app/services/product-certification-service/product-certification.service";
import { OrganisationMinimalInterface } from "src/app/model/organisations/organisation-minimal-interface";
import { UserService } from 'src/app/services/user-service/userservice';
import { ProductCategory } from 'src/app/model/enums/productCategory';
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-certification-variant-version-manage-screen",
  templateUrl: "./cert-verrsion-manage-screen.component.html",
  styleUrls: ["./cert-verrsion-manage-screen.component.css"],
})
export class CertVersionManageScreenComponent implements OnInit, OnDestroy {
  public overviewList: ProductCertificationOverview[] = [];
  public productList: ProductMinimalInterface[] = [];
  public orgaList: OrganisationMinimalInterface[] = [];

  // list of products that already have the current certification, used for the pagination
  public certifiedProductPage: ProductCertificationOverview[] = [];
  public certifiedNonListedProductPage: ProductCertificationOverview[] = [];
  public selectedProduct: number;
  public productCertificationUpdateList: ProductCertificationUpdateInterface[] = [];
  public selectedOrganisation: number;

  public productname: string;
  public orgaName: string;
  public productVariant: string;
  public productVariantVersion: string;
  public emailofOrga: string;
  public validated: boolean;
  public productType: ProductCategory[] = [
    ProductCategory.APP,
    ProductCategory.CONTENT,
    ProductCategory.ALLGEMEIN,
    ProductCategory.HOCHBAU,
    ProductCategory.STRASENBAU,
        ProductCategory.TIEFBAU,
        ProductCategory.WASSERBAU,
        ProductCategory.EISENBAHNBAU,
    ProductCategory.INFRASTRUKTUR,
    ProductCategory.SERVICE,
  ];
  public prodType: ProductCategory;
  public undefinedVariable: string;
  public error: boolean;
  public showNonListedProdCert: boolean;
  public toggleOrganisationSwitch: boolean;

  constructor(
    private router: Router,
    private organisationService: OrganisationService,
    private certificationService: CertificationService,
    private certificationVariantService: CertificationVariantService,
    private certificationVariantVersionService: CertificationVariantVersionService,
    private productCertificationService: ProductCertificationService,
    private userService: UserService,
    private titleService: Title,
    private datepipe: DatePipe,
    private cd: ChangeDetectorRef,
  ) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  async ngOnInit() {
    await this.organisationService
      .getMinimalOrganisationList()
      .subscribe((res: OrganisationMinimalInterface[]) => {
        this.orgaList = res;
      });
    await this.productCertificationService
      .getCertifiedProducts(
        this.certificationVariantVersionService.activeItem.id
      )
      .subscribe((res: ProductCertificationOverview[]) => {
        this.certifiedProductPage = res;
        this.cd.detectChanges();
      });
    await this.certificationVariantVersionService
      .getNonListedProductCertificationForCertVar(
        this.certificationService.activeItem.id,
        this.certificationVariantService.activeItem.id
      )
      .subscribe((result: ProductCertificationOverview[]) => {
        this.certifiedNonListedProductPage = result;
        this.cd.detectChanges();
      });

    this.titleService.setTitle(
      this.certificationVariantService.activeItem.name +
        ": Produkte zertifizieren - BIMSWARM"
    );
  }

  commitCertificationProcess() {
    // create key-value pair array from the productversionid and whether it has the certification
    let anythingChanged: boolean = false;
    let productCertifications: ProductCertificationUpdateInterface[] = [];

    this.overviewList.forEach((overview: ProductCertificationOverview) => {
      if (overview.localChanged) {
        this.selectedProduct = overview.productId;
        anythingChanged = true;
        productCertifications.push(this.buildProductCertificationUpdate(overview, false));
      }
    });
    if (this.productCertificationUpdateList.length>0) {
      this.certificationVariantVersionService.updateProductCertifications(
        this.selectedProduct,
        this.certificationService.activeItem.id,
        this.productCertificationUpdateList
      );
    }
    if (anythingChanged) {
      this.certificationVariantVersionService.updateProductCertifications(
        this.selectedProduct,
        this.certificationService.activeItem.id,
        productCertifications
      );
    }

    //Route back
    this.cancelCertificationProcess();
  }

  cancelCertificationProcess() {
    this.cancelNonListedProductCertification(true);
    this.router.navigateByUrl(
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/certifications/" +
        this.certificationService.activeCertification.id +
        "/variants/" +
        this.certificationVariantService.activeItem.id
    );
  }

  ngOnDestroy() : void {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }


  private buildProductCertificationUpdate(overview: ProductCertificationOverview, hasDateChanged:boolean): ProductCertificationUpdateInterface {
    let productCertification: ProductCertificationUpdateInterface = {
      versionId: overview.versionId,
      hasCert: overview.hasCertification,
      hasDateChanged: hasDateChanged,
      newDate: overview.lastCertified,
    };
    return productCertification;
  }

  toggleCertification(overview: ProductCertificationOverview) {
    overview.hasCertification = !overview.hasCertification;
  }

  updateProductCertificationDateValue(date: Date, overview: ProductCertificationOverview) {
    overview.lastCertified = this.datepipe.transform(date, "yyyy-MM-dd");
    overview.hasDateChanged = true;
    overview.hasCertification = false;

    this.productCertificationUpdateList.push(this.buildProductCertificationUpdate(overview, true));
  }

  public localChange(overview: ProductCertificationOverview) {
    overview.localChanged = !overview.localChanged;
  }

  async loadTableData() {
    await this.productCertificationService
      .getProductCertificationOverviews(
        this.certificationVariantVersionService.activeItem.id,
        this.selectedOrganisation
      )
      .subscribe((res: ProductCertificationOverview[]) => {
        this.overviewList = [];
        this.overviewList = res;
        this.overviewList.forEach((overview: ProductCertificationOverview) => {
          overview.localChanged = false;
        });
      });
  }

  /**
   * find a user in the organisation with the role of an admin
   */
  findEmail(orgaId: number): Number {
    if (orgaId !== null) {
      this.organisationService.getItem(orgaId).then((selectedOrga) => {
        selectedOrga.organisationMembers.forEach((member) => {
          if (member.role === "ADMIN") {
            this.emailofOrga = member.userName;
            return;
          }
        });
      });
    }
    return orgaId;
  }

  /**
   * handling of information put into product certification field
   * if all information is correct => send to backend to create a db entry
   */
  submitNonListedProductCertification(): void {
    if (this.orgaName === undefined) {
      this.undefinedVariable = "Es wurde kein Organisationsname angegeben.";
      this.error = true;
      return;
    } else if (this.productname === undefined) {
      this.undefinedVariable = "Es wurde kein Produkt angegeben.";
      this.error = true;
      return;
    } else if (this.productVariant == undefined) {
      this.undefinedVariable = "Es wurde keine Variante angegeben.";
      this.error = true;
      return;
    } else if (this.productVariantVersion === undefined) {
      this.undefinedVariable = "Es wurde keine Varianten Version angegeben.";
      this.error = true;
      return;
    } else if (!this.validateEmail(this.emailofOrga)) {
      this.undefinedVariable =
        "Es wurde entweder keine E-Mail Adresse angegeben oder die E-Mail Adresse ist nicht korrekt.";
      this.error = true;
      return;
    } else if (this.prodType === undefined) {
      this.undefinedVariable = "Es wurde kein Produkttyp angegeben";
      this.error = true;
      return;
    }

    this.error = false;

    this.certificationVariantVersionService.createProductCertificationForNotRegistratedProducts(
      this.certificationService.activeItem.id,
      this.orgaName,
      this.productname,
      this.emailofOrga,
      this.productVariant,
      this.productVariantVersion,
      this.prodType.toString()
    );

    this.cancelNonListedProductCertification(true);
  }

  /**
   * used for the toggle if a organisation is already on the plattform if eraseAll is false
   * oth. it is used by pressing the "cancel" button so the values aren't stored in the variables.
   * @param deleteAll
   */
  cancelNonListedProductCertification(eraseAll: boolean): void {
    this.orgaName = null;
    this.productname = null;
    this.productVariant = null;
    this.productVariantVersion = null;
    this.emailofOrga = null;
    if (eraseAll) {
      this.showNonListedProdCert = false;
    }
  }

  showNonListedProdCertOptions(): void {
    this.error = false;

    this.showNonListedProdCert = true;
  }

  validateEmail(email: string): boolean {
    var emailPattern = /[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/g;
    return emailPattern.test(email);
  }
}
