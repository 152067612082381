/* Asynchronously reads a File and returns its Content */
export async function getTextOfFile(
  filename: string,
  filetype: AssetType
): Promise<any> {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        if (request.status === 200 || request.status === 0) {
          resolve(request.responseText);
        } else {
          reject(request.response);
        }
      }
    };
    request.open("GET", "/assets/" + parseAssetType(filetype) + filename);
    request.send();
  });
}

/* Parses an AssetType to the corresponding Folder */
function parseAssetType(type: AssetType): string {
  switch (type) {
    case AssetType.Image:
      return "image/";
    case AssetType.Mime:
      return "mime-icons/";
    case AssetType.SVG:
      return "svg-icons/";
  }
}

export enum AssetType {
  Image,
  SVG,
  Mime,
}
