import { Component, OnInit, OnDestroy } from '@angular/core';
import  { MatDividerModule } from '@angular/material/divider';
import { HeaderServiceService } from "src/app/services/header-service/header-service.service";





@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit, OnDestroy {

  public toggleVerzeichnis : Boolean = false;

  constructor(  public headerService: HeaderServiceService) {

    document.documentElement.style.setProperty('--scrollStatus', 'auto');

  }

  ngOnInit(): void {


    this.headerService.loadHeaderText("Datenschutzerklärung");
    document.body.scrollTop=0;

  }
ngOnDestroy(): void {


  document.documentElement.style.setProperty('--scrollStatus', 'hidden');

}

}
