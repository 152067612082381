import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";
import { inherits } from "util";
import { AssetType, getTextOfFile } from "../../util/fileUtil";
import { joinEvent } from '../../util/preloadedAssets';
import { parseSvg } from "../../util/svgUtil";

/* Allows dynamic Parameter Injection */
JoinEventRender["$inject"] = ["eventBus"];

/* Custom Renderer for Join-Events */
export default function JoinEventRender(eventBus: any) {
  BaseRenderer.call(this, eventBus, 1500);

  /* Checks if the Renderer is able to render the Element */
  this.canRender = function (element) {
    return element.type === "composer:JoinEvent";
  };

  /* Draws the Element */
  this.drawShape = function (parent, shape) {
    let image = parseSvg(joinEvent).firstChild;
    image = document.importNode(image, true);

    parent.appendChild(image);

    return image;
  }
}

inherits(JoinEventRender, BaseRenderer);
