<div
  style="
    background-color: white;
  "
>
  <mat-toolbar color="primary" style="position: relative; z-index: 3">
    <span class="left">{{actualProduct?.name}}</span>
    <button
    (click)="addToCart()"
    [ngClass]="{ 'added': addedToCart }"
    class="cart-button"
  >
    <span *ngIf="!addedToCart">
      <i class="fa fa-cart-plus"></i> in den Warenkorb
    </span>
    <span *ngIf="addedToCart" class="success-check">
      <svg viewBox="0 0 24 24" class="checkmark">
        <path
          class="checkmark-path"
          fill="none"
          stroke="white"
          stroke-width="2"
          d="M2 12.5l6 6L22 4"
        />
      </svg>
    </span>
  </button>
    <button mat-icon-button class="back" (click)="goBack.emit()" color="white">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <div *ngIf="this.displaySpinner && actualProduct == undefined">
    <mat-spinner></mat-spinner>
  </div>
  <mat-tab-group *ngIf="!this.displaySpinner && actualProduct != undefined" style="flex: 1" [backgroundColor]="'primary'">
    <mat-tab label="Übersicht">
      <div class="container">
        <app-image-slider
        [item]="this.actualProduct"
        [category]="'product'"
        ></app-image-slider>
      </div>
      <div class="padding">
        <div class="grid-template">
          <div class="a">
            <div style="display: flex; align-items: center">
              <div class="pointer" (click)="navigateToOrganisation(organisation.organisationId)">
              <app-image-slider
                [organisation]="this.organisation"
              >
              </app-image-slider>
            </div>
              <div style="padding: 1rem;">
                <h1 [ngClass]="(actualProduct?.name.length > 35) ? 'wordellipsis' : ''" style="margin: 0">{{actualProduct?.name}}</h1>
                <h3 class="pointer" [ngClass]="(actualProduct?.name.length > 35) ? 'wordellipsis' : ''" style="margin: 0" (click)="navigateToOrganisation(organisation.organisationId)">{{organisation?.organisationName}}</h3>
              </div>
            </div>
          </div>
          <div class="b twocolumn-grid">
            <div #colmn1 *ngIf="actualProduct?.rating != 0" class="certificate-icon certificate-margin">
              <app-star
                [rating]="actualProduct?.rating"
                [ratingCount]="actualProduct?.ratingCount"
              ></app-star>
            </div>
            <div
              #colmn2
              class="certificate-icon">
              <app-certificate-mini-display
                *ngIf="
                    actualProduct?.attributeCertificationCount &&
                    actualProduct?.attributeCertificationCount > 0
                  "
                [certificateType]="'ATTRIBUTE_CERT'"
                [certCount]="actualProduct?.attributeCertificationCount"
              >
              </app-certificate-mini-display>
              <!---------------------->
              <app-certificate-mini-display
                *ngIf="
                    actualProduct?.ptgCertificationCount && actualProduct?.ptgCertificationCount > 0
                  "
                [certificateType]="'PTG_CERT'"
                [certCount]="actualProduct?.ptgCertificationCount"
              ></app-certificate-mini-display>
              <!---------------------->
              <app-certificate-mini-display
                *ngIf="
                    actualProduct?.toolchainCertificationCount &&
                    actualProduct?.toolchainCertificationCount > 0
                  "
                [certificateType]="'TOOLCHAIN_CERT'"
                [certCount]="actualProduct?.toolchainCertificationCount"
              >
              </app-certificate-mini-display>
            </div>
          </div>
          <div class="c btn-grid">
            <button  [disabled]=" organisation?.contacts.length < 1 " mat-raised-button color="accent" (click)="requestDemo()" class="margin-right btn">
              Demo anfragen

            </button>

            <button [disabled]="organisation?.contacts.length < 1 " mat-raised-button color="accent" (click)="requestOffer()" class="margin-right btn">
              Angebot anfragen
            </button>
            <button mat-raised-button class="margin-right btn" [disabled]="this.actualProduct.url == null
                                      || this.actualProduct.url == undefined
                                      || this.actualProduct.url == ''"
                                      mat-raised-button (click)="routeToWebsite()" color="accent"
                                      >Produktwebseite</button>
            <button mat-raised-button class="btn"
                    color="accent"
                    [disabled]="(!userService.currentUser || hasUserReviewedProduct()) || showWriteReview"
                    (click)="goToBottom(rating);">
              Produkt bewerten
            </button>
          </div>
        </div>
        <br />
        <span style="display: flex; opacity: 0.8">
          <p *ngIf="actualProduct?.category == 'APP'; else otherType" class="left" style="margin: 0">Produktkategorie: APPLIKATION</p>

          <ng-template #otherType>
            <p class="left" style="margin: 0">Produktkategorie: {{actualProduct?.category}}</p>
          </ng-template>
          <p class="middle" style="margin: 0">Seit {{actualProduct?.publishDate | humanReadableDate}} auf BIMSWARM</p>
          <p class="right" style="margin: 0">Letztes Update: {{actualProduct?.editDate | humanReadableDate}} </p>
        </span>
        <br />
        <mat-divider></mat-divider>
        <br />
        <h3>Produktbeschreibung</h3>
        <p class="word-wrap" [innerHTML]="actualProduct?.description"></p>
        <mat-divider></mat-divider>
        <br />
        <h3>Schlagwörter</h3>
        <mat-chip-list>
          <mat-chip *ngFor="let item of this.keywords">{{item}}</mat-chip>
        </mat-chip-list>
        <br />
        <mat-divider></mat-divider>
        <br />
        <div id="ratingPane" #rating></div>
        <app-marketplace-rating-extended
          [targetId]="actualProduct?.id"
          [reviewType]="reviewType"
          [reviews] = "reviews"
          [average]="average"
          [nrOfReviews]="nrOfReviews"
          [showWriteReview]="showWriteReview"
          (reviewCloseEmitter)="showWriteReview = false;"
          (reviewOpenEmitter)="showWriteReview = true;"
          (averageEmitter)="loadAverageScore()"
          (statisticsEmitter)="loadReviewStatistic()"
          (reviewEmitter)="loadReviews()"
        ></app-marketplace-rating-extended>
        <br />
        <mat-divider></mat-divider>
      </div>
    </mat-tab>
    <mat-tab label="Features">
      <div *ngIf="this.actualProduct != null" class="featuretab">
      <app-product-detail-display-feature
      [product]="this.actualProduct"
      >
      </app-product-detail-display-feature>
      </div>
    </mat-tab>
    <mat-tab *ngIf="!(this.actualProduct.documentFileIds == null)"label="Downloads">
      <div class="clickable">
        <app-product-detail-display-downloads
          [fileIds]="this.actualProduct.documentFileIds"
        ></app-product-detail-display-downloads>
      </div>
    </mat-tab>
    <mat-tab label="Zertifikate">
      <div *ngIf="this.actualProduct != null">
        <app-product-detail-display-certificates
        [product]="this.actualProduct"
        >
        </app-product-detail-display-certificates>
      </div>
    </mat-tab>


  
    <mat-tab label="Anbieter">
        <app-product-detail-display-organisation
        [organisation]="this.organisation"
        >
        </app-product-detail-display-organisation>

    </mat-tab>


  </mat-tab-group>


</div>
