import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";

import { ProductVariantInterface } from "../../../model/products/product-variant-interface";

import { UserService } from "../../user-service/userservice";
import { API_BASE_PRODUCTSERVICE, API_MAP } from "src/environments/api";
import { BaseService } from "../../base.service";
import { ProductVariantVersionService } from "../product-variant-version-service/product-variant-version.service";
import { VariantVersionInterface } from "src/app/model/products/variant-version-interface";
import { ProductDtoConverterServiceService } from "../../product-dto-converter-service/product-dto-converter-service.service";

const SWARM_API = API_BASE_PRODUCTSERVICE;
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class ProductVariantService extends BaseService<
  ProductVariantInterface
> {
  protected equal(
    item1: ProductVariantInterface,
    item2: ProductVariantInterface
  ): boolean {
    return JSON.stringify(item1) === JSON.stringify(item2);
  }
  protected toID(item: ProductVariantInterface | number): number {
    if (typeof item === "number") return item;
    if (item.id) return item.id;
    return -1;
  }

  activeOrganisation: number;

  constructor(
    protected http: HttpClient,
    protected dtoConverter: ProductDtoConverterServiceService,
    private userService: UserService,
    private versionService: ProductVariantVersionService
  ) {
    super(http, dtoConverter);
    this.SWARM_API_BASE = SWARM_API;
    //-----------------------
    // Specify a item mapping to know which URL identifier is to be mapped
    // to witch information on an item
    this.ITEM_MAP = userService.ACTIVE_MAP;

    this.SERVICE_FLAG = ":variantId";
    // Setting coded URL path for the API of this service
    this.SWARM_API_MAP = API_MAP["variants"];

    //-----------------------
    this.versionService.deleteRequestObserver.subscribe(
      (toBeDeleted: VariantVersionInterface) => {
        if (this.activeItem && this.activeItem.variantVersions) {
          const index: number = this.activeItem.variantVersions.findIndex(
            (x) => {
              return x.id === toBeDeleted.id;
            }
          );
          if (index >= 0) {
            this.activeItem.variantVersions.splice(index, 1);
          }
        }
      }
    );
    //-----------------------
    // This is the upwards subscription.
    // Subscribe to the version service so this instance of variant is updated
    // this ensures that there is no unnecessary traffic to update
    this.versionService.activeItemObserver.subscribe(
      (version: VariantVersionInterface) => {
        if (!version || typeof version === "undefined") {
          return;
        }

        // The active Item is always the current variant that is edited
        if (version) {
          let index = this.activeItem.variantVersions.findIndex((other) => {
            return other.id === version.id;
          });

          if (index > -1) {
            // there is version with same ID

            // but the version was edited so override the reference
            // in the active variant with the new version
            this.activeItem.variantVersions[index] = version;
          } else {
            // version is not in variant so add it
            this.activeItem.variantVersions.push(version);
            // we update the active item in collection
          }
          this.updateItemInCollection(this.activeItem);
        }
        this.nextItem();
      }
    );

    //-----------------------
  }

  get activeVariant() {
    return this.activeItem;
  }

  /**
   *  Downward propagation to update new loaded items into the subservice
   * @param variant
   */
  updateVariantService(variant: ProductVariantInterface) {
    this.updateItemInCollection(variant);
    variant.variantVersions.forEach((version: VariantVersionInterface) => {
      this.versionService.updateVersionService(version);
    });
  }

  deleteVersionsIn(variant: ProductVariantInterface) {
    variant.variantVersions.forEach((version) => {
      this.versionService.deleteLocalVersion(version);
    });
    let index = this.collection.findIndex((x) => this.idCompare(x, variant));
    if (index >= 0) {
      this.collection.splice(index, 1);
    }
  }

  setActiveVariant(
    variant: ProductVariantInterface | number
  ): Promise<boolean> {
    let id = this.toID(variant);
    this.userService.ACTIVE_MAP[":variantId"] = String(id);
    return this.setActiveItem(variant);
  }
}
