import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "booleanToString",
})
export class BooleanToStringPipe implements PipeTransform {
  transform(value: boolean, args?: any): string {
    if (value == null) {
      return "";
    } else if (value === true) {
      return "Ja";
    } else {
      return "Nein";
    }
    return "";
  }
}
