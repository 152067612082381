<h1 mat-dialog-title>Allgemeine Merkmale auswählen</h1>
<!------------------------------------------------------------------------------>
<div class="productCommonFeatureEditDialog" mat-dialog-content>
  <!---------------------------------------------------------------------->
  <div class="allFeatures">
    <h3>Verfügbare allgemeine Merkmale</h3>
    <div #TextSearch>
      <!----------------------------------------------------->
      <mat-form-field>
        <mat-label>Suchen</mat-label>
        <input
          matInput
          placeholder="Suchen"
          id="searchText"
          (keyup)="updateAvailableCommonFeature()"
        />
      </mat-form-field>
      <!----------------------------------------------------->
      <button
        class="btn_red clearSearchText"
        mat-raised-button
        (click)="clearSearchText()"
      >
        X
      </button>
      <!----------------------------------------------------->
    </div>
    <!------------------------------------------------------------->
    <div class="availableFeatureContainer">
      <div
        *ngFor="let feature of availableCommonFeatures; let i = index"
        class="feature"
      >
        <!----------------------------------------------------->
        <div class="featureHeadline">
          <p>
            <b>{{ feature.name }}</b>
          </p>
          <button
            class="btn_green"
            mat-raised-button
            [id]="'expandButton' + i"
            (click)="toggleFeatureExpand(i)"
          >
            Ausklappen
          </button>
        </div>
        <div *ngIf="expandedFeature[i]" class="featureEntries">
          <div
            *ngFor="
              let entry of removeSelectedEntriesFrom(feature.featureEntries);
              let j = index
            "
            class="featureEntry"
          >
            <p>{{ entry.name }}</p>
            <button
              class="btn_green"
              mat-raised-button
              (click)="selectFeatureEntry(feature, entry)"
            >
              Auswählen
            </button>
          </div>
        </div>
        <!----------------------------------------------------->
      </div>
    </div>
    <!------------------------------------------------------------->
  </div>
  <!---------------------------------------------------------------------->
  <div class="selectedFeatures">
    <h3>Ausgewählte allgemeine Merkmale</h3>
    <div>
      <mat-form-field>
        <mat-label>Suchen</mat-label>
        <input
          matInput
          placeholder="Suchen"
          id="searchTextSelected"
          (keyup)="updateShownSelectedCommonFeatures()"
        />
      </mat-form-field>
      <button
        class="btn_red clearSearchText"
        mat-raised-button
        (click)="clearSearchTextSelected()"
      >
        X
      </button>
    </div>
    <div class="selectedFeatureContainer">
      <div
        *ngFor="let selected of shownSelectedFeatures; let k = index"
        class="selectedFeature"
      >
        <div class="selectedFeatureEntry">
          <span style="margin: 1em"
            >{{ selected.feature.name }}: {{ selected.entry.name }}</span
          >
          <button
            class="btn_red"
            mat-raised-button
            (click)="removeSelectedFeature(k)"
          >
            Entfernen
          </button>
        </div>
      </div>
    </div>
  </div>
  <!---------------------------------------------------------------------->
</div>
<!------------------------------------------------------------------------------>
<div mat-dialog-actions>
  <button class="btn_red" mat-raised-button (click)="cancel()">
    Abbrechen
  </button>
  <button class="btn_green" mat-raised-button (click)="save()">
    Speichern
  </button>
</div>
