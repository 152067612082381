import { Component, OnInit, OnDestroy, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { CamundaActionsService } from '../camunda-rest/actions';
import { WebsocketComponent } from '../web-socket/web-socket.component';
import { ComposerScreenComponent } from '../../composer/composer-screen/composer-screen.component';

@Component({
  selector: 'app-camunda-process-list',
  templateUrl: './camunda-process-list.component.html',
  styleUrls: ['./camunda-process-list.component.css']
})
export class CamundaProcessListComponent implements OnInit, OnDestroy {
  @ViewChild('websocket') websocket!: WebsocketComponent;
  @Input() xml: any;
  
  processDefinition: any;
  processDefinitionXML: any;
  selectedProcessId: string | null = null;
  socket: WebsocketComponent | null = null;
  private socketSubscription: Subscription = new Subscription();

  constructor(
    private router: Router, 
    private camundaActionsService: CamundaActionsService,
    private cdr: ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {
  try {
    const data = await this.camundaActionsService.loadAllProcessDefinitionsWithXML();
    this.processDefinition = data;
    this.fetchProcessXMLForAllProcesses();
  } catch (error) {
    console.error('Error loading process definitions:', error);
  }
    this.cdr.detectChanges(); 
}

  ngOnDestroy(): void {
    if (this.websocket) { this.websocket.closeWebSocket();}
    this.socketSubscription.unsubscribe();
  }

  public addNewTemplate() {
    this.router.navigateByUrl("/composer/template/new");
  }

  async fetchProcessXMLForAllProcesses() {
  if (this.processDefinition && this.processDefinition.length > 0) {
    const promises = this.processDefinition.map(async (process: any, index: number) => {
      const xmlDataArray = await this.camundaActionsService.loadProcessDefinitionXML(process.id);
      this.processDefinition[index].diagram = xmlDataArray;
      this.processDefinition[index].xml = xmlDataArray.bpmn20Xml;
      this.cdr.detectChanges(); 
    });

    await Promise.all(promises);
    this.cdr.detectChanges(); 
  }
}

  handleItemClick(processId: string): void {
    this.selectedProcessId = processId;
  }

  sendMessage(process: any): void {
    if (!this.websocket || this.websocket.getReadyState() !== WebSocket.OPEN) {
      console.error('WebSocket connection not open.');
      return;
    }
    this.websocket.sendMessage(JSON.stringify(process));
  }

  navigateToStartProcess(processKey: string, process: any): void {
    this.handleItemClick(process.id);
    this.sendMessage(process);
    const navigationExtras: NavigationExtras = {
      state: { process: process }
    };
    this.router.navigate(['/composer/startprocess'], {
      state: { 
        process: process }
    });  
  }
}
