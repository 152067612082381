import { FileServiceService } from 'src/app/services/file-service/file-service.service';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { CertificationRequestInterface } from './../../../../model/certifications/certification-request-interface';
import { UserService } from 'src/app/services/user-service/userservice';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { ProductInterface } from 'src/app/model/products/product-interface';
import { ProductVariantInterface } from 'src/app/model/products/product-variant-interface';
import { VariantVersionInterface } from 'src/app/model/products/variant-version-interface';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { ProductVariantService } from 'src/app/services/dataServices/product-variant-service/product-variant.service';
import { ProductVariantVersionService } from 'src/app/services/dataServices/product-variant-version-service/product-variant-version.service';
import { Router } from '@angular/router';
import { CertificationRequestLogInterface } from 'src/app/model/certifications/certification-request-log-interface';
import { CertificationRequestService } from 'src/app/services/dataServices/certification-request/certification-request.service';

@Component({
  selector: 'version-certification-request',
  templateUrl: './version-certification-request.component.html',
  styleUrls: ['./version-certification-request.component.css']
})
export class VersionCertificationRequestComponent implements OnInit {
  private product: ProductInterface;
  private variant: ProductVariantInterface;
  private version: VariantVersionInterface;
  private certificationRequest: CertificationRequestInterface = {};
  step: number;
  private filesById;

  testList: string[] = new Array("Merkmal1", "Merkmal2", "Merkmal3");
  testList2: string[] = new Array("PTG1", "PTG2", "PTG3");

  constructor(
    private productService: ProductService,
    private productVariantService: ProductVariantService,
    private productVariantVersionService: ProductVariantVersionService,
    private router: Router,
    private organisationService: OrganisationService,
    private userService: UserService,
    private dialogService: DialogService,
    private certificationRequestService: CertificationRequestService,
    private cd: ChangeDetectorRef,
  ) { 
    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnInit() {
    this.product = this.productService.activeProduct;
    this.variant = this.productVariantService.activeItem;
    this.version = this.productVariantVersionService.activeItem;
    this.step = 0;
    this.certificationRequest.requestingOrganisationName = this.organisationService.activeItem.organisationName;
    this.certificationRequest.productName = this.product.name;
    this.certificationRequest.productVariant = this.variant.name;
    this.certificationRequest.productVersion = this.version.name;
  }

  showCriteria() {  }

  nextStep() {
    this.step++;
  }

  stepBack() {
    if(this.step > 0) {
      this.step--;
    }
  }

  sendRequest() {
    this.dialogService
      .openConfirmDialog(
        "Zertifizierungsantrag abschicken",
        "Nein",
        "Ja",
        "Wollen Sie Ihre Zertifizierungsanfrage jetzt abschicken?"
      )
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.certificationRequestService.createCertificationRequest(this.certificationRequest);
          this.router.navigate([
            "/organisations/" +
              this.organisationService.activeItem.organisationId +
              "/products/" +
              this.productService.activeItem.id +
              "/variants/" +
              this.productVariantService.activeItem.id
          ]);
        }
      });
  }

  cancelCertificationProcess() {
    this.dialogService
      .openConfirmDialog(
        "Zertifizierungsantrag abbrechen",
        "Nein",
        "Ja",
        "Wollen Sie Ihre Zertifizierungsanfrage wirklich abbrechen?"
      )
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.router.navigate([
            "/organisations/" +
              this.organisationService.activeItem.organisationId +
              "/products/" +
              this.productService.activeItem.id +
              "/variants/" +
              this.productVariantService.activeItem.id
          ]);
        }
      });
  }
}
