import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "excerpt",
})
export class ExcerptPipe implements PipeTransform {
  transform(value: string, length: number = 200): string {
    let text = value;

    if (value.length > length && length > 0) {
      text = value.slice(0, length) + " ...";
    }

    return text;
  }
}
