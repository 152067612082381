import { ProductInterface } from 'src/app/model/products/product-interface';
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { ProductService } from "../services/dataServices/product-service/productService";
import { OrganisationService } from "../services/dataServices/organisation-service/organisationservice";
import { PtgService } from '../services/dataServices/ptg-service/ptgService';
import { AttributeService } from '../services/dataServices/attribute-service/attributeService';
import { OrganisationInterface } from '../model/organisations/organisation-interface';
import { CertificationService } from '../services/dataServices/certification-service/certificationService';

@Injectable()
export class OrganisationDetailResolver implements Resolve<Promise<any>> {
  private products: ProductInterface[];

  constructor(
    private productService: ProductService,
    private organisationService: OrganisationService,
    private ptgService: PtgService,
    private attributeSerivce: AttributeService,
    private certificationService: CertificationService
  ) {}

  async resolve(route: ActivatedRouteSnapshot){
    if (
      this.ptgService.collection == null ||
      this.ptgService.collection.length == 0
    ) {
    /*  await this.ptgService.getAllPtgs().then(() => {
        if (this.ptgService.collection.length > 0) {
          this.ptgService.setActivePtg(this.ptgService.collection[0]).catch();
        }
      }); */

      this.attributeSerivce.getAllAttributes();
    }

    let organisation = await this.organisationService.getOrganisationByDomainOrName(route.params['organisationDomain'].substring(1)).then(
      (organisation: OrganisationInterface) => {
          return organisation;

      });

      await this.productService.loadProductsForOrganisation(organisation);
      await this.certificationService.loadCertificationsForOrganisation(organisation);
  }
}
