<div class="one-column" *ngIf="organisationService.activeItem">
  <h1>Mitglieder verwalten</h1>
  <div class="table">
    <div class="row">
      <div>
        <p><b>Mitglieder</b></p>
      </div>
      <div>
        <p><b>Rolle</b></p>
      </div>
    </div>
    <div
      *ngFor="
        let member of organisationService.activeItem.organisationMembers
          | confirmedMembers
      "
      class="row"
    >
      <div>
        <p>{{ member.userName }}</p>
      </div>
      <div *ngIf="isCurrentUserAdmin()">
        <mat-form-field>
          <mat-select
            [value]="member.role"
            (selectionChange)="changeMemberRole($event, member.userId)"
          >
            <mat-option [value]="'ADMIN'"
              >Administrator</mat-option
            >
            <mat-option [value]="'USER'"
              >Benutzer</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="!isCurrentUserAdmin()">
        <p>{{ getRole(member.role) }}</p>
      </div>
      <div *ngIf="isCurrentUserAdmin()">
        <app-hexagon-button-text-icon
          [text]="'Entfernen'"
          [icon]="'/assets/img/icons/discard.png'"
          [width]="1.5"
          (click)="removeMember(member)"
        >
        </app-hexagon-button-text-icon>
      </div>
    </div>
  </div>

  <button
    *ngIf="isCurrentUserAdmin()"
    mat-raised-button
    class="btn_green"
    (click)="updateOrganisationMembers()"
  >
    Alle Änderungen speichern
  </button>
  <br />

  <div *ngIf="isCurrentUserAdmin()">
    <app-hexagon-button-text-icon
      [text]="'Offene Mitglieder'"
      [icon]="'/assets/img/icons/Organisation_hinzufuegen.png'"
      (click)="openMembersModeToggle()"
    >
    </app-hexagon-button-text-icon>
  </div>

  <div *ngIf="openMembersMode">
    <div class="table">
      <div class="request-row">
        <div>
          <p><b>Offene Anfragen</b></p>
        </div>
        <div>
          <p><b>Rolle</b></p>
        </div>
        <div></div>
      </div>
      <div
        class="request-row"
        *ngFor="
          let member of organisationService.activeItem.organisationMembers
            | adminConfirmationPending
        "
      >
        <div>
          <p>{{ member.userName }}</p>
        </div>
        <div>
          <p>
            {{ getRole(member.role) }}
          </p>
        </div>
        <div>
          <app-hexagon-button-text-icon
            [text]="'Annehmen'"
            [icon]="'/assets/img/icons/Bestaetigen.png'"
            (click)="acceptMemberQuery(member.userId)"
          >
          </app-hexagon-button-text-icon>
        </div>
        <div>
          <app-hexagon-button-text-icon
            [text]="'Ablehnen'"
            [icon]="'/assets/img/icons/discard.png'"
            (click)="declineMemberQuery(member.userId)"
          >
          </app-hexagon-button-text-icon>
        </div>
      </div>
    </div>
    <div class="table">
      <div class="row">
        <div>
          <p><b>Offene Einladungen</b></p>
        </div>
        <div>
          <p><b>Rolle</b></p>
        </div>
        <div></div>
      </div>
      <div
        class="row"
        *ngFor="
          let member of organisationService.activeItem.organisationMembers
            | userConfirmationPending
        "
      >
        <div>
          <p>{{ member.userName }}</p>
        </div>
        <div>
          <p>
            {{ getRole(member.role) }}
          </p>
        </div>
        <div>
          <app-hexagon-button-text-icon
            [text]="'Entfernen'"
            [icon]="'/assets/img/icons/discard.png'"
            (click)="removeMember(member)"
          >
          </app-hexagon-button-text-icon>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isCurrentUserAdmin()">
    <app-hexagon-button-text-icon
      [text]="'Neue Mitglieder einladen'"
      [icon]="'/assets/img/icons/Organisation_hinzufuegen.png'"
      (click)="inviteMembersModeToggle()"
    >
    </app-hexagon-button-text-icon>
  </div>

  <div *ngIf="inviteMemberMode" class="columns-50-50">
    <div class="columns-50-50 invite-table">
      <mat-form-field>
        <input
          matInput
          placeholder="E-Mail des Benutzers"
          [(ngModel)]="userToInvite.userName"
          pattern="[^@]+@[^@]+\.[^@]+"
        />
        <mat-hint>Bitte eine valide E-Mail angeben.</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="Rolle" [(value)]="userToInvite.role">
          <mat-option [value]="'ADMIN'">Administrator</mat-option>
          <mat-option [value]="'USER'">Benutzer</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <hexagon
      [outerColor]="'white'"
      [middleColor]="'green'"
      [icon]="'plus'"
      [hover]="'true'"
      (click)="addOneEntry()"
      class="add-button"
    ></hexagon>
    <table
      mat-table
      [dataSource]="dataSource"
      class="invite-table"
      *ngIf="getInvites()"
    >
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef class="header-table">E-Mail</th>
        <td
          mat-cell
          *matCellDef="let user"
          class="name-column-table column-table"
        >
          {{ user.userName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef class="header-table">Rolle</th>
        <td
          mat-cell
          *matCellDef="let user"
          class="role-column-table column-table"
        >
          {{ getRole(user.role) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef class="header-table"></th>
        <td mat-cell *matCellDef="let user" class="column-table">
          <app-hexagon-button-text-icon
            [text]="'Entfernen'"
            [icon]="'/assets/img/icons/discard.png'"
            [width]="1.5"
            (click)="removeEntry(user)"
          ></app-hexagon-button-text-icon>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    </table>
    <div *ngIf="getInvites()"></div>
    <div class="columns-50-50">
      <button
        class="btn_green"
        mat-raised-button
        (click)="inviteMembersConfirm()"
      >
        Ok
      </button>
      <button
        class="btn_red"
        mat-raised-button
        (click)="inviteMembersModeToggle()"
      >
        Abbrechen
      </button>
    </div>
  </div>

  <div *ngIf="isCurrentUserAdmin()">
    <app-hexagon-button-text-icon
      [text]="'Organisation überschreiben'"
      [icon]="'/assets/img/icons/Organisation_hinzufuegen.png'"
      (click)="transmitMembersModeToggle()"
    >
    </app-hexagon-button-text-icon>
  </div>

  <div *ngIf="transmitMemberMode" class="columns-50-50">
    <mat-form-field>
      <input
        matInput
        placeholder="E-Mail des Benutzers"
        [(ngModel)]="userToInvite.userName"
      />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Nachricht" [(ngModel)]="message" />
    </mat-form-field>
    <div *ngIf="enableSendBtn" class="columns-50-50">
      <button class="btn_green" mat-raised-button (click)="transmitConfirm()">
        Senden
      </button>

      <button
        class="btn_red"
        mat-raised-button
        (click)="transmitMembersModeToggle()"
      >
      Abbrechen
      </button>
    </div>
    <div *ngIf="enableSendBtn" class="columns-50-50">
      <font size="3" color="green">{{ messageTransmitResponse }}</font>
    </div>
  </div>

  <app-hexagon-button-text-icon
    *ngIf="hasContacts"
    [text]="'Kontakte der Organisation'"
    [icon]="'/assets/img/icons/Organisation_hinzufuegen.png'"
    (click)="organisationContactsToggle()"
  >
  </app-hexagon-button-text-icon>

  <app-organisation-contact-overview
    *ngIf="showOrganisationContacts"
    (sent)="organisationContactsToggle()"
    [isViewForAdmin]="isCurrentUserAdmin()"
  ></app-organisation-contact-overview>
</div>
