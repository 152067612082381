import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { ProductVariantService } from "src/app/services/dataServices/product-variant-service/product-variant.service";
import { ProductVariantVersionService } from "src/app/services/dataServices/product-variant-version-service/product-variant-version.service";
import { UserService } from "src/app/services/user-service/userservice";
import { VariantVersionInterface } from "src/app/model/products/variant-version-interface";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { ProductService } from "src/app/services/dataServices/product-service/productService";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { UtilService } from "src/app/services/util-service/utilService";

@Component({
  selector: "app-product-variant-screen",
  templateUrl: "./product-variant-screen.component.html",
  styleUrls: [
    "./product-variant-screen.component.css",
    "../../../../../app.component.css",
  ],
})
export class ProductVariantScreenComponent implements OnInit, OnDestroy {
  public activeVersion: VariantVersionInterface;
  public activeVersionPtgs: string[] = [];

  public doneLoading = false;

  private activeItemSub: Subscription;
  private productListSub: Subscription;

  private certificate: String = "Testzertifikat";

  get isOrgaAdminOrPlattformAdmin() {
    return this.userService.isUserAdminOfOrganisationOrPlattformAdmin(
      this.organisationService.activeItem.organisationId
    );
  }

  constructor(
    public productService: ProductService,
    public router: Router,
    public organisationService: OrganisationService,
    public ptgService: PtgService,
    public utilService: UtilService,
    public productVariantService: ProductVariantService,
    public versionService: ProductVariantVersionService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private titleService: Title
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  routeEditVariant(): void {
    this.router.navigate([
      "organisations/" +
        this.organisationService.activeItem.organisationId +
        "/products/" +
        this.productService.activeItem.id +
        "/variants/" +
        this.productVariantService.activeItem.id +
        "/edit",
    ]);
  }

  editVariantVersionPress(versionId: number): void {
    this.versionService.ITEM_MAP[":versionId"] = String(versionId);
    this.versionService
      .setActiveVersion(versionId)
      .then(() => {
        var url =
          this.router.routerState.snapshot.url.toString() +
          "/version/" +
          versionId +
          "/edit";
        this.router.navigate([url]);
      })
      .catch(() => {});
  }

  addVariantVersionPress(versionId: number): void {
    this.versionService.ITEM_MAP[":versionId"] = String(versionId);

    var url =
      this.router.routerState.snapshot.url.toString() + "/version/" + versionId + "/create";
    this.router.navigate([url]);
  }

  showPrevious(id: number) {
    let v = this.versionService.collection.find((v) => {
      return v.id === id;
    });
    if (!v) {
      return "-";
    }
    return v.name;
  }

  showInfoOfVersion(versionId: number): void {
    this.activeVersionPtgs = [];
    if (this.activeVersion && this.activeVersion.id === versionId) {
      this.activeVersion = null;
      return;
    }
    this.activeVersion = this.productVariantService.activeItem.variantVersions.filter(
      (version) => version.id === versionId
    )[0];
  }

  deleteVariant() {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie die Variante wirklich löschen?",
        "Abbrechen",
        "Löschen"
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.productVariantService.ITEM_MAP[":organisationId"] = String(
            this.productService.activeItem.company
          );
          this.productVariantService.ITEM_MAP[":productId"] = String(
            this.productService.activeItem.id
          );

          this.productVariantService.ITEM_MAP[":variantId"] = String(
            this.productVariantService.activeItem.id
          );
          this.productVariantService
            .deleteItem(this.productVariantService.activeItem)
            .then(
              async () => {
                // On success, route to product overview and reload organisationProducts
                await this.productService.loadProductsForOrganisation(
                  this.organisationService.activeItem
                );
                let url: string =
                  "/organisations/" +
                  this.productVariantService.ITEM_MAP[":organisationId"] +
                  "/products/" +
                  this.productVariantService.ITEM_MAP[":productId"];
                this.router.navigateByUrl(url);
                this.dialogService.openDialog(
                  "Variant gelöscht",
                  "Die Variante wurde erfolgreich gelöscht."
                );
              },
              () => {
                // On error, alert user
                this.dialogService.openDialog(
                  "Variant nicht gelöscht",
                  "Die letzte Variante eines Produktes darf nicht gelöscht werden."
                );
              }
            )
            .catch((e) => {
              console.error(e);
            });
        }
      });
  }

  async deleteVariantVersionPress(versionId: number) {
    await this.versionService.setActiveVersion(versionId);
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie die Version wirklich löschen?",
        "Abbrechen",
        "Löschen"
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.versionService
            .deleteItem(this.versionService.activeItem)
            .then(
              async () => {
                // On success, reload page
                this.dialogService.openDialog(
                  "Version gelöscht",
                  "Die Version wurde erfolgreich gelöscht."
                );
                this.activeVersion = null;
                this.cd.detectChanges();
              },
              () => {
                // On error, alert user
                this.dialogService.openDialog(
                  "Version nicht gelöscht",
                  "Die letzte Version einer Zertifizierungsvariante darf nicht gelöscht werden."
                );
              }
            )
            .catch((e) => {
              console.error(e);
            });
        }
      });
  }

  private requestCertification(versionId: number) {
    this.versionService.ITEM_MAP[":versionId"] = String(versionId);
    this.versionService
      .setActiveVersion(versionId)
      .then(() => {
        var url =
          this.router.routerState.snapshot.url.toString() +
          "/version/" +
          versionId +
          "/certification";
        this.router.navigate([url]);
      })
      .catch(() => {});
  }


  //MISSING HERE: SWITCHING BETWEEN DIFFERENT REQUESTS
  private showCertificationRequestStatus(versionId: number) {
    let certificationRequestId: number = 1;
    this.versionService.ITEM_MAP[":versionId"] = String(versionId);
    this.versionService
      .setActiveVersion(versionId)
      .then(() => {
        var url =
          this.router.routerState.snapshot.url.toString() +
          "/version/" +
          versionId +
          "/certificationRequests"
        this.router.navigate([url]);
      })
      .catch(() => {});
  }

  ngOnInit() {
    this.versionService.ITEM_MAP[":organisationId"] = String(
      this.organisationService.activeItem.organisationId
    );
    this.versionService.ITEM_MAP[":productId"] = String(
      this.productService.activeItem.id
    );
    this.versionService.ITEM_MAP[":variantId"] = String(
      this.productVariantService.activeItem.id
    );

    this.ptgService.itemCollectionObserver.subscribe(
      () => {},
      (e) => {}
    );

    this.productListSub = this.productService.itemCollectionObserver.subscribe(
      (e) => {
        if (this.productVariantService.activeItem) {
          // this.productVariantService.setActiveVariant(this.productVariantService.activeItem.id);
          this.cd.detectChanges();
        }
      }
    );
    //this.productService.fetchAllProducts(true);

    this.activeItemSub = this.productVariantService.activeItemObserver.subscribe(
      (x: VariantVersionInterface) => {
        this.activeVersion = this.productVariantService.activeItem.variantVersions[0];
        this.titleService.setTitle(this.productService.activeItem.name + " Variante: " +
                                     this.productVariantService.activeItem.name + " - BIMSWARM");
        this.cd.detectChanges();
      }
    );
    this.cd.detectChanges();
    /*
    this.routerSubscription = this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.activeVersion = null;
        this.cd.detectChanges();
      }); */
  }

  ngOnDestroy() {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
    if (this.activeItemSub) {
      this.activeItemSub.unsubscribe();
    }

    if (this.productListSub) {
      this.productListSub.unsubscribe();
    }
    //this.routerSubscription.unsubscribe();
  }
}
