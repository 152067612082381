import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { ContactInterface } from "src/app/model/organisations/contact-interface";
import { FormArray, FormGroup, FormControl, Validators } from "@angular/forms";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { GENDERS } from 'src/environments/constants';

@Component({
  selector: "app-organisation-contact-overview",
  templateUrl: "./organisation-contact-overview.component.html",
  styleUrls: ["./organisation-contact-overview.component.css"],
})
export class OrganisationContactOverviewComponent implements OnInit {
  @Output()
  private sent = new EventEmitter();

  public contacts: ContactInterface[];
  private formArray: FormArray;
  private formGroup: FormGroup;

  @Input()
  private isViewForAdmin: boolean = false;

  public genders = GENDERS;

  constructor(
    private organisationService: OrganisationService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    const activeOrg = this.organisationService.activeItem;
    if (activeOrg == null) {
      this.sent.emit();
      return;
    }
    this.contacts = activeOrg.contacts;
    let formGroups: FormGroup[] = [];
    for (const contact of this.contacts) {
      const formGroup: FormGroup = new FormGroup({
        contactName: new FormControl(contact.contactName, [
          Validators.required,
          Validators.pattern(/[A-ZÜÖÄ][ A-Za-z-üöä]+/),
        ]),
        phoneNumber: new FormControl(
          contact.phoneNumber,
          Validators.pattern(/[\w\d-+]+/)
        ),
        mobilePhoneNumber: new FormControl(
          contact.mobilePhoneNumber,
          Validators.pattern(/[\w\d-+]+/)
        ),
        email: new FormControl(contact.email, [
          Validators.required,
          Validators.pattern(/[^@]+@[^@]+\.[^@]+/),
        ]),
        website: new FormControl(
          contact.website,
          Validators.pattern(/[\w\düöä-]+[.][\w]+/)
        ),
        gender: new FormControl(contact.gender),
      });
      formGroups.push(formGroup);
    }
    this.formArray = new FormArray(formGroups);
    this.formGroup = new FormGroup({ formArray: this.formArray });
    if(!this.isViewForAdmin) this.formGroup.disable();
  }

  private removeContact(index: number) {
    this.formArray.removeAt(index);
    this.contacts.splice(index, 1);
  }

  private save(): void {
    for (let i = 0; i < this.contacts.length; i++) {
      let formGroup = this.formArray.at(i);
      if (formGroup.touched) {
        let contact = this.contacts[i];
        contact.contactName = formGroup.get("contactName").value;
        contact.phoneNumber = formGroup.get("phoneNumber").value;
        contact.mobilePhoneNumber = formGroup.get("mobilePhoneNumber").value;
        contact.email = formGroup.get("email").value;
        contact.website = formGroup.get("website").value;
        contact.gender = formGroup.get("gender").value;
      }
    }
    const activeOrgId = this.organisationService.activeItem.organisationId;
    this.organisationService
      .updateOrganisationContacts(activeOrgId, this.contacts)
      .subscribe(() => {
        this.dialogService.openDialog(
          "Kontakte erfolgreich geändert",
          "Die Kontakte der Organisation wurden erfolgreich bearbeitet."
        );
      });
    this.cancel();
  }

  private cancel(): void {
    this.sent.emit();
  }
}
