<form [formGroup]="form">
  <div *ngFor="let control of inputs?.controls; index as i">
    <div [ngSwitch]="input.controlTypes[0]">
        <span class="addDelete">
        <div *ngSwitchCase="'textbox'">
          <mat-form-field [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
            <mat-label>{{input.name}}  <span *ngIf="hasRequiredValidator(control)" aria-required="true" class="required"></span>
            </mat-label>
            <input [formControl]="inputs.controls[i]" [matTooltip]="input.description[this.translate.currentLang]" placeholder="{{input.description[this.translate.currentLang]}}" matInput>
          </mat-form-field>
          <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                  mat-icon-button>
            <mat-icon>add</mat-icon>
          </button>
          <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=input.minCount" color="warn" mat-icon-button type="button">
            <mat-icon>delete icon</mat-icon>
          </button>
          <mat-error>
            <app-show-errors [ctrl]="inputs.controls[i]" [label]="input.name"></app-show-errors>
          </mat-error>
        </div>
      </span>
      <div *ngSwitchCase="'textbox_integer'">
        <mat-form-field [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
          <mat-label>{{input.name}}  <span *ngIf="hasRequiredValidator(control)" aria-required="true" class="required"></span>
          </mat-label>
          <input [formControl]="inputs.controls[i]" [matTooltip]="input.description[this.translate.currentLang]" placeholder="{{input.description[this.translate.currentLang]}}" appNumeric matInput type="number">
        </mat-form-field>
        <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                mat-icon-button>
          <mat-icon>add</mat-icon>
        </button>
        <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=input.minCount" color="warn" mat-icon-button type="button">
          <mat-icon>delete icon</mat-icon>
        </button>
        <mat-error>
          <app-show-errors [ctrl]="inputs.controls[i]" [label]="input.name"></app-show-errors>
        </mat-error>
      </div>

      <div *ngSwitchCase="'textbox_decimal'">
        <mat-form-field [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
          <mat-label>{{input.name}}  <span *ngIf="hasRequiredValidator(control)" aria-required="true" class="required"></span>
          </mat-label>
          <input [formControl]="inputs.controls[i]" [matTooltip]="input.description[this.translate.currentLang]" placeholder="{{input.description[this.translate.currentLang]}}" appNumeric decimals="2" matInput
                 type="number">
        </mat-form-field>
        <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                mat-icon-button>
          <mat-icon>add</mat-icon>
        </button>
        <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=input.minCount" color="warn" mat-icon-button type="button">
          <mat-icon>delete icon</mat-icon>
        </button>
        <mat-error>
          <app-show-errors [ctrl]="inputs.controls[i]" [label]="input.name"></app-show-errors>
        </mat-error>
      </div>

      <div *ngSwitchCase="'textbox_uri'">
        <mat-form-field [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
          <mat-label>{{input.name}}  <span *ngIf="hasRequiredValidator(control)" aria-required="true" class="required"></span>
          </mat-label>
          <input [formControl]="inputs.controls[i]" [matTooltip]="input.description[this.translate.currentLang]" placeholder="{{input.description[this.translate.currentLang]}}" matInput>
        </mat-form-field>
        <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                mat-icon-button>
          <mat-icon>add</mat-icon>
        </button>
        <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=input.minCount" color="warn" mat-icon-button type="button">
          <mat-icon>delete icon</mat-icon>
        </button>
        <mat-error>
          <app-show-errors [ctrl]="inputs.controls[i]" [label]="input.name"></app-show-errors>
        </mat-error>
      </div>

      <div *ngSwitchCase="'datepicker'">
        <mat-form-field [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
          <mat-label>{{input.name}}  <span *ngIf="hasRequiredValidator(control)" aria-required="true"></span>
          </mat-label>
          <input [formControl]="inputs.controls[i]" [matDatepicker]="picker" matInput>
          <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                mat-icon-button>
          <mat-icon>add</mat-icon>
        </button>
        <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=input.minCount" color="warn" mat-icon-button type="button">
          <mat-icon>delete icon</mat-icon>
        </button>
        <mat-error>
          <app-show-errors [ctrl]="inputs.controls[i]" [label]="input.name"></app-show-errors>
        </mat-error>
      </div>

      <div *ngSwitchCase="'datetimepicker'">
        <mat-form-field [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
          <mat-label>{{input.name}}  <span *ngIf="hasRequiredValidator(control)" aria-required="true" class="required"></span>
          </mat-label>
          <input [formControl]="inputs.controls[i]" [ngxMatDatetimePicker]="picker" matInput>
          <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
        </mat-form-field>
        <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                mat-icon-button>
          <mat-icon>add</mat-icon>
        </button>
        <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=input.minCount" color="warn" mat-icon-button type="button">
          <mat-icon>delete icon</mat-icon>
        </button>
        <mat-error>
          <app-show-errors [ctrl]="inputs.controls[i]" [label]="input.name"></app-show-errors>
        </mat-error>
      </div>

      <div *ngSwitchCase="'dropdown'">
        <mat-form-field [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
          <mat-label>{{input.name}}  <span *ngIf="hasRequiredValidator(control)" aria-required="true" class="required"></span>
          </mat-label>
          <mat-select [formControl]="inputs.controls[i]" [multiple]="false">
            <mat-option *ngFor="let option of input.in" [value]="option.value">
              {{option.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                mat-icon-button>
          <mat-icon>add</mat-icon>
        </button>
        <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=input.minCount" color="warn" mat-icon-button type="button">
          <mat-icon>delete icon</mat-icon>
        </button>
        <mat-error>
          <app-show-errors [ctrl]="inputs.controls[i]" [label]="input.name"></app-show-errors>
        </mat-error>
      </div>

      <div *ngSwitchCase="'dropdown_object'">
        <app-dynamic-form-in [control]="inputs.controls[i]" [form]="form" [input]="input"
                             [shapes]="shapes"></app-dynamic-form-in>
      </div>
    </div>
