<div [formGroup]="form">
  <div class="text-block">
      <div class="feedback">
          <mat-form-field class="input" appearance="outline">
              <textarea matInput rows="10" cols="30" formControlName="text" placeholder="Verfassen Sie einen Kommentar." required>
              </textarea>
              <mat-error *ngIf="form.get('text').invalid">{{getErrorMessageText()}}</mat-error>
          </mat-form-field>
      </div>
  </div>

  <div>
    <button mat-raised-button class="btn_green" (click)="sendComment()">Kommentar absenden</button>
    <button mat-raised-button class="btn_red" (click)="cancel()">Abbrechen</button>
  </div>
</div>
<br>
