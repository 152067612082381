import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CommonFeatureInterface } from 'src/app/model/commonFeature/common-feature-interface';
import { CommonFeatureService } from "src/app/services/common-feature-service/common-feature.service";
import { CommonFeatureItemEditFormComponent } from "../common-feature-item-edit-form/common-feature-item-edit-form.component";

@Component({
  selector: "app-common-feature-add",
  templateUrl: "./common-feature-add.component.html",
  styleUrls: ["./common-feature-add.component.css"],
})
export class CommonFeatureAddComponent implements OnInit {
  @ViewChild(CommonFeatureItemEditFormComponent)
  private child: CommonFeatureItemEditFormComponent;

  @Output()
  public addedFeature = new EventEmitter<CommonFeatureInterface>();

  public showNewFeature: boolean = false;
  public tempFeatureEntries: string[] = [];
  public featureAdded: boolean = false;

  constructor(
    private commonFeatureService: CommonFeatureService  ) {}

  resetState(): void {
    this.showNewFeature = false;
    this.tempFeatureEntries = [];
  }

  ngOnInit() {}

  /* Toggles the Frontend Form to add a Feature */
  public toggleNewFeatureForm(): void {
    this.showNewFeature = !this.showNewFeature;

    if (this.showNewFeature) {
      this.featureAdded = false;
    }
  }

  /* Takes the Form Values and calls the Service to create a new Feature with that Values */
  public addNewFeature(): void {
    const formValues = this.child.getElements();
    if (formValues) {
      this.commonFeatureService
        .addNewFeature(
          formValues.name,
          formValues.filterOption,
          formValues.featureEntries,
          formValues.showDetailPage,
          formValues.linkerMethod
        )
        .subscribe(
          (success) => {
            this.resetState();
            this.featureAdded = true;
            this.addedFeature.emit(success)
          },
          () => {}
        );
    }
  }
}
