<div class="fullheight2">
  <mat-tab-group #tab class="stickytabgroup" mat-align-tabs="center" [color]="'primary'" [backgroundColor]="'primary'"
                 (selectedTabChange)=" changeQueryTab($event)">


    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">category</mat-icon>
        Alle
      </ng-template>
      <ng-template matTabContent>
        <div class="fullheight">
          <div class="centerSpinner" *ngIf="this.spinner">
            <mat-spinner></mat-spinner>
          </div>
          <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #drawer class="sidenav" *ngIf="!this.spinner"
              [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
              [mode]="(isHandset$ | async) ? 'over' : 'side'" [disableClose]="true" [opened]="(isHandset$ | async) === false && !showDetails">
              <div *ngIf="marketScreenState === 'PRODUCT'" class="sidefilter">
                <app-market-place-side-filter></app-market-place-side-filter>
              </div>
            </mat-sidenav>
            <mat-sidenav-content *ngIf="showNoResults">
              <h1 style="font-size: xx-large">No results found.</h1>
              <br>
              <h1>Try changing the filters.</h1>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="!this.isLoggedIn() && !this.spinner && !showNoResults">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner" *ngIf="this.displaySpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="this.isLoggedIn() && !this.spinner">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item"
                    [bookmarked]="isBookmarked(item.id, 1)">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item" [bookmarked]="isBookmarked(item.id, 1)">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner"
                  *ngIf="this.displaySpinner && !this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav [opened]="showDetails" mode="side" position="end" class="product-details">
              <app-product-detail-display *ngIf="showDetails && this.actualProduct.category != 'EDGESERVER'" [actualProduct]="this.actualProduct" (goBack)="goBack()" (reloadDisplayItems)="loadDisplayItem($event)" >
              </app-product-detail-display>
              <app-server-detail-display *ngIf="showDetails && this.actualProduct.category === 'EDGESERVER'" [actualProduct]="this.actualProduct" (goBack)="goBack()" (reloadDisplayItems)="loadDisplayItem($event)" >
              </app-server-detail-display>
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">category</mat-icon>
        Software
      </ng-template>
      <ng-template matTabContent>
        <div class="fullheight">
          <div class="centerSpinner" *ngIf="this.spinner">
            <mat-spinner></mat-spinner>
          </div>
          <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #drawer class="sidenav" *ngIf="!this.spinner"
              [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
              [mode]="(isHandset$ | async) ? 'over' : 'side'" [disableClose]="true" [opened]="(isHandset$ | async) === false && !showDetails">
              <div *ngIf="marketScreenState === 'PRODUCT'" class="sidefilter">
                <app-market-place-side-filter></app-market-place-side-filter>
              </div>
            </mat-sidenav>
            <mat-sidenav-content *ngIf="showNoResults">
              <h1 style="font-size: xx-large">No results found.</h1>
              <br>
              <h1>Try changing the filters.</h1>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="!this.isLoggedIn() && !this.spinner && !showNoResults">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner" *ngIf="this.displaySpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="this.isLoggedIn() && !this.spinner">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item"
                    [bookmarked]="isBookmarked(item.id, 1)">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item" [bookmarked]="isBookmarked(item.id, 1)">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner"
                  *ngIf="this.displaySpinner && !this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav [opened]="showDetails" mode="side" position="end" class="product-details">
              <app-product-detail-display *ngIf="showDetails && this.actualProduct.category != 'EDGESERVER'" [actualProduct]="this.actualProduct" (goBack)="goBack()" (reloadDisplayItems)="loadDisplayItem($event)" >
              </app-product-detail-display>
              <app-server-detail-display *ngIf="showDetails && this.actualProduct.category === 'EDGESERVER'" [actualProduct]="this.actualProduct" (goBack)="goBack()" (reloadDisplayItems)="loadDisplayItem($event)" >
              </app-server-detail-display>
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </ng-template>
    </mat-tab>


    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">category</mat-icon>
        Content
      </ng-template>
      <ng-template matTabContent>
        <div class="fullheight">
          <div class="centerSpinner" *ngIf="this.spinner">
            <mat-spinner></mat-spinner>
          </div>
          <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #drawer class="sidenav" *ngIf="!this.spinner"
              [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
              [mode]="(isHandset$ | async) ? 'over' : 'side'" [disableClose]="true" [opened]="(isHandset$ | async) === false && !showDetails">
              <div *ngIf="marketScreenState === 'CONTENT'" class="sidefilter">
                <app-market-place-side-filter></app-market-place-side-filter>
              </div>
            </mat-sidenav>
            <mat-sidenav-content *ngIf="showNoResults">
              <h1 style="font-size: xx-large">No results found.</h1>
              <br>
              <h1>Try changing the filters.</h1>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="!this.isLoggedIn() && !this.spinner && !showNoResults">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner" *ngIf="this.displaySpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="this.isLoggedIn() && !this.spinner">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item"
                    [bookmarked]="isBookmarked(item.id, 1)">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item" [bookmarked]="isBookmarked(item.id, 1)">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner"
                  *ngIf="this.displaySpinner && !this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav [opened]="showDetails" mode="side" position="end" class="product-details">
              <app-product-detail-display *ngIf="showDetails && this.actualProduct.category != 'EDGESERVER'" [actualProduct]="this.actualProduct" (goBack)="goBack()" (reloadDisplayItems)="loadDisplayItem($event)" >
              </app-product-detail-display>

            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </ng-template>
    </mat-tab>



    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">category</mat-icon>
        Dienstliestungen
      </ng-template>
      <ng-template matTabContent>
        <div class="fullheight">
          <div class="centerSpinner" *ngIf="this.spinner">
            <mat-spinner></mat-spinner>
          </div>
          <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #drawer class="sidenav" *ngIf="!this.spinner"
              [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
              [mode]="(isHandset$ | async) ? 'over' : 'side'" [disableClose]="true" [opened]="(isHandset$ | async) === false && !showDetails">
              <div *ngIf="marketScreenState === 'DIENSTLIESTUNGEN'" class="sidefilter">
                <app-market-place-side-filter></app-market-place-side-filter>
              </div>
            </mat-sidenav>
            <mat-sidenav-content *ngIf="showNoResults">
              <h1 style="font-size: xx-large">No results found.</h1>
              <br>
              <h1>Try changing the filters.</h1>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="!this.isLoggedIn() && !this.spinner && !showNoResults">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner" *ngIf="this.displaySpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="this.isLoggedIn() && !this.spinner">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item"
                    [bookmarked]="isBookmarked(item.id, 1)">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item" [bookmarked]="isBookmarked(item.id, 1)">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner"
                  *ngIf="this.displaySpinner && !this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav [opened]="showDetails" mode="side" position="end" class="product-details">
              <app-product-detail-display *ngIf="showDetails && this.actualProduct.category != 'EDGESERVER'" [actualProduct]="this.actualProduct" (goBack)="goBack()" (reloadDisplayItems)="loadDisplayItem($event)" >
              </app-product-detail-display>
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </ng-template>
    </mat-tab>


    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">category</mat-icon>
        Künstliche Intelligenz
      </ng-template>
      <ng-template matTabContent>
        <div class="fullheight">
          <div class="centerSpinner" *ngIf="this.spinner">
            <mat-spinner></mat-spinner>
          </div>
          <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #drawer class="sidenav" *ngIf="!this.spinner"
              [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
              [mode]="(isHandset$ | async) ? 'over' : 'side'" [disableClose]="true" [opened]="(isHandset$ | async) === false && !showDetails">
              <div *ngIf="marketScreenState === 'KÜNTSTLICHE_INTELLIGENZ'" class="sidefilter">
                <app-market-place-side-filter></app-market-place-side-filter>
              </div>
            </mat-sidenav>
            <mat-sidenav-content *ngIf="showNoResults">
              <h1 style="font-size: xx-large">No results found.</h1>
              <br>
              <h1>Try changing the filters.</h1>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="!this.isLoggedIn() && !this.spinner && !showNoResults">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner" *ngIf="this.displaySpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="this.isLoggedIn() && !this.spinner">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item"
                    [bookmarked]="isBookmarked(item.id, 1)">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item" [bookmarked]="isBookmarked(item.id, 1)">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner"
                  *ngIf="this.displaySpinner && !this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav [opened]="showDetails" mode="side" position="end" class="product-details">
              <app-product-detail-display *ngIf="showDetails && this.actualProduct.category != 'EDGESERVER'" [actualProduct]="this.actualProduct" (goBack)="goBack()" (reloadDisplayItems)="loadDisplayItem($event)" >
              </app-product-detail-display>
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </ng-template>
    </mat-tab>
    
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">category</mat-icon>
        Roboter
      </ng-template>
      <ng-template matTabContent>
        <div class="fullheight">
          <div class="centerSpinner" *ngIf="this.spinner">
            <mat-spinner></mat-spinner>
          </div>
          <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #drawer class="sidenav" *ngIf="!this.spinner"
              [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
              [mode]="(isHandset$ | async) ? 'over' : 'side'" [disableClose]="true" [opened]="(isHandset$ | async) === false && !showDetails">
              <div *ngIf="marketScreenState === 'ROBOT'" class="sidefilter">
                <app-market-place-side-filter></app-market-place-side-filter>
              </div>
            </mat-sidenav>
            <mat-sidenav-content *ngIf="showNoResults">
              <h1 style="font-size: xx-large">No results found.</h1>
              <br>
              <h1>Try changing the filters.</h1>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="!this.isLoggedIn() && !this.spinner && !showNoResults">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner" *ngIf="this.displaySpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="this.isLoggedIn() && !this.spinner">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item"
                    [bookmarked]="isBookmarked(item.id, 1)">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id)" [focus]="checkIfFocusForProduct(item)" [isProduct]="true"
                    [itemInfo]="item" [bookmarked]="isBookmarked(item.id, 1)">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner"
                  *ngIf="this.displaySpinner && !this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav [opened]="showDetails" mode="side" position="end" class="product-details">
              <app-product-detail-display *ngIf="showDetails && this.actualProduct.category != 'EDGESERVER'" [actualProduct]="this.actualProduct" (goBack)="goBack()" (reloadDisplayItems)="loadDisplayItem($event)" >
              </app-product-detail-display>
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </ng-template>
    </mat-tab>


    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">verified</mat-icon>
        Zertifizierungen
      </ng-template>
      <ng-template matTabContent>
        <div class="fullheight">
          <div class="centerSpinner" *ngIf="this.spinner">
            <mat-spinner></mat-spinner>
          </div>
          <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #drawer class="sidenav" *ngIf="!this.spinner"
              [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
              [mode]="(isHandset$ | async) ? 'over' : 'side'"
              [opened]="(isHandset$ | async) === false  && !showDetails"
              [disableClose]="true">
              <div *ngIf="marketScreenState ==='CERTIFICATION'" style="display: flex; flex-flow: column; height: 100%">
                <app-market-place-side-filter></app-market-place-side-filter>
              </div>
            </mat-sidenav>
            <mat-sidenav-content *ngIf="!this.isLoggedIn() && !this.spinner">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id);" [focus]="checkIfFocusForCertificate(item)" [isCertification]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id);" [focus]="checkIfFocusForCertificate(item)" [isCertification]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner" *ngIf="this.displaySpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="this.isLoggedIn() && !this.spinner">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id);" [focus]="checkIfFocusForCertificate(item)" [isCertification]="true"
                    [itemInfo]="item" [bookmarked]="isBookmarked(item.id, 0)">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id);" [focus]="checkIfFocusForCertificate(item)" [isCertification]="true"
                    [itemInfo]="item" [bookmarked]="isBookmarked(item.id, 0)" >
                  </app-display-item>
                </div>
                <div class="displayItemSpinner" *ngIf="this.displaySpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav [opened]="showDetails" mode="side" position="end" class="product-details">
              <app-certification-detail-display *ngIf="showDetails" [actualCertification]="this.actualCertification"
                (goBack)="goBack()"></app-certification-detail-display>
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </ng-template>
    </mat-tab>


    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">store</mat-icon>
        Anbieter
      </ng-template>
      <ng-template matTabContent>
        <div class="fullheight">
          <div class="centerSpinner" *ngIf="this.spinner">
            <mat-spinner></mat-spinner>
          </div>
          <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #drawer *ngIf="!this.spinner" class="sidenav"
              [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
              [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false && !showDetails"
              [disableClose]="true">
              <div *ngIf="marketScreenState === 'ORGANISATION'" style="display: flex; flex-flow: column; height: 100%">
                <app-market-place-side-filter></app-market-place-side-filter>
              </div>
            </mat-sidenav>
            <mat-sidenav-content *ngIf="!this.isLoggedIn() && !this.spinner">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id);" [focus]="checkIfFocusForOrga(item)" [isProvider]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id);" [focus]="checkIfFocusForOrga(item)" [isProvider]="true"
                    [itemInfo]="item">
                  </app-display-item>
                </div>
                <div class="displayItemSpinner" *ngIf="this.displaySpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav-content *ngIf="this.isLoggedIn() && !this.spinner">
              <app-infinite-scroll *ngIf="displayItem.length <= 4" class="grid2" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id);" [focus]="checkIfFocusForOrga(item)" [isProvider]="true"
                    [itemInfo]="item" [bookmarked]="isBookmarked(item.id, 2)">
                  </app-display-item>
                </div>
              </app-infinite-scroll>
              <app-infinite-scroll *ngIf="displayItem.length > 4" class="grid" (scrolled)="onScroll()">
                <div class="filterSpinner" *ngIf="this.marketPlaceFilterService.filterSpinner">
                  <mat-spinner></mat-spinner>
                </div>
                <div #target class="card1" *ngFor="let item of displayItem">
                  <app-display-item *ngIf="!this.marketPlaceFilterService.filterSpinner"
                    (click)="changeQueryParams(item.id);" [focus]="checkIfFocusForOrga(item)" [isProvider]="true"
                    [itemInfo]="item" [bookmarked]="isBookmarked(item.id, 2)" >
                  </app-display-item>
                </div>
                <div class="displayItemSpinner" *ngIf="this.displaySpinner">
                  <mat-spinner></mat-spinner>
                </div>
              </app-infinite-scroll>
            </mat-sidenav-content>
            <mat-sidenav [opened]="showDetails" mode="side" position="end" class="product-details">
              <app-organisation-detail-display *ngIf="showDetails" [actualProduct]="this.actualOrganisation"
                (goBack)="goBack()"></app-organisation-detail-display>
            </mat-sidenav>
          </mat-sidenav-container>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
