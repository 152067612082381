import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { UserService } from "../../../../services/user-service/userservice";
import { MatDialog } from "@angular/material/dialog";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { DialogService } from "../../../../services/dialog-service/dialog-service.service";
import { ServiceError } from "../../../../components/service-error";
import { ValidatorFactory, VALIDATOR_EXP } from "src/environments/constants";
import { CryptoService } from "src/app/services/crypto-service/crypto.service";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { AdminOrganisationAction } from "src/app/model/enums/adminOrganisationAction";
import { OrganisationChangeInterface } from "src/app/model/organisations/organisation-change-interface";
import { VALIDATORS } from 'src/environments/validators';

@Component({
  selector: "app-konto-screen",
  templateUrl: "./konto-screen.component.html",
  styleUrls: ["./konto-screen.component.css"],
})
export class KontoScreenComponent implements OnInit, OnDestroy {
  private currentPassword: string;
  public changePasswordMode = false;
  public changeEmailMode = false;
  public deleteAccountMode = false;

  private passwordChangeForm: FormGroup;
  private emailChangeForm: FormGroup;
  private confirmDeletePassword: AbstractControl;
  matcher = new passwordChangeErrorStateMatcher();
  password: AbstractControl;
  newEmail: AbstractControl;
  confirmpassword: AbstractControl;

  constructor(
    public userservice: UserService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private cryptoService: CryptoService,
    private titleService: Title
  ) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    ValidatorFactory.addValidatorFromRegex(
      VALIDATOR_EXP.PASSWORD_LENGTH,
      "passwordlength"
    );
    ValidatorFactory.addValidatorFromRegex(
      VALIDATOR_EXP.CONTAINS_SPECIAL_CHAR,
      "passwordSpecialChar"
    );
    ValidatorFactory.addValidatorFromRegex(
      VALIDATOR_EXP.CONTAINS_NUMBER,
      "passwordNumber"
    );

    this.passwordChangeForm = formBuilder.group(
      {
        password: [
          "",
          Validators.compose([Validators.required, ValidatorFactory.create()]),
        ],
        confirmpassword: ["", Validators.required],
      },
      { validator: confirmPasswordValidator }
    );
    this.password = this.passwordChangeForm.controls["password"];
    this.confirmpassword = this.passwordChangeForm.controls["confirmpassword"];
    this.confirmDeletePassword = new FormControl("", Validators.required);

    this.emailChangeForm = formBuilder.group({
        newEmail: ["", VALIDATORS.email],
    });
    this.newEmail = this.emailChangeForm.controls["newEmail"];
  }

  changePasswordModeToggle(): void {
    this.deleteAccountMode = false;
    this.changeEmailMode = false;
    this.changePasswordMode = !this.changePasswordMode;
    if(!this.changePasswordMode) {
      this.passwordChangeForm.reset({
        password: "",
        confirmpassword: ""
      });
    }
  }

  changeEmailModeToggle(): void {
    this.deleteAccountMode = false;
    this.changePasswordMode = false;
    this.changeEmailMode = !this.changeEmailMode;
    if(!this.changeEmailMode) {
      this.emailChangeForm.reset({
        newEmail: "",
      });
    }
  }

  deleteAccountModeToggle(): void {
    this.changePasswordMode = false;
    this.changeEmailMode = false;
    this.deleteAccountMode = !this.deleteAccountMode;
  }

  onDelete(): void {
    if (!this.confirmDeletePassword.valid) {
      this.dialogService.openDialog(
        "Konto löschen fehlgeschlagen",
        "Bitte geben Sie zur Bestätigung Ihr Passwort ein."
      );
      return;
    }
    const memberships: OrganisationInterface[] = this.userservice
      .currentMemberships;
    this.dialogService
      .openDeleteUserDialog(this.userservice.currentUser.userId, memberships)
      .subscribe(
        (result: {
          confirmed: boolean;
          organisationChanges: OrganisationChangeInterface[];
        }) => {
          if (result == null || result.confirmed == false) {
            this.dialogService.openDialog("Konto löschen abgebrochen", "");
            return;
          }
          let userToDelete: {
            password: string;
            organisationChanges: OrganisationChangeInterface[];
          } = {
            password: this.cryptoService.encrypt(
              this.confirmDeletePassword.value
            ),
            organisationChanges: result.organisationChanges,
          };
          this.userservice.deleteAccount(userToDelete).subscribe(
            (data) => {
              this.userservice.logout();
            },
            (error) => {},
            () =>
              this.dialogService.openDialog(
                "Konto löschen",
                "Ihr Konto wurde erfolgreich gelöscht."
              )
          );
        }
      );
  }

  ngOnInit() {this.titleService.setTitle("Konto - BIMSWARM");}
  ngOnDestroy() {   document.documentElement.style.setProperty('--scrollStatus', 'hidden');}

  onChangePasswordConfirm() {
    this.onPasswordUpdateConfirm((event) => {
      if (event instanceof ServiceError) {
        this.dialogService.openDialog(
          event.errorTitle,
          event.errorText
        );
      } else {
        this.dialogService.openDialog(
          "Passwort ändern",
          "Ihr Passwort wurde erfolgreich geändert."
        );
        this.changePasswordModeToggle();
      }
    });
  }

  onPasswordUpdateConfirm(callback: Function): void {
    if (this.password.valid && this.confirmpassword.valid) {
      if (this.password.value === this.confirmpassword.value) {
        if (this.password.value !== this.currentPassword) {
          let user = {
            userName: this.userservice.currentUser.userName,
            currentPassword: this.cryptoService.encrypt(this.currentPassword),
            newPassword: this.cryptoService.encrypt(this.password.value),
          };
          this.userservice.updatePassword(user, callback);
        } else {
          callback(
            new ServiceError(
              1,
              "RequiredFieldsEmptyOrNull",
              "Passwort ändern fehlgeschlagen",
              "Das neue und das alte Passwort dürfen nicht identisch sein"
            )
          );
        }
      } else {
        callback(
          new ServiceError(
            1,
            "RequiredFieldsEmptyOrNull",
            "Passwort ändern fehlgeschlagen",
            "Die beiden Eingaben des neuen Passwortes stimmen nicht überein"
          )
        );
      }
    } else {
      callback(
        new ServiceError(
          1,
          "RequiredFieldsEmptyOrNull",
          "Passwort ändern fehlgeschlagen",
          "Bitte geben Sie ein Passwort mit mindestens 6 Zeichen an, welches jeweils mindestens einen Buchstaben, " +
            "eine Zahl und ein Sonderzeichen enthält."
        )
      );
    }
  }

  onCurrentPasswordChange(password: string): void {
    this.currentPassword = password;
  }

  onChangeEmailConfirm() {
    this.onEmailUpdateConfirm((event) => {
      if (event instanceof ServiceError) {
        this.dialogService.openDialog(
          event.errorTitle,
          event.errorText
        );
      } else {
        this.userservice.logout();
        this.dialogService.openDialog(
          "E-Mail ändern",
          "Es wurde eine Mail an Ihre aktuell hinterlegte E-Mail Adresse geschickt, um die Änderung zu bestätigen."
        );
      }
    });
  }

  onEmailUpdateConfirm(callback: Function): void {
        if (this.newEmail.value !== this.userservice.currentUser.userName) {
          let user = {
            userId: this.userservice.currentUser.userId,
            currentUserName: this.userservice.currentUser.userName,
            newUserName: this.newEmail.value,
          };
          this.userservice.updateEmail(user, callback);
        } else {
          callback(
            new ServiceError(
              1,
              "RequiredFieldsEmptyOrNull",
              "E-Mail ändern fehlgeschlagen",
              "Die neue und die alte E-Mail-Adresse dürfen nicht identisch sein"
            )
          );
        }
  }

}

export class passwordChangeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control.value !== control.parent.value.password && control.parent.invalid
    );
    return invalidCtrl || invalidParent;
  }
}

function passwordLengthValidator(
  control: FormControl
): { [s: string]: boolean } {
  if (control.value.length < 6) {
    return { passwordlength: true };
  }
}

function passwordSpecialCharValidator(
  control: FormControl
): { [s: string]: boolean } {
  if (!control.value.match(/\W/)) {
    return { passwordSpecialChar: true };
  }
}

function passwordNumberValidator(
  control: FormControl
): { [s: string]: boolean } {
  if (!control.value.match(/[0-9]+/)) {
    return { passwordNumber: true };
  }
}
function confirmPasswordValidator(group: FormGroup): { [s: string]: boolean } {
  if (group.controls.password.value !== group.controls.confirmpassword.value) {
    return { notEqual: true };
  }
}
