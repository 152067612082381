<mat-card
  class="example-card mat-elevation-z2"
  [ngClass]="{ 'mat-elevation-z8 hover': hover || focus }"
  (mouseenter)="hover = true"
  (mouseleave)="hover = false"
>
  <ng-container *ngIf="isProduct || isCertification">
    <app-secure-image
    *ngIf="itemInfo.fileId != null"
    [displayItem]="true"
    [fileId]="itemInfo.fileId"
    [src]="itemInfo.pictureUrl"
    >
  </app-secure-image>
  <img class="image"mat-card-image *ngIf="itemInfo.fileId == null " [src]="itemInfo.pictureUrl" alt="Produktbild"/>
  </ng-container>
  <ng-container *ngIf="isProvider">
    <app-secure-image
    *ngIf="itemInfo.fileId != null"
    [displayItem]="true"
    [fileId]="itemInfo.fileId"
    src="itemInfo.pictureUrl"
    >
    </app-secure-image>
    <img class="image" mat-card-image *ngIf="itemInfo.fileId==null" [src]="itemInfo.pictureUrl"/>
  </ng-container>
  <button mat-mini-fab color="white" *ngIf="isLoggedIn()" (click)="toggleProductInList(itemInfo.id); bookmark($event);">
    <mat-icon color="accent">{{bookmarked ? 'bookmark' : 'bookmark_border'}}</mat-icon>
  </button>
  <mat-card-title class="cut-text" id="providerName" *ngIf="isProvider" style="hyphens: auto; font-size: 20px;">
    {{itemInfo.name}}
  </mat-card-title>
  <mat-card-header *ngIf="!isProvider">
    <div mat-card-avatar class="avatar">
        <app-image-slider
        [organisation]="this.organisation"
        >
        </app-image-slider>
    </div>
    <mat-card-title class="cut-text" id="productOrCertifi"  [ngClass]="{'productOrCertifiName': (itemInfo.name.length > 45)}" style="hyphens: auto">
      <ng-container  *ngIf="isProduct"> {{itemInfo.name}} </ng-container>
      <ng-container  *ngIf="isCertification">
        {{itemInfo.name}}
      </ng-container>
    </mat-card-title>
    <mat-card-subtitle class="cut-text" id="subscr" *ngIf="!isProvider" >{{itemInfo.subscript}}</mat-card-subtitle>
    <div *ngIf="isCertification" class="certificate-icon2">
    <mat-card-content>
      <app-certificate-mini-display
        [certificateType]="itemInfo.certificationType"
        [certCount]="itemInfo.productsCertified">
      </app-certificate-mini-display>
    </mat-card-content>
  </div>
  </mat-card-header>
  <mat-card-content>
   <div class="twocolumn-grid">
    <div #colmn1 *ngIf="itemInfo.reviewRating" class="certificate-icon">
      <app-star
        [rating]="itemInfo.reviewRating.rating"
        [ratingCount]="itemInfo.reviewRating.reviewNumber"
      ></app-star>
      </div>
    <div
    #colmn2
    class="certificate-icon"
    *ngIf="itemInfo.displayType === 'PRODUCT'">
    <app-certificate-mini-display
    *ngIf="
      itemInfo.attributeCertificationCount &&
      itemInfo.attributeCertificationCount > 0
    "
    [certificateType]="'ATTRIBUTE_CERT'"
    [certCount]="itemInfo.attributeCertificationCount"
  >
  </app-certificate-mini-display>
  <!---------------------->
  <app-certificate-mini-display
    *ngIf="
      itemInfo.ptgCertificationCount && itemInfo.ptgCertificationCount > 0
    "
    [certificateType]="'PTG_CERT'"
    [certCount]="itemInfo.ptgCertificationCount"
  ></app-certificate-mini-display>
  <!---------------------->
  <app-certificate-mini-display
    *ngIf="
      itemInfo.toolchainCertificationCount &&
      itemInfo.toolchainCertificationCount > 0
    "
    [certificateType]="'TOOLCHAIN_CERT'"
    [certCount]="itemInfo.toolchainCertificationCount"
  >
  </app-certificate-mini-display>
  <app-certificate-mini-display *ngIf="itemInfo.ptgsWhereAllMandatoryAttributesImplementedCount > 0" [certificateType]="'ATTRIBUTE_MANDATORY_CERT'" [certCount]="itemInfo.ptgsWhereAllMandatoryAttributesImplementedCount">
  </app-certificate-mini-display>
  </div>
</div>
  <div class="description" *ngIf='isProduct'>
    <p [ngClass]="hasCertification()? 'wordwrap' : 'wordwrap4'" [innerHTML]="this.removeEnters().substring(0, 400).trim()"></p>
  </div>
  <div class="description" *ngIf='isCertification'>
    <p class="wordwrap3" [innerHTML]="this.removeEnters().substring(0, 400).trim()"></p>
  </div>
  <div class="description" *ngIf='isProvider'>
    <p class="wordwrap2" [innerHTML]="this.removeEnters().substring(0, 400).trim()"></p>
  </div>
  </mat-card-content>
</mat-card>
