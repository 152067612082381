import { TrackingService } from './../../tracking-service/tracking.service';
import { SortMethod } from "./../../../model/enums/SortMethod";
import { BehaviorSubject, Observable, Subject, Subscribable, Subscription } from "rxjs";
import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";

import { MarketScreenState } from "src/app/model/enums/marketScreenState";
import { OrganisationService } from "../../dataServices/organisation-service/organisationservice";
import { API_BASE_USERSERVICE } from "src/environments/api";
import { MarketplaceFilterInterface } from "src/app/model/marketplace/marketplace-filter-interface";
import { CheckBoxCategorie } from "src/app/model/marketplace/marketplace-checkbox";
import { DataType } from "src/app/model/data-type.enum";
import { OrganisationType } from "src/app/model/enums/organisationType";
import { CertificationTypes } from "src/app/model/enums/certificationTypes";
import { ProductCategory } from "src/app/model/enums/productCategory";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { LinkingMethod } from 'src/app/model/enums/linkingMethod';

const SWARM_API = "/proxy/api/v0/userservice";
const USER_PREFIX = "/users";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class MarketplaceFilterService {

  public pageIndex: number = 0;

  public filterSpinner: boolean = false;

  public selectedPtgs: number[] = [];

  public selectedOrganisations: number[] = [];

  public selectedAttributes: number[] = [];

  public selectedCommonFeatures: number[] = [];

  public filterLists = new Subject<number[][]>();

  public initialSelectedPtgs: number[] = [];

  public initialSelectedOrganisations: number[] = [];

  public initialSelectedAttributes: number[] = [];

  public initialFilterList = new BehaviorSubject<number[][]>([][0]);

  public initialSelectedCommonFeatures: number[] = [];

  public searchTextSubject = new Subject<string>();

  public orderByOrganisationSubject = new Subject<boolean>();

  public searchText: string = "";

  public sortingOption: SortMethod = undefined;

  public queryParamSubscription: Subscription;

  public toggleButtonForClearingFilter: BehaviorSubject<boolean>

  constructor(
    public organisationService: OrganisationService,
    private trackingService: TrackingService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {

    this.queryParamSubscription = this.route.queryParams
    .pipe(take(1))
    .subscribe(params => {

      this.initialiseFilter(params);
    });
    this.screenStateSubject = new BehaviorSubject<DataType>(
      this.marketScreenState
    );
    this.screenStateObservable = this.screenStateSubject.asObservable();
    this.toggleButtonForClearingFilter = new BehaviorSubject<boolean>(false);
    //this.fetchInitialFilterList();
  }
 /* Liest die Url Paramet ein und nutzt diese fuer die initialisierung der Filter
 */  reloadNav(){

    this.queryParamSubscription = this.route.queryParams
    .pipe(take(1))
    .subscribe(params => {

      this.initialiseFilter(params);
    });
    this.screenStateSubject = new BehaviorSubject<DataType>(
      this.marketScreenState
    );
    this.screenStateObservable = this.screenStateSubject.asObservable();


  }
  // LUCAS UPDATE CODE ------------------------------
  //#region

  /**
   * This holds the "toggle" Flag for the MarketplaceOverviewsCompontens
   */
  private screenStateSubject: BehaviorSubject<DataType>;
  public screenStateObservable: Observable<DataType>;
  public marketScreenState: DataType = DataType.PRODUCT;

  public changeToProductScreen() {
    this.marketScreenState = DataType.PRODUCT;
    this.screenStateSubject.next(this.marketScreenState);
    return this.marketScreenState;
  }

  public changeToProductAsSoftwareScreen() {
    this.marketScreenState = DataType.SOFTWARE;
    this.screenStateSubject.next(this.marketScreenState);
    return this.marketScreenState;
  }
  public changeToProductAsContentScreen() {
    this.marketScreenState = DataType.CONTENT;
    this.screenStateSubject.next(this.marketScreenState);
    return this.marketScreenState;
  }
  public changeToProductAsDienstleistungenScreen() {
    this.marketScreenState = DataType.DIENSTLEISTUNGEN;
    this.screenStateSubject.next(this.marketScreenState);
    return this.marketScreenState;
  }
  public changeToProductAsKIScreen() {
    this.marketScreenState = DataType.KÜNTSTLICHE_INTELLIGENZ;
    this.screenStateSubject.next(this.marketScreenState);
    return this.marketScreenState;
  }

  public changeToProductAsRobotScreen() {
    this.marketScreenState = DataType.ROBOT;
    this.screenStateSubject.next(this.marketScreenState);
    return this.marketScreenState;
  }

    public changeToBaustelleScreen() {
    this.marketScreenState = DataType.BAUSTELLE;
    this.screenStateSubject.next(this.marketScreenState);
    return this.marketScreenState;
  }
  
   public changeToEDGEScreen() {
    this.marketScreenState = DataType.EDGESERVER;
    this.screenStateSubject.next(this.marketScreenState);
    return this.marketScreenState;
  }
  /*
  public isProductScreen(): boolean {
    return this.marketScreenState === MarketScreenState.Products;
  }

  public isOrganisationScreen(): boolean {
    return this.marketScreenState === MarketScreenState.Organisations;
  }

  public changeToCertificationsScreen() {
    this.changeMarketScreenDisplay(MarketScreenState.Certifications);
  }

  public changeMarketScreenDisplay(marketScreenState: MarketScreenState) {
    this.marketScreenState = marketScreenState;
    this.screenStateSubject.next(marketScreenState);
  } */

  public getToggleBooleanClearFilter(): Observable<boolean> {
    return this.toggleButtonForClearingFilter.asObservable();
  }

  public setToggleBooleanClearFilter(newBoolean: boolean): void {
    this.toggleButtonForClearingFilter.next(newBoolean);
  }


  public changeToOrganisationScreen() {
    this.marketScreenState = DataType.ORGANISATION;
    this.screenStateSubject.next(this.marketScreenState);
    return this.marketScreenState;
  }

  public isCertificationScreen(): boolean {
    return this.marketScreenState === DataType.CERTIFICATION;
  }

  public isOrganisationScreen(): boolean {
    return this.marketScreenState === DataType.ORGANISATION;
  }

  public changeToCertificationScreen() {
    this.marketScreenState = DataType.CERTIFICATION;
    this.screenStateSubject.next(this.marketScreenState);
    return this.marketScreenState;
  }

  public isToolchainScreen(): boolean {
    return this.marketScreenState === DataType.TOOLCHAIN;
  }

  public changeToToolchainScreen() {
    this.marketScreenState = DataType.TOOLCHAIN;
    this.screenStateSubject.next(this.marketScreenState);
    return this.marketScreenState;
  }

  private _filter: MarketplaceFilterInterface = {
    attributeIds: [],
    ptgIds: [],
    organisationIds: [],
    certificationIds: [],
    productIds: [],
    certificationTypes: [],
    productCategories: [],
    commonFeatureIds: [],
    ratingScore: [],
    ptgAttrIds: []
  };
  private attributeFilter: number[] = [] as number[];
  private ptgFilter: number[] = [];
  private ptgAttrFilter: number[] = [];
  private organisationFilter: number[] = [];
  public certificationFilter: number[] = [];
  public productFilter: number[] = [];
  private organisationTypeFilter: OrganisationType;
  private productCategories: ProductCategory[] = [];
  private certificationTypes: CertificationTypes[] = [];
  public commonFeatureEntriesFilter: number[] = [];
  public ratingFilter: number[] = [];

  private filterChangeSubject = new BehaviorSubject<MarketplaceFilterInterface>(
    this._filter
  );

  sortingOptionSubject = new BehaviorSubject<SortMethod>(this.sortingOption);

  get filterChangeObservable() {
    return this.filterChangeSubject.asObservable();
  }

  /**
   * Adds an Object of Categorie with ID idToFilter to the marketplace filter
   * TODO: For each filter option added the query params need to be updated! (setQueryParams-method)
   * @param idToFilter
   * @param filterType
   */
  addFilter(idToFilter: number, filterType: CheckBoxCategorie) {
    this.trackingService.eventEmitter("checkbox", "filter", "checkbox_checked" , filterType);
    this.filterSpinner = true;
    switch (filterType) {
      case CheckBoxCategorie.PTG: {
        if (!this.ptgFilter.includes(idToFilter))
          this.ptgFilter.push(idToFilter);

        break;
      }
      case CheckBoxCategorie.RATING: {
          if(!this.ratingFilter.includes(idToFilter)) this.filter.ratingScore.push(idToFilter);
        break;
      }
      case CheckBoxCategorie.PTG_ATTRIBUTE: {
          if(!this.ptgAttrFilter.includes(idToFilter))
             this.filter.ptgAttrIds.push(idToFilter);
        break;
      }
      case CheckBoxCategorie.ORGANISATION: {
        this.addIdToOrganisationFilter(idToFilter);
        break;
      }
      case CheckBoxCategorie.CERTIFICATION_ORGANISATION_ATTRIBUTE: {
        this.addIdToOrganisationFilter(idToFilter);
        break;
      }
      case CheckBoxCategorie.CERTIFICATION_ORGANISATION_PTG: {
        this.addIdToOrganisationFilter(idToFilter);
        break;
      }
      case CheckBoxCategorie.CERTIFICATION_ORGANISATION_TOOLCHAIN: {
        this.addIdToOrganisationFilter(idToFilter);
        break;
      }
      case CheckBoxCategorie.PRODUCT_ORGANISATION_APPLICATION: {
        this.addIdToOrganisationFilter(idToFilter);
        break;
      }
      case CheckBoxCategorie.PRODUCT_ORGANISATION_SERVICE: {
        this.addIdToOrganisationFilter(idToFilter);
        break;
      }
      case CheckBoxCategorie.PRODUCT_ORGANISATION_CONTENT: {
        this.addIdToOrganisationFilter(idToFilter);
        break;
      }
      case CheckBoxCategorie.PRODUCT_ORGANISATION_ALLGEMEIN: {
              this.addIdToOrganisationFilter(idToFilter);
              break;
            }
      case CheckBoxCategorie.PRODUCT_ORGANISATION_HOCHBAU: {
              this.addIdToOrganisationFilter(idToFilter);
              break;
            }
            case CheckBoxCategorie.PRODUCT_ORGANISATION_TIEFBAU: {
              this.addIdToOrganisationFilter(idToFilter);
              break;
            }
                  case CheckBoxCategorie.PRODUCT_ORGANISATION_WASSERBAU: {
              this.addIdToOrganisationFilter(idToFilter);
              break;
            }
            case CheckBoxCategorie.PRODUCT_ORGANISATION_EISENBAHNBAU: {
              this.addIdToOrganisationFilter(idToFilter);
              break;
            }
            case CheckBoxCategorie.PRODUCT_ORGANISATION_STRASENBAU: {
              this.addIdToOrganisationFilter(idToFilter);
              break;
            }
      case CheckBoxCategorie.PRODUCT_ORGANISATION_INFRASTRUKTUR: {
              this.addIdToOrganisationFilter(idToFilter);
              break;
            }
      case CheckBoxCategorie.PRODUCT_ORGANISATION: {
        this.addIdToOrganisationFilter(idToFilter);
        break;
      }
      case CheckBoxCategorie.CERTIFICATION_ORGANISATION: {
        this.addIdToOrganisationFilter(idToFilter);
        break;
      }
      case CheckBoxCategorie.ATTRIBUTE: {
        if (!this.attributeFilter.includes(idToFilter))
          this.filter.attributeIds.push(idToFilter);
        break;
      }
      case CheckBoxCategorie.COMMON_FEATURE_ENTRY: {
        if (!this.attributeFilter.includes(idToFilter))
          this.filter.commonFeatureIds.push(idToFilter);
        break;
      }
      case CheckBoxCategorie.CERTIFICATION: {
        if(!this.certificationFilter.includes(idToFilter))
            this.filter.certificationIds.push(idToFilter);
          break;
      }
      case CheckBoxCategorie.PRODUCTS: {
        if(!this.productFilter.includes(idToFilter))
          this.filter.productIds.push(idToFilter);
        break;
      }
      case CheckBoxCategorie.APP_PRODUCTS: {
        if(!this.productFilter.includes(idToFilter))
          this.filter.productIds.push(idToFilter);
        break;
      }
      case CheckBoxCategorie.SERVICE_PRODUCTS: {
        if(!this.productFilter.includes(idToFilter))
          this.filter.productIds.push(idToFilter);
        break;
      }
      case CheckBoxCategorie.CONTENT_PRODUCTS: {
        if(!this.productFilter.includes(idToFilter))
          this.filter.productIds.push(idToFilter);
        break;
      }
      case CheckBoxCategorie.ALLGEMEIN_PRODUCTS: {
              if(!this.productFilter.includes(idToFilter))
                this.filter.productIds.push(idToFilter);
              break;
            }
      case CheckBoxCategorie.HOCHBAU_PRODUCTS: {
              if(!this.productFilter.includes(idToFilter))
                this.filter.productIds.push(idToFilter);
              break;
            }
            case CheckBoxCategorie.TIEFBAU_PRODUCTS: {
              if(!this.productFilter.includes(idToFilter))
                this.filter.productIds.push(idToFilter);
              break;
            }
                  case CheckBoxCategorie.WASSERBAU_PRODUCTS: {
              if(!this.productFilter.includes(idToFilter))
                this.filter.productIds.push(idToFilter);
              break;
            }
                  case CheckBoxCategorie.EISENBAHNBAU_PRODUCTS: {
              if(!this.productFilter.includes(idToFilter))
                this.filter.productIds.push(idToFilter);
              break;
            }
            case CheckBoxCategorie.STRASENBAU_PRODUCTS: {
              if(!this.productFilter.includes(idToFilter))
                this.filter.productIds.push(idToFilter);
              break;
            }
      case CheckBoxCategorie.INFRASTRUKTUR_PRODUCTS: {
              if(!this.productFilter.includes(idToFilter))
                this.filter.productIds.push(idToFilter);
              break;
            }
    }
    this.next();
      this.setQueryParams();
  }

  private addIdToOrganisationFilter(idToFilter: number) {
    if (!this.organisationFilter.includes(idToFilter))
      this.filter.organisationIds.push(idToFilter);
  }


  /**
   * Set Filter for OrganisationType & CertificationType/ProductCategory depending on the OrgaType
   * @param organisationType
   * @param certOrProdType
   */
  setOrgaTypeFilter(
    organisationType: OrganisationType,
    productCategories: ProductCategory[],
    certificationTypes: CertificationTypes[]
  ) {
    this.organisationTypeFilter = organisationType;
    this.certificationTypes = certificationTypes;
    this.productCategories = productCategories;
    this.next();
  }

  setCertificationTypeFilter(certificationTypes: CertificationTypes[]) {
    this.certificationTypes = certificationTypes;
    this.next();
    this.setQueryParams();
  }
  setProductCategoryFilter(productCategories: ProductCategory[]) {
    this.productCategories = productCategories;
    this.next();
    this.setQueryParams();
  }


/**
hier wird die URL beim Ankreuzen von Filter geupdated
1. Param in die Url
*/
  setQueryParams() {
    let currentCategory: ProductCategory = this.productCategories.length == 0 ? null : this.productCategories[0];
    let currentCertficationType: CertificationTypes[] = this.certificationTypes.length == 0 ? null : this.certificationTypes;
    let currentSubAppPro = this.filter.productIds.length == 0 ? null : this.filter.productIds ;
    let currOrg = this.filter.organisationIds.length == 0 ? null : this.filter.organisationIds;
    let currCertifi =   this._filter.certificationIds.length == 0 ? null : this._filter.certificationIds;
    let currSearch = this.searchText === ""?null:this.searchText;

    let commonAttr = this._filter.commonFeatureIds.length == 0 ? null : this._filter.commonFeatureIds;
      let currPtg = this.ptgFilter.length == 0? null: this.ptgFilter;

      let currRating = this._filter.ratingScore.length == 0 ? null: this.filter.ratingScore;

    let queryParams: Params = {
      category: currentCategory,
      certificationType: currentCertficationType,
      search: currSearch,
      appSubPro:currentSubAppPro,
      orgId:currOrg,
      certifiId: currCertifi,
      attr:commonAttr,
      ptgId: currPtg,
      rating:currRating
    }
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      }
    )
  }
  /**
   * Removes the Object of Categorie with ID idToDelete from the marketplace filter
   * @param idToDelete Object id
   * @param filterType Type Categorie of the filter check boxes
   */
  removeFilter(idToDelete: number, filterType: CheckBoxCategorie) {
    this.filterSpinner = true;
    switch (filterType) {
      case CheckBoxCategorie.PTG: {
        var index = this.ptgFilter.findIndex((x) => x == idToDelete);
        if (index >= 0) this.ptgFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.ORGANISATION: {
        var index = this.organisationFilter.findIndex((x) => x == idToDelete);
        if (index >= 0) this.organisationFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.PTG_ATTRIBUTE: {
        var index = this.ptgAttrFilter.findIndex((x) => x == idToDelete);
        if(index >= 0) this.ptgAttrFilter.splice(index,1);
        break;
      }
      case CheckBoxCategorie.RATING: {

        var index = this.ratingFilter.findIndex((x) => x == idToDelete);
        if (index >= 0) this.ratingFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.CERTIFICATION_ORGANISATION_ATTRIBUTE: {
        var index = this.organisationFilter.findIndex((x) => x == idToDelete);
        if (index >= 0) this.organisationFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.CERTIFICATION_ORGANISATION_PTG: {
        var index = this.organisationFilter.findIndex((x) => x == idToDelete);
        if (index >= 0) this.organisationFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.CERTIFICATION_ORGANISATION_TOOLCHAIN: {
        var index = this.organisationFilter.findIndex((x) => x == idToDelete);
        if (index >= 0) this.organisationFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.PRODUCT_ORGANISATION_APPLICATION: {
        var index = this.organisationFilter.findIndex((x) => x == idToDelete);
        if (index >= 0) this.organisationFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.PRODUCT_ORGANISATION_SERVICE: {
        var index = this.organisationFilter.findIndex((x) => x == idToDelete);
        if (index >= 0) this.organisationFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.PRODUCT_ORGANISATION_CONTENT: {
        var index = this.organisationFilter.findIndex((x) => x == idToDelete);
        if (index >= 0) this.organisationFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.PRODUCT_ORGANISATION_ALLGEMEIN: {
              var index = this.organisationFilter.findIndex((x) => x == idToDelete);
              if (index >= 0) this.organisationFilter.splice(index, 1);
              break;
            }
      case CheckBoxCategorie.PRODUCT_ORGANISATION_HOCHBAU: {
              var index = this.organisationFilter.findIndex((x) => x == idToDelete);
              if (index >= 0) this.organisationFilter.splice(index, 1);
              break;
            }
            case CheckBoxCategorie.PRODUCT_ORGANISATION_TIEFBAU: {
              var index = this.organisationFilter.findIndex((x) => x == idToDelete);
              if (index >= 0) this.organisationFilter.splice(index, 1);
              break;
            }
            case CheckBoxCategorie.PRODUCT_ORGANISATION_WASSERBAU: {
              var index = this.organisationFilter.findIndex((x) => x == idToDelete);
              if (index >= 0) this.organisationFilter.splice(index, 1);
              break;
            }
            case CheckBoxCategorie.PRODUCT_ORGANISATION_STRASENBAU: {
              var index = this.organisationFilter.findIndex((x) => x == idToDelete);
              if (index >= 0) this.organisationFilter.splice(index, 1);
              break;
            }
            case CheckBoxCategorie.PRODUCT_ORGANISATION_EISENBAHNBAU: {
              var index = this.organisationFilter.findIndex((x) => x == idToDelete);
              if (index >= 0) this.organisationFilter.splice(index, 1);
              break;
            }             
      case CheckBoxCategorie.PRODUCT_ORGANISATION_INFRASTRUKTUR: {
              var index = this.organisationFilter.findIndex((x) => x == idToDelete);
              if (index >= 0) this.organisationFilter.splice(index, 1);
              break;
            }
      case CheckBoxCategorie.PRODUCT_ORGANISATION: {
        var index = this.organisationFilter.findIndex((x) => x == idToDelete);
        if (index >= 0) this.organisationFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.CERTIFICATION_ORGANISATION: {
        var index = this.organisationFilter.findIndex((x) => x == idToDelete);
        if (index >= 0) this.organisationFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.ATTRIBUTE: {
        var index = this.attributeFilter.findIndex((x) => x == idToDelete);

        if (index >= 0) this.attributeFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.COMMON_FEATURE_ENTRY: {
        var index = this.commonFeatureEntriesFilter.findIndex((x) => x == idToDelete);
        if (index >= 0) this.commonFeatureEntriesFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.CERTIFICATION: {
        var index = this.certificationFilter.findIndex((x) => x == idToDelete);
        if(index >= 0) this.certificationFilter.splice(index, 1);
        break;
      }
      case CheckBoxCategorie.PRODUCTS: {
        var index = this.productFilter.findIndex((x) => x == idToDelete);
        if(index >= 0) this.productFilter.splice(index,1);
        break;
      }
      case CheckBoxCategorie.APP_PRODUCTS: {
        var index = this.productFilter.findIndex((x) => x == idToDelete);
        if(index >= 0) this.productFilter.splice(index,1);
        break;
      }
      case CheckBoxCategorie.SERVICE_PRODUCTS: {
        var index = this.productFilter.findIndex((x) => x == idToDelete);
        if(index >= 0) this.productFilter.splice(index,1);
        break;
      }
      case CheckBoxCategorie.CONTENT_PRODUCTS: {
        var index = this.productFilter.findIndex((x) => x == idToDelete);
        if(index >= 0) this.productFilter.splice(index,1);
        break;
      }
      case CheckBoxCategorie.ALLGEMEIN_PRODUCTS: {
        var index = this.productFilter.findIndex((x) => x == idToDelete);
        if(index >= 0) this.productFilter.splice(index,1);
        break;
      }
      case CheckBoxCategorie.INFRASTRUKTUR_PRODUCTS: {
        var index = this.productFilter.findIndex((x) => x == idToDelete);
        if(index >= 0) this.productFilter.splice(index,1);
        break;
      }
      case CheckBoxCategorie.HOCHBAU_PRODUCTS: {
              var index = this.productFilter.findIndex((x) => x == idToDelete);
              if(index >= 0) this.productFilter.splice(index,1);
              break;
            }
      case CheckBoxCategorie.TIEFBAU_PRODUCTS: {
              var index = this.productFilter.findIndex((x) => x == idToDelete);
              if(index >= 0) this.productFilter.splice(index,1);
              break;
            }            
      case CheckBoxCategorie.WASSERBAU_PRODUCTS: {
              var index = this.productFilter.findIndex((x) => x == idToDelete);
              if(index >= 0) this.productFilter.splice(index,1);
              break;
            }
      case CheckBoxCategorie.STRASENBAU_PRODUCTS: {
              var index = this.productFilter.findIndex((x) => x == idToDelete);
              if(index >= 0) this.productFilter.splice(index,1);
              break;
            } 
      case CheckBoxCategorie.EISENBAHNBAU_PRODUCTS: {
              var index = this.productFilter.findIndex((x) => x == idToDelete);
              if(index >= 0) this.productFilter.splice(index,1);
              break;
            }
          }
    this.next();
    this.setQueryParams();
  }

  /**
   * Resolves the filters from the query params and uses them for the "filter"-call to backend
   * Case: If any filter were set in the url query params as the marketplace-filter service is started (e.g. user refresh when filter were set or if the
   * link to some filtered results was shared/directly typed)
   * 3. hier fuer backend config
   * This method only initialises the filter for the backend request. It is important to also check the selected filter checkboxes. This is done in the
   * marketplace-side-filter components-setCheckedBoxesFromQueryParams method
   * @param params Query Parameters from URL: These directly reflect all filter options
   */
  initialiseFilter(params: Params) {

    if(params['certificationType'])
      this.certificationTypes = this.transformStringToCertificationTypes(params, 'certificationType', this.certificationTypes);
    if(params['category']) this.productCategories = [params['category']];
    if(params['search']) this.receiveSearchText(params['search']);


    if(params['appSubPro'])  {
    this.productFilter =  this.transformStringProductIdToNumber(params,'appSubPro',this.productFilter);
    }

    if(params['rating'])  {
    this.productFilter =  this.transformStringProductIdToNumber(params,'rating',this.ratingFilter);
    }

    if(params['orgId'])  {
  this.organisationFilter =    this.transformStringProductIdToNumber(params,'orgId',this.organisationFilter);
    }

    if(params['certifiId'])  {
  this.certificationFilter =    this.transformStringProductIdToNumber(params,'certifiId',this.certificationFilter);
    }
    if(params['ptgId'])  {
  this.ptgFilter =    this.transformStringProductIdToNumber(params,'ptgId',this.ptgFilter);
    }

if(params['attr']){

  this.commonFeatureEntriesFilter = this.transformStringProductIdToNumber(params,'attr',this.commonFeatureEntriesFilter);

}


  }

  private transformStringToCertificationTypes(params: Params, parKey:string, filter: CertificationTypes[])
  {
    let tmpListOfIds = params[parKey];
    if(Array.isArray(tmpListOfIds)) {
        tmpListOfIds.forEach(element => {

          let tmpId : CertificationTypes = element;
          filter.push(tmpId);

        }); } else {
            let tmpId = tmpListOfIds;
          filter = [tmpId];
        }


        return filter;
  }

  private transformStringProductIdToNumber(params: Params, parKey:string, filter: number[]): number[]{

    let tmpListOfIds = params[parKey];
    if(Array.isArray(tmpListOfIds)) {
        tmpListOfIds.forEach(element => {

          let tmpId = +element;
          filter.push(tmpId);
          this.addFilter(tmpId, CheckBoxCategorie.PRODUCTS);
        }); } else {
            let tmpId = +tmpListOfIds;
          filter = [tmpId];
        }


        return filter;
  }



  /**
   * Getter for the marketplace filter.
   * @returns Object of MarketplaceFilterInterface constructed from the indivdual
   * filter
   */
  get filter() {
    this._filter.attributeIds = this.attributeFilter;
    this._filter.organisationIds = this.organisationFilter;
    this._filter.ptgIds = this.ptgFilter;
    this._filter.ptgAttrIds = this.ptgAttrFilter;
    this._filter.certificationIds = this.certificationFilter;
    this._filter.productIds = this.productFilter;
    this._filter.orgaType = this.organisationTypeFilter;
    this._filter.certificationTypes = this.certificationTypes;
    this._filter.productCategories = this.productCategories;
    this._filter.commonFeatureIds = this.commonFeatureEntriesFilter;
    this._filter.ratingScore = this.ratingFilter;
    return this._filter;
  }T

  next() {
    this.filterChangeSubject.next(this.filter);
  }
  //#endregion
  // LUCAS UPDATE CODE ------------------------------

  updateFilterList(checkedBoxContent: {
    id: number;
    category: string;
    name: string;
    checked: boolean;
  }): void {
    if (checkedBoxContent.checked === true) {
      this.addEntryToFilterList(checkedBoxContent);
    } else {
      this.removeEntryFromFilterList(checkedBoxContent);
      if (this.organisationService.filterProductsInitialFromOrganisation) {
        this.organisationService.filterProductsInitialFromOrganisation = undefined;
      }
    }
    this.filterLists.next(this.getAllFilterLists());
  }

  updateInitialFilterList(checkedBoxContent: {
    id: number;
    category: string;
    name: string;
    checked: boolean;
  }): void {
    if (checkedBoxContent.checked === true) {
      this.addEntryToInitialFilterList(checkedBoxContent);
    } else {
      this.removeEntryFromInitialFilterList(checkedBoxContent);
      if (this.organisationService.filterProductsInitialFromOrganisation) {
        this.organisationService.filterProductsInitialFromOrganisation = undefined;
      }
    }
    this.initialFilterList.next(this.getInitialFilterLists());
  }

  saveInitialFilterList(): Observable<number[][]> {
    const json = JSON.stringify(this.getInitialFilterLists());
    return this.http.put<number[][]>(
      SWARM_API + USER_PREFIX + "/filterlist",
      json,
      httpOptions
    );
  }

  fetchInitialFilterList(): void {
    this.http
      .get<number[][]>(SWARM_API + USER_PREFIX + "/filterlist")
      .subscribe((data) => {
        this.initialSelectedPtgs = data["productTypeGroupIds"];
        this.initialSelectedAttributes = data["attributeIds"];
        this.initialSelectedOrganisations = data["organisationIds"];
        this.initialFilterList.next(this.getInitialFilterLists());
      });
  }

  private addEntryToFilterList(checkedBoxContent: {
    id: number;
    category: string;
    name: string;
    checked: boolean;
  }) {
    switch (checkedBoxContent.category) {
      case "PTG":
        this.selectedPtgs.push(checkedBoxContent.id);
        break;
      case "Attribute":
        this.selectedAttributes.push(checkedBoxContent.id);
        break;
      case "Organisation":
        this.selectedOrganisations.push(checkedBoxContent.id);
        break;
      case "CommonFeature":
        this.selectedCommonFeatures.push(checkedBoxContent.id);
        break;
    }
  }

  private addEntryToInitialFilterList(checkedBoxContent: {
    id: number;
    category: string;
    name: string;
    checked: boolean;
  }) {
    switch (checkedBoxContent.category) {
      case "PTG":
        if (!this.initialSelectedPtgs.includes(checkedBoxContent.id)) {
          this.initialSelectedPtgs.push(checkedBoxContent.id);
        }
        break;
      case "Attribute":
        if (!this.initialSelectedAttributes.includes(checkedBoxContent.id)) {
          this.initialSelectedAttributes.push(checkedBoxContent.id);
        }
        break;
      case "Organisation":
        if (!this.initialSelectedOrganisations.includes(checkedBoxContent.id)) {
          this.initialSelectedOrganisations.push(checkedBoxContent.id);
        }
        break;
    }
  }

  private removeEntryFromFilterList(toRemove: {
    id: number;
    category: string;
    name: string;
    checked: boolean;
  }): void {
    switch (toRemove.category) {
      case "PTG":
        this.selectedPtgs = this.removeEntryFromArray(
          this.selectedPtgs,
          toRemove.id
        );
        break;
      case "Attribute":
        this.selectedAttributes = this.removeEntryFromArray(
          this.selectedAttributes,
          toRemove.id
        );
        break;
      case "Organisation":
        this.selectedOrganisations = this.removeEntryFromArray(
          this.selectedOrganisations,
          toRemove.id
        );
        break;
    }
  }

  private removeEntryFromInitialFilterList(toRemove: {
    id: number;
    category: string;
    name: string;
    checked: boolean;
  }): void {
    switch (toRemove.category) {
      case "PTG":
        this.initialSelectedPtgs = this.removeEntryFromArray(
          this.initialSelectedPtgs,
          toRemove.id
        );
        break;
      case "Attribute":
        this.initialSelectedAttributes = this.removeEntryFromArray(
          this.initialSelectedAttributes,
          toRemove.id
        );
        break;
      case "Organisation":
        this.initialSelectedOrganisations = this.removeEntryFromArray(
          this.initialSelectedOrganisations,
          toRemove.id
        );
        break;
      case "CommonFeatre":
        this.initialSelectedCommonFeatures = this.removeEntryFromArray(
          this.initialSelectedCommonFeatures,
          toRemove.id
        )
    }
  }

  private removeEntryFromArray(list: number[], toRemove: number): number[] {
    return list.filter((item) => item != toRemove);
  }

  getAllFilterLists(): number[][] {
    return [
      this.selectedPtgs,
      this.selectedAttributes,
      this.selectedOrganisations,
      this.selectedCommonFeatures,
    ];
  }

  getInitialFilterLists(): number[][] {
    return [
      this.initialSelectedPtgs,
      this.initialSelectedAttributes,
      this.initialSelectedOrganisations,
      this.initialSelectedCommonFeatures
    ];
  }

   receiveSearchText(text: string): void {
    this.filterSpinner = true;
    this.searchText = text;
    this.searchTextSubject.next(text);
    this.setQueryParams();
  }

  resetSearchTextValue(): void {
    this.searchText = "";
  }

  resetFilters(): void {
    this._filter.attributeIds.length = 0;
    this._filter.organisationIds.length = 0;
    this._filter.ptgAttrIds.length = 0;
    this._filter.certificationIds.length = 0;
    this._filter.productIds.length = 0;
    this._filter.ptgIds.length = 0;
    this._filter.attributeIds.length = 0;
    this._filter.ratingScore.length = 0;
  }

  receiveOrder(byOrganisation: boolean): void {
    this.orderByOrganisationSubject.next(byOrganisation);
  }

  clearAllFilterLists(): void {
    this.selectedPtgs = [];
    this.selectedAttributes = [];
    this.selectedOrganisations = [];
    this.filterLists.next(this.getAllFilterLists());
  }

  clearAllFilterListsExceptOrga(): void {
    this.selectedPtgs = [];
    this.selectedAttributes = [];
    this.selectedCommonFeatures = [];
    this.filterLists.next(this.getAllFilterLists());
  }
}
