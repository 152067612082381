import { ObjectLinkInterface } from './../../model/object-link-interface';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError, of, Subscribable } from "rxjs";
import { FileInfoInterface } from "src/app/model/marketplace/file-info-interface";
import { UserInterface } from 'src/app/model/user/user-interface';

const SWARM_API = "/proxy/api/v0/fileservice/";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class FileServiceService {
  public fileList;
  public fileListById;

  private Datastore = new BehaviorSubject({});
  data$ = this.Datastore.asObservable();
  public uploading = false;

  constructor(private http: HttpClient) {
    this.updateMyFiles();
  }

  updateMyFiles() {
    this.http.get(SWARM_API + "fileinfo").subscribe((list) => {
      this.fileList = list;
      let listByID = {};
      for (const idx in list) {
        if (list.hasOwnProperty(idx)) {
          const element = list[idx];
          listByID[element.id] = element;
        }
      }
      this.Datastore.next(listByID);
    });
  }

  getMyFiles() {
    this.updateMyFiles();
  }

  async getFileInfo(fid:string){
    return new Promise((resolve, reject) => {
    this.http.get(SWARM_API + "fileinfo/" + fid).subscribe((list) => {
      resolve(list);
    },error => {resolve({})});
  });
  }

  deleteFile(fid): Subscribable<ObjectLinkInterface[]> {
    return this.http.delete<ObjectLinkInterface[]>(
      SWARM_API + "fileinfo/" + fid
    );
  }

  updateFileMeta(fid, data) {
    this.http.put(SWARM_API + "fileinfo/" + fid, data).subscribe((resp) => {
      this.updateMyFiles();
    });
  }

  getFileMeta(fid) {
    let fileinfo: FileInfoInterface;
    this.http
      .get<FileInfoInterface>(SWARM_API + "fileinfo/" + fid)
      .subscribe((resp) => {
        return resp;
      });
  }

  getMetaDataForFiles(fileIds: string[]) {
    return this.http.put<FileInfoInterface[]>(
      SWARM_API + "fileinfo/entity",
      fileIds
    );
  }

  doUpload(file2bSend) {
    const fd = new FormData();
    fd.append("file", file2bSend, file2bSend.name);
    this.uploading = true;
    this.http
      .post(SWARM_API + "file", fd, {
        reportProgress: true,
        observe: "response",
        responseType: "text",
      })
      .subscribe((events) => {
        this.updateMyFiles();
        this.uploading = false;
      });
  }
}

/*
return this.httpClient.post(url, formData, {
  reportProgress: true,
  observe: "response",
  responseType: "text",
});
*/
