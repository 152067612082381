import { ToolChainInstanceStatus } from './composer-toolchain-instance-status-interface';

export class ComposerToolchainInstanceOverview {
    toolchainId: number;
    userId: number;
    name: string;
    description: string;
    start: Date;
    toolchainTemplateId: number;
    status: ToolChainInstanceStatus;
    relTemplate: number;
}
