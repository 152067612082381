import { CertificationRequestService } from './../../../services/dataServices/certification-request/certification-request.service';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { ChangeDetectorRef, Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { CertificationRequestLogInterface } from 'src/app/model/certifications/certification-request-log-interface';
import { CertificationRequestInterface } from 'src/app/model/certifications/certification-request-interface';

@Component({
  selector: 'app-certification-request-log',
  templateUrl: './certification-request-log.component.html',
  styleUrls: ['./certification-request-log.component.css']
})
export class CertificationRequestLogComponent implements OnInit, OnChanges {
  public comment: string;
  public isCommentValid : boolean = true;
  public log: CertificationRequestLogInterface[];

  @Input()
  public certificationRequest: CertificationRequestInterface;

  @Output()
  commented = new EventEmitter<string>();

  constructor(
    private certificationRequestService: CertificationRequestService,
    private organisationService: OrganisationService,
    private cd: ChangeDetectorRef
  ) { 
    
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.certificationRequest) {
      this.log = this.certificationRequest.certificationRequestLog;
      this.cd.detectChanges();  
    }
  }

  public addComment() {
    //<p> and </p> always set in comment, only add if there is an actual user input
    if(this.comment.length > 7) {
      this.commented.emit(this.comment.substring(3, this.comment.length - 4));
      this.comment = "";
      this.cd.detectChanges();
    }
  }

  public onCommentValidityChange(commentValid: boolean) {
    this.isCommentValid = commentValid;
  }
}