import { matRaisedButtonCSS } from './composerSaveButton';

/* Custom Modul for the Properties Panel */
export function ComposerAssociatedProduct(options: {
    id: string;
    productId: string;
    productName: string;
    imageDataSrc: string;
  }) {
    const label = "Produkt";
    const buttonLabel = "Produkt wählen";
    const clearButtonLabel = "Produkt löschen";

    /* Custom Event that gets triggered when the Select Product Button is clicked - Handled by ComposerChangeHandler */
    const customEvent =
    "{const customEvent = document.createEvent('HTMLEvents');" +
    "customEvent.initEvent('chooseProduct', true, true);" +
    "customEvent.eventName = 'chooseProduct';" +
    "event.target.dispatchEvent(customEvent);}";

    /* Custom Event that gets triggered when the Clear Product Button is clicked - Handled by ComposerChangeHandler */
    const customClearEvent =
    "{const customClearEvent = document.createEvent('HTMLEvents');" +
    "customClearEvent.initEvent('clearProduct', true, true);" +
    "customClearEvent.eventName = 'clearProduct';" +
    "event.target.dispatchEvent(customClearEvent);}";

    const imageHTML = options.imageDataSrc !== "" ? '<img src="' + options.imageDataSrc + '" style="width: 60%; height: 40%;">' : '';

    return {
      id: options.id,
      html:
        '<div style="display: flex; flex-direction: column;"><label data-value="label" ' +
        'style="margin-bottom: 0"></label><label style="color: black;">' + 'ID: ' + options.productId + '<br>' + ' Name: ' +  options.productName + '</label></div><div>' + imageHTML +
        '<button class="btn_green" style="' + matRaisedButtonCSS + '" onclick="' + customEvent + '">' + buttonLabel + '</button>' +
        '<button class="btn_green" style="' + matRaisedButtonCSS + ' margin-top: 0.4vh; margin-bottom: 4vh;" onclick="' + customClearEvent + '">' + clearButtonLabel + '</button>' +
        '</div>',
      get: function (element, node) {
        return {
            label: label
        };
      },
    };
  }

