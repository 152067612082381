import RuleProvider from "diagram-js/lib/features/rules/RuleProvider";
import { every, find, forEach, some } from "lodash";
import { inherits } from "util";

export function ComposerRules(eventBus) {
  RuleProvider.call(this, eventBus);
}

inherits(ComposerRules, RuleProvider);

ComposerRules["$inject"] = ["eventBus"];

ComposerRules.prototype.init = function () {
  this.addRule("connection.create", 20000, function (context) {
    const target = context.target;
    const source = context.source;

    /* Start and End-Event are not allowed to be directly connected */
    if (
      source &&
      target &&
      source.type === "bpmn:StartEvent" &&
      target.type === "bpmn:EndEvent"
    ) {
      return false;
    }

    /* No Element is allowed to connect to itself */
    if (source && target && source === target) {
      return false;
    }

    /* 
      Source is not allowed to already have a Connection to another Element, except for ForkEvents
      Connections to DataObjectReferences are excluded
     */
    if (
      source &&
      source.outgoing.some(connection => connection.businessObject.targetRef.$type !== "bpmn:DataObjectReference") &&
      source.type !== "composer:ForkEvent" && source.type !== "bpmn:DataObjectReference" && target.type !== "bpmn:DataObjectReference"
    ) {
      return false;
    }

    /* Element cannot connect to an Element that already has a Connection with this Element */
    if (source && target && connectedByConnection(source.id, target.outgoing)) {
      return false;
    }

    /* There cannot be two Connections between the the same Elements */
    if (
      source &&
      target &&
      source.outgoing.length > 0 &&
      connectedByConnection(target.id, source.outgoing)
    ) {
      return false;
    }

    /* DataObjectReferences are only allowed to be connected to ToolchainItems */
    if(source && target && source.type === "bpmn:DataObjectReference" && target.type !== "composer:ToolchainItem"){
      return false;
    }

    /* DataObjectReferences are only allowed to be connected from ToolchainItems */
    if(source && target && source.type !== "composer:ToolchainItem" && target.type === "bpmn:DataObjectReference"){
      return false;
    }
  });

  this.addRule("connection.reconnect", 20000, function (context) {
    const target = context.target;
    const source = context.source;

    /* Start and End-Event are not allowed to be directly connected */
    if (
      source &&
      target &&
      source.type === "bpmn:StartEvent" &&
      target.type === "bpmn:EndEvent"
    ) {
      return false;
    }

    /* No Element is allowed to connect to itself */
    if (source && target && source === target) {
      return false;
    }

    /* Source is not allowed to already have a Connection to another Element, except for ForkEvents */
    if (
      source &&
      ((source.outgoing.length === 1 &&
        source.outgoing[0].id !== context.connection.id) ||
        source.outgoing.length > 1) &&
      source.type !== "composer:ForkEvent"
    ) {
      return false;
    }

    /* Element cannot connect to an Element that already has a Connection with this Element */
    if (source && target && connectedByConnection(source.id, target.outgoing)) {
      return false;
    }
  });

  /* There cannot be two StartEvents or EndEvents concurrently placed */
  this.addRule("shape.create", 20000, function (context) {
    if(context.shape.type === "bpmn:StartEvent"){
      if(context.target.children.some(child => child.type === "bpmn:StartEvent")){
        return false;
      }
    } else if(context.shape.type === "bpmn:EndEvent"){
      if(context.target.children.some(child => child.type === "bpmn:EndEvent")){
        return false;
      }
    }
  })
};

/* Checks if an Element is already connected by one of the given Connections */
function connectedByConnection(id: string, connections: any) {
  let contained: boolean;

  connections.forEach((item) => {
    if (item.businessObject.targetRef.id === id) {
      contained = true;
    }
  });

  return contained;
}

