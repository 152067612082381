<mat-toolbar color="primary" class="footer">
  <span class="right">
    <a href="https://www.bimswarm.de/" target="_blank">Über BIMSWARM</a>
    <a href="https://www.bimswarm.de/newsletter-anmeldung" target="_blank">Newsletter</a>
      <!--
    <a href="https://www.bimswarm.de/bimswarm-faq/" target="_blank">FAQ</a>
    <a href="https://www.bimswarm.de/anleitungen" target="_blank">Anleitungen</a>
    -->
    <a href="https://www.bimswarm.de/produkttypen" target="_blank">Produkttypen</a>
    <a href="mailto:contact@bimswarm.de">Kontakt</a>
    <a routerLink="/privacy">Datenschutz</a>
    <a routerLink="/impressum">Impressum</a>
    <a routerLink="/agb">AGB</a>

  </span>
</mat-toolbar>
