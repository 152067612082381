import { ComposerAccessGuard } from './../services/composerAccess-guard-service/composer-access-guard.service';
import { CertificationRequestOverviewComponent } from './../components/certification-request-shared/certification-request-overview/certification-request-overview.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuardService as AuthGuard } from "../services/auth-guard-service/authGuardService";
import { PtgEditResolver } from "../resolvers/ptg-edit-resolver";
import { PtgListResolver } from "../resolvers/ptg-list-resolver";
import { ProductListResolver } from "../resolvers/product-list-resolver";
import { OrganisationResolver } from "../resolvers/organisation-resolver";
import { VariantResolver } from "../resolvers/variant-resolver";
import { LoadingResolver } from "../resolvers/loading-resolver";
import { PtgVersionResolver } from "../resolvers/ptg-version-resolver";
import { VariantVersionResolver } from "../resolvers/variant-version-resolver";
import { AnonGuard } from "../services/anon-guard/anon.guard";

import { CertificationListResolver } from "../resolvers/certification-list-resolver";
import { CertificationVariantResolver } from "../resolvers/certification-variant-resolver";
import { CertificationVariantVersionResolver } from "../resolvers/certification-variant-version-resolver";
import { CertificationDocumentListResolver } from "../resolvers/certification-document-list-resolver";
import { PtgAttributeCatalogueComponent } from "../components/attributes/attribute-tables/ptg-attribute-catalogue/ptg-attribute-catalogue.component";
import { AttributesScreenComponent } from "../components/attributes/attributes-screen/attributes-screen.component";
import { MalfunctionOverviewOrganisationComponent } from "../components/malfunction/malfunction-overview-organisation/malfunction-overview-organisation.component";
import { MalfunctionOverviewComponent } from "../components/malfunction/malfunction-overview/malfunction-overview.component";
import { CertificationDetailDisplayComponent } from "../components/marketplace/detail-pages/certification-detail-display/certification-detail-display.component";
import { ProductDetailDisplayComponent } from "../components/marketplace/detail-pages/product-detail-display/product-detail-display.component";
import { MarketplaceOverviewComponent } from "../components/marketplace/marketplace-overview.component";
import { HomeScreenComponent } from "../components/miscellaneous/home-screen/home-screen.component";
import { OrganisationInformationComponent } from "../components/organisation-user-settings/views-organisation-management/organisation-information/organisation-information.component";
import { CertificationRequestsComponent } from './../components/organisation-user-settings/views-organisation-management/certification-requests/certification-requests.component';
import { OrganisationUserComponent } from "../components/organisation-user-settings/views-organisation-management/organisation-user/organisation-user.component";
import { KontoScreenComponent } from "../components/organisation-user-settings/views-user-settings/konto-screen/konto-screen.component";
import { OrganisationScreenComponent } from "../components/organisation-user-settings/views-user-settings/organisation-screen/organisation-screen.component";
import { SettingsScreenComponent } from "../components/organisation-user-settings/views-user-settings/settings-screen/settings-screen.component";
import { CreateCertificationVersionScreenComponent } from "../components/product-certification/certifications/certification-variant-versions/certification-version-create-screen/create-certification-version.component";
import { CertVersionManageScreenComponent } from "../components/product-certification/certifications/certification-variant-versions/certification-version-manage-screen/cert-verrsion-manage-screen.component";
import { CertificationVariantCreateScreenComponent } from "../components/product-certification/certifications/certification-variants/certification-variant-create-screen/certification-variant-create-screen.component";
import { CertificationVariantEditScreenComponent } from "../components/product-certification/certifications/certification-variants/certification-variant-edit-screen/certification-variant-edit-screen.component";
import { CertificationVariantScreenComponent } from "../components/product-certification/certifications/certification-variants/certification-variant-screen/certification-variant-screen.component";
import { CertificationEditScreenComponent } from "../components/product-certification/certifications/certifications/certification-edit-screen/certification-edit-screen.component";
import { CertificationInfoScreenComponent } from "../components/product-certification/certifications/certifications/certification-info-screen/certification-info-screen.component";
import { CertificationNoContentScreenComponent } from "../components/product-certification/certifications/certifications/certification-no-content-screen/certification-no-content-screen.component";
import { CertificationScreenComponent } from "../components/product-certification/certifications/certifications/certification-screen/certification-screen.component";
import { ProductVariantVersionCreateScreenComponent } from "../components/product-certification/products/product-variant-version-create-screen/product-variant-version-create-screen.component";
import { CreateProductVariantScreenComponent } from "../components/product-certification/products/product-variants/create-product-variant-screen/create-product-variant-screen.component";
import { EditProductVariantScreenComponent } from "../components/product-certification/products/product-variants/edit-product-variant-screen/edit-product-variant-screen.component";
import { ProductVariantScreenComponent } from "../components/product-certification/products/product-variants/product-variant-screen/product-variant-screen.component";
import { ProductEditScreenComponent } from "../components/product-certification/products/products/product-edit-screen/product-edit-screen.component";
import { ProductInfoScreenComponent } from "../components/product-certification/products/products/product-info-screen/product-info-screen.component";
import { ProductScreenComponent } from "../components/product-certification/products/products/product-screen/product-screen.component";
import { ProductSsoClientRegistrationComponent } from "../components/product-certification/products/products/product-sso-client-registration/product-sso-client-registration.component";
import { ProductsNoContentComponent } from "../components/product-certification/products/products/products-no-content/products-no-content.component";
import { ProductListComponent } from "../components/product-list/product-list.component";
import { ProductTypeGroupEditComponent } from "../components/product-type-groups/product-type-group-edit/product-type-group-edit.component";
import { ProductTypeGroupNoContentComponent } from "../components/product-type-groups/product-type-group-no-content/product-type-group-no-content.component";
import { ProductTypeGroupScreenComponent } from "../components/product-type-groups/product-type-group-screen/product-type-group-screen.component";
import { ProductTypeGroupVersionScreenComponent } from "../components/product-type-groups/product-type-group-version-screen/product-type-group-version-screen.component";
import { PtgOverviewComponent } from "../components/product-type-groups/ptg-overview/ptg-overview.component";
import { LoginScreenComponent } from "../components/registration-login/login/login-screen/login-screen.component";
import { PasswordResetScreenComponent } from "../components/registration-login/login/password-reset-screen/password-reset-screen.component";
import { ResendMailScreenComponent } from "../components/registration-login/login/resend-mail-screen/resend-mail-screen.component";
import { RegistryConfirmComponent } from "../components/registration-login/registration/registry-confirm/registry-confirm.component";
import { RegistryPageComponent } from "../components/registration-login/registration/registry-page/registry-page.component";
import { VerifyUserNameComponent } from "../components/registration-login/registration/verify-user-name/verify-user-name.component";
import { CommonFeatureAdministrationComponent } from "../components/common-feature/common-feature-administration/common-feature-administration.component";
import { OrganisationDetailDisplayComponent } from '../components/marketplace/detail-pages/organisation-detail-display/organisation-detail-display.component';
import { ComposerEditorScreenComponent } from '../components/composer/composer-editor-screen/composer-editor-screen.component';
import { ComposerScreenComponent } from '../components/composer/composer-screen/composer-screen.component';
import { ComposerOrganisationScreenComponent } from '../components/composer/composer-organisation-screen/composer-organisation-screen.component';
import { DataFormatsScreenComponent } from '../components/compatiblity-features/data-formats-screen/data-formats-screen.component';
import { EditDataFormatScreenComponent } from '../components/compatiblity-features/edit-data-format-screen/edit-data-format-screen.component';
import { DocumentTypesScreenComponent } from '../components/compatiblity-features/document-types-screen/document-types-screen.component';
import { EditDocumentTypeScreenComponent } from '../components/compatiblity-features/edit-document-type-screen/edit-document-type-screen.component';
import { ReviewAdministrationComponent } from '../components/reviews/review-administration/review-administration.component';
import { ComposerViewerScreenComponent } from '../components/composer/composer-viewer-screen/composer-viewer-screen.component';
import { ToolchainDetailDisplayComponent } from '../components/marketplace/detail-pages/toolchain-detail-display/toolchain-detail-display.component';
import { UserProfilePublicComponent } from "../components/components-profiles/user-profile-public/user-profile-public.component";
import { UserProfileScreenComponent } from "../components/components-profiles/user-profile-screen/user-profile-screen.component";
import { OrganisationDetailResolver } from "../resolvers/organisation-detail-resolver";

import {NutzerverwaltungScreenComponent} from "../components/organisation-user-settings/views-user-settings/nutzerverwaltung-screen/nutzerverwaltung-screen.component";
import { VersionCertificationRequestStatusComponent } from '../components/product-certification/products/version-certification-request-status/version-certification-request-status.component';
import { VersionCertificationRequestComponent } from '../components/product-certification/products/version-certification-request/version-certification-request.component';
import { CertificationRequestProcessingComponent } from '../components/organisation-user-settings/views-organisation-management/certification-requests/certification-request-processing/certification-request-processing.component';
import { ImpressumComponent } from '../components/impressum/impressum.component';
import { AgbComponent } from '../components/agb/agb.component';

import { PrivacyComponent } from '../components/privacy/privacy.component';
import {PtgForProductVariantVersionResolver} from "../resolvers/PtgForProductVariantVersionResolver";
import { StartingPageComponent } from '../gaiax/core/starting-page/starting-page.component';
import { FileUploadComponent } from '../gaiax/core/file-upload/component/file-upload.component';
import { DynamicFormComponent } from '../gaiax/core/dynamic-form/dynamic-form.component';
import { SelectShapeComponent } from '../gaiax/core/select-shape/select-shape.component';
import { SelectFileComponent } from '../gaiax/core/select-file/select-file.component';
import { CamundaProcessListComponent } from '../components/camunda/camunda-process-list/camunda-process-list.component';
import { ServiceTaskComponent } from '../components/camunda/service-task/service-task.component';
import { CamundaTaskComponent } from '../components/camunda/camunda-task/camunda-task.component';


const routes: Routes = [
  {
    path: 'upload',
    component: FileUploadComponent,
    canActivate: [AuthGuard, ComposerAccessGuard],
  },
  {
  path: 'form',
  component: DynamicFormComponent,
  canActivate: [AuthGuard, ComposerAccessGuard],

  },
  {
  path: 'select-shape',
  component: SelectShapeComponent,
  canActivate: [AuthGuard, ComposerAccessGuard],
  },
  {
  path: 'select-file',
  component: SelectFileComponent,
  canActivate: [AuthGuard, ComposerAccessGuard],
  },
  {
  path: "",
  resolve: { loadingResovler: LoadingResolver },
  children: [
      {
        path: "registry",
        children: [
          { path: "", component: RegistryPageComponent },
          { path: "confirm", component: RegistryConfirmComponent },
          { path: "**", component: RegistryPageComponent },
        ],
      },
      {
        path: "login",
        component: LoginScreenComponent,
        pathMatch: "full",
      },
      {
        path: "resetPassword",
        component: PasswordResetScreenComponent,
        pathMatch: "full",
      },
      {
        path: "resendMail",
        component: ResendMailScreenComponent,
        pathMatch: "full",
      },
      {
        path: "verify_old",
        component: VerifyUserNameComponent,
        pathMatch: "full",
      },
      {
        path: "verify_new",
        component: VerifyUserNameComponent,
        pathMatch: "full",
      },
      { path: "impressum",
      component: ImpressumComponent,
      pathMatch: "full",
      canActivate: [AuthGuard],
    },    { path: "agb",
          component: AgbComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
          },
      { path: "privacy",
      component: PrivacyComponent,
      pathMatch: "full",
      canActivate: [AuthGuard],
      },
      {
        path: "composer",
        canActivate: [AuthGuard, ComposerAccessGuard],
        children: [
          {
            path:"",
            component: CamundaProcessListComponent,
            canActivate: [AuthGuard, ComposerAccessGuard],
            pathMatch: "full"
          },
          {
            path:"organisations",
            component: ComposerOrganisationScreenComponent,
            canActivate: [AuthGuard, ComposerAccessGuard],
            pathMatch: "full"
          },
          {
            path:"template/:toolchainId",
            component: ComposerEditorScreenComponent,
            canActivate: [AuthGuard, ComposerAccessGuard],
            pathMatch: "full"
          },
          {
            path:"instance/:toolchainId",
            component: ComposerViewerScreenComponent,
            canActivate: [AuthGuard, ComposerAccessGuard],
            pathMatch: "full"
          },
          {
            path:"startprocess/list",
            component: CamundaProcessListComponent,
            canActivate: [AuthGuard, ComposerAccessGuard],
            pathMatch: "full"
          },
          {
            path:"startprocess",
            component: ServiceTaskComponent,
            canActivate: [AuthGuard, ComposerAccessGuard],
            pathMatch: "full"
          },
          {
            path:"camundaTasks",
            component: CamundaTaskComponent,
            canActivate: [AuthGuard, ComposerAccessGuard],
            pathMatch: "full"
          },
          
        ]
      },
      {
        path: "profil/konto",
        component: KontoScreenComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "profil/mitgliedschaften",
        component: OrganisationScreenComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "profil/commonfeatures",
        component: CommonFeatureAdministrationComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "profil/dataformats",
        component: DataFormatsScreenComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "profil/dataformats/add",
        component: EditDataFormatScreenComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "profil/dataformats/edit/:dataFormatId",
        component: EditDataFormatScreenComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },

      {
        path: "profil/documenttypes",
        component: DocumentTypesScreenComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "profil/documenttypes/add",
        component: EditDocumentTypeScreenComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "profil/documenttypes/edit/:documentTypeId",
        component: EditDocumentTypeScreenComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "profil/reviewReports",
        component: ReviewAdministrationComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "profil/settings",
        component: SettingsScreenComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "profil/:user/public",
        component: UserProfilePublicComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "profil/nutzerverwaltung",
        component: NutzerverwaltungScreenComponent,
        pathMatch: "full",
        canActivate: [AuthGuard]
      },
      {
        path: "profil/:user",
        component: UserProfileScreenComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "meineProdukte",
        component: ProductListComponent,
        pathMatch: "full",
        canActivate: [AuthGuard],
      },
      {
        path: "malfunction/:productId/overview",
        pathMatch: "full",
        component: MalfunctionOverviewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "marktplatz/products/:productId",
        component: ProductDetailDisplayComponent,
        pathMatch: "full",
        resolve: {
          ptgList: PtgListResolver,
        },
      },
      {
        path: "marktplatz/certification/:certificationId",
        component: CertificationDetailDisplayComponent,
        pathMatch: "full",
        resolve: { ptgList: PtgListResolver },
      },
      {
        path: "marktplatz/toolchain/:toolchainId",
        component: ToolchainDetailDisplayComponent,
        pathMatch: "full",
      },
      {
        path: "marktplatz",
        component: MarketplaceOverviewComponent,
        pathMatch: "full",
        resolve: { ptgList: PtgListResolver },
      },
      {
        path: ":organisationDomain",
        component: OrganisationDetailDisplayComponent,
        pathMatch: "full",
        resolve: { organisationdetailResolver: OrganisationDetailResolver },
      },
      {
        path: "organisations/:organisationId",
        //pathMatch: "full",
        canActivate: [AuthGuard, AnonGuard],
        resolve: { organisationResolver: OrganisationResolver },
        children: [
          {
            path: "information",
            component: OrganisationInformationComponent,
            pathMatch: "full",
            canActivate: [AuthGuard],
          },
          {
            path: "benutzer",
            component: OrganisationUserComponent,
            pathMatch: "full",
            canActivate: [AuthGuard],
          },
          {
            path: "certificationRequests",
            canActivate: [AuthGuard],
            children: [
              {
                path:"",
                component: CertificationRequestsComponent,
                canActivate: [AuthGuard],
                resolve: { organisationResolver: OrganisationResolver },
                pathMatch: "full"
              },
              {
                path: "details",
                component: CertificationRequestProcessingComponent,
                pathMatch: "full",
                canActivate: [AuthGuard],
                resolve: { organisationResolver: OrganisationResolver },
              }
            ]
          },
          {
            path: "productTypeGroups",
            // component: ProductTypeGroupNoContentComponent,
            //pathMatch: "full",
            canActivate: [AuthGuard],
            resolve: { ptgList: PtgListResolver },
            children: [
              {
                path: "create",
                component: ProductTypeGroupScreenComponent,
                pathMatch: "full",
                canActivate: [AuthGuard],
                // resolve: { ptgList: PtgListResolver },
              },
              {
                path: ":ptgId",
                //component: PtgOverviewComponent,
                //pathMatch: "full",
                canActivate: [AuthGuard],
                resolve: { ptgEdit: PtgEditResolver },
                children: [
                  {
                    path: "versions/create",
                    component: ProductTypeGroupVersionScreenComponent,
                    pathMatch: "full",
                    canActivate: [AuthGuard],
                    //resolve: { ptgEdit: PtgEditResolver },
                  },
                  {
                    path: "edit",
                    component: ProductTypeGroupEditComponent,
                    pathMatch: "full",
                    canActivate: [AuthGuard],
                    //resolve: { ptgEdit: PtgEditResolver },
                  },

                  {
                    path: "versions/:versionId",
                    component: PtgOverviewComponent,
                    pathMatch: "full",
                    canActivate: [AuthGuard],
                    resolve: { ptgEdit: PtgVersionResolver },
                  },
                  {
                    path: "versions/:versionId/edit",
                    component: ProductTypeGroupVersionScreenComponent,
                    pathMatch: "full",
                    canActivate: [AuthGuard],
                    resolve: { ptgEdit: PtgVersionResolver },
                  },
                  {
                    path: "",
                    component: PtgOverviewComponent,
                    //pathMatch: "full",
                    canActivate: [AuthGuard],
                    //resolve: { ptgEdit: PtgEditResolver },
                  },
                ],
              },
              {
                path: "",
                component: ProductTypeGroupNoContentComponent,
                //pathMatch: "full",
                canActivate: [AuthGuard],
                //resolve: { ptgEdit: PtgEditResolver },
              },
            ],
          },
          {
            path: "products",
            //  pathMatch: "full",
            canActivate: [AuthGuard],
            resolve: { ptgList: PtgListResolver },
            children: [
              {
                path: ":productId/malfunctionManagement",
                component: MalfunctionOverviewOrganisationComponent,
                pathMatch: "full",
                canActivate: [AuthGuard],
              },
              {
                path: "create",
                component: ProductScreenComponent,
                pathMatch: "full",
                canActivate: [AuthGuard],
                resolve: { productList: ProductListResolver },
              },
              {
                path: ":productId/sso",
                component: ProductSsoClientRegistrationComponent,
                pathMatch: "full",
                canActivate: [AuthGuard],
                resolve: { productList: ProductListResolver },
              },
              {
                path: ":productId",
                component: ProductInfoScreenComponent,
                pathMatch: "full",
                canActivate: [AuthGuard],
                resolve: { productList: ProductListResolver },
              },
              {
                path: ":productId/edit",
                component: ProductEditScreenComponent,
                pathMatch: "full",
                canActivate: [AuthGuard],
                resolve: {
                  productList: ProductListResolver,
                },
              },
              {
                path: ":productId/variants",
                //pathMatch: "full",
                canActivate: [AuthGuard],
                resolve: { productList: ProductListResolver },
                children: [
                  {
                    path: "create",
                    component: CreateProductVariantScreenComponent,
                    pathMatch: "full",
                    canActivate: [AuthGuard],
                  },
                  {
                    path: ":variantId",
                    //component: ProductVariantScreenComponent,
                    //pathMatch: "full",
                    canActivate: [AuthGuard],
                    resolve: { variantResolver: VariantResolver },
                    children: [
                      {
                        path: "version/:versionId/edit",
                        component: ProductVariantVersionCreateScreenComponent,
                        pathMatch: "full",
                        canActivate: [AuthGuard],
                        resolve: {
                          versionResolver: VariantVersionResolver,
                          ptgForProductVariantResolver: PtgForProductVariantVersionResolver
                        },
                      },
                      {
                        path: "version/:versionId/certification",
                        component: VersionCertificationRequestComponent,
                        pathMatch: "full",
                        canActivate: [AuthGuard],
                        resolve: {
                          versionResolver: VariantVersionResolver,
                        },

                      },
                      {
                        path: "version/:versionId/certificationRequests",
                        component: VersionCertificationRequestStatusComponent,
                        pathMatch: "full",
                        canActivate: [AuthGuard],
                        resolve: {
                          versionResolver: VariantVersionResolver,
                        },
                      },
                      {
                        path: "version/:versionId/create",
                        component: ProductVariantVersionCreateScreenComponent,
                        pathMatch: "full",
                        canActivate: [AuthGuard],
                        resolve: {
                          versionResolver: VariantVersionResolver,
                          ptgForProductVariantResolver: PtgForProductVariantVersionResolver                        },
                      },
                      {
                        path: "edit",
                        component: EditProductVariantScreenComponent,
                        pathMatch: "full",
                        canActivate: [AuthGuard],
                      },
                      {
                        path: "",
                        component: ProductVariantScreenComponent,
                        pathMatch: "full",
                        canActivate: [AuthGuard],
                      },
                    ],
                  },

                  {
                    path: "",
                    component: ProductInfoScreenComponent,
                    pathMatch: "full",
                    canActivate: [AuthGuard],
                  },
                ],
              },
              {
                path: "",
                component: ProductsNoContentComponent,
                pathMatch: "full",
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: "products",
            component: ProductsNoContentComponent,
            pathMatch: "full",
            canActivate: [AuthGuard],
          },

          {
            path: "certifications",
            //component: ProductsNoContentComponent,
            //  pathMatch: "full",
            canActivate: [AuthGuard],
            resolve: {
              certificationList: CertificationListResolver,
              ptgList: PtgListResolver,
            },

            children: [
              {
                path: "create",
                component: CertificationScreenComponent,
                pathMatch: "full",
                canActivate: [AuthGuard],
                resolve: { certificationList: CertificationListResolver },
              },
              {
                path: ":certificationId",
                component: CertificationInfoScreenComponent,
                pathMatch: "full",
                canActivate: [AuthGuard],
                resolve: { certificationList: CertificationListResolver },
              },
              {
                path: ":certificationId/edit",
                component: CertificationEditScreenComponent,
                pathMatch: "full",
                canActivate: [AuthGuard],
                resolve: {
                  certificationList: CertificationListResolver,
                  documentList: CertificationDocumentListResolver,
                },
              },
              {
                path: ":certificationId/variants",
                //pathMatch: "full",
                canActivate: [AuthGuard],
                resolve: { certificationList: CertificationListResolver },
                children: [
                  {
                    path: "create",
                    component: CertificationVariantCreateScreenComponent,
                    pathMatch: "full",
                    canActivate: [AuthGuard],
                  },
                  {
                    path: ":certificationVariantId",
                    //component: ProductVariantScreenComponent,
                    //pathMatch: "full",
                    canActivate: [AuthGuard],
                    resolve: {
                      cetrificationVariantResolver: CertificationVariantResolver,
                    },
                    children: [
                      {
                        path: "version/:certificationVersionId/edit",
                        component: CreateCertificationVersionScreenComponent,
                        pathMatch: "full",
                        canActivate: [AuthGuard],
                        resolve: {
                          versionResolver: CertificationVariantVersionResolver,
                        },
                      },
                      {
                        path: "version/:certificationVersionId/manage",
                        component: CertVersionManageScreenComponent,
                        pathMatch: "full",
                        canActivate: [AuthGuard],
                        resolve: {
                          versionResolver: CertificationVariantVersionResolver,
                        },
                      },
                      {
                        path: "version/create",
                        component: CreateCertificationVersionScreenComponent,
                        pathMatch: "full",
                        canActivate: [AuthGuard],
                        resolve: {
                          versionResolver: CertificationVariantVersionResolver,
                        },
                      },
                      {
                        path: "edit",
                        component: CertificationVariantEditScreenComponent,
                        pathMatch: "full",
                        canActivate: [AuthGuard],
                      },
                      {
                        path: "",
                        component: CertificationVariantScreenComponent,
                        pathMatch: "full",
                        canActivate: [AuthGuard],
                      },
                    ],
                  },

                  {
                    path: "",
                    component: CertificationInfoScreenComponent,
                    pathMatch: "full",
                    canActivate: [AuthGuard],
                  },
                ],
              },
              {
                path: "",
                component: CertificationNoContentScreenComponent,
                pathMatch: "full",
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: "certifications",
            component: CertificationNoContentScreenComponent,
            pathMatch: "full",
            canActivate: [AuthGuard],
          },
          {
            path: "features",
            component: PtgAttributeCatalogueComponent,
            pathMatch: "full",
            canActivate: [AuthGuard],
            resolve: { ptgList: PtgListResolver },
          },
          {
            path: "features/ptgs",
            component: PtgAttributeCatalogueComponent,
            pathMatch: "full",
            canActivate: [AuthGuard],
          },
          {
            path: "features/create",
            component: AttributesScreenComponent,
            pathMatch: "full",
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "", redirectTo: "/marktplatz", pathMatch: "full" },
    ],
  },
  { path: "**", redirectTo: "/marktplatz", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
