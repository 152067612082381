import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CertificationRequestStatus } from 'src/app/model/enums/certification-requests-status';

@Component({
  selector: 'app-cert-req-change-status',
  template: `
  <div [formGroup]="form" style="width=50%;">
    <h1 mat-dialog-title>{{data.title}}</h1>
    <mat-form-field style="width: 30em;" appearance="outline">
      <textarea
      matInput
        rows="10"
        cols="30"
        formControlName="requested"
        placeholder="Bitte geben Sie an, welche Dateien zur Verfügung gestellt werden sollen."
        required
      ></textarea>
      <mat-error *ngIf="form.get('requested').invalid">
        Angabe der fehlenden Dateien wird benötigt.
      </mat-error>
    </mat-form-field>
    <div mat-dialog-actions>
      <button mat-button (click)="onClick(false)" class="btn_red" cdkFocusInitial style="float: left;">{{ data.buttonNoLabel }}</button>
      <button mat-button (click)="onClick(true)" class="btn_green" cdkFocusInitial [disabled]="form.invalid" style="float: right;">{{ data.buttonYesLabel }}</button>
    </div>
  </div>
`,
  styleUrls: ['./dialog.css']
})
export class DialogFileRequestComponent{

  requested: string = "";
  form = new FormGroup({
    requested: new FormControl("", [
      Validators.required,
    ]),
  });
  
  constructor(
    public dialogRef: MatDialogRef<DialogFileRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{
      title: string;
      buttonNoLabel: string;
      buttonYesLabel: string;
      
    }) {}

  onClick(confirm: boolean): void{
    if(confirm) {
      this.requested = this.form.get("requested").value;
      this.dialogRef.close(this.requested);
    } else {
      this.dialogRef.close();
    }
  }
}