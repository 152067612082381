import { Component, Input, OnInit } from '@angular/core';
import { PublicReviewInterface } from 'src/app/model/reviews/public-review-interface';

@Component({
  selector: 'app-public-activity-item',
  templateUrl: './public-activity-item.component.html',
  styleUrls: ['./public-activity-item.component.css']
})
export class PublicActivityItemComponent implements OnInit {

  @Input()
  public review: PublicReviewInterface;

  @Input()
  public username: string;

  constructor() { }

  ngOnInit() {
  }

}
