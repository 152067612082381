import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'productVariantsOfPtg'})
export class FilterProductVariantsOfPtg implements PipeTransform {
  transform(productVariants, ptg) {
    if (!ptg) {
      return productVariants;
    }

    const filteredVariants = [];

      for (const variant of productVariants) {
        for (const version of variant.variantVersions) {
          for (const versionPtgVersion of version.ptgVersions) {
            if (ptg.versions.find(version => version.id === versionPtgVersion.id)) {
              if (filteredVariants.indexOf(variant) === -1) {
                filteredVariants.push(variant);
              }
            }
          }
        }
      }
    return filteredVariants;
  }
}
