<div class="file-upload-container">
  <input type="file" id="fileInput" (change)="selectAndUpload($event)" class="file-input" style="display: none;">
  <label for="fileInput" class="custom-file-button">Datei auswählen</label>

  <div *ngIf="message" class="upload-message">{{ message }}</div>
  <div *ngIf="progress > 0" class="upload-progress">Hochladen... {{ progress }}%</div>
</div>
<div *ngIf="selectedFiles" class="selected-file">
  Ausgewählte Datei: {{ selectedFile.name }}
</div>
