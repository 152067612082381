import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,} from "@angular/core";

import * as BpmnJS from "bpmn-js/dist/bpmn-modeler.production.min.js";
import propertiesPanelModule from "bpmn-js-properties-panel";
import propertiesPanel from "bpmn-js-properties-panel/lib/PropertiesPanel";
import propertiesProviderModule from "bpmn-js-properties-panel/lib/provider/bpmn";
import {CustomContextPad} from "./custom/customContextPad";
import {ComposerModdle} from "./custom/composerModdle";
import ForkEventRender from "./custom/renderer/forkEventRender";
import JoinEventRender from "./custom/renderer/joinEventRender";
import {loadIcons,} from "./util/preloadedAssets";
import {CustomPalette} from "./custom/customPalette";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {MatSelectChange} from "@angular/material/select";
import ToolchainItemRender from "./custom/renderer/toolchainItemRender";
import {ToolchainPropertiesProvider} from "./custom/propertiesProvider/toolchainPropertiesProvider";
import {
  buildComposerChangeHandler,
  ComposerEntriesChanged,
  reloadPropertiesPanel,
} from "./custom/composerChangeHandler";
import {
  setOrganisationSelectOptions,
  setPTGOptions,
  updateCurrentPropertiesPanelItem,
} from "./custom/composerChangeBuffer";
import {DialogService} from "src/app/services/dialog-service/dialog-service.service";
import {Observable} from "rxjs";
import {ComposerRules} from "./custom/composerRules";
import {PtgService} from "src/app/services/dataServices/ptg-service/ptgService";
import {ComposerService} from "src/app/services/composer-service/composer.service";
import {Router} from "@angular/router";
import {ComposerProductFileInterface} from "src/app/model/composer/composer-product-file-interface";
import {ProductlistService} from "src/app/services/productlist-service/productlist.service";
import {ProductOverview} from "src/app/model/product-overview/product-overview";
import {OrganisationService} from "src/app/services/dataServices/organisation-service/organisationservice";
import {UserService} from "src/app/services/user-service/userservice";
import {OrganisationMemberInterface} from "src/app/model/organisations/organisationMember-interface";
import {ProductService} from "src/app/services/dataServices/product-service/productService";
import {OrganisationType} from "src/app/model/enums/organisationType";
import {OrganisationInterface} from "src/app/model/organisations/organisation-interface";

@Component({
  selector: "app-model-editor",
  templateUrl: "./model-editor.component.html",
  styleUrls: ["./model-editor.component.css"],
})
export class ModelEditorComponent implements OnInit {
  private bpmnJS: BpmnJS;

  public showMyProducts: boolean = true;
  private selectedOrganisation: OrganisationMemberInterface;

  private xmlImported: boolean = false;

  private reloadPage: boolean = false;

  private myProducts: ProductOverview[] = [];

  private selectedOrganisationProducts: ProductOverview[] = [];

  private availableProductOrganisations: OrganisationInterface[] = [];

  @ViewChild("canvas") private canvas: ElementRef;

  @Input()
  private xml: string;

  @Input()
  private fileIdsForXML: ComposerProductFileInterface[];

  @Output()
  private validityChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private dialogService: DialogService,
    private ptgService: PtgService,
    private composerService: ComposerService,
    private router: Router,
    private productlistService: ProductlistService,
    private userService: UserService,
    private productService: ProductService,
    private organisationService: OrganisationService
  ) {
    this.ptgService.getAllPtgs().then((success) => {
      if (success) {
        setPTGOptions(this.ptgService.collection);
        if (this.bpmnJS && this.xmlImported) {
          reloadPropertiesPanel(this.bpmnJS);
        }
      }
    });

    this.organisationService.getAllOrgsIfAdminAndMembershipOrgsIfUser().then((success) => {
      if (success) {
        setOrganisationSelectOptions(
          this.organisationService.collection.map((organisation) => {
            return {
              organisationId: organisation.organisationId,
              name: organisation.organisationName,
            };
          })
        );
        if (this.bpmnJS && this.xmlImported) {
          reloadPropertiesPanel(this.bpmnJS);
        }
      }
    });

    this.productlistService
      .getAllListElementsOfUser()
      .subscribe((allProducts) => {
        this.myProducts = allProducts;

        this.composerService
          .loadDataUrlsForPaletteProducts(this.myProducts)
          .subscribe((success) => {
            if (success) {
              if (this.bpmnJS) {
                this.reloadPalette();
              }
            }
          });
      });

    if (userService.isCurrentUserPlatformAdmin()) {
      this.organisationService.getAllOrgs().then((success) => {
        if (success) {
          this.organisationService.collection.forEach(orga => {
            if (orga.organisationType === OrganisationType.PRODUCT_PROVIDER) {
              this.availableProductOrganisations.push(orga);
            }
          });
        }
      });
    } else {
      this.userService.currentMemberships.forEach((organisation) => {
        this.organisationService
          .getOrganisation(organisation.organisationId)
          .then((success) => {
            if (success) {
              if (
                success.organisationType === OrganisationType.PRODUCT_PROVIDER
              ) {
                this.availableProductOrganisations.push(organisation);
              }
            }
          });
      });
    }
  }

  ngOnInit() {
    /* Preloads Assets that can be needed for the Editor */
    loadIcons().subscribe((success) => {
      if (success) {
        this.createBPMNJsInstance();
        this.reloadPalette();
      }
    });
  }

  /* Loads the XML into the BpmnJS Instance */
  private loadXML() {
    this.bpmnJS.importXML(this.xml).then(
      (success) => {
        this.xmlImported = true;

        if (this.reloadPage) {
          const newId = parseInt(
            this.bpmnJS
              .get("elementRegistry")
              .filter(
                (element) => element.businessObject.$type === "bpmn:Process"
              )[0]
              .id.substring(1)
          );
          this.router.navigateByUrl("/composer/template/" + newId);
        }
      },
      (error) => {
      }
    );
    this.reloadPalette();
  }

  ngOnDestroy() {
    this.bpmnJS.destroy();
  }

  /* Toggles whether the own Products or the Products of an Organisation should be shown */
  public toggleButtonChange(event: MatButtonToggleChange) {
    this.showMyProducts = event.value === "myProducts";
    this.reloadPalette();
  }

  /* Updates the selected Organisation on Change */
  public changedSelectedOrganisation(event: MatSelectChange) {
    this.selectedOrganisation = event.value;

    this.selectedOrganisationProducts = [];

    this.productService
      .loadProductOverviewsOfOrganisation(
        this.selectedOrganisation.organisationId
      )
      .subscribe((productOverviews) => {
        if (productOverviews) {
          this.selectedOrganisationProducts = productOverviews;

          this.composerService
            .loadDataUrlsForPaletteProducts(this.selectedOrganisationProducts)
            .subscribe((finished) => {
              if (finished) {
                this.reloadPalette();
              }
            });
        }
      });
  }

  /* Creates the BPMNJS Instance with all custom Modules */
  private createBPMNJsInstance() {
    //Overrides Methods with custom Methods
    propertiesPanel.prototype._bindListeners = buildComposerChangeHandler(
      this,
      this.composerService
    );
    propertiesPanel.prototype._entriesChanged = ComposerEntriesChanged;

    this.bpmnJS = new BpmnJS({
      keyboard: {
        bindTo: document,
      },
      container: "#canvas",
      propertiesPanel: {
        parent: "#propertiesPanel",
      },
      //Custom Modules that override standard Modules
      additionalModules: [
        propertiesPanelModule,
        propertiesProviderModule,
        {
          dialogService: ["type", () => this.dialogService],
        },
        {
          showMyProducts: ["type", () => this.showMyProducts],
        },
        {
          myProducts: ["type", () => this.myProducts],
        },
        {
          selectedOrganisationProducts: [
            "type",
            () => this.selectedOrganisationProducts,
          ],
        },
        {
          contextPadProvider: ["type", CustomContextPad],
        },
        {
          paletteProvider: ["type", CustomPalette],
        },
        {
          __init__: ["composerRuleProvider"],
          composerRuleProvider: ["type", ComposerRules],
        },
        {
          __init__: ["forkEventRender"],
          forkEventRender: ["type", ForkEventRender],
        },
        {
          __init__: ["joinEventRender"],
          joinEventRender: ["type", JoinEventRender],
        },
        {
          __init__: ["toolchainItemRender"],
          toolchainItemRender: ["type", ToolchainItemRender],
        },
        {
          __init__: ["propertiesProvider"],
          propertiesProvider: ["type", ToolchainPropertiesProvider],
        },
      ],
      //Custom Definition Elements
      moddleExtensions: {
        composer: ComposerModdle,
      },
    });

    this.afterBPMNInstanceCreation();
  }

  /* Adds Listeners and loads the XML */
  private afterBPMNInstanceCreation() {
    this.bpmnJS.get("eventBus").on("selection.changed", function (e) {
      if (e.newSelection.length === 1) {
        updateCurrentPropertiesPanelItem(e.newSelection[0].id);
      } else if (e.newSelection.length === 0) {
        updateCurrentPropertiesPanelItem("");
      }
    });

    this.bpmnJS.on("import.done", ({ error }) => {
      if (!error) {
        this.bpmnJS.get("canvas").zoom("fit-viewport");
      }
    });

    if (this.fileIdsForXML.length === 0) {
      this.loadXML();
    } else {
      this.composerService
        .loadDataUrlsForProductsInXml(this.fileIdsForXML, this.xml, true)
        .subscribe((success) => {
          if (success) {
            this.xml = success;
            this.loadXML();
          }
        });
    }

    this.addChangeListeners();

    this.bpmnJS.attachTo(this.canvas.nativeElement);
  }

  /* Adds Listeners for ChangeEvents */
  private addChangeListeners() {
    this.bpmnJS.on("shape.added", ({ event }) => {
      setTimeout(() => {
        this.checkIfToolchainValid();
      }, 5);
    });

    this.bpmnJS.on("shape.changed", ({ event }) => {
      setTimeout(() => {
        this.checkIfToolchainValid();
      }, 5);
    });

    this.bpmnJS.on("shape.removed", ({ event }) => {
      setTimeout(() => {
        this.checkIfToolchainValid();
      }, 5);
    });

    this.bpmnJS.on("connection.added", ({ event }) => {
      setTimeout(() => {
        this.checkIfToolchainValid();
      }, 5);
    });

    this.bpmnJS.on("connection.changed", ({ event }) => {
      setTimeout(() => {
        this.checkIfToolchainValid();
      }, 5);
    });

    this.bpmnJS.on("connection.removed", ({ event }) => {
      setTimeout(() => {
        this.checkIfToolchainValid();
      }, 5);
    });
  }

  /* Reloads the Palette */
  private reloadPalette() {
    //Calls an Update within the Custom PaletteProvider
    this.bpmnJS
      .get("paletteProvider")
      .update(
        this.showMyProducts,
        this.selectedOrganisationProducts,
        this.myProducts
      );
  }

  /* Returns the current BPMNJS Instance */
  public getBPMNJSInstance() {
    return this.bpmnJS;
  }

  /* Opens the ProductToNode Dialog */
  public openProductToToolchainItemDialog(
    toolchainItemName: string,
    toolchainItemDescription: string
  ): Observable<{
    productName: string;
    productId: string;
    productFileId: string;
  }> {
    return this.dialogService.openAddProductToToolchainItem(
      toolchainItemName,
      toolchainItemDescription
    );
  }

  /* Opens the FileManager Dialog and returns the selected FileId */
  public openFileManagerDialog() {
    return this.dialogService.openFileManagerDialog(
      "Toolchain Logo auswählen",
      "Abbrechen",
      "Auswählen",
      ""
    );
  }

  /* Sends the XML to the Backend and imports the updated XML */
  public async saveModel(xml_original: string) {
    try {
      const result = await this.bpmnJS.saveXML({ format: true });
      const { xml } = result;
      return xml;
    }
    catch (err) {
    }
  }

  /* Sends the XML to the Backend and imports the updated XML */
  public async saveModel_old(xml_original: string) {
    try {
      const result = await this.bpmnJS.saveXML({});
      const { xml } = result;
      this.composerService.saveToolchain(xml).subscribe((newXML) => {
        this.dialogService.openDialog("Toolchain-Template angelegt", "Das Toolchain-Template wurde erfolgreich angelegt.")
        if (newXML) {
          this.reloadPage =
            this.bpmnJS
              .get("elementRegistry")
              .filter(
                (element) => element.businessObject.$type === "bpmn:Process"
              )[0].id === "PNeuerProzess";

          this.xml = newXML;
          this.loadXML();
        }
      });
    } catch (err) {
    }
  }


  public async saveModelList(){
     return this.bpmnJS.get("elementRegistry").getAll();
  }
  /* Checks if the current Toolchain is valid */
  private checkIfToolchainValid() {
    let valid: boolean = true;

    const elementRegistry = this.bpmnJS.get("elementRegistry");

    if (elementRegistry) {
      //Checks if exactly one StartEvent is available
      if (
        elementRegistry.filter(
          (element) => element.businessObject.$type === "bpmn:StartEvent"
        ).length !== 1
      ) {
        valid = false;
      }

      //Checks if exactly one EndEvent is available
      if (
        valid &&
        elementRegistry.filter(
          (element) => element.businessObject.$type === "bpmn:EndEvent"
        ).length !== 1
      ) {
        valid = false;
      }

      //Checks if the Start Event has exactly one Outgoing Connection
      if (
        valid &&
        (!elementRegistry.filter(
          (element) => element.businessObject.$type === "bpmn:StartEvent"
        )[0].businessObject.outgoing ||
          elementRegistry.filter(
            (element) => element.businessObject.$type === "bpmn:StartEvent"
          )[0].businessObject.outgoing.length !== 1)
      ) {
        valid = false;
      }

      //Checks if the End Event has exactly one Incoming Connection
      if (
        valid &&
        (!elementRegistry.filter(
          (element) => element.businessObject.$type === "bpmn:EndEvent"
        )[0].businessObject.incoming ||
          elementRegistry.filter(
            (element) => element.businessObject.$type === "bpmn:EndEvent"
          )[0].businessObject.incoming.length !== 1)
      ) {
        valid = false;
      }

      //Checks if all Toolchain Items have exactly one Incoming and one Outgoing Connection
      elementRegistry
        .filter(
          (element) => element.businessObject.$type === "composer:ToolchainItem"
        )
        .forEach((item) => {
          if (
            !item.businessObject.incoming ||
            item.businessObject.incoming.length !== 1 ||
            !item.businessObject.outgoing ||
            item.businessObject.outgoing.length !== 1
          ) {
            valid = false;
          }
        });

      //Checks if all ForkEvents have exactly one Incoming and at least two Outgoing Connection
      elementRegistry
        .filter(
          (element) => element.businessObject.$type === "composer:ForkEvent"
        )
        .forEach((fork) => {
          if (
            !fork.businessObject.incoming ||
            fork.businessObject.incoming.length !== 1 ||
            !fork.businessObject.outgoing ||
            fork.businessObject.outgoing.length <= 1
          ) {
            valid = false;
          }
        });

      //Checks if all JoinEvents have exactly one Outgoing and at least two Incoming Connection
      elementRegistry
        .filter(
          (element) => element.businessObject.$type === "composer:JoinEvent"
        )
        .forEach((fork) => {
          if (
            !fork.businessObject.incoming ||
            fork.businessObject.incoming.length <= 1 ||
            !fork.businessObject.outgoing ||
            fork.businessObject.outgoing.length !== 1
          ) {
            valid = false;
          }
        });
    }

    this.validityChanged.emit(valid);
  }
}
