<h1>Bewertung verfassen</h1>
<div [formGroup]="form" style="margin-top: -2rem;">
  <div class="titel-stars">
    <div class="headline">
      <mat-form-field class="titel" appearance="standard">
        <input
          matInput
          formControlName="titel"
          placeholder="Geben Sie den Titel ein."
          required
        />
    
      </mat-form-field>
    </div>
    <div>
      <h1 style="font-weight: 500;">Ihre Bewertung</h1>
    </div>
    <div style="margin-top: -1vh;">
      <app-star
        [veryBig]="true"
        (starClickEmitter)="setCurrentRating($event)"
        (starHoverEmitter)="hoverEnter($event)"
        (starHoverEmitter)="hoverLeave($event)"
        [rating]="hoverstate || currentRating"
      ></app-star>
    </div>
    <div class="user-orga-grid">
      <mat-form-field style="width: 47%" appearance="outline">
        <mat-label>Benutzer*</mat-label>
        <input matInput [value]="userService.currentUser.firstName + ' ' + userService.currentUser.lastName" disabled>
      </mat-form-field>
      <mat-form-field style="width: 47%" appearance="outline">
        <mat-label>Arbeitgeber*</mat-label>
        <input matInput [value]="userService.currentUser.employer" disabled>
      </mat-form-field>

    </div>
  </div>
  <div>
    <h1 style="font-weight: 500;">Ihr Kommentar*</h1>
  </div>
  <div>
    <mat-form-field class="input" appearance="outline">
      <textarea
        matInput
        rows="10"
        cols="30"
        formControlName="feedback"
        placeholder="Verfassen Sie eine Bewertung."
        required
      >
      </textarea>
    
    </mat-form-field>
  </div>
  <div style="display: flex; justify-content: flex-start">
    <h1 style="font-weight: 450; font-style: italic; font-size: medium; margin-top: -20px;">Pflichtfelder*</h1>
    <div class="buttons" style="margin-left: auto">
      <button mat-raised-button class="btn_red" (click)="cancel()">
        Abbrechen
      </button>
      <button mat-raised-button class="btn_green" (click)="sendReview()">
        Bewertung absenden
      </button>
    </div>
  </div>
</div>
