import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ProductlistService } from 'src/app/services/productlist-service/productlist.service';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import {Params, Router} from '@angular/router';
import { ProductOverview } from 'src/app/model/product-overview/product-overview';
import { MalfunctionService } from 'src/app/services/malfunction-service/malfunction.service';
import { NavbarService } from 'src/app/services/componentServices/navbar-service/navbar-service';
import { MarketplaceService } from 'src/app/services/dataServices/marketplace-service/marketplace.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
})
export class ProductListComponent implements OnInit, OnDestroy {

  constructor(
    public productlistService: ProductlistService,
    private malfunctionService: MalfunctionService,
    private dialogService: DialogService,
    private changeDetector: ChangeDetectorRef,
    private navbarService: NavbarService,
    private router: Router,
    private marketplaceService: MarketplaceService,
    private titleService: Title
  ) {
  document.documentElement.style.setProperty('--scrollStatus', 'auto');
  }

  ngOnInit() {
    this.getAllProducts();
    this.titleService.setTitle('Meine Merkliste - BIMSWARM');
  }

  ngOnDestroy() {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }
  /**
   * Holt alle Produkte des Users
   */
  getAllProducts(): void {
    this.productlistService.getAllProducts().then(() => {
      this.changeDetector.detectChanges();
    });
  }

  private getProductPreviewPicture(productOverview: ProductOverview): String {
    return productOverview.pictureAvailable
      ? productOverview.pictureUrl
      : environment.defaultPictureProducts;
  }

  /**
   * entfernt Produkt aus Liste des Users
   * @param productId, Id des zu entfernenden Produkts
   */
  deleteProductFromList(productId: number, displayType: number): void {
    if (displayType === 0) {
      this.deleteElementFromList(productId, displayType,
        'Wollen Sie wirklich diese Zertifizierung von Ihrer Merkliste entfernen?',
        'Die Zertifizierung wurde von Ihrer Merkliste entfernt');
    }
    if (displayType === 1) {
      this.deleteElementFromList(productId, displayType,
        'Wollen Sie wirklich dieses Produkt von Ihrer Merkliste entfernen?',
        'Das Produkt wurde von Ihrer Merkliste entfernt');
    }
    if (displayType === 2) {
      this.deleteElementFromList(productId, displayType,
        'Wollen Sie wirklich diesen Anbieter von Ihrer Merkliste entfernen?',
        'Der Anbieter wurde von Ihrer Merkliste entfernt');
    }
  }

  private deleteElementFromList(productId: number, displayType: number, confirmDialogText: string, deleteDialogText: string) {
    this.dialogService
      .openConfirmDialog(
        confirmDialogText,
        'Nein',
        'Ja'
      )
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.productlistService.deleteElementFromList(productId, displayType).subscribe(() => {
            this.dialogService.openDialog(deleteDialogText, '').subscribe(() => {
              this.productlistService.getAllProducts().then(() => {
                this.changeDetector.detectChanges();
                this.redirectIfListEmpty();
              });
            });
          });
        }
      });
  }

  /**
   * Detailsseite des Produktes anzeigen
   * @param productId
   */

  showDetailPage(productId: number, displayType: number): void {
    if (displayType === 0) {
    const queryParams: Params = { certificationId: productId };
    this.router.navigate(
      ['marktplatz'],
      {
        queryParams: queryParams,
        queryParamsHandling: '',
      });
    }
    if (displayType === 1) {
    const queryParams: Params = { productId: productId };
    this.router.navigate(
      ['marktplatz'],
      {
        queryParams: queryParams,
        queryParamsHandling: '',
      });
    }
    if (displayType === 2) {
    const queryParams: Params = { organisationId: productId };
    this.router.navigate(
      ['marktplatz'],
      {
        queryParams: queryParams,
        queryParamsHandling: '',
      });
    }

  }


  private redirectIfListEmpty() {
    if (this.productlistService.getProductList().getValue().length <= 0) {
      this.navbarService.NAV_STATE_MY_PRODUCTS_LIST = false;
      this.router.navigate(['/marktplatz']);
    }
  }

  /**
   * Störungsübersicht des Produktes anzeigen
   * @param productId
   */
  routeToMalfunctionOverview(productId: number): void {
    const url =
      this.router.url.replace('meineProdukte', 'malfunction') +
      '/' +
      productId +
      '/overview';

    this.router.navigateByUrl(url);
  }

}
