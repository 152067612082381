<div class="scrollable" *ngIf="this.product.productVariants != null">
  <!--------------------------------------------------------------->
  <app-detail-display-variant-version-selector *ngIf="this.product != null" [product]="product" [dataType]="'PRODUCT'"
    (onVersionChosen)="onVersionSelected($event)">
  </app-detail-display-variant-version-selector>
  <!--------------------------------------------------------------->
  <div class="desc" *ngIf="version.description && version.description?.length > 0" [ngClass]="{ hidden: !this.showComplete }"
    [innerHTML]="this.version.description"></div>
  <div>
    <br />
    <div class="twocolumnsgrid">
    <h3 style="margin-bottom: 0;">Produkttypen </h3>
    <mat-slide-toggle class="example-margin" color="accent" [checked]="ptgToggle" (change)="ptgToggle = !ptgToggle"></mat-slide-toggle>
    </div>
    <h5 style="font-style: italic;">Zugeordnete Produktkategorien für diese Produktversion</h5>
    <div *ngIf="ptgToggle">
    <mat-chip-list>
      <mat-chip *ngFor="let ptg of ptgVersions" color="accent" selected>{{ this.getPtgName(ptg) }}</mat-chip>
    </mat-chip-list>
    <br />
      <mat-divider></mat-divider>
      <br />
    </div>
    <div class="twocolumnsgrid">
    <h3 style="margin-bottom: 0;">Features</h3>
    <mat-slide-toggle class="example-margin" color="accent" (change)="productFeatureToggle = !productFeatureToggle" [checked]="productFeatureToggle"></mat-slide-toggle>
    </div>
    <h5 style="font-style: italic;">Vom Produktanbieter definierte Produkteigenschaften</h5>
    <div #productFeatureList *ngIf="productFeatureToggle" >
        <mat-chip-list>
        <mat-chip *ngFor="let productFeature of productFeatures" color="accent" selected>{{ productFeature }}</mat-chip>
        </mat-chip-list>
        <br/>
        <mat-divider></mat-divider>
        <br />
       </div>
    </div>
  <div>
    <div class="twocolumnsgrid">
    <h3 style="margin-bottom: 0;">Merkmale</h3>
    <mat-slide-toggle class="example-margin" color="accent" (change)="attributeToggle = !attributeToggle" [checked]="attributeToggle"></mat-slide-toggle>
    </div>
    <h5 style="font-style: italic;">Von den BIMSWARM-Fachgruppen vorgegebene Produkteigenschaften</h5>
    <div *ngIf="attributeToggle">
    <table
      mat-table
      [dataSource]="selectedVersionAttributeValues"
      class="mat-elevation-z4"
      style="width: 100%"
    >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Produkttyp</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="optional">
      <th mat-header-cell *matHeaderCellDef>Optional</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.optional">
          <mat-icon color="accent"> done </mat-icon>
        </ng-container>
        <ng-container *ngIf="!element.optional">
          <mat-icon color="accent">  </mat-icon>
        </ng-container>
      </td>
    </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="merkmal">
        <th mat-header-cell *matHeaderCellDef>Feature</th>
        <td mat-cell *matCellDef="let element">{{ element.attributeName }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>Validierungswert</th>
        <td mat-cell *matCellDef="let element">{{ element.value }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsForAttributes"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsForAttributes"></tr>
    </table>
    <br/>
    <mat-divider></mat-divider>
    <br />
    </div>
    <div class="twocolumnsgrid">
    <h3 style="margin-bottom: 0;">Allgemeine Merkmale</h3>
    <mat-slide-toggle class="example-margin" color="accent" (change)="commonFeaturesToggle = !commonFeaturesToggle" [checked]="commonFeaturesToggle"></mat-slide-toggle>
    </div>
    <h5 style="font-style: italic;">Von BIMSWARM vorgegebene Produkteigenschaften</h5>
    <div *ngIf="commonFeaturesToggle">
    <div *ngFor="let item of this.commonFeatureListTemp; index as i">


    <table
      mat-table
      [dataSource]="sortByName(this.commonFeatureNameList[i])"
      class="mat-elevation-z4"
      style="width: 100%"
    >
      <!-- Position Column -->
      <div class="twoColumnsTableGrid">
      <ng-container matColumnDef="feature">
        <th style="font-weight: bold; font-size: 1.1em;"mat-header-cell *matHeaderCellDef>{{item}}</th>
        <td mat-cell style="width: 75%; padding-left: 3em" *matCellDef="let element">{{element.entry?.name }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.value">
            <mat-icon color="accent"> done </mat-icon>
          </ng-container>
          <ng-container *ngIf="!element.value">
            <mat-icon color="accent"> done </mat-icon>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsForCommonFeatures"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsForCommonFeatures"></tr>
    </div>
    </table>
    </div>
  </div>
</div>
</div>




  <!--b>Merkmale</b>
  <div style="margin: 1em">
    <div *ngFor="let versionAttribute of selectedVersionAttributeValues" class="two-colmn-table">
      <div>{{ versionAttribute.attribute.name }}</div>
      <div>
        {{
          utilService.getTransformedAttributeValue(
            versionAttribute.attribute.datatype,
            versionAttribute.value
          )
        }}
      </div>
    </div>
  </div>
</div-->


  <!--div class="item down" *ngIf="product">
    <b *ngIf="version.description && version.description?.length > 0">Beschreibung</b>
    <div class="text-block">
      <div style="margin-bottom: 1em;">
        <div *ngIf="version.description && version.description?.length > 0" class="feedback test" [ngClass]="{ hidden: !this.showComplete }"
          [innerHTML]="this.version.description"></div>
        <div>
          <button *ngIf="hasMoreThatThreeLinesOfDescription" mat-button class="showMoreLess"
            (click)="this.showComplete = !this.showComplete">
            {{ this.showComplete ? "Weniger anzeigen" : "Mehr anzeigen" }}
          </button-->
          <!-- below could be overworked to work perfectly for all components-->
          <!--app-hmtl-display *ngIf="version.description" style="display: none" [text]="this.version.description"
            [lineLimit]="3"></app-hmtl-display-->
          <!--<div class="text" [innerHTML]="this.product.description"></div>--->
        <!--/div-->
      <!--/div-->
      <!--------------------------------------------------------------->
      <!--div #PTGs *ngIf="ptgVersions && ptgVersions.length > 0">
        <b>Zugeordnete Produkttypgruppen</b>
        <div *ngFor="let ptg of ptgVersions" style="margin: 1em">
          {{ this.getPtgName(ptg) }}
          <div *ngFor="let attribute of ptg.attributeValidations" class="two-colmn-table">
            <div>{{ attribute.attribute.name }}</div>
            <div>{{ getValidationValue(attribute) }}</div>
          </div>
        </div>
      </div-->
      <!--------------------------------------------------------------->
      <!--div #attributes *ngIf="
          selectedVersionAttributeValues != null &&
          this.selectedVersionAttributeValues.length > 0
        ">
        <b>Merkmale</b>
        <div style="margin: 1em">
          <div *ngFor="let versionAttribute of selectedVersionAttributeValues" class="two-colmn-table">
            <div>{{ versionAttribute.attribute.name }}</div>
            <div>
              {{
                utilService.getTransformedAttributeValue(
                  versionAttribute.attribute.datatype,
                  versionAttribute.value
                )
              }}
            </div>
          </div>
        </div>
      </div-->
      <!--------------------------------------------------------------->
      <!--div *ngIf="commonFeatureList.length > 0">
        <b>Allgemeine Merkmale</b>
      <ul class="commonFeatureList">
        <li class="margin-bottom" *ngFor="let productFeature of commonFeatureList">
         <p *ngIf="productFeature.feature.showDetailPage" style="margin: 1em;">{{productFeature.feature.name}}: {{productFeature.entry.name}}</p>
        </li>
      </ul>
      </div>
      <div #productFeatureList *ngIf="productFeatures?.length > 0">
        <b>Produkt-Features</b>
        <div *ngFor="let productFeature of productFeatures" style="margin: 1em;">
          {{ productFeature }}
        </div>
      </div>
    </div>
  </div-->
