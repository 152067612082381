<div *ngIf="orType == 'datatype'">
  <form [formGroup]="form">
    <div *ngFor="let formField of inputs; let i = index">
      <div *ngIf="formField.controlTypes.length > 1">
        <small>Select a datatype for {{input.name}} <span *ngIf="input.required" aria-required="true" class="required"></span></small>
        <br>
        <mat-radio-group [name]="radioGroupIds[i]">
          <mat-radio-button (change)="onItemChange(option, i, j)"
                            *ngFor="let option of formField.controlTypes; let j = index;"
                            [checked]='radioButtonsSelected[i] == option' [value]="option">
            {{formField.or[j]['datatype']['value']}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngFor="let controlType of formField.controlTypes">
        <div [ngSwitch]="controlType">
          <div *ngSwitchCase="'textbox'">
            <div *ngIf="radioButtonsSelected[i] == controlType">
              <mat-form-field
                [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
                <mat-label>{{formField.name}} <span *ngIf="formField.required" aria-required="true" class="required"> 
                  </span>
                </mat-label>
                <input [formControlName]="formField.id" [matTooltip]="formField.description[this.translate.currentLang]" placeholder="{{formField.description[this.translate.currentLang]}}" matInput>
              </mat-form-field>
              <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                      mat-icon-button>
                <mat-icon>add</mat-icon>
              </button>
              <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=formField.minCount" color="warn" mat-icon-button
                      type="button">
                <mat-icon>delete icon</mat-icon>
              </button>
              <mat-error>
                <app-show-errors [ctrl]="form.get(formField.id)" [label]="formField.name"></app-show-errors>
              </mat-error>
            </div>
          </div>

          <div *ngSwitchCase="'textbox_integer'">
            <div *ngIf="radioButtonsSelected[i] == controlType">
              <mat-form-field
                [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
                <mat-label>{{formField.name}} <span *ngIf="formField.required" aria-required="true" class="required"> 
                  </span>
                </mat-label>
                <input [formControlName]="formField.id" [matTooltip]="formField.description[this.translate.currentLang]" placeholder="{{formField.description[this.translate.currentLang]}}" appNumeric matInput
                       type="number">
              </mat-form-field>
              <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                      mat-icon-button>
                <mat-icon>add</mat-icon>
              </button>
              <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=formField.minCount" color="warn" mat-icon-button
                      type="button">
                <mat-icon>delete icon</mat-icon>
              </button>
              <mat-error>
                <app-show-errors [ctrl]="form.get(formField.id)" [label]="formField.name"></app-show-errors>
              </mat-error>
            </div>

          </div>

          <div *ngSwitchCase="'textbox_decimal'">
            <div *ngIf="radioButtonsSelected[i] == controlType">
              <mat-form-field
                [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
                <mat-label>{{formField.name}} <span *ngIf="formField.required" aria-required="true" class="required"> 
                  </span>
                </mat-label>
                <input [formControlName]="formField.id" [matTooltip]="formField.description[this.translate.currentLang]" placeholder="{{formField.description[this.translate.currentLang]}}" appNumeric decimals="2"
                       matInput type="number">
              </mat-form-field>
              <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                      mat-icon-button>
                <mat-icon>add</mat-icon>
              </button>
              <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=formField.minCount" color="warn" mat-icon-button
                      type="button">
                <mat-icon>delete icon</mat-icon>
              </button>
              <mat-error>
                <app-show-errors [ctrl]="form.get(formField.id)" [label]="formField.name"></app-show-errors>
              </mat-error>
            </div>

          </div>

          <div *ngSwitchCase="'textbox_uri'">
            <div *ngIf="radioButtonsSelected[i] == controlType">
              <mat-form-field
                [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
                <mat-label>{{formField.name}} <span *ngIf="formField.required" aria-required="true" class="required"> 
                  </span>
                </mat-label>
                <input [formControlName]="formField.id" [matTooltip]="formField.description[this.translate.currentLang]" placeholder="{{formField.description[this.translate.currentLang]}}" matInput>
              </mat-form-field>
              <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                      mat-icon-button>
                <mat-icon>add</mat-icon>
              </button>
              <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=formField.minCount" color="warn" mat-icon-button
                      type="button">
                <mat-icon>delete icon</mat-icon>
              </button>
              <mat-error>
                <app-show-errors [ctrl]="form.get(formField.id)" [label]="formField.name"></app-show-errors>
              </mat-error>
            </div>
          </div>

          <div *ngSwitchCase="'datepicker'">
            <div *ngIf="radioButtonsSelected[i] == controlType">
              <mat-form-field
                [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
                <mat-label>{{formField.name}} <span *ngIf="formField.required" aria-required="true" class="required"> 
                  </span>
                </mat-label>
                <input [formControlName]="formField.id" [matDatepicker]="picker" matInput>
                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                      mat-icon-button>
                <mat-icon>add</mat-icon>
              </button>
              <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=formField.minCount" color="warn" mat-icon-button
                      type="button">
                <mat-icon>delete icon</mat-icon>
              </button>
              <mat-error>
                <app-show-errors [ctrl]="form.get(formField.id)" [label]="formField.name"></app-show-errors>
              </mat-error>
            </div>

          </div>

          <div *ngSwitchCase="'datetimepicker'">
            <div *ngIf="radioButtonsSelected[i] == controlType">
              <mat-form-field
                [ngClass]="{'full-width-input': addFullWidthClass(i), 'width-input': addNonFullWidthClass(i)}">
                <mat-label>{{formField.name}} <span *ngIf="formField.required" aria-required="true" class="required">
                  </span>
                </mat-label>
                <input [formControlName]="formField.id" [ngxMatDatetimePicker]="picker" matInput>
                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
              </mat-form-field>
              <button (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
                      mat-icon-button>
                <mat-icon>add</mat-icon>
              </button>
              <button (click)="deleteInput(i)" *ngIf="i!=0 && i>=formField.minCount" color="warn" mat-icon-button
                      type="button">
                <mat-icon>delete icon</mat-icon>
              </button>
              <mat-error>
                <app-show-errors [ctrl]="form.get(formField.id)" [label]="formField.name"></app-show-errors>
              </mat-error>
            </div>

          </div>

          <div *ngSwitchCase="'dropdown'">
            <div *ngIf="radioButtonsSelected[i] == controlType">

              <mat-form-field class="full-width-input">
                <mat-label>{{formField.name}} <span *ngIf="formField.required" aria-required="true" class="required">
                  </span>
                </mat-label>
                <mat-select [formControlName]="formField.id" multiple>
                  <mat-option *ngFor="let option of input.in" [value]="option.value">
                    {{option.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error>
                <app-show-errors [ctrl]="form.get(formField.id)" [label]="formField.name"></app-show-errors>
              </mat-error>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>

<div *ngIf="orType == 'class'">
  <div *ngIf="allShapesNotFound()" class="shapes-not-found">
    <small>Select a shape for {{input.name}}</small><br>
    <mat-radio-group [name]="radioGroupIds[0]">
      <mat-radio-button *ngFor="let option of orObjects; let j = index;" [checked]='radioButtonsSelected[0] == option'
                        [disabled]=!orObjectsFound[j]>
        <span>
          {{option}} (no shape found)
        </span>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <form [formGroup]="form">
    <div *ngFor="let formGroup of formGroups; let i = index">
      <small>Select a shape for {{inputs[i].name}} <span *ngIf="inputs[i].required" aria-required="true"
                                                         class="required"></span></small><br>
      <mat-radio-group [name]="radioGroupIds[i]">
        <mat-radio-button (change)="onItemChangeObject(option,i,j)" *ngFor="let option of orObjects; let j = index;"
                          [checked]='radioButtonsSelected[i] == option' [disabled]=!orObjectsFound[j]
                          [value]="option">
          <span *ngIf=orObjectsFound[j]>
            {{option}}
          </span>
          <span *ngIf=!orObjectsFound[j]>
            {{option}} (no shape found)
          </span>
        </mat-radio-button>
      </mat-radio-group>
      <button (click)="addInputObject()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" color="primary"
              mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>
      <button (click)="deleteInputObject(i)" *ngIf="i!=0 && i>=input.minCount" color="warn" mat-icon-button
              type="button">
        <mat-icon>delete icon</mat-icon>
      </button>
      <div *ngFor="let field of inputs[i].childrenFields">
        <app-dynamic-form-input *ngIf="field.componentType == 'dynamicFormInput'" [form]="formGroup" [input]="field"
                                [shapes]="shapes"></app-dynamic-form-input>
        <app-dynamic-form-array *ngIf="field.componentType == 'dynamicFormArray'" [form]="formGroup" [input]="field"
                                [shapes]="shapes"></app-dynamic-form-array>
        <app-dynamic-form-or *ngIf="field.componentType == 'dynamicFormOr'" [form]="formGroup" [input]="field"
                             [shapes]="shapes">
        </app-dynamic-form-or>
        <app-expanded-fields *ngIf="field.componentType == 'dynamicExpanded'" [form]="formGroup" [input]="field"
                             [shapes]="shapes">
        </app-expanded-fields>
      </div>
    </div>
  </form>
</div>
