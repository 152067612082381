import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/services/user-service/userservice";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";

@Component({
  selector: "app-registry-confirm",
  templateUrl: "./registry-confirm.component.html",
  styleUrls: ["./registry-confirm.component.css"],
})
export class RegistryConfirmComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private userservice: UserService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      const token = params.get("code");
      this.userservice.confirmRegistration(token).subscribe(
        (_) => {
          const modalResult = this.dialogService.openDialog(
            "Registrierung abgeschlossen",
            "Sie können sich nun anmelden."
          );
          modalResult.subscribe((_) => {
            this.router.navigate(["login"]);
          });
        },
        (errorObj) => {
          this.handleConfirmationError(errorObj.error);
        }
      );
    });
  }

  handleConfirmationError(error) {
    this.router.navigate(["/"]);
  }
}
