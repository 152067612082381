import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { MarketScreenState } from "src/app/model/enums/marketScreenState";
import { ReviewType } from "src/app/model/enums/reviewType";
import { ToolchainDetailInterface } from "src/app/model/marketplace/toolchain-detail-interface";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { NavbarService } from "src/app/services/componentServices/navbar-service/navbar-service";
import { ComposerService } from "src/app/services/composer-service/composer.service";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";

@Component({
  selector: "app-toolchain-detail-display",
  templateUrl: "./toolchain-detail-display.component.html",
  styleUrls: ["./toolchain-detail-display.component.css"],
})
export class ToolchainDetailDisplayComponent implements OnInit {
  public toolchain: ToolchainDetailInterface;
  public organisation: OrganisationInterface;

  constructor(
    private organisationService: OrganisationService,
    private changeDetector: ChangeDetectorRef,
    private navbarService: NavbarService,
    private activeRoute: ActivatedRoute,
    private composerService: ComposerService
  ) {}

  ngOnInit(): void {
    this.navbarService.activeScreenSubject.next(
      MarketScreenState.Toolchaindetails
    );
    this.loadToolchainFromRouteParam();
  }

  /* Loads the the Toolchain corresponding to the Id within the Route */
  private loadToolchainFromRouteParam(): void {
    this.activeRoute.params.pipe(first()).subscribe((params) => {
      const toolchainId = params.toolchainId;
      this.composerService
        .getToolchainDetail(Number(toolchainId))
        .subscribe((toolchain) => {
          this.toolchain = toolchain;
          this.setReviewType();
          if (this.toolchain.organisationId !== -1) {
            this.loadOrganisation();
          } else {
            this.organisation = this.createPrivateOrganisation();
          }
          if (!this.changeDetector["destroyed"]) {
            this.changeDetector.detectChanges();
          }
        });
    });
  }

  /* Loads the Organisation of the Toolchain */
  private loadOrganisation(): void {
    this.organisationService
      .getCompanyById(this.toolchain.organisationId)
      .then((organisation: OrganisationInterface) => {
        this.organisation = organisation;

        if (!this.changeDetector["destroyed"]) {
          this.changeDetector.detectChanges();
        }
      });
  }

  /* Create a OrganisationInterface for a private Organisation */
  private createPrivateOrganisation(): OrganisationInterface {
    return {
      organisationName: "Privat",
      address: null,
      domain: null,
      organisationType: null,
    }
  }

  private setReviewType() {
    this.toolchain.reviewType = ReviewType.TOOLCHAIN_REVIEW;
  }

  public onReviewsChanged(reviewOverview: {
    avg: number;
    count: number;
  }): void {
    this.toolchain.rating = reviewOverview.avg;
    this.toolchain.ratingCount = reviewOverview.count;
  }
}
