import { UserService } from 'src/app/services/user-service/userservice';
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { TrackingService } from "./services/tracking-service/tracking.service";
import {Component, OnInit, ChangeDetectorRef, OnChanges} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { UserInterface } from "./model/user/user-interface";
import { ChangeDetectionStrategy } from "@angular/core";
import { DetectBrowserServiceService } from "./services/detect-browser-service/detect-browser-service.service";
import { Observable, Subject } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map, shareReplay } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import Quill from 'quill';
import {COOKIE_TIMOUT, TIMESTAMP_STRING} from "../environments/constants";
import { TranslateService } from '@ngx-translate/core';
import { FilesProvider } from '@shared/files-provider';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = "SWARM-Application";
  hasStaticFiles = false;
  public isIE: boolean;
  currentUser: UserInterface;
  public styles = {};

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private detectbrowserservice: DetectBrowserServiceService,
    private trackingService: TrackingService,
    private dialogService: DialogService,
    private userService: UserService,
    public translate: TranslateService,
    filesProvider: FilesProvider
  ) {
    this.hasStaticFiles = filesProvider.gethasStaticFiles();
    const Link = Quill.import('formats/link');
    Link.sanitize = function(url) {
      const https = 'http://';
      let newUrl: string = url.replace('http://', '');
      if (!newUrl.includes(https)) {
        newUrl = https.concat(newUrl);
      }
      return newUrl;
    };
    //Google Analytics
    if (localStorage.getItem("hasTrackingConsent") === null) {
      this.dialogService
        .openCookieConsentDialog("Cookie Consent", "Cookies akzeptieren", " ")
        .subscribe((isConsentGiven) => {
          if (isConsentGiven) {
            localStorage.setItem("hasTrackingConsent", "true");
            localStorage.setItem("hasTrackingConsent" + TIMESTAMP_STRING, new Date().toString());
            if(this.currentUser!=null){
              this.userService.setUserTrackingConsent(this.currentUser.userName, true);
            };

            this.trackingService.initGoogleAnalytics();

          } else {
            localStorage.setItem("hasTrackingConsent", "false");
            localStorage.setItem("hasTrackingConsent" + TIMESTAMP_STRING, new Date().toString());
            //soll tracking verhindern. Wird evtl. nicht gebraucht, da auch an anderen stellen abgefragt wird
            window['ga-disable-'+ environment.GA_TRACKING_ID] = true;
          }
        });
    } else if (localStorage.getItem("hasTrackingConsent")==="true"){
      this.trackingService.initGoogleAnalytics();
    } else {
      window['ga-disable-'+ environment.GA_TRACKING_ID] = true;
    }


  }

  containsString(string) {
    return this.router.url.includes(string);
  }

  equalsString(string) {
    return this.router.url === string;
  }

  startsWithString(string) {
    return this.router.url.startsWith(string);
  }

  showSideMenu(): boolean {
    return !(
      this.equalsString("/") ||
      this.startsWithString("/registry") ||
      this.startsWithString("/select-file") ||
      this.startsWithString("/select-shape") ||
      this.startsWithString("/form") ||
      this.startsWithString("/upload") ||
      this.startsWithString("/listing") ||
      this.equalsString("/home") ||
      this.startsWithString("/login") ||
      this.equalsString("/marktplatz") ||
      this.startsWithString("/marktplatz") ||
      this.startsWithString("/@") ||
      this.equalsString("/resetPassword") ||
      this.equalsString("/meineProdukte") ||
      this.startsWithString("/stoerungsuebersicht") ||
      this.startsWithString("/verify") ||
      this.startsWithString("/malfunction") ||
      this.startsWithString("/composer/template") ||
      this.startsWithString("/composer/instance") ||
      this.startsWithString("/impressum") ||
      this.equalsString("/impressum") ||
      this.equalsString("/privacy") ||
      this.containsString("/privacy")||
      this.containsString("/agb")
    );
  }

  /**
   * Checks if the current browser is any version of Internet Explorer.
   * If so, an error display page is shown,nthat IE is not supported for BIMSWARM.
   */
  checkIfIE() {
    this.isIE = this.detectbrowserservice.areYouIE();
  }

  ngOnInit() {
    this.checkIfIE();
    for (const prop in localStorage) {
      if (prop.includes(TIMESTAMP_STRING)) {
        const item = localStorage.getItem(prop);
        const timestamp = Date.parse(item);
        const now = new Date();
        const diff = Math.abs(now.getTime() - timestamp);
        if (diff > COOKIE_TIMOUT) {
          localStorage.removeItem(prop);
          localStorage.removeItem(prop.slice(0, -Math.abs(TIMESTAMP_STRING.length)));
        }
      }
    }
  }
}
