import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'filterAlreadyChosenArray'})
export class FilterAlreadyChosenArray implements PipeTransform {
  transform(products: any , chosenProducts: any) {
    if (chosenProducts === []) {
      return products;
    } else {
      const productsUnchosen = [];
      if (products) {
        for (const product of products) {
          let productAlreadyChosen = false;
          for (const chosenProduct of chosenProducts) {
            if (product.id === chosenProduct.id) {
              productAlreadyChosen = true;
            }
          }
          if (!productAlreadyChosen) {
            productsUnchosen.push(product);
          }
        }
        return productsUnchosen;
      }
    }
  }
}
