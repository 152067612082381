import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "removeMarkUp",
})
export class RemoveMarkUpPipe implements PipeTransform {
  transform(value: string): string {
    let replaced = value.split(new RegExp("<[^>]*>")).join("");
    return replaced;
  }
}
