<div class="two-columns" *ngIf="certificationService.activeItem">
  <div>
    <div class="min-auto">
      <h1>Zertifizierung bearbeiten</h1>
    </div>
    <div class="auto-min">
      <mat-form-field>
        <input
          matInput
          placeholder="Hersteller"
          [value]="organisationService.activeItem.organisationName"
          disabled
        />
      </mat-form-field>
    </div>
    <form #form="ngForm">
      <mat-form-field
        class="product-name"
        hintLabel="Keine schon vorhandenen Zertifizierungnamen oder Sonderzeichen"
      >
        <input
          matInput
          placeholder="Name der Zertifizierung"
          [(ngModel)]="certificationEdit.name"
          [(value)]="certificationEdit.name"
          required
          name="certificationName"
          #certificationName="ngModel"
        />
        <mat-error
          *ngIf="
            certificationName.errors?.required && certificationName.touched
          "
        >
          Bitte geben Sie einen Zertifizierungsnamen ein.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          placeholder="Zertifizierungstyp"
          [value]="getCertificationType()"
          disabled
        />
      </mat-form-field>

      <mat-form-field class="validity" hintLabel="Gültigkeit eines Zertifikats">
        <mat-select
          placeholder="Gültigkeit"
          [value]="fetchedCertification.validity"
          (valueChange)="setCertificationValidity($event)"
          name="validity"
        >
          <mat-option [value]="1">1 Jahr</mat-option>
          <mat-option [value]="2">2 Jahre</mat-option>
          <mat-option [value]="3">3 Jahre</mat-option>
          <mat-option [value]="4">4 Jahre</mat-option>
          <mat-option [value]="5">5 Jahre</mat-option>
        </mat-select>
      </mat-form-field>
      <app-text-editor
        [(editorText)]="certificationEdit.description"
        (textValidityChange)="onDescriptionValidityChange($event)"
      ></app-text-editor>
      <br />
      <mat-form-field class="url">
        <input
          matInput
          placeholder="URL"
          [(ngModel)]="certificationEdit.url"
          [value]="fetchedCertification.url"
          name="url"
        />
      </mat-form-field>

      <div class="">
        <h2>Zertifizierungsbild</h2>

        <div class="certLogo">
          <button mat-raised-button (click)="openFileManagerPicture('Logo')">Logo ändern</button><br>
          <img [src]="imagePath(certificationEdit.fileId)">
        </div>
        <h2>Dokumente</h2>
        <ul>
          <li *ngFor="let id of certificationEdit.documentFileIds" class="document-item">{{fileInfo(id)?.filename}} <button (click)="deleteDocument(id)">X</button></li>
        </ul>
        <button mat-raised-button (click)="openFileManagerPicture('Dokumente')">hinzufügen</button>

        <h2 class="titleGal">Zertifizierungsgalerie</h2>
        <div class="certification-gallery-editor">
        <div  class="gallery-preview">
        <div *ngFor="let id of certificationEdit.galleryFileIds" class="gallery-item">
          <div class="gallery-item-control">
            <button mat-flat-button class="gal_sortLeft" (click)="moveGalleryItem(id,true)">&lt;</button>
            <button mat-flat-button class="gal_delete" color="warn" (click)="deleteFromGallery(id)">&times;</button>
            <button mat-flat-button class="gal_sortRight" (click)="moveGalleryItem(id,false)">&gt;</button>

            </div>
            <app-secure-image class="pictureSize" style="margin-top: 1vw" [src]="imagePath(id)"></app-secure-image>
          </div>

        </div>
        <div class="gallery-preview-control">
          <div class="mini-hex-button" id="gallery-add-item">
            <hexagon [icon]="'plus'" [hover]="true" [outerColor]="'green'" (click)="openFileManagerPicture('Galerie')"></hexagon>
          </div>
        </div>
      </div>
      </div>

      <div class="auto-auto">
        <button
          mat-raised-button
          class="btn_green"
          [disabled]="form.invalid || !isDescriptionValid"
          (click)="onSaveEdit()"
        >
          Speichern
        </button>
        <button mat-raised-button class="btn_red" (click)="onCancelEdit()">
          Abbrechen
        </button>
      </div>
    </form>
  </div>


</div>
