import { Component, OnInit, Input } from "@angular/core";
import { AddressInterface } from "src/app/model/address-interface";

@Component({
  selector: "app-detail-display-adress-display",
  templateUrl: "./detail-display-adress-display.component.html",
  styleUrls: ["./detail-display-adress-display.component.css"],
})
export class DetailDisplayAdressDisplayComponent implements OnInit {
  @Input()
  name: string;

  @Input()
  address: AddressInterface;

  constructor() {}

  ngOnInit() {}

  private getFirstLineOfAddress(): string {
    if (!this.address) return "";

    let addressLine = this.address.street;
    if (this.address.streetAdditional != null) {
      addressLine += " ";
      addressLine += this.address.streetAdditional;
    }
    return addressLine;
  }

  private getSecondLineOfAddress(): string {
    if (!this.address) return "";
    let addressLine = this.address.postalCode + " " + this.address.city;
    return addressLine;
  }
}
