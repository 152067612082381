import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CamundaRestService {
  constructor(private http: HttpClient) {}

  postProcessXML(filename: string, file: Blob): Promise<any> {
    const formData = new FormData();
    formData.append('data', file, filename);

    return this.http.post('https://research.bimswarm.online/camunda/rest/deployment/create', formData, {
      headers: {
        'Accept': 'application/json'
      }
    }).toPromise();
  }

  fetchAllProcessDefinition(): Promise<any> {
    return this.http.get('https://research.bimswarm.online/camunda/rest/process-definition?latestVersion=true').toPromise();
  }

  fetchProcessDefinitions(processDefinitionId: string): Promise<any> {
    const endpoint = processDefinitionId ? `https://research.bimswarm.online/camunda/rest/process-definition?processDefinitionId=${processDefinitionId}` : 'https://research.bimswarm.online/camunda/rest/process-definition?latestVersion=true';

    return this.http.get(endpoint, {
      headers: {
        'Accept': 'application/json'
      }
    }).toPromise();
  }

  fetchProcessDefinition(): Promise<any> {
    return this.http.get('https://research.bimswarm.online/camunda/rest/process-definition?latestVersion=true', {
      headers: {
        'Accept': 'application/json'
      }
    }).toPromise();
  }

  fetchFormKey(processDefinitionKey: string): Promise<any> {
    return this.http.get(`https://research.bimswarm.online/camunda/rest/process-definition/key/${processDefinitionKey}/startForm`, {
      headers: {
        'Accept': 'application/json'
      }
    }).toPromise();
  }

  fetchProcessDefinitionXML(processDefinitionId: string): Promise<any> {
    return this.http.get(`https://research.bimswarm.online/camunda/rest/process-definition/${processDefinitionId}/xml`, {
      headers: {
        'Accept': 'application/json'
      }
    }).toPromise();
  }

  postProcessInstance(processDefinitionKey: string, values: any): Promise<any> {
    return this.http.post(`https://research.bimswarm.online/camunda/rest/process-definition/key/${processDefinitionKey}/start`, values, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).toPromise();
  }
}
