<div>
  <ngx-charts-bar-horizontal
    #review
    [view]="view"
    [barPadding]="4"
    [scheme]="colorScheme"
    [results]="data"
    [gradient]="false"
    [roundEdges]="false"
    [yAxis]="true"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)"
  >
  </ngx-charts-bar-horizontal>
</div>
