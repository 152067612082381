import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  Compiler,
  SimpleChange,
  SimpleChanges, OnChanges,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {ActivatedRoute, Params, Router} from '@angular/router';

import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { FileServiceService } from 'src/app/services/file-service/file-service.service';
import { ProductInterface } from 'src/app/model/products/product-interface';
import { environment } from 'src/environments/environment';
import { PtgService } from 'src/app/services/dataServices/ptg-service/ptgService';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { OrganisationInterface } from 'src/app/model/organisations/organisation-interface';
import { API_BASE_FILESERVICE } from 'src/environments/api';
import { VariantVersionInterface } from 'src/app/model/products/variant-version-interface';
import { ProductlistService } from 'src/app/services/productlist-service/productlist.service';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { Subscription } from 'rxjs';
import { NavbarService } from 'src/app/services/componentServices/navbar-service/navbar-service';
import { ProductVariantInterface } from 'src/app/model/products/product-variant-interface';
import { ReviewType } from 'src/app/model/enums/reviewType';
import { __await } from 'tslib';
import { DisplayItemInterface } from 'src/app/model/marketplace/display-item-interface';
import { DataType } from 'src/app/model/data-type.enum';
import { DisplayItemComponent } from '../../display-item/display-item.component';
import { CertificationInterface } from 'src/app/model/certifications/certification-interface';
import { resolve } from 'path';
import { MarketplaceFilterService } from 'src/app/services/componentServices/marketplace-filter-service/marketplace-filter.service';
import {UserService} from "../../../../services/user-service/userservice";
import {ReviewService} from "../../../../services/review-service/review.service";
import {ReviewInterface} from "../../../../model/reviews/review-interface";
import { first, timeout } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { TrackingService } from 'src/app/services/tracking-service/tracking.service';
import { SshService } from 'src/app/components/EConoM/ssh.service';
/**
 * This is the Display Component that is opened once a Product Icon in
 * the marketplace is clicked.
 *
 * The Component contains serveral subcomponents:
 *
 * app-detail-display-header
 * -> displays Produkt name, image etc. at the top
 *
 * tab-group  -> tabbed information provided by PB4.0
 *
 *    app-product-detail-display-overview
 *    app-product-detail-display-feature
 *    app-product-detail-display-downloads
 *    app-product-detail-display-certificates
 *    app-product-detail-display-organisation
 *
 * app-product-detail-display-demo-bar
 *    -> the "demo anfragen" buttom column
 *
 * app-marketplace-rating-extended
 *    -> review and comment bar
 *       (only displayed for "overview tab")
 *
 * app-detail-display-gallery
 *    -> image gallery
 *
 */
@Component({
  selector: 'app-product-detail-display',
  templateUrl: './product-detail-display.component.html',
  styleUrls: ['./product-detail-display.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductDetailDisplayComponent implements OnInit, OnChanges, OnDestroy {

  public reviewType: ReviewType;
  @Output() goBack = new EventEmitter<void>();
  @Output() reloadDisplayItems = new EventEmitter<number>();

  displayedColumns: string[] = ['merkmal', 'value'];

  selectedTab = 0;

  @Input() actualProduct: ProductInterface;


  sshComponentCreated = false;

  private product: ProductInterface;
  private paramSubscription: Subscription;


  private keywords: string[] = [];
  private ptgNames: string[];

  public pictureUrl: string;

  public displaySpinner = true;



  public organisation: OrganisationInterface;

  hasDocuments = false; // Determines visiablity for
  hasCertificate = false; // Download and Cert tab
  reviews: ReviewInterface[] = [];
  average: number;
  showWriteReview: boolean = false;
  private nrOfReviews: number[] = [0, 0, 0, 0, 0];
  addedToCart = false;
  constructor(
    private productService: ProductService,
    private userService: UserService,
    private reviewService: ReviewService,
    private fileService: FileServiceService,
    private readonly ptgService: PtgService,
    private readonly organisationService: OrganisationService,
    private readonly route: ActivatedRoute,
    private marketplaceFilter: MarketplaceFilterService,
    private productListService: ProductlistService,
    private dialogService: DialogService,
    private navbarService: NavbarService,
    private cd: ChangeDetectorRef,
    private titleService: Title,
    private compiler: Compiler,
    private router: Router,
    private sshService: SshService
  ) {
    this.reviewType = ReviewType.PRODUCT_REVIEW;
  }

   ngOnInit() {
     // --------------------------------------------
     // Moved from marketPlaceProductResolver
     // Executing this here is required to display spinner as long as product details load
     // this.cd.detectChanges();

     this.getProductDetails();
     this.cd.detectChanges();
   }

    ngOnChanges(product: SimpleChanges) {
      if (product != null) {
        this.actualProduct = product.actualProduct.currentValue;
        this.getProductDetails();
        if (this.actualProduct) {
          this.loadReviews();
        }
      }
      this.cd.detectChanges();
    }

    //EConoM 
  createSSHComponentAndConnect() {
      this.sshComponentCreated = true;
      this.cd.detectChanges();
  }
    
  get fileIds() {
    return this.product.documentFileIds;
  }

  getProductDetails() {
    this.setProductPicture();
    this.hasProductDocuments();
    this.hasProductCertification();
  //  this.getAllPtgNames(); // TEST STUFF
    this.collectKeywordsFromAllActiveVersions();
    this.setReviewType();
    this.getOrganisationName();
  }

  selectTab(tabIndex: number) {
    this.selectedTab = tabIndex;
  }

  addToCart() {
    this.addedToCart = true;
    setTimeout(() => {
// reset after some time if needed
    }, 2000); // change back after 2 seconds
  }

  setProductPicture() {
    // --------------------------------------------
    // display either default picture or specific organisation picture IF existent

    if (this.actualProduct === null) {
      this.pictureUrl = environment.defaultPictureOrganisationProducts;
    } else {
      this.pictureUrl = this.productService.getProductPicture(this.actualProduct?.id);
      this.displaySpinner = false;
    }
  }

  private setReviewType() {
    if (this.actualProduct != null) {
      this.actualProduct.reviewType = ReviewType.PRODUCT_REVIEW;
    }
  }

  hasProductDocuments() {
    // --------------------------------------------
    // Enables the 'Downloads' tab if this product has documents
    if (this.actualProduct?.documentFileIds != null) {
    if (this.actualProduct.documentFileIds.length > 0) {
      this.hasDocuments = true;

    }
    }
  }

  hasProductCertification() {
    // --------------------------------------------
    // get all variants
    // --------------------------------------------
    if (this.actualProduct?.productVariants != null) {

    const versions = this.actualProduct.productVariants
      .map((p) => p.variantVersions) // push version up
      .reduce((va1, va2) => va1.concat(va2)); // concat all arrays
    // --------------------------------------------
    // Enables the 'Zertifikate' tab if at least one variant/version is certified
    this.hasCertificate = versions.some((v: VariantVersionInterface) => {
      return (
        v.certificationVariantVersions &&
        v.certificationVariantVersions.length > 0
      );
    });
  }
}

  collectKeywordsFromAllActiveVersions() {
    if (this.actualProduct?.productVariants != null) {
    const variants: VariantVersionInterface[] = this.actualProduct.productVariants
      .map((x: ProductVariantInterface) => x.variantVersions)
      .reduce((x: VariantVersionInterface[], y: VariantVersionInterface[]) => x.concat(y))
      .filter((x: VariantVersionInterface) => x.status === 'ACTIVE');
    this.keywords = Array.from(
      new Set(
        variants
          .map((x) => x.keywords)
          .reduce((x, y) => x.concat(y))
          .sort()
      )
    );
    }
  }

  getOrganisationName() {
   if (this.actualProduct?.company != null) {
    this.organisationService.getOrganisation(this.actualProduct.company).then(
      (organisation) => {
        this.organisation = organisation;
      },
      (e) => {}
    );
  }
  }

  /**
   * Translator function for mask
   */
  get productCategory() {
    switch (this.product.category) {
      case 'APP': {
        return 'Anwendung';
      }
      case 'SERVICE': {
        return 'Service';
      }
      case 'CONTENT': {
        return 'zusätzlicher Inhalt';
      }
      case 'HOCHBAU': {
        return 'KI Dienste HOCHBAU';
      }
      case 'INFRASTRUKTUR': {
        return 'KI Dienste Infrastruktur';
      }
      case 'ALLGEMEIN': {
        return 'KI Dienste Allgemein';
      }
    }
    return '';
  }

  getAllPtgNames() {
   if (this.actualProduct?.productVariants != null) {
    this.ptgNames = Array.from(
      new Set(
        this.actualProduct.productVariants
          .map((x) => {
            return x.variantVersions;
          })
          .reduce((x, y) => {
            return x.concat(y);
          })
          .map((x) => {
            return x.ptgVersions;
          })
          .reduce((x, y) => {
            return x.concat(y);
          })
          .map((ptgVersion) => {
            return this.ptgService.getPtgFromVersion(ptgVersion).name;
          })
          .sort()
      )
    );
    }
  }

  public onReviewsChanged(reviewOverview: { avg: number; count: number; }): void {
    if (this.product !== undefined) {
      this.product.rating = reviewOverview.avg;
      this.product.ratingCount = reviewOverview.count;
    }
  }

  addToProductList(): void {
    this.productListService
      .addElementToList(this.product.id, 1)
      .subscribe(() => {
        this.dialogService.openDialog(
          'Das Produkt wurde zu Ihrer Merkliste hinzugefügt',
          ''
        );
        if (!this.navbarService.NAV_STATE_MY_PRODUCTS_LIST) {
            this.navbarService.NAV_STATE_MY_PRODUCTS_LIST = true;
            this.navbarService.loadButtons();
          }
        });
  }

  routeToWebsite() {
    if (this.actualProduct.url == null || this.actualProduct.url == '') {
      return;
    }

    // check for correct scheme
    let url;
    if (this.actualProduct.url.match('http')) {
      url = this.actualProduct.url;
    } else {
      url = 'http://' + this.actualProduct.url;
    }

    let valid_url;
    try {
      valid_url = new URL(url);
    } catch (e) {
      return;
    }

    window.open(url, '_blank');
  }

  requestDemo() {
    this.dialogService.openDemoRequestDialog(this.actualProduct.id);
  }


  navigateToOrganisation(id: number) {
    const queryParams: Params = { tab: 2,
                                productId: null,
                                ptgId: null,
                                ptgAttrId: null,
                                certificationId: null,
                                orgId: id,
                                organisationId: id,
                                attr: null,
                                category:null,
                                certificationType: null,
                                rating:null,
                                flag: null };
    this.displaySpinner = true;
    this.router.navigate(
    [],
    {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });

    this.cd.detectChanges();
  }

  requestOffer() {
    this.dialogService.openOfferRequestDialog(this.actualProduct.id);
  }

  async loadReviewsAsync() {
    this.reviews = await this.reviewService.getReviews(this.reviewType, this.actualProduct?.id);
  }

  loadReviews() {
    this.loadReviewsAsync().then(() => {
      this.loadReviewStatistic();
      this.loadAverageScore();
      this.reloadDisplayItems.emit(this.actualProduct.id);
    });
  }

  loadAverageScore() {
    let stars = 0;
    let amount = this.reviews.length;
    this.reviews.forEach((review) => {
      stars += review.stars;
    });
    if(amount != 0)
    {
      this.average = stars / amount;
    } else
    {
      this.average = 0;
    }
    this.onReviewsChanged({ avg: this.average, count: this.reviews.length });
    this.cd.detectChanges();
  }

  loadReviewStatistic() {
    this.nrOfReviews = [0, 0, 0, 0, 0];
    this.reviews.forEach((review) => {
      this.nrOfReviews[review.stars - 1]++;
    });
    this.cd.detectChanges();
  }

  /**
   * Determines whether the user has already submitted a review for the product.
   *
   * @returns boolean
   */
  hasUserReviewedProduct(): boolean {

    return this.reviews.some(
      (review: ReviewInterface) => review.creatorId === this.userService.currentUser.userId
    );
  }

  goToBottom(el: HTMLElement) {
    if(this.userService.loggedIn)
    {
    this.showWriteReview = true;
    setTimeout(() => {
      el.scrollIntoView({behavior:"smooth"});
    }, 250);
  } else {
    const id = this.route.snapshot.queryParams['productId'];
    localStorage.setItem("redirectingForRating", "true");
    this.userService.saveProductId(id);
    this.router.navigateByUrl('/login');
  }
    }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }
}
