<div>
  <!---------------------------->
  <div class="item" *ngIf="description">
    <b *ngIf="description && description.length > 0">Produktbeschreibung</b>
    <div class="text-block">
      <div style="margin-bottom: 1em;">
        <div *ngIf="description && description.length > 0" class="feedback test" [ngClass]="{ hidden: !this.showComplete }"
          [innerHTML]="description"></div>
        <div>
          <button *ngIf="hasMoreThatThreeLinesOfDescription" mat-button class="showMoreLess"
            (click)="this.showComplete = !this.showComplete">
            {{ this.showComplete ? "Weniger anzeigen" : "Mehr anzeigen" }}
          </button>
          <!-- below could be overworked to work perfectly for all components-->
          <app-hmtl-display *ngIf="description" style="display: none" [text]="description"
            [lineLimit]="3"></app-hmtl-display>
          <!--<div class="text" [innerHTML]="this.product.description"></div>--->
        </div>
      </div>
  </div>
  <!---------------------------->
  <div class="keywords" *ngIf="keywords && keywords.length > 0">
    <div>
      <p>
        <span class="bold">Schlagwörter: </span> {{ getKeyWordsAsString() }}
      </p>
    </div>
  </div>
  <!---------------------------->
  <div class="item" *ngIf="productCategory">
    <b> Produktkategorie: </b> {{ productCategory }}
  </div>
  <!---------------------------->
  <div class="item" *ngIf="targetGroups && targetGroups.length > 0">
    <b> Zielgruppen </b>
    <div *ngFor="let grp of targetGroups">{{ grp }}</div>
  </div>
  <!-- Currently not used features ------------------------
  <div class="item" *ngIf="servicePhase && servicePhase.length > 0">
    <b> Leistungsphase </b>
    <div *ngFor="let phase of servicePhase">{{ phase }}</div>
  </div>
  ---------------------------
  <div class="item" *ngIf="installations && installation.length > 0">
    <b> Installation </b>
    <div *ngFor="let installation of installations">{{ installation }}</div>
  </div>
  ---------------------------
  <div class="item" *ngIf="licenseModels && licenseModels.length > 0">
    <b> Lizenzmodell </b>
    <div *ngFor="let model of licenseModels">{{ model }}</div>
  </div> -->
  <!---------------------------->
  <div class="item" *ngIf="publishDate">
    <b> Auf BIMSWARM seit: </b> {{ publishDate | date: "dd.MM.y" }}
  </div>
  <!---------------------------->
  <div class="item" *ngIf="editDate">
    <b> Letztes Update: </b> {{ editDate | date: "dd.MM.y" }}
  </div>
</div>
</div>
