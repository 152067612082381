<div *ngIf="organisationService && organisationService.activeItem && product" class="two-columns">
  <div>
    <h1>Produkt anlegen</h1>
    <div class="mini-grid-product-type">
      <mat-form-field>
        <input matInput placeholder="Hersteller" [value]="organisationService.activeItem.organisationName" disabled />
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="Produktkategorie" [(ngModel)]="product.category">
          <mat-option [value]="'APP'">Applikation</mat-option>
          <mat-option [value]="'SERVICE'">Service</mat-option>
          <mat-option [value]="'CONTENT'">Content</mat-option>
          <mat-option [value]="'ALLGEMEIN'">Allgemein</mat-option>
          <mat-option [value]="'HOCHBAU'">Hochbau</mat-option>
          <mat-option [value]="'HOCHBAU'">Tiefbau</mat-option>
          <mat-option [value]="'WASSERBAU'">Wasserbau</mat-option>
          <mat-option [value]="'STRASENBAU'">Straßenbau</mat-option>
          <mat-option [value]="'EISENBAHNBAU'">Eisenbahnbau</mat-option>
          <mat-option [value]="'INFRASTRUKTUR'">Infrastruktur</mat-option>
          <mat-option [value]="'BAUSTELLE'">Baustelle</mat-option>
          <mat-option [value]="'EdgeServer'">EDGESERVER</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <form #form="ngForm" autocomplete="off">
      <mat-form-field class="fullWidth product-name" hintLabel="Keine schon vorhandenen Produktnamen oder Sonderzeichen">
        <input matInput [(ngModel)]="product.name" [placeholder]="product.category === 'BAUSTELLE' ? 'Baustellenname' : 'Produktname'" name="productName" required #productName="ngModel" />
        <mat-error *ngIf="productName.errors?.required && productName.touched">
          Bitte geben Sie einen {{ product.category === 'BAUSTELLE' ? 'Baustellennamen' : 'Produktnamen' }} ein.
        </mat-error>
      </mat-form-field>
      
      <!-- New fields for Baustelle -->
      <div *ngIf="product.category === 'BAUSTELLE'">
        <mat-form-field class="fullWidth">
          <input matInput [(ngModel)]="product.baustelleAdresse" placeholder="Baustelle Adresse" name="baustelleAdresse" required />
        </mat-form-field>
        <mat-form-field class="fullWidth">
          <input matInput type="number" [(ngModel)]="product.anzahlDerNutzung" placeholder="Anzahl der Nutzung" name="anzahlDerNutzung" required />
        </mat-form-field>
        <mat-form-field class="fullWidth">
          <input matInput type="date" [(ngModel)]="product.startDate" placeholder="Startdatum" name="startDate" required />
        </mat-form-field>
        <mat-form-field class="fullWidth">
          <input matInput type="date" [(ngModel)]="product.endDate" placeholder="Enddatum" name="endDate" required />
        </mat-form-field>
        
        <!-- Additional fields for Baustelle -->
        <mat-form-field class="fullWidth">
          <input matInput [(ngModel)]="product.baustelleIPAddress" placeholder="Baustelle IP Adresse" name="baustelleIPAddress" required />
        </mat-form-field>
        <mat-form-field class="fullWidth">
          <input matInput [(ngModel)]="product.edgeKey" placeholder="Edge Key (Secret Token)" name="edgeKey" required />
        </mat-form-field>
        <mat-form-field class="fullWidth">
          <input matInput [(ngModel)]="product.edgeIdentifier" placeholder="Edge Identifier" name="edgeIdentifier" required />
        </mat-form-field>
      </div>
      
      <!-- Remaining original fields -->
      <mat-form-field class="fullWidth url">
        <input matInput [(ngModel)]="product.url" placeholder="URL" name="url" />
      </mat-form-field>
      
      <!-- Original form content (part 2) -->
      <mat-form-field class="fullWidth url" *ngIf="product.category !== 'BAUSTELLE'">
        <input matInput [(ngModel)]="product.slogan" placeholder="Slogan" name="slogan" />
      </mat-form-field>

      <addEntriesToList-panel
        [(keywords)]="featureArray"
        [editable]="true"
        [keywordPanel]="false"
        *ngIf="product.category !== 'BAUSTELLE'">
      </addEntriesToList-panel>

      <div *ngIf="product.category !== 'BAUSTELLE'">
        <app-add-product-dependency-panel
          [product]="product"
          [isCreate]="true"
        ></app-add-product-dependency-panel>

        <app-selected-product-panel
          [hidden]="product.relatedProducts.length === 0"
          [product]="product"
        >
        </app-selected-product-panel>
      </div>

      <div class="commonFeatureSelection">
        <p><b>Allgemeine Merkmale</b></p>
        <button class="btn_green" mat-raised-button (click)="showCommonFeatureSelectionDialog()">Auswählen</button>
      </div>

      <div>
        <p>
          <em>
            Zum Anlegen eines Produkts ist das Anlegen einer Variante sowie
            einer dazugehörigen Version notwendig
          </em>
        </p>
        <div>
          <mat-form-field class="fullWidth">
            <input
              matInput
              placeholder="Variantenname"
              name="variantName"
              #variantName="ngModel"
              [(ngModel)]="product.productVariants[0].name"
              required
            />
            <mat-error
              *ngIf="variantName.errors?.required && variantName.touched"
            >
              Bitte geben Sie einen Variantennamen ein.
            </mat-error>
          </mat-form-field>
        </div>

        <app-version-edit
          [version]="product.productVariants[0].variantVersions[0]"
          [dataType]="'PRODUCT'"
          (validityChanged)="onValidityChanged($event)"
          *ngIf="product.category !== 'BAUSTELLE'"></app-version-edit>
      </div>
      <app-dialog-add-product-to-baustelle *ngIf="product.category == 'BAUSTELLE'"></app-dialog-add-product-to-baustelle>
      <div class="mini-grid-buttons">
        <button
          id="savebtn"
          mat-raised-button
          class="btn_green"
          [disabled]="
            !isFormValid ||
            !isInputValid() ||
            form.invalid ||
            !isDescriptionValid
          "
          (click)="createProduct()"
          *ngIf="product.category !== 'BAUSTELLE'">
          Speichern
        </button>

        <button
        mat-raised-button
        class="btn_green"
        (click)="createBaustelle()"
        *ngIf="product.category == 'BAUSTELLE'">
        Speichern
      </button>

        <button
          mat-raised-button
          class="btn_red"
          (click)="cancelCreateProduct()"
        >
          Abbrechen
        </button>
      </div>
    </form>
  </div>

  <div>
    <div class="image-upload">
      <div class="img-wrapper">
        <img
          class="img"
          *ngIf="product.fileId"
          [src]="imagePath(product.fileId)"
        />
        <img
          class="img"
          *ngIf="!product.fileId"
          src="../../../../../assets/img/hexagon.png"
        />
      </div>
    </div>
    <button
      class="image-upload-button"
      mat-raised-button
      (click)="openFileManager('logo')"
    >
      Logo ändern
    </button>
    <h2>Dokumente</h2>
    <ul>
      <li
        *ngFor="let id of product.documentFileIds"
        class="document-item"
      >
        {{ fileInfo(id)?.filename }}
        <button (click)="deleteDocument(id)">X</button>
      </li>
    </ul>
    <button mat-raised-button (click)="openFileManagerPicture('Dokumente')">
      hinzufügen
    </button>

    <h2>Produktgalerie</h2>
    <div class="certification-gallery-editor">
      <div class="gallery-preview">
        <div
          *ngFor="let id of product.galleryFileIds"
          class="gallery-item"
        >
          <div class="gallery-item-control">
            <button
              mat-flat-button
              class="gal_sortLeft"
              (click)="moveGalleryItem(id, true)"
            >
              &lt;
            </button>
            <button
              mat-flat-button
              class="gal_delete"
              color="warn"
              (click)="deleteFromGallery(id)"
            >
              &times;
            </button>
            <button
              mat-flat-button
              class="gal_sortRight"
              (click)="moveGalleryItem(id, false)"
            >
              &gt;
            </button>
          </div>
          <app-secure-image [src]="imagePath(id)"></app-secure-image>
        </div>
      </div>
      <div class="gallery-preview-control">
        <div class="mini-hex-button" id="gallery-add-item">
          <hexagon
            [icon]="'plus'"
            [hover]="true"
            [outerColor]="'green'"
            (click)="openFileManagerPicture('Galerie')"
          ></hexagon>
        </div>
      </div>
    </div>
</div>
