import { delegate } from "min-dom/dist";
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { reloadPropertiesPanel } from "../../model-editor/custom/composerChangeHandler";
import { ModelViewerComponent } from "../model-viewer.component";

const DEBOUNCE_DELAY = 300;

/*
 * Builds the ComposerChangeHandlerForViewer Function
 * Gets an Instance of ModelViewerComponent as Parameter so that the HandlerFunction
 * can use the BPMNJS Variable, that gets initialised after this, on Runtime
 */
export function buildComposerChangeHandlerForViewer(
  modelViewer: ModelViewerComponent
) {
  /* Custom Function that adds Listeners for handling Change Events */
  return function ComposerChangeHandler(container: any) {
    /* Handler for uploadData Events */
    const handleUploadData = function handleUploadData(event) {
      modelViewer.openUploadFileDialog();

      reloadPropertiesPanel(modelViewer.getBPMNJSInstance());
    };

    /* Handler for downloadData Events */
    const handleDownloadData = function handleDownloadData(event) {
      modelViewer.downloadFile();

      reloadPropertiesPanel(modelViewer.getBPMNJSInstance());
    };

    delegate.bind(container, "button", "uploadData", handleUploadData);
    delegate.bind(container, "button", "downloadData", handleDownloadData);
  };
}
