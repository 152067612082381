<mat-slide-toggle
  [checked]="contactManagement"
  (toggleChange)="toggleContactManagement()"
  class="margin-button"
  >Kontakte verwalten</mat-slide-toggle
>
<div *ngIf="contactManagement">
  <h1>Kontakte</h1>
  <div class="table">
    <div class="row">
      <div>
        <p><b>Name</b></p>
      </div>
      <div>
        <p><b>E-Mail</b></p>
      </div>
    </div>
    <div *ngFor="let contact of contacts">
      <div
        class="row"
      >
        <div>
          <p [ngClass]="contact.edit && 'bold'">{{ contact.contactName }}</p>
        </div>
        <div>
          <p [ngClass]="contact.edit && 'bold'">{{ contact.email }}</p>
        </div>
        <div
          style="display: flex"
        >
          <div
            style="margin-right: 0.5rem"
            class="mini-hex-button"
            [matTooltip]="'Kontakt bearbeiten'"
            [matTooltipPosition]="'below'"
            matTooltipClass="tooltip"
          >
            <hexagon
              [icon]="'edit'"
              [hover]="true"
              [outerColor]="'green'"
              (click)="toggleContactEdit(contact)">
            </hexagon>
          </div>
          <div
            class="mini-hex-button"
            [matTooltip]="'Kontakt löschen'"
            [matTooltipPosition]="'below'"
            matTooltipClass="tooltip"
          >
            <hexagon
              [icon]="'minus'"
              [hover]="true"
              [outerColor]="'green'"
              (click)="deleteOrganisationContact(contact)"
            >
            </hexagon>
            <!------------------------------------------>
          </div>
        </div>
      </div>
      <app-contact-form *ngIf="contact.edit"
        [contactInput] ="contact"
        (done)="editOrganisationContact($event)"
      >
      </app-contact-form>
    </div>
  </div>
  <div>
    <h1>Kontakt erstellen</h1>
    <mat-slide-toggle
      style="margin-top: -1rem"
      [checked]="contactCreation"
      (toggleChange)="toggleContactCreation()"
      class="margin-button"
    >Kontakt erstellen</mat-slide-toggle
    >
    <app-contact-form *ngIf="contactCreation"
      (done)="createOrganisationContact($event)"
    ></app-contact-form>
  </div>
</div>
