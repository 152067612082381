import { Component, OnInit } from '@angular/core';
import { HeaderServiceService } from 'src/app/services/header-service/header-service.service';


@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  constructor(  private headerService: HeaderServiceService) {
  }

  ngOnInit(): void {
    this.headerService.loadHeaderText('Impressum');
  }

}
