import { matRaisedButtonCSS } from "../../../model-editor/custom/factory/composerSaveButton";

/* Custom Modul for the Properties Panel */
export function ComposerUploadDataButtonForViewer(options: { id: string }) {
  /* Custom Event that gets triggered when the Upload Button gets clicked - Handled by ComposerChangeHandlerForViewer */
  const customEvent =
    "{const customEvent = document.createEvent('HTMLEvents');" +
    "customEvent.initEvent('uploadData', true, true);" +
    "customEvent.eventName = 'uploadData';" +
    "event.target.dispatchEvent(customEvent);}";

  return {
    id: options.id,
    html:
      '<button class="btn_green" style="' +
      matRaisedButtonCSS +
      ' width: 80%;" onclick="' +
      customEvent +
      '">Datei hochladen</button>',
  };
}
