<div class="mini-grid-product-dependencies" *ngIf="!checkIfNoProducts()">
  <div class="mikro-grid-product-dependencies">
    <p>Ist das Produkt abhängig von einem anderen Produkt?</p>
    <toggle-switch
      [trueLabel]="'Ja'"
      [falseLabel]="'Nein'"
      [allowUndefined]="false"
      (valueChange)="addProductDependencyModeToggle($event)"
    >
    </toggle-switch>
  </div>
  <div
    [ngClass]="{
      hide: !addProductDependencyMode,
      'dependency-products-panel': addProductDependencyMode
    }"
  >
    <triangle-indent></triangle-indent>
    <div>
      <mat-form-field *ngIf="!isCreate">
        <mat-label>Übergeordnetes Produkt</mat-label>
        <mat-select
          (selectionChange)="addProductDependency($event, selectBox)"
          #selectBox
        >
          <mat-option
            *ngFor="
              let productServiceProd of productService.collection
                | filterBelongsToOrganisation
                  : this.organisationService.activeItem.organisationId
                | filterAlreadyChosen: product.relatedProducts
                | filterItself: this.productService.activeItem.id
            "
            value="{{ productServiceProd.id }}"
            >{{ productServiceProd.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="isCreate">
        <mat-label>Übergeordnetes Produkt</mat-label>
        <mat-select
          (selectionChange)="addProductDependency($event, selectBox)"
          #selectBox
        >
          <mat-option
            *ngFor="
              let productServiceProd of productService.collection
                | filterBelongsToOrganisation
                  : this.organisationService.activeItem.organisationId
                | filterAlreadyChosen: product.relatedProducts
            "
            value="{{ productServiceProd.id }}"
            >{{ productServiceProd.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
