import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { DataFormat } from "src/app/model/compatiblity/data-format-interface";
import { DataFormatDTO } from "src/app/model/compatiblity/dto/data-format-dto";
import { API_MAP } from "src/environments/api";

const SWARM_API = "/proxy/api/v0";

const JSON_HEADERS = { "Content-Type": "application/json" };

@Injectable({
  providedIn: "root",
})
export class DataFormatService {
  url: string;
  constructor(protected http: HttpClient) {
    this.url = SWARM_API + API_MAP["compatiblity"]["DATA_FORMAT"];
  }

  /**
   * Returns an observable of the request to get a data format
   * @param dataFormatId the id of the dataformat
   */
  public getDataFormat(dataFormatId: number): Observable<DataFormat> {
    return this.http.get<DataFormat>(this.url + "/" + dataFormatId);
  }

  /**
   * Transfers the dto to the server and returns an observable of the request
   * @param dataFormatDto the dto describing the new @type {DataFormat}
   */
  public createDataFormat(
    dataFormatDto: DataFormatDTO
  ): Observable<DataFormat> {
    return this.http.post<DataFormat>(this.url, JSON.stringify(dataFormatDto), {
      headers: JSON_HEADERS,
    });
  }

  /**
   * Transfers the DTO with the updated values and returns the request
   * @param dataFormatId the id of the updated dataformat
   * @param dataFormatDto the DTO describing the updated dataformat
   */
  public updateDataFormat(
    dataFormatId: number,
    dataFormatDto: DataFormatDTO
  ): Observable<DataFormat> {
    return this.http.put<DataFormat>(
      this.url + "/" + dataFormatId,
      JSON.stringify(dataFormatDto),
      {
        headers: JSON_HEADERS,
      }
    );
  }

  /**
   * Returns an observable of the request to get all data formats
   */
  public getAllDataFormats(): Observable<DataFormat[]> {
    return this.http.get<DataFormat[]>(this.url);
  }
}
