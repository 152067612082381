import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from "@angular/core";
import { ReviewInterface } from "src/app/model/reviews/review-interface";
import { ReviewService } from "src/app/services/review-service/review.service";

@Component({
  selector: "app-marketplace-rating-extended",
  templateUrl: "./marketplace-rating-extended.component.html",
  styleUrls: [
    "./marketplace-rating-extended.component.css",
    "../../product-detail-display/product-detail-display.css",
  ],
})
export class MarketplaceRatingExtendedComponent {
  @Input()
  targetId: number;
  @Input()
  reviewType: number;
  @Input()
  reviews: ReviewInterface[] = [];
  @Input()
  average: number;
  @Input()
  nrOfReviews: number[];
  @Input()
  showWriteReview: boolean;
  @Output()
  averageEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  statisticsEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  reviewEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  reviewCloseEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  reviewOpenEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  public onReviewsChanged(updatedReviews: ReviewInterface[]) {
    this.reviews = updatedReviews;
    this.averageEmitter.emit();
    this.statisticsEmitter.emit();
    if (this.targetId !== undefined) {
      this.reviewEmitter.emit();
    }
  }
}
