import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MAX_TEXT_LENGTH } from "src/environments/constants";

@Component({
  selector: "app-text-editor",
  templateUrl: "./text-editor.component.html",
  styleUrls: ["./text-editor.component.css"],
})
export class TextEditorComponent implements OnInit {
  //-------------------------------------------------------------------------
  public readonly textEditorModules = {
    toolbar: [
      ["bold", "italic", "underline", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };
  //-------------------------------------------------------------------------
  public readonly textEditorStyle: {
    height: "auto";
    "font-family": "'Roboto', sans-serif";
  };
  //-------------------------------------------------------------------------
  public placeholder = "Hier Beschreibung einfügen";
  private editorTextInternal: string = "";
  public textLengthBelowMax: boolean = true;
  private max_text_length = MAX_TEXT_LENGTH;
  //-------------------------------------------------------------------------

  @Input()
  get editorText() {
    return this.editorTextInternal;
  }

  @Input()
  private placeholderText: string;

  //-------------------------------------------------------------------------

  set editorText(val) {
    if (val == null) {
      val = "";
    }
    this.editorTextInternal = val;
    this.editorTextChange.emit(this.editorTextInternal);

    const textLength = this.editorTextInternal.length;
    const textLengthBelowMax = textLength <= MAX_TEXT_LENGTH;
    if (this.textLengthBelowMax != textLengthBelowMax) {
      this.textLengthBelowMax = textLengthBelowMax;
      this.textValidityChange.emit(this.textLengthBelowMax);
    }
  }
  //-------------------------------------------------------------------------

  @Output()
  editorTextChange = new EventEmitter<string>();
  @Output()
  textValidityChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {
    const editorEle = document.getElementById('editor');


    editorEle.addEventListener('paste', function (e) {

      e.preventDefault();

      const text = e.clipboardData
        ? e.clipboardData.getData('text/plain')
        : '';

      if (document.queryCommandSupported('insertText')) {
        document.execCommand('insertText', false, text);
      } else {

        const range = document.getSelection().getRangeAt(0);
        range.deleteContents();

        const textNode = document.createTextNode(text);
        range.insertNode(textNode);
        range.selectNodeContents(textNode);
        range.collapse(false);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      }
    });
    if (this.editorText == null) {
      this.editorText = "";
    }
    if (this.placeholderText) {
      this.placeholder = this.placeholderText;
    }
  }
}
