<div class="two-columns">
  <div *ngIf="ptgId">
    <h1>Produkttyp bearbeiten</h1>
    <form #form="ngForm">
      <mat-form-field>
        <input
          matInput
          placeholder="Organisation"
          [value]="organisastionName"
          disabled
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Name des Produkttypen"
          [(ngModel)]="ptg.name"
          name="ptgName"
          required
          #ptgName="ngModel"
        />
        <mat-error *ngIf="ptgName.errors?.required && ptgName.touched">
          Bitte geben Sie einen Namen ein.
        </mat-error>
      </mat-form-field>
      <app-text-editor
        [(editorText)]="ptg.description"
        (textValidityChange)="onDescriptionValidityChange($event)"
      ></app-text-editor>
      <div class="buttons">
        <button
          mat-raised-button
          class="btn_green"
          (click)="savePtg()"
          [disabled]="form.invalid || !isDescriptionValid"
        >
          Speichern
        </button>
        <button mat-raised-button class="btn_red" (click)="savePtgCancel()">
          Abbrechen
        </button>
      </div>
    </form>
  </div>
</div>
