/* Custom Modul for the Properties Panel */
export function ComposerSaveButton(options: { id: string; disabled: boolean }) {

  /* Custom Event that gets triggered when the Save Button gets clicked - Handled by ComposerChangeHandler */
  const customEvent =
    "{const customEvent = document.createEvent('HTMLEvents');" +
    "customEvent.initEvent('saveInformation', true, true);" +
    "customEvent.eventName = 'saveInformation';" +
    "event.target.dispatchEvent(customEvent);}";

  const disabledAttribute = options.disabled ? "disabled" : "";

  const disabledButtonCSS = options.disabled ? "background-color: grey; color: white" : "";

  return {
    id: options.id,
    html:
      '<button class="btn_green" style="' + matRaisedButtonCSS + disabledButtonCSS + '" onclick="' +
      customEvent +
      '" ' +
      disabledAttribute +
      ">Speichern</button>",
  };
}

export const matRaisedButtonCSS =
    "box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12); box-sizing: border-box; position: relative; user-select: none; cursor: pointer; outline: 0; border: none; display: inline-block; white-space: nowrap; text-decoration: none; vertical-align: baseline; text-align: center; margin: 0; overflow: visible; transition: background .4s cubic-bezier(.25,.8,.25,1),box-shadow 280ms cubic-bezier(.4,0,.2,1); border-radius: 0.25em; padding: 0 1em; line-height: 2.25em; min-width: 4em; font-size: 1em;";

