import { ObjectLinkInterface } from './../../../model/object-link-interface';
import { CertificationService } from 'src/app/services/dataServices/certification-service/certificationService';
import { UserInterface } from 'src/app/model/user/user-interface';
import { UserService } from 'src/app/services/user-service/userservice';
import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FileServiceService } from "src/app/services/file-service/file-service.service";

import { ListOfFiles, FILETYPES } from "./elements/file.model";
import {OrganisationService} from "../../../services/dataServices/organisation-service/organisationservice";

@Component({
  selector: "filemanager",
  templateUrl: "./file-manager.component.html",
  styleUrls: ["./file-manager.component.css"],
})
export class FilemanagerComponent implements OnInit {

  @Output() selectedFileChanged = new EventEmitter();
  @Input() multiSelectMode: boolean;
  @Input() uploadDisabled: boolean;
  @Input() uploadModeSingle: boolean;
  @Input() fileEditEnabled: boolean;
  @Input() filter: string;
  @Input() fileId: string;

  public selectedItem: number[];
  public selectedSingleItem: string | boolean;
  public fileList = [];
  public UploadMode = true;
  public filePath = "/proxy/api/v0/fileservice/file/";
  public assetsPath = "/assets/mime-icons/";
  public lastItemId: boolean | string = false; //enthält ID oder false
  public fileById: ListOfFiles | {};
  public filterListe = [];
  public filtering = false;
  public userDetails: UserInterface;
  public difference: string[];

  fileById$ = this.fileService.data$;

  constructor(
    private _sanitizer: DomSanitizer,
    private fileService: FileServiceService,
    private productService: ProductService,
    public userservice: UserService,
    public organisationService: OrganisationService,
    public certificationService: CertificationService,
    public cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.selectedItem = [];
    this.selectedSingleItem = undefined;
    this.UploadMode = true;
    this.fileService.getMyFiles();
    this.userDetails = this.userservice.currentUser;
    this.fileById$.subscribe((data) => {
      this.difference = [];
      this.calculateDifference(data);
      this.fileById = data;
      this.selectInitialOrUploadedPicture();
    });
    if (this.filter) {
      const f = this.filter.split(" ");
      f.forEach((e) => {
        this.filterListe.push(e);
        this.filtering = true;
      });
    }
  }

  /**
   * Selects the initialPicture or the just uploaded picture
   */
  private selectInitialOrUploadedPicture(): void {
    if (this.fileId != null && this.selectedSingleItem !== this.fileId) {
      this.selectFile(this.fileId);
    }
    if (this.difference.length === 1) {
      this.selectFile(this.difference[0]);
    }
  }

  /**
   * Calculates which new pictures have been uploaded
   * @param data All pictures that are available in the gallery at the moment
   */
  private calculateDifference(data: {}): void {
    for (const newfile in data) {
      let isNewFile = true;
      for (const oldFile in this.fileById) {
        if (newfile === oldFile) {
          isNewFile = false;
        }
      }
      if (isNewFile) {
        this.difference.push(newfile);
      }
    }
  }

  fileViewClass(f): string {
    return (
      this.isFileSelected(f.id) + " " + this.isFiletypeVisible(f.contentType)
    );
  }

  isFiletypeVisible(mime): string {
    if (!this.filtering) {
      return "file-show";
    }
    if (this.filterListe.indexOf(mime) >= 0) {

      return "file-show";
    }
    return "file-hidden";
  }

  isFileSelected(fid): string {
    if (this.selectedSingleItem === fid) {
      return "file-selected file-selected-single";
    }
    if (this.selectedItem.indexOf(fid) !== -1) {
      if (this.lastItemId === fid && this.multiSelectMode) {
        return "file-selected file-selected-focus";
      }
      return "file-selected file-selected-multi";
    }
    return "file-unselected";
  }

  selectFile(id): void {
    this.lastItemId = id;
    if (this.multiSelectMode) {
      const idx = this.selectedItem.indexOf(id);
      if (idx === -1) {
        this.selectedItem.push(id);
      } else {
        this.selectedItem.splice(idx, 1);
      }
      this.selectedFileChanged.emit(this.selectedItem);
    } else {
      if (!this.selectedSingleItem || this.selectedSingleItem !== id) {
        this.selectedSingleItem = id;
        this.selectedFileChanged.emit([id]);
      } else {
        this.selectedSingleItem = undefined;
        this.selectedFileChanged.emit([]);
      }
    }
  }

  onFileSelected(event): void {
    if (this.uploadDisabled === true) {
      console.error("No file-upload allowed here");
      return;
    }
    let files = { length: 0 };

    if (event.srcElement) {
      files = event.srcElement.files;
    } else {
      files = event;
    }
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      this.fileService.doUpload(element);
    }
    if (files.length === 1) {
      alert("Es wird "+files.length+" Datei hochgeladen.");
    } else {
      alert("Es werden "+files.length+" Dateien hochgeladen.");
    }
  }

  confirmDelete(fid): void {
    if (this.productService.toBeDeletedIds) {
      this.productService.toBeDeletedIds.push(fid);
    } else {
      this.productService.toBeDeletedIds = [];
      this.productService.toBeDeletedIds.push(fid);
    }
    const choice = confirm(
      "Wollen sie folgende Datei wirklich unwiderruflich für alle Organisationen, Produkte und Zertifizierungen löschen? \n" + this.fileById[fid].filename
    );
    if (choice) {
      if (this.isFileSelected(fid) === "file-unselected"){
        this.selectFile(fid);
      }
      this.fileService.deleteFile(fid).subscribe(
        (objectLinkList: ObjectLinkInterface[]) => {
          this.handleFileDeletion(objectLinkList, fid);
          this.fileService.updateMyFiles();
        }
      );
      this.lastItemId = false;
    }
  }

  /**
   *
   * @param objectLinkList the list of object links that connected a now deleted file
   * to properties in user/products/certifications
   * @param fid the fileId of the now deleted file
   */
  handleFileDeletion(objectLinkList: ObjectLinkInterface[], fid: string): void {
    for (let objectLink of objectLinkList) {
      if (objectLink.type == "profile-picture") {
        this.userservice.deleteFileReference(objectLink.objectId, "profile-picture");
      }
      if (objectLink.type == "organisation-profile-picture") {
        this.userservice.deleteFileReference(objectLink.objectId, "organisation-profile-picture");
      }
      if (objectLink.type == "organisation-preview-picture") {
        this.userservice.deleteFileReference(objectLink.objectId, "organisation-preview-picture");
      }
      if (objectLink.type == "product-picture") {
        this.productService.deleteFileReference(objectLink.objectId, "product-picture");
      }
      if (objectLink.type == "gallery-picture") {
        this.productService.deleteFileReference(objectLink.objectId, "gallery-picture", fid);
      }
      if (objectLink.type == "product-doc") {
        this.productService.deleteFileReference(objectLink.objectId, "product-doc", fid);
      }
      if (objectLink.type == "certification-picture") {
        this.certificationService.deleteFileReference(objectLink.objectId, "certification-picture");
      }
      if (objectLink.type == "cert-gallery-picture") {
        this.certificationService.deleteFileReference(objectLink.objectId, "cert-gallery-picture", fid);
      }
      if (objectLink.type == "cert-product-doc") {
        this.certificationService.deleteFileReference(objectLink.objectId, "cert-product-doc", fid);
      }
    }
  }

  previewImage(fid): string {
    const file = this.fileById[fid];

    if (file.contentType.startsWith("image")) {
      return this.filePath + file.id;
    } else {
      const ft = FILETYPES[file.contentType] || { icon: "file-nn.svg" };
      return this.assetsPath + ft.icon;
    }
  }

  filetypeName(fid): string {
    const file = this.fileById[fid];
    const ft = FILETYPES[file.contentType] || { text: "???" };
    return ft.text;
  }

  updateFileMeta(object): void {
    const data = { description: "", filename: "" };
    data.description = object.description;
    data.filename = object.filename;
    this.fileService.updateFileMeta(object.id, data);
  }

  /**
   * Checks if there is at least one picture selected
   */
  isAnyFileSelected(): boolean {
    let isAnyFileSelected = false;
    for (let file in this.fileById) {
      if (file === this.selectedSingleItem) {
        isAnyFileSelected = true;
      }
    }
    return isAnyFileSelected;
  }
}
