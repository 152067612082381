import { productImageDataUrls } from "../util/preloadedAssets";
import {
  getCurrentlyActiveToolchain,
  parsePTGCode,
} from "./composerChangeBuffer";
import { applyCorrectState } from "./customPalette";

/* Registration */
export function CustomContextPad(
  config,
  contextPad,
  modeling,
  create,
  elementFactory,
  connect,
  injector,
  translate,
  dialogService,
  elementRegistry
) {
  this.create = create;
  this.elementFactory = elementFactory;
  this.translate = translate;
  this.modeling = modeling;
  this.connect = connect;

  if (config.autoPlace !== false) {
    this.autoPlace = injector.get("autoPlace", false);
  }

  this.dialogService = dialogService;
  this.elementRegistry = elementRegistry;

  contextPad.registerProvider(this);
}

/* Allows dynamic Parameter Injection */
CustomContextPad["$inject"] = [
  "config",
  "contextPad",
  "modeling",
  "create",
  "elementFactory",
  "connect",
  "injector",
  "translate",
  "dialogService",
  "elementRegistry",
];

/* Definies which Elements are shown in the Context */
CustomContextPad.prototype.getContextPadEntries = function (element: any) {
  const create = this.create;
  const autoPlace = this.autoPlace;
  const elementFactory = this.elementFactory;
  const translate = this.translate;
  const modeling = this.modeling;
  const connect = this.connect;
  const dialogService = this.dialogService;
  const elementRegistry = this.elementRegistry;

  const elements = {};

  /* Connections should only have a Delete Option */
  if (element.type === "bpmn:SequenceFlow") {
    return {
      delete: {
        group: "edit",
        className: "bpmn-icon-trash",
        title: "Entfernen",
        action: {
          click: removeElement,
        },
      },
    };
  }

  /* Starts a Connection from an Elements */
  function startConnect(event, element) {
    connect.start(event, element);
  }

  /* Removes the selected Element */
  function removeElement(e) {
    modeling.removeElements([element]);
  }

  /* Opens the Dialog to edit the Property Values of the current Element */
  function openEditMetaDataDialog() {
    const currentElement = getCurrentlyActiveToolchain(elementRegistry);
    const businessObject = currentElement.businessObject;

    const fileIdElement = productImageDataUrls.find(
      (element) => element.dataUrl === businessObject.imageDataUrl
    );

    dialogService.openEditToolchainMetaData(
      modeling,
      businessObject.toolchainItemId,
      businessObject.itemName,
      businessObject.description,
      parsePTGCode(businessObject.selectedPTGs).map(id => parseInt(id)),
      businessObject.productName,
      businessObject.productId,
      fileIdElement ? fileIdElement.fileId : "",
      currentElement
    );
  }

  /* Builds a new Element with a given Type, Title and a Class for an Icon */
  function appendAction(
    type: string,
    className: string,
    title: string,
    options: any
  ) {
    /* Function to be used when a new Element without Connections should be created  */
    function appendStart(event, element) {
      const shape = elementFactory.createShape(
        Object.assign({ type: type }, options)
      );
      create.start(event, shape, {
        source: element,
      });
    }

    /* Function to be used when a new Element should be appended to the current ELement */
    function appendElement(event, element) {
      const shape = elementFactory.createShape(
        Object.assign({ type: type }, options)
      );
      autoPlace.append(element, shape);
    }

    /* If autoPlace is disabled, the Element is always placed as a new Element without Connections */
    const append = autoPlace ? appendElement : appendStart;

    return {
      group: "model",
      className: className,
      title: title,
      action: {
        dragstart: appendStart,
        click: append,
      },
    };
  }

  /* Function to create an Toolchain Task Element that creates a Shape */
  function createToolchainTask(dataUrl: string, title: string, productId: string) {
    /* Function to be used when a new Element without Connections should be created  */
    function appendStart(event, element) {
      const shape = elementFactory.createShape(
        Object.assign({ type: "composer:ToolchainItem" }, {})
      );

      applyCorrectState(shape, title, dataUrl, productId);

      create.start(event, shape, {
        source: element,
      });
    }

    /* Function to be used when a new Element should be appended to the current ELement */
    function appendElement(event, element) {
      const shape = elementFactory.createShape(
        Object.assign({ type: "composer:ToolchainItem" }, {})
      );

      applyCorrectState(shape, title, dataUrl, productId);

      autoPlace.append(element, shape);
    }

    /* If autoPlace is disabled, the Element is always placed as a new Element without Connections */
    const append = autoPlace ? appendElement : appendStart;

    return {
      group: "model",
      className: "bpmn-icon-task",
      title: title,
      action: {
        dragstart: appendStart,
        click: append,
      },
    };
  }

  /* Fills the Context List with Elements */
  Object.assign(elements, {
    "append.fork-event": appendAction(
      "composer:ForkEvent",
      "composer-fork-icon",
      "Fork-Event anhängen",
      {}
    ),
    "append.append-task": createToolchainTask("", "", ""),
  });

  /* Do not assing End-Event to Start-Event Context as End-Events are not allowed to directly follow Start-Events  */
  if (element.type !== "bpmn:StartEvent") {
    Object.assign(elements, {
      "append.end-event": appendAction(
        "bpmn:EndEvent",
        "bpmn-icon-end-event-none",
        "End-Event anhängen",
        {}
      ),
    });
  }

  /* Fill Rest of ContextPad */
  Object.assign(elements, {
    "append.join-event": appendAction(
      "composer:JoinEvent",
      "composer-join-icon",
      "Join-Event anhängen",
      {}
    ),
    editMetaData: {
      group: "edit",
      className: "bpmn-icon-screw-wrench",
      title: "Meta-Daten bearbeiten",
      action: {
        click: openEditMetaDataDialog,
      },
    },
    delete: {
      group: "edit",
      className: "bpmn-icon-trash",
      title: "Entfernen",
      action: {
        click: removeElement,
      },
    },
    connect: {
      group: "edit",
      className: "bpmn-icon-connection",
      title: "Verbinden",
      action: {
        click: startConnect,
        dragstart: startConnect,
      },
    },
    swarm: {
      group: "edit",
      className: "composer-swarm-logo",
      title: "Swarm",
      action: {},
    },
  });

  return elements;
};
