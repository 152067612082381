import { UserService } from 'src/app/services/user-service/userservice';
import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Injectable({
  providedIn: "root",
})
export class TrackingService {
  private hasLoggedInitialInfo = false;

  constructor(public router: Router) {
  }

  public initGoogleAnalytics(){
    if(localStorage.getItem("hasTrackingConsent") === "true" && !this.hasLoggedInitialInfo){
      if(this.router!=null){
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            // gtag('config', environment.GA_TRACKING_ID, {
            //   'page_path': event.urlAfterRedirects,
            // });
          }
        });
      } else {
        // gtag('config',  environment.GA_TRACKING_ID);
      }
      this.hasLoggedInitialInfo=true;
    }
  }

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ): void {

   if(localStorage.getItem("hasTrackingConsent")=="false") {
      return
    }
   //  gtag("event", eventName, {
   //    eventCategory: eventCategory,
   //    eventLabel: eventLabel,
   //    eventAction: eventAction,
   //   eventValue: eventValue,
   // });
  }
}
