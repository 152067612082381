import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { PtgInterface } from "src/app/model/ptgs/ptg-interface";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { UserService } from "src/app/services/user-service/userservice";

@Component({
  selector: "app-product-type-group-no-content",
  templateUrl: "./product-type-group-no-content.component.html",
  styleUrls: ["./product-type-group-no-content.component.css"],
})
export class ProductTypeGroupNoContentComponent implements OnInit, OnDestroy {
  constructor(
    private ptgService: PtgService,
    private userService: UserService,
    private organisationService: OrganisationService,
    private router: Router
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }
  ngOnDestroy(): void {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }

  ngOnInit() {
    this.ptgService.nextCollection();
  }
}