<div class="one-column">
  <h1>Mitgliedschaften</h1>
  <h2>Eigene Mitgliedschaften</h2>
  <div class="table">
    <div class="row border">
      <p class="closer-to-border"><b>Organisation</b></p>
      <p class="closer-to-border"><b>Rolle</b></p>
    </div>
    <div
      *ngFor="
        let organisation of userService.currentMemberships | confirmedMembers
      "
      class="row"
    >
      <div>
        <p>{{ organisation.organisationName }}</p>
      </div>
      <div>
        <p *ngIf="organisation.role === 'ADMIN'">Administrator</p>
        <p *ngIf="organisation.role === 'USER'">Benutzer</p>
      </div>
      <div class="center-elements delete-button">
        <app-hexagon-button-text-icon
          [text]="'Entfernen'"
          [icon]="'/assets/img/icons/discard.png'"
          [width]="1.5"
          (click)="
            removeOrganisationMembership(
              organisation.organisationId,
              organisation.role
            )
          "
        >
        </app-hexagon-button-text-icon>
      </div>
    </div>
  </div>

  <div class="table">
    <div class="row border">
      <p class="closer-to-border"><b>Meine offenen Einladungen</b></p>
      <p class="closer-to-border"><b>Rolle</b></p>
    </div>
    <div
      *ngFor="
        let organisation of userService.currentMemberships
          | userConfirmationPending
      "
      class="row"
    >
      <div>
        <p>{{ organisation.organisationName }}</p>
      </div>
      <div>
        <p *ngIf="organisation.role === 'ADMIN'">Administrator</p>
        <p *ngIf="organisation.role === 'USER'">Benutzer</p>
      </div>
      <div class="center-elements">
        <app-hexagon-button-text-icon
          [text]="'Annehmen'"
          [icon]="'/assets/img/icons/Bestaetigen.png'"
          (click)="acceptOrganisationMembership(organisation.organisationId)"
        >
        </app-hexagon-button-text-icon>
      </div>
      <div class="center-elements">
        <app-hexagon-button-text-icon
          [text]="'Ablehnen'"
          [icon]="'/assets/img/icons/discard.png'"
          (click)="
            removeOrganisationMembership(
              organisation.organisationId,
              organisation.role
            )
          "
        >
        </app-hexagon-button-text-icon>
      </div>
    </div>
  </div>

  <div class="table">
    <div class="row border">
      <p class="closer-to-border"><b>Meine offenen Anfragen</b></p>
      <p class="closer-to-border"><b>Rolle</b></p>
    </div>
    <div
      *ngFor="
        let organisation of userService.currentMemberships
          | adminConfirmationPending
      "
      class="row"
    >
      <div>
        <p>{{ organisation.organisationName }}</p>
      </div>
      <div>
        <p *ngIf="organisation.role === 'ADMIN'">Administrator</p>
        <p *ngIf="organisation.role === 'USER'">Benutzer</p>
      </div>
      <div class="center-elements">
        <p>Bestätigung eines Administrators fehlt</p>
      </div>
      <div class="center-elements">
        <app-hexagon-button-text-icon
          [text]="'Anfrage löschen'"
          [icon]="'/assets/img/icons/discard.png'"
          (click)="removeMembershipRequest(organisation.organisationId)"
        >
        </app-hexagon-button-text-icon>
      </div>
    </div>
  </div>

  <div class="add-margin">
    <app-hexagon-button-text-icon
      [text]="'Antrag auf Mitgliedschaft zu einer Organisation stellen'"
      [icon]="'/assets/img/icons/Organisation_hinzufuegen.png'"
      (click)="onAddOrganisationToggle(); fillOrganisationlist()"
    >
    </app-hexagon-button-text-icon>
  </div>
  <div *ngIf="addOrganisationMode">
    <p>
      Nachdem der Administrator der Organisation Ihre Mitgliedschaft bestätigt
      hat, sind Sie als Mitglied dieser Organisation eingetragen
    </p>
    <div class="columns-50-50">
      <mat-form-field>
        <mat-select
          placeholder="Organisation"
          [(ngModel)]="addOrganisation.organisationName"
        >
          <mat-option
            *ngFor="let organisation of organisations"
            value="{{ organisation }}"
            >{{ organisation }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="Rolle" [(value)]="addOrganisation.role">
          <mat-option [value]="'ADMIN'">Administrator</mat-option>
          <mat-option [value]="'USER'">Benutzer</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="columns-50-50">
        <button
          mat-raised-button
          class="btn_green"
          (click)="addOrganisationMembership()"
        >
          Ok
        </button>
        <button
          mat-raised-button
          class="btn_red"
          (click)="onCancelAddOrganisation()"
        >
          Abbrechen
        </button>
      </div>
    </div>
  </div>

  <div class="add-margin">
    <app-hexagon-button-text-icon
      [text]="'Neue Organisation auf der Plattform einstellen'"
      [icon]="'/assets/img/icons/Organisation_gruenden.png'"
      (click)="newOrganisationModeToggle()"
    >
    </app-hexagon-button-text-icon>
  </div>
  <div *ngIf="newOrganisationMode">
    <div class="columns-50-50">
      <div class="image-upload">
        <div class="img-wrapper">
          <img
            class="img"
            *ngIf="newOrganisation.fileId"
            [src]="imagePath(newOrganisation.fileId)"
          />
          <img
            class="img"
            *ngIf="!newOrganisation.fileId"
            src="../../../../../assets/img/hexagon.png"
          />
        </div>
      </div>
      <div class="image-upload">
        <div class="img-wrapper">
          <img
            class="img"
            *ngIf="newOrganisation.previewFileId"
            [src]="imagePath(newOrganisation.previewFileId)"
          />
          <img
            class="img"
            *ngIf="!newOrganisation.previewFileId"
            src="../../../../../assets/img/hexagon.png"
          />
        </div>
      </div>
      <button
        class="image-upload-button"
        mat-raised-button
        (click)="openFileManager('logo')"
      >
        Logo ändern
      </button>
      <button
        class="image-upload-button"
        mat-raised-button
        (click)="openFileManager('preview')"
      >
        Vorschau ändern
      </button>
      <mat-form-field>
        <input
          matInput
          placeholder="Name der Organisation"
          [formControl]="name"
          (input)="changeDomainToName(nameInputField.value, domainInputField)"
          required
          #nameInputField
        />
        <mat-error *ngIf="domain.hasError"
          >Dieses Feld darf nicht leer sein.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <mat-select
          placeholder="Art der Organisation"
          [formControl]="type"
          required
        >
          <mat-option value="PRODUCT_PROVIDER">Produktanbieter</mat-option>
          <mat-option
            *ngIf="this.userService.isCurrentUserPlatformAdmin()"
            value="PRODUCT_TYPE_ASSOCIATION"
            >Fachgruppe</mat-option
          >
          <mat-option value="CERTIFICATION_PROVIDER"
            >Zertifizierungsanbieter</mat-option
          >
          <mat-option value="CERTIFICATION_OFFICE"
            >Zertifizierungsdienstleister</mat-option
          >
        </mat-select>
        <mat-error *ngIf="domain.hasError"
          >Dieses Feld darf nicht leer sein.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Straße"
          [formControl]="street"
          fromControlName="street"
        />
        <mat-error *ngIf="street.hasError('specialCharFound')"
          >Bitte keine Sonderzeichen benutzen.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Hausnummer"
          [formControl]="hNr"
        
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Postleitzahl"
          [formControl]="postalCode"
          pattern="[\d]+"
        />
        <mat-error *ngIf="postalCode.errors?.pattern"
          >Bitte nur Zahlen benutzen.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Ort"
          [formControl]="city"
          pattern="[A-ZÖÜÄ][a-z -öüä]+"
        />
        <mat-error *ngIf="city.errors?.pattern"
          >Bitte mindestens zwei Zeichen eingeben. Das erste Zeichen bitte groß schreiben.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Kurzbezeichnung"
          [formControl]="domain"
          required
          #domainInputField
        />
        <span matPrefix>{{ domainPrefix }}</span>
        <mat-error *ngIf="domain.hasError"
          >Dieses Feld darf nicht leer sein.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <input matInput type="email" placeholder="URL" [formControl]="url" />
      </mat-form-field>
    </div>
    <form #form="ngForm">
      <app-text-editor
        [(editorText)]="newOrganisation.description"
        (textValidityChange)="onDescriptionValidityChange($event)"
      ></app-text-editor>
    </form>
    <p>
      Wenn Sie eine neue Organisation auf der Plattform einstellen, werden Sie
      automatisch der Administrator dieser Organisation
    </p>
    <div class="columns-50-50 add-margin-bottom">
      <button
        mat-raised-button
        class="btn_green"
        (click)="onNewOrganisationConfirm(domainInputField)"
        [disabled]="organisationCreateForm.invalid || !isDescriptionValid"
      >
        Ok
      </button>
      <button
        mat-raised-button
        class="btn_red"
        (click)="newOrganisationModeToggle(); organisationCreateForm.reset()"
      >
        Abbrechen
      </button>
    </div>
  </div>
</div>
