<form
  class="two-columns"
  [formGroup]="featureForm"
  (ngSubmit)="onSubmit(featureForm)"
  (keydown.enter)="$event.stopPropagation()"
>
  <div *ngIf="organisationService.activeOrganisation">
    <!----------------------------------------------------------------------------->
    <h1>Merkmal anlegen</h1>
    <!----------------------------------------------------------------------------->
    <mat-form-field>
      <input
        [value]="organisationService.activeOrganisation.organisationName"
        matInput
        disabled
      />
    </mat-form-field>
    <!----------------------------------------------------------------------------->
    <div class="columns-50-50">
      <!------------------------------------------------------------------->
      <mat-form-field>
        <input
          matInput
          formControlName="featureName"
          placeholder="Name des Merkmals"
          [maxLength]="255"
        />
      </mat-form-field>
      <!------------------------------------------------------------------->
      <mat-form-field>
        <mat-select formControlName="dataType" placeholder="Datentyp">
          <mat-option value="BOOLEAN">Wahrheitswert</mat-option>
          <mat-option value="STRING">Text</mat-option>
          <mat-option value="NUMERIC">Zahl</mat-option>
          <mat-option value="LIST">Liste</mat-option>
        </mat-select>
      </mat-form-field>
      <!------------------------------------------------------------------->
    </div>
    <!----------------------------------------------------------------------------->
    <div>
      <!------------------------------------------------------------------->
      <mat-form-field>
        <textarea
          matInput
          rows="3"
          formControlName="description"
          placeholder="Beschreibung des Merkmals"
          [maxLength]="3000"
        ></textarea>
      </mat-form-field>
      <!------------------------------------------------------------------->
      <div
        *ngIf="featureForm.value.description != null"
        class="description-info-message"
      >
        Aktuelle Zeichenlänge {{ featureForm.value.description.length }} (Max:
        3000)
      </div>
      <!------------------------------------------------------------------->
    </div>
    <!----------------------------------------------------------------------------->
    <div
      *ngIf="featureForm.value.dataType === 'LIST'"
      class="columns-right-min"
    >
      <!------------------------------------------------------------------->
      <mat-form-field>
        <input
          matInput
          placeholder="Listenwert"
          #listitem
          (keyup.enter)="processListItem(listitem.value); listitem.value = ''"
        />
      </mat-form-field>
      <!------------------------------------------------------------------->
      <div class="mini-hex-button">
        <hexagon
          [hover]="true"
          [outerColor]="'green'"
          [icon]="'plus'"
          (click)="processListItem(listitem.value); listitem.value = ''"
        ></hexagon>
      </div>
      <!------------------------------------------------------------------->
      <span
        class="error-message"
        *ngIf="checkIfItemTooLarge(listitem.value, 100)"
        >Der Listenwert darf nicht länger als 100 Zeichen sein!</span
      >
      <!------------------------------------------------------------------->
      <span
        class="error-message"
        *ngIf="listAlreadyContainsItem(listitem.value)"
        >Achtung! Die Liste enthält diesen Listenwert bereits!</span
      >
      <!------------------------------------------------------------------->
      <ul>
        <!--------------------------------------------------------->
        <div
          *ngFor="let listValue of listValues; let i = index"
          [ngClass]="{
            'mini-grid-list-items': !checkIfIsDuplicate(listValue),
            'is-redundand-value': checkIfIsDuplicate(listValue)
          }"
        >
          <!------------------------------------------------>
          <li>{{ listValue }}</li>
          <!------------------------------------------------>
          <div class="mini-hex-button">
            <hexagon
              [hover]="true"
              [outerColor]="'green'"
              [icon]="'minus'"
              (click)="onDeleteFromList(i)"
            ></hexagon>
          </div>
          <!------------------------------------------------>
        </div>
        <!--------------------------------------------------------->
      </ul>
      <!------------------------------------------------------------------->
    </div>
    <!----------------------------------------------------------------------------->
    <div *ngIf="featureForm.value.dataType" class="light-green-box">
      <!------------------------------------------------------------------->
      <div class="columns-70-30">
        <!--------------------------------------------------------->
        <p>Soll für dieses Merkmal eine Validierung bestimmt werden?</p>
        <!--------------------------------------------------------->
        <toggle-switch
          [trueLabel]="'Ja'"
          [falseLabel]="'Nein'"
          [allowUndefined]="false"
          [value]="false"
          formControlName="shouldValidate"
        >
        </toggle-switch>
        <!--------------------------------------------------------->
      </div>
      <!------------------------------------------------------------------->
      <app-attribute-validation
        [featureForm]="featureForm"
        [listValues]="listValues"
      ></app-attribute-validation>
      <!------------------------------------------------------------------->
    </div>
    <!----------------------------------------------------------------------------->
    <div class="columns-50-50">
      <!------------------------------------------------------------------->
      <button mat-raised-button type="submit" class="btn_green">
        Speichern
      </button>
      <!------------------------------------------------------------------->
      <button
        mat-raised-button
        type="button"
        class="btn_red"
        (click)="cancel()"
      >
        Abbrechen
      </button>
      <!------------------------------------------------------------------->
    </div>
    <!----------------------------------------------------------------------------->
  </div>
</form>
