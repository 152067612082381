<!-- ssh-console.component.html -->

<div *ngIf="product?.sApiEndpoint" class="console-window">
  <div style=" display: grid; margin: 1vw;">
    <label for="username">Host Username:</label>
    <input type="text" id="username" [(ngModel)]="username" />
  </div>
  <div style=" display: grid;  margin: 1vw;">
    <label for="pemFile">Hochladen dem PEM Daten:</label>
    <input type="file" id="pemFile" (change)="onFileChange($event)" />
  </div>
  <div style=" display: grid;  margin: 1vw;">
    <label for="host">Host IP-Adress:</label>
    <input type="text" id="host" [(ngModel)]="host" />
  </div>
  <div style=" display: none;">
    <label for="command">Command:</label>
    <input type="text" id="command" [(ngModel)]="command" />
    <button
    mat-raised-button class="btn"
                  color="accent"
                  (click)="executeCommand();">
                  Execute
    </button>
  </div>
  <div style=" display: grid;">
    <button
    mat-raised-button class="btn"
                  color="accent"
                  (click)="Bereitstellen();">
                  Docker-Image Bereitstellen
    </button>
  </div>
  <div *ngIf="output" style=" display: grid;">
    <p>Output:</p>
    <pre>{{ output }}</pre>
  </div>
</div>

<div *ngIf="!product?.sApiEndpoint" style=" margin: 2vw;">
    Kein Docker-Image für dieses Produkt verfügbar.
</div>
