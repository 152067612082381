<h2>Toolchain Instanzen</h2>
<div class="table-container">
  <div class="table">
    <div *ngIf="toolchainInstances && toolchainInstances.length > 0" class="headerInstances">
      <div class="firstTableColumn">
        <p><b>Toolchain-ID</b></p>
      </div>
      <div class="centered">
        <p><b>Name</b></p>
      </div>
      <div class="centered">
        <p><b>Status</b></p>
      </div>
      <div class="centered">
        <p><b>Start</b></p>
      </div>
      <div class="centered">
        <p><b>Besitzer</b></p>
      </div>
      <div></div>
      <div></div>
    </div>
    <div *ngIf="toolchainInstances" class="content">
      <ng-container *ngFor="let data of toolchainInstances; let i = index">
        <app-toolchain-instance-table-row
          [data]="data"
          [index]="i"
          (deleteToolchainEvent)="deleteToolchain($event)"
        ></app-toolchain-instance-table-row>
      </ng-container>
    </div>
    <mat-spinner *ngIf="!toolchainInstances"></mat-spinner>
  </div>
</div>
<p *ngIf="toolchainInstances && toolchainInstances.length === 0">
  Es sind keine Toolchain Instanzen vorhanden.
</p>
