import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserService} from "../../../../services/user-service/userservice";
import { MatPaginator } from "@angular/material/paginator";
import {BehaviorSubject} from "rxjs";
import {UserRoleInterface} from "../../../../model/user/user-role-interface";
import {UserRoleDataSource} from "../../../../dataSource/userRoleDataSource";
import {DialogService} from "../../../../services/dialog-service/dialog-service.service";
import {NutzerverwaltungService} from "../../../../services/nutzerverwaltung/nutzerverwaltung.service";
import {OrganisationInterface} from "../../../../model/organisations/organisation-interface";
import {OrganisationService} from "../../../../services/dataServices/organisation-service/organisationservice";

@Component({
  selector: 'app-nutzerverwaltung-screen',
  templateUrl: './nutzerverwaltung-screen.component.html',
  styleUrls: ['./nutzerverwaltung-screen.component.css']
})
export class NutzerverwaltungScreenComponent implements OnInit, OnDestroy,AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public totalNumberOfElements: BehaviorSubject<number>;
  private dataSourceUserRoles: UserRoleDataSource;
  public userRoleGroups: UserRoleInterface[] = [];
  public userSearch: string = '';
  public addOrganisation: OrganisationInterface;
  public organisations: Array<string> = [];
  public toggleDeletionScreen: boolean = false;

  constructor(public dialogService: DialogService,
              private cd: ChangeDetectorRef,
              public nutzerverwaltungService: NutzerverwaltungService,
              public organisationService: OrganisationService,
              private userService: UserService) {
    this.totalNumberOfElements = new BehaviorSubject<number>(0);
    this.addOrganisation = {
      membershipStatus: "CONFIRMED",
      organisationId: null,
      organisationName: null,
      organisationType: null,
      address: null,
      domain: null,
      role: null,
      website: null,
    };
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
  }

  ngOnInit(): void {
    this.initializeUserRoleSource();
  }

  ngOnDestroy()
  {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }

  ngAfterViewInit(): void {
    this.initializePaginator();
    this.subscribeToPaginator();
    this.nutzerverwaltungService.searchTextSubject.subscribe((searchString: string) => {
      this.loadUserRolePage(this.paginator.pageIndex, searchString);
    });
    this.fillOrganisationlist();
  }

  /**
   * Initialisiert den Paginator
   **/
  initializePaginator(): void {
    const paginatorIntl = this.paginator._intl;
    paginatorIntl.nextPageLabel = "Nächste Seite";
    paginatorIntl.previousPageLabel = "Vorherige Seite";
    paginatorIntl.itemsPerPageLabel = "Einträge pro Seite";
    paginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      let end = (page + 1) * pageSize;
      if (end > length) {
        end = length;
      }
      return `${start} - ${end} von ${length}`;
    };
  }

  /**
   * Initialisiert die Tabelle mit allen Benutzern
   */
  initializeUserRoleSource(): void {
    this.dataSourceUserRoles = new UserRoleDataSource(this.nutzerverwaltungService);
    this.dataSourceUserRoles.getObservable().subscribe((res) => {
      this.userRoleGroups = res;
      this.totalNumberOfElements.next(
        this.dataSourceUserRoles.getTotalNumberOfElements()
      );
    });
    this.dataSourceUserRoles.loadUserRoles(0, 10, '');
  }

  /**
   * Immer wenn eine Seite geblättert wird, werden die entsprechenden Nutzer geladen
   */
  subscribeToPaginator(): void {
    this.paginator.page.subscribe(() => {
        this.loadUserRolePage(this.paginator.pageIndex, this.userSearch);
    });
  }

  /**
   * Lädt die entsprechenden Nutzer in die Tabelle
   * @param pageIndex Der Index der Seite auf der man sich befindet
   * @param searchString
   */
  loadUserRolePage(pageIndex: number, searchString: string): void {
    this.dataSourceUserRoles.loadUserRoles(pageIndex, this.paginator.pageSize, searchString);
  }

  /**
   * Ändert die Rolle eines bestimmten Benutzers
   * @param event Die neue Rolle
   * @param userId Die ID des Nutzers dessen Rolle geändert werden soll
   */
  changeUserRole(event, userId: number): void {
    this.nutzerverwaltungService.updateUserRole(userId, event.value,
      () => this.dialogService.openDialog(
        "Rolle des Nutzers Erfolgreich geändert",
        "Der Nutzer ist nun " + event.value
      ),
      () => this.dialogService.openDialog(
        "Ändern der Rolle fehlgeschlagen",
        "Die Rolle des Nutzers konnte nicht auf " + event.value + " geändert werden"
      ).subscribe(
        () => {
          this.userRoleGroups.find((user) => user.userId === userId).userRole = event.value === "USER" ? "PLATTFORMADMIN" : "USER";
          this.cd.detectChanges();
        }
      ));
  }

  /**
   * Ändert die Composer-Zugriffsrechte eines bestimmten Benutzers
   * @param event Neue Composer-Zugriffsrechte des Benutzers
   * @param userId Die ID des Nutzers dessen Composer-Zugriffsrechte geändert werden sollen
   */
  changeComposerAccess(event: boolean, userId: number): void {
    this.nutzerverwaltungService.changeComposerAccessOfUser(userId, event,
      () => this.dialogService.openDialog(
        "Die Composer-Nutzungsrechte des Nutzers Erfolgreich geändert",
        event ? "Der Nutzer hat nun Zugriffsrechte für den Composer" : "Der Nutzer hat nun keine Zugriffsrechte für den Composer mehr"
      ),
      () => this.dialogService.openDialog(
        "Ändern der Nutzungsrechte des Nutzers fehlgeschlagen",
        event ? "Der Nutzer hat weiterhin keine Rechte den Composer zu nutzen" : "Der Nutzer hat weiterhin Rechte den Composer zu nutzen"
      ).subscribe(
        () => {
          this.userRoleGroups.find((user) => user.userId === userId).hasComposerAccess = !event;
          this.cd.detectChanges();
        }
      ));
  }

  filterUserSearchText() {
    this.nutzerverwaltungService.receiveSearchText(this.userSearch);
  }

  onAddOrganisationToggle(index: number): void {
    for (let i = 0; i < this.dataSourceUserRoles.addOrganisationMode.length; i++) {
        if (i !== index) {
          this.dataSourceUserRoles.addOrganisationMode[i] = false;
        } else {
          this.dataSourceUserRoles.addOrganisationMode[i] = !this.dataSourceUserRoles.addOrganisationMode[i];
        }
    }
  }

  addOrganisationMembership(index: number): void {
    const user: UserRoleInterface = this.userRoleGroups[index];
    if (!this.addOrganisation.organisationName || !this.addOrganisation.role) {
      this.dialogService.openDialog(
        "Hinzufügen eines Nutzers zu Organisation",
        "Bitte geben Sie die Organisation und Rolle an!"
      );
      return;
    }
    this.addOrganisation.organisationId = this.organisationService.collection.filter(
      (organisation: OrganisationInterface) =>
        organisation.organisationName === this.addOrganisation.organisationName
    )[0].organisationId;
    this.nutzerverwaltungService.addUserToOrganisation(this.addOrganisation, user.userId, () => this.dialogService.openDialog(
      "Der Benutzer wurde erfolgreich der Organisation hinzugefügt",
      "Der Benutzer ist nun Teil der Organisation und kann im Rahmen dieser Aktionen ausführen."
      ).subscribe(() => {
        this.dataSourceUserRoles.addOrganisationMode[index] = false;
        this.cd.detectChanges();
      }),
      () => this.dialogService.openDialog(
        "Benutzer zur Organisation hinzufügen Fehlgeschlagen",
        "Es ist ein Fehler beim Hinzufügen des Benutzers zur Organisation aufgetreten. Bitte versuchen Sie es später noch einmal."
      ).subscribe(() => {
        this.dataSourceUserRoles.addOrganisationMode[index] = false;
        this.cd.detectChanges();
      }));
  }

  deleteUser(id: number): void {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie die Mitgliedschaft wirklich entfernen?",
        "Abbrechen",
        "Entfernen"
      )
      .subscribe((result) => {
        if (result) {
          this.userService.deleteAccountAsPlatformAdmin(id).subscribe((
            (res) => this.dialogService.openDialog("Nutzer gelöscht", "Das Konto mit der Id: " + id + " wurde gelöscht")
          ))
        }
      });
  
  }

  fillOrganisationlist(): void {
    this.organisationService.getAllOrgs().then(() => {
      for (let i = 0; i < this.organisationService.collection.length; i++) {
        this.organisations.push(
          this.organisationService.collection[i].organisationName
        );
      }
    });
  }

}
