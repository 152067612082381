import { Component, Input, OnInit } from '@angular/core';
import { CertificationRequestStatus } from 'src/app/model/enums/certification-requests-status';

@Component({
  selector: 'status-display',
  templateUrl: './status-display.component.html',
  styleUrls: ['./status-display.component.css']
})
export class StatusDisplayComponent implements OnInit {
  public color = 'grey';

  @Input()
  status: CertificationRequestStatus;

  constructor() { }

  ngOnInit() {}

  ngOnChanges() {
    this.setColor(this.status);
  }

  setColor(status: string) {
    switch(this.status) {
      case CertificationRequestStatus.REQUESTED: {
        this.color = 'grey';
        break;
      };
      case CertificationRequestStatus.ACCEPTED: {
        this.color = 'yellow';
        break;
      }
      case CertificationRequestStatus.WAITING: {
        this.color = 'grey';
        break;
      }
      case CertificationRequestStatus.IN_PROGRESS: {
        this.color = 'yellow';
        break;
      }
      case CertificationRequestStatus.PASSED: {
        this.color = 'green';
        break;
      }
      case CertificationRequestStatus.NOT_PASSED: {
        this.color = 'red';
        break;
      }
      case CertificationRequestStatus.CLOSED: {
        this.color = 'green';
        break;
      }
      default:
        this.color = 'red';
    }
  }
}
