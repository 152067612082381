<div class="version-info">
  <div *ngIf="showExternalProductInfo && product">
    <h2>{{ product.name }}</h2>
    <p class="description" [innerHTML]="product.description"></p>
    <h3 *ngIf="relProducts?.length > 0">Produktabhängigkeiten</h3>
    <ul>
      <li *ngFor="let relProduct of relProducts">{{ relProduct }}</li>
    </ul>
    <h3>
      Organisation:
      <button
        md-raised-button
        (click)="navigateOrganisation()"
        class="btn_green"
      >
        {{ organisationDomainOrName }}
      </button>
    </h3>
    <h3>Variante: {{ variant.name }}</h3>
    <p class="description" [innerHTML]="variant.description"></p>
  </div>
  <h2 *ngIf="variant">{{variant.name}}</h2>
  <h3 *ngIf="showExternalProductInfo; else versionHeader">
    Version {{ version.name }}
  </h3>
  <ng-template #versionHeader>
    <h2>Version {{ version.name }}</h2>
    <div class="description" *ngIf="version.description">
      <h3>Beschreibung</h3>
      <p [innerHTML]="version.description"></p>
    </div>
  </ng-template>
  <div *ngIf="!showExternalProductInfo">
    <div *ngIf="version.comment">
      <h3>Kommentar</h3>
      <p>{{ version.comment }}</p>
    </div>
    <div *ngIf="previousVersionName">
      <h3>Vorgängerversion</h3>
      <p>
        {{ previousVersionName }}
      </p>
    </div>
  </div>

  <div *ngIf="version.ptgVersions.length != 0">
    <h3>Produkttypen</h3>
    <ng-container *ngFor="let ptg of activeVersionPtgs">
      <h4>Produkttyp {{ ptg.ptgName }} - Version {{ ptg.ptgVersionNumber }}</h4>
      <div class="version-info-attributes">
        <ng-container
          *ngFor="let attributeValidation of ptg.ptgVersionAttributes"
        >
          <div>
            <div
              *ngIf="attributeValidation.attribute.description"
              matTooltip="{{ attributeValidation.attribute.description }}"
              [matTooltipPosition]="'below'"
              matTooltipClass="tooltip"
            >
              <img
                [src]="'/assets/img/icons/info.png'"
                alt="Beschreibungs-Icon"
              />
            </div>
          </div>
          <div>
            {{
              attributeValidation.optionalValidation
                ? attributeValidation.attribute.name + " (optional)"
                : attributeValidation.attribute.name
            }}
          </div>
          <div>
            {{
              attrValueNotNull(getAttrValue(attributeValidation.attribute.id))
                ? checkAndTransformBoolValueFromId(
                    attributeValidation.attribute.id
                  )
                : "Keine Angabe"
            }}
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <h3 *ngIf="additionalAttributes.length != 0">
    Zusätzliche Merkmale
  </h3>
  <div class="version-info-attributes">
    <ng-container *ngFor="let attributeValue of additionalAttributes">
      <div>
        <div
          *ngIf="attributeValue.attribute.description"
          matTooltip="{{ attributeValue.attribute.description }}"
          [matTooltipPosition]="'below'"
          matTooltipClass="tooltip"
        >
          <img [src]="'/assets/img/icons/info.png'" alt="Beschreibungs-Icon" />
        </div>
      </div>
      <div>{{ attributeValue.attribute.name }}</div>
      <div>
        {{
          attrValueNotNull(attributeValue.value)
            ? checkAndTransformBoolValue(attributeValue.value)
            : "Keine Angabe"
        }}
      </div>
    </ng-container>
  </div>
  <h3 *ngIf="version.keywords.length != 0">Extra Schlagworte</h3>
  <addEntriesToList-panel
    [keywords]="version.keywords"
    [keywordPanel]="true"
  ></addEntriesToList-panel>
</div>
