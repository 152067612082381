/* Custom Modul for the Properties Panel */
export function ComposerLabel(options: {
  id: string;
  labelName: string;
  labelContent: string;
}) {
  return {
    id: options.id,
    html:
      '<label ' +
      'data-show="showLabel" style="margin-top: 1vh' +
      '">' +
      '<span data-value="labelName"></span> <div><span style="color: black" data-value="labelContent"></span></div>' +
      "</label>",
    get: function (element, node) {
      return {
        labelName: options.labelName,
        labelContent: options.labelContent,
      };
    },
    showLabel: function (element, node) {
      return true;
    },
  };
}
