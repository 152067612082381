//(import { DocumentInterface } from '../model/documents/document-interface';

import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpClient,
  HttpResponse,
  HttpEvent,
  HttpRequest,
  HttpParams,
} from "@angular/common/http";
import { API_MAP, API_BASE_PRODUCTSERVICE } from "src/environments/api";
import { BaseService } from "../../base.service";
import {
  CertificationInterface,
  CertificationInputError,
} from "src/app/model/certifications/certification-interface";
import { FileUploadService } from "../file-upload-service/file-upload.service";
import { UserService } from "../../user-service/userservice";
import { CertificationVariantService } from "../certification-variant-service/certification-variant-service";
import { CertificationDtoConverterService } from "../../certification-dto-converter-service/certification-dto-converter-service.service";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { Observable, Subscription, Subscribable } from "rxjs";
import { Status } from "src/app/model/enums/status";
import { SortMethod } from "src/app/model/enums/SortMethod";
import { CertificationOverviewInterface } from "src/app/model/marketplace/certification-overview-interface";
import { RequestInterface } from "src/app/model/marketplace/request-interface";


const SWARM_API = API_BASE_PRODUCTSERVICE;
const SWARM_PIC_API = "/proxy/api/v0/productservice/pictures/";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable()
export class CertificationService extends BaseService<CertificationInterface> {
  /**
   * @param item
   * @returns -1 if certification has no id
   */
  protected toID(item: CertificationInterface | number): number {
    if (typeof item === "number") return item;
    if (item.id) return item.id;
    return -1;
  }

  constructor(
    protected dtoConverter: CertificationDtoConverterService,
    protected fileUploadService: FileUploadService,
    protected http: HttpClient,
    private variantService: CertificationVariantService,
    private userService: UserService
  ) {
    super(http, null, dtoConverter);
    //set the swarm API
    this.SWARM_API_BASE = SWARM_API;
    this.SWARM_PIC_API = SWARM_PIC_API;
    //-----------------------
    // Specify a item mapping to know which URL identifier is to be mapped
    // to witch information on an item
    this.ITEM_MAP = this.userService.ACTIVE_MAP;
    // Setting coded URL path for the API of this service
    this.SERVICE_FLAG = ":certificationId";
    this.SWARM_API_MAP = API_MAP["certifications"];

    //-----------------------
    //-----------------------
    this.variantService.deleteRequestObserver.subscribe(
      (toBeDeleted: CertificationVariantInterface) => {
        if (this.activeItem && this.activeItem.certificationVariants) {
          const index: number = this.activeItem.certificationVariants.findIndex(
            (x) => {
              return x.id === toBeDeleted.id;
            }
          );
          if (index >= 0) {
            this.activeItem.certificationVariants.splice(index, 1);
          }
        }
      }
    );
    //-----------------------
    // Subscribe to the variant service so this instance of certification is updated
    // this ensures that there is no unnecessary traffic to update
    this.variantService.activeItemObserver.subscribe(
      (variant: CertificationVariantInterface) => {
        if (!variant || typeof variant === "undefined") {
          return;
        }

        if (variant) {
          // The active Item is always the current certification that is edited
          // see if variant is already in certification
          let index = this.activeItem.certificationVariants.findIndex(
            (other) => other.id === variant.id
          );
          if (index > -1) {
            // there is variant with same ID

            // but the variant was edited so we override the reference
            // in the active certification with the new variant
            this.activeItem.certificationVariants[index] = variant;
          } else {
            // variant is not in certification so add it
            this.activeItem.certificationVariants.push(variant);
          }
          // we update the active item in collection
          this.updateItemInCollection(this.activeItem);
          //
        }
        this.next();
      }
    );
    //-----------------------
    this.activeItemObserver.subscribe(() => {
      // router.navigate();
    });
    //-----------------------
  }

  get activeCertification() {
    return this.activeItem;
  }

  get certifications() {
    return this.collection;
  }

  getCertificationByName(name: string): CertificationInterface {
    return this.collection.find((x) => {
      return x.name === name;
    });
  }

  setActiveCertification(
    certification: CertificationInterface | number
  ): Promise<boolean> {
    let id = this.toID(certification);
    this.userService.ACTIVE_MAP[":certificationId"] = String(id);
    return this.setActiveItem(id);
  }

  getCertificationsOfOrganisation(organisationId: number) {
    return this.collection.filter((certification) => {
      return certification.company === organisationId;
    });
  }

  getCertification(certification: CertificationInterface | number) {
    this.ITEM_MAP[":certificationId"] = String(this.toID(certification));
    return this.getItem(certification);
  }

  /**
   * If through deletion of a file (process within file-manager component) one or more fields
   * of a certification point to a no longer existing file this function deletes the references
   * @param certificationId the certificationId that was connected to a now deleted file via object link
   * @param type the kind of connection the object link held to the now deleted file
   * @param fid fileId of the now deleted file
   */
  deleteFileReference(certificationId: number, type: string, fid?: string) {
    for(let i = 0; i < this.collection.length; i++) {
      if(this.collection[i].id == certificationId) {
        if(type == "certification-picture") {
          this.collection[i].fileId = null;
          return;
        }
        if(type == "cert-gallery-picture") {
          let index = this.collection[i].galleryFileIds.findIndex((fileId: string) => fileId == fid);
          if (index >= 0) {
            this.collection[i].galleryFileIds.splice(index, 1);
          }
          return;
        }
        if(type == "cert-product-doc") {
          let index = this.collection[i].documentFileIds.findIndex((fileId: string) => fileId == fid);
          if (index >= 0) {
            this.collection[i].documentFileIds.splice(index, 1);
          }
        }
      }
    }
  }

  /**
   * Creates a new certification in backend
   * @param certification certification data to create
   * @param file optional logo of the certification, if given, is uploaded in advance
   */
  async createCertification(
    certification: CertificationInterface,
    file?: File
  ) {
    this.ITEM_MAP[":certificationId"] = String(this.toID(certification));

    if (file) {
      let f = await this.uploadCertificationLogo(file);
      // manually set certificationPic ID in frontend and send it to backend afterwards
      certification.pictureId = f;
    }
    return this.postItem(certification);
  }

  /**
   * Sends edited certification to backend
   * @param certification certificationdata of the edited certification
   * @param file optional logo of the cert
   */
  async editCertification(certification: CertificationInterface, file?: File) {
    this.ITEM_MAP[":certificationId"] = String(this.toID(certification));
    if (file) {
      let f = await this.uploadCertificationLogo(file);
      certification.pictureId = f;
    }

    return this.putItem(certification);
  }

  loadCertificationsForOrganisation(
    organisation: OrganisationInterface
  ): Promise<boolean> {
    let orgURL = "/proxy/api/v0/productservice/certifications/organisation/";
    return new Promise((resolve, reject) => {
      this.http
        .get<CertificationInterface[]>(orgURL + organisation.organisationId)
        .subscribe(
          //success
          (certificationDTO) => {
            let ids: Promise<void>[] = [];
            certificationDTO.forEach(async (element) => {
              // add certifications to list
              ids.push(
                this.getCertification(element.id).then((x) => {
                  this.updateCertificationVariantService(x);
                })
              );

              await ids[ids.length - 1];
            });

            Promise.all(ids).then(() => {
              resolve(true);
            });
          },
          //failure
          () => {
            reject(false);
          }
        );
    });
  }

  /**
   *  Downward propagation to update new loaded items into the subservice
   * @param certification current certification
   */
  updateCertificationVariantService(certification: CertificationInterface) {
    certification.certificationVariants.forEach(
      (variant: CertificationVariantInterface) =>
        this.variantService.updateCertificationVariantService(variant)
    );
  }

  deleteVariantsIn(certification: CertificationInterface) {
    certification.certificationVariants.forEach((variant) => {
      this.variantService.deleteVersionsIn(variant);
    });
    let index = this.collection.findIndex((x) =>
      this.idCompare(x, certification)
    );
    if (index >= 0) {
      this.collection.splice(index, 1);
    }
  }

  deleteCurrentCertification() {
    this.ITEM_MAP[":certificationId"] = String(this.activeItem.id);
    return this.deleteCertification(this.activeItem);
  }

  // Downwards propagation on delete ( deleting variants and versions of deleted cert)
  deleteCertification(certification: CertificationInterface) {
    return this.deleteItem(certification).then((success) =>
      this.deleteVariantsIn(certification)
    );
  }

  uploadCertificationLogo(certificationLogo: File): Promise<number> {
    let certificationId: number = this.activeItem.id;
    var url =
      API_BASE_PRODUCTSERVICE +
      API_MAP["certificationPictures"]["POST"].replace(
        ":certificationId",
        certificationId + ""
      );

    return new Promise((resolve, reject) => {
      // forwards file to fileUploadService with true flag (3rd param), because its a file for a certification
      this.fileUploadService.uploadFile(certificationLogo, url, true).subscribe(
        //success
        (x: HttpResponse<string>) => {
          resolve(parseInt(x.body));
        },
        //failure
        () => {
          reject(-1);
        }
      );
    });
  }

  /**
   * Uploads the given File to the picture Gallery of the active Item
   * @param galleryPicture
   */
  uploadPictureToGallery(galleryPicture: File): Promise<number> {
    let certificationId: number = this.activeItem.id;
    var url =
      API_BASE_PRODUCTSERVICE +
      API_MAP["pictureGallery"]["POST"].replace(
        ":entityId",
        certificationId + ""
      );

    return new Promise((resolve, reject) => {
      this.fileUploadService.uploadFile(galleryPicture, url, true).subscribe(
        //success
        (x: HttpResponse<string>) => {
          resolve(parseInt(x.body));
        },
        //failure
        () => {
          reject(-1);
        }
      );
    });
  }

  deletePictureFromGallery(
    certificationId: number,
    pictureId: number
  ): Observable<HttpEvent<string>> {
    const url =
      this.SWARM_PIC_API +
      "gallery/delete/" +
      certificationId +
      "/" +
      pictureId;
    const req = new HttpRequest("GET", url, {
      headers: new HttpHeaders({}),
      reportProgress: true,
      responseType: "text",
      // CertificationRequest needs extra parameter
      params: new HttpParams().set("isCertification", String(true)),
    });
    return this.http.request(req);
  }

  updatePictureOrderOfGallery(
    pictureIds: number[],
    certificationid: number
  ): Observable<HttpEvent<any>> {
    const url = this.SWARM_PIC_API + "galleryPictureIds/" + certificationid;
    const req = new HttpRequest("POST", url, pictureIds, {
      //  params: params,
      headers: new HttpHeaders({}),
      reportProgress: true,
      responseType: "text",
      // CertificationRequest needs extra parameter
      params: new HttpParams().set("isCertification", String(true)),
    });
    return this.http.request(req);
  }

  getListOfGalleryPictureIds(certificationId: number): Observable<number[]> {
    let url: string =
      API_BASE_PRODUCTSERVICE +
      API_MAP["pictureGallery"]["ALL"].replace(
        ":entityId",
        certificationId + ""
      );

    return this.http.get<number[]>(url, {
      // CertificationRequest needs extra parameter
      params: new HttpParams().set("isCertification", String(true)),
    });
  }

  getCertificationPicture(certificationId: number): string {
    return (
      API_BASE_PRODUCTSERVICE +
      API_MAP["certificationPictures"]["GET"].replace(
        ":certificationId",
        certificationId + ""
      ) +
      "?isCertification=true"
    );
  }

  generateRandomNumber(): number {
    return Math.floor(Math.random() * 100 + 1);
  }

  checkIfCertificationHasActiveVersion(
    certification: CertificationInterface
  ): boolean {
    return certification.certificationVariants.some((variant) =>
      variant.certificationVariantVersions.some(
        (version) => version.status === Status.ACTIVE
      )
    );
  }

  getFilteredCertificationSlice(
    pageIndex: number,
    pageSize: number,
    organisationIds: number[],
    ptgIds: number[],
    attributeIds: number[],
    statusIds: number[],
    searchTerm: string,
    orderByOrganisation: SortMethod
  ): Subscribable<{
    certificationSlice: CertificationOverviewInterface[];
    totalNumberOfCertifications: number;
  }> {
    //Create pageRequest
    let pageRequestDto = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      organisationIds: organisationIds.filter((x) => x != null),
      ptgIds: ptgIds.filter((x) => x != null),
      attributeIds: attributeIds.filter((x) => x != null),
      statusIds: statusIds,
      searchTerm: searchTerm,
      sortMethod: orderByOrganisation,
    };

    // Stringify and post pagerequest
    const json = JSON.stringify(pageRequestDto);
    return this.http.post<{
      certificationSlice: CertificationOverviewInterface[];
      totalNumberOfCertifications: number;
    }>(this.SWARM_API_BASE + this.SWARM_API_MAP["FILTER"], json, httpOptions);
  }
/** sendet eine potenzielle Angebotsanfrage fuer zertifikate ans backend */
  sendOfferRequest(requestDto: RequestInterface) {
    const json = JSON.stringify(requestDto);
    const url = this.SWARM_API_BASE + this.SWARM_API_MAP["OFFERREQUEST"];


    return this.http.post(url, json, httpOptions);
  }

  filterCertificationsByStatus(
    certifications: CertificationInterface[],
    status: Status
  ): CertificationInterface[] {
    return certifications.filter(
      (certification) =>
        certification.certificationVariants.filter(
          (variant) =>
            variant.certificationVariantVersions.filter(
              (version) => version.status === status
            ).length > 0
        ).length > 0
    );
  }
}
