import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { UserService } from 'src/app/services/user-service/userservice';

  const userMessageNewMail: string = "Verifizierung abgeschlossen";
  const userMessageDetailNewMail: string = "Sie können sich nun mit Ihrer neuen E-Mail anmelden.";
  const userMessageOldMail: string = "Bestätigung erfolgreich";
  const userMessageDetailOldMail: string = "Es wurde eine Bestätigung an Ihre neue E-Mail versendet. Bitte bestätigen Sie diese, um fortzusetzen.";

@Component({
  selector: 'app-verify-user-name',
  templateUrl: './verify-user-name.component.html',
  styleUrls: ['./verify-user-name.component.css']
})
export class VerifyUserNameComponent implements OnInit {
  

  private userMessage: string = '';
  private userMessageDetail: string = '';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private userservice: UserService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {

    this.route.queryParamMap.subscribe((params) => {
    const token = params.get("code");
    const isNewMail: boolean = this.router.url.includes('verify_new');
    this.userservice.verifyNewUserName(token, isNewMail).subscribe(
        (_) => {
          this.chooseUserMessage(isNewMail);
          const modalResult = this.dialogService.openDialog(
            this.userMessage,
            this.userMessageDetail
          );
         modalResult.subscribe((_) => {
            this.router.navigate(["login"]);
          });
        },
        
        (errorObj) => {
         this.handleVerifyError(errorObj.error);
        }
      );
    });
  }

  private chooseUserMessage(isNewMail: boolean) : void{
    if(isNewMail){
      this.userMessage = userMessageNewMail;
      this.userMessageDetail = userMessageDetailNewMail;
    }else {
      this.userMessage = userMessageOldMail;
      this.userMessageDetail = userMessageDetailOldMail;
    }
  }

  handleVerifyError(error) {
    this.router.navigate(["/"]);
  }

}
