import { Injectable } from '@angular/core';
import { ReviewInterface } from 'src/app/model/reviews/review-interface';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CommentInterface } from 'src/app/model/reviews/comment-interface';
import { API_MAP } from 'src/environments/api';
import { PublicReviewInterface } from 'src/app/model/reviews/public-review-interface';
import { PublicReviewPageInterface } from 'src/app/model/reviews/public-review-page-interface';

const SWARM_API = "/proxy/api/v0/userservice/reviews";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
};

@Injectable()



export class ReviewService {


  upvoteReview(reviewId: number, userId: number) {
    return this.http.get<ReviewInterface>(SWARM_API + '/upvotes/' + reviewId + "/" + userId);
  }

  constructor(
    private http: HttpClient
  ) { }

  getReviews(reviewType: number, targetId: number) {
     return this.http.get<ReviewInterface[]>(SWARM_API + '/' + reviewType + '/' + targetId).toPromise();
  }

  //erstellen und bearbeiten von Reviews
  writeEditReview(newReview: ReviewInterface) {
    return this.http.post<ReviewInterface>(SWARM_API, newReview, httpOptions);
  }

  deleteReview(review: ReviewInterface) {
    return this.http.delete<void>(SWARM_API + '/' + review.reviewId);
  }

   addComment(reviewId: number, comment: CommentInterface) {
    return this.http.post<ReviewInterface>(SWARM_API + "/comments/" + reviewId, comment, httpOptions);
  }

  deleteComment(reviewId: number, commentId: number){
    return this.http.delete<ReviewInterface>(SWARM_API + "/comments/" + reviewId + "/" + commentId);
  }

  getPublicReviewSlice(reviewRequest: {pageIndex: number, pageSize: number}, username: string) {
    return this.http.post<PublicReviewPageInterface>(SWARM_API + "/overview/" + username + "/slice", reviewRequest);
  }

  public createReport(reportReason: String, reviewId: number) {
    return this.http.post(SWARM_API + "/report/" + reviewId, reportReason, httpOptions).subscribe();
  }

  public getReviewsWithReports(): Observable<ReviewInterface[]> {
    return this.http.get<ReviewInterface[]>(SWARM_API + '/reported' );
  }

  public deleteReviewReport(reviewReportId: number, status: String) {
    return this.http.put(SWARM_API + "/report/" + reviewReportId, JSON.stringify(status), httpOptions).pipe();
  }
}
