<div class="one-column">
  <h1>Zertifizierungsanträge</h1>
  <div class="table">
    <div class="header-row">
      <h3 class="margin-left"> ID </h3>
      <h3> Datum Eingang </h3>
      <h3> Organisation </h3>
      <h3> Produktname, Variante & Version </h3>
      <h3> Status </h3>
    </div>
    <div *ngFor="let request of certificationRequests; let i = index" class="row">
      <p class="margin-left"> {{ request.id }} </p>
      <p> {{ request.certificationRequestLog[0].timestamp | humanReadableTime }} </p>
      <p> {{ request.requestingOrganisationName }} </p>
      <p> {{ request.productName }}, {{ request.productVariant }},  {{ request.productVersion }}</p>
      <div>
        <status-display
          [status] = "request.status" class="left">
        </status-display>
      </div>
      <div>
        <button
          type="button"
          mat-raised-button
          class="btn_green right"
          (click)="showDetails(i)"
        >
        Anzeigen
        </button>
      </div>
    </div>
    <p *ngIf="certificationRequests.length == 0" class="italic"> Aktuell liegen keine Zertifizierungsanträge vor.</p>
  </div>
</div>