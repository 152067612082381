<!--TODO Eigene Hexagon Komponente auslagern-->
<div>
  <div class="file-manager" appFileDrop (onFileDropped)="onFileSelected($event)">
    <div class="file-list">
      <div
        *ngFor="let fileItem of fileById$ | async | keyvalue"
        (click)="selectFile(fileItem.key)"
        [ngClass]="fileViewClass(fileItem.value)"
      >
        <div class="file-preview">
          <red-cross-button
            class="delete-button"
            (click)="confirmDelete(fileItem.key)"
          >
          </red-cross-button>
          <img
            [src]="previewImage(fileItem.key)"
            [alt]="fileItem.value.filename"
          />
        </div>
        <div class="file-info">
          {{ fileItem.value.filename }}
        </div>
      </div>
    </div>
  </div>
  <div class="uploadFileDisplay" *ngIf="!uploadDisabled">
    <div>
      <button
        mat-raised-button
        class="btn_green"
        (click)="fileUpload.click()"
      >
        Datei zum Upload auswählen
      </button>
      <div *ngIf="!isAnyFileSelected()" class="tooltip-no-selection">Es wurde noch kein Bild ausgewählt. Bitte klicken Sie ein Bild an um dieses übernehmen zu können.</div>
    </div>
      <input
        style="display: none;"
        [multiple]="!uploadModeSingle"
        type="file"
        name=""
        value=""
        (change)="onFileSelected($event)"
        #fileUpload
      />
  </div>
<hr>
  <div >
    <h4>
      Ausgewählte Dateien:
    </h4>
    <div *ngIf="!multiSelectMode" class="fileInfoDisplay">
      <!-- SingleFileSelect -->
      Name:
      <span *ngIf="selectedSingleItem">{{
        fileById[selectedSingleItem].filename
      }}</span>
    </div>
    <div *ngIf="multiSelectMode" class="multiFileDisplay">
      <!-- MultiFileSelect -->
      <ul>
        <li *ngFor="let fileItemId of selectedItem" class="file-selected-list">
          {{ fileById[fileItemId].filename }}
          <red-cross-button class="file-unselect" (click)="selectFile(fileItemId)"></red-cross-button>
        </li>
      </ul>
    </div>
    <div class="fileInfoPanel" *ngIf="lastItemId && fileEditEnabled" >
      <div>
        <label for="FileDetailsName">Dateiname: </label
        ><input
          readonly
          type="text"
          name="FileDetailsName"
          value=""
          [(ngModel)]="fileById[lastItemId].filename"
        />
      </div>
      <div>
        Vorschau:
        <div class="file-preview">
          <img [src]="previewImage(lastItemId)" [alt]="lastItemId" />
        </div>
      </div>
      <div>
        Dateityp: <span class="file-type">{{ filetypeName(lastItemId) }}</span>
      </div>
      <div>
        <label for="FileDetailsName">Beschreibung: </label
        ><textarea
          name="name"
          rows="8"
          cols="80"
          [(ngModel)]="fileById[lastItemId].description"
        ></textarea>
      </div>
      <button
        type="button"
        name="button"
        (click)="updateFileMeta(fileById[lastItemId])"
      >
        Speichern
      </button>
      <button type="button" name="button" (click)="confirmDelete(lastItemId)">
        Löschen
      </button>
    </div>

  </div>
</div>
