/* Registration */
export function CustomContextPadForViewer(
  contextPad,
) {
  contextPad.registerProvider(this);
}

/* Allows dynamic Parameter Injection */
CustomContextPadForViewer["$inject"] = [
  "contextPad",
];

/* Definies which Elements are shown in the Context */
CustomContextPadForViewer.prototype.getContextPadEntries = function (element: any) {
  return {};
};
