import { ComposerOrganisationSelectInterface } from "src/app/model/composer/composer-organisation-select-interface";
import { PtgInterface } from "src/app/model/ptgs/ptg-interface";

/* Buffer for the temporary Values */
export let currentPropertiesPanelItem: string = "";
export let ptgOptions: PtgInterface[];
export let addedPTGs: PtgInterface[] = [];
export let removePTGs: PtgInterface[] = [];
export let newName: string = "";
export let newItemName: string = "";
export let newDescription: string = "";
export let newItemDescription: string = "";
export let temporaryProductName: string = "";
export let temporaryProductId: string = "";
export let temporaryProductImageSource: string = "";
export let temporarySelectedOrganisationId: number = -2;
export let organisationOptions: ComposerOrganisationSelectInterface[] = [{organisationId: -1, name: "Privat"}];
export let productChanged: boolean;
export let temporarySelectedLogo: string = "";
export let temporarySelectedLogoFileId: string = "";

const ptgCodeSplittingCode = '|';

/* Updates the Currently Shown Item and resets the temporary Save Elements*/
export function updateCurrentPropertiesPanelItem(newId: string) {
  if (currentPropertiesPanelItem !== newId) {
    resetBufferElements();
    currentPropertiesPanelItem = newId;
  }
}

/* Adds a PTG to the temporary added PTGs or removes it from the temporary removed PTGs */
export function addPTGOnPropertiesPanel(ptgId: string) {
  const parsedPtgId = parseInt(ptgId);
  const ptg = ptgOptions.find((ptg) => ptg.id === parsedPtgId);
  if (ptg) {
    if (removePTGs.includes(ptg)) {
      removePTGs.splice(removePTGs.indexOf(ptg), 1);
    } else {
      addedPTGs.push(ptg);
    }
  }
}

/* Adds a PTG to the temporary removed PTGs or removes it from the temporary added PTGs */
export function removePTGOnPropertiesPanel(ptgId: string) {
  const parsedPtgId = parseInt(ptgId);
  const ptg = ptgOptions.find((ptg) => ptg.id === parsedPtgId);
  if (ptg) {
    if (addedPTGs.includes(ptg)) {
      addedPTGs.splice(addedPTGs.indexOf(ptg), 1);
    } else {
      removePTGs.push(ptg);
    }
  }
}

/* Updates the temporary new Text for the given Element and returns true if this was the first Change made */
export function updateText(updatedValue: string, element: string): boolean {
  let neverChangedBefore: boolean = false;

  //Checks if no Change was made before
  if (
    newName === "" &&
    newDescription === "" &&
    newItemName === "" &&
    newItemDescription === ""
  ) {
    neverChangedBefore = true;
  }

  switch (element) {
    case "name": {
      newName = updatedValue;
      break;
    }
    case "itemNameProp": {
      newItemName = updatedValue;
      break;
    }
    case "description": {
      newDescription = updatedValue;
      break;
    }
    case "itemDescription": {
      newItemDescription = updatedValue;
      break;
    }
  }

  return neverChangedBefore;
}

/* Returns whether there are temporary Changes */
export function hasChanges() {
  return (
    addedPTGs.length > 0 ||
    removePTGs.length > 0 ||
    newName !== "" ||
    newDescription !== "" ||
    newItemName !== "" ||
    newItemDescription !== "" ||
    temporaryProductName !== "" ||
    temporaryProductImageSource !== "" ||
    temporarySelectedOrganisationId !== -2 ||
    temporarySelectedLogo !== "" ||
    temporarySelectedLogoFileId !== "" ||
    productChanged ||
    temporarySelectedOrganisationId !== -2
  );
}

/* Overrides the Property Values of the selected Element with the temporary Values */
export function updateValuesofPropertyPanelElement(
  modeling: any,
  elementRegistry: any
) {
  if (currentPropertiesPanelItem === "") {
    updateProcessProperties(elementRegistry, modeling);
  } else {
    updateToolchainProperties(elementRegistry, modeling);
  }

  resetBufferElements();
}

/* Overrides the Property Values of a ToolchainItem with the temporary Values */
function updateToolchainProperties(elementRegistry: any, modeling: any) {
  const processElement = getCurrentlyActiveToolchain(elementRegistry);

  //Update Name
  if (newItemName !== "") {
    modeling.updateProperties(processElement, {
      itemName: newItemName,
    });
  }

  //Update Description
  if (newItemDescription !== "") {
    modeling.updateProperties(processElement, {
      description: newItemDescription,
    });
  }

  //Update Selected PTGs
  if (addedPTGs.length > 0 || removePTGs.length > 0) {
    const oldPTGs: number[] = parsePTGCode(
      processElement.businessObject.selectedPTGs
    ).map((id) => parseInt(id));

    const stillWorkingPTGs: PtgInterface[] = [];

    oldPTGs.forEach((ptgId) => {
      const ptg = ptgOptions.find((option) => option.id === ptgId);

      if (ptg) {
        stillWorkingPTGs.push(ptg);
      }
    });

    removePTGs.forEach((ptg) => {
      if (stillWorkingPTGs.includes(ptg)) {
        stillWorkingPTGs.splice(stillWorkingPTGs.indexOf(ptg), 1);
      }
    });

    addedPTGs.forEach((ptg) => {
      stillWorkingPTGs.push(ptg);
    });

    const newCodedPTGs = buildPTGCode(
      stillWorkingPTGs.map((ptg) => ptg.id.toString())
    );

    modeling.updateProperties(processElement, {
      selectedPTGs: newCodedPTGs,
    });
  }

  //Update selectedProduct
  if (
    productChanged
  ) {
    modeling.updateProperties(processElement, {
      productName: temporaryProductName,
      productId: temporaryProductId,
      imageDataUrl: temporaryProductImageSource,
    });
  }
}

/* Returns the currently active ToolchainItem or null */
export function getCurrentlyActiveToolchain(elementRegistry: any) {
  return elementRegistry.filter(function (element) {
    return (
      element.businessObject.$type === "composer:ToolchainItem" &&
      element.id === currentPropertiesPanelItem
    );
  })[0];
}

/* Overrides the Property Values of the Process with the temporary Values */
function updateProcessProperties(elementRegistry: any, modeling: any) {
  const processElement = elementRegistry.filter(function (element) {
    return element.businessObject.$type === "bpmn:Process";
  })[0];

  //Update Name
  if (newName !== "") {
    modeling.updateProperties(processElement, {
      name: newName,
    });
  }

  //Update Description
  if (newDescription !== "") {
    modeling.updateProperties(processElement, {
      description: newDescription,
    });
  }

  //Update Creator Organisation
  if (temporarySelectedOrganisationId !== -2) {
    modeling.updateProperties(processElement, {
      organisation: temporarySelectedOrganisationId,
    });
  }

  //Update Logo
  if (temporarySelectedLogo !== "") {
    modeling.updateProperties(processElement, {
      logoDataUrl: temporarySelectedLogo,
      logo: temporarySelectedLogoFileId
    });
  }
}

/* Parses a coded PTG String into a String Array */
export function parsePTGCode(code: string): string[] {
  const splittedItems: string[] = code.split(ptgCodeSplittingCode);
  if (splittedItems.length === 1 && splittedItems[0].length === 0) {
    splittedItems.splice(0, 1);
  }
  return splittedItems;
}

/* Updates the temporary Product with the given Values  */
export function updateSelectedProduct(
  productName: string,
  productId: string,
  imageDataUrl: string
) {
  if (currentPropertiesPanelItem !== "") {
    temporaryProductName = productName;
    temporaryProductId = productId;
    temporaryProductImageSource = imageDataUrl;
    productChanged = true;
  }
}

/* Updates the temporary Logo with the given Values  */
export function updateSelectedLogo(imageDataUrl: string, fileId: string) {
  temporarySelectedLogo = imageDataUrl;
  temporarySelectedLogoFileId = fileId;
}

/* Builds a ptgCode from a String Array */
export function buildPTGCode(items: string[]): string {
  let code = "";

  items.forEach((ptg) => {
    code += ptg;
    code += ptgCodeSplittingCode;
  });

  //Removes the last SplittingCode
  return code.substring(0, code.length - 1);
}

/* Resets the temporary Elements */
function resetBufferElements() {
  addedPTGs = [];
  removePTGs = [];
  newName = "";
  newDescription = "";
  newItemName = "";
  newItemDescription = "";
  temporaryProductName = "";
  temporaryProductId = "";
  temporaryProductImageSource = "";
  temporarySelectedOrganisationId = -2;
  productChanged = false;
  temporarySelectedLogo = "";
  temporarySelectedLogoFileId = "";
}

/* Updates the PTGOptions to the given */
export function setPTGOptions(ptgOption: PtgInterface[]) {
  ptgOptions = ptgOption;
}

/* Updates the currently selected Organisation to the given */
export function updateSelectedOrganisation(organisationId: string) {
  temporarySelectedOrganisationId = parseInt(organisationId);
}

/* Updates the Options for the OrganisationSelect to the given */
export function setOrganisationSelectOptions(
  organisations: ComposerOrganisationSelectInterface[]
) {
  organisationOptions = organisations;
  organisations.push({ organisationId: -1, name: "Privat" });
}
