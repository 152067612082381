import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Schema } from "src/app/model/compatiblity/schema-interface";
import { API_MAP } from "src/environments/api";

const SWARM_API = "/proxy/api/v0";

@Injectable({
  providedIn: "root",
})
export class SchemaService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = SWARM_API + API_MAP["compatiblity"]["SCHEMA"];
  }

  /**
   * Returns an observable of the request to get all schemas
   */
  public getAllSchemas(): Observable<Schema[]> {
    return this.http.get<Schema[]>(this.url);
  }
}
