<div class="down" *ngIf="files && files.length > 0 && fileIds.length !- 0">
  <div
    matRipple
    [matRippleColor]="ripple"
    *ngFor="let file of files"
    class="item download"
    (click)="onClick(file.url)"
  >
<!-- TODO: was fuer formate sind alels erlaubt ? ZWISCHEN allen differenzieren ? -->
  <div class="rightX z2">
    <div *ngIf="this.file.contentType === 'application/pdf'" class="image z2">
      <mat-icon mat-list-icon style="transform: scale(2); opacity: 0.0;">description</mat-icon>

        </div>
        <div *ngIf="this.file.contentType !== 'application/pdf'" class="imageZip z2">
          <mat-icon mat-list-icon style="transform: scale(2); opacity: 0.0;">description</mat-icon>

            </div>
      <div class="file-info z2">
        <div class="filename prevent-overflow">
          {{ file.filename }}
        </div>
        <div class="prevent-overflow">
          Upload: {{ getDate(file.timeStampUploaded) | date: "dd.MM.yyyy" }}
        </div>
        <div class="prevent-overflow">
          Dateigröße: {{ getFormattedFileSize(file.size) }}
        </div>
      </div>
    </div>
  </div>
</div>
