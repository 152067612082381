import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { OrganisationService } from "../../../../services/dataServices/organisation-service/organisationservice";
import { OrganisationInterface } from "../../../../model/organisations/organisation-interface";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { SidemenuServiceService } from "src/app/services/componentServices/sidemenu-service/sidemenu-service.service";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { UserService } from "src/app/services/user-service/userservice";
import { ProductService } from "src/app/services/dataServices/product-service/productService";
import { Router } from "@angular/router";
import { OrganisationDeleteInterface } from 'src/app/model/organisations/organisation-delete-interface';
import { NavbarService } from 'src/app/services/componentServices/navbar-service/navbar-service';
import { HeaderServiceService } from "src/app/services/header-service/header-service.service";

@Component({
  selector: "app-organisation-information",
  templateUrl: "./organisation-information.component.html",
  styleUrls: ["./organisation-information.component.css"],
})
export class OrganisationInformationComponent implements OnInit, OnDestroy {
  private changeOrganisationDetailsMode = false;

  private initialImageUrl: string;

  private initialPreviewImageUrl: string;

  private organisationUploadPictureFile: File;

  private organisationPreviewPictureFile: File;

  private organisationDetails: OrganisationInterface;

  @ViewChild("logoUpload") logoUpload;
  @ViewChild("previewUpload") previewUpload;

  private sidemenuDataSubscription: Subscription;
  private activeItemSubscription: Subscription;

  private readonly domainPrefix = "@";

  public organisation: OrganisationInterface;
  private isDescriptionValid: boolean = true;

  constructor(
    private organisationService: OrganisationService,
    private router: Router,
    private sidemenuService: SidemenuServiceService,
    private cd: ChangeDetectorRef,
    private dialogService: DialogService,
    private userService: UserService,
    public productService: ProductService,
    private navbarService: NavbarService,
    private titleService: Title,
    private headerService: HeaderServiceService
  ) {
    //Reroutes to the Marketplace if the User is not a member of the Organisation

    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      // TODO: beim refresh auf der Einstellungen wird man teilweise  ausgeloggt
    //  this.router.navigateByUrl("/marktplatz");
    }

    document.documentElement.style.setProperty('--scrollStatus', 'auto');

  }

  onChangeOrganisationDetailsToggle(close?: boolean): void {
    if (!close) {
      this.organisationDetails = {
        address: {
          city: this.organisation.address.city,
          postalCode: this.organisation.address.postalCode,
          street: this.organisation.address.street,
          streetAdditional: this.organisation.address.streetAdditional,
        },
        domain: this.organisation.domain,
        organisationId: this.organisation.organisationId,
        organisationName: this.organisation.organisationName,
        organisationType: this.organisation.organisationType,
        description: this.organisation.description,
        website: this.organisation.website,
        fileId: this.organisation.fileId,
        previewFileId: this.organisation.previewFileId,
      };
    }
    this.changeOrganisationDetailsMode = !this.changeOrganisationDetailsMode;
  }

  onChangeOrganisationDetailsConfirm(): void {
    this.onChangeOrganisationDetailsToggle(true);
    this.organisationService
      .putItem(this.organisationDetails)
      .then((org) => {
        this.organisationService.loadOrganisationsForUser();
        this.dialogService.openDialog(
          "Organisationsinformationen bearbeitet",
          "Die Organisationsinformationen der Organisation " +
            org.organisationName +
            " wurden erfolgreich bearbeitet."
        );
      })
      .catch((e) => {});
  }

  getOrgaType(): string {
    let result: string;
    let type = this.organisation.organisationType;
    if (type === "PRODUCT_PROVIDER") result = "Produktanbieter";
    if (type === "PRODUCT_TYPE_ASSOCIATION") result = "PTG-Fachgruppe";
    if (type === "CERTIFICATION_PROVIDER") result = "Zertifizierungsanbieter";
    if (type === "CERTIFICATION_OFFICE")
      result = "Zertifizierungsdienstleister";

    return result;
  }

  openFileManager(target): void {
    this.dialogService
      .openFileManagerDialog(
        "Datei für " + target + " auswählen",
        "Abbrechen",
        "Übernehmen",
        "TEST",
        null,
        target === 'logo' ? this.organisationDetails.fileId : this.organisationDetails.previewFileId
      )
      .subscribe((confirmed: string[]) => {
        if (confirmed && confirmed.length > 0) {
          if (target == "preview") {
            this.organisationDetails.previewFileId = confirmed[0];
          }
          if (target == "logo") {
            this.organisationDetails.fileId = confirmed[0];
          }
        }
        if(this.productService.toBeDeletedIds){
          this.productService.toBeDeletedIds.forEach((deletedFileId: string) => {
            if (deletedFileId == this.organisationDetails.previewFileId) {
              this.organisationDetails.previewFileId = null;
            }
            if (deletedFileId == this.organisationDetails.fileId) {
              this.organisationDetails.fileId = null;
            }
          });
          this.productService.toBeDeletedIds = [];
          this.cd.detectChanges();
        }
      });
  }

  imagePath(id) {
    return "/proxy/api/v0/fileservice/file/" + id;
  }

  isUserAdminOfOrganisationOrPlattformAdmin() {
    return this.userService.isUserAdminOfOrganisationOrPlattformAdmin(
      this.organisation.organisationId
    );
  }

  onPressAskForDeleteConfirm() {
    this.organisationService
      .requestOrganisationDelete(this.organisation)
      .subscribe((response: OrganisationDeleteInterface) => {
        let text =
          "Möchten Sie die Organisation wirklich unwiderruflich auflösen?";
        if(response) {
          if(response.productCount > 0) {
            text += " " + this.pluralizeDeleteSentence("Produkt", "Produkte", response.productCount);
          } else if(response.certificateCount > 0) {
            text += " " + this.pluralizeDeleteSentence(
              "Zertifikat",
              "Zertifikate",
              response.certificateCount
            );
          } else {
            text += " Dadurch werden keine Produkte oder Zertifikate gelöscht.";
          }
        } else {
          // Response was null (shouldn't happen) thus we don't know how many certifications or products the org has
          text += " Dadurch werden möglicherweise Produkte oder Zertifikate gelöscht."
        }
        this.dialogService
          .openConfirmDialog("Organisation löschen?", "Nein", "Ja", text)
          .subscribe((result) => {
            if (result) {
              this.organisationService
                .deleteItem(this.organisation)
                .then(() => {
                  this.navbarService.loadButtons();
                  this.userService.fetchCurrentUser();
                  this.router.navigate(["profil/settings"]);
                });
            }
          });
      });
  }

  private pluralizeDeleteSentence(singular: string, plural: string, count: number): string {
    const isSingular = count === 1;
    return `Dadurch ${isSingular ? 'wird' : 'werden'} ${count} ${isSingular ? singular : plural} gelöscht.`;
  }

  ngOnInit(): void {



    this.sidemenuService.productsVisible = false;
    this.sidemenuDataSubscription = this.sidemenuService.sidemenuDataObserver.subscribe(
      (x) => this.cd.detectChanges()
    );
    this.activeItemSubscription = this.organisationService.activeItemObserver.subscribe(
      (orga: OrganisationInterface) => {
        this.organisation = orga;
        this.userService.currentMemberships.forEach((memberOrga: OrganisationInterface) => {
          if(memberOrga && memberOrga.organisationId == orga.organisationId) {
            //keep fileId and previewFileId matching the ones saved in the membership-organisation
            orga.fileId = memberOrga.fileId;
            orga.previewFileId = memberOrga.previewFileId;

            this.titleService.setTitle("Informationen: " + this.organisation.organisationName + " - BIMSWARM");


          }
        })
        if (this.changeOrganisationDetailsMode) {
          this.changeOrganisationDetailsMode = !this
            .changeOrganisationDetailsMode;
        }

        if (orga && orga.organisationPicture) {
          this.initialImageUrl = this.organisationService.getOrganisationPictureUrl(
            orga.organisationId
          );
        } else {
          this.initialImageUrl = environment.defaultPictureProducts;
        }
        if (orga && orga.previewPictureId) {
          this.initialPreviewImageUrl = this.organisationService.getOrganisationPreviewPictureUrl(
            orga.organisationId
          );
        } else {
          this.initialPreviewImageUrl = environment.defaultPictureProducts;
        }

        if (!this.cd["destroyed"]) {
          this.cd.detectChanges();
        }
      }
    );
    this.organisationDetails = this.organisation;
    this.headerService.loadHeaderText(this.organisation.organisationName);
  }

  ngOnDestroy() {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');

    if (this.sidemenuDataSubscription) {
      this.sidemenuDataSubscription.unsubscribe();
    }

    if (this.activeItemSubscription) {
      this.activeItemSubscription.unsubscribe();
    }
  }

  public onDescriptionValidityChange(descriptionValid: boolean): void {
    this.isDescriptionValid = descriptionValid;
  }
}
