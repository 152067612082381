  import { Component, OnInit, Input, ChangeDetectorRef, SimpleChange } from "@angular/core";
  import { CertificationInterface } from "src/app/model/certifications/certification-interface";
  import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
  import { CertificationVariantVersionService } from "src/app/services/dataServices/cetrification-variant-version-service/certification-variant-version-service";
  import { CertificationVersionProductInfoInterface } from "src/app/model/marketplace/product-infos-of-certification-interface";
  import { Subscription } from "rxjs";
  import { CertificationRelatedProductDataSource } from "src/app/dataSource/certificationRelatedProductDataSource";
  import { SortMethod } from "src/app/model/enums/SortMethod";
  import { Router, ActivatedRoute } from "@angular/router";
  import { environment } from "src/environments/environment";
  import { MarketplaceFilterService } from 'src/app/services/componentServices/marketplace-filter-service/marketplace-filter.service';
  import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';

  const arrowUpIcon: string = "&#9650;"; // Unicpde for Arrow up
  const arrowDownIcon: string = "&#9660;"; // Unicpde for Arrow down
  const ascStr = "ASC";
  const descStr = "DESC";

  @Component({
  selector: "app-certification-detail-display-certified-products",
  templateUrl: "./certification-certified-products.component.html",
  styleUrls: ["./certification-certified-products.component.css"],
  })
  export class CertificationDetailDisplayCertifiedProductsComponent
  implements OnInit {
  @Input()
  certification: CertificationInterface;
  private selectedVersion: CertificationVariantVersionInterface;
  public certifiedProducts: CertificationVersionProductInfoInterface[] = [];

  private dataSourceRelatedProductInfoHub: Subscription;
  private dataSource: CertificationRelatedProductDataSource;
  private pageIndex: number = 0;
  private readonly pageSize: number = 10;
  public searchTerm: string = "";
  private currentSortMethod: SortMethod = SortMethod.PRODUCT_NAME_ASC;


  displayColumnsCertifiedProducts: string[] = ["Anbieter", "Produkt", "Produktvariante", "Produktversion"];


  constructor(
    private certificationVariantVersionService: CertificationVariantVersionService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private marketplace: MarketplaceFilterService,
    private organisationService: OrganisationService
  ) {}

  ngOnInit() {
    this.dataSource = new CertificationRelatedProductDataSource(
      this.certificationVariantVersionService
    );
    this.dataSourceRelatedProductInfoHub = this.dataSource
      .getAsObservable()
      .subscribe((page: CertificationVersionProductInfoInterface[]) => {
        this.certifiedProducts = this.certifiedProducts.concat(page);
        this.changeDetector.detectChanges();
      });
      
  }

  onVersionChosen(version: CertificationVariantVersionInterface): void {
    this.selectedVersion = version;
    this.resetAndReload();
  }

  onScroll(): void {
    if (this.certifiedProducts.length < this.dataSource.totalNumberOfElements) {
      this.reload();
      this.pageIndex++;
    }
  }

  private reload() {
    this.dataSource.loadProducts(
      this.selectedVersion.id,
      this.pageIndex,
      this.pageSize,
      [],
      [],
      [],
      [],
      [],
      this.searchTerm,
      this.currentSortMethod
    );
  }

  ngOnDestroy() {
    if (this.dataSourceRelatedProductInfoHub != null) {
      this.dataSourceRelatedProductInfoHub.unsubscribe();
    }
  }

  toggleSort(newSortMethodAsString: string): void {
    if (this.currentSortMethod == newSortMethodAsString) {
      this.currentSortMethod = this.getOppositeSortDirection(
        newSortMethodAsString
      );
    } else {
      this.currentSortMethod = SortMethod[newSortMethodAsString];
    }
    this.onVersionChosen(this.selectedVersion);
  }

  navigateToOrganisationDetailPage(organisationName: string) : void {
  this.router.navigateByUrl( "/privacy");
    this.organisationService.getOrganisationByDomainOrName(organisationName)
    .then((organisation) => {

        this.router.navigateByUrl("/marktplatz?tab=2&organisationId=" + organisation.organisationId);
    });
  }

  navigateToProductDetailPage(productId: number): void {
    this.router.navigateByUrl('/privacy', {skipLocationChange: true}).then(() => {

        this.router.navigateByUrl( "/marktplatz?tab=0&productId=" + productId);
  });




  }

  private filterSearchTerm(searchTerm: string): void {
    this.searchTerm = searchTerm.replace(/\s/g, "");
  }

  public resetAndReload(): void {
    this.dataSource.totalNumberOfElements = 1;
    this.pageIndex = 0;
    this.certifiedProducts = [];
    this.onScroll();
  }

  private getOppositeSortDirection(sortMethodAsString: string): SortMethod {
    let newSortString: string;
    if (sortMethodAsString.endsWith(ascStr)) {
      newSortString = sortMethodAsString.slice(0, -3) + descStr;
    } else if (sortMethodAsString.endsWith(descStr)) {
      newSortString = sortMethodAsString.slice(0, -3) + ascStr;
    } else {
      throw new Error("SortMethod not allowed");
    }
    return SortMethod[newSortString];
  }

  private checkForSortIcon(startsWithTerm: string): string {
    return this.currentSortMethod.endsWith(ascStr) &&
      this.currentSortMethod.startsWith(startsWithTerm)
      ? arrowUpIcon
      : arrowDownIcon;
  }

  // NOT USED RIGHT NOW
  showAllProducts(certificatioinVariantVersionId: number): void {
    this.certificationVariantVersionService
      .getRelatedProductInfos(certificatioinVariantVersionId)
      .subscribe(
        (allProductInfos: CertificationVersionProductInfoInterface[]) => {
          this.certifiedProducts = allProductInfos;
          this.changeDetector.detectChanges();
        }
      );
  }
  }
