<h1 class="headline">Meine Merkliste</h1>
<div class="product-list">
    <ng-container *ngFor="let product of productlistService.getProductList().getValue()">
        <div class="product-card">
            <!-- (click)="showProductDetail(product.productId)" -->
            <!--TODO: ProductPicture laden-->
            <button class="fa fa-minus btn_red" (click)="deleteProductFromList(product.id, product.displayType)"></button>
            <div class="imageContainer" (click)="showDetailPage(product.id, product.displayType)">
                <ng-container>
                <app-secure-image
                class="productImage"
                *ngIf="product.fileId != null"
                [productList]="true"
                [displayItem]="true"
                [fileId]="product.fileId"
                [src]="product.pictureUrl"
                >
              </app-secure-image>
              <img *ngIf="product.fileId == null" [src]="getProductPreviewPicture(product)" alt="Produktbild"/>
              </ng-container>
            </div>

            <!--div *ngIf="product.hasActiveMalfunctions"
                style="width: 2em; height: 2em; z-index: 1; left: 0;top: 0; position: absolute; margin-bottom: 0;"
                class="warning" matTooltip="Für dieses Produkt liegt eine Störung vor." [matTooltipPosition]="'left'"
                matTooltipClass="tooltip-warning" (click)="routeToMalfunctionOverview(product.productId)"></div-->

            <div class=" product-name" (click)="showDetailPage(product.id, product.displayType)">
                <p class="name-wrapping " matTooltip={{product.bookMarkName}} matTooltipClass="header-tooltip ">
                    {{product.bookMarkName}}</p>
            </div>
            <div class="organisation-name" (click)="showDetailPage(product.id, product.displayType)">
                <p class="name-wrapping " matTooltip={{product.organisationName}} matTooltipClass="header-tooltip ">
                    {{product.organisationName}}</p>
                <app-star *ngIf="product.displayType == 1"
                [rating]="product.rating"
                [ratingCount]="product.ratingCount"
                (click)="showDetailPage(product.id)"
                >
                </app-star>
            </div>
        </div>
    </ng-container>


</div>
