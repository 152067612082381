import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-navbar-hexagon',
  templateUrl: './navbar-hexagon.component.html',
  styleUrls: ['./navbar-hexagon.component.css']
})
export class NavbarHexagonComponent implements OnInit, OnChanges {
  private style;

  constructor(private _sanitizer: DomSanitizer) { }
  
  @Input() pictureImage: string;
  
  ngOnInit() {
    this.style = { 'background-image': this.pictureImage };
  }

  ngOnChanges(changes: SimpleChanges) {
    const value = changes.pictureImage;
    this.pictureImage = value.currentValue;
  }


  getBackground(image) {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }

}
