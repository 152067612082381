import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { element } from "protractor";
import { PtgInterface } from 'src/app/model/ptgs/ptg-interface';
import { PtgService } from 'src/app/services/dataServices/ptg-service/ptgService';
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { environment } from 'src/environments/environment';
import { buildPTGCode, getCurrentlyActiveToolchain } from "../custom/composerChangeBuffer";
import { addNewFileId, productImageDataUrls } from "../util/preloadedAssets";

@Component({
  selector: "app-dialog-edit-meta-data",
  templateUrl: "./dialog-edit-meta-data.component.html",
  styleUrls: ["./dialog-edit-meta-data.component.css"],
})
export class DialogEditMetaDataComponent implements OnInit {
  public id: string;
  public name: string;
  public description: string;
  public options: PtgInterface[] = [];
  public selectedPTGs: PtgInterface[] = [];
  public productName: string;
  public productFileId: string;
  public productId: string;

  public currentlySelectedPTG: FormControl = new FormControl();

  private dialogService: DialogService;
  private modeling: any;
  private element: any;

  public changesMade: boolean;

  public imageBackupSource: string = environment.defaultPictureProducts;

  constructor(
    public dialogRef: MatDialogRef<DialogEditMetaDataComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    private ptgService: PtgService,
  ) {
    this.dialogService = dialogData.dialogService;
    this.modeling = dialogData.modeling;
    this.id = dialogData.id;
    this.name = dialogData.name;
    this.description = dialogData.description;
    this.options = dialogData.options;
    this.productName = dialogData.productName;
    this.productId = dialogData.productId;
    this.productFileId = dialogData.productFileId !== "noFileId" ? dialogData.productFileId : null;
    this.element = dialogData.element;

    this.ptgService.getAllPtgs().then(success => {
      if(success) {
        this.options = this.ptgService.collection;
        this.dialogData.selectedPTGs.forEach(ptgId => {
          const option = this.options.find(option => option.id === ptgId);
          if(option){
            this.selectedPTGs.push(option);
          }
        });

        this.selectedPTGs.forEach(selected => {
          this.options.splice(this.options.indexOf(selected), 1);
        })
        this.updateAddingPTGDefaultValue();
      }
    })
  }

  ngOnInit() {
  }

  /* Closes the Dialog */
  public cancel() {
    this.dialogRef.close();
  }

  /* Adds the current Value of the Select to the selectedPTGs and removes it from the Options */
  public addToSelected() {
    const option = this.currentlySelectedPTG.value;

    if (option !== "") {
      this.options.splice(this.options.indexOf(option), 1);
      this.selectedPTGs.push(option);
      this.updateAddingPTGDefaultValue();
    }
    this.changesMade = true;
  }

  /* Removes the selected PTG from the selectedPTGs and adds it to the Options */
  public removeFromSelected(option: PtgInterface) {
    this.selectedPTGs.splice(this.selectedPTGs.indexOf(option), 1);
    this.updateAddingPTGDefaultValue();
    this.changesMade = true;
  }

  private updateAddingPTGDefaultValue() {
    if (this.options && this.options.length > 0) {
      this.currentlySelectedPTG.setValue(this.options[0]);
    } else {
      this.currentlySelectedPTG.setValue("");
    }
  }

  /* Updates the Dialog to select a Product */
  public openProductSelection() {
    this.dialogService
      .openAddProductToToolchainItem(this.productName, this.description)
      .subscribe((productInformation) => {
        if (productInformation) {
          this.productName = productInformation.productName;
          this.productId = productInformation.productId;
          this.productFileId = productInformation.productFileId;
          this.changesMade = true;
        }
      });
  }

  /* Saves the Changes and closes the Dialog */
  public save() {
    if (
      !productImageDataUrls.some(
        (element) => element.fileId === this.productFileId
      )
    ) {      
      addNewFileId(
        this.productFileId,
        this.httpClient,
        this.domSanitizer
      ).subscribe((success) => {
        if (success) {
          this.updateProperties(
            productImageDataUrls.find(
              (element) => element.fileId === this.productFileId
            ).dataUrl
          );
        }
      });
    } else {
      this.updateProperties(
        productImageDataUrls.find(
          (element) => element.fileId === this.productFileId
        ).dataUrl
      );
    }
    
    this.dialogRef.close();
  }

  private updateProperties(imageDataUrl: string) {
    this.modeling.updateProperties(this.element, {
      itemName: this.name,
      description: this.description,
      productName: this.productName,
      productId: this.productId,
      imageDataUrl: imageDataUrl,
      selectedPTGs: buildPTGCode(this.selectedPTGs.map(ptg => ptg.id.toString())),
    });
  }

  /* Called for Changes on Name and Description */
  public change(){
    if(!this.changesMade){
      this.changesMade = true;
    }
  }
}
