<div class="wrapper">
  <app-infinite-scroll (scrolled)="onScroll()">
    <div class="search-panel">
      <div class="button-search"></div>
      <mat-form-field floatLabel="never">
        <input matInput [formControl]="ptgSearch" placeholder="Suchen..." />
      </mat-form-field>
    </div>
    <div class="table-container">
      <div *ngIf="mode == 'ptgs'" class="table">
        <div
          matSort
          matSortDisableClear
          (matSortChange)="sortData($event)"
          class="header"
        >
          <div mat-sort-header="ALPHABETICAL">
            <p>Produkttyp-Name</p>
          </div>
          <div>
            <p>Orga-Name</p>
          </div>
          <div>
            <p>Versionsnummer</p>
          </div>
          <div>
            <p>#Merkmale</p>
          </div>
        </div>
        <div class="content">
          <ng-container
            *ngFor="let attributeGroup of ptgAttributeGroups; let i = index"
          >
            <div
              class="groupRows"
              [ngClass]="i % 2 ? 'evenGroup' : 'oddGroup'"
              [ngStyle]="
                attributeGroup.attributes.length == 0 &&
                !attributeGroup.ptgVersionDescription
                  ? { cursor: 'default', 'pointer-events': 'none' }
                  : {}
              "
              (click)="
                showAttributes(
                  attributeGroup.ptgName,
                  attributeGroup.ptgVersionNumber
                )
              "
            >
              <div>
                <p>
                  <b>{{ attributeGroup.ptgName }}</b>
                </p>
              </div>
              <div>
                <p>
                  <b>{{ attributeGroup.orgaName }}</b>
                </p>
              </div>
              <div>
                <p>
                  <b>{{ attributeGroup.ptgVersionNumber }}</b>
                </p>
              </div>
              <div>
                <p>
                  <b>{{ attributeGroup.attributes.length }}</b>
                </p>
              </div>
              <div>
                <div
                  class="triangle"
                  [ngClass]="{ turn: attributeGroup.hidden }"
                ></div>
              </div>
            </div>
            <div [hidden]="attributeGroup.hidden">
              <div *ngIf="attributeGroup.ptgVersionDescription">
                <p
                  class="description"
                  [innerHTML]="attributeGroup.ptgVersionDescription"
                ></p>
              </div>
              <div class="test" *ngIf="attributeGroup.attributes.length > 0">
                <div class="attrHeader">
                  <div></div>
                  <div><p>Merkmals-Name</p></div>
                  <div><p>Datentyp</p></div>
                  <div><p>Optional</p></div>
                  <div></div>
                </div>
                <app-attribute-table
                  [attributes]="attributeGroup.attributes"
                  [showAll]="false"
                  [showOptional]="true"
                  [showCheckbox]="false"
                ></app-attribute-table>

                <div class="attrFooter"></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="mode == 'all'" class="table">
        <div class="content">
          <div class="attrHeaderAll">
            <div></div>
            <div><p>Merkmals-Name</p></div>
            <div><p>Datentyp</p></div>
            <div></div>
            <div></div>
          </div>
          <app-attribute-table
            [attributes]="allAttributes"
            [showAll]="false"
            [showOptional]="false"
            [showCheckbox]="false"
          ></app-attribute-table>
        </div>
        <div class="attrFooter"></div>
      </div>
    </div>
  </app-infinite-scroll>
</div>
