import { Component, OnInit, OnDestroy } from '@angular/core';
import  {MatDividerModule } from '@angular/material/divider';
import { HeaderServiceService } from "src/app/services/header-service/header-service.service";
import {DialogService} from "../../services/dialog-service/dialog-service.service";


@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.scss']
})
export class AgbComponent implements OnInit, OnDestroy {

  constructor(  private headerService: HeaderServiceService, private dialogService: DialogService) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');


  }

  showPrivacy() {
    this.dialogService.openPrivacyDialogInform('Schließen');
  }

  ngOnInit(): void {
    this.headerService.loadHeaderText("AGB");
    document.body.scrollTop=0;
  }

  ngOnDestroy(): void {

    document.documentElement.style.setProperty('--scrollStatus', 'hidden');

  }

}
