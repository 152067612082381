import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges} from '@angular/core';
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-certification-detail-display-type-overview',
  templateUrl: './certification-detail-display-type-overview.component.html',
  styleUrls: ['./certification-detail-display-type-overview.component.css']
})
export class CertificationDetailDisplayTypeOverviewComponent implements OnInit {
  @Input() certificationType : string = "";

  public translatedCertificationType: string = "";
  public newPicturePath: string = "";
  constructor(private cd : ChangeDetectorRef) { }
  ngOnInit() {
    this.setCorrectCertificationType();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setCorrectCertificationType();
    this.cd.detectChanges();
  }


  /**
   *  puts the url to the picture path
   */
  public setCorrectCertificationType() : void
  { 
    switch(this.certificationType)
    {
      case "PTG_CERT" : this.translatedCertificationType = "Produkttypen-Zertifikat";
                        this.newPicturePath = environment.certificationPtgIcon;
      break;
      case "ATTRIBUTE_CERT" : this.translatedCertificationType = "Merkmal-Zertifikat";
                              this.newPicturePath = environment.certificationAttributeIcon;
      break;
      case "TOOLCHAIN_CERT" : this.translatedCertificationType = "Toolchain-Zertifikat";
                              this.newPicturePath = environment.certificationToolchain;
      break;
    }
  }
}
