import { AttributeInterface } from "../model/attributes/attribute-interface";
import { Observable, BehaviorSubject, of, Subscription } from "rxjs";

import { catchError } from "rxjs/operators";
import { SortMethod } from "../model/enums/SortMethod";
import { AttributeService } from "../services/dataServices/attribute-service/attributeService";

export class AttributeDataSource {
  private attributesSubject: BehaviorSubject<
    AttributeInterface[]
  > = new BehaviorSubject<AttributeInterface[]>([]);
  private totalNumberOfElementsSubject: BehaviorSubject<
    number
  > = new BehaviorSubject<number>(0);
    private currentRequest: Subscription;
    
    public totalNumberOfElements: number;

  constructor(private attributeService: AttributeService) {}

  loadAttributes(
    pageIndex: number = 0,
    pageSize: number = 5,
    searchTerm = "",
    sortMethod = SortMethod.ID
  ): void {
    this.attributeService
      .getAttributeSlice(pageIndex, pageSize, searchTerm, sortMethod)
      .pipe(catchError(() => of([])))
      .subscribe((attributePage) => {
        const attributes: AttributeInterface[] = attributePage["attributes"];
        this.totalNumberOfElementsSubject.next(
          attributePage["totalNumberOfAttributes"]
        );
        this.attributesSubject.next(attributes);
        this.totalNumberOfElements = attributePage["totalNumberOfAttributes"];
      });
  }

  getObservable(): Observable<AttributeInterface[]> {
    return this.attributesSubject.asObservable();
  }

  getTotalNumberOfElementsObservable(): Observable<number> {
    return this.totalNumberOfElementsSubject.asObservable();
  }
}
