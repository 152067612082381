export enum SortMethod {
  ALPHABETICAL_ASC = "ALPHABETICAL_ASC",
  ALPHABETICAL_DESC = "ALPHABETICAL_DESC",
  ID = "ID",
  ORGANISATION = "ORGANISATION",
  DATATYPE_ASC = "DATATYPE_ASC",
  DATATYPE_DESC = "DATATYPE_DESC",
  DATE_ASC = "DATE_ASC",
  DATE_DESC = "DATE_DESC",

  // F�r Sortierung im RelatedProducsOfCertificationService
  PRODUCT_NAME_ASC = "PRODUCT_NAME_ASC",
  PRODUCT_NAME_DESC = "PRODUCT_NAME_DESC",
  PRODUCT_VARIANT_NAME_ASC = "PRODUCT_VARIANT_NAME_ASC",
  PRODUCT_VARIANT_NAME_DESC = "PRODUCT_VARIANT_NAME_DESC",
  PRODUCT_VARIANT_VERSION_NAME_ASC = "PRODUCT_VARIANT_VERSION_NAME_ASC",
  PRODUCT_VARIANT_VERSION_NAME_DESC = "PRODUCT_VARIANT_VERSION_NAME_DESC",
  ORGANISATION_NAME_ASC = "ORGANISATION_NAME_ASC",
  ORGANISATION_NAME_DESC = "ORGANISATION_NAME_DESC",
}
