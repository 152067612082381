   <div class="carousel" >
    <ng-container *ngFor="let slide of slides; let i = index">
      <!--app-secure-image *ngIf="this.organisation && i == currentSlide" class='slide' @carouselAnimation [src]="pictureURL" [fileId]="slide" [displayItem]="falsef"></app-secure-image-->
      <app-secure-image *ngIf="this.item && i == currentSlide" class='slide' @carouselAnimation [src]="pictureURL" [fileId]="slide" [displayItem]="false"></app-secure-image>
    </ng-container>
    <app-secure-image *ngIf="this.organisation" [src]="pictureURL" [fileId]="this.organisation.fileId" [organisation]="true"></app-secure-image>

    <!-- controls -->
    <div class="wrapper" *ngIf="this.organisation == null && this.category != 'organisation'">
      <button  *ngIf="this.slides && (this.slides.length > 1 )" mat-icon-button (click)="onPreviousClick()">
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button *ngIf="this.slides && (this.slides.length > 1 )" mat-icon-button (click)="onNextClick()">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>
