import { Component, EventEmitter, OnInit, Output } from "@angular/core";

import { Router } from "@angular/router";
import { NavbarService } from "src/app/services/componentServices/navbar-service/navbar-service";
import { MarketplaceFilterService } from "src/app/services/componentServices/marketplace-filter-service/marketplace-filter.service";
import { DataType } from "src/app/model/data-type.enum";

@Component({
  selector: "app-marketplace-category-switch",
  templateUrl: "./marketplace-category-switch.component.html",
  styleUrls: ["./marketplace-category-switch.component.css"],
})
export class MarketplaceCategorySwitchComponent implements OnInit {
  constructor(public sideFilter: MarketplaceFilterService) {}
  @Output() marketScreenStateEmitter: EventEmitter<DataType> = new EventEmitter<
    DataType
  >();

  ngOnInit() {}

  onProductSwitch() {
    this.marketScreenStateEmitter.emit(this.sideFilter.changeToProductScreen());
  }

  onOrgaSwitch() {
    this.marketScreenStateEmitter.emit(
      this.sideFilter.changeToOrganisationScreen()
    );
  }

  onCertificationSwitch() {
    this.marketScreenStateEmitter.emit(
      this.sideFilter.changeToCertificationScreen()
    );
  }

  onToolchainSwitch() {
    this.marketScreenStateEmitter.emit(
      this.sideFilter.changeToToolchainScreen()
    );
  }
}
