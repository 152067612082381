import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ReviewInterface } from "src/app/model/reviews/review-interface";
import { UserService } from "src/app/services/user-service/userservice";
import { CHARACTER_DISPLAY_LIMIT } from "src/environments/constants";
import { ReviewService } from "src/app/services/review-service/review.service";
import { UserInterface } from "src/app/model/user/user-interface";

@Component({
  selector: "app-comment-item",
  templateUrl: "./comment-item.component.html",
  styleUrls: [
    "./comment-item.component.css",
    "../../../product-detail-display/product-detail-display.css",
  ],
})
export class CommentItemComponent implements OnInit {
  @Input()
  review: ReviewInterface;
  @Input()
  orgaAdmin: boolean;
  @Input()
  loggedIn: UserInterface;
  @Output() commentAddEmitter = new EventEmitter<ReviewInterface>();
  @Output() commentDeleteEmitter = new EventEmitter<ReviewInterface>();

  showWriteComment: boolean = false;
  isExpanded: boolean = false;

  @Output()
  public upvote: EventEmitter<number> = new EventEmitter<number>();
  //-----------------------------------------------------------------------

  public get characterLimit(): number {
    if (this.isExpanded) return 0;
    return CHARACTER_DISPLAY_LIMIT;
  }

  constructor(
    private userService: UserService,
    private reviewService: ReviewService
  ) {}

  ngOnInit() {}
  //-----------------------------------------------------------------------
  public get userId(): number {
    return this.userService.currentUser.userId;
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  deleteComment(reviewId: number, commentId: number) {
    let newReview: ReviewInterface;
    this.reviewService
      .deleteComment(reviewId, commentId)
      .subscribe((review: ReviewInterface) => {
        newReview = review;
        this.commentDeleteEmitter.emit(newReview);
      });
  }

  handleCommentAdd(review: ReviewInterface) {
    this.commentAddEmitter.emit(review);
  }
}
