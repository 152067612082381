import { matRaisedButtonCSS } from "../../../model-editor/custom/factory/composerSaveButton";

/* Custom Modul for the Properties Panel */
export function composerDownloadDataButtonForViewer(options: {
  id: string;
  visible: boolean;
}) {
  /* Custom Event that gets triggered when the Download Button gets clicked - Handled by ComposerChangeHandlerForViewer */
  const customEvent =
    "{const customEvent = document.createEvent('HTMLEvents');" +
    "customEvent.initEvent('downloadData', true, true);" +
    "customEvent.eventName = 'downloadData';" +
    "event.target.dispatchEvent(customEvent);}";

  const button = options.visible
    ? '<button class="btn_green" style="' +
      matRaisedButtonCSS +
      ' margin-top: 0.5vh; margin-bottom: 0.2vh; width: 80%;" onclick="' +
      customEvent +
      '">Herunterladen</button>'
    : "";

  return {
    id: options.id,
    html:
      '<div id="' +
      options.id +
      '"style="display: flex; flex-direction: column; overflow-y: auto; margin-bottom: 3vh;">' +
      button +
      "</div>",
  };
}
