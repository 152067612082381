<!--  This component resembles the small panel to filter and search through the side-menu. It contains a "Search-Button",
  a "Filter-Button" and a "Text-Input-Field"-->
<!----------------------------------------------------->
<div style="max-width: 21vw;">
  <div class="search-input"></div>
  <div class="hexagonWrapper">
    <hexagon
      [outerColor]="'white'"
      [middleColor]="'lightgreen'"
    ></hexagon>
  </div>
</div>
<!----------------------------------------------------->
<rounded-button
  *ngIf="optionsFiltered && filterMode"
  (press)="resetFilter()"
  [label]="'zurücksetzen'"
  [bold]="true"
></rounded-button>

<!--
    Clicking on the "Filter-Button" will open the "Filter-Menu", which will allow to filter the products, use-cases, etc. later on.
-->
<app-filter-panel
  *ngIf="filterMode"
  [composerItem]="false"
  (optionsFiltered)="setFiltered()"
></app-filter-panel>
