import {Pipe, PipeTransform} from '@angular/core';
import { ProductInterface } from '../model/products/product-interface';


@Pipe({name: 'filterItself'})
export class FilterItself implements PipeTransform {
  transform(products: ProductInterface[], itselfId: number): ProductInterface[] {

    if(products){
      return products.filter(product => product.id !== itselfId);
    }
  }
}
