export class ComposerToolchainTemplateOverview {
    toolchainId: number;
    userId: number;
    name: string;
    description: string;
    lastEdit: Date;
    lastEditor: number;
    active: boolean;
    previousVersion: number;
    valid: boolean;
}
