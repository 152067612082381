import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductInterface } from 'src/app/model/products/product-interface';
import { ProductVariantInterface } from 'src/app/model/products/product-variant-interface';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { ProductVariantService } from "src/app/services/dataServices/product-variant-service/product-variant.service";
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { UserService } from 'src/app/services/user-service/userservice';
import { UtilService } from 'src/app/services/util-service/utilService';

@Component({
  selector: "app-edit-product-variant-screen",
  templateUrl: "./edit-product-variant-screen.component.html",
  styleUrls: ["./edit-product-variant-screen.component.css"],
})
export class EditProductVariantScreenComponent implements OnInit, OnDestroy{
  // TODO: To be integrated into product service!!!
  productEdit: ProductInterface;
  variantEdit: ProductVariantInterface;
  variantEditName: string = "";
  variantEditComment: string = "";
  variantEditDescription: string = "";
  private isDescriptionValid: boolean = true;

  constructor(
    public productService: ProductService,
    public router: Router,
    private route: ActivatedRoute,
    public organisationService: OrganisationService,
    private utilService: UtilService,
    private dialogService: DialogService,
    public productVariantService: ProductVariantService,
    private userService: UserService,
    private titleService: Title
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    document.documentElement.style.setProperty('--scrollStatus', 'auto');

    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnDestroy(): void {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');

  }

  editVariant(): void {
    if (this.utilService.isStringNullOrEmpty(this.variantEditName)) {
      this.dialogService.openDialog(
        "Variante bearbeiten fehlgeschlagen",
        "Bitte geben Sie einen Variantennamen ein!"
      );
      return;
    }

    if (!UtilService.areStringsValid(this.variantEditName, this.variantEditDescription)) {
      this.dialogService.openDialog(
        "Variante bearbeiten fehlgeschlagen",
        "Der Name oder die Beschreibung sind zu lang."
      );
      return;
    }

    let variant: ProductVariantInterface = this.productEdit.productVariants.find(
      (variant) => variant.id === this.variantEdit.id
    );
    variant.name = this.variantEditName;
    variant.comment = this.variantEditComment;
    variant.description = this.variantEditDescription;
    this.productVariantService.ITEM_MAP[":organisationId"] = String(
      this.productService.activeItem.company
    );

    this.productVariantService
      .putItem(variant)
      .then((v) => {
        let url = this.router.url.toString().replace("/edit", "");
        this.router.navigateByUrl(url);
        this.dialogService.openDialog(
          "Variant bearbeitet",
          "Die Variante " + v.name + " wurde erfolgreich bearbeitet."
        );
      })
      .catch((e) => {});
  }

  cancelVariantEdit(): void {
    var url = this.router.url.toString().replace("/edit", "");
    this.router.navigate([url]);
  }

  ngOnInit(): void {
    this.productEdit = this.productService.activeItem;
    this.titleService.setTitle(this.productService.activeItem.name + ": Variante bearbeiten - BIMSWARM");
    this.variantEdit = this.productEdit.productVariants.find(
      (variant) =>
        variant.id === parseInt(this.route.snapshot.paramMap.get("variantId"))
    );
    this.variantEditName = this.variantEdit.name;
    this.variantEditDescription = this.variantEdit.description;
    this.variantEditComment = this.variantEdit.comment;
  }

  public onDescriptionValidityChange(descriptionValid: boolean): void {
    this.isDescriptionValid = descriptionValid;
  }
}
