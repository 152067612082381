import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-overview-example-dialog',
  styleUrls: ['./dialog.css'],
  template: `
    <h1 mat-dialog-title>{{data.title}} </h1>
      <div mat-dialog-content>
        <p class="dialog-text">{{data.message}}</p>
      </div>
      <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()" class="btn_green" cdkFocusInitial>Ok</button>
      </div>
  `,
})
export class DialogOverviewExampleDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
