<ng-container *ngIf="!loggedIn">
  <button mat-button  (click)="warenkorb()" style="margin-right: 1em">
    Warenkorb
  </button>
    <button *ngIf="!router.url.includes('/resetPassword')" mat-button (click)="listing()" style="margin-right: 1em">
      Listung anfragen
    </button>
    <button *ngIf="!router.url.includes('/resetPassword')" mat-button (click)="register()" style="margin-right: 1em">
      Registrieren
    </button>
    <button mat-button (click)="login()">Anmelden</button>
  </ng-container>
  <ng-container *ngIf="loggedIn && (activeProvider$ | async)">
    <button mat-button [matMenuTriggerFor]="menu">
        <!--Non-displayed mat-card is a workaround: Style-class "mat-card-avatar" is not loaded if left out-->
        <mat-card style="display: none"></mat-card>
      <img
        mat-card-avatar
        class="example-header-image"
        [src]="(activeProvider$ | async).img"
        style="margin-right: 0.5rem"
      />
      {{ (activeProvider$ | async).name }}
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <div>
        <div class="orgas">
          <ng-container *ngFor="let provider of (providers$ | async)">
            <div
              matRipple
              (click)="headerService.setActiveProvider(provider)"
              class="orga"
            >
              <img mat-card-avatar class="example-header-image" [src]="provider.img" />
              <span>
                {{ provider.name }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
      <button mat-menu-item (click)="openMyProducts()">
        <mat-icon>bookmark</mat-icon>
        Merkliste
      </button>
      <button *ngIf="this.userService.currentUser.hasComposerAccess" mat-menu-item (click)="openComposer()">
        <mat-icon>trip_origin</mat-icon>
        Composer
      </button>
      <button *ngIf="this.userService.currentUser.hasComposerAccess" mat-menu-item (click)="openGaiaX()">
        <mat-icon>trip_origin</mat-icon>
        GAIA-X Selbstbeschribung
      </button>
      <button mat-menu-item (click)="headerService.openSettings(activeProvider.organisationId)">
        <mat-icon>settings</mat-icon>
        Einstellungen
      </button>
      <button mat-menu-item (click)="warenkorb()">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/></svg>
        Warenkorb
      </button>
          <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        Abmelden
      </button>
    </mat-menu>
  </ng-container>
