/* Custom Modul for the Properties Panel */
export function ComposerAssociatedProductForViewer(options: {
    id: string;
    productId: string;
    productName: string;
    imageDataSrc: string;
  }) {
    const label = "Produkt";

    const imageHTML = options.imageDataSrc !== "" ? '<img src="' + options.imageDataSrc + '" style="width: 60%; height: 40%;">' : '';

    return {
      id: options.id,
      html:
        '<div style="display: flex; flex-direction: column;"><label data-value="label" ' +
        'style="margin-bottom: 0"></label><label style="color: black;">' + 'ID: ' + options.productId + '<br>' + 'Name: ' +  options.productName + '</label></div><div>' + imageHTML +
        '</div>',
      get: function (element, node) {
        return {
            label: label
        };
      },
    };
  }
