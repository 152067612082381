import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { PtgInterface } from "../model/ptgs/ptg-interface";

import { Injectable } from "@angular/core";
import { Subscribable } from "rxjs";
import { UserService } from "../services/user-service/userservice";
import { PtgService } from "../services/dataServices/ptg-service/ptgService";
@Injectable()
export class PtgEditResolver implements Resolve<any> {
  constructor(
    private ptgService: PtgService,
    private userservice: UserService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    let ptg = parseInt(route.paramMap.get("ptgId"));
    if (ptg) {
      this.ptgService.setActiveItem(ptg);
    }
    return;
  }
}
