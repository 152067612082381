import { Injectable, Attribute } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { AttributeInterface } from "../../../model/attributes/attribute-interface";
import { Subscribable, Observable, of } from "rxjs";
import { UserService } from "../../user-service/userservice";
import { OrganisationService } from "../organisation-service/organisationservice";
import { SortMethod } from "src/app/model/enums/SortMethod";
import { PtgInterface } from "../../../model/ptgs/ptg-interface";
import { BaseService } from "../../base.service";
import { ProductDtoConverterServiceService } from "../../product-dto-converter-service/product-dto-converter-service.service";
import { API_BASE_PRODUCTSERVICE, API_MAP } from 'src/environments/api';

const SWARM_API = API_BASE_PRODUCTSERVICE;
const ALL_ATTRIBUTES_URI = "/attributes";
// um ein einzelnes attribute zu bekommen muss die URI für alle attribute um die id des ausgewählten attributes concatineirt werden
const SINGLE_ATTRIBUTE_URI = ALL_ATTRIBUTES_URI + "/";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable()
export class AttributeService extends BaseService<AttributeInterface> {

  protected toID(attribute: AttributeInterface | number): number {
    if (typeof attribute === "number") return attribute;
    if (attribute.id) return attribute.id;
    return -1;
  }

  constructor(
    protected http: HttpClient,
    private userService: UserService,
    protected dtoConverter: ProductDtoConverterServiceService
  ) {
    super(http, dtoConverter);
    //set the swarm API
    this.SWARM_API_BASE = SWARM_API;

    this.ITEM_MAP = this.userService.ACTIVE_MAP;


    // Setting coded URL path for the API of this service
    this.SWARM_API_MAP = API_MAP["attributes"]
    this.SERVICE_FLAG = ":attributeId"
    //-----------------------

    this.getAllAttributes().subscribe((attributes)=>{
      this.collection.concat(attributes)
    });
  }

  setActiveAttribute(attribute: AttributeInterface) {
    this.userService.ACTIVE_MAP[":attributeId"] = String(attribute.id);
    this.setActiveItem(attribute);
  }

  getAllAttributesWithActiveProducts(): Subscribable<AttributeInterface[]> {

    return this.http.get<AttributeInterface[]>(this.SWARM_API_BASE + this.SWARM_API_MAP["ACTIVE"]);
  }

  
  save(feature: AttributeInterface) : Promise<AttributeInterface> {
   return this.putItem(feature) ;
  } 

  getAllAttributes(): Subscribable<AttributeInterface[]> {
    return this.http.get<AttributeInterface[]>(this.SWARM_API_BASE + this.SWARM_API_MAP["ALL"]);
  }

 
  getAttributeSlice(
    pageIndex: number,
    pageSize: number,
    searchText: string,
    sortMethod: SortMethod
  ): Observable<AttributeInterface[]> {
    let dto = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchText.trim(),
      sortMethod: sortMethod,
    };
    const json = JSON.stringify(dto);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    if (!this.userService.isAuthorized("system", "nutzerrechte")) {

      return;
    }

    return this.http.post<AttributeInterface[]>(
      SWARM_API + this.SWARM_API_MAP["FILTER"],
      json,
      httpOptions
    );
  }
}
