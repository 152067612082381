import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ComposerProductFileInterface } from "src/app/model/composer/composer-product-file-interface";
import { ComposerService } from "src/app/services/composer-service/composer.service";
import { UserService } from "src/app/services/user-service/userservice";
import { ModelEditorComponent } from "../model-editor/model-editor.component";
import { FileSaverService } from 'ngx-filesaver';
import { HttpClient } from '@angular/common/http';
import { CamundaRestService } from "../../camunda/camunda-rest/camunda-rest.service";

@Component({
  selector: "app-composer-editor-screen",
  templateUrl: "./composer-editor-screen.component.html",
  styleUrls: ["./composer-editor-screen.component.css"],
})
export class ComposerEditorScreenComponent implements OnInit {
  public blankLayout: string =
    '<?xml version="1.0" encoding="UTF-8"?><bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:camunda="http://camunda.org/schema/1.0/bpmn"  xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:composer="http://composer.bimswarm.de/definition" id="Definitions_1" targetNamespace="http://bpmn.io/schema/bpmn"><bpmn:process id="PNeuerProzess" name="Neue Toolchain" isExecutable="true"></bpmn:process><bpmndi:BPMNDiagram id="BPMNDiagram_1"><bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="PNeuerProzess"></bpmndi:BPMNPlane></bpmndi:BPMNDiagram></bpmn:definitions>';

  public xml: string;
  public xmlFileIds: ComposerProductFileInterface[];

  private paramSubscription: Subscription;
  private xml_original: string;

  @ViewChild(ModelEditorComponent)
  private modelEditorComponent: ModelEditorComponent;

  public validToolchain: boolean;
  public text: string;
  public saved_text = "";
  public fileName: string;

  constructor(
    private composerService: ComposerService,
    private changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private _httpClient: HttpClient,
    private _FileSaverService: FileSaverService,
    private camundaRest: CamundaRestService
  ) {}

  ngOnInit() {
    this.paramSubscription = this.route.paramMap.subscribe(async (params) => {
      const toolchainIdParameter = params.get("toolchainId");

      if (toolchainIdParameter === "new") {
        this.xml = this.customizedBlankLayout();
        this.xmlFileIds = [];
        this.changeDetector.detectChanges();
      } else {
        const toolchainId = parseInt(toolchainIdParameter);

        this.composerService
          .loadXMLForToolchain(toolchainId)
          .subscribe((xmlAndFileIds) => {
            if (xmlAndFileIds) {
              this.xml = xmlAndFileIds.xml;
              this.xmlFileIds = xmlAndFileIds.fileIds;
              if (!this.changeDetector["destroyed"]) {
                this.changeDetector.detectChanges();
              }
              this.xml_original = this.xml;
            }
          });
      }
    });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }
  modifyBPMN(xml) {
    // Parse the XML
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(xml, "text/xml");

    // Generate random ID
    let randomId = Math.random().toString(36).substr(2, 9);

    // Update process ID
    let processElement = xmlDoc.querySelector("bpmn\\:process, process");
    let currentId = processElement.getAttribute("id");
    processElement.setAttribute("id", currentId + "_" + randomId);

    // Remove composer:ToolchainItem and convert it to bpmn:manualTask
    let toolchainItemElements = xmlDoc.querySelectorAll("composer\\:ToolchainItem, ToolchainItem");
    toolchainItemElements.forEach(toolchainItemElement => {
        let parent = toolchainItemElement.parentNode;
        let manualTaskElement = xmlDoc.createElement("bpmn:manualTask");
        manualTaskElement.setAttribute("id", toolchainItemElement.getAttribute("id"));
        manualTaskElement.setAttribute("name", toolchainItemElement.getAttribute("itemName"));

        // Copy all attributes from ToolchainItem to ManualTask except the specified ones
        Array.from(toolchainItemElement.attributes).forEach(attr => {
            if (
                attr.name !== "itemName" &&
                attr.name !== "description" &&
                attr.name !== "productId" &&
                attr.name !== "productName" &&
                attr.name !== "imageDataUrl" &&
                attr.name !== "selectedPTGs"
            ) {
                manualTaskElement.setAttribute(attr.name, attr.value);
            }
        });

        // Add extensionElements with executionListener as the first child element
        let extensionElementsElement = xmlDoc.createElement("bpmn:extensionElements");
        let executionListenerElement = xmlDoc.createElement("camunda:executionListener");
        executionListenerElement.setAttribute("delegateExpression", "${executionlistener}");
        executionListenerElement.setAttribute("event", "start");
        extensionElementsElement.appendChild(executionListenerElement);
        manualTaskElement.appendChild(extensionElementsElement);

        // Move the original child elements after the executionListener
        Array.from(toolchainItemElement.children).forEach(child => {
            manualTaskElement.appendChild(child.cloneNode(true));
        });

        parent.replaceChild(manualTaskElement, toolchainItemElement);
    });

    // Convert XML back to string
    let serializer = new XMLSerializer();
    let modifiedXml = serializer.serializeToString(xmlDoc);

    return modifiedXml;
}





  /* Download Local JSON */
  download() {
      const fileName = `toolchain_informationen.bpmn`;
      const fileType = this._FileSaverService.genType(fileName);
      if(!this.text){
        const txtBlob = new Blob([this.saved_text], { type: fileType });
        this._FileSaverService.save(txtBlob, fileName);
        }
      else {
      const txtBlob = new Blob([this.text], { type: fileType });
      this._FileSaverService.save(txtBlob, fileName);
  }}


  /* Redirects to the Overview Page */
  public goToOverviewPage() {
    this.router.navigateByUrl("/composer/startprocess/list");
  }

  public deploy() {
    const fileName = `toolchain_informationen.bpmn`;
    const fileType = this._FileSaverService.genType(fileName);
    this.modelEditorComponent.saveModel(this.xml_original).then(text => {
      this.text = this.modifyBPMN(text);
      const txtBlob = new Blob([this.text], { type: fileType });
      this.camundaRest.postProcessXML(fileName,txtBlob)
      this.text="";
      });
    this.router.navigateByUrl("/composer/startprocess/list");
  }

  /* Saves the Toolchain to the Backend */
  public saveToolchain() {
     this.modelEditorComponent.saveModel(this.xml_original).then(text => {
     this.text = text;
     this.download();
     this.text="";
     });

  }

    public saveElementsList() {
       this.modelEditorComponent.saveModelList().then(list => {
                                                     list.forEach((i) => {
                                                        if(i.businessObject.itemName){
                                                         this.text +=  '<' + i.businessObject.itemName + '>';
                                                          }
                                                     });
                                                     this.download();
                                                     this.text="";
                                                     });
    }

  /* Customizes the Blank Layout and adds the correct Values for the Replace Elements */
  private customizedBlankLayout(): string {
    let customized = this.blankLayout;

    customized = customized.replace(
      "CREATORIDREPLACE",
      this.userService.currentUser.userId.toString()
    );
    customized = customized.replace(
      "CREATORNAMEREPLACE",
      this.userService.currentUser.lastName +
        ", " +
        this.userService.currentUser.firstName.substring(0, 1) +
        "."
    );
    //TODO TOOLCHAIN INSTANCES

    return customized;
  }

  public changeToolchainValidity(valid: boolean) {
    this.validToolchain = valid;
  }
}
