<div class="two-columns" *ngIf="certificationService.activeCertification">
  <div>
    <h1>Zertifizierungsvariante anlegen</h1>
    <form #form="ngForm">
      <mat-form-field class="fullWidth">
        <input
          matInput
          placeholder="Variantenname"
          #variantName="ngModel"
          [(ngModel)]="variant.name"
          name="variantName"
          required
        />
        <mat-error *ngIf="variantName.errors?.required && variantName.touched">
          Bitte geben Sie einen Variantennamen ein.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="fullWidth">
        <textarea
          matInput
          placeholder="Kommentar (intern)"
          [(ngModel)]="variant.comment"
          name="comment"
        ></textarea>
      </mat-form-field>
      <app-text-editor
        [(editorText)]="variant.description"
        (textValidityChange)="onDescriptionValidityChange($event)"
      ></app-text-editor>
      <div>
        <p>
          <em>
            Zum Anlegen einer Variante ist das Anlegen einer Version notwendig
          </em>
        </p>
        <app-version-edit
          [version]="version"
          [dataType]="'CERTIFICATION_VARIANT'"
          (validityChanged)="onValidityChanged($event)"
        ></app-version-edit>
      </div>
      <div class="mini-grid-buttons">
        <button
          mat-raised-button
          class="btn_green"
          (click)="createVariant()"
          [disabled]="
            !isFormValid ||
            !isInputValid() ||
            form.invalid ||
            !isDescriptionValid
          "
        >
          Speichern
        </button>
        <button
          mat-raised-button
          class="btn_red"
          (click)="cancelCreateVariant()"
        >
          Abbrechen
        </button>
      </div>
    </form>
  </div>
</div>
