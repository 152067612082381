import { Pipe, PipeTransform } from '@angular/core';
import {CommonFeatureEntryInterface} from "../model/commonFeature/common-feature-entry-interface";


@Pipe({name: 'sortCommonFeaturesByName'})
export class SortCommonFeaturesByName implements PipeTransform{
  transform(members: CommonFeatureEntryInterface[]){
    return members.slice().sort((a, b) => a.name.localeCompare(b.name));
  }
}
