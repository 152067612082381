import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm',
  template: `
  <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>
        <p class="dialog-text">{{data.message}}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onClick(true)" class="btn_green" cdkFocusInitial>{{data.buttonYes}}</button>
      <button mat-button (click)="onClick(false)" class="btn_red" cdkFocusInitial>{{data.buttonNo}}</button>
    </div>
`,
  styleUrls: ['./dialog.css']
})
export class DialogConfirmComponent{

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {}

  onClick(confirmed: boolean): void{
    this.dialogRef.close(confirmed);

  }

}
