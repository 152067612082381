import {
  FormControl,
  Validators,
  Validator,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";

//#region MAGIC NUMBERS

export const CHARACTER_DISPLAY_LIMIT: number = 200;
export const PASSWORD_LENGTH: number = 6;
export const MAX_INTEGER: number = 2147483647;
export const LIST_ATTRIBUTE_MAX_SIZE: number = 100;
export const MAX_TEXT_LENGTH: number = 10000;
export const COOKIE_TIMOUT: number = 2592000000; // 30 days in milliseconds
export const TIMESTAMP_STRING: string = '_timestamp';
/**
 * 500 ms
 */
export const UPDATE_DELAY_IN_MS: number = 500; //Update Delay for user Input in ms
//#endregion

//#region Textfield Validators

export class ValidatorFactory {
  private static validatorFunctions: ValidatorFn[] = [];

  public static addValidatorFunction(f: ValidatorFn) {
    this.validatorFunctions.push(f);
  }

  public static addValidatorFromRegex(
    regex: string,
    mapstring: string,
    invert?: boolean
  ) {
    this.addValidatorFunction(function name(
      control: FormControl
    ): ValidationErrors {
      const regularExpression = new RegExp(regex);
      let map: { [key: string]: boolean } = {};
      if (invert) {
        if (regularExpression.test(control.value)) {
          map[mapstring] = true;
          return map;
        }
      } else {
        if (!regularExpression.test(control.value)) {
          map[mapstring] = true;
          return map;
        }
      }
    });
  }

  public static create(): ValidatorFn {
    return Validators.compose(this.validatorFunctions);
  }
}

export enum VALIDATOR_EXP {
  PASSWORD_LENGTH = "^.{6,}?",
  CONTAINS_SPECIAL_CHAR = "[^A-Za-z0-9]",
  CONTAINS_NUMBER = ".*[0-9].*",
  CONTAINS_LETTER = ".*[A-z].*",
}

export const NAME_CHAR_VALIDATION_REGEX = /^[a-zA-Z ß\-äÄöÖüÜ]+$/;
export const ORGANISATION_CHAR_VALIDATION_REGEX = /^[0-9A-Za-zÀ-ÿ\s,._+;()*~'#@!?:&-]+$/;

//#endregion

//#region Genders

type Gender = {
  // For the value attribute of an <option> element
  value?: string;
  // To display in the <option> element
  label: string;
}

export const GENDERS: Gender[] = [
  {
    label: "-",
  },
  {
    value: "Herr",
    label: "Herr",
  },
  {
    value: "Frau",
    label: "Frau",
  },
  {
    value: "Divers",
    label: "Divers",
  },
];

//#endregion
