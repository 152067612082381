<div class="one-column">
  <h1>Bewertungsmeldungen</h1>
  <div class="table">
    <div class="row border">
      <p class="closer-to-border"><b>Gemeldete Bewertungen</b></p>
    </div>
    <div *ngFor="let review of reportedReviews; let i = index" class="row">
      <app-review-report-item 
        *ngIf="hasUncheckedReports(review)" [review]="review" 
        (reportDeleted)="deleteReport($event)" 
        (reviewDeleted)="deleteReview($event)"> 
      </app-review-report-item>
    </div>
    <p *ngIf="reportedReviews.length == 0" class="italic"> Aktuell liegen keine gemeldeten Bewertungen vor.</p>
  </div>