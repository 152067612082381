<div class="wrapper">

  <h1 style="margin-left: 1.5em">Datenschutzerklärung</h1>
  <div class="agb">
    <div class="tabIn">
      <h2><button (click)="this.toggleVerzeichnis = !this.toggleVerzeichnis">Inhaltsverzeichnis</button></h2>
      <div *ngIf="this.toggleVerzeichnis">
        <a href="/privacy#Praaemble">1 Präambel </a> <br>
        <a href="/privacy#Datenschutzerklaerung">2 Wofür gilt diese Datenschutzerklärung? </a> <br>
        <a href="/privacy#Verantwortlicher">3 Verantwortlicher </a> <br>
        <a style="margin-left: 1em" href="/privacy#Hauptansprechpartner">3.1 Hauptansprechpartner</a> <br>
        <a style="margin-left: 1em" href="/privacy#Datenschutzbeauftragter">3.2 Datenschutzbeauftragter</a> <br>
        <a href="/privacy#Datenverarbeitung">4 Datenverarbeitung </a> <br>
        <a style="margin-left: 1em" href="/privacy#Webseite">4.1 Webseite</a> <br>
        <a style="margin-left: 2em" href="/privacy#BesuchDerWebseite">4.1.1 Besuch der Webseite</a> <br>
        <a style="margin-left: 2em" href="/privacy#Newsletter">4.1.2 Newsletter</a> <br>
        <a style="margin-left: 1em" href="/privacy#BIMSWARM-Marktplatz">4.2 BIMSWARM-Marktplatz</a> <br>
        <a style="margin-left: 2em" href="/privacy#BesuchVomMarktplatz">4.2.1 Besuch des BIMSWARM-Marktplatzes</a> <br>
        <a style="margin-left: 2em" href="/privacy#Registrierung_Anmeldung">4.2.2 Registrierung, Anmeldung und
          Nutzerkonto</a> <br>
        <a style="margin-left: 2em" href="/privacy#Listung">4.2.3 Listung anfragen</a> <br>
        <a style="margin-left: 2em" href="/privacy#Demo">4.2.4 Demo anfragen</a> <br>
        <a style="margin-left: 2em" href="/privacy#Angebot">4.2.5 Angebot anfragen</a> <br>
        <a style="margin-left: 2em" href="/privacy#Produktbewertung">4.2.6 Produktbewertung und
          Merkzettelfunktion</a> <br>
        <a style="margin-left: 1em" href="/privacy#Kontaktaufnahme">4.3 Kontaktaufnahme über E-Mail,
          Kontaktformular, Telefon
          oder per Post </a> <br>
        <a style="margin-left: 1em" href="/privacy#Social_Media">4.4 Social Media Präsenzen </a> <br>
        <a style="margin-left: 1em" href="/privacy#Cookies">4.5 Cookies </a> <br>
        <a style="margin-left: 2em" href="/privacy#AllgInfoCookies">4.5.1 Allgemeine Informationen zu Cookies </a> <br>
        <a style="margin-left: 2em" href="/privacy#DrittanbieterCookies">4.5.2 Cookies von Drittanbietern und
          Social-Media-Plugins </a> <br>
        <a style="margin-left: 3em" href="/privacy#GoogleAnalytics">4.5.2.1 Google Analytics </a> <br>
        <a style="margin-left: 3em" href="/privacy#YouTube">4.5.2.2 YouTube </a> <br>
        <a style="margin-left: 3em" href="/privacy#Twitter">4.5.2.3 Twitter </a> <br>
        <a style="margin-left: 3em" href="/privacy#GoogleFonts">4.5.2.4 Google Fonts </a> <br>
        <a href="/privacy#Erhebung">5 Erhebung personenbezogener Daten </a> <br>
        <a href="/privacy#Weitergabe">6 Weitergabe personenbezogener Daten</a> <br>
        <a style="margin-left: 2em" href="/privacy#WeitergabePflichten"> 6.1 Weitergabe aufgrund gesetzlicher Pflichten
          oder zur
          Wahrung berechtigter Interessen </a> <br>
        <a style="margin-left: 2em" href="/privacy#Auftragsdatenverwaltung"> 6.2 Auftragsdatenverwaltung </a> <br>
        <a style="margin-left: 2em" href="/privacy#VerbundeneUnternehmen"> 6.3 Verbundene Unternehmen und
          Produktanbieter</a> <br>
        <a href="/privacy#Datenuebermittlung">7 Datenübermittlung </a> <br>
        <a href="/privacy#PersonenbezogeneDaten">8 Speichern personenbezogener Daten</a> <br>
        <a href="/privacy#Rechte">9 Rechte betroffener Nutzer </a> <br>
        <a href="/privacy#Bereitstellung"> 10 Bereitstellung personenbezogener Daten </a> <br>
        <a href="/privacy#Entscheidungsfindung">11 Automatisierte Entscheidungsfindung </a> <br>
        <a href="/privacy#Schutzmassnahmen">12 Technische Schutzmaßnahmen</a> <br>
        <a href="/privacy#Stand">13 Stand und Aktualisierung</a> <br>
      </div>
    </div>
    <div>
      <ol>
        <li id="Praaemble" class="format-text"><h2>1 Präambel</h2></li>
        <p>
          Der planen-bauen 4.0 Gesellschaft zur Digitalisierung des Planens, Bauens und Betreibens mbH (“<b>planen-bauen
            4.0</b>“, "<b>wir</b>“
          oder „<b>uns</b>“) ist der Schutz Ihrer Privatsphäre und personenbezogenen Daten ein wichtiges Anliegen. Mit
          der folgenden Datenschutzerklärung
          informieren wir Sie umfassend über den Umgang mit Ihren personenbezogenen Daten und den Datenschutz bei
          planen-bauen 4.0.
        </p>


        <li id="Datenschutzerklaerung" class="format-text"><h2>2 Wofür gilt diese Datenschutzerklärung?</h2></li>
        <p> Diese Datenschutzerklärung findet Anwendung auf den Umgang mit Ihren personenbezogenen Daten,
          wenn Sie die BIMSWARM-Webseite unter <a href="https://www.bimswarm.de"
            target="_blank">https://www.bimswarm.de</a>,
          den BIMSWARM Marktplatz unter <a href="https://www.bimswarm.online"
            target="_blank">https://www.bimswarm.online</a> oder unser Social-Media-Profil auf Twitter besuchen.
          Zudem gilt diese Datenschutzerklärung, wenn Sie mit uns über E-Mail, Telefon oder Post Kontakt aufnehmen. </p>

        <li id="Verantwortlicher" class="format-text"><h2>3 Wer ist Verantwortlicher?</h2></li>
        <ul>
          <ol>
            <li id="Hauptansprechpartner" style="font-weight: bold; margin-left: -1.5em;">3.1 Hauptansprechpartner für
              Datenschutzanfragen</li>
            <p>Der für die in dieser Datenschutzerklärung beschriebenen Datenverarbeitungen Verantwortliche ist: </p>

            <ul>
              <li id="deleteBullets">planen-bauen 4.0 Gesellschaft zur Digitalisierung</li>
              <li id="deleteBullets">des Planens, Bauens und Betreibens mbH</li>
              <li id="deleteBullets">Geneststraße 5 / Aufgang A</li>
              <li id="deleteBullets">10829 Berlin</li>
              <li id="deleteBullets">Deutschland</li>
              <li id="deleteBullets">Tel. <a style="margin-left: 4em;">+49 30 22 45 20 40</a></li>
              <li id="deleteBullets">E-Mail <a style="margin-left: 3em;">info@planen-bauen40.de</a></li>
            </ul>

            <p>Sie können sich hinsichtlich sämtlicher datenschutzrechtlicher Anliegen, insbesondere der Geltendmachung
              Ihrer Betroffenenrechte (Ziffer 9)
              jederzeit an den Verantwortlichen wenden. Hierfür steht Ihnen insbesondere die folgende E-Mail-Adresse
              bereit: <a href="mailto:contact@bimswarm.de" target="_blank">contact@bimswarm.de</a> </p>

            <li id="Datenschutzbeauftragter" style="font-weight: bold; margin-left:-1.5em;">3.2 Datenschutzbeauftragter
            </li>
            <p>
              Bei allen Fragen und als Ansprechpartner zum Thema Datenschutz bei uns
              steht Ihnen unser betrieblicher Datenschutzbeauftragter jederzeit zur Verfügung. Die Kontaktdaten sind:
            </p>
            <ul>
              <li id="deleteBullets">Christian Tomaske</li>
              <li id="deleteBullets">Beauftragter & Auditor für Datenschutz, Informationssicherheit und
                Qualitätsmanagement</li>
              <li id="deleteBullets">E-Mail: <a style="margin-left: 2em;">ct@ufdi.de</a></li>
              <li id="deleteBullets">Mobil: <a style="margin-left: 2em;">+49 (0)171 380 47 73</a></li>
              <li id="deleteBullets">UfDI - Unternehmensberatung für Datenschutz, Informationssicherheit und
                Qualitätsmanagement</li>
              <li id="deleteBullets">Bleekstraße 1</li>
              <li id="deleteBullets">31655 Stadthagen</li>
              <li id="deleteBullets">Telefon: <a style="margin-left: 1em;">+49 (0)5721 820 999 0</a></li>
            </ul>
          </ol>
        </ul>

        <li id="Datenverarbeitung" class="format-text"><h2>4 Wie werden Ihre Daten verarbeitet?</h2></li>
        <p>Planen-bauen 4.0 erhebt und verarbeitet abhängig von der konkreten Verarbeitungssituation verschiedene
          personenbezogene Daten von Ihnen.
          Ihre personenbezogenen Daten verarbeiten wir ausschließlich im Einklang mit den Vorgaben der
          Datenschutz-Grundverordnung (“<b>DSGVO</b>“) und dem Bundesdatenschutzgesetz (“<b>BDSG</b>“).
          Nachfolgend finden Sie eine Auflistung welche personenbezogenen Daten wir je nach Verarbeitungssituation
          verarbeiten, welchen Zwecken diese Datenverarbeitungen dienen und auf welcher Rechtsgrundlage wir die Daten
          verarbeiten.
        </p>
        <ul>
          <ol>
            <li id="Webseite" style="font-weight: bold; margin-left:-1.5em;">4.1 Webseite <a
                href="https://www.bimswarm.de" target="_blank_">https://www.bimswarm.de</a></li>
            <p>In diesem Abschnitt 4.1 beschreiben wir,
              wie Ihre personenbezogenen Daten im Zusammenhang mit dem Besuch der BIMSWARM-Webseite unter:
              <a href="https://www.bimswarm.de">https://www.bimswarm.de</a> verarbeitet werden.</p>
            <ol>
              <li id="BesuchDerWebseite" style="font-weight: bold; margin-left:-1.5em; margin-bottom:0.5em;">4.1.1
                Besuch der Webseite</li>
              <table style="width: 100%; margin-bottom: 1.5em;">
                <tr>
                  <th style="font-style: italic; text-align: left;">(a) Kategorien personenbezogener Daten</th>
                </tr>
                <tr>
                  <th style="font-weight:500; text-align: left;">Der Besuch unserer Webseite ist grundsätzlich ohne
                    Offenlegung Ihrer Identität möglich.
                    Allerdings verarbeiten wir bei dem Aufruf unserer Webseite insbesondere die folgenden
                    personenbezogenen Daten:
                    <p>• Protokolldaten, d.h. Daten in Bezug auf die Nutzung der bereitgestellten Webseite (z.B.
                      verwendeter Webbrowser und Betriebssystem, Referrer-URL, Datum und Uhrzeit des Aufrufs,
                      übertragene Datenmenge),</p>
                    <p>• IP-Adresse des anfragenden Rechners, sowie</p>
                    <p>• weitere technische Daten, die mit den vorangegangenen vergleichbar sind.</p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(b) Zwecke und Rechtsgrundlage der Datenverarbeitung
                  </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    Die Verarbeitung der Daten dient dazu Ihnen den Besuch unserer Webseite zu ermöglichen und unsere
                    Webseite ordnungsgemäß
                    anzeigen zu können. Daneben dient die Verarbeitung statistischen Zwecken und der Verbesserung der
                    Qualität unserer Webseite,
                    insbesondere der Stabilität und der Sicherheit der Verbindung.
                    Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(c) Speicherdauer </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    Die Daten werden nach 30 Tagen gelöscht, sofern keine längere gesetzliche Frist zur weiteren
                    Speicherung eingreift.
                  </th>
                </tr>
              </table>
              <li id="Newsletter" style="font-weight: bold; margin-left:-1.5em; margin-bottom:0.5em;">4.1.2 Newsletter
              </li>
              <p>Sie können sich auf unserer Webseite für unseren Newsletter anmelden.</p>
              <table style="width: 100%; margin-bottom: 1.5em;">
                <tr>
                  <th style="font-style: italic; text-align: left;">(a) Kategorien personenbezogener Daten</th>
                </tr>
                <tr>
                  <th style="font-weight:500; text-align: left;">Wenn Sie sich für unseren Newsletter anmelden,
                    verarbeiten wir von Ihnen:
                    <p>• E-Mail-Adresse,</p>
                    <p>• Vorname und Nachname,</p>
                    <p>• Referrer-URL, Datum und Uhrzeit der Anmeldung und des Aufrufs, Webbrowser,</p>
                    <p>• IP-Adresse, sowie </p>
                    <p>• Informationen zwecks Nachweises der Newsletter-Anmeldung. </p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(b) Zwecke und Rechtsgrundlage der Datenverarbeitung
                  </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    <p>Die Verarbeitung der Daten dient ausschließlich dazu,
                      Ihnen Informationen zu unseren Leistungen und uns zu übersenden sowie Sie über neue Leistungen
                      und Entwicklungen von BIMSWARM auf dem Laufenden zu halten.</p>

                    <p>Rechtsgrundlage ist Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO. Eine Einwilligung ist
                      stets freiwillig.
                      Die Verweigerung der Einwilligung oder ein Widerruf derselben hat keine negativen Auswirkungen auf
                      Sie.
                      Für die Anmeldung zu unserem Newsletter verwenden wir das sog.
                      Double-Opt-In-Verfahren. Das heißt, dass wir Ihnen nach Ihrer Anmeldung eine E-Mail an die
                      angegebene E-Mail-Adresse senden,
                      in welcher wir Sie um Bestätigung bitten, dass Sie den Versand des Newsletters wünschen. Zweck
                      dieses Verfahrens ist,
                      Ihre Anmeldung nachweisen und ggf. einen möglichen Missbrauch Ihrer persönlichen Daten aufklären
                      zu können.
                      Wir weisen darauf hin, dass unsere Newsletter sogenannte Tracking-Pixel enthalten, um ihr
                      Nutzerverhalten auszuwerten.
                      Ein Tracking-Pixel ist eine Ein-Pixel-Bilddatei, die in den E-Mails eingebettet wird, die im
                      HTML-Format versendet werden,
                      um eine Logdatei-Aufzeichnung und -Analyse zu ermöglichen. Anhand des eingebetteten
                      Tracking-Pixels können wir erkennen,
                      ob und wann eine E-Mail von Ihnen geöffnet wurde und welche in der E-Mail befindlichen Links von
                      Ihnen aufgerufen wurden.
                      Die über die Tracking-Pixel erhobenen personenbezogenen Daten werden von uns gespeichert und
                      ausgewertet,
                      um den Versand der Newsletter zu optimieren und den Inhalt zukünftiger Newsletter noch besser an
                      Ihre Interessen anzupassen.
                      Diese personenbezogenen Daten werden nicht an Dritte weitergegeben.
                      Ihre Einwilligung in die Übersendung des Newsletters können Sie jederzeit widerrufen und den
                      Newsletter abbestellen.
                      Den Widerruf können Sie durch Klick auf den in jeder Newsletter-E-Mail bereitgestellten Link,
                      per E-Mail an contact@bimswarm.de oder durch eine Nachricht an die im Impressum angegebenen
                      Kontaktdaten erklären.</p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(c) Speicherdauer </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    Die Daten werden nach Abmeldung vom Newsletter gelöscht, sofern keine längere gesetzliche Frist zur
                    weiteren Speicherung eingreift.
                  </th>
                </tr>
              </table>
            </ol>
            <li id="BIMSWARM-Marktplatz" style="font-weight: bold; margin-left:-1.5em;">4.2 BIMSWARM Marktplatz</li>
            <p>In diesem Abschnitt 4.2 beschreiben wir, wie Ihre personenbezogenen Daten im Zusammenhang mit dem Besuch
              des BIMSWARM-Marktplatzes unter: <a href="https://www.bimswarm.online"
                target="_blank">https://www.bimswarm.online </a> verarbeitet werden.
              Über den BIMSWARM-Marktplatz können sich Nutzer über aktuelle Informationen und Angebote aus dem
              Bausoftwaremarkt informieren. Der BIMSWARM-Marktplatz ist eine neutrale Plattform für zielgerichtete
              Recherche in Produktgruppen und Anwendungsbereichen, Lösungssuche für BIM-Anwendungsfälle, Informationen
              zu Funktionalitäten, Schnittstellen und Kompatibilitäten von Bau-IT-Produkten sowie Informationen zu
              offenen Standards und Qualitätskriterien.
            </p>
            <ol>
              <li id="BesuchVomMarktplatz" style="font-weight: bold; margin-left:-1.5em; margin-bottom: 1em;">4.2.1
                Besuch des
                BIMSWARM-Marktplatzes</li>
              <table style="width: 100%; margin-bottom: 1.5em;">
                <tr>
                  <th style="font-style: italic; text-align: left;">(a) Kategorien personenbezogener Daten</th>
                </tr>
                <tr>
                  <th style="font-weight:500; text-align: left;">Der Besuch unseres Marktplatzes ist grundsätzlich ohne
                    Offenlegung Ihrer Identität möglich. Allerdings verarbeiten wir bei dem Aufruf des Marktplatzes
                    insbesondere die folgenden personenbezogenen Daten:
                    <p>• Protokolldaten, d.h. Daten in Bezug auf die Nutzung der bereitgestellten Webseite (z.B.
                      verwendeter Webbrowser und Betriebssystem, Referrer-URL, Datum und Uhrzeit des Aufrufs,
                      übertragene Datenmenge),</p>
                    <p>• IP-Adresse des anfragenden Rechners, sowie</p>
                    <p>• weitere technische Daten, die mit den vorangegangenen vergleichbar sind.</p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(b) Zwecke und Rechtsgrundlage der Datenverarbeitung
                  </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    <p>Die Verarbeitung der Daten dient dazu Ihnen den Besuch des Marktplatzes zu ermöglichen und diesen
                      ordnungsgemäß anzeigen zu können. Daneben dient die Verarbeitung statistischen Zwecken und der
                      Verbesserung der Qualität unseres Marktplatzes, insbesondere der Stabilität und der Sicherheit der
                      Verbindung.
                      Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(c) Speicherdauer </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    Die Daten werden nach 30 Tagen gelöscht, sofern keine längere gesetzliche Frist zur weiteren
                    Speicherung eingreift.
                  </th>
                </tr>
              </table>
              <li id="Registrierung_Anmeldung" style="font-weight: bold; margin-left:-1.5em; margin-bottom: 1em;">4.2.2
                Registrierung, Anmeldung und
                Nutzerkonto</li>
              <p>Sie können sich auf dem BIMSWARM-Marktplatz als Nutzer registrieren und ein Nutzerkonto anlegen. Ein
                solches Nutzerkonto ermöglicht es Ihnen Produkte auf dem BIMSWARM-Marktplatz zu bewerten und Produkte
                auf einem Merkzettel zu speichern.

                Für das Nutzerkonto bieten wir aus Gründen der Benutzerfreundlichkeit ein sog. Single-Sign-On Verfahren
                an. Dies umfasst das Einrichten einer „Sitzung“ zwischen dem Client und Server, welche Ihnen die
                Bewegung im gesamten Marktplatz ermöglicht, ohne dass Sie sich für jeden Bereich erneut anmelden zu
                müssen. Diese Sitzung wird dabei durch ein Cookie dargestellt (siehe Ziffer 4.5). Überdies speichern wir
                in einem weiteren Cookie die Ihnen zugeordneten Login-Informationen (Benutzername, Benutzerrechte und
                die Gültigkeit der Sitzung) zur Zugriffssteuerung. Anstatt Sie zur erneuten Eingabe Ihrer
                Anmeldeinformationen aufzufordern, wird das Cookie an den Server gesendet und als Identitätsbeweis
                akzeptiert.
              </p>
              <table style="width: 100%; margin-bottom: 1.5em;">
                <tr>
                  <th style="font-style: italic; text-align: left;">(a) Kategorien personenbezogener Daten</th>
                </tr>
                <tr>
                  <th style="font-weight:500; text-align: left;">Wenn Sie ein Nutzerkonto anlegen, verarbeiten wir von
                    Ihnen:
                    <p>• E-Mail-Adresse,</p>
                    <p>• weitere im Rahmen der Registrierung freiwillig angegebene Informationen,</p>
                    <p>• Passwort-Hash,</p>
                    <p>• Referrer-URL, Datum und Uhrzeit des Aufrufs und der Anmeldung, Webbrowser,</p>
                    <p>• IP-Adresse, sowie </p>
                    <p>• Informationen zwecks Nachweises der Registrierung</p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(b) Zwecke und Rechtsgrundlage der Datenverarbeitung
                  </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    <p>Die Verarbeitung der Daten dient ausschließlich dazu Ihnen ein Nutzerkonto auf dem Marktplatz
                      bereitzustellen und Ihnen die damit verbundenen Funktionen anzubieten.
                      Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO. </p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(c) Speicherdauer </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    <p>Grundsätzlich werden Ihre Daten für die Dauer Ihrer Registrierung gespeichert. Wenn Sie von Ihrem
                      Widerspruchsrecht Gebrauch machen wollen und/oder die Löschung Ihres Nutzerkontos oder einzelner
                      Datensätze wünschen, können Sie uns eine E-Mail an contact@bimswarm.de senden. Wir veranlassen
                      dann unverzüglich die Löschung Ihres Nutzerkontos oder der betreffenden Datensätze. Mit Löschung
                      Ihres Nutzerkontos werden all Ihre Profildaten und damit verknüpften Informationen vollständig
                      gelöscht, sofern keine längere gesetzliche Frist zur weiteren Speicherung eingreift. </p>
                  </th>
                </tr>
              </table>
              <li id="Listung" style="font-weight: bold; margin-left:-1.5em; margin-bottom: 1em;">4.2.3 Listung anfragen
              </li>
              <table style="width: 100%; margin-bottom: 1.5em;">
                <tr>
                  <th style="font-style: italic; text-align: left;">(a) Kategorien personenbezogener Daten</th>
                </tr>
                <tr>
                  <th style="font-weight:500; text-align: left;">Wenn Sie eine Listung anfragen, verarbeiten wir von
                    Ihnen:
                    <p>• E-Mail-Adresse, </p>
                    <p>• Vorname und Nachname,</p>
                    <p>• Informationen zum Produkt,</p>
                    <p>• Referrer-URL, Datum und Uhrzeit des Aufrufs und der Anfrage, Webbrowser,</p>
                    <p>• IP-Adresse, sowie </p>
                    <p>• weitere im Rahmen der Anfrage freiwillig angegebene Informationen.</p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(b) Zwecke und Rechtsgrundlage der Datenverarbeitung
                  </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    <p>Die Verarbeitung der Daten dient ausschließlich dazu Ihre Anfrage zu bearbeiten und Ihnen dadurch
                      ggf. eine Listung auf dem Marktplatz zu ermöglichen.
                      Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO.
                    </p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(c) Speicherdauer </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    <p>Die Daten werden nach Bearbeitung Ihrer Anfrage gelöscht, sofern keine Listung erfolgt und keine
                      längere gesetzliche Frist zur weiteren Speicherung eingreift.
                      Sofern Ihr Produkt gelistet wird, werden Ihre Daten für die Dauer der Listung gespeichert. Wenn
                      Sie von Ihrem Widerspruchsrecht Gebrauch machen wollen und/oder die Löschung Ihres Produkts oder
                      einzelner Datensätze wünschen, können Sie uns eine E-Mail an contact@bimswarm.de senden. Wir
                      veranlassen dann unverzüglich die Löschung Ihrer Produktlistung auf dem Marktplatz oder der
                      betreffenden Datensätze. Mit Löschung Ihrer Listung werden all Ihre Profildaten und damit
                      verknüpften Informationen vollständig gelöscht, sofern keine längere gesetzliche Frist zur
                      weiteren Speicherung eingreift.
                    </p>
                  </th>
                </tr>
              </table>
              <li id="Demo" style="font-weight: bold; margin-left:-1.5em; margin-bottom: 1em;">4.2.4 Demo anfragen</li>
              <p>Als Nutzer können Sie eine Demo für ein Produkt auf dem BIMSWARM-Marktplatz anfragen. Dadurch haben Sie
                die Möglichkeit die Produkte auf dem Marktplatz kennenzulernen. </p>
              <table style="width: 100%; margin-bottom: 1.5em;">
                <tr>
                  <th style="font-style: italic; text-align: left;">(a) Kategorien personenbezogener Daten</th>
                </tr>
                <tr>
                  <th style="font-weight:500; text-align: left;">
                    Wenn Sie eine Demo-Version anfragen, verarbeiten wir von Ihnen:
                    <p>• E-Mail-Adresse, </p>
                    <p>• Vorname und Nachname,</p>
                    <p>• Inhalt der Anfrage,</p>
                    <p>• Referrer-URL, Datum und Uhrzeit des Aufrufs und der Anfrage, Webbrowser,</p>
                    <p>• IP-Adresse, sowie </p>
                    <p>• weitere im Rahmen der Anfrage freiwillig angegebene Informationen.</p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(b) Zwecke und Rechtsgrundlage der Datenverarbeitung
                  </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    <p>Die Verarbeitung der Daten dient ausschließlich dazu Ihre Anfrage zu bearbeiten und Ihnen eine
                      Demo-Version des Produktes zur Verfügung zu stellen.
                      Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO.
                    </p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(c) Speicherdauer </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    <p>Die Daten werden nach Bearbeitung Ihrer Anfrage gelöscht, sofern keine längere gesetzliche Frist
                      zur weiteren Speicherung eingreift.
                    </p>
                  </th>
                </tr>
              </table>
              <li id="Angebot" style="font-weight: bold; margin-left:-1.5em; margin-bottom: 1em;">4.2.5 Angebot anfragen
              </li>
              <p>Als Nutzer können Sie ein Angebot für ein spezifisches Produkt auf dem BIMSWARM-Marktplatz anfragen.
                Dadurch haben Sie die Möglichkeit das Produkt zu erwerben. </p>
              <table style="width: 100%; margin-bottom: 1.5em;">
                <tr>
                  <th style="font-style: italic; text-align: left;">(a) Kategorien personenbezogener Daten</th>
                </tr>
                <tr>
                  <th style="font-weight:500; text-align: left;">
                    Wenn Sie ein Angebot für ein spezifisches Produkt anfragen, verarbeiten wir von Ihnen:
                    <p>• E-Mail-Adresse,</p>
                    <p>• Vorname und Nachname,</p>
                    <p>• Inhalt der Anfrage,</p>
                    <p>• Referrer-URL, Datum und Uhrzeit des Aufrufs und der Anfrage, Webbrowser,</p>
                    <p>• IP-Adresse, sowie </p>
                    <p>• weitere im Rahmen der Anfrage freiwillig angegebene Informationen.</p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(b) Zwecke und Rechtsgrundlage der Datenverarbeitung
                  </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    <p>Die Verarbeitung der Daten dient ausschließlich dazu Ihre Anfrage zu bearbeiten und Ihnen ein
                      Angebot für ein Produkt zu erstellen.
                      Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO.

                    </p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(c) Speicherdauer </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    <p>Die Daten werden nach Bearbeitung Ihrer Anfrage gelöscht, sofern keine längere gesetzliche Frist
                      zur weiteren Speicherung eingreift.
                    </p>
                  </th>
                </tr>
              </table>
              <li id="Produktbewertung" style="font-weight: bold; margin-left:-1.5em; margin-bottom: 1em;">4.2.6
                Produktbewertung und
                Merkzettelfunktion</li>
              <p>Registrierte Nutzer haben auf den Produktseiten des Marktplatzes die Möglichkeit, Produkte zu bewerten
                und Produkte für sich auf einem Merkzettel zu speichern. Diese Funktionen sind freiwillig, d.h. Sie sind
                nicht verpflichtet Bewertungen abzugeben. Die Bewertung erfolgt ausschließlich über die Angabe von
                Sternen (zwischen 1 und 5 Sterne). </p>
              <table style="width: 100%; margin-bottom: 1.5em;">
                <tr>
                  <th style="font-style: italic; text-align: left;">(a) Kategorien personenbezogener Daten</th>
                </tr>
                <tr>
                  <th style="font-weight:500; text-align: left;">
                    Wenn Sie eine Produktbewertung abgeben, erfassen wir die folgenden personenbezogenen Daten, die Sie
                    freiwillig eingeben:
                    <p>• Rating (Bewertung zwischen 1 und 5 Sternen), </p>
                    <p>• Protokolldaten, d.h. Daten in Bezug auf die Nutzung der bereitgestellten Webseite (z.B.
                      verwendeter Webbrowser und Betriebssystem, Referrer-URL, Datum und Uhrzeit des Aufrufs,
                      übertragene Datenmenge),</p>
                    <p>• IP-Adresse des anfragenden Rechners und Nutzerkonto-ID, sowie</p>
                    <p>• weitere technische Daten, die mit den vorangegangenen vergleichbar sind. </p>
                    Wenn Sie ein Produkt auf dem Merkzettel speichern, erfassen wir die folgenden personenbezogenen
                    Daten, die Sie freiwillig eingeben:
                    <p>• Protokolldaten, d.h. Daten in Bezug auf die Nutzung der bereitgestellten Webseite (z.B.
                      verwendeter Webbrowser und Betriebssystem, Referrer-URL, Datum und Uhrzeit des Aufrufs,
                      übertragene Datenmenge),</p>
                    <p>• IP-Adresse des anfragenden Rechners und Nutzerkonto-ID, sowie</p>
                    <p>• weitere technische Daten, die mit den vorangegangenen vergleichbar sind. </p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(b) Zwecke und Rechtsgrundlage der Datenverarbeitung
                  </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    <p>Die Verarbeitung der Daten dient dazu Produktbewertungen auf dem Marktplatz anzuzeigen bzw. Ihnen
                      zu ermöglichen sich bestimmte Produkte zu merken und diese wieder zu finden. Daneben dient die
                      Verarbeitung statistischen Zwecken und der Verbesserung der Qualität unseres Marktplatzes.
                      Rechtsgrundlagen ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an den
                      Bewertungen der Produkte auf dem Marktplatz sowie daran Ihnen das Wiederauffinden von Produkten zu
                      vereinfachen.
                    </p>
                  </th>
                </tr>
                <tr>
                  <th style="font-style: italic; text-align: left;">(c) Speicherdauer </th>
                </tr>
                <tr>
                  <th style="font-weight: 500; text-align: left;">
                    <p>Die Bewertungsdaten werden solange gespeichert, wie das betreffende Produkt gelistet ist. Die
                      Merkzetteldaten werden solange gespeichert, wie Sie das Produkt auf Ihrem Merkzettel speichern.
                      Sie können den Merkzettel jederzeit löschen.
                      Wenn Sie von Ihrem Widerspruchsrecht Gebrauch machen wollen und/oder die Löschung Ihrer Bewertung
                      oder einzelner Datensätze wünschen, können Sie uns eine E-Mail an contact@bimswarm.de senden. Wir
                      veranlassen dann unverzüglich die Löschung Ihrer Bewertung oder der betreffenden Datensätze. Mit
                      Löschung Ihrer Bewertung werden damit verknüpfte Informationen vollständig gelöscht, sofern keine
                      längere gesetzliche Frist zur weiteren Speicherung eingreift.
                    </p>
                  </th>
                </tr>
              </table>
            </ol>
            <li id="Kontaktaufnahme" style="font-weight: bold; margin-left:-1.5em; margin-bottom: 1em;">4.3
              Kontaktaufnahme über E-Mail,
              Kontaktformular, Telefon
              oder per Post </li>
            <table style="width: 100%; margin-bottom: 1.5em;">
              <tr>
                <th style="font-style: italic; text-align: left;">(a) Kategorien personenbezogener Daten</th>
              </tr>
              <tr>
                <th style="font-weight:500; text-align: left;">
                  Bei der Kontaktaufnahme verarbeiten wir insbesondere die folgenden personenbezogenen Daten:
                  <p>• E-Mail-Adresse, </p>
                  <p>• Telefonnummer,</p>
                  <p>• Adresse,</p>
                  <p>• Vorname und Nachname,</p>
                  <p>• Zeitpunkt der Anfrage,</p>
                  <p>• Informationen, die Sie im Rahmen der Kontaktaufnahme zur Verfügung stellen. </p>
              </tr>
              <tr>
                <th style="font-style: italic; text-align: left;">(b) Zwecke und Rechtsgrundlage der Datenverarbeitung
                </th>
              </tr>
              <tr>
                <th style="font-weight: 500; text-align: left;">
                  <p>Die Verarbeitung der Daten dient ausschließlich dazu Ihre Anfrage zu bearbeiten.
                    Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO.
                  </p>
                </th>
              </tr>
              <tr>
                <th style="font-style: italic; text-align: left;">(c) Speicherdauer </th>
              </tr>
              <tr>
                <th style="font-weight: 500; text-align: left;">
                  <p>Die Daten werden nach zufriedenstellender Beantwortung Ihrer Anfrage gelöscht, sofern keine längere
                    gesetzliche Frist zur weiteren Speicherung eingreift.
                  </p>
                </th>
              </tr>
            </table>
            <li id="Social_Media" style="font-weight: bold; margin-left:-1.5em;">4.4 Social Media Präsenzen </li>
            <p>
              Wir unterhalten in verschiedenen sozialen Netzwerken öffentlich zugängliche Profile. Ihr Besuch dieser
              Profile setzt eine Vielzahl von Datenverarbeitungsvorgängen in Gang. Nachfolgend geben wir Ihnen einen
              Überblick darüber, welche Ihrer personenbezogenen Daten von uns erhoben, genutzt und gespeichert werden,
              wenn Sie unsere Profile besuchen.
            </p>
            <p>
              Bei Ihrem Besuch unserer Profile werden Ihre personenbezogenen Daten nicht nur durch uns, sondern auch
              durch die Betreiber des jeweiligen sozialen Netzwerks („<b>Anbieter</b>“)) erhoben, genutzt und
              gespeichert. Dies
              geschieht auch dann, wenn Sie selbst kein Profil in dem jeweiligen sozialen Netzwerk haben. Die einzelnen
              Datenverarbeitungsvorgänge und ihr Umfang unterscheiden sich je nach Anbieter und sie sind nicht zwingend
              für uns nachvollziehbar. Einzelheiten über die Erhebung und Speicherung Ihrer persönlichen Daten sowie
              über Art, Umfang und Zweck ihrer Verwendung durch den Anbieter entnehmen Sie bitte den
              Datenschutzerklärungen des jeweiligen Anbieters:</p>
            <p>• die Datenschutzerklärung für <b>YouTube</b>, das von Google Ireland Limited, Gordon House, Barrow
              Street,
              Dublin 4, Irland, betrieben wird, können Sie unter <a
                href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a> einsehen.
            </p>
            <p>• Die Datenschutzerklärung von <b>Twitter</b>, das von Twitter International Company, One Cumberland
              Place,
              Fenian Street, Dublin 2, D02 AX07 Ireland betrieben wird, können Sie unter <a
                href="https://twitter.com/de/privacy">https://twitter.com/de/privacy</a>
              einsehen.</p>
            <p>
              Als Betreiber einer Social Media Präsenz können wir nur die in Ihrem öffentlichen Profil hinterlegten
              Informationen einsehen, und dies auch nur, sofern Sie über ein solches Profil verfügen und in dieses
              eingeloggt sind, während Sie unsere Seite aufrufen. Zusätzlich stellen uns die Anbieter zum Teil anonyme
              Nutzungsstatistiken zur Verfügung, die wir zur Verbesserung des Nutzererlebnisses beim Besuch unserer
              Seiten verwenden. Wir haben keinen Zugriff auf die Nutzungsdaten, die die Anbieter zur Erstellung dieser
              Statistiken erheben.</p>

            <p>
              Dennoch besteht zwischen uns und den Anbietern, welche derartige <b>Nutzungsstatistiken</b> erstellen in
              Bezug
              auf die dabei stattfindenden Datenverarbeitungsvorgänge eine <b>gemeinsame Verantwortlichkeit nach Art. 26
                DSGVO.</b> Als „gemeinsam Verantwortliche“ sind wir gemeinschaftlich für die Verarbeitung verantwortlich
              und
              müssen die Einhaltung des anwendbaren Datenschutzrechts sicherstellen.
            </p>
            <p>
              In diesem Rahmen haben sich die Anbieter uns gegenüber verpflichtet, die primäre Verantwortung nach der
              DSGVO für die Verarbeitung dieser Daten zu übernehmen, sämtliche Pflichten aus der DSGVO hinsichtlich
              dieser Daten zu erfüllen und den Betroffen das Wesentliche dieser Verpflichtung zur Verfügung zu stellen.
            <p>
              Diese Datenverarbeitungen dienen unserem und Ihrem berechtigten Interesse, das Nutzererlebnis beim Besuch
              unserer Social Media Seiten zielgruppengerecht zu verbessern. <b>Rechtsgrundlage für die
                Datenverarbeitungen
                ist damit Art. 6 Abs. 1 S. 1 lit. f DSGVO</b>.
            </p>

            <p>
              Daneben verwenden die Anbieter sog. Cookies, die beim Besuch unserer Seiten auch dann auf Ihrem Endgerät
              abgelegt werden, wenn Sie über kein eigenes Profil verfügen oder in dieses nicht während Ihres Besuchs
              unserer Seite eingeloggt sind. Diese Cookies erlauben es den Anbietern, Nutzerprofile anhand Ihrer
              Präferenzen und Interessen zu erstellen und Ihnen darauf abgestimmte Werbung anzuzeigen. Cookies befinden
              sich solange auf Ihrem Endgerät, bis Sie sie löschen. Einzelheiten hierzu finden Sie in den
              Datenschutzerklärungen der Anbieter. </p>

            <p>
              Sofern Sie unsere Profile in sozialen Netzwerken nutzen, um mit uns Kontakt aufzunehmen (bspw. durch die
              Erstellung eigener Beiträge, die Reaktion auf einen unserer Beiträge oder durch private Nachrichten an
              uns), werden die uns von Ihnen mitgeteilten Daten von uns ausschließlich zu dem Zweck verarbeitet, mit
              Ihnen in Kontakt treten zu können. Rechtsgrundlage für die Datenerhebung ist Art. 6 Abs. 1 S. 1 lit. b
              DSGVO.
            </p>
            <p>
              Wir löschen gespeicherte Daten sobald deren Speicherung nicht mehr erforderlich ist oder Sie uns zu deren
              Löschung auffordern. Im Falle gesetzlicher Aufbewahrungspflichten beschränken wir die Verarbeitung der
              gespeicherten Daten entsprechend.
            </p>
            <p>
              Im Rahmen der Datenverarbeitungen im Zusammenhang mit den Social Media Präsenzen können Ihre
              personenbezogenen Daten in Drittländer übermittelt werden. Insofern verweisen wir auf Ziffer 7.
            </p>
            <li id="Cookies" style="font-weight: bold; margin-left:-1.5em; margin-bottom:1em;">4.5 Cookies und
              Tracking-Technologien
            </li>
            <ol>
              <li id="AllgInfoCookies" style="font-weight: bold; margin-left:-1.5em;">4.5.1 Allgemeine Informationen zu
                Cookies </li>
              <p>
                Auf unseren Webseiten nutzen wir Cookies. Bei Cookies handelt es sich um kleine Textdateien, die auf
                Ihrer
                Festplatte dem von Ihnen verwendeten Browser durch eine charakteristische Zeichenfolge zugeordnet und
                gespeichert werden und durch welche der Stelle, die das Cookie setzt, bestimmte Informationen zufließen.
                Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen und daher keine
                Schäden
                anrichten. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver, also für
                Sie
                angenehmer zu machen.
              </p>
              <p>
                Cookies können Daten enthalten, die eine Wiedererkennung des genutzten Geräts möglich machen. Teilweise
                enthalten Cookies aber auch lediglich Informationen zu bestimmten Einstellungen, die nicht
                personenbeziehbar sind. Cookies können einen Nutzer aber nicht direkt identifizieren. Ein Cookie dient
                in
                erster Linie dazu, die Informationen über einen Benutzer während oder nach seinem Besuch innerhalb eines
                Onlineangebotes zu speichern. Zu den gespeicherten Angaben können z.B. die Spracheinstellungen auf einer
                Webseite, der Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut wurde, gehören.
              </p>
              <p>
                Man unterscheidet zwischen <b>Session-Cookies</b>, die wieder gelöscht werden, sobald Sie ihren Browser
                schließen
                und <b>permanenten Cookies</b>, die über die einzelne Sitzung hinaus gespeichert werden. Daneben
                unterscheidet
                man zwischen <b>First-Party-Cookies</b> (diese werden von uns selbst gesetzt) und
                <b>Third-Party-Cookies</b> (diese
                werden hauptsächlich von Werbetreibenden, d.h. Dritten, verwendet), um Benutzerinformationen zu
                verarbeiten.
              </p>
              <p>
                Hinsichtlich ihrer Funktion unterscheidet man bei Cookies wiederum zwischen:
              </p>
              <ul>
                <li>
                  <p style="margin-top: -1em;">• <b>Zwingend erforderliche Cookies</b>: Diese Cookies sind für das
                    Funktionieren unserer Websites
                    unerlässlich
                    und ermöglichen es Ihnen, sich auf unseren Websites zu bewegen und deren Funktionen zu nutzen. Ohne
                    diese
                    Cookies können bestimmte Dienste, die für das vollständige Funktionieren unserer Websites
                    erforderlich
                    sind, nicht bereitgestellt werden.</p>
                </li>
                <li>
                  <p style="margin-top: -1em;">• <b>Analyse/Performance Cookies</b>: Mit Hilfe dieser Cookies sammeln
                    wir
                    Informationen darüber, wie unsere Nutzer unsere Websites nutzen, z. B. welche Seiten am häufigsten
                    aufgerufen und gelesen werden oder wie die Nutzer von einem Link zum nächsten wechseln. Alle
                    Informationen, die durch diese Art von Cookies gesammelt werden, beziehen sich nicht auf einen
                    einzelnen Nutzer, sondern werden mit den Informationen anderer Nutzer zusammengefasst und
                    verarbeitet. Cookies liefern uns analytische Daten darüber, wie unsere Websites funktionieren und
                    wie wir sie verbessern können. </p>
                </li>
                <li>
                  <p style="margin-top: -1em;">• <b>Funktionsbezogene Cookies</b>: Diese Cookies ermöglichen es uns,
                    eine
                    bestimmte Auswahl, die Sie getroffen haben, zu speichern und unsere Website so anzupassen, dass
                    sie Ihnen erweiterte Funktionen und Inhalte bietet. Diese Cookies können z.B. dazu verwendet
                    werden, Ihre Sprachauswahl oder Länderauswahl zu speichern. </p>
                </li>
                <li>
                  <p style="margin-top: -1em;">• <b>Analyse/Performance Cookies</b>: Mit Hilfe dieser Cookies sammeln
                    wir
                    Informationen darüber, wie unsere Nutzer unsere Websites nutzen, z. B. welche Seiten am häufigsten
                    aufgerufen und gelesen werden oder wie die Nutzer von einem Link zum nächsten wechseln. Alle
                    Informationen, die durch diese Art von Cookies gesammelt werden, beziehen sich nicht auf einen
                    einzelnen Nutzer, sondern werden mit den Informationen anderer Nutzer zusammengefasst und
                    verarbeitet. Cookies liefern uns analytische Daten darüber, wie unsere Websites funktionieren und
                    wie wir sie verbessern können. </p>
                </li>
                <li>
                  <p style="margin-top: -1em;">• <b>Marketing-Cookies</b>: Diese Cookies speichern Ihren Besuch auf
                    unserer Webseite, die Seiten, die Sie besucht haben, und die Links, denen Sie gefolgt sind. Wir
                    verwenden diese Informationen, um unsere Website und die darauf angezeigte Werbung sowie die von
                    uns an Sie gesendeten Marketingnachrichten besser auf Ihre Interessen abzustimmen. Wir können
                    diese Informationen auch an Dritte weitergeben, die uns zu diesem Zweck einen Dienst erbringen.
                  </p>
                </li>
                <li>
                  <p style="margin-top: -1em;">
                    • <b>Cookies sozialer Netzwerke</b>: Um den Austausch von Inhalten im Internet zu erleichtern,
                    können
                    einige unserer Internetseiten kleine Softwareanwendungen von Drittanbietern, z.B. Twitter oder
                    LinkedIn, zum Austausch von Daten enthalten. Dabei werden die Cookies nicht von uns, sondern von dem
                    Drittanbieter auf Ihrem Gerät gespeichert. Wir können diese Cookies nicht kontrollieren. Für weitere
                    Informationen sollten Sie daher die Internetseite des Drittanbieters besuchen. </p>
                </li>
              </ul>
              <p>
                Jeder Einsatz von Cookies, der nicht zwingend technisch erforderlich ist, stellt eine Datenverarbeitung
                dar, die nur mit einer ausdrücklichen und aktiven Einwilligung Ihrerseits gem. Art. 6 Abs. 1 S. 1 lit. a
                DSGVO erlaubt ist. Eine Einwilligung ist stets freiwillig. Die Verweigerung der Einwilligung oder ein
                Widerruf derselben hat keine negativen Auswirkungen auf Sie. Darüber hinaus geben wir Ihre durch Cookies
                verarbeiteten personenbezogenen Daten nur an Dritte weiter, wenn Sie nach Art. 6 Abs. 1 S. 1 lit. a
                DSGVO
                eine ausdrückliche Einwilligung dazu erteilt haben.
              </p>
              <p>
                <b>Allgemeine Hinweise zum Widerruf und Widerspruch</b>: Abhängig davon, ob die Verarbeitung auf
                Grundlage einer
                Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die Möglichkeit, eine erteilte
                Einwilligung zu widerrufen oder der Verarbeitung Ihrer Daten durch Cookie-Technologien zu widersprechen
                (zusammenfassend als "Opt-Out" bezeichnet). Sie können Ihren Widerspruch zunächst mittels der
                Einstellungen Ihres Browsers erklären, z.B., indem Sie die Nutzung von Cookies deaktivieren (wobei
                hierdurch auch die Funktionsfähigkeit unseres Onlineangebotes eingeschränkt werden kann).
                Ein Widerspruch gegen den Einsatz von Cookies zu Zwecken des Onlinemarketings kann auch mittels einer
                Vielzahl von Diensten, vor allem im Fall des Trackings, über die Webseiten https://optout.aboutads.info
                und https://www.youronlinechoices.com erklärt werden. Daneben können Sie weitere Widerspruchshinweise im
                Rahmen der Angaben zu den eingesetzten Dienstleistern und Cookies erhalten.
              </p>
              <li id="DrittanbieterCookies" style="font-weight: bold; margin-left:-1.5em;">4.5.2 Cookies von
                Drittanbietern und
                Social-Media-Plugins</li>
              <p>Um Ihnen eine komfortable Website anbieten zu können, nutzen wir unter anderem Google Analytics,
                YouTube sowie sogenannte Social Media Plugins der sozialen Netzwerke.</p>
              <ol>
                <li id="GoogleAnalytics" style="font-weight: bold; margin-left:-1.5em;">4.5.2.1 Google Analytics</li>
                <p>
                  Zur bedarfsgerechten Gestaltung unserer Webseiten erstellen wir pseudonyme Nutzungsprofile mit Hilfe
                  von Google Analytics. Google Analytics verwendet Targeting Cookies, die auf Ihrem Endgerät gespeichert
                  und von uns ausgelesen werden können. Auf diese Weise sind wir in der Lage, wiederkehrende Besucher zu
                  erkennen und als solche zu zählen und zu erfahren, wie häufig unsere Webseiten von unterschiedlichen
                  Nutzern aufgerufen wurden.
                </p>
                <p>
                  Die durch das Cookie erzeugten Informationen über Ihre Benutzung unserer Webseite werden in der Regel
                  an einen Server von <b>Google in den USA</b> übertragen und dort gespeichert. Da wir die
                  IP-Anonymisierung
                  auf unserer Webseite aktiviert haben, wird Ihre IP-Adresse von Google jedoch zuvor innerhalb von
                  Mitgliedstaaten der EU gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von
                  Google in den USA übertragen und erst dort gekürzt (weitere Informationen zu Zweck und Umfang der
                  Datenerhebung erhalten Sie z.B. unter <a
                    href="https://policies.google.com/privacy?hl=de&gl=de)">https://policies.google.com/privacy?hl=de&gl=de)</a>".
                  Zu den Übermittlungen in Drittländer verweisen wir auf Ziffer 7.
                </p>
                <p>
                  In Bezug auf die Datenverarbeitungsvorgänge durch Google Analytics agieren wir zusammen mit Google LLC
                  <b>als gemeinsam Verantwortliche nach Art. 26 DSGVO</b>. Als „gemeinsam Verantwortliche“ sind wir
                  gemeinschaftlich für die Verarbeitung verantwortlich und müssen die Einhaltung des anwendbaren
                  Datenschutzrechts sicherstellen. Wir haben mit Google LLC (<b>USA</b>) daher einen Vertrag zur
                  gemeinsamen
                  Verantwortlichkeit nach Art. 26 DSGVO geschlossen. Hauptansprechpartner für Datenschutzfragen ist
                  Google. Informationen hierzu finden Sie unter:
                  <a
                    href="https://support.google.com/policies/answer/9581826?p=privpol_privts&hl=de&visit_id=637655779858103449-2238882241&rd=1">
                    https://support.google.com/policies/answer/9581826?p=privpol_privts&hl=de&visit_id=637655779858103449-2238882241&rd=1ß
                  </a>.
                </p>
                <p>
                  Google setzt folgende Cookies, wenn Sie unsere Webseite besuchen und der Verwendung der Cookie Google
                  Analytics einwilligen:
                </p>
                <table style="width: 100%; margin-bottom: 1.5em;">
                  <tr>
                    <th style="text-align: left;">Name</th>
                    <th style="text-align: left;">Zweck</th>
                    <th style="text-align: left;">Auslaufen</th>
                  </tr>
                  <tr>
                    <th style="text-align: left;">_ga</th>
                    <th style="text-align: left;">Das hilft uns zu zählen, wie viele Personen unsere
                      Internetpräsentation besuchen, wenn Sie diese bereits besucht haben.</th>
                    <th style="text-align: left;">2 Jahre</th>
                  </tr>
                  <tr>
                    <th style="text-align: left;">_gid</th>
                    <th style="text-align: left;">Das hilft uns zu zählen, wie viele Personen unsere
                      Internetpräsentation besuchen, wenn Sie diese bereits besucht haben.</th>
                    <th style="text-align: left;">24 Std.</th>
                  </tr>
                  <tr>
                    <th style="text-align: left;">_gat</th>
                    <th style="text-align: left;">Dies hilft uns, die Frequenz zu verwalten, in der Anfragen für das
                      Anzeigen einer Seite gestellt waren.</th>
                    <th style="text-align: left;">1 min.</th>
                  </tr>
                </table>
                <p>
                  <b>Rechtsgrundlage ist Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</b>. Eine Einwilligung ist stets
                  freiwillig. Die Verweigerung der Einwilligung oder ein Widerruf derselben hat keine negativen
                  Auswirkungen auf Sie.
                </p>
                <p>
                  Sie können Ihre einmal erteilte Einwilligung jederzeit widerrufen. Bitte nutzen Sie hierfür eine der
                  folgenden Möglichkeiten:
                </p>
                <ul>
                  <p>• Sie teilen uns mit, dass Sie Ihre Einwilligung widerrufen möchten.</p>

                  <p>• Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
                    Browser-Software
                    verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                    Funktionen dieser Webseite vollumfänglich werden nutzen können.</p>

                  <p>
                    • Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung
                    unserer
                    Webseiten bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten
                    durch
                    Google verhindern, indem sie das unter dem folgenden Link
                    (<a target="_blank"
                      href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>)
                    verfügbare Browser-Plugin herunterladen und
                    installieren.</p>
                </ul>
                <li id="YouTube" style="font-weight: bold; margin-left:-1.5em;">4.5.2.2 YouTube</li>
                <p>
                  Wir nutzen auf unserer BIMSWARM-Webseiten die Dienste des Videoportals YouTube LLC, 901 Cherry Ave.
                  94066 San Bruno, CA, USA, zur Einbindung von Videos.
                </p>
                <p>
                  Die Datenschutzerklärung für <b>YouTube</b>, das von Google Ireland Limited, Gordon House, Barrow
                  Street,
                  Dublin 4, Irland, betrieben wird, können Sie unter <a target="_blank"
                    href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>
                  einsehen.
                </p>
                <p>
                  Im Zusammenhang mit der Bereitstellung von YouTube nutzen wir den "Enhanced Privacy Mode", der
                  sicherstellen soll, dass nur beim Abruf der Videos Daten an YouTube übermittelt werden. Erst wenn Sie
                  mit dem Video interagieren, wird also eine Verbindung zu YouTube hergestellt, um das Video abrufen und
                  anzeigen zu können. In diesem Zusammenhang speichert YouTube mindestens die IP-Adresse, das Datum und
                  die Uhrzeit sowie die von Ihnen besuchte Website. Außerdem wird eine Verbindung zu Googles
                  Werbenetzwerk "DoubleClick" hergestellt.
                </p>
                <p>
                  Wenn Sie zum Zeitpunkt des Besuchs unserer Website bei YouTube eingeloggt sind, kann YouTube eine
                  Verbindung zu Ihrem YouTube-Konto herstellen. Um dies zu verhindern, müssen Sie sich entweder vor dem
                  Besuch unserer Website bei YouTube ausloggen oder entsprechende Einstellungen in Ihrem
                  YouTube-Benutzerkonto vornehmen. Zum Zwecke der Verbesserung der Benutzerfreundlichkeit und zur
                  Analyse des Nutzungsverhaltens speichert YouTube über Ihren Internetbrowser dauerhaft Cookies auf
                  Ihrem Endgerät. Wenn Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit,
                  die Speicherung von Cookies durch eine Einstellung in Ihrem Internetbrowser zu verhindern. Weitere
                  Informationen hierzu finden Sie oben unter "Cookies". Weitere Informationen zur Erhebung und Nutzung
                  von Daten sowie zu Ihren diesbezüglichen Rechten und Schutzmöglichkeiten stellt Google in den
                  Datenschutzhinweisen zur Verfügung.
                </p>
                <p>
                  Rechtsgrundlage für die Datenverarbeitungsvorgänge in diesem Zusammenhang ist Ihre Einwilligung nach
                  Art. 6 Abs. 1 S. 1 lit. a DSGVO.
                </p>
                <li id="Twitter" style="font-weight: bold; margin-left:-1.5em;">4.5.2.3 Twitter</li>
                <p>
                  Wir binden auf unserer BIMSWARM-Webseite den Nachrichtenfeed von Twitter ein. Zu den verarbeiteten
                  Daten können insbesondere verschiedene Browser- und Gerätedaten sowie die IP-Adresse gehören. Die
                  Daten können in den USA verarbeitet werden.
                </p>
                <p>
                  Über in die Webseite eingebundene Twitterfeeds und die Verwendung von Cookies ist es Twitter möglich,
                  Ihre Besuche auf der Webseite zu erfassen und Ihrem Twitter-Profil zuzuordnen. Anhand dieser Daten
                  können Inhalte oder Werbung auf Sie zugeschnitten angeboten werden.
                </p>
                <p>
                  Rechtsgrundlage für Datenverarbeitungsvorgänge im Zusammenhang mit dem Twitter Plugin ist Ihre
                  Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO.
                </p>
                <p>
                  Die Datenschutzerklärung von Twitter, das von Twitter International Company, One Cumberland Place,
                  Fenian Street, Dublin 2, D02 AX07 Ireland betrieben wird, können Sie unter
                  <a href="https://twitter.com/de/privacy" target="_blank">https://twitter.com/de/privacy</a> einsehen.
                </p>
                <li id="GoogleFonts" style="font-weight: bold; margin-left:-1.5em;">4.5.2.4 Google Fonts</li>
                <p>
                  Wir binden die Schriftarten „Google Fonts“ des Anbieters Google ein, wobei die Daten der Nutzer allein
                  zu Zwecken der Darstellung der Schriftarten im Browser der Nutzer verwendet werden.
                </p>
                <p>
                  Zu den verarbeiteten Daten können insbesondere verschiedene Browser- und Gerätedaten sowie die
                  IP-Adresse gehören. Die Daten können in den USA verarbeitet werden.
                </p>
                <p>
                  Rechtsgrundlage für Datenverarbeitungsvorgänge im Zusammenhang mit Google Fonts ist <b>Art. 6 Abs. 1
                    S. 1
                    lit. f DSGVO.</b> Wir haben ein berechtigtes Interesse an einer technisch sicheren, wartungsfreien
                  und
                  effizienten Nutzung von Schriftarten, deren einheitlichen Darstellung sowie unter Berücksichtigung
                  möglicher lizenzrechtlicher Restriktionen für deren Einbindung.
                </p>
                <p>
                  Dienstanbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Weitere
                  Informationen finden Sie in den Datenschutzhinweisen von Google, die Sie unter
                  <a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>
                  einsehen können.
                </p>
              </ol>
            </ol>
          </ol>
        </ul>
        <li id="Erhebung" class="format-text"><h2>5 Bei wem erheben wir Ihre personenbezogenen Daten?</h2></li>
        <p>
          Personenbezogene Daten werden ausschließlich bei Ihnen direkt erhoben, beispielsweise wenn Sie unsere Webseite
          oder den Markplatz BIMSWARM besuchen oder sich zu unserem Newsletter anmelden.
        </p>
        <li id="Weitergabe" class="format-text"><h2>6 An wen geben wir Ihre personenbezogenen Daten weiter?</h2></li>
        <p>Planen-bauen 4.0 gibt Ihre personenbezogenen Daten nur weiter, wenn dies nach europäischem Datenschutzrecht
          zulässig ist, z. B. weil die Datenübermittlung für die Erfüllung eines Vertrages erforderlich ist oder weil
          Sie uns Ihre Einwilligung zur Weitergabe der Daten erteilt haben. Wir arbeiten mit einigen Dienstleistern
          zusammen, wie z. B. technischen Dienstleistern (z. B. Hosting-Dienste, Pflege der Website, Unterstützung
          unserer Marketingaktivitäten, Bereitstellung von Kommunikationsmitteln). </p>

        <ul>
          <ol>
            <li id="WeitergabePflichten" style="font-weight: bold; margin-left:-1.5em;">6.1 Weitergabe aufgrund
              gesetzlicher Pflichten oder zur
              Wahrung berechtigter Interessen</li>
            <p>
              Soweit wir gesetzlich, per Gerichtsbeschluss oder aufgrund einer vollziehbaren behördlichen Anordnung dazu
              verpflichtet sind, müssen wir Ihre personenbezogenen Daten an auskunftsberechtigte Stellen (z.B.
              Aufsichts- oder Finanzbehörden) übermitteln. Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S.
              1 lit. c DSGVO.
            </p>
            <li id="Auftragsdatenverwaltung" style="font-weight: bold; margin-left:-1.5em;">6.2 Auftragsdatenverwaltung
            </li>
            <p>
              Es kann vorkommen, dass für einzelne Angebote auf beauftragte Dienstleister („<b>Auftragsverarbeiter</b>“)
              zurückgegriffen wird. Diese werden nur nach unserer Weisung tätig und wurden im Sinne von Art. 28 DSGVO
              vertraglich dazu verpflichtet, die datenschutzrechtlichen Bestimmungen einzuhalten.
            </p>
            <p>
              Auch die Auftragsverarbeiter sind vertraglich verpflichtet, z. B. die personenbezogenen Daten nach
              Beendigung des Auftrags entweder zu löschen oder zurückzugeben.
            </p>
            <p>
              Folgende Kategorien von Empfängern, bei denen es sich im Regelfall um Auftragsverarbeiter handelt,
              erhalten ggf. Zugriff auf Ihre personenbezogenen Daten:
            </p>
            <ul>
              <p>
                • IT- und Web-Dienstleister bzw. Unternehmen, die im Rahmen der Betreuung unserer Webseite bzw.
                Plattform und unternehmensinternen IT-Infrastruktur (Software, Hardware) beauftragt werden.
              </p>
            </ul>
            <p>
              Rechtsgrundlage für die Weitergabe ist Art. 6 Abs. 1 S. 1 lit. b oder lit. f DSGVO.

              Unsere wichtigsten Auftragsverarbeiter sind projectfacts (betrieben von 5 POINT AG, Rheinstraße 40-42,
              64283 Darmstadt für das Marketing), WordPress (für den Betrieb unserer Website), Mittwald CM Service GmbH
              & Co.KG (Königsberger Str. 4 - 6, 32339 Espelkamp), und adesso as a service GmbH (Adessoplatz 1, 44269
              Dortmund) für Hosting-Dienste (mit Servern in Deutschland).
            </p>
            <li id="VerbundeneUnternehmen" style="font-weight: bold; margin-left:-1.5em;">6.3 Verbundene Unternehmen und
              Produktanbieter</li>
            <p>
              Zudem können Ihre personenbezogenen Daten an verbundene Unternehmen sowie an die Anbieter von Produkten
              auf dem BIMSWARM-Marktplatz weitergegeben werden.
              Eine Weitergabe Ihrer Daten ist beispielsweise erforderlich, wenn Sie bestimmte Leistungen auf unserem
              BIMSWARM-Marktplatz in Anspruch nehmen wollen, wie etwa eine Demo-Version eines Produktes anfragen.
            </p>
            <p>
              Rechtsgrundlage ist dann Art. 6 Abs. 1 S. 1 lit. b DSGVO.
            </p>
            <p>
              Darüber hinaus geben wir Ihre personenbezogenen Daten nur an Dritte weiter, wenn Sie nach Art. 6 Abs. 1 S.
              1 lit. a DSGVO eine ausdrückliche Einwilligung dazu erteilt haben.
            </p>
          </ol>
        </ul>
        <li id="Datenuebermittlung" b class="format-text"><h2>7 Erfolgt eine Datenübermittlung an Drittländer?</h2></li>
        <p>
          Im Rahmen unserer Geschäftsbeziehungen können Ihre personenbezogenen Daten an Drittgesellschaften
          weitergegeben oder offengelegt werden. Diese können sich auch außerhalb der Europäischen Union („<b>EU</b>“)
          oder des
          Europäischen Wirtschaftsraums („<b>EWR</b>“), in sogenannten Drittländern, befinden. Eine derartige
          Verarbeitung
          erfolgt ausschließlich zur Erfüllung der vertraglichen und geschäftlichen Verpflichtungen und zur Pflege Ihrer
          Geschäftsbeziehung zu uns.
        </p>
        <p>Einigen Drittländern bescheinigt die Europäische Kommission durch sog. Angemessenheitsbeschlüsse einen
          Datenschutz, der dem EU-Standard vergleichbar ist (eine Liste dieser Länder sowie eine Kopie der
          Angemessenheitsbeschlüsse erhalten Sie hier:
          http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.html).
        </p>
        <p>
          In anderen Drittländern, in die ggf. personenbezogene Daten übertragen werden, herrscht aber unter Umständen
          wegen fehlender gesetzlicher Bestimmungen kein mit der EU vergleichbares Datenschutzniveau. Dies kann
          bedeuten, dass Ihre personenbezogenen Daten in einer Rechtsordnung verarbeitet werden, die ein Schutzniveau
          bietet, das in bestimmten Fällen weniger Schutz für Ihre personenbezogenen Daten bietet als die Rechtsordnung,
          in der Sie normalerweise ansässig sind. Soweit dies der Fall ist, achten wir darauf, dass der Datenschutz
          ausreichend gewährleistet ist und entsprechende Garantien bestehen. Das heißt, wir schließen beispielsweise
          die Standardvertragsklauseln der Europäischen Kommission zum Schutz personenbezogener Daten ab.
        </p>
        <p>
          Bitte wenden Sie sich an unseren Datenschutzbeauftragten (siehe unter Ziffer 3.3), wenn Sie hierzu nähere
          Informationen erhalten möchten.
        </p>
        <li id="PersonenbezogeneDaten" class="format-text"><h2>8 Wie lange speichern wir Ihre personenbezogenen Daten?</h2></li>
        <p>
          Soweit unter Ziffer 4 keine ausdrückliche Speicherdauer angegeben ist, speichern wir Ihre personenbezogenen
          Daten grundsätzlich nur solange, wie wir die Daten für die Zwecke, für die wir sie erhoben haben, und für die
          Erfüllung rechtlicher Vorgaben und Pflichten benötigen. Werden. Eine Speicherung Ihrer Daten erfolgt
          grundsätzlich nur auf unseren Servern in Deutschland, vorbehaltlich einer ggf. erfolgenden Weitergabe nach den
          Regelungen in Ziffer 6 und 7.
        </p>
        <p>
          Eine Speicherung kann jedoch über die angegebene Zeit hinaus im Falle einer (drohenden) Rechtsstreitigkeit mit
          Ihnen oder eines sonstigen rechtlichen Verfahrens erfolgen oder wenn die Speicherung durch gesetzliche
          Vorschriften, denen wir als Verantwortlicher unterliegen (z.B. § 257 HGB oder § 147 AO), vorgesehen ist. Wenn
          die durch die gesetzlichen Vorschriften vorgeschriebene Speicherfrist abläuft, erfolgt eine Sperrung oder
          Löschung der personenbezogenen Daten, es sei denn, dass eine weitere Speicherung durch uns erforderlich ist
          und dafür eine Rechtsgrundlage besteht.
        </p>
        <li id="rechteNutzer" class="format-text"><h2>9 Welche Rechte haben Sie als betroffene Nutzer?</h2></li>
        <p>
          Wenn Sie von Ihren nachfolgend beschriebenen Betroffenenrechten Gebrauch machen möchten, wenden Sie sich bitte
          formlos an contact@bimswarm.de oder nutzen die unter Ziffer 3 angegebene Postadresse.
        </p>
        <ul>
          <ol>
            <p>
              • <b>Recht auf Auskunft</b>: Unter den Voraussetzungen des Art. 15 DSGVO können Sie jederzeit Auskunft
              über Ihre
              von uns verarbeiteten personenbezogenen Daten sowie eine Kopie Ihrer Daten verlangen.
            </p>
            <p>
              • <b> Recht auf Berichtigung</b>: Unter den Voraussetzungen des Art. 16 DSGVO können Sie unverzüglich die
              Berichtigung unrichtiger oder Vervollständigung unvollständiger Daten zu Ihrer Person verlangen.
            </p>
            <p>
              •<b> Recht auf Löschung</b> : Darüber hinaus können Sie unter den Voraussetzungen des Art. 17 DSGVO die
              Löschung
              Ihrer bei uns gespeicherten personenbezogenen Daten verlangen.
            </p>
            <p>
              • Recht auf Einschränkung der Verarbeitung: Nach Maßgabe von Art. 18 DSGVO können Sie ferner die
              Einschränkung der Verarbeitung Ihrer personenbezogenen Daten verlangen.
            </p>
            <p>
              •<b> Recht auf Auskunft über Empfänger</b>: Nach Art. 19 DSGVO haben Sie das Recht Auskunft über die
              Empfänger
              von Daten zu verlangen, denen eine Berichtigung, Löschung Ihrer personenbezogenen Daten oder eine
              Einschränkung der Verarbeitung mitgeteilt wurde.
            </p>
            <p>
              •<b> Recht auf Datenübertragbarkeit</b>: Unter den Voraussetzungen des Art. 20 DSGVO haben Sie das Recht,
              Ihre
              personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder
              die Übermittlung unmittelbar an einen anderen Verantwortlichen zu verlangen.
            </p>
            <p>
              •<b> Widerrufsrecht</b>: Sollten wir personenbezogene Daten aufgrund einer Ihrerseits erteilten
              Einwilligung
              verarbeiten, sind Sie zudem berechtigt, Ihre Einwilligung gemäß Art. 7 Abs. 3 DSGVO jederzeit zu
              widerrufen. Ihr Widerruf hat zur Folge, dass wir die Datenverarbeitung, die bis dahin auf dieser
              Einwilligung beruht, für die Zukunft nicht mehr fortführen. Die Rechtmäßigkeit der aufgrund Ihrer
              Einwilligung bis zum Widerruf erfolgten Verarbeitung bleibt von Ihrem Widerruf unberührt.
            </p>
            <p>
              •<b> Beschwerderecht</b>: Sofern Sie der Auffassung sind, dass die Verarbeitung der Sie betreffenden
              personenbezogenen Daten durch uns gegen datenschutzrechtliche Bestimmungen verstößt, steht Ihnen das Recht
              auf Beschwerde bei einer Aufsichtsbehörde nach Art. 77 DSGVO zu. In Berlin ist der Berliner Beauftragte
              für Datenschutz und Informationsfreiheit (BInBDI) die zuständige Aufsichtsbehörde (Friedrichstr. 219,
              10969 Berlin, mailbox@datenschutz-berlin.de).
            </p>
            <p>
              <b>WIDERSPRUCHSRECHT</b>: UNTER DEN VORAUSSETZUNGEN DES ART. 21 DSGVO HABEN SIE ZUDEM DAS RECHT, AUS
              GRÜNDEN, DIE
              SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, JEDERZEIT DER VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN ZU
              WIDERSPRECHEN, SOFERN DIE VERARBEITUNG AUF EINEM BERECHTIGTEN INTERESSE VON UNS ODER EINES DRITTEN GEMÄSS
              ART. 6 ABS. 1 S. 1 LIT. F DSGVO ODER AUF ART. 6 ABS. 1 S. 1 LIT. E DSGVO BERUHT (DIES GILT AUCH FÜR EIN
              AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING.). WIR WERDEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
              EINSTELLEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE
              IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUSÜBUNG
              ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN DIENT.
            <p>
              NACH <b>ART. 21 ABS. 2</b> DSGVO HABEN SIE DAS RECHT JEDERZEIT WIDERSPRUCH GEGEN DIE VERWENDUNG IHRER
              PERSONENBEZOGENEN DATEN ZUM ZECKE DER DIREKTWERBUNG EINZULEGEN. DIES GILT AUCH FÜR DAS PROFILING, SOWEIT
              ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WIDERSPRECHEN SIE DER VERARBEITUNG FÜR ZWECKE DER
              DIREKTWERBUNG, SO WERDEN WIR IHRE PERSONENBEZOGENEN DATEN NICHT MEHR FÜR DIESE ZWECKE VERARBEITEN.
            </p>
            <p>
              <b>EINLEGUNG DES WIDERSPRUCHS</b>: DER WIDERSPRUCH KANN FORMLOS PER POST ODER E-MAIL ERKLÄRT WERDEN UND
              IST AN:
            </p>

            <p>planen-bauen 4.0 Gesellschaft zur Digitalisierung</p>
            <p>des Planens, Bauens und Betreibens mbH</p>
            <p>Geneststraße 5 / Aufgang A</p>
            <p>10829 Berlin</p>
            <p> Deutschland</p>
            <p>TEL. +49 30 22 45 20 40</p>
            <p>E-MAIL info@planen-bauen40.de</p>
            <p>ZU RICHTEN. </p>
          </ol>
        </ul>
        <li id="Bereitstellung" class="format-text"><h2>10 Besteht eine Pflicht zur Bereitstellung personenbezogener Daten?</h2>
        </li>
        <p>Wir machen den Abschluss von Verträgen mit uns nicht davon abhängig, dass Sie uns zuvor personenbezogene
          Daten bereitstellen. Für Sie als Nutzer besteht grundsätzlich auch keine gesetzliche oder vertragliche
          Verpflichtung, uns Ihre personenbezogenen Daten zur Verfügung zu stellen; es kann jedoch sein, dass wir
          bestimmte Angebote nur eingeschränkt oder gar nicht erbringen können, wenn Sie die dafür erforderlichen Daten
          nicht bereitstellen. </p>
        <li id="Entscheidungsfindung" class="format-text"><h2>11 Findet eine automatisierte Entscheidungsfindung
          (einschließlich Profiling)
          statt?</h2></li>
        <p>
          Wir haben nicht die Absicht, von Ihnen erhobene personenbezogene Daten für ein Verfahren zur automatisierten
          Entscheidungsfindung (einschließlich Profiling) zu verwenden.
        </p>
        <li id="Schutzmassnahmen" class="format-text"><h2>12 Existieren technische Schutzmaßnahmen?</h2></li>
        <p>
          Wir bedienen uns geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre personenbezogenen
          Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder
          gegen den unbefugten Zugriff Dritter zu schützen unter Berücksichtigung des Stands der Technik, der
          Implementierungskosten und der Natur, des Umfangs, des Kontextes und des Zwecks der Verarbeitung sowie der
          bestehenden Risiken einer Datenpanne (inklusive von deren Wahrscheinlichkeit und Auswirkungen) für den
          Betroffenen.
        </p>
        <p>
          Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von
          Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden
          Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben
          wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen
          auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten
          bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
          Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
        </p>
        <p>
          Sofern IP-Adressen von uns oder von den eingesetzten Dienstleistern und Technologien verarbeitet werden und
          die Verarbeitung einer vollständigen IP-Adresse nicht erforderlich ist, wird die IP-Adresse gekürzt (auch als
          "IP-Masking" bezeichnet). Hierbei werden die letzten beiden Ziffern, bzw. der letzte Teil der IP-Adresse nach
          einem Punkt entfernt, bzw. durch Platzhalter ersetzt. Mit der Kürzung der IP-Adresse soll die Identifizierung
          einer Person anhand ihrer IP-Adresse verhindert oder wesentlich erschwert werden.
        </p>
        <p>
          Um Ihre via unser Online-Angebot übermittelten Daten zu schützen, nutzen wir eine SSL-Verschlüsselung. Sie
          erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der Adresszeile Ihres Browsers.
        </p>
        <p>
          Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert. Nähere
          Informationen hierzu erteilen wir Ihnen auf Anfrage gerne.
        </p>
        <li id="Stand" class="format-text"><h2>13 Stand und Aktualisierung</h2></li>
        <p>
          Bitte beachten Sie, dass diese Datenschutzerklärung jederzeit von uns geändert werden kann, soweit dies
          erforderlich ist, um Sie angemessen über die Verarbeitung Ihrer personenbezogenen Daten zu informieren. Bitte
          prüfen Sie diese Datenschutzerklärung daher in regelmäßigen Abständen, soweit Sie unsere Dienstleistungen
          regelmäßig in Anspruch nehmen.
        </p>
        <p>
          Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen
          angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können und bitten die Angaben vor
          Kontaktaufnahme zu prüfen.
        </p>


        <p><b>Stand: 06.09.2021 </b></p>
      </ol>
    </div>
  </div>
</div>
