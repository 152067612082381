import { Component, OnInit, Input } from "@angular/core";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";

@Component({
  selector: "app-certification-detail-display-criteria",
  templateUrl: "./certification-detail-display-criteria.component.html",
  styleUrls: ["./certification-detail-display-criteria.component.css"],
})
export class CertificationDetailDisplayCriteriaComponent implements OnInit {
  @Input() certification: CertificationInterface;
  private selectedVersion: CertificationVariantVersionInterface;

  get fileIds() {
    return this.certification.documentFileIds;
  }

  constructor() {}

  ngOnInit() {}

  getDocumentsOfVariantVersion() {
    //TODO
  }
}
