import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";
import { inherits } from "util";
import { toolchainItemBase } from "../../util/preloadedAssets";
import { parseSvg } from "../../util/svgUtil";

/* Allows dynamic Parameter Injection */
ToolchainInstanceItemRender["$inject"] = ["eventBus"];

/* Custom Renderer for Toolchain Items */
export default function ToolchainInstanceItemRender(eventBus: any) {
  BaseRenderer.call(this, eventBus, 1500);

  /* Checks if the Renderer is able to render the Element */
  this.canRender = function (element) {
    return element.type === "composer:ToolchainInstanceItem";
  };

  /* Draws the Element */
  this.drawShape = function (parent, shape) {
    /* Reads the Attributes of the BusinessObject */
    const task = shape.businessObject;
    const productName = task.productName;
    const itemName = task.itemName;
    const dataUrl = task.imageDataUrl;
    const status = task.status;

    let color = "black";

    switch (status) {
      case "SUCCESS":
        color = "#94c028";
        break;
      case "FAILED":
        color = "#ec6c6e";
        break;
      case "WORKING":
        color = "#61bdae";
        break;
    }

    let svg = toolchainItemBase;
    svg = modifySvg(svg, productName, itemName, dataUrl, color);

    let image = parseSvg(svg).firstChild;
    image = document.importNode(image, true);

    parent.appendChild(image);

    return image;
  };
}

/* Modifies the SVG String and adds the Productname, Itemname and the Product Image as new SVG Tags */
function modifySvg(
  svg: string,
  productName: string,
  itemName: string,
  imageDataSource: string,
  newItemColor: string
): string {
  const endofSvg: number = svg.indexOf("</svg>");

  /* Shortens the Names if they are too long to be fully displayed */
  if (itemName.length > 18) {
    itemName = itemName.substring(0, 14) + " ...";
  }
  if (productName.length > 18) {
    productName = productName.substring(0, 14) + " ...";
  }
  const image =
    imageDataSource !== ""
      ? '<image href="' +
        imageDataSource +
        '" height="55" width="92" x="5" y="18" />'
      : "";

  return (
    svg
      .substring(0, endofSvg)
      .replace("stroke:black", "stroke:" + newItemColor) +
    '<text id="itemName" x="10" y="15">' +
    itemName +
    "</text>" +
    image +
    svg.substring(endofSvg, svg.length)
  );
}

inherits(ToolchainInstanceItemRender, BaseRenderer);
