<div class="two-columns">
  <div>
    <h1 *ngIf="isCreateNew; else edit">Zertifizierungsversion anlegen</h1>
    <ng-template #edit>
      <h1>Zertifizierungsversion bearbeiten</h1>
    </ng-template>
    <app-version-edit
      [version]="version"
      [dataType]="'CERTIFICATION_VERSION'"
      (validityChanged)="onValidityChanged($event)"
    ></app-version-edit>
    <mat-form-field class="validity" hintLabel="Gültigkeit des Zertifikats">
      <mat-select
      placeholder="Gültigkeit"
      [(ngModel)]="version.validity"
      >
      <mat-option [value]="0">(unbegrenzt)</mat-option>
      <mat-option [value]="1">1 Jahr</mat-option>
      <mat-option [value]="2">2 Jahre</mat-option>
      <mat-option [value]="3">3 Jahre</mat-option>
      <mat-option [value]="4">4 Jahre</mat-option>
      <mat-option [value]="5">5 Jahre</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="validity" hintLabel="Erste Erinnerung an den Ablauf des Zertifikats">
      <mat-select
        placeholder="1. Erinnerung"
        [(ngModel)]="version.firstReminder"
        name="firstReminder"
      >
      <mat-option [value]="0">Keine Erinnerung</mat-option>
      <mat-option [value]="1">1 Monat vorher</mat-option>
      <mat-option [value]="2">2 Monate vorher</mat-option>
      <mat-option [value]="3">3 Monate vorher</mat-option>
      <mat-option [value]="4">4 Monate vorher</mat-option>
      <mat-option [value]="5">5 Monate vorher</mat-option>
      <mat-option [value]="6">6 Monate vorher</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="validity" hintLabel="Zweite Erinnerung an den Ablauf des Zertifikats">
      <mat-select
        placeholder="2. Erinnerung"
        [(ngModel)]="version.secondReminder"
        name="secondReminder"
      >
        <mat-option [value]="0">Keine Erinnerung</mat-option>
        <mat-option [value]="1">1 Monat vorher</mat-option>
        <mat-option [value]="2">2 Monate vorher</mat-option>
        <mat-option [value]="3">3 Monate vorher</mat-option>
        <mat-option [value]="4">4 Monate vorher</mat-option>
        <mat-option [value]="5">5 Monate vorher</mat-option>
        <mat-option [value]="6">6 Monate vorher</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="validity" hintLabel="Dritte Erinnerung an den Ablauf des Zertifikats">
      <mat-select
        placeholder="3. Erinnerung"
        [(ngModel)]="version.thirdReminder"
        name="thirdReminder"
      >
      <mat-option [value]="0">Keine Erinnerung</mat-option>
      <mat-option [value]="1">1 Monat vorher</mat-option>
      <mat-option [value]="2">2 Monate vorher</mat-option>
      <mat-option [value]="3">3 Monate vorher</mat-option>
      <mat-option [value]="4">4 Monate vorher</mat-option>
      <mat-option [value]="5">5 Monate vorher</mat-option>
      <mat-option [value]="6">6 Monate vorher</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="mini-grid-buttons">
      <button
        mat-raised-button
        class="btn_green"
        (click)="createVersion()"
        [disabled]="!isFormValid || !isInputValid()"
      >
        Speichern
      </button>
      <button mat-raised-button class="btn_red" (click)="cancelCreateVersion()">
        Abbrechen
      </button>
    </div>
  </div>
</div>
<ng-template #loading>
  <p>Loading data</p>
</ng-template>
