import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { Subscription } from 'rxjs';
import { ComposerToolchainInstanceOverview } from "src/app/model/composer/composer-toolchain-instance-overview";
import { ComposerToolchainTemplateOverview } from "src/app/model/composer/composer-toolchain-overview-interface";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { OrganisationMinimalInterface } from "src/app/model/organisations/organisation-minimal-interface";
import { OrganisationMemberInterface } from 'src/app/model/organisations/organisationMember-interface';
import { ComposerService } from "src/app/services/composer-service/composer.service";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { HeaderServiceService } from "src/app/services/header-service/header-service.service";
import { UserService } from "src/app/services/user-service/userservice";
import { loadIcons } from '../model-editor/util/preloadedAssets';
import {DialogService} from "../../../services/dialog-service/dialog-service.service";

@Component({
  selector: "app-composer-organisation-screen",
  templateUrl: "./composer-organisation-screen.component.html",
  styleUrls: ["./composer-organisation-screen.component.css"],
})
export class ComposerOrganisationScreenComponent implements OnInit {
  public toolchainTemplates: ComposerToolchainTemplateOverview[];
  public toolchainInstances: ComposerToolchainInstanceOverview[];

  public selectedOrganisation: OrganisationInterface;

  public availableOrganisations: OrganisationInterface[] = [];

  private membershipSubscription: Subscription;

  constructor(
    private composerService: ComposerService,
    private changeDetector: ChangeDetectorRef,
    private headerService: HeaderServiceService,
    private userService: UserService,
    private dialogService: DialogService
  ) {
    //Preloads the Icons used within the BPMN Viewer
    loadIcons().subscribe();

    this.membershipSubscription = this.userService.currentMembershipsObserver.subscribe(
      (change) => {
        this.availableOrganisations = this.userService.currentMemberships;
      }
    );
  }

  ngOnInit() {
    this.headerService.loadHeaderText("Composer");
  }

  ngOnDestroy() {
    this.membershipSubscription.unsubscribe();
  }

  public deleteToolchainTemplate(templateId) {
    this.dialogService.openConfirmDialog("Toolchain-Template Löschen", "Abbrechen", "Löschen", "Wollen Sie das Template wirklich löschen?")
      .subscribe((res) => {
        if (res) {
          this.composerService.deleteToolchainTemplate(templateId)
            .subscribe(() => {
              this.reloadToolchains();
            });
        }
      });
  }

  public deleteToolchain(toolchainId) {
    this.dialogService.openConfirmDialog("Toolchain-Instanz Löschen", "Abbrechen", "Löschen", "Wollen Sie die Instanz wirklich löschen?")
      .subscribe((res) => {
        if (res) {
          this.composerService.deleteToolchain(toolchainId)
            .subscribe(() => {
              this.reloadToolchains();
            });
        }
      });
  }

  /* Changes the Selected Organisation to the given one */
  public onSelectedOrganisationChange(event: MatSelectChange) {
    if (event.value) {
      this.selectedOrganisation = event.value;
      this.reloadToolchains();
    }
  }

  /* Reloads the currently shown Templates */
  public reloadToolchains() {
    this.toolchainTemplates = null;
    this.composerService
      .getToolchainTemplatesForOrganisation(
        this.selectedOrganisation.organisationId
      )
      .subscribe((toolchainTemplates) => {
        if (toolchainTemplates) {
          this.toolchainTemplates = toolchainTemplates;
          if (!this.changeDetector["destroyed"]) {
            this.changeDetector.detectChanges();
          }
        }
      });

    this.composerService
      .getToolchainInstancesForOrganisation(
        this.selectedOrganisation.organisationId
      )
      .subscribe((toolchainInstances) => {
        if (toolchainInstances) {
          this.toolchainInstances = toolchainInstances;
          if (!this.changeDetector["destroyed"]) {
            this.changeDetector.detectChanges();
          }
        }
      });
  }
}
