import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DataFormatDTO } from "src/app/model/compatiblity/dto/data-format-dto";
import { FileFormatDTO } from "src/app/model/compatiblity/dto/file-format-dto";
import { SchemaDTO } from "src/app/model/compatiblity/dto/schema-dto";
import { FileFormat } from "src/app/model/compatiblity/file-format-interface";
import { Schema } from "src/app/model/compatiblity/schema-interface";
import { DataFormatService } from "src/app/services/compatiblity-service/data-format-service.service";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";

@Component({
  selector: "app-edit-data-format-screen",
  templateUrl: "./edit-data-format-screen.component.html",
  styleUrls: ["./edit-data-format-screen.component.css"],
})
export class EditDataFormatScreenComponent {
  /**
   * Holds the id of the currently editted @type {DataFormat}
   * or NAN if it wasn't provided through the routes parameters
   */
  dataFormatId = +this.route.snapshot.params["dataFormatId"];
  /**
   * Indicates, whether this screen is currently editing, or creating a DataFormat,
   * derived from if the activated route has a "dataFormatId" param
   */
  public isEdit: boolean = this.route.snapshot.paramMap.has("dataFormatId");

  public form: FormGroup;

  public showAddSchemaForm: boolean = false;
  public showAddFileFormatForm: boolean = false;

  public addSchemaFormGroup: FormGroup;
  public addFileFormatFromGroup: FormGroup;

  public schemas: (Schema | SchemaDTO)[] = [];
  public fileFormats: (FileFormat | FileFormatDTO)[] = [];

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private dataFormatService: DataFormatService
  ) {
    this.form = fb.group({
      name: ["", Validators.required],
      description: [""],
      active: [false],
    });
  }

  ngOnInit(): void {
    if (this.isEdit) {
      // if the current screen is used to edit an existing dataformat,
      //  1. fetch the corresponding entity from the service
      //  2. Set its values into the form
      this.dataFormatService
        .getDataFormat(this.dataFormatId)
        .subscribe((dataFormat) => {
          this.form.setValue({
            name: dataFormat.name,
            description: dataFormat.description,
            active: dataFormat.active,
          });
          this.schemas = dataFormat.schemas;
          this.fileFormats = dataFormat.fileFormats;
        });
    }
  }

  /**
   * Callback handler that is called whenever the text of the <app-text-editor>
   * used for the description changed.
   * @param text the new text
   */
  public onDescriptionTextChange(text: string): void {
    this.form.controls.description.setValue(text);
  }

  /**
   * Function used to toggle the "showAddSchemaForm" variable and
   * thus open the "add schema" form of the component and initialize
   * the addSchemaFormGroup with default values.
   *
   * If the form is currently open, this function will close it and vice-versa.
   *
   * @param forceOpen Optional - if supplied and true, the form will open or
   *                  stay open if it is already open
   */
  public toggleAddSchemaForm(forceOpen?: boolean) {
    this.showAddSchemaForm = forceOpen ? true : !this.showAddSchemaForm;
    // Since newly added schemas are only transferred to the backend
    // upon saving a dataformat not all schemas that appear in the
    // table have an id.
    // To cover this case, also add an index, that is passed to the submit
    // function for it to handle the updating of "not-yet-submitted" schemas
    this.addSchemaFormGroup = this.fb.group({
      id: [null],
      index: [null],
      name: ["", Validators.required],
      version: [""],
      subSchema: [""],
    });
  }

  /**
   * Callback function that adds a new @type {Schema} if the form
   * was used to create a new entity or @type {SchemaDTO} if the form
   * was used to edit an existing schema
   */
  public async handleAddSchemaSubmit() {
    if (this.addSchemaFormGroup.invalid) {
      return;
    }
    const values = this.addSchemaFormGroup.value;
    // If there is no value passed into "index" assume,
    // that the current schema form is creating a new schema
    // Else: Current form is editing a schema
    const isEditSchema = values.index !== null;
    const schema: SchemaDTO = {
      id: values.id,
      name: values.name,
      version: values.version,
      subSchema: values.subSchema,
    };
    if (isEditSchema) {
      // If the form was used to edit a schema, use the index, that was passed
      // in the form to update the schema at that index
      const schemaIndex = values.index;
      const updatedSchemas = this.schemas;
      updatedSchemas[schemaIndex] = schema;
      this.schemas = [...updatedSchemas];
    } else {
      // Add the schema to the list of schemas
      this.schemas = [...this.schemas, schema];
    }

    this.toggleAddSchemaForm();
  }

  /**
   * Function used to toggle the "showAddFileFormatForm" variable and
   * thus open the "add file format" form of the component and initialize
   * the addFileFormatFromGroup with default values.
   *
   * If the form is currently open, this function will close it and vice-versa.
   *
   * @param forceOpen Optional - if supplied and true, the form will open or
   *                  stay open if it is already open
   */
  public toggleAddFileFormatForm(forceOpen?: boolean) {
    this.showAddFileFormatForm = forceOpen ? true : !this.showAddFileFormatForm;
    this.addFileFormatFromGroup = this.fb.group({
      id: [null],
      index: [null],
      name: ["", Validators.required],
      mimeType: ["", Validators.required],
    });
  }

  /**
   * Opens the "schema" form and sets its values to the referenced schema's
   * values.
   *
   * @param schemaIndex The index of the referenced schema in the schemas array
   */
  public editSchema(schemaIndex: number) {
    const schema = this.schemas[schemaIndex];
    if (!schema) {
      throw Error("Could not find schema at index " + schemaIndex);
    }
    this.toggleAddSchemaForm(true);
    this.addSchemaFormGroup.setValue({
      index: schemaIndex,
      id: schema.id,
      name: schema.name,
      version: schema.version,
      subSchema: schema.subSchema,
    });
  }

  /**
   * Removes the schema that is at the supplied index from the schemas array
   * and closes the "schema" form if it is currently editing the deleted schema
   *
   * @param schemaIndex The index of the schema-to-be-deleted in the schemas array
   */
  public deleteSchema(schemaIndex: number) {
    this.schemas = this.schemas.filter((_, index) => index !== schemaIndex);
    if (this.addSchemaFormGroup) {
      const indexControl = this.addSchemaFormGroup.get("index");
      if (indexControl && indexControl.value === schemaIndex) {
        this.toggleAddSchemaForm();
      }
    }
  }

  /**
   * Callback function that adds a new @type {FileFormat} if the form
   * was used to create a new entity or @type {FileFormatDTO} if the form
   * was used to edit an existing file format
   */
  public async handleAddFileFormatSubmit() {
    if (this.addFileFormatFromGroup.invalid) {
      return;
    }
    const values = this.addFileFormatFromGroup.value;
    const isEditFileFormat = values.index !== null;
    const fileFormat: FileFormatDTO = {
      id: values.id,
      name: values.name,
      mimeType: values.mimeType,
    };

    if (isEditFileFormat) {
      // If the form was used to edit a schema, use the index, that was passed
      // in the form to update the schema at that index
      const fileFormatIndex = values.index;
      const updatedFileFormats = this.fileFormats;
      updatedFileFormats[fileFormatIndex] = fileFormat;
      this.fileFormats = [...updatedFileFormats];
    } else {
      // Add the schema to the list of schemas
      this.fileFormats = [...this.fileFormats, fileFormat];
    }

    this.toggleAddFileFormatForm();
  }
  /**
   * Opens the "file format" form and sets its values to the referenced file format's
   * values.
   *
   * @param fileFormatIndex The index of the referenced file format in the fileFormats array
   */
  public editFileFormat(fileFormatIndex: number) {
    if (!this.showAddFileFormatForm) {
      this.toggleAddFileFormatForm(true);
    }
    const fileFormat = this.fileFormats[fileFormatIndex];
    if (!fileFormat) {
      throw Error("Could not find file format at index " + fileFormatIndex);
    }
    this.addFileFormatFromGroup.setValue({
      id: fileFormat.id,
      index: fileFormatIndex,
      name: fileFormat.name,
      mimeType: fileFormat.mimeType,
    });
  }

  /**
   * Removes the file format that is at the supplied index from the fileFormats array
   * and closes the "file format" form if it is currently editing the deleted file format
   *
   * @param schemaIndex The index of the file format-to-be-deleted in the schemas array
   */
  public deleteFileFormat(fileFormatIndex: number) {
    this.fileFormats = this.fileFormats.filter(
      (_, index) => index !== fileFormatIndex
    );
    if (this.addFileFormatFromGroup) {
      // To prevent saving a deleted file format
      const indexControl = this.addFileFormatFromGroup.get("index");
      if (indexControl && indexControl.value === fileFormatIndex) {
        this.toggleAddFileFormatForm();
      }
    }
  }

  /**
   * Opens a dialog asking the user if they really want to cancel the form submission
   * and navigates to the dataformats overview page if confirmed.
   */
  public handleCancelClick() {
    this.dialogService
      .openConfirmDialog(
        "Abbrechen?",
        "Nein",
        "Ja, abbrechen",
        "Dadurch gehen alle ungespeicherten Änderungen verloren"
      )
      .subscribe((result) => {
        if (result) {
          this.router.navigate(["profil", "dataformats"]);
        }
      });
  }

  /**
   * Function used to update the currently editted dataFormat entity
   * or create a new entity on the server.
   */
  public save() {
    const result: DataFormatDTO = {
      id: this.dataFormatId,
      name: this.form.get("name").value,
      description: this.form.get("description").value,
      active: this.form.get("active").value,
      schemas: this.schemas,
      fileFormats: this.fileFormats,
    };
    let updateObservable;
    if (this.isEdit) {
      updateObservable = this.dataFormatService.updateDataFormat(
        this.dataFormatId,
        result
      );
    } else {
      updateObservable = this.dataFormatService.createDataFormat(result);
    }
    updateObservable.subscribe(
      () => {
        this.router.navigate(["profil", "dataformats"]);
      },
      (err) => {
        this.dialogService.openDialog(
          "Fehler beim Speichern",
          "Es gab einen Fehler beim Speichern des Datenformats. Bitte versuchen Sie es später nochmal."
        );
      }
    );
  }

  /**
   * Getter for the "name" formControl of "form"
   */
  get name() {
    return this.form.get("name");
  }
}
