// ssh.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SshService {

  constructor(private http: HttpClient) { }

  executeCommand(commandData: FormData) {
    return this.http.post<any>('http://research.bimswarm.online:1000/execute', commandData);
  }
}
