import { ProductOverview } from 'src/app/model/product-overview/product-overview';
import { PtgInterface } from 'src/app/model/ptgs/ptg-interface';
import { productImageDataUrls } from "../util/preloadedAssets";

/* Registration */
export function CustomPalette(
  palette,
  create,
  elementFactory,
  spaceTool,
  lassoTool,
  handTool,
  globalConnect,
  showMyProducts,
  myProducts,
  selectedOrganisationProducts
) {
  this.create = create;
  this.elementFactory = elementFactory;
  this.spaceTool = spaceTool;
  this.lassoTool = lassoTool;
  this.handTool = handTool;
  this.globalConnect = globalConnect;
  this.showMyProducts = showMyProducts;
  this.palette = palette;
  this.myProducts = myProducts;
  this.selectedOrganisationProducts = selectedOrganisationProducts;

  palette.registerProvider(this);
}

/* Allows dynamic Parameter Injection */
CustomPalette["$inject"] = [
  "palette",
  "create",
  "elementFactory",
  "spaceTool",
  "lassoTool",
  "handTool",
  "globalConnect",
  "showMyProducts",
  "myProducts",
  "selectedOrganisationProducts",
];

/* Definies which Elements are shown in the Palette */
CustomPalette.prototype.getPaletteEntries = function (element: any) {
  const create = this.create;
  const elementFactory = this.elementFactory;
  const spaceTool = this.spaceTool;
  const lassoTool = this.lassoTool;
  const handTool = this.handTool;
  const globalConnect = this.globalConnect;
  const showMyProducts: boolean = this.showMyProducts;
  const myProducts: ProductOverview[] = this.myProducts;
  const selectedOrganisationProducts: ProductOverview[] = this.selectedOrganisationProducts;

  const elements = {};

  /* Function to create an Element that creates a Shape */
  function createShapeElement(
    type: string,
    group: string,
    className: string,
    title: string,
    options: any
  ) {
    /* Function called when the Element is placed in the Editor */
    function createElement(event) {
      const shape = elementFactory.createShape(
        Object.assign({ type: type }, options)
      );
      if (options) {
        shape.businessObject.di.isExpanded = options.isExpanded;
      }
      create.start(event, shape);
    }

    return {
      group: group,
      className: className,
      title: title,
      action: {
        dragstart: createElement,
        click: createElement,
      },
    };
  }

  /* Function to create an custom Toolchain Task Element */
  function createToolchainTask(dataUrl: string, title: string, productId: string) {
    /* Function called when the Element is placed in the Editor */
    function createElement(event) {
      const shape = elementFactory.createShape(
        Object.assign({ type: "composer:ToolchainItem" }, {})
      );

      //Applies the Attributes to the BusinessObject
      applyCorrectState(shape, title, dataUrl, productId);

      create.start(event, shape);
    }

    return {
      group: "task",
      html: buildHTMLForToolchainTask(dataUrl, title),
      title: title,
      action: {
        dragstart: createElement,
        click: createElement,
      },
    };
  }

  /* Function to create an Toolchain Task Element in the standard Palette */
  function createToolchainTaskForPalette(dataUrl: string, title: string, productId: string){
    /* Function called when the Element is placed in the Editor */
    function createElement(event) {
      const shape = elementFactory.createShape(
        Object.assign({ type: "composer:ToolchainItem" }, {})
      );

      //Applies the Attributes to the BusinessObject
      applyCorrectState(shape, title, dataUrl, productId);

      create.start(event, shape);
    }

    return {
      group: "event",
      className: "bpmn-icon-task",
      title: title,
      action: {
        dragstart: createElement,
        click: createElement,
      },
    };
  }

  /* Fills the Palette List with Icon Elements */
  appendStandardPalette(
    elements,
    handTool,
    lassoTool,
    spaceTool,
    globalConnect,
    createShapeElement,
    createToolchainTaskForPalette
  );

  if (showMyProducts === true) {
    if (myProducts.length > 0 && productImageDataUrls.length > 0) {
      createElementsForProducts(myProducts, elements, createToolchainTask);
    } else {
      createFillerElement(elements);
    }
  } else {
    //Adds spacing for the Organisation Select
    Object.assign(elements, {
      "second-separator": {
        group: "horizontalSeparator",
        separator: true,
      },
    });

    if (
      selectedOrganisationProducts.length > 0 &&
      productImageDataUrls.length > 0
    ) {
      createElementsForProducts(
        selectedOrganisationProducts,
        elements,
        createToolchainTask
      );
    } else {
      createFillerElement(elements);
    }
  }

  return elements;
};

/* Custom Update Method to refresh the Parameter and update the Palette */
CustomPalette.prototype.update = function (
  showMyProducts: boolean,
  selectedOrganisationProducts: ProductOverview[],
  myProducts: ProductOverview[]
) {
  this.showMyProducts = showMyProducts;
  this.selectedOrganisationProducts = selectedOrganisationProducts;
  this.myProducts = myProducts;
  // Internal update Method
  this.palette._update();
};

/* Updates the Properties of the Shape to the given Properties */
export function applyCorrectState(shape: any, title: string, dataUrl: string, productId: string) {
  shape.businessObject.productName = title;
  shape.businessObject.productId = productId;
  shape.businessObject.imageDataUrl = dataUrl;
  shape.businessObject.itemName =
    title != "" ? title + " Item" : "Toolchain Item";
  shape.businessObject.toolchainItemId = 1; //TODO Change to variable
  shape.businessObject.description = "Dies ist eine Beschreibung"; //TODO Change to variable
  shape.businessObject.selectedPTGs = "";
}

/* Creates an Element to keep the Palette extended - The Element itself is not visible */
function createFillerElement(elements: {}) {
  Object.assign(elements, {
    filler: {
      group: "filler",
      separator: true,
    },
  });
}

/* Builds a custom Div with an Image and a Title to be displayed as an Element */
function buildHTMLForToolchainTask(dataUrl: string, title: string) {
  const image =
    dataUrl !== ""
      ? "<img style='width: 80%; height: 10vh; -webkit-user-drag: none; user-select: none; margin-left: 10%;' src=" +
        dataUrl +
        ">"
      : "";

  return (
    "<div class='entry' style='width: 80%; height: 14vh; border: 2px solid black; border-radius: 25px; margin-left: 10%; margin-top: 1.5vh; text-align: left; line-height: 20px' draggable='true'><div style='text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 90%; margin-top: 0.5vh;'><span style='font-size: 0.6em; margin-left: 10%; color: black'>" +
    title +
    "</span></div>" +
    image +
    "</div>"
  );
}

/* Creates and adds Elements for every Product given */
function createElementsForProducts(
  products: ProductOverview[],
  elements: {},
  createToolchainTask: (
    dataUrl: string,
    title: string,
    productId: string,
  ) => {
    group: string;
    html: string;
    title: string;
    action: { dragstart: (event: any) => void; click: (event: any) => void };
  }
) {
  products.forEach((product, index) => {
    elements["create.toolchain-task-" + index] = createToolchainTask(
      productImageDataUrls.find((element) => element.fileId === product.fileId)
        ? productImageDataUrls.find(
            (element) => element.fileId === product.fileId
          ).dataUrl
        : "",
      product.productName,
      product.productId.toString()
    );
  });
}

/* Creates and assigns all Elements of the standard Palette */
function appendStandardPalette(
  elements: {},
  handTool: any,
  lassoTool: any,
  spaceTool: any,
  globalConnect: any,
  createShapeElement: (
    type: string,
    group: string,
    className: string,
    title: string,
    options: any
  ) => {
    group: string;
    className: string;
    title: string;
    action: { dragstart: (event: any) => void; click: (event: any) => void };
  },
  createToolchainTask: (
    dataUrl: string,
    title: string,
    productId: string,
  ) => {
    group: string;
    className: string;
    title: string;
    action: { dragstart: (event: any) => void; click: (event: any) => void };
  }
) {
  Object.assign(elements, {
    "hand-tool": {
      group: "tools",
      className: "bpmn-icon-hand-tool",
      title: "Hand-Tool aktivieren",
      action: {
        click: function (event) {
          handTool.activateHand(event);
        },
      },
    },
    "lasso-tool": {
      group: "tools",
      className: "bpmn-icon-lasso-tool",
      title: "Lasso-Tool aktivieren",
      action: {
        click: function (event) {
          lassoTool.activateSelection(event);
        },
      },
    },
    "space-tool": {
      group: "tools",
      className: "bpmn-icon-space-tool",
      title: "Space-Tool aktivieren",
      action: {
        click: function (event) {
          spaceTool.activateSelection(event);
        },
      },
    },
    "global-connect-tool": {
      group: "tools",
      className: "bpmn-icon-connection",
      title: "Verbindungs-Tool aktivieren",
      action: {
        click: function (event) {
          globalConnect.toggle(event);
        },
      },
    },
    "vertical-separator": {
      group: "verticalSeparator",
      separator: true,
    },
    "create.start-event": createShapeElement(
      "bpmn:StartEvent",
      "event",
      "bpmn-icon-start-event-none",
      "Start-Event erstellen",
      {}
    ),
    "create.fork-event": createShapeElement(
      "composer:ForkEvent",
      "event",
      "composer-fork-palette-icon",
      "Fork-Event erstellen",
      {}
    ),
    "create.task": createToolchainTask("", "", ""),
    "create.end-event": createShapeElement(
      "bpmn:EndEvent",
      "event",
      "bpmn-icon-end-event-none",
      "End-Event erstellen",
      {}
    ),
    "create.join-event": createShapeElement(
      "composer:JoinEvent",
      "event",
      "composer-join-palette-icon",
      "Join-Event erstellen",
      {}
    ),
    "create.dataReference": createShapeElement(
      "bpmn:DataObjectReference",
      "event",
      "bpmn-icon-data-object",
      "Daten-Referenz erstellen",
      {}
    ),
    "horizontal-separator": {
      group: "horizontalSeparator",
      separator: true,
    },
  });
}
