import { FormControl, FormGroup, Validators } from '@angular/forms';
import {NAME_CHAR_VALIDATION_REGEX, ORGANISATION_CHAR_VALIDATION_REGEX} from './constants';
import { environment } from './environment';

export function emailValidator(control: FormControl): { [s: string]: boolean } {
  if (
    // yeah... sure...
    !control.value.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    return { invalidEmail: true };
  }
}

function firstNameLengthValidator(
  control: FormControl
): { [s: string]: boolean } {
  if (control.value.length > 60) {
    return { firstnamelength: true };
  }
}

function firstNameCharacterValidator(
  control: FormControl
): { [s: string]: boolean } {
  if (!control.value.match(NAME_CHAR_VALIDATION_REGEX)) {
    return { firstNameInvalidCharacter: true };
  }
}

function lastNameLengthValidator(
  control: FormControl
): { [s: string]: boolean } {
  if (control.value.length > 60) {
    return { lastnamelength: true };
  }
}

function lastNameCharacterValidator(
  control: FormControl
): { [s: string]: boolean } {
  if (!control.value.match(NAME_CHAR_VALIDATION_REGEX)) {
    return { lastNameInvalidCharacter: true };
  }
}

function passwordLengthValidator(
  control: FormControl
): { [s: string]: boolean } {
  if (control.value.length < 6) {
    return { passwordlength: true };
  }
}

function passwordSpecialCharValidator(
  control: FormControl
): { [s: string]: boolean } {
  if (!control.value.match(environment.specialCharacterRegex)) {
    return { passwordSpecialChar: true };
  }
}

function passwordNumberValidator(
  control: FormControl
): { [s: string]: boolean } {
  if (!control.value.match(/[0-9]+/)) {
    return { passwordNumber: true };
  }
}
function confirmPasswordValidator(group: FormGroup): { [s: string]: boolean } {
  if (group.controls.password.value !== group.controls.confirmpassword.value) {
    return { notEqual: true };
  }
}

function agbAcceptionValidator(control: FormControl): { [s: string]: boolean } {
  if (control.dirty && !control.value) {
    return { agbAcception: true };
  }
}

function positonLengthValidator(control: FormGroup): {[s: string]: boolean} {
  if (control.value.length > 60) {
    return { positionLength: true };
  }
}

function urlLengthValidator(control: FormGroup): {[s: string]: boolean} {
  if (control.value.length > 60) {
    return { websiteLength: true };
  }
}

function organizationLengthValidator(control: FormGroup): {[s: string]: boolean} {
  if (control.value.length > 60) {
    return { organizationLength: true };
  }
}

function organizationCharacterValidator(
  control: FormControl
): { [s: string]: boolean } {
  if (!control.value.match(ORGANISATION_CHAR_VALIDATION_REGEX)) {
    return { organizationInvalidCharacter: true };
  }
}

function positionCharacterValidator(
  control: FormControl
): { [s: string]: boolean } {
  if (!control.value.match(NAME_CHAR_VALIDATION_REGEX) && control.value.length > 0) {
    return { positionInvalidCharacter: true };
  }
}

export const VALIDATORS = {
  email: Validators.compose([Validators.required, emailValidator]),
  firstname: Validators.compose([
    Validators.required,
    firstNameLengthValidator,
    firstNameCharacterValidator,
  ]),
  lastname: Validators.compose([
    Validators.required,
    lastNameLengthValidator,
    lastNameCharacterValidator,
  ]),
  password: Validators.compose([
    Validators.required,
    passwordLengthValidator,
    passwordSpecialCharValidator,
    passwordNumberValidator,
  ]),
  agbAcception: agbAcceptionValidator,
  registryForm: confirmPasswordValidator,
  organization: Validators.compose([
    Validators.required,
    organizationLengthValidator,
    organizationCharacterValidator
  ]),
  position: Validators.compose([
    positonLengthValidator,
    positionCharacterValidator]),
  website: Validators.compose([urlLengthValidator])
};
