import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
} from "@angular/core";

@Component({
  selector: "app-review-table",
  templateUrl: "./review-table.component.html",
  styleUrls: ["./review-table.component.css"],
})
export class ReviewTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  reviewData: number[];

  public data: { name: string; value: number }[];

  view: any[] = [, 125];

  colorScheme = {
    domain: ["#025958"],
  };

  constructor(private el: ElementRef, private cd: ChangeDetectorRef) {}

  onSelect(data): void {}

  onActivate(data): void {}

  onDeactivate(data): void {}

  ngAfterViewInit(): void {
    this.view[0] = this.el.nativeElement.parentElement.offsetWidth;
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setData();
  }

  ngOnInit() {
    this.setData();
  }

  private setData(): void {
    this.data = [];
    for (let i = 5; i > 0; i--) {
      this.data.push({ name: "" + i, value: this.reviewData[i - 1] });
    }
  }
}
