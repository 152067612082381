import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-composer-product-filter-element",
  templateUrl: "./composer-product-filter-element.component.html",
  styleUrls: ["./composer-product-filter-element.component.css"],
})
export class ComposerProductFilterElementComponent implements OnInit {
  @Input()
  public filterName: string;

  @Input()
  public options: FilterEntry[];

  @Input()
  public selected: FilterEntry[];

  @Output()
  public onSelectedChange = new EventEmitter<FilterEntry[]>();

  public selectedValue: FilterEntry;

  constructor() {}

  ngOnInit() {}

  /* Adds an Entry to the Selected Filter Options */
  public addEntryToSelected() {
    if (this.selectedValue && this.options.length > 0) {
      this.options.splice(this.options.indexOf(this.selectedValue), 1);
      this.selected.push(this.selectedValue);
      this.selectedValue = undefined;
      this.onSelectedChange.emit(this.selected);
    }
  }

  /* Removes an Entry from the selected Filter Options */
  public removeEntryFromSelected(entry: FilterEntry) {
    this.selected.splice(this.selected.indexOf(entry), 1);
    this.options.push(entry);
    this.onSelectedChange.emit(this.selected);
  }
}

export class FilterEntry {
  id: number;
  name: string;
  permanent?: boolean;
}
