<div class="newTemplate" (click)="addNewTemplate()">
    <div class="templateButton">
        <hexagon
            [icon]="'plus'"
            [hover]="true"
            [outerColor]="'darkgreen'"
        ></hexagon>
    </div>
    <div class="label"><h3>Neues Template</h3></div>
</div>
<br />
<div class="process-list-container">
  <h1>Wählen Sie eine Dienstkette aus, um den Prozess zu starten</h1>
  <div *ngIf="!processDefinition || processDefinition.length === 0" class="no-toolchains">
    <h1>Keine Toolchains bereitgestellt...</h1>
  </div>
  <div *ngFor="let process of processDefinition" class="process-item">
    <div class="process-details">
      <h3 (click)="navigateToStartProcess(process.id, process)">
        Dienstkette {{ process.name }} - Version {{ process.version }}
      </h3>
    </div>
    <div class="bpmn-diagram-container" *ngIf="process.diagram?.bpmn20Xml">
      <app-bpmn-diagram [xml]="process.diagram.bpmn20Xml"></app-bpmn-diagram>
    </div>
    <div *ngIf="!process.diagram?.bpmn20Xml">
      <p>Für diesen Prozess ist kein BPMN-XML verfügbar....</p>
    </div>
  </div>
</div>
<app-websocket #websocket></app-websocket>
