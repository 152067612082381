<div *ngIf="requestSuccess">

  <div fxLayout="row wrap" fxLayoutGap="16px grid">
    <div *ngFor="let shape of shapes" fxFlex="40%" fxFlex.sm="40%" fxFlex.xs="100%">
      <button (click)="navigate(shape.schema)" [matTooltip]="shape.toolTipText" class="shape" color="primary"
              mat-stroked-button>
        <mat-icon>library_add</mat-icon>
        {{shape.name}}</button>

    </div>
  </div>
</div>
<div class="error-request">
  <mat-error *ngIf="!requestSuccess">{{'select-shape.error' | translate}}</mat-error>
</div>
