import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { ProductService } from "../services/dataServices/product-service/productService";

@Injectable()
export class ProductListResolver implements Resolve<any> {
  constructor(
    private productService: ProductService,
    private router: Router,
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    let pro = parseInt(route.paramMap.get("productId"));
    if (pro) {
      await this.productService.setActiveProduct(pro).catch((e) => {
        this.router.navigate([
          "/organisations/" +
            route.paramMap.get("organisationId") +
            "/products",
        ]);
      });
    }
    return;
  }
}
