import { Pipe, PipeTransform } from '@angular/core';
import { ProductInterface } from '../model/products/product-interface';


@Pipe({ name: 'filterCertificates' })
export class FilterCertificatesOfProducts implements PipeTransform {
  transform(products: ProductInterface[], certificate: number[]) {

    if (null == certificate || certificate.length == 0) {
      return products;
    }

    const filteredProducts = [];

    for (const product of products) {
      for (const cert of certificate) {
        if ((cert == null) == product.certificat) {
          if (!filteredProducts.includes(product)) {
            filteredProducts.push(product)
          }
        }
      }
    }
    return filteredProducts;
  }
}
