<div class="two-columns">
  <div *ngIf="newPtg.organisationId">
    <h1>Produkttyp anlegen</h1>
    <form #form="ngForm">
      <mat-form-field>
        <input
          matInput
          placeholder="Organisation"
          [value]="organisationService.activeItem.organisationName"
          disabled
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Name des Produkttypen"
          [(ngModel)]="newPtg.name"
          name="ptgName"
          required
          #ptgName="ngModel"
        />
        <mat-error *ngIf="ptgName.errors?.required && ptgName.touched">
          Bitte geben Sie einen Namen ein.
        </mat-error>
      </mat-form-field>
      <app-text-editor
        [(editorText)]="newPtg.description"
        (textValidityChange)="onDescriptionValidityChange($event)"
      ></app-text-editor>
      <p>
        <i>
          Zum Anlegen eines Produkttypen ist das Anlegen einer Version
          notwendig.
        </i>
      </p>
      <div class="auto-auto">
        <mat-form-field>
          <input matInput placeholder="Version 1" disabled />
        </mat-form-field>
        <mat-form-field>
          <mat-select
            placeholder="Status der Version"
            [(value)]="newPtg.versions[0].status"
          >
            <mat-option value="ACTIVE">Aktiv</mat-option>
            <mat-option value="INACTIVE">Inaktiv</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <p><b>Merkmale für die Version 1</b></p>
      <div class="min-auto">
        Merkmal hinzufügen
        <attribute-catalogue
          (onPressConfirm)="featureCatalogueConfirmPressed($event)"
          [noMargin]="true"
        ></attribute-catalogue>
      </div>
      <attribute-select-table
        [(ptgAttributeValidations)]="ptgAttributeValidations"
        [editable]="true"
      ></attribute-select-table>
      <div class="auto-auto">
        <button
          mat-raised-button
          class="btn_green"
          (click)="savePtg()"
          [disabled]="form.invalid || !isDescriptionValid"
        >
          Speichern
        </button>
        <button mat-raised-button class="btn_red" (click)="savePtgCancel()">
          Abbrechen
        </button>
      </div>
    </form>
  </div>
</div>
