<div *ngIf="toolchain && organisation" class="toolchain-detail">
  <!---------------------------------------------------------------------------------------->
  <app-detail-display-header
    [name]="toolchain.toolchainName"
    [rating]="toolchain.rating"
    [ratingCount]="toolchain.ratingCount"
    [reviewType]="toolchain.reviewType"
    [organisation]="organisation"
    [fileId]="toolchain.fileId"
  ></app-detail-display-header>
  <!---------------------------------------------------------------------------------------->
  <div class="toolchain-button padding-left">
    <div #twocolumn class="two-column-grid">
      <!------------------------------------------------------------------------------------>
      <div #column1 class="toolchain-detail-overview">
        {{toolchain.description}}
        <app-marketplace-rating-extended
        [targetId]="toolchain.toolchainId"
        [reviewType]="toolchain.reviewType"
        (averageEmitter)="onReviewsChanged($event)"
      ></app-marketplace-rating-extended>
      </div>
      <!------------------------------------------------------------------------------------>
      <div #colmun2>
      </div>
      <!------------------------------------------------------------------------------------>
    </div>
  </div>
</div>
