import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { ProductVariantInterface } from "src/app/model/products/product-variant-interface";

import { VariantVersionInterface } from "src/app/model/products/variant-version-interface";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";
import { DataType } from "src/app/model/data-type.enum";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { Status } from "src/app/model/enums/status";
import { ProductInterface } from 'src/app/model/products/product-interface';
import { CertificationInterface } from 'src/app/model/certifications/certification-interface';

@Component({
  selector: "app-detail-display-variant-version-selector",
  templateUrl: "./detail-display-variant-version-selector.component.html",
  styleUrls: ["./detail-display-variant-version-selector.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DetailDisplayVariantVersionSelectorComponent implements OnInit {
  @Input()
  product : ProductInterface;
  @Input() 
  certification : CertificationInterface;

  private variants: ProductVariantInterface[] | CertificationVariantInterface[];

  @Input()
  dataType: DataType;

  @Input()
  disabled: boolean;

  public activeVariants: (ProductVariantInterface &
    CertificationVariantInterface)[] = [];
  public versionsOfSelectedVariant: (CertificationVariantVersionInterface &
    VariantVersionInterface)[];

  @Output()
  onVersionChosen: EventEmitter<
    VariantVersionInterface | CertificationVariantVersionInterface
  >;

  @Output()
  onVariantChosen: EventEmitter<
    ProductVariantInterface | CertificationVariantInterface
  >;

  // Form Controll of selectors
  variantControl: FormControl;
  versionControl: FormControl;

  constructor() {
    this.onVersionChosen = new EventEmitter();
    this.onVariantChosen = new EventEmitter();
  }

  ngOnChanges() {
    if(this.certification != null && this.certification.certificationVariants)
    {
      this.variants = this.certification.certificationVariants;
      this.refreshVariantVariables();
    }
    if(this.product != null && this.product.productVariants != null) {
      this.variants = this.product.productVariants;
      this.refreshVariantVariables();
    }
    if (
      !this.disabled &&
      this.variantControl &&
      this.versionControl &&
      this.variantControl.disabled &&
      this.versionControl.disabled
    ) {
      this.enableForm();
    } else if (
      this.disabled &&
      this.variantControl &&
      this.versionControl &&
      this.variantControl.enabled &&
      this.versionControl.enabled
    ) {
      this.disableForm();
    }

  }

  ngOnInit() {
    this.refreshVariantVariables();
  }

  private refreshVariantVariables() {
    this.versionControl = new FormControl("");
    this.variantControl = new FormControl("");


    if (this.disabled) {
      this.disableForm();
    }
  
    this.variantControl.statusChanges.subscribe(() => {
      this.versionsOfSelectedVariant = this.getActiveVersionsOfVariant(
        this.variantControl.value
      );
      this.setFirstVersionSelected();

      if (this.dataType === DataType.PRODUCT) {
        this.onVariantChosen.emit(
          this.variantControl.value as ProductVariantInterface
        );
      } else {
        this.onVariantChosen.emit(
          this.variantControl.value as CertificationVariantInterface
        );
      }
    });
    //-----------------------------------------------------------------
    this.versionControl.statusChanges.subscribe(() => {
      if (this.dataType === DataType.PRODUCT) {
        this.onVersionChosen.emit(
          this.versionControl.value as VariantVersionInterface
        );
      } else {
        this.onVersionChosen.emit(
          this.versionControl.value as CertificationVariantVersionInterface
        );
      }
    });
    
    if (this.variants != null && this.variants.length > 0) {
      this.setActiveVariants();
      if (this.activeVariants.length > 0) {
        this.variantControl.setValue(this.activeVariants[0]);
      }
    }
  }

  
  private disableForm(): void {
    this.variantControl.disable();
    this.versionControl.disable();
  }

  private enableForm(): void {
    this.variantControl.enable();
    this.versionControl.enable();
  }

  private setActiveVariants() {
    this.activeVariants = [];
    for (const variant of this.variants) {
      const activeVersions = this.getActiveVersionsOfVariant(variant);
      if (activeVersions.length > 0) {
        this.activeVariants.push(variant);
      }
    }
  }

  private getActiveVersionsOfVariant(
    variant: CertificationVariantInterface | ProductVariantInterface
  ): CertificationVariantVersionInterface[] | VariantVersionInterface[] {
    switch (this.dataType) {
      case DataType.CERTIFICATION: {
        const variantVersions = (variant as CertificationVariantInterface)
          .certificationVariantVersions;

        return variantVersions.filter(
          (version) => version.status == Status.ACTIVE
        );
      }
      case DataType.PRODUCT: {
        const variantVersions = (variant as ProductVariantInterface)
          .variantVersions;

        return variantVersions.filter(
          (version) => version.status == Status.ACTIVE
        );
      }
    }
  }

  private setFirstVersionSelected(): void {
    if (
      this.versionsOfSelectedVariant != null &&
      this.versionsOfSelectedVariant.length > 0
    ) {
      this.versionControl.setValue(this.versionsOfSelectedVariant[0]);
    }
  }
}
