import { Component, OnInit, Input } from "@angular/core";
import { AddressInterface } from "src/app/model/address-interface";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { ContactInterface } from "src/app/model/organisations/contact-interface";

@Component({
  selector: "app-detail-display-contact-display",
  templateUrl: "./detail-display-contact-display.component.html",
  styleUrls: ["./detail-display-contact-display.component.css"],
})
export class DetailDisplayContactDisplayComponent implements OnInit {
  @Input()
  contact: ContactInterface;

  constructor() {}

  ngOnInit() {}

  private get contactEmail(): string {
    if (this.contact) return this.contact.email;
    return "";
  }

  public get contactTelephoneNumber(): string {
    if (this.contact) return this.contact.phoneNumber;
    return "";
  }

  public get contactWebsite(): string {
    if (this.contact) return this.contact.website;
    return "";
  }

  public get contactName() {
    if (this.contact) return this.contact.contactName;
    return "";
  }
  public get c() {
    if (this.contact) return this.contact.mobilePhoneNumber;
    return "";
  }
}
