<div [formGroup]="featureForm" *ngIf="featureForm.value.shouldValidate">
  <div>
    <mat-form-field>
      <textarea
        matInput
        rows="3"
        formControlName="validationDescription"
        placeholder="Beschreibung der Validierung"
        [maxLength]="3000"
      ></textarea>
    </mat-form-field>
    <div
      *ngIf="featureForm.value.validationDescription != null"
      class="description-info-message"
    >
      Aktuelle Zeichenlänge
      {{ featureForm.value.validationDescription.length }} (Max: 3000)
    </div>
  </div>
  <div [ngSwitch]="featureForm.value.dataType" class="min-auto">
    <triangle-indent></triangle-indent>
    <div *ngSwitchCase="'BOOLEAN'" class="min-auto">
      <p>Validierungswert</p>
      <toggle-switch
        [trueLabel]="'Ja'"
        [falseLabel]="'Nein'"
        [allowUndefined]="false"
        [value]="false"
        formControlName="booleanValue"
      >
      </toggle-switch>
    </div>

    <div *ngSwitchCase="'STRING'" class="prevent-grid-blowout">
      <!-- <mat-form-field>
          <input matInput formControlName="stringFormat" placeholder="Format" />
          <span matPrefix>
            <mat-checkbox formControlName="stringFormatInclude"></mat-checkbox>
          </span>
        </mat-form-field> -->
      <div class="columns-50-50">
        <mat-form-field>
          <input
            matInput
            formControlName="stringMin"
            type="text"
            placeholder="Min. Länge"
            appDigitOnly
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            formControlName="stringMax"
            type="text"
            placeholder="Max. Länge"
            appDigitOnly
          />
        </mat-form-field>
      </div>
    </div>

    <div *ngSwitchCase="'NUMERIC'" class="columns-50-50">
      <mat-form-field>
        <input
          matInput
          formControlName="numberMin"
          type="text"
          placeholder="Min. Wert"
          appDigitOnly
          allowNegative="true"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          formControlName="numberMax"
          type="text"
          placeholder="Max. Wert"
          appDigitOnly
          allowNegative="true"
        />
      </mat-form-field>
    </div>

    <div *ngSwitchCase="'LIST'">
      <div class="columns-50-50">
        <mat-form-field>
          <input
            matInput
            formControlName="listMin"
            type="text"
            placeholder="Min. Auswahl"
            appDigitOnly
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            formControlName="listMax"
            type="text"
            placeholder="Max. Auswahl"
            appDigitOnly
          />
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
