import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router} from "@angular/router";
import {PtgService} from "../services/dataServices/ptg-service/ptgService";

@Injectable()
export class PtgForProductVariantVersionResolver implements Resolve<any> {
  constructor(
    private ptgService: PtgService,
    private router: Router,
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const versionId = parseInt(route.paramMap.get("versionId"));
    if (versionId) {
      return this.ptgService.getAllPtgsForProductVariantVersion(versionId).catch((e) => {
        this.router.navigate([
          "/organisations/" +
          route.paramMap.get("organisationId") +
          "/products",
        ]);
      });
    }
  }
}
