<div class="maxWidth">
  <!-------- Header ------------------------->
  <div class="two-column-grid-header">
    <div>
      <h1>Zertifizierungsantrag</h1>
    </div>
    <div>
      <status-display
        [status] = "this.certificationRequest?.status">
      </status-display>
    </div>
  </div>
</div>
<!-------- Certification request details ------------------------->
<certification-request-overview
  [certificationRequest] = "this.certificationRequest"
  [isCertifier] = false>
</certification-request-overview>
<div class="maxWidth">
  <!-------- Buttons "Zurück"/"Zertifizierung abbrechen" ------------------------->
  <div class="mini-grid-buttons">
    <div>
      <button
        type="button"
        mat-raised-button
        class="btn_red"
        (click)="backToProductVariantScreen()"
      >
        Zurück
      </button>
    </div>
    <div>
      <button
        mat-raised-button
        class="btn_green right"
        (click)="cancelCertificationProcess()"
      >
        Zertifizierung abbrechen
      </button>
    </div>
  </div>
</div>
