import { ProductInterface } from 'src/app/model/products/product-interface';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProductSearch'
})
export class FilterProductSearchPipe implements PipeTransform {

  transform(products: ProductInterface[], searchText: string): ProductInterface[] {
    if (searchText.length === 0 || searchText[0] == undefined || searchText[0] === "") {
      return products;
    }

    searchText = searchText[0].toLowerCase();

    return products.filter(prod => {
      return prod.name.toLowerCase().includes(searchText);
    })
  }

}
