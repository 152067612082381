
export enum MarketScreenState {
  Organisations,
  Products,
  Certifications,
  Organisationdetails,
  Productdetails,
  Certificationdetails,
  Toolchaindetails
}
