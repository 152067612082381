<div
  class="gallery-dialog"
  [style.display]="gallerySelected ? 'block' : 'none'"
>
</div>
<div>
  <div
    #sideImageHexagon
    [ngClass]="hasGalleryImages() ? 'gallery-link' : 'certification-image'"
  >
    <app-marketplace-sideimagehexagon
      [img]="previewImageUrl"
      (click)="onGallerySelected()"
    >
    </app-marketplace-sideimagehexagon>
  </div>
</div>
