<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p class="dialog-text">

  Diese Webseite verwendet Cookies – nähere Informationen dazu finden Sie in der <a (click)="showPrivacy()">Datenschutzerklärung.</a>
  Bitte wählen Sie die Cookies-Einstellung und klicken Sie auf „Cookies akzeptieren“, um direkt BIMSWARM benutzen zu können.</p>
</div>
<div>
  <mat-slide-toggle checked="true" disabled="true"
    >Notwendige Cookies</mat-slide-toggle
  >
</div>
<div>

    <mat-slide-toggle [matTooltip]="toolTipText()" [(ngModel)]="isChecked">Statistik</mat-slide-toggle></div>
<div mat-dialog-actions>
  <button mat-button (click)="onClick()" class="btn_green" cdkFocusInitial>
    {{ data.buttonYes }}
  </button>
</div>
