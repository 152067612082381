<div class="review">
  <div class="write_new_review_wrapper">
    <button style="top: 1vh"
      mat-raised-button
      class="btn_green"
      id="createReview"
      *ngIf="
        !showWriteReview && userService.loggedIn && userService.currentUser && !hasUserReviewedProduct()
      "
      (click)="showReview()"
    >
      Produkt bewerten
    </button>
    <div *ngIf="showWriteReview">
      <app-write-review
        [targetId]="targetId"
        [reviewType]="reviewType"
        [currentRating]="0"
        [titelInput]="''"
        [feedbackInput]="''"
        (reviewChangeEmitter)="addReview($event); showWriteReview = false"
        (reviewCancelEmitter)="cancelReview()"
      >
      </app-write-review>
    </div>
  </div>
  <div *ngFor="let review of reviews; let i = index">
    <!-- Pop Up für das bearbeiten -->
    <div class="write_new_review_wrapper" *ngIf="review">
      <div *ngIf="review.onEdit">
        <h1>Bewertung bearbeiten</h1>
        <app-write-review
          [reviewType]="reviewType"
          [targetId]="targetId"
          [comments]="review.comments"
          [currentRating]="review.stars"
          [feedbackInput]="review.reviewText"
          [titelInput]="review.headline"
          [reviewId]="review.reviewId"
          [organisationId]="review.creatorOrganisationId"
          (reviewCancelEmitter)="cancelReview(review)"
          (reviewChangeEmitter)="
            editReview($event, review); review.onEdit = false
          "
        ></app-write-review>
      </div>

      <!-- Nur lesen nicht bearbeitbar -->
      <div *ngIf="!review.onEdit">
        <div class="header-block">
          <app-profile-picture
            [fileId]="review.creatorFileId"
            [name]="review.creatorFullName"
            class="author_picture"
            [index]="i"
          ></app-profile-picture>
          <div
            class="headline"
            matTooltip="{{ review.headline }}"
            matTooltipClass="header-tooltip"
            matTooltipPosition="below"
            matTooltipShowDelay="650"
            matTooltipHideDelay="400"
          >
           {{ review.headline }}
         </div>
         <div
            class="author"
            (click)="navigateToUserProfile(review.creatorId)"
          >
            {{ review.creatorFullName + ", " + review.creatorOrganisationName}}
          </div>
          <div class="star_wrapper">
            <app-star style="float: left" [rating]="review.stars"></app-star>
            <div class="publish_date">
              {{ review.publishDate | humanReadableDate }}
            </div>
          </div>
          <br>
          <div class="text-block" #textBlock>
            <div
              *ngIf="review.showComplete"
              class="feedback"
              [innerHtml]="review.reviewText"
            ></div>
            <div
              *ngIf="!review.showComplete"
              class="feedback"
              [innerHtml]="review.shortReviewText"
            ></div>
            <div #twocolumn class="two-column-grid">
              <div class="column1">
                <button
                  mat-raised-button
                  *ngIf="review.hasLongDescription"
                  class="btn_green showMoreLess"
                  (click)="review.showComplete = !review.showComplete"
                >
                  {{
                    review.showComplete ? "Weniger anzeigen" : "Mehr anzeigen"
                  }}
                </button>
              </div>
              <!--div
                *ngIf="review.comments && review.comments.length > 0"
                class="column2"
              >
                <button
                  mat-raised-button
                  class="btn_green showMoreLess right"
                  (click)="review.showComments = !review.showComments"
                >
                  {{
                    review.showComments
                      ? "Kommentare verbergen"
                      : "Kommentare anzeigen"
                  }}
                </button>
              </div-->
            </div>
          </div>
          <div *ngIf="userService.currentUser; else anonym" class="comment">
            <app-comment-item
              *ngIf=" (review.showComments || review.comments.length < 1)"
              [loggedIn]="userService.currentUser"
              [orgaAdmin]="
                userService.isUserAdminOfOrganisationOrPlattformAdmin(orgaId)
              "
              [review]="review"
              (commentDeleteEmitter)="editReview($event, review)"
              (commentAddEmitter)="editReview($event, review)"
            >
            </app-comment-item>
          </div>
          <ng-template #anonym>
          <div class="comment">
            <app-comment-item
              *ngIf=" review.showComments "

              [review]="review"
              (commentDeleteEmitter)="editReview($event, review)"
              (commentAddEmitter)="editReview($event, review)"
            >
            </app-comment-item>

          </div>
          </ng-template>

          <div class="button_wrapper" *ngIf="userService.currentUser">
            <button
              class="button positive edit"
              *ngIf=" userService.currentUser &&
                userService.loggedIn &&
                userService.currentUser.userId == review.creatorId
              "
              (click)="review.onEdit = true"
            >
              <i class="fa fa-edit"></i>
            </button>
            <button
              class="button negative delete"
              *ngIf=" userService.currentUser &&
                userService.loggedIn &&
                userService.currentUser.userId == review.creatorId
              "
              (click)="deleteReview(review)"
            >
              <i class="fa fa-trash fa-sx"></i>
            </button>
            <button
              class="button negative delete"
              *ngIf="userService.loggedIn && userService.currentUser; else dummyButton"
              (click)="reportReview(review)"
            >
              <i class="fa fa-exclamation-circle"></i>
            </button>




          </div>
          <!--------------------------------------------------->
          <!--------------- Right Upvote Icon ----------------->
          <!--------------------------------------------------->
          <div class="upvote_wrapper">
            <div
              class="triangle"
              [ngClass]="{ upvoted: hasUserUpvoted(review) }"
              (click)="upvoteClick(review)"
            ></div>
            <div class="upvote_count">{{ review.upvotes.length }}</div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
  <div *ngIf="!reviews">
    <p style="font-size: 200%">Es gibt noch keine Bewertungen</p>
  </div>
</div>
