import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { AttributeService } from "src/app/services/dataServices/attribute-service/attributeService";
import { Router } from "@angular/router";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { AttributeValidationServiceService } from "src/app/services/dataServices/dataValidation/attribute-validation-service/attribute-validation-service.service";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { UtilService } from "src/app/services/util-service/utilService";
import { FileServiceService } from 'src/app/services/file-service/file-service.service';
import { FileInfoInterface } from 'src/app/model/marketplace/file-info-interface';
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-certification-screen",
  templateUrl: "./certification-screen.component.html",
  styleUrls: ["./certification-screen.component.css"],
})
export class CertificationScreenComponent implements OnInit, OnDestroy {
  initialImageUrl = environment.defaultPictureProducts;

  public certification: CertificationInterface;

  private uploadPictureFile: File;

  private fileName: string = "";
  private isFormValid: boolean = false;
  private isDescriptionValid: boolean = true;

  private filesById;

  constructor(
    public organisationService: OrganisationService,
    public certificationService: CertificationService,
    public attributeService: AttributeService,
    public router: Router,
    private dialogService: DialogService,
    public attributeValidationService: AttributeValidationServiceService,
    private cd: ChangeDetectorRef,
    private fileService: FileServiceService,
    private userService: UserService
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnInit(): void {
    this.uploadPictureFile = null;

    if (this.organisationService && this.organisationService.activeItem) {
      this.certification = {
        company: this.organisationService.activeItem.organisationId,
        category: "APP",
        certificationType: 0,
        description: "",
        galleryFileIds: [],
        documentFileIds: [],
        certificationVariants: [
          {
            description: "",
            certificationVariantVersions: [{}],
          },
        ],
      };
    }
  }

  ngOnDestroy(): void { 
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }

  private cancelCreateCertification(): void {
    if (this.certificationService.activeItem) {
      this.router.navigate([
        "/organisations/" +
          this.organisationService.activeItem.organisationId +
          "/certifications/" +
          this.certificationService.activeItem.id,
      ]);
    } else {
      this.router.navigate([
        "/organisations/" +
          this.organisationService.activeItem.organisationId +
          "/certifications",
      ]);
    }
  }

  private createCertification(): void {
    if (
      !UtilService.areStringsValid(
        this.certification.name,
        this.certification.description
      )
    ) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Der Name oder die Beschreibung sind zu lang."
      );
      return;
    }

    if (!this.isInputValid()) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen Sie sicher, dass alle Merkmale validiert sind" +
          " oder entfernen Sie alle Produkttypzugehörigkeiten, von denen nicht alle Merkmale validiert sind."
      );
      return;
    }
    // first variant
    let certificationVariant: CertificationVariantInterface = this.certification
      .certificationVariants[0];
    // first version
    let certificationVariantVersion: CertificationVariantVersionInterface =
      certificationVariant.certificationVariantVersions[0];

    if (!UtilService.areStringsValid(certificationVariant.name, null)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen sie sicher, dass der Name der Variante nicht zu lang ist."
      );
      return;
    }
    if (!UtilService.areStringsValid(certificationVariantVersion.name, null)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen sie sicher, dass der Name der Version nicht zu lang ist."
      );
      return;
    }
    // picture
    //TODO Replace old Upload by Filemanager
    // this.uploadPictureFile = this.imageUploadComponent.uploadPictureFile;
    // validate

    if (this.certification.description == null) {
      this.certification.description = "";
    }
    if (certificationVariant == null) {
      certificationVariant.description = "";
    }
    if (certificationVariantVersion.description == null) {
      certificationVariantVersion.description = "";
    }
    certificationVariantVersion.attributeValidationValues = this.attributeValidationService.getAttributeValidationValues();

    //TODO if userOrganisationRole is User and product has active Version, prevent the creation and tell the user
    //TODO Manual setting of certificationType,
    this.certificationService.createCertification(this.certification).then(
      async (c: CertificationInterface) => {
        this.certificationService.loadCertificationsForOrganisation(
          this.organisationService.activeOrganisation
        );
        this.router.navigateByUrl(
          "/organisations/" +
            this.organisationService.activeItem.organisationId +
            "/certifications/" +
            c.id
        );
      },
      (e) => {
        this.dialogService.openDialog("Zertifizierung nicht erstellt", e.error);
      }
    );
  }

  // Opens the filemanager for uploading the logo
  public openFileManager(target: string): void {
    this.dialogService
      .openFileManagerDialog(
        "Datei für " + target + " auswählen",
        "Abbrechen",
        "Übernehmen",
        "Wählen sie eine Datei.",
        { multi: false, upload: true }
      )
      .subscribe((confirmed: string[]) => {
        if (confirmed.length >= 1) {
          if (target == "Logo") {
            this.certification.fileId = confirmed[0];
            this.cd.detectChanges();
          }
        }
      });
  }

  // deletes a fileid from gallery picture list
  public deleteFromGallery(fileId: string): void {
    let galleryIndex: number = this.certification.galleryFileIds.indexOf(fileId);
    this.certification.galleryFileIds.splice(galleryIndex, 1);
  }

  // deletes a fileid from document file list
  public deleteDocument(fileId: string): void {
    let galleryIndex: number = this.certification.documentFileIds.indexOf(fileId);
    this.certification.documentFileIds.splice(galleryIndex, 1);
  }

  // changes the position of a picutere in the gallery
  public moveGalleryItem(fileId: string, toLeft: boolean): void {
    let galleryIndex: number = this.certification.galleryFileIds.indexOf(fileId);
    if (toLeft && galleryIndex > 0) {
      let tmp = this.certification.galleryFileIds[galleryIndex - 1];
      this.certification.galleryFileIds[
        galleryIndex - 1
      ] = this.certification.galleryFileIds[galleryIndex];
      this.certification.galleryFileIds[galleryIndex] = tmp;
    }
    if (!toLeft && galleryIndex < this.certification.galleryFileIds.length - 1) {
      let tmp = this.certification.galleryFileIds[galleryIndex];
      this.certification.galleryFileIds[
        galleryIndex
      ] = this.certification.galleryFileIds[galleryIndex + 1];
      this.certification.galleryFileIds[galleryIndex + 1] = tmp;
    }
  }

  // opens the filemanager for uploading documents or gallery pictures
  public openFileManagerPicture(target: string): void {
    let options = { multi: false, filter: false, upload: true };
    if (target == "Dokumente" || target == "Galerie") {
      options.multi = true;
    }
    this.dialogService
      .openFileManagerDialog(
        "Datei für " + target + " auswählen",
        "Abbrechen",
        "Übernehmen",
        "Wählen Sie eine Datei.",
        options
      )
      .subscribe((confirmed: string[]) => {
        if (confirmed.length > 0) {
          let certificationData = this.certification;
          if (target == "Logo") {
            certificationData.fileId = confirmed[0];
          }
          if (target == "Galerie") {
            confirmed.forEach((element: string) => {
              if (certificationData.galleryFileIds.indexOf(element) == -1) {
                certificationData.galleryFileIds.push(element);
              }
            });
          }
          if (target == "Dokumente") {
            confirmed.forEach((element: string) => {
              if (certificationData.documentFileIds.indexOf(element) == -1) {
                certificationData.documentFileIds.push(element);
              }
            });
          }
          this.certification = certificationData;
          this.cd.detectChanges();
        }
      });
  }

  // Gets fileinfo to a fileid
  public fileInfo(fileId: string) {
    this.fileService.data$.subscribe((data: FileInfoInterface) => {
      this.filesById = data;
    });
    return this.filesById[fileId];
  }

  private isInputValid(): boolean {
    return this.attributeValidationService.checkAllEntityAttributeValuesValidated();
  }

  public onValidityChanged(valid: boolean): void {
    this.isFormValid = valid;
  }

  public onDescriptionValidityChange(descriptionValid: boolean): void {
    this.isDescriptionValid = descriptionValid;
  }

  // helper method for image path of fileid
  public imagePath(fileId: string) {
    return "/proxy/api/v0/fileservice/file/" + fileId;
  }
}
