import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { MalfunctionInterface } from "src/app/model/malfunctions/malfunction-interface";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { MalfunctionPageRequest } from "src/app/model/malfunctions/malfunction-page-request-interface";
import { BaseService } from "../base.service";

const SWARM_API = "/proxy/api/v0/userservice/malfunction";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable()
export class MalfunctionService {

  // Subject um direkt die Maske zu updaten, bei Erstellen, Bearbeiten und Löschen von Störungen
  private newMalfunction: Subject<MalfunctionInterface> = new Subject<MalfunctionInterface>(); // Subject für alle neuen sowie bearbeiteten Malfunctions
  private deletedMalfunction: Subject<MalfunctionInterface> = new Subject<MalfunctionInterface>(); // Subject für die gelöschte Malfunction

  constructor(private http: HttpClient) {}
  
 getNewMalfunctionSubject(): Subject<MalfunctionInterface>{
    return this.newMalfunction;
  }

  getDeletedMalfunctionSubject(): Subject<MalfunctionInterface>{
    return this.deletedMalfunction;
  }
  setDeletedMalfunction(deletedMalfunction: MalfunctionInterface):void {
    this.deletedMalfunction.next(deletedMalfunction);
  }

  setNewMalfunction(newMalfunction: MalfunctionInterface): void{
    this.newMalfunction.next(newMalfunction);
  }

  getMalfunctions(productId: number): Promise<MalfunctionInterface[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get<MalfunctionInterface[]>(SWARM_API + "/product/" + productId)
        .subscribe(
          (x) => {
            resolve(x);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  createMalfunction(
    newMalfunction: MalfunctionInterface
  ): Observable<MalfunctionInterface> {
    return this.http.post<MalfunctionInterface>(
      SWARM_API,
      newMalfunction,
      httpOptions
    );
  }

  deleteMalfunction(
    malfunction: MalfunctionInterface
  ): Observable<MalfunctionInterface> {
    return this.http.delete<MalfunctionInterface>(
      SWARM_API + "/" + malfunction.malfunctionId
    );
  }

  getMalfunctionPage(
    productId: number,
    pageRequest: MalfunctionPageRequest
  ): Observable<MalfunctionInterface[]> {
    return this.http.post<MalfunctionInterface[]>(
      SWARM_API + "/" + productId,
      pageRequest,
      httpOptions
    );
  }
}
