import { Component, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-privacy",
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class DialogPrivacy {
  constructor(
    public dialogRef: MatDialogRef<DialogPrivacy>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onClick(confirmed: boolean): void {
    this.dialogRef.close(confirmed);
  }
}
