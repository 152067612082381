import { ProductCertificationUpdateInterface } from './../../../model/product-certification/product-certification-update-interface';
import { API_BASE_PRODUCTSERVICE, API_MAP } from "src/environments/api";
import { Injectable } from "@angular/core";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { BaseService } from "../../base.service";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { UserService } from "../../user-service/userservice";
import { CertificationDtoConverterService } from "../../certification-dto-converter-service/certification-dto-converter-service.service";
import { CertificationVersionProductInfoInterface } from "src/app/model/marketplace/product-infos-of-certification-interface";
import { BehaviorSubject, Observable } from "rxjs";
import { DtoPageRequest } from "src/app/model/marketplace/dto-page-request";
import { ProductCertificationOverview } from 'src/app/model/product-certification/product-certification-overview';

const SWARM_API_CERTIFICATIONSERVICE = API_BASE_PRODUCTSERVICE;
const API_PRODUCTCERTIFICATION =
  API_BASE_PRODUCTSERVICE + "/productcertification/";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class CertificationVariantVersionService extends BaseService<
  CertificationVariantVersionInterface
> {
  protected equal(
    item1: CertificationVariantVersionInterface,
    item2: CertificationVariantVersionInterface
  ): boolean {
    return JSON.stringify(item1) === JSON.stringify(item2);
  }
  protected toID(item: CertificationVariantVersionInterface | number): number {
    if (!item) return -1;
    if (typeof item === "number") return item;
    if (item.id) return item.id;
    return -1;
  }

  activeOrganisation: number;

  // Pbservable to update product service
  private addedCertificationSubject: BehaviorSubject<number>;
  public addedCertificationObserver: Observable<number>;
  // Helper Storage Item
  private addedCertifcation: number;

  constructor(
    protected http: HttpClient,
    private userService: UserService,
    protected certificationDtoConverter: CertificationDtoConverterService
  ) {
    //Second Parameter is null because productDtoConverter is not needed but cant be optional in this case
    super(http, null, certificationDtoConverter);
    //set the swarm API
    this.SWARM_API_BASE = SWARM_API_CERTIFICATIONSERVICE;
    //-----------------------
    this.addedCertificationSubject = new BehaviorSubject<number>(
      this.addedCertifcation
    );
    this.addedCertificationObserver = this.addedCertificationSubject.asObservable();
    //-----------------------
    // Specify a item mapping to know which URL identifier is to be mapped
    // to witch information on an item
    this.ITEM_MAP = userService.ACTIVE_MAP;

    this.SWARM_API_MAP = API_MAP["certificationVersions"];
    this.SERVICE_FLAG = ":certificationVersionId";
    //-----------------------
  }

  deleteLocalVersion(version: CertificationVariantVersionInterface) {
    let index = this.collection.findIndex((x) => this.idCompare(x, version));
    if (index >= 0) {
      this.collection.splice(index, 1);
    }
  }

  /**
   * Downward propagation to update new loaded items into the subservice
   * @param version
   */
  updateVersionService(version: CertificationVariantVersionInterface) {
    this.updateItemInCollection(version);
  }

  setActiveVersion(version: CertificationVariantVersionInterface | number) {
    let id = this.toID(version);
    this.ITEM_MAP[":userId"] = String(this.userService.currentUser.userId);
    this.userService.ACTIVE_MAP[":certificationVersionId"] = String(id);
    return this.setActiveItem(version);
  }

  getRelatedProductInfos(
    certificationVersionId: number
  ): Observable<CertificationVersionProductInfoInterface[]> {
    return this.http.get<CertificationVersionProductInfoInterface[]>(
      API_BASE_PRODUCTSERVICE +
        "/certificationversions/versionOverview/" +
        certificationVersionId
    );
  }

  getRelatedProductInfosPage(
    certificationVersionId: number,
    pageRequest: DtoPageRequest
  ): Observable<CertificationVersionProductInfoInterface[]> {
    return this.http.post<CertificationVersionProductInfoInterface[]>(
      API_BASE_PRODUCTSERVICE +
        "/certificationversions/versionOverview/" +
        certificationVersionId,
      pageRequest
    );
  }

  // Sends ProductCertifications to Backend and updates the certifications for the specific product in the collection
  updateProductCertifications(
    productId: number,
    certificationId: number,
    productCertifications: ProductCertificationUpdateInterface[],
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          API_PRODUCTCERTIFICATION +
            certificationId +
            "/" +
            this.activeItem.id,
          productCertifications,
          httpOptions
        )
        .subscribe(
          (response: boolean) => {
            // --------------------------------------------------------------------
            // Update Product Service with new Certifications
            // --------------------------------------------------------------------
            if (response) {
              this.addedCertificationSubject.next(productId);
            }
            // --------------------------------------------------------------------
            resolve(response);
          },
          (e: HttpErrorResponse) => {
            //failure
            reject(e);
          }
        );
    });
  }

  /**
   * gets all the information from the certification manage packe 
   * and sends to backend for db entry
   */
  createProductCertificationForNotRegistratedProducts(
    certificationId: number,
    organisationName: String,
    productName: String, 
    email: String,
    productVariant: String,
    productVariantVersion: String,
    productType: String
  ) {
    //certId + certVerId + prodName + email + prodVar + prodVarVer 
    return new Promise((resolve, reject) => {
           this.http
          .post(
            API_PRODUCTCERTIFICATION + 
            certificationId + 
            "/" + 
            this.activeItem.id + 
            "/" + 
            organisationName + 
            "/" + productName + 
            "/" + email + 
            "/" + 
            productVariant + 
            "/" + 
            productVariantVersion 
            + "/" +
            productType,
            httpOptions
        ).subscribe((response: boolean) => {
          if(response) {
          }
          resolve(response);
        },
        (e: HttpErrorResponse) => {
          reject(e);
        });
      })
  }

/**
 * gets all non listed procut certifications for the specific certification and certificationVariant
 * @param certificationId 
 * @param certificationVarId 
 */
  getNonListedProductCertificationForCertVar(certificationId : number ,certificationVarId : number) {
    return this.http.get<ProductCertificationOverview[]>(
      API_PRODUCTCERTIFICATION + "nonlistedProducts/"+
      certificationId +
      "/" + 
      certificationVarId
    );
  }
}

