<h1 mat-dialog-title>Konto Löschen</h1>
<div mat-dialog-content>
  <p class="dialog-text">
    Um Ihr Konto zu löschen müssen Sie zunächst entscheiden, was mit den von
    Ihnen administrierten Organisation passieren soll.
  </p>
</div>

<div *ngFor="let orgToDecide of decisions" class="memberShipDecisionTable">
  <p>{{ orgToDecide.organisationName }}</p>
  <mat-button-toggle-group
    (change)="onSelectionChange($event, orgToDecide.organisationId)"
    [disabled]="
      orgToDecide.organisationMembers == null ||
      orgToDecide.organisationMembers.length === 0
    "
    [value]="orgToDecide.selectedAction"
  >
    <mat-button-toggle value="TRANSFER">Übertragen</mat-button-toggle>
    <mat-button-toggle value="DELETE">Löschen</mat-button-toggle>
  </mat-button-toggle-group>
  <div *ngIf="orgToDecide.selectedAction == 'TRANSFER'; else reason">
    <mat-select
      (selectionChange)="
        onTransferSelectionChange($event, orgToDecide.organisationId)
      "
      ><mat-option
        *ngFor="let member of orgToDecide.organisationMembers"
        [value]="member.userName"
        >{{ member.userName }}</mat-option
      >
    </mat-select>
  </div>

  <ng-template #reason>
    <p class="reason">
      {{
        orgToDecide.organisationMembers == null ||
        orgToDecide.organisationMembers.length === 0
          ? orgToDecide.reason
          : ""
      }}
    </p>
  </ng-template>
</div>

<div *ngIf="leftOrganisations.length > 0">
  <h3>Zusätzlich werden die folgenden Organisationen verlassen</h3>
  <div
    *ngFor="let leftOrganisation of leftOrganisations"
    class="leftOrganisations"
  >
    <p>{{ leftOrganisation.organisationName }}</p>
    <p>{{ leftOrganisation.reason }}</p>
  </div>
</div>

<div mat-dialog-actions>
  <button
    [disabled]="hasIncompleteTransfer()"
    mat-raised-button
    (click)="onClick(true)"
    class="btn_green"
  >
    Bestätigen
  </button>
  <button mat-raised-button (click)="onClick(false)" class="btn_red">
    Abbrechen
  </button>
</div>
