<div class="top-nav-buttons">
  <nav-icon
    *ngFor="let hexagon of navbarService.navbarData; let i = index"
    [label]="hexagon.label"
    [icon]="hexagon.icon"
    [imageId]="hexagon.img"
    [labelBelow]="labelBelow(i)"
    (click)="action(hexagon.link)"
  >
  </nav-icon>
</div>
<br />
<div
  *ngIf="userService.loggedIn && this.userService.currentMemberships"
  class="organisations"
>
  <div
    *ngFor="
      let organisation of this.userService.currentMemberships | confirmedMembers
    "
    class="orga-wrapper"
  >
    <nav-icon
      (click)="
        this.setActive(
          organisation.organisationId,
          organisation.organisationName,
          this.userService.currentUser.userId
        )
      "
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      [img]="getNavButtonImage(organisation)"
      [imageId]="organisation.fileId"
      [label]="organisation.organisationName"
      [labelBelow]="true"
    >
    </nav-icon>
  </div>
</div>
