<div class="category-switch">
  <br />
  <ul>
    <li class="screen-category" [ngClass]="{
        'active-switch': this.sideFilter.marketScreenState === 'ORGANISATION'
      }" (click)="onOrgaSwitch()">
      <span>
        Anbieter
      </span>
      <span *ngIf="this.sideFilter.marketScreenState === 'ORGANISATION'" class="arrow">
        &#9664;
      </span>
    </li>
    <li class="screen-category" [ngClass]="{
        'active-switch': this.sideFilter.marketScreenState === 'PRODUCT'
      }" (click)="this.onProductSwitch()">
      <span>
        Produkte
      </span>
      <span *ngIf="this.sideFilter.marketScreenState === 'PRODUCT'" class="arrow">
        &#9664;
      </span>
    </li>

    <li class="screen-category" [ngClass]="{ 'active-switch': this.sideFilter.isCertificationScreen() }"
      (click)="this.onCertificationSwitch()">
      <span>
        Zertifizierungen
      </span>
      <span *ngIf="this.sideFilter.isCertificationScreen()" class="arrow">
        &#9664;
      </span>
    </li>

    <li class="screen-category" [ngClass]="{ 'active-switch': this.sideFilter.isToolchainScreen() }"
      (click)="this.onToolchainSwitch()">
      <span>
        Toolchain
      </span>
      <span *ngIf="this.sideFilter.isToolchainScreen()" class="arrow">
        &#9664;
      </span>
    </li>
  </ul>
</div>