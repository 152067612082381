import {Pipe, PipeTransform} from '@angular/core';
import {ComposerToolchainInstanceOverview} from '../model/composer/composer-toolchain-instance-overview';

@Pipe({name: 'filterInstanceReferencesTemplate'})
export class FilterInstanceReferencesTemplate implements PipeTransform {
  transform(instances: ComposerToolchainInstanceOverview[], templateId: number) {
    if (instances === undefined) {
      return [];
    } else {
      return instances.filter((instance: ComposerToolchainInstanceOverview) => instance.relTemplate === templateId);
    }
  }
}
