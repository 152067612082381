import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { AttributeInterface } from "src/app/model/attributes/attribute-interface";
import { ValidationInterface } from "src/app/model/attributes/validation-interface";

import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { DialogService } from "../../../services/dialog-service/dialog-service.service";

import { AttributeService } from "src/app/services/dataServices/attribute-service/attributeService";
import {
  MAX_INTEGER,
  LIST_ATTRIBUTE_MAX_SIZE,
} from "src/environments/constants";
import { AttributeValidationComponent } from '../attribute-validation/attribute-validation.component';
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-attributes-screen",
  templateUrl: "./attributes-screen.component.html",
  styleUrls: ["./attributes-screen.component.css"],
})
export class AttributesScreenComponent implements OnInit {
  @Input()
  public featureForm: FormGroup;

  public listValues: string[] = [];

  public doYouReallyWantToSave: boolean;

  @ViewChild(AttributeValidationComponent)
  public attributeValidation: AttributeValidationComponent;

  constructor(
    public organisationService: OrganisationService,
    private attributeService: AttributeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialogService: DialogService,
    private userService: UserService
  ) {
    //Reroutes to the Marketplace if the User is not a member of the Organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnInit() {
    this.featureForm = this.formBuilder.group({
      dataType: "",
      featureName: "",
      description: "",
      shouldValidate: false,
      booleanValue: undefined,
      stringFormat: "",
      stringMin: undefined,
      stringMax: undefined,
      numberMin: undefined,
      numberMax: undefined,
      listMin: undefined,
      listMax: undefined,
      validationDescription: "",
    });
    //this.attributeService.fetchAllAttributes();
  }

  onSubmit(form: FormGroup): boolean {
    //------------------------------------------------------------------------------
    if (!form.value.dataType || !form.value.featureName) {
      this.dialogService.openDialog(
        "Merkmal anlegen",
        "Bitte geben Sie einen Namen und Datentyp an!"
      );
      return false;
    }

    //------------------------------------------------------------------------------

    if (!this.attributeValidation.validate(form, this.listValues)) {
      return true;
    }

    //------------------------------------------------------------------------------
    let feature: AttributeInterface = {
      name: form.value.featureName,
      datatype: form.value.dataType,
      description: form.value.description,
      validations: [],
    };
    //------------------------------------------------------------------------------
    let validation: ValidationInterface = {
      attributeName: feature.name,
      description: form.value.validationDescription
        ? form.value.validationDescription
        : "",
    };

    //------------------------------------------------------------------------------
    if (form.value.dataType === "LIST") {
      if (
        this.attributeValidation.listValues.length > LIST_ATTRIBUTE_MAX_SIZE
      ) {
        this.dialogService.openDialog(
          "Anzahl der Listenelemente ist zu groß.",
          "Die Anzahl der eingetragenen Listenelemente überschreitet das maximale Limit von " +
            LIST_ATTRIBUTE_MAX_SIZE +
            " Elementen. Bitte entfernen Sie " +
            (this.attributeValidation.listValues.length -
              LIST_ATTRIBUTE_MAX_SIZE) +
            " Elemente aus der Liste und versuchen Sie erneut zu speichern."
        );
        return false;
      }
      validation.values = this.attributeValidation.listValues;
    }

    //------------------------------------------------------------------------------
    feature.validations.push(validation);
    let listContainsDoubleNoValues = true;

    this.dialogService
      .openDialogAttribute(
        "Sind Sie sicher, dass sie dieses Merkmal anlegen wollen?",
        feature,
        this.doYouReallyWantToSave
      )
      .subscribe((result) => {
        this.doYouReallyWantToSave = result;

        if (this.doYouReallyWantToSave && listContainsDoubleNoValues) {
          this.attributeService
            .save(feature)
            .then((attribute) => {
              this.router.navigate([
                "/organisations/" +
                  this.organisationService.activeItem.organisationId +
                  "/features",
              ]);
              this.dialogService.openDialog(
                "Merkmal erstellt",
                "Das Merkmal: " +
                  attribute.name +
                  " wurde erfolgreich angelegt."
              );
            })
            .catch((error: HttpErrorResponse) => {});
        } else {
          return false;
        }
      });

    //------------------------------------------------------------------------------
    if (form.value.shouldValidate) {
      switch (form.value.dataType) {
        case "BOOLEAN":
          validation.value = form.value.booleanValue;
          break;

        case "STRING":
          validation.pattern = form.value.stringFormat;
          validation.minSize = form.value.stringMin;
          validation.maxSize = form.value.stringMax;
          break;

        case "NUMERIC":
          validation.minSize = form.value.numberMin;
          validation.maxSize = form.value.numberMax;
          if (validation.maxSize > MAX_INTEGER)
            validation.maxSize = MAX_INTEGER;
          if (validation.minSize > MAX_INTEGER)
            validation.minSize = MAX_INTEGER;
          break;

        case "LIST":
          validation.minSize = form.value.listMin;
          validation.maxSize = form.value.listMax;
          break;

        default:
          this.dialogService.openDialog(
            "Es ist ein Fehler aufgetreten:",
            "Bitte wählen Sie einen Datentypen."
          );
          break;
      }
    }
    //------------------------------------------------------------------------------
  }

  //------------------------------------------------------------------------------

  onDeleteFromList(index: number): void {
    this.listValues.splice(index, 1);
  }

  checkIfIsDuplicate(listitem: string): boolean {
    let counter = 0;
    this.listValues.forEach((x) => {
      if (x == listitem) {
        if (counter > 0) {
          return true;
        }
        counter++;
      }
    });
    return false;
  }

  //------------------------------------------------------------------------------

  // prüft, ob Listenelement länger ist als MaxSize und es schon in der Liste enthalten ist
  processListItem(listItem2: string): void {
    const listItem: string = listItem2.trim();

    if (
      this.listAlreadyContainsItem(listItem) ||
      this.checkIfItemTooLarge(listItem)
    ) {
      this.dialogService.openDialog(
        "Listenwert kann nicht zur Liste hinzugefügt werden",
        "Der Listenwert ist zu lang oder bereits in der Liste vorhanden und kann nicht erneut hinzugefügt werden"
      );
    } else if (listItem != null && listItem.length > 0) {
      this.listValues.push(listItem);
    }
  }

  listAlreadyContainsItem(listItem): boolean {
    return this.listValues.includes(listItem.replace(/\s/g, ""));
  }

  // Prüft, ob das Listenelement länger als MaxSize ist
  checkIfItemTooLarge(listItem: string): boolean {
    return listItem.length > LIST_ATTRIBUTE_MAX_SIZE;
  }

  //------------------------------------------------------------------------------

  cancel() {
    this.router.navigate([
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/features",
    ]);
  }
}
