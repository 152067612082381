<h1>Nutzungsbedingungen</h1>
<p> Stand 09.09.2021 </p>

(1)	Diese Nutzungsbedingungen regeln das rechtliche Verhältnis im Rahmen der Nutzung der Plattform „BIMSWARM“ (nachfolgend: „Plattform“) zwischen der planen-bauen 4.0 GmbH (nachfolgend: „planen-bauen 4.0“) und dem Nutzer der Plattform (nachfolgend: „Nutzer“).
<h2> § 1 Vertragsparteien und Vertragsgegenstand </h2>
<ol class="ollbe">
  <li class="lilbe"><p>Diese Nutzungsbedingungen regeln das rechtliche Verhältnis im Rahmen der Nutzung der Plattform „BIMSWARM“ (nachfolgend: <b>„Plattform“</b>) zwischen der planen-bauen 4.0 GmbH (nachfolgend: <b>„planen-bauen 4.0“</b>) und dem Nutzer der Plattform (nachfolgend: <b>„Nutzer“</b>). </p></li>
  <li class="lilbe"><p>Vertragsgegenstand ist der Zugriff auf die Plattform und die Nutzung verschiedener Funktionalitäten nach erfolgter Registrierung. </p></li>
</ol>


<h2>§ 2 Registrierung </h2>
<ol class="ollbe">
  <li class="lilbe"><p>Mit der Registrierung auf der Plattform erhält der Nutzer einen personalisierten Account, der ihm die Nutzung verschiedener Funktionalitäten ermöglicht, die ohne eine Registrierung nicht zugänglich sind.</p></li>
  <li class="lilbe"><p>Die Registrierung sowie die Nutzung der hiermit zugänglichen Funktionalitäten sind für den Nutzer kostenlos.</p></li>
  <li class="lilbe"><p>Der Nutzer kann jederzeit seinen Account löschen und den Vertrag über die Plattformnutzung damit unmittelbar beenden.</p></li>
</ol>


<h2> § 3 Leistungsbeschreibung</h2>
<ol class="ollbe">
  <li class="lilbe"><p>Die Plattform dient der Digitalisierung des Bauwesens und ermöglicht als offene Plattform die einfache Orientierung im Bereich unterschiedlicher Anwendungen, Dienste und Inhalte, um die durchgängig digitale Projektbearbeitung mit Building Information Modeling (BIM) bei Planung, Ausführung und Betrieb von Bauwerken zu unterstützen. Dabei bietet sie die Möglichkeit zur Recherche und gezielten Suche von IT-Produkten sowie Zertifizierungen und Informationen über Produktfunktionalitäten. Informationen zu Produkten und Angeboten werden von den jeweiligen Anbietern auf der Plattform bereitgestellt. Es handelt sich hierbei stets um Inhalte der jeweiligen Anbieter und nicht um Inhalte von planen-bauen 4.0.</p></li>
  <li class="lilbe"><p>Über den Zugang zum persönlichen Bereich der Plattform kann der Nutzer erweiterte Funktionalitäten der Plattform nutzen, d.h. insbesondere Merkzettel erstellen und Produkte bewerten.</p></li>
  <li class="lilbe"><p>Die auf der Plattform angebotenen Leistungen können jederzeit erweitert werden. Aus Gründen der Datensicherheit, aus technischen Gründen oder aus wirtschaftlichen Gründen, zur Fehlerbehebung oder wegen einer Veränderung der Rechtslage durch Gesetzgebung, Rechtsprechung oder behördliche Vorgaben können die angebotenen Leistungen auch jederzeit geändert, eingeschränkt und teilweise oder vollständig eingestellt werden.</p></li>
</ol>


<h2> § 4 Nutzungsrechte</h2>
<ol class="ollbe">
  <li class="lilbe"><p>Der Nutzer erhält das zeitlich auf die Vertragsdauer beschränkte nicht ausschließliche Recht, auf die Plattform zuzugreifen und die darin enthaltenen Funktionalitäten zu nutzen.</p></li>
  <li class="lilbe"><p>Der Nutzer darf die für ihn verfügbaren Inhalte speichern oder drucken, allerdings nur so weit und in dem Umfang, in dem dies in unmittelbarem Zusammenhang mit der bestimmungsgemäßen Nutzung der Plattform steht.</p></li>
  <li class="lilbe"><p>Jegliche andere Nutzung und/oder Vervielfältigung der Inhalte ist ohne die vorherige ausdrückliche Zustimmung von planen-bauen 4.0 nicht gestattet. Insbesondere verpflichtet sich der Nutzer, Reverse Engineering, Disassemblierungen, Dekompilierungen, Übersetzungen oder unzulässige Offenlegungen weder selbst vorzunehmen, noch zu veranlassen, noch zu ermöglichen, soweit dies nicht nach anwendbarem zwingendem Recht zulässig ist.</p></li>
  <li class="lilbe"><p>Die Verwendung von automatisierten Systemen oder Software zum Extrahieren von Daten von der Plattform, insbesondere für gewerbliche Zwecke, ist untersagt.</p></li>
</ol>


<h2>§ 5 Pflichten des Nutzers</h2>
<ol class="ollbe">
  <li class="lilbe"><p>Es ist dem Nutzer nicht gestattet, die Plattform zu stören, zu belasten oder zu unterbrechen, den Betrieb oder die Funktionsfähigkeit zu umgehen, zu behindern oder zu manipulieren. Weiterhin ist es nicht gestattet, technische Beschränkungen zu umgehen oder zu unterlaufen oder ein Tool zu nutzen, um Funktionalitäten, die ansonsten deaktiviert, unzugänglich oder nicht dokumentiert sind, freizuschalten.</p></li>
  <li class="lilbe"><p>Der Nutzer verpflichtet sich, Bewertungen nur für solche Produkte vorzunehmen, die er tatsächlich verwendet hat und sich bei den Bewertungen stets im Rahmen des rechtlich Zulässigen zu halten (d.h. insbesondere keine Beleidigungen, falsche Verdächtigungen, unwahre herabsetzende Tatsachenbehauptungen etc. zu veröffentlichen). Der Nutzer verpflichtet sich weiterhin, keine Inhalte zu veröffentlichen, die offensichtlich moralische, gesellschaftliche oder religiöse Gefühle anderer verletzen oder als pornografische Inhalte einzuordnen sind.</p></li>
</ol>

<h2>§ 6 Beendigung des Zugriffsrechts </h2>
<p style="padding-left: 1em; margin-top: -0.25em">Das Recht des Nutzers, auf die Plattform zuzugreifen, endet, wenn durch den Nutzer eine nicht autorisierte, rechtswidrige, vertragswidrige oder missbräuchliche Nutzung der Plattform stattfindet oder droht, z.B. durch Beeinträchtigung oder Störung der Funktionsweise der Plattform (Verletzung der Systemintegrität) durch Übertragung des Accounts auf Dritte oder durch Verschaffung des Zugangs zum Account an unberechtigte Dritte.
</p>

<h2>§ 7 Datenschutz und Vertraulichkeit</h2>
<ol class="ollbe">
  <li class="lilbe"><p>Der Nutzer ist verpflichtet, die für eine Nutzung der Plattform erforderlichen Zugangsdaten vertraulich zu behandeln, gegen unbefugten Gebrauch Dritter zu schützen und diese nicht an Dritte weiterzugeben.</p></li>
  <li class="lilbe"><p>planen-bauen 4.0 verarbeitet personenbezogene Daten im Einklang mit den einschlägigen Datenschutzbestimmungen, insbesondere der Europäischen Datenschutz-Grundverordnung (DSGVO) und des Bundesdatenschutzgesetzes (BDSG). Eine detaillierte Beschreibung der Erfassung, Verarbeitung und Verwendung personenbezogener Daten im Rahmen der Plattformnutzung ist der <a (click)="showPrivacy()">Datenschutzerklärung</a> zu entnehmen.</p></li>
</ol>


<h2> § 8 Haftung, Löschung</h2>
<ol class="ollbe">
  <li class="lilbe"><p>planen-bauen 4.0 haftet im Rahmen der gesetzlichen Bestimmungen.</p></li>
  <li class="lilbe"><p>planen-bauen 4.0 übernimmt keine Haftung für die Aktualität und die inhaltliche Richtigkeit der Daten, insbesondere der Angebote und Produktinformationen der jeweiligen Anbieter.</p></li>
  <li class="lilbe"><p>Etwaige gesetzliche Haftungsprivilegierungen, z.B. nach §§ 8 – 11 TMG, bleiben unberührt.</p></li>
  <li class="lilbe"><p>planen-bauen 4.0 hat das Recht, offensichtlich rechtswidrige Inhalte des Nutzers ohne vorherige Rücksprache von der Plattform zu löschen. In diesem Fall wird der Nutzer über die Löschung informiert.</p></li>
  <li class="lilbe"><p>Wird planen-bauen 4.0 durch Dritte auf eine Rechtsverletzung auf der Plattform hingewiesen und handelt es sich nicht um offensichtlich rechtswidrige Inhalte, so wird planen-bauen 4.0 den Nutzer, der die fraglichen Inhalte eingestellt hat, zu einer Stellungnahme innerhalb einer angemessenen Frist auffordern. Erfolgt innerhalb der Frist keine Stellungnahme, die dem Vorwurf einer Rechtsverletzung entgegensteht, ist planen-bauen 4.0 berechtigt, die fraglichen Inhalte zu löschen. Erfolgt eine entsprechende Stellungnahme, wird planen-bauen 4.0 diese unmittelbar an den Dritten weiterleiten. Sobald sich anschließend dennoch herausstellt, dass die fraglichen Inhalte Rechte verletzen, ist planen-bauen 4.0 zur Löschung berechtigt.</p></li>
</ol>

<h2>§ 9 Schlussbestimmungen</h2>
<ol class="ollbe">
  <li class="lilbe"><p>Sämtliche Änderungen oder Ergänzungen dieser Nutzungsbedingungen bedürfen der Textform (z. B. Brief oder E-Mail). Das gilt auch für Änderungen dieser Schriftformklausel.</p></li>
  <li class="lilbe"><p>Das gesamte Vertragsverhältnis unterliegt ausschließlich deutschem Recht unter Ausschluss des UN-Kaufrechts.</p></li>
  <li class="lilbe"><p>Sollte eine Bestimmung dieser Vertragsbedingungen unwirksam sein oder werden, so wird hiervon die Gültigkeit aller übrigen Bestimmungen nicht berührt. Die Parteien werden sich im Fall der Unwirksamkeit einzelner Bestimmungen auf eine wirksame Regelung einigen, die dem mit der unwirksamen Klausel Gewollten wirtschaftlich nahe kommt.</p></li>
</ol>

