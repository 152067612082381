<div class="review">
  <div class="reviewHeadline">
    <h3>{{ review.headline }}</h3>
    <app-star [rating]="review.stars" [bigger]="true"></app-star>
  </div>
  <hr />
  <span>{{ review.reviewText }}</span>
  <div class="reviewFooter">
    <span>{{review.upvotes}} {{review.upvotes.length == 1 ? "Upvote" : "Upvotes"}}</span>
    <span *ngIf="review.reviewType === reviewTypeEnum.PRODUCT_REVIEW" class="reviewLink" (click)="navigateToProduct(review.targetId)">Zum Produkt</span> 
    <span *ngIf="review.reviewType === reviewTypeEnum.ORGANISATION_REVIEW" class="reviewLink" (click)="navigateToOrganisation(review.targetName)">Zur Organisation</span>
  </div>
</div>