import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-profile-picture",
  templateUrl: "./profile-picture.component.html",
  styleUrls: ["./profile-picture.component.css"],
})
export class ProfilePictureComponent implements OnInit {
  @Input()
  public fileId: string;
  @Input()
  private name: string;

  @Input()
  private index?: number;

  public color: string;

  private colors: string[] = [
    "#eb4034",
    "#eb9934",
    "#ebe534",
    "#96eb34",
    "#34ebd0",
    "#34ebd0",
  ];
  public letter: string;
  constructor() {
    if (!this.index) {
      this.color = this.chooseColor();
    }
  }

  ngOnInit() {
    this.letter = this.name[0].toLocaleUpperCase();
  }

  // Wählt eine zufällige Farbe (Index zwischen 1 und 6) für den Hintergrund des Autorbuchstabens aus.
  chooseColor(): string {
    return this.colors[Math.floor(Math.random() * 6)];
  }

  // Holt das default Profilbild vom environment
  getDefaultProfileImageUrl(): string {
    return environment.defaultPictureProfile;
  }
}
