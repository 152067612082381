import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CertificationRequestStatus } from 'src/app/model/enums/certification-requests-status';

@Component({
  selector: 'app-cert-req-change-status',
  template: `
  <h1 mat-dialog-title>{{data.title}}</h1>
  <mat-radio-group
    class="radio-group"
    [(ngModel)]="newStatus">
    <mat-radio-button class="radio-button" *ngFor="let status of statusList" [value]="status">
      {{status}}
    </mat-radio-button>
  </mat-radio-group>
    <div mat-dialog-actions>
      <button mat-button (click)="onClick()" class="btn_red" cdkFocusInitial>Abbrechen</button>
      <button mat-button (click)="onClick()" class="btn_green" cdkFocusInitial>Bestätigen</button>
    </div>
`,
  styleUrls: ['./dialog.css']
})
export class DialogCertReqChangeStatusComponent{
  newStatus: CertificationRequestStatus;
  statusList: CertificationRequestStatus[] = [CertificationRequestStatus.WAITING, CertificationRequestStatus.IN_PROGRESS, CertificationRequestStatus.CLOSED]

  constructor(
    public dialogRef: MatDialogRef<DialogCertReqChangeStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {}

  onClick(): void{
    this.dialogRef.close(this.newStatus);
  }

}
