<div class="wrapper">
    <div class="hexagon outer white">
        <div class="hexagon inner green">
            <div class="hexagon inner white">
                <div class="hexagon inner">
                    <app-secure-image [src]="img"></app-secure-image>
                </div>
            </div>
        </div>
    </div>
</div>