import { Component, OnInit, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { EventEmitter } from "@angular/core";
import { ProductlistService } from "src/app/services/productlist-service/productlist.service";
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-product-detail-display-demo-bar",
  templateUrl: "./product-detail-display-demo-bar.component.html",
  styleUrls: [
    "./product-detail-display-demo-bar.component.css",
    "../product-detail-display.css",
  ],
})
export class ProductDetailDisplayDemoBarComponent implements OnInit {
  constructor(private router: Router, private dialogService: DialogService, private userService: UserService) {}

  @Input()
  website: string;

  @Input()
  productId: number;

  requestDemo() {
    this.dialogService.openDemoRequestDialog(this.productId);
  }
  @Output() addToProductListEmitter = new EventEmitter<any>();


  requestOffer() {
    this.dialogService.openOfferRequestDialog(this.productId);
  }

  routeToMalfunctionOverview() {
    let url =
      this.router.url.replace("marktplatz/products", "malfunction") +
      "/overview";
    this.router.navigateByUrl(url);
  }

  routeToWebsite() {
    if (this.website == null || this.website == undefined || this.website == "")
      return;

    // check for correct scheme
    let url;
    if (this.website.match("http")) {
      url = this.website;
    } else {
      url = "http://" + this.website;
    }

    let valid_url;
    try {
      valid_url = new URL(url);
    } catch (e) {
      return;
    }

    window.open(url, "_blank");
  }

  addToProductList() {
    this.addToProductListEmitter.emit();
  }

  isLoggedIn(): boolean {
    return this.userService.loggedIn;
  }

  ngOnInit() {}
}
