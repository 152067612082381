<div class="wrapper">
  <h1>Produkte zertifizieren</h1>

  <mat-form-field appearance="fill">
    <mat-label>Organisationsauswahl</mat-label>
    <mat-select
      [(value)]="selectedOrganisation"
      (valueChange)="this.loadTableData()"
    >
      <mat-option
        *ngFor="let organisation of orgaList"
        [value]="organisation.id"
      >
        {{ organisation.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="table-container" *ngIf="this.selectedOrganisation">
    <div class="grid_wrapper_4">
      <p class="grid_title">Zertifiziert</p>
      <p class="grid_title">Produkt</p>
      <p class="grid_title">Variante</p>
      <p class="grid_title">Version</p>
    </div>
    <ng-container *ngFor="let overview of this.overviewList">
      <div class="grid_wrapper_4">
        <div class="grid_checkbox">
          <mat-checkbox
            [(ngModel)]="overview.hasCertification"
            (change)="localChange(overview)"
          >
          </mat-checkbox>
        </div>
        <div class="grid_field">{{ overview.productName }}</div>
        <div class="grid_field">{{ overview.variantName }}</div>
        <div class="grid_field">{{ overview.versionName }}</div>
      </div>
    </ng-container>
  </div>
  <!-- Checkbox Column -->

  <p>Bereits zertifizierte Produkte:</p>
  <div class="table-container">
    <div class="grid_wrapper">
      <p class="grid_title">Organisation</p>
      <p class="grid_title">Produkt</p>
      <p class="grid_title">Variante</p>
      <p class="grid_title">Version</p>
      <p class="grid_title">Erstelldatum</p>
      <p class="grid_title">Gültigkeit</p>
    </div>
    <ng-container *ngFor="let overview of this.certifiedProductPage">
      <div class="grid_wrapper">
        <div class="grid_field">{{ overview.organisation }}</div>
        <div class="grid_field">{{ overview.productName }}</div>
        <div class="grid_field">{{ overview.variantName }}</div>
        <div class="grid_field">{{ overview.versionName }}</div>
        <div [ngClass]="{highlight: overview.hasDateChanged}" class="grid_field_clickable" (click)="picker.open()">
          {{ overview.lastCertified | humanReadableDate }}
          <i class="fa fa-calendar"></i>
          <div style="width: 1px; visibility: hidden">
            <input
              matInput
              ngModel="{{ overview.lastCertified | humanReadableDate }}"
              (ngModelChange)="updateProductCertificationDateValue($event, overview)"
              [matDatepicker]="picker"
            />
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div
          *ngIf="overview.validUntil != 'unbegrenzt'"
          [ngClass]="{ highlight: overview.hasHighlightValidUntil }"
          class="grid_field"
        >
          {{ overview.validUntil | humanReadableDate }}
        </div>
        <div
          *ngIf="overview.validUntil == 'unbegrenzt'"
          [ngClass]="{ highlight: overview.hasHighlightValidUntil }"
          class="grid_field"
        >
          {{ overview.validUntil }}
        </div>
      </div>
    </ng-container>
  </div>
  <div>
    <p>Bereits zertifizierte nicht gelistete Produkte:</p>
    <div class="table-container">
      <div class="grid2_wrapper">
        <p class="grid_title">Organisation</p>
        <p class="grid_title">Produkt</p>
        <p class="grid_title">Variante</p>
        <p class="grid_title">Version</p>
        <!--p class="grid_title">Gültigkeit</p-->
      </div>
      <ng-container *ngFor="let overview of this.certifiedNonListedProductPage">
        <div class="grid2_wrapper">
          <div class="grid_field">{{ overview.organisationName }}</div>
          <div class="grid_field">{{ overview.productName }}</div>
          <div class="grid_field">{{ overview.productVariant }}</div>
          <div class="grid_field">{{ overview.variantVersion }}</div>
          <!--div class="grid_field">
        {{ overview.validUntil | humanReadableDate }}
      </div-->
        </div>
      </ng-container>
    </div>

    <div *ngIf="this.showNonListedProdCert">
      <h3>Nicht gelistete Produkte zertifizieren</h3>
      <div class="toggleswitchgrid">
        <h4>Die Organisation existiert bereits auf der Plattform</h4>
        <mat-slide-toggle
          class="toggleswitch"
          [(ngModel)]="this.toggleOrganisationSwitch"
          (change)="cancelNonListedProductCertification(false)"
        >
        </mat-slide-toggle>
      </div>
      <div class="two-columns" *ngIf="this.toggleOrganisationSwitch">
        <div class="not-listed-prod-grid-typography">
          <h4>Organisation *</h4>
          <h4>Produkt *</h4>
          <h4>Variante *</h4>
          <h4>Version *</h4>
          <h4>Produkttyp*</h4>
          <h4>E-Mail Adresse des Anbieters *</h4>
        </div>
        <div class="not-listed-prod-grid">
          <mat-form-field (change)="this.findEmail()">
            <mat-label required>Organisationsauswahl*</mat-label>
            <mat-select>
              <mat-option
                *ngFor="let organisation of orgaList"
                [value]="organisation.id"
                (click)="
                  this.orgaName = organisation.name;
                  this.findEmail(organisation.id)
                "
                required
              >
                {{ organisation.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Produkt"
              [(ngModel)]="productname"
              required
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Variante"
              [(ngModel)]="productVariant"
              required
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Version"
              [(ngModel)]="productVariantVersion"
              required
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label required>Produkttypauswahl*</mat-label>
            <mat-select>
              <mat-option
                *ngFor="let productTyp of productType"
                (click)="this.prodType = productTyp; setProductType()"
                [value]="this.prodType"
              >
                {{ productTyp }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="E-Mail Adresse des Anbieters"
              [(ngModel)]="emailofOrga"
              required
            />
          </mat-form-field>
        </div>
        <div>
          <h4>* ... - Pflichtfelder</h4>
        </div>
      </div>
      <div class="two-columns" *ngIf="!this.toggleOrganisationSwitch">
        <div class="not-listed-prod-grid-typography">
          <h4>Organisation *</h4>
          <h4>Produkt *</h4>
          <h4>Variante *</h4>
          <h4>Version *</h4>
          <h4>Produkttyp *</h4>
          <h4>E-Mail Adresse des Anbieters *</h4>
        </div>
        <div class="not-listed-prod-grid">
          <mat-form-field>
            <input
              matInput
              placeholder="Organisation"
              [(ngModel)]="orgaName"
              required
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Produkt"
              [(ngModel)]="productname"
              required
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Variante"
              [(ngModel)]="productVariant"
              required
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Version"
              [(ngModel)]="productVariantVersion"
              required
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label required>Produkttypauswahl*</mat-label>
            <mat-select>
              <mat-option
                *ngFor="let productTyp of productType"
                (click)="this.prodType = productTyp; setProductType()"
                [value]="this.prodType"
              >
                {{ productTyp }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="E-Mail Adresse des Anbieters"
              [(ngModel)]="emailofOrga"
              required
            />
          </mat-form-field>
        </div>
        <div>
          <h4>* ... - Pflichtfelder</h4>
        </div>
      </div>
      <div *ngIf="this.error" (change)="(this.error)">
        <h4 class="errorTitle">{{ this.undefinedVariable }}</h4>
      </div>
    </div>
  </div>

  <div *ngIf="!this.showNonListedProdCert" class="mini-grid-buttons">
    <button
      mat-raised-button
      class="btn_green certbutton"
      (click)="this.showNonListedProdCertOptions()"
    >
      Nicht gelistete Produkte zertifizieren
    </button>
  </div>

  <div *ngIf="this.showNonListedProdCert" class="mini-grid-buttons">
    <button
      mat-raised-button
      (click)="this.submitNonListedProductCertification()"
      class="btn_green"
    >
      Hinzufügen
    </button>

    <button
      mat-raised-button
      (click)="this.cancelNonListedProductCertification(true)"
      class="btn_red"
    >
      Abbrechen
    </button>
  </div>

  <div *ngIf="!this.showNonListedProdCert" class="mini-grid-buttons">
    <button
      mat-raised-button
      class="btn_green"
      (click)="this.commitCertificationProcess()"
    >
      Speichern
    </button>
    <button
      mat-raised-button
      class="btn_red"
      (click)="this.cancelCertificationProcess()"
    >
      Abbrechen
    </button>
  </div>
</div>
