import { Router } from "@angular/router";
import { UserService } from "src/app/services/user-service/userservice";
import { FormBuilder, Validators } from "@angular/forms";
import { FormGroup, AbstractControl } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { VALIDATORS } from 'src/environments/validators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const SWARM_API_PCSSO = "/proxy/api/v0/pcssoservice/";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Component({
  selector: "app-password-reset-screen",
  templateUrl: "./password-reset-screen.component.html",
  styleUrls: ["./password-reset-screen.component.css"],
})


export class PasswordResetScreenComponent implements OnInit {
  passwordResetForm: FormGroup;

  email: AbstractControl;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private dialogService: DialogService,
    private http: HttpClient
  ) {
    this.passwordResetForm = formBuilder.group({
        email: ["", VALIDATORS.email],
    });
    this.email = this.passwordResetForm.controls["email"];
  }

  onSubmit() {
    let username = this.email.value.toLowerCase();
    this.userService
      .resetPassword(username)
      .then(() => {
        this.dialogService
          .openDialog(
            "Passwort zurückgesetzt",
            "Ihr Passwort wurde erfolgreich zurückgesetzt. " +
              "Innerhalb der nächsten Minuten sollten Sie eine E-mail mit Ihrem neuen Passwort erhalten. " +
              "Nach erfolgreicher Anmeldung können Sie Ihr Passwort auf Ihr gewünschtes Passwort ändern."
          )
          .subscribe(() => {
            this.router.navigate(["login"]);
          });
      })
      .catch(() => {});

      return new Promise((resolve, reject) => {
        this.http.get(SWARM_API_PCSSO + "oauth/resetRestrictionCooldown/"
         + username,
          httpOptions)
          .subscribe (
            () => {
              resolve(true);
            },
            () => {
              reject(false);
            }
          )
      })
  }

  public onCancel(): void {
    window.close();
  }

  ngOnInit() {}
}
