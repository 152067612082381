import { ProviderInterface } from './../../model/user/provider-interface';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription, Unsubscribable  } from 'rxjs';
import { OrganisationInterface } from 'src/app/model/organisations/organisation-interface';
import { OrganisationMenuTabs } from 'src/app/model/enums/organisationMenuTabs';
import { OrganisationType } from 'src/app/model/enums/organisationType';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { API_BASE_FILESERVICE, API_MAP } from 'src/environments/api';
import { environment } from 'src/environments/environment';
import { UserService } from '../user-service/userservice';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeaderServiceService {

  public activeOrgSub: Unsubscribable;
  public activeOrganisation: OrganisationInterface;

  private activeHeaderSubject = new BehaviorSubject<String>("Marktplatz");
  public activeHeaderObservable$: Observable<String> = this.activeHeaderSubject.asObservable();

  private providersSubject = new BehaviorSubject<ProviderInterface[]>([]);
  public providersObservable$ : Observable<ProviderInterface[]> = this.providersSubject.asObservable();

  private activeProviderSubject = new BehaviorSubject<ProviderInterface>({});
  private activeProviderUserObservable$ : Observable<ProviderInterface> = this.activeProviderSubject.asObservable();
  public activeProviderObservable$ : Observable<ProviderInterface>;

  private menuTabs: OrganisationMenuTabs[] = [OrganisationMenuTabs.SETTINGS];
  public activeTabIndex: number = 0;

  constructor(
    private organisationService: OrganisationService,
    private userService: UserService,
    private router: Router,
  ) {

    this.activeOrgSub = this.organisationService.activeItemObserver.subscribe(
      (org) => {
        if (org) {
          this.activeOrganisation = org;
          this.setMenuTabOptions(this.activeOrganisation.organisationType);
        }
      }
    );


    this.providersObservable$ = combineLatest([this.userService.currentUserObservable, this.userService.currentMembershipsObserver]).pipe(map(([user, memberships]) => {
      let updatedProviders: ProviderInterface[] = [];
      if(user) {
        let image = (user.fileId)? API_BASE_FILESERVICE + API_MAP["files"]["GET"].replace(":fileId", user.fileId) : environment.defaultPictureProfile;
        let updatedUser: ProviderInterface = {
          name: user.firstName + " " + user.lastName,
          img: image
        };
        updatedProviders.push(updatedUser);
      }
      let updatedMemberships = memberships.map(membership => {
        let image = (membership.fileId) ? API_BASE_FILESERVICE + API_MAP["files"]["GET"].replace(":fileId", membership.fileId) : environment.defaultPictureOrganisationProducts;
        let updatedMembership: ProviderInterface = {
          name: membership.organisationName,
          img: image,
          organisationId: membership.organisationId
        }
        return updatedMembership;
      })
      updatedProviders.push(...updatedMemberships);
      return updatedProviders;
    }));

    this.activeProviderObservable$ = combineLatest([this.providersObservable$, this.organisationService.activeItemObserver, this.activeProviderUserObservable$])
      .pipe(map(([providers, activeOrganisation, activeProviderUser]) => {
        let updatedActiveProvider : ProviderInterface = providers[0];
        if(activeOrganisation) {
          let indexInProviders = providers.findIndex(provider => provider.organisationId == activeOrganisation.organisationId);
          if(indexInProviders != -1) {
            updatedActiveProvider = providers[indexInProviders];
          }
                this.loadHeaderText(this.setHeaderText(updatedActiveProvider.name));
        }
        if(activeProviderUser) {
          let indexInProviders = providers.findIndex(provider => provider.name == activeProviderUser.name);
          if(indexInProviders != -1) {
            updatedActiveProvider = providers[indexInProviders];

          }
          this.loadHeaderText(this.setHeaderText(updatedActiveProvider.name));

        }

        return updatedActiveProvider;
      }))
  }






 private setHeaderText(userName: string): string {
   let tmpHeader: string = this.router.url.split('?')[0] ;

   if (tmpHeader.includes('/privacy')) {

     tmpHeader = 'Datenschutzerklärung';

   } else if (tmpHeader == '/impressum') {

     tmpHeader = 'Impressum';

    } else if (tmpHeader == '/composer') {

      tmpHeader = 'Composer';

    } else if (tmpHeader == '/marktplatz') {

        tmpHeader = 'Marktplatz';

    } else{
         tmpHeader = userName;
      }
   return tmpHeader ;
 }

  loadHeaderText(text: string) {
    this.activeHeaderSubject.next(text);
  }

  public getMenuTabs(): OrganisationMenuTabs[] {
    return this.menuTabs;
  }

  public setMenuTabs(newTabs: OrganisationMenuTabs[]) {
    this.menuTabs = newTabs;
  }

  /**
   * Set the tab options dependent on the organisationType: e.g. "Produkte"-Tab for product provider organisations.
   * If the user is the activeProvider this function will not be called as the user has no organisationId. The only available tab then is "Einstellungen"
   * @param organisationType
   */
  public setMenuTabOptions(organisationType: OrganisationType): void {



    switch(organisationType) {
      case OrganisationType.PRODUCT_PROVIDER: {
        this.setMenuTabs([OrganisationMenuTabs.SETTINGS, OrganisationMenuTabs.PRODUCTS]);
        break;
      }
      case OrganisationType.PRODUCT_TYPE_ASSOCIATION: {
        this.setMenuTabs([OrganisationMenuTabs.SETTINGS, OrganisationMenuTabs.PRODUCT_TYPES, OrganisationMenuTabs.ATTRIBUTES]);
        break;
      }
      default: {
        //Certification office or certification provider

        this.setMenuTabs([OrganisationMenuTabs.SETTINGS, OrganisationMenuTabs.CERTIFICATIONS]);


        break;
      }
    }
  }

  /**
   * The user is the only provider that doesnt have an organisationId in the providers-Array: If no organisationId is present, route to user settings
   * @param organisationId
   */
  private openSettings(organisationId?: number): void {
    if(organisationId) {
      this.router.navigate(["organisations/" + organisationId + "/information"]);

    } else {
      this.router.navigate(["profil/settings"]);

    }
    this.activeTabIndex = 0;

  }

  setActiveProvider(newActiveProvider: ProviderInterface,backToOverview?: boolean) {
    this.activeProviderSubject.next(newActiveProvider);
    if(!backToOverview) this.openSettings(newActiveProvider.organisationId);
    if(newActiveProvider.organisationId) {
      this.organisationService.setActiveOrganisation(newActiveProvider.organisationId);

    } else if(backToOverview) {// auf dem Marktplatz aktives profil immer der user

      this.router.navigate(["/"]);


    } else {
      //No organisationId --> user is activeProvider and no tabs should be available.



     //this.setMenuTabs([OrganisationMenuTabs.SETTINGS]);
     this.setMenuTabs([]);


    }
    if(!backToOverview)  this.activeTabIndex = 0;

    this.loadHeaderText(newActiveProvider.name);


  }

  public changedTab(event: MatTabChangeEvent): void {
    this.activeTabIndex = event.index;
    let activeMenuTab: OrganisationMenuTabs = this.getMenuTabs()[event.index];
    switch(activeMenuTab) {
      case OrganisationMenuTabs.SETTINGS: {
        this.openSettings(this.activeOrganisation.organisationId);
        break;
      }
      case OrganisationMenuTabs.PRODUCTS: {
        this.router.navigate(["/organisations/" + this.activeProviderSubject.value.organisationId + "/products"]);
        break;
      }
      case OrganisationMenuTabs.CERTIFICATIONS: {
        this.router.navigate(["/organisations/" + this.activeProviderSubject.value.organisationId + "/certifications"]);
        break;
      }
      case OrganisationMenuTabs.ATTRIBUTES: {
        this.router.navigate(["/organisations/" + this.activeProviderSubject.value.organisationId + "/features"]);
        break;
      }
      case OrganisationMenuTabs.PRODUCT_TYPES: {
        this.router.navigate(["/organisations/" + this.activeProviderSubject.value.organisationId + "/productTypeGroups"]);
        break;
      }
      default: {
        this.openSettings(null);

     break;
  }
    }
  }
}
