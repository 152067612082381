<div [ngClass]="editForm ? 'grid-layout' : 'grid-layout disabled'" [formGroup]="form">
  <!-----------Linke Spalte----------------------------->
  <div class="left-column">
    <!--------------Titel-Eingabe------------>
    <mat-form-field class="titel">
      <input matInput formControlName="titel" placeholder="Geben Sie den Titel ein." required />
      <mat-error *ngIf="form.get('titel').invalid">{{
      getErrorMessageTitel()
    }}</mat-error>
    </mat-form-field>

    <div>
      <p>Beschreibung</p>
      <!-----------Beschreibungseingabe------------------------>
      <quill-editor class=" write-description" formControlName="description" placeholder="Hier Beschreibung einfügen"
        [styles]="textEditorStyle" [modules]="textEditorModules" maxlength="maxDescriptionChar">
      </quill-editor>
      <mat-error *ngIf="form.get('description').invalid">"Ihre Beschreibung darf nicht länger als
        {{this.maxDescriptionChar}} Zeichen sein"
      </mat-error>
    </div>
  </div>

  <!-------------------------------------Rechte Splate------------------------------------>
  <div class="right-column">
    <!--------------------------------Auswahl für Status------------------------------>
    <mat-form-field class="status" required>
      <mat-select placeholder="Status" [value]="form.value.status" formControlName="status">
        <mat-option [value]="'REPORTED'">Störung gemeldet</mat-option>
        <mat-option [value]="'IN_PROGRESS'">Störung in Arbeit</mat-option>
        <mat-option *ngIf="malfunction" [value]="'FIXED'">Störung behoben</mat-option>
      </mat-select>
    </mat-form-field>
    <br />

    <!--------------------------------Auswahl für Priorität-------------------------------->
    <mat-form-field class="priority">
      <mat-select placeholder="Priorität" [value]="form.value.priority" (valueChange)="form.value.priority = $event" formControlName="priority">
        <mat-option [value]="1">sehr gering</mat-option>
        <mat-option [value]="2">gering</mat-option>
        <mat-option [value]="3">mäßig</mat-option>
        <mat-option [value]="4">hoch</mat-option>
        <mat-option [value]="5">sehr hoch</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox formControlName="isPublic">Störung öffentlich anzeigen</mat-checkbox>

    <!------------------Auswahl der Sachbearbeiter------------------>
    <div *ngIf="form.get('status').value==='IN_PROGRESS' || form.get('status').value === 'FIXED'">
      <p>Bearbeiter</p>
      <div class="flex" *ngFor="let clerk of clerkList.value; let i = index">
        <div class="clerk-selector">
          <mat-form-field class="clerk-selector">
            <mat-select [disabled]="true"
              placeholder="{{clerk.firstName + ' ' + clerk.lastName + ' - ' + clerk.userName}}">
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <red-cross-button *ngIf="clerk && editForm" (press)="removeClerk(clerk, i)"></red-cross-button>
        </div>
      </div>

      <div class="flex" *ngIf="editForm">
        <div class="clerk-selector">
          <mat-form-field class="clerk-selector">
            <mat-select placeholder="Bearbeiter" [disabled]="membersOfOrga && membersOfOrga?.length <= 0"
              [(value)]="currentlySelectedClerk">
              <mat-option *ngFor="let member of membersOfOrga" [value]="member">
                {{member.firstName + " " + member.lastName + " - " + member.userName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mini-hex-button">
          <hexagon [icon]="'plus'" [hover]="true" [outerColor]="'green'" (click)="addClerk()"></hexagon>
        </div>
      </div>
    </div>
  </div>

  <!--------------------Auswahl der betroffenen Produkte-------------------------->
  <div class="reported-versions-row">
    <p>Wählen Sie die Produktversionen aus, für die diese Störung gilt.</p>
    <div class="flex">
      <div class="variant-version-selector">
        <app-detail-display-variant-version-selector *ngIf="productVariants" [dataType]="'PRODUCT'"
          [variants]="productVariants" [disabled]="!editForm"
          (onVersionChosen)="currentlySelectedVariantName = $event.name"
          (onVariantChosen)="currentlySelectedVersionName = $event.name">
        </app-detail-display-variant-version-selector>
      </div>
      <div class="mini-hex-button" *ngIf="editForm">
        <hexagon [icon]="'plus'" [hover]="true" [outerColor]="'green'" (click)="addVariantVersion()"></hexagon>
      </div>
    </div>

    <div class="version-list">
      <h4 *ngIf="versionList.value.length > 0">Betroffene Produktversionen</h4>
      <div class="flex" *ngFor="let version of versionList.value; let i=index">
        <p> {{version}}</p>
        <red-cross-button *ngIf="version && editForm" (press)="removeVariantVersion(i)"></red-cross-button>
      </div>
    </div>
  </div>

  <!---------Submit- und Cancel-Buttons----------->
  <div class="button-row" *ngIf="editForm">
    <button mat-raised-button class="btn_green align-left" (click)="submitMalfunction()">
      Störung absenden
    </button>
    <button mat-raised-button class="btn_red align-right" (click)="cancel()">
      Abbrechen
    </button>
  </div>

  <!---------Buttons zum bearbeiten-->
  <div class="edit button-row" *ngIf="!editForm && adminView">
    <button mat-raised-button class="btn_green align-left" (click)="enableEdit()">Bearbeiten</button>
    <button mat-raised-button class="btn_red align-right" (click)="deleteMalfunction(malfunction)">Löschen</button>
  </div>
</div>