import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from "@angular/router";
import { DialogService } from "../dialog-service/dialog-service.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router, public dialogService: DialogService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem("access_token")) {
      return true;
    }
    this.router.navigate(["/login"]);
    //Hier muss der Dialog direkt aufgerufen werden, weil die Methodensignatur
    //aus Angular heraus festgelegt ist und kein Aufruf durch eine Komponente
    //stattfindet.
    this.dialogService.openDialog(
      "Kein Zugang!",
      "Loggen Sie sich mit einem vorhandenen Account ein oder erstellen Sie einen Account um auf die BIM-SWARM-Plattform zugreifen zu können!"
    );
    return false;
  }
}
