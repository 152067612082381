export class ServiceError {

  errorNumber: number;
  errorType: string;
  errorTitle: string;
  errorText: string;

  constructor(id: number, type: string, title: string, text: string) {
    this.errorNumber = id;
    this.errorType = type;
    this.errorTitle = title;
    this.errorText = text;

  }

}
