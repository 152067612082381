import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { ProductVariantInterface } from "src/app/model/products/product-variant-interface";
import { VariantVersionInterface } from "src/app/model/products/variant-version-interface";
import { ProductVariantService } from "src/app/services/dataServices/product-variant-service/product-variant.service";
import { AttributeValidationServiceService } from "src/app/services/dataServices/dataValidation/attribute-validation-service/attribute-validation-service.service";
import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { ProductDtoConverterServiceService } from 'src/app/services/product-dto-converter-service/product-dto-converter-service.service';
import { UtilService } from 'src/app/services/util-service/utilService';
import { UserService } from 'src/app/services/user-service/userservice';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';

@Component({
  selector: "app-create-product-variant-screen",
  templateUrl: "./create-product-variant-screen.component.html",
  styleUrls: ["./create-product-variant-screen.component.css"],
})
export class CreateProductVariantScreenComponent implements OnInit, OnDestroy {
  public variant: ProductVariantInterface;
  public version: VariantVersionInterface;

  private isFormValid: boolean = false;
  private isDescriptionValid: boolean = true;

  constructor(
    public productService: ProductService,
    private productVariantService: ProductVariantService,
    private productDtoConverterService: ProductDtoConverterServiceService,
    private router: Router,
    private dialogService: DialogService,
    private utilService: UtilService,
    public attributeValidationService: AttributeValidationServiceService,
    private userService: UserService,
    private organisationService: OrganisationService,
    private titleService: Title
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnInit(): void {
    this.version = {};
    
    this.variant = {
      name: "",
      variantVersions: [this.version],
      description: "",
    };
    this.titleService.setTitle(this.productService.activeItem.name + ": Variante anlegen - BIMSWARM");
  }

  ngOnDestroy(): void { 
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }


  cancelCreateVariant(): void {
    this.router.navigate([
      this.productDtoConverterService.getRedirectUrl() +
        this.productService.activeItem.id,
    ]);
  }

  createVariant(): void {
    if (!this.isInputValid()) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen Sie sicher, dass alle Attribute validiert sind" +
          " oder entfernen Sie alle Produkttypzugehörigkeiten, von denen nicht alle Attribute validiert sind"
      );
      return;
    }

    if(!UtilService.areStringsValid(this.variant.name, this.variant.description)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Der Name oder die Beschreibung sind zu lang."
      );
      return;
    }

    if(!UtilService.areStringsValid(this.version.name, null)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen sie sicher, dass der Name der Version nicht zu lang ist."
      );
      return;
    }

    if (this.variant.description == null) {
      this.variant.description = "";
    }
    if (this.version.description == null) {
      this.version.description = "";
    }

    if (
      this.utilService.isStringNullOrEmpty(this.variant.name) ||
      this.utilService.isStringNullOrEmpty(this.version.name)
    ) {
      this.dialogService.openDialog(
        "Variante erstellen",
        "Bitte geben Sie einen Varianten- und Versionsnamen an!"
      );
      return;
    }

    this.version.attributeValidationValues = this.attributeValidationService.getAttributeValidationValues();

    this.productVariantService.postItem(this.variant).then(
      (x) => {
        let url =
          "/organisations/" +
          this.productService.activeItem.company +
          "/products/" +
          this.productService.activeItem.id +
          "/variants/" +
          x.id;
        this.router.navigateByUrl(url);
        this.dialogService.openDialog(
          "Variante erstellt",
          "Die neue Variante " + x.name + " wurde erfolgreich erstellt."
        );
      },
      (e) => {}
    );
  }

  public isInputValid(): boolean {
    return this.attributeValidationService.checkAllEntityAttributeValuesValidated();
  }

  public onValidityChanged(valid: boolean): void {
    this.isFormValid = valid;
  }

  public onDescriptionValidityChange(descriptionValid: boolean): void {
    this.isDescriptionValid = descriptionValid;
  }
}
