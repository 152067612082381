<div class="two-columns">
  <div>
    <h1>Konto</h1>
    <mat-form-field *ngIf="userservice.currentUser">
      <input
        matInput
        placeholder="E-Mail"
        [value]="userservice.currentUser.userName"
        disabled
      />
    </mat-form-field>

    <app-hexagon-button-text-icon
      [text]="'Passwort ändern'"
      [icon]="'/assets/img/icons/Konto_zusammenfuehren.png'"
      (click)="changePasswordModeToggle()"
    >
    </app-hexagon-button-text-icon>
    <div *ngIf="changePasswordMode" class="grid-change-pw">
      <form
        [formGroup]="passwordChangeForm"
        (keydown.enter)="$event.preventDefault()"
      >
        <div class="gridAbove">
          <mat-form-field class="password-field">
            <input
              matInput
              placeholder="Altes Passwort"
              type="password"
              (input)="onCurrentPasswordChange(curPassword.value)"
              #curPassword
              required
            />
          </mat-form-field>
          <mat-form-field class="password-field">
            <input
              matInput
              placeholder="Neues Passwort"
              formControlName="password"
              [formControl]="password"
              type="password"
              required
            />
            <mat-error *ngIf="password.hasError('required')"
              >Diese Angabe ist erforderlich</mat-error
            >
            <mat-error
              *ngIf="
                !password.hasError('required') &&
                password.hasError('passwordlength')
              "
              >Das Passwort muss mindestens 6 Zeichen lang sein</mat-error
            >
            <mat-error
              *ngIf="
                !password.hasError('required') &&
                password.hasError('passwordSpecialChar')
              "
              >Das Passwort muss mindestens ein Sonderzeichen
              beinhalten</mat-error
            >
            <mat-error
              *ngIf="
                !password.hasError('required') &&
                password.hasError('passwordNumber')
              "
              >Das Passwort muss mindestens eine Zahl beinhalten</mat-error
            >
          </mat-form-field>
          <mat-form-field class="password-repeat-field">
            <input
              matInput
              placeholder="Neues Passwort wiederholen"
              formControlName="confirmpassword"
              [formControl]="confirmpassword"
              type="password"
              [errorStateMatcher]="matcher"
              required
            />
            <mat-error *ngIf="password.hasError('required')">
              Diese Angabe ist erforderlich
            </mat-error>
            <mat-error *ngIf="passwordChangeForm.hasError('notEqual')">
              Diese Angabe stimmt nicht mit dem Passwort überein
            </mat-error>
          </mat-form-field>
        </div>
        <app-password-strength [password]="password.value"></app-password-strength>
        <div class="buttons">
          <button
            mat-raised-button
            type="button"
            class="btn_green"
            [disabled]="passwordChangeForm.invalid"
            (click)="onChangePasswordConfirm()"
          >
            Speichern
          </button>
          <button
            mat-raised-button
            type="button"
            class="btn_red"
            (click)="changePasswordModeToggle()"
          >
            Abbrechen
          </button>
        </div>
      </form>
    </div>

    <app-hexagon-button-text-icon
    [text]="'E-Mail Adresse ändern'"
    [icon]="'/assets/img/icons/Konto_zusammenfuehren.png'"
    (click)="changeEmailModeToggle()"
    >
    </app-hexagon-button-text-icon>
    <div *ngIf="changeEmailMode" class="grid-change-email">
        <form
        [formGroup]="emailChangeForm"
        (keydown.enter)="$event.preventDefault()"
        >
        <div class="gridAbove">
            <mat-form-field *ngIf="userservice.currentUser">
                <input
                  matInput
                  placeholder="Aktuelle E-Mail Adresse"
                  disabled
                  [value]="userservice.currentUser.userName"
                />
            </mat-form-field>
              <mat-form-field class="stretch">
                <input
                    matInput
                    placeholder="Neue E-Mail Adresse"
                    formControlName="newEmail"
                    [formControl]="newEmail"
                    type="email"
                    autocomplete="email"
                    required
                />
                <mat-error *ngIf="newEmail.errors?.required"
                  >Bitte geben sie eine E-Mail an</mat-error
                >
                <mat-error
                  *ngIf="newEmail.errors?.pattern && !newEmail.errors?.required"
                  >Bitte geben Sie eine valide E-Mail an</mat-error
                >
              </mat-form-field>
        </div>
        <div class="buttons">
            <button
            mat-raised-button
            type="button"
            class="btn_green"
            [disabled]="emailChangeForm.invalid"
            (click)="onChangeEmailConfirm()"
            >
            Speichern
            </button>
            <button
            mat-raised-button
            type="button"
            class="btn_red"
            (click)="changeEmailModeToggle()"
            >
            Abbrechen
            </button>
        </div>
        </form>
    </div>

    <app-hexagon-button-text-icon
      [text]="'Konto löschen'"
      [icon]="'/assets/img/icons/Konto_loeschen.png'"
      (click)="deleteAccountModeToggle()"
    >
    </app-hexagon-button-text-icon>

    <div *ngIf="deleteAccountMode">
      <form>
        <div class="grid-delete-account">
          <mat-form-field>
            <textarea
              matInput
              placeholder="Warum möchten Sie ihr BimSWARM Konto löschen?"
            ></textarea>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Passwort"
              type="password"
              [formControl]="confirmDeletePassword"
              required
            />
            <mat-error *ngIf="confirmDeletePassword.invalid"
              >Diese Angabe ist erforderlich</mat-error
            >
          </mat-form-field>
          <div class="buttons">
            <button
              type="button"
              mat-raised-button
              class="btn_green"
              [disabled]="confirmDeletePassword.invalid"
              (click)="onDelete()"
            >
              Konto löschen
            </button>
            <button
              type="button"
              mat-raised-button
              class="btn_red"
              (click)="deleteAccountModeToggle(); confirmDeletePassword.reset()"
            >
              Abbrechen
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
