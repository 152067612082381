<div
  class="row"
  [ngClass]="{
    odd: index % 2 != 0,
    even: index % 2 == 0
  }"
>
  <div class="firstTableColumn">
    <p>{{ data.toolchainId }}</p>
  </div>
  <div class="centered">
    <p>{{ data.name }}</p>
  </div>
  <div class="centered">
    <!-- Update to Angular 9: Splitting dates no longer works this way. Needs correction. -->
    <!-- <p>{{ data.lastEdit.split(" ")[0] | humanReadableDate }} {{ data.lastEdit.split(" ")[1] | humanReadableClockTime }}Uhr</p> -->
  </div>
  <div class="centered">
    <p>{{ editorName }}</p>
  </div>
  <div class="buttonContainer">
    <div class="svg-icon inspectButton" (click)="openTemplate()"></div>
    <div class="svg-icon startButton" (click)="createToolchainInstance()"></div>
  </div>
  <div>
    <div
      class="triangle"
      [ngClass]="{
        turn: !collapsed
      }"
      (click)="toggleCollapse()"
    ></div>
  </div>
</div>
<div
  class="expandedRow"
  [ngClass]="{
    odd: index % 2 != 0,
    even: index % 2 == 0,
    hidden: collapsed
  }"
>
  <div class="expandedRowRow">
    <div class="textWithLabel">
      <p>Name</p>
      <p class="underlined">{{ data.name }}</p>
    </div>
    <div class="textWithLabel">
      <p>Assoziierte Benutzer</p>
      <div class="associatedUsers">
        <p *ngIf="creatorName">{{creatorName}}</p>
        <p>{{editorName}}</p>
        <!-- TODO <p *ngFor="let user of data.associatedUsers">{{ user }}</p> -->
      </div>
    </div>
  </div>
  <div class="expandedRowRow">
    <div class="textWithLabel">
      <p>Beschreibung</p>
      <p>{{ data.description }}</p>
    </div>
    <div [id]="'viewerContainer' + data.toolchainId"></div>
  </div>
  <div class="expandedRowRow">
    <button mat-raised-button class="btn_green" (click)="openTemplate()">Template öffnen</button>
    <div>
      <button mat-raised-button class="btn_green" (click)="createToolchainInstance()" [disabled]="!data.valid || !data.active">Ausführen</button>
      <button mat-raised-button class="btn_red" (click)="deleteToolchainTemplate()">Löschen</button>
    </div>
  </div>
  <app-toolchain-instance-table [toolchainInstances]="instances" (deleteToolchainEvent)="deleteToolchain($event)"></app-toolchain-instance-table>
</div>
