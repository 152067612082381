import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DetectBrowserServiceService {

  constructor() { }

  getInternetExplorerVersion() {
  var rV = -1; // Return value assumes failure.

  if (navigator.appName == 'Microsoft Internet Explorer' || navigator.appName == 'Netscape') {

    var uA = navigator.userAgent;
    var rE = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
    if (rE.exec(uA) != null) {
      rV = parseFloat(RegExp.$1);
    }
    /*check for IE 11*/
    else if (!!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      rV = 11;
    }
  }
  return rV;
}


  areYouIE() : boolean {
    var iEVersion = this.getInternetExplorerVersion();
  if (iEVersion != -1) {
    let alertText = "Leider kann die BIM-SWARM Website nicht im Internet Explorer angezeigt werden. Bitte benutzen Sie einen anderen Browser!";
    alert(alertText); 
    return true;
  }
  return false
}
}

