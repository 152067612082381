<div>
  <h1>BIMSWARM ist eine Plattform der planen-bauen 4.0 <br/>
    Gesellschaft zur Digitalisierung des Planens, Bauens und Betreibens GmbH</h1>
  <br/>
  <h2>Anschrift</h2>
  <p>Geneststraße 5 / Aufgang A</p>
  <p>10829 Berlin</p>
  <p>+49 30 22 45 20 40</p>
  <p>info@planen-bauen40.de</p>
  <p>Amtsgericht Berlin Charlottenburg HRB 170 590 B</p>
  <p>USt.-Id.-Nummer DE301689217</p>
  <br/>
  <br/>
  <h2>Vertretungsberechtigte / Geschäftsführung</h2>
  <p>Dr. Jan Tulke und Inga Stein-Barthelmes</p>
  <br/>
  <br/>
  <h2>Entwicklung/Design</h2>
  <p>adesso SE</p>
  <p>Adessoplatz 1</p>
  <p>44562 Dortmund</p>
</div>
