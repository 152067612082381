import {
  Component,
  OnInit
} from "@angular/core";
import {
  FormBuilder,

  FormGroup,
  Validators
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { DocumentType } from "src/app/model/compatiblity/document-type-interface";
import { DocumentTypeDTO } from "src/app/model/compatiblity/dto/document-type-dto";
import { Schema } from "src/app/model/compatiblity/schema-interface";
import { DocumentTypeService } from "src/app/services/compatiblity-service/document-type-service.service";
import { SchemaService } from "src/app/services/compatiblity-service/schema-service.service";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";

@Component({
  selector: "app-edit-document-type-screen",
  templateUrl: "./edit-document-type-screen.component.html",
  styleUrls: ["./edit-document-type-screen.component.css"],
})
export class EditDocumentTypeScreenComponent implements OnInit {
  /**
   * Holds the id of the currently editted DocumentType or
   * NAN if none (e.g. the form is used to create a new document type)
   */
  documentTypeId = +this.route.snapshot.params["documentTypeId"];
  /**
   *
   */
  isEdit: boolean = this.route.snapshot.paramMap.has("documentTypeId");

  public form: FormGroup;

  public showAddSchemaForm: boolean = false;
  public selectedSchemas = new BehaviorSubject<Schema[]>([]);
  public allSchemas: Schema[];

  constructor(
    fb: FormBuilder,
    private route: ActivatedRoute,
    private documentTypeService: DocumentTypeService,
    private router: Router,
    private dialogService: DialogService,
    private schemaService: SchemaService,
  ) {
    this.form = fb.group({
      name: ["", Validators.required],
      description: [""],
      active: [false],
    });
  }

  ngOnInit(): void {
    if (this.isEdit) {
      // initialize the form with the values of the document type, if the
      // document type id was supplied
      this.documentTypeService
        .getDocumentType(this.documentTypeId)
        .subscribe((documentType) => {
          this.form.setValue({
            name: documentType.name,
            description: documentType.description,
            active: documentType.active,
          });
          this.selectedSchemas.next(
            documentType.schemas
          );
        });
    }
    // initialize the schema arrays
    this.schemaService.getAllSchemas().subscribe((values) => {
      this.allSchemas = values;
    });
  }

  /**
   * Callback for the description text change
   */
  public onDescriptionTextChange(text: string): void {
    this.form.controls.description.setValue(text);
  }

  /**
   * Transfers the selected schemas and form values as a @type {DocumentTypeDTO}
   * to the server
   */
  public save() {
    const schemaIds = this.selectedSchemaIds;
    const dto: DocumentTypeDTO = {
      name: this.form.controls.name.value,
      description: this.form.controls.description.value,
      active: this.form.controls.active.value,
      schemaIds,
    };

    let updateObservable: Observable<DocumentType>;
    if (this.isEdit) {
      dto.id = this.documentTypeId;
      updateObservable = this.documentTypeService.updateDocumentType(
        this.documentTypeId,
        dto
      );
    } else {
      updateObservable = this.documentTypeService.createDocumentType(dto);
    }
    updateObservable.subscribe(
      (value) => {
        this.router.navigate(["profil", "documenttypes"]);
      },
      (e) => {
        // TODO: Might want to add a more specific error message
        this.dialogService.openDialog(
          "Fehler beim Speichern",
          "Es gab einen Fehler beim Speichern des Dokumenttyps. Bitte versuchen Sie es später nochmal."
        );
      }
    );
  }

  /**
   * Cancels the form submission by asking the user whether they really want
   * to cancel, and does so if they confirm
   */
  public handleCancelClick() {
    this.dialogService
      .openConfirmDialog(
        "Abbrechen?",
        "Nein",
        "Ja, abbrechen",
        "Dadurch gehen alle ungespeicherten Änderungen verloren"
      )
      .subscribe((result) => {
        if (result) {
          this.router.navigate(["profil", "documenttypes"]);
        }
      });
  }

  /**
   * Toggles the "add schema" from and initializes it with emtpy values
   */
  public toggleAddSchema() {
    this.showAddSchemaForm = !this.showAddSchemaForm;
  }

  /**
   * Add the selected schemas to the selected schemas
   */
  public onSchemaSave(schemaIds: number[]) {
    const currentSchemas = this.selectedSchemas.getValue();
    const addedSchemas = schemaIds.map((schemaId) => {
      return this.allSchemas.find(schema => schema.id === schemaId);
    })

    this.selectedSchemas.next([...currentSchemas, ...addedSchemas]);
    this.toggleAddSchema();
  }

  /**
   * Callback for the removing of a schema
   * @param removedSchemaId the id of the removed schema
   */
  public handleRemoveSchemaClick(removedSchemaId: number) {
    const currentSchemas = this.selectedSchemas.getValue();
    const newSchemas = currentSchemas.filter(
      (schema) => schema.id !== removedSchemaId
    );
    this.selectedSchemas.next(newSchemas);
  }

  public get selectedSchemaIds() {
    return this.selectedSchemas.getValue().map(schema => schema.id);
  }
}
