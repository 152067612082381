import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { ProductService } from "../services/dataServices/product-service/productService";
import { OrganisationService } from "../services/dataServices/organisation-service/organisationservice";
import { OrganisationType } from '../model/enums/organisationType';
import { CertificationService } from '../services/dataServices/certification-service/certificationService';

@Injectable()
export class OrganisationResolver implements Resolve<Promise<any>> {
  constructor(
    private productService: ProductService,
    private certificationService: CertificationService,
    private organisationService: OrganisationService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    let organisationDomain = route.paramMap.get("organisationDomain");
    let organisationId = parseInt(route.paramMap.get("organisationId"));

    //---------------------------------
    if (organisationDomain) {
      this.organisationService
        .getOrganisationByDomainOrName(organisationDomain)
        .then((organisation) => {
          this.organisationService.setActiveItem(organisation);
        });
    } //-------------------------------
    else if (organisationId) {
      await this.organisationService.setActiveOrganisation(organisationId);

      if(this.organisationService.activeOrganisation.organisationType == OrganisationType.CERTIFICATION_OFFICE ||
         this.organisationService.activeOrganisation.organisationType == OrganisationType.CERTIFICATION_PROVIDER){
        await this.certificationService.loadCertificationsForOrganisation(
          this.organisationService.activeItem
        );
      } else {
      await this.productService.loadProductsForOrganisation(
        this.organisationService.activeItem
      );
      }
    }

    //---------------------------------
  }
}
