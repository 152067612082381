import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CommonFeatureEntryInterface } from "src/app/model/commonFeature/common-feature-entry-interface";
import { CommonFeatureInterface } from "src/app/model/commonFeature/common-feature-interface";
import { ProductCommonFeatureInterface } from "src/app/model/commonFeature/product-common-feature-interface";
import { LinkingMethod } from 'src/app/model/enums/linkingMethod';
import { API_MAP } from "src/environments/api";

const SWARM_API = "/proxy/api/v0/productservice";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class CommonFeatureService {
  constructor(private http: HttpClient) {}

  /* REST Call to add a new Feature */
  public addNewFeature(
    name: string,
    filterOption: boolean,
    entries: string[],
    showDetailPage: boolean,
    linkingMethod: LinkingMethod,
  ): Observable<CommonFeatureInterface> {
    let featureEntries = [];

    for (let entry in entries) {
      featureEntries.push({ name: entries[entry] });
    }
    return this.http.put<CommonFeatureInterface>(
      SWARM_API + API_MAP["commonFeatures"]["ADD"],
      {
        name: name,
        filterOption: filterOption,
        featureEntries: featureEntries,
        showDetailPage: showDetailPage,
        linkingMethod
      }
    );
  }

  /* REST Call to get all created Features */
  public getAllFeatures(): Observable<CommonFeatureInterface[]> {
    return this.http.get<CommonFeatureInterface[]>(
      SWARM_API + API_MAP["commonFeatures"]["GET"]
    );
  }

  /* REST Call to update a Feature */
  public updateFeature(
    id: number,
    name: string,
    filterOption: boolean,
    entries: string[],
    showDetailPage: boolean,
    linkingMethod: LinkingMethod,
    newToOldEntryMap: any,
    position?: number
  ): Observable<CommonFeatureInterface> {
    let featureEntries = [];

    for (let entry in entries) {
      featureEntries.push({ name: entries[entry] });
    }
    const url =
      SWARM_API + API_MAP["commonFeatures"]["UPDATE"].replace(":id", id + "");

    return this.http.put<CommonFeatureInterface>(url, {
      name: name,
      filterOption: filterOption,
      featureEntries: featureEntries,
      showDetailPage: showDetailPage,
      linkingMethod,
      newToOldEntryMap: newToOldEntryMap,
      position: position,
    });
  }

  public updateFeatures(commonFeatureList: CommonFeatureInterface[]) {
    const url = SWARM_API + API_MAP["commonFeatures"]["UPDATE_MULT"];

    return this.http.put<CommonFeatureInterface[]>(url, commonFeatureList);
  }

  /* REST Call to delete a Feature */
  public deleteFeature(id: number): Observable<any> {
    const url =
      SWARM_API + API_MAP["commonFeatures"]["DELETE"].replace(":id", id + "");

    return this.http.delete(url);
  }

  /* REST Call to add Feature Entries to a Product */
  public addFeaturesForProduct(
    productId: number,
    entries: number[]
  ): Observable<any> {
    const url =
      SWARM_API +
      API_MAP["commonFeatures"]["PRODUCTADD"].replace(":id", productId + "");

    return this.http.put(url, entries);
  }

  /* REST Call to get all Feature Entries for a Product */
  public getFeaturesForProduct(
    productId: number
  ): Observable<ProductCommonFeatureInterface[]> {
    const url =
      SWARM_API +
      API_MAP["commonFeatures"]["PRODUCTGET"].replace(":id", productId + "");

    return this.http.get<ProductCommonFeatureInterface[]>(url);
  }

  /* REST Call to update the Feature Entries for a Product */
  public updateFeaturesForProduct(
    productId: number,
    entries: number[]
  ): Observable<any> {
    const url =
      SWARM_API +
      API_MAP["commonFeatures"]["PRODUCTUPDATE"].replace(":id", productId + "");

    return this.http.put(url, entries);
  }

  /* REST Call to delete all Feature Entries for a Product */
  public deleteFeaturesForProduct(productId: number): Observable<any> {
    const url =
      SWARM_API +
      API_MAP["commonFeatures"]["PRODUCTDELETE"].replace(":id", productId + "");

    return this.http.delete(url);
  }
}
