import { PtgInterface } from 'src/app/model/ptgs/ptg-interface';

/* Custom Modul for the Properties Panel */
export function ComposerSelectionAdding(options: {
  id: string;
  label: string;
  options: PtgInterface[];
}) {
  let optionsString = "";
  options.options.forEach(
    (element) => (optionsString += createSelectOption(element))
  );

  /* Custom Event that gets triggered when an Element is added - Handled by ComposerChangeHandler */
  const customEvent =
    "{const customEvent = document.createEvent('HTMLEvents');" +
    "customEvent.initEvent('ptgAdded', true, true);" +
    "customEvent.eventName = 'ptgAdded';" +
    "event.target.dispatchEvent(customEvent);}";

  return {
    id: options.id,
    html:
      '<div><label data-value="label" ' +
      '></label><div style="display: flex; justify-content: space-between;"><select style="width: 83%" id="' +
      options.id +
      '">' +
      optionsString +
      '</select><button style="width: 15%" onclick="' +
      customEvent +
      '"><b>+</b></button></div>',
    get: function (element, node) {
      return {
        label: options.label,
      };
    },
  };
}

/* Creates the HTML Code for a Select Option */
function createSelectOption(option: PtgInterface) {
  return "<option value='" + option.id + "'>" + option.name + "</option>";
}
