import { BehaviorSubject, of, Observable, Subscription } from "rxjs";

import { SortMethod } from "../model/enums/SortMethod";
import { catchError } from "rxjs/operators";
import { PtgService } from "../services/dataServices/ptg-service/ptgService";
import { PtgAttributesOverviewInterface } from "../model/pagination/ptg-attributes-overview-interface";

export class PtgAttributeDataSource {
  private ptgAttributeSubject = new BehaviorSubject<
    PtgAttributesOverviewInterface[]
  >([]);
  public totalNumberOfElements: number;
  private currentRequest: Subscription;

  constructor(private ptgService: PtgService) {}

  loadPtgAttributes(
    pageIndex: number = 0,
    pageSize: number = 10,
    searchTerm: string = "",
    sortMethod: SortMethod = SortMethod.ID
  ) {
    this.ptgService
      .getPtgSlice(pageIndex, pageSize, searchTerm, sortMethod)
      .pipe(catchError(() => of([])))
      .subscribe(
        (ptgData: {
          totalNumberOfElements: number;
          ptgAttributeDtos: PtgAttributesOverviewInterface[];
        }) => {
          this.totalNumberOfElements = ptgData.totalNumberOfElements;
          this.ptgAttributeSubject.next(ptgData.ptgAttributeDtos);
        }
      );
  }

  getObservable(): Observable<PtgAttributesOverviewInterface[]> {
    return this.ptgAttributeSubject.asObservable();
  }

  getTotalNumberOfElements(): number {
    return this.totalNumberOfElements;
  }
}
