<button
  class="btn_green"
  mat-raised-button
  (click)="toggleNewFeatureForm()"
  id="newFeatureButton"
>
  Neues Filtergruppe hinzufügen
</button>
<p *ngIf="featureAdded">Das Feature wurde erfolgreich angelegt.</p>
<div *ngIf="showNewFeature" (keyup.enter)="$event.preventDefault()">
  <app-common-feature-item-edit-form
    index="0"
    name=""
    [filterOption]="false"
    [tempFeatureEntries]="tempFeatureEntries"
    [showFeatureEntry]="true"
  ></app-common-feature-item-edit-form>
  <button style="left: 60%;" class="btn_green" mat-raised-button (click)="addNewFeature()">
    Filter hinzufügen
  </button>
</div>
