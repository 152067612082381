import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { ReportInterface } from './../../../model/reviews/report-interface';
import { ReviewInterface } from 'src/app/model/reviews/review-interface';
import { ReviewService } from 'src/app/services/review-service/review.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-service/userservice';
import { ReviewReportStatus } from 'src/app/model/enums/reviewReportStatus';

@Component({
  selector: 'app-review-administration',
  templateUrl: './review-administration.component.html',
  styleUrls: ['./review-administration.component.css'],
})
export class ReviewAdministrationComponent implements OnInit, OnDestroy {
  public reportedReviews: ReviewInterface[] = [];

  constructor(
    private reviewService: ReviewService,
    private userService: UserService,
    private dialogService: DialogService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) { 
    // Reroutes to Marketplace if User is not Platform Admin
    if (!this.userService.isCurrentUserPlatformAdmin()) {
      this.router.navigateByUrl("/marktplatz");
    }
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
  }

  ngOnInit() {
    this.reviewService
      .getReviewsWithReports()
      .subscribe((reviewsWithReports) => {
        this.reportedReviews = reviewsWithReports;
        this.changeDetector.detectChanges();
      });
  }

  ngOnDestroy() 
  {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }

  public hasUncheckedReports(review: ReviewInterface) {
    let hasUnchecked = false;
    var reports = review.reports;
    reports.forEach(report => {
      if(report.reportStatus == ReviewReportStatus.UNCHECKED) {
        hasUnchecked = true;
      }
    })
    return hasUnchecked;
  }

  deleteReview(review: ReviewInterface) {
    this.reviewService.deleteReview(review)
      .subscribe(deleted => {
        //Remove now deleted review from current list of reviews
        this.reportedReviews.splice(this.reportedReviews.indexOf(review));
        this.dialogService.openDialog("Bewertung erfolgreich gelöscht", "");
        this.changeDetector.detectChanges();
      });
  }

  deleteReport(report: ReportInterface) {
    this.reviewService.deleteReviewReport(report.reportId, "REJECTED")
      .subscribe(deleted => {
        this.updateReportStatus(report);
        this.dialogService.openDialog("Meldung erfolgreich gelöscht", "");
        this.changeDetector.detectChanges();
      })
  }

  private updateReportStatus(report: ReportInterface) {
    this.reportedReviews.forEach(review => {
      let reportIndex = review.reports.indexOf(report);
      if(reportIndex != -1) {
        review.reports[reportIndex].reportStatus = ReviewReportStatus.REJECTED;
      }
      if(!this.hasUncheckedReports(review)) {
        this.reportedReviews.splice(this.reportedReviews.indexOf(review));
      }
    })
  }

}
