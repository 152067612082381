import { Component, Input, OnInit } from "@angular/core";
import { UserService } from "../../../services/user-service/userservice";
import { environment } from "src/environments/environment";

@Component({
  selector: "nav-icon",
  templateUrl: "./nav-icon.component.html",
  styleUrls: ["./nav-icon.component.css"],
})
export class NavIconComponent {
  @Input() label: string;
  @Input() labelBelow?: boolean;
  @Input() icon: string;
  @Input() img?: string;
  @Input() noHover?: boolean;
  @Input() imageId: string;

  constructor(private userService: UserService) {}

  logout() {
    if (this.label === "Abmelden") {
      this.userService.logout();
    }
  }
}
