import { ContactInterface } from "./../../../model/organisations/contact-interface";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpEvent, HttpParams } from "@angular/common/http";
import { OrganisationMemberInterface } from "../../../model/organisations/organisationMember-interface";
import { OrganisationInterface } from "../../../model/organisations/organisation-interface";
import { Subscribable, Observable } from "rxjs";
import { FileUploadService } from "../file-upload-service/file-upload.service";
import { UserService } from "src/app/services/user-service/userservice";
import { BaseService } from "../../base.service";
import { ProductService } from "../product-service/productService";
import { ProductInterface } from "src/app/model/products/product-interface";
import { ProductDtoConverterServiceService } from "../../product-dto-converter-service/product-dto-converter-service.service";
import { API_MAP, API_BASE_USERSERVICE } from "src/environments/api";
import { ContactMailInterface } from "src/app/model/user/contact-mail-interface";
import { OrganisationMinimalInterface } from 'src/app/model/organisations/organisation-minimal-interface';
import { OrganisationDeleteInterface } from 'src/app/model/organisations/organisation-delete-interface';
import {OrganisationType} from "../../../model/enums/organisationType";

const SWARM_API = API_BASE_USERSERVICE;
const ORGA_PREFIX = "/organisations";
const ORGA_PICTURE_PREFIX = ORGA_PREFIX + "/pictures";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable()
export class OrganisationService extends BaseService<OrganisationInterface> {
  filterProductsInitialFromOrganisation;
  public activeItemMembership: OrganisationMemberInterface;

  get activeOrganisationMembership() {
    return this.activeItemMembership;
  }

  protected equal(
    item1: OrganisationInterface,
    item2: OrganisationInterface
  ): boolean {
    return true;
  }

  protected toID(item: OrganisationInterface | number): number {
    if (typeof item === "number") return item;
    if (item.organisationId) {
      return item.organisationId;
    }
    return -1;
  }

  get activeOrganisation() {
    return this.activeItem;
  }

  constructor(
    protected http: HttpClient,
    protected dtoConverter: ProductDtoConverterServiceService,
    private userService: UserService,
    private productService: ProductService,
    private fileUploadService: FileUploadService
  ) {
    super(http, dtoConverter);
    // set the API for the org service
    this.SWARM_API_BASE = SWARM_API; //+ userService.ACTIVE_MAP[":userId"];
    this.ITEM_MAP = userService.ACTIVE_MAP;
    this.SERVICE_FLAG = ":organisationId";

    this.SWARM_API_MAP = API_MAP["organisations"];

    this.productService.activeItemObserver.subscribe(
      (item: ProductInterface) => {
        //   HANDLE WHEN A NEW ITEM OR VARIANT WAS CREATE //
        //
      }
    );
  }

  setActiveOrganisation(
    orgOrID: OrganisationInterface | number
  ): Promise<void> {
    let id = orgOrID;
    // Check if Object | is not a number
    if (!(typeof orgOrID === "number")) {
      // use abstract toID method to get ID
      id = this.toID(orgOrID);
    }
    this.userService.ACTIVE_MAP[":organisationId"] = String(id);

    return this.setActiveItem(id).then(() => {
      this.setMembershipForUser(this.userService.currentUser.userId);
    });
  }

  setMembershipForUser(userId: number) {
    this.activeItemMembership = this.activeItem.organisationMembers.find(
      (x) => x.userId === userId
    );
  }

  /**
   * Creates a new Organisation
   * @param organisation
   */
  createOrganisation(
    organisation: OrganisationInterface
  ): Promise<OrganisationInterface> {
    return this.postItem(organisation);
  }

  /**
   * Returns organsiation in collection by name or domain.
   * @param NameOrDomain
   */
  async getOrganisationByDomainOrName(
    NameOrDomain: string
  ): Promise<OrganisationInterface> {
    let orgName = NameOrDomain;
    let orgDomain = NameOrDomain;

    let index = this.collection.findIndex((org: OrganisationInterface) => {
      if (org.domain === orgDomain || org.organisationName == orgName) {
        return true;
      }
      return false;
    });
    if (index >= 0) {
      return Promise.resolve(this.collection[index]);
    }

    var url =
      API_BASE_USERSERVICE +
      API_MAP["organisations"]["GET_BY_DOMAIN"].replace(
        ":organisationDomain",
        NameOrDomain
      );

    return new Promise((resolve, reject) => {
      this.http.get<OrganisationInterface>(url).subscribe(
        //success
        (organisation: OrganisationInterface) => {
          this.updateItemInCollection(organisation);
          resolve(organisation);
        },
        //failure
        () => {
          reject(false);
        }
      );
    });
  }

  // BACKEND NEEDS API
  loadOrganisationsForUser(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      var url = API_BASE_USERSERVICE + API_MAP["users"]["MEMBERSHIPS"];
      this.http.get<OrganisationMemberInterface[]>(url).subscribe(
        //success
        (memberships: OrganisationMemberInterface[]) => {
          this.userService.saveMemberships(memberships);
          resolve(true);
        },
        //failure
        () => {
          reject(false);
        }
      );
    });
  }

  // Sollte nach Änderungen der DTOs sich nur noch die Namen der Organisationen holen
  getAllOrgs(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .get<OrganisationInterface[]>(
          API_BASE_USERSERVICE + API_MAP["organisations"]["ALL"]
        )
        .subscribe(
          // success
          (orgs: OrganisationInterface[]) => {
            this.collection = orgs;
            resolve(true);
          },
          // failure
          () => {
            reject(false);
          }
        );
    });
  }


  // Sollte nach Änderungen der DTOs sich nur noch die Namen der Organisationen holen
  getAllOrgsIfAdminAndMembershipOrgsIfUser(): Promise<boolean> {
    if (this.userService.isCurrentUserPlatformAdmin()) {
      return this.getAllOrgs();
    } else {
      return new Promise((resolve, reject) => {
        const availableProductOrganisations: OrganisationInterface[] = [];
        let counter: number = 0;
        this.userService.currentMemberships.forEach((organisation) => {
          counter++;
                if (
                  organisation.organisationType === OrganisationType.PRODUCT_PROVIDER
                ) {
                  availableProductOrganisations.push(organisation);
                }
                if (counter === this.userService.currentMemberships.length) {
                  this.collection = availableProductOrganisations;
                  resolve(true);
                }
            });
        // });
      });
    }
  }

  getCompanyById(id: number) {
    return this.getOrganisation(id);
  }

  getOrganisation(organisation: OrganisationInterface | number) {
    let id = this.toID(organisation);
    return this.getItem(id);
  }

  getAllOrganisationsWithActiveProducts(): Subscribable<
    OrganisationInterface[]
  > {
    return this.http.get<OrganisationInterface[]>(
      this.SWARM_API_BASE + this.SWARM_API_MAP["ACTIVE"]
    );
  }

  getAllOrganisationOverviewsWithActiveProducts(isCertification: boolean): Subscribable<
    any[]
  > {
    return this.http.get<any[]>(
      this.SWARM_API_BASE + this.SWARM_API_MAP["ACTIVE"] + "/overviews", {params: new HttpParams().set("isCertification", String(isCertification))}
    );
  }

  uploadOrganisationPicture(
    orgaPicture: File,
    organisationId,
    isCreate?: boolean
  ): Observable<HttpEvent<string>> {
    if (!isCreate) {
      let rightname = "changeOrganisationPicture";
      if (
        !this.userService.isAuthorized(
          this.activeItem.organisationId,
          rightname
        )
      ) {
        alert(
          "Berechtigung fehlt: " +
            "Bearbeitung der Organisation [" +
            rightname +
            "] nicht erlaubt"
        );
        return;
      }
    }
    var url =
      API_BASE_USERSERVICE +
      API_MAP["organisationPictures"]["POST"].replace(
        ":organisationId",
        String(organisationId)
      );

    return this.fileUploadService.uploadFile(orgaPicture, url);
  }

  uploadOrganisationPreviewPicture(
    orgaPreviewPicture: File,
    organisationId
  ): Observable<HttpEvent<string>> {
    let rightname = "changeOrganisationPicture";
    if (
      !this.userService.isAuthorized(this.activeItem.organisationId, rightname)
    ) {
      alert(
        "Berechtigung fehlt: " +
          "Bearbeitung der Organisation [" +
          rightname +
          "] nicht erlaubt"
      );
      return;
    }
    let url =
      API_BASE_USERSERVICE +
      API_MAP["organisationPictures"]["POST_PREVIEW"].replace(
        ":organisationId",
        String(organisationId)
      );

    return this.fileUploadService.uploadFile(orgaPreviewPicture, url);
  }

  getOrganisationPictureUrl(organisationId): string {
    var url =
      API_BASE_USERSERVICE +
      API_MAP["organisationPictures"]["GET"].replace(
        ":organisationId",
        String(organisationId)
      );

    return url;
  }

  getOrganisationPreviewPictureUrl(organisationId): string {
    return (
      API_BASE_USERSERVICE +
      API_MAP["organisationPictures"]["GET_PREVIEW"].replace(
        ":organisationId",
        String(organisationId)
      )
    );
  }

  acceptMemberQuery(
    userIdToAccept: number
  ): Subscribable<OrganisationInterface> {
    let rightname = "allowUser";
    if (
      !this.userService.isAuthorized(this.activeItem.organisationId, rightname)
    ) {
      alert(
        "Berechtigung fehlt: " +
          "Bearbeitung der Organisation [" +
          rightname +
          "] nicht erlaubt"
      );
      return;
    }
    var url =
      API_BASE_USERSERVICE +
      API_MAP["members"]["ACCEPT"]
        .replace(":organisationId", "" + this.activeItem.organisationId)
        .replace(":userId", "" + userIdToAccept);
    return this.http.put<OrganisationInterface>(url, httpOptions);
  }

  declineMemberQuery(
    userId: number,
    message?: string
  ): Subscribable<OrganisationInterface> {
    let rightname = "removeUser";
    if (
      !this.userService.isAuthorized(this.activeItem.organisationId, rightname)
    ) {
      alert(
        "Berechtigung fehlt: " +
          "Bearbeitung der Organisation [" +
          rightname +
          "] nicht erlaubt"
      );
      return;
    }
    var url =
      API_BASE_USERSERVICE +
      API_MAP["members"]["DELETE"]
        .replace(":organisationId", "" + this.activeItem.organisationId)
        .replace(":userId", "" + userId);
    let options = { ...httpOptions, body: message };
    return this.http.delete<OrganisationInterface>(url, options);
  }

  inviteMembersToOrganisation(
    usersToInvite: OrganisationMemberInterface[]
  ): Subscribable<OrganisationInterface> {
    let rightname = "inviteUser";
    const json = JSON.stringify(usersToInvite);
    if (
      !this.userService.isAuthorized(this.activeItem.organisationId, rightname)
    ) {
      alert(
        "Berechtigung fehlt: " +
          "Bearbeitung der Organisation [" +
          rightname +
          "] nicht erlaubt"
      );
      return;
    }

    var url =
      API_BASE_USERSERVICE +
      API_MAP["members"]["POST"].replace(
        ":organisationId",
        "" + this.activeItem.organisationId
      );
    return this.http.post<OrganisationInterface>(url, json, httpOptions);
  }

  // TODO Parameter typisieren
  updateOrganisationMembers(memberRoleChanges, callback: (error?: Error) => void): void {
    let rightname = "changeUser";
    if (
      !this.userService.isAuthorized(this.activeItem.organisationId, rightname)
    ) {
      alert(
        "Berechtigung fehlt: " +
          "Bearbeitung der Organisation [" +
          rightname +
          "] nicht erlaubt"
      );
      return;
    }
    // Making a copy of the members of the original activeItem, so it can be changed first and only gets made persistent when the backend send back ok
    let organisationMemberDetails = JSON.parse(
      JSON.stringify(this.activeItem.organisationMembers)
    );

    for (let userId in memberRoleChanges) {
      for (let member in this.activeItem.organisationMembers) {
        if (
          this.activeItem.organisationMembers[member].userId === Number(userId)
        ) {
          organisationMemberDetails[member].role = memberRoleChanges[userId];
        }
      }
    }

    let json = JSON.stringify(organisationMemberDetails);
    var url =
      API_BASE_USERSERVICE +
      API_MAP["members"]["PUT"].replace(
      ":organisationId",
      "" + this.activeItem.organisationId
      );
    this.http
      .put<OrganisationInterface>(url, json, httpOptions)
      .subscribe(
        (res) => {
          this.activeItem = res;
          this.nextItem();
          callback();
        },
        (error) => {
          callback(error);
        }
      );
  }
  getFilteredOrganisationSlice(
    pageIndex: number,
    pageSize: number,
    organisationIds: number[],
    searchTerm: string,
    organisationType?: string
  ) {
    let pageRequestDto = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      organisationIds: organisationIds,
      searchTerm: searchTerm,
      organisationType: organisationType,
    };
    const json = JSON.stringify(pageRequestDto);
    if (!this.userService.isAuthorized("system", "nutzerrechte")) {
      return;
    }
    return this.http.post(
      SWARM_API + this.SWARM_API_MAP["FILTER"],
      json,
      httpOptions
    );
  }

  createContactForOrganisation(
    contact: ContactInterface,
    orgaName: string
  ): Subscribable<ContactInterface> {
    let rightname: string = "addContact";
    let organisation = this.collection.find(
      (org) => orgaName === org.organisationName
    );

    if (
      !this.userService.isAuthorized(organisation.organisationId, rightname)
    ) {
      alert(
        "Berechtigung fehlt: " +
          "Bearbeitung der Organisation [" +
          rightname +
          "] nicht erlaubt"
      );
      return;
    }
    let url =
      SWARM_API +
      API_MAP["contacts"]["POST"].replace(
        ":organisationId",
        String(organisation.organisationId)
      );
    const json = JSON.stringify(contact);

    return this.http.post<ContactInterface>(url, json, httpOptions);
  }

  public updateOrganisationContacts(
    organisationId: number,
    contacts: ContactInterface[]
  ): Observable<void> {
    const url =
      SWARM_API +
      API_MAP["contacts"]["PUT"].replace(
        ":organisationId",
        String(organisationId)
      );
    const json = JSON.stringify(contacts);
    return this.http.put<void>(url, json, httpOptions);
  }

  public updateOrganisationContact(organisationId: number, contact: ContactInterface): Observable<void> {
    const url =
      SWARM_API +
      API_MAP["contacts"]["UPDATE"].replace(":organisationId", String(organisationId));
    const json = JSON.stringify(contact);
    return this.http.put<void>(url, json, httpOptions);
  }

  public deleteOrganisationContact(organisationId: number, contactId: number): Observable<void> {
    const url =
      SWARM_API +
      API_MAP["contacts"]["DELETE"]
        .replace(":organisationId", String(organisationId))
        .replace(":contactId", String(contactId));
    return this.http.delete<void>(url, httpOptions);
  }

  sendContactMail(
    contactMail: ContactMailInterface
  ): Observable<ContactMailInterface> {
    return this.http.post<ContactMailInterface>(
      SWARM_API + ORGA_PREFIX + "/contact",
      contactMail
    );
  }

  requestOrganisationDelete(organisation: OrganisationInterface) {
    return this.http.get<OrganisationDeleteInterface>(
      SWARM_API + ORGA_PREFIX + "/delete/request/" + organisation.organisationId
    );
  }

  getAllMembersOfOrganisationById(
    organisationId: number
  ): Observable<OrganisationMemberInterface[]> {
    return this.http.get<OrganisationMemberInterface[]>(
      SWARM_API + ORGA_PREFIX + "/" + organisationId + "/members"
    );
  }

  getMinimalOrganisationList() {
    const url: string =
      API_BASE_USERSERVICE + API_MAP["organisations"]["MINIMAL"];
    return this.http.get<OrganisationMinimalInterface[]>(url);
  }
}
