import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { ValidationInterface } from "src/app/model/attributes/validation-interface";
import { AttributeInterface } from "src/app/model/attributes/attribute-interface";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';

const SWARM_API = "/proxy/api/v0/productservice";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};
@Component({
  selector: "app-attribute-table",
  templateUrl: "./attribute-table.component.html",
  styleUrls: ["./attribute-table.component.css"],
})
export class AttributeTableComponent implements OnInit, OnChanges {
  @Input() attributes: {
    id: number;
    name: string;
    datatype: string;
    description: string;
    hidden?: boolean;
    hiddenEdit?: boolean;
    validations?: ValidationInterface[];
    validation?: ValidationInterface; //PTG-Attributes only have one validation
    optional?: boolean;
  }[];

  @Input() showAll: boolean;
  @Input() checkedValidations?: boolean[] = [];
  @Input() selectedAttributes?: AttributeInterface[];
  @Input() showOptional?: boolean = false;
  @Input() showCheckbox?: boolean = true;
  @Input() attributeNameEditable?: boolean = true;
  public editField: boolean = false;
  public newAttributeName: string;
  constructor(private http: HttpClient,
              private dialogService: DialogService,
              private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    for (const attribute of this.attributes) {
      if (attribute.validation != null && attribute.validations == null) {
        attribute.validations = [attribute.validation];
      }
      attribute.hiddenEdit = !this.showAll;
      attribute.hidden = !this.showAll;
    }
  }

  public showAttributeValidation(attributeId: number): void {
    for (const attribute of this.attributes) {
      if (attribute.id == attributeId) {
        attribute.hidden = !this.showAll && !attribute.hidden;
      } else {
        attribute.hidden = !this.showAll;
      }
    }
  }

  public showEditField(attributeId: number): void {
    for (const attribute of this.attributes) {
      if (attribute.id == attributeId) {
        attribute.hiddenEdit = !this.showAll && !attribute.hiddenEdit;
        this.newAttributeName = "";
      } else {
        this.newAttributeName = "";
        attribute.hiddenEdit = !this.showAll;
      }
    }
  }

  public hideAttributes(): void {
    for (const attribute of this.attributes) {
      attribute.hidden = !this.showAll;
      attribute.hiddenEdit = !this.showAll;
    }
  }

  public toggleValidation(
    attribute: AttributeInterface,
    validation: ValidationInterface
  ): void {
    const attrExists: AttributeInterface = this.selectedAttributes.find(
      (attr) => attr.id === attribute.id
    );
    if (attrExists != null) {
      attribute = attrExists;
      const valExists = attribute.validations.find(
        (val) => val.id === validation.id
      );
      if (valExists != null) {
        this.removeSelectedAttribute(attribute.id);
      } else {
        const previousValidation = attribute.validations[0];
        this.checkedValidations[previousValidation.id] = false;
        attribute.validations = [validation];
      }
    } else {
      let newAttribute: AttributeInterface = {
        id: attribute.id,
        name: attribute.name,
        datatype: attribute.datatype,
        description: attribute.description,
        validations: [validation],
      };
      this.selectedAttributes.push(newAttribute);
    }
  }

  saveChanges(attributeId: number) : void
  {
    this.http.post(SWARM_API + "/attributes/name/" + attributeId + "/" + this.newAttributeName, httpOptions).subscribe((x) => {
      if(x)
      {
      this.dialogService.openDialog(
        "Merkmalsname wurde geändert",
        "Bitte wechseln Sie einmal den Tab"
      );
      }
    })
    this.cd.detectChanges()
  }

  abortEdit(attributeId: number): void {
    for (const attribute of this.attributes) {
      if (attribute.id == attributeId) {
        attribute.hiddenEdit = true;
      }
    }
    this.newAttributeName = "";
  }

  /**
   * Method that removes an attribute from the list of selected attributes.
   * This has to be done since reassigning the selectedAttributes array (e.g. by using the filter method) leads to inconsistent arrays references
   * between the "super"-component AttributeCatalogueComponent and this.
   * @param attributeId The Id of the attribute to be removed from the selected attributes
   */
  public removeSelectedAttribute(attributeId: number): void {
    const indexOfAttribute = this.selectedAttributes.findIndex(
      (attribute) => attribute.id === attributeId
    );
    this.selectedAttributes.splice(indexOfAttribute, 1);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["attributes"] != null) {
      this.hideAttributes();
    }
  }
}