import { OrganisationOverview } from './../../dataSource/organisationDataSource';
import { OrganisationService } from './../../services/dataServices/organisation-service/organisationservice';
import { Component, Inject, Input , OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delegate-cert',
  template: `
  <h1 mat-dialog-title>{{data.title}}</h1>
  <form #form="ngForm">
    <mat-form-field style="display: flex; justify-content: center;">
      <mat-select
        placeholder="Zertifizierungsdienstleister"
        [(ngModel)]="delegatedTo"
        name="certifier"
        required
      >
        <mat-option
          *ngFor="let organisation of certificationOffices"
          [value]="organisation"
        >{{ organisation.organisationName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div mat-dialog-actions>
      <button mat-button (click)="onClick(false)" class="btn_red" style="float: left" cdkFocusInitial>Abbrechen</button>
      <button mat-button (click)="onClick(true)" class="btn_green" style="float: right" cdkFocusInitial [disabled] = form.invalid >Übernehmen</button>
    </div>
  </form>
`,
  styleUrls: ['./dialog.css'],
  styles: [':host ::ng-deep .mat-form-field-infix { display: block; position: relative; flex: auto; min-width: 0; width: 400px;} .button:disabled, button[disabled]{background-color: #e8e4e4 !important;}']
})
export class DialogDelegateCertificationComponent implements OnInit {
  certificationOffices: OrganisationOverview[] = [];
  delegatedTo: OrganisationOverview;

  constructor(
    private organisationService: OrganisationService,
    public dialogRef: MatDialogRef<DialogDelegateCertificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{
      title: string;
      delegatedToOrganisations: OrganisationOverview[];
    }) {}

  ngOnInit() {
    //Choosing -1 for pageSize: Returns all found, filtered, alphabetically sorted organisations that match criteria but without pagination
    this.organisationService.getFilteredOrganisationSlice(0, -1, [], "", "CERTIFICATION_OFFICE")
      .subscribe((organisationData) => {
        this.certificationOffices = organisationData["organisations"];
        //Dont show already chosen ones in dropdown
        if(this.data.delegatedToOrganisations.length > 0) {
          for(let delegatedOrganisation of this.data.delegatedToOrganisations) {
            let index = this.getIndexInCertificationOffices(delegatedOrganisation);
            if(index != -1) {
              this.certificationOffices.splice(index, 1);
            } 
          }
        }
      })
  }

  private getIndexInCertificationOffices(delegatedOrganisation: OrganisationOverview) : number {
    for(let i = 0; i < this.certificationOffices.length; i++) {
      if(delegatedOrganisation.organisationId == this.certificationOffices[i].organisationId) {
        return i;
      }
    }
    return -1;
  }

  onClick(confirm: boolean): void{
    if(confirm) {
      this.dialogRef.close(this.delegatedTo);
    } else {
      this.dialogRef.close();
    }
  }
}
