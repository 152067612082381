<form #form="ngForm">
    <div class="border">
    <cdk-virtual-scroll-viewport  style="height: 250px" itemSize="90" *ngIf="log">
      <ng-container *ngFor="let logEntry of log">
        <app-certification-request-log-entry
          [logEntry]="logEntry"
          style="margin: 0.5vw"
        >
        </app-certification-request-log-entry>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
  <app-text-editor
    [(editorText)]="comment"
    (textValidityChange)="onCommentValidityChange($event)"
    [placeholderText]="'Kommentar schreiben'"
  ></app-text-editor>
  <div class="centered">
    <button
      type="button"
      mat-raised-button
      class="btn_green right"
      [disabled]="form.invalid || !isCommentValid"
      (click)="addComment()"
    >
      Kommentar hinzufügen
    </button>
  </div>
</form>

