<div class="publicProfile" *ngIf="profile">
  <div class="profileHeader">
    <div class="profile-picture">
      <img
        *ngIf="profile.profilePictureId == null; else picture"
        src="{{ getDefaultProfileImageUrl() }}"
        alt="Profilbild"
        width="100%"
        height="100%"
      />
      <ng-template #picture>
        <app-secure-image
          [src]="getDefaultProfileImageUrl()"
          [fileId]="profile.profilePictureId"
          [fill]="true"
          [isScreen]="true"
        ></app-secure-image>
      </ng-template>
    </div>
    <div class="userInformation">
      <h1>{{ profile.name }}</h1>
      <!--h2>{{ profile.username }}</h2-->
      <div class="reviewAndUpvoteCount">
        <div>
          <h4>Bewertungen</h4>
          <p *ngIf="profile.reviews" class="number">{{ profile.reviews.length }}</p>
        </div>
        <div>
          <h4>Upvotes</h4>
          <p  class="number">{{ profile.totalNumberOfUpvotes }}</p>
        </div>
      </div>
    </div>
  </div>
  <app-infinite-scroll (scrolled)="onScroll()">
    <div class="activities" *ngIf=" reviews && reviews.length > 0">
      <h3>Aktivitäten</h3>
      <app-public-activity-item
        *ngFor="let review of reviews"
        [username]="profile.name"
        [review]="review"
      ></app-public-activity-item>
    </div>
  </app-infinite-scroll>
</div>
