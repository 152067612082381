import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {UserRoleInterface} from "../../model/user/user-role-interface";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {OrganisationInterface} from "../../model/organisations/organisation-interface";
import {UserInterface} from "../../model/user/user-interface";
import {UserService} from "../user-service/userservice";
import {UserRoleDatasourceInterface} from "../../model/user/user-role-datasource-interface";

const SWARM_API = "/proxy/api/v0/userservice";
const USER_PREFIX = "/users";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: 'root'
})
export class NutzerverwaltungService {

  public searchTextSubject = new Subject<String>();

  public searchText: string = '';

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) { }

  receiveSearchText(userSearch: string) {
    this.searchText = userSearch;
    this.searchTextSubject.next(userSearch);
  }

  updateUserRole(userId: number, userRole: String, success: Function, error: Function): void {
    this.http.put<UserRoleInterface>(SWARM_API + USER_PREFIX + "/" + userId + "/role", userRole).subscribe(
      (user) => {
        success();
      },
      () => {
        error();
      }
    );
  }

  changeComposerAccessOfUser(userId: number, composerAccess: boolean, success: Function, error: Function): void {
    this.http.put<UserRoleInterface>(SWARM_API + USER_PREFIX + "/" + userId + "/composerAccess", composerAccess).subscribe(
      (user) => {
        success();
      },
      () => {
        error();
      }
    );
  }

  addUserToOrganisation(organisation: OrganisationInterface, userId: number, success: Function, error: Function): void {
    if (!this.userService.isCurrentUserPlatformAdmin()) {
      return;
    } else {
      const json = JSON.stringify(organisation);

      this.http.post<UserInterface>(
        SWARM_API + USER_PREFIX + "/memberships/" + userId,
        json,
        httpOptions
      )
        .subscribe(
          (data) => {
            success();
          },
          () => {
            error();
          }
        );
    }
  }

  getUsersWithRolesSlice(pageIndex: number, pageSize: number, searchString: string): Observable<UserRoleDatasourceInterface> {
    const dto =  {
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchString: searchString
    };
    const json = JSON.stringify(dto);
    return this.http.post<UserRoleDatasourceInterface>(SWARM_API + USER_PREFIX + "/roles", json, httpOptions);
  }
}
