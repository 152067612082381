export class FileDescriptor {
  id: string;
  filename: string;
  contentType:string;
  description:any;
  size:number;
  url:string;
}

export class ListOfFiles{
  string:FileDescriptor
}

export const FILETYPES = {
  "application/pdf" : {icon:"file-pdf.svg",text:"PDF"},
  "image/png" : {text:"png"},
  "image/jpg" : {text:"jpg"},
  "image/jpeg" : {text:"jpg"},
  "image/bmp" : {text:"bmp"},
  "image/gif" : {text:"gif"},
  "image/svg+xml" : {text:"svg"},
  "application/x-zip-compressed" : {text:"zip",icon:"file-zip.svg"},
}
