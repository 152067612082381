import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportReviewDialogData } from 'src/app/services/dialog-service/dialog-service.service';
import { DialogEditMetaDataComponent } from '../../composer/model-editor/dialog-edit-meta-data/dialog-edit-meta-data.component';

@Component({
  selector: 'report-review',
  templateUrl: './report-review.component.html',
  styleUrls: ['./report-review.component.css']
})
export class DialogReportReviewComponent implements OnInit {
  reason = "";
  form = new FormGroup({
    reason: new FormControl("", [
      Validators.required,
    ]),
  });
  
  constructor(
    public dialogRef: MatDialogRef<DialogEditMetaDataComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ReportReviewDialogData
  ) { }

  ngOnInit() {
  }

  onSubmit(confirm: boolean) {
    if(confirm) {
      this.reason = this.form.get("reason").value;
      this.dialogRef.close(this.reason);
    } else {
      this.dialogRef.close();
    }
  }
}
