import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { SidemenuButtonInterface } from "../../../model/sidemenuButtonInterface";
import { OrganisationService } from "../../dataServices/organisation-service/organisationservice";
import { PtgInterface } from "../../../model/ptgs/ptg-interface";
import { ProductInterface } from "src/app/model/products/product-interface";
import { UtilService } from "../../util-service/utilService";
import { BehaviorSubject, Observable } from "rxjs";
import { ComposerUsecaseInterface } from "../../../model/composer/composer-usecase-interface";
import { environment } from "src/environments/environment";
import { Status } from "../../../model/enums/status";
import { ProductVariantInterface } from "src/app/model/products/product-variant-interface";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { SidemenuButttonFactory } from "./sidemenu-buttton-factory";
import { ProductService } from "../../dataServices/product-service/productService";
import { PtgService } from "../../dataServices/ptg-service/ptgService";
import { ProductVariantService } from "../../dataServices/product-variant-service/product-variant.service";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { API_MAP } from "src/environments/api";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";
import { CertificationService } from "../../dataServices/certification-service/certificationService";
import { UserService } from "../../user-service/userservice";
import { OrganisationType } from 'src/app/model/enums/organisationType';

enum SIDEMENU_STATE {
  PRODUCT,
  ORGANISATION,
  ATTRIBUTE_CATALOGUE,
  PTG_CATALAGUE,
  USER,
  COMPOSER,
  CERTIFICATION,
  NONE,
}

@Injectable({
  providedIn: "root",
})
export class SidemenuServiceService {
  private routeState: SIDEMENU_STATE = SIDEMENU_STATE.NONE;

  subscribeRouteChange() {
    let url = "";
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        url = this.router.routerState.snapshot.url.toString();

        var map = 0;
        map += new RegExp(".*organisations/.*/products.*").test(url) ? 1 : 0;
        map += new RegExp(".*organisations/.*/productTypeGroups.*").test(url)
          ? 2
          : 0;
        map += new RegExp(".*organisations/.*/features$").test(url) ? 3 : 0;
        map += new RegExp(".*organisations/.*/features/ptgs.*").test(url)
          ? 4
          : 0;
        map += new RegExp(".*marktplatz*").test(url) ? 5 : 0;
        map += new RegExp(".*profil*").test(url)
          //Exclude Public Profile Route
          ? new RegExp(".*profil/.*/public$").test(url)
            ? 5
            : 6
          : 0;
        map += new RegExp(".*organisations/.*/[bi].*").test(url) ? 7 : 0;
        map += new RegExp(".*organisations/.*/certifications.*").test(url)
          ? 8
          : 0;
        map += new RegExp(".*organisations/.*/products$").test(url) ? 9 : 0;
        map += new RegExp(".*composer*").test(url) ? 11 : 0;

        map += new RegExp(".*organisations/.*/certifications$").test(url) ? 12 : 0;
        map += new RegExp(".*organisations/.*/productTypeGroups$").test(url) ? 13 : 0;

        switch (map) {
          case 1: {
            this.reloadProducts();
            break;
          }
          case 2: {
            this.loadSidemenuPtgs(this.ptgService.collection);
            break;
          }
          case 3: {
            this.loadSidemenuAttributes();
            break;
          }
          case 4: {
            this.loadSidemenuAttributes();
            break;
          }
          case 5: {
            // NONE ?
            break;
          }
          case 6: {
            this.loadSidemenuUserSettings();
            break;
          }
          case 7: {
            this.loadSidemenuOrganisationSettings();
            break;
          }
          case 8: {
            this.reloadCertifications();
            break;
          }
          // this is 10 because we also trigger 1
          // so 1+9 = 10
          case 10: {
            this.productService.activeItem = null;
            this.reloadProducts();

            break;
          }
          case 11: {
            this.loadComposerSidemenu();
            break;
          }

          case 15: {
            this.ptgService.activeItem = null;
            this.reloadPTGs();
            break;
          }
          // this is 20 because we trigger 8 and 12
          // so 12 + 8 = 20
          case 20:
          {
            this.certificationService.activeItem = null;
            this.reloadCertifications();
            break;
          }
          default: {
          }
        }
      }
    });
  }

  sidemenuData: Array<SidemenuButtonInterface> = [];
  private sidemenuDataSubject: BehaviorSubject<Array<SidemenuButtonInterface>>;
  public sidemenuDataObserver: Observable<Array<SidemenuButtonInterface>>;

  productsVisible: boolean = false;
  certificationsVisible: boolean = false;
  sidemenuDataCopy: Array<SidemenuButtonInterface> = [];

  status: boolean = false;

  constructor(
    private organisationService: OrganisationService,
    private utilService: UtilService,
    private productService: ProductService,
    private variantService: ProductVariantService,
    private ptgService: PtgService,
    private router: Router,
    private certificationService: CertificationService,
    private userService: UserService,
    private titleService: Title,
  ) {
    this.subscribeRouteChange();

    this.sidemenuDataSubject = new BehaviorSubject<
      Array<SidemenuButtonInterface>
    >([]);
    this.sidemenuDataObserver = this.sidemenuDataSubject.asObservable();
  }

  reloadProducts() {
    this.loadSidemenuProducts(this.productService.collection);
  }

  reloadCertifications() {
    this.loadSidemenuCertifications(this.certificationService.collection);
  }

  async reloadPTGs()
  {
    this.loadSidemenuPtgs(await this.getPTGsForOrganisation());
  }

  getPTGsForOrganisation() {
   // let ptgArray : PtgInterface[] = [];
    return this.ptgService?.getAllPTGsForOrganisation(this.organisationService.activeItem.organisationId).then((dataArray : PtgInterface[]) => {
      return dataArray;
    })
  }

  loadSidemenuProducts(sidemenuProducts: ProductInterface[]) {
    // null guard

    if(!this.productService.activeItem)
    {
      this.titleService.setTitle(this.organisationService.activeItem.organisationName + ": Produkte - BIMSWARM");
    }

    if (sidemenuProducts.length >= 0) {
      this.productsVisible = true;
      this.sidemenuData = [];
      //-------------------
      // sort products
      this.utilService.sortByName(sidemenuProducts);
      // filter for the active organsiation
      let sidemenuProductsFiltered = sidemenuProducts.filter((x) => {
        return x.company === this.organisationService.activeItem.organisationId;
      });
      sidemenuProductsFiltered = this.productService.filterProductsByStatus(
        sidemenuProductsFiltered,
        this.status
      );
      // create Button at the top
      this.sidemenuData[0] = this.createProduktAnlegenButton();
      //-------------------

      //iterate the products
      for (let i: number = 0; i != sidemenuProductsFiltered.length; i++) {
        // get work copy
        let product = sidemenuProductsFiltered[i];
        //-------------------
        // sort the variants
        this.utilService.sortByName(product.productVariants);
        //-------------------
        // create the product button
        this.sidemenuData[
          i + 1
        ] = SidemenuButttonFactory.CreateButtonFromProduct(product);
        // imediatly add the create button to the children
        this.sidemenuData[i + 1].children[0] = this.createVarianteAnlegenButton(
          product
        );

        // now create variant buttons
        for (let j: number = 0; j != product.productVariants.length; j++) {
          // get work copy
          let variant = product.productVariants[j];
          //-------------------
          // sort the versions
          this.utilService.sortByName(variant.variantVersions);
          // add the variant Button
          this.sidemenuData[i + 1].children[
            j + 1
          ] = SidemenuButttonFactory.CreateButtonFromVariant(variant, product);
        }
      }
      this.sidemenuDataSubject.next(this.sidemenuData);
    }
  }

  private createProduktAnlegenButton() {
    return this.createSidemenuObject(
      this.isUserOrganisationAdminOrPlattformAdmin() ? "Produkt anlegen" : "",
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/products/create",
      true
    );
  }

  private createProductEntry(product: ProductInterface) {
    return this.createSidemenuObject2(
      product.name,
      false,
      "null",
      true,
      [],
      null,
      product.id,
      null,
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/products/" +
        product.id
    );
  }

  private createVarianteAnlegenButton(product: ProductInterface) {
    return this.createSidemenuObject(
      this.isUserOrganisationAdminOrPlattformAdmin() ? "Variante anlegen" : "",
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/products/" +
        product.id +
        "/variants/create",
      true
    );
  }

  private createVariantEntry(
    variant: ProductVariantInterface,
    product: ProductInterface
  ) {
    return this.createSidemenuObject(
      variant.name,
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/products/" +
        product.id +
        "/variants/" +
        variant.id,
      false,
      variant.id
    );
  }

  createSidemenuObject(
    label: string,
    link: string = null,
    addButton: boolean = false,
    entityId = null,
    ptgId = null,
    image = null,
    isCertification = false
  ): SidemenuButtonInterface {
    return {
      label: label,
      link: link,
      addButton: addButton,
      entityId: entityId,
      ptgId: ptgId,
      image: image,
      isCertification: isCertification,
    };
  }

  createSidemenuObject2(
    label: string,
    composerItem: boolean,
    type: string,
    hasChildren: boolean,
    children: any,
    ptgId: any = null,
    entityId: any = null,
    usecaseId: any = null,
    link: string,
    isCertification?: boolean
  ): SidemenuButtonInterface {
    return {
      label: label,
      composerItem: composerItem,
      type: type,
      hasChildren: hasChildren,
      children: children,
      ptgId: ptgId,
      entityId: entityId,
      usecaseId: usecaseId,
      link: link,
    };
  }

  async loadSidemenuPtgs(newSidemenuData: PtgInterface[]): Promise<void> {

    if(!this.ptgService.activeItem)
    {
      this.titleService.setTitle(this.organisationService.activeItem.organisationName + ": Produkttypen - BIMSWARM");
    }

    this.productsVisible = false;
    if (this.organisationService.activeItem) {
      newSidemenuData = this.sortPtgs(await this.getPTGsForOrganisation()).filter((x) => {
        return (
          x.organisationId ===
          this.organisationService.activeItem.organisationId
        );
      });
      this.sidemenuData = [];
      //-------------------------------
      if (this.isUserOrganisationAdminOrPlattformAdmin()) {
        this.sidemenuData[0] = this.createSidemenuObject(
          "Produkttyp anlegen",
          "/organisations/" +
            this.organisationService.activeItem.organisationId +
            "/productTypeGroups/create",
          true
        );
      } else {
        this.sidemenuData[0] = this.createSidemenuObject(
          "",
          "/organisations/" +
            this.organisationService.activeItem.organisationId +
            "/productTypeGroups/",
          true
        );
      }
      
      //-------------------------------
      for (let i: number = 0; i != newSidemenuData.length; i++) {
        //-------------------------------
        let ptg = newSidemenuData[i];
        this.sidemenuData[i + 1] = SidemenuButttonFactory.CreateButtonFromPtg(
          ptg
        );
        this.sidemenuData[i + 1].image = "/assets/img/defaultPtg.png";
        this.sidemenuData[i + 1].children = [];
        //-------------------------------
        if (this.isUserOrganisationAdminOrPlattformAdmin()) {
          this.sidemenuData[i + 1].children[0] = {
            label: "Version anlegen",
            link:
              "/organisations/" +
              this.organisationService.activeItem.organisationId +
              "/productTypeGroups/" +
              ptg.id +
              "/versions/create",
            addButton: true,
          };
        } else {
          this.sidemenuData[i + 1].children[0] = {
            label: "",
            link:
              "/organisations/" +
              this.organisationService.activeItem.organisationId +
              "/productTypeGroups/" +
              ptg.id +
              "/versions",
            addButton: true,
          };
        }
        //-------------------------------
        for (let j: number = 0; j != newSidemenuData[i].versions.length; j++) {
          let version = newSidemenuData[i].versions[j];
          this.sidemenuData[i + 1].children[
            j + 1
          ] = SidemenuButttonFactory.CreateButtonFromPtgVersion(
            version,
            ptg,
            version.number
          );
        }
      }
    }

    this.sidemenuDataSubject.next(this.sidemenuData);
  }

  public loadComposerSidemenu() {
    this.productsVisible = false;
    this.sidemenuData = [];
    this.sidemenuData[0] = this.createSidemenuObject(
      "Meine Toolchains",
      "/composer/startprocess/list",
      false,
      null,
      null,
      "/assets/svg-icons/composer.svg"
    );
    this.sidemenuData[1] = this.createSidemenuObject(
      "Organisation",
      "/composer/organisations",
      false,
      null,
      null,
      "/assets/svg-icons/team.svg"
    );
    this.sidemenuData[2] = this.createSidemenuObject(
      "Toolchain Suche",
      "/composer/toolchain/search",
      false,
      null,
      null,
      "/assets/svg-icons/team.svg"
    );
    this.sidemenuDataSubject.next(this.sidemenuData);
  }

  isUserOrganisationAdminOrPlattformAdmin(): boolean {
    return this.userService.isUserAdminOfOrganisationOrPlattformAdmin(
      this.organisationService.activeItem.organisationId
    );
  }

  isUserPlatformAdmin(): boolean {
    return this.userService.isCurrentUserPlatformAdmin();
  }

  sortPtgs(ptgs: PtgInterface[]) {
    let belongsToOrganisation: Array<PtgInterface> = [];
    let belongsNotToOrganisation: Array<PtgInterface> = [];
    for (let ptg of ptgs) {
      if (
        ptg.organisationId ===
        this.organisationService.activeItem.organisationId
      ) {
        belongsToOrganisation.push(ptg);
      } else {
        belongsNotToOrganisation.push(ptg);
      }
    }
    belongsToOrganisation.sort((ptg1, ptg2) =>
      ptg1.name > ptg2.name ? 1 : ptg1.name > ptg2.name ? -1 : 0
    );
    belongsNotToOrganisation.sort((ptg1, ptg2) =>
      ptg1.name > ptg2.name ? 1 : ptg1.name > ptg2.name ? -1 : 0
    );
    let result = belongsToOrganisation.concat(belongsNotToOrganisation);
    return result;
  }

  loadSidemenuAttributes(): void {
    this.productsVisible = false;
    this.sidemenuData = [];
    if (this.organisationService.activeItem) {
      this.sidemenuData[0] = this.createSidemenuObject(
        this.isUserOrganisationAdminOrPlattformAdmin() ? "Merkmal anlegen" : "",
        "/organisations/" +
          this.organisationService.activeItem.organisationId +
          "/features/create",
        true
      );
      this.sidemenuData[1] = this.createSidemenuObject(
        "Alle Merkmale",
        "/organisations/" +
          this.organisationService.activeItem.organisationId +
          "/features",
        false,
        null,
        null,
        "/assets/svg-icons/attributes.svg"
      );
      this.sidemenuData[2] = this.createSidemenuObject(
        "Merkmale nach Produkttypen",
        "/organisations/" +
          this.organisationService.activeItem.organisationId +
          "/features/ptgs"
      );
      this.sidemenuDataSubject.next(this.sidemenuData);
    }
  }

  loadSidemenuUserSettings(): void {
    this.productsVisible = false;
    this.sidemenuData = [];
    this.sidemenuData.push(this.createSidemenuObject(
      "Persönliche Daten",
      "/profil/settings",
      false,
      null,
      null,
      "/assets/img/single.png"
      )
    );
    this.sidemenuData.push(this.createSidemenuObject(
      "Konto",
      "/profil/konto",
      false,
      null,
      null,
      "/assets/svg-icons/construction-and-tools.svg"
    ));
    this.sidemenuData.push(this.createSidemenuObject(
      "Mitgliedschaften",
      "/profil/mitgliedschaften",
      false,
      null,
      null,
      "/assets/svg-icons/team.svg"
    ));
    if(this.isUserPlatformAdmin()){
      this.sidemenuData.push(this.createSidemenuObject(
        "Allgemeine Merkmale",
        "/profil/commonfeatures",
        false,
        null,
        null,
        "/assets/svg-icons/team.svg"
      ));
      this.sidemenuData.push(this.createSidemenuObject(
        "Bewertungsmeldungen",
        "/profil/reviewReports",
      ));
      this.sidemenuData.push(this.createSidemenuObject(
        "Nutzerverwaltung",
        "/profil/nutzerverwaltung"
      ));
      this.sidemenuData.push(this.createSidemenuObject(
        "Datenformate",
        "/profil/dataformats",
        false,
        null,
        null,
        "/assets/svg-icons/team.svg"
      ));
      this.sidemenuData.push(this.createSidemenuObject(
        "Dokumententypen",
        "/profil/documenttypes",
        false,
        null,
        null,
        "/assets/svg-icons/team.svg"
      ));
      this.sidemenuData.push(this.createSidemenuObject(
        "Persönlicher Bereitstellungsserver",
        "/profil/aws",
        false,
        null,
        null,
        "/assets/svg-icons/team.svg"
      ));
    }
    this.sidemenuData.push(this.createSidemenuObject(
      "Eigenes Profil",
      "/profil/"+this.userService.currentUser.userName,
      false,
      null,
      null,
      "/assets/img/single.png"
    ));

    
    this.sidemenuDataSubject.next(this.sidemenuData);
  }

  loadSidemenuOrganisationSettings(): void {
    this.productsVisible = false;
    this.sidemenuData = [];
    this.sidemenuData[0] = this.createSidemenuObject(
      "Informationen",
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/information",
      false,
      null,
      null,
      "/assets/svg-icons/info.svg"
    );
    this.sidemenuData[1] = this.createSidemenuObject(
      "Mitglieder verwalten",
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/benutzer",
      false,
      null,
      null,
      "/assets/svg-icons/team.svg"
    );
    if(this.organisationService.activeItem.organisationType == OrganisationType.CERTIFICATION_OFFICE ||
      this.organisationService.activeItem.organisationType == OrganisationType.CERTIFICATION_PROVIDER) {
        this.sidemenuData[2] = this.createSidemenuObject(
          "Zertifizierungsanträge",
          "/organisations/" +
          this.organisationService.activeItem.organisationId +
          "/certificationRequests",
          false,
          null,
          null,
          "/assets/svg-icons/team.svg"
        ); 
      }
    this.sidemenuDataSubject.next(this.sidemenuData);
  }

  getProductsOfPtg(
    ptgVersionIds: Array<number> = [],
    products: Array<ProductInterface>
  ): Array<SidemenuButtonInterface> {
    let index: number = 0;
    let productsBelongingToPtg = [];
    for (let product of products) {
      for (let productVariant of product.productVariants) {
        for (let variantVersion of productVariant.variantVersions) {
          for (let ptg of variantVersion.ptgVersions) {
            if (ptgVersionIds.includes(ptg.id)) {
              productsBelongingToPtg[index] = this.createSidemenuObject2(
                product.name,
                true,
                "Product",
                true,
                product.productVariants,
                null,
                product.id,
                null,
                null
              );
              index++;
            }
          }
        }
      }
    }
    return productsBelongingToPtg;
  }

  getProductWithoutPtg(
    ptgs: Array<PtgInterface>,
    products: Array<ProductInterface>
  ): Array<SidemenuButtonInterface> {
    let productsWithoutPtg = [];
    let index: number = 0;
    for (let product of products) {
      let productBelongsToPtg: boolean = false;
      for (let productVariant of product.productVariants) {
        for (let variantVersion of productVariant.variantVersions) {
          for (let ptgProduct of variantVersion.ptgVersions) {
            for (let ptg of ptgs) {
              let ptgVersionIdArray = [];
              ptgVersionIdArray = ptgVersionIdArray.concat(ptg.id);
              for (let ptgVersion of ptg.versions) {
                ptgVersionIdArray = ptgVersionIdArray.concat(ptgVersion.id);
              }
              if (ptgVersionIdArray.includes(ptgProduct.id)) {
                productBelongsToPtg = true;
              }
            }
          }
        }
      }
      if (!productBelongsToPtg) {
        productsWithoutPtg[index] = this.createSidemenuObject2(
          product.name,
          true,
          "Product",
          true,
          product.productVariants,
          null,
          product.id,
          null,
          null
        );
        index++;
      }
    }
    return productsWithoutPtg;
  }

  filterSidemenuComposer(filterValue: any, attributeId: number) {
    for (let sidemenuPtg of this.sidemenuData) {
      if (sidemenuPtg.children) {
        sidemenuPtg.children = sidemenuPtg.children.filter(
          (productFilter: SidemenuButtonInterface) => {
            let containsFilteredAttribute: boolean = false;
            for (let productVariant of productFilter.children) {
              for (let variantVersions of productVariant.variantVersions) {
                for (let attribute of variantVersions.attributeValidationValues) {
                  if (attribute.attribute.id === attributeId) {
                    if (Array.isArray(filterValue)) {
                      let containsAllFilterValues = true;
                      for (let value of filterValue) {
                        if (!attribute.value.includes(value.toString())) {
                          containsAllFilterValues = false;
                        }
                      }
                      containsFilteredAttribute = containsAllFilterValues;
                    } else {
                      containsFilteredAttribute =
                        attribute.value === filterValue.toString();
                    }
                  }
                }
              }
            }
            return containsFilteredAttribute;
          }
        );
      }
    }
  }

  searchSidemenuComposerProducts(searchString): void {
    for (let i = 0; i < this.sidemenuData.length; i++) {
      if (this.sidemenuData[i].children !== null) {
        this.sidemenuData[i].children = this.sidemenuDataCopy[
          i
        ].children.filter((child) =>
          child.label.toLowerCase().includes(searchString.toLowerCase())
        );
      }
    }
  }

  getNameWithoutSpecialCharacters(name: string): string {
    return name ? name.replace(environment.specialCharacterRegex, "") : "";
  }

  searchSidemenuComposerUsecases(searchString): void {
    this.sidemenuData = this.sidemenuDataCopy.filter((usecase) =>
      usecase.label.toLowerCase().includes(searchString.toLowerCase())
    );
  }

  loadSidemenuCertifications(sideMenuCertifications: CertificationInterface[]) {
    // null guard
    if(!this.certificationService.activeItem)
    {
      this.titleService.setTitle(this.organisationService.activeItem.organisationName + ": Zertifizierungen - BIMSWARM");
    }

    if (sideMenuCertifications.length >= 0) {
      this.certificationsVisible = true;
      this.sidemenuData = [];
      //-------------------
      // sort certifications
      this.utilService.sortByName(sideMenuCertifications);
      // filter for the active organsiation
      let sidemenuCertificationsFiltered = sideMenuCertifications.filter(
        (x) => {
          return (
            x.company === this.organisationService.activeItem.organisationId
          );
        }
      );
      // create Button at the top
      this.sidemenuData[0] = this.createZertifizierungAnlegenButton();
      //-------------------

      //iterate the certifications
      for (let i: number = 0; i != sidemenuCertificationsFiltered.length; i++) {
        // get work copy
        let certification = sidemenuCertificationsFiltered[i];
        //-------------------
        // sort the variants
        this.utilService.sortByName(certification.certificationVariants);
        //-------------------
        // create the certifications button
        this.sidemenuData[
          i + 1
        ] = SidemenuButttonFactory.CreateButtonFromCertification(certification);
        // imediatly add the create button to the children
        this.sidemenuData[
          i + 1
        ].children[0] = this.createZertifizierungsVarianteAnlegenButton(
          certification
        );

        // now create variant buttons
        for (
          let j: number = 0;
          j != certification.certificationVariants.length;
          j++
        ) {
          // get work copy
          let variant = certification.certificationVariants[j];
          //-------------------
          // sort the versions
          this.utilService.sortByName(variant.certificationVariantVersions);
          // add the variant Button
          this.sidemenuData[i + 1].children[
            j + 1
          ] = SidemenuButttonFactory.CreateButtonFromCertificationVariant(
            variant,
            certification
          );
        }
      }
      this.sidemenuDataSubject.next(this.sidemenuData);
    }
  }
  private createCertificationEntry(certification: CertificationInterface) {
    return this.createSidemenuObject2(
      certification.name,
      false,
      "Certification",
      true,
      [],
      null,
      certification.id,
      null,
      "/organisations/" +
        this.organisationService.activeOrganisation.organisationId +
        "/certifications/" +
        certification.id
    );
  }

  private createZertifizierungAnlegenButton() {
    return this.createSidemenuObject(
      "Zertifizierung anlegen",
      "/organisations/" +
        this.organisationService.activeOrganisation.organisationId +
        "/certifications/create",
      true
    );
  }

  private createZertifizierungsVarianteAnlegenButton(
    certification: CertificationInterface
  ) {
    return this.createSidemenuObject(
      "Zertifizierungsvariante anlegen",
      "/organisations/" +
        this.organisationService.activeOrganisation.organisationId +
        "/certifications/" +
        certification.id +
        "/variants/create",
      true
    );
  }

  private createCertificationVariantEntry(
    variant: CertificationVariantInterface,
    certification: CertificationInterface
  ) {
    return this.createSidemenuObject(
      variant.name,
      "/organisations/" +
        this.organisationService.activeOrganisation.organisationId +
        "/certifications/" +
        certification.id +
        "/variants/" +
        variant.id,
      false,
      variant.id
    );
  }
}
