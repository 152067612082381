import { FileServiceService } from 'src/app/services/file-service/file-service.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { CertificationRequestInterface } from 'src/app/model/certifications/certification-request-interface';
import { CertificationRequestLogInterface } from 'src/app/model/certifications/certification-request-log-interface';
import { ProductInterface } from 'src/app/model/products/product-interface';
import { ProductVariantInterface } from 'src/app/model/products/product-variant-interface';
import { VariantVersionInterface } from 'src/app/model/products/variant-version-interface';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { ProductVariantService } from 'src/app/services/dataServices/product-variant-service/product-variant.service';
import { ProductVariantVersionService } from 'src/app/services/dataServices/product-variant-version-service/product-variant-version.service';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { UserService } from 'src/app/services/user-service/userservice';
import { CertificationRequestStatus } from 'src/app/model/enums/certification-requests-status';
import { CertificationRequestService } from 'src/app/services/dataServices/certification-request/certification-request.service';
import { timeStamp } from 'console';

@Component({
  selector: 'app-version-certification-request-status',
  templateUrl: './version-certification-request-status.component.html',
  styleUrls: ['./version-certification-request-status.component.css']
})
export class VersionCertificationRequestStatusComponent implements OnInit {
  public product: ProductInterface;
  public variant: ProductVariantInterface;
  public version: VariantVersionInterface;
  public certificationRequest: CertificationRequestInterface = {};
  public filesById;

  constructor(
    private certificationRequestService: CertificationRequestService,
    private productService: ProductService,
    private productVariantService: ProductVariantService,
    private productVariantVersionService: ProductVariantVersionService,
    private fileService: FileServiceService,
    private router: Router,
    private organisationService: OrganisationService,
    private userService: UserService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.certificationRequestService.getCertificationRequestById(2).then((certificationRequest: CertificationRequestInterface) => {
      this.certificationRequest = certificationRequest;
    })
  }

  public uploadFile() {
    this.dialogService.openFileManagerDialog(
      "Datei zum Zertifizierungsantrag hinzufügen",
      "Abbrechen",
      "Speichern",
      "Wählen Sie eine oder mehrere Dateien aus, die dem Zertifizierungsantrag hinzugefügt werden sollen.",
      { multi: true, filter: 'application/pdf', upload: true }
    ).subscribe( (confirmed: string[]) => {
      let newFileIds = [];
      if(this.certificationRequest.fileIds) {
        newFileIds = this.certificationRequest.fileIds;
      }
      confirmed.forEach((element: string) => {
        if (newFileIds.indexOf(element) == -1) {
          newFileIds.push(element);
        }
      });
      this.certificationRequest.fileIds = newFileIds;
      //Save this information --> inform backend of change of certification request
      //LogEntry for this change
      this.cd.detectChanges();
    })
  }

  public removeFile(fileId: string) {
    this.dialogService.openConfirmDialog("Wollen Sie diese Datei aus dem Zertifizierungsantrag entfernen?", "Abbrechen", "Entfernen")
      .subscribe((confirmed: boolean) => {
        if(confirmed) {
          let newFileIds = this.certificationRequest.fileIds;
          newFileIds.splice(newFileIds.indexOf(fileId), 1);
          this.certificationRequest.fileIds = newFileIds;
          //Save this information --> inform backend of change of certification request
          //LogEntry for this change
          this.cd.detectChanges();
        }
      })
  }

  public fileInfo(fileId: string) {
    this.fileService.data$.subscribe((data) => {
      this.filesById = data;
    });
    //Racing Codition with File-Service.service.ts updateMyFiles() on Startup
    //So this.filesById can be empty when used to check for fid
    return Object.keys(this.filesById).length > 0
      ? this.filesById[fileId]
      : "Loading...";
  }

  public backToProductVariantScreen() {
    this.router.navigate([
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/products/" +
        this.productService.activeItem.id +
        "/variants/" +
        this.productVariantService.activeItem.id
    ]);
  }

  public cancelCertificationProcess() {
    this.dialogService
      .openConfirmDialog(
        "Zertifizierung abbrechen",
        "Nein",
        "Ja",
        "Wollen Sie diesen Zertifizierungsvorgang wirklich abbrechen?"
      )
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          //PUT DELETION OF CERTIFICATION_REQUEST HERE (BACKEND_REQUEST)
          this.router.navigate([
            "/organisations/" +
              this.organisationService.activeItem.organisationId +
              "/products/" +
              this.productService.activeItem.id +
              "/variants/" +
              this.productVariantService.activeItem.id
          ]);
        }
      });
  }

}
