<div [formGroup]="form">
<h1 mat-dialog-title> Bewertung melden </h1>
<mat-form-field class="input" appearance="outline">
  <textarea
  matInput
    rows="10"
    cols="30"
    formControlName="reason"
    placeholder="Bitte geben Sie den Grund für die Meldung dieser Bewertung an."
    required
  ></textarea>
  <mat-error *ngIf="form.get('reason').invalid">
    Meldegrund wird benötigt.
  </mat-error>
</mat-form-field>

<div mat-dialog-actions>
  <button mat-raised-button (click)="onSubmit(true)" class="btn_green" [disabled]="form.invalid">{{dialogData.buttonYes}}</button>
  <button mat-raised-button (click)="onSubmit(false)" class="btn_red" cdkFocusInitial>{{dialogData.buttonNo}}</button>
</div>
</div>