<div class="two-columns">
  <div>
    <h2 *ngIf="!isEdit">Datenformat anlegen</h2>
    <h2 *ngIf="isEdit">Datenformat bearbeiten</h2>
    <form [formGroup]="form" *ngIf="form">
      <div class="auto-min">
        <mat-form-field>
          <input
            matInput
            placeholder="Name"
            formControlName="name"
            class="full-width"
          />
          <mat-hint>Name gemäß DIN SPEC Open CDE</mat-hint>
          <mat-error *ngIf="name.hasError('required')"
            >Bitte geben Sie einen Namen an</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <mat-select
            placeholder="Status"
            formControlName="active"
            class="full-width"
          >
            <mat-option [value]="true">Aktiv</mat-option>
            <mat-option [value]="false">Inaktiv</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <app-text-editor
        [(editorText)]="form.controls.description.value"
        (editorTextChange)="onDescriptionTextChange($event)"
        class="full-width"
      ></app-text-editor>
    </form>
    <h3>Schema</h3>
    <table mat-table [dataSource]="schemas" class="full-width data-table">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef>Version</th>
        <td mat-cell *matCellDef="let element">{{ element.version }}</td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="subSchema">
        <th mat-header-cell *matHeaderCellDef>Subschema</th>
        <td mat-cell *matCellDef="let element">
          {{ element.subSchema }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; let i = index">
          <div class="flex">
            <button mat-icon-button (click)="editSchema(i)">
              <i class="fa fa-pencil"></i>
            </button>
            <button mat-icon-button (click)="deleteSchema(i)">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="['name', 'version', 'subSchema', 'actions']"
      ></tr>
      <tr
        mat-row
        *matRowDef="
          let row;
          columns: ['name', 'version', 'subSchema', 'actions']
        "
      ></tr>
    </table>
    <div *ngIf="schemas.length === 0" class="text-center">
      <i>Keine Schemas angelegt</i>
    </div>
    <button mat-button class="btn_green" (click)="toggleAddSchemaForm()">
      Schema hinzufügen
    </button>
    <ng-container *ngIf="showAddSchemaForm">
      <form
        [formGroup]="addSchemaFormGroup"
        class="add-form"
        (submit)="handleAddSchemaSubmit()"
      >
        <input
          type="hidden"
          name="index"
          [value]="addSchemaFormGroup.controls.index.value"
        />
        <input
          type="hidden"
          name="id"
          [value]="addSchemaFormGroup.controls.id.value"
        />
        <div class="auto-min">
          <mat-form-field>
            <input
              matInput
              placeholder="Name"
              formControlName="name"
              class="full-width"
            />
            <mat-hint>z.B. gemäß DIN SPEC Open CDE</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Version"
              formControlName="version"
              class="full-width"
            />
            <mat-hint>z.B. gemäß DIN SPEC Open CDE</mat-hint>
          </mat-form-field>
        </div>
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Subschema"
            formControlName="subSchema"
            class="full-width"
          />
          <mat-hint>z.B. gemäß DIN SPEC Open CDE</mat-hint>
        </mat-form-field>
        <button mat-button class="btn_green" type="submit">Hinzufügen</button>
        <button mat-button (click)="toggleAddSchemaForm()" type="reset">
          Abbrechen
        </button>
      </form>
    </ng-container>
    <h3>Dateiformate</h3>
    <table mat-table [dataSource]="fileFormats" class="full-width data-table">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="mimeType">
        <th mat-header-cell *matHeaderCellDef>MIME-Typ</th>
        <td mat-cell *matCellDef="let element">{{ element.mimeType }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; let i = index">
          <div class="flex">
            <button mat-icon-button (click)="editFileFormat(i)">
              <i class="fa fa-pencil"></i>
            </button>
            <button mat-icon-button (click)="deleteFileFormat(i)">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="['name', 'mimeType', 'actions']"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['name', 'mimeType', 'actions']"
      ></tr>
    </table>
    <div *ngIf="fileFormats.length === 0" class="text-center">
      <i>Keine Dateiformate angelegt</i>
    </div>
    <button mat-button class="btn_green" (click)="toggleAddFileFormatForm()">
      Dateiformat hinzufügen
    </button>
    <ng-container *ngIf="showAddFileFormatForm">
      <form
        [formGroup]="addFileFormatFromGroup"
        class="add-form"
        (submit)="handleAddFileFormatSubmit()"
      >
        <input
          type="hidden"
          name="index"
          [value]="addFileFormatFromGroup.controls.index.value"
        />
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Name"
            formControlName="name"
            class="full-width"
          />
          <mat-hint>Name des Dateiformats</mat-hint>
        </mat-form-field>
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="MIME-Typ"
            formControlName="mimeType"
            class="full-width"
          />
          <mat-hint>z.B. gemäß DIN SPEC Open CDE</mat-hint>
        </mat-form-field>
        <button mat-button class="btn_green" type="submit">Speichern</button>
        <button mat-button (click)="toggleAddFileFormatForm()" type="reset">
          Abbrechen
        </button>
      </form>
    </ng-container>
    <div class="button-row">
      <button mat-button class="btn_green" (click)="save()">Speichern</button>
      <button mat-button (click)="handleCancelClick()">Abbrechen</button>
    </div>
  </div>
</div>
