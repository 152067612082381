import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "app-product-detail-display-overview",
  templateUrl: "./product-detail-display-overview.component.html",
  styleUrls: [
    "./product-detail-display-overview.component.css",
    "../product-detail-display.css",
  ],
})
export class ProductDetailDisplayOverviewComponent implements OnInit {
  @Input()
  description: string;

  @Input()
  targetGroups: string[];

  @Input()
  productCategory: string;

  @Input()
  keywords: string[];

  @Input()
  servicePhase: string[];

  @Input()
  installations: string[];

  @Input()
  productType: string[];

  @Input()
  editDate: Date | string;

  @Input()
  publishDate: Date | string;

  @Input()
  licenseModels: string[];

  private hasMoreThatThreeLinesOfDescription: boolean = true;

  constructor() {}

  ngOnInit() {
    if (typeof this.editDate == "string") {
      this.editDate = new Date(this.editDate);
    }
    if (typeof this.publishDate == "string") {
      this.publishDate = new Date(this.editDate);
    }
    this.checkIfDescriptionContainsMoreThanThreeLines(this.description);
  }

  getKeyWordsAsString(): string {
    let result = "";
    let index = 0;
    for (; index < this.keywords.length - 1; index++) {
      result += this.keywords[index] + ", ";
    }
    result += this.keywords[index];
    return result;
  }

  public checkIfDescriptionContainsMoreThanThreeLines(description: string): void {
    if (
      description == null ||
      (description.length < 200 &&
        this.containsFourOrLessParagraphs(description))
    ) {
      this.hasMoreThatThreeLinesOfDescription = false;
    } else {
      this.hasMoreThatThreeLinesOfDescription = true;
    }
  }

  /**
   * Checks whether a description string contains at most four <p> tags.
   * This matters since each <p> inside the enclosing tag causes a new line, thus 4 <p> tags result in 3 lines of text,
   * which is the amount initially displayed
   */
  public containsFourOrLessParagraphs(description: string): boolean {
    const pCount = (description.match(/<p>/g) || []).length;
    return pCount <= 4;
  }
}
