<div>
  <h1>Allgemeine Merkmale</h1>
  <button mat-raised-button class="btn_green" *ngIf="!editOrder" (click)="editOrder = true">Reihenfolge
    bearbeiten</button>
  <button mat-raised-button class="btn_green" *ngIf="editOrder" (click)="saveOrder();">Änderungen speichern</button>
  <button mat-raised-button class="btn_red" *ngIf="editOrder" (click)="cancel()">Abbrechen</button>

  <app-common-feature-add (addedFeature)="addCommonFeature($event)"></app-common-feature-add>

  <div *ngFor="let feature of commonFeatureList; let i = index" class="item">
    <app-common-feature-item class="col1" [commonFeature]="feature" [index]="i"
      (updatedFeature)="updateCommonFeature($event)" (deletedFeature)="deleteCommonFeature($event)">
    </app-common-feature-item>
    <div class="arrows" *ngIf="editOrder" class="col2">
      <button mat-raised-button [ngClass]="isTopOfList(feature) ? '' : 'btn_green'" [disabled]="isTopOfList(feature)"
        (click)="moveCommonFeature(feature, true)" [disableRipple]="true">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
      </button>
      <button mat-raised-button [ngClass]="isBottomOfList(feature) ? '' : 'btn_green'"
        [disabled]="isBottomOfList(feature)" (click)="moveCommonFeature(feature, false)" [disableRipple]="true">
        <i class="fa fa-arrow-down" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>
