<div
  style="
    height: 100%;
    display: flex;
    flex-flow: column;
    background-color: white;
  "
>
  <mat-toolbar color="primary" style="position: relative; z-index: 3">
    <span class="left">{{actualCertification?.name}}</span>
    <button mat-icon-button class="back" (click)="goBack.emit()" color="white">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <div *ngIf="this.displaySpinner">
    <mat-spinner></mat-spinner>
  </div>
  <mat-tab-group *ngIf="!this.displaySpinner" style="flex: 1; overflow: hidden; padding-bottom: 6rem;" [backgroundColor]="'primary'">
    <mat-tab label="Übersicht">
      <div class="container">
        <app-image-slider
        [item]="actualCertification"
        [category]="'certification'"
        ></app-image-slider>
      </div>
      <div class="padding">
        <div style="display: flex">
          <div class="left">
            <div style="display: flex; align-items: center">
              <div class='pointer' (click)="navigateToOrganisation(organisation.organisationId)" >
              <app-image-slider
               [organisation]="this.organisation"
              >
              </app-image-slider>
            </div>
              <div style="padding: 1rem">
                <h1 [ngClass]="(actualCertification?.name.length > 25) ? 'wordellipsis' : ''" style="margin: 0;">{{actualCertification?.name}}</h1>
                <h3 class="pointer" (click)="navigateToOrganisation(organisation.organisationId)" [ngClass]="(actualCertification?.organisationName?.length > 30) ? 'wordellipsis' : ''"style="margin: 0">{{actualCertification?.organisationName}}</h3>
              </div>
            </div>
          </div>
          <div class="right">
  
            <button mat-raised-button [disabled]="organisation?.contacts.length < 1 " color="accent" (click)="requestOffer()"  style="margin-right: 1em">
              Angebot anfragen
            </button>
            <button mat-raised-button [disabled]="this.actualCertification?.url == null
            || this.actualCertification?.url == undefined
            || this.actualCertification?.url == ''"
            (click)="this.routeToWebsite()" color="accent">Webseite</button>
          </div>
        </div>
        <mat-divider></mat-divider>
        <br />
        <span style="display: flex; opacity: 0.8">
          <p class="left" style="margin: 0">Kategorie: {{actualCertification?.certificationType}}</p>
          <p *ngIf="this.actualCertification?.editDate" class="middle" style="margin: 0">Seit {{ 'Certification Publish Date'}} auf BIMSWARM</p>
          <p *ngIf="this.actualCertification?.editDate" class="right" style="margin: 0">Letztes Update: {{"Certification Edit Date"}} </p>
        </span>
        <br />
        <mat-divider></mat-divider>
        <br />
        <h3>Beschreibung</h3>
        <p class="word-wrap" [innerHTML]="actualCertification?.description"></p>
        <mat-divider></mat-divider>
        <br />
        <h3>Schlagwörter</h3>
        <mat-chip-list>
          <mat-chip *ngFor="let item of certificationKeywords">{{item}}</mat-chip>
        </mat-chip-list>
        <br />
      </div>
    </mat-tab>
    <mat-tab *ngIf="!(this.actualCertification?.documentFileIds == null)" label="Downloads">
      <div class="padding">
        <app-certification-detail-display-criteria
        [certification]="this.actualCertification"
        >
        </app-certification-detail-display-criteria>
      </div>
    </mat-tab>
    <mat-tab label="Zertifikate">
    <div>
      <app-certification-detail-display-certified-products
      [certification]="this.actualCertification">
      </app-certification-detail-display-certified-products>
    </div>
    </mat-tab>
    <!--mat-tab label="Anbieter"> Content 3 </mat-tab-->
  </mat-tab-group>
</div>
