<div class="filter">
  <div class="select">
    <mat-form-field>
      <mat-label>{{ filterName }}</mat-label>
      <mat-select [(ngModel)]="selectedValue">
        <mat-option *ngFor="let option of options" [value]="option">{{
          option.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div
      class="mini-hex-button"
      [matTooltip]="'Hinzufügen'"
      [matTooltipPosition]="'below'"
      matTooltipClass="tooltip"
    >
      <hexagon
        [icon]="'plus'"
        [hover]="true"
        [outerColor]="'green'"
        (click)="addEntryToSelected()"
      >
      </hexagon>
    </div>
  </div>
  <div class="currentlySelected">
    <div class="filterItem" *ngFor="let select of selected">
      <div
        *ngIf="!select.permanent"
        class="mini-hex-button"
        [matTooltip]="'Entfernen'"
        [matTooltipPosition]="'below'"
        matTooltipClass="tooltip"
      >
        <hexagon
          [icon]="'minus'"
          [hover]="true"
          [outerColor]="'green'"
          (click)="removeEntryFromSelected(select)"
        >
        </hexagon>
      </div>
      <div *ngIf="select.permanent" class="mini-hex-button">
        <hexagon
          [outerColor]="'lightgrey'"
        >
        </hexagon>
      </div>
      <span class="label" [ngClass]="{ grey: select.permanent }">{{
        select.name
      }}</span>
    </div>
  </div>
</div>
