<div *ngIf="!isIE; else warning">
  <!-- Header Navbar -->
  <div class="stickyHeader">
    <app-header></app-header>
  </div>
  <!-- Navbar Downwards -->
  <div
    [ngClass]="showSideMenu()? 'layoutWithSideMenu' : 'layoutNoSideMenu'"
  >
    <!-- left Bar background  -->
    <div [hidden]="!showSideMenu()" class="zBelowHeader">
      <!-- Left Sidemenu -->
      <div class="leftBar">
      </div>
      <div>
        <app-search-panel
          (filter)="filter($event)"
          (reset)="reset($event)"
          [urlEqualsComposer]="equalsString('/composer')"
        ></app-search-panel>
        <app-sidemenu-new
          style="max-width: 21vw;"
          class="sidemenu"
          [urlEqualsComposer]="equalsString('/composer')"
        ></app-sidemenu-new>
      </div>
    </div>
    <!-- Main Content -->
    <div [ngClass]="showSideMenu()? 'content': 'contentNoSideMenu'">
      <router-outlet></router-outlet>
    </div>
    <div #emptyDropdown></div>
  </div>
</div>

<!--router-outlet></router-outlet-->

<ng-template #warning>
  <div class="warningscreen"></div>
</ng-template>
<app-footer class="footer"*ngIf="!equalsString('/resetPassword')"></app-footer>
