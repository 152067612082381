import { AttributeInterface } from 'src/app/model/attributes/attribute-interface';
import { Pipe, PipeTransform } from '@angular/core';
import { ProductInterface } from '../model/products/product-interface';


@Pipe({ name: 'filterAttributes' })
export class FilterAttributesOfProducts implements PipeTransform {
  transform(products: ProductInterface[], attributes: AttributeInterface[]) {

    if (null == attributes || attributes.length == 0) {
      return products;
    }

    const filteredProducts = [];

    for (const product of products) {
      for (const variant of product.productVariants) {
        for (const version of variant.variantVersions) {
          for (const attributeOfProduct of version.attributeValidationValues) {
            for (const attribute of attributes) {
              if (attributeOfProduct.attribute.name === attribute) {
                if (!filteredProducts.includes(product)) {
                  filteredProducts.push(product);
                }
              }
            }
          }
        }
      }
    }

    return filteredProducts;
  }
}
