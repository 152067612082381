import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpEvent, HttpResponse, HttpParams } from "@angular/common/http";
import { BaseService } from "../../base.service";
import { resolve } from "url";
import { UserService } from "../../user-service/userservice";
import { OrganisationService } from "../organisation-service/organisationservice";

const SWARM_PIC_API = "/proxy/api/v0/productservice/pictures/";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  productPictureFileGalleryDict: {
    [id: number]: { file: File[]; pictureId: number[]; name: string[] };
  } = {};

  constructor(
    private httpClient: HttpClient,

  ) {}



  private pushOnGallery(productId: number, file: File, pictureId: number) {
    this.productPictureFileGalleryDict[productId].file.push(file);
    this.productPictureFileGalleryDict[productId].pictureId.push(pictureId);
    this.productPictureFileGalleryDict[productId].name.push(file.name);
  }

  doesAlreadyExist(productId: number, file: File) {
    if (!this.productPictureFileGalleryDict[productId]) {
      // if not init
      this.productPictureFileGalleryDict[productId].file = [];
      this.productPictureFileGalleryDict[productId].pictureId = [];
      this.productPictureFileGalleryDict[productId].name = [];

      return -1;
    }
    // check if the file is in local storage
    let index = this.productPictureFileGalleryDict[productId].file.indexOf(
      file
    );
    return index;
  }

  getFile(pictureId: number, url: string) {
    //formData: FormData = new FormData();
    this.httpClient.get(url);
  }

  updateFile(fileToUpload: File, url: string): Observable<HttpEvent<string>> {
    let b;
    return b;
  }

  uploadFile(fileToUpload: File, url: string, isCertification?: boolean): Observable<HttpEvent<string>> {


    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);

    if(isCertification){
      return this.httpClient.post(url, formData, {
        reportProgress: true,
        observe: "response",
        responseType: "text",
        params: new HttpParams().set('isCertification', String(true)),
      })
    }
    return this.httpClient.post(url, formData, {
      reportProgress: true,
      observe: "response",
      responseType: "text",
    });
  }
}
