import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";

import { UserService } from '../services/user-service/userservice';
import { CertificationService } from '../services/dataServices/certification-service/certificationService';

@Injectable()
export class CertificationListResolver implements Resolve<any> {
  constructor(
    private certificationService: CertificationService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    let pro = parseInt(route.paramMap.get("certificationId"));
    if (pro) {
      await this.certificationService.setActiveCertification(pro);
    }

    return;
  }
}
