<div class="scrollable"
*ngIf="this.product.productVariants != null">
  <!------------------------------------------------------------->
  <!--#########################################################-->
  <!------------------------------------------------------------->
  <app-detail-display-variant-version-selector
    [product]="this.product"
    [dataType]="'PRODUCT'"
    (onVersionChosen)="updateCertificates($event)"
  >
  </app-detail-display-variant-version-selector>
  <div *ngIf="hasAtLeastOneCertificate(); else none">
    <!------------------------------------------------------------->
    <!--################## Merkmal-Zertifikate ##################-->
    <!------------------------------------------------------------->
    <div *ngIf="attr_certificates && attr_certificates.length > 0">
    <div class="twocolumnsgrid" >
    <th class="certification_attribute" style="margin-right: 0.5em"></th>
    <h3>Merkmal-Zertifikate</h3>
    </div>
    <div>
      <table
      mat-table
      [dataSource]="attr_certificates"
      class="mat-elevation-z4"
      style="width: 100%"
    >
      <!-- Position Column -->
      <ng-container matColumnDef="Zertifikat">
        <th mat-header-cell *matHeaderCellDef>Zertifikat</th>
        <td (click)="routeToCertificationDetailPage(element.certification.id)" class="onhover" mat-cell *matCellDef="let element">{{ element.certification.name }}</td>
      </ng-container>

      <ng-container matColumnDef="Variante">
        <th mat-header-cell *matHeaderCellDef>Variante</th>
        <td  mat-cell *matCellDef="let element">{{ element.variant.name }}</td>
      </ng-container>

      <ng-container matColumnDef="Version">
        <th mat-header-cell *matHeaderCellDef>Version</th>
        <td  mat-cell *matCellDef="let element">{{ element.version.name }}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayColumnsCertificate"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumnsCertificate"></tr>
    </table>
    </div>
    </div>
    <br/>
    <mat-divider></mat-divider>
    <br/>
    <!------------------------------------------------------------->
    <!--################## Produkttyp-Zertifikate ###############-->
    <!------------------------------------------------------------->
    <div class="item" *ngIf="ptg_certificates && ptg_certificates.length > 0">
      <!---------------------------------------------------->
      <div class="twocolumnsgrid">
        <th class="certification_ptg" style="margin-right: 0.5em"></th>
        <h3>Produkttyp-Zertifikate</h3>
        </div>
        <div>
          <table
          mat-table
          [dataSource]="ptg_certificates"
          class="mat-elevation-z4"
          style="width: 100%"
        >
          <!-- Position Column -->
          <ng-container matColumnDef="Zertifikat">
            <th mat-header-cell *matHeaderCellDef>Zertifikat</th>
            <td (click)="routeToCertificationDetailPage(element.certification.id)" class="onhover" mat-cell *matCellDef="let element">{{ element.certification.name }}</td>
          </ng-container>

          <ng-container matColumnDef="Variante">
            <th mat-header-cell *matHeaderCellDef>Variante</th>
            <td mat-cell *matCellDef="let element">{{ element.variant.name }}</td>
          </ng-container>

          <ng-container matColumnDef="Version">
            <th mat-header-cell *matHeaderCellDef>Version</th>
            <td mat-cell *matCellDef="let element">{{ element.version.name }}</td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayColumnsCertificate"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumnsCertificate"></tr>
        </table>
        </div>
        <br/>
        <mat-divider></mat-divider>
        <br/>
    </div>
    <!------------------------------------------------------------->
    <!--################## Toolchain-Zertifikate ################-->
    <!------------------------------------------------------------->
    <div class="item" *ngIf="tc_certificates && tc_certificates.length > 0">
      <div class="twocolumnsgrid">
        <th class="certification_tc" style="margin-right: 0.5em"></th>
        <h3>Toolchain-Zertifikate</h3>
        </div>
        <div>
          <table
          mat-table
          [dataSource]="tc_certificates"
          class="mat-elevation-z4"
          style="width: 100%"
        >
          <!-- Position Column -->
          <ng-container matColumnDef="Zertifikat">
            <th mat-header-cell *matHeaderCellDef>Zertifikat</th>
            <td (click)="routeToCertificationDetailPage(element.certification.id)" class="onhover" mat-cell *matCellDef="let element">{{ element.certification.name }}</td>
          </ng-container>

          <ng-container matColumnDef="Variante">
            <th mat-header-cell *matHeaderCellDef>Variante</th>
            <td  mat-cell *matCellDef="let element">{{ element.variant.name }}</td>
          </ng-container>

          <ng-container matColumnDef="Version">
            <th mat-header-cell *matHeaderCellDef>Version</th>
            <td  mat-cell *matCellDef="let element">{{ element.version.name }}</td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayColumnsCertificate"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumnsCertificate"></tr>
        </table>
        </div>
        <br/>
        <mat-divider></mat-divider>
        <br/>
    </div>

    <!------------------------------------------------------------->
    <!--#########################################################-->
    <!------------------------------------------------------------->
  </div>
  <ng-template #none>
    <p class="certification_none"> (keine)</p>
  </ng-template>
</div>
