<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Hepta+Slab:wght@500&display=swap" rel="stylesheet">
</head>

<div class="outer">
<div class="left">
    <p class="headline">
        {{'startingPage.headline' | translate}}
    </p>
    <h2>
        {{'startingPage.generate' | translate}} <span class="underline">Gaia-X SHACL-</span> {{'startingPage.erstellen' | translate}}.
    </h2>
    <div class="action">
        <button class="button" [routerLink]="getStartedUrl">{{'startingPage.started' | translate}}</button>
        <div class="details">
            <a href="https://international.eco.de/legal-notice/" target="_blank">{{'startingPage.imprint' | translate}}</a>
            <a href="https://international.eco.de/legal-notice/privacy-policy/" target="_blank"> |  {{'startingPage.data' | translate}}</a>
        </div>
    </div>
</div>


<div class="right">
    <div class="line"></div>
    <p class="heroTitle"> {{'startingPage.steps' | translate}}</p>
    <div class="hero">
        <div class="step">
            <img src="../../assets/images/select.svg" alt="SVG mit img laden" width="60" height="60" >
            <p> {{'startingPage.select' | translate}}</p>
        </div>
        <div class="step">
            <img src="../../assets/images/form.svg" alt="SVG mit img laden" width="60" height="60" >
            <p> {{'startingPage.form' | translate}}</p>
        </div>
        <div class="step">
            <img src="../../assets/images/generate.svg" alt="SVG mit img laden" width="60" height="60" >
            <p> {{'startingPage.create' | translate}}</p>
        </div>
        <div class="step">
            <img src="../../assets/images/export.svg" alt="SVG mit img laden" width="60" height="60" >
            <p> {{'startingPage.export' | translate}}</p>
        </div>
    </div>

</div>

</div>

