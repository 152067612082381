import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { ProductInterface } from "src/app/model/products/product-interface";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { ProductService } from "src/app/services/dataServices/product-service/productService";
import { UserService } from "src/app/services/user-service/userservice";

@Component({
  selector: "app-product-info-screen",
  templateUrl: "./product-info-screen.component.html",
  styleUrls: [
    "./product-info-screen.component.css",
    "../../../../../app.component.css",
  ],
})
export class ProductInfoScreenComponent implements OnInit, OnDestroy {
  private activeProductSubscription: Subscription;

  public productEdit: ProductInterface;

  constructor(
    public productService: ProductService,
    public organisationService: OrganisationService,
    public router: Router,
    public route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private titleService: Title
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }

  }

  ngOnInit() {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    this.activeProductSubscription = this.productService.activeItemObserver.subscribe(
      (x) => {
        if (x) {
          this.productEdit = x;
          this.titleService.setTitle(this.organisationService.activeItem.organisationName + ": " + this.productService.activeProduct.name + " - BIMSWARM");
          this.cd.detectChanges();
        }
      }
      
    );

  }

  ngOnDestroy() {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
    if (this.activeProductSubscription) {
      this.activeProductSubscription.unsubscribe();
    }
  }
}
