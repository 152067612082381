<div class="two-columns" *ngIf="organisation">
  <div>
    <h1>Informationen</h1>
    <div *ngIf="!changeOrganisationDetailsMode" class="leftColumn">
      <div class="prevent-grid-blowout">
        <mat-form-field>
          <input
            matInput
            placeholder="Name der Organisation"
            [value]="organisation.organisationName"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Straße"
            [value]="organisation.address.street"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Postleitzahl"
            [value]="organisation.address.postalCode"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Kurzbezeichnung"
            [value]="organisation.domain"
            disabled
          />
        </mat-form-field>
      </div>
      <div class="prevent-grid-blowout">
        <mat-form-field>
          <input
            matInput
            placeholder="Art der Organisation"
            [value]="getOrgaType()"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Hausnummer"
            [value]="organisation.address.streetAdditional"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Ort"
            [value]="organisation.address.city"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="URL"
            [value]="organisation.website"
            disabled
          />
        </mat-form-field>
      </div>
    </div>
    <form #form="ngForm">
      <div *ngIf="changeOrganisationDetailsMode" class="leftColumn">
        <div class="prevent-grid-blowout">
          <mat-form-field>
            <input
              matInput
              placeholder="Name der Organisation"
              name="name"
              required
              [(ngModel)]="organisationDetails.organisationName"
              #orgaName="ngModel"
            />
            <mat-error *ngIf="orgaName.errors?.required && orgaName.touched">
              Bitte geben Sie einen Organisationsnamen ein.
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Straße"
              name="street"
              [(ngModel)]="organisationDetails.address.street"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Postleitzahl"
              name="plz"
              [(ngModel)]="organisationDetails.address.postalCode"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Kurzbezeichnung"
              name="domain"
              required
              [(ngModel)]="organisationDetails.domain"
              #domain="ngModel"
            />
            <span matPrefix>{{ domainPrefix }}</span>
            <mat-error *ngIf="domain.errors?.required && domain.touched">
              Bitte geben Sie eine Kurzbezeichnung ein.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="prevent-grid-blowout">
          <mat-form-field>
            <input
              matInput
              placeholder="Art der Organisation"
              [value]="getOrgaType()"
              disabled
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Hausnummer"
              name="number"
              [(ngModel)]="organisationDetails.address.streetAdditional"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Ort"
              name="city"
              [(ngModel)]="organisationDetails.address.city"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="URL"
              name="website"
              [(ngModel)]="organisationDetails.website"
            />
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="!changeOrganisationDetailsMode">
        <h4 class="disabled">Beschreibung</h4>
        <p
          class="disabled description"
          [innerHTML]="organisation.description"
        ></p>
      </div>
      <div *ngIf="changeOrganisationDetailsMode">
        <app-text-editor
          [(editorText)]="organisationDetails.description"
          (textValidityChange)="onDescriptionValidityChange($event)"
        ></app-text-editor>
      </div>
      <div *ngIf="changeOrganisationDetailsMode" class="fileUpload">
        <div class="logo-upload">
          <img
            *ngIf="organisationDetails.fileId"
            [src]="imagePath(organisationDetails.fileId)"
          />
          <button mat-raised-button (click)="openFileManager('logo')">
            Logo ändern
          </button>
        </div>

        <div class="preview-upload">
          <img
            *ngIf="organisationDetails.previewFileId"
            [src]="imagePath(organisationDetails.previewFileId)"
          />
          <button mat-raised-button (click)="openFileManager('preview')">
            Vorschau ändern
          </button>
        </div>
      </div>
    </form>
    <div class="buttons">
      <button
        *ngIf="
          !changeOrganisationDetailsMode && this.isUserAdminOfOrganisationOrPlattformAdmin()
        "
        mat-raised-button
        class="btn_green"
        (click)="onChangeOrganisationDetailsToggle()"
      >
        Bearbeiten
      </button>
      <!---- && organisationService.activeOrganisationMembership.role==='ADMIN' -->

      <button
        *ngIf="
          !changeOrganisationDetailsMode && this.isUserAdminOfOrganisationOrPlattformAdmin()
        "
        mat-raised-button
        class="btn_red"
        (click)="onPressAskForDeleteConfirm()"
      >
        Organisation löschen
      </button>
      <!---- && organisationService.activeOrganisationMembership.role==='ADMIN' -->

      <button
        *ngIf="changeOrganisationDetailsMode"
        mat-raised-button
        class="btn_green"
        [disabled]="form.invalid || !isDescriptionValid"
        (click)="onChangeOrganisationDetailsConfirm()"
      >
        Speichern
      </button>
      <button
        *ngIf="changeOrganisationDetailsMode"
        mat-raised-button
        class="btn_red"
        (click)="onChangeOrganisationDetailsToggle()"
      >
        Abbrechen
      </button>
    </div>
    <app-create-contact-form
      *ngIf="changeOrganisationDetailsMode"
      [orga]="organisation"
      [contacts]="organisation.contacts"
    ></app-create-contact-form>
  </div>
</div>
