import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { PtgService } from "../services/dataServices/ptg-service/ptgService";
import { AttributeService } from "../services/dataServices/attribute-service/attributeService";
import { OrganisationService } from "../services/dataServices/organisation-service/organisationservice";
import { HeaderServiceService } from "../services/header-service/header-service.service";

@Injectable()
export class PtgListResolver implements Resolve<any> {
  constructor(
    private ptgService: PtgService,
    private attributeSerivce: AttributeService,
    private organisationService: OrganisationService,
    private headerService: HeaderServiceService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    // let name = this.organisationService.activeItem.organisationName;
    // this.headerService.loadHeaderTextNormal(name);
    if (
      this.ptgService.collection == null ||
      this.ptgService.collection.length == 0
    ) {
    /*  await this.ptgService.getAllPtgs().then(() => {
        if (this.ptgService.collection.length > 0) {
          this.ptgService.setActivePtg(this.ptgService.collection[0]).catch();
        }
      });*/

      this.attributeSerivce.getAllAttributes();
    }
  }
}
