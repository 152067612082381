import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ComposerToolchainInstanceOverview } from 'src/app/model/composer/composer-toolchain-instance-overview';

@Component({
  selector: 'app-toolchain-instance-table',
  templateUrl: './toolchain-instance-table.component.html',
  styleUrls: ['./toolchain-instance-table.component.css']
})
export class ToolchainInstanceTableComponent implements OnInit {

  @Output() deleteToolchainEvent = new EventEmitter<number>();

  @Input()
  public toolchainInstances: ComposerToolchainInstanceOverview[];

  constructor() { }

  ngOnInit() {
  }

  deleteToolchain(templateId: number) {
    this.deleteToolchainEvent.emit(templateId);
  }

}
