import RuleProvider from "diagram-js/lib/features/rules/RuleProvider";
import { every, find, forEach, some } from "lodash";
import { inherits } from "util";

export function ComposerRulesForViewer(eventBus) {
  RuleProvider.call(this, eventBus);
}

inherits(ComposerRulesForViewer, RuleProvider);

ComposerRulesForViewer["$inject"] = ["eventBus"];

ComposerRulesForViewer.prototype.init = function () {

  //Disallow every Change of Connections
  this.addRule("connection.reconnect", 20000, function (context) {
    return false;
  });
};

