import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from "@angular/material/dialog";
import { DialogPrivacy } from "src/app/components/dialog-windows/dialog-privacy-text/dialog-privacy";
import { Observable } from "rxjs";





@Component({
  selector: 'app-dialog-cookie-consent',
  templateUrl: './dialog-cookie-consent.component.html',
  styleUrls: ['./dialog-cookie-consent.component.css']
})
export class DialogCookieConsentComponent implements OnInit {

  isChecked = true;

  constructor(
    public dialogRef: MatDialogRef<DialogCookieConsentComponent>,
      private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {
  }

  onClick(): void{
    this.dialogRef.close(this.isChecked);
  }

  toolTipText(): string {
    if(this.isChecked){
      return "Bei der jetzigen Einstellung dürfen wir Cookies sammeln, die uns statistische Auswertungen ermöglichen, um die Seite zu optimieren";
    }
    return "Bei der jetzigen Einstellung dürfen wir KEINE Cookies für statistische Zwecke sammeln."
  }

  showPrivacy() {
this.openPrivacyDialogInform(

          "Schließen"
      );

  }

  /** Dialogfenster was zur reinen Information des Users dient uber Privacy / Um Circular dependency zu vermeiden die Methode aus dialogSErvice kopiert */
    openPrivacyDialogInform(
      buttonNoLabel: string
    ): Observable<any> {
      const reference = this.dialog.open(DialogPrivacy, {
        width: "75vw",
        autoFocus: false,
      maxHeight: '90vh',
      data: {

        buttonNo: buttonNoLabel,

      },
      });
      return reference.afterClosed();
    }

}
