<h2>Dokumententypen</h2>

<a routerLink="/profil/documenttypes/add" mat-button class="btn_green">
  Neuen Dokumenttyp Anlegen
</a>
<div>
  <table mat-table [dataSource]="data" class="full-width data-table">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let documentType">{{ documentType.name }}</td>
    </ng-container>

    <ng-container matColumnDef="showColumn">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let documentType">
        <button mat-button class="btn_green">Anzeigen</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="editColumn">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let documentType">
        <a routerLink="/profil/documenttypes/edit/{{documentType.id}}" mat-button >Bearbeiten</a>
      </td>
    </ng-container>
    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></tr>
    <tr
      mat-row
      *matRowDef="let documentType; columns: displayedColumns"
      class="element-row"
    ></tr>
  </table>
</div>
