import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { ProductVariantInterface } from "src/app/model/products/product-variant-interface";
import { VariantVersionInterface } from "src/app/model/products/variant-version-interface";
import { environment } from "src/environments/environment";
import { AttributeService } from "src/app/services/dataServices/attribute-service/attributeService";
import { AttributeValidationServiceService } from "src/app/services/dataServices/dataValidation/attribute-validation-service/attribute-validation-service.service";
import { UtilService } from 'src/app/services/util-service/utilService';
import { ProductCategory } from "src/app/model/enums/productCategory";
import { CommonFeatureService } from "src/app/services/common-feature-service/common-feature.service";
import { ProductCommonFeatureInterface } from "src/app/model/commonFeature/product-common-feature-interface";
import { FileServiceService } from "src/app/services/file-service/file-service.service";
import { FileInfoInterface } from 'src/app/model/marketplace/file-info-interface';
import { ProductInterface } from 'src/app/model/products/product-interface';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-product-screen",
  templateUrl: "./product-screen.component.html",
  styleUrls: ["./product-screen.component.css"],
})
export class ProductScreenComponent implements OnInit, OnDestroy {
  private initialImageUrl = environment.defaultPictureProducts;
  public product: ProductInterface;
  private uploadPictureFile: File;
  private fileName: string = "";
  private featureArray: string[] = [];
  private isFormValid: boolean = false;
  private isDescriptionValid: boolean = true;
  private commonFeatures: ProductCommonFeatureInterface[] = [];

  private filesById;

  constructor(
    public organisationService: OrganisationService,
    public productService: ProductService,
    public attributeService: AttributeService,
    public router: Router,
    private dialogService: DialogService,
    public attributeValidationService: AttributeValidationServiceService,
    private commonFeatureService: CommonFeatureService,
    private cd: ChangeDetectorRef,
    private fileService: FileServiceService,
    private userService: UserService,
    private titleService: Title
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    this.titleService.setTitle("Produkt anlegen: " + this.organisationService.activeItem.organisationName + " - BIMSWARM");
  }

  ngOnInit(): void {
    this.uploadPictureFile = null;

    if (this.organisationService && this.organisationService.activeItem) {
      this.product = {
        company: this.organisationService.activeItem.organisationId,
        category: ProductCategory.APP,
        description: "",
        slogan: "",
        features: [],
        galleryFileIds: [],
        documentFileIds: [],
        relatedProducts: [],
        productVariants: [
          {
            variantVersions: [{}],
          },
        ],
      };
    }
  }

  ngOnDestroy() : void {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }

  private cancelCreateProduct(): void {
    if (this.productService.activeItem) {
      this.router.navigate([
        "/organisations/" +
          this.organisationService.activeItem.organisationId +
          "/products/" +
          this.productService.activeItem.id,
      ]);
    } else {
      this.router.navigate([
        "/organisations/" +
          this.organisationService.activeItem.organisationId +
          "/products",
      ]);
    }
  }

  private createProduct(): void {
    //TODO Replace old Upload by Filemanager

    // first variant
    let productVariant: ProductVariantInterface = this.product
      .productVariants[0];
    // first version
    let productVariantVersion: VariantVersionInterface =
      productVariant.variantVersions[0];
    // picture
    // TODO uncomment this this.uploadPictureFile = this.imageUploadComponent.uploadPictureFile;
    // validate
    //Check for string lengths
    if(!UtilService.areStringsValid(this.product.name, this.product.description)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen sie sicher, dass der Name oder die Beschreibung nicht zu lang sind."
      );
      return;
    }
    if(!UtilService.areStringsValid(productVariant.name, null)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen sie sicher, dass der Name der Variante nicht zu lang ist."
      );
      return;
    }
    if(!UtilService.areStringsValid(productVariantVersion.name, null)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen sie sicher, dass der Name der Version nicht zu lang ist."
      );
      return;
    }
    if (!this.isInputValid()) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen Sie sicher, dass alle Merkmale validiert sind" +
          " oder entfernen Sie alle Produkttypenzugehörigkeiten, von denen nicht alle Merkmale validiert sind."
      );
      return;
    }

    if (this.product.description == null) {
      this.product.description = "";
    }
    if (productVariant.description == null) {
      productVariant.description = "";
    }
    if (productVariantVersion.description == null) {
      productVariantVersion.description = "";
    }
    productVariantVersion.attributeValidationValues = this.attributeValidationService.getAttributeValidationValues();
    this.product.features = this.featureArray;

    this.productService.createProduct(this.product).then(
      async (p: ProductInterface) => {
        this.productService.loadProductsForOrganisation(
          this.organisationService.activeOrganisation
        );

        this.commonFeatureService
          .addFeaturesForProduct(
            p.id,
            this.commonFeatures.map((elem) => elem.entry.id)
          )
          .subscribe((success) => {});

        this.router.navigateByUrl(
          "/organisations/" +
            this.organisationService.activeItem.organisationId +
            "/products/" +
            p.id
        );
        this.dialogService.openDialog(
          "Produkt erstellt",
          "Das neue Produkt " + p.name + " wurde erfolgreich erstellt"
        );
      },
      (e: ErrorEvent) => {
        this.uploadPictureFile = null;
      }
    );
  }

  private createBaustelle(): void {
    //TODO Replace old Upload by Filemanager
    // first variant
    let productVariant: ProductVariantInterface = this.product
      .productVariants[0];
    // first version
    let productVariantVersion: VariantVersionInterface =
      productVariant.variantVersions[0];
    // picture
    // TODO uncomment this this.uploadPictureFile = this.imageUploadComponent.uploadPictureFile;
    // validate
    //Check for string lengths
    if(!UtilService.areStringsValid(this.product.name, this.product.description)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen sie sicher, dass der Name oder die Beschreibung nicht zu lang sind."
      );
      return;
    }
    if(!UtilService.areStringsValid(productVariant.name, null)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen sie sicher, dass der Name der Variante nicht zu lang ist."
      );
      return;
    }
    if(!UtilService.areStringsValid(productVariantVersion.name, null)) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen sie sicher, dass der Name der Version nicht zu lang ist."
      );
      return;
    }
    if (!this.isInputValid()) {
      this.dialogService.openDialog(
        "Speichern fehlgeschlagen",
        "Bitte stellen Sie sicher, dass alle Merkmale validiert sind" +
          " oder entfernen Sie alle Produkttypenzugehörigkeiten, von denen nicht alle Merkmale validiert sind."
      );
      return;
    }

    if (this.product.description == null) {
      this.product.description = "";
    }
    if (productVariant.description == null) {
      productVariant.description = "";
    }
    if (productVariantVersion.description == null) {
      productVariantVersion.description = "";
    }
    productVariantVersion.attributeValidationValues = this.attributeValidationService.getAttributeValidationValues();
    this.product.features = this.featureArray;

    this.productService.createProduct(this.product).then(
      async (p: ProductInterface) => {
        this.productService.loadProductsForOrganisation(
          this.organisationService.activeOrganisation
        );

        this.commonFeatureService
          .addFeaturesForProduct(
            p.id,
            this.commonFeatures.map((elem) => elem.entry.id)
          )
          .subscribe((success) => {});

        this.router.navigateByUrl(
          "/organisations/" +
            this.organisationService.activeItem.organisationId +
            "/products/" +
            p.id
        );
        this.dialogService.openDialog(
          "Produkt erstellt",
          "Das neue Produkt " + p.name + " wurde erfolgreich erstellt"
        );
      },
      (e: ErrorEvent) => {
        this.uploadPictureFile = null;
      }
    );
  }


  public showCommonFeatureSelectionDialog() {
    this.dialogService
      .openCommonFeatureEditDialog([...this.commonFeatures])
      .subscribe((success) => {
        if (success) {
          this.commonFeatures = success;
        }
      });
  }

  // Opens the file manager to upload the logo
  public openFileManager(target): void {
    this.dialogService
      .openFileManagerDialog(
        "Datei für " + target + " auswählen",
        "Abbrechen",
        "Übernehmen",
        "Wählen sie eine Datei aus.",
        { multi: false, upload: true }
      )
      .subscribe((confirmed: string[]) => {
        if (confirmed.length >= 1) {
          this.product.fileId = confirmed[0];
          this.cd.detectChanges();
        }
      });
  }

  // Deletes fileId from gallery list
  public deleteFromGallery(fileId: string): void {
    let galleryIndex = this.product.galleryFileIds.indexOf(fileId);
    this.product.galleryFileIds.splice(galleryIndex, 1);
  }

  // deletes documentId from document list
  public deleteDocument(fileId: string): void {
    let galleryIndex = this.product.documentFileIds.indexOf(fileId);
    this.product.documentFileIds.splice(galleryIndex, 1);
  }

  //Changes the order of a galleryItem
  public moveGalleryItem(fileId: string, toLeft: boolean): void {
    let galleryIndex = this.product.galleryFileIds.indexOf(fileId);
    if (toLeft && galleryIndex > 0) {
      let tmp = this.product.galleryFileIds[galleryIndex - 1];
      this.product.galleryFileIds[galleryIndex - 1] = this.product.galleryFileIds[galleryIndex];
      this.product.galleryFileIds[galleryIndex] = tmp;
    }
    if (!toLeft && galleryIndex < this.product.galleryFileIds.length - 1) {
      let tmp = this.product.galleryFileIds[galleryIndex];
      this.product.galleryFileIds[galleryIndex] = this.product.galleryFileIds[galleryIndex + 1];
      this.product.galleryFileIds[galleryIndex + 1] = tmp;
    }
  }

  // Opens the file manager for setting documents or gallery pictures
  public openFileManagerPicture(target: string): void {
    let options = { multi: false, filter: false, upload: true };
    if (target == "Dokumente" || target == "Galerie") {
      options.multi = true;
    }
    this.dialogService
      .openFileManagerDialog(
        "Datei für " + target + " auswählen",
        "Abbrechen",
        "Übernehmen",
        "Wählen Sie eine Datei.",
        options
      )
      .subscribe((confirmed: string[]) => {
        if (confirmed.length > 0) {
          let productData = this.product;
          if (target == "Logo") {
            productData.fileId = confirmed[0];
          }
          if (target == "Galerie") {
            confirmed.forEach((element: string) => {
              if (productData.galleryFileIds.indexOf(element) == -1) {
                productData.galleryFileIds.push(element);
              }
            });
          }
          if (target == "Dokumente") {
            confirmed.forEach((element: string) => {
              if (productData.documentFileIds.indexOf(element) == -1) {
                productData.documentFileIds.push(element);
              }
            });
          }
          this.product = productData;
          this.cd.detectChanges();
        }
      });
  }

  // Gets the fileinfo of a fileID
  public fileInfo(fileId: string) {
    this.fileService.data$.subscribe((data: FileInfoInterface) => {
      this.filesById = data;
    });
    return this.filesById[fileId];
  }

  //Helper method for image path
  public imagePath(fileId: string) {
    return "/proxy/api/v0/fileservice/file/" + fileId;
  }

  private isInputValid(): boolean {
    return this.attributeValidationService.checkAllEntityAttributeValuesValidated();
  }

  public onValidityChanged(valid: boolean): void {
    this.isFormValid = valid;
  }

  public onDescriptionValidityChange(descriptionValid: boolean): void {
    this.isDescriptionValid = descriptionValid;
  }

  private areStringsValid(): boolean {
    let checkName: boolean = true;
    if(this.product.name) {
      checkName = this.product.name.length < 256;
    }
    let checkDescr: boolean = true;
    if(this.product.description) {
      checkDescr = this.product.description.length < 10001;
    }

    return checkName && checkDescr;
  }
}
