
import { ChangeDetectorRef, Component, Inject, Input, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { ComposerProductPageRequestInterface } from "src/app/model/composer/composer-product-page-request-interface";
import { DisplayItemInterface } from "src/app/model/marketplace/display-item-interface";
import { MarketplaceService } from "src/app/services/dataServices/marketplace-service/marketplace.service";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { UPDATE_DELAY_IN_MS } from "src/environments/constants";
import { FilterEntry } from "src/app/components/composer/composer-product-filter-element/composer-product-filter-element.component";

@Component({
  selector: 'app-dialog-add-product-to-baustelle',
  templateUrl: './dialog-add-product-to-baustelle.component.html',
  styleUrls: ['./dialog-add-product-to-baustelle.component.css']
})
export class DialogAddProductToBaustelleComponent implements OnInit {
  @Input()
  public serverId: any;
  public data: AddProductToNodeMockData = {
    item: {
      name: "TC-Item Architekt",
      format: ".ifc",
      ptgSpecifications: ["CAFM", "AVA"],
      organisationSpecifications: ["adesso"],
    },
    description:
      "Dies ist der Architekt Knoten. Unser Architekt soll sich hier für die aktuellsten Änderungen am CAD Model einloggen, so dass die Planung für Elektrik und das Heizkonzept auf dem Model arbeiten können. <br><br>Wir arbeiten mit dem IFC Standard. Vorlage für das CAS Model ist das GeoProfil vom Standort.<br>@Hans: Bitte stell hier deine Software ein!",
    inputItems: [
      {
        name: "GeoProfil",
        format: ".gml",
        ptgSpecifications: ["GML"],
        organisationSpecifications: ["RIB"],
      },
    ],
    outputItems: [
      {
        name: "Elek. Planung",
        format: ".ifc",
        ptgSpecifications: ["CAFM"],
        organisationSpecifications: ["PB4.0"],
      },
      {
        name: "Wärme Planung",
        format: ".ifc",
        ptgSpecifications: ["CDE"],
        organisationSpecifications: ["adesso"],
      },
    ],
  };

  public availablePTGs: FilterEntry[] = [];

  public selectedPTGs: FilterEntry[] = [];

  public availableOrgas: FilterEntry[] = [];

  public changeDetected: boolean;

  //Node.JS:Timer
  private updateDelay: any;

  private selectedPTG: FilterEntry[] = [];
  private selectedOrga: FilterEntry[] = [];

  private pageIndex = 0;
  private pageSize = 10;

  public onlyCertified = false;

  public displayItem: DisplayItemInterface[] = [];
  public selectedItem: DisplayItemInterface = undefined;

  public searchText: string = "";
  public searchTextSubject = new Subject<string>();

  public toolchainItemName: string = "";
  public toolchainItemDescription: string = "";

  get totalNrOfItems(): number {
    return this.marketplaceService.totalNrOfItems;
  }

  constructor(
    private marketplaceService: MarketplaceService,
    private changeDetector: ChangeDetectorRef,
    private ptgService: PtgService,
    private organisationService: OrganisationService,
    public dialogRef: MatDialogRef<DialogAddProductToBaustelleComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.toolchainItemName = dialogData.toolchainItemName;
    this.toolchainItemDescription = dialogData.toolchainItemDescription;
  }

  ngOnInit() {
    this.loadDisplayItems();

    this.searchTextSubject.subscribe((_) => {
      this.changeDetected = true;
      // ######################################################
      // this sets a delay timer that is "restarted" everytime
      // there is a new update request send to the display.
      // this prevents unnecessary backend request when
      // the filter changes to quickly f.i. when typing a
      // searchterm or toggling filter icons quickly
      // ######################################################
      if (this.updateDelay) clearTimeout(this.updateDelay);
      this.updateDelay = setTimeout(() => {
        this.pageIndex = 0;
        this.loadDisplayItems();
        this.changeDetected = false;
      }, UPDATE_DELAY_IN_MS); // 500ms
    });

    this.ptgService.getAllPtgs().then((success) => {
      if (success) {
        this.availablePTGs = this.ptgService.collection.map((ptgInterface) => {
          return {
            name: ptgInterface.name,
            id: ptgInterface.id,
          };
        });
      }
    });

    this.organisationService
      .getAllOrganisationOverviewsWithActiveProducts(false)
      .subscribe((success) => {
        const ids = Object.keys(success);
        const orgas: FilterEntry[] = [];
        ids.forEach((id) => {
          orgas.push({
            id: parseInt(id),
            name: success[id],
          });
        });
        this.availableOrgas = orgas;
      });
  }

  /* Updates the selected PTGs Filter */
  public updateSelectedPTGs(list: FilterEntry[]) {
    this.selectedPTG = list;
    this.pageIndex = 0;
    this.loadDisplayItems();
  }

  /* Updates the selected Orgas Filter */
  public updateSelectedOrgas(list: FilterEntry[]) {
    this.selectedOrga = list;
    this.pageIndex = 0;
    this.loadDisplayItems();
  }

  /* 
    Updates the selected Product
    Removes the highlight from the current Item 
    and adds it to the new selected Item

    Does not add the Highlight if the Item was already selected
  */
  private updateSelectedProduct(product: DisplayItemInterface) {
    if (this.selectedItem) {
      const id = this.displayItem.indexOf(this.selectedItem);
      document
        .getElementById("displayItem" + id)
        .classList.remove("highlighted");
    }

    if (this.selectedItem === product) {
      this.selectedItem = undefined;
    } else {
      this.selectedItem = product;
      const newId = this.displayItem.indexOf(this.selectedItem);
      if (newId !== -1) {
        document
          .getElementById("displayItem" + newId)
          .classList.add("highlighted");
      }
    }
  }

  private repaintHighlightedIfStillVisible() {
    if (
      this.selectedItem &&
      this.displayItem.find((item) => item.id === this.selectedItem.id)
    ) {
      this.selectedItem = this.displayItem.find(
        (item) => item.id === this.selectedItem.id
      );
      const id = this.displayItem.indexOf(this.selectedItem);
      document.getElementById("displayItem" + id).classList.add("highlighted");
    } else {
      this.selectedItem = undefined;
    }
  }

  /* Triggers the loading of the next Items */
  public onScroll() {
    if (this.displayItem.length < this.totalNrOfItems) {
      this.pageIndex++;
      this.loadNextDisplayPage();
    }
  }

  /* Updates the Searchtext */
  public receiveSearchText() {
    this.searchTextSubject.next(this.searchText);
  }

  /* Updates the Checkbox Value */
  public receiveCertifiedCheckboxValueChange(event: MatCheckboxChange) {
    this.onlyCertified = event.checked;
    this.pageIndex = 0;
    this.loadDisplayItems();
  }

  private loadDisplayItems() {
    //-----------------------------------------------------------
    // the createFunction handles the filter
    const dtoPageRequest: ComposerProductPageRequestInterface = this.createPageRequest();
    // the marketplaceService handles the right api
    let request = this.marketplaceService.getFilteredSliceForBaustelle(
      dtoPageRequest
    );

    this.marketplaceService
      .getDisplayItems(request)
      .then((displayItem: DisplayItemInterface[]) => {
        this.displayItem = displayItem;
        if (!this.changeDetector["destroyed"]) {
          this.changeDetector.detectChanges();
        }
        this.repaintHighlightedIfStillVisible();
      });
  }
  private loadNextDisplayPage() {
    //-----------------------------------------------------------
    // the createFunction handles the filter
    const dtoPageRequest: ComposerProductPageRequestInterface = this.createPageRequest();
    // the marketplaceService handles the right api
    let request = this.marketplaceService.getFilteredSliceForBaustelle(
      dtoPageRequest
    );

    this.marketplaceService
      .getDisplayItems(request)
      .then((displayItem: DisplayItemInterface[]) => {
        this.displayItem = this.displayItem.concat(displayItem);
        if (!this.changeDetector["destroyed"]) {
          this.changeDetector.detectChanges();
        }
        this.repaintHighlightedIfStillVisible();
      });
  }

  private createPageRequest(): ComposerProductPageRequestInterface {
    return {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      organisationIds: this.selectedOrga.map((orga) => orga.id),
      ptgIds: this.selectedPTG.map((ptg) => ptg.id),
      searchTerm: this.searchText,
      onlyCertified: this.onlyCertified,
    };
  }

  /* Closes the Dialog without any Changes */
  public cancel() {
    this.dialogRef.close();
  }

  /* Closes the Dialog and returns the currently selected Product */
  public save() {
    this.dialogRef.close({
      productName: this.selectedItem.name,
      productId: this.selectedItem.id,
      productFileId: this.selectedItem.fileId,
    });
  }

  /* Removes the selected Product and closes the ProductOverview Panel with that */
  public collapseProductOverview() {
    this.updateSelectedProduct(undefined);
  }
}

export class AddProductToNodeMockData {
  item: TcItem;
  description: string;
  inputItems: TcItem[];
  outputItems: TcItem[];
}

export class TcItem {
  name: string;
  format: string;
  ptgSpecifications: string[];
  organisationSpecifications: string[];
}
