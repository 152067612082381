import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { PtgInterface } from "../../../model/ptgs/ptg-interface";

import { PtgVersionInterface } from "../../../model/ptgs/ptg-version-interface";
import { VariantVersionInterface } from "../../../model/products/variant-version-interface";
import { Status } from "src/app/model/enums/status";
import { ProductService } from "src/app/services/dataServices/product-service/productService";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { AttributeValidationServiceService } from "src/app/services/dataServices/dataValidation/attribute-validation-service/attribute-validation-service.service";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { MatSelectChange } from "@angular/material/select";
import { SortMethod } from 'src/app/model/enums/SortMethod';
import { PtgPageInterface } from 'src/app/model/ptgs/ptg-page-interface';

@Component({
  selector: "app-add-ptg-dependency-panel",
  templateUrl: "./add-ptg-dependency-panel.component.html",
  styleUrls: ["./add-ptg-dependency-panel.component.css"],
})
export class AddPtgDependencyPanelComponent implements OnInit {
  @Input()
  version: VariantVersionInterface | CertificationVariantVersionInterface;

  @Input()
  isCertification?: boolean;

  @Output()
  addedPtgVersion = new EventEmitter<PtgVersionInterface>();

  public addPtgDependencyMode: boolean = false;
  private selectedPtg: PtgInterface;
  private selectedPtgVersion: PtgVersionInterface;
  public ptgWithActiveVersions: PtgPageInterface;
  public totalNumberOfElements: number;
  public pageIndex: number = 0;

  constructor(
    public ptgService: PtgService,
    public productService: ProductService,
    public certificationService: CertificationService,
    private cd: ChangeDetectorRef,
    private attributeValidationService: AttributeValidationServiceService
  ) {}

  filterActiveVersionsOfSelectedPtg(): PtgVersionInterface[] {
    if (this.selectedPtg) {
      const versions = this.selectedPtg.versions;
      return versions.filter((version) => {
        return version.status == Status.ACTIVE;
      });
    } else {
      return [];
    }
  }

  onToggle(event) {
    this.addPtgDependencyMode = event;
   if(this.addPtgDependencyMode)
    {
      if(!this.cd["destroyed"]){
        this.cd.detectChanges();
      }
    }
  }

  pageChanged(event) {
    this.ptgWithActiveVersions = null;
    if(event.previousPageIndex > event.pageIndex){
     this.pageIndex = this.pageIndex - 5;
     this.ptgService.getPtgSliceActVer(this.pageIndex, 5, "", SortMethod.ALPHABETICAL_ASC).subscribe((ptgArray : PtgPageInterface) => {
       this.ptgWithActiveVersions = ptgArray;
       this.cd.detectChanges();
     });
    } else {
      this.pageIndex = this.pageIndex + 5;
      this.ptgService.getPtgSliceActVer(this.pageIndex, 5, "", SortMethod.ALPHABETICAL_ASC).subscribe((ptgArray : PtgPageInterface) =>  {
          this.ptgWithActiveVersions = ptgArray;
          this.cd.detectChanges();
      });
    }
  }

  onPtgSelected(event: MatSelectChange): void {
    const ptg = event.value;
    this.selectedPtg = ptg;

    this.selectedPtgVersion = undefined;
  }

  addPtgVersion(ptg: PtgInterface): void {
    let newVersion: PtgVersionInterface = ptg.versions[0];

    this.cd.detectChanges();
    this.selectedPtgVersion = newVersion;
    const containsSelectedVersion = this.version.ptgVersions.some((version) => {
      return version.id == newVersion.id;
    });
    if (!containsSelectedVersion) {
      this.version.ptgVersions.push(newVersion);
      this.version.ptgVersionIds.push(newVersion.id);
      newVersion.attributeValidations.forEach((attributeValidation) => {
        const attribute = attributeValidation.attribute;
        if (
          this.attributeValidationService.activeEntityFeatureValues.get(
            attribute.id
          ) != null
        ) {
          this.attributeValidationService.attributesValidated.set(
            attribute.id,
            false
          );
        }
        this.attributeValidationService.activeEntityFeatureValues.set(
          attribute.id,
          null
        );
      });
      this.addedPtgVersion.emit(newVersion);
    }
    this.selectedPtg = undefined;
  }

  checkIfNoPTGs(): boolean {
    return !(this.ptgService.collection.length === 0);
  }

  ngOnInit(): void {
  this.ptgService.getPtgSliceActVer(this.pageIndex, 5, "", SortMethod.ALPHABETICAL_ASC).subscribe((ptgArray : PtgPageInterface) =>
  this.ptgWithActiveVersions = ptgArray
    );
  }
}
