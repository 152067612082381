import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";

import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { ProductService } from "src/app/services/dataServices/product-service/productService";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import {
  API_BASE_PRODUCTSERVICE,
  API_MAP,
  API_BASE_FILESERVICE,
} from "src/environments/api";
import { ProductVariantService } from "src/app/services/dataServices/product-variant-service/product-variant.service";
import { MalfunctionService } from "src/app/services/malfunction-service/malfunction.service";
import { CertificationInterface } from "src/app/model/certifications/certification-interface";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { CertificationVariantService } from "src/app/services/dataServices/certification-variant-service/certification-variant-service";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";
import { UserService } from "src/app/services/user-service/userservice";
import { CommonFeatureService } from "src/app/services/common-feature-service/common-feature.service";
import { ProductInterface } from 'src/app/model/products/product-interface';
import { ProductVariantInterface } from 'src/app/model/products/product-variant-interface';

@Component({
  selector: "app-info-box",
  templateUrl: "./info-box.component.html",
  styleUrls: ["./info-box.component.css"],
})
export class InfoBoxComponent implements OnInit, OnDestroy {
  @Input()
  product: ProductInterface;

  @Input()
  certification: CertificationInterface;

  @Input()
  variant: ProductVariantInterface;

  @Input()
  certificationVariant: CertificationVariantInterface;

  public productPictureUrl: string;
  public productDescription: string;
  public variantDescription: string;

  public certificationPictureUrl: string;
  public certificationDescription: string;
  public certificationVariantDescription: string;

  private activeRouteSub: Subscription;
  private activeProductSub: Subscription;
  private activeCertificationSub: Subscription;

  showPictures: boolean = false;

  isPlattformAdmin: boolean;
  hasActiveProductVersion: boolean;

  constructor(
    public router: Router,
    public productService: ProductService,
    public organisationService: OrganisationService,
    public variantService: ProductVariantService,
    private malfunctionService: MalfunctionService,
    private activeRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private dialogService: DialogService,
    public certificationService: CertificationService,
    public certificationVariantService: CertificationVariantService,
    private userService: UserService,
    private commonFeatureService: CommonFeatureService,
  ) {}

  editProduct(): void {
    this.router.navigate([
      "/organisations/" +
        this.organisationService.activeOrganisation.organisationId +
        "/products/" +
        this.productService.activeProduct.id +
        "/edit",
    ]);
  }

  editCertification(): void {
    this.router.navigate([
      "/organisations/" +
        this.organisationService.activeOrganisation.organisationId +
        "/certifications/" +
        this.certificationService.activeCertification.id +
        "/edit",
    ]);
  }

  deleteProduct(): void {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie das Produkt wirklich löschen?",
        "Abbrechen",
        "Löschen"
      )
      .subscribe(
        (result) => {
          if (result) {
            this.productService
              .deleteProduct(this.productService.activeItem)
              .then(() => {
                this.router.navigate([
                  "organisations/" +
                    this.organisationService.activeOrganisation.organisationId +
                    "/products",
                ]);
                this.dialogService.openDialog("Das Produkt wurde erfolgreich gelöscht", "");
              });
          }
        },
        (e) => {}
      );
  }

  deleteCertification(): void {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie die Zertifizierung wirklich löschen?",
        "Abbrechen",
        "Löschen"
      )
      .subscribe(
        (result) => {
          if (result) {
            this.certificationService
              .deleteCertification(this.certificationService.activeItem)
              .then(() => {
                this.router.navigate([
                  "organisations/" +
                    this.organisationService.activeOrganisation.organisationId +
                    "/certifications",
                ]);
              });
          }
        },
        (e) => {
          // this.dialogService.openDialog(e.errorTitle, e.errorText);
        }
      );
  }
  registerSsoClient(): void {
    this.router.navigate([
      "/organisations/" +
        this.organisationService.activeOrganisation.organisationId +
        "/products/" +
        this.productService.activeProduct.id +
        "/sso",
    ]);
  }

  showMalfunctionManagement(): void {
    this.router.navigate([
      "/organisations/" +
        this.organisationService.activeOrganisation.organisationId +
        "/products/" +
        this.productService.activeProduct.id +
        "/malfunctionManagement",
    ]);
  }

  ngOnInit(): void {
    this.isPlattformAdmin = this.userService.isUserAdminOfOrganisationOrPlattformAdmin(
      this.organisationService.activeItem.organisationId
    );


    if (this.certification) {
      this.activeCertificationSub = this.certificationService.activeItemObserver.subscribe(
        (certification) => {
          this.showPictures = false;

          if (certification) {
            this.certification = certification;
            this.certificationDescription = this.certification.description;

            if (certification.pictureId) {
              this.certificationPictureUrl = this.certificationService.getCertificationPicture(
                certification.id
              );
            } else {
              this.certificationPictureUrl = environment.defaultPictureProducts;
            }

            if (this.certification.galleryPictureIds.length > 0) {
              if (this.certification.galleryPictureIds) {
                for (let pictureId of this.certification.galleryPictureIds) {
                  var url = API_BASE_FILESERVICE + "/file/" + pictureId;
                }
              }
            }
          }
        }
      );
    }

    this.activeProductSub = this.productService.activeItemObserver.subscribe(
      (product) => {
        this.showPictures = false;
        if (product && product.pictureId) {
          this.productPictureUrl = this.productService.getProductPicture(
            product.id
          );
        } else {
          this.productPictureUrl = environment.defaultPictureProducts;
        }

        if (this.product) {
          this.activeProductSub = this.productService.activeItemObserver.subscribe(
            (product) => {
              this.showPictures = false;
              if (product) {
                this.product = product;
                this.productDescription = this.product.description;
                this.hasActiveProductVersion = this.productService.checkIfProductHasActiveVersion(
                  this.product
                );

                if (product.pictureId) {
                  this.productPictureUrl = this.productService.getProductPicture(
                    product.id
                  );
                } else {
                  this.productPictureUrl = environment.defaultPictureProducts;
                }

                if (this.product.galleryFileIds.length > 0) {
                  if (this.product.galleryFileIds) {
                    for (let pictureId of this.product.galleryFileIds) {
                      var url = API_BASE_FILESERVICE + "/file/" + pictureId;
                    }
                  }
                }
              }
            }
          );
        }
        if (!this.cd["destroyed"]) {
          this.cd.detectChanges();
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.activeRouteSub) {
      this.activeRouteSub.unsubscribe();
    }
    if (this.activeProductSub) {
      this.activeProductSub.unsubscribe();
    }
    if (this.activeCertificationSub) {
      this.activeCertificationSub.unsubscribe();
    }
  }
}
