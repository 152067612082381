import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import * as go from 'gojs';

@Component({
  selector: 'app-bpmn-diagram',
  template: '<div #diagramDiv class="BPMNDiagram" style="height: 20vh"></div>',
  styleUrls: ['./bpmn-diagram.component.css']
})
export class BpmnDiagramComponent implements AfterViewInit {
  @ViewChild('diagramDiv', { static: true }) diagramDiv!: ElementRef;
  @Input() xml: string = '';

  private diagram!: go.Diagram;

  constructor() { }

  ngAfterViewInit(): void {
    this.renderDiagram();
  }

  renderDiagram(): void {
    try {
      const $ = go.GraphObject.make;
  
      const diagram = $(go.Diagram, this.diagramDiv.nativeElement, {
        'undoManager.isEnabled': true
      });
  
      diagram.nodeTemplateMap.add("manualTask",
        $(go.Node, "Spot", { locationSpot: go.Spot.Center },
          $(go.Panel, "Auto",
            $(go.Shape, "RoundedRectangle", { name: "SHAPE_NAME", fill: "lightblue", stroke: "black" }),
            $(go.TextBlock, { margin: 8, editable: true },
              new go.Binding("text", "text").makeTwoWay())
          )
        )
      );
  
      diagram.nodeTemplateMap.add("startEvent",
        $(go.Node, "Spot", { locationSpot: go.Spot.Center },
          $(go.Shape, "Circle", { fill: "lightgreen", stroke: "black", desiredSize: new go.Size(25, 25) }),
          $(go.TextBlock, { margin: 8, editable: true },
            new go.Binding("text", "text").makeTwoWay())
        )
      );
  
      diagram.nodeTemplateMap.add("endEvent",
        $(go.Node, "Spot", { locationSpot: go.Spot.Center },
          $(go.Shape, "Circle", { fill: "white", strokeWidth: 3, stroke: "black", desiredSize: new go.Size(25, 25) }),
          $(go.Shape, "Circle", { fill: "white", stroke: "black", desiredSize: new go.Size(20, 20) }),
          $(go.TextBlock, { margin: 8, editable: true },
            new go.Binding("text", "text").makeTwoWay())
        )
      );
  
      diagram.linkTemplate =
        $(go.Link,
          { routing: go.Link.AvoidsNodes, corner: 5, fromSpot: go.Spot.Right, toSpot: go.Spot.Left },
          $(go.Shape, { stroke: "black" })
        );
  
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(this.xml, 'text/xml');
      if (!xmlDoc) {
        console.error("Failed to parse XML.");
        return;
      }
  
      // Parsing successful, continue building the diagram
      const startEvent = xmlDoc.getElementsByTagName('bpmn:startEvent');
      const endEvent = xmlDoc.getElementsByTagName('bpmn:endEvent');
      const manualTasks = xmlDoc.getElementsByTagName('bpmn:manualTask');
      const sequenceFlows = xmlDoc.getElementsByTagName('bpmn:sequenceFlow');
  
      const model = $(go.GraphLinksModel);
  
      const nodeDataArray = [];
      const linkDataArray = [];
  
      nodeDataArray.push({ key: startEvent[0].getAttribute('id'), category: "startEvent", readyState: "initial" });
      for (let i = 0; i < manualTasks.length; i++) {
        const node = manualTasks[i];
        const taskId = node.getAttribute('id');
        const taskName = node.getAttribute('name');
        nodeDataArray.push({ key: taskId, category: "manualTask", text: taskName, readyState: "initial" });
      }
      nodeDataArray.push({ key: endEvent[0].getAttribute('id'), category: "endEvent", readyState: "initial" });
  
      // Create links for sequence flows
      for (let i = 0; i < sequenceFlows.length; i++) {
        const sequenceFlow = sequenceFlows[i];
        const sourceId = sequenceFlow.getAttribute('sourceRef');
        const targetId = sequenceFlow.getAttribute('targetRef');
        linkDataArray.push({ from: sourceId, to: targetId });
      }
  
      model.nodeDataArray = nodeDataArray;
      model.linkDataArray = linkDataArray;
      diagram.model = model;
  
      diagram.layout = new go.TreeLayout();
      diagram.commandHandler.zoomToFit();
      this.diagram = diagram;
      window.addEventListener('resize', () => this.handleWindowResize());
    } catch (error) {
      console.error("Error rendering diagram:", error);
    }
  }
  

@HostListener('window:resize')
handleWindowResize(): void {
  if (this.diagram) {
    this.diagram.commandHandler.zoomToFit();
  }
}

updateNodeStyle(task: any): void {
  const node = this.diagram.findNodeForKey(task.id);
  if (!node) return;

  const shape = node.findObject("SHAPE_NAME") as go.Shape | null;
  if (!shape) return;

  switch (task.readyState) {
      case 'start':
          shape.fill = "lightyellow";
          break;
      case 'finished':
          shape.fill = "lightgreen";
          break;
      case 'failed':
          shape.fill = "red";
          break;
      default:
          shape.fill = "lightgrey"; // Default color
          break;
  }
}


}
