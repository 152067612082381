import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectorRef, SimpleChanges, OnDestroy } from "@angular/core";
import { ProductInterface } from "src/app/model/products/product-interface";
import { MatFormFieldControl } from "@angular/material/form-field";
import { VariantVersionInterface } from "src/app/model/products/variant-version-interface";
import { PtgVersionInterface } from "src/app/model/ptgs/ptg-version-interface";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";

import { PtgAttributeValidationInterface } from "src/app/model/attributes/ptg-attribute-validation-interface";
import { AttributeInterface } from "src/app/model/attributes/attribute-interface";
import { indexOf } from "lodash";
import { UtilService } from "src/app/services/util-service/utilService";
import { CommonFeatureService } from 'src/app/services/common-feature-service/common-feature.service';
import { CommonFeatureInterface } from 'src/app/model/commonFeature/common-feature-interface';
import { ProductCommonFeatureInterface } from 'src/app/model/commonFeature/product-common-feature-interface';
import { CertificationInterface } from 'src/app/model/certifications/certification-interface';
import { createNonNullChain } from 'typescript';
import { ProductVariantVersionCreateScreenComponent } from 'src/app/components/product-certification/products/product-variant-version-create-screen/product-variant-version-create-screen.component';
import { ServerPortainerService } from "../server-portainer.service";
import { ProductService } from "src/app/services/dataServices/product-service/productService";
import { PORTAINER_AUTH } from "src/environments/api";

export interface PeriodicElement {
  name: string;
  value: boolean;
}

@Component({
  selector: 'app-server-detail-display-docker',
  templateUrl: './server-detail-display-docker.component.html',
  styleUrls: ['./server-detail-display-docker.component.css',
    "../product-detail-display.css",
  ],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ServerDetailDisplayDockerComponent,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})


export class ServerDetailDisplayDockerComponent implements OnInit, OnDestroy {
  @Input()
  product: ProductInterface;

  @Input()
  certification: CertificationInterface;

  private ptgVersions: PtgVersionInterface[] = [];
  public commonFeatureList: ProductCommonFeatureInterface[] = [];
  public commonFeatureNameList: ProductCommonFeatureInterface[][];
  @Input()
  ptg_names: string[] = [];
  version: VariantVersionInterface;
  @Input() licenseModels: string[];
  productFeatures: string[];
 // public showComplete: boolean = false;
  public selectedVersionAttributeValues: {
    name: string;
    optional: boolean;
    attributeName: string;
    value: any;
  }[];
  private hasMoreThatThreeLinesOfDescription: boolean = true;
  endpoints: any[];
  displayedColumnsForAttributes: string[] = ['name','optional', 'merkmal', 'value'];
  displayedColumnsForCommonFeatures: string[] = ['feature', 'value'];

  public productFeatureToggle: boolean = true;
  public ptgToggle: boolean = true;
  public commonFeaturesToggle: boolean = true;
  public attributeToggle: boolean = true;


  loginAndFetchEndpoints(): void {
          this.endpointService.setAuthToken(PORTAINER_AUTH);
          this.getEndpoints();
  }

  getEndpoints(): void {
    this.productService.getBaustelleByProductId(this.product.id).subscribe(
      data =>
        this.endpointService.getEndpointById(data.portainerId)
          .subscribe(
            endpoints => {
              console.log(endpoints);
            this.endpoints = endpoints;
            },
        error => {
          console.error('Failed to fetch endpoints:', error);
        }
      ));
  }

  getValidationValue(attribute: PtgAttributeValidationInterface): string {
    let validationValue = "";
    let validationValues = this.version.attributeValidationValues as {
      attribute: AttributeInterface;
      value: string;
    }[];

    let validationValueIndex = validationValues.findIndex((x) => {
      return x.attribute.id == attribute.attribute.id;
    });

    if (validationValueIndex >= 0) {
      let item = validationValues[validationValueIndex];
      if (item != null) {
        validationValue = item.value;
      }
    }
    return this.utilService.getTransformedAttributeValue(
      attribute.attribute.datatype,
      validationValue
    );
  }

  getPtgName(ptg: PtgVersionInterface): string {
    let ptgIndex = this.ptgVersions.lastIndexOf(ptg);

    if(ptgIndex < 0 || !this.ptg_names){
      return "no name";
    }
    return this.ptg_names[ptgIndex];
  }

  public onVersionSelected(version: VariantVersionInterface): void {
    this.version = version;
    this.updatePTGlist(version);
    this.updateVersionAttributes(version);


   // this.commonFeaturesToggle = (this.commonFeatureList != undefined) ? true : false;
    this.attributeToggle = (this.selectedVersionAttributeValues.length > 0) ? true : false;
    this.productFeatureToggle = (this.productFeatures.length > 0) ? true : false;
    this.ptgToggle = (this.ptgVersions.length > 0) ? true : false;

    this.cd.detectChanges();
  }

  private updatePTGlist(version: VariantVersionInterface): void {
    this.ptgVersions = version.ptgVersions;
    this.version = version;
    this.ptg_names = version.productTypeNames;
  }

  private updateVersionAttributes(version: VariantVersionInterface): void {
    this.checkIfDescriptionContainsMoreThanThreeLines(version);
    this.selectedVersionAttributeValues = [];
      this.product.productVariants.forEach((prodVar) => {
        prodVar.variantVersions.forEach((varVer) => {
          if(varVer.id == version.id) {
          varVer.ptgVersions.forEach((ptgVer) => {
            ptgVer.attributeValidations.forEach((attributeVal) => {
              var attribute = {
                name: ptgVer.ptgName,
                attributeName: attributeVal.attribute.name,
                value: this.getValidationValue(attributeVal),
                optional: attributeVal.optionalValidation
              }
              if(attribute.name != null)
              {
              this.selectedVersionAttributeValues.push(attribute)
              }
            })
          })
        }
         /*
          varVer.attributeValidationValues.forEach(element => {
            let isPresent: boolean = false;
            var attribute = {
              name: "",
              attributeName: element.attribute.name,
              value: element.value,
              optional: false
            }
            this.selectedVersionAttributeValues.forEach((selectedVersionAttribute) => {
              if(selectedVersionAttribute.attributeName == attribute.attributeName)
                isPresent = true;
              })
            if(!isPresent)
              this.selectedVersionAttributeValues.push(attribute);
          }); */
        })
      })


     // this.selectedVersionAttributeValues.push(versionAttribute);

    this.cd.detectChanges();
  }
  public commonFeatureListTemp : String[] = [];
  constructor(
    private ptgService: PtgService,
    private utilService: UtilService,
    private commonFeatureService: CommonFeatureService,
    private cd: ChangeDetectorRef,
    private endpointService: ServerPortainerService,
    private productService: ProductService,
  ) {

  }
  ngOnDestroy(): void {
   // throw new Error('Method not implemented.');
  }

  ngOnChanges(products: SimpleChanges) {
    this.commonFeatureNameList = [];
    this.loginAndFetchEndpoints();
    if(products != null)
    {
      this.product = products.product.currentValue;
    }
    if(this.product.productVariants != null)
    {
      this.product.productVariants.map((x) => {
        this.version = x;
      })

      this.productFeatures = this.product.features;
      this.commonFeatureService.getFeaturesForProduct(this.product.id).subscribe((success: ProductCommonFeatureInterface[]) => {
        this.commonFeatureList = success.filter(productCommonFeature => productCommonFeature.feature.showDetailPage);
        this.commonFeatureList.forEach((x) => {
          if(!this.commonFeatureListTemp?.includes(x.feature.name))
            this.commonFeatureListTemp?.push(x.feature.name);
        })

        let j = 0;
        this.commonFeatureListTemp.forEach((x) => {
          this.commonFeatureNameList[j] = [];
          j++;
        })
        let i = 0;
        let compareName = this.commonFeatureList[0]?.feature.name;
        for(let n = 0; n < this.commonFeatureList.length; n++)
        {
          if(n == 0)
          this.commonFeatureNameList[i].push(this.commonFeatureList[n]);
          else
          {
            if(this.commonFeatureList[n].feature.name == this.commonFeatureList[n-1].feature.name)
            {
              this.commonFeatureNameList[i].push(this.commonFeatureList[n])
            } else
            {
              i++;
              this.commonFeatureNameList[i].push(this.commonFeatureList[n])
            }
          }
        }
        this.commonFeaturesToggle = (this.commonFeatureList?.length > 0) ? true : false;
      });

   }

  }

  ngOnInit() {
    this.loginAndFetchEndpoints();
  }



  private checkIfDescriptionContainsMoreThanThreeLines(version: VariantVersionInterface): void {
    const description = version.description;
    if (
      description == null ||
      (description.length < 200 &&
        this.containsFourOrLessParagraphs(description))
    ) {
      this.hasMoreThatThreeLinesOfDescription = false;
    } else {
      this.hasMoreThatThreeLinesOfDescription = true;
    }
  }

  /**
   * Checks whether a description string contains at most four <p> tags.
   * This matters since each <p> inside the enclosing tag causes a new line, thus 4 <p> tags result in 3 lines of text,
   * which is the amount initially displayed
   */
  private containsFourOrLessParagraphs(description: string): boolean {
    const pCount = (description.match(/<p>/g) || []).length;
    return pCount <= 4;
  }

  sortByName(list) {
    return list.slice().sort((a, b) => a.entry?.name.localeCompare(b.entry?.name));
  }



}
