<div class="editFeature">
  <div style="display: flex; flex-direction: column">
    <mat-form-field
      ><input
        matInput
        name="featureName"
        [id]='"featureName" + index'
        placeholder="Name"
        [value]="name"
      />
      <mat-hint *ngIf="showNameMustBeFilled">
        Der Name muss ausgefüllt sein.
      </mat-hint>
      <mat-hint *ngIf="showAtLeastOneEntry">
        Es muss mindestens eine Auswahlmöglichkeit vorhanden sein.
      </mat-hint>
    </mat-form-field>
    <mat-checkbox
      labelPosition="before"
      [id]='"featureFilterOption" + index'
      [checked]="filterOption"
      >Filter Option</mat-checkbox
    >
    <mat-checkbox
      labelPosition="before"
      [id]='"showDetailOnPage" + index'
      [checked]="showDetailPage"
    >
      Anzeige auf Produktdetailseite
    </mat-checkbox>
    <div>
      <p>Verknüpfungsmethode:<p>
      <mat-checkbox 
        (change)="checkbox.checked = !checkbox.checked; disableCheckboxes(checkbox.id); "
        [checked]="checkbox.checked"
        *ngFor="let checkbox of linkingMethod" 
        style="margin-right: 1.5vw; margin-top:-1vw">
        <a *ngIf="checkbox.linkingMethod == 0"> UND </a>
        <a *ngIf="checkbox.linkingMethod == 1"> ODER </a>
        <a *ngIf="checkbox.linkingMethod == 2"> EXKLUSIV-ODER </a>
      </mat-checkbox>
    </div>
  </div>

  <div class="featureEntry">
    <div *ngFor="let entry of tempFeatureEntries; let i = index">
      <mat-form-field>
        <mat-label> Auswahlmöglichkeit {{ i + 1 }} </mat-label
        ><input
          matInput
          [name]="i"
          [id]="'featureEntry' + index + ',' +  i"
          [value]="entry"
        />
      </mat-form-field>
      <button class="btn_red" mat-raised-button (click)="removeFeatureEntry(i)">
        X
      </button>
    </div>
    <div *ngIf="showFeatureEntry">
      <mat-form-field>
        <mat-label>
          Auswahlmöglichkeit {{ tempFeatureEntries.length + 1 }} </mat-label
        ><input matInput name="featureEntry" [id]='"featureEntry" + index' value="" />
        <mat-hint *ngIf="showEntryFilloutHint">
          Bitte zuerst diese Auswahlmöglichkeit ausfüllen.
        </mat-hint>
      </mat-form-field>
      <button
        class="btn_red"
        mat-raised-button
        (click)="removeNewFeatureEntry()"
      >
        X
      </button>
    </div>
    <button
      class="btn_green"
      mat-raised-button
      (click)="showFeatureEntryForm()"
      id="featureEntryButton"
    >
      Auswahlmöglichkeit hinzufügen
    </button>
  </div>
</div>
