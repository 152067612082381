export const API_BASE_PRODUCTSERVICE: string = "/proxy/api/v0/productservice";
export const API_BASE_USERSERVICE: string = "/proxy/api/v0/userservice";
export const API_BASE_FILESERVICE: string = "/proxy/api/v0/fileservice";
export const API_BASE_TOOLCHAINSERVICE: string = "/proxy/api/v0/toolchainservice";
export const API_BASE_BAUSTELLE: string = "/proxy/api/v0/productservice/products/baustellen";
export const PORTAINER_AUTH = "ptr_qZNBmzyPh4Cgl+zTY8130iZ1qsgTvLbIFEgadZdo3Hc=";

/**
 * :organisationId
 * :productId
 * :variantId
 * :versionId
 * :ptgId
 * :attributeId
 * :pictureId
 * :userId
 */
export const API_MAP: { [id: string]: { [id: string]: string } } = {
  users: {
    MEMBERSHIPS: "/users/memberships",
    NAME: "/users/:userId/name",
    PUBLIC_PROFILE: "/users/:username/public",
    LOGGEDIN_PROFILE: "/users/:username/loggedin",
    PROJECTS: "/users/projects"
  },

  attributes: {
    POST: "/attributes/:organisationId",
    GET: "/attributes/:attributeId",
    PUT: "",
    ALL: "/attributes",
    FILTER: "/attributes/filter",
    // Gives all attributes referenced in all active products
    ACTIVE: "/attributes/products",
  },

  members: {
    ALL: "/organisations/:organisationId/members",
    POST: "/organisations/:organisationId/member",
    //GET : "/organisations/:organisationId/member",
    PUT: "/organisations/:organisationId/member",
    DELETE: "/organisations/:organisationId/member/:userId",
    ACCEPT: "/organisations/:organisationId/member/:userId",
  },
  organisationPictures: {
    POST: "/organisations/pictures/:organisationId",
    GET: "/organisations/pictures/:organisationId",
    POST_PREVIEW: "/organisations/pictures/preview/:organisationId",
    GET_PREVIEW: "/organisations/pictures/preview/:organisationId",
  },

  organisations: {
    POST: "/organisations",
    PUT: "/organisations",
    // Gives all organisations
    ALL: "/organisations",
    GET: "/organisations/:organisationId",
    // Gives the name of an organisation
    NAME: "/organisations/:organisationId/name",
    DELETE: "/organisations/:organisationId",
    // Gives all organisations that have an active product
    ACTIVE: "/organisations/active",
    GET_BY_DOMAIN: "/organisations/by?domain=:organisationDomain",
    GET_BY_NAME: "/organisations/by?name=:organisationName",
    FILTER: "/organisations/filter",
    EXTENDED: "/organisations/filter/extended",
    HIGHLIGHTED: "/organisations/highlighted",
    MINIMAL: "/organisations/minimal",
  },


  baustelle: {

  },
  
  PTGs: {
    POST: "/producttypegroups/:organisationId",
    GET: "/producttypegroups/:ptgId",
    PUT: "/producttypegroups/:ptgId/:organisationId",
    FILTER: "/producttypegroups/filter",
    EXTENDED: "/producttypegroups/filter/extended",
    FILTER_ATTRIBUTE: "/producttypegroups/filter/attributes",
    // Gives all PTGs that are referenced by an active product
    ACTIVE: "/producttypegroups/products",
    ALL: "/producttypegroups",
    ASSIGNED: "/producttypegroups/isAssignedToProductOrCertification"
  },

  SSO: {
    // sets status of SSO-Client for a product
    PUT: "/products/sso/:productId",
  },
  documents: {
    POST: "/documents/:entityId",
    GET: "/documents/:documentId",
    ALL: "/documents/products/:productId",
    ALL_CERT: "/documents/certifications/:certificationId",
    DELETE: "/documents/:documentId/:organisationId",
  },

  pictureGallery: {
    POST: "/pictures/gallery/:entityId",
    // Updates the picture ids that belong to a product
    PUT: "/pictures/galleryPictureIds/:entityId",
    GET: "/pictures/gallery/:pictureId",
    // Gives all pictures of a product
    ALL: "/pictures/galleryPictureIds/:entityId",
    DELETE: "/pictures/gallery/:entityId/:pictureId",
  },

  productPictures: {
    POST: "/pictures/:entityId",
    GET: "/pictures/:entityId",
  },

  variants: {
    PUT: "/variants/:productId",
    POST: "/variants/:productId",
    GET: "/variants/:variantId",
    DELETE: "/variants/:organisationId/:productId/:variantId",
  },

  versions: {
    PUT: "/versions/:productId",
    POST: "/versions/:variantId",
    GET: "/versions/:versionId",
    ALL: "/versions",
    DELETE: "/versions/:organisationId/:variantId/:versionId",
  },

  productFilters: {
    POST: "",
    PTG: "",
  },

  products: {
    PUT: "/products/:productId",
    POST: "/products/:organisationId",
    GET: "/products/:productId",
    ALL: "/products",
    DELETE: "/products/:productId",
    FILTER: "/products/filter",
    FILTER_ONE: "/products/filter/:productId",
    EXTENDED: "/products/filter/extended",
    COMPOSER_FILTER: "/products/composer/filter",
    BAUSTELLE_FILTER: "/products/baustelle/filter",
    HIGHLIGHTED: "/products/highlighted",
    // Gives all products of an organisation
    OF_ORGANISATION: "/products/organisation/:organisationId",
    // Gives all product Overviews of an organisation
    OF_ORGANISATION_OVERVIEW: "/products/organisation/:organisationId/overview",
    // Gives all products with status "ACTIVE"
    OF_ACTIVE: "/products/organisation/active",
    ORGANISATION_WTIH_PTG: "/products/filter/organisations",
    // Gives all ptgs, attributes and orgas that are related to active products for filtering in marketplace
    FILTER_ACTIVE: "/products/filter/active",
    MINIMAL: "/products/minimal",
    // Overview Dto of Certification associated with the product
    CERTIFICATION: "/overviews/{certificationVersionId}/{productId}",
    DEMOREQUEST: "/products/demoRequest",
    OFFERREQUEST: "/products/offerRequest/Produkt",
    LISTINGREQUEST: "/products/listingRequest",
    BAUSTELLEN: "/products/baustellen",
    SOFTWARE: "/products/software",
    CONTENT: "/products/content",
    DIENSTLEISTUNGEN: "/products/dienstleistungen",
    KÜNTSTLICHE_INTELLIGENZ: "/products/ki",    
    ROBOT: "/products/robot",
    EDGE: "/products/edge"

  },

  certificationVariants: {
    PUT: "/certificationvariants/:certificationId",
    POST: "/certificationvariants/:certificationId",
    GET: "/certificationvariants/:certificationVariantId",
    DELETE:
      "/certificationvariants/:organisationId/:certificationId/:certificationVariantId",
  },

  certificationVersions: {
    PUT: "/certificationversions/:certificationId",
    POST: "/certificationversions/:certificationVariantId",
    GET: "/certificationversions/:certificationVariantId",
    ALL: "/certificationversions",
    DELETE:
      "/certificationversions/:organisationId/:certificationVariantId/:certificationVersionId",
  },

  certificationFilters: {
    POST: "",
    PTG: "",
  },

  certificationPictures: {
    POST: "/pictures/:certificationId",
    GET: "/pictures/:certificationId",
  },
  certifications: {
    PUT: "/certifications/:certificationId",
    POST: "/certifications/:organisationId",
    GET: "/certifications/:certificationId",
    ALL: "/certifications",
    DELETE: "/certifications/:certificationId",
    FILTER: "/certifications/filter",
    EXTENDED: "/certifications/filter/extended",
    // Gives all certifications of an organisation
    OF_ORGANISATION: "/certifications/organisation/:organisationId",
    // Gives all certifications with status "ACTIVE"
    OF_ACTIVE: "/certifications/organisation/active",
    ORGANISATION_WTIH_PTG: "/certifications/filter/organisations",
    FILTER_ACTIVE: "/certifications/filter/active",
    HIGHLIGHTED: "/certifications/highlighted",
    OFFERREQUEST: "/certifications/offerRequest/Zertifikat",
  },

  contacts: {
    PUT: "/organisations/:organisationId/contacts",
    POST: "/organisations/:organisationId/contact",
    DELETE: "/organisations/:organisationId/contact/:contactId",
    UPDATE: "/organisations/:organisationId/contact"
  },

  files: {
    GET: "/file/:fileId",
  },

  commonFeatures: {
    ADD: "/commonfeature/add",
    GET: "/commonfeature",
    UPDATE: "/commonfeature/:id/update",
    UPDATE_MULT: "/commonfeature/update",
    DELETE: "/commonfeature/:id/delete",
    PRODUCTADD: "/commonfeature/product/:id/add",
    PRODUCTGET: "/commonfeature/product/:id",
    PRODUCTUPDATE: "/commonfeature/product/:id/update",
    PRODUCTDELETE: "/commonfeature/product/:id/delete"
  },
  fileInfos: {
    GET: "/fileinfo/:fileId",
  },
  composer: {
    CREATE: "/toolchain/createByXML",
    GET: "/toolchain/xml/:toolchainId",
    USER: "/toolchain/user/templates",
    ORGANISATION: "/toolchain/organisation/:organisationId/templates",
    START: "/toolchain/instance/template/:templateId",
    USER_INSTANCES: "/toolchain/user/instances",
    ORGANISATION_INSTANCES: "/toolchain/organisation/:organisationId/instances",
    FILTER: "/toolchain/filter",
    HIGHLIGHTED: "/toolchain/highlighted",
    DETAIL: "/toolchain/:toolchainId/detail",
    OCDE_CONTAINER: "/toolchain/:toolchainId/container",
    FILE: "/cde/containers/:containerId/contents/:contentId/attachment",
    DATATYPE_GET_ALL: "/filetypecde",
    DATATYPE_GET_BY_CDE_PATH:"/filetypecde/:cdePath",
    DATATYPE_SAVE:"/filetypecde/:cdePath/:fileType",
    DELETE_TOOLCHAIN: "/toolchain/instance/:toolchainId",
    DELETE_TEMPLATE: "/toolchain/template/:templateId",
  },
  compatiblity: {
    DATA_FORMAT: "/compatiblityservice/dataformat",
    SCHEMA: "/compatiblityservice/schema",
    DOCUMENT_TYPE: "/compatiblityservice/documenttype"
  },
};
