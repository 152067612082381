import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { UtilService } from "../../../../services/util-service/utilService";
import { VariantVersionInterface } from "../../../../model/products/variant-version-interface";

import { ProductVariantInterface } from "../../../../model/products/product-variant-interface";
import { ProductInterface } from "../../../../model/products/product-interface";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { ProductVariantVersionService } from "src/app/services/dataServices/product-variant-version-service/product-variant-version.service";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { CertificationVariantVersionService } from "src/app/services/dataServices/cetrification-variant-version-service/certification-variant-version-service";
import {ActivatedRoute} from "@angular/router";
import {ActiveVersionPtgInterface} from "../../../../model/ptgs/ActiveVersionPtgInterface";

@Component({
  selector: "app-version-info-box",
  templateUrl: "./version-info-box.component.html",
  styleUrls: ["./version-info-box.component.css"],
})
export class VersionInfoBoxComponent implements OnInit {
  @Input()
  public version:
    | VariantVersionInterface
    | CertificationVariantVersionInterface;

  @Input()
  public variant: ProductVariantInterface;

  @Input()
  public product: ProductInterface;

  @Input()
  public showExternalProductInfo: boolean;

  public previousVersionName: string;

  public activeVersionPtgs: ActiveVersionPtgInterface[] = [];
  public additionalAttributes: any[] = [];

  constructor(
    private utilService: UtilService,
    private ptgService: PtgService,
    private variantVersionService: ProductVariantVersionService,
    private certificationVariantVersionService: CertificationVariantVersionService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(() => {
      this.additionalAttributes = this.version.attributeValidationValues;

      const ptgAttributes: number[] = this.initializeActiveVersionPtgs();

      this.additionalAttributes = this.additionalAttributes.filter(
        (attrValValue) => {
          return !ptgAttributes.includes(attrValValue.attribute.id);
        }
      );
      if (this.version.previousVersion != null) {
        this.previousVersionName = this.getPreviousVersionName();
      }
    });
  }

  private initializeActiveVersionPtgs(): number[] {
    this.activeVersionPtgs = [];
    const ptgAttributes: number[] = [];
    for (const ptgVersion of this.version.ptgVersions) {
      const ptg: ActiveVersionPtgInterface = {
        ptgName: ptgVersion.ptgName,
        ptgVersionNumber: ptgVersion.number,
        ptgVersionAttributes: ptgVersion.attributeValidations,
      };
      ptg.ptgVersionAttributes.forEach((attrValidation) => {
        const attribute = attrValidation.attribute;
        ptgAttributes.push(attribute.id);
      });
      this.activeVersionPtgs.push(ptg);
    }
    return ptgAttributes;
  }

  public getAttrValue(attributeId: number): string {
    const attrValue = this.version.attributeValidationValues.find(
      (attrValValue) => {
        return attrValValue.attribute.id === attributeId;
      }
    );
    if (attrValue != null) {
      return attrValue.value;
    }
  }

  public attrValueNotNull(value: string): boolean {
    return value != null;
  }

  private getPreviousVersionName(): string {
    const versionCollection = this.variantVersionService.collection;
    const previousVersion: CertificationVariantVersionInterface | VariantVersionInterface = versionCollection.find(
      (
        version: CertificationVariantVersionInterface | VariantVersionInterface
      ) => {
        return version.id === this.version.previousVersion;
      }
    );
    if (!previousVersion) {
      return null;
    }
    return previousVersion.name;
  }

  public checkAndTransformBoolValueFromId(attributeId: number): string {
    const value: string = this.utilService.transformAttributeValue(
      this.getAttrValue(attributeId)
    );
    return this.checkAndTransformBoolValue(value);
  }

  public checkAndTransformBoolValue(value: string): string {
    if (value === "true") {
      return "Ja";
    } else if (value === "false") {
      return "Nein";
    } else {
      return value;
    }
  }
}
