import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { Router } from "@angular/router";
import { PtgVersionInterface } from "src/app/model/ptgs/ptg-version-interface";
import { AttributeInterface } from "src/app/model/attributes/attribute-interface";
import { PtgInterface } from "src/app/model/ptgs/ptg-interface";
import { Subscription } from "rxjs";
import { PtgAttributeValidationInterface } from "src/app/model/attributes/ptg-attribute-validation-interface";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { Status } from 'src/app/model/enums/status';
import { ProductInterface } from 'src/app/model/products/product-interface';
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: "app-product-type-group-version-screen",
  templateUrl: "./product-type-group-version-screen.component.html",
  styleUrls: ["./product-type-group-version-screen.component.css"],
})
export class ProductTypeGroupVersionScreenComponent
  implements OnInit, OnDestroy {
  public EDIT_FLAG: boolean = true;

  public version: PtgVersionInterface;
  public ptg: PtgInterface;
  public ptgAttributeValidations: PtgAttributeValidationInterface[] = [];
  public showFeatureCatalogue = false;

  private productListSubscription: Subscription;
  public belongsToProductOrCertification: boolean;
  public allProducts: ProductInterface[];
  public isDescriptionValid: boolean = true;
  public selectedAttributes: AttributeInterface [];

  constructor(
    private ptgService: PtgService,
    private router: Router,
    private organisationService: OrganisationService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private titleService: Title
  ) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');

    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnInit() {
    this.EDIT_FLAG = !this.router.url.toString().includes("create");

    this.ptg = this.ptgService.activeItem;

    if(this.EDIT_FLAG)
    {
      this.titleService.setTitle(this.ptgService.activeItem.name + ": Version " + this.ptgService.activeVersion.id + " PTG Version bearbeiten - BIMSWARM");
    } else if(!this.EDIT_FLAG)
    {
      this.titleService.setTitle(this.ptgService.activeItem.name + " Produkttypversion anlegen - BIMSWARM");
    }




    if (this.ptgService.activeVersion.id) {
      this.version = this.ptgService.activeVersion;
    }
    let latestVersion = Math.max.apply(
      Math,
      this.ptg.versions.map((x) => {
        return x.number;
      })
    );

    if (!this.EDIT_FLAG) {
      const nextVersionNumber = latestVersion + 1;
      this.version = {
        number: nextVersionNumber,
        status: Status.ACTIVE,
        description: "",
        deleted: false,
        attributeValidations: [],
      };
    }

    // This creates a deep copy otherwise we can change the optinal value
    // of the attributeValidations even when canceling because of object persistence
    this.version.attributeValidations.forEach((x) => {
      this.ptgAttributeValidations.push(JSON.parse(JSON.stringify(x)));
    });
    this.selectedAttributes = this.ptgAttributeValidations.map(a => a.attribute);
    this.cd.detectChanges();

    //this.ptgService.isProductTypeGroupAssignedToProductOrCertification(this.version.id) //throws 400
    //.subscribe(res => this.belongsToProductOrCertification = res);
  }

  showFeatureCatalogueToggle(): void {
    this.showFeatureCatalogue = !this.showFeatureCatalogue;
  }

  featureCatalogueConfirmPressed(attributes: AttributeInterface[]): void {
    for (const attribute of attributes) {
      const ptgAttributeValidationContainingAttribute = this.ptgAttributeValidations.find(
        (attributeValidation) => {
          const existingAttribute = attributeValidation.attribute;
          return attribute.id == existingAttribute.id;
        }
      );
      if (ptgAttributeValidationContainingAttribute == null) {
        const newAttributeValidation: PtgAttributeValidationInterface = {
          attribute: attribute,
          optionalValidation: false,
        };
        this.ptgAttributeValidations.push(newAttributeValidation);
      } else {
        const indexOfAttribute = this.ptgAttributeValidations.findIndex(
          (attr) => attr.attribute.id === attribute.id);
        this.ptgAttributeValidations.splice(indexOfAttribute, 1);
      }
    }
    this.showFeatureCatalogueToggle();
  }

  isPtgVersionUsedByProduct(): boolean {
    if (this.allProducts) {
      for (let product of this.allProducts) {
        for (let variant of product.productVariants) {
          for (let version of variant.variantVersions) {
            for (let ptgVersion of version.ptgVersions) {
              if (ptgVersion.id === this.version.id) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }

  saveVersion(): void {
    this.ptgService.ITEM_MAP[":organisationId"] = String(
      this.organisationService.activeItem.organisationId
    );
    let initialValids = this.version.attributeValidations;
    this.version.attributeValidations = this.ptgAttributeValidations;
    this.version.deleted = false;
    if (!this.EDIT_FLAG) {
      this.ptg.versions.push(this.version);
    }

    this.ptgService.putItem(this.ptg).then(
      (ptg) => {
  
        this.routeToPtgOverview();
        if (this.EDIT_FLAG) {
          this.dialogService.openDialog(
            "Produkttypversion Nr. " + this.version.number + " bearbeitet",
            "Die Produkttypversion Nr. " +
              this.version.number +
              " wurde für den Produkttypen " +
              ptg.name +
              " erfolgreich bearbeitet."
          );
        } else {
          this.dialogService.openDialog(
            "Producttypversion Nr. " + this.version.number + " erstellt",
            "Die Produkttypversion Nr. " +
              this.version.number +
              " wurde für den Produkttypen " +
              ptg.name +
              " erfolgreich erstellt."
          );
        }
      },
      (e) => {
        //Reset state of component to initial state
        this.version.attributeValidations = initialValids;
        this.ptgAttributeValidations = [];
        this.ngOnInit();
      }
    );
  }

  private routeToPtgOverview(): void {
    var url = this.router.url
      .replace("/edit", "")
      .replace("/versions/create", "");
    this.router.navigateByUrl(url);
  }

  ngOnDestroy(): void {
    document.documentElement.style.setProperty('--scrollStatus', "hidden");

    if (this.productListSubscription) {
      this.productListSubscription.unsubscribe();
    }
  }

  public onDescriptionValidityChange(descriptionValid: boolean): void {
    this.isDescriptionValid = descriptionValid;
  }
}
