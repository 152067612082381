import {Component, OnInit, Input, Output, ViewChild, ElementRef} from "@angular/core";
import { FormControl, Validators, FormGroup, AbstractControl } from "@angular/forms";
import { DialogService } from "../../../services/dialog-service/dialog-service.service";
import { ReviewService } from "src/app/services/review-service/review.service";
import { ReviewInterface } from "src/app/model/reviews/review-interface";
import { UserService } from "src/app/services/user-service/userservice";
import { UserInterface } from "src/app/model/user/user-interface";
import { EventEmitter } from "@angular/core";
import { CommentInterface } from "src/app/model/reviews/comment-interface";

@Component({
  selector: "app-write-review",
  templateUrl: "./write-review.component.html",
  styleUrls: [
    "../reviews/reviews.component.css",
    "./write-review.component.css",
  ],
})
export class WriteReviewComponent implements OnInit {
  hoverstate: number = 0;
  // feedback: string = '';
  // titel: string = '';
  private maxTitelChar: number = 100;
  private maxFeedbackChar: number = 10000;
  @Input() currentRating: number = 0;
  @Input() reviewType: number;
  @Input() targetId: number;
  @Input() feedbackInput: string = "";
  @Input() titelInput: string = "";
  @Input() reviewId?: number;
  @Input() organisationId: number = 0;
  @Input() comments: CommentInterface[];
  @Output() reviewChangeEmitter = new EventEmitter<ReviewInterface>();
  @Output() reviewCancelEmitter = new EventEmitter();

  form = new FormGroup({
    titel: new FormControl("", [
      Validators.required,
      Validators.maxLength(this.maxTitelChar),
    ]),
    organisation: new FormControl(null, [
      Validators.required]
    ),
    feedback: new FormControl("", [
      Validators.required,
      Validators.maxLength(this.maxFeedbackChar),
    ]),
  });

  constructor(
    private reviewService: ReviewService,
    private dialogService: DialogService,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.form.setValue({
      titel: this.titelInput,
      feedback: this.feedbackInput,
      organisation: this.organisationId
    });
  }

  getErrorMessageTitel() {
    let errorMessage = "";
    if (this.form.get("titel").hasError("required")) {
      errorMessage = "Bitte geben Sie ihren Titel ein";
    } else if (this.form.get("titel").hasError("maxlength")) {
      errorMessage = `Ihr Titel darf nicht länger als ${this.maxTitelChar} Zeichen sein`;
    }
    return errorMessage;
  }

  getErrorMessageFeedback() {
    var errorMessage = "";
    if (this.form.get("feedback").hasError("required")) {
      errorMessage = "Bitte geben Sie ihr Feedback ein";
    } else if (this.form.get("feedback").hasError("maxlength")) {
      errorMessage = `Ihr Kommentar darf nicht länger als ${this.maxFeedbackChar} Zeichen sein`;
    }
    return errorMessage;
  }

  getErrorMessageOrganisation() {
    var errorMessage = "";
    if (this.form.get("organisation").hasError("required")) {
      errorMessage = "Bitte wählen Sie eine Organisation aus";
    }
    return errorMessage;
  }

  sendReview(): void {

          let currentUser = this.userService.currentUser;
          if (this.form.get("titel").valid && this.form.get("feedback").valid && this.currentRating > 0 && currentUser.employer != "") {
              const newReview: ReviewInterface = {
                headline: this.form.get("titel").value,
                stars: this.currentRating,
                reviewText: (this.form.get("feedback").value as string).trim(),
                reviewType: this.reviewType,
                reviewId: this.reviewId,
                creatorId: currentUser.userId,
                creatorOrganisationName: currentUser.employer,
                targetId: this.targetId,
                creatorUserName: currentUser.userName,
                comments: this.comments,
              };
              this.reviewService
                .writeEditReview(newReview)
                .subscribe((review: ReviewInterface) => {
                  if (this.reviewId == null) {
                    this.dialogService.openDialog(
                      "Bewertung erfolgreich abgesendet",
                      ""
                    );
                  } else {
                    this.dialogService.openDialog(
                      "Bewertung erfolgreich bearbeitet",
                      ""
                    );
                  }
                  this.reviewChangeEmitter.emit(review);
                });
          } else {
            this.dialogService.openDialog("Es ist ein Fehler aufgetreten",this.buildErrorMessageForTextfields([String(this.form.get("titel").value).length > 0 ? "":"titel", 
            ((this.form.get("feedback").value) as string).trim().length > 0 ? "" : "feedback", String(this.form.get("organisation").value) === "0" ? "organisation" : "", this.currentRating > 0 ? "" : "rating"])
            );
          }

  }

  buildErrorMessageForTextfields(acArray: String[]): string{
    let errorMessage= "";
    for(var i = 0; i < acArray?.length; i++){
      if(acArray[i].length > 0){
        switch(acArray[i]?.toString()){
          case "titel": errorMessage+="Der Titel muss 1 bis 100 Zeichen lang sein. \n"; break;
          case "feedback" : errorMessage+="Ihr Kommentar muss zwischen 1 bis 10.000 Zeichen lang sein. \n"; break;
          case "organisation": errorMessage+="Es muss ein Arbeitgeber im Profil angegeben sein. \n";  break;
          case "rating": errorMessage+="Für die Bewertung muss mindestens ein Stern ausgewählt sein. \n"; break;
          default: break;
        }
      }
    }
      errorMessage = errorMessage.replace("/\n$/", "");
      return errorMessage;
  }

  cancel(): void {
    this.dialogService
      .openConfirmDialog(
        "Wollen Sie die Bewertung wirklich abbrechen?",
        "Nein",
        "Ja"
      )
      .subscribe((result) => {
        if (result) {
          this.reviewCancelEmitter.emit();
        }
      });
  }

  setCurrentRating(newRating: number): void {
    this.currentRating = newRating;
  }

  hoverEnter(newHoverState: number): void {
    this.hoverstate = newHoverState;
  }

  hoverLeave(newHoverState: number): void {
    this.hoverstate = newHoverState;
  }
}
