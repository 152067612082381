import { LoggedInProfileInterface } from './../../../model/user/loggedin-profile-interface';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { PublicReviewInterface } from 'src/app/model/reviews/public-review-interface';
import { ReviewService } from 'src/app/services/review-service/review.service';
import { UserService } from 'src/app/services/user-service/userservice';
import { environment } from 'src/environments/environment';
import { ProjectInterface } from 'src/app/model/user/user-project-interface';

const PAGE_SIZE: number = 5;

@Component({
  selector: 'app-user-profile-screen',
  templateUrl: './user-profile-screen.component.html',
  styleUrls: ['./user-profile-screen.component.css']
})
export class UserProfileScreenComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private changeDetector: ChangeDetectorRef,
    private reviewService: ReviewService,
    private titleService: Title,
  ) { 
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
  }

  private username: string;
  public profile: LoggedInProfileInterface;
  public reviews: PublicReviewInterface[] = [];
  private totalNumberOfReviews: number;
  private pageIndex: number = 0;

  ngOnInit(): void  {
    this.titleService.setTitle("Eigenes Profil - BIMSWARM");
    this.route.params.subscribe((params: Params) => {
      this.username = params['user'];
      this.userService.getLoggedInProfile(this.username).subscribe(
        (loggedInProfile) => {
          this.profile = loggedInProfile;
          if (!this.changeDetector["destroyed"]) {
            this.changeDetector.detectChanges();
          }
          this.loadReviewPage();
        },
        (err) =>
          console.error("There was an Error loading the Public Profile", err)
      )
    });
   }

   ngOnDestroy() {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
   }


   public getDefaultProfileImageUrl(): string {
    return environment.defaultPictureProfile;
  }

  /* Loads Reviews and replaces the current */
  public loadReviewPage() {
    const reviewPageDto = this.createReviewPageDto();
    this.reviewService
      .getPublicReviewSlice(reviewPageDto, this.username)
      .subscribe((sliceOfReviewDtos) => {
        this.reviews = sliceOfReviewDtos.reviewSlice;
        this.totalNumberOfReviews = sliceOfReviewDtos.totalNumberOfReviews;
        if (!this.changeDetector["destroyed"]) {
          this.changeDetector.detectChanges();
        }
      });
  }

  /* Loads more Reviews when scrolled */
  public onScroll() {
    if (this.reviews.length < this.totalNumberOfReviews) {
      this.pageIndex++;
      this.loadNextReviewPage();
    }
  }

  /* Loads the next Page of Reviews and concats them to the current */
  public loadNextReviewPage() {
    const reviewPageDto = this.createReviewPageDto();
    let request = this.reviewService
      .getPublicReviewSlice(reviewPageDto, this.username)
      .subscribe((sliceOfReviewDtos) => {
        this.reviews = this.reviews.concat(sliceOfReviewDtos.reviewSlice);
        this.totalNumberOfReviews = sliceOfReviewDtos.totalNumberOfReviews;
        if (!this.changeDetector["destroyed"]) {
          this.changeDetector.detectChanges();
        }
      });
  }

  public createReviewPageDto() {
    return {
      pageIndex: this.pageIndex,
      pageSize: PAGE_SIZE,
    };
  }
}
