<div [ngClass]="this.addPtgDependencyMode && 'fixed-size-container-300'" class="mini-grid-ptg margin">
  <div class="mikro-grid-ptg">
    <p *ngIf="!isCertification">
      Sollen der Produktversion (weitere) Produkttypen zugeordnet werden?
    </p>
    <p *ngIf="isCertification">
      Sollen der Zertifizierungsversion (weitere) Produkttypen zugeordnet
      werden?
    </p>
    <toggle-switch
      [trueLabel]="'Ja'"
      [falseLabel]="'Nein'"
      [allowUndefined]="false"
      (valueChange)="onToggle($event)"
    >
    </toggle-switch>
  </div>

  <div *ngIf="this.addPtgDependencyMode" [ngClass]="this.addPtgDependencyMode && 'fixed-size-container-240'">
      <div *ngIf="this.ptgWithActiveVersions != undefined">
      <div *ngFor="let ptg of this.ptgWithActiveVersions.ptgList">
        <button id="basic" class="extendWidth" (click)="addPtgVersion(ptg)" mat-button> {{ptg.name}} </button>
      </div>
    </div>
      <mat-paginator
        style="position: absolute; bottom: 5px; left: 0px; right: 0px;"
            [length]="this.ptgWithActiveVersions?.totalNumberOfElements"
            [pageSize]="5"
            aria-label="Select page"
            (page)="pageChanged($event)"
            >
      </mat-paginator>
    </div>
    <!--div class="mikro-grid-product-ptg-second">
      <mat-form-field>
        <mat-select
          [ngModel]="selectedPtg"
          placeholder="Produkttypen"
          (selectionChange)="onPtgSelected($event)"
        >
          <mat-option
            *ngFor="let ptg of ptgWithActiveVersions"
            [value]="ptg"
            >{{ ptg.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select
          [(ngModel)]="selectedPtgVersion"
          placeholder="Version"
          (selectionChange)="addPtgVersion($event)"
        >
          <mat-option
            *ngFor="let version of filterActiveVersionsOfSelectedPtg()"
            [value]="version"
            >{{ version.number }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div-->
  </div>
