import { PtgInterface } from "src/app/model/ptgs/ptg-interface";
import { ComposerPTGSelectionForViewer } from "../../model-viewer/custom/factory/composerPTGSelectionForViewer";
import {
  addedPTGs,
  hasChanges,
  newDescription,
  newItemDescription,
  newItemName,
  newName,
  organisationOptions,
  parsePTGCode,
  productChanged,
  ptgOptions,
  removePTGs,
  setPTGOptions,
  temporaryProductImageSource,
  temporaryProductName,
  temporarySelectedLogo,
  temporarySelectedOrganisationId,
} from "./composerChangeBuffer";
import { ComposerAssociatedProduct } from "./factory/composerAssociatedProduct";
import { ComposerInstanceList } from "./factory/composerInstanceList";
import { ComposerLabel } from "./factory/composerLabel";
import { ComposerSaveButton } from "./factory/composerSaveButton";
import { ComposerSelect } from "./factory/composerSelect";
import { ComposerSelectionAdding } from "./factory/composerSelectionAdding";
import { ComposerSelectionRemoving } from "./factory/composerSelectionRemoving";
import { ComposerSelectLogo } from './factory/composerSelectLogo';
import { ComposerTextBox } from "./factory/composerTextBox";
import { ComposerTextField } from "./factory/composerTextField";

/* Creates the Property Panel Elements and adds them in the correct Order */
export function ComposerProperties(group: any, element: any) {
  if (isProcess(element)) {
    createProcessIdAndNameElements();
  }

  if (isToolchainItem(element)) {
    createToolchainItemIdNameAndDescriptionElements();
  }

  if (isProcess(element)) {
    createProcessElements();
  }

  if (isToolchainItem(element)) {
    createToolchainItemElements(element);
  }

  // if (isProcess(element) || isToolchainItem(element)) {
  //   createSaveButton();
  // }

  /* Creates and adds a Save Button */
  function createSaveButton() {
    group.entries.push(
      ComposerSaveButton({
        id: "saveButton",
        disabled: !hasChanges(),
      })
    );
  }

  /* Creates and adds multiple ToolchainItemElements */
  function createToolchainItemElements(element: any) {
    let removeOptions: PtgInterface[] = [];
    if (
      element.businessObject.selectedPTGs &&
      element.businessObject.selectedPTGs.length > 0
    ) {
      const selectedPTGIds = parsePTGCode(
        element.businessObject.selectedPTGs
      ).map((id) => parseInt(id));
      selectedPTGIds.forEach((ptgId) => {
        const ptg = ptgOptions.find((ptg) => ptg.id === ptgId);

        if (ptg) {
          removeOptions.push(ptg);
        }
      });
    }

    removePTGs.forEach((ptg) => {
      if (removeOptions.includes(ptg)) {
        removeOptions.splice(removeOptions.indexOf(ptg), 1);
      }
    });
    addedPTGs.forEach((ptg) => removeOptions.push(ptg));

    //Create a shallow Copy of ptgOptions
    let addOptions: PtgInterface[] = ptgOptions.slice(0, ptgOptions.length);

    removeOptions.forEach((ptg) => {
      if (addOptions.includes(ptg)) {
        addOptions.splice(addOptions.indexOf(ptg), 1);
      }
    });

    addedPTGs.forEach((ptg) => {
      if (addOptions.includes(ptg)) {
        addOptions.splice(addOptions.indexOf(ptg), 1);
      }
    });
    removePTGs.forEach((ptg) => addOptions.push(ptg));

    if (element.businessObject.productId !== "") {
      group.entries.push(
        ComposerPTGSelectionForViewer({
          id: "ptgList",
          label: "Produkttypen",
          options: removeOptions,
        })
      );
    } else {
      group.entries.push(
        ComposerSelectionAdding({
          id: "ptgAdding",
          label: "Produkttypen",
          options: addOptions,
        })
      );

      group.entries.push(
        ComposerSelectionRemoving({
          id: "ptgRemoving",
          label: "Produkttypen",
          options: removeOptions,
        })
      );
    }

    group.entries.push(
      ComposerAssociatedProduct({
        id: "associatedProduct",
        productId: element.businessObject.productId,
        productName: productChanged
          ? temporaryProductName
          : element.businessObject.productName,
        imageDataSrc: productChanged
          ? temporaryProductImageSource
          : element.businessObject.imageDataUrl,
      })
    );
  }

  /* Creates and adds multiple Process Elements */
  function createProcessElements() {
/*     group.entries.push(
      ComposerTextBox({
        id: "description",
        label: "Beschreibung",
        content:
          newDescription !== ""
            ? newDescription
            : element.businessObject.$attrs.description,
      })
    );

    group.entries.push(
      ComposerLabel({
        id: "creator",
        labelName: "Ersteller",
        labelContent: element.businessObject.$attrs.creator,
      })
    );

    group.entries.push(
      ComposerSelect({
        id: "creatorOrganisation",
        label: "Ersteller Organisation",
        options: organisationOptions,
        currentlySelectedId:
          temporarySelectedOrganisationId !== -2
            ? temporarySelectedOrganisationId
            : element.businessObject.$attrs.organisation,
      })
    );

    group.entries.push(
      ComposerSelectLogo({
        id: "logo",
        imageDataSrc: temporarySelectedLogo ? temporarySelectedLogo : element.businessObject.$attrs.logoDataUrl,
      })
    ); */

/*     group.entries.push(
      ComposerLabel({
        id: "lastChanges",
        labelName: "Letzte Änderungen",
        labelContent: element.businessObject.$attrs.lastChanges,
      })
    ); */

    // group.entries.push(
    //   ComposerInstanceList({
    //     id: "toolchainInstances",
    //     label: "Toolchain Instanzen",
    //     content: element.businessObject.$attrs.toolchainInstances.split(":;:;"),
    //   })
    // );
  }

  /* Creates and adds Id, Name and Description of the ToolchainItem*/
  function createToolchainItemIdNameAndDescriptionElements() {
    group.entries.push(
      ComposerLabel({
        id: "id",
        labelName: "Id",
        labelContent: element.businessObject.id.substring(
          1,
          element.businessObject.id.length
        ),
      })
    );

    group.entries.push(
      ComposerTextField({
        id: "itemNameProp",
        label: "Name",
        content:
          newItemName !== "" ? newItemName : element.businessObject.itemName,
      })
    );

  }

  /* Creates and adds Id and Name of the Process */
  function createProcessIdAndNameElements() {
    group.entries.push(
      ComposerLabel({
        id: "id",
        labelName: "Id",
        labelContent: element.businessObject.id.substring(
          1,
          element.businessObject.id.length
        ),
      })
    );

    group.entries.push(
      ComposerTextField({
        id: "name",
        label: "Name",
        content: newName !== "" ? newName : element.businessObject.name,
      })
    );
  }
}

/* Checks if the Element is a BPMN Process */
function isProcess(element: any) {
  return element.businessObject.$type === "bpmn:Process";
}

/* Checks if the Element is a Composer ToolchainItem */
function isToolchainItem(element: any) {
  return element.businessObject.$type === "composer:ToolchainItem";
}
