import { Injectable } from "@angular/core";
import { ReviewInterface } from "src/app/model/reviews/review-interface";
import { UserService } from "../../user-service/userservice";

@Injectable({
  providedIn: "root",
})
export class MarketplaceReviewService {
  constructor(private userService: UserService) {
  }

  //-------------------------------------------------------------------

  get userId() {
    return this.userService.currentUser.userId;
  }

  private reviews = new Map<number, any>();
  private productId: number = 0;

  //-------------------------------------------------------------------

  upvoteReview(reviewId: number) {
    let review = this.getReview(reviewId);
    let index = review.upvotes.findIndex((x) => x === this.userId);
    if (index >= 0) {
      review.upvotes.splice(index, 1);
    } else {
      review.upvotes.push(this.userId);
    }
  }

  getReviewsForProduct(productId: number) {
    // TODO ADD BACKEND CONNECTION
    return this.reviews.get(this.productId);
  }

  getReview(reviewId: number) {
    let index = this.reviews
      .get(this.productId)
      .findIndex((x) => x.reviewId === reviewId);
    if (index >= 0) {
      return this.reviews.get(this.productId)[index];
    }
    return null;
  }
}
