//TODO: DELETE
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ProductInterface } from 'src/app/model/products/product-interface';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { HeaderServiceService } from 'src/app/services/header-service/header-service.service';
import { UserService } from 'src/app/services/user-service/userservice';

@Component({
  selector: 'app-products-no-content',
  templateUrl: './products-no-content.component.html',
  styleUrls: ['./products-no-content.component.css']
})
export class ProductsNoContentComponent implements OnInit {

  private resolvedProductList: ProductInterface[];

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    private headerService: HeaderServiceService,
    private organisationService: OrganisationService,
    private userService: UserService
  ) {
    //Reroutes to marketplace if the user is not a member of the organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    }
  }

  ngOnInit() {
    this.resolvedProductList = this.activeRoute.snapshot.data.productList;
    
    if (this.organisationService && this.organisationService.activeItem) {
      this.headerService.loadHeaderText(this.organisationService.activeItem.organisationName);
    }
  }

}
