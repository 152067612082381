<div style="margin-right: 2vw;">
  <mat-form-field   appearance="outline" style="width: 100%;">
  <mat-label >Suche</mat-label>
  <div class="search">
  <input  matInput placeholder="Schlagwort"
  [(ngModel)]="searchText"
  (keydown.enter)="this.filterBySearchText()"
  (keydown.escape)="$event.stopPropagation()"
  [formControl]="prodControl"
  [matAutocomplete]="auto">

  <button mat-mini-fab color="white"  (click)="this.filterBySearchText()">
    <mat-icon  matPrefix color="accent">search</mat-icon>
  </button>
</div>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{option}}
    </mat-option>
  </mat-autocomplete>

</mat-form-field>

<button (click)="this.resetAllFilters();
"mat-button class="btn_green"> Filter zurücksetzen</button>

<div>
  <div *ngIf="marketScreenState !='CERTIFICATION'" style="margin-top: 1vw;">
    <!--Checkboxes product categories and subcategories-->
    <h3>Bereiche</h3>
    <mat-divider></mat-divider>
    <section *ngFor="let productItem of productCategoryCheckboxItems; index as i">
      <mat-checkbox
        class="checkbox"
        [(ngModel)]="productItem.checked"
        (change)="
          _receiveCheckedValueProductCertificationType(
            'PRODUCT_PROVIDER',
            i
          ); toggleProductCheckBoxes(productItem.value, true);
        "
      >
        {{ productItem.name }}
      </mat-checkbox>
      <!--PRODUKTFILTERKATEGORIE SUBKATEGORIEN-->

    </section>


  </div>
  <div *ngIf="marketScreenState !='ORGANISATION' && marketScreenState !='KÜNTSTLICHE_INTELLIGENZ'">
    <br />
    <h3>Zertifikate</h3>
    <mat-divider></mat-divider>
    <section *ngFor="let certItem of certificationCategoryCheckboxItems; index as i">
<!--       <mat-checkbox
      class="checkbox"
      [(ngModel)]="certItem.checked"
      (change)="
        _receiveCheckedValueProductCertificationType(
          'CERTIFICATION_PROVIDER',
          i
        ); toggleCertificationSubCheckBoxes(null, certItem.value);
        sortCertificationCheckBoxCategories();
        this.resetAllSubCheckBoxWOCertFilter();
      "
    >
      {{ certItem.name }}
    </mat-checkbox> -->

    <!-- CHECKBOXES FOR CERTIFICATIONFILTER ON CERTIFICATION MARKETPLACE PAGE  -->
      <!-- CHECKBOXES FOR CERTIFICATION FILTER ON PRODUCT MARKETPLACE PAGE  -->
      <ul *ngIf="certItem.checked && marketScreenState !='ORGANISATION' && marketScreenState !='CERTIFICATION' && marketScreenState !='KÜNTSTLICHE_INTELLIGENZ' ">
      <!--ATTRIBUTE/MERKMAL ZERTIFIKATE-->
      <div [ngClass]="{'scrollpane' : attr_certificationCheckboxes.length > 5}" style="max-height: 10em;" *ngIf="certItem.value === 'ATTRIBUTE_CERT'">
      <li  *ngFor="let certProviders of attr_certificationCheckboxes; let i = index">
        <mat-checkbox class="subcheckbox"
          [(ngModel)]=certProviders.isChecked
          (change)="
          toggleCertificationSubCheckBoxes(certProviders.categorie, null);
          this._receiveCheckedValue(certProviders,$event);">
              {{certProviders.label}}
        </mat-checkbox>
      </li>
    </div>
    <!-- TOOLCHAIN ZERTIFIKATE -->
    <div [ngClass]="{'scrollpane' : toolChain_certificationCheckBoxes.length > 5}" style="max-height: 10em;"  *ngIf="certItem.value === 'TOOLCHAIN_CERT'">
      <li *ngFor="let certProviders of toolChain_certificationCheckBoxes; let i = index">
        <mat-checkbox class="subcheckbox"
          [(ngModel)]=certProviders.isChecked
          (change)="
          toggleCertificationSubCheckBoxes(certProviders.categorie, null);
          this._receiveCheckedValue(certProviders,$event);"
          >
              {{certProviders.label}}
        </mat-checkbox>
      </li>
    </div>
    <!-- PRODUKTTYPGRUPPEN ZERTIFIKATE -->
    <div [ngClass]="{'scrollpane' : ptg_certificationChecbkBoxes.length > 5}" style="max-height: 10em;" *ngIf="certItem.value === 'PTG_CERT'">
<!--       <li  *ngFor="let certProviders of ptg_certificationChecbkBoxes; let i = index" >
        <mat-checkbox class="subcheckbox"
        [(ngModel)]=certProviders.isChecked
        (change)="
        toggleCertificationSubCheckBoxes(certProviders.categorie, null);
        this._receiveCheckedValue(certProviders,$event);"
        >
              {{certProviders.label}}
        </mat-checkbox>
      </li> -->
    </div>
    </ul>
    </section>
  </div>

<!--PTGs & Filteroption innerhalb der PTG-checkboxes-->
<div *ngIf="marketScreenState !='ORGANISATION' && marketScreenState !='CERTIFICATION'">
  <span class="filterTitle">
    <h3>Produkttypen</h3>
    <button
      mat-icon-button
      color="primary"
      (click)="showProducttypeFilter = !showProducttypeFilter"
    >
      <mat-icon
        [ngClass]="{
          'material-icons-outlined': !producttypeFilterString
        }"
        >filter_alt</mat-icon
      >
    </button>
  </span>
  <mat-divider></mat-divider>
  <div
  [@openClose]="showProducttypeFilter ? 'open' : 'closed'"
  class="filterPanel"
  >
    <mat-form-field appearance="outline" [floatLabel]="'never'" style="width: 95%;">
      <input
        matInput
        type="text"
        placeholder="Suche"
        [(ngModel)]="producttypeFilterString"
      />
      <mat-icon matPrefix>search</mat-icon>
      <button
        mat-button
        *ngIf="producttypeFilterString"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="producttypeFilterString = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <section [ngClass]="{'scrollpane' : ptgCheckboxes.length > 5, 'producttypes' : ptgCheckboxes.length < 5}" style="max-height: 10em; margin-bottom: 1.5em;">
    <ng-container

      *ngFor="
        let ptg of ptgCheckboxes | filter: producttypeFilterString;
        let last = last;
        let i = index
      "
    >
      <mat-checkbox
      class="checkbox"
      name="organisationList"
      [checked]="ptg.isChecked"
      (change)="_receiveCheckedValue(ptg, $event); toggleAllSubCheckBoxesPTG(ptg.id);"
      >
       {{ ptg.label }} </mat-checkbox>
       <div *ngIf="ptg.isChecked" class="subCheckBoxForPTG">
         <ng-container
         *ngFor="let ptgAttr of ptgAttributeCheckboxes
                  let last = last;
                  let i = index
                "
                >
                <mat-checkbox *ngIf="ptg.id == ptgAttr.ptgId"
                      [checked]="ptgAttr.isChecked"
                      (change)="_receiveCheckedValue(ptgAttr, $event)"
                >
                <a *ngIf="ptgAttr.ptgOptional == true">{{ptgAttr.label}}</a>
                <a *ngIf="ptgAttr.ptgOptional != true" style="font-weight: bold;">{{ptgAttr.label}}</a>
                </mat-checkbox>
         </ng-container>
       </div>
      <br *ngIf="!last" />
    </ng-container>
  </section>

</div>
<!-- CommonFeature-Filters with Entry-Checkboxes -->
<div *ngIf="marketScreenState != 'ORGANISATION' && marketScreenState != 'CERTIFICATION'">
  <div *ngFor="let commonFeature of commonFeatures" class="margin-bottom">
    <!-- Only show 'KI_Kategorie' when marketScreenState is 'KÜNTSTLICHE_INTELLIGENZ' -->
    <div *ngIf="(marketScreenState === 'KÜNTSTLICHE_INTELLIGENZ' && commonFeature.name === 'KI_Kategorie') || 
                 (marketScreenState !== 'KÜNTSTLICHE_INTELLIGENZ' && commonFeature.name !== 'KI_Kategorie')">
      <span class="filterTitle">
        <h3>{{ commonFeature.name === 'KI_Kategorie' ? 'Kategorie' : commonFeature.name }}</h3>
      </span>
      <mat-divider></mat-divider>
      <section [ngClass]="{'scrollpane' : commonFeature.entries.length > 5}" style="max-height: 10em;">
        <ng-container *ngFor="let entry of commonFeature.entries; let last = last;">
          <mat-checkbox
            name="commonFeatureEntryList"
            [checked]="entry.isChecked"
            value="{{ entry.name }}"
            (change)="checkedCommonFeatureEntryBox(entry, $event)"
          >
            {{ entry.name }}
          </mat-checkbox>
          <br *ngIf="!last" />
        </ng-container>
      </section>
    </div>
  </div>
</div>

<div *ngIf="marketScreenState !='ORGANISATION' && marketScreenState !='CERTIFICATION'">

  <h3>Bewertungen</h3>
  <mat-divider></mat-divider>


  <section  *ngFor="let score of productRatingCheckboxItem" >
  <mat-checkbox *ngIf="score.value; else noScore"
    [(ngModel)]="score.checked"
  (change)="_receiveCheckedValueRating(score.rating, $event)" >
   <div class="ratingStar">
    <app-star
      [rating]= "score.rating"
    ></app-star>
  </div>
  </mat-checkbox>

  <ng-template #noScore>
  <mat-checkbox
    [(ngModel)]="score.checked"
    (change)="_receiveCheckedValueRatingAllWithout(score.rating, $event)"
   >
   <div class="ratingStar">
   <app-star
   [rating]= "0"
  ></app-star>
  </div>
  </mat-checkbox>
  </ng-template>
  </section>
  </div>
  <!--Anbieter & Filteroption innerhalb der Anbieter-checkboxes-->
<div *ngIf="marketScreenState !='ORGANISATION'">
  <span class="filterTitle">
    <h3>Anbieter</h3>
    <button
      mat-icon-button
      color="primary"
      (click)="showProviderFilter = !showProviderFilter"
    >
      <mat-icon
        [ngClass]="{
          'material-icons-outlined': !providerFilterString
        }"
        >filter_alt</mat-icon
      >
    </button>
  </span>
  <mat-divider></mat-divider>
<div
[@openClose]="showProviderFilter ? 'open' : 'closed'"
class="filterPanel"
>
  <mat-form-field appearance="outline" [floatLabel]="'never'" style="width: 95%;">
    <input
      matInput
      type="text"
      placeholder="Suche"
      [(ngModel)]="providerFilterString"
    />
    <mat-icon matPrefix>search</mat-icon>
    <button
      mat-button
      *ngIf="providerFilterString"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="providerFilterString = ''"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
<section [ngClass]="{'scrollpane' : organisationCheckboxes.length > 5}" style="max-height: 10em;">
  <ng-container
    *ngFor="
      let org of organisationCheckboxes | filter: providerFilterString;
      let last = last;
      let i = index
    "
  >
    <mat-checkbox
    name="organisationList"
    [checked]="org.isChecked"
    (change)="_receiveCheckedValue(org, $event)"
    >
      {{ org.label }} </mat-checkbox>
    <br *ngIf="!last" />
  </ng-container>
</section>
<br />
</div>
<!-- Organisation screen state-->
<div *ngIf="marketScreenState === 'ORGANISATION'">
  <span class="filterTitle">
    <h3>Produktanbieter</h3>
  </span>
  <mat-divider></mat-divider>
  <section *ngFor="let productOrganisationCategory of productCategoryCheckboxItems; index as i">
    <mat-checkbox
      class="checkbox"
      [(ngModel)]="productOrganisationCategory.checked"
      (change) ="
        _receiveCheckedValueProductCertificationType(
          'PRODUCT_PROVIDER',
          i, productOrganisationCategory.value, productOrganisationCategory.checked
        ); toggleProductCheckBoxes(productOrganisationCategory.value, true);
      "
    >
      {{ productOrganisationCategory.name }}
    </mat-checkbox>

    <div *ngIf="productOrganisationCategory.value === 'APP'" [ngClass]="{'scrollpane' : productOrganisationApplicationCheckBoxes.length > 5}" style="max-height: 10em;">
      <ul *ngIf="productOrganisationCategory.checked">
        <li *ngFor="let productItemCheckBox of productOrganisationApplicationCheckBoxes; index as i">
          <mat-checkbox *ngIf="productOrganisationCategory.value === 'APP'"
          class="subcheckbox"
          [(ngModel)]="productItemCheckBox.isChecked"
          (change)="_receiveCheckedValue(productItemCheckBox, $event); toggleProductCheckBoxes(productItemCheckBox.categorie, false)"
          >
          {{productItemCheckBox.label}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
    <div *ngIf="productOrganisationCategory.value === 'SERVICE'" [ngClass]="{'scrollpane' : productOrganisationServiceCheckBoxes.length > 5}" style="max-height: 10em;">
      <ul *ngIf="productOrganisationCategory.checked">
        <li *ngFor="let productItemCheckBox of productOrganisationServiceCheckBoxes; index as i">
          <mat-checkbox *ngIf="productOrganisationCategory.value === 'SERVICE'"
          class="subcheckbox"
          [(ngModel)]="productItemCheckBox.isChecked"
          (change)="_receiveCheckedValue(productItemCheckBox, $event); toggleProductCheckBoxes(productItemCheckBox.categorie, false)"
          >
          {{productItemCheckBox.label}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
    <div *ngIf="productOrganisationCategory.value === 'CONTENT'" [ngClass]="{'scrollpane' : productOrganisationContentCheckBoxes.length > 5}" style="max-height: 10em;">
      <ul *ngIf="productOrganisationCategory.checked">
        <li *ngFor="let productItemCheckBox of productOrganisationContentCheckBoxes; index as i">
          <mat-checkbox *ngIf="productOrganisationCategory.value === 'CONTENT'"
          class="subcheckbox"
          [(ngModel)]="productItemCheckBox.isChecked"
          (change)="_receiveCheckedValue(productItemCheckBox, $event); toggleProductCheckBoxes(productItemCheckBox.categorie, false)"
          >
          {{productItemCheckBox.label}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
    <div *ngIf="productOrganisationCategory.value === 'HOCHBAU'" [ngClass]="{'scrollpane' : productOrganisationHochbauCheckBoxes.length > 5}" style="max-height: 10em;">
      <ul *ngIf="productOrganisationCategory.checked">
        <li *ngFor="let productItemCheckBox of productOrganisationHochbauCheckBoxes; index as i">
          <mat-checkbox *ngIf="productOrganisationCategory.value === 'HOCHBAU'"
                        class="subcheckbox"
                        [(ngModel)]="productItemCheckBox.isChecked"
                        (change)="_receiveCheckedValue(productItemCheckBox, $event); toggleProductCheckBoxes(productItemCheckBox.categorie, false)"
          >
            {{productItemCheckBox.label}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
    <div *ngIf="productOrganisationCategory.value === 'TIEFBAU'" [ngClass]="{'scrollpane' : productOrganisationTeifbauCheckBoxes.length > 5}" style="max-height: 10em;">
      <ul *ngIf="productOrganisationCategory.checked">
        <li *ngFor="let productItemCheckBox of productOrganisationTeifbauCheckBoxes; index as i">
          <mat-checkbox *ngIf="productOrganisationCategory.value === 'TEIFBAU'"
                        class="subcheckbox"
                        [(ngModel)]="productItemCheckBox.isChecked"
                        (change)="_receiveCheckedValue(productItemCheckBox, $event); toggleProductCheckBoxes(productItemCheckBox.categorie, false)"
          >
            {{productItemCheckBox.label}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
    <div *ngIf="productOrganisationCategory.value === 'WASSERBAU'" [ngClass]="{'scrollpane' : productOrganisationWasserbauCheckBoxes.length > 5}" style="max-height: 10em;">
      <ul *ngIf="productOrganisationCategory.checked">
        <li *ngFor="let productItemCheckBox of productOrganisationWasserbauCheckBoxes; index as i">
          <mat-checkbox *ngIf="productOrganisationCategory.value === 'WASSERBAU'"
                        class="subcheckbox"
                        [(ngModel)]="productItemCheckBox.isChecked"
                        (change)="_receiveCheckedValue(productItemCheckBox, $event); toggleProductCheckBoxes(productItemCheckBox.categorie, false)"
          >
            {{productItemCheckBox.label}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
    <div *ngIf="productOrganisationCategory.value === 'STRASENBAU'" [ngClass]="{'scrollpane' : productOrganisationStrasenbauCheckBoxes.length > 5}" style="max-height: 10em;">
      <ul *ngIf="productOrganisationCategory.checked">
        <li *ngFor="let productItemCheckBox of productOrganisationStrasenbauCheckBoxes; index as i">
          <mat-checkbox *ngIf="productOrganisationCategory.value === 'STRASENBAU'"
                        class="subcheckbox"
                        [(ngModel)]="productItemCheckBox.isChecked"
                        (change)="_receiveCheckedValue(productItemCheckBox, $event); toggleProductCheckBoxes(productItemCheckBox.categorie, false)"
          >
            {{productItemCheckBox.label}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
    <div *ngIf="productOrganisationCategory.value === 'EISENBAHNBAU'" [ngClass]="{'scrollpane' : productOrganisationEisenbahnbauCheckBoxes.length > 5}" style="max-height: 10em;">
      <ul *ngIf="productOrganisationCategory.checked">
        <li *ngFor="let productItemCheckBox of productOrganisationEisenbahnbauCheckBoxes; index as i">
          <mat-checkbox *ngIf="productOrganisationCategory.value === 'EISENBAHNBAU'"
                        class="subcheckbox"
                        [(ngModel)]="productItemCheckBox.isChecked"
                        (change)="_receiveCheckedValue(productItemCheckBox, $event); toggleProductCheckBoxes(productItemCheckBox.categorie, false)"
          >
            {{productItemCheckBox.label}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
    <div *ngIf="productOrganisationCategory.value === 'INFRASTRUKTUR'" [ngClass]="{'scrollpane' : productOrganisationInfrastrukturCheckBoxes.length > 5}" style="max-height: 10em;">
      <ul *ngIf="productOrganisationCategory.checked">
        <li *ngFor="let productItemCheckBox of productOrganisationInfrastrukturCheckBoxes; index as i">
          <mat-checkbox *ngIf="productOrganisationCategory.value === 'INFRASTRUKTUR'"
                        class="subcheckbox"
                        [(ngModel)]="productItemCheckBox.isChecked"
                        (change)="_receiveCheckedValue(productItemCheckBox, $event); toggleProductCheckBoxes(productItemCheckBox.categorie, false)"
          >
            {{productItemCheckBox.label}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
  </section>
  <br />
  <span class="filterTitle">
    <h3>Zertifizierungsanbieter</h3>
  </span>
  <mat-divider></mat-divider>
  <section *ngFor="let certificationOrganisationCategory of certificationCategoryCheckboxItems; index as i">
    <mat-checkbox
      class="checkbox"
      [(ngModel)]="certificationOrganisationCategory.checked"
      (change) = "
      _receiveCheckedValueProductCertificationType(
        'CERTIFICATION_PROVIDER',
        i, certificationOrganisationCategory.value, certificationOrganisationCategory.checked
      ); toggleProductCheckBoxes(certificationOrganisationCategory.value, true);"
    >
      {{ certificationOrganisationCategory.name }}
    </mat-checkbox>

    <!--"Alle" checkbox-->
    <div *ngIf="certificationOrganisationCategory.value === 'ATTRIBUTE_CERT'" [ngClass]="{'scrollpane' : attributeCertificationOrganisationCheckBoxes.length > 5}" style="max-height: 10em;">
      <ul *ngIf="certificationOrganisationCategory.checked">
        <li *ngFor="let productItemCheckBox of attributeCertificationOrganisationCheckBoxes; index as i">
          <mat-checkbox *ngIf="certificationOrganisationCategory.value === 'ATTRIBUTE_CERT'"
          class="subcheckbox"
          [(ngModel)]="productItemCheckBox.isChecked"
          (change)="_receiveCheckedValue(productItemCheckBox, $event); toggleProductCheckBoxes(productItemCheckBox.categorie, false)"
          >
          {{productItemCheckBox.label}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
    <div *ngIf="certificationOrganisationCategory.value === 'PTG_CERT'" [ngClass]="{'scrollpane' : ptgCertificationOrganisationCheckBoxes.length > 5}" style="max-height: 10em;">
      <ul *ngIf="certificationOrganisationCategory.checked">
        <li *ngFor="let productItemCheckBox of ptgCertificationOrganisationCheckBoxes; index as i">
          <mat-checkbox *ngIf="certificationOrganisationCategory.value === 'PTG_CERT'"
          class="subcheckbox"
          [(ngModel)]="productItemCheckBox.isChecked"
          (change)="_receiveCheckedValue(productItemCheckBox, $event); toggleProductCheckBoxes(productItemCheckBox.categorie, false)"
          >
          {{productItemCheckBox.label}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
    <div *ngIf="certificationOrganisationCategory.value === 'TOOLCHAIN_CERT'" [ngClass]="{'scrollpane' : toolchainCertificationOrganisationCheckBoxes.length > 5}" style="max-height: 10em;">
      <ul *ngIf="certificationOrganisationCategory.checked">
        <li *ngFor="let productItemCheckBox of toolchainCertificationOrganisationCheckBoxes; index as i">
          <mat-checkbox *ngIf="certificationOrganisationCategory.value === 'TOOLCHAIN_CERT'"
          class="subcheckbox"
          [(ngModel)]="productItemCheckBox.isChecked"
          (change)="_receiveCheckedValue(productItemCheckBox, $event); toggleProductCheckBoxes(productItemCheckBox.categorie, false)"
          >
          {{productItemCheckBox.label}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
  </section>
</div>
</div>
</div>
