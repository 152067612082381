import {Component, OnInit, Output, Input, EventEmitter, ViewChild} from '@angular/core';
import {FilterPanelComponent} from "../filter-panel/filter-panel.component";
import { SidemenuServiceService } from 'src/app/services/componentServices/sidemenu-service/sidemenu-service.service';
import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { PtgService } from 'src/app/services/dataServices/ptg-service/ptgService';



@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.css']
})
export class SearchPanelComponent implements OnInit {

  @ViewChild(FilterPanelComponent) child: FilterPanelComponent;

  public filterMode: boolean = false;
  private mode: string = 'products';
  public optionsFiltered: boolean = false;

  constructor(
    private sidemenuService: SidemenuServiceService,
    private productService: ProductService,
    private ptgService: PtgService
  ) { }

  @Input()
  urlEqualsComposer: boolean;

  @Output()
  reset: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
  }


  filterModeToggle(){
    this.filterMode = !this.filterMode;
  }

  onInputStringChange(event){
      this.sidemenuService.searchSidemenuComposerUsecases(event)
  }

  setFiltered(){
    this.optionsFiltered = true;
  }

  resetFilter(){
    this.child.resetFilter(true);
    this.optionsFiltered = false;
  }
}
