import { HeaderServiceService } from './../services/header-service/header-service.service';
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { UserService } from "../services/user-service/userservice";
import { StartUpService } from "../services/start-up-service/start-up.service";
import { PtgService } from "../services/dataServices/ptg-service/ptgService";

@Injectable()
export class LoadingResolver implements Resolve<any> {
  constructor(
    private userService: UserService,
    private startUpService: StartUpService,
    private ptgService: PtgService,
    private headerService: HeaderServiceService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    this.headerService.loadHeaderText("BIMSWARM");
    const isLoginRoute = route.firstChild.routeConfig.path.match(/login/);
    if (!(isLoginRoute && route.queryParamMap.has("code"))) {
      await this.startUpService.handleLogin();
      if (this.userService.loggedIn) {
        // this.ptgService.getAllPtgs();
      }
    }
  }
}
