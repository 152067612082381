<!--div *ngIf="!this.displayItem">
  <img
    *ngIf="(dataUrl$ && dataUrl$ | async)"
    [attr.src]="dataUrl$ | async"
    [ngClass]="{
      custom: resize === true,
      custom2: resize === false,
      circle: circle === true,
      fill: fill === true,
      contain: contain === true
    }"
/>
</div-->

<img mat-card-image
class="custom3"
*ngIf="this.productList && this.isUploadedPicture && this.displayItem && !this.organisation"
[src]="dataUrl$ | async"
alt="Produktbild" />

<img mat-card-image
style="margin-left: 0em"
class="contain"
*ngIf="!this.productList && this.isUploadedPicture && this.displayItem && !this.organisation"
[src]="dataUrl$ | async"
alt="Produktbild" />

<img
*ngIf="this.isUploadedPicture && !this.displayItem && !this.organisation && !this.isProfilPic && !this.isComment && !this.isScreen"
class="custom"
[src]="dataUrl$ | async"
alt="Produktbild" />

<img
*ngIf="this.isUploadedPicture && !this.displayItem && !this.organisation && !this.isProfilPic && !this.isComment && this.isScreen"
class="customScreen"
[src]="dataUrl$ | async"
alt="UserScreenPic" />

<img
*ngIf="this.isUploadedPicture && !this.displayItem && !this.organisation && !this.isProfilPic && this.isComment"
class="customCommentProfilPic"
[src]="dataUrl$ | async"
alt="CommentProfilPic" />

<img
*ngIf="this.isUploadedPicture && !this.displayItem && !this.organisation && this.isProfilPic"
class="customProfilPic"
[src]="dataUrl$ | async"
alt="Profilbild" />

<img
mat-card-avatar
class="example-header-image"
*ngIf="this.isUploadedPicture && !this.displayItem && this.organisation"
[src]="dataUrl$ | async"
alt="Produktbild" />
