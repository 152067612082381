<div>
  <div class="wrapper">
    <div class="container">
      <div class="hexagonWrapper">
        <div class="hexagon outer" [ngClass]="whiteBorder ? 'white' : 'lightgreen'">
          <div class="hexagon inner">
            <div class="stripe"></div>
            <div class="stripe rotated"></div>
          </div>
        </div>
      </div>
      <div class="label">
        <p class="prevent-selection">{{label}}</p>
      </div>
    </div>
  </div>
</div>