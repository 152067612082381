import { Injectable } from "@angular/core";
import { AttributeInterface } from "src/app/model/attributes/attribute-interface";
import { ValidationInterface } from "src/app/model/attributes/validation-interface";

@Injectable({
  providedIn: "root",
})
export class AttributeValidationServiceService {
  public attributes: AttributeInterface[];
  public attributesValidated: Map<number, boolean>; // attributeId -> is current value valid
  public activeEntityFeatureValues: Map<number, string>; //attributeId -> selected value
  private effectiveAttributeRanges: Map<number, EffectiveAttributeValueRange>; //attributeId -> Effective Value Range for that attribute

  constructor() {
    this.attributesValidated = new Map();
    this.activeEntityFeatureValues = new Map();
    this.effectiveAttributeRanges = new Map();
  }

  public loadValidation(
    attributes: AttributeInterface[],
    optional: boolean
  ): void {
    for (let attribute of attributes) {
      const validation = attribute.validations[0];
      if (optional) {
        this.modelChangedIsOptional(
          attribute,
          validation,
          this.activeEntityFeatureValues.get(attribute.id)
        );
      } else {
        this.modelChangedValid(
          attribute,
          validation,
          this.activeEntityFeatureValues.get(attribute.id)
        );
      }
    }
  }

  public modelChangedIsOptional(
    attribute: AttributeInterface,
    validation: ValidationInterface,
    event: string
  ): void {
    const effectiveAttributeRange = this.effectiveAttributeRanges.get(
      attribute.id
    );
    if (
      this.hasUnsolvableConflict(effectiveAttributeRange) ||
      (this.hasConflict(effectiveAttributeRange) &&
        event != null &&
        event != "")
    ) {
      this.attributesValidated.set(attribute.id, false);
      return;
    }
    // FEHLER? !=null ?
    if (event == null && effectiveAttributeRange.optional) {
      this.activeEntityFeatureValues.set(attribute.id, event);
      this.attributesValidated.set(attribute.id, true);
    } else if (event === "" && effectiveAttributeRange.optional) {
      //Removes empty Optional Attributes from the Value List
      this.activeEntityFeatureValues.delete(attribute.id);
      this.attributesValidated.set(attribute.id, true);
    } else {
      this.modelChangedValid(attribute, validation, event);
    }
  }

  private hasUnsolvableConflict(
    effectiveAttributeRange: EffectiveAttributeValueRange
  ): boolean {
    return (
      effectiveAttributeRange == null ||
      (this.isNonOptional(effectiveAttributeRange) &&
        this.hasConflict(effectiveAttributeRange))
    );
  }

  private hasConflict(
    effectiveAttributeRange: EffectiveAttributeValueRange
  ): boolean {
    return effectiveAttributeRange.conflict;
  }

  private isNonOptional(
    effectiveAttributeRange: EffectiveAttributeValueRange
  ): boolean {
    return !effectiveAttributeRange.optional;
  }

  public modelChangedValid(
    attribute: AttributeInterface,
    validation: ValidationInterface,
    event: string,
    currentString?: string
  ): void {
    const effectiveAttributeRange = this.effectiveAttributeRanges.get(
      attribute.id
    );
    if (
      this.hasUnsolvableConflict(effectiveAttributeRange) ||
      (attribute.datatype !== "BOOLEAN" && attribute.datatype != "STRING" && event == null)
    ) {
      this.attributesValidated.set(attribute.id, false);
      return;
    }
    this.activeEntityFeatureValues.set(attribute.id, event);
    this.attributesValidated.set(attribute.id, false);
    const min = effectiveAttributeRange.min;
    const max = effectiveAttributeRange.max;
    const booleanValue = effectiveAttributeRange.booleanValue;
    if (attribute.datatype === "NUMERIC") {
      this.attributesValidated.set(
        attribute.id,
        this.checkNumberValue(min, max, event)
      );
    } else if (attribute.datatype === "BOOLEAN") {
      this.attributesValidated.set(
        attribute.id,
        this.checkBooleanValue(booleanValue, event)
      );
    } else if (attribute.datatype === "LIST") {
      this.attributesValidated.set(
        attribute.id,
        this.checkListValue(validation.values, min, max, event)
      );
    } else if (attribute.datatype === "STRING") {
      if(event) {
        const textLength = event.length;
        this.attributesValidated.set(
          attribute.id,
          this.checkNumberValue(min, max, textLength)
        );
      } else {
        currentString = "";
        this.attributesValidated.set(
          attribute.id,
          this.checkNumberValue(min, max, currentString.length)
        );
      }
    }
  }

  private checkNumberValue(minValue, maxValue, entityValue): boolean {
    return (
      (minValue == null || minValue <= entityValue) &&
      (maxValue == null || entityValue <= maxValue)
    );
  }

  private checkBooleanValue(attributeValue, entityValue): boolean {
    if (entityValue === "undefined") {
      return true;
    } else if (attributeValue != null && entityValue != null) {
      return attributeValue === entityValue;
    }
    return true;
  }

  private checkListValue(
    attributeValues,
    minLength,
    maxLength,
    entityValues
  ): boolean {
    for (let value of entityValues) {
      if (attributeValues.indexOf(value) === -1) {
        return false;
      }
    }
    const length = entityValues.length;
    return this.checkNumberValue(minLength, maxLength, length);
  }

  public modelChangedInvalid(attributeId: number, value: string): void {
    this.activeEntityFeatureValues.set(attributeId, value);
    this.attributesValidated.set(attributeId, false);
  }

  public setEffectiveAttributeValidations(
    entityAttributes: AttributeInterface[],
    nonOptionalPtgAttributes: AttributeInterface[],
    optionalPtgAttributes: AttributeInterface[]
  ): void {
    //------------------------------------------------------------------
    this.effectiveAttributeRanges.clear();
    this.attributesValidated.clear();
    this.attributes = entityAttributes.concat(
      nonOptionalPtgAttributes,
      optionalPtgAttributes
    );
    //------------------------------------------------------------------
    for (const attribute of optionalPtgAttributes) {
      //-----------------------------------------------
      const effectiveRange = this.getEffectiveRangeOfAttribute(attribute, true);
      this.effectiveAttributeRanges.set(attribute.id, effectiveRange);
      //-----------------------------------------------
      this.modelChangedIsOptional(
        attribute,
        attribute.validations[0],
        this.activeEntityFeatureValues.get(attribute.id)
      );
      //-----------------------------------------------
    }
    //------------------------------------------------------------------
    const allNonOptionalAttributes = entityAttributes.concat(
      nonOptionalPtgAttributes
    );
    //------------------------------------------------------------------
    for (const attribute of allNonOptionalAttributes) {
      //-----------------------------------------------
      const effectiveRange = this.getEffectiveRangeOfAttribute(
        attribute,
        false
      );
      this.effectiveAttributeRanges.set(attribute.id, effectiveRange);
      //-----------------------------------------------
      this.modelChangedValid(
        attribute,
        attribute.validations[0],
        this.activeEntityFeatureValues.get(attribute.id)
      );
      //-----------------------------------------------
    }
    //------------------------------------------------------------------
  }

  private getEffectiveRangeOfAttribute(
    attribute: AttributeInterface,
    optional: boolean
  ): EffectiveAttributeValueRange {
    const validation = attribute.validations[0];
    const effectiveRange = {
      min: validation.minSize,
      max: validation.maxSize,
      booleanValue: validation.value,
      dataType: attribute.datatype,
      optional: optional,
      conflict: false,
    };
    if (effectiveRange.dataType == "LIST" && effectiveRange.max == null) {
      effectiveRange.max = validation.values.length;
    }
    if (this.effectiveAttributeRanges.has(attribute.id)) {
      const currentRange = this.effectiveAttributeRanges.get(attribute.id);
      if (currentRange.conflict) {
        return currentRange;
      }
      this.getIntersectionOfValueRanges(currentRange, effectiveRange);
    }
    return effectiveRange;
  }

  private getIntersectionOfValueRanges(
    currentRange: EffectiveAttributeValueRange,
    effectiveRange: EffectiveAttributeValueRange
  ): void {
    if (this.exactlyOneValueNull(effectiveRange.min, currentRange.min)) {
      this.setMinNullValueToExistingValue(effectiveRange, currentRange);
    }
    if (this.exactlyOneValueNull(effectiveRange.max, currentRange.max)) {
      this.setMaxNullValueToExistingValue(effectiveRange, currentRange);
    }

    if (this.areValueRangesDisjoint(effectiveRange, currentRange)) {
      effectiveRange.min = null;
      effectiveRange.max = null;
      effectiveRange.booleanValue = null;
      effectiveRange.conflict = true;
    } else {
      if (effectiveRange.min != null && currentRange.min != null) {
        effectiveRange.min = Math.max(effectiveRange.min, currentRange.min);
      }
      if (effectiveRange.max != null && currentRange.max != null) {
        effectiveRange.max = Math.min(effectiveRange.max, currentRange.max);
      }
    }
  }

  private exactlyOneValueNull(value1, value2): boolean {
    return value1 != value2 && (value1 == null || value2 == null);
  }

  private setMinNullValueToExistingValue(
    effectiveRange: EffectiveAttributeValueRange,
    currentRange: EffectiveAttributeValueRange
  ): void {
    if (effectiveRange.min != null) {
      currentRange.min = effectiveRange.min;
    } else {
      effectiveRange.min = currentRange.min;
    }
  }
  private setMaxNullValueToExistingValue(
    effectiveRange: EffectiveAttributeValueRange,
    currentRange: EffectiveAttributeValueRange
  ): void {
    if (effectiveRange.max != null) {
      currentRange.max = effectiveRange.max;
    } else {
      effectiveRange.max = currentRange.max;
    }
  }

  private areValueRangesDisjoint(
    effectiveRange: EffectiveAttributeValueRange,
    currentRange: EffectiveAttributeValueRange
  ): boolean {
    return (
      (effectiveRange.min != currentRange.min &&
        effectiveRange.max != currentRange.max &&
        (effectiveRange.min > currentRange.max ||
          currentRange.min > effectiveRange.max)) ||
      effectiveRange.booleanValue != currentRange.booleanValue
    );
  }

  public getEffectiveMinValueForAttribute(attributeId: number): number {
    const effectiveRange = this.effectiveAttributeRanges.get(attributeId);
    if (effectiveRange != null) {
      return effectiveRange.min;
    }
    return null;
  }

  public getEffectiveMaxValueForAttribute(attributeId: number): number {
    const effectiveRange = this.effectiveAttributeRanges.get(attributeId);
    if (effectiveRange != null) {
      return effectiveRange.max;
    }
    return null;
  }

  public getEffectiveBooleanValueForAttribute(attributeId: number): boolean {
    const effectiveRange = this.effectiveAttributeRanges.get(attributeId);
    if (effectiveRange != null) {
      return effectiveRange.booleanValue;
    }
    return null;
  }

  public hasAttributeConflict(attributeId: number): boolean {
    const effectiveRange = this.effectiveAttributeRanges.get(attributeId);
    if (effectiveRange == null) {
      console.error(
        "EFFECTIVE RANGE OF ATTRIBUTE DOES NOT EXIST - attributeId: " +
          attributeId
      );
      return true;
    }
    return this.hasConflict(effectiveRange);
  }

  public checkAllEntityAttributeValuesValidated(): boolean {
    const attributeIds = Array.from(this.attributesValidated.keys());
    for (const attributeId of attributeIds) {
      if (!this.attributesValidated.get(attributeId)) {
        return false;
      }
    }
    return true;
  }

  public isAttributeEffectivelyOptional(attributeId: number): boolean {
    const effectiveRange = this.effectiveAttributeRanges.get(attributeId);
    return effectiveRange != null && effectiveRange.optional;
  }

  public getAttributeValidationValues() {
    let attributeValidationValues = {};
    for (const attribute of this.attributes) {
      const attributeId = attribute.id;
      const validationId = attribute.validations[0].id;
      let value = this.activeEntityFeatureValues.get(attributeId);
      if (value === undefined || (value == "" && attribute.datatype === "STRING")) {
        value = null;
      }
      if (value != null && attribute.datatype === "LIST") {
        value = value.toString().replace(new RegExp(",", "g"), ";");
      }
      attributeValidationValues[validationId] = value;
    }
    return attributeValidationValues;
  }

  public reset(): void {
    this.activeEntityFeatureValues.clear();
    this.attributesValidated.clear();
    this.effectiveAttributeRanges.clear();
    this.attributes = [];
  }
}

interface EffectiveAttributeValueRange {
  min: number;
  max: number;
  booleanValue: boolean;
  dataType: string;
  optional: boolean;
  conflict: boolean;
}
