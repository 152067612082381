import {BehaviorSubject, Observable} from "rxjs";
import {UserRoleInterface} from "../model/user/user-role-interface";
import {UserService} from "../services/user-service/userservice";
import {UserRoleDatasourceInterface} from "../model/user/user-role-datasource-interface";
import {NutzerverwaltungService} from "../services/nutzerverwaltung/nutzerverwaltung.service";

export class UserRoleDataSource {
  private userRoleSubject = new BehaviorSubject<UserRoleInterface[]>([]);
  public totalNumberOfElements: number;
  public addOrganisationMode: boolean[] = [];

  constructor(private nutzerverwaltungsService: NutzerverwaltungService) {}

  loadUserRoles(pageIndex: number = 0, pageSize: number = 10, searchString: string): void {
   this.nutzerverwaltungsService.getUsersWithRolesSlice(pageIndex, pageSize, searchString).subscribe(
     (userRoles: UserRoleDatasourceInterface)  => {
       this.totalNumberOfElements = userRoles.totalNumberOfElements;
       this.userRoleSubject.next(userRoles.userRoleDtos);
       for (let i = 0; i < this.totalNumberOfElements; i++) {
         this.addOrganisationMode[i] = false;
       }
     }
   );
  }

  getObservable(): Observable<UserRoleInterface[]> {
    return this.userRoleSubject.asObservable();
  }

  getTotalNumberOfElements(): number {
    return this.totalNumberOfElements;
  }
}
