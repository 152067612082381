import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-remove-member-confirm",
  templateUrl: "./dialog-remove-member-confirm.component.html",
  styleUrls: ["./dialog-remove-member-confirm.component.css", "../dialog.css"],
})
export class DialogRemoveMemberConfirmComponent {
  public message: string = "";
  constructor(
    public dialogRef: MatDialogRef<DialogRemoveMemberConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.message = "";
  }

  onClick(confirmed: boolean): void {
    const result = { confirmed: confirmed, message: this.message };
    this.dialogRef.close(result);
  }
}
