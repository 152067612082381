import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'hex-label-item',
  templateUrl: './hex-label-item.component.html',
  styleUrls: ['./hex-label-item.component.css']
})
export class HexLabelItemComponent implements OnInit {

  @Input() label: string;

  @Input() link: string;

  constructor(public router: Router) { }

  ngOnInit() {
  }

  isActive() {
    return this.link && this.router.url == this.link.replace(/\s/g, '%20');
  }
}
