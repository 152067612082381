import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { environment } from "src/environments/environment";

import {
  API_BASE_PRODUCTSERVICE,
  API_MAP,
  API_BASE_FILESERVICE,
} from "src/environments/api";
@Component({
  selector: "app-detail-display-gallery",
  templateUrl: "./detail-display-gallery.component.html",
  styleUrls: ["./detail-display-gallery.component.css"],
})
export class DetailDisplayGalleryComponent implements OnInit {
  @Input() galleryPictureIds: number[];

  @ViewChild("sideImageHexagon") private sideImageHexagon: ElementRef;
  public gallerySelected: boolean;
  public previewImageUrl: string;

  constructor() {}

  ngOnInit() {
    this.previewImageUrl = this.getPreviewImageUrl();
    if (this.galleryPictureIds == null) {
      this.galleryPictureIds = [];
    }
  }

  public onGallerySelected(): void {
    if (this.hasGalleryImages()) {
      this.gallerySelected = true;
    }
  }

  public hasGalleryImages(): boolean {
    return this.galleryPictureIds != null && this.galleryPictureIds.length > 0;
  }

  // private onClickOutsideGallery(clickEventTarget: EventTarget): void {
  //   if (!this.isClickOnPreviewImage(clickEventTarget)) {
  //     this.gallerySelected = false;
  //   }
  // }

  // private isClickOnPreviewImage(clickEventTarget: EventTarget): boolean {
  //   return this.sideImageHexagon.nativeElement.contains(clickEventTarget);
  // }

  public getPreviewImageUrl(): string {
    let previewImageUrl = "";
    if (this.hasGalleryImages()) {
      previewImageUrl = this.getUrlOfFirstGalleryImage();
    } else {
      previewImageUrl = environment.defaultPictureProducts;
    }
    return previewImageUrl;
  }

  public getUrlOfFirstGalleryImage(): string {
    // TODO: REST hat nichts in den Components zu suchen! Umbau in Service nötig
    // Die URL wird im Service gebaut
    return API_BASE_FILESERVICE + "/file/" + this.galleryPictureIds[0];
  }
}
