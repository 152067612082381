import { Injectable } from "@angular/core";
import { UserService } from "../user-service/userservice";
import { OrganisationService } from "../dataServices/organisation-service/organisationservice";
import { HttpClient } from "@angular/common/http";
import { API_BASE_USERSERVICE } from "src/environments/api";


@Injectable({
  providedIn: "root",
})
export class StartUpService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private organisationService: OrganisationService) {}

  async handleLogin() {
    return this.configureUserService().then((x) => {
      if (x || this.userService.loggedIn) {
        this.organisationService.loadOrganisationsForUser();
      }
    });
  }

  async configureUserService(): Promise<boolean> {
    return  new Promise((resolve, reject) => {

      if (this.isUserLoggedIn()) {
        var credentials = this.checkUserCredentials();

        credentials.then(
          //access credentials are correct
          (id) => {
            // set userId in userService

            this.userService.currentUser.userId = id;
            // reload data for user

            this.userService.fetchCurrentUser();
            // do login
            this.userService.loggedIn = true;

            this.userService.updateAuthorisation().then(
              (x) => {
                resolve(true);
              },
              (x) => {
                reject(false);
              }
            );
          },

          //access credentials are declined
          () => {
    this.loginAnonymous();
    resolve(false);
          }
        );
      } else {
    // wait for access token
    this.loginAnonymous().then((x) => {
      resolve(false);
    });

      }
    });

  }

  /**
   * Check if the local storage contains a refresh token, which indicates that the
   * user was logged in the last time he accessed the website.
   * @returns true if there is a refresh token in local storage else false
   */
  isUserLoggedIn(): boolean {
    //const currentUserId: number = Number(localStorage.getItem("swarmId"));
    const refresh_token: string = localStorage.getItem("refresh_token");

    //var hasUserId: boolean =
    //  typeof currentUserId === "undefined" ||
    // currentUserId === null ||
    // currentUserId === 0;
    return !!refresh_token;
  }

  /**
   * If the user is not logged in set authorisation to anonymous
   */
async  loginAnonymous(): Promise<boolean> {
    var url = API_BASE_USERSERVICE + "/users/login/anonymous";
  return new Promise((resolve, reject) => {  this.http.post<{ access_token: string }>(url, {}).subscribe((token) => {
      let isUserLoggedIn: string = localStorage.getItem("refresh_token");
      if (token && !isUserLoggedIn) {
        localStorage.setItem("access_token", token.access_token);
        //this.userService.currentUser.userName == "Anonymous";
        this.userService.currentUser.userId = 0;
        this.userService.getAnonymousAuthorisation();
        resolve(true);
      } else {
        this.userService.fetchCurrentUser();
        this.userService.loggedIn = true;
        resolve(true);

      }
    });

  });
  }

  /**
   * We check if user ID and access credentials match
   * //TODO
   */
  checkUserCredentials(): Promise<number> {
    var userId = -1;
    new Promise((resolve, reject) => {
      this.userService.getCurrentUser().subscribe((user) => {
        if (user) {
          this.userService.saveUser(user);
          userId = user.userId;
          resolve(userId);
        } else {
          reject(userId);
        }
      });
    });

    return Promise.resolve(1);
  }
}
