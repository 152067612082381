import { Pipe, PipeTransform } from '@angular/core';
import { ProductInterface } from '../model/products/product-interface';


@Pipe({ name: 'filterRatings' })
export class FilterRatingOfProducts implements PipeTransform {
  transform(products: ProductInterface[], rating: number[]) {

    if (null == rating || rating.length == 0) {
      return products;
    }

    const filteredProducts = [];

    for (const product of products) {
      for (const rat of rating) {
        if (rat === product.rating) {
          if (!filteredProducts.includes(product)) {
            filteredProducts.push(product)
          }
        }
      }
    }
    return filteredProducts;
  }
}
