import { Component, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-agb",
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.scss'],
})
export class DialogAgb {
  openPrivacy: any;
  constructor(
    public dialogRef: MatDialogRef<DialogAgb>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.openPrivacy = this.data["openPrivacy"];
  }

  onClick(confirmed: boolean): void {
    this.dialogRef.close(confirmed);
  }

  showPrivacy() {
    this.openPrivacy();
  }
}
