<table mat-table [dataSource]="cartItems" class="mat-elevation-z8">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let item">{{item.name}}</td>
    </ng-container>

    <!-- Quantity Column -->
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let item">{{item.quantity}}</td>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>Price</th>
      <td mat-cell *matCellDef="let item">{{item.price | currency:'EUR'}}</td>
    </ng-container>

    <!-- Total Column -->
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>Total</th>
      <td mat-cell *matCellDef="let item">{{item.quantity * item.price | currency:'EUR'}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div class="total-cost">
    <strong> {{getTotalCost() | currency:'EUR'}}</strong>
  </div>

  <button
  style="width: 125px"
  mat-raised-button
  class="btn_green"
>
  Bereitstellen
</button>
<button style="width: 125px"  mat-raised-button (click)="cancel()" class="btn_red">
Abbrechen
</button>
<button style="width: 125px" mat-raised-button (click)="cancel()" class="btn_red">
Schließen
</button>