import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'red-cross-button',
  templateUrl: './red-cross-button.component.html',
  styleUrls: ['./red-cross-button.component.css']
})
export class RedCrossButtonComponent implements OnInit {


  @Output()
  press = new EventEmitter()
  constructor() { }

  onPress(){
    this.press.emit();
  }

  ngOnInit() { }

}
