<div class="wrapper">
  <div class="inner">
    <h1>Aktivierungsmail senden</h1>
    <form
      [formGroup]="resendMailForm"
      (ngSubmit)="onSubmit()"
      (keydown.enter)="$event.preventDefault()"
    >
      <div class="left-column">
        <div>
          Geben Sie Ihre E-Mail-Adresse ein, um die Aktivierungsmail erneut zusenden.
          <div>
            <!------------------------------------------------------------------->
            <mat-form-field class="stretch">
              <input
                matInput
                type="email"
                placeholder="E-Mail"
                [formControl]="email"
                autocomplete="email"
                required
              />
              <mat-error *ngIf="email.errors?.required"
                >Bitte geben sie eine E-Mail an</mat-error
              >
              <mat-error
                *ngIf="email.errors?.pattern && !email.errors?.required"
                >Bitte geben Sie eine valide E-Mail an</mat-error
              >
            </mat-form-field>
            <div></div>
            <div class="buttons">
              <button
                class="btn_green"
                mat-raised-button
                type="submit"
                [disabled]="!email.valid"
              >
                Aktivierungsmail senden
              </button>
              <button class="btn_red" mat-raised-button (click)="onCancel()">
                Abbrechen
              </button>
            </div>
          </div>
          <!------------------------------------------------------------------->
        </div>
      </div>
    </form>
  </div>
</div>
