import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";

import { VariantVersionInterface } from "../../../model/products/variant-version-interface";
import { ProductDtoConverterServiceService } from "../../product-dto-converter-service/product-dto-converter-service.service";
import { UserService } from "../../user-service/userservice";
import { BaseService } from "../../base.service";
import { API_BASE_PRODUCTSERVICE, API_MAP } from "src/environments/api";

const SWARM_API_PRODUCTSERVICE = API_BASE_PRODUCTSERVICE;

@Injectable({
  providedIn: "root",
})
export class ProductVariantVersionService extends BaseService<
  VariantVersionInterface
> {
  protected equal(
    item1: VariantVersionInterface,
    item2: VariantVersionInterface
  ): boolean {
    return JSON.stringify(item1) === JSON.stringify(item2);
  }
  protected toID(item: VariantVersionInterface | number): number {
    if (!item) return -1;
    if (typeof item === "number") return item;
    if (item.id) return item.id;
    return -1;
  }

  activeOrganisation: number;

  constructor(
    protected http: HttpClient,
    private userService: UserService,
    protected dtoConverter: ProductDtoConverterServiceService
  ) {
    super(http, dtoConverter);
    //set the swarm API
    this.SWARM_API_BASE = SWARM_API_PRODUCTSERVICE;
    //-----------------------
    //-----------------------
    // Specify a item mapping to know which URL identifier is to be mapped
    // to witch information on an item
    this.ITEM_MAP = userService.ACTIVE_MAP;

    this.SWARM_API_MAP = API_MAP["versions"];
    this.SERVICE_FLAG = ":versionId";
    //-----------------------
  }

  deleteLocalVersion(version: VariantVersionInterface) {
    let index = this.collection.findIndex((x) => this.idCompare(x, version));
    if (index >= 0) {
      this.collection.splice(index, 1);
    }
  }

  /**
   * Downward propagation to update new loaded items into the subservice
   * @param version
   */
  updateVersionService(version: VariantVersionInterface) {
    this.updateItemInCollection(version);
  }

  setActiveVersion(version: VariantVersionInterface | number) {
    let id = this.toID(version);
    this.ITEM_MAP[":userId"] = String(this.userService.currentUser.userId);
    this.userService.ACTIVE_MAP[":versionId"] = String(id);
    return this.setActiveItem(version);
  }
}
