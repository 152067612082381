import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'productVersionsOfPtg'})
export class FilterProductVersionsOfPtg implements PipeTransform {
  transform(productVersions, ptg) {
    if (!ptg) {
      return productVersions;
    }

    const filteredVersions = [];

        for (const version of productVersions) {
          for (const versionPtgVersion of version.ptgVersions) {
            if (ptg.versions.find(version => version.id === versionPtgVersion.id)) {
              if (filteredVersions.indexOf(version) === -1) {
                filteredVersions.push(version);
              }
            }
          }
        }

    return filteredVersions;
  }
}
