import { Component, OnChanges, Input, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { API_MAP, API_BASE_FILESERVICE } from "src/environments/api";

@Component({
  selector: "app-secure-image",
  templateUrl: "./secure-image.component.html", // <img [src]="dataUrl$ && dataUrl$ | async" class="custom" />
  styleUrls: ["./secure-image.component.scss"],
})

export class SecureImageComponent implements OnChanges {
  @Input()
  public resize: boolean = true;

  @Input() productList = false;

  @Input() private fill: boolean = false;

  @Input() private circle: boolean = false;

  @Input() private contain: boolean = false;

  @Input() public displayItem: boolean = false;

  @Input() public organisation: boolean = false;

  @Input() public src: string;
  @Input() public isProfilPic: boolean = false;
  @Input() public isComment: boolean = false;
  @Input() public isScreen: boolean = false;



  @Input() private fileId: string = null;
  private fileId$ = new BehaviorSubject(this.fileId);
  dataUrl$ = this.fileId$.pipe(switchMap((fileId) => this.loadFile(fileId)));

  public isUploadedPicture: boolean = true;

  ngOnChanges(): void {
    this.fileId$.next(this.fileId);
  }

  constructor(
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer
  ) {}

  public loadImage(url: string): Observable<any> {
    return this.httpClient.get(url, { responseType: "blob" }).pipe(
      map((e) => {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(e)
        );
      })
    );
  }

  public loadFile(fileId: string): Observable<any> {
    if (fileId == null) {
      this.isUploadedPicture;
      return this.loadImage(this.src);
    }
    this.isUploadedPicture = true;
    return this.loadImage(
      API_BASE_FILESERVICE + API_MAP["files"]["GET"].replace(":fileId", fileId)
    );
  }
}
