import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CertificationRequestInterface } from 'src/app/model/certifications/certification-request-interface';
import { CertificationRequestLogInterface } from 'src/app/model/certifications/certification-request-log-interface';
import { CertificationRequestStatus } from 'src/app/model/enums/certification-requests-status';
import { CertificationRequestService } from 'src/app/services/dataServices/certification-request/certification-request.service';

@Component({
  selector: 'app-certification-requests',
  templateUrl: './certification-requests.component.html',
  styleUrls: ['./certification-requests.component.css']
})
export class CertificationRequestsComponent implements OnInit, OnDestroy {

  public certificationRequests: CertificationRequestInterface[] = [];
  constructor(
    private certificationRequestService: CertificationRequestService,
    private organisationService: OrganisationService,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit() {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
    this.certificationRequestService.getCertificationRequestsByOrganisation(1).then((certificationRequests: CertificationRequestInterface[]) => {
      this.certificationRequests = certificationRequests;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }

  private showDetails(i: number) {
    this.router.navigate([
      "/organisations/" +
        this.organisationService.activeItem.organisationId +
        "/certificationRequests/details"
    ]);
  }
}