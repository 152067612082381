import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { UserService } from "src/app/services/user-service/userservice";

@Component({
  selector: "app-malfunction-overview-organisation",
  templateUrl: "./malfunction-overview-organisation.component.html",
  styleUrls: ["./malfunction-overview-organisation.component.css"],
})
export class MalfunctionOverviewOrganisationComponent implements OnInit {
  showForm: boolean = false;
  showOverview: boolean = false;

  constructor(
    private userService: UserService,
    private organisationService: OrganisationService,
    private router: Router,
    private titleService: Title
  ) {
    //Reroutes to the Marketplace if the User is not a member of the Organisation
    if (
      !this.userService.currentMemberships.find(
        (orga) =>
          orga.organisationId ===
          this.organisationService.activeItem.organisationId
      )
    ) {
      this.router.navigateByUrl("/marktplatz");
    } else {
      //Fixes an Issue with the MatButtonToggles in the Overview Component if you redirect on loading
      this.showOverview = true;
    }
    this.titleService.setTitle(this.organisationService.activeItem.organisationName + ": Störungsmanagement - BIMSWARM");
  }

  ngOnInit() {}
}
