import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide : NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ToggleSwitchComponent),
  multi : true,
  };

@Component({
  selector: "toggle-switch",
  providers : [CUSTOM_VALUE_ACCESSOR],
  templateUrl: "./toggle-switch.component.html",
  styleUrls: ["./toggle-switch.component.css"]
})
export class ToggleSwitchComponent implements ControlValueAccessor, OnInit {

  @Input()
  trueLabel: string;
  @Input()
  falseLabel: string;
  @Input()
  value?: any;
  @Input()
  disableToggle: boolean
  @Output()
  valueChange = new EventEmitter<boolean>();
  @Input()
  allowUndefined: boolean;
  name = new Date().getTime()+"";

  public disabled: boolean;
  public onChange: Function;
  public onTouched: Function;

  constructor() {
  }

  changeValue(value: any){
    this.value = value;
    this.valueChange.emit(this.value);
    this.onChange(this.value);
    this.onTouched();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    this.disabled = false;
    if(this.value == null && !this.allowUndefined){
      this.value = false;
    }
    else if(this.value === undefined){
      this.value = null;
    }
    this.valueChange.emit(this.value)
  }
}
