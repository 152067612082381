import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AttributeInterface } from "src/app/model/attributes/attribute-interface";
import { ValidationInterface } from "src/app/model/attributes/validation-interface";
import { ProductService } from "src/app/services/dataServices/product-service/productService";
import { CertificationService } from "src/app/services/dataServices/certification-service/certificationService";
import { AttributeValidationServiceService } from "src/app/services/dataServices/dataValidation/attribute-validation-service/attribute-validation-service.service";

@Component({
  selector: "attribute-validation-table",
  templateUrl: "./attribute-validation-table.component.html",
  styleUrls: ["./attribute-validation-table.component.css"],
})
export class AttributeValidationTableComponent implements OnInit {
  @Input() editable?: boolean;

  @Input() optional?: boolean;

  @Input() attributes: AttributeInterface[];

  @Output() attributesChange: EventEmitter<
    AttributeInterface[]
  > = new EventEmitter<AttributeInterface[]>();

  @Output() attributesChanged: EventEmitter<
    AttributeInterface[]
  > = new EventEmitter<AttributeInterface[]>();

  @Output() attributesRemoved: EventEmitter<
    AttributeInterface
  > = new EventEmitter<AttributeInterface>();

  private activeAttributeId: number = 0;

  constructor(
    public attributeValidationService: AttributeValidationServiceService,
    public productService: ProductService,
    public certificationService: CertificationService
  ) {}

  ngOnInit() {
    this.attributeValidationService.loadValidation(
      this.attributes,
      this.optional
    );
  }

  removeAttribute(attribute: AttributeInterface) {
    const validationId = attribute.validations[0].id;
    this.attributes = this.attributes.filter((attribute) => {
      return attribute.validations[0].id !== validationId;
    });
    this.attributesRemoved.emit(attribute);
    this.attributesChanged.emit(this.attributes);
    this.attributesChange.emit(this.attributes);
  }

  public setAttributeActive(attributeId: number): void {
    if (this.activeAttributeId !== attributeId) {
      this.activeAttributeId = attributeId;
    } else {
      this.activeAttributeId = 0;
    }
  }

  public getMinValue(attribute: AttributeInterface): string {
    const minValue = this.attributeValidationService.getEffectiveMinValueForAttribute(
      attribute.id
    );
    if (minValue != null) {
      return minValue + "";
    } else {
      if (this.hasAttributeConflict(attribute)) {
        return "Ungültige Kombination von Merkmalen";
      }
      if (attribute.datatype === "NUMERIC") {
        return "-Unendlich";
      }
      return "0";
    }
  }

  public getMaxValue(attribute: AttributeInterface): string {
    const maxValue = this.attributeValidationService.getEffectiveMaxValueForAttribute(
      attribute.id
    );
    if (maxValue != null) {
      return maxValue + "";
    } else {
      if (this.hasAttributeConflict(attribute)) {
        return "Ungültige Kombination von Merkmalen";
      }
      return "Unendlich";
    }
  }

  public hasAttributeConflict(attribute: AttributeInterface): boolean {
    return this.attributeValidationService.hasAttributeConflict(attribute.id);
  }

  public getConflictErrorMessage(attribute: AttributeInterface): string {
    const isOptional = this.attributeValidationService.isAttributeEffectivelyOptional(
      attribute.id
    );
    const attributeName = attribute.name;
    let conflictMessage =
      "Es wurden mehrere Validierungen für das Merkmal " +
      attributeName +
      " ausgewählt, welche nicht gleichzeitig erfüllt werden können.";
    if (isOptional) {
      conflictMessage +=
        " Da es sich um ein optionales Merkmal handelt, ist nur keine Angabe valide";
    }
    return conflictMessage;
  }

  public getBooleanValue(attribute: AttributeInterface): boolean {
    return this.attributeValidationService.getEffectiveBooleanValueForAttribute(
      attribute.id
    );
  }
}
