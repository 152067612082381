import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComposerService } from 'src/app/services/composer-service/composer.service';

@Component({
  selector: 'app-dialog-toolchain-instance-upload-data',
  templateUrl: './dialog-toolchain-instance-upload-data.component.html',
  styleUrls: ['./dialog-toolchain-instance-upload-data.component.css']
})
export class DialogToolchainInstanceUploadDataComponent implements OnInit {

  public file: File;

  constructor(public dialogRef: MatDialogRef<DialogToolchainInstanceUploadDataComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: {containerId: string, contentId: string},
    private composerService: ComposerService) { }

  ngOnInit() {
  }

  /* Gets called when a File is selected */
  public onFileSelected(event) {
    if (event.srcElement) {
      this.file = event.srcElement.files[0];
      //Reset the Value of the Input Element, so that the same File can be uploaded multiple Times
      (document.getElementById("fileUpload") as HTMLInputElement).value = "";
    }
  }

  /* Close the Dialog without saving any Changes */
  public cancel(){
    this.dialogRef.close();
  }

  /* Close the Dialog after saving the Changes */
  public save(){
    this.composerService.uploadFile(this.dialogData.containerId, this.dialogData.contentId, this.file).subscribe();
    this.dialogRef.close();
  }
}
