export enum CheckBoxCategorie {
  PTG = "PTG",
  PTG_ATTRIBUTE = "PTG_ATTRIBUTE",
  ATTRIBUTE = "ATTRIBUTE",
  ORGANISATION = "ORGANISATION",
  CERTIFICATION_ORGANISATION = "CERTIFICATION_ORGANISATION",
  CERTIFICATION_ORGANISATION_ATTRIBUTE = "CERTIFICATION_ORGANISATION_ATTRIBUTE",
  CERTIFICATION_ORGANISATION_PTG = "CERTIFICATION_ORGANISATION_PTG",
  CERTIFICATION_ORGANISATION_TOOLCHAIN = "CERTIFICATION_ORGANISATION_TOOLCHAIN",
  PRODUCT_ORGANISATION = "PRODUCT_ORGANISATION",
  PRODUCT_ORGANISATION_APPLICATION = "PRODUCT_ORGANISATION_APPLICATION",
  PRODUCT_ORGANISATION_SERVICE = "PRODUCT_ORGANISATION_SERVICE",
  PRODUCT_ORGANISATION_CONTENT = "PRODUCT_ORGANISATION_CONTENT",
  PRODUCT_ORGANISATION_ALLGEMEIN = "PRODUCT_ORGANISATION_ALLGEMEIN",
  PRODUCT_ORGANISATION_HOCHBAU = "PRODUCT_ORGANISATION_HOCHBAU",
  PRODUCT_ORGANISATION_TIEFBAU = "PRODUCT_ORGANISATION_TIEFBAU",
  PRODUCT_ORGANISATION_WASSERBAU = "PRODUCT_ORGANISATION_WASSERBAU",
  PRODUCT_ORGANISATION_EISENBAHNBAU = "PRODUCT_ORGANISATION_EISENBAHNBAU",
  PRODUCT_ORGANISATION_STRASENBAU = "PRODUCT_ORGANISATION_STRASENBAU",
  PRODUCT_ORGANISATION_INFRASTRUKTUR = "PRODUCT_ORGANISATION_INFRASTRUKTUR",
  CERTIFICATION_TYPE = "CERTIFICATION_TYPE",
  PRODUCT_CATEGORY = "PRODUCT_CATEGORY",
  COMMON_FEATURE_ENTRY = "COMMON_FEATURE_ENTRY",
  COMMON_FEATURE = "COMMON_FEATURE",
  CERTIFICATION = "CERTIFICATION",
  ATTR_CERTIFICATION =  "ATTR_CERTIFICATION",
  TOOLCHAIN_CERTIFICATION = "TOOLCHAIN_CERTIFICATION" ,
  PTG_CERTIFICATION = "PTG_CERTIFICATION",
  PRODUCTS = "PRODUCTS",
  APP_PRODUCTS= "APP_PRODUCTS",
  SERVICE_PRODUCTS= "SERVICE_PRODUCTS",
  CONTENT_PRODUCTS= "CONTENT_PRODUCTS",
  ALLGEMEIN_PRODUCTS= "ALLGEMEIN_PRODUCTS",
  HOCHBAU_PRODUCTS= "HOCHBAU_PRODUCTS",
  WASSERBAU_PRODUCTS= "WASSERBAU_PRODUCTS",
  TIEFBAU_PRODUCTS= "TIEFBAU_PRODUCTS",
  STRASENBAU_PRODUCTS= "STRASENBAU_PRODUCTS",
  EISENBAHNBAU_PRODUCTS= "EISENBAHNBAU_PRODUCTS",
  INFRASTRUKTUR_PRODUCTS= "INFRASTRUKTUR_PRODUCTS",
  RATING = "PRODUCT_RATING"
}

export class MarketplaceCheckbox {
  constructor(
    id: number,
    categorie: CheckBoxCategorie,
    label: string,
    ptgId: number,
    ptgOptional: boolean,
    isChecked: boolean,
    isVisible: boolean
  ) {
    this.id = id;
    this.categorie = categorie;
    this.label = label;
    this.ptgId = ptgId;
    this.ptgOptional = ptgOptional;
    this.isChecked = isChecked;
    this.isVisible = isVisible;
  }

  id: number;
  categorie: CheckBoxCategorie;
  label: string;
  ptgId: number;
  ptgOptional: boolean;
  isChecked: boolean;
  isVisible: boolean;
}
