import { LinkingMethod } from '../enums/linkingMethod';
import { CommonFeatureEntryInterface } from "./common-feature-entry-interface";

export class CommonFeatureInterface {
  id: number;
  name: string;
  filterOption: boolean;
  featureEntries: CommonFeatureEntryInterface[];
  showDetailPage: boolean;
  linkingMethod: LinkingMethod;
  position: number;
}
