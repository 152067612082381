<div class="grid">
  <div class="table">
    <h3>{{review.headline}}</h3>
    <div class="star_wrapper">
      <app-star style="float: left" [rating]="review.stars"></app-star>
      <div class="publish_date">
        {{ review.publishDate | humanReadableDate }}
      </div>
    </div>
  </div>
  <div class="buttons">
    <button
      class="btn_green"
      mat-raised-button
      (click)="toggleShowReports()"
    >
      {{toggleButtonText}}
    </button>
    <button class="btn_red" mat-raised-button (click)="deleteReview(review)">
      Bewertung löschen
    </button>
  </div>
</div>
<p class="indent"><b>Bewertungstext: </b>{{review.reviewText}}</p>
<div *ngIf="showReports">
  <div class="indent row border">
    <p class="closer-to-border"><b>Meldungen</b></p>
  </div>
  <div *ngFor="let report of review.reports; let i = index">
    <div *ngIf="report.reportStatus === 'UNCHECKED'" class="items grid indent">
      <div class="entry">
        <p><b>Meldegrund:</b> {{ report.reportReason }}</p>
        <p><b>Gemeldet:</b> {{ report.reportedTimestamp | humanReadableDate}}</p>
      </div>
      <div class="buttons">
        <button
        class="btn_red"
        mat-raised-button
        (click)="deleteReviewReport(report)"
        >
          Meldung löschen
        </button>
      </div>
    </div> 
  </div>
</div>