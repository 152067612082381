import { TrackingService } from './../../../../services/tracking-service/tracking.service';
import { MarketplaceFilterInterface } from 'src/app/model/marketplace/marketplace-filter-interface';
import { map, startWith, take } from 'rxjs/operators';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  Input,
} from "@angular/core";
import { MarketplaceFilterService } from "src/app/services/componentServices/marketplace-filter-service/marketplace-filter.service";
import {
  MarketplaceCheckbox,
  CheckBoxCategorie,
} from "src/app/model/marketplace/marketplace-checkbox";
import { MarketplaceService } from "src/app/services/dataServices/marketplace-service/marketplace.service";
import { DataType } from "src/app/model/data-type.enum";
import { FilterCheckboxDtoInterface } from "src/app/model/marketplace/filter-checkbox-interface";
import { ProductCategory } from "src/app/model/enums/productCategory";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { OrganisationType } from "src/app/model/enums/organisationType";
import { CertificationTypes } from "src/app/model/enums/certificationTypes";
import { SortMethod } from "src/app/model/enums/SortMethod";
import { ProductService } from 'src/app/services/dataServices/product-service/productService';
import { CertificationService } from 'src/app/services/dataServices/certification-service/certificationService';
import { CertificationOverviewInterface } from 'src/app/model/marketplace/certification-overview-interface';
import { CertificationInterface } from 'src/app/model/certifications/certification-interface';
import { CertificationVariantService } from 'src/app/services/dataServices/certification-variant-service/certification-variant-service';
import { BehaviorSubject, merge, Observable, Subscription, Unsubscribable } from 'rxjs';
import { ProductCertificationService } from 'src/app/services/product-certification-service/product-certification.service';
import { FilterCommonFeature } from 'src/app/model/marketplace/filter-common-feature';
import { FilterCommonFeatureEntry } from 'src/app/model/marketplace/filter-common-feature-entry';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { FilterOrganisationProduct } from 'src/app/model/marketplace/filter-organisationProduct';
import { FilterOrganisationCertification } from 'src/app/model/marketplace/filter-organisationCertification';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FilterProductCategory } from 'src/app/model/marketplace/filter-product-category';
import { CheckboxControlValueAccessor, FormControl } from '@angular/forms';
import { LinkingMethod } from 'src/app/model/enums/linkingMethod';
import { filter } from 'lodash';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PtgService } from 'src/app/services/dataServices/ptg-service/ptgService';
import { NgIfContext } from '@angular/common';

@Component({
  selector: "app-market-place-side-filter",
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: AUTO_STYLE,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
        })
      ),
      transition('* => *', [animate('0.2s ease-out')]),
    ]),
  ],
  templateUrl: "./market-place-side-filter.component.html",
  styleUrls: ["./market-place-side-filter.component.scss"],
})



export class MarketPlaceSideFilterComponent implements OnInit, OnDestroy {

  public queryParamSubscription: Subscription;

  @Input()
  filterReset: boolean = false;
  filterString: string[] = [];
  public prodControl = new FormControl();
  filteredOptions: Observable<string[]>;

  public marketScreenState: DataType; //MarketScreenState.Products;
  private dataTypeEnum = DataType;

  public changeDetected: boolean = false;
  public subscription: Subscription;
  public filterSubscription: Subscription;
  public showProviderFilter: boolean = false;
  public showSecondProviderFilter: boolean = false;
  public providerFilterString: string = '';
  public secondProviderFilterString: string = '';

  public showProducttypeFilter: boolean = false;
  public producttypeFilterString: string = '';

  public sortOptions = [
    { name: SortMethod.ALPHABETICAL_ASC, title: "Produktname" },
    { name: SortMethod.ORGANISATION, title: "Anbieter" },
  ];

  public sortOrder = SortMethod.ALPHABETICAL_ASC;

  public commonFeatures: FilterCommonFeature[];

  public ptgCheckboxesOnlyInitial: {
    id: number;
    category: string;
    name: string;
    checked: boolean;
  }[] = [];
  public ptgCheckboxesShow: {
    id: number;
    category: string;
    name: string;
    checked: boolean;
  }[] = [];
  public organisationCheckboxesOnlyInitial: {
    id: number;
    category: string;
    name: string;
    checked: boolean;
  }[] = [];
  public organisationCheckboxesShow: {
    id: number;
    category: string;
    name: string;
    checked: boolean;
  }[] = [];
  public certificationCheckboxesShow: {
    id: number;
    category: string;
    name: string;
    checked: boolean;
  };
  public _commonFeatureCheckboxes: {
    id: number;
    category: string;
    name: string;
    checked: boolean;
  }[] = [];

  public searchText: string = ""

  public showProductCategoryBoxes: boolean = false;
  public showCertificationTypeBoxes: boolean = false;
  public showOrganisationBoxes: boolean = false;
  public showPtgBoxes: boolean = false;
  public showCommonFeatureBoxes: boolean = false;

  private activeOrgaType: OrganisationType;

  private orgaCertificationFromFilter: FilterOrganisationCertification[];
  private orgaProductsFromFilter: FilterOrganisationProduct[];

  public productCategoryCheckboxItems: {
    name: string;
    checked: boolean;
    value: ProductCategory;
  }[] = [
      //{ name: "Applikationen", checked: false, value: ProductCategory.APP },
      //{ name: "Services", checked: false, value: ProductCategory.SERVICE },
      //{ name: "Content", checked: false, value: ProductCategory.CONTENT,},
      { name: "Allgemein", checked: false, value: ProductCategory.ALLGEMEIN,},
      { name: "Hochbau", checked: false, value: ProductCategory.HOCHBAU,},
      { name: "Tiefbau", checked: false, value: ProductCategory.TIEFBAU,},
      { name: "Wasserbau", checked: false, value: ProductCategory.WASSERBAU,},
      { name: "Straßenbaz", checked: false, value: ProductCategory.STRASENBAU,},
      { name: "Eisenbahnbau", checked: false, value: ProductCategory.EISENBAHNBAU,},
      { name: "Infrastruktur", checked: false, value: ProductCategory.INFRASTRUKTUR,}
    ];

  // Stellt die Filtermoeglichkeiten nach Bewertungen da
  public productRatingCheckboxItem: {
    rating: string;
    checked: boolean;
    value: CheckBoxCategorie;
  }[] = [
      { rating: "5", checked: false, value: CheckBoxCategorie.RATING },
      { rating: "4", checked: false, value: CheckBoxCategorie.RATING },
      { rating: "3", checked: false, value: CheckBoxCategorie.RATING },
      { rating: "2", checked: false, value: CheckBoxCategorie.RATING },
      { rating: "1", checked: false, value: CheckBoxCategorie.RATING },
      { rating: "Keine Bewertung", checked: false, value: null }
    ];

  public certificationCategoryCheckboxItems: {
    name: string;
    checked: boolean;
    value: CertificationTypes;
  }[] = [
      {
        name: "Merkmal-Zertifizierungen",
        checked: true,
        value: CertificationTypes.ATTRIBUTE_CERT,
      },
      {
        name: "Produkttyp-Zertifizierungen",
        checked: true,
        value: CertificationTypes.PTG_CERT,
      },
      // {
      //   name: "Toolchain-Zertifizierungen",
      //   checked: false,
      //   value: CertificationTypes.TOOLCHAIN_CERT,
      // }
    ];
  /**
   * checkbox values for subcategories for the marketplace filter
   */
  public certificationSubCategoriesCheckBoxItems: {
    name: string;
    checked: boolean;
    value: CertificationTypes;
  }[] = [
      {
        name: "Alle",
        checked: false,
        value: null,
      },
    ];
  certification: CertificationInterface;

  /**
   * checkbox values for subcategories for the marketplace filter
   */
  public applicationSubCategoriesCheckBoxItems: {
    name: string;
    checked: boolean;
    checkBoxCategory: CheckBoxCategorie;
  }[] = [
      {
        name: "Alle",
        checked: false,
        checkBoxCategory: CheckBoxCategorie.APP_PRODUCTS,
      },
    ];

  public doNothing() {

  }


  constructor(
    private marketPlaceFilterService: MarketplaceFilterService,
    private marketPlaceService: MarketplaceService,
    private certificationService: CertificationService,
    private productService: ProductService,
    private ptgService: PtgService,
    private certificationVariantService: CertificationVariantService,
    private productCertificationService: ProductCertificationService,
    private trackingService: TrackingService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  get categoryCheckboxes() {
    return this._checkBoxes[CheckBoxCategorie.PRODUCT_CATEGORY];
  }

  get ptgCheckboxes() {
    return this._checkBoxes[CheckBoxCategorie.PTG];
  }

  get ptgAttributeCheckboxes() {
    return this._checkBoxes[CheckBoxCategorie.PTG_ATTRIBUTE].sort(((a,b) => Number(a.ptgOptional) - Number(b.ptgOptional)));
  }

  get organisationCheckboxes() {
    return this._checkBoxes[CheckBoxCategorie.ORGANISATION];
  }

  get attributeCertificationOrganisationCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION_ATTRIBUTE];
  }

  get ptgCertificationOrganisationCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION_PTG];
  }

  get toolchainCertificationOrganisationCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION_TOOLCHAIN];
  }

  get commonFeatureCheckboxes() {
    return this._checkBoxes[CheckBoxCategorie.COMMON_FEATURE_ENTRY];
  }

  get certificationCheckboxes() {
    return this._checkBoxes[CheckBoxCategorie.CERTIFICATION];
  }

  get attr_certificationCheckboxes() {
    return this._checkBoxes[CheckBoxCategorie.ATTR_CERTIFICATION];
  }

  get toolChain_certificationCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.TOOLCHAIN_CERTIFICATION];
  }

  get ptg_certificationChecbkBoxes() {
    return this._checkBoxes[CheckBoxCategorie.PTG_CERTIFICATION];
  }

  get productCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.PRODUCTS];
  }

  get appProductCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.APP_PRODUCTS];
  }


  get serviceProductCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.SERVICE_PRODUCTS];
  }

  get contentProductCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.CONTENT_PRODUCTS];
  }
  get hochbauProductCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.HOCHBAU_PRODUCTS];
    }
  get tiefbauProductCheckBoxes() {
      return this._checkBoxes[CheckBoxCategorie.TIEFBAU_PRODUCTS];
      }
  get wasserbauProductCheckBoxes() {
        return this._checkBoxes[CheckBoxCategorie.WASSERBAU_PRODUCTS];
        }        
  get strasenbauProductCheckBoxes() {
          return this._checkBoxes[CheckBoxCategorie.STRASENBAU_PRODUCTS];
          }
  get eisenbahnbauProductCheckBoxes() {
            return this._checkBoxes[CheckBoxCategorie.EISENBAHNBAU_PRODUCTS];
            }          
  get infrastrukturProductCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.INFRASTRUKTUR_PRODUCTS];
  }
   get allgemeinProductCheckBoxes() {
      return this._checkBoxes[CheckBoxCategorie.ALLGEMEIN_PRODUCTS];
    }
  get productOrganisationApplicationCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_APPLICATION];
  }

  get productOrganisationServiceCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_SERVICE];
  }

  get productOrganisationContentCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_CONTENT];
  }
  get productOrganisationHochbauCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_HOCHBAU];
  }
  get productOrganisationTiefbauCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_TIEFBAU];
  }
    get productOrganisationWasserbauCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_WASSERBAU];
  }
  get productOrganisationStrasenbauCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_STRASENBAU];
  }
  get productOrganisationEisenbahnbauCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_EISENBAHNBAU];
  }

  get productOrganisationInfrastrukturCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_INFRASTRUKTUR];
  }

  get productOrganisationAllgemeinCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_ALLGEMEIN];
  }
  get certificationOrganisationCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION];
  }
  get ratingCheckBoxes() {
    return this._checkBoxes[CheckBoxCategorie.RATING];
  }

  private _checkBoxes: { [category: string]: MarketplaceCheckbox[] } = {
    PTG: [],
    PTG_ATTRIBUTE: [],
    ATTRIBUTE: [],
    ORGANISATION: [],
    CERTIFICATION_ORGANISATION_ATTRIBUTE: [],
    CERTIFICATION_ORGANISATION_PTG: [],
    CERTIFICATION_ORGANISATION_TOOLCHAIN: [],
    PRODUCT_ORGANISATION_APPLICATION: [],
    PRODUCT_ORGANISATION_SERVICE: [],
    PRODUCT_ORGANISATION_CONTENT: [],
    PRODUCT_ORGANISATION_ALLGEMEIN: [],
    PRODUCT_ORGANISATION_HOCHBAU: [],
    PRODUCT_ORGANISATION_EISENBAHNBAU: [],
    PRODUCT_ORGANISATION_TIEFBAU: [],
    PRODUCT_ORGANISATION_WASSERBAU: [],
    PRODUCT_ORGANISATION_STRASENBAU: [],
    PRODUCT_ORGANISATION_INFRASTRUKTUR: [],
    CERTIFICATION_ORGANISATION: [],
    COMMON_FEATURE: [],
    //
    CERTIFICATION: [],
    ATTR_CERTIFICATION: [],
    TOOLCHAIN_CERTIFICATION: [],
    PTG_CERTIFICATION: [],
    // monday 4.01
    PRODUCTS: [],
    APP_PRODUCTS: [],
    SERVICE_PRODUCTS: [],
    CONTENT_PRODUCTS: [],
    ALLGEMEIN_PRODUCTS: [],
    HOCHBAU_PRODUCTS: [],
    TIEFBAU_PRODUCTS: [],
    WASSERBAU_PRODUCTS: [],
    EISENBAHNBAU_PRODUCTS: [],
    STRASENBAU_PRODUCTS: [],
    INFRASTRUKTUR_PRODUCTS: [],
    //
    RATING: []
  };

  /**
   * gibt eine Liste mit allen ausgewählten Produktkategorien wieder
   */
  private getCheckedProductCategories(): ProductCategory[] {
    let checkedCategories: ProductCategory[] = [];
    this.productCategoryCheckboxItems.forEach((checkbox) => {
      if (checkbox.checked) {
        checkedCategories.push(checkbox.value);
      }
    });


    return checkedCategories;
  }

  /**
   * gibt eine Liste mit allen ausgewählten Zertifikatstypen wieder
   */
  private getCheckedCertificationTypes(): CertificationTypes[] {
    let checkedCertTypes: CertificationTypes[] = [];
    this.certificationCategoryCheckboxItems.forEach((checkbox) => {
      if (checkbox.checked) {
        checkedCertTypes.push(checkbox.value);
      }
    });
    return checkedCertTypes;
  }

  /**
   * Generates and adds a Checkbox to the specific category of _checkBoxes if the Category does not yet exist
   *
   * @param id
   * @param label
   * @param category
   * @param isVisible
   * @param isCheck
   */
  public addCheckBox(
    id: number,
    label: string,
    category: CheckBoxCategorie,
    ptgId: number,
    ptgOptional: boolean,
    isVisible: boolean,
    isCheck: boolean
  ) {
    // select category

    var boxes = this._checkBoxes[category];
    // if boxes dont have the box with id
    if (
      !boxes
        .map((x) => {
          return x.id;
        })
        .includes(id)
    ) {
      // add the new box
      let newBox = new MarketplaceCheckbox(
        id,
        category,
        label,
        ptgId,
        ptgOptional,
        isCheck,
        isVisible
      );
      if (boxes.length == 0) {
        boxes.push(newBox);
        return;
      }

      let i = 0;
      while (boxes.length != i && boxes[i].label.localeCompare(label) < 1) {
        i++;
      }
      boxes.splice(i, 0, newBox);
    }
  }

  /**
   * Sets isChecked for the given checkbox or boxes to true.
   *
   * @param id Id or array of Ids to identifiy checkbox by
   * @param category type of checkbox
   * @returns true if the checkbox or all checkboxes exists, else false
   */
  public setChecked(
    id: number | number[],
    category: CheckBoxCategorie
  ): boolean {
    if (!id) {
      return false;
    }
    // typeguard for array
    if (typeof id === "number") {
      // single id function
      //--------------------------------------------------
      var boxes = this._checkBoxes[category]; // get matching checkboxes
      var index = boxes.findIndex((x) => x.id == id);
      if (index > 0) {
        // it it exists set it checked
        boxes[index].isChecked = true;

        return true;
      }
      return false;
      //--------------------------------------------------
    }
    if (id.length >= 1) {
      // recursive calling of the single number function
      // map basically becomes a foreach here
      return id
        .map((x) => this.setChecked(x, category))
        .reduce((x, y) => x && y);
    }
  }

  /**
   * Queries the Backend for all active attributes, orgnisations, PTgs and Common Features
   * to generate the initial checkboxes from.
   * Distinguishes between product screen state and certification screenstate to send the correct request
   * @param params The query parameters from the url: needed for checkbox initialization
   */
  initialiseCheckboxes(params: Params): void {
    this.marketPlaceService
      .getOfActiveEntities()
      .subscribe((filterData: FilterCheckboxDtoInterface) => {
        const mpFilter = this.marketPlaceFilterService.filter;

        this.initialiseAttributeCheckBoxes(filterData.attributes, mpFilter);
        this.initialiseCertificationCheckBoxes(filterData.certifications, mpFilter);
        this.initialiseProductCheckboxesByCategory(filterData.products);

        this.orgaCertificationFromFilter = filterData.organisationCertifications;
        this.orgaProductsFromFilter = filterData.organisationProducts;

        this.initializeOrganisationCheckBoxes();
        this.initialisePtgCheckboxes(filterData.ptgs, filterData.ptgAttr, mpFilter);
        this.commonFeatures = filterData.commonFeatures;


        //Set checked filter boxes from urls query params, the filter init happens in marketplace-filter service updateFromService

        this.setCheckedBoxesFromQueryParams(params);
      });
  }

  private initialiseAttributeCheckBoxes(attributeMap: Map<number, string>, mpFilter: MarketplaceFilterInterface) {
    Object.keys(attributeMap).forEach((id) => {
      this.addCheckBox(
        Number(id),
        attributeMap[id],
        CheckBoxCategorie.ATTRIBUTE,
        undefined,
        undefined,
        true,
        mpFilter.attributeIds.includes(Number(id))
      );
    });

  }

  private initialiseCertificationCheckBoxes(certificationMap: Map<number, string>, mpFilter: MarketplaceFilterInterface) {
    Object.keys(certificationMap).forEach((certification: string) => {
      this.addCheckBox(
        Number(certification),
        certificationMap[certification],
        CheckBoxCategorie.CERTIFICATION,
        undefined,
        undefined,
        true,
        mpFilter.certificationIds.includes(Number(certification))
      );
    });
  }

  private initialiseProductCheckboxesByCategory(products: FilterProductCategory[]) {
    for (let product of products) {
      if (product.category == ProductCategory.APP) {
        this.addCheckBox(product.id, product.name, CheckBoxCategorie.APP_PRODUCTS, undefined, undefined, false, false);
      }
      if (product.category == ProductCategory.CONTENT) {
        this.addCheckBox(product.id, product.name, CheckBoxCategorie.CONTENT_PRODUCTS, undefined, undefined, false, false);
      }
      if (product.category == ProductCategory.ALLGEMEIN) {
        this.addCheckBox(product.id, product.name, CheckBoxCategorie.ALLGEMEIN_PRODUCTS, undefined, undefined, false, false);
      }
      if (product.category == ProductCategory.HOCHBAU) {
        this.addCheckBox(product.id, product.name, CheckBoxCategorie.HOCHBAU_PRODUCTS, undefined, undefined, false, false);
      }
      if (product.category == ProductCategory.TIEFBAU) {
        this.addCheckBox(product.id, product.name, CheckBoxCategorie.TIEFBAU_PRODUCTS, undefined, undefined, false, false);
      }
      if (product.category == ProductCategory.WASSERBAU) {
        this.addCheckBox(product.id, product.name, CheckBoxCategorie.WASSERBAU_PRODUCTS, undefined, undefined, false, false);
      }
      if (product.category == ProductCategory.STRASENBAU) {
        this.addCheckBox(product.id, product.name, CheckBoxCategorie.STRASENBAU_PRODUCTS, undefined, undefined, false, false);
      }
            if (product.category == ProductCategory.EISENBAHNBAU) {
        this.addCheckBox(product.id, product.name, CheckBoxCategorie.EISENBAHNBAU_PRODUCTS, undefined, undefined, false, false);
      }
      if (product.category == ProductCategory.INFRASTRUKTUR) {
        this.addCheckBox(product.id, product.name, CheckBoxCategorie.INFRASTRUKTUR_PRODUCTS, undefined, undefined, false, false);
      }
      if (product.category == ProductCategory.SERVICE) {
        this.addCheckBox(product.id, product.name, CheckBoxCategorie.SERVICE_PRODUCTS, undefined, undefined, false, false);
      }
    }
  }

  private initialisePtgCheckboxes(ptgMap: Map<number, string>, ptgAttrMap: Map<number, Map<number, string>>, mpFilter: MarketplaceFilterInterface) {
    let skipOverEntry: boolean = false;
    Object.keys(ptgMap).forEach((id) => {
      if(ptgAttrMap){
        Object.keys(ptgAttrMap).forEach((ptgAttrEntryId) => {
          if (ptgAttrMap[ptgAttrEntryId][id] != undefined) {
            this._checkBoxes[CheckBoxCategorie.PTG_ATTRIBUTE].forEach((entry) => {
              if (entry.ptgId === Number(id) && entry.label == ptgAttrMap[ptgAttrEntryId][id])
                skipOverEntry = true;
            })
            if (!skipOverEntry) {
              this.addCheckBox(
                Number(ptgAttrEntryId),
                ptgAttrMap[ptgAttrEntryId][id].first,
                CheckBoxCategorie.PTG_ATTRIBUTE,
                Number(id),
                ptgAttrMap[ptgAttrEntryId][id].second,
                true,
                mpFilter.ptgAttrIds.includes(Number(ptgAttrEntryId))
              )
            }
            skipOverEntry = false;
          }

        });
      }
      this.addCheckBox(
        Number(id),
        ptgMap[id],
        CheckBoxCategorie.PTG,
        undefined,
        undefined,
        true,
        mpFilter.ptgIds.includes(Number(id))
      );
    });
  }

  /**
   * initilialisert die CheckBoxen der Organisation neu abhängig vom MarketScreenState
   */
  private initializeOrganisationCheckBoxes() {
    switch (this.marketScreenState) {
      case DataType.PRODUCT:
        this.initialiseOrganisationBoxesFromArray(this.orgaProductsFromFilter, CheckBoxCategorie.ORGANISATION);
        break;
      case DataType.CERTIFICATION:
        this.initialiseOrganisationBoxesFromArray(this.orgaCertificationFromFilter, CheckBoxCategorie.ORGANISATION);
        break;
      case DataType.ORGANISATION:
        //Organisation screenstate: both filter returns (organisationProducts & organisationCertifications) are needed
        //For each product provider: Initialise checkbox by category
        this.initialiseProductOrganisationBoxesByCategoryFromArray(this.orgaProductsFromFilter);
        //For each certification provider: Initialise checkbox by type
        this.initialiseCertificationOrganisationBoxesByTypeFromArray(this.orgaCertificationFromFilter)
        break;
    }
  }

  private initialiseOrganisationBoxesFromArray(boxInfoArray: any[], category: CheckBoxCategorie) {
    this.clearCheckboxByCategory(category);
    let organisationBoxesInfo: { organisationName: string, organisationId: number }[] = [];
    boxInfoArray.forEach((organisation) => {
      let organisationBoxInfo = {
        organisationName: organisation.name,
        organisationId: organisation.id
      }
      organisationBoxesInfo.push(organisationBoxInfo);
    })
    this.addCheckBoxForAllOrgaKeys(organisationBoxesInfo, category);
  }

  private initialiseProductOrganisationBoxesByCategoryFromArray(boxInfoArray: FilterOrganisationProduct[]) {
    this.clearCheckboxByCategory(CheckBoxCategorie.PRODUCT_ORGANISATION_APPLICATION);
    this.clearCheckboxByCategory(CheckBoxCategorie.PRODUCT_ORGANISATION_SERVICE);
    this.clearCheckboxByCategory(CheckBoxCategorie.PRODUCT_ORGANISATION_CONTENT);
    this.clearCheckboxByCategory(CheckBoxCategorie.PRODUCT_ORGANISATION_ALLGEMEIN);
    this.clearCheckboxByCategory(CheckBoxCategorie.PRODUCT_ORGANISATION_HOCHBAU);
    this.clearCheckboxByCategory(CheckBoxCategorie.PRODUCT_ORGANISATION_TIEFBAU);
    this.clearCheckboxByCategory(CheckBoxCategorie.PRODUCT_ORGANISATION_WASSERBAU);
    this.clearCheckboxByCategory(CheckBoxCategorie.PRODUCT_ORGANISATION_STRASENBAU);
    this.clearCheckboxByCategory(CheckBoxCategorie.PRODUCT_ORGANISATION_EISENBAHNBAU);
    this.clearCheckboxByCategory(CheckBoxCategorie.PRODUCT_ORGANISATION_INFRASTRUKTUR);
    let productOrganisationsApplication: { organisationName: string, organisationId: number }[] = [];
    let productOrganisationService: { organisationName: string, organisationId: number }[] = [];
    let productOrganisationContent: { organisationName: string, organisationId: number }[] = [];
    let productOrganisationAllgemein: { organisationName: string, organisationId: number }[] = [];
    let productOrganisationHochbau: { organisationName: string, organisationId: number }[] = [];
    let productOrganisationTiefbau: { organisationName: string, organisationId: number }[] = [];
    let productOrganisationWasserbau: { organisationName: string, organisationId: number }[] = [];
    let productOrganisationStrasenbau: { organisationName: string, organisationId: number }[] = [];
    let productOrganisationEisenbahnbau: { organisationName: string, organisationId: number }[] = [];
    let productOrganisationInfrastruktur: { organisationName: string, organisationId: number }[] = [];

    boxInfoArray.forEach((organisation) => {
      let organisationBoxInfo = {
        organisationName: organisation.name,
        organisationId: organisation.id
      }
      if (organisation.hasActiveApplicationProduct) productOrganisationsApplication.push(organisationBoxInfo);
      if (organisation.hasActiveServiceProduct) productOrganisationService.push(organisationBoxInfo);
      if (organisation.hasActiveContentProduct) productOrganisationContent.push(organisationBoxInfo);
      if (organisation.hasActiveAllgemeinProduct) productOrganisationAllgemein.push(organisationBoxInfo);
      if (organisation.hasActiveHochbauProduct) productOrganisationHochbau.push(organisationBoxInfo);
      if (organisation.hasActiveTiefbauProduct) productOrganisationTiefbau.push(organisationBoxInfo);
      if (organisation.hasActiveWasserbauProduct) productOrganisationWasserbau.push(organisationBoxInfo);
      if (organisation.hasActiveStrasenbauProduct) productOrganisationStrasenbau.push(organisationBoxInfo);
      if (organisation.hasActiveEisenbahnbauProduct) productOrganisationEisenbahnbau.push(organisationBoxInfo);
      if (organisation.hasActiveInfrastrukturProduct) productOrganisationInfrastruktur.push(organisationBoxInfo);
    })
    this.addCheckBoxForAllOrgaKeys(productOrganisationsApplication, CheckBoxCategorie.PRODUCT_ORGANISATION_APPLICATION);
    this.addCheckBoxForAllOrgaKeys(productOrganisationService, CheckBoxCategorie.PRODUCT_ORGANISATION_SERVICE);
    this.addCheckBoxForAllOrgaKeys(productOrganisationContent, CheckBoxCategorie.PRODUCT_ORGANISATION_CONTENT);
    this.addCheckBoxForAllOrgaKeys(productOrganisationAllgemein, CheckBoxCategorie.PRODUCT_ORGANISATION_ALLGEMEIN);
    this.addCheckBoxForAllOrgaKeys(productOrganisationHochbau, CheckBoxCategorie.PRODUCT_ORGANISATION_HOCHBAU);
    this.addCheckBoxForAllOrgaKeys(productOrganisationTiefbau, CheckBoxCategorie.PRODUCT_ORGANISATION_TIEFBAU);
    this.addCheckBoxForAllOrgaKeys(productOrganisationWasserbau, CheckBoxCategorie.PRODUCT_ORGANISATION_WASSERBAU);
    this.addCheckBoxForAllOrgaKeys(productOrganisationStrasenbau, CheckBoxCategorie.PRODUCT_ORGANISATION_STRASENBAU);
    this.addCheckBoxForAllOrgaKeys(productOrganisationEisenbahnbau, CheckBoxCategorie.PRODUCT_ORGANISATION_EISENBAHNBAU);
    this.addCheckBoxForAllOrgaKeys(productOrganisationInfrastruktur, CheckBoxCategorie.PRODUCT_ORGANISATION_INFRASTRUKTUR);
  }

  private initialiseCertificationOrganisationBoxesByTypeFromArray(boxInfoArray: FilterOrganisationCertification[]) {
    this.clearCheckboxByCategory(CheckBoxCategorie.CERTIFICATION_ORGANISATION_ATTRIBUTE);
    this.clearCheckboxByCategory(CheckBoxCategorie.CERTIFICATION_ORGANISATION_PTG);
    this.clearCheckboxByCategory(CheckBoxCategorie.CERTIFICATION_ORGANISATION_TOOLCHAIN);
    let certificationOrganisationsAttribute: { organisationName: string, organisationId: number }[] = [];
    let certificationOrganisationsPtg: { organisationName: string, organisationId: number }[] = [];
    let certificationOrganisationsToolchain: { organisationName: string, organisationId: number }[] = [];
    boxInfoArray.forEach((organisation) => {
      let organisationBoxInfo = {
        organisationName: organisation.name,
        organisationId: organisation.id
      }
      if (organisation.hasActiveAttributeCertification) certificationOrganisationsAttribute.push(organisationBoxInfo);
      if (organisation.hasActivePtgCertification) certificationOrganisationsPtg.push(organisationBoxInfo);
      if (organisation.hasActiveToolchainCertification) certificationOrganisationsToolchain.push(organisationBoxInfo);
    })
    this.addCheckBoxForAllOrgaKeys(certificationOrganisationsAttribute, CheckBoxCategorie.CERTIFICATION_ORGANISATION_ATTRIBUTE);
    this.addCheckBoxForAllOrgaKeys(certificationOrganisationsPtg, CheckBoxCategorie.CERTIFICATION_ORGANISATION_PTG);
    this.addCheckBoxForAllOrgaKeys(certificationOrganisationsToolchain, CheckBoxCategorie.CERTIFICATION_ORGANISATION_TOOLCHAIN);
  }

  private addCheckBoxForAllOrgaKeys(organisationBoxesInfo: { organisationName: string, organisationId: number }[], checkBoxCategory: CheckBoxCategorie) {
    organisationBoxesInfo.forEach((organisationBox) => {
      this.addCheckBox(
        organisationBox.organisationId,
        organisationBox.organisationName,
        checkBoxCategory,
        undefined,
        undefined,
        true,
        this.marketPlaceFilterService.filter.organisationIds.includes(
          organisationBox.organisationId
        )
      );
    });
  }

  /**
   * This method only sets the checked boxes for all filters that are set within the url query parameters, it does not affect the filter request
   * that is send to the backend. The filter is initialised form the query params in the marketplace-filter service
   * @param params Query Parameters from URL: These directly reflect all filter options
   */
  private setCheckedBoxesFromQueryParams(params: Params) {
    //productCategory checkboxes (Produktkategorie in Marketscreenstate Product | Produktanbieter in Marketscreenstate organisation)


    for (let productCategoryCheckBoxItem of this.productCategoryCheckboxItems) {
      if (productCategoryCheckBoxItem.value == params['category']) {
        productCategoryCheckBoxItem.checked = true;
        //

        if (this.marketScreenState === DataType.PRODUCT) {
          this._receiveCheckedValueProductState();
        }
        if (this.marketScreenState === DataType.ORGANISATION) {
          this.applicationSubCategoriesCheckBoxItems[0].checked = true;
        }

      }

    }// 2. hier setzen wir die boxen auf true aus der url
    let arr = params['certificationType'];



    for (let certificationCategoryCheckboxItem of this.certificationCategoryCheckboxItems) {


      if ((arr != undefined) && arr.includes(certificationCategoryCheckboxItem.value.toString())) {
        certificationCategoryCheckboxItem.checked = true;

        if (params["certifiId"] || this.marketScreenState === DataType.PRODUCT) {
          this._receiveCheckedValueCertificationState();
        }



        if (params["orgId"]) {

          this.certificationCategoryCheckboxItems[0].checked = true;
        }
      }
    }
    if (params['search']) {
      this.searchText = params['search'];
      this.filterBySearchText();

    }

    this.sortCertificationCheckBoxCategories();
    this.setSubCheckBox(CheckBoxCategorie.APP_PRODUCTS, 'appSubPro', params);
    this.setSubCheckBox(CheckBoxCategorie.SERVICE_PRODUCTS, 'appSubPro', params);
    this.setSubCheckBox(CheckBoxCategorie.CONTENT_PRODUCTS, 'appSubPro', params);
    this.setSubCheckBox(CheckBoxCategorie.ALLGEMEIN_PRODUCTS, 'appSubPro', params);
    this.setSubCheckBox(CheckBoxCategorie.HOCHBAU_PRODUCTS, 'appSubPro', params);
    this.setSubCheckBox(CheckBoxCategorie.INFRASTRUKTUR_PRODUCTS, 'appSubPro', params);
    this.setSubCheckBox(CheckBoxCategorie.ORGANISATION, 'orgId', params);
    this.setSubCheckBoxCerti(CheckBoxCategorie.CERTIFICATION_ORGANISATION_ATTRIBUTE, 'orgId', params);
    this.setSubCheckBoxCerti(CheckBoxCategorie.CERTIFICATION_ORGANISATION_PTG, 'orgId', params);
    this.setSubCheckBoxCerti(CheckBoxCategorie.CERTIFICATION_ORGANISATION_TOOLCHAIN, 'orgId', params);
    this.setSubCheckBox(CheckBoxCategorie.PRODUCT_ORGANISATION_APPLICATION, 'orgId', params);
    this.setSubCheckBox(CheckBoxCategorie.PRODUCT_ORGANISATION_SERVICE, 'orgId', params);
    this.setSubCheckBox(CheckBoxCategorie.PRODUCT_ORGANISATION_CONTENT, 'orgId', params);
    this.setSubCheckBox(CheckBoxCategorie.PRODUCT_ORGANISATION_ALLGEMEIN, 'orgId', params);
    this.setSubCheckBox(CheckBoxCategorie.PRODUCT_ORGANISATION_HOCHBAU, 'orgId', params);
    this.setSubCheckBox(CheckBoxCategorie.PRODUCT_ORGANISATION_EISENBAHNBAU, 'orgId', params);
    this.setSubCheckBox(CheckBoxCategorie.PRODUCT_ORGANISATION_STRASENBAU, 'orgId', params);
    this.setSubCheckBox(CheckBoxCategorie.PRODUCT_ORGANISATION_TIEFBAU, 'orgId', params);
    this.setSubCheckBox(CheckBoxCategorie.PRODUCT_ORGANISATION_WASSERBAU, 'orgId', params);
    this.setSubCheckBox(CheckBoxCategorie.PRODUCT_ORGANISATION_INFRASTRUKTUR, 'orgId', params);
    this.setSubCheckBox(CheckBoxCategorie.CERTIFICATION_ORGANISATION, 'orgId', params);
    this.setSubCheckBox(CheckBoxCategorie.PTG, 'ptgId', params);

    this.setRatingCheckBox(CheckBoxCategorie.RATING, "rating", params);


    this.setSubCheckBoxCerti(CheckBoxCategorie.CERTIFICATION, 'certifiId', params);

    if (params['attr']) this.setCommunFeatureCheckBox('attr', params);
    this.cd.detectChanges();
  }

  private isIdinParam(id: string, listOfids: string[]): boolean {

    let idIsThere = false;

    for (let idx = 0; idx < listOfids.length && !idIsThere; idx++) {

      if (listOfids[idx] === id) {

        idIsThere = true;

      }
    }

    return idIsThere;
  }

  private setCommunFeatureCheckBox(paramKey: string, params: Params) {
    let urlattr = params[paramKey];

    for (let commonFeature of this.commonFeatures) {
      for (let entry of commonFeature.entries) {
        let idOfEntry = entry.id + "";
        let correctId = Array.isArray(urlattr) ? this.isIdinParam(idOfEntry, urlattr) : idOfEntry === urlattr;

        if (urlattr && urlattr.includes(idOfEntry) && correctId) {
          entry.isChecked = true;

          this.marketPlaceFilterService.addFilter(
            entry.id,
            CheckBoxCategorie.COMMON_FEATURE_ENTRY
          );


        }

      }

    }

  }

  private setRatingCheckBox(categorie: CheckBoxCategorie, paramKey: string, params: Params): void {
    let urlAppSubPro = params[paramKey];


    for (let productAppSubCheckBoxItem of this.productRatingCheckboxItem) {
      let idOfProduct = productAppSubCheckBoxItem.rating;

      if (!productAppSubCheckBoxItem.value) idOfProduct = productAppSubCheckBoxItem.rating === "Alle" ? "6" : "0";
      let correctId = Array.isArray(urlAppSubPro) ? this.isIdinParam(idOfProduct, urlAppSubPro) : idOfProduct === urlAppSubPro;

      if (urlAppSubPro && urlAppSubPro.includes(idOfProduct) && correctId) {

        productAppSubCheckBoxItem.checked = true;





        this.marketPlaceFilterService.addFilter(
          Number(idOfProduct),
          CheckBoxCategorie.RATING
        );

      }
    }

  }

  private setSubCheckBox(categorie: CheckBoxCategorie, paramKey: string, params: Params): void {
    let urlAppSubPro = params[paramKey];

    for (let productAppSubCheckBoxItem of this._checkBoxes[categorie]) {
      let idOfProduct = productAppSubCheckBoxItem.id + "";
      let correctId = Array.isArray(urlAppSubPro) ? this.isIdinParam(idOfProduct, urlAppSubPro) : idOfProduct === urlAppSubPro;

      if (urlAppSubPro && urlAppSubPro.includes(idOfProduct) && correctId) {

        productAppSubCheckBoxItem.isChecked = true;

        this.marketPlaceFilterService.addFilter(
          productAppSubCheckBoxItem.id,
          productAppSubCheckBoxItem.categorie
        );

      }
    }

  }

  private setSubCheckBoxCerti(categorie: CheckBoxCategorie, paramKey: string, params: Params): void {
    let counterAll = 0;
    let urlAppSubPro = params[paramKey];

    this._checkBoxes[categorie].forEach((categoryBox: MarketplaceCheckbox) => {
      let idOfProduct = categoryBox.id + "";
      let correctId = Array.isArray(urlAppSubPro) ? this.isIdinParam(idOfProduct, urlAppSubPro) : idOfProduct === urlAppSubPro;
      if (urlAppSubPro && urlAppSubPro.includes(idOfProduct) && correctId) {
        counterAll++;

        categoryBox.isChecked = true;

        this.marketPlaceFilterService.addFilter(
          categoryBox.id,
          categoryBox.categorie
        );

      }

    });
    if (counterAll == 0) {

      this.certificationSubCategoriesCheckBoxItems[0].checked = true;

    }

  }

  /**
   * Löscht die Filter sowie alle Checkboxen der angegebenen Kategorie
   * @param checkBoxCategorie
   */
  private clearCheckboxByCategory(checkBoxCategorie: CheckBoxCategorie): void {
    this._checkBoxes[checkBoxCategorie].forEach((element) => {
      this.marketPlaceFilterService.removeFilter(element.id, element.categorie);
    });
    this._checkBoxes[checkBoxCategorie] = [];
  }

  updateFromService() {
    const mpFilter = this.marketPlaceFilterService.filter;
    mpFilter.attributeIds.forEach((id) => {
      this.setCheckBox(id, CheckBoxCategorie.ATTRIBUTE, true);
    });
    mpFilter.organisationIds.forEach((id) => {
      this.setCheckBox(id, CheckBoxCategorie.ORGANISATION, true);
    });
    mpFilter.ptgIds.forEach((id) => {
      this.setCheckBox(id, CheckBoxCategorie.PTG, true);
    });
    mpFilter.commonFeatureIds.forEach((id) => {
      this.setCheckBox(id, CheckBoxCategorie.COMMON_FEATURE_ENTRY, true);
    });
    mpFilter.certificationIds.forEach((id) => {
      this.setCheckBox(id, CheckBoxCategorie.CERTIFICATION, true);
    });
    mpFilter.productIds.forEach((id) => {
      this.setCheckBox(id, CheckBoxCategorie.PRODUCTS, true);
    });

    this.cd.detectChanges();
  }

  setCheckBox(id: number, categorie: CheckBoxCategorie, setChecked: boolean) {
    this._checkBoxes[categorie].forEach((checkBox: MarketplaceCheckbox) => {
      if (checkBox.isChecked && checkBox.isVisible) {
        checkBox.isChecked = setChecked;
        this.marketPlaceFilterService.addFilter(
          checkBox.id,
          checkBox.categorie
        );
      }
    });
  }

  /**
   * This is the function that is bound to the Checkbox clicked method in the gui.
   * Adds respectively Removes the filter condition in the filter service depending
   * if the fireing checkbox was set "checked" or "unchecked"
   *
   * @param box the Checkbox handed from the gui
   * @param event the MathCheckbox event from the gui
   */
  _receiveCheckedValue(box: MarketplaceCheckbox, event: MatCheckboxChange) {
    box.isChecked = event.checked;

    // this links the MatCheckbox state to the Checkbox Domain Object
    if (box.isChecked) {
      this.marketPlaceFilterService.addFilter(box.id, box.categorie);
    } else {
      this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
    }
  }

  /**
  Specific for ratings
   * This is the function that is bound to the Checkbox clicked method in the gui.
   * Adds respectively Removes the filter condition in the filter service depending
   * if the fireing checkbox was set "checked" or "unchecked"
   *
   * @param box the Checkbox handed from the gui
   * @param event the MathCheckbox event from the gui
   */
  _receiveCheckedValueRating(rating: string, event: MatCheckboxChange) {

    this.productRatingCheckboxItem.forEach(
      (checkbox) => {
        if (!checkbox.value) {
          (checkbox.checked = false)
          let idx = checkbox.rating === "Alle" ? "6" : "0";
          this.marketPlaceFilterService.removeFilter(Number(idx), CheckBoxCategorie.RATING);
        }
      }
    );
    // this links the MatCheckbox state to the Checkbox Domain Object
    if (event.checked) {

      this.marketPlaceFilterService.addFilter(Number(rating), CheckBoxCategorie.RATING);
    } else {

      this.marketPlaceFilterService.removeFilter(Number(rating), CheckBoxCategorie.RATING);
    }
  }


  /**
  Specific for ratings
   * This is the function that is bound to the Checkbox clicked method in the gui.
   * Adds respectively Removes the filter condition in the filter service depending
   * if the fireing checkbox was set "checked" or "unchecked"
   *
   * @param box the Checkbox handed from the gui
   * @param event the MathCheckbox event from the gui
   */
  _receiveCheckedValueRatingAllWithout(rating: string, event: MatCheckboxChange) {

    this.productRatingCheckboxItem.forEach(
      (checkbox) => {
        if (checkbox.rating !== rating) {
          (checkbox.checked = false)
          let idx = checkbox.rating;

          if (!checkbox.value) idx = checkbox.rating === "Alle" ? "6" : "0";

          this.marketPlaceFilterService.removeFilter(Number(idx), CheckBoxCategorie.RATING);
        }
      }
    );

    rating = rating === "Alle" ? "6" : "0";
    // this links the MatCheckbox state to the Checkbox Domain Object
    if (event.checked) {

      this.marketPlaceFilterService.addFilter(Number(rating), CheckBoxCategorie.RATING);
    } else {

      this.marketPlaceFilterService.removeFilter(Number(rating), CheckBoxCategorie.RATING);
    }
  }

  checkedCommonFeatureEntryBox(commonFeatureEntry: FilterCommonFeatureEntry, event: MatCheckboxChange) {
    commonFeatureEntry.isChecked = event.checked;
    let commonFeatureBox: MarketplaceCheckbox = {
      id: commonFeatureEntry.id,
      categorie: CheckBoxCategorie.COMMON_FEATURE_ENTRY,
      label: commonFeatureEntry.name,
      ptgId: undefined,
      isChecked: commonFeatureEntry.isChecked,
      isVisible: true,
      ptgOptional: undefined
    }
    switch (commonFeatureEntry.linkingMethod.toString()) {
      case "XOR":
        this.commonFeatures.forEach((x) => {
          x.entries.forEach((y) => {
            if (commonFeatureEntry.id != y.id && y.linkingMethod?.toString() == "XOR") {
              y.isChecked = false;
              this.marketPlaceFilterService.removeFilter(y.id, commonFeatureBox.categorie);
            }
          })
        })
        break;
    }
    if (commonFeatureBox.isChecked) {
      if (!this.marketPlaceFilterService.filter.commonFeatureIds.includes(commonFeatureBox.id)) {
        this.marketPlaceFilterService.addFilter(commonFeatureBox.id, commonFeatureBox.categorie);
      }
    } else {
      this.marketPlaceFilterService.removeFilter(commonFeatureBox.id, commonFeatureBox.categorie);
    }
  }

  /**
   * Aufgerufen, wenn in der OrganisationsScreenState nur nach dem OrgaTyp geklickt wird
   */
  _receiveCheckedValueOrganisationType(): void {
    this.marketPlaceFilterService.setOrgaTypeFilter(
      this.activeOrgaType,
      [],
      []
    );
  }

  /**
   * Aufgerufen, wenn einer der Filtermöglichkeiten unter Produktkategorie oder Zertifizierungstyp angeklickt wurde abhängig vom MarketScreenState
   * @param organisationType Orgatyp, damit in der OrganisationState zwischen Produktanbietern und Zertifizierungsanbietern unterschieden werden kann
   * @param index, position der einzigen CheckBox die checked sein kann, alle anderen checkboxes werden false gesetzt
   */
  _receiveCheckedValueProductCertificationType(
    organisationType?: OrganisationType,
    index?: number,
    category?,
    checked?
  ): void {
    this.activeOrgaType = organisationType;

    // limitiert die Boxauswahl auf maximal 1
    if (organisationType != OrganisationType.CERTIFICATION_PROVIDER) {
      this.limitCheckboxesToOne(organisationType, index);
    }
    if (this.marketScreenState === DataType.CERTIFICATION) {
      this._receiveCheckedValueCertificationState();
    } else if (this.marketScreenState === DataType.PRODUCT) {
      this._receiveCheckedValueProductState();
    } else if (this.marketScreenState === DataType.ORGANISATION) {
      if (category) {
        if (!checked) {

          // categorys die noch angekreuzt sind
          let arrCategory = [];
          let notSelected = true;

          for (let certificationOrganisationCategory of this.certificationCategoryCheckboxItems) {
            notSelected = notSelected && !certificationOrganisationCategory.checked;

            if (certificationOrganisationCategory.checked) {
              arrCategory.push(certificationOrganisationCategory.value);
            }
          }



          if (notSelected || organisationType === OrganisationType.PRODUCT_PROVIDER) {
            this.resetFilters();
          }

          this.marketPlaceFilterService.setCertificationTypeFilter(arrCategory);
          this.marketPlaceFilterService.setProductCategoryFilter([]);


        } else if (organisationType === OrganisationType.PRODUCT_PROVIDER) {

          for (let certificationOrganisationCategory of this.certificationCategoryCheckboxItems) {
            certificationOrganisationCategory.checked = false;
          }
          this.marketPlaceFilterService.setCertificationTypeFilter([]);
          category = checked ? [category] : [];
          this.marketPlaceFilterService.setProductCategoryFilter(category);
        } else {

          for (let productOrganisationCategory of this.productCategoryCheckboxItems) {
            productOrganisationCategory.checked = false;
            //  this.resetAllSubCheckBoxFilter();
          }
          this.marketPlaceFilterService.setProductCategoryFilter([]);

          this._receiveCheckedValueCertificationState();
        }
      }
    }
  }

  private _receiveCheckedValueCertificationState(): void {
    let certificationTypes = this.getCheckedCertificationTypes();
    this.marketPlaceFilterService.setCertificationTypeFilter(
      certificationTypes
    );
  }


  private _receiveCheckedValueProductState(): void {
    let productCategories = this.getCheckedProductCategories();
    let certificationTypes = this.getCheckedCertificationTypes();

    this.marketPlaceFilterService.setProductCategoryFilter(productCategories);
    this.marketPlaceFilterService.setCertificationTypeFilter(
      certificationTypes
    );
  }


  // limitiert Auswahl der Checkboxes auf 1
  private limitCheckboxesToOne(
    orgaType: OrganisationType,
    index: number
  ): void {
    let selectedCheckboxes: {
      name: string;
      checked: boolean;
      value: ProductCategory | CertificationTypes;
    }[];
    if (orgaType == OrganisationType.PRODUCT_PROVIDER) {
      selectedCheckboxes = this.productCategoryCheckboxItems;
    } else if (orgaType == OrganisationType.CERTIFICATION_PROVIDER) {
      selectedCheckboxes = this.certificationCategoryCheckboxItems;
    }
    let checkedBoxName = selectedCheckboxes[index].name;

    //set other boxes false
    selectedCheckboxes.forEach((box) => {
      if (box.name != checkedBoxName) {
        box.checked = false;
      }
    });
  }

  /**
   * Sortfunction used with the sort order button.
   * This is another 3 way merry-go-round property binding function.
   * Endpoint is orderByOrganisation in marketplace-productoverview.component.
   * #LEGACY
   *
   * @param sort some wierd string to number thingamajig which ends up a boolean anyway....
   */
  sortBy(sort: string) {
    var int = Math.abs(this.sortOptions.findIndex((y) => y.name === sort));
    switch (int) {
      case 0: {
        this.marketPlaceFilterService.orderByOrganisationSubject.next(false);
        break;
      }
      case 1: {
        this.marketPlaceFilterService.orderByOrganisationSubject.next(true);
        break;
      }
    }
  }

  setSortOption(sort: SortMethod): void {
    this.marketPlaceFilterService.sortingOptionSubject.next(sort);
    this.sortOrder = sort;
  }

  /**
   * Setzt alle Filter zurück
   */
  resetFilters(): void {
    for (var category in CheckBoxCategorie) {
      if (this._checkBoxes[category]) {
        this.resetCategoryBoxes(category);
      }
    }
    this.showCertificationTypeBoxes = false;
    this.showOrganisationBoxes = false;
    this.showProductCategoryBoxes = false;
    this.showCommonFeatureBoxes = false;
    this.showPtgBoxes = false;
    this.marketPlaceFilterService.productFilter = [];
    this.marketPlaceFilterService.setOrgaTypeFilter(null, [], []);
    this.marketPlaceFilterService.commonFeatureEntriesFilter = [];
    this.marketPlaceFilterService.ratingFilter = [];


  }

  private resetCategoryBoxes(category: CheckBoxCategorie | string): void {
    this._checkBoxes[category].forEach((checkBox: MarketplaceCheckbox) => {
      if (checkBox.isChecked) {
        checkBox.isChecked = false;
        this.marketPlaceFilterService.removeFilter(
          checkBox.id,
          checkBox.categorie
        );
      }
    });
  }

  filterProductSearchText(): void {
    this.marketPlaceFilterService.receiveSearchText(this.searchText);
    this.trackingService.eventEmitter("search", "filter", "product_search_term", this.searchText);
  }

  filterOrganisationSearchText(): void {
    this.marketPlaceFilterService.receiveSearchText(this.searchText);
    this.trackingService.eventEmitter("search", "filter", "orga_search_term", this.searchText);
  }

  filterCertificationSearchText(): void {
    this.marketPlaceFilterService.receiveSearchText(this.searchText);
    this.trackingService.eventEmitter("search", "filter", "certification_search_term", this.searchText);
  }

  filterToolchainSearchText(): void {
    this.marketPlaceFilterService.receiveSearchText(this.searchText);
    this.trackingService.eventEmitter("search", "filter", "toolchain_search_term", this.searchText);
  }

  toggleCheckBoxByCategory(category: CheckBoxCategorie) {
    if (category === CheckBoxCategorie.ORGANISATION) {
      this.showOrganisationBoxes = !this.showOrganisationBoxes;
    } else if (category === CheckBoxCategorie.PTG) {
      this.showPtgBoxes = !this.showPtgBoxes;
    } else if (category === CheckBoxCategorie.COMMON_FEATURE_ENTRY) {
      this.showCommonFeatureBoxes = !this.showCommonFeatureBoxes;
    }

    this._checkBoxes[category].forEach((box) => {
      this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      box.isChecked = false;
    });
  }

  private resetCertificationAndProductBoxes(): void {
    this.resetCertificationBoxes();
    this.resetProductCategoryBoxes();
  }

  private resetCertificationBoxes(): void {
    this.certificationCategoryCheckboxItems.forEach(
      (checkbox) => (checkbox.checked = false)
    );
  }

  private resetCommonFeaturesBoxes(): void {
    this.commonFeatures?.forEach((x) => {
      x.entries.forEach((y) => {
        y.isChecked = false;
        this.marketPlaceFilterService.removeFilter(y.id, CheckBoxCategorie.COMMON_FEATURE_ENTRY);
      })
    })


  }

  private resetProductCategoryBoxes(): void {
    this.productCategoryCheckboxItems.forEach(
      (checkbox) => {
        (checkbox.checked = false)
        this.resetAllSubCheckBoxFilter();
      }
    );
  }

  private resetRatingCategoryBoxes(): void {
    this.productRatingCheckboxItem.forEach(
      (checkbox) => {
        (checkbox.checked = false)
        this.marketPlaceFilterService.removeFilter(Number(checkbox.rating), checkbox.value);
      }
    );
  }

  /** storing all certifications in the CHECKBOXCATEGORIE.CERTIFICATION checkbox
   *   and then dividing them based on the certificationtype to different checkboxes
   */
  public sortCertificationCheckBoxCategories() {

    var totalNumberOfElements: number = 0;
    var certificationSubject: BehaviorSubject<CertificationOverviewInterface[]> = new BehaviorSubject([]);
    var currentRequest: Unsubscribable;
    var pageSize: number = 100;
    var breakLoop: number = Math.ceil((this._checkBoxes[CheckBoxCategorie.CERTIFICATION].length / pageSize));
    if (!this._checkBoxes[CheckBoxCategorie.ATTR_CERTIFICATION].length) {
      for (var pageIndex: number = 0; pageIndex < breakLoop; pageIndex++) {
        currentRequest = this.certificationService.getFilteredCertificationSlice(pageIndex, pageSize, [], [], [], [], "", SortMethod.ALPHABETICAL_DESC)
          .subscribe((certPage: any) => {
            totalNumberOfElements = certPage.totalNumberOfCertifications;
            certPage.certificationSlice.forEach((certification: CertificationOverviewInterface) => {
              this._checkBoxes[CheckBoxCategorie.CERTIFICATION].forEach((certBox: MarketplaceCheckbox) => {
                if (certBox.label === certification.certificationName) {
                  switch (certification.certificationType) {
                    case "ATTRIBUTE_CERT":
                      if (!this._checkBoxes[CheckBoxCategorie.ATTR_CERTIFICATION].includes(certBox) && certification.productsCertified > 0)
                        this._checkBoxes[CheckBoxCategorie.ATTR_CERTIFICATION].push(certBox);
                      break;
                    case "TOOLCHAIN_CERT":
                      if (!this._checkBoxes[CheckBoxCategorie.TOOLCHAIN_CERTIFICATION].includes(certBox) && certification.productsCertified > 0)
                        this._checkBoxes[CheckBoxCategorie.TOOLCHAIN_CERTIFICATION].push(certBox);
                      break;
                    case "PTG_CERT":
                      if (!this._checkBoxes[CheckBoxCategorie.PTG_CERTIFICATION].includes(certBox) && certification.productsCertified > 0)
                        this._checkBoxes[CheckBoxCategorie.PTG_CERTIFICATION].push(certBox);
                      break;
                  }
                }
              })
            })
            certificationSubject.next(certPage.certificationSlice);
          });


      }
    }
  }

  /**
   *  handles the toggle behaviour of the subcheckboxes for product categories on the product marketplace page
   *  if "alle" is selected, every other element that is currently selected gets unselected and removed from the filter
   * @param category
   * @param isMainCheckBox
   */
  public toggleProductCheckBoxes(
    category: CheckBoxCategorie,
    isMainCheckBox: boolean
  ) {
    if (!isMainCheckBox) {
      if (!this.marketPlaceFilterService.productFilter.length)
        this.applicationSubCategoriesCheckBoxItems[0].checked = true;
      this._checkBoxes[category].forEach((box: MarketplaceCheckbox) => {
        if (box.isChecked) {
          this.applicationSubCategoriesCheckBoxItems[0].checked = false;
        }
      });
    } else {
      this.marketPlaceFilterService.filterSpinner = true;
      this.applicationSubCategoriesCheckBoxItems[0].checked = true;
      this.resetAllSubCheckBoxFilter();
    }
    this.cd.detectChanges();
  }


  /**
   *  handles the toggle behavior of the subcheckboxes
   *  if "alle" is selected, every other element that is selected gets unselected and removed from the filter
   */
  public toggleCertificationSubCheckBoxes(
    category: CheckBoxCategorie,
    certType: CertificationTypes
  ) {

    if (!this.marketPlaceFilterService.certificationFilter.length)
      this.certificationSubCategoriesCheckBoxItems[0].checked = true;
    if (certType === null) {
      let isActive: boolean = true;
      this._checkBoxes[category].forEach((categoryBox: MarketplaceCheckbox) => {
        if (categoryBox.isChecked) {

          isActive = false;
        }
      });
      this.certificationSubCategoriesCheckBoxItems[0].checked = isActive;

    } else {
      this.marketPlaceFilterService.filterSpinner = true;
      this.certificationSubCategoriesCheckBoxItems[0].checked = true;
      this.resetToggleCertificationBoxes(certType);
    }
    this.cd.detectChanges();

  }

  public resetToggleCertificationBoxes(certType: CertificationTypes) {
    switch (certType) {
      case CertificationTypes.ATTRIBUTE_CERT:
        this._checkBoxes[CheckBoxCategorie.ATTR_CERTIFICATION].forEach((box: MarketplaceCheckbox) => {
          box.isChecked = false;
          this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
        });
        break;
      case CertificationTypes.PTG_CERT:
        this._checkBoxes[CheckBoxCategorie.PTG_CERTIFICATION].forEach((box: MarketplaceCheckbox) => {
          box.isChecked = false;
          this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
        });
        break;
      case CertificationTypes.TOOLCHAIN_CERT:
        this._checkBoxes[CheckBoxCategorie.TOOLCHAIN_CERTIFICATION].forEach((box: MarketplaceCheckbox) => {
          box.isChecked = false;
          this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
        });
        break;
    }
  }


  public resetAllFilters() {
    this.marketPlaceFilterService.filterSpinner = true;
    this.resetFilters();
    this.resetCertificationAndProductBoxes();
    this.resetCommonFeaturesBoxes();
    this.marketPlaceFilterService.filter.organisationIds.length = 0;
    this.marketPlaceFilterService.setQueryParams();
    this.marketPlaceFilterService.resetSearchTextValue();
    this.resetRatingCategoryBoxes();
    this.searchText = "";
    this.cd.detectChanges();
  }

  /**
   * resetting all subcheckboxes when 'alle' is selected
   */
  private resetAllSubCheckBoxFilter() {

    if (this.marketScreenState == DataType.ORGANISATION) {

      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_APPLICATION].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_SERVICE].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });

      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_CONTENT].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_ALLGEMEIN].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_HOCHBAU].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
            this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_EISENBAHNBAU].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
                  this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_STRASENBAU].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
                  this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_TIEFBAU].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
            this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_WASSERBAU].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_INFRASTRUKTUR].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
      this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION_ATTRIBUTE].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });

      this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION_PTG].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });

      this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION_TOOLCHAIN].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
    }

    if (this.marketPlaceFilterService.productFilter.length) {
      this._checkBoxes[CheckBoxCategorie.APP_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.SERVICE_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.CONTENT_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.ALLGEMEIN_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
      this._checkBoxes[CheckBoxCategorie.HOCHBAU_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
            this._checkBoxes[CheckBoxCategorie.TIEFBAU_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
            this._checkBoxes[CheckBoxCategorie.WASSERBAU_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
            this._checkBoxes[CheckBoxCategorie.EISENBAHNBAU_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
                  this._checkBoxes[CheckBoxCategorie.STRASENBAU_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });      
      this._checkBoxes[CheckBoxCategorie.INFRASTRUKTUR_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
              box.isChecked = false;
              this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
            });
      this._checkBoxes[CheckBoxCategorie.PRODUCTS].forEach((box: MarketplaceCheckbox) =>
        this.marketPlaceFilterService.removeFilter(
          box.id,
          CheckBoxCategorie.PRODUCTS
        )
      );
    }
    if (this.marketPlaceFilterService.certificationFilter.length) {
      this._checkBoxes[CheckBoxCategorie.ATTR_CERTIFICATION].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
      });
      this._checkBoxes[CheckBoxCategorie.TOOLCHAIN_CERTIFICATION].forEach(
        (box: MarketplaceCheckbox) => {
          box.isChecked = false;
        }
      );
      this._checkBoxes[CheckBoxCategorie.PTG_CERTIFICATION].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
      });
      this._checkBoxes[CheckBoxCategorie.CERTIFICATION].forEach((box: MarketplaceCheckbox) => {
        this.marketPlaceFilterService.removeFilter(
          box.id,
          CheckBoxCategorie.CERTIFICATION
        );
      });
    }
  }

  public resetAllSubCheckBoxWOCertFilter() {

    if (this.marketScreenState == DataType.ORGANISATION) {

      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_APPLICATION].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_SERVICE].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });

      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_CONTENT].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_ALLGEMEIN].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_HOCHBAU].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_EISENBAHNBAU].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_STRASENBAU].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_TIEFBAU].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_WASSERBAU].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_INFRASTRUKTUR].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });

      this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION_ATTRIBUTE].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });

      this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION_PTG].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });

      this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION_TOOLCHAIN].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
    }

    if (this.marketPlaceFilterService.productFilter.length) {
      this._checkBoxes[CheckBoxCategorie.APP_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.SERVICE_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.CONTENT_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.ALLGEMEIN_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.HOCHBAU_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.INFRASTRUKTUR_PRODUCTS].forEach((box: MarketplaceCheckbox) => {
        box.isChecked = false;
        this.marketPlaceFilterService.removeFilter(box.id, box.categorie);
      });
      this._checkBoxes[CheckBoxCategorie.PRODUCTS].forEach((box: MarketplaceCheckbox) =>
        this.marketPlaceFilterService.removeFilter(
          box.id,
          CheckBoxCategorie.PRODUCTS
        )
      );
    }
  }

  public filterBySearchText(): void {
    switch (this.marketScreenState) {
      case this.dataTypeEnum.PRODUCT: {
        this.filterProductSearchText();
      }
      case this.dataTypeEnum.ORGANISATION: {
        this.filterOrganisationSearchText();
      }
      case this.dataTypeEnum.CERTIFICATION: {
        this.filterCertificationSearchText();
      }
      case this.dataTypeEnum.TOOLCHAIN: {
        this.filterToolchainSearchText();
      }
    }
  }




  private _autoComplete(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.filterString = [];
    if (this.marketScreenState == "PRODUCT") {
      this._checkBoxes[CheckBoxCategorie.APP_PRODUCTS].forEach((element) => { this.filterString.push(element.label) });
      this._checkBoxes[CheckBoxCategorie.SERVICE_PRODUCTS].forEach((element) => { this.filterString.push(element.label) })
      this._checkBoxes[CheckBoxCategorie.CONTENT_PRODUCTS].forEach((element) => { this.filterString.push(element.label) })
      this._checkBoxes[CheckBoxCategorie.ALLGEMEIN_PRODUCTS].forEach((element) => { this.filterString.push(element.label) })
      this._checkBoxes[CheckBoxCategorie.HOCHBAU_PRODUCTS].forEach((element) => { this.filterString.push(element.label) })
      this._checkBoxes[CheckBoxCategorie.TIEFBAU_PRODUCTS].forEach((element) => { this.filterString.push(element.label) })
      this._checkBoxes[CheckBoxCategorie.WASSERBAU_PRODUCTS].forEach((element) => { this.filterString.push(element.label) })
      this._checkBoxes[CheckBoxCategorie.STRASENBAU_PRODUCTS].forEach((element) => { this.filterString.push(element.label) })
      this._checkBoxes[CheckBoxCategorie.EISENBAHNBAU_PRODUCTS].forEach((element) => { this.filterString.push(element.label) })
      this._checkBoxes[CheckBoxCategorie.INFRASTRUKTUR_PRODUCTS].forEach((element) => { this.filterString.push(element.label) })
    } else if (this.marketScreenState == "CERTIFICATION") {
      this._checkBoxes[CheckBoxCategorie.ATTR_CERTIFICATION].forEach((element) => { this.filterString.push(element.label) });
      this._checkBoxes[CheckBoxCategorie.TOOLCHAIN_CERTIFICATION].forEach((element) => { this.filterString.push(element.label) })
      this._checkBoxes[CheckBoxCategorie.PTG_CERTIFICATION].forEach((element) => { this.filterString.push(element.label) })
    } else if (this.marketScreenState == "ORGANISATION") {
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_APPLICATION].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_CONTENT].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_ALLGEMEIN].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_HOCHBAU].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_TIEFBAU].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_EISENBAHNBAU].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_STRASENBAU].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_WASSERBAU].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_INFRASTRUKTUR].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
      this._checkBoxes[CheckBoxCategorie.PRODUCT_ORGANISATION_SERVICE].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
      this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION_ATTRIBUTE].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
      this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION_PTG].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
      this._checkBoxes[CheckBoxCategorie.CERTIFICATION_ORGANISATION_TOOLCHAIN].forEach((element) => { if (!this.filterString.includes(element.label)) { this.filterString.push(element.label) } });
    }
    return this.filterString.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  fillSearchAutoCompleteList(): string[] {

    this._checkBoxes[CheckBoxCategorie.APP_PRODUCTS].forEach((element) => { this.filterString.push(element.label) });

    return this.filterString;
  }

  ngOnChange(): void {

  }

  ngOnInit(): void {
    this.filteredOptions = this.prodControl.valueChanges.pipe(startWith(''), map(value => this._autoComplete(value)));
    this.marketPlaceFilterService.screenStateObservable.subscribe((marketScreenState) => {
      this.marketScreenState = marketScreenState;
    })
    //Takes the Query parameters from url; initialise all checkboxes and check the selected filter boxes from params
    //Take(1) is important: Only the first set of params is used for initialising and checking of the filter boxes
    //A permanent subscription will cause an infinite loop

    this.queryParamSubscription = this.route.queryParams
      .pipe(take(1))
      .subscribe(params => {
        this.initialiseCheckboxes(params);
      });

    this.filterSubscription = this.marketPlaceFilterService.getToggleBooleanClearFilter().subscribe((x) => {
      if (x) {
        this.marketPlaceFilterService.resetFilters();
        this.resetAllFilters();
      }
    })

  }

  ngOnDestroy() {

    if (!this.router.url.includes("tab=")) {
      this.resetAllFilters();

    }

    if (this.filterSubscription)
      this.filterSubscription.unsubscribe();

  }

  toggleAllSubCheckBoxesPTG(id: number) {
    this._checkBoxes[CheckBoxCategorie.PTG_ATTRIBUTE].forEach((ptgAttrBox) => {
      if (id === ptgAttrBox.ptgId) {
        ptgAttrBox.isChecked = false;
        this.marketPlaceFilterService.removeFilter(ptgAttrBox.id, CheckBoxCategorie.PTG_ATTRIBUTE);
      }
    })
  }

}
