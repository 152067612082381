<div class="content">
  <div>
    <h1>Registrierung</h1>
    <form [formGroup]="registryForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
      <div class="left-column">
        <mat-form-field *ngIf="registerWithInvite">
          <input matInput placeholder="E-Mail" [formControl]="email" autocomplete="email" required />
          <mat-error *ngIf="
              email.hasError('invalidEmail') && !email.hasError('required')
            ">Bitte geben Sie eine valide E-Mail an</mat-error>
          <mat-error *ngIf="email.hasError('required')">Diese Angabe ist erforderlich</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="!registerWithInvite">
          <input matInput placeholder="E-Mail" [formControl]="email" autocomplete="email" required />
          <mat-error *ngIf="
              email.hasError('invalidEmail') && !email.hasError('required')
            ">Bitte geben Sie eine valide E-Mail an</mat-error>
          <mat-error *ngIf="email.hasError('required')">Diese Angabe ist erforderlich</mat-error>
        </mat-form-field>
        <div></div>
        <mat-form-field>
          <mat-select placeholder="Anrede" [formControl]="gender">
            <mat-option *ngFor="let gender of genders" [value]="gender.value">{{gender.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Titel" [formControl]="title">
            <mat-option>-</mat-option>
            <mat-option value="Dr.">Dr.</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Vorname" [formControl]="firstname" autocomplete="given-name" required />
          <mat-error *ngIf="firstname.hasError('required')">Diese Angabe ist erforderlich</mat-error>
          <mat-error *ngIf="
              !firstname.hasError('required') &&
              firstname.hasError('firstnamelength')
            ">
            Der Vorname darf maximal 60 Zeichen lang sein</mat-error>
          <mat-error *ngIf="
              !firstname.hasError('required') &&
              firstname.hasError('firstNameInvalidCharacter')
            ">Bitte keine Zahlen oder Sonderzeichen</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Nachname" [formControl]="lastname" autocomplete="family-name" required />
          <mat-error *ngIf="lastname.hasError('required')">Diese Angabe ist erforderlich</mat-error>
          <mat-error *ngIf="
              !lastname.hasError('required') &&
              lastname.hasError('lastnamelength')
            ">
            Der Nachname darf maximal 60 Zeichen lang sein</mat-error>
          <mat-error *ngIf="
              !lastname.hasError('required') &&
              lastname.hasError('lastNameInvalidCharacter')
            ">Bitte keine Zahlen oder Sonderzeichen</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Unternehmen" [formControl]="organization" autocomplete="organization" required />
          <mat-error *ngIf="organization.hasError('required')">Diese Angabe ist erforderlich</mat-error>
          <mat-error *ngIf="
              !organization.hasError('required') &&
              organization.hasError('organizationLength')
            ">
            Das Unternehmen darf maximal 60 Zeichen lang sein</mat-error>
          <mat-error *ngIf="
              !organization.hasError('required') &&
              organization.hasError('organizationInvalidCharacter')
            ">Bitte keine Zahlen oder Sonderzeichen</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="URL" [formControl]="website" autocomplete="url" />
          <mat-error *ngIf="
              website.hasError('websiteLength')
            ">
            Die URL darf maximal 60 Zeichen lang sein</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Position" [formControl]="position" autocomplete="position" />
          <mat-error *ngIf="
              position.hasError('positionLength')
            ">
            Die Position darf maximal 60 Zeichen lang sein</mat-error>
            <mat-error *ngIf="
               position.hasError('positionInvalidCharacter')
            ">Bitte keine Zahlen oder Sonderzeichen</mat-error>
        </mat-form-field>
        <div #passwordField>
          <mat-form-field class="password-field">
            <input matInput placeholder="Passwort" formControlName="password" [formControl]="password"
              autocomplete="new-password" type="password" required />
            <mat-error *ngIf="password.invalid">
              <span *ngIf="password.hasError('required')">
                Diese Angabe ist erforderlich
              </span>
            </mat-error>
          </mat-form-field>
          <div *ngIf="arePasswordRulesVisible" class="password-rules-container">
            <div class="password-rules">
              <p class="password-header">Das Passwort muss mindestens:</p>
              <ul>
                <li [ngClass]="{ error: password.hasError('passwordlength') }">
                  6 Zeichen lang sein
                </li>
                <li [ngClass]="{
                    error: password.hasError('passwordSpecialChar')
                  }">
                  ein Sonderzeichen beinhalten
                </li>
                <li [ngClass]="{ error: password.hasError('passwordNumber') }">
                  eine Zahl beinhalten
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div #passwordFieldConfirm>
          <mat-form-field class="password-field">
            <input matInput placeholder="Passwort wiederholen *" formControlName="confirmpassword"
              [formControl]="confirmpassword" autocomplete="new-password" type="password"
              [errorStateMatcher]="matcher" />
            <mat-error *ngIf="registryForm.hasError('notEqual')">Diese Angabe stimmt nicht mit dem Passwort überein
            </mat-error>
          </mat-form-field>
        </div>
        <app-password-strength [password]="password.value"></app-password-strength>
        <p>
          Mit Absenden des Formulars akzeptieren Sie die
          <a (click)="showAgb()">AGB</a>
          und
          <a (click)="showPrivacy()">Datenschutzerklärung</a>.
        </p>
      </div>
      <div style="display: flex; justify-content: center;">
        <button
          style="width: 150px; justify-self: right; margin-right: 1rem"
          mat-raised-button
          type="submit"
          class="btn_green"
          [disabled]="registryForm.invalid || registerClicked">
          Registrieren
        </button>
        <button
          style="width: 150px; justify-self: left;"
          class="btn_red"
          mat-raised-button (click)="onCancel()">
          Abbrechen
        </button>
      </div>
      <p>*Pflichtangaben</p>
    </form>
  </div>
</div>
