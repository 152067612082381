<div class="itemContainer" *ngIf="!showEditFeature">
  <div class="headline">
    <h3 class="name">{{ commonFeature.name }}</h3>
    <div class="buttons">
      <button
        class="btn_green"
        mat-raised-button
        (click)="toggleFeatureEntries()"
        [id]="'showEntryButton' + index"
      >
        Ausklappen
      </button>
      <button class="btn_green" mat-raised-button (click)="editFeature()">
        Bearbeiten
      </button>
    </div>
  </div>
  <div *ngIf="showFeatureEntries" class="items">
    <p>
      <b>Filter Option:</b> {{ commonFeature.filterOption ? "Ja" : "Nein" }}
    </p>
    <p>
      <b>Anzeige auf der Produktdetailseite:</b> {{commonFeature.showDetailPage ? "Ja": "Nein"}}
    </p>

    <p *ngFor="let entry of commonFeature.featureEntries | sortCommonFeaturesByName; let i = index">
      <b>Auswahlmöglichkeit {{ i + 1 }}:</b> {{ entry.name }}
    </p>
</div>

<div *ngIf="showEditFeature" (keyup.enter)="$event.preventDefault()">
  <div class="headline">
    <h3>{{ commonFeature.name }}</h3>
    <div class="buttons">
      <button class="btn_red" mat-raised-button (click)="cancelEdit()">
        Abbrechen
      </button>
      <button class="btn_green" mat-raised-button (click)="saveFeature()">
        Speichern
      </button>
      <button class="btn_red" mat-raised-button (click)="deleteFeature()">
        Löschen
      </button>
  </div>
  </div>
  <app-common-feature-item-edit-form
    [index]="index"
    [name]="commonFeature.name"
    [filterOption]="commonFeature.filterOption"
    [showDetailPage]="commonFeature.showDetailPage"
    [tempFeatureEntries]="sortByName(tempFeatureEntries)"
    [linkingMethodInput]="commonFeature.linkingMethod"
    [showFeatureEntry]="false"
  ></app-common-feature-item-edit-form>
</div>

</div>
