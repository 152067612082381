import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {AttributeInterface} from "../../../../model/attributes/attribute-interface";
import {ValidationInterface} from "../../../../model/attributes/validation-interface";
import {FilterOptionsInterface} from "../../../../model/composer/filter-options-interface";
import { AttributeService } from 'src/app/services/dataServices/attribute-service/attributeService';
import { SidemenuServiceService } from 'src/app/services/componentServices/sidemenu-service/sidemenu-service.service';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.css']
})
export class FilterPanelComponent implements OnInit {

  public filterOptions: any[];
  private filterOptionValues = {};
  private itemCopy;
  public isPtgFiltered: boolean = false;

  constructor(
    private attributeService: AttributeService,
    private sidemenuService: SidemenuServiceService
  ) { }

  @Input()
    filterMode;

  @Input()
  item;

  @Input()
  composerItem: boolean;

  @Output()
    optionsFiltered: EventEmitter<any> = new EventEmitter<any>();

  generateFilterOptionsFromAllAttributes(): Array<any>{
    let index: number = 0;
    let filterOptions = []
    if(this.attributeService.collection){
      for(let attribute of this.attributeService.collection) {
        if (this.alreadyContainsAttribute(attribute, filterOptions)) {
          filterOptions[index] = {
            name: attribute.name,
            datatype: attribute.datatype,
            validations: this.convertValidationValuesToArray(attribute.validations),
            attributeId: attribute.id
          }
          index++;
        }
      }
    }
    else{
      //
    }
    return filterOptions;
  }

  generateFilterOptionsFromSinglePtg(): Array<FilterOptionsInterface>{
    let index: number = 0;
    let filterOptions: Array<FilterOptionsInterface> = [];
    for(let product of this.item.children){
      for(let productVariant of product.children){
        for(let variantVersion of productVariant.variantVersions){
          for(let attribute of variantVersion.attributeValidationValues){
            if(this.alreadyContainsAttribute(attribute.attribute, filterOptions)){
            filterOptions[index] = {
              name: attribute.attribute.name,
              datatype: attribute.attribute.datatype,
              validations: this.convertValidationValuesToArray(attribute.attribute.validations),
              attributeId: attribute.attribute.id
            }
            index++;
          }
          }
        }
      }
    }
    for(let filterOption of filterOptions){
      if(filterOption.datatype === 'STRING' || filterOption.datatype === 'NUMERIC'){
        this.filterOptionValues[filterOption.attributeId] = [];
      } else {
        this.filterOptionValues[filterOption.attributeId] = [];
      }
    }
    return filterOptions;
  }

  alreadyContainsAttribute(attribute: AttributeInterface, filterOptions: Array<FilterOptionsInterface>): boolean {
    for(let filterOption of filterOptions){
      if(attribute.id === filterOption.attributeId){
        return false;
      }
    }
    return true;
  }

  convertValidationValuesToArray(validations: Array<ValidationInterface>): Array<string>{
    let validationValues = [];
    for(let validation of validations){
      validationValues = validationValues.concat(validation.values);
    }
    return validationValues;
  }

  filterOptionsChanged(event, eventFromSelection: boolean, filterValue: any, attributeId: number){
    if(eventFromSelection || event.which == 13) {
      if(!this.isPtgFiltered){
        this.isPtgFiltered = true;
      }
     let filterOptionValues = this.convertFilterOptionsToString();
     this.optionsFiltered.emit();
      this.sidemenuService.filterSidemenuComposer(filterValue, attributeId);
    }
  }

  checkIfProductVersionMatchesAllFilterOptions(child, filterOptionValues): boolean {
    for (let productVariant of child.children) {
      for (let variantVersion of productVariant.variantVersions) {
        let allOptionsMatching: Array<boolean> = [];
        if(variantVersion.attributeValidationValues.length > 0){
          for(let filterOptionValue in filterOptionValues){
            if(!Array.isArray(filterOptionValues[filterOptionValue]) || (Array.isArray(filterOptionValues[filterOptionValue]) && filterOptionValues[filterOptionValue].length > 0)){
              let hasMatchingAttribute: boolean = false;
              for(let attribute of variantVersion.attributeValidationValues){
                if(attribute.attribute.id === parseInt(filterOptionValue)){
                  if(Array.isArray(filterOptionValues[filterOptionValue])){
                    let containsAllStrings = true;
                    for(let option of filterOptionValues[filterOptionValue]){
                      if(!attribute.value.includes(option)){
                        containsAllStrings = false;
                      }
                    }
                    hasMatchingAttribute = containsAllStrings;
                  }
                  else{
                    hasMatchingAttribute = attribute.value === filterOptionValues[filterOptionValue];
                  }
                }
              }
              allOptionsMatching = allOptionsMatching.concat(hasMatchingAttribute);
            }
          }
          return !allOptionsMatching.includes(false);
        }
        else {
          return false;
        }
      }
    }
  }

  convertFilterOptionsToString(){
    let result = JSON.parse(JSON.stringify(this.filterOptionValues));

    for (let filterOptionValue in result) {
      if (typeof result[filterOptionValue] !== 'string') {
        if (!Array.isArray(result[filterOptionValue])) {
          result[filterOptionValue] = result[filterOptionValue].toString();
        }
      }
    }
    return result;
  }

  resetFilter(calledFromSidemenu: boolean = false): void{
   if(!calledFromSidemenu){
     this.isPtgFiltered = false;
     this.item.children = this.itemCopy.children
   }
    for(let filterOption of this.filterOptions){
        this.filterOptionValues[filterOption.attributeId] = [];
    }
  }

  filterRating(event): void {
    //
  }

  ngOnInit() {
    if(this.composerItem && this.item){
      this.itemCopy = JSON.parse(JSON.stringify(this.item));
    }
    this.filterOptions =
      this.composerItem ? this.generateFilterOptionsFromSinglePtg() : this.generateFilterOptionsFromAllAttributes();
  }

}
