import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ProductCommonFeatureInterface } from "src/app/model/commonFeature/product-common-feature-interface";
import { ProductInterface } from "src/app/model/products/product-interface";
import { CommonFeatureService } from "src/app/services/common-feature-service/common-feature.service";

@Component({
  selector: "app-product-detail-display-common-features",
  templateUrl: "./product-detail-display-common-features.component.html",
  styleUrls: ["./product-detail-display-common-features.component.css"],
})
export class ProductDetailDisplayCommonFeaturesComponent implements OnInit {
  @Input()
  public product: ProductInterface;

  @Input()
  public selected: boolean;

  public commonFeatures: ProductCommonFeatureInterface[];

  constructor(private commonFeatureService: CommonFeatureService, private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.selected === true && this.commonFeatures === undefined){
        this.commonFeatures = [];
        this.loadCommonFeatures();
    }
  }

  private loadCommonFeatures() {
    this.commonFeatureService
      .getFeaturesForProduct(this.product.id)
      .subscribe((success) => {
        this.commonFeatures = success;
        this.changeDetector.detectChanges();
      });
  }
}
