import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rounded-button',
  templateUrl: './rounded-button.component.html',
  styleUrls: ['./rounded-button.component.css']
})
export class RoundedButtonComponent implements OnInit {

  @Input() label;

  @Input() bold;

  //Only colors defined in rounded-button.component.css are possible, if no color input was received it remains default color
  @Input() color;

  public nonDefaultColor = false;

  @Output()
  press = new EventEmitter()
  constructor() { }

  onPress(){
    this.press.emit();
  }

  ngOnInit() {
    if(this.color) {
      this.nonDefaultColor = true;
    }
  }

}
