<div *ngIf="this.product != null || this.certification != null" #picker class="picker">
  <div #variant style="display: grid; grid-template-rows: auto auto; margin-top: 1em;">
    <!-------------------------------------->
    <b *ngIf="dataType === 'PRODUCT'">Produktvariante</b>
    <b *ngIf="dataType != 'PRODUCT'">Variante</b>
    <!-------------------------------------->
    <mat-form-field>
      <mat-select
        #variantSelect
        [disableOptionCentering]="false"
        panelClass="dropdown"
        [formControl]="variantControl"
      >
        <mat-option *ngFor="let variant of activeVariants" [value]="variant">
          {{ variant.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-------------------------------------->
  
  </div>
  <div #version style="display: grid; grid-template-rows: auto auto; margin-top: 1em;">
    <!-------------------------------------->
    <b *ngIf="dataType === 'PRODUCT'">Produktversion</b>
    <b *ngIf="dataType != 'PRODUCT'">Version</b>
    <!-------------------------------------->
    <mat-form-field>
      <mat-select
        #versionSelect
        panelClass="dropdown"
        [formControl]="versionControl"
      >
    <mat-option
          *ngFor="let version of versionsOfSelectedVariant"
          [value]="version"
        >
          {{ version.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-------------------------------------->
  </div>
</div>
