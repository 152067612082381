<quill-editor
  [(ngModel)]="editorText"
  [placeholder]="placeholder"
  name="description"
  [styles]="textEditorStyle"
  [modules]="textEditorModules"
  #description="ngModel"
  id="editor"
>
</quill-editor>
<div
  *ngIf="!textLengthBelowMax; else descriptionInfo"
  class="description error"
>
  Die Beschreibung darf nicht länger sein als {{ max_text_length }} Zeichen
  (aktuell {{ editorText.length }}).
</div>
<ng-template #descriptionInfo>
  <div class="description">
    Aktuelle Zeichenlänge (inklusive Formatierung)
    {{ editorText.length }} (Max: {{ max_text_length }})
  </div>
</ng-template>
