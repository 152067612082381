import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataType } from "src/app/model/data-type.enum";
import { OrganisationType } from "src/app/model/enums/organisationType";
import { CertificationOverviewInterface } from "src/app/model/marketplace/certification-overview-interface";
import { DisplayItemInterface } from "src/app/model/marketplace/display-item-interface";
import { OrganisationOverviewInterface } from "src/app/model/marketplace/organisation-overview-interface";
import { ProductOverviewInterface } from "src/app/model/marketplace/product-overview-interface";

import { MarketplaceService } from "src/app/services/dataServices/marketplace-service/marketplace.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-marketplace-product-rondell",
  templateUrl: "./marketplace-product-rondell.component.html",
  styleUrls: ["./marketplace-product-rondell.component.css"],
})
export class MarketplaceProductRondellComponent implements OnInit {
  public displayItem: DisplayItemInterface[] = [];
  private slider: HTMLElement;
  private sliderElements: HTMLElement[] = [];
  private currentMoveDirection: Direction = Direction.RIGHT;
  private moveTimeout: any = null;
  private currentMouseXPosition: number;
  private scrollingPaused: boolean = false;
  private dragging: boolean = false;

  private SCROLL_TIMEOUT: number = 3000; //ms

  @Input()
  public title = "";

  private screenChangeSubscription: any;

  constructor(
    private router: Router,
    private marketplaceService: MarketplaceService
  ) {}

  ngOnInit() {
    this.screenChangeSubscription = this.marketplaceService.filterService.screenStateObservable.subscribe(
      () => {
        this.scrollingPaused = true;
        clearTimeout(this.moveTimeout);
        this.moveTimeout = null;
        //this.loadItems();
        this.setDefaultTitle();
      }
    );
  }

  ngOnDestroy(): void {
    clearTimeout(this.moveTimeout);
    this.moveTimeout = null;
    this.screenChangeSubscription.unsubscribe();
  }

  setDefaultTitle() {
    if (this.title != "") {
      switch (this.marketplaceService.filterService.marketScreenState) {
        case DataType.PRODUCT:
          this.title = "Neuste Produkte";
          break;
        case DataType.ORGANISATION:
          this.title = "Neuste Organisationen";
          break;
        case DataType.CERTIFICATION:
          this.title = "Neuste Zertifikate";
          break;
        case DataType.TOOLCHAIN:
          this.title = "Neuste Toolchains";
          break;
      }
    }
  }

  /*
  loadItems() {
    this.marketplaceService
      .getHighlightedElements()
      .then((displayItem: DisplayItemInterface[]) => {
        this.displayItem = displayItem;
        /*this.displayItem.sort((a, b) => {
          if (a.name == null) return -1;
          if (b.name == null) return 1;
          return a.name.localeCompare(b.name);
        }); 

        setTimeout(() => this.initSlider(), 250);
      });
  } */

  /**
   * This function does the linking to the detail page.
   * @param link link to navigate to
   */
  onDisplayItemClicked(link: string) {
    if (
      this.marketplaceService.filterService.marketScreenState ===
      DataType.ORGANISATION
    ) {
      link = "@" + link;
    }

    if (!this.dragging) {
      clearTimeout(this.moveTimeout);
      this.moveTimeout = null;
      this.router.navigateByUrl(link);
    }
  }

  initSlider() {
    this.slider = document.getElementById("rondell");

    if (this.slider !== null) {
      this.sliderElements = [];
      for (let i = 0; i < this.slider.children.length; i++) {
        this.sliderElements.push(this.slider.children.item(i) as HTMLElement);
      }

      if (this.moveTimeout === null) {
        this.moveTimeout = setTimeout(
          () => this.moveAutomatically(),
          this.SCROLL_TIMEOUT
        );
        this.scrollingPaused = false;
      }
    } else {
      //Retry every second
      setTimeout(() => this.initSlider(), 1000);
    }
  }

  moveLeft(): void {
    this.move(Direction.LEFT);
    this.resetTimeout();
  }

  moveRight(): void {
    this.move(Direction.RIGHT);
    this.resetTimeout();
  }

  resetTimeout(): void {
    clearTimeout(this.moveTimeout);

    this.moveTimeout = setTimeout(
      () => this.moveAutomatically(),
      this.SCROLL_TIMEOUT
    );
  }

  moveAutomatically(): void {
    if (this.slider === null) {
      return;
    }

    switch (this.currentMoveDirection) {
      case Direction.LEFT:
        if (!this.isAtScrollMinimum()) {
          this.move(Direction.LEFT);
        } else {
          this.move(Direction.RIGHT);
          this.currentMoveDirection = Direction.RIGHT;
        }
        break;
      case Direction.RIGHT:
        if (!this.isAtScrollMaximum()) {
          this.move(Direction.RIGHT);
        } else {
          this.move(Direction.LEFT);
          this.currentMoveDirection = Direction.LEFT;
        }
        break;
    }

    if (!this.scrollingPaused) {
      this.moveTimeout = setTimeout(
        () => this.moveAutomatically(),
        this.SCROLL_TIMEOUT
      );
    }
  }

  clearDragging(): void {
    this.dragging = false;
  }

  pauseScrolling($event: MouseEvent): void {
    if (!this.scrollingPaused) {
      this.scrollingPaused = true;
      this.currentMouseXPosition = $event.clientX;
      clearTimeout(this.moveTimeout);
    }
  }

  resumeScrolling(): void {
    if (this.dragging) {
      //Has to be called after the Click Event
      setTimeout(() => this.clearDragging(), 1);
    }

    if (this.scrollingPaused) {
      this.scrollingPaused = false;
      this.moveTimeout = setTimeout(
        () => this.moveAutomatically(),
        this.SCROLL_TIMEOUT
      );
      this.currentMouseXPosition = null;
    }
  }

  movingMouse($event: MouseEvent): void {
    if (this.currentMouseXPosition === null) {
      return;
    }

    if (this.slider === null || this.slider === undefined) {
      return;
    }

    if (this.scrollingPaused) {
      const movement = this.currentMouseXPosition - $event.clientX;
      this.dragging = true;

      if (
        this.slider.scrollLeft + movement >= 0 &&
        this.slider.scrollWidth - this.slider.clientWidth >=
          this.slider.scrollLeft + movement
      ) {
        this.slider.scrollLeft = this.slider.scrollLeft + movement;
      } else if (this.slider.scrollLeft + movement < 0) {
        this.slider.scrollLeft = 0;
      } else {
        this.slider.scrollLeft =
          this.slider.scrollWidth - this.slider.clientWidth;
      }

      this.currentMouseXPosition = $event.clientX;
    }

    $event.preventDefault();
  }

  move(direction: Direction) {
    if (this.slider === null || this.sliderElements.length === 0) {
      return;
    }

    const mostLeftElement = this.findMostLeftElement();

    if (mostLeftElement === null || this.scrollingPaused) {
      return;
    }

    switch (direction) {
      case Direction.LEFT:
        if (this.sliderElements.indexOf(mostLeftElement) > 0) {
          this.scrollTo(
            this.sliderElements[
              this.sliderElements.indexOf(mostLeftElement) - 1
            ].offsetLeft
          );
        }
        break;
      case Direction.RIGHT:
        if (
          this.sliderElements.indexOf(mostLeftElement) <
          this.sliderElements.length - 1
        ) {
          this.scrollTo(
            this.sliderElements[
              this.sliderElements.indexOf(mostLeftElement) + 1
            ].offsetLeft
          );
        }
        break;
    }
  }

  scrollTo(offsetLeft: number): void {
    this.slider.scroll({
      top: 0,
      left: offsetLeft,
      behavior: "smooth",
    });
  }

  isAtScrollMaximum(): boolean {
    return this.slider
      ? this.slider.scrollLeft + this.slider.clientWidth ===
          this.slider.scrollWidth
      : false;
  }

  isAtScrollMinimum(): boolean {
    return this.slider ? this.slider.scrollLeft === 0 : false;
  }

  findMostLeftElement(): HTMLElement {
    if (
      this.slider === undefined ||
      this.slider === null ||
      this.sliderElements.length === 0
    ) {
      return null;
    }

    const currentScrollLeft = this.slider.scrollLeft;
    let mostLeftElement: HTMLElement = this.sliderElements[0];
    let distanceToScroll: number =
      this.sliderElements[0].offsetLeft - currentScrollLeft;

    for (let i = 1; i < this.sliderElements.length; i++) {
      const distance = this.sliderElements[i].offsetLeft - currentScrollLeft;

      if (
        distance >= 0 &&
        (distance < distanceToScroll || distanceToScroll < 0)
      ) {
        distanceToScroll = distance;
        mostLeftElement = this.sliderElements[i];
      }
    }

    return mostLeftElement;
  }

  itemDrag($event: MouseEvent): void {
    $event.preventDefault();
  }
}

enum Direction {
  LEFT,
  RIGHT,
}
