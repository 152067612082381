<div class="scrollable" *ngIf="this.product.productVariants != null">
  <!--------------------------------------------------------------->
  <app-detail-display-variant-version-selector *ngIf="this.product != null" [product]="product" [dataType]="'PRODUCT'"
    (onVersionChosen)="onVersionSelected($event)">
  </app-detail-display-variant-version-selector>
  <!--------------------------------------------------------------->

  <div class="desc" *ngIf="version.description && version.description?.length > 0"
    [ngClass]="{ hidden: !this.showComplete }" [innerHTML]="this.version.description"></div>
  <h3 style="margin-bottom: 4em;">
    <table class="mat-elevation-z4" style="width: 100%">
      <tr>
        <th style="font-weight: bold; font-size: 1.1em;">{{ endpoints.Name }}</th>
      </tr>
      <div *ngFor="let Snapshots of endpoints.Snapshots" class="one-column-table">
        <tr  *ngFor="let Containers of Snapshots.DockerSnapshotRaw.Containers" class="three-column-table">
            <td>{{ Containers.Names }}</td>
            <td>{{ Containers.State }}</td>
            <td>{{ Containers.Status }}</td>
        </tr>
      </div>
    </table>
  </h3>
</div>