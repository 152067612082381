import { Injectable } from '@angular/core';
import * as JSEncryptModule from 'jsencrypt';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  rsaEncrypt;

  constructor() {
        // Non-perfect variant of RSA is used by this library. Maybe search for a better one
        this.rsaEncrypt = new JSEncryptModule.JSEncrypt({});
        this.rsaEncrypt.setPublicKey(environment.publicKey);
  }


  public encrypt(plaintext: string): string {
    return this.rsaEncrypt.encrypt(plaintext);
  }
}
