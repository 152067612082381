<div class="wrapper">
  <div class="container" [ngClass]="{ 'activeBg': isActive() }">
    <div class="hexagonWrapper">
      <div class="hexagon outer">
        <div class="hexagon inner" [ngClass]="{ 'activeHex': isActive() }">
          <div class="stripe"></div>
          <div class="stripe rotated"></div>
        </div>
      </div>
    </div>
    <div class="label" [ngClass]="{ 'activeLabel': isActive() }">
      <p class="prevent-selection">{{label}}</p>
    </div>
  </div>
</div>