import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { catchError, take, switchMap, filter } from "rxjs/operators";
import { AuthService } from "../services/auth-service/auth.service";
import { DialogService } from "../services/dialog-service/dialog-service.service";
import { ErrorToDialog } from "../model/error/error-to-dialog";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authService: AuthService,
    private dialogService: DialogService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = this.addAuthenticationToken(req);
    return next.handle(req).pipe(
      //#region  ERROR / EXCEPTION HANDELING ---------
      catchError((error) => {
        if (!error) {
          return;
        }
        //---------------------------------------------------------------------
        if (req.url.includes("refresh") || req.url.includes("login")) {
          if (req.url.includes("refresh")) {
            // I guess this refresh = logout + new login
            this.authService.logout();
          }
        }
        //---------------------------------------------------------------------
        if (error.error && typeof error.error === 'object' && "title" in error.error) {
          let errorToDialog: ErrorToDialog = error.error;
          this.dialogService.openDialog(
            errorToDialog.title,
            errorToDialog.message
          );
        }
        //---------------------------------------------------------------------
        // to catch wierd nested errors
        if (error.error && error.error.message &&  (error.error.message as string).match("title")) {
          let errorToDialog: ErrorToDialog = JSON.parse(error.error.message);
          this.dialogService.openDialog(
            errorToDialog.title,
            errorToDialog.message
          );
        }

        if (error.status === 401) {
          //---------------------------------------------------------------------
          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter((result) => result != null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(req)))
            );
          }
          //---------------------------------------------------------------------
          if (localStorage.getItem("refresh_token")) {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            return this.authService.refreshTokens().pipe(
              switchMap((token: string) => {
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(token);
                return next.handle(this.addAuthenticationToken(req));
              }),
              catchError((error) => {
                this.refreshTokenInProgress = false;
                this.authService.logout();
                return throwError(error);
              })
            );
          }
          //---------------------------------------------------------------------
        }
        return throwError(error);
      })
      //#endregion
    );
  }

  addAuthenticationToken(request) {
    const access_token: string = localStorage.getItem("access_token");
    if (access_token) {
      const headers: HttpHeaders = request.headers.set(
        "Authorization",
        "Bearer " + access_token
      );
      request = request.clone({ headers: headers });
    }
    return request;
  }
}
