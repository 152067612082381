import { API_MAP, API_BASE_PRODUCTSERVICE } from "src/environments/api";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../../base.service";
import { CertificationVariantInterface } from "src/app/model/certifications/certification-variant-interface";
import { CertificationVariantVersionService } from "../cetrification-variant-version-service/certification-variant-version-service";
import { UserService } from "../../user-service/userservice";
import { CertificationVariantVersionInterface } from "src/app/model/certifications/certification-variant-version-interface";
import { CertificationDtoConverterService } from "../../certification-dto-converter-service/certification-dto-converter-service.service";

const SWARM_API = API_BASE_PRODUCTSERVICE;
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class CertificationVariantService extends BaseService<
  CertificationVariantInterface
> {
  protected equal(
    item1: CertificationVariantInterface,
    item2: CertificationVariantInterface
  ): boolean {
    return JSON.stringify(item1) === JSON.stringify(item2);
  }
  protected toID(item: CertificationVariantInterface | number): number {
    if (typeof item === "number") return item;
    if (item.id) return item.id;
    return -1;
  }

  activeOrganisation: number;

  constructor(
    protected http: HttpClient,
    protected certificationDtoConverter: CertificationDtoConverterService,
    private userService: UserService,
    private versionService: CertificationVariantVersionService
  ) {
    super(http, null, certificationDtoConverter);
    this.SWARM_API_BASE = SWARM_API;
    //-----------------------
    // Specify a item mapping to know which URL identifier is to be mapped
    // to witch information on an item
    this.ITEM_MAP = userService.ACTIVE_MAP;

    this.SERVICE_FLAG = ":cetrificationVariantId";
    // Setting coded URL path for the API of this service
    this.SWARM_API_MAP = API_MAP["certificationVariants"];

    this.versionService.deleteRequestObserver.subscribe(
      (toBeDeleted: CertificationVariantVersionInterface) => {
        if (this.activeItem && this.activeItem.certificationVariantVersions) {
          const index: number = this.activeItem.certificationVariantVersions.findIndex(
            (version: CertificationVariantVersionInterface) => {
              return version.id === toBeDeleted.id;
            }
          );
          if (index >= 0) {
            this.activeItem.certificationVariantVersions.splice(index, 1);
          }
        }
      }
    );
    //-----------------------
    // This is the upwards subscription.
    // Subscribe to the version service so this instance of variant is updated
    // this ensures that there is no unnecessary traffic to update
    this.versionService.activeItemObserver.subscribe(
      (version: CertificationVariantVersionInterface) => {
        if (!version || typeof version === "undefined") {
          return;
        }

        // The active Item is always the current variant that is edited
        if (version) {
          let index = this.activeItem.certificationVariantVersions.findIndex(
            (other) => {
              return other.id === version.id;
            }
          );

          if (index > -1) {
            // there is version with same ID

            // but the version was edited so override the reference
            // in the active variant with the new version
            this.activeItem.certificationVariantVersions[index] = version;
          } else {
            // version is not in variant so add it
            this.activeItem.certificationVariantVersions.push(version);
            // we update the active item in collection
          }
          this.updateItemInCollection(this.activeItem);
        }
        this.nextItem();
      }
    );

    //-----------------------
  }

  get activeVariant() {
    return this.activeItem;
  }

  /**
   *  Downward propagation to update new loaded items into the subservice
   * @param variant to set versions in versionservice for
   */
  updateCertificationVariantService(variant: CertificationVariantInterface) {
    this.updateItemInCollection(variant);
    variant.certificationVariantVersions.forEach(
      (version: CertificationVariantVersionInterface) => {
        this.versionService.updateVersionService(version);
      }
    );
  }

  // Downward propagation on exit
  deleteVersionsIn(variant: CertificationVariantInterface) {
    variant.certificationVariantVersions.forEach((version) => {
      this.versionService.deleteLocalVersion(version);
    });
    let index = this.collection.findIndex((version) => this.idCompare(version, variant));
    if (index >= 0) {
      this.collection.splice(index, 1);
    }
  }

  // Sets Variant active in UserService
  setActiveVariant(
    variant: CertificationVariantInterface | number
  ): Promise<boolean> {
    let id = this.toID(variant);
    this.userService.ACTIVE_MAP[":certificationVariantId"] = String(id);
    return this.setActiveItem(variant);
  }
}
