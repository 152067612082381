<div class="two-columns" *ngIf="productService.activeItem">
  <div>
    <div class="min-auto">
      <h1>Produkt bearbeiten</h1>
    </div>
    <div class="auto-min">
      <mat-form-field>
        <input
          matInput
          placeholder="Hersteller"
          [value]="organisationService.activeItem.organisationName"
          disabled
        />
      </mat-form-field>
      <mat-form-field>
        <mat-select
          placeholder="Produktkategorie"
          [value]="fetchedProduct.category"
          (valueChange)="setProductEditCategory($event)"
        >
          <mat-option [value]="productCategory.APP">Anwendung</mat-option>
          <mat-option [value]="productCategory.SERVICE">Service</mat-option>
          <mat-option [value]="productCategory.CONTENT"
            >Zusätzliche Inhalte</mat-option>
          <mat-option [value]="productCategory.ALLGEMEIN"
            >Allgemein</mat-option>
          <mat-option [value]="productCategory.HOCHBAU"
            >Hochbau</mat-option>
            <mat-option [value]="productCategory.TIEFBAU"
            >Tiefbau</mat-option>
            <mat-option [value]="productCategory.WASSERBAU"
            >Wasserbau</mat-option>
            <mat-option [value]="productCategory.EISENBAHNBAU"
            >Eisenbahnbau</mat-option>
            <mat-option [value]="productCategory.STRASENBAU"
            >Straßenbau</mat-option>
          <mat-option [value]="productCategory.INFRASTRUKTUR"
            >Infrastruktur</mat-option>
            <mat-option [value]="productCategory.BAUSTELLE"
            >Baustelle</mat-option>
            <mat-option [value]="productCategory.EDGESERVER"
            >EdgeServer</mat-option>  
        </mat-select>
        
      </mat-form-field>
    </div>
    <form #form="ngForm">
      <mat-form-field
        class="product-name"
        hintLabel="Keine schon vorhandenen Produktnamen oder Sonderzeichen"
      >
        <input
          matInput
          placeholder="Name des Produktes"
          [(ngModel)]="productEdit.name"
          [(value)]="productEdit.name"
          required
          name="productName"
          #productName="ngModel"
        />
        <mat-error *ngIf="productName.errors?.required && productName.touched">
          Bitte geben Sie einen Produktnamen ein.
        </mat-error>
      </mat-form-field>
      <app-text-editor
        [(editorText)]="productEdit.description"
        (textValidityChange)="onDescriptionValidityChange($event)"
      ></app-text-editor>
      <br />
      <div class="auto-min">
        <app-add-product-dependency-panel
          [product]="productEdit"
          [isCreate]="false"
        ></app-add-product-dependency-panel>
      </div>
      <div class="auto-min">
        <app-selected-product-panel
          [hidden]="productEdit.relatedProducts.length === 0"
          [product]="productEdit"
        >
        </app-selected-product-panel>
      </div>
      <br />
        <app-add-interface-dependency-pannel [product]="productEdit" [isCreate]="false" > </app-add-interface-dependency-pannel>
        <app-selected-interface-dependency-pannel [hidden]="productEdit.relatedProducts.length === 0" [product]="productEdit"> </app-selected-interface-dependency-pannel>
      <br />
      <mat-form-field class="url">
        <input
          matInput
          placeholder="URL"
          [(ngModel)]="productEdit.url"
          [value]="fetchedProduct.url"
          name="url"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Slogan"
          [(ngModel)]="productEdit.slogan"
          [value]="productEdit.slogan"
          name="slogan"
        />
      </mat-form-field>
      <addEntriesToList-panel
        [(keywords)]="productEdit.features"
        [editable]="true"
        [keywordPanel]="false"
      >
      </addEntriesToList-panel>

      <div class="commonFeatureSelection">
        <p><b>Allgemeine Merkmale</b></p>
        <button class="btn_green" mat-raised-button (click)="showCommonFeatureSelectionDialog()">Auswählen</button>
      </div>

      <div class="">
        <h2>Produktbild</h2>

        <div class="prodLogo">
          <button mat-raised-button (click)="openFileManagerPicture('Logo')">Logo ändern</button><br>
          <img [src]="imagePath(productEdit.fileId)">
        </div>
        <h2>Dokumente</h2>
        <ul>
          <li *ngFor="let id of productEdit.documentFileIds" class="document-item">{{getNameOfDocument(id)}} <button (click)="deleteDocument(id)">X</button></li>
        </ul>
        <button mat-raised-button (click)="openFileManagerPicture('Dokumente')">hinzufügen</button>

        <h2 class="titleGal">Produktgalerie</h2>
        <div class="product-gallery-editor">
        <div  class="gallery-preview">
        <div *ngFor="let id of productEdit.galleryFileIds" class="gallery-item">
          <div class="gallery-item-control">
            <button mat-flat-button class="gal_sortLeft" (click)="moveGalleryItem(id,true)">&lt;</button>
            <button mat-flat-button class="gal_delete" color="warn" (click)="deleteFromGallery(id)">&times;</button>
            <button mat-flat-button class="gal_sortRight" (click)="moveGalleryItem(id,false)">&gt;</button>

            </div>
            <app-secure-image class="pictureSize" style="margin-top: 1vw" [src]="imagePath(id)"></app-secure-image>
          </div>

        </div>
        <div class="gallery-preview-control">
          <div class="mini-hex-button" id="gallery-add-item">
            <hexagon [icon]="'plus'" [hover]="true" [outerColor]="'green'" (click)="openFileManagerPicture('Galerie')"></hexagon>
          </div>
        </div>
      </div>
      </div>
      <div class="auto-auto">
        <button
          mat-raised-button
          class="btn_green"
          [disabled]="form.invalid || !isDescriptionValid"
          (click)="onSaveEdit()"
        >
          Speichern
        </button>
        <button mat-raised-button class="btn_red" (click)="onCancelEdit()">
          Abbrechen
        </button>
      </div>
      
  
    </form>
    <app-dialog-add-product-to-baustelle [serverId]="productEdit.id"  *ngIf="productEdit.category == 'Baustelle'"></app-dialog-add-product-to-baustelle>
    <button mat-raised-button style="margin-top: 0.5vw;" class="btn_green" (click)="handleChooseProduct()"> Bereitstellung von Docker-Containern auf der Baustelle </button>
  </div>

</div>
