<div>
  <div *ngFor="let attribute of attributes">
    <div
      class="attributeRow"
      (click)="setAttributeActive(attribute.validations[0].id)"
    >
      <div>
        <div
          *ngIf="attribute.description"
          matTooltip="{{ attribute.description }}"
          [matTooltipPosition]="'below'"
          matTooltipClass="tooltip"
        >
          <img [src]="'/assets/img/icons/info.png'" alt="Beschreibungs-Icon" />
        </div>
      </div>
      <div>
        <p *ngIf="!optional">
          {{ attribute.name }}
        </p>
        <p *ngIf="optional">{{ attribute.name }} (optional)</p>
      </div>
      <div [ngSwitch]="attribute.datatype">
        <div *ngSwitchCase="'STRING'">
          <mat-form-field>
            <input
              [disabled]="hasAttributeConflict(attribute)"
              autocomplete="off"
              matInput
              placeholder="Text"
              [ngModel]="
                attributeValidationService.activeEntityFeatureValues.get(
                  attribute.id
                )
              "
              (ngModelChange)="
                attributeValidationService.modelChangedIsOptional(
                  attribute,
                  attribute.validations[0],
                  $event
                )
              "
              [minlength]="getMinValue(attribute)"
              [maxlength]="getMaxValue(attribute)"
              name="stringField{{ attribute.validations[0].id }}"
              [ngModelOptions]="{ standalone: true }"
              #stringField="ngModel"
            />
          </mat-form-field>
        </div>
        <div *ngSwitchCase="'NUMERIC'">
          <mat-form-field>
            <input
              [disabled]="hasAttributeConflict(attribute)"
              autocomplete="off"
              matInput
              appDigitOnly
              allowNegative="true"
              type="text"
              placeholder="Zahl"
              [ngModel]="
                attributeValidationService.activeEntityFeatureValues.get(
                  attribute.id
                )
              "
              (ngModelChange)="
                attributeValidationService.modelChangedIsOptional(
                  attribute,
                  attribute.validations[0],
                  $event
                )
              "
              name="numericField{{ attribute.validations[0].id }}"
              [ngModelOptions]="{ standalone: true }"
              #numericField="ngModel"
            />
          </mat-form-field>
        </div>
        <div *ngSwitchCase="'BOOLEAN'">
          <toggle-switch
            [disableToggle]="hasAttributeConflict(attribute)"
            [trueLabel]="'Ja'"
            [falseLabel]="'Nein'"
            [allowUndefined]="true"
            [value]="
              attributeValidationService.activeEntityFeatureValues.get(
                attribute.id
              )
            "
            (valueChange)="
              attributeValidationService.modelChangedIsOptional(
                attribute,
                attribute.validations[0],
                $event
              )
            "
          >
          </toggle-switch>
        </div>
        <div *ngSwitchCase="'LIST'">
          <mat-form-field>
            <mat-select
              [disabled]="hasAttributeConflict(attribute)"
              multiple
              placeholder="Liste"
              [ngModel]="
                attributeValidationService.activeEntityFeatureValues.get(
                  attribute.id
                )
              "
              (ngModelChange)="
                attributeValidationService.modelChangedIsOptional(
                  attribute,
                  attribute.validations[0],
                  $event
                )
              "
              name="listField{{ attribute.validations[0].id }}"
              #listField="ngModel"
            >
              <mat-option
                *ngFor="let listEntry of attribute.validations[0].values"
                [value]="listEntry"
                >{{ listEntry }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div>
        <validation-icon
          [value]="
            attributeValidationService.attributesValidated.get(attribute.id)
          "
        ></validation-icon>
      </div>
      <div *ngIf="editable">
        <red-cross-button
          (click)="removeAttribute(attribute)"
        ></red-cross-button>
      </div>
    </div>
    <div
      [hidden]="
        activeAttributeId !== attribute.validations[0].id ||
        hasAttributeConflict(attribute)
      "
    >
      <ng-container [ngSwitch]="attribute.datatype">
        <ng-container *ngSwitchCase="'STRING'">
          <!----- TEMP
          <mat-card *ngIf="attribute.description" class="description-card">
            <mat-card-header>
              <mat-card-title class="description-title"
                >Beschreibung</mat-card-title
              >
            </mat-card-header>
            <mat-card-content>{{ attribute.description }}</mat-card-content>
          </mat-card>
          -->
          <div class="validationRow">
            <div>
              <p>Min. Länge:</p>
              <p>Max. Länge:</p>
            </div>
            <div>
              <p>{{ getMinValue(attribute) }}</p>
              <p>{{ getMaxValue(attribute) }}</p>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'NUMERIC'">
          <!----- TEMP
          <mat-card *ngIf="attribute.description" class="description-card">
            <mat-card-header>
              <mat-card-title class="description-title"
                >Beschreibung</mat-card-title
              >
            </mat-card-header>
            <mat-card-content>{{ attribute.description }}</mat-card-content>
          </mat-card>
        -->
          <div class="validationRow">
            <div>
              <p>Min. Wert:</p>
              <p>Max. Wert:</p>
            </div>
            <div>
              <p>{{ getMinValue(attribute) }}</p>
              <p>{{ getMaxValue(attribute) }}</p>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'LIST'">
          <!----- TEMP
          <mat-card *ngIf="attribute.description" class="description-card">
            <mat-card-header>
              <mat-card-title class="description-title"
                >Beschreibung</mat-card-title
              >
            </mat-card-header>
            <mat-card-content>{{ attribute.description }}</mat-card-content>
          </mat-card>
        -->
          <div class="validationRow">
            <div>
              <p>Min. Auswahl:</p>
              <p>Max. Auswahl:</p>
            </div>
            <div>
              <p>{{ getMinValue(attribute) }}</p>
              <p>{{ getMaxValue(attribute) }}</p>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'BOOLEAN'">
          <!----- TEMP
          <mat-card *ngIf="attribute.description" class="description-card">
            <mat-card-header>
              <mat-card-title class="description-title"
                >Beschreibung</mat-card-title
              >
            </mat-card-header>
            <mat-card-content>{{ attribute.description }}</mat-card-content>
          </mat-card>
        -->
          <div class="validationRow">
            <div>
              <p>Validierungswert:</p>
            </div>
            <div>
              <p>
                {{ getBooleanValue(attribute) | booleanToString }}
              </p>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div *ngIf="hasAttributeConflict(attribute)" class="conflict-error">
      {{ getConflictErrorMessage(attribute) }}
    </div>
  </div>
</div>
