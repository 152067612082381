import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "certification-counter",
  templateUrl: "./certification-counter.component.html",
  styleUrls: ["./certification-counter.component.css"],
})
export class CertificationCounterComponent implements OnInit {
  @Input()
  public certCount: number;

  @Input()
  public tooltip = "";

  @Input()
  public certImageUrl: string;

  constructor() {}

  ngOnInit() {}
}
