import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  Input,
} from "@angular/core";
import { MalfunctionService } from "src/app/services/malfunction-service/malfunction.service";
import { ActivatedRoute } from "@angular/router";
import { MalfunctionInterface } from "src/app/model/malfunctions/malfunction-interface";
import { MalfunctionDataSource } from "src/app/dataSource/malfunctionDataSource";
import { Subscription } from "rxjs";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { ProductService } from "src/app/services/dataServices/product-service/productService";

@Component({
  selector: "app-malfunction-overview",
  templateUrl: "./malfunction-overview.component.html",
  styleUrls: ["./malfunction-overview.component.css"],
})
export class MalfunctionOverviewComponent implements OnInit, OnDestroy {
  private productId: number = +this.route.snapshot.paramMap.get("productId");
  private productName: string;
  malfunctionsActive: MalfunctionInterface[];
  malfunctionsFixed: MalfunctionInterface[];
  malfunctionsInProgress: MalfunctionInterface[];
  public dataSource: MalfunctionDataSource;
  public showMalfunctions: number;
  @Input() adminView?: boolean;

  private dataSourceHubFixed: Subscription;
  private dataSourceHubActive: Subscription;
  private dataSourceHubInProgress: Subscription;
  private moreMalfunctionAvailable: boolean = true;
  private pageIndexActive: number = 0;
  private pageIndexFixed: number = 0;
  private pageIndexInProgress: number = 0;
  private readonly pageSize: number = 10;

  constructor(
    private malfunctionService: MalfunctionService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private productService: ProductService
  ) {}

  ngOnInit() {
    this.malfunctionService
      .getNewMalfunctionSubject()
      .subscribe((malfunction) => this.moveMalfunction(malfunction));

    this.malfunctionService
      .getDeletedMalfunctionSubject()
      .subscribe((deletedMalfunction) => {
        this.removeOldMalfunction(deletedMalfunction);
      });

    this.productService
      .getProduct(this.productId)
      .then((product) => (this.productName = product.name));

    this.dataSource = new MalfunctionDataSource(
      this.malfunctionService,
      this.changeDetector
    );

    this.malfunctionsActive = [];
    this.malfunctionsFixed = [];
    this.malfunctionsInProgress = [];

    this.showMalfunctions = this.dataSource.malfuncActiveNum; // initial malfunctions to be shown, 0 for active malfunctions
    this.dataSourceHubActive = this.dataSource
      .getObservableActive()
      .subscribe((page) => {
        this.malfunctionsActive = this.malfunctionsActive.concat(page);
        this.changeDetector.detectChanges();
      });

    this.dataSourceHubInProgress = this.dataSource
      .getObservableInProgress()
      .subscribe((page) => {
        this.malfunctionsInProgress = this.malfunctionsInProgress.concat(page);
        this.changeDetector.detectChanges();
      });

    this.dataSourceHubFixed = this.dataSource
      .getObservableFixed()
      .subscribe((page) => {
        this.malfunctionsFixed = this.malfunctionsFixed.concat(page);
        this.changeDetector.detectChanges();
      });
  }

  /**
   * Gibt es die Malfunction schon in einer der 3 MalfunctionArrays
   */
  private doesMalfunctionExist(malfunction: MalfunctionInterface): boolean {
    return (
      malfunction.malfunctionId &&
      (this.malfunctionsActive
        .map((malfunc) => malfunc.malfunctionId)
        .includes(malfunction.malfunctionId) ||
        this.malfunctionsInProgress
          .map((malfunc) => malfunc.malfunctionId)
          .includes(malfunction.malfunctionId) ||
        this.malfunctionsFixed
          .map((malfunc) => malfunc.malfunctionId)
          .includes(malfunction.malfunctionId))
    );
  }

  /**
   * verschiebt die Malfunction in die richtig Kategorie nach dem Bearbeiten
   */
  private moveMalfunction(malfunction: MalfunctionInterface): void {
    // falls es die Störung schon gab (also sie wurde bearbeitet), soll hier die alte Version entfernt werden
    let doesMalfuncExist: boolean = this.doesMalfunctionExist(malfunction);
    if (doesMalfuncExist) {
      this.removeOldMalfunction(malfunction);
    }

    switch (malfunction.status) {
      case "REPORTED":
        this.malfunctionsActive = [];
        this.pageIndexActive = 0;
        this.dataSource.totalNumberOfElementsActive++;
        this.onScrollActive();
        break;
      case "IN_PROGRESS":
        this.malfunctionsInProgress = [];
        this.pageIndexInProgress = 0;
        this.dataSource.totalNumberOfElementsInProgress++;
        this.onScrollInProgress();
        break;
      case "FIXED":
        this.malfunctionsFixed = [];
        this.pageIndexFixed = 0;
        this.dataSource.totalNumberOfElementsFixed++;
        this.onScrollFixed();
        break;
      default:
        throw new Error("Invalid Status");
    }
  }

  /**
   * Entfernt die ursprüngliche Malfunction vor der Bearbeitung
   * @param removeOldMalfunction
   */
  private removeOldMalfunction(removeOldMalfunction: MalfunctionInterface) {
    this.malfunctionsActive = this.malfunctionsActive.filter(
      (malfunc) => removeOldMalfunction.malfunctionId !== malfunc.malfunctionId
    );
    this.malfunctionsInProgress = this.malfunctionsInProgress.filter(
      (malfunc) => removeOldMalfunction.malfunctionId !== malfunc.malfunctionId
    );
    this.malfunctionsFixed = this.malfunctionsFixed.filter(
      (malfunc) => removeOldMalfunction.malfunctionId !== malfunc.malfunctionId
    );
  }

  ngOnDestroy() {
    if (this.dataSourceHubActive != null) {
      this.dataSourceHubActive.unsubscribe();
    }

    if (this.dataSourceHubFixed != null) {
      this.dataSourceHubFixed.unsubscribe();
    }

    if (this.dataSourceHubInProgress != null) {
      this.dataSourceHubInProgress.unsubscribe();
    }
  }

  /**
   * Pagination für aktive Störungen
   */
  onScrollActive(): void {
    if (
      this.malfunctionsActive.length <
      this.dataSource.totalNumberOfElementsActive
    ) {
      this.dataSource.loadMalfunctions(
        this.pageIndexActive,
        this.pageSize,
        this.productId,
        this.dataSource.malfuncActiveNum,
        !this.adminView
      );
      this.pageIndexActive++;
    }
  }

  /**
   * Pagination für Störungen in Bearbeitung
   */
  onScrollInProgress(): void {
    if (
      this.malfunctionsInProgress.length <
      this.dataSource.totalNumberOfElementsInProgress
    ) {
      this.dataSource.loadMalfunctions(
        this.pageIndexInProgress,
        this.pageSize,
        this.productId,
        this.dataSource.malfuncInProgressNum,
        !this.adminView
      );
      this.pageIndexInProgress++;
    }
  }

  /**
   * Pagination für gefixte Störungen
   */
  onScrollFixed(): void {
    if (
      this.malfunctionsFixed.length < this.dataSource.totalNumberOfElementsFixed
    ) {
      this.dataSource.loadMalfunctions(
        this.pageIndexFixed,
        this.pageSize,
        this.productId,
        this.dataSource.malfuncFixedNum,
        !this.adminView
      );
      this.pageIndexFixed++;
    }
  }

  malfuncActiveShown(): boolean {
    return this.showMalfunctions == this.dataSource.malfuncActiveNum;
  }

  malfuncInProgressShown(): boolean {
    return this.showMalfunctions == this.dataSource.malfuncInProgressNum;
  }
  malfuncFixedShown(): boolean {
    return this.showMalfunctions == this.dataSource.malfuncFixedNum;
  }
}
