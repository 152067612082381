import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'validation-icon',
  templateUrl: './validation-icon.component.html',
  styleUrls: ['./validation-icon.component.css']
})
export class ValidationIconComponent implements OnInit {

  @Input() value: boolean;

  constructor() { }

  ngOnInit() {
  }

}
