<div class="malfunction-overview">
  <h1 *ngIf="!adminView">Störungsübersicht</h1>
  <h2 *ngIf="!adminView">Betroffenes Produkt: {{productName}}</h2>
  <div class="toggle-buttons">
    <mat-button-toggle-group>
      <mat-button-toggle [ngClass]="malfuncActiveShown() ? 'btn_green active' : 'passive'" [disableRipple]="true"
        (click)="showMalfunctions = dataSource.malfuncActiveNum">
        offene Störungen
      </mat-button-toggle>
      <mat-button-toggle [ngClass]="
          showMalfunctions == dataSource.malfuncInProgressNum
            ? 'btn_green active'
            : 'passive'
        " [disableRipple]="true" (click)="showMalfunctions = dataSource.malfuncInProgressNum">
        Störungen in Arbeit
      </mat-button-toggle>
      <mat-button-toggle [ngClass]="
          showMalfunctions == dataSource.malfuncFixedNum
            ? 'btn_green active'
            : 'passive'
        " [disableRipple]="true" (click)="showMalfunctions = dataSource.malfuncFixedNum">
        behobene Störungen
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <app-infinite-scroll *ngIf="malfuncActiveShown()" (scrolled)="onScrollActive()">
    <table>
      <tr>
        <th>
          <p>Titel</p>
        </th>
        <th>
          <p>Aufgetreten am</p>
        </th>
        <th>
          <p>Priorität</p>
        </th>
      </tr>
      <ng-container *ngFor="let malfunction of malfunctionsActive">
        <tr class="pointer" (click)="malfunction.showDescription = !malfunction.showDescription">
          <td width="70%">
            <p>{{ malfunction.titel }}</p>
          </td>
          <td width="20%">
            <p>{{ malfunction.timeStampOccured }}</p>
          </td>
          <td width="10%">
            <p> {{ malfunction.priority }}</p>
          </td>
        </tr>
        <tr *ngIf="!malfunction.onEdit" class="details">
          <td colspan="3" *ngIf="malfunction.showDescription">
            <app-report-malfunction-screen [malfunction]="malfunction" [editForm]="false" [adminView]="adminView"
              (showFormEmitter)="malfunction.showDescription = false">
            </app-report-malfunction-screen>
          </td>
        </tr>
        <tr>
          <app-report-malfunction-screen *ngIf="malfunction.onEdit" [editForm]="true"
            (showFormEmitter)="malfunction.onEdit = false"></app-report-malfunction-screen>
        </tr>
      </ng-container>
    </table>
  </app-infinite-scroll>

  <app-infinite-scroll *ngIf="malfuncInProgressShown()" (scrolled)="onScrollInProgress()">
    <table>
      <tr>
        <th>
          <p>Titel</p>
        </th>
        <th>
          <p>Aufgetreten am</p>
        </th>
        <th>
          <p>Priorität</p>
        </th>
      </tr>
      <ng-container *ngFor="let malfunction of malfunctionsInProgress">
        <tr (click)="malfunction.showDescription = !malfunction.showDescription" class="pointer">
          <td width="80%">
            <p>{{ malfunction.titel }}</p>
          </td>
          <td width="15%">
            <p>{{ malfunction.timeStampOccured }}</p>
          </td>
          <td width="5%">
            <p>{{ malfunction.priority }}</p>
          </td>
        </tr>
        <tr *ngIf="!malfunction.onEdit" class="details">
          <td colspan="3" *ngIf="malfunction.showDescription">
            <app-report-malfunction-screen [malfunction]="malfunction" [editForm]="false" [adminView]="adminView"
              (showFormEmitter)="malfunction.showDescription = false">
            </app-report-malfunction-screen>
          </td>
        </tr>
      </ng-container>
    </table>
  </app-infinite-scroll>

  <app-infinite-scroll *ngIf="malfuncFixedShown()" (scrolled)="onScrollFixed()">
    <table>
      <tr>
        <th>
          <p>Titel</p>
        </th>
        <th>
          <p>Aufgetreten am</p>
        </th>
        <th>
          <p>Behoben am</p>
        </th>
        <th>
          <p>Priorität</p>
        </th>
      </tr>
      <ng-container *ngFor="let malfunction of malfunctionsFixed">
        <tr (click)="malfunction.showDescription = !malfunction.showDescription" class="pointer">
          <td width="70%">
            <p>{{ malfunction.titel }}</p>
          </td>
          <td width="14%">
            <p>{{ malfunction.timeStampOccured }}</p>
          </td>
          <td width="14%">
            <p>{{ malfunction.timeStampFixed }}</p>
          </td>
          <td width="2%">
            <p>{{ malfunction.priority }}</p>
          </td>
        </tr>
        <tr class="details">
          <td colspan="4" *ngIf="malfunction.showDescription">
            <app-report-malfunction-screen [malfunction]="malfunction" [editForm]="false" [adminView]="adminView"
              (showFormEmitter)="malfunction.showDescription = false">
            </app-report-malfunction-screen>
          </td>
        </tr>
      </ng-container>
    </table>
  </app-infinite-scroll>
</div>