import { Component, OnInit, Input } from "@angular/core";
import { CertificationTypes } from "src/app/model/enums/certificationTypes";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-certificate-mini-display",
  templateUrl: "./certificate-mini-display.component.html",
  styleUrls: ["./certificate-mini-display.component.css"],
})
export class CertificateMiniDisplayComponent implements OnInit {
  @Input()
  certificateType: string; //CertificationTypes ist im Backend als Enum, im frontend wird das Enum-Objekt als String geladen
  @Input()
  certCount: number;
  icon: [string, string]; // 1. Element url zum Icon, 2. Beschreibung des Icons beim hovern

  public imageurl: string;
  public tooltip: string;

  getImage(): void {
    let imagePathDescription: [string, string] = [null, null];
    switch (this.certificateType) {
      case CertificationTypes.ATTRIBUTE_CERT:
        imagePathDescription[0] = environment.certificationAttributeIcon;
        imagePathDescription[1] = "Zertifikat für Merkmale";
        break;
      case CertificationTypes.PTG_CERT:
        imagePathDescription[0] = environment.certificationPtgIcon;
        imagePathDescription[1] = "Zertifikat für Produkttyp";
        break;
      case CertificationTypes.TOOLCHAIN_CERT:
        imagePathDescription[0] = environment.certificationToolchain;
        imagePathDescription[1] = "Zertifikat für Toolchains";
        break;
      case CertificationTypes.ATTRIBUTE_MANDATORY_CERT:
        imagePathDescription[0] = environment.certificationAttributeMandatory;
        imagePathDescription[1] = "Zertifikat für verpflichtende Merkmale";
        break;
      default:
        //throw Error("invalid CertificationType");
        break;
    }
    this.icon = imagePathDescription;
  }

  constructor() {}

  ngOnInit() {
    this.getImage();
  }
}
