import { BehaviorSubject, Subscription, Observable, of } from "rxjs";
import { MalfunctionInterface } from "../model/malfunctions/malfunction-interface";
import { MalfunctionService } from "../services/malfunction-service/malfunction.service";
import { catchError } from "rxjs/operators";
import { MalfunctionPageRequest } from "../model/malfunctions/malfunction-page-request-interface";
import { ChangeDetectorRef } from "@angular/core";

export class MalfunctionDataSource {
  readonly malfuncActiveNum = 0;
  readonly malfuncInProgressNum = 1;
  readonly malfuncFixedNum = 2;
  private malfunctionFixedSubject: BehaviorSubject<
    MalfunctionInterface[]
  > = new BehaviorSubject<MalfunctionInterface[]>([]);
  private malfunctionActiveSubject: BehaviorSubject<
    MalfunctionInterface[]
  > = new BehaviorSubject<MalfunctionInterface[]>([]);
  private malfunctionInProgressSubject: BehaviorSubject<
    MalfunctionInterface[]
  > = new BehaviorSubject<MalfunctionInterface[]>([]);

  totalNumberOfElementsActive: number = 1;
  totalNumberOfElementsFixed: number = 1;
  totalNumberOfElementsInProgress: number = 1;
  private currentRequest: Subscription;

  constructor(
    private malfunctionService: MalfunctionService,
    private changeDetector: ChangeDetectorRef
  ) {}

  loadMalfunctions(
    pageIndex: number,
    pageSize: number,
    productId: number,
    status: number,
    onlyPublic: boolean
  ): void {
    if (this.currentRequest) {
      this.currentRequest.unsubscribe();
      this.currentRequest = null;
    }
    let pageRequest: MalfunctionPageRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      status: status,
      onlyPublic: onlyPublic,
    };

    this.currentRequest = this.malfunctionService
      .getMalfunctionPage(productId, pageRequest)
      .pipe(catchError(() => of([])))
      .subscribe((malfunctionPage) => {
        if (status == this.malfuncActiveNum) {
          //"content" is a field of Object of class "Page" in Java, contains all Malfunction of page
          this.malfunctionActiveSubject.next(malfunctionPage["content"]);
          //"totalElements" is a field of "Page" in Java, total amount of Elements
          this.totalNumberOfElementsActive = malfunctionPage["totalElements"];
        } else if (status == this.malfuncInProgressNum) {
          this.malfunctionInProgressSubject.next(malfunctionPage["content"]);
          this.totalNumberOfElementsInProgress =
            malfunctionPage["totalElements"];
        } else if (status == this.malfuncFixedNum) {
          this.malfunctionFixedSubject.next(malfunctionPage["content"]);
          this.totalNumberOfElementsFixed = malfunctionPage["totalElements"];
        } else {
          throw new Error("status value not found"); // Störungsstatus muss zwischen 0 bis 2 sein
        }
      });
  }

  getObservableFixed(): Observable<MalfunctionInterface[]> {
    return this.malfunctionFixedSubject.asObservable();
  }

  getObservableActive(): Observable<MalfunctionInterface[]> {
    return this.malfunctionActiveSubject.asObservable();
  }

  getObservableInProgress(): Observable<MalfunctionInterface[]> {
    return this.malfunctionInProgressSubject.asObservable();
  }
}
