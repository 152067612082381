<div #twocolmn class="two-colmn-grid">
  <!------------------------------------------------------------------------------->
  <div #colmn1 class="image">
    <!--------------------------------------------------------------------->
    <app-secure-image
      [src]="defaultPictureUrl"
      [fileId]="this.fileId ? this.fileId : null"
      [contain]="true"
    ></app-secure-image>
    <!--------------------------------------------------------------------->
  </div>
  <!------------------------------------------------------------------------------->
  <div #colmn2>
    <!--------------------------------------------------------------------->
    <div #5row class="five-row-grid">
      <!---------------------------------------------------------->
      <div class="name">{{ name }}</div>
      <!---------------------------------------------------------->
      <div class="subscript">{{ subscript }}</div>
      <!---------------------------------------------------------->
      <div class="organisationName">
        {{ organisation != null ? organisation.organisationName : "" }}
      </div>
      <!---------------------------------------------------------->
      <div class="right">
        <div class="right" *ngIf="ptgNames && ptgNames.length > 0">
          <div
            class="ptg-list-item"
            *ngFor="let ptgName of ptgNames; let isLast = last"
          >
            {{ ptgName }}{{ isLast ? "" : ", " }}
          </div>
        </div>
      </div>
      <!---------------------------------------------------------->
      <div class="right bottom">
        <!----------------------------------------------->
        <div class="review-stars" *ngIf="reviewType != -1">
          <app-star
            [rating]="this.rating"
            [ratingCount]="this.ratingCount"
          ></app-star>
        </div>
        <!----------------------------------------------->
        <div class="certification-mini-display" *ngIf="product != null">
          <app-certificate-mini-display
            *ngIf="
              product.attributeCertificationCount != null &&
              product.attributeCertificationCount > 0
            "
            [certCount]="product.attributeCertificationCount"
            [certificateType]="'ATTRIBUTE_CERT'"
          >
          </app-certificate-mini-display>
          <app-certificate-mini-display
            *ngIf="
              product.ptgCertificationCount != null &&
              product.ptgCertificationCount > 0
            "
            [certCount]="product.ptgCertificationCount"
            [certificateType]="'PTG_CERT'"
          >
          </app-certificate-mini-display>
          <app-certificate-mini-display
            *ngIf="
              product.toolchainCertificationCount != null &&
              product.toolchainCertificationCount > 0
            "
            [certCount]="product.toolchainCertificationCount"
            [certificateType]="'TOOLCHAIN_CERT'"
          >
          </app-certificate-mini-display>
        </div>
      </div>
      <!---------------------------------------------------------->
    </div>
    <!--------------------------------------------------------------------->
  </div>
  <!------------------------------------------------------------------------------->

  <!------------------------------------------------------------------------------->
  <div class="right">
    <div class="aspectRatio"></div>
    <div
      *ngIf="isThereMalfunction"
      style="width: 4em; height: 4em; margin-left: auto; margin-bottom: auto"
      class="warning"
      matTooltip="Für dieses Produkt liegt eine Störung vor."
      [matTooltipPosition]="'left'"
      matTooltipClass="tooltip-warning"
      (click)="routeMalfunctions()"
    ></div>
  </div>
  <!------------------------------------------------------------------------------->
</div>
