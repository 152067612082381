<h2>Toolchain Templates</h2>
<div class="table-container">
  <div class="table">
    <div
      *ngIf="toolchainTemplates && toolchainTemplates.length > 0"
      class="headerTemplates"
    >
      <div class="firstTableColumn">
        <p><b>Template-ID</b></p>
      </div>
      <div class="centered">
        <p><b>Name</b></p>
      </div>
      <div class="centered">
        <p><b>Zuletzt bearbeitet</b></p>
      </div>
      <div class="centered">
        <p><b>Letzter Bearbeiter</b></p>
      </div>
      <div></div>
      <div></div>
    </div>
    <div *ngIf="toolchainTemplates" class="content">
      <ng-container *ngFor="let data of toolchainTemplates; let i = index">
        <app-toolchain-template-table-row
          [data]="data"
          [index]="i"
          [instances]="toolchainInstances | filterInstanceReferencesTemplate : data.toolchainId"
          (deleteTemplateEvent)="deleteToolchainTemplate($event)"
          (deleteToolchainEvent)="deleteToolchain($event)"
          (reloadToolchainsAndTemplatesEvent)="reloadToolchainsAndTemplates()"
        ></app-toolchain-template-table-row>
      </ng-container>
    </div>
    <mat-spinner *ngIf="!toolchainTemplates"></mat-spinner>
  </div>
</div>
<p *ngIf="toolchainTemplates && toolchainTemplates.length === 0">
  Es sind keine Toolchain Templates vorhanden.
</p>
