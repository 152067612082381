<div *ngIf="product" class="ptg">
  <div class="two-colmn-grid">
    <div class="column1">
      <h2 class="no-overflow">{{ product.name }}</h2>
    </div>
    <div class="column2 headline right">
      <rounded-button
        class="edit-btn"
        [label]="'Produkt bearbeiten'"
        (click)="editProduct()"
        *ngIf="isPlattformAdmin"
      ></rounded-button>
      <rounded-button
        class="del-btn"
        [label]="'Produkt löschen'"
        (click)="deleteProduct()"
        *ngIf="isPlattformAdmin"
      ></rounded-button>
      <rounded-button
        [label]="'SSO'"
        (click)="registerSsoClient()"
        *ngIf="isPlattformAdmin"
      ></rounded-button>
      <rounded-button
        [label]="'Störungsmanagement'"
        (click)="showMalfunctionManagement()"
        *ngIf="isPlattformAdmin && hasActiveProductVersion"
      ></rounded-button>
    </div>
  </div>
  <div class="version-info">
    <div>   
      <p class="description" [innerHTML]="productDescription"></p>
      <div class="version-count">
        <div>
          <b>
            <p>Varianten</p>
          </b>
          <b>
            <!-- TODO: FIX missing Parameterns in case of undefined; flache Scope-Elemente -->
            <h2>{{ product.productVariants.length }}</h2>
          </b>
        </div>
      </div>
    </div>
    <div class="image-wrapper">
      <div class="image-preview">
        <app-secure-image
          [src]="this.productPictureUrl"
          [fileId]="product.fileId"
        ></app-secure-image>
      </div>
    </div>
  </div>
  <div
    *ngIf="showPictures && product && product.galleryPictureIds"
    class="info-box-gallery"
  >
    <b>
      <p>Galerie</p>
    </b>
  </div>
</div>

<div *ngIf="certification" class="ptg">
  <div class="two-colmn-grid wide">
    <div class="column1">
      <h2 class="no-overflow">{{ certification.name }}</h2>
    </div>
    <div class="column2 headline right">
      <rounded-button
        class="edit-btn"
        [label]="'Zertifizierung bearbeiten'"
        (click)="editCertification()"
      ></rounded-button>
      <rounded-button
        class="del-btn"
        [label]="'Zertifizierung löschen'"
        (click)="deleteCertification()"
      ></rounded-button>
    </div>
  </div>
  <div class="version-info">
    <div>
      <p class="description" [innerHTML]="certificationDescription"></p>
      <div class="version-count">
        <div>
          <b>
            <p>Varianten</p>
          </b>
          <b>
            <!-- TODO: FIX missing Parameterns in case of undefined; flache Scope-Elemente -->
            <h2>{{ certification.certificationVariants.length }}</h2>
          </b>
        </div>
      </div>
    </div>
    <div class="image-wrapper">
      <div class="image-preview">
        <app-secure-image
          [src]="this.certificationPictureUrl"
          [fileId]="certification.fileId"
        ></app-secure-image>
      </div>
    </div>
  </div>
  <div
    *ngIf="showPictures && certification && certification.galleryPictureIds"
    class="info-box-gallery"
  >
    <b>
      <p>Galerie</p>
    </b>
  </div>
</div>

<div *ngIf="variant" class="versionsBackground">
  <div class="headline">
    <h2>{{ variant.name }}</h2>
  </div>
  <div class="version-info">
    <div class="twoRows">
      <p><b>Beschreibung</b></p>
      <p class="description" [innerHTML]="variantDescription"></p>
      <p><b>Kommentar</b></p>
      <p class="description">{{ variant.comment }}</p>
    </div>
    <div class="stats">
      <p><b>Versionen</b></p>
      <b>
        <h2>{{ variant.variantVersions.length }}</h2>
      </b>
      <p><b>Davon aktiv</b></p>
      <b>
        <h2>{{ (variant.variantVersions | variantVersionActive).length }}</h2>
      </b>
    </div>
  </div>
</div>

<div *ngIf="certificationVariant" class="versionsBackground">
  <div class="headline">
    <h2>{{ certificationVariant.name }}</h2>
  </div>
  <div class="version-info">
    <div class="twoRows">
      <p><b>Beschreibung</b></p>
      <p class="description" [innerHTML]="certificationVariantDescription"></p>
      <p><b>Kommentar</b></p>
      <p class="description">{{ certificationVariant.comment }}</p>
    </div>
    <div class="stats">
      <p><b>Versionen</b></p>
      <b>
        <h2>{{ certificationVariant.certificationVariantVersions.length }}</h2>
      </b>
      <p><b>Davon aktiv</b></p>
      <b>
        <h2>
          {{
            (
              certificationVariant.certificationVariantVersions
              | variantVersionActive
            ).length
          }}
        </h2>
      </b>
    </div>
  </div>
</div>
