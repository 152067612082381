<div class="two-columns">
  <div>
    <h1 *ngIf="isCreateNew; else edit">Produktversion anlegen</h1>
    <ng-template #edit>
      <h1>Produktversion bearbeiten</h1>
    </ng-template>
    <app-version-edit
      [version]="version"
      [dataType]="'PRODUCT_VERSION'"
      [isCreateNew]="isCreateNew"
      (validityChanged)="onValidityChanged($event)"
    ></app-version-edit>

    <div class="mini-grid-buttons">
      <button
        mat-raised-button
        class="btn_green"
        (click)="createVersion()"
        [disabled]="!(isFormValid && isInputValid())"
      >
        Speichern
      </button>
      <button mat-raised-button class="btn_red" (click)="cancelCreateVersion()">
        Abbrechen
      </button>
    </div>
  </div>
</div>
<ng-template #loading>
  <p>Loading data</p>
</ng-template>
