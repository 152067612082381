<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <form
    [formGroup]="demoRequestForm"

    *ngIf="showForm"
  >
    <div class="formGrid">

      <div class="fullWidth nameInfo">
        <mat-form-field>
          <mat-select placeholder="Anrede" [formControl]="gender">
            <mat-option *ngFor="let gender of genders" [value]="gender.value">{{gender.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Vorname"
            [formControl]="firstname"
            autocomplete="given-name"
            required
          />
          <mat-error *ngIf="firstname.hasError"
            >Diese Angabe ist erforderlich.</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Nachname"
            [formControl]="lastname"
            autocomplete="family-name"
            required
          />
          <mat-error *ngIf="lastname.hasError"
            >Diese Angabe ist erforderlich.</mat-error
          >
        </mat-form-field>
      </div>
      <mat-form-field>
        <input
          matInput
          placeholder="E-Mail"
          [formControl]="email"
          autocomplete="email"
          pattern="[^@]+@[^@]+\.[^@]+"
          required
        />
        <mat-error *ngIf="email.hasError">
          Bitte eine valide E-Mail angeben.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Telefonnummer"
          [formControl]="phoneNumber"
          autocomplete="tel-national"
        />
        <mat-error *ngIf="phoneNumber.hasError">
          Diese Angabe ist erforderlich.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Unternehmen"
          [formControl]="company"
          autocomplete="organization"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="URL"
          [formControl]="url"
          autocomplete="url"
        />
      </mat-form-field>

      <mat-form-field >
        <input
          matInput
          placeholder="Baustellenadresse"
          [formControl]="baustellenAdresse"
          autocomplete="baustellenAdresse"
        />
        <mat-error>Diese Angabe ist erforderlich.</mat-error>
      </mat-form-field>

      <mat-form-field >
        <input
          matInput
          placeholder="Anzahl der Nutzer"
          [formControl]="anzahlNutzer"
          autocomplete="anzahlNutzer"
        />
        <mat-error>Diese Angabe ist erforderlich.</mat-error>
      </mat-form-field>

      <mat-form-field >
        <input
          matInput
          placeholder="Start der Nutzung"
          [formControl]="startDatum"
          autocomplete="startDatum"
        />
        <mat-error>Diese Angabe ist erforderlich.</mat-error>
      </mat-form-field>

      <mat-form-field >
        <input
          matInput
          placeholder="Ende der Nutzung"
          [formControl]="endDatum"
          autocomplete="endDatum"
        />
        <mat-error>Diese Angabe ist erforderlich.</mat-error>
      </mat-form-field>

      <mat-form-field class="fullWidth" *ngIf="type === REQUEST_TYPE_LISTING">
        <input
          matInput
          placeholder="Produkte"
          required
          [formControl]="products"
          autocomplete="products"
        />
        <mat-error>Diese Angabe ist erforderlich.</mat-error>
      </mat-form-field>
      <mat-form-field class="fullWidth">
        <textarea
          placeholder="Anfrage"
          [formControl]="request"
          matInput
          required
          maxlength="10000"
          rows="5"
        ></textarea>
        <mat-error>Diese Angabe ist erforderlich.</mat-error>
      </mat-form-field>
    </div>
    <p *ngIf="type === REQUEST_TYPE_LISTING">
      Mit Absenden des Formulars akzeptieren Sie die
      <a (click)="showAgb()">AGB</a>
      und
      <a (click)="showPrivacy()">Datenschutzerklärung</a>.
    </p>
  </form>
  <div *ngIf="showDemoSuccessMessage">
    <p>
      Vielen Dank für Ihre Anfrage. Ihr Formular wurde erfolgreich an den
      Anbieter weitergeleitet und er meldet sich demnächst bei Ihnen, um einen
      Demo-Termin zu vereinbaren.
    </p>
  </div>
  <div *ngIf="showOfferSuccessMessage">
    <p>
      Vielen Dank für Ihre Anfrage. Ihr Formular wurde erfolgreich an den
      Anbieter weitergeleitet und er meldet sich demnächst bei Ihnen, um Ihre
      Anfrage zu beantworten.
    </p>
  </div>
  <div *ngIf="showListingSuccessMessage">
    <p>
      Vielen Dank für Ihre Anfrage. Ihre Anfrage wurde erfolgreich gestellt. Wir melden uns in Kürze bei Ihnen, um Ihre Anfrage zu beantworten.
    </p>
  </div>
  <div *ngIf="showErrorMessage">
    <p>
      Leider ist bei der Anfrage ein Fehler aufgetreten. Bitte versuchen sie es
      später erneut.
    </p>
  </div>
  <div *ngIf="showNoContactMail">
    <p>
      Leider konnte die Anfrage nicht gesendet werden, da die Organisation keine
      Kontaktadresse hinterlegt hat.
    </p>
  </div>
  <div *ngIf="showWaitForResult">
    <p>Bitte warten</p>
  </div>
</div>
<div mat-dialog-actions>
  <button
    style="width: 125px"
    mat-raised-button
    (click)="onSubmit()"
    class="btn_green"
    [disabled]="demoRequestForm.invalid"
    *ngIf="showForm"
  >
    Senden
  </button>
  <button style="width: 125px"  mat-raised-button   *ngIf="showForm"  (click)="cancel()" class="btn_red">
Abbrechen
  </button>
  <button style="width: 125px" mat-raised-button   *ngIf="!showForm"  (click)="cancel()" class="btn_red">
Schließen
  </button>


</div>
