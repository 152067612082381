import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError, of } from "rxjs";
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { FileUploadService } from "./dataServices/file-upload-service/file-upload.service";
import { concatMap, catchError, map } from "rxjs/operators";
import { resolve } from "url";
import { ProductDtoConverterServiceService } from "./product-dto-converter-service/product-dto-converter-service.service";
import { DialogService } from "./dialog-service/dialog-service.service";
import { CertificationDtoConverterService } from "./certification-dto-converter-service/certification-dto-converter-service.service";

// HEADER OPTION CONSTANT
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export abstract class BaseService<T> {
  // #region DATA VALUE
  //------------------------------------
  collection: T[] = [];
  activeItem: T;
  // The server API base adresses
  SWARM_API_BASE: string;
  SWARM_PIC_API: string;
  SWARM_DOC_API: string;
  //------------------------------------
  SWARM_API_MAP: { [id: string]: string } = {
    PUT: "",
    POST: "",
    GET: "",
    DELETE: "",
  };
  ITEM_MAP: { [id: string]: string };
  SERVICE_FLAG: string;

  // #endregion

  //#region  OBSERVABLES
  //------------------------------------
  // active Item Observable
  private activeItemSubject: BehaviorSubject<T>;
  public activeItemObserver: Observable<T>;

  // collection Observable
  private itemCollectionSubject: BehaviorSubject<T[]>;
  public itemCollectionObserver: Observable<T[]>;

  private deleteRequestSubject: BehaviorSubject<T>;
  public deleteRequestObserver: Observable<T>;

  private deletedItem;
  //------------------------------------
  //#endregion

  constructor(
    protected http: HttpClient,
    protected productDtoConverter: ProductDtoConverterServiceService,
    protected certificationDtoConverter?: CertificationDtoConverterService
  ) {
    //-----------------------
    this.activeItemSubject = new BehaviorSubject<T>(this.activeItem);
    this.activeItemObserver = this.activeItemSubject.asObservable();
    //-----------------------
    this.itemCollectionSubject = new BehaviorSubject<T[]>(this.collection);
    this.itemCollectionObserver = this.itemCollectionSubject.asObservable();
    //-----------------------

    this.deleteRequestSubject = new BehaviorSubject<T>(this.deletedItem);
    this.deleteRequestObserver = this.deleteRequestSubject.asObservable();
  }

  //#region UPDATE, ACCESS AND UTILITY FUNCTIONS

  //-----------------------
  /**
   * Abstract function to be overwritten in child class to ensure
   * that equality of Object of T
   *
   * @param item1
   * @param item2
   */
  protected equal(item1: T, item2: T): boolean {
    return JSON.stringify(item1) === JSON.stringify(item2);
  }
  //-----------------------

  //-----------------------
  /**
   * Abstract function to be overwritten in child class to
   * ensure identification by ID.
   * @param item
   */ K;
  protected abstract toID(item: T | number): number;
  //-----------------------
  //-----------------------

  protected idCompare(item1: T | number, item2: T | number): boolean {
    return this.toID(item1) === this.toID(item2);
  }

  //-----------------------
  //-----------------------
  /**
   * This function sets the order in which to update both
   * item and collection.
   */
  next(): void {
    this.nextItem();
    this.nextCollection();
  }
  //-----------------------
  //-----------------------
  /**
   * Informs subscribers of activeItemObserver about the
   * current state of the active/selected item.
   */
  nextItem(): void {
    this.activeItemSubject.next(this.activeItem);
  }
  //-----------------------

  //-----------------------
  /**
   * Informs subscribers of itemCollectionObserver about the
   * current state of the item collection.
   */
  nextCollection(): void {
    this.itemCollectionSubject.next(this.collection);
  }
  /**
   * This is a function that uses the API_MAP and the ITEM_MAP
   * to create an individual adress for each service to send
   * the specific request to
   * @param action
   * @param item
   */
  URL(action: string, item: T | number): string {
    this.ITEM_MAP[this.SERVICE_FLAG] = String(this.toID(item));
    let actionURL = this.SWARM_API_MAP[action];
    let mappedURL = this.SWARM_API_BASE;
    actionURL.split("/").forEach((URLpart: string) => {
      if (URLpart) {
        mappedURL = mappedURL.concat("/").concat(
          this.ITEM_MAP[URLpart] !== undefined // if URL is in the map it is a keyword
            ? this.ITEM_MAP[URLpart] // replace keyword
            : URLpart // if not a keyword it is base url part
        );
      }
    });
    return mappedURL;
  }

  /**
   * Propagate a delete Request to the other services.
   * @param item the item to delete
   */
  delete(item: T) {
    this.deleteRequestSubject.next(item);
  }

  reload(itemOrId: T | number): Promise<T> {
    if (!itemOrId) {
      return Promise.reject(null);
    }
    let id = this.toID(itemOrId);
    //check in collection -> retrieve index
    if (id == -1) {
      return Promise.reject(null);
    }

    return new Promise((resolve, reject) => {
      this.http.get<T>(this.URL("GET", id)).subscribe(
        //success
        (item: T) => {
          if (item) {
            this.updateItemInCollection(item);
            resolve(item);
          } else {
            reject(null);
          }
        },
        // failure
        () => {
          reject(null);
        }
      );
    });
  }

  //-----------------------
  /**
   * Sends a get request to the API and returns a Promise of the item
   *
   * @param itemOrId Either the object in question or an ID
   * @param IdentificationFunction Needs a identification number for T
   */
  getItem(itemOrId: T | number): Promise<T> {
    if (!itemOrId) {
      return Promise.reject(null);
    }
    let id = this.toID(itemOrId);
    //check in collection -> retrieve index
    if (id == -1) {
      return Promise.reject(null);
    }
    let itemID = this.collection.findIndex((x) => this.idCompare(x, id));
    // check if item in collection
    if (itemID > -1) {
      // return a resolved Promise
      return Promise.resolve(this.collection[itemID]);
    }
    // otherwise send a get request to server
    return new Promise((resolve, reject) => {
      this.http.get<T>(this.URL("GET", id)).subscribe(
        //success
        (item: T) => {
          if (item) {
            this.updateItemInCollection(item);
            resolve(item);
          } else {
            reject(null);
          }
        },
        // failure
        () => {
          reject(null);
        }
      );
    });
  }

  /**
   * Information are published using the observers. There is no direct
   * access to the active item. To recieve Information set a item
   * as the active item.
   * @param item
   */
  setActiveItem(item: T | number): Promise<boolean> {
    if (!item) return Promise.reject(false);
    return new Promise((resolve, reject) => {
      this.getItem(item).then(
        (item: T) => {
          this.activeItem = item;
          this.nextItem();
          resolve(true);
        },
        () => {
          reject(false);
        }
      );
    });
  }

  /**
   * Overrides the item of id:x with with the new item that share the same id.
   * Updating does not set the updated item as active.
   * @param item
   */
  protected updateItemInCollection(item: T): boolean {
    let index = this.collection.findIndex((x) => this.idCompare(x, item));
    if (index > -1) {
      this.collection[index] = item;
      this.nextCollection();
      return true;
    }
    this.collection.push(item);
    this.nextCollection();
    return true;
  }

  //TODO
  getFiltered(
    pageIndex: number,
    pageSize: number,
    ptgIds: string,
    searchText
  ) {}

  //#endregion

  //#region MODIFCATION FUNCTIONS

  //-----------------------

  //-----------------------
  /**
   * This function post an item to the server.
   * @param item To be posted on the server
   */
  postItem(item: T): Promise<T> {
    const json = JSON.stringify(item);
    return new Promise((resolve, reject) => {
      this.http.post<T>(this.URL("POST", item), json, httpOptions).subscribe(
        (postedItem: T) => {
          // success
          // set new item as active item
          // push on collection
          // and publish

          // !!! THIS ORDER IS IMPORTANT !!!
          this.updateItemInCollection(postedItem);
          this.setActiveItem(postedItem);
          // !!! THIS ORDER IS IMPORTANT !!!

          // and resolve
          resolve(postedItem);
        },
        (e: HttpErrorResponse) => {
          //failure
          reject(e);
        }
      );
    });
  }
  //-----------------------
  //-----------------------
  /**
   *
   * @param item
   */
  putItem(item: T): Promise<T> {
    // check the collection if we have an item that can be update
    // check for ID and NOT equality as Id is constant while content can vary
    let index: number = this.collection.findIndex((x) =>
      this.idCompare(x, item)
    );

    // if we can not find the item create it instead
    if (index < 0) {
      return this.postItem(item);
    }
    // TODO MAKE DTO CONVERTER WORK GOOD ?
    //------------------------------------------------
    let json;
    if (this.SERVICE_FLAG === ":variantId") {
      json = JSON.stringify(this.productDtoConverter.convertToVariantDto(item));
    } else if (this.SERVICE_FLAG === ":productId") {
      json = JSON.stringify(this.productDtoConverter.convertToProductDto(item));
    } else if (this.SERVICE_FLAG === ":certificationId") {
      json = JSON.stringify(
        this.certificationDtoConverter.convertToCertificationDto(item)
      );
    } else if (this.SERVICE_FLAG === ":certificationVariantId") {
      json = JSON.stringify(
        this.certificationDtoConverter.convertToVariantDto(item)
      );
    } else if (this.SERVICE_FLAG === ":certificationVersionId") {
      json = JSON.stringify(
        this.certificationDtoConverter.convertToVersionDto(item)
      );
    } else {
      json = JSON.stringify(item);
    }
    //------------------------------------------------
    return new Promise((resolve, reject) => {
      this.http.put(this.URL("PUT", item), json, httpOptions).subscribe(
        //success
        (putItem: T) => {
          // !!! THIS ORDER IS IMPORTANT !!!
          this.updateItemInCollection(putItem);
          this.setActiveItem(putItem);
          // !!! THIS ORDER IS IMPORTANT !!!
          resolve(putItem);
        },
        //failure
        () => {
          reject(null);
        }
      );
    });

    // now check for equality to avoid traffic with accidental update.

    // else update
  }

  deleteItem(item: T): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(item);
      // delete item in Backend
      this.http.delete(this.URL("DELETE", item)).subscribe(
        //successfully deleted
        () => {
          // look for item in collection
          let index = this.collection.findIndex((x) => this.idCompare(x, item));
          // if there is a copy of item in collection
          if (index >= 0) {
            //remove it from collection
            this.collection.splice(index, 1);
          }
          this.delete(item);
          // was the deleted item the active item?
          if (this.toID(this.activeItem) === this.toID(item)) {
            // we need to set a new active item ->
            // first check if the collection is empty now ?
            this.activeItem = null;
          }

          resolve(true);
        },
        //failure
        () => {
          reject(false);
        }
      );
    });
  }

  //#endregion

  //#region EXPERIMENTAL

  /**
   * EXPERIMENTAL
   * This function sends a post request to the server.
   * If a picture file is supplied the picture is posted first.
   * If the picture cannot be uploaded the deleteFileConnection is
   * called on the item and the item is uploaded.
   *
   * @param item the item we want to publish
   * @param picture optinal file to upload
   * @param deleteFileConnection This is a function of type (x:T)=>{x.fileRef = ""}
   * @returns Promise<boolean> to check if post was successfull
   */
  /*
  post(
    item: T,
    picture?: File,
    deleteFileConnection?: Function
  ): Promise<boolean> {
    let promise = new Promise<boolean>((resolve, reject) => {
      //-----------------------
      of(picture)
        .pipe(
          // map on existence to reduce nested ifs
          map((x) => {
            if (!picture) throw new Error();
          }),
          // upload picture to server
          map(() => this.fileUpload.uploadFile(picture, this.SWARM_PIC_API)),
          // if either picture="" or the upload failed => delete the connection
          catchError((err) => {
            deleteFileConnection(item);
            return of([]);
          })
          // then subscribe to the stream
        )
        .subscribe(
          // success
          // push item on collection and publish to subscribers
          // finally resolve Promise
          () => {
            this.collection.push(item);
            this.collectionNext();
            resolve(true);
          },
          // failure
          // just reject the promise
          () => {
            reject(false);
          }
        );
    });
    return promise;
  } */

  //#endregion
}
