import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { CollectionViewer } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DataFormat } from "src/app/model/compatiblity/data-format-interface";
import { DataFormatService } from "src/app/services/compatiblity-service/data-format-service.service";

const detailExpandAnimation = trigger("detailExpand", [
  state(
    "collapsed",
    style({ height: "0px", minHeight: "0", visibility: "hidden" })
  ),
  state("expanded", style({ height: "*", visibility: "visible" })),
  transition(
    "expanded <=> collapsed",
    animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
  ),
]);

@Component({
  selector: "app-data-formats-screen",
  templateUrl: "./data-formats-screen.component.html",
  styleUrls: ["./data-formats-screen.component.css"],
  animations: [detailExpandAnimation],
})
export class DataFormatsScreenComponent implements OnInit, OnDestroy {
  // The datasource used in the table
  public dataSource = new DataFormatDataSource(this.dataFormatService);
  // The columns displayed in the table
  public displayedColumns = ["name", "showColumn", "editColumn"];

  /**
   * Holds the id of the currently expanded element or null
   * if none is expanded
   */
  expandedElement: number | null = null;

  /**
   *
   * @param dataFormatService an instance of @type {DataFormatService}
   */
  constructor(private dataFormatService: DataFormatService) {
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
  }
  ngOnInit(){}
  ngOnDestroy(){  document.documentElement.style.setProperty('--scrollStatus', 'hidden');}
  /**
   * Helper function that returns true if a row is a "detailRow".
   * E.g. returns true if the row is the expanded section of a table entry
   * and false if it is not
   *
   * @param i index of the currently row of the table
   * @param row the @type {ExpandableDataFormatRow} object of the row
   */
  public isExpansionDetailRow = (i: number, row: ExpandableDataFormatRow) =>
    row.isDetailRow;
}

interface ExpandableDataFormatRow {
  /**
   * Holds the @type {DataFormat} object of the row
   */
  dataFormat: DataFormat;
  /**
   * true if the current row is a detailRow, false otherwise
   */
  isDetailRow: boolean;
}

/**
 * A DataSource that emits two "row" entries for each input
 * with "isDetailRow" set to true and false.
 *
 * This can then be used in a table to model the case of
 * "expanding rows" to show more context for a row entry.
 *
 */
export class DataFormatDataSource extends DataSource<ExpandableDataFormatRow> {
  /**
   *
   * @param dataFormatService an instance of @type {DataFormatService}
   */
  constructor(private dataFormatService: DataFormatService) {
    super();
  }

  /**
   * Called when the datasource client connects to this @type {DataSource}
   *
   * @param _ an instance of @type {CollectionViewer} which is
   *          ignored as it's not needed
   */
  connect(_: CollectionViewer): Observable<ExpandableDataFormatRow[]> {
    return this.dataFormatService.getAllDataFormats().pipe(
      map((dataFormat) => {
        const rows: ExpandableDataFormatRow[] = [];
        dataFormat.forEach((dataFormat) => {
          // Add two "row" entries. One with "isDetailRow" set to false,
          // which indicates that its the default row, that is always visible.
          // And the other with "isDetailRow" set to "true" to indicate that
          // it is the row that expands.
          // See also the template and "isExpansionDetailRow" in the component
          rows.push(
            { dataFormat, isDetailRow: false },
            { dataFormat, isDetailRow: true }
          );
        });
        return rows;
      })
    );
  }
  disconnect(_: CollectionViewer): void {}
}
