import { ComposerOrganisationSelectInterface } from 'src/app/model/composer/composer-organisation-select-interface';

/* Custom Modul for the Properties Panel */
export function ComposerSelectForViewer(options: {
  id: string;
  label: string;
  options: ComposerOrganisationSelectInterface[];
  currentlySelectedId: string;
}) {
  let optionsString = "";
  options.options.forEach(
    (element) => (optionsString += createSelectOption(element, options.currentlySelectedId))
  );

  return {
    id: options.id,
    html:
      '<div><label data-value="label" ' +
      '></label><div><select disabled style="width: 95%" id="' +
      options.id +
      '">' +
      optionsString +
      "</select>",
    get: function (element, node) {
      return {
        label: options.label,
      };
    },
  };
}

/* Creates the HTML Code for a Select Option */
function createSelectOption(option: ComposerOrganisationSelectInterface, currentlySelectedId: string) {
  if(option.organisationId === parseInt(currentlySelectedId)){
    return "<option selected value='" + option.organisationId + "'>" + option.name + "</option>";
  }
  return "<option value='" + option.organisationId + "'>" + option.name + "</option>";
}
