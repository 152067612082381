<div
  class="certificate-container"
  matTooltip="{{ tooltip }}"
  matTooltipClass="header-tooltip"
>
  <div
    class="cert-image"
    [ngStyle]="{ 'background-image': 'url(' + certImageUrl + ')' }"
  ></div>
  <p
    style="margin: auto"
    matTooltip="Anzahl der Zertifikate"
    matTooltipClass="header-tooltip"
  >
    {{ this.certCount }}
  </p>
</div>
