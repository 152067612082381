<span class="app-star">
  <div class="score-wrap">
    <span  class="stars-active" [style.width.%]="getRatingInPercent">
      <div>
      <span
        *ngFor="let stars of starArray"
        class="fa fa-star"
        aria-hidden="true"
        (click)="clickOnStar(stars)"
        (mouseenter)="hover(stars)"
        (mouseleave)="leave()"
        [ngClass]="{
          bigger: this.bigger,
          veryBig: this.veryBig
        }"
      ></span>
      </div>
    </span>
    <span class="stars-inactive">
      <span
        *ngFor="let stars of starArray"
        class="fa fa-star-o"
        aria-hidden="true"
        (click)="clickOnStar(stars)"
        (mouseenter)="hover(stars)"
        (mouseleave)="leave()"
        [ngClass]="{
          bigger: this.bigger,
          veryBig: this.veryBig
        }"
      ></span>
    </span>
  </div>
</span>
<div *ngIf="ratingCount != null" class="rating-count">
  ( {{ ratingCount }} )
</div>
