<!-- Hintergrundelemente (Sechsecke) -->

<div class="background"></div>

<!-- Linie die den Header vom Main-Content trennt -->

<!-- TODO: DELETE
This is now in the new header component -->

<div class="top-line-wrapper">
  <div class="line white">
    <div class="innerline"></div>
  </div>
</div>

<div class="bottom-line-wrapper">
  <div class="line white">
    <div class="innerline"></div>
  </div>
</div>

<div class="diagonal-line-wrapper">
  <div class="line">
    <div class="innerline"></div>
  </div>
</div>

<div class="app-navbar">
  <!-- Bildmarke (BimSwarm Logo links oben) -->

  <div class="logo">
    <hexagon [outerColor]="'white'" [icon]="'bimswarmLogo'"></hexagon>
  </div>

  <!-- Wortmarke (Text im Header) -->
<!-- 
  <div>
    <p>
      {{headerService.activeHeaderItem}}
    </p>
  </div> -->

  <!-- Menü-Icons (rechts im Header) -->

  <app-nav-buttons></app-nav-buttons>
</div>
