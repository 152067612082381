<div class="newTemplate" (click)="addNewTemplate()">
    <div class="templateButton">
        <hexagon
            [icon]="'plus'"
            [hover]="true"
            [outerColor]="'darkgreen'"
            (click)="addEntryToSelected()"
        ></hexagon>
    </div>
    <div class="label"><h3>Neues Template</h3></div>
</div>
<br />
