import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as BpmnJS from "bpmn-js/dist/bpmn-modeler.production.min.js";
import propertiesPanel from "bpmn-js-properties-panel/lib/PropertiesPanel";
import propertiesPanelModule from "bpmn-js-properties-panel";
import propertiesProviderModule from "bpmn-js-properties-panel/lib/provider/bpmn";
import { ComposerProductFileInterface } from 'src/app/model/composer/composer-product-file-interface';
import { PtgService } from 'src/app/services/dataServices/ptg-service/ptgService';
import { ComposerService } from 'src/app/services/composer-service/composer.service';
import { OrganisationService } from 'src/app/services/dataServices/organisation-service/organisationservice';
import { currentPropertiesPanelItem, getCurrentlyActiveToolchain, setOrganisationSelectOptions, setPTGOptions } from '../model-editor/custom/composerChangeBuffer';
import { reloadPropertiesPanel } from '../model-editor/custom/composerChangeHandler';
import { loadIcons } from '../model-editor/util/preloadedAssets';
import ForkEventRender from '../model-editor/custom/renderer/forkEventRender';
import JoinEventRender from '../model-editor/custom/renderer/joinEventRender';
import ToolchainItemRender from '../model-editor/custom/renderer/toolchainItemRender';
import ToolchainInstanceItemRender from '../model-editor/custom/renderer/toolchainInstanceItemRender';
import { ComposerModdle } from '../model-editor/custom/composerModdle';
import { CustomContextPadForViewer } from './custom/customContextPadForViewer';
import { CustomPaletteForViewer } from './custom/customPaletteForViewer';
import { ToolchainPropertiesProviderForViewer } from './custom/propertiesProvider/toolchainPropertiesProviderForViewer';
import { ComposerRulesForViewer } from './custom/customRulesForViewer';
import { buildComposerChangeHandlerForViewer } from './custom/composerChangeHandlerForViewer';
import { DialogService } from 'src/app/services/dialog-service/dialog-service.service';
import { currentPropertiesPanelItemOfViewer, updateCurrentPropertiesPanelItemForViewer } from './custom/composerChangeBufferForViewer';

@Component({
  selector: 'app-model-viewer',
  templateUrl: './model-viewer.component.html',
  styleUrls: ['./model-viewer.component.css']
})
export class ModelViewerComponent implements OnInit {

  private bpmnJS: BpmnJS;

  private xmlImported: boolean = false;

  @ViewChild("canvas") private canvas: ElementRef;

  @Input()
  private xml: string;

  @Input()
  private fileIdsForXML: ComposerProductFileInterface[];

  constructor(
    private ptgService: PtgService,
    private composerService: ComposerService,
    private organisationService: OrganisationService,
    private dialogService: DialogService
  ) {
    this.ptgService.getAllPtgs().then((success) => {
      if (success) {
        setPTGOptions(this.ptgService.collection);
        if (this.bpmnJS && this.xmlImported) {
          reloadPropertiesPanel(this.bpmnJS);
        }
      }
    });

    this.organisationService.getAllOrgs().then((success) => {
      if (success) {
        setOrganisationSelectOptions(
          this.organisationService.collection.map((organisation) => {
            return {
              organisationId: organisation.organisationId,
              name: organisation.organisationName,
            };
          })
        );
        if (this.bpmnJS && this.xmlImported) {
          reloadPropertiesPanel(this.bpmnJS);
        }
      }
    });
  }

  ngOnInit() {
    /* Preloads Assets that can be needed for the Editor */
    loadIcons().subscribe((success) => {
      if (success) {
        this.createBPMNJsInstance();
      }
    });
  }

  /* Loads the XML into the BpmnJS Instance */
  private loadXML() {
    this.bpmnJS.importXML(this.xml).then(
      () => {
        this.xmlImported = true;
      },
      (error) => {
       
      }
    );
  }

  ngOnDestroy() {
    this.bpmnJS.destroy();
  }

  /* Creates the BPMNJS Instance with all custom Modules */
  private createBPMNJsInstance() {
    //Overrides Methods with custom Methods
    propertiesPanel.prototype._bindListeners = buildComposerChangeHandlerForViewer(
      this
    );

    this.bpmnJS = new BpmnJS({
      container: "#canvas",
      propertiesPanel: {
        parent: "#propertiesPanel",
      },
      //Custom Modules that override standard Modules
      additionalModules: [
        propertiesPanelModule,
        propertiesProviderModule,
        {
          contextPadProvider: ["type", CustomContextPadForViewer],
        },
        {
          paletteProvider: ["type", CustomPaletteForViewer],
        },
        {
          __init__: ["forkEventRender"],
          forkEventRender: ["type", ForkEventRender],
        },
        {
          __init__: ["joinEventRender"],
          joinEventRender: ["type", JoinEventRender],
        },
        {
          __init__: ["toolchainItemRender"],
          toolchainItemRender: ["type", ToolchainItemRender],
        },
        {
          __init__: ["toolchainInstanceItemRender"],
          toolchainInstanceItemRender: ["type", ToolchainInstanceItemRender],
        },
        {
          __init__: ["propertiesProvider"],
          propertiesProvider: ["type", ToolchainPropertiesProviderForViewer],
        },
        {
          __init__: ["composerRuleProvider"],
          composerRuleProvider: ["type", ComposerRulesForViewer],
        },
      ],
      //Custom Definition Elements
      moddleExtensions: {
        composer: ComposerModdle,
      },
    });

    this.afterBPMNInstanceCreation();
  }

  /* Adds Listeners and loads the XML */
  private afterBPMNInstanceCreation() {
    this.bpmnJS.on("import.done", ({ error }) => {
      if (!error) {
        this.bpmnJS.get("canvas").zoom("fit-viewport");
      }
    });

    this.bpmnJS.get("eventBus").on("selection.changed", function (e) {
      if (e.newSelection.length === 1) {
        updateCurrentPropertiesPanelItemForViewer(e.newSelection[0].id);
      } else if (e.newSelection.length === 0) {
        updateCurrentPropertiesPanelItemForViewer("");
      }
    });

    if (this.fileIdsForXML.length === 0) {
      this.loadXML();
    } else {
      this.composerService
        .loadDataUrlsForProductsInXml(this.fileIdsForXML, this.xml, false)
        .subscribe((success) => {
          if (success) {
            this.xml = success;
            this.loadXML();
          }
        });
    }

    this.bpmnJS.attachTo(this.canvas.nativeElement);
  }

  /* Returns the current BPMNJS Instance */
  public getBPMNJSInstance() {
    return this.bpmnJS;
  }

  public downloadFile(){
    const processElement = this.bpmnJS.get("elementRegistry").filter(function (element) {
      return element.businessObject.$type === "bpmn:Process";
    })[0];

    const containerExternalId = processElement.businessObject.$attrs.externalId;
    const contentExternalId = currentPropertiesPanelItemOfViewer;

    this.composerService.downloadFile(containerExternalId.substring(2), contentExternalId.substring(2));
  }

  public uploadFile(file: File) {
    const processElement = this.bpmnJS.get("elementRegistry").filter(function (element) {
      return element.businessObject.$type === "bpmn:Process";
    })[0];

    const containerExternalId = processElement.businessObject.$attrs.externalId;
    const contentExternalId = currentPropertiesPanelItemOfViewer;

    //TODO NOT TESTED BECAUSE CDE IS ONLY WORKING ON DEV03
    this.composerService.uploadFile(containerExternalId.substring(2), contentExternalId.substring(2), file).subscribe();
  }

  public openUploadFileDialog(){
    const processElement = this.bpmnJS.get("elementRegistry").filter(function (element) {
      return element.businessObject.$type === "bpmn:Process";
    })[0];

    const containerExternalId = processElement.businessObject.$attrs.externalId;
    const contentExternalId = currentPropertiesPanelItemOfViewer;

    this.dialogService.openToolchainInstanceDataUpload(containerExternalId.substring(2), contentExternalId.substring(2))
  }
}
