<div class="maxWidth">
  <!-------- Header ------------------------->
  <div class="two-column-grid-header">
    <div>
      <h1>Antrag ID{{ this.certificationRequest.id }}</h1>
    </div>
    <div>
      <div *ngIf="this.certificationRequest.status === 'Beantragt' || this.certificationRequest.status === 'Abgeschlossen'">
        <status-display class="right"
          [status] = "this.certificationRequest.status">
        </status-display>
      </div>
      <div class="mini-grid" *ngIf="this.certificationRequest.status != 'Beantragt' && this.certificationRequest.status != 'Abgeschlossen'">
        <status-display
          [status] = "this.certificationRequest.status">
        </status-display>
        <div>
          <button
            type="button"
            mat-raised-button
            class="btn_green"
            (click)="changeStatus()"
          >
            Status ändern
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<certification-request-overview 
  [certificationRequest] = "certificationRequest"
  [isCertifier] = true
  (commented) = "onCommented($event)">
</certification-request-overview>
<div class="maxWidth">
  <!-------- Certification processing ------------------------->
  <form #form="ngForm" *ngIf="this.certificationRequest.status != 'Beantragt' && this.certificationRequest.status != 'Abgeschlossen'">
    <div class="three-column-grid">
      <div>
        <h3>Rechnungseinstellung</h3>
      </div>
      <div class="aligned">
        <toggle-switch
          [trueLabel]="'Vorkasse'"
          [falseLabel]="'Nach Durchführung'"
          [allowUndefined]="false"
          [disableToggle]="inAdvanceConfirmed"
          [value]="inAdvanceToggle"
          (valueChange)="onInAdvanceValueChanged($event)"
        >
        </toggle-switch>
      </div>
      <div class="aligned">
        <button
          mat-raised-button
          class="btn_green right"
          (click)="confirmPaymentVariant()"
          [disabled]="inAdvanceConfirmed"
        >
          Bestätigen
        </button>
      </div>
    </div>
  </form>
  <form #form="ngForm" *ngIf="this.certificationRequest.status != 'Beantragt' && this.certificationRequest.status != 'Abgeschlossen'">
    <div class="three-column-grid">
      <div>
        <h3>Durchführung</h3>
      </div>
      <div>
        <mat-form-field>
          <mat-select
            placeholder="Durchführungsart wählen"
            [(ngModel)]="executionType"
            (selectionChange)="resetExecutionTypeConfirmed()"
            [disabled]="hasPassedConfirmed || delegatedToOrganisations.length != 0"
            name="executionType"
            required
          >
            <mat-option [value]="executionTypeEnum.INHOUSE">Zertifizierung durchführen</mat-option>
            <mat-option [value]="executionTypeEnum.EXTERNAL">Zertifizierungsdienstleister beauftragen</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="aligned">
        <button
          mat-raised-button
          class="btn_green right"
          (click)="confirmExecutionType()"
          [disabled]="form.invalid || executionTypeConfirmed"
        >
          Auswahl bestätigen
        </button>
      </div>
    </div>
    <div class="two-column-grid">
      <div *ngIf="executionTypeConfirmed && executionType === executionTypeEnum.EXTERNAL && this.certificationRequest.status != 'Abgeschlossen'" style="grid-column: 2">
        <div *ngFor="let delegatedOrga of delegatedToOrganisations">
          <mat-form-field>
            <input
              matInput
              [value] = "delegatedOrga.organisationName"
              disabled
            />
          </mat-form-field>
          <button *ngIf="!delegationConfirmed"
            mat-raised-button
            class="btn_red right aligned"
            (click)="removeDelegation(delegatedOrga)"
          >
            Entfernen
          </button>
          <!---Missing here: Only as long as delegation was not accepted yet ----->
          <button *ngIf="delegationConfirmed"
            mat-raised-button
            class="btn_red right aligned"
            (click)="removeDelegation(delegatedOrga)"
          >
            Stornieren
          </button>
          <button
            mat-raised-button
            class="btn_green right spaced aligned"
            (click)="changeDelegation(delegatedOrga)"
          >
            Ändern
          </button>
        </div>
        <div style="grid-column: 2">
          <app-hexagon-button-text-icon
          [text]="'Dienstleister hinzufügen'"
          [icon]="'/assets/img/misc/add.png'"
          (click)="addDelegation()"
          >
          </app-hexagon-button-text-icon>
        </div>
      </div>
      <div *ngIf="executionTypeConfirmed && executionType === executionTypeEnum.EXTERNAL 
                  && this.certificationRequest.status != 'Abgeschlossen' 
                  && delegatedToOrganisations.length > 0 
                  && !delegationConfirmed" 
            style="grid-column: 2">
        <button
            mat-raised-button
            class="btn_green aligned"
            (click)="confirmDelegation()"
          >
            Auswahl bestätigen
          </button>
      </div>
    </div>
  </form>
  <!-------- case: inhouse - Bestanden, Toggle Switch, Bestätigen ------------------------->
  <form #form="ngForm" *ngIf="executionTypeConfirmed && executionType === executionTypeEnum.INHOUSE && this.certificationRequest.status != 'Abgeschlossen'">
    <div class="three-column-grid">
      <div>
        <h3>Bestanden</h3>
      </div>
      <div class="aligned">
        <toggle-switch
          [trueLabel]="'Ja'"
          [falseLabel]="'Nein'"
          [allowUndefined]="false"
          [disableToggle]="hasPassedConfirmed"
          value="hasPassed"
          (valueChange)="onHasPassedValueChanged($event)"
        >
        </toggle-switch>
      </div>
      <div class="aligned">
        <button
          mat-raised-button
          class="btn_green right"
          (click)="confirmCertification()"
          [disabled]="hasPassedConfirmed"
        >
          Bestätigen
        </button>
      </div>
    </div>
  </form>
  <!-------- Buttons "Zurück"/"Anfrage annehmen" ------------------------->
  <div class="mini-grid-buttons">
    <div>
      <button
        type="button"
        mat-raised-button
        class="btn_red"
        (click)="backToAllRequests()"
      >
        Zurück zur Übersicht
      </button>
    </div>
    <div *ngIf="this.certificationRequest.status === 'Beantragt'">
      <button
        mat-raised-button
        class="btn_green right"
        (click)="acceptRequest()"
      >
        Annehmen
      </button>
    </div>
    <div *ngIf="this.hasPassedConfirmed && this.certificationRequest.status != 'Abgeschlossen'">
      <button
        mat-raised-button
        class="btn_green right"
      (click)="closeTicket()"
      >
        Ticket schließen
      </button>
    </div>
  </div>
</div>

