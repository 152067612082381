<div>
  <b>
    <p>Übergeordnete Produkte</p>
  </b>
  <table>
    <tbody>
    <tr *ngFor="let relatedProduct of product.relatedProducts" class="not">
      <td class="wider">
        <p>{{productService.getRelatedProductNameFromId(relatedProduct)}}</p>
      </td>
      <td>
        <red-cross-button (press)="removeProductDependency(relatedProduct)"
                              class="red-square-cross-button">
        </red-cross-button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
