import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { CertificationVariantVersionService } from '../services/dataServices/cetrification-variant-version-service/certification-variant-version-service';

@Injectable()
export class CertificationVariantVersionResolver implements Resolve<any> {
  constructor(private certificationVersionService: CertificationVariantVersionService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    if (!route.url.toString().includes("create")) {
      let version = parseInt(route.paramMap.get("certificationVersionId"));
      await this.certificationVersionService.setActiveVersion(version);
    }
  }
}
