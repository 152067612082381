import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AttributeService } from "src/app/services/dataServices/attribute-service/attributeService";
import { AttributeInterface } from "src/app/model/attributes/attribute-interface";
import { OrganisationService } from "src/app/services/dataServices/organisation-service/organisationservice";
import { PtgService } from "src/app/services/dataServices/ptg-service/ptgService";
import { MatPaginator } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";

import { BehaviorSubject } from "rxjs";
import { PtgAttributeDataSource } from "src/app/dataSource/ptgAttributeDataSource";
import { PtgAttributesOverviewInterface } from "src/app/model/pagination/ptg-attributes-overview-interface";
import { AttributeDataSource } from 'src/app/dataSource/attributeDataSource';
import { SortMethod } from 'src/app/model/enums/SortMethod';
import { PtgAttributeValidationInterface } from "src/app/model/attributes/ptg-attribute-validation-interface";

@Component({
  selector: "attribute-catalogue",
  templateUrl: "./attribute-catalogue.component.html",
  styleUrls: ["./attribute-catalogue.component.css"],
})
export class AttributeCatalogueComponent implements OnInit, AfterViewInit {
  @Input()
  noMargin = false;

  @Input()
  attributeList?: AttributeInterface[];

  @Output()
  onPressConfirm: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onPressCancel: EventEmitter<any> = new EventEmitter<any>();

  // dataSources
  public dataSource: AttributeDataSource;
  public dataSourcePtg: PtgAttributeDataSource;

  // template references
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public sortString = "ID";
  public selectedAttributes: AttributeInterface[] = [];
  public displayBy = "name";

  public totalNumberOfElements: BehaviorSubject<number>;
  public toggle: Boolean = true;
  public attributeForm: FormGroup;
  public attributeGroups: AttributeOverviewInterface[] = [];
  public ptgVersionGroups: PtgAttributesOverviewInterface[] = [];
  public checkedValidations: boolean[] = []; // Used to reset checkboxes after catalogue is closed. DON'T REMOVE
  public attributeOverviewList: AttributeOverviewInterface[] = [];
  public displayOptions = [
    { name: "name", title: "Nach Name" },
    { name: "ptg", title: "Nach Produkttypen" },
  ];

  constructor(
    public organisationService: OrganisationService,
    public attributeService: AttributeService,
    private formBuilder: FormBuilder,
    public ptgService: PtgService
  ) {
    this.totalNumberOfElements = new BehaviorSubject<number>(0);
  }




  ngOnInit(): void {
    this.attributeForm = this.formBuilder.group({
      attributeSearch: "",
    });

    if(this.attributeList){
      this.attributeList.forEach( (element) => {
        if (element.id) {
          this.checkedValidations[element.id] = true;
        }
      });
    };
    

    this.initializePtgSource();

    this.initializeAttributeSourceAndLoadAttributes();

    this.subscribeToSearchInputField();
  }

  ngAfterViewInit(): void {
    this.initializePaginator();
    this.subscribeToPaginator();
  }

  switchToggle(): void {
    this.toggle = !this.toggle;
  }

  showPtgVersionValues(ptgVersion: PtgAttributesOverviewInterface): void {
    ptgVersion.hidden = !ptgVersion.hidden;
    const currentValues: PtgAttributesOverviewInterface[] = this
      .ptgVersionGroups;
    for (const ptgVersionGroup of currentValues) {
      if (
        ptgVersion.ptgName !== ptgVersionGroup.ptgName ||
        ptgVersion.ptgVersionNumber !== ptgVersionGroup.ptgVersionNumber
      ) {
        ptgVersionGroup.hidden = true;
      }
    }
    this.ptgVersionGroups = currentValues;
  }

  preparePtgsForDisplay(ptgs: PtgAttributesOverviewInterface[]) {
    for (const ptgAttributeOverview of ptgs) {
      ptgAttributeOverview.hidden = true;
      ptgAttributeOverview.ptgVersionDescription = this.getDescriptionString(
        ptgAttributeOverview.ptgVersionDescription
      );
    }
    return ptgs;
  }

  confirm(): void {
    this.onPressConfirm.emit(this.selectedAttributes);
    this.restoreDefaults();
  }

  cancel(): void {
    this.onPressCancel.emit();
    this.selectedAttributes.forEach((attribute) => {
      if(this.checkedValidations[attribute.id])
      {
        this.checkedValidations[attribute.id] = false;
      }
    })
    this.restoreDefaults();
  }

  restoreDefaults(): void {
    if(this.attributeList)
    {
    this.attributeList.forEach( (element) => {
      if (element.id) {
        this.checkedValidations[element.id] = true;
      }
    });
    }
    this.toggle = true;
    this.attributeGroups = this.hideAllAttributes(this.attributeGroups);
    this.ptgVersionGroups = this.preparePtgsForDisplay(this.ptgVersionGroups);
    this.selectedAttributes = [];
  }

  public hideAllAttributes(
    attributes: AttributeOverviewInterface[]
  ): AttributeOverviewInterface[] {
    for (let attribute of attributes) {
      attribute.hidden = true;
    }
    return attributes;
  }

  displayByNameOrPtg(rule: string): void {
    if (this.displayBy != rule) {
      this.paginator.pageIndex = 0;
      this.displayBy = rule;
      this.attributeForm.setValue({ attributeSearch: "" });
      if (rule === "name") {
        this.loadAttributePage("", SortMethod.ID);
      } else if (rule === "ptg") {
        this.loadPtgPage("", SortMethod.ID);
      }
    }
  }

  sortData(sort: Sort): void {
    this.sortString = "ID";
    if (sort.direction !== "") {
      this.sortString = sort.active + "_" + sort.direction.toUpperCase();
    }
    const searchString = this.attributeForm.get("attributeSearch").value;
    if (this.displayBy === "name") {
      this.loadAttributePage(searchString, SortMethod[this.sortString]);
    } else if (this.displayBy === "ptg") {
      this.loadPtgPage(searchString, SortMethod[this.sortString]);
    }
  }

  initializePaginator(): void {
    const paginatorIntl = this.paginator._intl;
    paginatorIntl.nextPageLabel = "Nächste Seite";
    paginatorIntl.previousPageLabel = "Vorherige Seite";
    paginatorIntl.itemsPerPageLabel = "Einträge pro Seite";
    paginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      const start = page * pageSize + 1;
      let end = (page + 1) * pageSize;
      if (end > length) {
        end = length;
      }
      return `${start} - ${end} von ${length}`;
    };
  }

  initializePtgSource(): void {
    this.dataSourcePtg = new PtgAttributeDataSource(this.ptgService);
    this.dataSourcePtg.getObservable().subscribe((res) => {
      this.ptgVersionGroups = this.preparePtgsForDisplay(res);
      this.totalNumberOfElements.next(
        this.dataSourcePtg.getTotalNumberOfElements()
      );
    });
  }

  initializeAttributeSourceAndLoadAttributes(): void {
    this.dataSource = new AttributeDataSource(this.attributeService);
    this.dataSource.getObservable().subscribe((res) => {
      this.attributeGroups = this.hideAllAttributes(res);
    });
    this.dataSource.getTotalNumberOfElementsObservable().subscribe((res) => {
      this.totalNumberOfElements.next(res);
    });
    this.dataSource.loadAttributes();
  }

  subscribeToSearchInputField(): void {
    this.attributeForm.valueChanges.subscribe(
      (formGroup) => {
        this.paginator.pageIndex = 0;
        if (this.displayBy === "ptg") {
          this.loadPtgPage(
            this.attributeForm.get("attributeSearch").value,
            this.sortString,
            0
          );
        } else if (this.displayBy === "name") {
          this.loadAttributePage(
            this.attributeForm.get("attributeSearch").value,
            this.sortString,
            0
          );
        }
      },
      (error) => {
        //
      }
    );
  }

  

  subscribeToPaginator(): void {
    this.paginator.page.subscribe(() => {
      if (this.displayBy === "name") {
        this.loadAttributePage(
          this.attributeForm.get("attributeSearch").value,
          this.sortString
        );
      } else if (this.displayBy === "ptg") {
        this.loadPtgPage(
          this.attributeForm.get("attributeSearch").value,
          this.sortString
        );
      }
    });
  }


  loadAttributePage(
    searchTerm: string,
    sortMethod: any,
    pageIndex: number = this.paginator.pageIndex
  ): void {
    this.dataSource.loadAttributes(
      pageIndex,
      this.paginator.pageSize,
      searchTerm.trim(),
      sortMethod
    );
  }

  loadPtgPage(
    searchTerm: string,
    sortMethod: any,
    pageIndex: number = this.paginator.pageIndex
  ): void {
    this.dataSourcePtg.loadPtgAttributes(
      pageIndex,
      this.paginator.pageSize,
      searchTerm.trim(),
      sortMethod
    );
  }

  getDescriptionString(textContainingHtmlElements: string): string {
    textContainingHtmlElements = textContainingHtmlElements.replace(
      /<br>/g,
      "\n"
    );
    textContainingHtmlElements = textContainingHtmlElements.replace(
      /\&nbsp;/g,
      ""
    );
    return textContainingHtmlElements.replace(/<[^>]*>/g, "");
  }
}

interface AttributeOverviewInterface extends AttributeInterface {
  hidden?: boolean;
}
