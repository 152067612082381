<div class="information">
    <div class="leftSide">
      <div class="itemWithLabel">
        <p>Name </p>
        <h2>{{ toolchainItemName }}</h2>
      </div>
    </div>
    <div class="rightSide">
      <div class="itemWithLabel">
        <p>Beschreibung</p>
        <app-hmtl-display [text]="toolchainItemDescription"></app-hmtl-display>
      </div>
    </div>
  </div>
  <div class="productSearch">
    <div class="rightSide">
      <div class="products" [ngClass]="{
        smallVersion: selectedItem !== undefined
      }">
        <mat-spinner *ngIf="changeDetected"></mat-spinner>
        <app-infinite-scroll (scrolled)="onScroll()" >
          <div class="list" *ngIf="!changeDetected">
            <ng-container *ngFor="let item of displayItem; let i = index;">
              <app-display-item [itemInfo]="item" [id]="'displayItem'  + i" (click)="updateSelectedProduct(item)"></app-display-item>
            </ng-container>
          </div>
        </app-infinite-scroll>
      </div>
      <app-baustelle-product-overview *ngIf="selectedItem"  [serverId]="serverId" [productId]="selectedItem.id" (collapseOverview)="collapseProductOverview()"></app-baustelle-product-overview>
    </div>
  </div>
<!--   <div class="buttons">
    <button mat-raised-button class="btn_green" [disabled]="!selectedItem" (click)="save()">Speichern</button>
    <button mat-raised-button class="btn_red" (click)="cancel()">Abbrechen</button>
  </div> -->
  