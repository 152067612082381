import { DataType } from './../../../../../model/data-type.enum';
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-product-detail-display-downloads",
  templateUrl: "./product-detail-display-downloads.component.html",
  styleUrls: [
    "./product-detail-display-downloads.component.css",
    "../product-detail-display.css",
  ],
})
export class ProductDetailDisplayDownloadsComponent implements OnInit {
  dataTypeEnum = DataType; 

  @Input() fileIds: string[];

  constructor() {}

  ngOnInit() {}
}
