import { ComposerDefinition } from "./composerDefinition";

/* Custom Types for BPMN Notation */
export const ComposerModdle = {
  name: ComposerDefinition.name,
  prefix: ComposerDefinition.prefix,
  uri: ComposerDefinition.uri,
  assosications: [],
  types: [
    {
      name: "ForkEvent",
      superClass: ["bpmn:IntermediateCatchEvent"],
      properties: [],
    },
    {
      name: "JoinEvent",
      superClass: ["bpmn:IntermediateCatchEvent"],
      properties: [],
    },
    {
      name: "ToolchainItem",
      superClass: ["bpmn:Task"],
      properties: [
        {
          name: "itemName",
          isAttr: true,
          type: "String",
        },
        {
          name: "description",
          isAttr: true,
          type: "String",
        },
        {
          name: "productId",
          isAttr: true,
          type: "String",
        },
        {
          name: "productName",
          isAttr: true,
          type: "String",
        },
        {
          name: "imageDataUrl",
          isAttr: true,
          type: "String",
        },
        {
          name: "selectedPTGs",
          isAttr: true,
          type: "String",
        },
      ],
    },
    {
      name: "ToolchainInstanceItem",
      superClass: ["bpmn:Task"],
      properties: [
        {
          name: "itemName",
          isAttr: true,
          type: "String",
        },
        {
          name: "description",
          isAttr: true,
          type: "String",
        },
        {
          name: "productId",
          isAttr: true,
          type: "String",
        },
        {
          name: "productName",
          isAttr: true,
          type: "String",
        },
        {
          name: "imageDataUrl",
          isAttr: true,
          type: "String",
        },
        {
          name: "selectedPTGs",
          isAttr: true,
          type: "String",
        },
        {
          name: "status",
          isAttr: true,
          type: "String"
        }
      ],
    },
    {
      name: "Process",
      superClass: ["bpmn:process"],
      properties: [
        {
          name: "description",
          isAttr: true,
          type: "String",
        },
        {
          name: "externalId",
          isAttr: true,
          type: "String",
        },
        {
          name: "logoDataUrl",
          isAttr: true,
          type: "String"
        },
        {
          name: "logo",
          isAttr: true,
          type: "String"
        }
      ],
    },
  ],
};
