import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { CommonFeatureInterface } from "src/app/model/commonFeature/common-feature-interface";
import { LinkingMethod } from 'src/app/model/enums/linkingMethod';
import { CommonFeatureService } from "src/app/services/common-feature-service/common-feature.service";
import { DialogService } from "src/app/services/dialog-service/dialog-service.service";
import { UserService } from "src/app/services/user-service/userservice";

@Component({
  selector: "app-common-feature-administration",
  templateUrl: "./common-feature-administration.component.html",
  styleUrls: ["./common-feature-administration.component.css"],
})
export class CommonFeatureAdministrationComponent implements OnInit, OnDestroy {
  public commonFeatureList: CommonFeatureInterface[] = [];
  public backUpCommonFeatureList: CommonFeatureInterface[] = [];
  public editOrder: boolean = false;
  constructor(
    private commonFeatureService: CommonFeatureService,
    private changeDetector: ChangeDetectorRef,
    private dialogService: DialogService,
    private userService: UserService,
    private router: Router,
    private titleService: Title,
  ) {
    // Reroutes to Marketplace if User is not Platform Admin
    if (!this.userService.isCurrentUserPlatformAdmin()) {
      this.router.navigateByUrl("/marktplatz");
    }
    document.documentElement.style.setProperty('--scrollStatus', 'auto');
  }

  ngOnInit() {
    this.titleService.setTitle("Allgemeine Merkmale - BIMSWARM");
    this.commonFeatureService
      .getAllFeatures()
      .subscribe((success: CommonFeatureInterface[]) => {
        this.commonFeatureList = success;
        this.backUpCommonFeatureList = success.map(
          (feature: CommonFeatureInterface) =>  Object.assign({}, feature)
        );
        if (!this.changeDetector["destroyed"]) {
          this.changeDetector.detectChanges();
        }
      });
  }

  ngOnDestroy()
  {
    document.documentElement.style.setProperty('--scrollStatus', 'hidden');
  }

  /* Updates a Feature from the List of shown Feature */
  public updateCommonFeature(feature: CommonFeatureInterface): void {
    this.commonFeatureList.splice(
      this.commonFeatureList.indexOf(
        this.commonFeatureList.find(
          (commonFeature) => {
            return commonFeature.id === feature.id;
          }
        )
      ),
      1,
      feature,
    );

    this.backUpCommonFeatureList.splice(
      this.backUpCommonFeatureList.indexOf(
        this.backUpCommonFeatureList.find(
          (commonFeature) => {
            return commonFeature.id === feature.id;
          }
        )
      ),
      1,
      Object.assign({}, feature)
    );
  }

  /* Deletes a Feature from the List of shown Features */
  public deleteCommonFeature(feature: CommonFeatureInterface): void {
    this.commonFeatureList.splice(
      this.commonFeatureList.indexOf(
        this.commonFeatureList.find(
          (commonFeature) => commonFeature.id === feature.id
        )
      ),
      1
    );
  }

  /* Adds a Feature to the List of shown Features */
  public addCommonFeature(feature: CommonFeatureInterface): void {
    this.commonFeatureList.unshift(feature);
    this.backUpCommonFeatureList.unshift(Object.assign({}, feature));
  }

  /**
   * Verändert die Position der Merkmale
   * @param commonFeature
   * @param isDirectionUp true for up, false for down
   */
  public moveCommonFeature(
    commonFeature: CommonFeatureInterface,
    isDirectionUp: boolean
  ) {
    const offset: number = isDirectionUp ? -1 : 1;

    const index: number = this.commonFeatureList.findIndex(
      (c) => c === commonFeature
    );
    const oldPosition: number = commonFeature.position;

    if (
      (!this.isTopOfList(commonFeature) && isDirectionUp) ||
      (!this.isBottomOfList(commonFeature) && !isDirectionUp)
    ) {
      let commonFeatureWithOffset: CommonFeatureInterface = this
        .commonFeatureList[index + offset];

      // swap position value
      commonFeature.position = commonFeatureWithOffset.position;
      commonFeatureWithOffset.position = oldPosition;

      // swap position in Array
      this.commonFeatureList[index] = commonFeatureWithOffset;
      this.commonFeatureList[index + offset] = commonFeature;
    }
  }

  /**
   * Prüft ob commonFeature, oben in der Liste ist
   * @param commonFeature
   */
  public isTopOfList(commonFeature: CommonFeatureInterface): boolean {
    return (
      this.commonFeatureList.reduce((a, b) => (a.position > b.position ? a : b))
        .position === commonFeature.position
    );
  }

  /**
   * Prüft, ob commonFeature, unten in der Liste ist
   * @param commonFeature
   */
  public isBottomOfList(commonFeature: CommonFeatureInterface): boolean {
    return (
      this.commonFeatureList.reduce((a, b) => (a.position < b.position ? a : b))
        .position === commonFeature.position
    );
  }

  /**
   * Reihenfogle ändern abbrechen, alte Reihenfolge wird wieder hergestellt
   */
  public cancel() {
    this.editOrder = false;
    this.commonFeatureList = this.backUpCommonFeatureList.map((feature) =>
      Object.assign({}, feature)
    );
  }

  /**
   * Neue Reihenfolge speichern
   */
  public saveOrder(): void {
    this.commonFeatureService
      .updateFeatures(this.commonFeatureList)
      .subscribe((newList) => {
        this.commonFeatureList = newList;
        this.backUpCommonFeatureList = newList.map((feature) =>
          Object.assign({}, feature)
        );
        this.dialogService
          .openDialog("Die Reihenfolge wurde erfolgreich gespeichert", "")
          .subscribe();
        this.editOrder = false;
        this.changeDetector.detectChanges();
      });
  }
}
